import {bindable, customElement} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {Transaction} from '../models/ad-server-debug-response';

@customElement('transaction-request')
export class TransactionRequestViewModel {
    @bindable
    public data: Transaction;

    public requestHeaders = [];
    public headerNames = [
        {
            colClass: 't175',
            colHeadName: 'header',
            colHeadValue: 'Header',
        },
        {
            colClass: 't450',
            colHeadName: 'value',
            colHeadValue: 'Value',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
            ),
        },
    ];

    public dataChanged(newVal) {
        if (newVal && newVal.request_headers) {
            const headersObj = JSON.parse(newVal.request_headers);
            this.requestHeaders = Object.keys(headersObj)
                .sort()
                .map(header => ({
                    header,
                    value: headersObj[header],
                }));
        } else {
            this.requestHeaders = [];
        }
    }
}
