import {bindable, computedFrom} from 'aurelia-framework';
import {DurationTools} from 'utils/duration-tools';

export class DurationInput {
  @bindable
  public disabled: boolean = false;

  @bindable
  public duration: number = 0;

  @bindable
  public errorMessage: string;

  private _errorMessage: string;

  @bindable
  public helpTip: string;

  @bindable
  public helpText: string;

  @bindable
  public label: string;

  /* A function to call when the duration changes
   * because the humanized display value is typically not what we want to
   * listen for changes to until the duration has been recalculated
   */
  @bindable
  public onChange: (duration: number) => void;

  @bindable
  public placeholder: string = 'HH:MM:SS';

  @bindable
  public readonly: boolean = false;

  @bindable
  public required: boolean = false;

  @bindable
  public size: 'large' | 'medium' | 'small' = 'medium';

  @bindable
  public state: 'error' | 'warning' | 'success' | 'default' = 'default';

  @bindable
  public unit: 'milliseconds' | 'seconds' = 'seconds';

  /* The string value of the input
   * this is converted to the specified unit and returned in the onChange
   * callback then transformed to HHMMSS format for the input display value
   */
  @bindable
  public value: string = '';

  @computedFrom('errorMessage', '_errorMessage')
  get durationErrorMessage() {
    return this._errorMessage || this.errorMessage;
  }

  public attached() {
    if (this.value) {
      this.handleChange();
    }
  }

  public handleChange() {
    this._errorMessage = null;
    this.state = 'default';

    try {
      let units = 0;

      if (!this.value && this.required) {
        this._errorMessage = 'Please enter a valid duration.';
        this.state = 'error';
      }

      if (this.value && this.unit === 'seconds') {
        units = DurationTools.toSeconds(this.value);
        this.value = DurationTools.secondsToHms(units);
      } else if (this.value && this.unit === 'milliseconds') {
        units = DurationTools.toMilliseconds(this.value);
        this.value = DurationTools.millisecondsToHms(units);
      }

      this.duration = units;

      if (_.isFunction(this.onChange)) {
        this.onChange(units);
      }
    } catch (err) {
      this._errorMessage = err.message;
      this.state = 'error';
    }
  }
}
