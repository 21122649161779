import {XAuthUtils} from './x-auth-utils';

import {WorkspaceKey} from '../models/workspace-key';
import {WorkspaceKeyRevision} from '../models/workspace-key-revision';

export interface IEnvParams {
  PRIVATE_B64?: string;
  PUBLIC_B64: string;
  ALG: string;
  ISS?: string;
  KID: string;
  SUB: string;
  SCP: string[];
}

export function saveFile(text, fileName) {
  const file = new Blob([text], {type: 'text/plain'});

  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(link.href);
}

export function asConfigString(key: WorkspaceKey, revision: WorkspaceKeyRevision) {
  const {headers, payload} = XAuthUtils.revisionDetails(key, revision);
  const {pk} = revision;

  const {origin = null} = window.location;

  const params = [
    {key: 'PRIVATE_B64', value: key.key || '<REPLACE-WITH-B64-ENCODED-PRIVATE-KEY>'},
    {key: 'PUBLIC_B64', value: pk.key},
    {key: 'ALG', value: pk.algorithm},
    {key: 'KID', value: headers.kid},
    {key: 'SUB', value: payload.sub},
    {key: 'SCP', value: payload.scp},
    {key: 'ROOT_URL', value: origin.replace('cms', 'services')},
  ];

  return params.map(p => `${p.key}="${p.value}"`).join('\n');
}

export function asEnvFile(key: WorkspaceKey, revision: WorkspaceKeyRevision) {
  const text = asConfigString(key, revision);

  saveFile(text, `${key.id}_rev${revision.revision}.env`);
}

export function asPemFile(key: WorkspaceKey, {pk, revision}: WorkspaceKeyRevision) {
  if (!key.key) {
    throw new Error('"key" cannot be null');
  }

  const text = `
${window.atob(pk.key)}

${window.atob(key.key)}
`;

  saveFile(text, `${key.id}_rev${revision}.pem`);
}
