import {autoinject, BindingEngine, observable} from 'aurelia-framework';
import {InputValidationCustomAttribute} from 'custom-attributes/input-validation';
import {basicSetup, dirtyCheckPrompt} from 'decorators';
// tslint:disable-next-line:import-name
import * as DragSelect from 'dragselect';
import {Notification} from 'resources/notification/service';
import {StreamDomainService} from './services/stream-domain-service';

@basicSetup
@dirtyCheckPrompt
@autoinject()
export class StreamDomains {
    @observable
    public streamDomainType: string;

    public originalStreamDomainType: string;
    public selectedDomains: string[] = [];
    public streamDomains: string[] = [];
    public streamDomainsPristine: string[] = [];
    public newDomain;
    public streamDomainBox;
    public tc;
    public canSave: boolean = false;
    public validationAttr: InputValidationCustomAttribute;

    constructor(
        public notification: Notification,
        public streamDomainService: StreamDomainService,
        public bindingEngine: BindingEngine,
    ) {}

    public streamDomainTypeChanged(newValue, oldValue) {
        if (newValue && oldValue && newValue !== oldValue) {
            this.streamDomainType = newValue;
            this.canSave = this.streamDomainType !== this.originalStreamDomainType;
        }
    }

    public setDragSelect = () => {
        this.streamDomainBox = new DragSelect({
            area: document.getElementById('stream-box'),
            selectables: document.querySelectorAll('.domain'),
        });
    };

    public updateDragSelectInstance = () => {
        if (this.streamDomainBox) {
            this.streamDomainBox.removeSelectables();
            this.streamDomainBox.clearSelection();
            this.streamDomainBox.addSelectables(document.querySelectorAll('.domain'));
        }
    };

    public validateDomain = (newDomain): boolean =>
        newDomain &&
        this.validationAttr.domainNameValidation(newDomain) &&
        this.streamDomains.indexOf(newDomain) === -1;

    public getData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.streamDomainService
                .getData()
                .then(res => {
                    this.streamDomains = _.get(res, 'stream_domain_list');
                    this.streamDomainType = _.get(res, 'stream_domain_type');
                    this.originalStreamDomainType = this.streamDomainType;
                    this.streamDomainsPristine = _.cloneDeep(this.streamDomains);
                    setTimeout(() => {
                        this.setDragSelect();
                    }, 0);
                    resolve();
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public addDomainName(newDomain): Promise<any> {
        if (!newDomain) {
            return Promise.resolve();
        }

        if (this.streamDomains.indexOf(newDomain) > -1) {
            this.newDomain = '';
            this.notification.info('This domain is already added.');
            return Promise.resolve();
        }

        return this.update(
            false,
            newDomain.split(',').map(d => d.trim()),
        ).then(() => {
            this.newDomain = '';
            // timeout needed to properly update drag select when there are no entries in the
            // 'stream-box' element to ensure it has rendered on the DOM, otherwise item
            // will not be selectable
            setTimeout(() => {
                this.updateDragSelectInstance();
            }, 0);
        });
    }

    public deleteSelectedDomain(): Promise<any> {
        if (this.streamDomainBox) {
            const selectedNodes = this.streamDomainBox.getSelection();
            const copy = _.cloneDeep(this.streamDomains);
            selectedNodes.forEach(node => {
                const s = node.getAttribute('id');
                copy.splice(copy.indexOf(s), 1);
            });
            return this.update(true, copy).then(() => {
                this.tc.hide();
            });
        }
        return Promise.resolve();
    }

    public saveData(): Promise<any> {
        return this.update(false);
    }

    public update(isDel?: boolean, _data?: string[]): Promise<any> {
        const n = this.streamDomains.length;
        return new Promise((resolve, reject) => {
            const data = {
                stream_domain_list: isDel
                    ? _data
                    : _data
                    ? [..._.cloneDeep(this.streamDomains), ..._data]
                    : this.streamDomains,
                stream_domain_type: this.streamDomainType,
            };
            this.streamDomainService
                .update(data)
                .then(({item, error} = {}) => {
                    if (error) {
                        this.notification.error('Server side error');
                        reject(new Error('Server side error'));
                    } else {
                        this.streamDomainsPristine = _.cloneDeep(this.streamDomains);
                        const notificationMsg = isDel
                            ? 'Stream Domain deleted successfully.'
                            : 'Stream Domain saved successfully.';
                        this.notification.success(notificationMsg);
                        this.streamDomains.splice(0, n, ..._.get(item, 'stream_domain_list'));
                        this.originalStreamDomainType = this.streamDomainType;
                        this.canSave = false;
                        resolve();
                    }
                })
                .catch(e => {
                    const msg = `${e}`;
                    this.notification.error(msg);
                    reject(e);
                });
        });
    }

    public isDirty(): boolean {
        return this.canSave;
    }
}
