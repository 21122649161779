import {computedFrom} from 'aurelia-framework';

import * as moment from 'moment';
import {plainToClass} from 'class-transformer';
import {BaseAssetSingle} from './base';
import {ILibrarySelect, Library, LibraryType, SimpleAsset} from '../models/models';

export class AssetDetails extends BaseAssetSingle {
  public isUploading: boolean = false;
  public tabFields: string[] = [
    'title',
    'external_id',
    'autoexpire',
    'poster_url',
    'generate_staticm3u8',
  ];

  public library: Library = null;
  public librarySelect: ILibrarySelect = {
    actions: {
      onScrollBottom: () => {
        const {meta} = this.librarySelect;
        const {hasMore = false} = meta;

        if (hasMore) {
          this.loadLibraryOptions(true);
        }
      },
      onSearch: searchQuery => {
        this.librarySelect.params.page = 1;
        this.librarySelect.searchQuery = searchQuery;
        this.loadLibraryOptions();
      },
    },
    meta: {},
    options: [],
    params: {
      order: 'desc',
      page: 1,
    },
    searchQuery: '',
  };

  public selectedLibrary: string;

  // Used for the little tip table for profile details
  public profileCols = [
    {
      colHeadName: 'key',
      colHeadValue: 'Key',
    },
    {
      colHeadName: 'value',
      colHeadValue: 'Value',
    },
  ];

  public showBif: boolean = false;

  // Private variables
  public isProcessing: boolean = false;
  private loadedLibraryOptions = false;

  @computedFrom('fields["title"].errors.length', 'model.mine')
  get assetNameState() {
    if (!this.model || !this.model.mine) {
      return 'disabled';
    }

    if (this.fields.title.errors.length > 0) {
      return 'error';
    }

    return '';
  }

  @computedFrom('model.autoexpire')
  get autoExpireDirty() {
    const {currentModel} = this.content;
    const {model} = this;

    if (!currentModel || !model) {
      return false;
    }
    if (!currentModel.autoexpire && !model.autoexpire) {
      return false;
    }

    const timeFormat = 'MM/DD/YYYY HH:MM';
    let timeA;
    let timeB;

    if (currentModel.autoexpire) {
      timeA = moment(currentModel.autoexpire).format(timeFormat);
    }

    if (model.autoexpire) {
      timeB = moment(model.autoexpire).format(timeFormat);
    }

    const isDirty = timeA !== timeB;

    // Update field `isDirty` state
    this.fields.autoexpire.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.autoexpire', 'content.currentModel.state')
  get autoExpireState() {
    if (
      !this.content.currentModel ||
      !this.model ||
      !this.model.mine ||
      this.content.currentModel.state === 'slicing'
    ) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('model.autoexpire', 'content.currentModel.state')
  get autoExpireDisabledText() {
    if (!this.content.currentModel || !this.model) {
      return '';
    }

    if (this.content.currentModel.state === 'slicing') {
      return 'Cannot be set while slicing';
    }

    if (!this.model.autoexpire) {
      return 'Never';
    }

    return '';
  }

  @computedFrom('selectedMeta.length', 'model.mine')
  get deleteButtonState() {
    if (!this.model || !this.model.mine) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('model.external_id')
  get externalIdDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.content.currentModel.external_id !== this.model.external_id;

    // Update field `isDirty` state
    this.fields.external_id.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.poster_url')
  get posterUrl() {
    if (!this.model) {
      return undefined;
    }

    return this.model.poster_url;
  }

  @computedFrom('model.poster_url')
  get posterUrlDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.content.currentModel.poster_url !== this.model.poster_url;

    // Update field `isDirty` state
    this.fields.poster_url.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.poster_url', 'model.default_poster_url')
  get showPosterReset() {
    return this.model && this.model.poster_url !== this.model.default_poster_url;
  }

  @computedFrom('model.generate_staticm3u8')
  get staticM3u8EnabledDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.content.currentModel.generate_staticm3u8 !== this.model.generate_staticm3u8;

    // Update field `isDirty` state
    this.fields.generate_staticm3u8.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('titleDirty', 'autoExpireDirty', 'externalIdDirty', 'posterUrlDirty', 'staticM3u8EnabledDirty')
  get tabDirty() {
    return (
      this.titleDirty ||
      this.externalIdDirty ||
      this.autoExpireDirty ||
      this.posterUrlDirty ||
      this.staticM3u8EnabledDirty
    );
  }

  // Need one for every field on the tab + one for the tab
  @computedFrom('model.title')
  get titleDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.content.currentModel.title !== this.model.title;

    // Update field `isDirty` state
    this.fields.title.isDirty = isDirty;

    return isDirty;
  }

  /* Aurelia Hooks */
  public attached() {
    this.content.activeTab = '';
    super.attached();

    if (!this.loadedLibraryOptions) {
      this.loadLibraryOptions();
    }
  }

  public async resetPosterUrl() {
    try {
      this.isUploading = true;
      await this.content.resetThumbnailImage(this.model.id);
      await this.gracefulUpdate();
      this.notification.success('Poster has been reset to default');
    } catch (err) {
      this.notification.error('Cannot reset poster');
    } finally {
      this.isUploading = false;
    }
  }

  public async uploadImage(files: File[] = []) {
    if (files && files.length > 0) {
      try {
        this.isUploading = true;
        await this.content.uploadThumbnailImage(this.model.id, files);
        await this.gracefulUpdate();
        this.notification.success('Uploaded');
      } catch (err) {
        this.notification.error(`Cannot upload file: ${err.message}`);
      } finally {
        this.isUploading = false;
      }
    }
  }

  @computedFrom('libraryService.currentLibraries.length')
  get currentLibraries() {
    const libraries = this.libraryService.currentLibraries;

    if (libraries && libraries.length > 0) {
      return {
        owner: libraries.filter(l => l.library_type === LibraryType.OWNER),
        shared: libraries.filter(l => l.library_type === LibraryType.SHARED),
      };
    }
    return undefined;
  }

  @computedFrom('librarySelect.options.length', 'libraries.owner')
  get librarySelectMap() {
    let libs = [];

    if (this.currentLibraries) {
      libs = this.currentLibraries.owner;
    }

    const excludeIds = libs.map(l => l.id);
    const {options} = this.librarySelect;
    const filtered = options
      .filter(l => !excludeIds.includes(l.id))
      .map(library => ({
        text: library.desc,
        value: library.id,
      }));

    return filtered;
  }

  public async addToLibrary() {
    this.isProcessing = true;

    const sAsset = plainToClass(SimpleAsset, _.cloneDeep(this.content.currentModel));
    const asset = await this.libraryService.addAssetToLibrary(this.selectedLibrary, sAsset);

    if (asset) {
      const {currentLibraries = []} = this.libraryService;
      const {options = []} = this.librarySelect;
      const library = options.find(l => l.id === this.selectedLibrary);

      currentLibraries.push(library);
    }

    this.isProcessing = false;
    this.selectedLibrary = null;
  }

  public async removeFromLibrary(library: Library) {
    if (await this.libraryService.removeAssetsFromLibrary(library.id, [this.content.currentModel.id])) {
      const {currentLibraries} = this.libraryService;

      const index = currentLibraries.findIndex(l => l.id === library.id);

      if (index > -1) {
        currentLibraries.splice(index, 1);
      }
    }
  }

  private async loadLibraryOptions(loadMore: boolean = false) {
    const {options, searchQuery, params: queryParams} = this.librarySelect;
    const {libraries, meta, params} = await this.libraryService.getLibraries(
      options,
      queryParams,
      searchQuery,
      loadMore,
    );

    this.librarySelect.options = libraries;
    this.librarySelect.params = params;
    this.librarySelect.meta = meta;
    this.loadedLibraryOptions = true;
  }
}
