exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3p3EZ{cursor:pointer;float:right;font-size:12px}", ""]);

// exports
exports.locals = {
	"copy": "_3p3EZ"
};