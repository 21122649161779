import {CTableSelectable} from 'resources/components/c-table-selectable/c-table-selectable';
import {
    CTableColSelectable,
    CTableRowBehavior,
} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {ProfileDefault} from '../../single/models';
import {ProfileTableRow} from './table-models';

export class ProfileUsageTable extends CTableSelectable {
    public rows: ProfileTableRow[];
    private colsSelectableDefaultLiveSlicers: CTableColSelectable[] = [
        {
            colHeadName: 'slicer_id',
            colHeadValue: 'Slicer ID',
            selected: true,
            sort: true,
            valueConverter: 'defaultValue',
        },
        {
            colHeadName: 'lastused',
            colHeadValue: 'Last Used',
            selected: true,
            sort: true,
            valueConverter: 'timezoneTimeToStandardDateTimeMS',
        },
        {
            colHeadName: 'created',
            colHeadValue: 'Date Created',
            selected: true,
            sort: true,
            valueConverter: 'timezoneTimeToStandardDateTimeMS',
        },
        {
            colHeadName: 'lastmod',
            colHeadValue: 'Date Modified',
            selected: true,
            sort: true,
            valueConverter: 'timezoneTimeToStandardDateTimeMS',
        },
    ];

    private colsSelectableDefaultVodAssets: CTableColSelectable[] = [
        {
            colHeadName: 'desc',
            colHeadValue: 'Description',
            selected: true,
            sort: true,
            valueConverter: 'defaultValue',
        },
        {
            colHeadName: 'slicer_id',
            colHeadValue: 'Slicer ID',
            selected: true,
            sort: true,
            valueConverter: 'defaultValue',
        },
        {
            colHeadName: 'duration',
            colHeadValue: 'Duration',
            selected: true,
            sort: true,
            valueConverter: 'defaultValue',
        },
        {
            colHeadName: 'created',
            colHeadValue: 'Date Created',
            selected: true,
            sort: true,
            valueConverter: 'timezoneTimeToStandardDateTimeMS',
        },
        {
            colHeadName: 'lastmod',
            colHeadValue: 'Date Modified',
            selected: true,
            sort: true,
            valueConverter: 'timezoneTimeToStandardDateTimeMS',
        },
    ];

    constructor(type: ProfileDefault) {
        super(CTableRowBehavior.DEFAULT);
        if (type === ProfileDefault.LIVE) {
            this.colsSelectable = this.colsSelectableDefaultLiveSlicers;
        } else if (type === ProfileDefault.VOD) {
            this.colsSelectable = this.colsSelectableDefaultVodAssets;
        }
    }
}
