import {SharedNav} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterConfiguration} from 'aurelia-router';

export enum RouteName {
    FailoverGroupsList = 'failoverGroupsList',
    FailoverGroupSingle = 'failoverGroupSingle',
}

@autoinject()
export class FailoverGroupsIndex {
    public router: Router;
    public routes = [
        {
            moduleId: PLATFORM.moduleName('./list/index'),
            name: RouteName.FailoverGroupsList,
            nav: false,
            route: '',
            title: 'Failover Groups',
        },
        {
            moduleId: PLATFORM.moduleName('./single/route'),
            name: RouteName.FailoverGroupSingle,
            nav: false,
            route: ':id',
            title: 'Failover Group',
        },
    ];

    constructor(public sharedNav: SharedNav) {}

    public attached() {
        this.sharedNav.setNextText('Failover Groups List');
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        this.router = router;
        config.map(this.routes);
    }
}
