import {Type} from 'class-transformer';
import {IsInt, IsOptional, IsString, ValidateNested} from 'class-validator';
import {IHyperion, IMSOSession, IMSOTotalItems} from './mso';

// Slicer Version common properties

export interface IMSOSlicerVersion {
    /**
     * (optional) A date string indicating when this Slicer Version was created.
     * Example: "Jan 31, 2021 9:19:22 AM"
     */
    creation_time?: string;

    /**
     * (optional) A label or human friendly tag for identifying this Slicer Version
     */
    description?: string;

    /**
     * (optional) The MSS tag of the docker the slicer version is distributed in
     */
    // todo: re-enable this once MSO adds support for it
    // dockerTag?: string;

    /**
     * The Slicer software version (i.e 20010700) associated with this MSS Slicer Version
     */
    slicer_version: string;

    /**
     * The MSS identifier for this Slicer Version (usually "LATEST" or "STABLE")
     */
    version_id: string;
}

// Slicer Version response: get

export class MSOSlicerVersionResponseItem implements IMSOSlicerVersion {
    @IsOptional()
    @IsString()
    public creation_time?: string;

    @IsOptional()
    @IsString()
    public description?: string;

    // todo: re-enable this once MSO adds support for it
    // @IsOptional()
    // @IsString()
    // public dockerTag?: string;

    @IsString()
    public slicer_version: string;

    @IsString()
    public version_id: string;
}

export class MSOSlicerVersionResponseItems implements IHyperion, IMSOTotalItems, IMSOSession {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsString()
    public cms_session_id_fingerprint: string;

    @ValidateNested()
    @Type(() => MSOSlicerVersionResponseItem)
    public items: MSOSlicerVersionResponseItem[];

    @IsInt()
    public total_items: number;
}
