import {AureliaConfiguration} from 'aurelia-configuration';
import {EventAggregator} from 'aurelia-event-aggregator';
import {inject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {CToastsService} from '@bindable-ui/bindable';

import {Acceo} from 'services/acceo';
import {AuthorizeStep} from 'services/auth';
import {Authorization} from 'services/authorization';
import {SessionService} from 'services/session';

@inject(EventAggregator, AureliaConfiguration, SessionService, Authorization, Acceo, CToastsService)
export class App {
  constructor(eventAggregator, config, sessionService, authorization, acceo, cToastsService) {
    this.eventAggregator = eventAggregator;
    this.config = config;
    this.session = sessionService;
    this.acceo = acceo;
    this.notification = cToastsService;

    this.routes = [
      {
        route: [
          '',
          'default',
        ],
        name: 'default',
        moduleId: PLATFORM.moduleName('./panels/default'),
        nav: false,
        title: 'Default Panel',
        settings: {live: true, allUsers: true},
      },
      {
        route: 'decrypturl',
        name: 'decryptUrl',
        moduleId: PLATFORM.moduleName('./panels/decrypt-url/index'),
        nav: true,
        title: 'Decrypt URL',
        settings: {live: true, allUsers: true},
      },
      {
        route: 'cms-analytics',
        name: 'cmsAnalytics',
        moduleId: PLATFORM.moduleName('./panels/cms-analytics/index'),
        nav: true,
        title: 'CMS Analytics',
        settings: {live: true, entitlement: 'billingRollup'},
      },
      {
        route: 'create-account',
        name: 'createAccount',
        moduleId: PLATFORM.moduleName('./panels/create-account/index'),
        nav: true,
        title: 'Create Account',
        settings: {live: true},
      },
      {
        route: 'failover-settings',
        name: 'failoverSettings',
        moduleId: PLATFORM.moduleName('./panels/failover-settings/index'),
        nav: true,
        title: 'Failover Settings',
        settings: {live: true, entitlement: 'failoverSettings'},
      },
      {
        route: 'billing-rollup',
        name: 'billingRollup',
        moduleId: PLATFORM.moduleName('./panels/billing-rollup/index'),
        nav: true,
        title: 'Billing Rollup',
        settings: {live: true, entitlement: 'ownerPerms'},
      },
      {
        route: 'encoding_profiles',
        name: 'encodingProfiles',
        moduleId: PLATFORM.moduleName('./panels/encoding-profiles/index'),
        nav: true,
        title: 'Encoding Profiles',
        settings: {live: true},
      },
      {
        route: 'balance-adjustments',
        name: 'balanceAdjustment',
        navigationStrategy: 'newWindow',
        nav: true,
        title: 'Balance Adjustments (v1)',
        settings: {live: true, newWindow: this.getOldAcuityUrl('/balanceAdjustments')},
      },
      {
        route: 'admin-actions',
        name: 'adminActions',
        navigationStrategy: 'newWindow',
        nav: true,
        title: 'Admin Actions (v1)',
        settings: {live: true, newWindow: this.getOldAcuityUrl('/adminactions')},
      },
      {
        route: 'ids-settings',
        name: 'idsSettings',
        moduleId: PLATFORM.moduleName('./panels/ids-settings/index'),
        nav: true,
        title: 'IDS Settings',
        settings: {live: true},
      },
      {
        route: 'identity',
        name: 'identity',
        moduleId: PLATFORM.moduleName('./panels/identity/index'),
        nav: true,
        title: 'Identity Tools',
        settings: {live: true},
      },
      {
        route: 'owner-permissions',
        name: 'ownerPerms',
        moduleId: PLATFORM.moduleName('./panels/owner-permissions/index'),
        nav: true,
        title: 'Owner Permissions',
        settings: {live: true},
      },
      {
        route: 'multicdn-settings',
        name: 'multiCdnSettings',
        navigationStrategy: 'newWindow',
        nav: true,
        title: 'MultiCDN Settings (v1)',
        settings: {live: true, newWindow: this.getOldAcuityUrl('/multicdnsetings')},
      },
      {
        route: 'vpa-service-settings',
        name: 'vpaServicesSettings',
        navigationStrategy: 'newWindow',
        nav: true,
        title: 'VPA Service Settings (v1*)',
        settings: {live: true, newWindow: this.getOldAcuityUrl('')},
      },
      {
        route: 'encoding-profiles-old',
        name: 'encodingProfilesOld',
        navigationStrategy: 'newWindow',
        nav: true,
        title: 'Encoding Profiles (v1)',
        settings: {live: true, newWindow: this.getOldAcuityUrl('/encodingprofiles')},
      },
      {
        route: 'ingest-routing',
        name: 'ingestRouting',
        moduleId: PLATFORM.moduleName('./panels/ingest-routing/index'),
        nav: true,
        title: 'Ingest Routing',
        settings: {live: true},
      },
      {
        route: 'news-items',
        name: 'newsItems',
        moduleId: PLATFORM.moduleName('./panels/news-items/index'),
        nav: true,
        title: 'News Items',
        settings: {live: true},
      },
      {
        route: 'object-explorer',
        name: 'objectExplorer',
        moduleId: PLATFORM.moduleName('./panels/object-explorer/index'),
        nav: true,
        title: 'Object Explorer',
        settings: {live: true},
      },
      {
        route: 'deadbeats',
        name: 'deadbeats',
        moduleId: PLATFORM.moduleName('./panels/deadbeats/index'),
        nav: true,
        title: 'Deadbeats',
        settings: {live: true},
      },
      {
        route: 'external-ads',
        name: 'externalAds',
        navigationStrategy: 'newWindow',
        nav: true,
        title: 'External Ads (v1)',
        settings: {live: true, newWindow: this.getOldAcuityUrl('/externalads')},
      },
      {
        route: 'box-manage',
        name: 'boxManage',
        moduleId: PLATFORM.moduleName('./panels/box-manage/index'),
        nav: true,
        title: 'Box Manage',
        settings: {live: true},
      },
      {
        route: 'search-service',
        name: 'searchService',
        moduleId: PLATFORM.moduleName('./panels/search-service/index'),
        nav: true,
        title: 'Search Service',
        settings: {live: true},
      },
      {
        route: 'dns-tooling',
        name: 'dnsTooling',
        moduleId: PLATFORM.moduleName('./panels/dns-tooling/index'),
        nav: true,
        title: 'Content Dns Tooling',
        settings: {live: true},
      },
      {
        route: 'low-latency',
        name: 'lowLatency',
        moduleId: PLATFORM.moduleName('./panels/low-latency/index'),
        nav: true,
        title: 'Low Latency',
        settings: {live: true},
      },
      {
        route: 'google-ad-manager',
        name: 'googleAdManager',
        moduleId: PLATFORM.moduleName('./panels/google-ad-manager/index'),
        nav: true,
        title: 'Google Ad Manager',
        settings: {live: true},
      },
      {
        route: 'retokeniser',
        name: 'reTokeniser',
        moduleId: PLATFORM.moduleName('./panels/retokeniser/index'),
        nav: true,
        title: 'Re-Tokeniser',
        settings: {live: true, entitlement: 'reTokeniser'},
      },
      {
        route: 'player-tester',
        name: 'playerTester',
        moduleId: PLATFORM.moduleName('./panels/player-tester/index'),
        nav: true,
        title: 'Player Tester',
        settings: {live: true},
      },
      {
        route: 'old-acuity',
        name: 'oldAcuity',
        navigationStrategy: 'newWindow',
        nav: true,
        title: 'Acuity v1',
        settings: {live: true, newWindow: this.getOldAcuityUrl(''), allUsers: true},
      },
      {
        route: 'layout/basic',
        name: 'layoutBasic',
        moduleId: PLATFORM.moduleName('./panels/layout-examples/basic/index'),
        nav: true,
        title: 'Layout Basic',
        settings: {live: true, allUsers: true},
      },
      {
        route: 'layout/cover',
        name: 'layoutCover',
        moduleId: PLATFORM.moduleName('./panels/layout-examples/cover/index'),
        nav: true,
        title: 'Layout Cover',
        settings: {live: true, allUsers: true},
      },
      {
        route: 'layout/cover-sidebar',
        name: 'layoutCoverSidebar',
        moduleId: PLATFORM.moduleName('./panels/layout-examples/cover-sidebar/index'),
        nav: true,
        title: 'Layout Cover Sidebar',
        settings: {live: true, allUsers: true},
      },
      {
        route: 'layout/cover-table',
        name: 'layoutCoverTable',
        moduleId: PLATFORM.moduleName('./panels/layout-examples/cover-table/index'),
        nav: true,
        title: 'Layout Cover Table',
        settings: {live: true, allUsers: true},
      },
      {
        route: 'layout/cover-table-horizontal',
        name: 'layoutCoverTableHorizontal',
        moduleId: PLATFORM.moduleName('./panels/layout-examples/cover-table-horizontal/index'),
        nav: true,
        title: 'Layout Cover Table Horizontal',
        settings: {live: true, allUsers: true},
      },
    ];

    // sort titles in navigation sidebar
    const sortTitle = (a, b) => a.title.localeCompare(b.title);
    this.routes = this.routes.sort(sortTitle);

    // Handle new tab transitions.
    /* istanbul ignore next */
    this.eventAggregator.subscribe('router:navigation:processing', resp => {
      const {live, newWindow} = resp.instruction.config.settings;

      if (live && newWindow) {
        window.open(newWindow);
      }
    });
  }

  getOldAcuityUrl(appURI) {
    // todo: determine baseurl uplynk/downlynk .com/.net/.localhost
    return `https://acuity.uplynk.com/#!/selector${appURI}`;
  }

  async configureRouter(config, router) {
    const url = '/ops/session/entitlements';
    try {
      const resp = await this.acceo.get()(url);
      this.routes = this.routes.filter(
        route =>
          resp.opsEntitlements.includes(route.name) ||
          route.settings.allUsers ||
          resp.opsEntitlements.includes(route.settings.entitlement) ||
          resp.isAdmin,
      );
    } catch (err) {
      this.routes = this.routes.filter(route => route.settings.allUsers);
      this.notification(`Error getting user entitlements. ${err}`);
    }

    this.router = router;
    config.addPipelineStep('authorize', AuthorizeStep);
    config.map(this.routes);
    config.fallbackRoute('default');
  }

  /* istanbul ignore next */
  async attached() {
    this.session.sessionPing();
  }
}
