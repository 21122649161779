import {IsArray, IsBoolean, IsJSON, IsNumber, IsOptional, IsString, IsUrl, ValidateNested} from 'class-validator';

import {Type} from 'class-transformer';

export class MetaKeyValue {
    @IsString()
    public key: string;

    @IsOptional()
    public value: string;

    @IsBoolean()
    @IsOptional()
    public checkbox?: boolean = false;
}

export class ProfileObject {
    @IsString()
    @IsOptional()
    public id: string;

    @IsString()
    @IsOptional()
    public rate_card_id: string;

    @IsString()
    @IsOptional()
    public gpu_slicer: string;

    @IsString()
    @IsOptional()
    public gpu_encoder: string;

    @IsArray()
    @IsOptional()
    public b_frame_rays: string[];

    @IsArray()
    @IsOptional()
    public ifo_rays: string[];
}

export class BreakOffset {
    @IsString()
    public break_type: string;

    @IsString()
    @IsOptional()
    public name?: string;

    @IsNumber()
    public offset: number;

    @IsNumber()
    @IsOptional()
    public duration?: number;

    @IsJSON()
    @IsOptional()
    public ad_meta?: any = {};
}

export class TestPlayer {
    @IsUrl()
    @IsOptional()
    public url?: string;

    @IsUrl()
    @IsOptional()
    public url_html5?: string;

    @IsString()
    @IsOptional()
    public id?: string;

    @IsNumber()
    @IsOptional()
    public expire?: number;

    @IsString()
    @IsOptional()
    public desc?: string;

    @IsString()
    @IsOptional()
    public params?: string;

    @IsBoolean()
    @IsOptional()
    public whitelist?: boolean = false;

    @IsBoolean()
    @IsOptional()
    public checkbox?: boolean = false;

    @IsString()
    @IsOptional()
    public view?: string = 'View';

    @IsString()
    @IsOptional()
    public copy?: string = 'Copy URL';
}

export class AssetModel {
    @IsBoolean()
    @IsOptional()
    public audio_only?: boolean;

    @IsString()
    @IsOptional()
    public autoexpire?: string;

    @IsArray()
    @IsOptional()
    public break_offsets?: BreakOffset[];

    @IsBoolean()
    @IsOptional()
    public clear_dash?: boolean;

    @IsBoolean()
    @IsOptional()
    public cloudsliced?: boolean;

    @IsString()
    public created: string;

    @IsBoolean()
    @IsOptional()
    public dash_enabled?: boolean;

    @IsOptional()
    public dash_url?: string;

    @IsUrl()
    @IsOptional()
    public default_poster_url?: string;

    @IsString()
    @IsOptional()
    public desc?: string;

    @IsNumber()
    @IsOptional()
    public duration?: number;

    @IsString()
    @IsOptional()
    public embed_domains?: string;

    @IsUrl()
    @IsOptional()
    public embed_html5_player_url?: string;

    @IsString()
    @IsOptional()
    public external_id?: string;

    @IsBoolean()
    @IsOptional()
    public export_mp4_error?: boolean = false;

    @IsString()
    @IsOptional()
    public export_mp4_job?: string;

    @IsString()
    @IsOptional()
    public event_id?: string;

    @IsNumber()
    @IsOptional()
    public event_ver?: number;

    @IsBoolean()
    @IsOptional()
    public event_vod?: boolean;

    @IsBoolean()
    @IsOptional()
    public fairplay_enabled?: boolean;

    @IsUrl()
    @IsOptional()
    public hd_exported_url?: string;

    @IsOptional()
    public hd_exported_urls?: any;

    @IsUrl()
    @IsOptional()
    public hls_url?: string;

    @IsString()
    public id: string;

    @IsBoolean()
    @IsOptional()
    public is_hd?: boolean;

    @IsString()
    @IsOptional()
    public job_type?: string;

    @IsArray()
    @IsOptional()
    public libraries: string[];

    @ValidateNested()
    @Type(() => MetaKeyValue)
    public meta: MetaKeyValue[];

    @IsBoolean()
    @IsOptional()
    public mine?: boolean;

    @IsUrl()
    @IsOptional()
    public poster_url?: string;

    @IsBoolean()
    @IsOptional()
    public require_drm?: boolean;

    @IsOptional()
    public slicer?: string;

    @IsOptional()
    public slicer_id?: string;

    @IsString()
    @IsOptional()
    public source?: string;

    @IsString()
    @IsOptional()
    public state: string;

    @IsBoolean()
    @IsOptional()
    public generate_staticm3u8?: boolean;

    @IsUrl()
    @IsOptional()
    public staticm3u8_url?: string;

    @IsBoolean()
    @IsOptional()
    public studio_drm_required?: boolean;

    @IsString()
    @IsOptional()
    public title?: string;

    @ValidateNested()
    @IsOptional()
    @Type(() => TestPlayer)
    public test_players?: TestPlayer[];

    @IsBoolean()
    @IsOptional()
    public widevine_enabled?: boolean;

    @IsOptional()
    @Type(() => ProfileObject)
    public profile_info?: ProfileObject;
}
