import {inject} from 'aurelia-framework';

import {Notification} from './service';

@inject(Notification)
export class Notifications {
    constructor(notification) {
        this.notification = notification;
    }

    remove(notification) {
        this.notification.remove(notification);
    }

    keepAlive(id) {
        this.notification.keepAlive(id);
    }

    resetTimeout(id) {
        this.notification.setTimeout(id);
    }
}
