// import {inject, BindingEngine} from 'aurelia-framework';
import {autoinject} from 'aurelia-framework';
import {authorizationConstants} from 'services/authorization';
import {SessionService} from 'services/session';

@authorizationConstants
@autoinject()
export class Unreleased {
    constructor(public sessionService: SessionService) {}
}
