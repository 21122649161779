import {bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {PrebidConfig} from '../models/prebid-config';

export class PrebidServerOptionalParams {
    @bindable public callbacks;
    @bindable public config: PrebidConfig;
    @bindable public configPristine: PrebidConfig;
    @bindable public isNew: boolean;
    @bindable public validationErrors: ValidationError[];
}
