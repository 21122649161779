import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';

import {IsBoolean, IsNumber, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class FailoverSettings {
  /*
   * Computed Properties
   */
  private enabled = true;
  private not_since_threshold = 10;
  private processing: boolean = false;

  @computedFrom('processing', 'not_since_threshold')
  get saveBtnState() {
    if (this.thresholdState === 'error') {
      return 'disabled';
    }
    return this.processing ? 'thinking' : '';
  }

  @computedFrom('not_since_threshold')
  get thresholdState() {
    return this.not_since_threshold < 5 || this.not_since_threshold > 100 ? 'error' : '';
  }

  constructor(private acceo: Acceo, private notification: CToastsService) {
    this.getFailoverSettings();
  }

  /*
   * Public Methods
   */

  public async getFailoverSettings() {
    const url = '/ops/failoversettings';
    try {
      const resp = await this.acceo.get(Response)(url);
      this.enabled = resp.enabled;
      this.not_since_threshold = resp.not_since_threshold;
    } catch (err) {
      this.notification.error(err);
    }
  }

  public async saveFailoverSettings() {
    this.processing = true;
    const url = '/ops/failoversettings/update';
    try {
      await this.acceo.post()(url, {enabled: this.enabled, not_since_threshold: this.not_since_threshold});
      this.notification.success('Successfully updated failover settings.');
    } catch (err) {
      this.notification.error(`Error updating failover settings. ${err}`);
    }
    this.processing = false;
  }
}

export class Response {
  @IsString()
  public cms_session_id_fingerprint: string;

  @IsString()
  public cms_session_fingerprint: string;

  @IsBoolean()
  public enabled: boolean;

  @IsNumber()
  public not_since_threshold: number;
}
