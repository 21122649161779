import {DialogService} from 'aurelia-dialog';
import {autoinject, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {classToPlain} from 'class-transformer';

import {CToastsService, SharedNav} from '@bindable-ui/bindable';
import {Acceo} from 'services/acceo';

import {DEFAULT_PAGE_NUM, DEFAULT_PER_PAGE_LIMIT, LIST_NAV_INDEX} from '../../models/defaults';
import {HyperionFilterParams, HyperionMeta} from '../../models/models';
import {ALT_CONTENT_TYPE, Rule, RulesListResponse, RulesMetadata} from '../models/models';

const log = LogManager.getLogger('live-channels-rules-service');
export const RULES_HREF: string = '#/live-channels/rules';
export const RULES_URL: string = '/api/v4/rules';
export const RULES_META_URL: string = '/api/v4/rule-metas';

@autoinject()
export class RuleService {
  public params: HyperionFilterParams = {};
  public selectedRules: string[] = [];
  public createRuleDialog: any;
  public createRuleForm: any;
  public ruleTypeInput: any;
  public isLoading: boolean;
  public isLoadingMore: boolean;
  public isDeleting: boolean;
  public isCreating: boolean;
  public isSaving: boolean;
  public ruleNotFound: boolean;
  public ruleStatusCodeError: number = 0;
  public rules: Rule[] = [];
  public latestBatchRules: Rule[] = [];
  public meta: HyperionMeta = {};
  public newRuleName: string = '';
  public newRuleType: string = ALT_CONTENT_TYPE.SLATE;
  public newAltContentId: string = '';
  public createErrMsg: string = null;
  public createErrFull: string = null;
  public createErrMsgContent: string = null;
  public searchQuery: string = '';
  public searchQueryApplied: string = '';
  public searchLoading: boolean = false;
  public currentMetadata: RulesMetadata = null;
  public metadataNotFound: boolean = false;

  private currentIndex: string = null;

  private navPage: any = {
    loadMore: () => this.getMoreRules(),
    navs: [],
    prevText: 'Live Channels Menu',
    searchFn: query => this.search(query),
    searchPlaceholder: 'Search Rules',
    searchQuery: '',
    title: 'Rules',
  };

  constructor(
    private acceo: Acceo,
    public sharedNav: SharedNav,
    public notification: CToastsService,
    private dialogService: DialogService,
    public router: Router,
  ) {}

  /**
   * Reset params to default
   */
  public cleanParams() {
    // Reset the params to be default
    this.params = {};
  }

  /**
   * Delete all the selected rules
   */
  public async deleteRules(id?: string) {
    if (this.isDeleting) {
      return;
    }

    if (id) {
      this.selectedRules = [id];
    }

    this.isDeleting = true;

    const conflicts = [];
    const errors = [];

    this.selectedRules = await Promise.filter(this.selectedRules, async ruleId => {
      try {
        await this.acceo.delete()(`${RULES_URL}/${ruleId}`);

        // Remove it from the rules array
        _.remove(this.rules, {id: ruleId});

        // Remove it from the side nav
        const navItemIndex = _.findIndex(this.navPage.navs, (navItem: any) =>
          _.startsWith(navItem.href, `${RULES_HREF}/${ruleId}`),
        );
        if (navItemIndex > -1) {
          this.navPage.navs.splice(navItemIndex, 1);
        }
        this.meta.total -= 1;
        this.meta.showing = this.rules.length;

        return false; // Only keep errors
      } catch (e) {
        if (e.status_code === 409) {
          conflicts.push({id: ruleId, message: e.message, details: e.details});
        } else {
          errors.push(ruleId);
        }
        return true;
      }
    });

    if (errors.length) {
      this.notification.error(`Error removing rule${errors.length > 1 ? 's' : ''}. Please try again.`);
    }

    if (conflicts.length) {
      const messages = _.map(conflicts, conflict => {
        const rule: any = _.find(this.rules, {id: conflict.id});
        return {
          notes: [conflict.message],
          subject: rule.desc,
        };
      });

      const ruleWord = `rule${conflicts.length > 1 ? 's' : ''}`;
      const correctWording = `${conflicts.length > 1 ? 'they are' : 'it is'}`;

      this.dialogService.open({
        model: {
          bodyModel: {
            messages,
            message: `Cannot delete ${ruleWord} below because ${correctWording} in use`,
          },
          bodyViewModel: PLATFORM.moduleName('resources/dialogs/notifications/notifications-modal-body'),
          footerEnable: true,
          footerViewModel: PLATFORM.moduleName('resources/dialogs/notifications/notifications-modal-footer'),
          size: 'medium',
          title: 'Errors occurred',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      });
    }

    this.isDeleting = false;

    if (!this.selectedRules.length) {
      this.selectedRules = [];

      if (id) {
        this.router.navigate('#/live-channels/rules');
      }
    }
  }

  /**
   * Track a rule as it's toggled for deletion
   *
   * @param rule SimpleRule object
   */
  public trackSelectedRule(rule: Rule) {
    if (rule.selected) {
      this.selectedRules.push(rule.id);
    } else {
      _.remove(this.selectedRules, ruleId => ruleId === rule.id);
    }
  }

  /**
   * Toggle all visible rules as deletable
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.rules, rule => {
      if (isSelected) {
        rule.selected = true;
      } else {
        rule.selected = false;
      }
    });

    this.selectedRules = _.map(
      _.filter(this.rules, rule => rule.selected),
      rule => rule.id,
    );
  }

  /**
   * Get a list of rules based on a set of parameters
   *
   * @param params HyperionFilterParams object
   * @param isLoadMore Optional boolean for if it's a fresh load or it's loading more in the existing list
   */
  public async getRules(params: HyperionFilterParams = {}, isLoadMore: boolean = false) {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }
    if (!params.page) {
      params.page = DEFAULT_PAGE_NUM;
    }
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }
    if (this.searchQuery) {
      params.search = this.searchQuery;
    }

    this.params = _.cloneDeep(params);
    const postParams = $.param(this.params);

    if (!isLoadMore) {
      this.rules = [];
      this.isLoading = true;
      this.navPage.isLoading = true;
    } else {
      this.isLoadingMore = true;
    }

    try {
      const res = await this.acceo.get(RulesListResponse)(`${RULES_URL}?${postParams}`);
      this.latestBatchRules = res.items;
      this.rules = _.uniqBy(this.rules.concat(this.latestBatchRules), 'id');
      this.navPage.navs = _.map(this.rules, rule => ({
        active: this.currentIndex === rule.id,
        href: `${RULES_HREF}/${rule.id}`,
        title: rule.desc,
      }));

      this.meta.total = res.total_items;
      this.meta.showing = this.rules.length;
      this.meta.page = this.params.page;
      this.meta.hasMore = this.meta.total > this.meta.showing;
    } catch (err) {
      log.error(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.navPage.isLoading = false;
    }
  }

  public async getMoreRules() {
    if (this.meta.hasMore) {
      const params = _.cloneDeep(this.params);
      // updating params page count before making the call.
      // meta page count shall be updated once the call completes.
      params.page = this.meta.page + 1;
      await this.getRules(params, true);
    }
  }

  /**
   * Get a rule based on id.
   *
   * @param ruleId Rule Id
   * @returns Rule instance
   */
  public getRule(ruleId: string): Promise<Rule> {
    this.ruleNotFound = false;
    return new Promise((resolve, reject) => {
      this.acceo
        .get(Rule)(`${RULES_URL}/${ruleId}`)
        .then(resolve)
        .catch(err => {
          this.ruleNotFound = true;
          this.ruleStatusCodeError = err.status_code;
          log.error(err);
          reject(err);
        });
    });
  }

  /**
   * Create a new rule
   *
   * @param andEdit If you're going to navigate to it after creation
   */
  public async createRule(andEdit: boolean = false) {
    if (this.isCreating) {
      return;
    }

    this.createErrMsg = null;
    this.createErrMsgContent = null;

    if (!this.newRuleName.length) {
      this.createErrMsg = 'Name is required.';
    }

    if (this.newRuleType !== ALT_CONTENT_TYPE.SLATE && !this.newAltContentId) {
      this.createErrMsgContent = 'Alternate Content ID is required.';
    }

    if (this.createErrMsg || this.createErrMsgContent) {
      return;
    }

    const createParams: any = {
      alternate_content_type: this.newRuleType,
      desc: this.newRuleName,
    };

    if (this.newAltContentId && this.newRuleType !== ALT_CONTENT_TYPE.SLATE) {
      createParams.alternate_content_id = this.newAltContentId;
    }

    this.isCreating = true;

    try {
      const newRule = await this.acceo.post(Rule)(`${RULES_URL}`, createParams);
      this.notification.success('Rule created successfully.');
      this.rules.unshift(newRule);
      this.navPage.navs.unshift({
        active: false,
        href: `#/live-channels/rules/${newRule.id}`,
        title: newRule.desc,
      });

      this.meta.total += 1;
      this.meta.showing = this.rules.length;

      this.newRuleName = '';
      this.newAltContentId = '';
      this.newRuleType = ALT_CONTENT_TYPE.SLATE;

      this.createRuleDialog.hide();

      if (andEdit) {
        document.location.href = `${RULES_HREF}/${newRule.id}`;
      }
    } catch (err) {
      log.error(err);
      this.notification.error('Error creating Rule. Please try again.');
    } finally {
      this.isCreating = false;
    }
  }

  /**
   * Updates a rule
   *
   * @param rule It could be a complete or partial rule object.
   */
  public saveRule(rule: Rule): Promise<Rule> {
    if (this.isSaving) {
      return Promise.reject(new Error('Already saving.'));
    }

    this.isSaving = true;
    const data: any = classToPlain(rule);

    return this.acceo
      .patch(Rule)(`${RULES_URL}/${rule.id}`, data)
      .then(updatedRule => {
        this.notification.success('Changes saved successfully.');
        return updatedRule;
      })
      .catch(e => {
        this.notification.error(e.message || 'Error updating rule.');
        log.error(e);
        throw e;
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  /**
   * Search rules
   *
   * @param searchQuery Optional search term
   */
  public async search(searchQuery?: string) {
    this.navPage.searchQuery = searchQuery;
    this.searchQuery = searchQuery;
    this.searchQueryApplied = '';

    const params: HyperionFilterParams = {};
    if (searchQuery) {
      params.search = searchQuery;
    }

    this.searchLoading = true;

    try {
      await this.getRules(params);
      this.searchQueryApplied = searchQuery;
    } catch (err) {
      log.error(err);
    } finally {
      this.searchLoading = false;
    }
  }

  /**
   * Sets a rule active in the side nav
   *
   * @param id Rule ID
   */
  public setNavActive(id: string): void {
    this.sharedNav.replacePage(this.navPage, LIST_NAV_INDEX);
    this.currentIndex = id;

    if (id) {
      for (let index = 0; index < this.rules.length; index++) {
        const rule = this.rules[index];
        if (rule.id === id) {
          this.sharedNav.setActive(LIST_NAV_INDEX, index);
          break;
        }
      }
    }
  }

  /**
   * Changes the navigation title in the side nav
   *
   * @param id rule ID
   * @param title Title of the new navigation text
   */
  public setNavText(id: string, title: string): void {
    this.sharedNav.replacePage(this.navPage, LIST_NAV_INDEX);

    if (id) {
      for (let index = 0; index < this.rules.length; index++) {
        const rule = this.rules[index];
        if (rule.id === id) {
          this.sharedNav.nav.pages[LIST_NAV_INDEX].navs[index].title = title;
          this.sharedNav.setActive(LIST_NAV_INDEX, index);
          break;
        }
      }
    }
  }

  /**
   * Get metadata for Rule.  This is used to hide/show columns in the Rule List view
   */
  public async getMetadata() {
    try {
      const res = await this.acceo.get(RulesMetadata)(RULES_META_URL);

      this.currentMetadata = res;
    } catch (err) {
      if (err && err.status_code === 404) {
        this.metadataNotFound = true;
      }
      log.error(err);
      throw new Error(err);
    }
  }

  /**
   * Save metadata for Rule.
   */
  public async saveMetadata(metadata: any): Promise<RulesMetadata> {
    try {
      this.isSaving = true;
      const data: any = classToPlain(metadata);
      const res = await this.acceo.put(RulesMetadata)(RULES_META_URL, data);
      return res;
    } catch (err) {
      log.error(err);
      throw new Error(err.message);
    }
  }

  /**
   * Removes all selected Rules
   */
  public clearSelected() {
    this.selectedRules = [];
  }
}
