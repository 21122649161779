import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {MULTI_CDN_SETTINGS_CONFIG_EVENT} from '../index';
import {MultiCdnSettingsConfig, QosPreset} from '../models/multi-cdn-settings-config';
import {MultiCdnUtil} from '../services/cdn-util';

export const MULTI_CDN_SETTINGS_PRESET_DELETE_EVENT = {
    PresetDelete: 'MultiCdnSettingsScenarios:DeletePreset',
};

@autoinject()
export class MultiCdnSettingsQos {
    @bindable
    public multiCdnSettings: MultiCdnSettingsConfig;

    @bindable
    public validationErrors: ValidationError[];

    @bindable
    public callbacks;

    @bindable
    public initialError;

    @bindable
    public advancedStreamRoutingFeaturesEnabled;

    public currentPreset;

    public subscriptions: Subscription[] = [];
    public deleteTip;
    public hasError = false;
    public newPresetName: string = '';
    public tcNewPreset;
    public presetIsDuplicate: boolean = false;
    public presetNameRequired: boolean = false;

    constructor(public eventAggregator: EventAggregator) {}

    public detached() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    public attached() {
        this.subscriptions.push(
            this.eventAggregator.subscribe(
                MULTI_CDN_SETTINGS_CONFIG_EVENT.NewConfigSaved,
                () =>
                    new Promise(resolve => {
                        resolve();
                    }),
            ),
        );

        if (!this.currentPreset && this.multiCdnSettings && this.multiCdnSettings.qoSCdnSelectionPresets) {
            this.currentPreset = this.multiCdnSettings.qoSCdnSelectionPresets[0];
        }
    }

    public setCurrentConfig(preset) {
        // this is called by config nav item select or by new config create event.
        this.currentPreset = preset;
    }

    public deletePreset() {
        let presetToDelete;
        let deletedIndex = -1;
        let nextIndex = -1;
        let deletedPresetName = '';

        for (const preset of this.multiCdnSettings.qoSCdnSelectionPresets) {
            deletedIndex += 1;
            if (preset.name === this.currentPreset.name) {
                presetToDelete = preset;
                deletedPresetName = preset.name;
            }
        }
        const index = this.multiCdnSettings.qoSCdnSelectionPresets.indexOf(presetToDelete);
        this.multiCdnSettings.qoSCdnSelectionPresets.splice(index, 1);

        if (this.multiCdnSettings.qoSCdnSelectionPresets.length) {
            if (deletedIndex === this.multiCdnSettings.qoSCdnSelectionPresets.length) {
                nextIndex = deletedIndex - 1;
            } else {
                nextIndex = deletedIndex;
            }
            this.setCurrentConfig(this.multiCdnSettings.qoSCdnSelectionPresets[nextIndex]);
        }

        this.eventAggregator.publish(MULTI_CDN_SETTINGS_PRESET_DELETE_EVENT.PresetDelete, {
            qosPresetName: deletedPresetName,
        });
        this.deleteTip.hide();
        this.callbacks.onChange();
    }

    public createNewPreset() {
        this.presetNameRequired = false;
        this.presetIsDuplicate = false;
        if (this.newPresetName.trim() === '') {
            this.presetNameRequired = true;
            return;
        }

        for (const item of this.multiCdnSettings.qoSCdnSelectionPresets) {
            if (item.name === this.newPresetName.trim()) {
                this.presetIsDuplicate = true;
                return;
            }
        }

        this.presetIsDuplicate = false;
        const preset: QosPreset = new QosPreset();
        preset.name = this.newPresetName.trim();
        this.currentPreset = preset;
        this.multiCdnSettings.qoSCdnSelectionPresets.push(preset);

        // clear out the values & close the tip
        this.presetIsDuplicate = false;
        this.presetNameRequired = false;
        this.newPresetName = '';
        this.tcNewPreset.hide();
        this.callbacks.onChange();
    }

    get isDefaultPreset() {
        if (this.currentPreset) {
            if (this.currentPreset.name === MultiCdnUtil.defaultPresetName) {
                return true;
            }
        }
        return false;
    }
}
