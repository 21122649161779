import {autoinject, singleton} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {CONTENT_TYPE} from 'services/constants';

const baseURL = '/api/v3/drm/';

@autoinject()
@singleton()
export class FPSService {
    constructor(public acceo: Acceo) {}

    public hasCert(): Promise<any> {
        return this.acceo.get()(`${baseURL}fairplay/hascert`, {
            responseTransform: resp => resp.has_fairplay_cert,
        });
    }

    public hasKey(): Promise<any> {
        return this.acceo.get()(`${baseURL}fairplay/haskey`, {
            responseTransform: resp => resp.has_fairplay_key,
        });
    }

    public getFPSCertInfo(): Promise<any> {
        return this.acceo.get()(`${baseURL}fairplay/get-fps-cert-info`, {
            responseTransform: resp => resp,
        });
    }

    public uploadCertAndKeys(certFile, privateKey, passphrase, secretKey): Promise<any> {
        if (!certFile || !privateKey || !passphrase || !secretKey) return Promise.reject();
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('file_data', certFile);
            this.acceo
                .post()(`${baseURL}fairplay/cert`, formData, {
                    headers: {
                        'Content-Type': CONTENT_TYPE.SET_BY_BROWSER,
                    },
                })
                .then(() => {
                    formData = new FormData();
                    formData.append('overwrite', '1');
                    formData.append('app_cert', certFile);
                    formData.append('rsa_pem', privateKey);
                    formData.append('fps_ask', secretKey);
                    formData.append('pass_phrase', passphrase);
                    this.acceo
                        .post()(`${baseURL}fairplay/key`, formData, {
                            headers: {
                                'Content-Type': CONTENT_TYPE.SET_BY_BROWSER,
                            },
                        })
                        .then(resolve)
                        .catch(reject);
                })
                .catch(reject);
        });
    }
}
