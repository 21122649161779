import {PLATFORM} from 'aurelia-pal';
import {activationStrategy} from 'aurelia-router';

export class SlateRoute {
    public routes;

    constructor() {
        this.routes = [
            {
                activationStrategy: activationStrategy.replace,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'acountDefaultSlate',
                nav: true,
                route: '',
                title: 'Account Default Slate',
            },
            {
                activationStrategy: activationStrategy.replace,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'slateTypes',
                nav: true,
                route: 'slate-types',
                title: 'Slate Types',
            },
            {
                activationStrategy: activationStrategy.replace,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'liveEventSlates',
                nav: true,
                route: 'live-event-slates',
                title: 'Live Event Slates',
            },
        ];
    }

    public configureRouter(config) {
        config.map(this.routes);
    }
}
