import {
    Config as ConfigBase,
    ConfigInput as ConfigInputBase,
} from 'apps/cms/routes/slicers/encoding-profiles/list/models';
import {IEncodingProfileTableRow} from 'apps/cms/routes/slicers/encoding-profiles/list/table-models';

export class Config extends ConfigBase {
    public input = new ConfigInput();
}

class ConfigInput extends ConfigInputBase {
    public selection = {} as IEncodingProfileTableRow;
    public selectionReset: () => void;
}
