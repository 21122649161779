import {SharedNav} from '@bindable-ui/bindable';
import {autoinject, LogManager} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {IsArray} from 'class-validator';
import {PLATFORM} from 'aurelia-pal';
import {Router, activationStrategy} from 'aurelia-router';
import {SessionService} from 'services/session';
import {ANALYTICS_INTERNAL_DOMAINS} from 'apps/cms/routes/constants';

const log = LogManager.getLogger('roles-service');

@autoinject()
export class AnalyticsIndex {
  public showAdminReports = this.canViewAdminReports();
  public dashboards = [];
  public router: Router;
  public routes = [];

  constructor(public sharedNav: SharedNav, public sessionService: SessionService, private acceo: Acceo) {}

  public isInternalEmail(username) {
    if (username.includes('@')) {
      if (ANALYTICS_INTERNAL_DOMAINS.includes(username.split('@')[1])) {
        return true;
      }
    }
    return false;
  }

  public hasPerms(perms) {
    if (perms.includes('admin') || perms.includes('cms_su')) {
      return true;
    }
    return false;
  }

  public canViewAdminReports() {
    const {origUsername, ids_email: idsEmail, origPerms, perms} = this.sessionService.sessionInfo;
    if (this.hasPerms(perms) && this.hasPerms(origPerms)) {
      return true;
    }
    if (this.isInternalEmail(idsEmail) && this.isInternalEmail(origUsername)) {
      return true;
    }
    return false;
  }

  public async getDashboards(config) {
    try {
      const res = await this.acceo.get(Response)(`/api/v4/analytics/dashboards`);
      this.dashboards = res.dashboards;
      const routes = this.dashboards.map((d, i) => ({
        activationStrategy: activationStrategy.invokeLifecycle,
        moduleId: PLATFORM.moduleName('./dashboard/index'),
        name: d.route,
        nav: true,
        route:
          i === 0
            ? ['', "analytics", d.route]
            : d.route,
        title: d.title,
        settings: {style: d.view},
      }));
      config.map(routes);
    } catch (err) {
      log.error(err);
    }
  }

  public async configureRouter(config, router) {
    this.router = router;
    await this.getDashboards(config);
  }
}

export class Response {
  @IsArray()
  public dashboards: [];
}
