import {generateRandom} from '@bindable-ui/bindable';
import {Config} from './config';
import {ConfigFormat} from './models/config-format';
import {ConfigImportMode} from './import/models/config-import';
import {ConfigLineType} from './models/config-line';
import {IConfigLineTableRow} from './models/config-line-table';
import {IConfigSetting} from './models/config-setting';

export class ConfigTableData extends Config {
  public keys: string[];
  public keysDisallowed: string[];
  public keysMacro: string[];
  public keysManaged: string[];
  public keysRequired: string[];
  public model: IConfigLineTableRow[] = [];

  constructor(settings: IConfigSetting[], protected isDuplicateKeySupported: boolean) {
    super();
    this.keys = settings.map(setting => setting.key);
    this.keysDisallowed = settings.filter(setting => setting.disallowed).map(setting => setting.key);
    this.keysMacro = settings.filter(setting => setting.macro).map(setting => setting.macro);
    this.keysManaged = settings.filter(setting => setting.managed).map(setting => setting.key);
    this.keysRequired = settings.filter(setting => setting.required).map(setting => setting.key);
  }

  public editLine(requestedId: string, key?: string, value?: string, comment?: string) {
    this.sortRows();
    const matchingLine = this.model.find(line => line.id === requestedId);
    if (matchingLine) {
      matchingLine.key = key;
      matchingLine.value = value;
      matchingLine.comment = comment;
    }
    this.mapRows();
    return this.model;
  }

  public exportString(format: ConfigFormat = ConfigFormat.KVP) {
    this.sortRows();
    return super.exportString(format);
  }

  public exportFile(fileName: string, fileFormat: ConfigFormat) {
    this.sortRows();
    return super.exportFile(fileName, fileFormat);
  }

  public importLine(key?: string, value?: string, comment?: string) {
    super.importLine(key, value, comment);
    this.mapRows();
    return this.model;
  }

  public importString(value: string, mode: ConfigImportMode = ConfigImportMode.APPEND) {
    super.importString(value, mode);
    this.mapRows();
    return this.model;
  }

  protected isMacro(row: IConfigLineTableRow) {
    return !!this.keysMacro.find(macro => row.value.indexOf(macro) !== -1);
  }

  public removeLines(requestedIds: string[]) {
    this.sortRows();
    this.model = this.model
      .filter(line => requestedIds.indexOf(line.id) === -1)
      .map((line, index) => {
        line.order = index + 1;
        return line;
      });
    this.mapRows();
    return this.model;
  }

  protected mapRows() {
    this.sortRows();
    this.model = this.model.map((line, index) => {
      const result: IConfigLineTableRow = {
        _strictShow: false,
        comment: line.type !== ConfigLineType.COMMENT ? line.comment : undefined,
        id: line.id || generateRandom(),
        key:
          line.type === ConfigLineType.COMMENT || line.type === ConfigLineType.UNSUPPORTED ? line.original : line.key,
        keySpan: 0,
        order: index + 1,
        original: line.original,
        selected: false,
        type: line.type,
        value: line.value,
      };
      if (
        result.type === ConfigLineType.CUSTOM ||
        result.type === ConfigLineType.DUPLICATE ||
        result.type === ConfigLineType.KVP ||
        result.type === ConfigLineType.KVP_AND_COMMENT ||
        result.type === ConfigLineType.MACRO
      ) {
        if (this.keysDisallowed.indexOf(result.key) !== -1) {
          result.type = ConfigLineType.DISALLOWED;
        } else if (this.keysManaged.indexOf(result.key) !== -1) {
          result.type = ConfigLineType.MANAGED;
        } else if (this.keysRequired.indexOf(result.key) !== -1) {
          result.type = ConfigLineType.REQUIRED;
        } else if (this.isMacro(result)) {
          result.type = ConfigLineType.MACRO;
        } else if (this.keys.indexOf(result.key) === -1) {
          result.type = ConfigLineType.CUSTOM;
        } else if (this.keys.indexOf(result.key) !== -1) {
          result.type = result.comment ? ConfigLineType.KVP_AND_COMMENT : ConfigLineType.KVP;
        }
        if (!this.isDuplicateKeySupported) {
          const matchingKeys = this.model.filter(setting => setting.key === result.key);
          if (matchingKeys.length > 1) {
            result.type = ConfigLineType.DUPLICATE;
          }
        }
      }
      if (
        result.type === ConfigLineType.COMMENT ||
        result.type === ConfigLineType.EMPTY ||
        result.type === ConfigLineType.UNSUPPORTED
      ) {
        result.keySpan = 3;
        result._strictShow = true;
      }

      return result;
    });
  }

  protected sortRows() {
    this.model = this.model.sort((lineA, lineB) => lineA.order - lineB.order);
  }
}
