import {AureliaConfiguration} from 'aurelia-configuration';
import {inject} from 'aurelia-framework';
import {BetaMode} from 'services/beta-mode';
import {SessionService} from './session';

@inject(AureliaConfiguration, SessionService, BetaMode)
export class CheckIfLiveStep {
    constructor(config, sessionService, betaMode) {
        this.config = config;
        this.session = sessionService;
        this.betaMode = betaMode;
    }

    run(navigationInstruction, next) {
        const {oldCmsIndex, beta} = navigationInstruction.config.settings;
        let {live} = navigationInstruction.config.settings;
        const useOldCms = this.session.getQueryString('legacy');
        const useOldCmsIndex = 0;

        // Enable CMS2 for conventions_live account (IBC 2019)
        if (this.session.sessionInfo.ownerID === 'fb3a4cb9965b46678fa101477ffad8fb') {
            live = true;
        } else if (oldCmsIndex != null) {
            // Set active tab index for ye olde cms
            window.localStorage.setItem('mainTabIndex', oldCmsIndex);
        } else if (useOldCms != null) {
            window.localStorage.setItem('mainTabIndex', useOldCmsIndex);
        }

        // If the route is not live then open old CMS
        if ((!this.config.is('development') && !live && !(beta && this.betaMode.inBetaMode)) || useOldCms != null) {
            let cmsUrl = '/static/cms/index.html';
            if (useOldCms) {
                cmsUrl += '?legacy=1';
            }
            if (this.session.isIdsEnabled()) {
                window.location.href = `${cmsUrl}?idsEnabled=true`;
            } else {
                window.location.href = cmsUrl;
            }
            return next.cancel();
        }

        return next();
    }
}
