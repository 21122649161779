import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {IsArray, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class ObjectExplorer {
    /*
     * Computed Properties
     */

    @computedFrom('state')
    get searchState() {
        return this.state === 'searching' ? 'disabled' : '';
    }

    @computedFrom('stateURL')
    get searchStateURL() {
        return this.stateURL === 'searching' ? 'disabled' : '';
    }

    @computedFrom('stateProfiles')
    get searchStateProfiles() {
        return this.stateProfiles === 'searching' ? 'disabled' : '';
    }

    @computedFrom('ownerIDSState', 'idsState')
    get unlinkOwnerState() {
        if (this.idsState === 'notIDS') {
            return 'disabled';
        }
        if (this.ownerIDSState) {
            return 'thinking';
        }
    }

    @computedFrom('migOwnerState')
    get migrateOwnerState() {
        if (this.migOwnerState) {
            return 'thinking';
        }
    }

    /*
     * Private Properties
     */
    public detailCols = [
        {
            colClass: 't200',
            colHeadName: 'object',
            colHeadValue: '',
            sort: true,
        },
        {
            colHeadName: 'objValue',
            colHeadValue: '',
        },
    ];

    public detailData = [];

    public accountUpCols = [
        {
            colClass: 't200',
            colHeadName: 'object',
            colHeadValue: '',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip'),
        },
        {
            _class: 'textInput',
            colHeadName: 'objValue',
            colHeadValue: '',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-text-input/c-td-text-input.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-text-input/c-td-text-input',
            ),
        },
    ];

    public accountUpData = [];

    public migrateIDSCols = [
        {
            colClass: 't200',
            colHeadName: 'object',
            colHeadValue: '',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip'),
        },
        {
            _class: 'textInput',
            colHeadName: 'objValue',
            colHeadValue: '',
            sort: true,
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-text-input/c-td-text-input.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-text-input/c-td-text-input',
            ),
        },
    ];

    public migrateIDSData = [];

    public guidCols = [
        {
            colClass: 't190',
            colHeadName: 'object',
            colHeadValue: '',
            sort: true,
        },
        {
            colHeadName: 'objValue',
            colHeadValue: '',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
            ),
        },
    ];

    public guidColsURL = [
        {
            colClass: 't190',
            colHeadName: 'object',
            colHeadValue: '',
            sort: true,
        },
        {
            colAction: async data => this.openModal(data),
            colHeadName: 'objValue',
            colHeadValue: '',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action',
            ),
        },
    ];

    public guidColsProfiles = [
        {
            colClass: 't190',
            colHeadName: 'object',
            colHeadValue: '',
            sort: true,
        },
        {
            colHeadName: 'objValue',
            colHeadValue: '',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip'),
        },
    ];

    public guidData = [];
    public guidDataURL = [];
    public guidDataProfiles = [];

    public accountSpecialCols = [
        {
            colClass: 't200',
            colHeadName: 'object',
            colHeadValue: 'Special Options',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip'),
        },
        {
            colHeadName: 'status',
            colHeadValue: 'Status',
            colOnChange: async data => this.openModal(data),
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle',
            ),
        },
    ];

    public accountSpecialData = [];
    public selectedOwner;
    public ownerIDSState: boolean = false;
    public migOwnerState: boolean = false;
    public idsState: string = 'IDS';
    public selectedChan;
    public channelCols = [
        {
            colClass: 't150',
            colHeadName: 'object',
            colHeadValue: '',
        },
        {
            colHeadName: 'objValue',
            colHeadValue: '',
        },
    ];

    public channelData = [];
    public selectedAssetData;
    public assetCols = [
        {
            colClass: 't150',
            colHeadName: 'object',
            colHeadValue: '',
        },
        {
            colHeadName: 'objValue',
            colHeadValue: '',
        },
    ];

    public assetData = [];
    public selectedEventData;
    public eventCols = [
        {
            colClass: 't150',
            colHeadName: 'object',
            colHeadValue: '',
        },
        {
            colHeadName: 'objValue',
            colHeadValue: '',
        },
    ];

    public eventData = [];

    public new_val = [];

    public guid_resp;

    private username: string = '';
    private owners: any[] = [];
    public selectedValue: string = '';
    private origOwners: any[] = [];
    private ownerStates: string[] = [];
    private state: string = 'idle';
    private stateURL: string = 'idle';
    private stateProfiles: string = 'idle';
    private studioDRMStatus: boolean = false;
    private syndicationStatus: boolean = false;
    private secureStorageStatus: boolean = false;
    private azureCloudStatus: boolean = false;
    private azureBrokerStatus: boolean = false;

    private channelID: string = '';
    private channels: any[] = [];

    private assetID: string = '';
    private assets: any[] = [];

    private eventID: string = '';
    private events: any[] = [];

    private guidID: string = '';

    constructor(private acceo: Acceo, private notification: CToastsService, public dialogService: DialogService) {}

    /*
     * Public Methods
     */

    public async exploreUsername() {
        this.state = 'searchingAccount';
        this.detailData = [];
        this.accountUpData = [];
        this.migrateIDSData = [];
        this.accountSpecialData = [];
        this.selectedValue = '';
        this.idsState = 'IDS';
        if (!this.username) {
            this.username = '';
            this.state = 'idle';
            this.notification.error('No email/username entered.');
        } else {
            const url: string = '/ops/owners';
            const params = $.param({username: this.username});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.owners = resp.owners;
                if (this.owners.length !== 0) {
                    this.origOwners = _.cloneDeep(resp.owners);
                    for (let i = 0; i < this.owners.length; i += 1) {
                        this.ownerStates[i] = 'clean';
                    }
                    this.state = this.owners.length ? 'hasData' : 'not-found';
                    this.selectedAccount(0);
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching owner for ${this.username}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedAccount(ownerIndex) {
        this.detailData = [];
        this.accountUpData = [];
        this.migrateIDSData = [];
        this.accountSpecialData = [];
        this.selectedValue = this.owners[ownerIndex].username;
        this.selectedOwner = this.owners[ownerIndex];
        this.idsState = 'IDS';
        this.state = 'accountDataSelected';
        // this.state ? 'disabled' : '';

        let note: string;
        let sharing: string;
        let deleted: string;
        let moocher: string;
        let demo: string;
        let deadbeat: string;
        let ad_timeouts: string;

        if (this.selectedOwner.studiodrm === 1) {
            this.studioDRMStatus = true;
        } else {
            this.studioDRMStatus = false;
        }
        if (this.selectedOwner.syndicationEnabled === 1) {
            this.syndicationStatus = true;
        } else {
            this.syndicationStatus = false;
        }
        if (this.selectedOwner.secureStorageEnabled === true) {
            this.secureStorageStatus = true;
        } else {
            this.secureStorageStatus = false;
        }
        if (this.selectedOwner.cloudProvider === 'azure') {
            this.azureCloudStatus = true;
        } else {
            this.azureCloudStatus = false;
        }
        if (this.selectedOwner.brokerGroup === 'azure') {
            this.azureBrokerStatus = true;
        } else {
            this.azureBrokerStatus = false;
        }

        if (this.selectedOwner.note) {
            note = this.selectedOwner.note;
        } else {
            note = '(None/blank)';
        }

        if (this.selectedOwner.sharing_enabled === 1) {
            sharing = 'True';
        } else {
            sharing = 'False';
        }

        if (this.selectedOwner.deleted === 0) {
            deleted = 'No';
        } else {
            deleted = 'Yes';
        }

        if (this.selectedOwner.billing_moocher === 0) {
            moocher = 'False';
        } else {
            moocher = 'True';
        }

        if (this.selectedOwner.demo_expires) {
            demo = `True (expires on ${this.selectedOwner.demo_expires})`;
        } else {
            demo = 'False';
        }

        if (this.selectedOwner.deadbeat) {
            if (this.selectedOwner.deadbeat === 1 || this.selectedOwner.deadbeat === true) {
                deadbeat = 'True';
            } else {
                deadbeat = 'False';
            }
        } else {
            deadbeat = 'False';
        }

        if (this.selectedOwner.ad_timeouts.vod) {
            ad_timeouts = `VOD: ${this.selectedOwner.ad_timeouts.vod} sec | Read Override: ${this.selectedOwner.ad_timeouts.read_override} sec`;
        } else {
            ad_timeouts = '';
        }

        this.detailData.push(
            {object: 'Owner ID', objValue: this.selectedOwner.id},
            {object: 'Account State', objValue: this.selectedOwner.account_state},
            {object: 'Ad Timeouts', objValue: ad_timeouts},
            {object: 'Billing balance', objValue: this.selectedOwner.billing_balance},
            {object: 'Billing Contacts', objValue: this.selectedOwner.billing_contacts},
            {object: 'Billing Moocher', objValue: moocher},
            {object: 'Contact Emails', objValue: this.selectedOwner.contact_emails},
            {object: 'Created', objValue: this.selectedOwner.created},
            {object: 'Deadbeat', objValue: deadbeat},
            {object: 'Demo Account', objValue: demo},
            {object: 'Deleted', objValue: deleted},
            {object: 'Lastmod', objValue: this.selectedOwner.lastmod},
            {object: 'Note', objValue: note},
            {object: 'Pricing', objValue: this.selectedOwner.pricing.desc},
            {object: 'Sharing Enabled', objValue: sharing},
            {object: 'Username', objValue: this.selectedOwner.username},
            {object: 'IDS Email', objValue: this.selectedOwner.ids_email},
            {object: 'IDS Username', objValue: this.selectedOwner.ids_username},
        );
        if (this.selectedOwner.ids_email) {
            this.accountUpData.push(
                {
                    objValue: this.selectedOwner.ids_email,
                    objectTipArrowPosition: 'leftEdge',
                    objectTipSide: 'bottom',
                    objectTipSize: 'small',
                    objectTipTriggerIcon: 'question',
                    objectTipTriggerIconAlign: '-0.3em',
                    objectTipTriggerIconSize: '1.3em',
                    objectTipTriggerText: 'IDS Email',
                    objectTipViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-update-ids-email',
                    ),
                },
                {
                    objValue: this.selectedOwner.ids_username,
                    objectTipArrowPosition: 'leftEdge',
                    objectTipSide: 'bottom',
                    objectTipSize: 'small',
                    objectTipTriggerIcon: 'question',
                    objectTipTriggerIconAlign: '-0.3em',
                    objectTipTriggerIconSize: '1.3em',
                    objectTipTriggerText: 'IDS Username',
                    objectTipViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-update-ids-username',
                    ),
                },
            );
        }
        this.accountUpData.push(
            {
                objValue: this.selectedOwner.billing_contacts,
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'top',
                objectTipSize: 'small',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'Billing Contacts',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-update-billing-contacts',
                ),
            },
            {
                objValue: this.selectedOwner.contact_emails,
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'top',
                objectTipSize: 'small',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'Contact Emails',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-update-contact-emails',
                ),
            },
            {
                objValue: this.selectedOwner.note,
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'top',
                objectTipSize: 'small',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'Note',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-update-note',
                ),
            },
        );
        this.migrateIDSData.push(
            {
                objValue: this.selectedOwner.username,
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'bottom',
                objectTipSize: 'small',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'IDS Email',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-update-ids-email',
                ),
            },
            {
                objValue: this.selectedOwner.ids_username,
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'bottom',
                objectTipSize: 'small',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'IDS Username',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-update-ids-username',
                ),
            },
        );
        this.accountSpecialData.push(
            {
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'bottom',
                objectTipSize: 'xlarge',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'Studio DRM',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-enable-studio-drm',
                ),
                status: this.studioDRMStatus,
            },
            {
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'bottom',
                objectTipSize: 'big',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'Syndication',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-enable-syndication',
                ),
                status: this.syndicationStatus,
            },
            {
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'bottom',
                objectTipSize: 'big',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'Secure Storage',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-enable-secure-storage',
                ),
                status: this.secureStorageStatus,
            },
            {
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'top',
                objectTipSize: 'big',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'Enable Azure Cloud',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-enable-azure-cloud',
                ),
                status: this.azureCloudStatus,
            },
            {
                objectTipArrowPosition: 'leftEdge',
                objectTipSide: 'top',
                objectTipSize: 'big',
                objectTipTriggerIcon: 'question',
                objectTipTriggerIconAlign: '-0.3em',
                objectTipTriggerIconSize: '1.3em',
                objectTipTriggerText: 'Enable Azure Broker',
                objectTipViewModel: PLATFORM.moduleName(
                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/owner-enable-azure-broker',
                ),
                status: this.azureBrokerStatus,
            },
        );

        if (!this.selectedOwner.ids_email) {
            this.idsState = 'notIDS';
            this.ownerIDSState = false;
        }
    }

    public async updateOwnerData(ownerIndex) {
        if (this.owners[ownerIndex].ids_email || this.owners[ownerIndex].ids_username) {
            this.idsState = 'IDS';
        }

        const url: string = '/ops/owners';
        const params = $.param({username: this.username});
        try {
            const resp = await this.acceo.get(Response)([url, params].join('?'));
            this.owners = resp.owners;
            if (this.owners) {
                await this.selectedAccount(ownerIndex);
                this.state = this.owners.length ? 'accountDataSelected' : 'not-found';
            }
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }

    public async deleteOwner() {
        this.notification.info(`Attempting to delete "${this.selectedOwner.username}".`);

        const url = `/ops/owners/delete/${this.selectedOwner.id}`;
        try {
            await this.acceo.post()(url, this.selectedOwner);
            this.notification.success(`Successfully deleted ${this.selectedOwner.username}.`);
        } catch (err) {
            this.notification.error(`Error deleting owner record. ${err}`);
        }
    }

    public async unlinkOwner(activeOwner) {
        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }
        this.ownerIDSState = true;
        this.notification.info(`Attempting to unlink "${this.selectedOwner.username}" from IDS.`);

        this.selectedOwner.ids_username = '';

        const url = `/ops/owners/unlink_ids/${this.selectedOwner.id}`;
        try {
            await this.acceo.post()(url, this.selectedOwner);
            this.notification.success(`Successfully unlinked ${this.selectedOwner.username} from IDS.`);
        } catch (err) {
            this.notification.error(`Error un-linking owner record from IDS. ${err}`);
        }

        await this.updateOwnerData(index);
        this.idsState = 'notIDS';
        this.ownerIDSState = false;
    }

    public async updateOwner(activeOwner) {
        this.notification.info(`Attempting to update "${this.selectedOwner.username}".`);
        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }

        this.owners[index].ids_email = this.accountUpData[0].objValue;
        this.owners[index].ids_username = this.accountUpData[1].objValue;
        this.owners[index].billing_contacts = this.accountUpData[2].objValue;
        this.owners[index].contact_emails = this.accountUpData[3].objValue;
        this.owners[index].note = this.accountUpData[4].objValue;

        const owner: any = this.owners[index];
        const originalOwner: any = this.origOwners[index];
        if (_.isEqual(owner, originalOwner)) {
            this.ownerStates[index] = 'clean';
        } else {
            this.ownerStates[index] = 'dirty';
        }

        if (this.ownerStates[index] === 'dirty') {
            this.origOwners[index] = this.owners[index];
            const url = `/ops/owners/${this.origOwners[index].id}`;
            try {
                await this.acceo.post()(url, this.origOwners[index]);
                this.notification.success('Successfully updated owner record.');
            } catch (err) {
                this.notification.error(`Error updating owner record. ${err}`);
            }

            await this.updateOwnerData(index);
        } else {
            this.notification.info('No modified data for owner record. Not Updating.');
        }
    }

    public async migrateOwner(activeOwner) {
        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }
        this.migOwnerState = true;
        this.notification.info(`Attempting to migrate "${this.selectedOwner.username}" to IDS.`);

        const url = `/ops/owners/migrate/${this.selectedOwner.id}`;
        try {
            await this.acceo.post()(url, this.selectedOwner);
            this.notification.success(`Successfully migrated ${this.selectedOwner.username} to IDS.`);
        } catch (err) {
            this.notification.error(`Error migrating owner record to IDS. ${err}`);
        }

        await this.updateOwnerData(index);
        this.idsState = 'IDS';
        this.migOwnerState = false;
    }

    public async specialStatusModal(resp, option) {
        if (option) {
            if (resp.objectTipTriggerText === 'Studio DRM') {
                if (!_.isEqual(resp.status, this.selectedOwner.studiodrm)) {
                    if (resp.status === false) {
                        this.selectedOwner.studiodrm = 0;
                    } else {
                        this.selectedOwner.studiodrm = 1;
                    }
                }
            } else if (resp.objectTipTriggerText === 'Syndication') {
                if (!_.isEqual(resp.status, this.selectedOwner.syndicationEnabled)) {
                    if (resp.status === false) {
                        this.selectedOwner.syndicationEnabled = 0;
                    } else {
                        this.selectedOwner.syndicationEnabled = 1;
                    }
                }
            } else if (resp.objectTipTriggerText === 'Secure Storage') {
                if (!_.isEqual(resp.status, this.selectedOwner.secureStorageEnabled)) {
                    if (resp.status === false) {
                        this.selectedOwner.secureStorageEnabled = false;
                    } else {
                        this.selectedOwner.secureStorageEnabled = true;
                    }
                }
            } else if (resp.objectTipTriggerText === 'Enable Azure Cloud') {
                if (!_.isEqual(resp.status, this.selectedOwner.cloudProvider)) {
                    if (resp.status === false) {
                        this.selectedOwner.cloudProvider = 'aws';
                    } else {
                        this.selectedOwner.cloudProvider = 'azure';
                    }
                }
            } else if (resp.objectTipTriggerText === 'Enable Azure Broker') {
                if (!_.isEqual(resp.status, this.selectedOwner.brokerGroup)) {
                    if (resp.status === false) {
                        this.selectedOwner.brokerGroup = 'aws';
                    } else {
                        this.selectedOwner.brokerGroup = 'azure';
                    }
                }
            }
        }
        this.ownerSpecialUpdate(this.selectedOwner.username);
    }

    public async openModal(option) {
        if (option.object !== undefined) {
            if (option.object === 'Test Player URL') {
                this.dialogService.open({
                    model: {
                        bodyModel: {
                            inputValues: option,
                        },
                        bodyViewModel: PLATFORM.moduleName(
                            'apps/acuity/templates/components/modals/modal/object-explorer/test-player-body',
                        ),
                        footerEnable: false,
                        size: 'auto',
                    },
                    viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
                });
            }
        } else if (option.status) {
            if (option.objectTipTriggerText === 'Studio DRM') {
                this.dialogService
                    .open({
                        model: {
                            bodyViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/studio-drm-body',
                            ),
                            footerEnable: true,
                            footerViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/footer',
                            ),
                            size: 'medium',
                            title: '!!WARNING!!',
                            titleHelp: 'Please carefully read over this before agreeing',
                        },
                        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
                    })
                    .whenClosed()
                    .then(response => {
                        this.specialStatusModal(option, response.output);
                    });
            } else if (option.objectTipTriggerText === 'Syndication') {
                this.dialogService
                    .open({
                        model: {
                            bodyViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/syndication-body',
                            ),
                            footerEnable: true,
                            footerViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/footer',
                            ),
                            size: 'medium',
                            title: '!!WARNING!!',
                            titleHelp: 'Please carefully read over this before agreeing',
                        },
                        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
                    })
                    .whenClosed()
                    .then(response => {
                        this.specialStatusModal(option, response.output);
                    });
            } else if (option.objectTipTriggerText === 'Secure Storage') {
                this.dialogService
                    .open({
                        model: {
                            bodyViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/secure-storage-body',
                            ),
                            footerEnable: true,
                            footerViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/footer',
                            ),
                            size: 'medium',
                            title: '!!WARNING!!',
                            titleHelp: 'Please carefully read over this before agreeing',
                        },
                        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
                    })
                    .whenClosed()
                    .then(response => {
                        this.specialStatusModal(option, response.output);
                    });
            } else if (option.objectTipTriggerText === 'Enable Azure Cloud') {
                this.dialogService
                    .open({
                        model: {
                            bodyViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/azure-cloud-body',
                            ),
                            footerEnable: true,
                            footerViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/footer',
                            ),
                            size: 'medium',
                            title: '!!WARNING!!',
                            titleHelp: 'Please carefully read over this before agreeing',
                        },
                        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
                    })
                    .whenClosed()
                    .then(response => {
                        this.specialStatusModal(option, response.output);
                    });
            } else if (option.objectTipTriggerText === 'Enable Azure Broker') {
                this.dialogService
                    .open({
                        model: {
                            bodyViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/azure-broker-body',
                            ),
                            footerEnable: true,
                            footerViewModel: PLATFORM.moduleName(
                                'apps/acuity/templates/components/modals/modal/object-explorer/footer',
                            ),
                            size: 'medium',
                            title: '!!WARNING!!',
                            titleHelp: 'Please carefully read over this before agreeing',
                        },
                        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
                    })
                    .whenClosed()
                    .then(response => {
                        this.specialStatusModal(option, response.output);
                    });
            }
        } else {
            if (option.objectTipTriggerText === 'Studio DRM') {
                this.selectedOwner.studiodrm = 0;
            }
            if (option.objectTipTriggerText === 'Syndication') {
                this.selectedOwner.syndicationEnabled = 0;
            }
            if (option.objectTipTriggerText === 'Secure Storage') {
                this.selectedOwner.secureStorageEnabled = false;
            }
            if (option.objectTipTriggerText === 'Enable Azure Cloud') {
                this.selectedOwner.cloudProvider = 'aws';
            }
            if (option.objectTipTriggerText === 'Enable Azure Broker') {
                this.selectedOwner.brokerGroup = 'aws';
            }
            this.ownerSpecialUpdate(this.selectedOwner.username);
        }
    }

    public async ownerSpecialUpdate(activeOwner) {
        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }

        const owner: any = this.owners[index];
        const originalOwner: any = this.origOwners[index];
        if (_.isEqual(owner, originalOwner)) {
            this.ownerStates[index] = 'clean';
        } else {
            this.ownerStates[index] = 'dirty';
        }

        if (this.ownerStates[index] === 'dirty') {
            this.origOwners[index] = this.owners[index];
            const url = `/ops/owners/${this.origOwners[index].id}`;
            try {
                await this.acceo.post()(url, this.origOwners[index]);
                this.notification.success('Successfully updated owner record.');
            } catch (err) {
                this.notification.error(`Error updating owner record. ${err}`);
            }

            await this.updateOwnerData(index);
        } else {
            this.notification.info('No modified data for owner record. Not Updating.');
        }
        this.updateOwnerData(index);
    }

    public async exploreChannel() {
        this.channels = [];
        this.channelData = [];
        this.state = 'searchingChannel';
        if (!this.channelID) {
            this.channelID = '';
            this.state = 'idle';
            this.notification.error('No channelID or channel name entered.');
        } else {
            this.notification.success(`Searching for ${this.channelID}.`);
            const url: string = '/ops/channels';
            const params = $.param({query: this.channelID, profile: true});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.channels = resp.channels;
                if (this.channels.length !== 0) {
                    this.state = 'hasChannelData';
                    this.state = 'channelDataSelected';
                    this.selectedChannel(0);
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching channel for ${this.username}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedChannel(channel_index) {
        this.channelData = [];
        this.selectedChan = this.channels[channel_index];
        this.selectedValue = this.channels[channel_index].id;

        let deleted: string;

        if (this.selectedChan.deleted === 0) {
            deleted = 'No';
        } else {
            deleted = 'Yes';
        }

        this.channelData.push(
            {object: 'Created', objValue: this.selectedChan.created},
            {object: 'Description', objValue: this.selectedChan.desc},
            {object: 'Deleted', objValue: deleted},
            {object: 'External ID', objValue: this.selectedChan.external_id},
            {object: 'ID', objValue: this.selectedChan.id},
            {object: 'Lastmod', objValue: this.selectedChan.lastmod},
            {object: 'Owner Username', objValue: this.selectedChan.owner_username},
            {object: 'Profile Description', objValue: this.selectedChan.profile_desc},
        );
    }

    public async exploreAsset() {
        this.assets = [];
        this.assetData = [];
        this.state = 'searchingAsset';
        if (!this.assetID) {
            this.assetID = '';
            this.state = 'idle';
            this.notification.error('No channelID or channel name entered.');
        } else {
            this.notification.success(`Searching for ${this.assetID}.`);
            const url: string = '/ops/assets';
            const params = $.param({query: this.assetID, profile: true});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.assets = resp.assets;
                if (this.assets.length !== 0) {
                    this.state = 'hasAssetData';
                    this.state = 'assetDataSelected';
                    this.selectedAsset(0);
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching asset for ${this.assetID}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedAsset(asset_index) {
        this.assetData = [];
        this.selectedAssetData = this.assets[asset_index];
        this.selectedValue = this.assets[asset_index].id;

        let deleted: string;

        if (this.selectedAssetData.deleted === 0) {
            deleted = 'No';
        } else {
            deleted = 'Yes';
        }

        this.assetData.push(
            {object: 'Created', objValue: this.selectedAssetData.created},
            {object: 'Description', objValue: this.selectedAssetData.desc},
            {object: 'Deleted', objValue: deleted},
            {object: 'ID', objValue: this.selectedAssetData.id},
            {object: 'Lastmod', objValue: this.selectedAssetData.lastmod},
            {object: 'Owner Username', objValue: this.selectedAssetData.owner_username},
            {object: 'Profile Description', objValue: this.selectedAssetData.profile_desc},
            {object: 'Slicer Version', objValue: this.selectedAssetData.slicer},
            {object: 'Approximate Slice Duration', objValue: this.selectedAssetData.slice_dur_approx},
            {object: 'Secure Storage', objValue: this.selectedAssetData.secure_storage},
        );
    }

    public async exploreEvent() {
        this.events = [];
        this.eventData = [];
        this.state = 'searchingEvent';
        if (!this.eventID) {
            this.eventID = '';
            this.state = 'idle';
            this.notification.error('No channelID or channel name entered.');
        } else {
            this.notification.success(`Searching for ${this.eventID}.`);
            const url: string = '/ops/events';
            const params = $.param({query: this.eventID, profile: true});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.events = resp.events;
                if (this.events.length !== 0) {
                    this.state = 'hasEventData';
                    this.state = 'eventDataSelected';
                    this.selectedEvent(0);
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching event for ${this.eventID}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedEvent(event_index) {
        this.eventData = [];
        this.selectedEventData = this.events[event_index];
        this.selectedValue = this.events[event_index].id;

        let deleted: string;

        if (this.selectedEventData.deleted === 0) {
            deleted = 'No';
        } else {
            deleted = 'Yes';
        }

        this.eventData.push(
            {object: 'Created', objValue: this.selectedEventData.created},
            {object: 'Description', objValue: this.selectedEventData.desc},
            {object: 'Deleted', objValue: deleted},
            {object: 'ID', objValue: this.selectedEventData.id},
            {object: 'Lastmod', objValue: this.selectedEventData.lastmod},
            {object: 'State', objValue: this.selectedEventData.state},
            {object: 'Test Player', objValue: this.selectedEventData.testPlayerUrl},
            {object: 'Owner Username', objValue: this.selectedEventData.owner_username},
            {object: 'Profile Description', objValue: this.selectedEventData.profile_desc},
        );
    }

    public async magicalSearch() {
        this.guidData = [];
        this.guidDataURL = [];
        this.guidDataProfiles = [];
        this.new_val = [];
        this.stateURL = 'idle';
        this.stateProfiles = 'idle';

        this.state = 'searchingGUID';
        if (!this.guidID) {
            this.guidID = '';
            this.state = 'idle';
            this.notification.error('No GUID Entered.');
        } else if (this.guidID.length < 32) {
            this.notification.error('GUIDs need to be 32 characters in length. Please try again.');
            this.state = 'idle';
        } else {
            this.notification.success(`Running data search for ${this.guidID}...`);
            const url: string = '/ops/global-guid-search';
            const params = $.param({id: this.guidID});
            try {
                this.guid_resp = await this.acceo.get(Response)([url, params].join('?'));
                if (this.guid_resp.guid_info.length !== 0) {
                    this.notification.info(this.guid_resp.msg);
                    this.state = 'hasGUIDData';
                    for (const [key, value] of Object.entries(this.guid_resp.guid_info[0])) {
                        if (key === 'Profiles') {
                            this.stateProfiles = 'hasGUIDDataProfiles';
                            this.guidDataProfiles.push({
                                objValue: value,
                                objValueTip: true,
                                objValueTipArrowPosition: 'leftEdge',
                                objValueTipSide: 'top',
                                objValueTipSize: 'auto',
                                objValueTipTriggerText: 'Click To View',
                                objValueTipViewModel: PLATFORM.moduleName(
                                    'apps/acuity/templates/components/tables/table/views-and-view-models/tips/object-explorer/magic-search/details',
                                ),
                                object: key.replace(/_/g, ' '),
                            });
                        } else if (key === 'Test_Player_URL') {
                            this.stateURL = 'hasGUIDDataURL';
                            this.guidDataURL.push({
                                objValue: value,
                                object: key.replace(/_/g, ' '),
                            });
                        } else {
                            this.guidData.push({
                                objValue: value,
                                objValueTip: true,
                                objValueTipArrowPosition: 'leftEdge',
                                objValueTipSide: 'top',
                                objValueTipSize: 'auto',
                                objValueTipTriggerText: value,
                                object: key.replace(/_/g, ' '),
                            });
                        }
                    }
                } else {
                    this.state = 'idle';
                    this.stateURL = 'idle';
                    this.stateProfiles = 'idle';
                    this.notification.warning(`Couldn't find a matching record for ${this.guidID}`);
                }
            } catch (err) {
                this.notification.error(err.json);
                this.state = 'idle';
                this.stateURL = 'idle';
                this.stateProfiles = 'idle';
            }
        }
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsString()
    public msg: string = '';

    @IsArray()
    public owners: any[] = [];

    @IsArray()
    public channels: any[] = [];

    @IsArray()
    public assets: any[] = [];

    @IsArray()
    public events: any[] = [];

    @IsArray()
    public guid_info: any[] = [];
}
