import {CToastsService, dirtyCheckPrompt, generateRandom} from '@bindable-ui/bindable';
import {PLAYLIST_ASSETS_LIMIT} from 'apps/cms/routes/constants';
import {DialogService} from 'aurelia-dialog';
import {autoinject, BindingEngine, computedFrom} from 'aurelia-framework';
import {Field, FieldMap} from 'resources/field';
import {authorizationConstants} from 'services/authorization';
import {SessionService} from 'services/session';
import {Asset, AssetType, Playlist} from '../models';
import {PlaylistService} from '../service';

@authorizationConstants
@dirtyCheckPrompt
@autoinject()
export class BasePlaylistSingle {
  public playlist: Playlist = null;
  public model: Playlist = null;
  public playlistId: string = null;

  /** Fields for validation/dirty checking */
  public fields: FieldMap = {
    active: new Field('active'),
    ad_slate_fill: new Field('ad_slate_fill'),
    beam_break_duration: new Field('beam_break_duration'),
    desc: new Field('desc'),
    embed_domains: new Field('embed_domains'),
    embed_html5_player_url: new Field('embed_html5_player_url'),
    external_id: new Field('external_id'),
    playlist: new Field('playlist'),
    repeat: new Field('repeat'),
    skip_drm: new Field('skip_drm'),
    studio_drm_required: new Field('studio_drm_required'),
    test_players: new Field('test_players'),
  };

  /** Assigned on extended class */
  public tabFields: string[] = [];

  constructor(
    public bindingEngine: BindingEngine,
    public notification: CToastsService,
    public playlistService: PlaylistService,
    public session: SessionService,
    public dialogService: DialogService,
  ) {}

  public async activate(params) {
    const playlistId: string = params.id;

    if (playlistId) {
      this.playlist = await this.playlistService.getPlaylistFromCacheOrLoad(playlistId);
      this.model = this.playlistService.model;
    }

    this.playlistService.viewModel = this;
    this.populateTableData();
  }

  // Each tab must override this to enable dirty check.
  // eslint-disable-next-line class-methods-use-this
  get tabDirty(): boolean {
    return false;
  }

  public isDirty(): boolean {
    return this.tabDirty;
  }

  private getDirtyFields(): string[] {
    const arr: string[] = [];
    _.forEach(this.tabFields, field => {
      if (this.fields[field].isDirty) {
        arr.push(field);
      }
    });

    return arr;
  }

  /* istanbul ignore next */

  public assetTableUpdate() {
    const playlist = [];
    this.playlistService.assetTableData.forEach((item: any) => {
      if (item._type === AssetType.AD) {
        playlist.push({ad: {dur: parseInt(item.duration, 10)}});
      }
      if (item._type === AssetType.BEAM) {
        playlist.push({beam: {id: item.id}});
      }
    });
    return playlist;
  }

  public addAdBreak(duration: number, order: number) {
    const tempId = generateRandom();
    this.playlistService.assetTableData.push({
      duration,
      order,
      _type: AssetType.AD,
      id: tempId,
      uid: tempId,
      title: 'Ad Break',
    });
  }

  public addAsset(asset: Asset, order: number) {
    this.playlistService.assetTableData.push({
      order,
      _type: AssetType.BEAM,
      break_offsets: asset.break_offsets,
      duration: asset.duration,
      uid: generateRandom(),
      id: asset.id,
      title: asset.title,
    });
  }

  private populateTableData() {
    this.playlistService.assetTableData = [];
    if (!('@included' in this.playlistService.model)) {
      this.playlistService.model['@included'] = [];
    }
    let order = 1;
    this.playlistService.model.playlist.forEach(item => {
      if (item.beam) {
        const asset: Asset = this.playlistService.model['@included'].find(_asset => _asset.id === item.beam.id);
        if (asset) {
          this.addAsset(asset, order);
          order += 1;
        }
      }
      if (item.ad) {
        this.addAdBreak(item.ad.dur, order);
        order += 1;
      }
    });
  }

  public async save() {
    /* istanbul ignore if */
    if (!this.tabDirty) {
      return;
    }

    const dirtyFieldsToUpdate = this.getDirtyFields() || [];

    if (!this.validateTab(dirtyFieldsToUpdate)) {
      this.notification.error('Error updating asset. Please make sure all fields are valid.');
      throw new Error('Validation Error'); // Bubble up to dirty checking modal
    }

    const updateModel = {
      id: this.model.id,
    };

    if (dirtyFieldsToUpdate[0] === 'playlist') {
      updateModel[dirtyFieldsToUpdate[0]] = this.assetTableUpdate();
    }

    _.forEach(dirtyFieldsToUpdate, field => {
      if (field !== 'playlist') {
        updateModel[field] = this.model[field];
      }
    });

    try {
      this.playlist = await this.playlistService.savePlaylist(updateModel);
      this.playlistService.viewModel.playlist = this.playlistService.playlist;
      this.playlistService.viewModel.model = this.playlistService.model;
    } catch (err) {
      this.notification.error('Error saving playlist. Please try again.');
      throw new Error(err);
    } finally {
      this.playlistService.isSaving = false;
    }
  }

  public async duplicatePlayList(edit: boolean = false) {
    this.playlistService.duplicatePlaylist(edit);
  }

  /**
   * Validate the data on the tab doesn't have any errors
   *
   * @param dirtyFields list of dirty fields
   */
  private validateTab(dirtyFields: string[]): boolean {
    let isValid = true;

    dirtyFields.forEach(dirtyField => {
      const field = this.fields[dirtyField];

      // Reset field errors.
      field.errors = [];

      // Validate against field rules.
      field.rules.forEach(r => {
        let didPass = true;

        // Handle RegExp rules.
        if (_.isRegExp(r.rule)) {
          didPass = r.rule.test(this.model[dirtyField]);
        }

        // Handle Function rules.
        if (_.isFunction(r.rule)) {
          didPass = r.rule(this.model[dirtyField]);
        }

        if (!didPass) {
          field.errors.push(r.message);
          isValid = false;
        }
      });
    });

    return isValid;
  }

  @computedFrom('tabDirty', 'playlistService.isSaving', 'playlistService.assetTableData.length')
  get saveButtonState() {
    if (this.playlistService.isSaving) {
      return 'thinking';
    }
    if (!this.tabDirty) {
      return 'disabled';
    }
    if (this.playlistService.assetTableData.length > PLAYLIST_ASSETS_LIMIT) {
      return 'disabled';
    }

    return '';
  }
}
