import {AudiencesService} from '../../../audiences/services/audiences';

export class ChangeAudienceFooter {
    public controller: any;
    public model: any;
    public service: AudiencesService;

    public activate(model) {
        this.model = model.footer;
        this.controller = model.controller;
        this.service = this.model.audiencesService;
    }

    public selectAudience() {
        this.model.onSelect();
        this.controller.cancel();
    }
}
