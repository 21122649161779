import {Router, RouterConfiguration, RouteConfig} from 'aurelia-router';
import {PLATFORM} from 'aurelia-pal';

export class IdentityIndex {
  public routes: RouteConfig[];
  public router: Router;

  constructor() {
    this.routes = [
      {
        route: [
          '',
          'merge-users',
        ],
        name: 'mergeUsers',
        moduleId: PLATFORM.moduleName('./merge-users/index'),
        nav: true,
        title: 'Merge IDS Users',
      },
      {
        route: 'sso-integrations',
        name: 'ssoIntegrationsIndex',
        moduleId: PLATFORM.moduleName('./sso-integrations/index'),
        nav: true,
        title: 'SSO Integrations',
      },
    ];
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }
}
