import {LogManager, singleton} from 'aurelia-framework';
import {validate, ValidationError} from 'class-validator';

const log = LogManager.getLogger('util');
@singleton()
export class Util {
    public static generateGuid = () => {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
    };

    public static formatXML(xml) {
        const PADDING = '         ';
        const reg = /(>)(<)(\/*)/g;
        let pad = 0;
        const xmlFormatted = xml.replace(reg, '$1\r\n$2$3');
        return xmlFormatted
            .split('\r\n')
            .map(node => {
                let indent = 0;
                if (node.match(/.+<\/\w[^>]*>$/)) {
                    indent = 0;
                } else if (node.match(/^<\/\w/) && pad > 0) {
                    pad -= 1;
                } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
                    indent = 1;
                } else {
                    indent = 0;
                }
                pad += indent;
                return PADDING.repeat(pad - indent) + node;
            })
            .join('\r\n');
    }

    // this function removes any '', null, undefined, {}, [] values iteratively except for the object itself.
    public static compact(obj: any, path?: string) {
        let object;
        if (path) {
            object = obj[path];
        } else {
            object = obj;
        }

        if (object === '' || object === null || typeof object === 'undefined') {
            Util.deleteFromParent(obj, path);
        } else if (typeof object === 'object' && !Array.isArray(object)) {
            Object.keys(object).forEach(key => Util.compact(object, key));
            if (_.isEmpty(object)) {
                Util.deleteFromParent(obj, path);
            }
        } else if (Array.isArray(object)) {
            object = object.filter((o, index) => {
                if (typeof o === 'object') {
                    return Util.compact(object, `${index}`);
                }
                return object !== '' && object !== null && typeof object !== 'undefined';
            });
            if (_.isEmpty(object)) {
                Util.deleteFromParent(obj, path);
            }
        }
        return obj;
    }

    public static validate<T>(t: T): Promise<any> {
        return new Promise((resolve, reject) => {
            validate(t, {skipMissingProperties: true})
                .then((errs: ValidationError[]) => {
                    let errMap = {};
                    if (errs.length) {
                        errs.forEach(err => {
                            log.error(`${err}`);
                            errMap[err.property] = Object.values(err.constraints).join(' ');
                        });
                    }
                    if (_.isEmpty(errMap)) {
                        errMap = null;
                    }
                    resolve(errMap);
                })
                .catch(er => {
                    const msg = 'Validation Error';
                    log.error(`${msg}:`, er);
                    reject(new Error(msg));
                });
        });
    }

    private static deleteFromParent(obj, path) {
        if (obj && path) {
            if (typeof obj === 'object' && !Array.isArray(obj)) {
                delete obj[path];
            }
            return false;
        }
    }
}
