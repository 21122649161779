import {autoinject, computedFrom} from 'aurelia-framework';
import {ScheduleEntryService} from '../../../services';

@autoinject
export class AddOverlayFooter {
  constructor(public scheduleEntryService: ScheduleEntryService) {}
  public model;
  public controller;

  public activate(model) {
    const {controller, shared} = model;

    this.controller = controller;
    this.model = shared.entry;
  }

  public save() {
    this.scheduleEntryService.saveCallback(true);
  }

  public close() {
    this.controller.close();
  }

  @computedFrom('scheduleEntryService.saveEnabled')
  public get saveButtonState(): string {
    return this.scheduleEntryService.saveEnabled ? 'enabled' : 'disabled';
  }
}
