import {Exclude, Type} from 'class-transformer';
import {IsBoolean, IsInt, IsString, ValidateNested} from 'class-validator';
import {SyndicationPlatformType, SyndicationScheduleStatus, SyndicationTargetProtocolType} from './syndication';

export class SyndicationJob {
  @IsString()
  public content_id: string;

  @IsString()
  public content_type: string = '';

  @IsString()
  public id: string;

  @IsString()
  public status?: SyndicationScheduleStatus = SyndicationScheduleStatus.ERROR;

  @IsString()
  public start: string = '';

  @IsString()
  public stop: string = '';

  @IsString()
  public description: string;

  @IsString()
  public syndication_target_id: string = '';

  @IsString()
  public platform?: SyndicationPlatformType = SyndicationPlatformType.TWITCH;

  @IsString()
  public target_protocol: SyndicationTargetProtocolType = SyndicationTargetProtocolType.RTMP;

  @IsString()
  public content_description: string = '';

  @IsString()
  public region?: string = '';

  @IsBoolean()
  public region_auto_selected?: boolean = false;

  @Exclude()
  @IsBoolean()
  public selected: boolean = false;
}

export class SyndicationJobCollection {
  @ValidateNested()
  @Type(() => SyndicationJob)
  public items: SyndicationJob[];

  @IsInt()
  public total_items: number;
}

export interface JobsMeta {
  total?: number;
  showing?: number;
  limit?: number;
  nextToken?: number;
  hasMore?: boolean;
}
