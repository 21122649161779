import {ActionModal} from './templates/action-buttons';

export const SAFE_TO_DELETE: string[] = ['stopped', 'error', 'scheduled'];

export const EMPTY_ACTION_MODAL: ActionModal = {
    title: '',
    body: '',
    color: '',
    buttonText: '',
};
