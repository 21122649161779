import {autoinject, PLATFORM} from 'aurelia-framework';
import {RouteConfig, Router, RouterConfiguration, NavigationInstruction} from 'aurelia-router';
import {SessionService} from 'services/session';
import {BetaMode} from 'services/beta-mode';
import {EventAggregator} from 'aurelia-event-aggregator';

@autoinject()
export class AllSlicersIndex {
  public router: Router;
  public routes: RouteConfig[];
  public isCSLv2Allowed: boolean;
  public routeSubscription: any;
  public subnav: any;
  public subnavmenu: any;

  constructor(public session: SessionService, public betaMode: BetaMode, private eventAggregator: EventAggregator) {
    this.isCSLv2Allowed = this.betaMode.hasScope('csl_v2');

    this.routes = [
      {
        moduleId: PLATFORM.moduleName('./cloud-slicers/index'),
        name: 'slicersHostedSlicers2',
        nav: this.session.hasSlicersCloudAccess && this.isCSLv2Allowed,
        route: ['cloud-slicers'],
        title: 'Cloud Slicers',
        settings: {
          new: true,
        },
      },
      {
        moduleId: PLATFORM.moduleName('./hosted-slicers/index'),
        name: 'slicersHostedSlicers',
        nav: this.session.hasSlicersCloudAccess,
        route: ['hosted'],
        title: 'Hosted Slicers',
        settings: {
          legacy: true,
        },
      },
      {
        moduleId: PLATFORM.moduleName('./owned-slicers/index'),
        name: 'on-prem',
        nav: this.session.hasSlicersLiveAccess,
        route: 'on-prem',
        title: 'On-Prem Slicers',
      },
      {
        moduleId: PLATFORM.moduleName('./groups/index'),
        name: 'slicerGroups',
        nav: true,
        route: 'groups',
        title: 'Failover Groups',
      },
      {
        moduleId: PLATFORM.moduleName('./encoding-profiles/index'),
        name: 'slicerEncodingProfiles',
        nav: this.session.hasSlicersProfileTabAccess,
        route: 'encoding-profiles',
        title: 'Encoding Profiles',
      },

      {
        moduleId: PLATFORM.moduleName('./ingest-points/index'),
        name: 'slicerIngestPoints',
        nav: this.session.hasSlicersIngestPointsTabAccess,
        route: 'ingest-points',
        title: 'Ingest Points',
        settings: {
          legacy: true,
        },
      },
    ];

    let index;
    if (this.isCSLv2Allowed) {
      index = _.find(this.routes, nav => nav.name === 'slicersHostedSlicers2');
    } else {
      index = _.find(this.routes, nav => nav.name === 'slicersHostedSlicers');
    }
    if (index) {
      index.route.push('');
    }
  }

  private getCurrentViewModel() {
    const {currentInstruction} = this.router;
    if (currentInstruction && currentInstruction.viewPortInstructions) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in currentInstruction.viewPortInstructions) {
        // eslint-disable-next-line no-prototype-builtins
        if (currentInstruction.viewPortInstructions.hasOwnProperty(key)) {
          const viewPortInstruction = currentInstruction.viewPortInstructions[key];
          if (viewPortInstruction.component && viewPortInstruction.component.viewModel) {
            return viewPortInstruction.component.viewModel;
          }
        }
      }
    }

    return null;
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }

  public attached() {
    this.setNav(this.router.currentInstruction.parentInstruction);

    this.routeSubscription = this.eventAggregator.subscribe('router:navigation:success', ({instruction}) => {
      this.setNav(instruction);
    });
  }

  public async setNav(instruction: NavigationInstruction) {
    const {fragment} = instruction;
    const fragments = fragment.replace('/slicers', '').split('/');
    const correctSubnav = this.subnav;

    this.subnav = null;
    if (fragments.length >= 3) {
      let viewModel = null;
      viewModel = await this.getCurrentViewModel();
      if (viewModel.navPage) {
        this.subnav = viewModel.navPage;
      } else if (viewModel.sharedNav) {
        this.subnav = viewModel.sharedNav.nav.pages[viewModel.sharedNav.nav.activeIndex];
      }
    }

    this.subnavmenu.play = correctSubnav !== this.subnav;
  }

  public detached() {
    this.routeSubscription.dispose();
  }
}
