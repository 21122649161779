import {autoinject, computedFrom} from 'aurelia-framework';
import {GraphicsService} from 'apps/cms/routes/content/graphics/list/services/graphics-service';
import {Overlay} from 'apps/cms/routes/settings/clipping/models/clipping-config';
import {CToastsService} from '@bindable-ui/bindable';
import {ContentService} from 'apps/cms/routes/content/services';
import {IAsset} from 'apps/cms/routes/content/playlists/models';
import * as moment from 'moment';
import {IScheduledEntryParams, ScheduleEntryService} from '../../../services/scheduled-entry';
import {ITrackedScheduledEntry, ScheduleEntry, ScheduledOverlaySourceType} from '../../models/event-model';

@autoinject
export class AddOverlay {
  private modalController;
  private channelId: string;
  private canError = false;
  private removeEntry: () => boolean;
  private start: string = '';

  public currentAsset: IAsset;
  public model: ITrackedScheduledEntry;
  public isNew = true;
  public durationDefault: string = '';
  public duration: number = 0;

  public overlayList: Overlay[];
  public selectedOverlay: Overlay;
  public timeFormat: string = 'MM/DD/YYYY HH:mm:ss';
  public searchOverlayLibraryText: string = '';

  constructor(
    private graphicService: GraphicsService,
    private scheduleEntryService: ScheduleEntryService,
    private notification: CToastsService,
    private contentService: ContentService,
  ) {}

  @computedFrom('model.start')
  public get startTime(): string {
    return this.model.start;
  }

  public set startTime(time: string) {
    this.model.start = time;
    this.canSubmit();
  }

  public handleDurationChange(duration: number) {
    this.duration = duration;
    this.canSubmit();
  }

  private startTimeIsValid() {
    let minimumTime = moment().add(1, 'hours');
    if (this.model.overlay_source_type === ScheduledOverlaySourceType.LIVE) {
      minimumTime = moment();
    }
    return moment(this.model.start).isAfter(minimumTime);
  }

  @computedFrom('model.start', 'model.overlay_source_type', 'canError')
  public get startError(): string {
    let errMsg = 'Start time must be at least 1 hour in the future';
    if (this.model.overlay_source_type === ScheduledOverlaySourceType.LIVE) {
      errMsg = 'Start time must be in the future';
    }
    if (this.canError && !this.startTimeIsValid()) {
      return errMsg;
    }
    return '';
  }

  @computedFrom('duration', 'canError')
  public get durationError(): string {
    if (!this.duration && this.canError) {
      return 'Duration is required.';
    }
    return '';
  }

  public async saveOverlay(closeDialog: boolean = true) {
    try {
      if (this.isNew) {
        await this.addOverlay();
      } else {
        await this.updateOverlay();
      }
      if (closeDialog) {
        this.modalController.close();
      }
    } catch {
      this.notification.error('Overlay Error');
    }
  }

  public async activate(model) {
    const {controller, shared} = model;
    const {entry, channelId, removeEntry} = shared;
    this.removeEntry = removeEntry;

    this.modalController = controller;
    this.model = entry;
    this.start = this.model.start;
    this.channelId = channelId;
    await this.getCurrentAsset();
    await this.updateOverlayList();
    if (this.model.content_id) {
      this.durationDefault = (this.model.dur / 1000).toString();
      this.selectedOverlay = this.overlayList.find(overlay => overlay.id === this.model.content_id);
      this.isNew = false;
    }

    this.scheduleEntryService.saveCallback = async closeDialog => this.saveOverlay(closeDialog);
    this.canSubmit();
  }

  public rowClick(overlay) {
    this.canError = true;
    this.selectedOverlay = overlay;
    this.model.content_id = this.selectedOverlay.id;
    if (overlay.overlay_type === 'html5') {
      this.model.overlay_source_type = ScheduledOverlaySourceType.LIVE;
    }
    this.canSubmit();
  }

  // private async updateOverlayList() {
  //   this.overlayList = await this.graphicService.getOverlayList();
  // }

  public async updateOverlayList() {
    this.overlayList = await this.graphicService.getOverlayList(this.searchOverlayLibraryText);
  }

  public clearOverlayListSearch() {
    this.searchOverlayLibraryText = '';
    this.updateOverlayList();
  }

  private async updateOverlay() {
    const entry: IScheduledEntryParams = {
      content_id: this.model.content_id,
      content_type: 'overlay',
      dur: this.duration,
      desc: this.selectedOverlay.name,
      start: this.model.start,
      overlay_source_type: this.model.overlay_source_type,
    };

    if (this.start && moment(this.start).format('YYYY-MM-DD') !== moment(this.model.start).format('YYYY-MM-DD')) {
      if (this.removeEntry) {
        this.removeEntry();
      }
    }

    try {
      await this.scheduleEntryService.updateScheduledEntry(this.model.id, entry, this.channelId);
    } catch (err) {
      const msg = err.message || 'Error updating Graphic Overlay.';
      this.notification.error(msg);
    }
  }

  private async addOverlay() {
    const entry: IScheduledEntryParams = {
      content_id: this.model.content_id,
      content_type: 'overlay',
      dur: this.duration,
      rules: [],
      ad_breaks: [],
      desc: this.selectedOverlay.name,
      start: this.model.start,
      replay_source_start: this.model.start,
      replay_source_end: this.model.start,
      overlay_source_type: this.model.overlay_source_type,
    };

    try {
      await this.scheduleEntryService.saveScheduledEntry(entry, this.channelId);
    } catch (err) {
      const msg = err.message || 'Error Adding Graphic Overlay to Schedule.';
      this.notification.error(msg);
    }
  }

  private async getCurrentAsset() {
    try {
      const scheduleEntries = await this.getSchedules();
      if (scheduleEntries.total_items === 0) return;
      const nextEntry: ScheduleEntry = scheduleEntries.items[0];
      if (!nextEntry) return;
      this.currentAsset = await this.contentService.getAsset(nextEntry.content_id);
    } catch (e) {
      this.notification.warning('Unable to get concurrent content.');
    }
  }

  private async getSchedules() {
    const start = new Date(this.model.start);
    const end = new Date(start.getTime() + 24 * 60 * 60 * 1000);
    const params = {
      start: start.toISOString(),
      end: end.toISOString(),
    };
    return this.scheduleEntryService.getScheduledEntries(params, this.channelId);
  }

  private canSubmit() {
    this.scheduleEntryService.saveEnabled = this.selectedOverlay && this.duration > 0 && this.startTimeIsValid();
  }

  // eslint-disable-next-line class-methods-use-this
  get getOverlaySourceTypes() {
    return [
      ScheduledOverlaySourceType.LIVE,
      ScheduledOverlaySourceType.VOD,
    ];
  }

  public async onChangeOverlaySourceType(event: any) {
    this.model.overlay_source_type = event.target.value;
    this.canSubmit();
  }
}
