import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import {CToastsService, generateRandom} from '@bindable-ui/bindable';
import {Acceo, AcceoError} from 'services/acceo';
import {Asset, AssetType, Playlist, PlaylistListResponse} from 'apps/cms/routes/content/playlists/models';
import {ScheduleEntryListResponse} from 'apps/cms/routes/live-channels/channels/single/models/event-model';
import {CTableRowSelectable} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {HyperionFilterParams, HyperionMeta} from '../../models/models';

const DEFAULT_PER_PAGE_LIMIT = 20;

export interface IPlaylistAssetsTableRow extends CTableRowSelectable, Asset {
  _type: AssetType;
  adBreaksTipArrowPosition?: string;
  adBreaksTipSide?: string;
  adBreaksTipSize?: string;
  adBreaksTipTriggerText?: string;
  adBreaksTipViewModel?: string;
}

const log = LogManager.getLogger('schedule-playlist-service');

@autoinject()
export class PlaylistService {
  @computedFrom('meta.hasMore', 'meta.page', 'meta.page_size')
  get hasMore() {
    return this.meta.hasMore;
  }

  public createTip: any;
  public isCreating: boolean;
  public isLoading: boolean;
  public isSaving: boolean = false;
  public searchLoading: boolean;
  public isLoadingMore: boolean;
  public playUrl: string;
  public newPlaylistName: string = '';
  public playlists: Playlist[] = [];
  public playlist: Playlist = null;
  public model: Playlist = null;
  public meta: HyperionMeta = {};
  public params: HyperionFilterParams = {};
  public viewModel: any;
  public deleteTip: any;
  public searchQuery: string = '';
  public searchQueryApplied: string = '';
  public assetTableData: IPlaylistAssetsTableRow[] = [];
  public cleanAssetTableData: object[] = [];
  private linearPlaylistApiUrl = '/api/v4/linear-playlist';
  private channelsApiUrl = '/api/v4/channels';

  constructor(private acceo: Acceo, private notification: CToastsService) {}

  /**
   * Get a list of Linear playlists
   * @param params HyperionFilterParams object
   * @param isLoadMore Optional Boolean for if it's a fresh load or it's loading more in the existing list
   * @returns Promise with resolve or reject
   */
  public async getPlaylists(params: HyperionFilterParams, isLoadMore: boolean = false) {
    if (this.isLoading || this.isLoadingMore) {
      return this.playlists;
    }
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (this.searchQuery) {
      params.search = this.searchQuery;
    }
    // if (!params.order) {
    //     params.order = 'search';
    // }
    if (!isLoadMore) {
      this.playlists = [];
      this.isLoading = true;
      params.page = 1;
    } else {
      if (!this.meta.hasMore) {
        return this.playlist;
      }

      this.isLoadingMore = true;
      params.page = (params.page || 1) + 1;
    }

    this.params = _.cloneDeep(params);

    try {
      const res = await this.acceo.get(PlaylistListResponse)(`${this.linearPlaylistApiUrl}?${$.param(this.params)}`, {
        responseTransform: (resp: PlaylistListResponse) => resp,
      });
      /* Since we don't have polling for linear playlists
            Sometimes our server cache returns the deleted items that we just remove
            */
      for (let i = 0; i < res.items.length; i++) {
        if (res.items[i].deleted) {
          res.items.splice(i, 1);
        }
      }
      this.playlists = _.uniqBy(this.playlists.concat(res.items), 'id');

      // @ts-ignore
      this.params.since = res.now;

      this.meta.total = res.total_items;
      this.meta.showing = this.playlists.length;
      this.meta.limit = params.page_size;

      // Safety check to prevent query spamming
      const totalPages = Math.ceil(res.total_items / params.page_size);

      this.meta.hasMore = params.page < totalPages;

      return this.playlists;
    } catch (err) {
      log.error(err);
      this.notification.error(`Error loading playlists: ${err}`);
      return err;
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  public async getMorePlaylists() {
    if (this.isLoading || this.isLoadingMore) {
      return this.playlists;
    }

    if (this.hasMore) {
      const params = _.cloneDeep(this.params);
      delete params.since;

      return this.getPlaylists(params, true);
    }

    return this.playlists;
  }

  /**
   * Get a playlist based on ID. If it is in the list of playlists, return it, otherwise
   * retrieve using the API.
   * @param playlistId ID of the playlist to retrieve
   */
  public async getPlaylistFromCacheOrLoad(playlistId) {
    let playlist: Playlist = this.playlists.find(pl => pl.id === playlistId);
    if (!playlist) {
      playlist = await this.getPlaylist(playlistId);
      this.playlists.push(playlist);
    } else if (this.playlistHasAssets(playlist) && !('@included' in playlist)) {
      playlist = await this.getPlaylist(playlistId);
      // replace updated playlist in the list
      this.replacePlaylistInList(playlist);
    }

    this.playlist = playlist;
    this.model = _.cloneDeep(this.playlist);
    return playlist;
  }

  /**
   * Updates the playlist in the main list of playlists. This keeps the cache up to date and reduces API calls
   */
  public replacePlaylistInList(playlist) {
    const idx: number = this.playlists.findIndex(pl => pl.id === playlist.id);
    if (idx === -1) {
      this.playlists.push(playlist);
    } else {
      this.playlists.splice(idx, 1, playlist);
    }
  }

  /**
   * Check to see if a playlist has any assets
   * @param playlist Playlist object
   * @returns boolean
   */
  // eslint-disable-next-line class-methods-use-this
  public playlistHasAssets(playlist: Playlist) {
    return playlist.playlist.some((pl: object) => AssetType.BEAM in pl);
  }

  /**
   * Get a playlist by ID
   * @param id ID of a single playlist to display.
   * @returns Single playlist.
   */
  public async getPlaylist(id: string) {
    try {
      return await this.acceo.get(Playlist)(`${this.linearPlaylistApiUrl}/${id}`);
    } catch (err) {
      log.error(err);
      this.notification.error(`Error getting playlist by ID: ${err}`);
      return null;
    }
  }

  /**
   * Schedule a playlist by channel id, playlist id, overwrite param and start time
   * @param channel_id ID of a single channel to scheulde the playlist at.
   * @param playlist_id ID of a single playlist to scheulde.
   * @param overwrite boolean flag to ignore conflicts and overwrite them.
   * @param start the start time of the playlist scheulde.
   * @returns the last schedule in the playlist.
   */
  public async schedulePlaylist(channel_id, playlist_id, overwrite, start) {
    if (this.isSaving) {
      return null;
    }
    const payload = {
      playlist_id,
      start,
      overwrite,
    };
    try {
      this.isSaving = true;
      const url: string = `${this.channelsApiUrl}/${channel_id}/schedule-playlist`;
      const response = await this.acceo.post(ScheduleEntryListResponse)(url, payload);
      return response.items[response.total_items - 1];
    } catch (ex) {
      log.error(ex);
      throw new AcceoError(ex.message, ex.status_code, ex.details);
    } finally {
      this.isSaving = false;
    }
  }

  public async getPlayURL(id: string) {
    try {
      (this.playUrl as any) = await this.acceo.post()(
        '/playlist/playurl',
        {id},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          requestTransform: params => $.param(params),
          responseTransform: resp => resp.url,
        },
      );
    } catch (err) {
      this.playUrl = '';
      log.error(err);
      throw new Error(err.message);
    }

    return this.playUrl;
  }

  public addAdBreak(duration: number, order: number) {
    this.assetTableData.push({
      duration,
      order,
      _type: AssetType.AD,
      id: generateRandom(),
      title: 'Ad Break',
    });
  }

  public addAsset(asset: Asset, order: number) {
    this.assetTableData.push({
      order,
      _type: AssetType.BEAM,
      adBreaksTipArrowPosition: 'leftEdge',
      adBreaksTipSide: 'bottom',
      adBreaksTipSize: 'medium',
      adBreaksTipTriggerText: `${asset.break_offsets ? asset.break_offsets.length : undefined}`,
      break_offsets: asset.break_offsets,
      duration: asset.duration,
      id: asset.id,
      title: asset.title,
    });
  }
}
