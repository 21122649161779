import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {computedFrom, autoinject, BindingEngine} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {SessionService} from 'services/session';

import {Notification} from 'resources/notification/service';
import {LiveEventsService} from '../services/live-events';
import {LiveEventsListIndex} from '../list';

interface IRouter extends Router {
  eventId: string;
}

interface IWarnModal {
  header: string;
  yes: string;
  hideCancel: boolean;
  footerClass: string;
  question?: string;
  no?: string;
}

@autoinject
export class BaseEventSingle {
  public isSaving: boolean = false;
  public isDeleting: boolean = false;

  public eventPromise: any;
  public eventId: string;
  public errMsg: string;

  constructor(
    protected router: Router,
    protected notification: Notification,
    protected liveEvents: LiveEventsService,
    protected bindingEngine: BindingEngine,
    protected dialogService: DialogService,
    protected list: LiveEventsListIndex,
    protected session: SessionService,
  ) {
    this.isSaving = false;
    this.isDeleting = false;
  }

  async activate(params) {
    this.eventPromise = $.Deferred();
    this.eventId = params.id;

    if (this.eventId !== 'new') {
      await this.liveEvents.getLiveEvent(this.eventId);

      (this.router as IRouter).eventId = params.id;
      this.liveEvents.setRecordIndex(params.id);
      this.eventPromise.resolve();
    }

    this.liveEvents.startSinglePoll();

    // Lazy load data if empty
    if (this.liveEvents.data.length === 0) {
      const p = this.list == null ? {} : this.liveEvents.sanitizeParams(this.list.params);

      try {
        await this.liveEvents.getLiveEvents(p);
        this.liveEvents.setRecordIndex(this.model.id);
        if (this.liveEvents.recordIndex == null) {
          // If we can't find the record in the first 20 results, just append it
          this.liveEvents.data.push(this.model);
          this.liveEvents.setRecordIndex(this.model.id);
        }
        this.tryLazyLoad();
      } catch (err) {
        this.errMsg = err;
      }
    } else {
      this.tryLazyLoad();
    }
  }

  tryLazyLoad() {
    if (this.list) {
      if (this.liveEvents.meta.total > this.liveEvents.meta.showing) {
        if (this.liveEvents.meta.showing - this.liveEvents.recordIndex <= 2 || this.liveEvents.recordIndex == null) {
          this.list.loadMoreEvents().then(() => {
            this.liveEvents.setRecordIndex(this.model.id);
          });
        }
      }
    }
  }

  canDeactivate() {
    if (!this.liveEvents.checkDirty) {
      return true;
    }

    if (this.liveEvents.eventIsDirty && this.liveEvents.sessionWrite) {
      const dirtyModel = {
        header: 'Are you sure you want to leave?',
        question: 'There are unsaved changes. If you leave before saving, your changes will be lost.',
        yes: 'Yes, Discard My Changes',
        hideNo: true,
        cancel: 'Cancel',
        footerClass: 'btn-split',
      };
      return this.dialogService
        .open({
          viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
          model: dirtyModel,
        })
        .whenClosed(response => {
          if (!response.wasCancelled) {
            this.liveEvents.isLeavingNewRoute = true;
          }
          return !response.wasCancelled;
        });
    }

    return true;
  }

  deactivate() {
    this.liveEvents.stopSinglePoll();
    this.liveEvents.conflicts = [];

    if (this.liveEvents.isLeavingNewRoute) {
      this.liveEvents.cleanupNewEvent();
    }

    this.liveEvents.checkDirty = true;
  }

  @computedFrom('liveEvents.model')
  get model() {
    return this.liveEvents.model;
  }

  @computedFrom('isSaving')
  get saveBtnText() {
    return this.isSaving ? 'Saving...' : 'Save';
  }

  @computedFrom('isDeleting')
  get confirmDeleteBtnText() {
    return this.isDeleting ? 'Deleting...' : 'Yes, Delete';
  }

  /**
   * Resolves any items that were marked for deletion in preparation to save model.
   *
   * @param {Object} model - Model object.
   *
   * @returns {Object} resolved - Resolved model object.
   */
  resolveMarkedForDelete(model) {
    const slicersToBeDeleted = this.liveEvents.tabs.liveEventSlicers.markedForDeletion;
    const adPodsToBeDeleted = this.liveEvents.tabs.liveEventPodFormat.podsToDelete;
    const metadataToBeDeleted = this.liveEvents.tabs.liveEventMetadata.keysToDelete;

    // metadata.
    metadataToBeDeleted.forEach(key => {
      delete model.meta[key];
    });
    metadataToBeDeleted.length = 0;

    // slicers.
    slicersToBeDeleted.forEach(id => {
      const idx = model.slicers.findIndex(s => s.id === id);

      model.slicers.splice(idx, 1);
    });
    slicersToBeDeleted.length = 0;

    // ad pods.
    adPodsToBeDeleted.forEach(id => {
      const idx = model.ad_pods.findIndex(s => s.name === id);
      model.ad_pods.splice(idx, 1);
    });
    adPodsToBeDeleted.length = 0;

    return model;
  }

  public async saveEvent(event, options): Promise<void> {
    if (this.isSaving) {
      return;
    }

    let model = event;

    // Resolve marked for delete items.
    model = this.resolveMarkedForDelete(model);

    // Remove saved edits on metadata
    this.liveEvents.tabs.liveEventMetadata.edits = {};
    this.liveEvents.tabs.liveEventMetadata.schema = null;

    // Clean non-dirty fields.
    model = this.liveEvents.cleanEvent(model);

    if (!this.liveEvents.validateEvent(model)) {
      throw new Error('Invalid event.');
    }

    try {
      this.isSaving = true;
      await this.liveEvents.saveLiveEvent(model, options);
    } catch (err) {
      this.notification.error(err.message);
    } finally {
      this.isSaving = false;
    }
  }

  public async deleteEvent(id, tc = null, override = null): Promise<any> {
    if (this.liveEvents.isNew) {
      this.liveEvents.isLeavingNewRoute = true;
      this.liveEvents.checkDirty = false;
      return this.router.parent.navigateToRoute('liveEventList');
    }

    if (this.isDeleting) {
      return null;
    }

    this.isDeleting = true;
    try {
      await this.liveEvents.deleteLiveEvent(id, override);
      this.router.parent.navigateToRoute('liveEventList');
    } catch (err) {
      const warnModel: IWarnModal = {
        header: 'Warning',
        yes: "That's fine. Delete it anyway.",
        hideCancel: true,
        footerClass: 'btn-split',
      };
      let showWarning = false;

      if (err.message === 'Event is live') {
        warnModel.question = `This event is currently LIVE.
                      If you delete it now your slicer will continue to run unattached to an event.`;
        warnModel.no = "Never mind. I'll end the event first.";
        showWarning = true;
      } else if (err.message === 'VOD being created') {
        // show confirm dialog
        warnModel.question = `We are currently building the final VOD for this event.
                      If you delete this event now, the final VOD will be broken.`;
        warnModel.no = "Never mind. I'll wait.";
        showWarning = true;
      }

      if (showWarning) {
        this.dialogService
          .open({
            viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
            model: warnModel,
          })
          .whenClosed(data => {
            if (data.output === true) {
              this.deleteEvent(id, tc, true);
            }
          });
      }
    } finally {
      this.isDeleting = false;
    }

    tc.hide();
    return null;
  }
}
