import {copyToClipboard} from '@bindable-ui/bindable';
import {autoinject, bindable, customElement} from 'aurelia-framework';
import {Notification} from 'resources/notification/service';
import {Transaction} from '../models/ad-server-debug-response';
import responseCss from './response.css';

@autoinject()
@customElement('transaction-response')
export class TransactionResponseViewModel {
    @bindable
    public data: Transaction;

    public styles;

    constructor(public notification: Notification) {
        this.styles = responseCss;
    }

    public copyRawResponse() {
        return copyToClipboard(this.data.raw_response, this.notification.success('Copied to clipboard'));
    }
}
