export default function sortArrayOfObjectsByKey(field, reverse, primer) {
    const key = function getPrimer(x) {
        return primer ? primer(x[field]) : x[field];
    };
    const sortOrder = reverse ? -1 : 1;

    return function compareValues(a, b) {
        const A = key(a);
        const B = key(b);

        let rval = 0;
        if (A < B) {
            rval = -1;
        }
        if (A > B) {
            rval = 1;
        }
        return rval * sortOrder;
    };
}
