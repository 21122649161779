import {autoinject, bindable} from 'aurelia-framework';
import {CdnCapacity, MultiCdnSettingsConfig, RegionCapacity} from '../models/multi-cdn-settings-config';

const ERROR = {
    isDup: false,
};

export const ERROR_OBJ = {
    hasErr: false,
    regionName: {...ERROR},
};

interface ErrorObject {
    isDup?: boolean;
    hasErr?: boolean;
}

interface ErrorObjects {
    regionName: ErrorObject;
    hasErr: boolean;
}

interface ErrorMessages {
    [index: string]: ErrorObjects;
}

@autoinject()
export class RegionConfigCreate {
    @bindable
    public multiCdnSettings: MultiCdnSettingsConfig;

    @bindable
    public currentCdn: CdnCapacity;

    @bindable
    public callbacks;

    public newRegionError: ErrorObjects;
    public regionNameNew: string;
    public hasErr: boolean;
    public errorMessages: ErrorMessages = {};

    public addNewRegion(): void {
        const newRegion = new RegionCapacity();
        newRegion.regionname = this.regionNameNew;

        const itemError = {..._.cloneDeep(ERROR_OBJ)};
        // check add new entry
        const result = this.validateRegionName('regionname', this.regionNameNew || '', itemError);

        this.newRegionError = {
            ...{regionName: _.cloneDeep(result).regionName},
            hasErr: _.get(result, 'hasErr'),
        };

        if (_.every(Object.values(this.newRegionError.regionName), e => !e)) {
            this.currentCdn.regions.push(newRegion);
            this.hasErr = this.checkHasErr(this.errorMessages) || this.newRegionError.hasErr;
            this.regionNameNew = this.multiCdnSettings.availableregions[0];
        }

        this.callbacks.onChange();
    }

    // validate if not disable save, show error
    public checkIsValid(target: string, index: number): void {
        const value = _.get(this.currentCdn.regions[index], target);
        const itemError = {..._.cloneDeep(ERROR_OBJ)};
        this.errorMessages[index] = this.errorMessages[index] || {..._.cloneDeep(ERROR_OBJ)};
        const copy = _.cloneDeep(this.errorMessages[index]);
        this.errorMessages[index] = {
            ...copy,
            [target]: this.validateRegionName(target, value, itemError, index)[target],
            hasErr: this.validateRegionName(target, value, itemError, index).hasErr,
        };
        this.hasErr = this.checkHasErr(this.errorMessages);
        this.callbacks.onChange();
    }

    public checkHasErr(errorMessages: ErrorMessages): boolean {
        if (errorMessages) {
            const test = !_.every(Object.keys(errorMessages) || [], index => !_.get(errorMessages[index], 'hasErr'));
            return !!test;
        }
        return false;
    }

    public deleteRegion(index: number): void {
        this.currentCdn.regions.splice(index, 1);

        let cdnIndex = -1;

        this.multiCdnSettings.cdns.forEach(cdnCapacity => {
            if (cdnCapacity.name === this.currentCdn.name) {
                cdnIndex += 1;
            }
        });

        if (cdnIndex > 0) {
            this.multiCdnSettings.cdns[cdnIndex] = this.currentCdn;
        }

        this.callbacks.onChange();
    }

    public validateRegionName(target: string, value: string, itemError: ErrorObjects, index?: number): ErrorObjects {
        if (target === 'regionname') {
            const dupIndex = [];
            // when index is undefined
            this.currentCdn.regions.forEach((region, idx) => {
                if (region.regionname === value && idx !== index) {
                    dupIndex.push(idx);
                }
            });
            if (dupIndex.length) {
                dupIndex.forEach(i => {
                    // this if statement didnt initialize the errorObject
                    let ithErrorObj = this.errorMessages[i];
                    if (_.isEmpty(ithErrorObj)) {
                        ithErrorObj = {..._.cloneDeep(ERROR_OBJ)};
                    }
                    ithErrorObj.regionName.isDup = true;
                    ithErrorObj.hasErr = true;
                    this.errorMessages[i] = ithErrorObj;
                });
                itemError.regionName.isDup = true;
                itemError.hasErr = true;
            } else {
                itemError.regionName.isDup = false;
                itemError.hasErr = false;
                const indexes = Object.keys(this.errorMessages);
                indexes.forEach(idx => {
                    const ithErrorObj = this.errorMessages[idx];
                    if (_.get(ithErrorObj, 'regionName.isDup', '')) {
                        ithErrorObj.regionName.isDup = false;
                        ithErrorObj.hasErr = false;
                    }
                    this.errorMessages[idx] = ithErrorObj;
                });
            }
        }
        return itemError;
    }
}
