/* eslint-disable max-classes-per-file */
import {IsNumber, IsString, IsBoolean, IsArray, IsOptional} from 'class-validator';

export class Dashboard {
  @IsString()
  public route: string;

  @IsString()
  public id: string;

  @IsString()
  public guid: string;

  @IsString()
  public title: string;

  @IsString()
  public view: string;

  @IsString()
  public tag: string;
}

export class AnalyticsSystemGetResponse {
  @IsBoolean()
  public custom_msg_enabled: boolean;

  @IsString()
  public custom_msg_text: string;

  @IsString()
  public custom_msg_style: string;

  @IsArray()
  public dashboards: Dashboard[] = [];

  @IsNumber()
  public error: any;

  @IsString()
  @IsOptional()
  public msg: string;
}

export class AnalyticsSystemPostResponse {
  @IsNumber()
  public success: number;

  @IsNumber()
  public error: any;

  @IsString()
  @IsOptional()
  public msg: string;
}
