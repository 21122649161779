import {AdServerLabel as Label, AdServerType} from '../../ad-server/models/ad-config';
import {AdConfigListTableRow} from './table-models';

export class AdConfigListTable {
  public rows: AdConfigListTableRow[];

  constructor() {}

  public get rowsSelected() {
    // This will be used later
    return this.rows.filter(row => row.selected).map(row => row.id);
  }

  public getDeprecated(type: AdServerType) {
    // TODO enhance this to use data to drive this flag
    if (type === AdServerType.AdTech || type === AdServerType.AOLOneVideo) {
      return true;
    }
    return false;
  }

  public getRowState(row: AdConfigListTableRow) {
    if (!row.valid) return 'danger';
    // deprecated server types
    if (row.type === AdServerType.AdTech || row.type === AdServerType.AOLOneVideo) {
      return 'warning';
    }
    return '';
  }

  public getTypeListLabel(type: AdServerType) {
    if (type === AdServerType.UniversalAdConfig) {
      return Label.UniversalAdConfig;
    }
    return 'Standard';
  }
}
