import {IngestPoint} from 'apps/cms/routes/slicers/models/slicer';
import {autoinject} from 'aurelia-dependency-injection';
import {computedFrom} from 'aurelia-framework';

@autoinject()
export class SlicerIngestPointModal {
  public shared: any;
  public zones: any;
  public types: any;
  public slicerIngestPoint: IngestPoint;
  public slicerIngestPoints: IngestPoint[];

  @computedFrom('shared.creationError')
  get createErrMsgName() {
    return this.shared.creationError;
  }

  public activate(model) {
    this.shared = model.shared;
    ({zones: this.zones, types: this.types, slicerIngestPoint: this.slicerIngestPoint} = model.shared);
    ({
      slicerIngestPoint: this.slicerIngestPoint,
      slicerIngestPoints: this.slicerIngestPoints,
      types: this.types,
      zones: this.zones,
    } = model.shared);
  }
}
