import {IFieldValidations} from 'apps/cms/utils/form-validator';
import {FieldValidation} from 'apps/cms/utils/field-validation';

export function validatorHasErrors(validator): boolean {
  const keys = Object.keys(validator.errors);

  const errors = keys.reduce((total, key) => total + (validator.errors[key] || []).length, 0);

  return errors > 0;
}

export const SsoDomainValidators: IFieldValidations = {
  desc: [
    new FieldValidation({
      length: {max: 1048},
    }),
  ],
  domain: [
    new FieldValidation({
      presence: true,
      format: /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/,
      length: {max: 256},
    }),
  ],
  // ids_tenant_id: [
  //   new FieldValidation({
  //     presence: true,
  //     length: {max: 120},
  //   }),
  // ],
  title: [
    new FieldValidation({
      presence: true,
      length: {max: 120},
    }),
  ],
};

export const SsoIntegrationValidators: IFieldValidations = {
  certificate: [
    new FieldValidation({
      presence: true,
    }),
  ],
  entity: [
    new FieldValidation({
      presence: true,
      length: {max: 256},
    }),
  ],
  sign_in_uri: [
    new FieldValidation({
      presence: true,
      length: {max: 1024},
    }),
  ],
  user_email_attribute: [
    new FieldValidation({
      presence: false,
      length: {max: 256},
    }),
  ],
  user_id_attribute: [
    new FieldValidation({
      presence: true,
      length: {max: 256},
    }),
  ],
};
