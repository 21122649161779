export function groupBy(list, iteratee) {
    let fieldName;
    let _iteratee = iteratee;
    if (typeof _iteratee === 'string') {
        fieldName = iteratee;
        _iteratee = undefined;
    }

    const groups = {};

    for (let n = 0; n < list.length; n += 1) {
        const item = list[n];
        const key = _iteratee ? iteratee(item) : item[fieldName];

        const group = groups[key];

        if (group) {
            group.push(item);
        } else {
            groups[key] = [item];
        }
    }

    return groups;
}
