import {autoinject, bindable} from 'aurelia-framework';
import {LynkTextarea} from '@uplynk/lynk-design';
import {TextFile} from 'resources/file/text-file';
import {HostedSlicersSingle} from '.';
import {HyperionPolling} from '../../../../../../services/hyperion-polling';

@autoinject()
export class HostedSlicersSingleLogs {
  public isLoading: boolean = false;
  public logArea: LynkTextarea;
  public logs: string = '';

  public hyperionPolling: HyperionPolling;
  public hyperionPollingInterval = 3000;

  @bindable()
  public logPolling = false;

  @bindable()
  public lines: number = 30;

  constructor(public parentView: HostedSlicersSingle) {
    this.hyperionPolling = new HyperionPolling({
      ms: this.hyperionPollingInterval,
      promiseFn: this.loadLogs.bind(this),
    });
  }

  public logPollingChanged(value) {
    if (value) {
      this.hyperionPolling.start();
    } else {
      this.hyperionPolling.stop();
    }
  }

  public attached() {
    this.loadLogs();

    if (this.logPolling) {
      this.hyperionPolling.start();
    }
  }

  public linesChanged(value) {
    if (value < 10 || value > 1000) {
      return;
    }

    this.loadLogs();
  }

  public async loadLogs() {
    const {slicerID, slicerCopy} = this.parentView;

    if (!slicerCopy) {
      this.parentView.slicerCopy = await this.parentView.service.getSlicer(slicerID);
    }

    if (!(this.parentView.slicerCopy.isRunning || this.parentView.slicerCopy.isInitializing)) {
      this.logs = 'No logs, slicer not running';
      return;
    }

    try {
      this.isLoading = true;
      this.logs = await this.parentView.service.getLogs(slicerID, this.lines, 100000000);
      setTimeout(() => {
        this.logArea.scrollPosition({top: 100000});
      }, 10);
    } catch (error) {
      // this.parentView.notification.error(`Failed to load logs: ${error}`);
      this.logs = `Failed to load logs: ${error}`;
    } finally {
      this.isLoading = false;
    }
  }

  public async actionExport() {
    const fileData = encodeURIComponent(this.logs);
    const file = new File([fileData], 'logs.txt', {type: 'text/plain'});
    await TextFile.save(file);
  }

  public detached() {
    this.hyperionPolling.stop();
  }
}
