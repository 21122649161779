export enum ConfigFormat {
  JSON = 'JSON',
  KVP = 'KVP',
}

export class ConfigFormatExt {
  public static [ConfigFormat.JSON] = 'json';
  public static [ConfigFormat.KVP] = 'txt';
}

export class ConfigFormatMimeType {
  public static [ConfigFormat.JSON] = 'application/json';
  public static [ConfigFormat.KVP] = 'text/plain';
}
