import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, containerless, LogManager} from 'aurelia-framework';
import {validate, ValidationError} from 'class-validator';
import {NewKeyOption, SchemaProperty} from '../models/metadata';
import {MetadataSchemaEvent} from '.';

const log = LogManager.getLogger('live-events-settings-metadata-schema-add-key-option');

@autoinject()
@containerless()
export class KeyOptionAdd {
    @bindable
    public key: SchemaProperty;

    public newKeyOption: NewKeyOption;
    public newKeyOptionErrors: ValidationError[];
    public newKeyOptionInput: HTMLInputElement;

    constructor(public eventAggregator: EventAggregator) {}

    public init() {
        this.newKeyOption = new NewKeyOption();
        this.newKeyOption.existingKeyOptionValues = this.key.option_values;
        this.newKeyOptionErrors = null;
    }

    public onShow() {
        this.newKeyOptionInput.focus();
    }

    public async addKeyOption(key: SchemaProperty, event: MouseEvent) {
        event.preventDefault();
        return validate(this.newKeyOption, {skipMissingProperties: true}).then((errs: ValidationError[]) => {
            if (errs.length) {
                errs.forEach(err => log.error(String(err)));
                this.newKeyOptionErrors = errs;
            } else {
                key.option_values.push(this.newKeyOption.name);
                this.newKeyOptionInput.focus();
                this.init();
                this.eventAggregator.publish(MetadataSchemaEvent.CHANGED);
            }
        });
    }
}
