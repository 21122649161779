import {bindable} from 'aurelia-framework';
import {customElement} from 'aurelia-templating';
import {LynkPopover} from '@uplynk/lynk-design';
import {ConfirmationBase} from './base';

@customElement('confirmation-popover')
export class ConfirmationPopover extends ConfirmationBase {
    public popover: LynkPopover;

    /**
     * Hide the popover by clicking outside the panel.
     */
    @bindable public popoverClickToHide: boolean = false;

    /**
     * The preferred placement of the popover panel. Note that the actual placement may vary as needed to keep the panel
     * inside of the viewport.
     */
    // eslint-disable-next-line max-len
    @bindable public popoverPlacement:
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'right'
        | 'right-start'
        | 'right-end'
        | 'left'
        | 'left-start'
        | 'left-end' = 'top-end';

    public handleCancel() {
        this.popover.hide();
        super.handleCancel();
    }

    public handleSubmit(event: Event) {
        this.popover.hide();
        super.handleSubmit(event);
    }
}
