import {IVNavSliderNavList, IVNavSliderPageList, SharedNav} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';
import {RouteConfig, Router, RouterConfiguration, RouterEvent} from 'aurelia-router';
import {PLATFORM} from 'aurelia-pal';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {Toast} from 'resources/toast/toast';
import {HyperionPolling} from 'services/hyperion-polling';
import {DialogService} from 'aurelia-dialog';
import {LynkTabGroup} from '@uplynk/lynk-design';
import {SlicerService} from '../../services/slicer-service';
import {Slicer} from '../../models/slicer';

@autoinject()
export class SlicerViewModel {
  public slicer: Slicer;
  public slicerCopy: Slicer;
  public slicers: Slicer[];

  public isLoading: boolean = true;
  public isRestarting: boolean = false;

  public slicerId: string;
  public isSaving: boolean = false;
  public routes = [
    {
      moduleId: PLATFORM.moduleName('./general'),
      name: 'general',
      nav: true,
      route: '',
      title: 'General',
    },
    {
      moduleId: PLATFORM.moduleName('./advanced'),
      name: 'advanced',
      nav: true,
      route: '/advanced',
      title: 'Advanced Config',
    },
  ];

  public routerEventComplete: Subscription;
  public navPage: IVNavSliderPageList = {
    navs: [],
    prevText: 'Slicers',
    searchFn: this.searchSlicers.bind(this),
    searchPlaceholder: 'Search On-Prem Slicers',
    searchQuery: '',
    title: 'On-Prem Slicers',
  };

  public subtabs: LynkTabGroup;

  @computedFrom('slicer.profile.id', 'slicerCopy.profile.id')
  get isDirty() {
    if (this.slicer && this.slicer.profile) {
      return this.slicer.profile.id !== this.slicerCopy.profile.id;
    }
    return false;
  }

  @computedFrom('isDirty')
  get isDisabled() {
    return !this.isDirty;
  }

  protected hyperionPollingInterval = 5000;

  constructor(
    public slicerService: SlicerService,
    public sharedNav: SharedNav,
    public router: Router,
    public hyperionPolling: HyperionPolling,
    public eventAggregator: EventAggregator,
    public dialogService: DialogService,
  ) {
    this.hyperionPolling = new HyperionPolling({
      callbackFn: slicer => {
        if (!this.isDirty) {
          this.slicer = _.cloneDeep(slicer);
        }
        this.slicerCopy = _.cloneDeep(slicer);
        this.slicer.status = slicer.status;
        this.slicerCopy.status = slicer.status;
      },
      ms: this.hyperionPollingInterval,
      promiseFn: this.requestReadInterval.bind(this),
      useAfter: true,
    });
  }

  public requestReadInterval() {
    return Promise.resolve(this.slicerService.getSlicer(this.slicerId));
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }

  public async activate(params: any, routeConfig: RouteConfig) {
    this.slicerId = params.id;

    // Set slicers and slicer from previous page data
    if ('data' in routeConfig.settings && 'slicers' in routeConfig.settings.data) {
      this.slicers = routeConfig.settings.data.slicers;
    }
    if (this.slicers && this.slicers.length) {
      this.slicer = this.slicers.find(s => s.id === this.slicerId);
      this.slicerCopy = _.cloneDeep(this.slicer);
      this.hyperionPolling.start();
    }
  }

  public async attached() {
    this.routerEventComplete = this.eventAggregator.subscribe(
      RouterEvent.Complete,
      this.onRouterEventComplete.bind(this),
    );
    await this.init();
    this.hyperionPolling.start();
  }

  public searchSlicers(query: string) {
    this.setNavItems(query);
  }

  public setNavItems(filter?: string) {
    this.sharedNav.replacePage(this.navPage, 1);
    this.sharedNav.setNextText('On-Prem Slicers');

    const activeNavRoute = this.router.navigation.find(nav => nav.isActive).relativeHref || '';

    if (this.slicers) {
      this.navPage.navs = this.slicers
        .map(
          (slicer: Slicer): IVNavSliderNavList => ({
            active: false,
            href: `#/slicers/on-prem/${slicer.id}/${activeNavRoute}`,
            title: slicer.id,
          }),
        )
        .filter((item: IVNavSliderNavList) => {
          if (filter) {
            return item.title.includes(filter);
          }
          return item;
        });

      this.setActiveNavItem();
    }
    // @ts-ignore
    this.sharedNav.clearPageActive(0);
  }

  public async tabClick(row, event) {
    const route = row.config.name;

    // Prevent the tab-group from activating the new tab immediately
    event.stopPropagation();

    // Wait until the router actually navigates
    await this.router.navigateToRoute(route);

    // Use the lynk-tab-group show() method to manually activate the tabs
    if (this.router.currentInstruction.config.name === route) {
      this.subtabs.show(route);
    }
  }

  public detached() {
    this.routerEventComplete.dispose();
  }

  private async init() {
    try {
      this.isLoading = true;
      if (!this.slicers) {
        this.isLoading = true;
        this.slicers = _.orderBy(await this.slicerService.getSlicers(), 'id', 'asc');
        this.slicer = this.slicers.find(s => s.id === this.slicerId);
        this.slicerCopy = _.cloneDeep(this.slicer);
      }
    } catch (err) {
      Toast.danger('Error loading On-Prem Slicer');
    } finally {
      this.setNavItems();
      this.isLoading = false;
    }
  }

  private setActiveNavItem() {
    const index = _.findIndex(this.slicers, {id: this.slicerId});
    this.sharedNav.setActive(1, index);
  }

  private onRouterEventComplete() {
    this.setNavItems(this.navPage.searchQuery);
  }

  public async onSave(_event?, action = null) {
    try {
      await this.slicerService.updateSlicer(this.slicer);
      this.slicerCopy = _.cloneDeep(this.slicer);
      if (action === 'restart') {
        await this.restartSlicer();
      } else {
        Toast.success(`Slicer updated successfully`);
      }
    } catch (error) {
      Toast.danger(`Failed to update slicer: ${error}`);
    }
  }

  public async restartSlicer() {
    try {
      this.isRestarting = true;
      await this.slicerService.restartSlicer(this.slicer.id);
      Toast.success(`Restart of On-Prem Slicer (${this.slicer.id}) initiated`);
    } catch (error) {
      Toast.danger(`Failed to restart slicer: ${error}`);
    } finally {
      this.isRestarting = false;
    }
  }

  public navigateToGroup(failoverGroupId: string) {
    this.router.navigate(`/slicers/groups/${failoverGroupId}`);
  }

  public deactivate() {
    this.hyperionPolling.stop();
  }
}
