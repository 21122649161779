import {Expose, Type} from 'class-transformer';
import {IsInt, IsString, ValidateNested} from 'class-validator';

export class SyndicationFaceBookPage {
    @Expose({name: 'id'})
    @IsString()
    public id: string;

    @Expose({name: 'name'})
    @IsString()
    public name: string;
}

export class SyndicationFaceBookPageCollection {
    @ValidateNested()
    @Type(() => SyndicationFaceBookPage)
    public items: SyndicationFaceBookPage[];

    @IsInt()
    public total_items: number;
}

export class SyndicationClippingProfile {
    @Expose({name: 'id'})
    @IsString()
    public id: string;

    @Expose({name: 'name'})
    @IsString()
    public name: string;
}

export class SyndicationClippingProfileCollection {
    @ValidateNested()
    @Type(() => SyndicationClippingProfile)
    public items: SyndicationClippingProfile[];

    @IsInt()
    public total_items: number;
}
