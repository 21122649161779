import {bindable, inject} from 'aurelia-framework';

export interface ILazyLoaderActions {
  getMore: () => any;
}

@inject(Element)
export class LazyLoader {
  @bindable()
  public actions: ILazyLoaderActions;

  @bindable()
  public isLoading: boolean;

  public async getMore() {
    return this.actions.getMore();
  }

  public observer: IntersectionObserver;

  constructor(private element: Element) {
    const options = {
      root: null,
      threshold: 0.5,
    };

    const callback = entries => {
      entries.forEach(entry => this.isInViewport(entry));
    };

    this.observer = new IntersectionObserver(callback, options);
  }

  /*
    Aurelia Hooks
   */

  protected attached() {
    this.observer.observe(this.element);
  }

  private isInViewport(entry) {
    if (entry.isIntersecting && !this.isLoading) {
      this.actions.getMore();
    }
  }
}
