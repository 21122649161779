import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {ProviderConfigResponseCollection} from '../models/providerconfig-response-collection';

@autoinject
export class ProviderConfigService {
  private url: string = '/api/v4/providerconfig';

  constructor(public acceo: Acceo) {}

  public get() {
    return this.acceo.get(ProviderConfigResponseCollection)(this.url);
  }
}
