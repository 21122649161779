import {bindable} from 'aurelia-framework';

import * as Draggabilly from 'draggabilly';

import * as styles from './styles.css';

export interface IAsset {
  duration: number;
  title: string;
}

export interface ITimelineStep {
  timestamp: string;
  thumbnail: string;
}

const RESOLUTION = [
  1,
  5,
  10,
  30,
  60,
  120,
  300,
  600,
];

const DEFAULT_DURATION = 60 * 60;
const STEP_WIDTH = 80;

export class AssetTimeline {
  @bindable()
  public asset: IAsset;

  public styles = styles;

  public elTimeline;
  public elTimelineScroll;
  public elTimelineScrollable;

  public resolutionMin: number = 0;
  public resolutionMax: number = RESOLUTION.length - 1;
  public timelineResolution: number = 4;
  public timelineStep: number = 1;

  public steps: ITimelineStep[] = [];

  private scrollRatio: number;

  public attached() {
    this.updateTimeline();
    this.bindScrollable();
  }

  public incrementResolution(value = 0): void {
    let next = value + this.timelineResolution;

    if (next <= 0) {
      next = 0;
    }

    if (next > this.resolutionMax) {
      next = this.resolutionMax;
    }

    if (next === this.timelineResolution) {
      return;
    }

    this.timelineResolution = next;

    this.updateTimeline();
  }

  public onRangeChanged() {
    this.updateTimeline();
  }

  private generateSteps() {
    const steps = [];
    let duration = DEFAULT_DURATION;

    if (this.asset) {
      duration = this.asset.duration;
    }

    const resolution = RESOLUTION[this.timelineResolution];

    const stepCt = Math.ceil(duration / resolution);

    for (let i = 0; i < stepCt; i++) {
      steps.push({
        timestamp: i * resolution,
      });
    }

    if (this.elTimeline) {
      this.elTimeline.style.width = `${stepCt * STEP_WIDTH}px`;
    }

    this.steps = steps;
  }

  private updateScrollRatio() {
    const w = this.elTimelineScrollable.parentElement.clientWidth;
    const timelineWidth = this.elTimeline.clientWidth;
    this.scrollRatio = timelineWidth / w;
  }

  private updateTimeline() {
    this.generateSteps();
    this.updateScrollRatio();
    this.updateScrollable();
  }

  private updateScrollable(): void {
    if (!this.elTimelineScroll || !this.elTimelineScrollable) {
      return;
    }

    const parentWidth = this.elTimelineScrollable.parentElement.clientWidth;
    const timelineWidth = this.elTimeline.clientWidth;
    let percent = Math.ceil((parentWidth / timelineWidth) * 100);

    if (percent > 100) {
      percent = 100;
    }

    this.elTimelineScrollable.style.width = `${percent}%`;
  }

  private bindScrollable() {
    if (!this.elTimelineScrollable) {
      return;
    }

    const draggie = new Draggabilly(this.elTimelineScrollable, {
      containment: '.limited',
      axis: 'x',
    });

    draggie.on('dragMove', () => {
      const x = Math.floor(draggie.position.x);
      const left = Math.abs(x * this.scrollRatio);

      this.elTimeline.style.left = `-${left}px`;
    });
  }
}
