import {AcceoError} from 'services/acceo';

export class AcceoErrorUtil {
  public static getHTML(error: AcceoError) {
    if (error.details && error.details.length) {
      return error.details.join('<br><br>');
    }
    if (error.message) {
      return error.message;
    }
    if (error.status_code) {
      return `Server error code ${error.status_code}`;
    }
    return `${error}`;
  }
}
