import {bindable} from 'aurelia-framework';
import {FailoverGroup} from '../../../models/failover-group';

export class MultipleTdPill {
  @bindable
  public model: FailoverGroup;

  public slicers = [];

  public attached() {
    this.setGroupSlicersStatuses();
  }

  private setGroupSlicersStatuses() {
    if (this.model && this.model.slicers) {
      let hotSlicersCount = this.model.active ? 1 : 0;
      this.slicers = this.model.slicers.map(s => {
        if (
          s.enabled &&
          (!s.blacklist_until || s.blacklist_until === 0) &&
          s.id !== _.get(this.model, 'active.id', '')
        ) {
          hotSlicersCount += 1;
        }
        s.hot_warm_state = this.getSlicerFailoverState(s, hotSlicersCount);
        return s;
      });
    }
  }

  private getSlicerFailoverState(s, hotSlicersCount): string {
    if (!s.enabled) {
      return 'disabled';
    }
    if (!_.get(s, 'status.failover_healthy', false) || s.blacklist_until - new Date().getTime() > 0) {
      return 'unhealthy';
    }
    if (this.model.active && s.id === this.model.active.id) {
      return 'active';
    }
    if (this.model.hot_warm === 0 || hotSlicersCount <= this.model.hot_warm) {
      return 'hot';
    }
    return 'warm';
  }
}
