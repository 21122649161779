import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Router} from 'aurelia-router';
import * as fetchIntercept from 'fetch-intercept';
import {gtag, install} from 'ga-gtag';

const ENVS = [
  'localhost',
  'downlynk',
  'uplynk',
];
// anything containing localhost in the domain
// or if neither downlynk or uplynk is found will default to localhost ENV
const ENV: string = ENVS.find(str => window.location.hostname.indexOf(str) >= 0) || 'localhost';
const APP_NAME: string = window.location.hostname;
// STATIC_FILES_VERSION is added as a compile time global in weback.config.js
// and is based on the package.json version
declare const STATIC_FILES_VERSION: string;
const APP_VERSION = STATIC_FILES_VERSION;

export const ERROR_TYPE = {
  JS_ERROR: 'JS Error',
  SERVER_ERROR: 'Server Error',
};

@inject(EventAggregator, Router)
export class Analytics {
  private eventAggregator: EventAggregator;
  private router: Router;
  private trackerId: string;

  constructor(eventAggregator: EventAggregator, router: Router) {
    this.eventAggregator = eventAggregator;
    this.router = router;
    this.trackerId = {
      localhost: 'G-B3R5DCD33T',
      downlynk: 'G-VJHZXMK8KT',
      uplynk: 'G-20HDK8W38H',
    }[ENV];
    this.initGA();
    this.trackErrors();
    this.trackPages();
    this.trackServerErrors();
  }

  initGA() {
    install(this.trackerId);
    gtag('set', {app_name: APP_NAME, app_version: APP_VERSION});
  }

  trackErrors() {
    const existingWindowErrorCallback = window.onerror;
    window.onerror = (errorMessage, url, lineNumber, columnNumber, errorObject) => {
      let errorDescription;
      // errorObject can be string or object depending on error type and browser
      if (errorObject && typeof errorObject.message !== 'undefined') {
        errorDescription = errorObject.message;
      } else {
        errorDescription = errorMessage;
      }
      errorDescription += ` @  ${url}`;
      // include additional details if available
      if (lineNumber !== undefined && columnNumber !== undefined) {
        errorDescription += `:${lineNumber}:${columnNumber}`;
      }

      this.sendGAError(errorDescription, ERROR_TYPE.JS_ERROR);
      gtag('event', 'exception', `${errorDescription} ${ERROR_TYPE.JS_ERROR}`);

      if (typeof existingWindowErrorCallback === 'function') {
        return existingWindowErrorCallback(errorMessage, url, lineNumber, columnNumber, errorObject);
      }

      // otherwise continue with the error.
      return false;
    };
  }

  static sendGAError(description, router, type) {
    let eventLabel;
    // error could occur before router is initialized,
    // so check the router instruction exists
    if (router.currentInstruction && router.currentInstruction.config) {
      eventLabel = `${router.currentInstruction.config.title} ${router.currentInstruction.fragment}`;
    } else {
      eventLabel = window.location.href;
    }
    gtag('event', type, {
      event_category: type,
      event_label: eventLabel,
      description,
    });
  }

  sendGAError(description, type) {
    Analytics.sendGAError(description, this.router, type);
  }

  trackPages() {
    this.eventAggregator.subscribe('router:navigation:success', payload => {
      gtag('config', this.trackerId, {
        page_path: payload.instruction.fragment,
        page_title: payload.instruction.config.title,
      });
    });
  }

  interceptors = {
    request: (url, config) => [
      url,
      config,
    ],
    // this error is triggered by a runtime error caused by the fetch request handler
    requestError: error => {
      this.sendGAError(error, ERROR_TYPE.SERVER_ERROR);
      return Promise.reject(error);
    },
    response: response => {
      if (response.status >= 400) {
        const error = `[${response.status}] ${response.url}`;
        this.sendGAError(error, ERROR_TYPE.SERVER_ERROR);
      }
      return response;
    },
    // this error is triggered by a runtime error caused by the fetch response handler
    responseError: error => {
      this.sendGAError(error, ERROR_TYPE.SERVER_ERROR);
      return Promise.reject(error);
    },
  };

  trackServerErrors() {
    fetchIntercept.register(this.interceptors);
  }
}

@inject(Router)
export class AnalyticsLogAppender {
  private router: Router;

  constructor(router) {
    this.router = router;
  }

  debug() {}
  warning() {}
  info() {}
  error(logger, message) {
    try {
      Analytics.sendGAError(`[${logger.id}] ${message}`, this.router, ERROR_TYPE.JS_ERROR);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
}
