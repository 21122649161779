import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, LogManager} from 'aurelia-framework';
import {NavigationInstruction, Router} from 'aurelia-router';
import {plainToClass} from 'class-transformer';
import * as JSZip from 'jszip';
import {Notification} from 'resources/notification/service';

import {downloadFile} from 'apps/cms/utils/download-file';
import {AdServerDebugItem, AdServerDebugResponse} from './models/ad-server-debug-response';
import {DebugSearchCriteria} from './models/debug-search-criteria';
import {AdServerDebugService} from './services/ad-server-debug-service';
import {dateDelta} from './query';
import {CriteriaAndReponse} from './list';
import {CallbackDetail} from './callback-detail';
import {AdDetail} from './ad-detail';
import {TransactionDetail} from './transaction-detail';

const logger = LogManager.getLogger('AdServerDebug2');

export const AD_DEBUG_EVENT = {
  BackToResults: 'AdDebugEvent:BackToResults',
  ExportBeacons: 'AdDebugEvent:ExportBeacons',
  ExportJobs: 'AdDebugEvent:ExportJobs',
  ExportTransaction: 'AdDebugEvent:ExportTransaction',
  ExportTransactions: 'AdDebugEvent:ExportTransactions',
  ExportAds: 'AdDebugEvent:ExportAds',
  ExportAllDetails: 'AdDebugEvent:ExportAllDetails',
  Fetch: 'AdDebugEvent:Fetch',
  Reset: 'AdDebugEvent:Reset',
  SelectJob: 'AdDebugEvent:SelectJob',
  UpdateQueryparams: 'AdDebugEvent:UpdateQueryparams',
};

export enum Page {
  Query = 'query',
  List = 'list',
  SelectJob = 'selectJob',
}

@autoinject()
export class AdServerDebug {
  public subscriptions: Subscription[] = [];
  public debugError: string;
  public response: AdServerDebugResponse;
  public currentCriteria: DebugSearchCriteria;
  public pageView: Page;
  public job: AdServerDebugItem;

  public isLoading: boolean = false;

  constructor(
    public eventAggregator: EventAggregator,
    public notification: Notification,
    public adServerDebugService: AdServerDebugService,
    public router: Router,
  ) {}

  public attached() {
    this.pageView = Page.Query;
    this.subscriptions.push(
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.Fetch, ({criteria}: {criteria: DebugSearchCriteria}) => {
        let {route} = this.router.currentInstruction.config;
        route = Array.isArray(route) ? route[0] : route;
        this.router.navigate(`${route}?${criteria.toQueryString()}`);
        this.fetchAdData(criteria);
      }),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.Reset, () => {
        let {route} = this.router.currentInstruction.config;
        route = Array.isArray(route) ? route[0] : route;
        this.router.navigate(route);
      }),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.BackToResults, () => {
        this.pageView = Page.List;
        const instruction: NavigationInstruction = this.router.currentInstruction;
        delete instruction.queryParams.job_id;
        let {route} = instruction.config;
        route = Array.isArray(route) ? route[0] : route;
        this.router.navigate(`${route}?${this.currentCriteria.toQueryString()}`);
      }),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.UpdateQueryparams, this.updateQueryparamCallback.bind(this)),
      this.eventAggregator.subscribe(
        'router:navigation:success',
        ({instruction}: {instruction: NavigationInstruction}) => {
          const {fragment, queryParams} = instruction;
          if (fragment.indexOf('ad-server-debug') >= 0) {
            if (!Object.keys(queryParams).length) {
              this.pageView = Page.Query;
            }
          }
        },
      ),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.SelectJob, ({job}: {job: AdServerDebugItem}) => {
        this.job = job;
        this.pageView = Page.SelectJob;
      }),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.ExportJobs, data => {
        this.exportJobsAsCSV(data.columns, data.items);
      }),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.ExportBeacons, data => {
        this.exportBeaconsAsCSV(data.jobId, data.columns, data.items);
      }),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.ExportTransactions, data => {
        this.exportTransactionsAsZip(data.jobId, data.transactions);
      }),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.ExportTransaction, data => {
        this.exportTransactionAsJson(data.transaction);
      }),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.ExportAds, data => {
        this.exportAdsAsJson(data.jobId, data.slots);
      }),
      this.eventAggregator.subscribe(AD_DEBUG_EVENT.ExportAllDetails, data => {
        this.exportAllDetails(data.jobId, data.transactions, data.slots, data.callbacks);
      }),
    );
  }

  public activate(params: any) {
    if (Object.keys(params).length) {
      this.updateFromParams(params);
    }
  }

  public updateFromParams(params: any) {
    const criteria: DebugSearchCriteria = plainToClass(DebugSearchCriteria, params as object);
    return this.fetchAdData(criteria).then(() => {
      if (params.job_id) {
        this.job = this.response.items.find(i => i.id === params.job_id);
        this.pageView = Page.SelectJob;
      }
    });
  }

  public fetchAdData(criteria: DebugSearchCriteria): Promise<any> {
    if (!this.isLoading) {
      this.debugError = null;
      this.isLoading = true;
      // map request_url params into the config for the UI
      this.setAdDebugRequestUrlConfig(criteria);
      // default start and end dates when they are not present on the URI
      if (!criteria.start_adjob_date) {
        criteria.start_adjob_date = dateDelta(12, 1);
      }
      if (!criteria.end_adjob_date) {
        criteria.end_adjob_date = dateDelta();
      }
      this.currentCriteria = criteria;

      return this.adServerDebugService
        .getAdDebugItems(criteria)
        .then((response: AdServerDebugResponse) => {
          this.response = response;
          this.pageView = Page.List;
        })
        .catch(error => {
          this.debugError = error || 'error';
          logger.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
    return Promise.reject(new Error('Fetching ad data already in progress.'));
  }

  public exportJobsAsCSV(columns: any[], items: any[]) {
    const d = new Date();
    const filename = `ad_debug_results_${d.getFullYear()}_${
      d.getMonth() + 1
    }_${d.getDate()}_${d.getHours()}_${d.getMinutes()}_${d.getSeconds()}.csv`;
    this.downloadCSVBlob('Jobs', filename, this.createCSV(columns, items));
  }

  public exportTransactionsAsZip(jobId: string, transactions: any[]) {
    if (!transactions || !transactions.length) {
      return;
    }
    const zip = new JSZip();
    const zipname = `transactions_for_job_${jobId}.zip`;
    transactions.forEach(t => {
      const filename = `transaction_${t.id}.json`;
      zip.file(`${filename}`, JSON.stringify(t, null, 4));
    });

    try {
      zip.generateAsync({type: 'blob'}).then(content => {
        downloadFile(zipname, content);
      });
      this.notification.info(
        'Zipped transaction file download complete.  Check your downloads folder or browser to view your data.',
        'Download Complete',
      );
    } catch (err) {
      this.notification.error('Unable to zip / download transaction data at this time.', 'Transactions Download Error');
    }
  }

  public exportTransactionAsJson(transaction: any) {
    const filename = `transaction_${transaction.id}`;
    const jsonString = JSON.stringify(transaction, null, 4);
    try {
      const blob = new Blob([jsonString], {type: 'application/json;charset=utf-8;'});
      downloadFile(filename, blob);
    } catch (err) {
      this.notification.error('Unable to download transaction json file at this time.', 'Transaction Download Error');
    }
  }

  public exportAllDetails(jobId: string, transactions: any, slots: any, callbacks: any) {
    const zipExport = new JSZip();

    // file name for zip
    const zipFilename = `job_export_jobid_${jobId}`;
    // var zipFolder = zipExport.folder(zipFilename);

    if (callbacks.length > 0) {
      // do beacons
      const beaconsFilename = `beacons_for_job_${jobId}.csv`;
      const beaconFolder = zipExport.folder(`beacons_for_job_${jobId}`);
      const data = this.prepBeaconsExport(jobId, callbacks);
      beaconFolder.file(beaconsFilename, this.createCSV(data.columns, data.items)); // requires filesaver.js
    }

    if (slots.length > 0) {
      // do ads
      // this.exportAdsAsJson(jobId, slots);
      const adsFilename = `ads_for_job_${jobId}.json`;
      const adsFolder = zipExport.folder(`ads_for_job_${jobId}`);
      const filteredSlots = slots.map((slot: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {ads, ...rest} = slot;
        return rest;
      });
      const jsonString = JSON.stringify(filteredSlots, null, 4);
      const adsCsvFilename = `ads_for_job_${jobId}.csv`;
      adsFolder.file(adsFilename, new Blob([jsonString], {type: 'application/json;charset=utf-8;'})); // requires filesaver.js
      const adsCsvData = this.prepAdsExport(jobId, slots);
      adsFolder.file(adsCsvFilename, this.createCSV(adsCsvData.columns, adsCsvData.items));
    }

    if (transactions.length > 0) {
      const transactionFolder = zipExport.folder(`transactions_for_job_${jobId}`);
      // do transactions
      transactions.forEach(t => {
        const transactionFilename = `transaction_${t.id}.json`;
        transactionFolder.file(`${transactionFilename}`, JSON.stringify(t, null, 4));
      });
      const transactionsCsvFilename = `transactions_for_job_${jobId}.csv`;
      const transactionsCsvData = this.prepTransactionsExport(jobId, transactions);
      transactionFolder.file(
        transactionsCsvFilename,
        this.createCSV(transactionsCsvData.columns, transactionsCsvData.items),
      );
    }

    try {
      zipExport.generateAsync({type: 'blob'}).then(content => {
        downloadFile(zipFilename, content);
      });
      this.notification.info(
        'Zipped download complete.  Check your downloads folder or browser to view your data.',
        'Download Complete',
      );
    } catch (err) {
      this.notification.error('Unable to zip / download job data at this time.', 'Job Export Error');
    }
  }

  public exportAdsAsJson(jobId: string, slots: any) {
    const filename = `ads_for_job_${jobId}`;
    const filteredSlots = slots.map((slot: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {ads, ...rest} = slot;
      return rest;
    });
    const jsonString = JSON.stringify(filteredSlots, null, 4);
    try {
      const blob = new Blob([jsonString], {type: 'application/json;charset=utf-8;'});
      downloadFile(filename, blob);
      this.notification.info(
        'Ads JSON file download complete.  Check your downloads folder or browser to view your data.',
        'Download Complete',
      );
    } catch (err) {
      this.notification.error('Unable to download ads json file at this time.', 'Ads Download Error');
    }
  }

  public prepBeaconsExport(jobId: string, callbacks: any) {
    const callbackThings = new CallbackDetail(this.eventAggregator);

    const data = {
      columns: callbackThings.downloadColumns,
      items: callbacks.map((c: any) => {
        c.show_browser = c.show_browser ? 'Show' : 'Hide';
        c.job_id = jobId;
        c.channel_guid = 'should be job.channel_guid';
        c.session_id = 'should be job.viewer_guid';
        c.asset_id = 'should be job.video_guid';
        return c;
      }),
      jobId,
    };

    return data;
  }

  public prepAdsExport(jobId: string, slots: any) {
    const adModel = new AdDetail(this.eventAggregator);
    const items = [];
    slots.forEach((slot, slot_index) => {
      slot.ads.forEach((ad, ad_index) => {
        items.push({
          isSelected: ad.selected,
          breakIndex: slot_index,
          adIndex: ad_index,
          initialAdId: ad.initialAdId,
          adId: ad.adId,
          creativeId: ad.creativeId,
          duration: ad.duration,
          isFallback: ad.isFallback,
          uplynkStatus: ad.uplynkStatus,
        });
      });
    });
    return {
      columns: adModel.displayColumns,
      items,
      jobId,
    };
  }

  public prepTransactionsExport(jobId: string, transactions: any) {
    const transactionModel = new TransactionDetail(this.eventAggregator);
    return {
      columns: transactionModel.export_columns,
      items: transactions,
      jobId,
    };
  }

  public exportBeaconsAsCSV(jobId: string, columns: any[], items: any[]) {
    const filename = `beacons_for_job_${jobId}.csv`;
    this.downloadCSVBlob('Beacons', filename, this.createCSV(columns, items));
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }

  private setAdDebugRequestUrlConfig(criteria: DebugSearchCriteria) {
    // for each csv request_url param, set the config values for the UI
    const requestUrlArray = criteria.request_url.split(',');
    const requestUrlJSON = {};
    requestUrlArray.forEach(element => {
      if (element.includes('=')) {
        const k = element.split('=')[0];
        const v = element.split('=')[1];
        requestUrlJSON[k] = v;
      }
    });
    criteria.ad_debug_request_url_config.forEach(adprovider => {
      adprovider.params.forEach(param => {
        if (Object.prototype.hasOwnProperty.call(requestUrlJSON, param.parm_key)) {
          param.value = requestUrlJSON[param.parm_key];
        }
      });
    });
  }

  private processRow(columns: any[], item: any) {
    const buffer = [];
    // CSV header
    if (!item) {
      return `${columns.map(i => i.colHeadValue).join(',')}\n`;
    }

    // CSV body
    columns.forEach(col => {
      const key = col.colHeadName;
      let innerValue = item[key] === null ? '' : item[key].toString();
      if (item[key] instanceof Date) {
        innerValue = item[key].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = `"${result}"`;
      }
      buffer.push(result);
    });

    return `${buffer.join(',')}\n`;
  }

  private createCSV(columns: any[], items: any[]) {
    let csvString = '';
    csvString += this.processRow(columns, null);
    items.forEach(item => {
      csvString += this.processRow(columns, item);
    });

    const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
    return blob;
  }

  private downloadCSVBlob(dataType: string, filename: string, blob: Blob) {
    try {
      downloadFile(filename, blob);
      this.notification.info(
        `${dataType} CSV file download complete.  Check your downloads folder or browser to view your data.`,
        'Download Complete',
      );
    } catch (err) {
      this.notification.error('Unable to download data at this time.', `${dataType} Download Error`);
    }
  }

  private updateQueryparamCallback(criteriaAndReponseParam: CriteriaAndReponse) {
    // update the URL
    this.pageView = Page.List;
    const instruction: NavigationInstruction = this.router.currentInstruction;
    instruction.queryParams.page = criteriaAndReponseParam.criteria.page;
    this.currentCriteria = criteriaAndReponseParam.criteria;
    this.response = criteriaAndReponseParam.response;
    let {route} = instruction.config;
    route = Array.isArray(route) ? route[0] : route;
    this.router.navigate(`${route}?${this.currentCriteria.toQueryString()}`);
  }
}
