import {autoinject, singleton} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {Plugin, Region, Slicer, SlicerListResponse, SlicerVersion} from '../models/csl-slicers';

export const CLOUD_SLICER_LIVE_URL: string = '/api/v4/ingest/cloud-slicers/live/slicers';

@autoinject()
@singleton()
export class SlicerService {
  constructor(public acceo: Acceo) {}

  private config = {
    responseTransform: data => {
      data.slicer_id = data.id;
      return data;
    },
  };

  public getSlicers(): Promise<SlicerListResponse> {
    return this.acceo.get(SlicerListResponse)(CLOUD_SLICER_LIVE_URL, {
      responseTransform: data => {
        _.each(data.items, i => {
          i.slicer_id = i.id;
        });
        return data;
      },
    });
  }

  public getSlicer(slicerID: string): Promise<Slicer> {
    return this.acceo.get(Slicer)(`${CLOUD_SLICER_LIVE_URL}/${slicerID}`, this.config);
  }

  public deleteSlicer(slicerID: string): Promise<any> {
    return this.acceo.delete()(`${CLOUD_SLICER_LIVE_URL}/${slicerID}`);
  }

  public createSlicer(slicer: Slicer): Promise<Slicer> {
    return this.acceo.post(Slicer)(`${CLOUD_SLICER_LIVE_URL}`, slicer, this.config);
  }

  public updateSlicer(slicer: Slicer): Promise<Slicer> {
    return this.acceo.put(Slicer)(`${CLOUD_SLICER_LIVE_URL}/${slicer.id}`, slicer, this.config);
  }

  public startSlicer(slicer: Slicer): Promise<Slicer> {
    slicer.target_state = 'ACTIVE';
    return this.updateSlicer(slicer);
  }

  public stopSlicer(slicer: Slicer): Promise<Slicer> {
    slicer.target_state = 'INACTIVE';
    return this.updateSlicer(slicer);
  }

  public restartSlicer(slicer: Slicer): Promise<any> {
    slicer.status.state = 'RESTARTING';
    return this.acceo.post()(`${CLOUD_SLICER_LIVE_URL}/${slicer.id}/restart`, {}, this.config);
  }

  public getPlugins() {
    return this.acceo.get([Plugin])(`${CLOUD_SLICER_LIVE_URL}/plugins`, {
      prefix: 'items',
    });
  }

  public getLogs(slicerID: string, lines: number = 50, since: number = 0): Promise<any> {
    return this.acceo.get()(`${CLOUD_SLICER_LIVE_URL}/${slicerID}/log?lines=${lines}&since=${since}`, {
      prefix: 'log',
    });
  }

  public getProtocols(): Promise<any> {
    return this.acceo.get()(`${CLOUD_SLICER_LIVE_URL}/protocols`, {
      prefix: 'protocols',
    });
  }

  public getRegions(): Promise<Region[]> {
    return this.acceo.get([Region])(`${CLOUD_SLICER_LIVE_URL}/regions`, {
      prefix: 'regions',
    });
  }

  public getVersions(region: string): Promise<any> {
    return this.acceo.get([SlicerVersion])(`${CLOUD_SLICER_LIVE_URL}/versions?region=${region}`, {
      prefix: 'items',
    });
  }
}
