import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, LogManager} from 'aurelia-framework';
import {FailoverGroup} from '../../models/failover-group';
import {FailoverGroupService} from '../../services/failover-group-service';
import {BaseGroup} from './base';
import {FailoverGroupEvent} from './index';

const logger = LogManager.getLogger('Thresholds');

@autoinject()
export class Thresholds extends BaseGroup {
  @bindable
  public group: FailoverGroup;

  public logger = logger;

  public descriptions = {
    failover_audio_loss_fault_duration: 'Considered failed when missing audio for 90 seconds',
    failover_audio_loss_recovery_duration: 'Considered working when not missing audio for 60 seconds',

    failover_blackness_fault_duration: 'Considered failed when black screen for 120 seconds',
    failover_blackness_low: 'Luma value for black screen threshold (0-100, with higher being more sensitive)',
    failover_blackness_recovery_duration: 'Considered working when no black screen for 60 seconds',

    failover_cc_last_seen_fault_duration: 'Considered failed when missing closed captions for 60 seconds',
    failover_cc_last_seen_recovery_duration: 'Considered working when not missing closed captions for 90 seconds',

    failover_dropped_fault_duration: 'Considered failed when frames dropped threshold exceeded for 5 seconds',
    failover_dropped_high: 'Number of dropped frames allowed within a five second window',
    failover_dropped_recovery_duration:
      'Considered working when frames dropped threshold not exceeded for 6 seconds.  Frames may be dropped if Slicer overloaded.',

    failover_input_fault_duration: 'Considered failed when no input for 0 seconds',
    failover_input_recovery_duration: 'Considered working when input for 30 seconds',

    failover_nielsen_last_seen_fault_duration: 'Considered failed when missing Nielsen tags for 60 seconds',
    failover_nielsen_last_seen_recovery_duration: 'Considered working when not missing Nielsen tags for 90 seconds',

    failover_proc_q_fault_duration: 'Considered failed when processing queue threshold exceeded for 10 seconds',
    failover_proc_q_high:
      'Slicer processing queue depth (A/V packets) where exceeding is considered a failure.  This should trigger before the Slicer drops frames.',
    failover_proc_q_recovery_duration: 'Considered working when processing queue threshold not exceeded for 30 seconds',

    failover_scte_last_seen_fault_duration: 'Considered failed when no SCTE cues for 60 seconds',
    failover_scte_last_seen_recovery_duration: 'Considered working when SCTE cues for 90 seconds',

    failover_static_audio_fault_duration:
      'Considered failed when static audio (volume exactly the same) for 90 seconds',
    failover_static_audio_recovery_duration:
      'Considered working when no static audio (volume exactly the same) for 0 seconds',

    failover_static_video_fault_duration: 'Considered failed when static video (static luma value) for 90 seconds',
    failover_static_video_recovery_duration:
      'Considered working when no static video (static luma value) for 60 seconds',

    failover_queue_fault_duration: 'Considered failed when upload queue threshold exceeded for 5 seconds',
    failover_queue_high: 'Considered working when upload queue threshold not exceeded for 5 seconds',
    failover_queue_recovery_duration:
      'Slicer upload queue depth (A/V packets) where exceeding is considered a failure.  This indicates that the Slicer is unable to upload packets to the cloud.',

    failover_video_loss_fault_duration:
      'Considered failed when no video feed in input transport stream for 90 seconds (No video PID in stream)',
    failover_video_loss_recovery_duration:
      'Considered working when video feed in input transport stream for 60 seconds (Video PID in stream)',

    failover_tr_101_290_stats_P1_errors_fault_duration:
      'Considered failed when TR 101 290 P1 error threshold exceeded for 0 seconds',
    failover_tr_101_290_stats_P1_errors_recovery_duration:
      'Considered working when TR 101 290 P1 error threshold not exceeded for 30 seconds',
    failover_tr_101_290_stats_P1_errors_high:
      'Considered failed when there are X number of TR 101 290 P1 errors during the set duration',

    failover_tr_101_290_stats_P2_errors_fault_duration:
      'Considered failed when TR 101 290 P2 error threshold exceeded for 0 seconds',
    failover_tr_101_290_stats_P2_errors_recovery_duration:
      'Considered working when TR 101 290 P2 error threshold not exceeded for 30 seconds',
    failover_tr_101_290_stats_P2_errors_high:
      'Considered failed when there are X number of TR 101 290 P1 errors during the set duration',
  };

  constructor(public failoverGroupService: FailoverGroupService, public eventAggregator: EventAggregator) {
    super();
  }

  public async groupChanged(_newGroup: FailoverGroup, _oldGroup: FailoverGroup) {
    this.dirtyCheck(_newGroup, _oldGroup);
  }

  public statusChanged(name, event) {
    const status = event.target.checked;
    this.group.thresholds[name].status = status;

    this.groupChanged(this.group, this.originalGroup);
  }

  public async attached() {
    try {
      if (this.group && !this.group.thresholds) {
        this.isLoading = true;
        const groups = await this.failoverGroupService.getFailoverGroups(this.group.id, {thresholds: true});
        this.group.thresholds = _.cloneDeep(_.get(groups[0], 'thresholds', null));
        this.originalGroup.thresholds = _.cloneDeep(_.get(groups[0], 'thresholds', null));
        this.isLoading = false;
      }
      this.subscriptions.push(this.eventAggregator.subscribe(FailoverGroupEvent.SAVE, this.save.bind(this)));
    } catch (e) {
      this.logger.error(e);
    }
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }
}
