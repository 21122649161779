import {CToastsService, IVNavSliderNavList, IVNavSliderPageList, SharedNav} from '@bindable-ui/bindable';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable, computedFrom, LogManager} from 'aurelia-framework';
import {RouteConfig, Router} from 'aurelia-router';
import {dirtyCheckPrompt} from 'decorators';
import {HyperionPolling} from 'services/hyperion-polling';
import {FailoverGroup} from '../../models/failover-group';
import {FailoverGroupService} from '../../services/failover-group-service';
import {SlicerService} from '../../services/slicer-service';
import {RouteName} from '../index';
import {FailoverGroupRoute} from './route';

const logger = LogManager.getLogger('FailoverGroup');
const GROUPS_REFRESH_INTERVAL = 5000;

export enum FailoverGroupEvent {
  SAVE = 'FailoverGroup:SAVE',
  SAVE_COMPLETE = 'FailoverGroup:SAVE_COMPLETE',
}

@dirtyCheckPrompt
@autoinject()
export class FailoverGroupViewModel {
  public routes: RouteConfig[];
  public routeName: string;

  public isModified = false;
  public isLoading: boolean = true;
  public isSaving: boolean = false;

  public failoverGroupId: string;
  public originalGroup: FailoverGroup;

  @bindable()
  public failoverGroup: FailoverGroup;

  public failoverGroups: FailoverGroup[];
  public subscriptions: Subscription[] = [];
  public logger = logger;

  public foTabs: any;

  public navPage: IVNavSliderPageList = {
    navs: [],
    prevText: 'Failover Groups',
    searchFn: this.searchFailoverGroups.bind(this),
    searchPlaceholder: 'Search Failover Groups',
    searchQuery: '',
    title: 'All Failover Groups',
  };

  public pollTracker: HyperionPolling;
  public groupBadgeClass;

  public constructor(
    public slicerService: SlicerService,
    public failoverGroupService: FailoverGroupService,
    public sharedNav: SharedNav,
    public router: Router,
    public failoverGroupRoute: FailoverGroupRoute,
    public eventAggregator: EventAggregator,
    public toastsService: CToastsService,
  ) {
    this.pollTracker = new HyperionPolling({
      callbackFn: updatedFailoverGroups => {
        const currentGroup = _.find(updatedFailoverGroups, {id: this.failoverGroupId});
        if (this.failoverGroup && currentGroup) {
          this.failoverGroup.active = _.get(currentGroup, 'active');
          if (this.failoverGroup.slicers) {
            this.failoverGroup.slicers = _.map(_.cloneDeep(this.failoverGroup.slicers), s => {
              const currentGroupSlicer = _.find(currentGroup.slicers, _s => _s.id === s.id);
              if (currentGroupSlicer) {
                s.status = _.merge({}, s.status, _.get(currentGroupSlicer, 'status'));
                s.blacklist_until = _.get(currentGroupSlicer, 'blacklist_until');
              }
              return s;
            });
          }
          if (!this.failoverGroup.thresholds) {
            this.failoverGroup.thresholds = _.cloneDeep(currentGroup.thresholds);
          }
        }
        this.setGroupState();
      },
      getParams: () => ({active: true, channels: true, slicers: true, thresholds: true}),
      includeDeleted: false,
      ms: GROUPS_REFRESH_INTERVAL,
      promiseFn: async paramsArg => this.failoverGroupService.getFailoverGroups(this.failoverGroupId, paramsArg),
      useAfter: true,
    });
  }

  public async activate(params: any, routeConfig: RouteConfig) {
    this.routeName = routeConfig.name || '';
    this.routes = this.failoverGroupRoute.routes;
    this.failoverGroupId = params.id;
    try {
      this.isLoading = true;

      const routeObj = this.router.parent.routes.find(x => x.name === RouteName.FailoverGroupsList);

      const data = _.get(routeObj, 'settings.data');
      this.failoverGroups = _.get(data, 'failoverGroups');

      if (!this.failoverGroups) {
        this.failoverGroups = await this.failoverGroupService.getFailoverGroups('', {
          active: true,
          channels: true,
          slicers: true,
          thresholds: true,
        });
      }

      this.failoverGroup = _.get(data, 'failoverGroup');

      if (_.get(this.failoverGroup, 'id') !== this.failoverGroupId) {
        this.failoverGroup = _.find(this.failoverGroups, {id: this.failoverGroupId});

        if (!this.failoverGroup) {
          const res = await this.failoverGroupService.getFailoverGroups(this.failoverGroupId, {
            active: true,
            channels: true,
            slicers: true,
            thresholds: true,
          });

          this.failoverGroup = res[0];
        }
        this.failoverGroup.slicers = _.orderBy(this.failoverGroup.slicers, ['order'], ['asc']);
      }

      this.originalGroup = _.cloneDeep(this.failoverGroup);

      this.pollTracker.start();
    } catch (err) {
      this.logger.error(err);
    } finally {
      this.isLoading = false;
    }

    this.setActiveNavItem();
  }

  public async attached() {
    this.isLoading = true;

    this.sharedNav.replacePage(this.navPage, 1);
    this.sharedNav.setNextText('All Failover Groups');

    this.subscriptions.push(
      this.eventAggregator.subscribe(FailoverGroupEvent.SAVE_COMPLETE, async () => {
        this.isSaving = false;
        this.failoverGroups = await this.failoverGroupService.getFailoverGroups('', {
          active: true,
          channels: true,
          slicers: true,
          thresholds: true,
        });
      }),
    );

    this.setNavItems();
    this.setGroupState();

    this.isLoading = false;
  }

  public setNavItems(filter?: string) {
    if (this.failoverGroups) {
      this.navPage.navs = this.failoverGroups
        .map(
          (failoverGroup: FailoverGroup): IVNavSliderNavList => ({
            active: false,
            href: `#/slicers/groups/${failoverGroup.id}`,
            title: failoverGroup.name,
          }),
        )
        .filter((item: IVNavSliderNavList) => {
          if (filter) {
            return item.title.toLowerCase().includes(filter.toLowerCase());
          }
          return item;
        });
      this.setActiveNavItem();
      // @ts-ignore
      this.sharedNav.clearPageActive(0);
    }
  }

  public searchFailoverGroups(query: string) {
    this.setNavItems(query);
  }

  public isDirty(): boolean {
    // no need to do dirty check if we are still in single slicer view page (routes has current slicer id in it)
    // if (window.location.hash.indexOf(this.failoverGroupId) !== -1) return false;

    return this.isModified;
  }

  @computedFrom('isModified', 'isSaving')
  public get saveButtonState(): string {
    if (this.isSaving) return 'thinking';
    if (!this.isModified) return 'disabled';
    return '';
  }

  public async save() {
    this.isSaving = true;
    this.eventAggregator.publish(FailoverGroupEvent.SAVE);
  }

  public async reset() {
    this.failoverGroup = _.cloneDeep(this.originalGroup);
  }

  public backToContent() {
    this.router.navigate('/slicers/groups');
  }

  public detached() {
    this.pollTracker.stop();
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }

  public deactivate() {
    const routeObj = this.router.parent.routes.find(x => x.name === RouteName.FailoverGroupsList);
    routeObj.settings.data = {
      failoverGroup: this.failoverGroup,
      failoverGroups: this.failoverGroups,
    };
  }

  public setGroupState() {
    const status = this.failoverGroup?.active?.status;

    if (!status || typeof status === 'string') {
      return null;
    }

    const {stateLabel} = status;

    const stateMap: Record<string, string | null> = {
      Slicing: 'slicing',
      Ads: 'ad',
      'Replacing content': 'override',
      Blackout: 'primary',
      'Shutting Down': 'warning',
      Shutdown: 'neutral',
    };

    const badgeClass = stateLabel ? stateMap[stateLabel] ?? null : null;
    this.groupBadgeClass = badgeClass;
    return badgeClass;
  }

  private setActiveNavItem() {
    if (this.failoverGroups && this.failoverGroup) {
      const index = _.findIndex(this.failoverGroups, {id: this.failoverGroupId});
      if (index > -1) {
        this.sharedNav.setActive(1, index);
      }
    }
  }

  public async tabChangeHandler(row, event) {
    const route = row.config.name;

    // Prevent the tab-group from activating the new tab immediately
    event.stopPropagation();

    // If model is modified, dirtyCheckPrompt is triggered.
    // Wait until the router actually navigates
    await this.router.navigateToRoute(route);

    // Use the lynk-tab-group show() method to manually activate the tabs
    if (this.router.currentInstruction.config.name === route) {
      this.foTabs.show(route);
    }
  }

  public onDirtyPromptLeave() {
    this.reset();
  }
}
