import {LynkRange} from '@uplynk/lynk-design';
import {autoinject, BindingEngine, bindable} from 'aurelia-framework';
import {App} from '../app';

@autoinject
export class AdBreaks {
  public newAdMarkerTimestamp: string = '';
  public newAdMarkerTimestampInput: any;
  public newAdMarkerPopover: any;
  public newAdMarkerState: string = '';
  public newAdMarkerHelpText: string = '';
  @bindable public enableKeyboardShortcuts: boolean = true;

  private subscription: any;

  constructor(public app: App, private bindingEngine: BindingEngine) {}

  attached() {
    /* istanbul ignore next */
    this.subscription = this.bindingEngine.propertyObserver(this.app, 'source_model').subscribe(() => {
      this.loadAdBreaks();
    });
    this.loadAdBreaks();

    // Add keyboard shortcuts if enabled
    if (this.enableKeyboardShortcuts) {
      document.addEventListener('keydown', this.keyDownHandler.bind(this));
    }
  }

  detached() {
    this.subscription.dispose();

    // Remove keyboard shortcuts if they were added
    if (this.enableKeyboardShortcuts) {
      document.removeEventListener('keydown', this.keyDownHandler.bind(this));
    }
  }

  loadAdBreaks() {
    this.app.adBreakOffsets = [];
    if (this.app.source_model.break_offsets) {
      this.app.source_model.break_offsets.forEach((b, idx) => {
        this.app.adBreakOffsets.push({
          offset: b.offset,
          timestamp: this.convertTimestamp(b.offset),
          existingIndex: idx,
        });
      });
    }
    this.app.existingAdBreakOffsets = _.cloneDeep(this.app.adBreakOffsets);
  }

  convertTimestamp(input: string) {
    let totalSeconds = 0;

    const regexHms = /^(?<hours>\d{0,2}):(?<minutes>\d{0,2}):(?<seconds>\d{0,2}\.?\d*)$/;
    const matchHms = regexHms.exec(input);
    const regexMs = /^(?<minutes>\d{0,2}):(?<seconds>\d{0,2}\.?\d*)$/;
    const matchMs = regexMs.exec(input);
    if (matchHms) {
      if (matchHms.groups.hours) totalSeconds += parseInt(matchHms.groups.hours, 10) * 3600;
      if (matchHms.groups.minutes) totalSeconds += parseInt(matchHms.groups.minutes, 10) * 60;
      if (matchHms.groups.seconds) totalSeconds += parseFloat(matchHms.groups.seconds);
    } else if (matchMs) {
      if (matchMs.groups.minutes) totalSeconds += parseInt(matchMs.groups.minutes, 10) * 60;
      if (matchMs.groups.seconds) totalSeconds += parseFloat(matchMs.groups.seconds);
    } else {
      const regex = /(?:(?<hours>\d+)h)?\s?(?:(?<minutes>\d+)m)?\s?(?:(?<seconds>\d+\.?\d*)s?)?/g;
      const match = regex.exec(input);
      if (match.groups.hours) totalSeconds += parseInt(match.groups.hours, 10) * 3600;
      if (match.groups.minutes) totalSeconds += parseInt(match.groups.minutes, 10) * 60;
      if (match.groups.seconds) totalSeconds += parseFloat(match.groups.seconds);
    }

    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return [
      _.padStart(hours.toString(), 2, '0'),
      _.padStart(minutes.toString(), 2, '0'),
      this.formatSeconds(seconds),
    ].join(':');
  }

  // eslint-disable-next-line class-methods-use-this
  formatSeconds(num: number): string {
    let [
      intPart,
      decPart,
    ] = num.toFixed(3).split('.');

    intPart = intPart.padStart(2, '0');
    decPart = decPart.replace(/0+$/, '');

    return decPart ? `${intPart}.${decPart}` : intPart;
  }

  // eslint-disable-next-line class-methods-use-this
  timestampToSeconds(timestamp: string): number {
    const [
      hours,
      minutes,
      seconds,
    ] = timestamp.split(':');
    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseFloat(seconds);
  }

  initNewAdMarker() {
    this.newAdMarkerState = '';
    this.newAdMarkerHelpText = '';
    this.newAdMarkerTimestampInput.focus();

    if (this.app.playHeadTime > 0) {
      this.newAdMarkerTimestamp = this.convertTimestamp(this.app.playHeadTime.toString());
      this.newAdMarkerTimestampInput.select();
    }
  }

  jumpToTimestamp(timestamp: string) {
    this.app.sourcePlayer.setCurrentTime(this.timestampToSeconds(timestamp));
  }

  insertAdMarker() {
    if (!this.newAdMarkerTimestamp) {
      this.newAdMarkerState = 'warning';
      this.newAdMarkerHelpText = 'Enter a valid time.';
      return;
    }
    // convert to seconds
    const reprocessOffsetTime = this.timestampToSeconds(this.newAdMarkerTimestamp);

    // ensure the offset is greater or equal to 1 second
    if (reprocessOffsetTime < 1) {
      this.newAdMarkerState = 'error';
      this.newAdMarkerHelpText = 'Offset time cannot be less than 1 second.';
      return;
    }
    // ensure the offset is not greater than the asset duration
    if (reprocessOffsetTime > this.app.source_model.duration) {
      this.newAdMarkerState = 'error';
      this.newAdMarkerHelpText = 'Offset time cannot be greater than the asset duration.';
      return;
    }
    // ensure that the offset is not a duplicate
    if (this.app.adBreakOffsets.some(b => b.offset === reprocessOffsetTime)) {
      this.newAdMarkerState = 'error';
      this.newAdMarkerHelpText = 'Offset time already exists.';
      return;
    }
    this.app.adBreakOffsets.push({
      offset: reprocessOffsetTime,
      timestamp: this.newAdMarkerTimestamp,
      existingIndex: null,
    });
    this.sortBreaks();

    // clear input value
    this.newAdMarkerTimestamp = '';

    // close modal
    this.newAdMarkerPopover.hide();
  }

  handleAddKeydown(event) {
    if (event.key === 'Enter') {
      this.newAdMarkerTimestamp = this.convertTimestamp(this.newAdMarkerTimestamp);
      this.insertAdMarker();
      return false;
    }
    return true;
  }

  convertAndSortEdit(adBreak) {
    adBreak.timestamp = this.convertTimestamp(adBreak.timestamp);
    adBreak.offset = this.timestampToSeconds(adBreak.timestamp);
    this.sortBreaks();
  }

  sortBreaks() {
    this.app.adBreakOffsets.sort((a, b) => a.offset - b.offset);
  }

  removeAdBreak(adBreak) {
    this.app.adBreakOffsets.splice(this.app.adBreakOffsets.indexOf(adBreak), 1);
  }

  private keyDownHandler(event: KeyboardEvent) {
    // Ignore the key press if it originates from input, textarea, or select
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLTextAreaElement ||
      event.target instanceof HTMLSelectElement
    ) {
      return;
    }
    const originalEventTarget = event.composedPath()[0];
    if (
      (!(event.target instanceof LynkRange) && originalEventTarget instanceof HTMLInputElement) ||
      originalEventTarget instanceof HTMLTextAreaElement ||
      originalEventTarget instanceof HTMLSelectElement
    ) {
      return;
    }

    // If a key is pressed insert an ad marker
    if (event.key === 'a') {
      this.newAdMarkerTimestamp = this.convertTimestamp(this.app.playHeadTime.toString());
      this.insertAdMarker();
    }

    // If a + shift + control is pressed remove all ad markers
    if (event.key === 'A' && event.shiftKey && event.ctrlKey) {
      this.app.adBreakOffsets = [];
    }
  }
}
