import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, LogManager} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {
    OwnerNotificationNotifyee,
    OwnerNotificationNotifyeeAction,
    OwnerNotificationNotifyeeCollection,
} from '../models/models';

const log = LogManager.getLogger('notifyee-notification-service');

export const OWNER_NOTIFICATION_NOTIFYEE_URL: string = '/api/v4/owner_notification-notifyee/';

@autoinject()
export class NotifyeeNotificationService {
    public isLoading: boolean = false;
    public notifyeeNotifications: OwnerNotificationNotifyee[] = [];

    constructor(public acceo: Acceo, public notification: CToastsService, public session: SessionService) {}

    public async getNotifications() {
        if (this.isLoading) {
            return;
        }
        try {
            this.isLoading = true;
            const res = await this.acceo.get(OwnerNotificationNotifyeeCollection)(OWNER_NOTIFICATION_NOTIFYEE_URL, {
                responseTransform: (resp: OwnerNotificationNotifyeeCollection) => resp,
            });
            this.notifyeeNotifications = _.uniqBy(this.notifyeeNotifications.concat(res.items), 'id');
            this.session.total_notifications = this.notifyeeNotifications.length;
        } catch (err) {
            log.error(err);
            this.notification.error('Error loading audiences. Please try again.');
        } finally {
            this.isLoading = false;
        }
    }

    public async processNotification(id: string, mode: string) {
        try {
            const res = await this.acceo.patch(OwnerNotificationNotifyeeAction)(
                `${OWNER_NOTIFICATION_NOTIFYEE_URL}${id}/action`,
                {mode},
            );
            let msg = '';
            switch (mode) {
                case 'acknowledge':
                    msg = `You have successfully granted access to your api key`;
                    break;
                case 'accept':
                case 'decline':
                    msg = `You have successfully ${res.message} access to the library`;
                    break;
            }
            this.notification.success(msg);
            _.remove(this.notifyeeNotifications, {id});
            this.session.total_notifications -= 1;
        } catch (err) {
            log.error(err);
            this.notification.error('Error processing notification. Please try again.');
        }
    }
}
