import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';

import {IsNumber, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class BillingRollup {
    /*
     * Computed Properties
     */

    @computedFrom('processing')
    get rollupBtnState() {
        return this.processing ? 'thinking' : '';
    }

    /*
     * Public Properties
     */

    public parentUsername: string = '';
    public childUsername: string = '';
    private processing: boolean = false;

    constructor(private acceo: Acceo, private notification: CToastsService) {}

    /*
     * Public Methods
     */

    public async rollupAccount() {
        this.processing = true;

        const url: string = '/ops/rollup-account';
        try {
            const resp = await this.acceo.post(Response)(url, {
                childUsername: this.childUsername,
                parentUsername: this.parentUsername,
            });
            if (resp.success) {
                this.notification.success(`Account successfully rolled up for "${this.childUsername}"`);
            } else {
                this.notification.error(`API returned an error: ${resp.error}`);
            }
        } catch (err) {
            this.notification.error(err);
        }

        this.processing = false;
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsNumber()
    public success: number;

    @IsNumber()
    public error: any;
}
