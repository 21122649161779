import {inject} from 'aurelia-framework';
import 'select2';
import {Shared} from './shared-data';

@inject(Shared)
export class ModifyModalBody {
    public controller;
    public model: any;
    public shared;
    public titleState;
    public htmlState;
    public tagsTest = [];

    public publishDefault = true;
    public addTagInput = 'show';
    public newTag = '';
    public activeTag = '';
    public tagInputState = '';
    public tagInputErrorMsg = '';
    public tag_options = [];

    constructor(shared) {
        this.shared = shared;
        this.shared.title = '';
        this.shared.html = '';
    }

    public activate(model) {
        this.controller = model.controller;
        this.model = model.body;
        this.shared.title = this.model.inputValues.title;
        this.shared.html = this.model.inputValues.detailHTML;
        if (!this.shared.title) {
            this.titleState = 'error';
        }
        if (!this.shared.html) {
            this.htmlState = 'error';
        }
    }

    public addNewTag(tag) {
        if (tag) {
            if (this.model.inputValues.tags) {
                if (!this.model.inputValues.tags.includes(tag)) {
                    this.model.inputValues.tags.push(tag);
                    this.removeOptionTag(tag);
                    this.newTag = '';
                    this.tagInputState = '';
                } else {
                    this.tagInputErrorMsg = 'The entered tag already exists in the list of tags.';
                    this.tagInputState = 'error';
                }
            } else {
                this.model.inputValues.tags = [];
                this.model.inputValues.tags.push(tag);
                this.newTag = '';
                this.tagInputState = '';
            }
        } else {
            this.tagInputErrorMsg = 'Cannot enter a blank tag.';
            this.tagInputState = 'error';
        }
    }

    public removeTag(oldTag) {
        this.model.inputValues.tags.forEach((item, index) => {
            if (item === oldTag) {
                this.model.inputValues.tags.splice(index, 1);
                this.model.inputValues.tag_options.push(oldTag);
            }
        });
    }

    public removeOptionTag(oldTag) {
        this.model.inputValues.tag_options.forEach((item, index) => {
            if (item === oldTag) this.model.inputValues.tag_options.splice(index, 1);
        });
    }

    public watchTitle() {
        if (this.shared.title !== '') {
            this.titleState = '';
        } else {
            this.titleState = 'error';
        }
        this.model.inputValues.title = this.shared.title;
    }

    public watchHTML() {
        if (this.shared.html !== '') {
            this.htmlState = '';
        } else {
            this.htmlState = 'error';
        }
        $('#preview_body').html(
            `<div style='color:black;max-width: 1024px;min-width: 320px;height: 10rem;overflow:auto;'>${this.shared.html}</div>`,
        );
        this.model.inputValues.detailHTML = this.shared.html;
    }

    public deleteNewsItem() {
        this.model.inputValues.delete = true;
        this.controller.ok(true);
    }
}
