import {autoinject} from 'aurelia-framework';
import {IdsSettingsService} from '../service';
import { CToastsService } from '@bindable-ui/bindable';

@autoinject()
export class Trial {
    public clientData;
    constructor(private notification: CToastsService, public IdsSetting: IdsSettingsService) {}

    public async activate() {
        await this.getClientSettings();
    }

    public async getClientSettings(){
        try {
            const resp = await this.IdsSetting.getIdsSettings();
            this.clientData = JSON.stringify(resp, undefined, 2);
        } catch (error) {
            this.notification.error(error);
        }
    }


}
