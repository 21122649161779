import {CToastsService, ITimeEntryBasic} from '@bindable-ui/bindable';
import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import * as moment from 'moment-timezone';
import {Acceo, AcceoError} from 'services/acceo';
import {getTzOffset} from 'apps/cms/utils/time-format';
import {checkActiveStatus, transformEntries} from './schedule-workers';

import {ChannelItems} from '../models/models';

import {
  ConflictResolution,
  IScheduleEntry,
  ScheduleEntry,
  ScheduleEntryListResponse,
} from '../single/models/event-model';

// Set in the API as the max number of updates to process
const MAX_UPDATES = 500;

export interface IConflictData {
  entryName: string;
  entryNameTip?: boolean;
  entryNameTipSize?: string;
  model: ScheduleEntry;
  startTime: string;
  endTime: string;
  type: string;
}

export interface IScheduledEntryParams {
  ad_breaks?: number[];
  blackout_id?: string;
  buffer?: string;
  conflict_resolution?: ConflictResolution;
  channel?: string;
  content_id?: string;
  content_owner?: string;
  content_type?: string;
  desc?: string;
  dur: number;
  external_id?: string;
  rules?: string[];
  start: string;
  replay_source_start?: string;
  replay_source_end?: string;
  overlay_source_type?: string;
}

export interface IScheduledEntryListParams {
  channel?: string;
  end: string;
  start: string;
}

export interface IScheduleEntryQueryParams {
  after?: string;
  included?: string;
}

export const CHANNELS_URL: string = '/api/v4/channels';

const log = LogManager.getLogger('schedule-entry-service');

const correctDate = (start, end, query) => {
  const startDate = moment(query.start);
  const endDate = moment(query.stop);

  const entryStart = moment(start);
  const entryEnd = moment(end);

  const startIn = entryStart.isSameOrAfter(startDate) && entryStart.isBefore(endDate);
  const endIn = entryEnd.isAfter(startDate) && entryStart.isBefore(endDate);

  return startIn || endIn;
};

@autoinject()
export class ScheduleEntryService {
  @computedFrom('conflicts.length')
  get hasConflicts() {
    return this.conflicts.length > 0;
  }

  @computedFrom('gap')
  get hasGap() {
    return this.gap > 0;
  }

  @computedFrom('entryBeforeConflicts')
  get hasEntryBeforeConflicts() {
    return this.entryBeforeConflicts !== null;
  }

  @computedFrom('entryAfterConflicts')
  get hasEntryAfterConflicts() {
    return this.entryAfterConflicts !== null;
  }

  public allowOverwrite: boolean = false;
  public conflicts: IConflictData[] = [];
  public entryBeforeConflicts: IConflictData = null;
  public entryAfterConflicts: IConflictData = null;
  public conflictChosenMethod: ConflictResolution | null = null;
  public gap: number = 0;
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public isUpdating: boolean = false;
  public saveCallback: any;
  public saveEnabled: boolean = false;
  public saveText: string = '';
  public scheduledEntry: ScheduleEntry = null;
  public forcePoll: string = null;
  public forceUpdate: string = null;

  constructor(private acceo: Acceo, private notificationService: CToastsService) {}

  public clearConflicts(): void {
    if (this.conflicts.length > 0) {
      this.conflicts = [];
      this.gap = 0;
      this.entryBeforeConflicts = null;
      this.entryAfterConflicts = null;
      this.conflictChosenMethod = null;
    }
  }

  public async getConflicts(params: IScheduledEntryListParams, channelId: string, entryId: string) {
    let conflicts = [];

    try {
      const resp = await this.getScheduledEntries(params, channelId);
      conflicts = resp.items.filter(entry => entry.id !== entryId);
      conflicts = conflicts.map(entry => this.mapConflict(entry));

      if (conflicts.length > 0) {
        const gap = moment.duration(moment(conflicts[0].startTime).diff(moment(params.start)));
        let start;
        if (gap.asSeconds() > 0) {
          // convert to milliseconds
          this.gap = gap.asSeconds() * 1000;
          start = params.start;
        } else {
          start = conflicts[0].startTime;
        }

        const _params = {
          start: moment(start).toISOString(), // Takes the start time of the first conflict or the one that the user chooses
          end: moment(conflicts[conflicts.length - 1].endTime).toISOString(),
        };

        await this.setBeforeAndAfterEntries(_params, channelId);
      }
    } catch (ex) {
      log.error(ex);

      this.notificationService.error('Unable to get conflicts');
    } finally {
      this.conflicts = conflicts;
    }
  }

  public async getChannelSchedule(query, channelId, readOnly): Promise<ITimeEntryBasic[]> {
    try {
      const entries = await this.loopChannelSchedule(query, channelId, readOnly);
      return await transformEntries(entries, readOnly, getTzOffset());
    } catch (e) {
      log.error(e);
      this.notificationService.error('Failed to get history');
      return null;
    }
  }

  public async getScheduleUpdates(channelId, entries: any[], query): Promise<ITimeEntryBasic[]> {
    try {
      let updatedEntries = _.cloneDeep(entries) || [];
      const res = await this.getChannelUpdates(channelId);
      const polledEntries: ScheduleEntry[] = [
        ...(res['@included'] || []),
        ...res.items,
      ];

      if (polledEntries.length >= MAX_UPDATES) {
        this.forceUpdate = new Date().toISOString();
        return null;
      }

      const transformedEntries: any[] = await transformEntries(polledEntries, false, getTzOffset());

      transformedEntries.forEach(entry => {
        if (!correctDate(entry.start, entry.end, query)) {
          return;
        }

        const index = _.findIndex(updatedEntries, (item: any) => item.model.id === entry.model.id);

        if (index < 0) {
          if (!entry.model.deleted) {
            updatedEntries.push(entry);
          }

          return;
        }

        if (entry.model.deleted) {
          updatedEntries.splice(index, 1);
          return;
        }

        updatedEntries.splice(index, 1, entry);
      });

      updatedEntries = await checkActiveStatus(updatedEntries, getTzOffset());

      if (_.isEqual(entries, updatedEntries)) {
        return null;
      }

      return updatedEntries;
    } catch (e) {
      log.error(e);
      return null;
    }
  }

  /**
   * Deletes specified ScheduleEntry
   * @param scheduleEntryId
   */
  public async delete(scheduleEntry: IScheduleEntry, channelId: string, query: any = {}) {
    try {
      let url = `${CHANNELS_URL}/${channelId}/schedules/${scheduleEntry.id}`;
      if (query) {
        const urlParams = $.param(query);
        url += `?${urlParams}`;
      }
      await this.acceo.delete()(url);

      this.forceTimelinePoll();
    } catch (ex) {
      log.error(ex);

      throw new AcceoError(ex.message, ex.status_code, ex.details);
    }
  }

  /**
   * Deletes all scheduled entries within a daterange. If start date is in the past, the API
   * will interpret that to the time of request. End date cannot be in the past. This method
   * will try to use whatever dates you give it, so make sure they are valid.
   * @param query
   * @param channelId
   */
  public async deleteScheduledEntriesByDaterange(channelId: string, query: any) {
    try {
      const urlParams = $.param(query);
      const resp = await this.acceo.delete()(`${CHANNELS_URL}/${channelId}/schedules?${urlParams}`);
      this.forceTimelinePoll();
      return resp;
    } catch (ex) {
      log.error(ex);
      throw new AcceoError(ex.message, ex.status_code, ex.details);
    }
  }

  /**
   * Retrieve a ScheduledEntry list for a channel within range
   * @param params IScheduledEntryListParams
   */
  public async getScheduledEntries(
    params: IScheduledEntryListParams,
    channelId: string,
  ): Promise<ScheduleEntryListResponse> {
    if (this.isLoading) {
      return null;
    }

    try {
      this.isLoading = true;

      const urlParams = $.param(params);

      const resp = await this.acceo.get(ScheduleEntryListResponse)(
        `${CHANNELS_URL}/${channelId}/schedules?${urlParams}`,
      );

      return resp;
    } catch (ex) {
      log.error(ex);

      this.notificationService.error('Failed to get entries');
    } finally {
      this.isLoading = false;
    }
    return null;
  }

  public async getScheduledEntry(
    id: string,
    params: IScheduleEntryQueryParams,
    channelId: string,
  ): Promise<ScheduleEntry> {
    if (this.isLoading) {
      return null;
    }

    try {
      this.isLoading = true;

      const urlParams = $.param(params);

      const resp = await this.acceo.get(ScheduleEntry)(`${CHANNELS_URL}/${channelId}/schedules/${id}?${urlParams}`);

      this.scheduledEntry = resp;

      return resp;
    } catch (ex) {
      log.error(ex);

      this.notificationService.error('Failed to get entry');
    } finally {
      this.isLoading = false;
    }
    return null;
  }

  /**
   * Create a ScheduledEntry
   * @param params IScheduledEntryParams
   */
  public async saveScheduledEntry(params: IScheduledEntryParams, channelId: string): Promise<ScheduleEntry> {
    if (this.isSaving) {
      return null;
    }

    try {
      this.isSaving = true;
      const url = `${CHANNELS_URL}/${channelId}/schedules`;
      const scheduledEntry = await this.acceo.post(ScheduleEntry)(url, params);

      this.forceTimelinePoll();

      return scheduledEntry;
    } catch (ex) {
      log.error(ex);

      throw new AcceoError(ex.message, ex.status_code, ex.details);
    } finally {
      this.isSaving = false;
    }
  }

  public resetService() {
    this.conflicts = [];
    this.gap = 0;
    this.entryBeforeConflicts = null;
    this.entryAfterConflicts = null;
    this.conflictChosenMethod = null;
    this.saveCallback = null;
    this.saveEnabled = false;
    this.saveText = '';
    this.scheduledEntry = null;
  }

  public async updateScheduledEntry(
    scheduleEntryId: string,
    params: IScheduledEntryParams,
    channelId: string,
  ): Promise<ScheduleEntry> {
    if (this.isUpdating) {
      return null;
    }

    try {
      this.isUpdating = true;
      const url = `${CHANNELS_URL}/${channelId}/schedules/${scheduleEntryId}`;
      const scheduledEntry = await this.acceo.patch(ScheduleEntry)(url, params);

      this.forceTimelinePoll();

      return scheduledEntry;
    } catch (ex) {
      log.error(ex);

      throw new AcceoError(ex.message, ex.status_code, ex.details);
    } finally {
      this.isUpdating = false;
    }
  }

  public async saveModal(closeDialog = true) {
    if (this.saveCallback && _.isFunction(this.saveCallback)) {
      return this.saveCallback(closeDialog);
    }

    this.notificationService.error('No save callback defined');
    return null;
  }

  public forceTimelinePoll() {
    // Cancel throttle
    this.getChannelUpdates.cancel();
    this.forcePoll = new Date().toISOString();
  }

  // This will throttle the channel schedule update so all days won't kill the server
  private getChannelUpdates = _.throttle(
    (channelId: string): Promise<ScheduleEntryListResponse> => {
      const urlParams = $.param({
        after: 'now-80s',
        include_deleted: true,
        slicer_assets: 1,
      });

      return this.acceo.get(ScheduleEntryListResponse)(`${CHANNELS_URL}/${channelId}/schedules?${urlParams}`);
    },
    10000,
    {leading: true, trailing: false},
  );

  private async loopChannelSchedule(query, channelId, readOnly): Promise<any[]> {
    let response: any;
    let entries: any[] = [];
    let hasFailed: any = null;

    if (readOnly) {
      response = await this.queryChannelHistory(query);
      entries = [...response.assets];

      while (Number(response.start) > query.start) {
        const newQuery = _.cloneDeep(query);
        newQuery.stop = Number(response.start) - 1;

        try {
          // eslint-disable-next-line no-await-in-loop
          response = await this.queryChannelHistory(newQuery);
          entries = [
            ...response.assets,
            ...entries,
          ];
        } catch (e) {
          hasFailed = e;
          break; // Break loop when call fails
        }
      }
    } else {
      response = await this.queryChannelSchedule(query, channelId);
      entries = [
        ...(response['@included'] || []),
        ...response.items,
        ...entries,
      ];

      while (moment(response.end).isBefore(moment(query.end))) {
        const newQuery = _.cloneDeep(query);
        newQuery.start = moment(response.end).add(1, 'ms').toISOString();

        try {
          // eslint-disable-next-line no-await-in-loop
          response = await this.queryChannelSchedule(newQuery, channelId);
          entries = [
            ...(response['@included'] || []),
            ...response.items,
            ...entries,
          ];
        } catch (e) {
          hasFailed = e;
          break; // Break loop when call fails
        }
      }
    }

    if (hasFailed) {
      throw new Error(hasFailed);
    }

    return entries;
  }

  // eslint-disable-next-line class-methods-use-this
  private mapConflict(entry: ScheduleEntry) {
    return {
      entryName: !entry.isSlicer ? entry.desc : entry.content_id,
      model: entry,
      startTime: entry.start,
      endTime: entry.end,
      type: entry.type,
    };
  }

  private queryChannelHistory(query) {
    return this.acceo.post(ChannelItems)('/channel/items/', query, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      requestTransform: params => $.param(params),
    });
  }

  private queryChannelSchedule(query, channelId) {
    query.slicer_assets = 1;
    const urlParams = $.param(query);

    return this.acceo.get(ScheduleEntryListResponse)(`${CHANNELS_URL}/${channelId}/schedules?${urlParams}`);
  }

  private async setBeforeAndAfterEntries(_params, channelId) {
    // Schedule entries before the start time of the new schedule (in the hour before)
    const beforeEntries: any = await this.getChannelSchedule(
      {
        start: moment(_params.start).add(-1, 'hour').toISOString(),
        end: _params.start,
      },
      channelId,
      false,
    );

    // Schedule entries after the last conflict end time (in the hour after)
    const afterEntries: any = await this.getChannelSchedule(
      {
        start: _params.end,
        end: moment(_params.end).add(1, 'hour').toISOString(),
      },
      channelId,
      false,
    );

    // Assigns the last entry from the entries of the hour before the conflicts
    if (beforeEntries.length > 0) {
      this.entryBeforeConflicts = this.mapConflict(beforeEntries[beforeEntries.length - 1].model);
    }

    // Assigns the first entry from the entries of the hour after the conflicts
    if (afterEntries.length > 0) {
      this.entryAfterConflicts = this.mapConflict(afterEntries[0].model);
    }
  }
}
