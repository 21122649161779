// Importing the class from the location of the file
import {inject} from 'aurelia-framework';
import {Shared} from './shared-data';

@inject(Shared)
export class ModifyFooter {
    public shared;
    public controller;

    constructor(shared) {
        this.shared = shared;
    }

    get btnState() {
        if (this.shared.title && this.shared.html) {
            return 'enabled';
        }
        return 'disabled';
    }

    public activate(model) {
        this.controller = model.controller;
    }
}
