import {
  FailoverGroupSlicersResponse,
  FailoverGroupsResponse,
  FailoverGroupSlicer,
  FailoverGroup,
} from '../models/failover-group';

export function getSlicerHotWarmStateDesc(group: FailoverGroup | FailoverGroupSlicer, s: FailoverGroupSlicer) {
  if (!s.enabled) {
    return 'disabled';
  }
  if (!_.get(s, 'status.failover_healthy', false) || s.blacklist_until - new Date().getTime() > 0) {
    return 'unhealthy';
  }
  if (s.id === _.get(group, 'active.id', '')) {
    return 'active';
  }
  if (!s.active) {
    return 'warm';
  }
  return 'hot';
}

export function transformFailoverGroupResponse(data: FailoverGroupSlicersResponse | FailoverGroupsResponse) {
  const {items = []} = data;

  items.forEach(group => {
    const {slicers = []} = group;

    slicers.forEach((slicer: FailoverGroupSlicer) => {
      slicer.hot_warm_state = getSlicerHotWarmStateDesc(group, slicer);
    });
  });

  return data;
}
