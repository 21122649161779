import {Exclude, Expose, Type} from 'class-transformer';
import {
    IsBoolean,
    IsDateString,
    IsDefined,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    MaxLength,
    ValidateIf,
    ValidateNested,
} from 'class-validator';

export enum ALT_CONTENT_TYPE {
    SLATE = 'slate',
    CHANNEL = 'channel',
    ASSET = 'asset',
    SLICER = 'slicer',
}

export const ALT_CONTENT_TYPE_VALUES = Object.values(ALT_CONTENT_TYPE);
export const DEFAULT_DATE_FORMAT = 'MMM D, YYYY @ h:mma';

const MESSAGE_REQUIRED = 'This is required.';
export class Rule {
    @IsString()
    @ValidateIf(o => o.alternate_content_type && o.alternate_content_type !== ALT_CONTENT_TYPE.SLATE)
    @IsNotEmpty({message: MESSAGE_REQUIRED})
    public alternate_content_id?: string;

    @IsBoolean()
    @IsOptional()
    public alternate_content_loop?: boolean;

    @IsEnum(ALT_CONTENT_TYPE)
    public alternate_content_type?: ALT_CONTENT_TYPE;

    @IsString()
    @IsOptional()
    public audience?: string;

    @IsString()
    @IsOptional()
    public external_id?: string;

    @IsString()
    @IsOptional()
    @Exclude({toPlainOnly: true})
    public id?: string;

    @IsDefined({message: MESSAGE_REQUIRED})
    @IsNotEmpty({message: MESSAGE_REQUIRED})
    @IsString()
    public desc: string;

    @IsDateString()
    @IsOptional()
    public created?: string;

    @IsBoolean()
    @IsOptional()
    public all_viewers?: boolean = false;

    // runtime field, need not be serialized
    public selected: boolean = false;
}

export class RulesListResponse {
    @ValidateNested()
    @Type(() => Rule)
    public items?: Rule[];

    @IsNumber()
    public total_items: number;
}

export class RulesMetadata {
    @Expose({name: 'hidden-columns'})
    @MaxLength(25, {each: true})
    public hiddenColumns: string[] = [];
}
