import {bindable, customAttribute, inject, LogManager} from 'aurelia-framework';
import {validate, ValidationError} from 'class-validator';

const log = LogManager.getLogger('validate-custom-attribute');

@customAttribute('validate')
// here using autoinject causes problems with unit tests.
@inject(Element)
export class ValidateCustomAttribute {
    @bindable
    public object: any;

    // need value binding so that onChanage can be called to enable submit button
    // by default input component calls onChange only onBlur.
    @bindable
    public value: any;

    @bindable
    public path: string;

    @bindable
    public pattern: string;

    @bindable
    public servererr: string; // very weird that if this declaration is put below errs, binding does not work

    @bindable
    public errs: ValidationError[];

    @bindable
    public callbacks;

    public id: string;
    public messageId: string;
    public pathArray: string[];

    private element: Element;
    private debouncedOnChange = _.debounce(this.onChange, 250);
    private regExp: RegExp;

    constructor(element) {
        this.element = element;
        this.id = `${Math.floor(Math.random() * 1000000000)}`;
    }

    public valueChanged(newValue) {
        this.debouncedOnChange(newValue);
    }

    public patternChanged(newValue) {
        if (newValue) {
            this.regExp = new RegExp(newValue, 'g');
        }
    }

    public onFocus = () => {
        if (this.callbacks && this.callbacks.onFocus) {
            this.callbacks.onFocus();
        }
        if (this.servererr) return;
        this.removeError();
    };

    public onBlur = () => {
        if (this.callbacks && this.callbacks.onBlur) {
            this.callbacks.onBlur();
        }
        if (this.object.skipValidate || this.servererr) return;
        this.validateField();
    };

    public onKeyUp = () => {
        let valString = `${this.value}`;
        if (this.regExp && valString) {
            valString = (valString.match(this.regExp) || []).join('').toLowerCase();
        }
        this.value = valString;
        if (typeof this.value === 'number') {
            const valNum = Number(valString);
            if (!Number.isNaN(valNum)) {
                this.value = valNum;
            }
        }
    };

    public pathChanged(newValue: string) {
        if (!newValue) throw new Error('path value must not be empty.');
        this.pathArray = newValue.split('.');
    }

    public errsChanged(newValue: ValidationError[]) {
        if (this.servererr) return;
        this.parseErrors(newValue);
    }

    public attached() {
        this.element.addEventListener('focus', this.onFocus);
        this.element.addEventListener('blur', this.onBlur);
        if (this.pattern) {
            this.element.addEventListener('keyup', this.onKeyUp);
        }
    }

    public detached() {
        this.element.removeEventListener('focus', this.onFocus);
        this.element.removeEventListener('blur', this.onBlur);
        this.element.removeEventListener('keyup', this.onKeyUp);
    }

    private addError(err: ValidationError) {
        if (!err) return;
        const formGroup = this.element.closest('.form-group');
        if (!formGroup) {
            return;
        }
        // add the has-error class to the enclosing form-group div
        formGroup.classList.add('form-error');
        const existingErrors = formGroup.querySelectorAll('.form-error__message');
        Array.from(existingErrors).forEach((elem: any) => {
            /* tslint:disable-next-line:no-string-literal */
            elem.style.display = 'none';
        });

        const message = document.createElement('span');
        message.className = 'form-error__message static capitalize-first-char';
        message.textContent = Object.values(err.constraints)[0];
        this.messageId = this.element.getAttribute('id') || this.id;
        message.id = `validation-message-${this.messageId}`;
        formGroup.appendChild(message);
    }

    private removeError() {
        const formGroup = this.element.closest('.form-group');
        if (!formGroup) {
            return;
        }
        const existingErrors = formGroup.querySelectorAll('.form-error__message');
        Array.from(existingErrors).forEach((elem: any) => {
            /* tslint:disable-next-line:no-string-literal */
            elem.style.display = 'inline-block';
        });
        const message = formGroup.querySelector(`#validation-message-${this.messageId}`);
        if (message) {
            formGroup.removeChild(message);
            // remove the has-error class from the enclosing form-group div
            if (formGroup.querySelectorAll('.form-error__message').length === 0) {
                formGroup.classList.remove('form-error');
            }
        }
    }

    private parseErrors(errs: ValidationError[]) {
        if (!errs || !errs.length) {
            this.removeError();
            return;
        }
        const pathArrayCopy = [...this.pathArray];
        const firstLevel = pathArrayCopy.shift();
        let myError: ValidationError = errs.find(err => err.property === firstLevel);
        if (!myError) {
            this.removeError();
            return;
        }
        while (pathArrayCopy.length) {
            const levelName = pathArrayCopy.shift();
            myError = myError.children.find(err => err.property === levelName);
            if (!myError) {
                break;
            }
        }
        this.removeError();
        this.addError(myError);
    }

    private validateField() {
        return validate(this.object, {skipMissingProperties: true})
            .then(errs => {
                this.parseErrors(errs);
            })
            .catch(err => log.error('Validation Error: ', err));
    }

    private onChange(newValue) {
        if (this.callbacks && this.callbacks.onChange) {
            this.callbacks.onChange(newValue);
        }
    }
}
