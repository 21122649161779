import {computedFrom} from 'aurelia-framework';

import * as moment from 'moment';

import {getTzOffset, formatHHmm, formatHHmmss} from 'apps/cms/utils/time-format';

import {IDraggiePosition, IDraggie} from 'resources/timeline/services/draggabilly-service';
import {ITimeEntry} from 'resources/timeline/b-timeline/b-timeline-interfaces';
import {BLOCK_HEIGHT, ZOOM_LEVELS} from 'resources/timeline/b-timeline/b-timeline';

interface IDragMeta {
  position: IDraggiePosition;
  origin: IDraggiePosition;
  slotIndex: number;
  shadowIndex: number;
  start: string;
  width: number;
}

interface IEntryData {
  bottom: number;
  top: number;
  element: HTMLElement;
}

interface ISlotEntry {
  entries: IEntryData[];
  slot: HTMLElement;
}

interface IStepMeta {
  days: number;
  minutes: number;
}

function calcSteps(position: IDraggiePosition, origin: IDraggiePosition, width: number): IStepMeta {
  const x = position.x - origin.x;
  const y = position.y - origin.y;

  return {
    days: x / width,
    minutes: y / (BLOCK_HEIGHT / 2),
  };
}

function isSamePosition(position1, position2) {
  if (!position1 || !position2) {
    return false;
  }

  const {x: x1, y: y1} = position1;
  const {x: x2, y: y2} = position2;

  return x1 === x2 && y1 === y2;
}

function mapSlotEntires(): ISlotEntry[] {
  const slots = document.querySelectorAll('b-timeline-container [slot="entries"]');

  return Array.from(slots).map((slot: HTMLElement) => {
    const els = Array.from(slot.querySelectorAll('b-time-entry > div'));
    const entries = els.map((element: HTMLElement) => {
      const top = parseInt(element.style.top, 10);
      const height = parseInt(element.style.height, 10);

      return {
        element,
        top,
        bottom: top + height,
      };
    });

    return {
      entries,
      slot,
    };
  });
}

function parseTime(zoomLevel, value: string) {
  const zoom = parseInt(zoomLevel.toString(), 10);
  const tzOffset = getTzOffset();

  return zoom === 5 ? formatHHmmss(value, tzOffset) : formatHHmm(value, tzOffset);
}

function removeArtifacts() {
  const shadows = Array.from(document.querySelectorAll('.drag-clone'));

  while (shadows.length) {
    shadows.pop().remove();
  }
}

export class EntryDragHandler {
  public slots: ISlotEntry[];

  private cloned: HTMLElement;
  private element: HTMLElement;
  private shadowed: HTMLElement;

  private start: string;
  private meta: IDragMeta;
  private magnetized: boolean;
  private stepMeta: IStepMeta;

  @computedFrom('start', 'entry.duration')
  private get end() {
    return moment(this.start).add(this.entry.duration, 'seconds').toISOString();
  }

  constructor(private entry: ITimeEntry, private zoomLevel: number, private draggie: IDraggie) {
    const {element, position} = this.draggie;

    removeArtifacts();

    this.element = element;
    this.cloned = this.element.cloneNode(true) as HTMLElement;
    this.shadowed = this.element.cloneNode(true) as HTMLElement;
    this.slots = mapSlotEntires();
    this.start = this.entry.start;

    this.initMeta(position);
    this.createShadow();
    this.shrinkElement();
  }

  /*
   *  Public Methods
   */

  public onMove(event: any) {
    const {position} = this.draggie;

    const stepMeta = calcSteps(position, this.meta.origin, this.meta.width);

    if (this.stepMeta && stepMeta.days === this.stepMeta.days && stepMeta.minutes === this.stepMeta.minutes) {
      return;
    }

    this.stepMeta = {...stepMeta};

    this.moveShadow(event, position);

    if (!this.magnetized) {
      const interval = ZOOM_LEVELS[this.zoomLevel].minutes / 2;
      const minutes = stepMeta.minutes * interval;

      this.start = moment(this.meta.start).add(minutes, 'minutes').add(stepMeta.days, 'days').toISOString();
    }

    this.updateEntryTime();
    this.updateShadowTime();

    // Break memory link
    this.meta.position = {...position};
  }

  public async onEnd(updateEntryCallback: (e: ITimeEntry) => Promise<boolean>) {
    const {position} = this.draggie;

    if (isSamePosition(this.meta.origin, {x: position.x, y: position.y})) {
      this.start = this.meta.start;

      this.updateEntryTime();

      this.removeShadow();
      this.resetElementStyle();

      return false;
    }

    // Hide the element temporarily
    this.element.style.opacity = '0';

    this.draggie.isEnabled = false;

    const updated = await updateEntryCallback(this.entry);

    if (!updated) {
      this.start = this.meta.start;

      this.updateEntryTime();
      this.resetElementStyle();

      this.draggie.isEnabled = true;
    }

    setTimeout(() => {
      this.removeShadow();
    }, 1000);

    return updated;
  }

  /*
   * Private Methods
   */

  private createShadow() {
    const {slot} = this.slots[this.meta.slotIndex];

    if (slot) {
      this.shadowed.style.opacity = '0.5';
      this.shadowed.style.zIndex = '0';
      this.shadowed.classList.add('drag-clone');
      this.shadowed.style.width = 'calc(100% - 30px)';
      this.shadowed.style.left = '30px';

      slot.appendChild(this.shadowed);
    }
  }

  private initMeta(position: IDraggiePosition) {
    const slot: HTMLElement = this.element.closest('[slot="entries"]');
    const slotIndex = this.slots.findIndex(s => s.slot === slot);
    const {start} = this.entry;

    this.meta = {
      slotIndex,
      start,
      origin: {...position},
      position: null,
      shadowIndex: this.slots[slotIndex].entries.findIndex(e => e.top === position.y),
      width: slot.offsetWidth,
    };
  }

  private magnetizeShadow(position: IDraggiePosition, entries: IEntryData[]): boolean {
    const height = parseInt(this.shadowed.style.height, 10);
    const top = position.y;
    const bottom = position.y + height;

    const filtered = entries.filter((_e, i) => i !== this.meta.shadowIndex);
    const bottomIdx = filtered.findIndex(e => bottom >= e.top && bottom < e.bottom);
    const topIdx = filtered.findIndex(e => top >= e.top && top < e.bottom);
    const coveredIdx = filtered.findIndex(e => top < e.top && bottom > e.bottom);

    if (topIdx === -1 && bottomIdx === -1 && coveredIdx === -1) {
      return false;
    }

    let idx;

    if (coveredIdx > -1) {
      idx = coveredIdx;
    } else if (bottomIdx > -1) {
      idx = bottomIdx;
    } else {
      idx = topIdx;
    }

    const entryData = filtered[idx];
    const {element} = entryData;
    const entryMid = parseInt(element.style.top, 10) + parseInt(element.style.height, 10) / 2;

    if (position.y > entryMid) {
      const bottomPx = `${entryData.bottom}px`;

      if (this.shadowed.style.top !== bottomPx) {
        this.shadowed.style.top = `${entryData.bottom}px`;
      }

      const el: HTMLElement = element.closest('b-time-entry');
      this.start = el.dataset.end;
    } else {
      const el: HTMLElement = element.closest('b-time-entry');
      this.shadowed.style.top = `${entryData.top - height}px`;

      this.start = moment(el.dataset.start).subtract(this.entry.duration, 'seconds').toISOString();
    }

    return true;
  }

  private moveShadow(event: any, position: IDraggiePosition): void {
    const idx = this.meta.slotIndex + this.stepMeta.days;
    const {entries, slot} = this.slots[idx];

    if (!slot.contains(this.shadowed)) {
      slot.appendChild(this.shadowed);
    }

    this.magnetized = event.shiftKey ? this.magnetizeShadow(position, entries) : false;

    if (!this.magnetized) {
      this.shadowed.style.top = `${position.y}px`;
    }
  }

  private removeShadow() {
    this.shadowed.remove();
  }

  private resetElementStyle() {
    this.element.style.width = this.cloned.style.width;
    this.element.style.left = this.cloned.style.left;
    this.element.style.top = this.cloned.style.top;
    this.element.style.zIndex = this.cloned.style.zIndex;
    this.element.style.opacity = this.cloned.style.opacity;
    this.element.style.minHeight = this.cloned.style.minHeight;
  }

  private shrinkElement() {
    this.element.style.minHeight = '45px';
    this.element.style.width = '70%';
    this.element.style.left = '30%';
    this.element.style.zIndex = '1000';
  }

  private updateEntryTime() {
    this.entry.start = moment(this.start).toISOString();
    this.entry.startTime = parseTime(this.zoomLevel, this.start);
    this.entry.end = moment(this.end).toISOString();
    this.entry.endTime = parseTime(this.zoomLevel, this.end);
  }

  private updateShadowTime() {
    const block = this.shadowed.querySelector('[data-id="duration-hhmm"] > p');
    block.innerHTML = `${parseTime(this.zoomLevel, this.start)} - ${parseTime(this.zoomLevel, this.end)}`;
  }
}
