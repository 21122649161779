import {IEncodingProfileTableRow} from './table-models';

export class EncodingProfileTableRowFormatter {
  public static format(row: IEncodingProfileTableRow) {
    this.setLabelLiveSlicers(row);
    this.setLabelResolution(row);
    this.setLabelSliceDur(row);
    this.setLabelVodAssets(row);
    this.setPillDesc(row);
    this.setPillRateCardId(row);
    this.setRateCardId(row);
    return row;
  }

  private static setLabelLiveSlicers(row: IEncodingProfileTableRow) {
    row.live_slicers_label = `${row.live_slicers} slicers`;
  }

  private static setLabelResolution(row: IEncodingProfileTableRow) {
    row.resolution = `${row.top_ray_width}x${row.top_ray_height}${row.top_ray_fps ? ` @${row.top_ray_fps}fps` : ''}`;
  }

  private static setLabelSliceDur(row: IEncodingProfileTableRow) {
    row.slicedur_label = `${row.slicedur ? row.slicedur : 4}s`;
  }

  private static setLabelVodAssets(row: IEncodingProfileTableRow) {
    row.vod_assets_label = `${row.vod_assets} assets`;
  }

  private static setPill(
    row: IEncodingProfileTableRow,
    colHeadName: PillColHeadName,
    pillLabel: string,
    pillColor: string = PillColor.SUCCESS,
  ) {
    const labels = row[`${colHeadName}Pill`] ? row[`${colHeadName}Pill`] : [];
    labels.push(pillLabel);
    row[`${colHeadName}Pill`] = labels;
    const colors = row[`${colHeadName}PillColor`] ? row[`${colHeadName}PillColor`] : [];
    colors.push(pillColor);
    row[`${colHeadName}PillColor`] = colors;
    row[`${colHeadName}PillRight`] = true;
    row[`${colHeadName}PillNoWrap`] = true;
  }

  private static setPillDesc(row: IEncodingProfileTableRow) {
    if (row.is_default_live_profile && row.is_default_vod_profile) {
      this.setPill(row, PillColHeadName.DESC, 'Selected Live and VoD Default');
    } else if (row.is_default_live_profile) {
      this.setPill(row, PillColHeadName.DESC, 'Selected Live Default');
    } else if (row.is_default_vod_profile) {
      this.setPill(row, PillColHeadName.DESC, 'Selected VoD Default');
    }
    if (row.deprecated) {
      this.setPill(row, PillColHeadName.DESC, 'Deprecated', PillColor.DANGER);
      row._class = 'bgCritical';
    }
  }

  private static setPillRateCardId(row: IEncodingProfileTableRow) {
    if (row.is_default_system_profile) {
      this.setPill(row, PillColHeadName.RATE_CARD_ID, 'System Default', PillColor.PRIMARY);
    } else if (row.is_default_live_rate_card && row.is_default_vod_rate_card) {
      this.setPill(row, PillColHeadName.RATE_CARD_ID, 'Rate Card Live and VoD Default');
    } else if (row.is_default_live_rate_card) {
      this.setPill(row, PillColHeadName.RATE_CARD_ID, 'Rate Card Live Default');
    } else if (row.is_default_vod_rate_card) {
      this.setPill(row, PillColHeadName.RATE_CARD_ID, 'Rate Card VoD Default');
    }
  }

  private static setRateCardId(row: IEncodingProfileTableRow) {
    row.rate_card_id = row.rate_card_id || 'None';
  }
}

const enum PillColHeadName {
  DESC = 'desc',
  RATE_CARD_ID = 'rate_card_id',
}

const enum PillColor {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  DANGER = 'danger',
}
