import {BetaMode} from 'services/beta-mode';

export class BetaFeatureCustomAttribute {
    public static inject = [Element, BetaMode];

    constructor(public element: HTMLElement, public betaMode: BetaMode) {}

    public valueChanged(value: string | string[]) {
        let betaScopes: string[] = [];
        if (_.isString(value)) {
            betaScopes = value.split(',');
        } else if (_.isArray(value)) {
            betaScopes = value;
        }

        // ensure only non-empty string values are used to compare
        betaScopes = betaScopes.filter(s => _.isString(s) && s.length).map(s => s.trim());

        const hasBetaScopes = _.intersection(betaScopes, this.betaMode.scopes).length > 0;
        if (!this.betaMode.inBetaMode && !hasBetaScopes) {
            this.element.style.display = 'none';
        }
    }
}
