import {autoinject, singleton} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {MetadataSchema} from '../models/metadata';

const URL_PREFIX = '/settings/live-events/metadata-schema/';
const cacheTTL: number = 15000; // 15 second cache TTL

interface ListOptions {
    cache?: boolean | number;
    excludeEmpty?: boolean; // exclude schemas without keys
}

@autoinject()
@singleton()
export class MetadataSchemaService {
    private schemaListCache: MetadataSchema[];
    private cacheTS: number;

    constructor(public acceo: Acceo) {}

    public list({cache, excludeEmpty}: ListOptions = {}): Promise<MetadataSchema[]> {
        const TTL = typeof cache === 'number' ? cache : cacheTTL;
        if (cache && this.schemaListCache && Date.now() - this.cacheTS < TTL) {
            return Promise.resolve(this.schemaListCache);
        }
        return new Promise((resolve, reject) => {
            this.acceo
                .get([MetadataSchema])(`${URL_PREFIX}list`, {
                    prefix: 'schemas',
                })
                .then((schemas: MetadataSchema[]) => {
                    this.schemaListCache = schemas;
                    this.cacheTS = Date.now();
                    resolve(excludeEmpty ? schemas.filter(s => s.keys.length > 0) : schemas);
                })
                .catch(reject);
        });
    }

    public create(schema: MetadataSchema): Promise<MetadataSchema> {
        return this.acceo.post(MetadataSchema)(`${URL_PREFIX}create`, schema, {
            prefix: 'schema',
        });
    }

    public update(schema: MetadataSchema): Promise<MetadataSchema> {
        return this.acceo.post(MetadataSchema)(`${URL_PREFIX}update`, schema, {
            prefix: 'schema',
        });
    }

    public delete(schemaId: string): Promise<DeletedSchema> {
        return this.acceo.delete(DeletedSchema)(`${URL_PREFIX}delete/${schemaId}`);
    }
}

export class DeletedSchema {
    public deleted_schema: string;
}
