import {BindingEngine} from 'aurelia-binding';
import {autoinject} from 'aurelia-framework';

import {InputValidationCustomAttribute} from 'custom-attributes/input-validation';
import {basicSetup} from 'decorators';
import {Notification} from 'resources/notification/service';
import {BUFFERS_KEYS, EDITOR_FIELD_NAMES} from '../models/constants';
import {LiveEventsService} from '../services/live-events-service';

interface Buffers {
    liveEventResumeBuffer: number;
    postHours: number;
    postMinutes: number;
    preHours: number;
    preMinutes: number;
}

@basicSetup
@autoinject()
export class LiveEventsBuffers {
    public unsubscribe;
    public buffersObserver;
    public buffersPristine;
    public isLoading: boolean;
    public isSaving: boolean;
    public liveEvents;
    public subscription;
    public isDataDirty: boolean;
    public maxResumeBuffer: number;
    public maxResumeBufferValidation: number;
    public buffers: Buffers = {
        liveEventResumeBuffer: 0,
        postHours: 0,
        postMinutes: 0,
        preHours: 0,
        preMinutes: 0,
    };

    public validationAttr: InputValidationCustomAttribute;
    public bufferErrorMessage = '';

    constructor(
        public liveEventsService: LiveEventsService,
        public notification: Notification,
        public bindingEngine: BindingEngine,
    ) {}

    public getData() {
        return new Promise((resolve, reject) => {
            this.liveEventsService
                .getLiveEventsSettings()
                .then(res => {
                    this.liveEvents = res || {};
                    BUFFERS_KEYS.forEach(key => {
                        this.buffers[`${_.camelCase(key)}`] = _.get(this.liveEvents, key);
                    });
                    this.maxResumeBuffer = _.get(this.liveEvents, 'live_event_max_resume_buffer');
                    this.maxResumeBufferValidation = this.maxResumeBuffer + 1;
                    this.buffersPristine = _.cloneDeep(this.buffers);
                    resolve();
                })
                .catch(err => {
                    // TODO: show error on the page
                    this.notification.error('Error loading live event buffers');
                    reject(err);
                });
        });
    }

    public validate = (key): void => {
        const v = this.validationAttr.wholeNumValidation(
            _.get(this.buffers, key),
            key === 'liveEventResumeBuffer' ? this.maxResumeBufferValidation : undefined,
        );
        // If there is an error with the buffer, display the appropriate
        // error message to the end user.
        if (!v) {
            if (this.buffers.liveEventResumeBuffer > 5) {
                this.bufferErrorMessage = 'Needs to be less than max resume buffer';
            } else if (this.buffers.liveEventResumeBuffer < 0) {
                this.bufferErrorMessage = 'Needs to be greater than or equal to 0';
            } else {
                this.bufferErrorMessage = 'Needs to be a whole number';
            }
        }
        this.liveEventsService.validate(key, v);
    };

    public saveData(): Promise<any> {
        return new Promise((resolve, reject) => {
            const dataToSend = {...this.liveEvents};

            Object.keys(this.buffers).forEach(key => {
                dataToSend[_.snakeCase(key)] = _.get(this.buffers, key);
            });

            // validate
            this.liveEventsService
                .saveLiveEvents({
                    EDITOR_FIELD_NAMES,
                    ...dataToSend,
                })
                .then(() => {
                    this.buffersPristine = _.cloneDeep(this.buffers);
                    this.notification.success('Changes saved successfully.');
                    this.isDataDirty = false;
                    resolve();
                })
                .catch(e => {
                    const msg = `${e}`;
                    this.notification.error(msg);
                    reject(e);
                });
        });
    }

    public canDeactivate(): void {
        // update live events data cache
        const dataToSend = {...this.liveEvents};
        Object.keys(this.buffers).forEach(key => {
            dataToSend[_.snakeCase(key)] = _.get(this.buffers, key);
        });

        if (this.liveEventsService.cache) {
            this.liveEventsService.cache = dataToSend;
        }
    }

    public get canSave(): boolean {
        const leData = !this.liveEventsService.checkIsEqual(
            this.liveEventsService.dataPristine,
            this.liveEventsService.cache,
        );
        const bufferData = !this.liveEventsService.checkIsEqual(this.buffers, this.buffersPristine);
        return (leData || bufferData) && this.liveEventsService.isValid();
    }
}
