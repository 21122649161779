import {CToastsService} from '@bindable-ui/bindable';

import {asEnvFile, asPemFile} from '../utils/key-download-utils';
import {WorkspaceKey} from '../models/workspace-key';
import {WorkspaceKeyRevision} from '../models/workspace-key-revision';

// eslint-disable-next-line no-shadow
export enum FileFormat {
  ENV = 'env',
  PEM = 'pem',
}

export interface IActions {
  onClose: () => void;
}

export interface IKeyInfoModel {
  model: WorkspaceKey;
  revisionId?: string;
  actions: IActions;
}

export class DialogBase {
  public model: WorkspaceKey;
  public revision: WorkspaceKeyRevision;

  constructor(protected notificationService: CToastsService) {}

  public async copyText(key: string, value: string) {
    try {
      await window.navigator.clipboard.writeText(value);
      this.notificationService.success('Copied to clipboard.');
    } catch (err) {
      this.notificationService.error('Failed to copy to clipboard. Outputting to console');
      // eslint-disable-next-line no-console
      console.log(`${key}: `, value);
    }
  }

  public downloadFile(saveAsType: FileFormat) {
    switch (saveAsType) {
      case FileFormat.ENV:
        asEnvFile(this.model, this.revision);
        break;
      case FileFormat.PEM:
        asPemFile(this.model, this.revision);
        break;
      default:
        throw new Error('Invalid file format');
    }
  }
}
