import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {ProfileResponseItems} from '../models/profile';

@autoinject
export class ProfileService {
    private url: string = '/api/v4/profile';

    constructor(public acceo: Acceo) {}

    public getLiveSlicers(id: string) {
        return this.acceo.get()(`${this.url}/${id}/liveslicers`) as Promise<ProfileResponseItems>;
    }

    public getVodAssets(id: string) {
        return this.acceo.get()(`${this.url}/${id}/vodassets`) as Promise<ProfileResponseItems>;
    }
}
