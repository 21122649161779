import {autoinject} from 'aurelia-framework';
import {ConfigSettingInputType, IConfigSetting} from 'resources/config/models/config-setting';
import {Acceo} from 'services/acceo';
import {AdServerRoute} from '../models/config-route';
import {MacroConfigResponseCollection} from '../models/macroconfig-response-collection';

@autoinject
export class MacroConfigService {
  private url: string = '/api/v4/macroconfig';

  private mockCollection: MacroConfigResponseCollection = {
    items: [
      {
        formElement: 'text',
        key: '[U_ASSET_DESC]',
        info: 'Description for the asset being played',
        input: 'text',
        text: 'Asset Description',
      },
      {
        formElement: 'text',
        key: '[U_ASSET_ID]',
        info: 'Uplynk ID for the asset being played',
        input: 'text',
        text: 'Asset ID',
      },
      {
        formElement: 'number',
        key: '[U_BREAK_DUR_MS]',
        info: 'Used for live only, this macro will return the desired break duration in milliseconds.',
        input: 'number',
        text: 'Break Duration (Milliseconds)',
      },
      {
        formElement: 'number',
        key: '[U_BREAK_DUR_S]',
        info: 'Used for live only, this macro will return the desired break duration in seconds.',
        input: 'number',
        text: 'Break Duration (Seconds)',
      },
      {
        formElement: 'number',
        key: '[U_BREAK_NUM]',
        info: 'Used for live only, this macro will return the current ad break number.',
        input: 'number',
        text: 'Break Number',
      },
      {
        formElement: 'select',
        key: '[U_BREAK_TYPE]',
        info: 'Ad Break Type, applicable to live playback only.',
        input: 'text',
        options: [
          {
            text: 'Preroll',
            value: 'preroll',
          },
          {
            text: 'Midroll',
            value: 'midroll',
          },
          {
            text: 'Postroll',
            value: 'postroll',
          },
        ],
        text: 'Break Type',
      },
      {
        formElement: 'number',
        key: '[U_RANDOM_INT]',
        info: 'This macro will return a random eight digit number.',
        input: 'number',
        text: 'Random Number',
      },
      {
        formElement: 'text',
        key: '[U_SESSION_ID]',
        info: 'This macro will return the current playback session id.',
        input: 'text',
        text: 'Session ID',
      },
      {
        formElement: 'datetime-local',
        key: '[U_TIMESTAMP]',
        info: 'This macro will return the current timestamp.',
        input: 'text',
        text: 'Timestamp',
      },
    ],
  } as MacroConfigResponseCollection;

  constructor(public acceo: Acceo) {}

  public async get() {
    try {
      const response = AdServerRoute.useMockServerResponses
        ? this.mockCollection
        : await this.acceo.get(MacroConfigResponseCollection)(this.url);

      return Promise.resolve(
        response.items
          .filter(item => item.key)
          .map(
            item =>
              ({
                info: item.info,
                formElement: item.formElement as ConfigSettingInputType,
                input: item.formElement as ConfigSettingInputType, // set the input to the formElement
                macro: item.key,
                options: item.options || [],
                text: item.text,
              } as IConfigSetting),
          ),
      );
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
