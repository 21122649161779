export class DownloadsIndex {
  constructor() {
    this.routes = [
      {
        route: [
          '',
          'download-slicer',
        ],
        name: 'downloadSlicer',
        moduleId: PLATFORM.moduleName('./download-slicer'),
        nav: true,
        title: 'Download Slicer',
      },
      // {
      //     route: 'download-player-sdk',
      //     name: 'downloadPlayerSdk',
      //     moduleId: PLATFORM.moduleName('./download-player-sdk'),
      //     nav: true,
      //     title: 'Download Player SDK',
      // },
    ];
  }

  configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }
}
