import {autoinject, BindingEngine, computedFrom, LogManager, observable} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {CTableActions, CToastsService, LocalStorageHelper} from '@bindable-ui/bindable';

import * as moment from 'moment';

import {IContentFilterParams} from 'apps/cms/routes/content/models/models';
import {ContentService} from 'apps/cms/routes/content/services/content';
import {FieldValidation} from 'apps/cms/utils/field-validation';
import {FormValidator} from 'apps/cms/utils/form-validator';
import {
  Asset,
  AssetType,
  // Playlist,
} from 'apps/cms/routes/content/playlists/models';

import {NAV_TABS} from 'apps/cms/routes/live-channels/channels/single/utils/constants';
import {IAssetRow, ISelectOption} from 'apps/cms/routes/live-channels/channels/single/utils/interfaces';

import {IScheduledEntryListParams, ScheduleEntryService} from '../../../services/scheduled-entry';
import {PlaylistService, IPlaylistAssetsTableRow} from '../../../services/schedule-playlist';

import {ChannelModel} from '../../models/channel-model';
import {ConflictResolution, ITrackedScheduledEntry, ScheduleEntry} from '../../models/event-model';

const ADD_BODY = 'add-body';
const log = LogManager.getLogger('playlist-add-body-modal');

export interface IPlaylistAssetRow extends IAssetRow {
  type: AssetType;
}

export interface IPlaylistData {
  adBreakCount: number;
  adBreakDuration: number;
  assetDuration: number;
  rows: IPlaylistAssetRow[];
  totalDuration: number;
}

@autoinject()
export class AddBody {
  @computedFrom('activeTab')
  get conflictsNavState() {
    return this.activeTab === NAV_TABS.CONFLICTS ? 'active' : '';
  }

  @computedFrom(
    'model.start',
    'model.dur',
    'formValidator.errors.end_ro',
    'selectedPlaylist',
    'playlistService.model',
    'playlistData.totalDuration',
  )
  get endTime() {
    if (!this.model) {
      return '';
    }

    const {start, dur} = this.model;

    if (!start) {
      return 'You must select a start time.';
    }

    if (!this.selectedPlaylist || !this.playlistData.totalDuration) {
      return 'Select a Playlist';
    }

    if (this.formValidator && this.formValidator.errors.end_ro) {
      return this.formValidator.errors.end_ro;
    }

    // Add duration to startTime
    const time = moment(start).add(dur, 'milliseconds');

    return time.format(this.timeFormat);
  }

  @computedFrom('playlistService.playlists.length')
  get playlistOptions(): ISelectOption[] {
    const mapped = this.playlistService.playlists.map(p => ({text: p.desc, value: p.id}));

    const options = [
      {
        text: '--Select a Playlist--',
        value: null,
      },
      ...mapped,
    ];

    return options;
  }

  @computedFrom('activeTab', 'model.isNew')
  get selectPlaylistNavState() {
    return this.activeTab === NAV_TABS.SELECT_PLAYLIST ? 'active' : '';
  }

  @computedFrom('formValidator.errors.start', 'model.start', 'model.id', 'selectedPlaylist')
  get startTimeState() {
    if (this.formValidator && this.formValidator.getErrorMsg('start')) {
      return 'error';
    }

    if (this.model && this.model.id && moment(this.model.start).isBefore(moment())) {
      return 'disabled';
    }

    return '';
  }

  @observable public overwriteSchedule: boolean = false;

  public durationDelta: number = 0;

  public NAV_TABS = NAV_TABS;

  public activeTab: number;
  public channelModel: ChannelModel;
  public fieldValidations: any = {};
  public formValidator: FormValidator;

  public playlistActions = {
    onChange: () => {
      setTimeout(() => {
        LocalStorageHelper.save(ADD_BODY, this.selectedPlaylist);
        this.loadPlaylist();
      }, 0);
    },
  };

  public playlistAssetsActions: CTableActions = {
    getColClass: (row, col): string => {
      let cls = col._class || '';

      if (col.colHeadName === 'duration' && row.state === 'slicing') {
        cls += ' bgProcessing';
      }

      return cls.trim();
    },
    getRowClass: row => {
      let cls = row._class || '';

      if (row.state === 'slicing') {
        cls += ' notAllowed';
      }

      return cls.trim();
    },
  };

  public model: ITrackedScheduledEntry;
  public params: IContentFilterParams = {};
  public playerUrl: string;
  public playlistData: IPlaylistData;
  public selectedPlaylist: string;
  public timeFormat: string = 'MM/DD/YYYY HH:mm:ss';

  // Select PLAYLIST Table
  public entriesCols = [
    {
      colHeadName: 'title',
      colHeadValue: 'Title',
      sort: true,
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate'),
    },
    {
      colClass: 't120',
      colHeadName: 'ad_breaks',
      colHeadValue: 'Ad Breaks',
    },
    {
      colClass: 't105',
      colHeadName: 'duration',
      colHeadValue: 'Duration',
      colWidth: 50,
      sort: true,
      view: PLATFORM.moduleName('resources/components/forms/v-duration-or-status/v-duration-or-status.html'),
      viewModel: PLATFORM.moduleName('resources/components/forms/v-duration-or-status/v-duration-or-status'),
    },
  ];

  // Conflicts Table
  public conflictsCols = [
    {
      colHeadName: 'entryName',
      colHeadValue: 'Entry Name',
      sort: true,
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate'),
    },
    {
      colClass: 't105',
      colHeadName: 'type',
      colHeadValue: 'Type',
      sort: true,
    },
    {
      colClass: 't190',
      colHeadName: 'startTime',
      colHeadValue: 'Start Time',
      sort: true,
      valueConverter: 'isoToFormattedMoment',
      valueConverterFormat: this.timeFormat,
    },
  ];

  private defaultFieldValidations = {
    end_ro: [
      new FieldValidation({
        customValidator: value => moment(value).isAfter(moment()),
        message: 'End Time cannot be in the past',
      }),
    ],
    start: [
      new FieldValidation({
        message: 'Start Time is required',
        presence: true,
      }),
      new FieldValidation({
        customValidator: value => moment(value).isValid(),
        message: 'Start Time must be a valid date time',
        passive: true,
      }),
      new FieldValidation({
        customValidator: value => moment(value).isBefore(moment().add(30, 'days')),
        message: 'Start Time cannot be more than 30 days in the future',
      }),
    ],
  };

  private modalController = null;
  private observers = [];
  private channelId: string = null;

  constructor(
    public contentService: ContentService,
    public playlistService: PlaylistService,
    private bindingEngine: BindingEngine,
    private notificationService: CToastsService,
    private scheduleEntryService: ScheduleEntryService,
  ) {}

  /*
        Aurelia Hooks
    */

  public activate(model) {
    const {controller, shared} = model;
    const {entry, channelId} = shared;

    this.modalController = controller;
    this.model = entry;
    this.channelId = channelId;

    this.initModel();
  }

  public attached() {
    this.selectedPlaylist = LocalStorageHelper.loadOrDefault(ADD_BODY, null);

    this.observers = [this.bindingEngine.propertyObserver(this, 'endTime').subscribe(() => this.clearConflicts())];

    this.formValidator = new FormValidator(this.model, this);
    this.formValidator.register({...this.defaultFieldValidations, ...this.fieldValidations}, this.bindingEngine, () =>
      this.isValid(),
    );
  }

  public detached() {
    this.clear();
    this.scheduleEntryService.resetService();
    this.clearConflicts();

    this.contentService.cleanParams();
    this.contentService.resetCurrentModel();
    this.contentService.resetSearch();

    this.model = null;

    this.observers.forEach(observer => {
      try {
        observer.dispose();
      } catch {
        // Do nothing
      }
    });

    this.observers = [];
  }

  public overwriteScheduleChanged() {
    if (this.overwriteSchedule) {
      this.scheduleEntryService.conflictChosenMethod = ConflictResolution.REPLACE;
    } else {
      this.scheduleEntryService.conflictChosenMethod = null;
    }
  }

  /*
        Public Methods
    */

  public isValid(): boolean {
    if (!this.formValidator) {
      return false;
    }
    const formIsValid: boolean = this.formValidator.isValid();
    const isValid: boolean = this.playlistData.rows.length === 0 ? false : formIsValid;

    this.scheduleEntryService.saveEnabled = isValid;

    return isValid;
  }

  public setActiveTab(activeTab) {
    this.activeTab = activeTab;
  }

  public goToPlaylists() {
    window.location.href = '#/content/playlists';
    this.modalController.close();
  }

  public goToPlaylist() {
    window.location.href = `#/content/playlists/${this.selectedPlaylist}`;
    this.modalController.close();
  }

  /*
        Private Methods
    */

  private asIPlaylistAssetRow(asset: IPlaylistAssetsTableRow): IPlaylistAssetRow {
    let adBreaks: number = 0;

    if (asset._type === AssetType.AD) {
      adBreaks = 1;
    } else {
      adBreaks = +(asset.adBreaksTipTriggerText || 0);
    }

    const row: IPlaylistAssetRow = {
      _class: '',
      ad_breaks: adBreaks,
      duration: asset.duration,
      id: asset.id,
      poster_url: asset.poster_url,
      state: 'ready',
      title: asset.title,
      type: asset._type,
    };

    return row;
  }

  private clearConflicts() {
    this.scheduleEntryService.clearConflicts();

    if (this.activeTab === NAV_TABS.CONFLICTS) {
      this.showDefaultTab();
    }
  }

  private initModel() {
    this.selectedPlaylist = LocalStorageHelper.loadOrDefault(ADD_BODY, null);

    if (this.model.isNew) {
      this.initPlaylists();

      this.setActiveTab(NAV_TABS.SELECT_PLAYLIST);
    }

    this.setupScheduleEntryService('Playlist');
  }

  private async initPlaylists() {
    await this.loadPlaylists();
    await this.loadPlaylist();
  }

  private async loadPlaylist() {
    if (this.selectedPlaylist) {
      await this.loadPlaylistData();
    } else if (this.model) {
      const {start} = this.model;
      this.resetModel(start);
      this.clear();
    }
    this.isValid();
  }

  private async loadPlaylistData() {
    try {
      this.playlistData = {
        adBreakCount: 0,
        adBreakDuration: 0,
        assetDuration: 0,
        rows: [],
        totalDuration: 0,
      };

      this.playlistService.isLoading = true;

      const playlistID = this.selectedPlaylist;
      const playlist = await this.playlistService.getPlaylistFromCacheOrLoad(playlistID);

      // eslint-disable-next-line no-multi-assign
      this.playlistService.playlist = this.playlistService.model = playlist;
      this.populateTableData();

      if (!this.model.desc) {
        this.model.desc = this.playlistService.playlist.desc;
      }

      // Don't use await, we don't need to block on loading the player
      this.loadPlayer(playlistID);

      const {assetTableData} = this.playlistService;

      // map playlist data
      const rows = assetTableData.map(asset => this.asIPlaylistAssetRow(asset));
      this.playlistData.rows = rows;

      rows.forEach(r => {
        if (r.type === AssetType.AD) {
          this.playlistData.adBreakDuration += +r.duration;
        }

        if (r.type === AssetType.BEAM) {
          this.playlistData.assetDuration += +r.duration;
        }

        this.playlistData.adBreakCount += +r.ad_breaks;
        this.playlistData.totalDuration += +r.duration;
      });

      this.playlistData.totalDuration *= 1000;
      this.playlistData.adBreakDuration *= 1000;
      this.playlistData.assetDuration *= 1000;
      this.model.dur = this.playlistData.totalDuration;
    } catch {
      this.notificationService.error('Failed to load playlist');
    } finally {
      this.playlistService.isLoading = false;
    }
  }

  /**
   * Load all available playlists for account
   * @param loadMore
   */
  private async loadPlaylists(loadMore = false) {
    try {
      if (loadMore) {
        await this.playlistService.getMorePlaylists();
      } else {
        await this.playlistService.getPlaylists({});
      }

      if (this.playlistService.hasMore) {
        await this.loadPlaylists(true);
        return;
      }

      // this.mapPlaylistOptions(this.playlistService.playlists);
    } catch (ex) {
      log.error(ex);
      this.notificationService.error(`Unable to load playlists: ${ex.message}`);
    }
  }

  /**
   * Gets the player url. Can be used as a non-blocking request
   *
   * @param id
   */
  private async loadPlayer(id) {
    try {
      this.playerUrl = await this.playlistService.getPlayURL(id);
    } catch {
      this.notificationService.warning('Failed to load player.');
    }
  }

  private populateTableData() {
    this.playlistService.assetTableData = [];
    if (!('@included' in this.playlistService.model)) {
      this.playlistService.model['@included'] = [];
    }
    let order = 1;
    this.playlistService.model.playlist.forEach(item => {
      if (item.beam) {
        const asset: Asset = this.playlistService.model['@included'].find(_asset => _asset.id === item.beam.id);
        if (asset) {
          this.playlistService.addAsset(asset, order);
          order += 1;
        }
      }
      if (item.ad) {
        this.playlistService.addAdBreak(item.ad.dur, order);
        order += 1;
      }
    });
  }

  private async loadConflicts() {
    const {start} = this.model;
    const {totalDuration} = this.playlistData;

    const _params: IScheduledEntryListParams = {
      end: moment(start).add(totalDuration, 'milliseconds').toISOString(),
      start: moment(start).toISOString(),
    };

    await this.scheduleEntryService.getConflicts(_params, this.channelId, this.model.id);
    this.setActiveTab(NAV_TABS.CONFLICTS);
  }

  private resetModel(start) {
    Object.assign(this.model, {
      start,
      ad_breaks: null,
      desc: null,
      id: null,
      dur: null,
    });
  }

  private clear() {
    if (this.model) {
      this.model.updateCanonical();
      this.model.dur = 0;
    }

    this.playlistData = {
      adBreakCount: 0,
      adBreakDuration: 0,
      assetDuration: 0,
      rows: [],
      totalDuration: 0,
    };

    this.selectedPlaylist = '';
    this.playerUrl = '';
    this.playlistService.playlist = null;
    this.scheduleEntryService.conflictChosenMethod = null;
    this.overwriteSchedule = false;
  }

  private async saveSchedulePlaylist(closeDialog = true): Promise<boolean> {
    this.formValidator.validate();
    if (!this.isValid()) {
      return false;
    }
    const {start} = this.model;
    const playlist_id = this.selectedPlaylist;

    try {
      const lastEntry: ScheduleEntry = await this.playlistService.schedulePlaylist(
        this.channelId,
        playlist_id,
        this.overwriteSchedule,
        moment(start).toISOString(),
      );

      this.notificationService.success('Saved');
      this.clear();

      if (closeDialog) {
        this.resetModel(moment(start).toISOString());
        this.modalController.close();

        // force update timeline only when dialog is close
        // this has been moved to the popovers base controller to
        // handle updating the timeline if the dialog is cancelled as well
        // this.scheduleEntryService.forceTimelinePoll();
        // this.scheduleEntryService.forceUpdate = new Date().toISOString();
      } else {
        this.clearConflicts();
        this.resetModel(lastEntry.end);
        this.showDefaultTab();
      }
    } catch (ex) {
      log.error(ex);

      if (ex.details && ex.details.length > 0) {
        await this.loadConflicts();
      }

      this.notificationService.error(ex.message);

      return false;
    }

    return true;
  }

  private setupScheduleEntryService(saveText): void {
    this.scheduleEntryService.allowOverwrite = true;
    this.scheduleEntryService.saveCallback = async closeDialog => this.saveSchedulePlaylist(closeDialog);
    this.scheduleEntryService.saveEnabled = false;
    this.scheduleEntryService.saveText = saveText;
  }

  private showDefaultTab() {
    this.setActiveTab(NAV_TABS.SELECT_PLAYLIST);
  }
}
