import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject} from 'aurelia-framework';
import {CLIPPING_METADATA_EVENT} from '.';

@autoinject()
export class MetadataDeleteConfirm {
    public tcDeleteMetadata;

    constructor(public eventAggregator: EventAggregator) {}

    public buttonRemoveIntegration(itemType) {
        this.tcDeleteMetadata.hide();
        this.eventAggregator.publish(CLIPPING_METADATA_EVENT.MetadataDelete, {itemType});
    }
}
