import {computedFrom} from 'aurelia-framework';
import {AdServerDebugItem, Status} from '../models/ad-server-debug-response';

export class JobStatus {
  public status: string;
  public failed_transaction_count: number;
  public transaction_count: number;
  public beacon_count: number;
  public hasWarnings: boolean;
  public job: AdServerDebugItem;

  public activate(model: {value: Status; row: AdServerDebugItem}) {
    this.status = model.value;
    this.job = model.row;
    this.hasWarnings = model.row.hasWarnings;
    // model.row.hasWarnings only set AFTER the user clicks into the details
    this.failed_transaction_count = model.row.failed_transaction_count;
    this.transaction_count = model.row.transaction_count;
    this.beacon_count = model.row.beacon_count;
  }

  @computedFrom('status')
  public get tipBarColor(): string {
    if (this.status === Status.Fail) return 'var(--c_primaryLight)';
    if (this.status === Status.Complete && this.hasWarnings) return 'var(--c_secondaryMain)';
    return 'var(--c_subOneMain)';
  }
}
