import {PLATFORM} from 'aurelia-pal';
import {BetaMode} from 'services/beta-mode';
import {SessionService} from 'services/session';
import {deepCopy} from 'resources/deep-copy';
import {LogManager} from 'aurelia-framework';
import * as Promise from 'bluebird';
import * as Sentry from '@sentry/browser';
import 'whatwg-fetch';
import 'reflect-metadata';
import 'stylesheets/screen.css';
import '@bindable-ui/bindable/dist/native-modules/global-styles/themes/main.css';
import 'resources/global-stylesheets/bindable-to-lynk-theme.css';
import 'resources/global-stylesheets/global.css';
import '@uplynk/lynk-design/dist/themes/dark.css';
import {registerIconLibrary} from '@uplynk/lynk-design/dist/utilities/icon-library';
import {SentryAppender} from 'sentry-log-appender';

import 'plugins/jquery-when-all';

import '@iconify/iconify';

// import 'ts-polyfill';
import 'ts-polyfill/lib/es2015-collection';
import 'ts-polyfill/lib/es2016-array-include';
import 'ts-polyfill/lib/es2017-object';
import 'ts-polyfill/lib/es2017-string';
import 'ts-polyfill/lib/es2018-async-iterable';
import 'ts-polyfill/lib/es2018-promise';
import 'ts-polyfill/lib/es2019-array';
import 'ts-polyfill/lib/es2019-object';
import 'ts-polyfill/lib/es2019-string';
import 'ts-polyfill/lib/es2020-string';

import environment from './environment';

// Get current domain
const domain = window.location.hostname;

// Initialize Sentry
let SENTRY_DSN;
if (environment.environment === 'production' && domain.match(/uplynk\.com/)) {
  SENTRY_DSN = 'https://e259de13d9b84d7ab4b09b7a999fa67c@sentry.uplynk.net/5';
} else {
  SENTRY_DSN = 'https://f77d1c86ea1a4051acaff3a5fdd9b1f9@sentry.dev.inflynk.net/60';
}
Sentry.init({
  dsn: SENTRY_DSN,
});

// Global functions
window.deepCopy = deepCopy;

// Configure default icon library for Lynk icon components.
registerIconLibrary('default', {
  resolver: name => `https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/icons/${name}.svg`,
});

registerIconLibrary('uplynk', {
  resolver: name => `assets/uplynk-svg/${name}.svg`,
  mutator: svg => svg.setAttribute('fill', 'currentColor'),
});

// Configure Bluebird Promises.
// Note: You may want to use environment-specific configuration.
Promise.config({
  longStackTraces: false,
  warnings: {
    wForgottenReturn: false,
  },
});

export function configure(aurelia) {
  aurelia.use
    .standardConfiguration()
    .plugin('aurelia-configuration', config => {
      config.setEnvironments({
        development: [
          'cms.uplynk.localhost',
          'cms.downlynk.localhost',
          'cms.downlynk.me',
        ],
      });
      config.setDirectory(AURELIA_CONFIG_DIR);
    })
    .plugin(PLATFORM.moduleName('analytics'))
    .plugin(PLATFORM.moduleName('aurelia-animator-css'))
    .plugin(PLATFORM.moduleName('aurelia-cookie'))
    .plugin(PLATFORM.moduleName('@bindable-ui/bindable'))
    .plugin(PLATFORM.moduleName('@bindable-ui/bindable-icons'))
    .plugin(PLATFORM.moduleName('aurelia-dialog'))
    .plugin(PLATFORM.moduleName('aurelia-syntax-highlighter'))
    .plugin(PLATFORM.moduleName('aurelia-ui-virtualization'))
    .plugin(PLATFORM.moduleName('aurelia-portal-attribute'))
    .plugin(PLATFORM.moduleName('apps/cms/app'))
    .plugin(PLATFORM.moduleName('apps/cms/ids-notice'))
    .plugin(PLATFORM.moduleName('apps/live-event-dashboard/app'))
    .plugin(PLATFORM.moduleName('apps/esni/app'))
    .plugin(PLATFORM.moduleName('apps/acuity/app'))
    .plugin(PLATFORM.moduleName('apps/dev-docs/app'))
    .plugin(PLATFORM.moduleName('apps/pattern-library/app'))
    .plugin(PLATFORM.moduleName('@uplynk/lynk-design'))
    .plugin(PLATFORM.moduleName('apps/uplynk-studio/app'))
    .globalResources([
      PLATFORM.moduleName('aurelia-mask/masked-input'),

      PLATFORM.moduleName('components/file-uploader/index'),
      PLATFORM.moduleName('components/lazy-loader/index'),
      PLATFORM.moduleName('components/search-stack/index'),
      PLATFORM.moduleName('components/toggle-group/c-toggle-group'),

      PLATFORM.moduleName('custom-attributes/input-validation'),
      PLATFORM.moduleName('custom-attributes/validate'),

      PLATFORM.moduleName('resources/asset-timeline/index'),
      PLATFORM.moduleName('resources/components/videojs-player/index'),
      PLATFORM.moduleName('resources/empty-state/index'),
      PLATFORM.moduleName('resources/loading-spinner/index'),
      PLATFORM.moduleName('resources/modal/modal-placeholder.html'),
      PLATFORM.moduleName('resources/notification/index'),
      PLATFORM.moduleName('resources/select2/select2'),
      PLATFORM.moduleName('resources/tabs/tab-section'),
      PLATFORM.moduleName('resources/tabs/tab-sections'),
      PLATFORM.moduleName('resources/tabs/tabs'),
      PLATFORM.moduleName('resources/timeline/b-time-entry/b-time-entry'),
      PLATFORM.moduleName('resources/timeline/b-timeline-block/b-timeline-block'),
      PLATFORM.moduleName('resources/timeline/b-timeline-container/b-timeline-container'),
      PLATFORM.moduleName('resources/timeline/b-timeline-month-container/b-timeline-month-container'),
      PLATFORM.moduleName('resources/timeline/b-timeline-month-item/b-timeline-month-item'),
      PLATFORM.moduleName('resources/timeline/b-timeline-week-container/b-timeline-week-container'),
      PLATFORM.moduleName('resources/timeline/b-timeline/b-timeline'),
      PLATFORM.moduleName('resources/tip/tip'),
      PLATFORM.moduleName('resources/tip/tip-content'),
      PLATFORM.moduleName('resources/tip/tip-trigger'),
      PLATFORM.moduleName('resources/uplynk-player/uplynk-player'),

      PLATFORM.moduleName('services/authorization/custom-attributes'),
      PLATFORM.moduleName('services/beta-mode/custom-attributes'),

      PLATFORM.moduleName('value-converters/dasherize'),
    ]);

  if (environment.debug) {
    aurelia.use.developmentLogging();
  } else {
    LogManager.setLevel(LogManager.logLevel.error);
  }
  LogManager.addAppender(new SentryAppender());

  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
  }

  aurelia.start().then(a => {
    // Get the Session Server and check and see if the user is authenticated
    const auth = a.container.get(SessionService);
    const betaMode = a.container.get(BetaMode);

    auth
      .isAuthenticated()
      .then(
        response => {
          aurelia.setRoot(PLATFORM.moduleName(`${response}`)); // Expects a string
        },
        error => {
          // This is stored in the index.html (along with the app as well).
          // const login = a.host.attributes.login.value;
          // If there is an error go to the login page (isAuthenticated does this for us)
          aurelia.setRoot(PLATFORM.moduleName(`${error}`)); // Expects a string
        },
      )
      .finally(() => betaMode.setBetaMode());
  });
}
