import {CTableCol, CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {ITrackedLibrary, SimpleOwner} from '../models/models';
import {LibraryService} from '../services/library';

interface ISelectable {
  checkbox: boolean;
  id: string;
  user: string;
}

@autoinject()
export class LibraryDetailsBody {
  /*
   * Computed Properties
   */

  @computedFrom('username', 'usernameError')
  get addUserButtonState() {
    if (this.usernameError) {
      return 'error';
    }

    const username = (this.username || '').trim();

    if (username.length === 0) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('selectedUsers.length')
  get deleteButtonState() {
    return this.selectedUsers.length > 0 ? '' : 'disabled';
  }

  /*
   * Public Properties
   */

  public shared: ITrackedLibrary;
  public sharedLibraryData: ISelectable[] = [];
  public username: string = '';
  public usernameError?: string;
  public usernameFocus: boolean = false;
  public sharedLibraryCols: CTableCol[] = [
    {
      checkChanged: row => this.trackSelectedUser(row),
      colClass: 't30',
      colHeadName: 'checkbox',
      colHeadSelectedChanged: isChecked => this.selectAllUsers(isChecked),
      colHeadSelectedVal: false,
      colHeadValue: '',
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
    },
    {
      colHeadName: 'user',
      colHeadValue: 'User',
      sort: true,
    },
  ];

  public popoverActions = {
    onHide: () => {
      this.usernameFocus = false;
    },
    onShow: () => {
      this.username = null;
      this.usernameError = null;
      this.usernameFocus = true;
    },
  };
  /*
   * Private Properties
   */

  private selectedUsers: string[] = [];

  constructor(private libraryService: LibraryService, private notification: CToastsService) {}

  /*
   * Aurelia hooks
   */

  public activate(model) {
    this.shared = model.shared;

    this.sharedLibraryData = this.getLibraryData(this.shared.shared_owners);
  }

  /*
   * Public Methods
   */

  public async addUser() {
    try {
      this.usernameError = null;
      const username = (this.username || '').trim();

      if (username.length === 0) {
        throw new Error('No username provided');
      }

      const owners = this.sharedLibraryData.map(u => u.user);
      if (owners.includes(username)) {
        throw new Error('Duplicate Username');
      }

      await this.libraryService.addUserToLibrary(this.shared.id, username);

      this.notification.success(`Notification sent to ${username}`);
    } catch (err) {
      this.notification.error(`Error: ${err.message}`);
      this.usernameError = 'Failed to add user';
    }
  }

  public getLibraryData(owners = []) {
    return owners.map(this.mapOwnerRecord);
  }

  public async removeUser() {
    try {
      await this.libraryService.removeUserFromLibrary(this.shared.id, this.selectedUsers);

      const users = this.sharedLibraryData.filter(u => this.selectedUsers.includes(u.id)).map(u => u.user);
      this.sharedLibraryData = this.sharedLibraryData.filter(d => !this.selectedUsers.includes(d.id));

      this.selectedUsers = [];
      this.sharedLibraryCols[0].colHeadSelectedVal = false;

      this.notification.success(`Removed: \n${users.join('\n')}`);
    } catch (err) {
      this.notification.error(err.message);
    }
  }

  public trackSelectedUser(user) {
    if (user.checkbox) {
      this.selectedUsers.push(user.id);
    } else {
      _.remove(this.selectedUsers, userId => userId === user.id);
    }
  }

  /*
   * Private Methods
   */

  private mapOwnerRecord(owner: SimpleOwner): ISelectable {
    return {
      checkbox: false,
      id: owner.id,
      user: owner.username,
    };
  }

  private selectAllUsers(isSelected) {
    this.sharedLibraryData.forEach(u => {
      u.checkbox = isSelected;
    });
    if (isSelected) {
      this.selectedUsers = this.sharedLibraryData.map(u => u.id);
    } else {
      this.selectedUsers = [];
    }
  }
}
