/* eslint-disable max-classes-per-file */
import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom} from 'aurelia-framework';

import {IsArray, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class ProfileDetails {
    /*
     * Computed Properties
     */
    @computedFrom('state')
    get searchState() {
        return this.state === 'searching' ? 'disabled' : '';
    }

    /*
     * Private Properties
     */
    public selectedOwner;
    private state: string = 'idle';
    public selectedValue: string = '';
    public detailCols = [
        {
            colHeadName: 'ray',
            colHeadValue: 'Ray',
        },
        {
            colHeadName: 'max_w',
            colHeadValue: 'Max W',
        },
        {
            colHeadName: 'max_h',
            colHeadValue: 'Max H',
        },
        {
            colHeadName: 'v_kbps',
            colHeadValue: 'vKbps',
        },
        {
            colHeadName: 'a_kbps',
            colHeadValue: 'aKbps',
        },
    ];

    // Owner search specifics
    private username: string = '';
    private owners: any[] = [];
    public defaultProfile;
    public defaultLiveProfile;
    public mappedProfiles: any[] = [];
    public hasMappings: boolean;

    // Profile search specifics
    private profile: string = '';
    private profile_list: any[] = [];
    private profileSelected;

    // Asset search specifics
    private asset: string = '';
    private asset_list: any[] = [];
    private assetSelected;

    // Channel search specifics
    private channel: string = '';
    private channel_list: any[] = [];
    private channelSelected;

    constructor(private acceo: Acceo, private notification: CToastsService, public dialogService: DialogService) {}

    /*
     * Public Methods
     */
    public async searchUsername() {
        this.state = 'searchingOwners';
        this.selectedValue = '';
        if (!this.username) {
            this.username = '';
            this.state = 'idle';
            this.notification.error('No email/username entered.');
        } else {
            const url: string = '/ops/owners';
            const params = $.param({username: this.username, account_state: 'active'});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.owners = resp.owners;
                if (this.owners.length !== 0) {
                    this.state = this.owners.length ? 'hasData' : 'not-found';
                    this.selectedAccount(0);
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching owner for ${this.username}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedAccount(ownerIndex) {
        this.selectedValue = this.owners[ownerIndex].username;
        this.selectedOwner = this.owners[ownerIndex];
        this.defaultProfile = this.selectedOwner.default_profile;
        this.defaultLiveProfile = this.selectedOwner.default_live_profile;
        this.state = 'accountDataSelected';
        this.hasMappings = false;
        if (Object.entries(this.selectedOwner.slicer_profiles).length !== 0) {
            this.hasMappings = true;
            this.mappedProfiles = this.selectedOwner.slicer_profiles;
        }
    }

    // Profile search functions
    public async searchProfile() {
        this.state = 'searchingProfiles';
        this.selectedValue = '';
        if (!this.profile) {
            this.profile = '';
            this.state = 'idle';
            this.notification.error('No profile ID or Desc entered');
        } else {
            const url: string = '/ops/profiles';
            const params = $.param({query: this.profile});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.profile_list = resp.profiles;
                if (this.profile_list.length !== 0) {
                    this.state = this.profile_list.length ? 'hasData' : 'not-found';
                    this.selectedProfile(0);
                } else {
                    this.state = 'idle';
                    this.notification.error(`Couldn't find matching profile for ${this.profile}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedProfile(profileIndex) {
        this.profileSelected = this.profile_list[profileIndex];
        this.selectedValue = this.profileSelected.desc;
        this.state = 'profileDataSelected';
    }

    // Asset search functions
    public async searchAsset() {
        this.state = 'searchingAssets';
        this.selectedValue = '';
        if (!this.asset) {
            this.asset = '';
            this.state = 'idle';
            this.notification.error('No asset description, id, or external id entered');
        } else {
            const url: string = '/ops/assets';
            const params = $.param({query: this.asset, profile: true});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.asset_list = resp.assets;
                if (this.asset_list.length !== 0) {
                    this.state = this.asset_list.length ? 'hasData' : 'not-found';
                    this.selectedAsset(0);
                } else {
                    this.state = 'idle';
                    this.notification.error(`Couldn't find matching asset for ${this.profile}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedAsset(assetIndex) {
        this.assetSelected = this.asset_list[assetIndex];
        this.selectedValue = this.assetSelected.desc;
        this.state = 'assetDataSelected';
    }

    // Channel search functions
    public async searchChannel() {
        this.state = 'searchingChannels';
        this.selectedValue = '';
        if (!this.channel) {
            this.channel = '';
            this.state = 'idle';
            this.notification.error('No channel description, id, or external id entered');
        } else {
            const url: string = '/ops/channels';
            const params = $.param({query: this.channel, profile: true});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.channel_list = resp.channels;
                if (this.channel_list.length !== 0) {
                    this.state = this.channel_list.length ? 'hasData' : 'not-found';
                    this.selectedChannel(0);
                } else {
                    this.state = 'idle';
                    this.notification.error(`Couldn't find matching channel for ${this.profile}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedChannel(channelIndex) {
        this.channelSelected = this.channel_list[channelIndex];
        this.selectedValue = this.channelSelected.desc;
        this.state = 'channelDataSelected';
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsArray()
    public owners: any[] = [];

    @IsArray()
    public profiles: any[] = [];

    @IsArray()
    public assets: any[] = [];

    @IsArray()
    public channels: any[] = [];
}

export class KeysValueConverter {
    public toView(obj) {
        if (obj !== null && typeof obj === 'object') {
            return Reflect.ownKeys(obj).filter(x => x !== '__observers__');
        }
        return null;
    }
}
