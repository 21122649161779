// import {inject, bindable, TaskQueue, computedFrom} from 'aurelia-framework';
import {bindable, computedFrom} from 'aurelia-framework';
import * as dragula from 'dragula';
import {Util} from 'services/util';
import {DropdownValue} from '../models/clipping-config';

export class DragDrop {
    @bindable
    public data: DropdownValue[];

    @bindable
    public metaDataId: string;

    @bindable
    public callbacks;

    public drake;
    public _dragDropList = [];
    public dataUpdate: Date = new Date();

    public attached() {
        if (this.metaDataId === '-1') {
            this.metaDataId = Util.generateGuid();
        }
        this.setupDragDrop();
    }

    public detached() {
        if (this.drake) {
            this.drake.destroy();
            this.drake = null;
        }
    }

    public setupDragDrop() {
        if (this.drake) {
            this.drake.destroy();
            this.drake = null;
        }

        this.drake = dragula([document.getElementById(this.listFilterId)], {
            delay: 200,
            moves: ({}, {}, handle) => handle.classList.contains('td-drag'),
            revertOnSpill: false,
        });

        // this was the original way, but it caused an issue when drag/drop from the first position
        // to outside.
        // this.drake.on('drop', (el, { }, { }, sibling) => {
        //     const fromIndex = _.findIndex(this.data, filter => filter.key === el.getAttribute('data-name'));
        //     const toIndex = _.findIndex(this.data, filter => filter.key === sibling.getAttribute('data-name'));

        //     if (fromIndex >= 0 && toIndex >= 0 && fromIndex !== toIndex) {
        //         const movedFilter = this.data[fromIndex];
        //         this.data.splice(fromIndex, 1);
        //         this.data.splice(toIndex, 0, movedFilter);
        //         this.callbacks.onChange();
        //     }
        // });

        this.drake.on('drop', ({}, target) => {
            const dropOrder = [];
            const newData: DropdownValue[] = [];
            [].forEach.call(target.children, li => {
                dropOrder.push(li.getAttribute('data-name'));
            });

            dropOrder.forEach(el => {
                const foundItem = _.find(this.data, {key: el}) as DropdownValue;
                const newItem = new DropdownValue();
                newItem.key = foundItem.key;
                newItem.value = foundItem.value;
                newData.push(newItem);
            });

            this.data.splice(0, this.data.length);

            newData.forEach(el => {
                this.data.push(el);
            });

            this.callbacks.onChange();
        });
    }

    get listFilterId() {
        return `id-${this.metaDataId}`;
    }

    public deleteDropDownKey(event, values, keyvalue) {
        event.cancelBubble = true;
        const index = values.indexOf(keyvalue);
        values.splice(index, 1);
        this.dataUpdate = new Date();
        this.callbacks.onChange();
    }

    @computedFrom('data', 'dataUpdate')
    get dragDropList() {
        if (!this.data) {
            return [];
        }

        return this.data;
    }
}
