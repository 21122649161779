import {autoinject, observable} from 'aurelia-framework';
import {computedFrom} from 'aurelia-binding';
import {SessionService} from 'services/session';
import {Router} from 'aurelia-router';

@autoinject()
export class SwitchAccountIndex {
  public accountData: any;
  public data: any;
  public emailSearch: string = '';

  @observable
  public isLoading: boolean = true;

  public searchInput: any;
  public usersList: any[] = [];

  constructor(public session: SessionService, public router: Router) {}

  public activate(model) {
    this.accountData = model;
    this.loadSwitchUsersListData();
  }

  /**
   * This initializes the user list that is displayed to the end user.
   */
  public initializeDataProperties() {
    this.usersList = this.data.users;
  }

  /**
   * This calls the API to get the list of channels.  It then selects the
   * first channel in the list as the default channel.
   */
  public loadSwitchUsersListData() {
    this.accountData.getSwitchUsersListP().then(data => {
      this.data = data;
      this.initializeDataProperties();
      this.isLoading = false;
    });
  }

  /**
   * This handles the click when the user selects the user from the list.
   */
  public handleClick(user) {
    window.localStorage.removeItem('LiveEvents');
    return this.accountData.doSwitchUserP(user.username, user.id).then(response => {
      if (response) {
        this.router.navigateToRoute(this.router.currentInstruction.config.name);
        window.location.reload();
      }
    });
  }

  public getFilteredList() {
    const newValue = this.emailSearch.replace(/\+/g, '\\+');
    const regex = new RegExp(newValue, 'i');
    return this.usersList.filter(
      item =>
        (item.username !== this.session.username && !!item.username.toLowerCase().match(regex)) ||
        !!item.id.match(regex),
    );
  }

  /**
   * This is executed when the user presses the enter key.  We will only proceed if there is only
   * one user in the filtered list.
   */
  public doSwitchAccount() {
    const filteredList = this.getFilteredList();
    if (filteredList && filteredList.length === 1) {
      this.handleClick(filteredList[0]);
    }
  }

  /**
   * This gets our filtered list based on what is in the emailSearch property
   */
  @computedFrom('emailSearch')
  public get filteredUserList() {
    return this.getFilteredList();
  }

  public async isLoadingChanged() {
    if (!this.isLoading && this.searchInput) {
      this.searchInput.updateComplete.then(() => {
        this.searchInput.focus();
      });
    }
  }
}
