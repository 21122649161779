import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationError} from 'class-validator';
import {MULTI_CDN_SETTINGS_CONFIG_EVENT} from '../index';
import {MultiCdnSettingsConfig, RuleSet} from '../models/multi-cdn-settings-config';
import {MultiCdnUtil} from '../services/cdn-util';

export const MULTI_CDN_SETTINGS_SCENARIO_DELETE_EVENT = {
  ScenarioDelete: 'MultiCdnSettingsScenarios:Delete',
};

@autoinject()
export class MultiCdnSettingsRuleSets {
  @bindable
  public multiCdnSettings: MultiCdnSettingsConfig;

  @bindable
  public validationErrors: ValidationError[];

  @bindable
  public callbacks;

  @bindable
  public initialError;

  public currentRuleSet;

  public subscriptions: Subscription[] = [];
  public deleteTip;
  public hasError = false;
  public newRuleSetName: string = '';
  public tcNewRuleSet;
  public ruleSetIsDuplicate: boolean = false;
  public ruleSetNameRequired: boolean = false;

  constructor(public eventAggregator: EventAggregator, public router: Router) {}

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }

  public attached() {
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        MULTI_CDN_SETTINGS_CONFIG_EVENT.NewConfigSaved,
        () =>
          new Promise(resolve => {
            resolve();
          }),
      ),
    );

    if (!this.currentRuleSet && this.multiCdnSettings && this.multiCdnSettings.ruleSets) {
      this.currentRuleSet = this.multiCdnSettings.ruleSets[0];
    }
  }

  public setCurrentConfig(ruleSet) {
    // this is called by config nav item select or by new config create event.
    this.currentRuleSet = ruleSet;
  }

  public deleteRuleSet() {
    let ruleSetToDelete;
    let deletedIndex = -1;
    let nextIndex = -1;
    let deletedRuleSetName = '';

    for (const ruleSet of this.multiCdnSettings.ruleSets) {
      deletedIndex += 1;
      if (ruleSet.name === this.currentRuleSet.name) {
        ruleSetToDelete = ruleSet;
        deletedRuleSetName = ruleSet.name;
      }
    }
    const index = this.multiCdnSettings.ruleSets.indexOf(ruleSetToDelete);
    this.multiCdnSettings.ruleSets.splice(index, 1);

    if (this.multiCdnSettings.ruleSets.length) {
      if (deletedIndex === this.multiCdnSettings.ruleSets.length) {
        nextIndex = deletedIndex - 1;
      } else {
        nextIndex = deletedIndex;
      }
      this.setCurrentConfig(this.multiCdnSettings.ruleSets[nextIndex]);
    } else {
      this.currentRuleSet = null;
    }

    this.eventAggregator.publish(MULTI_CDN_SETTINGS_SCENARIO_DELETE_EVENT.ScenarioDelete, {
      deletedScenarioName: deletedRuleSetName,
    });
    this.callbacks.onChange();
  }

  public createNewRuleSet() {
    this.ruleSetNameRequired = false;
    this.ruleSetIsDuplicate = false;
    if (this.newRuleSetName.trim() === '') {
      this.ruleSetNameRequired = true;
      return;
    }

    for (const item of this.multiCdnSettings.ruleSets) {
      if (item.name === this.newRuleSetName.trim()) {
        this.ruleSetIsDuplicate = true;
        return;
      }
    }

    this.ruleSetIsDuplicate = false;
    const ruleSet: RuleSet = new RuleSet();
    ruleSet.name = this.newRuleSetName.trim();
    this.currentRuleSet = ruleSet;
    this.multiCdnSettings.ruleSets.push(ruleSet);

    // clear out the values & close the tip
    this.ruleSetIsDuplicate = false;
    this.ruleSetNameRequired = false;
    this.newRuleSetName = '';
    this.tcNewRuleSet.hide();
    this.callbacks.onChange();
  }

  get isDefaultRuleSet() {
    return this.currentRuleSet != null && this.currentRuleSet.name === MultiCdnUtil.defaultRuleSetName;
  }
}
