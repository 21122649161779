/* eslint-disable max-len */
import {computedFrom} from 'aurelia-framework';

import {BaseEventSingle} from './base';

export class Slicers extends BaseEventSingle {
    constructor(...baseTargets) {
        super(...baseTargets);

        // State
        this.isAdding = false;
        // this.isSaving = false;
        this.conflicts = [];
        this.errors = [];
        this.slicers = [];

        // this.bindingEngine = bindingEngine;

        this.keyPress = e => {
            if (e.which === 13) {
                // Make sure the document body is the active element
                if (
                    $(document.activeElement)[0].localName === 'body' &&
                    this.liveEvents.sessionWrite &&
                    this.newSlicer.id
                ) {
                    this.addSlicer();
                }
            }
            return true;
        };

        this.resetNewSlicer();
    }

    attached() {
        // There is a race condition with `this.liveEvents.sessionWrite`
        // so this is an easy way of making sure it has data before trying to get
        // the slicer list
        this.sessionWriteSubscription = this.bindingEngine
            .propertyObserver(this.liveEvents, 'sessionWrite')
            .subscribe(newValue => this.getSlicerPool(newValue));

        this.getSlicerPool(this.liveEvents.sessionWrite);

        document.addEventListener('keypress', this.keyPress);
    }

    detached() {
        this.sessionWriteSubscription.dispose();
        document.removeEventListener('keypress', this.keyPress);
    }

    /**
     * Get slicer pool
     */
    getSlicerPool(sessionWrite) {
        if (sessionWrite && !this.slicers.length) {
            this.liveEvents
                .getSlicerPool()
                .then(slicers => {
                    this.slicers = slicers;
                })
                .catch(() => {});
        }
    }

    /**
     * Reset newSlicer state
     */
    resetNewSlicer() {
        // New Slicer model.
        this.newSlicer = {
            id: '',
        };
    }

    /* ---------------------------------------------------------------------- *\
     * Computed Properties
    \* ---------------------------------------------------------------------- */
    @computedFrom(
        'liveEvents.conflicts',
        'liveEvents.tabs.liveEventSlicers.markedForDeletion',
        'liveEvents.tabs.liveEventSlicers.markedForDeletion.length',
    )
    get tableData() {
        this.liveEvents.fields.slicers.isDirty = !!this.liveEvents.tabs.liveEventSlicers.markedForDeletion.length;

        return this.liveEvents.conflicts.map(slicer => {
            const index = this.liveEvents.tabs.liveEventSlicers.markedForDeletion.findIndex(id => id === slicer.id);

            if (index > -1) {
                slicer.isDeleted = true;
            } else {
                slicer.isDeleted = false;
            }

            return slicer;
        });
    }

    /* ---------------------------------------------------------------------- *\
     * Helper Methods
    \* ---------------------------------------------------------------------- */

    /**
     * Determine if `model.slicers` is dirty.
     */
    slicersIsDirty() {
        if (!this.liveEvents.origEvent || !this.model) {
            return;
        }

        const isDirty = JSON.stringify(this.liveEvents.origEvent.slicers) !== JSON.stringify(this.model.slicers);

        // Update field `isDirty` state.
        this.liveEvents.fields.slicers.isDirty = isDirty;
    }

    validateSlicer(slicer) {
        let isValid = true;

        // Clear errors.
        this.errors = [];

        // Required field.
        if (!slicer.id.length) {
            this.errors.push('Please enter a Slicer ID.');
            isValid = false;
        }

        // Unique value.
        if (this.model.slicers.find(s => s.id === slicer.id)) {
            this.errors.push('Slicer already assigned to this event.');
            isValid = false;
        }

        return isValid;
    }

    /* ---------------------------------------------------------------------- *\
     * Actions
    \* ---------------------------------------------------------------------- */

    /**
     * Adds copy of `newSlicer` to `model.slicers` before clearing `newSlicer`.
     */
    addSlicer() {
        const slicer = deepCopy(this.newSlicer);

        if (!this.validateSlicer(slicer)) {
            this.notification.error('Please correct your form errors.');
            return;
        }

        this.resetNewSlicer();

        this.isAdding = true;

        // check to see if the slicer is in the pool
        if (this.slicers.indexOf(slicer.id) === -1) {
            const newSlicerModel = {
                header: 'New Slicer Warning',
                question: `You selected a slicer name that is not in your group of live slicers.
                    Would you like to add it to your pool of slicers for future use?`,
                yes: 'Yes',
                no: 'No',
                hideCancel: true,
                footerClass: 'btn-split',
            };
            this.dialogService
                .open({
                    viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
                    model: newSlicerModel,
                })
                .whenClosed(mresp => {
                    if (mresp.output === true) {
                        this.slicers.push(slicer.id);
                        this.liveEvents.updateSlicerPool(this.slicers).then(slicers => {
                            this.slicers = slicers;
                        });
                    }
                });
        }

        this.liveEvents.addSlicer(slicer.id).finally(() => {
            this.isAdding = false;
        });

        this.slicersIsDirty();
    }

    /**
     * Toggles if slicer is marked for deletion on save.
     */
    toggleMarkForDelete(slicer) {
        const deleteIndex = this.liveEvents.tabs.liveEventSlicers.markedForDeletion.findIndex(id => id === slicer.id);

        if (deleteIndex > -1) {
            this.liveEvents.tabs.liveEventSlicers.markedForDeletion.splice(deleteIndex, 1);
        } else {
            this.liveEvents.tabs.liveEventSlicers.markedForDeletion.push(slicer.id);
        }
    }

    /**
     * Handles transition to conflicting event's route.
     *
     * @param {Object} conflict
     */
    clickOnConflict(conflict) {
        if (this.liveEvents.eventIsDirty && this.liveEvents.sessionWrite) {
            const dirtyModel = {
                header: 'Are you sure you want to leave?',
                question: 'There are unsaved changes. If you leave before saving, your changes will be lost.',
                yes: 'Yes, Discard My Changes',
                hideNo: true,
                cancel: 'Cancel',
                footerClass: 'btn-split',
            };
            this.dialogService
                .open({
                    viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
                    model: dirtyModel,
                })
                .whenClosed(response => {
                    if (!response.wasCancelled) {
                        this.liveEvents.isLeavingNewRoute = true;
                        this.liveEvents.checkDirty = false;
                        this.liveEvents.origEvent = null;
                        this.router.navigateToRoute('liveEventSingle', {id: conflict.id});
                    }
                });
        } else {
            this.liveEvents.origEvent = null;
            this.router.navigateToRoute('liveEventSingle', {id: conflict.id});
        }
    }
}
