import {inject} from 'aurelia-framework';

import {Event} from '../event';

@inject(Event)
export class HeaderStatus {
    constructor(event) {
        this.event = event;
    }
}
