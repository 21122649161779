import {IsEmail, IsNotEmpty, IsNumber, IsOptional, IsString, MaxLength, MinLength} from 'class-validator';

export class AccountSettings {
  @IsNotEmpty()
  @IsString()
  @MinLength(3)
  @MaxLength(40)
  public username: string;

  @IsNotEmpty()
  @IsString()
  public ownerID: string;

  @IsOptional()
  @IsString()
  public ids_id?: string;

  @IsOptional()
  @IsString()
  public ids_domain_id?: string;

  @IsOptional()
  @IsEmail()
  public ids_email?: string;

  @IsOptional()
  @IsString()
  @MinLength(4)
  public ids_username?: string;
}

export class UpdateEmailResponse {
  @IsString()
  @IsOptional()
  public new_email?: string;

  @IsString()
  @IsOptional()
  public new_username?: string;

  @IsNumber()
  @IsOptional()
  public error?: number;

  @IsOptional()
  @IsString()
  public msg?: string;
}
