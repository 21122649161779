import {autoinject, singleton} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {AdServerDebugItem} from '../models/ad-server-debug-item';

@autoinject()
@singleton()
export class AdServerDebugService {
    constructor(public acceo: Acceo) {}

    public getAdDebugItems(adDebugName: string): Promise<AdServerDebugItem[]> {
        return new Promise((resolve, reject) => {
            const postData = `item=${encodeURIComponent(adDebugName)}`;
            this.acceo
                .post([AdServerDebugItem])('/settings/ad-config/debug/', postData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    prefix: 'items',
                })
                .then((debugItems: AdServerDebugItem[]) => resolve(debugItems))
                .catch(reject);
        });
    }
}
