import {Type} from 'class-transformer';
import {IsArray, IsInt, IsString, ValidateNested} from 'class-validator';
import {AdServerType} from '../../ad-server/models/ad-config';

export class AdConfigResponseItem {
  @IsArray()
  public warnings: string[];

  @IsArray()
  public errors: string[];

  @IsString()
  public name: string;

  @IsString()
  public provider: string;

  // save the providerLabel to avoid the need to look this up
  public providerLabel: string;

  @IsString()
  public serverURL: string;

  @IsString()
  public type: AdServerType;

  // save the typeLabel to avoid the need to look this up
  public typeLabel: string;

  @IsInt()
  public valid: number;
}

export class AdConfigResponseCollection {
  @ValidateNested()
  @Type(() => AdConfigResponseItem)
  public items: AdConfigResponseItem[];
}
