import {CTableActions, generateRandom, IDragOptions, sortDropData} from '@bindable-ui/bindable';
import * as CTableCssJson from '@bindable-ui/bindable/dist/native-modules/components/tables/c-table/c-table.css.json';
import {PLATFORM} from 'aurelia-framework';
import {TextFile} from 'resources/file/text-file';
import {TableStorage} from 'resources/table-storage/table-storage';
import {CTableColSelectable, CTableRowBehavior, CTableRowSelectable} from './c-table-selectable-models';

export class CTableSelectable {
  public get colsSelectable(): CTableColSelectable[] {
    return this._colsSelectable;
  }

  public set colsSelectable(value: CTableColSelectable[]) {
    this._colsSelectable = value;
    this.trackSelectedCol();
  }

  public actions: CTableActions = {};
  public cols: CTableColSelectable[] = [];
  public colsSelected: CTableColSelectable[] = [];
  public rows: CTableRowSelectable[] = [];
  public rowsSelected: string[] = [];
  protected rowSelectionColIdx = 0;
  protected styleJsonKeys = Object.keys(CTableCssJson);
  protected styleJsonValues = Object.values(CTableCssJson);
  protected _colsSelectable: CTableColSelectable[];
  public tableStorage = new TableStorage();

  constructor(protected rowBehavior: CTableRowBehavior) {
    const colCheck: CTableColSelectable = {
      _class: '',
      checkChanged: this.trackSelectedRow.bind(this),
      colClass: this.getClass(CTableCssJson.t30),
      colHeadName: 'selected',
      colHeadSelectedChanged: this.selectAllRows.bind(this),
      colHeadSelectedVal: false,
      colHeadValue: 'Select',
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
    };
    const colDrag = {
      _class: 'drag',
      colClass: this.getClass(CTableCssJson.t30),
      colHeadName: 'drag',
      colHeadValue: '',
      getDragOptions: this.dragOptions,
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-drag/c-td-drag.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-drag/c-td-drag'),
    };
    const colDragCheck = {
      _class: 'dragCheck',
      checkChanged: this.trackSelectedRow.bind(this),
      colClass: this.getClass(CTableCssJson.t30),
      colHeadName: 'selected',
      colHeadSelectedChanged: this.selectAllRows.bind(this),
      colHeadSelectedVal: false,
      colHeadValue: 'Select',
      getDragOptions: this.dragOptions,
      view: PLATFORM.moduleName(
        '@bindable-ui/bindable/components/tables/td-contents/c-td-drag-check/c-td-drag-check.html',
      ),
      viewModel: PLATFORM.moduleName(
        '@bindable-ui/bindable/components/tables/td-contents/c-td-drag-check/c-td-drag-check',
      ),
    };
    const colRadio: CTableColSelectable = {
      _class: '',
      colClass: this.getClass(CTableCssJson.t30),
      colHeadName: 'selected',
      radioChanged: this.trackSelectedRadio.bind(this),
      radioName: generateRandom(),
      radioSelected: undefined,
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-radio/c-td-radio.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-radio/c-td-radio'),
    };

    switch (rowBehavior) {
      case CTableRowBehavior.CHECK:
        this.cols = [colCheck];
        break;
      case CTableRowBehavior.DRAG:
        this.cols = [colDrag];
        break;
      case CTableRowBehavior.DRAG_AND_CHECK:
        this.cols = [
          colDrag,
          colCheck,
        ];
        this.rowSelectionColIdx = 1;
        break;
      case CTableRowBehavior.DRAG_CHECK:
        this.cols = [colDragCheck];
        break;
      case CTableRowBehavior.RADIO:
        this.cols = [colRadio];
        break;
      default:
        break;
    }
  }

  public addColsFromRows(colsIgnore: string[], selectedByDefault: boolean, sortable: boolean) {
    const colsOfficial = this.colsSelectable.map(col => col.colHeadName);
    const colsUnofficial: string[] = [];
    this.rows.forEach(row => {
      Object.keys(row).forEach(key => {
        if (colsOfficial.indexOf(key) === -1 && colsIgnore.indexOf(key) === -1) {
          colsUnofficial.push(key);
        }
      }, this);
    }, this);
    _.uniq(colsUnofficial)
      .sort()
      .forEach(key => {
        this.colsSelectable.push({
          colHeadName: key,
          colHeadValue: key,
          selected: selectedByDefault,
          sort: sortable,
        });
      }, this);
    this.trackSelectedCol();
  }

  public dragOptions(row: CTableRowSelectable): IDragOptions {
    return {
      dragData: event => {
        $(event.target).closest('TR').css('opacity', 0.4);
        return row;
      },
      getDragTarget: target => $(target).closest('TR').get(0),
      onDragEnd: event => {
        $(event.target).closest('TR').css('opacity', 1.0);
        return false;
      },
    };
  }

  public dropzoneActions(row: CTableRowSelectable) {
    return {
      onDragEnter(event) {
        $(event.target).closest('TR').css('border', '2px dashed red');
      },
      onDragLeave(event) {
        $(event.target).closest('TR').css('border', '');
      },
      onDrop: (event, data) => {
        $(event.target).closest('TR').css('border', '');
        this.rows = sortDropData(data, row, this.rows, 'order');
      },
    };
  }

  public exportCSV(name: string = 'table') {
    const cols = this.colsSelected.filter(col => col.colHeadName !== 'selected');
    let fileData = cols.map(col => `"${col.colHeadValue}"`).join(',');
    // eslint-disable-next-line no-return-assign
    this.rows.forEach(row => (fileData = `${fileData}\n${cols.map(col => `"${row[col.colHeadName]}"`).join(',')}`));
    TextFile.save(new File([encodeURIComponent(fileData)], `${name}.csv`, {type: 'text/csv'}));
  }

  public selectAllRows(isSelected: boolean) {
    const selectableRows = this.rows.filter(row => row.selectedState !== 'disabled');
    selectableRows.map(row => {
      row.selected = isSelected;
      return row;
    });
    this.rowsSelected = selectableRows.filter(row => row.selected).map(row => row.id);
    this.cols[this.rowSelectionColIdx].colHeadSelectedVal = selectableRows.length === this.rowsSelected.length;
  }

  public trackSelectedCol(col?: CTableColSelectable) {
    const selectedCols = this.tableStorage.getSelectedColumns();
    const colsRequested = this.cols.concat(this.colsSelectable.filter(column => column.selected));
    if (colsRequested.length > 0) {
      this.colsSelected = colsRequested;
      this.colsSelected.forEach(column => {
        if (selectedCols[column.colHeadName]) {
          column.selected = selectedCols[column.colHeadName];
        }
      });
      this.tableStorage.saveSelectedColumns(this.colsSelected);
    } else if (col) {
      col.selected = true;
    }
  }

  public removeSelectedRadio() {
    this.cols[this.rowSelectionColIdx].radioSelected = undefined;
    this.trackSelectedRadio();
  }

  protected getClass(value: string) {
    return this.styleJsonKeys[this.styleJsonValues.indexOf(value)];
  }

  protected rowClickRadio(row) {
    if (row.selectedState !== 'disabled') {
      this.cols[this.rowSelectionColIdx].radioSelected = row.id;
      this.trackSelectedRadio();
    }
  }

  protected trackSelectedRadio() {
    this.rowsSelected = [this.cols[this.rowSelectionColIdx].radioSelected];
  }

  protected trackSelectedRow() {
    this.rowsSelected = this.rows.filter(row => row.selected && row.selectedState !== 'disabled').map(row => row.id);
    this.cols[this.rowSelectionColIdx].colHeadSelectedVal =
      this.rows.filter(row => row.selectedState !== 'disabled').length === this.rowsSelected.length;
  }
}
