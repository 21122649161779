import {autoinject, LogManager} from 'aurelia-framework';
import {Acceo} from 'services/acceo';

import {IPaginationMeta, IHyperionFilterParams} from 'interfaces/hyperion';

import {transformFailoverGroupResponse} from './utils';

import {DEFAULT_PER_PAGE_LIMIT} from '../../live-channels/models/defaults';

import {FailoverGroup, FailoverGroupEventLog, FailoverGroupsResponse} from '../models/failover-group';

const log = LogManager.getLogger('failover-group-service');

const DEFAULT_ORDER = 'desc';

const URL_BASE = '/api/v4/groups';

interface IFailoverGroupParams extends IHyperionFilterParams {
  active?: boolean;
  channels?: boolean;
  slicers?: boolean;
  thresholds?: boolean;
}

@autoinject()
export class FailoverGroupService {
  public meta: IPaginationMeta = {};
  public params: IFailoverGroupParams;

  public order: string = DEFAULT_ORDER;

  public isProcessing: boolean = false;
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;

  public groups: FailoverGroup[] = [];
  public searchResultGroups: FailoverGroup[] = [];

  constructor(public acceo: Acceo) {}

  public async addFailoverGroup(createParams: object): Promise<FailoverGroup> {
    if (this.isProcessing) {
      return null;
    }

    this.isProcessing = true;

    let res = null;
    try {
      res = await this.acceo.post(FailoverGroup)(URL_BASE, createParams, {
        requestTransform: params => JSON.stringify(params),
      });

      this.groups.unshift(res);

      this.meta.total += 1;
      this.meta.showing = this.groups.length;
    } catch (err) {
      log.error(err);
      throw err;
    } finally {
      this.isProcessing = false;
    }

    return res;
  }

  public async getMore() {
    const params = _.cloneDeep(this.params);
    // delete params.since;

    return this.getFailoverGroups('', params, true);
  }

  public async getFailoverGroups(
    groupId: string = '',
    params: IFailoverGroupParams = null,
    isLoadMore: boolean = false,
  ): Promise<FailoverGroup[]> {
    if (this.isLoading || this.isLoadingMore) {
      return this.groups;
    }

    if (!groupId && !params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (!params.order) {
      params.order = this.order;
    }

    if (params.order !== this.order) {
      this.order = params.order;
    }

    if (!isLoadMore) {
      this.isLoading = true;
      this.groups = [];
      delete params.page;
    } else {
      if (!this.meta.hasMore) {
        return this.groups;
      }

      const {page = 1, page_size} = params;
      const {total = 0} = this.meta;

      if (page * page_size >= total) {
        return this.groups;
      }

      this.isLoadingMore = true;
      params.page = (params.page || 1) + 1;
    }

    let groups = [];
    this.params = _.cloneDeep(params);

    try {
      const url = `${URL_BASE}/${groupId}?${$.param(params)}`;
      const res = await this.acceo.get(FailoverGroupsResponse)(url, {
        responseTransform: transformFailoverGroupResponse,
      });

      groups = res.items;

      if (!groupId) {
        this.groups = _.uniqBy(this.groups.concat(groups), 'id');
        groups = this.groups;

        this.meta.total = res.total_items;
        this.meta.showing = groups.length;
        this.meta.limit = params.page_size || null;
        this.meta.hasMore = groups.length < res.total_items;
      }
    } catch (err) {
      log.error(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }

    return groups;
  }

  public async getFailoverGroupUpdates(ids: any, params: any) {
    const res = await this.acceo.post(FailoverGroupsResponse)(
      `${URL_BASE}/updates?${$.param(params)}`,
      {ids},
      {
        responseTransform: transformFailoverGroupResponse,
      },
    );

    return res.items;
  }

  public getFailoverGroupEvents(groupId: string = ''): Promise<FailoverGroupEventLog[]> {
    return this.acceo.get([FailoverGroupEventLog])(`${URL_BASE}/events/${groupId}`, {prefix: 'items'});
  }

  public processPollData(updates: FailoverGroupsResponse): boolean {
    if (!updates || updates.total_items === 0) {
      return false;
    }

    const {items = []} = updates;

    items.forEach(i => this.updateRecord(i));

    this.groups = _.uniqBy(this.groups.concat(), 'id');

    return true;
  }

  public async pollRecords(ids: string[], params: IFailoverGroupParams): Promise<FailoverGroupsResponse> {
    if (ids.length === 0) {
      return null;
    }

    const pollArgs = _.cloneDeep(params);
    delete pollArgs.page_size;
    delete pollArgs.page;
    delete pollArgs.order;
    delete pollArgs.include_deleted;

    return this.acceo.post(FailoverGroupsResponse)(
      `${URL_BASE}/updates?${$.param(pollArgs)}`,
      {ids},
      {
        responseTransform: transformFailoverGroupResponse,
      },
    );
  }

  public async removeFailoverGroup(id: any): Promise<any> {
    try {
      this.isProcessing = true;
      const resp = await this.acceo.delete()(`${URL_BASE}/${id}`);

      this.removeRecord(id);

      return resp;
    } catch (err) {
      log.error(err);
      throw err;
    } finally {
      this.isProcessing = false;
    }
  }

  /**
   * Search the groups
   *
   * @param searchQuery Optional search term that comes from the sidebar
   */
  public async searchGroups(searchQuery: string = '', params: any = {}) {
    this.meta = {};

    if (searchQuery) {
      params.search = searchQuery;
    }

    params.page = 1;

    return this.getFailoverGroups('', params);
  }

  public async updateFailoverGroup(groupId: string, params: object): Promise<any> {
    return this.acceo.patch()(`${URL_BASE}/${groupId}`, params);
  }

  /* ********************* */
  /*    Private Methods    */
  /* ********************* */

  /**
   * Updates or adds a record to the keys collection
   * @param record
   */
  private addOrUpdateRecord(record: FailoverGroup): void {
    const index = this.groups.findIndex(k => k.id === record.id);

    if (index < 0) {
      this.meta.total += 1;
      this.meta.showing += 1;
      this.groups.unshift(record);
    } else {
      this.groups[index] = record;
    }
  }

  /**
   * Removes record from keys collection if found
   * @param record
   */
  private removeRecord(recordId: string) {
    const index = this.groups.findIndex(k => k.id === recordId);

    if (index < 0) {
      return;
    }

    this.meta.total -= 1;
    this.meta.showing -= 1;
    this.groups.splice(index, 1);
  }

  /**
   * Updates record in keys collection
   * @param record
   */
  private updateRecord(record: FailoverGroup) {
    if (record.deleted) {
      this.removeRecord(record.id);
    } else {
      this.addOrUpdateRecord(record);
    }
  }
}
