import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {RouteConfig, Router, RouterConfiguration} from 'aurelia-router';
import {dirtyCheckPrompt} from 'decorators';
import {LiveEventsService} from './services/live-events-service';

export const LIVE_EVENTS_ACTIONS = {
    BUFFER_UPDATE: 'LIVE_EVENTS_ACTIONS:BUFFER_UPDATE',
    GENERAL_UPDATE: 'LIVE_EVENTS_ACTIONS:GENERAL_UPDATE',
    SLICER_UPDATE: 'LIVE_EVENTS_ACTIONS:SLICER_UPDATE',
};

interface General {
    autoAssetExpiry: number;
    autoAssetExpiryOption: number;
    postSlateDuration: number;
    postSlateDurationOption: number;
    vodAssetExpiry: number;
    vodAssetExpiryOption: number;
    liveEventRequireTesting: boolean;
    liveEventSecretType: number;
    liveEventWebhook: string | null;
}

interface Buffer {
    liveEventResumeBuffer: number;
    postHours: number;
    postMinutes: number;
    preHours: number;
    preMinutes: number;
}

interface Slicers {
    // live event slicers
    slicerPool: string[];
    // account slicers
    availableSlicers: string[];
}

@dirtyCheckPrompt
@autoinject()
export class LiveEvents {
    public general: General = {
        autoAssetExpiry: 0,
        autoAssetExpiryOption: -1,
        liveEventRequireTesting: false,
        liveEventSecretType: 0,
        liveEventWebhook: null,
        postSlateDuration: 0,
        postSlateDurationOption: -1,
        vodAssetExpiry: 0,
        vodAssetExpiryOption: -1,
    };

    public buffer: Buffer = {
        liveEventResumeBuffer: 0,
        postHours: 0,
        postMinutes: 0,
        preHours: 0,
        preMinutes: 0,
    };

    public slicers: Slicers = {
        availableSlicers: [],
        slicerPool: [],
    };

    public liveEvents = {
        ...this.general,
        ...this.buffer,
        ...this.slicers,
    };

    public isLoading: boolean;
    public routes: RouteConfig[];
    constructor(public liveEventsService: LiveEventsService, public router: Router) {
        this.router = router;

        this.routes = [
            {
                moduleId: PLATFORM.moduleName('./general/index'),
                name: 'general',
                nav: true,
                route: ['', 'general'],
                title: 'General',
            },
            {
                moduleId: PLATFORM.moduleName('./buffers/index'),
                name: 'buffers',
                nav: true,
                route: 'buffers',
                title: 'Buffers',
            },
            {
                moduleId: PLATFORM.moduleName('./slicers/index'),
                name: 'slicers',
                nav: true,
                route: 'slicers',
                title: 'Slicers',
            },
            {
                moduleId: PLATFORM.moduleName('./metadata/index'),
                name: 'metadata',
                nav: true,
                route: 'metadata',
                title: 'Metadata',
            },
            {
                moduleId: PLATFORM.moduleName('./marker-templates/index'),
                name: 'marker-templates',
                nav: true,
                route: 'marker-templates',
                title: 'Marker Templates',
            },
        ];
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        this.router = router;
        config.map(this.routes);
    }

    public tabClick(row) {
        this.liveEventsService.checkDirty = false;
        this.router.navigateToRoute(row.config.name);
    }

    public isDirty(): boolean {
        return this.liveEventsService.isCacheDirty();
    }

    public onDirtyPromptLeave() {
        this.liveEventsService.clearCache();
    }
}
