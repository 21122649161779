import {autoinject, computedFrom, containerless, observable} from 'aurelia-framework';

import {BasePlaylistSingle} from '../base';

@autoinject()
@containerless()
/* istanbul ignore next */
export class PlaylistDetails extends BasePlaylistSingle {
  public tabFields: string[] = [
    'desc',
    'beam_break_duration',
    'ad_slate_fill',
    'repeat',
    'active',
  ];

  @observable public adSlateRadio: string;
  @observable public repeatRadio: string;
  @observable public activeRadio: string;

  public async activate(params) {
    await super.activate(params);
    this.adSlateRadio = `ad_slate_fill_${this.model.ad_slate_fill}`;
    this.repeatRadio = `repeat_${this.model.repeat}`;
    this.activeRadio = `active_${this.model.active}`;
  }

  public adSlateRadioChanged(newVal: string) {
    this.model.ad_slate_fill = parseInt(newVal.split('_').pop(), 10);
  }

  public activeRadioChanged(newVal: string) {
    this.model.active = parseInt(newVal.split('_').pop(), 10);
  }

  public repeatRadioChanged(newVal: string) {
    this.model.repeat = parseInt(newVal.split('_').pop(), 10);
  }

  @computedFrom('descDirty', 'beamBreakDirty', 'adSlateFillDirty', 'repeatDirty', 'activeDirty')
  get tabDirty() {
    return this.descDirty || this.beamBreakDirty || this.adSlateFillDirty || this.repeatDirty || this.activeDirty;
  }

  private genericDirty(field) {
    /* istanbul ignore if */
    if (!this.playlist || !this.model) {
      return false;
    }
    const isDirty: boolean = this.playlist[field] !== this.model[field];
    this.fields[field].isDirty = isDirty;
    return isDirty;
  }

  @computedFrom('model.desc')
  get descDirty() {
    return this.genericDirty('desc');
  }

  @computedFrom('model.beam_break_duration')
  get beamBreakDirty() {
    /* istanbul ignore if */
    if (!this.playlist || !this.model) {
      return false;
    }
    const isDirty: boolean = _.toNumber(this.model.beam_break_duration) !== this.playlist.beam_break_duration;
    this.fields.beam_break_duration.isDirty = isDirty;
    return isDirty;
  }

  @computedFrom('model.ad_slate_fill')
  get adSlateFillDirty() {
    return this.genericDirty('ad_slate_fill');
  }

  @computedFrom('model.active')
  get activeDirty() {
    return this.genericDirty('active');
  }

  @computedFrom('model.repeat')
  get repeatDirty() {
    return this.genericDirty('repeat');
  }
}
