import {bindable} from 'aurelia-framework';

export interface ISearchActions {
  onClear: () => void;
  onLoadMore?: () => void;
  onSearch: (value: string) => void;
  onSelect: (value: string) => void;
}

export interface ISearchableItem {
  value: string;
  text: string;
  disabled?: boolean;
}

export class SearchStack {
  @bindable
  public actions: ISearchActions;

  @bindable
  public hideDisabled: boolean = false;

  @bindable
  public isLoading: boolean = false;

  @bindable
  public isLoadingMore: boolean = false;

  @bindable
  public items: ISearchableItem[] = [];

  @bindable
  public searchPlaceholder: string = '';

  @bindable
  public selected: Set<string> = new Set<string>();

  public onClear() {
    this.actions.onClear();
  }

  public onLoadMore() {
    if (this.actions.onLoadMore) {
      this.actions.onLoadMore();
    }
  }

  public onSearch(value) {
    this.actions.onSearch(value);
  }

  public onSelect({dataset}) {
    this.actions.onSelect(dataset.id);
  }
}

export default SearchStack;
