import {ConfigErrors as ConfigErrorsBase} from 'resources/config/import/models/config-errors';

export class ConfigErrorsMacro {
  public key: string;
  public value: string;
}

export class ConfigErrors extends ConfigErrorsBase {
  public macro = new ConfigErrorsMacro();
  public name: string;
  public type: string;
}
