import {autoinject, LogManager, computedFrom} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';

import {Acceo} from 'services/acceo';

import {IHyperionFilterParams, IPaginationMeta} from 'interfaces/hyperion';
import {SessionService} from 'services/session';
import {IToggleGroup} from 'components/toggle-group/c-toggle-group';

import {
  WorkspaceKeyResponse,
  WorkspaceKey,
  IWorkspaceKeyParams,
  IWorkspaceKeyUpdatableParams,
  WorkspaceKeyCreateResponse,
} from '../models/workspace-key';
import {IWorkspaceRevisionParams} from '../models/workspace-key-revision';

export const WORKSPACE_KEY_URL: string = '/api/v4/workspaces';

// eslint-disable-next-line no-shadow
export enum RevisionAction {
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export interface IRevisionAction {
  /**
   * Revision ID
   */
  id: string;

  /**
   * Revision action
   */
  action: RevisionAction;
}

const DEFAULT_PER_PAGE_LIMIT = 20;
const DEFAULT_ORDER = '-created';

@autoinject()
export class WorkspaceKeyService {
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public isProcessing: boolean = false;
  public meta: IPaginationMeta = {};
  public params: IHyperionFilterParams = {};
  public searchQuery: string;
  public searchQueryApplied: string;
  public selected: string[] = [];

  public keys: WorkspaceKey[] = [];
  public workspaceId: string = '';
  public scopes: IToggleGroup[];

  private _key: WorkspaceKey;

  protected logger: Logger;

  @computedFrom('workspaceId')
  public get url() {
    return `${WORKSPACE_KEY_URL}/${this.workspaceId}/keys`;
  }

  @computedFrom('key')
  public get hasKeyInfo() {
    return !!this.key;
  }

  @computedFrom('params.order')
  public get order() {
    return this.params?.order || DEFAULT_ORDER;
  }

  public get key(): WorkspaceKey {
    return this._key;
  }

  constructor(protected acceo: Acceo, private sessionService: SessionService) {
    this.logger = LogManager.getLogger('Workspace Key Service');
    this.scopes = this.sessionService.apiScopes as any;
  }

  /* ******************** */
  /*    Public Methods    */
  /* ******************** */

  public clearKey(): void {
    this._key = null;
  }

  /**
   * Creates new api key
   * @param params
   */
  public async createKey(params: IWorkspaceKeyParams): Promise<WorkspaceKey> {
    if (this.isProcessing) {
      return null;
    }

    let record: WorkspaceKey;

    try {
      this.isProcessing = true;

      const newParams = _.cloneDeep(params);
      const keys = Object.keys(newParams);

      keys.forEach(key => {
        if (newParams[key].length === 0) {
          delete newParams[key];
        }
      });

      record = await this.acceo.post(WorkspaceKey)(this.url, newParams);

      this._key = record;
    } catch (err) {
      this.logger.error(err);
      throw new Error(err.message);
    } finally {
      this.isProcessing = false;
    }

    return record;
  }

  /**
   * Creates a revision on key
   * @param id
   * @param params
   */
  public async createRevision(id: string, params: IWorkspaceRevisionParams): Promise<WorkspaceKey> {
    if (this.isProcessing) {
      return null;
    }

    let record: WorkspaceKey;

    try {
      this.isProcessing = true;

      const url = `${this.url}/${id}`;

      record = await this.acceo.put(WorkspaceKey)(url, params);

      this._key = record;
    } catch (err) {
      this.logger.error(err);
      throw new Error(err.message);
    } finally {
      this.isProcessing = false;
    }

    return record;
  }

  /**
   * Delete workspace key
   * @param id
   */
  public async deleteKey(id: string): Promise<void> {
    if (this.isProcessing) {
      return;
    }

    try {
      await this.acceo.delete(WorkspaceKey)(`${this.url}/${id}`);

      const record = this.keys.find(k => k.id === id);

      if (record) {
        this.removeRecord(record);
      }
    } catch (err) {
      this.logger.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Retrieve workspace key
   * @param id
   * @param isPolling
   */
  public async getKey(id: string, isPolling: boolean = false): Promise<WorkspaceKey> {
    let record: WorkspaceKey;

    if (!isPolling) {
      this.isLoading = true;
    }

    try {
      record = await this.acceo.get(WorkspaceKey)(`${this.url}/${id}`);
    } catch (err) {
      this.logger.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }

    return record;
  }

  /**
   * Retrieve workspace keys
   * @param params
   * @param isLoadingMore
   */
  public async getKeys(params: IHyperionFilterParams = {}, isLoadingMore: boolean = false): Promise<any> {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (this.searchQuery) {
      params.search = this.searchQuery;
    }

    if (!params.order) {
      params.order = DEFAULT_ORDER;
    }

    if (!isLoadingMore) {
      this.keys = [];
      this.isLoading = true;
      params.page = 1;
    } else {
      if (!this.meta.hasMore) {
        return;
      }

      this.isLoadingMore = true;
      params.page = (params.page || 1) + 1;
    }

    this.params = _.cloneDeep(params);

    try {
      const urlParams = $.param(this.params);

      const url = `${this.url}?${urlParams}`;
      const resp = await this.acceo.get(WorkspaceKeyResponse)(url);

      this.keys = _.uniqBy(this.keys.concat(resp.items), 'id');

      // Safety check to prevent query spamming
      const totalPages = Math.ceil(resp.total_items / params.page_size);

      this.meta.total = resp.total_items;
      this.meta.showing = this.keys.length;
      this.meta.limit = params.page_size || null;
      this.meta.hasMore = params.page < totalPages;
    } catch (err) {
      this.logger.error(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  /**
   * Loads more records
   */
  public async getMore() {
    if (this.isLoading || this.isLoadingMore || !this.meta.hasMore) {
      return;
    }

    const params = _.cloneDeep(this.params);

    await this.getKeys(params, true);
  }

  /**
   * Performs action on revisions
   * @param kid -- workspace key id
   * @param ids -- List of revision ids to perform action on
   * @param action -- activate/deactivate
   */
  public async performRevisionAction(kid: string, ids: string[], action: RevisionAction): Promise<void> {
    if (this.isProcessing) {
      return;
    }

    try {
      this.isProcessing = true;

      const revisions: IRevisionAction[] = ids.map(id => ({id, action}));

      const resp = await this.acceo.patch(WorkspaceKeyCreateResponse)(`${this.url}/${kid}`, {revisions});

      if (resp?.errors?.length > 0) {
        throw new Error(resp.errors.join('\n'));
      }
    } catch (err) {
      this.logger.error(err);
      throw new Error(err.message);
    } finally {
      this.isProcessing = false;
    }
  }

  /**
   * Poll workspace keys for changes
   * @param params
   */
  public async pollRecords(params): Promise<WorkspaceKeyResponse> {
    const pollArgs = _.cloneDeep(params);
    delete pollArgs.page_size;
    delete pollArgs.page;
    delete pollArgs.order;

    const urlParams = $.param(pollArgs);
    const url = `${this.url}?${urlParams}`;

    return this.acceo.get(WorkspaceKeyResponse)(url);
  }

  /**
   * Processes changes from polling
   * @param changes
   */
  public processPollData(changes: WorkspaceKeyResponse): boolean {
    if (!changes || changes.total_items === 0) {
      return false;
    }

    const {items = []} = changes;

    items.forEach(i => this.updateRecord(i));
    this.keys = _.uniqBy(this.keys.concat(), 'id');

    return true;
  }

  /**
   * Update workspace key
   * @param kid - Workspace key id
   * @param params - Params to update
   */
  public async updateKey(kid: string, params: IWorkspaceKeyUpdatableParams): Promise<WorkspaceKey> {
    if (this.isProcessing) {
      return null;
    }

    let record: WorkspaceKey;

    try {
      this.isProcessing = true;
      return await this.acceo.patch(WorkspaceKey)(`${this.url}/${kid}`, params);
    } catch (err) {
      this.logger.error(err);
    } finally {
      this.isProcessing = false;
    }

    return record;
  }

  /* ********************* */
  /*    Private Methods    */
  /* ********************* */

  /**
   * Updates or adds a record to the keys collection
   * @param record
   */
  private addOrUpdateRecord(record: WorkspaceKey): void {
    const index = this.keys.findIndex(k => k.id === record.id);

    if (index < 0) {
      this.meta.total += 1;
      this.meta.showing += 1;
      this.keys.unshift(record);
    } else {
      record.selected = this.keys[index].selected;
      this.keys[index] = record;
    }
  }

  /**
   * Removes record from keys collection if found
   * @param record
   */
  private removeRecord(record: WorkspaceKey) {
    const index = this.keys.findIndex(k => k.id === record.id);

    if (index < 0) {
      return;
    }

    this.meta.total -= 1;
    this.meta.showing -= 1;
    this.keys.splice(index, 1);
  }

  /**
   * Updates record in keys collection
   * @param record
   */
  private updateRecord(record: WorkspaceKey) {
    if (record.deleted) {
      this.removeRecord(record);
    } else {
      this.addOrUpdateRecord(record);
    }
  }
}
