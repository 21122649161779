import {CTipActions} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, PLATFORM} from 'aurelia-framework';
import {Config as SlicerConfig} from 'resources/config/config';
import {ConfigEditor} from 'resources/config/import/editor';
import {Config} from 'resources/config/import/models/config';
import {ConfigImportMode, ConfigImportSource} from 'resources/config/import/models/config-import';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {TemplatesService} from '../../services/template-service';
import {Config as SharedModel} from '../list/modal/models';

@autoinject
export class MSSConfigEditor extends ConfigEditor {
  public config: Config;
  public templatesService: TemplatesService;
  public tipImport: any;
  public tipImportActions: CTipActions = {
    onHide: () => {
      if (this.config.state.importRequested) {
        switch (this.config.input.import.source) {
          case ConfigImportSource.FILE:
            this.config.input.table.importString(
              this.config.input.import.config.exportString(),
              this.config.input.import.mode,
            );
            break;
          case ConfigImportSource.TEMPLATE:
            this.config.input.table.importString(
              JSON.stringify(this.config.input.import.template.config),
              this.config.input.import.mode,
            );
            break;
          default:
            break;
        }
      }
      this.config.state.importRequested = false;
    },
    onShow: () => {
      this.config.state.importRequested = false;
      this.config.errors.import.fileValue = undefined;
      this.config.errors.import.config = undefined;
      this.config.errors.import.template = undefined;
      this.config.input.import.fileValue = '';
      this.config.input.import.mode = ConfigImportMode.APPEND;
      this.config.input.import.config = new SlicerConfig();
      this.config.input.import.source = ConfigImportSource.FILE;
      this.config.input.import.template = undefined;

      const uploadedfile = $('#uploadedfile');
      uploadedfile.val('');
    },
  };

  constructor(
    protected acceo: Acceo,
    protected dialogService: DialogService,
    protected sessionService: SessionService,
  ) {
    super();
    this.templatesService = new TemplatesService(acceo);
  }

  public async actionImport() {
    if (!this.actionImportValidate()) return;
    this.config.state.importRequested = true;
    this.tipImport.hide();
  }

  public async actionImportTemplate() {
    const model = new SharedModel();
    this.config.input.import.template = model.input.selection;
    this.dialogService
      .open({
        model: {
          bodyViewModel: PLATFORM.moduleName('apps/cms/routes/slicers/templates/list/modal/body'),
          footerEnable: true,
          sharedModel: model,
          size: 'medium',
          title: 'Template Selection',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed(() => {
        this.actionImportValidate();
      });
  }

  public async onChangeConfig(files: FileList) {
    this.config.errors.import.fileValue = !this.config.input.import.fileValue ? 'File is required' : undefined;
    this.config.errors.import.config = undefined;
    if (this.config.errors.import.fileValue) return;
    try {
      await this.config.input.import.config.importFile(files[0], false);
      this.config.input.import.config.validate();
    } catch (error) {
      this.config.errors.import.config = error;
    }
  }

  protected actionImportValidate() {
    switch (this.config.input.import.source) {
      case ConfigImportSource.FILE:
        this.config.errors.import.template = undefined;
        this.config.errors.import.fileValue = !this.config.input.import.fileValue ? 'File is required' : undefined;
        break;
      case ConfigImportSource.TEMPLATE:
        this.config.errors.import.fileValue = undefined;
        this.config.errors.import.template = !this.config.input.import.template.name
          ? 'Template is required'
          : undefined;
        break;
      default:
        break;
    }
    return (
      !this.config.errors.import.fileValue && !this.config.errors.import.config && !this.config.errors.import.template
    );
  }
}
