import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
// import {TextFile} from 'resources/file/text-file';
import {AdConfigResponse} from '../models/adconfig-response';
import {AdConfig, AdServerType} from '../../ad-server/models/ad-config';
import {AdConfigService as LegacyService} from '../../ad-server/services/ad-config-service';
import {AdConfigRequest} from '../models/adconfig-request';
import {AdConfigResponseCollection, AdConfigResponseItem} from '../models/adconfig-response-collection';
import {AdConfigResponseDelete} from '../models/adconfig-response-delete';
import {TestResponseCollection} from '../models/test-response-collection';

@autoinject
export class AdConfigService {
  private legacyService: LegacyService;
  private url: string = '/api/v4/adconfig';

  constructor(public acceo: Acceo) {
    this.legacyService = new LegacyService(acceo);
  }

  public delete(pristineName: string) {
    return this.acceo.delete(AdConfigResponseDelete)(`${this.url}/${pristineName}`);
  }

  public async get() {
    return this.acceo.get(AdConfigResponseCollection)(this.url);
  }

  public async getByName(name: string) {
    return this.acceo.get(AdConfigResponse)(`${this.url}/${name}`);
  }

  public async patchLegacy(request: AdConfig) {
    return this.legacyService.createOrUpdateAdConfig(request, false);
  }

  public async patch(pristineName: string, request: AdConfigRequest) {
    return this.acceo.patch(AdConfigResponseItem)(`${this.url}/${pristineName}`, request);
  }

  public async post(configType: AdServerType, name: string, values: object) {
    if (configType !== AdServerType.UniversalAdConfig) {
      const request = new AdConfig();
      Object.assign(request, values);
      request.name = name;
      request.type = configType;
      return this.legacyService.createOrUpdateAdConfig(request, true);
    }
    const request = new AdConfigRequest();
    request.values = values;
    Object.assign(request.values, {
      name,
      type: configType,
    });
    return this.acceo.post(AdConfigResponseItem)(this.url, request);
  }

  public async test(item: AdConfigResponse, urlParams: string, configType: string) {
    const request = {
      name: item.name,
      origName: item.name,
      testable: item.testable,
      test_type: item.testType,
      type: item.type,
      urlParams,
      configType,
    };
    Object.assign(request, item.values);
    const body = `item=${encodeURIComponent(JSON.stringify(request))}`;
    const config = {
      headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
    };
    return this.acceo.post(TestResponseCollection)('/settings/ad-config/test/', body, config);
  }
}
