const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

export class SecondsToHmsdValueConverter {
  public static transform(timeInSeconds: number): string {
    if (timeInSeconds === null || timeInSeconds === undefined || timeInSeconds === 0) {
      return '00:00:00.000';
    }

    const isNegativeTime = timeInSeconds < 0;

    // Extract milliseconds and round them to 3 decimal places
    const milliseconds = Math.round((timeInSeconds - Math.floor(timeInSeconds)) * 1000);
    const time = Math.abs(Math.floor(timeInSeconds));

    const hours = Math.floor(time / SECONDS_IN_HOUR);
    const minutes = Math.floor((time % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
    const seconds = time - (hours * SECONDS_IN_HOUR + minutes * SECONDS_IN_MINUTE);

    const hoursDisplay = `${hours}`.padStart(2, '0');
    const minutesDisplay = `${minutes}`.padStart(2, '0');
    const secondsDisplay = `${seconds}`.padStart(2, '0');
    const millisecondsDisplay = `${milliseconds}`.padStart(3, '0');

    return `${isNegativeTime ? '-' : ''}${hoursDisplay}:${minutesDisplay}:${secondsDisplay}.${millisecondsDisplay}`;
  }

  // eslint-disable-next-line class-methods-use-this
  public toView(value: number): string {
    return SecondsToHmsdValueConverter.transform(value);
  }
}
