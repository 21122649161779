import {computedFrom} from 'aurelia-framework';

import {BaseChannelSingle} from './base';

export class ChannelSlate extends BaseChannelSingle {
  public tabFields: string[] = [
    'ad_slate',
    'blackout_slate',
    'missing_content_slate',
  ];

  public libraryList: object[] = [];
  public slateDisplay: any;

  public attached() {
    this.liveChannels.activeTab = 'slate';
    super.attached();
    // load library list
    return this.libraryService.getLibraryOptions().then(resp => {
      this.libraryList = resp;
    });
  }

  public async activate(params) {
    super.activate(params);
    this.slateDisplay = {
      adDisplayPlayer: false,
      adDisplayThumbnail: true,
      adShowingThumbnail: true,
      blackoutDisplayPlayer: false,
      blackoutDisplayThumbnail: true,
      blackoutShowingThumbnail: true,
      missingContentDisplayPlayer: false,
      missingContentDisplayThumbnail: true,
      missingContentShowingThumbnail: true,
    };
  }

  // Need one for every field on the tab + one for the tab
  @computedFrom('model.ad_slate')
  get adSlateDirty() {
    if (!this.liveChannels.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.liveChannels.currentModel.ad_slate !== this.model.ad_slate;

    // Update field `isDirty` state
    this.fields.ad_slate.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.blackout_slate')
  get blackoutSlateDirty() {
    if (!this.liveChannels.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.liveChannels.currentModel.blackout_slate !== this.model.blackout_slate;

    // Update field `isDirty` state
    this.fields.blackout_slate.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.missing_content_slate')
  get missingContentSlateDirty() {
    if (!this.liveChannels.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.liveChannels.currentModel.missing_content_slate !== this.model.missing_content_slate;

    // Update field `isDirty` state
    this.fields.missing_content_slate.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('adSlateDirty', 'blackoutSlateDirty', 'missingContentSlateDirty')
  get tabDirty() {
    return this.adSlateDirty || this.blackoutSlateDirty || this.missingContentSlateDirty;
  }
}
