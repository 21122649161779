export default function metaUpdate(modelMeta = [], canonicalMeta = []) {
    canonicalMeta.forEach(metaItem => {
        const findMeta = modelMeta.find(m => m.key === metaItem.key);

        if (!findMeta) {
            modelMeta.push(metaItem);
        } else {
            findMeta.value = metaItem.value;
        }
    });

    const canonicalKeys = canonicalMeta.map(tp => tp.key);

    return modelMeta.filter(m => canonicalKeys.includes(m.key));
}
