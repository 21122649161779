import {HttpClient} from 'aurelia-fetch-client';
import {inject, LogManager} from 'aurelia-framework';
import {classToClass} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {CmsHttpClient} from 'services/cms-http-client';
import {
    ContentFields,
    CutRecurrence,
    Operator,
    PlayBackFields,
    PlayerFields,
    RuleType,
    UsageRule,
} from '../models/fields-models';
import {
    Action,
    CdnCapacity,
    CdnDistribution,
    MultiCdnSettingsConfig,
    Profile,
    QosPreset,
    RegionCapacity,
    Rule,
    RuleSet,
    RuleSetId,
} from '../models/multi-cdn-settings-config';
import {MultiCdnSettingsConfigWrapper} from '../models/multi-cdn-settings-config-wrapper';

const logger = LogManager.getLogger('Multi Cdn Settings Service');
const cdnDistributionsString = 'cdnDistributions';
const overrideClientCdnString = 'overrideClientCdn';
const qoSCdnSelectionPresetIdString = 'qoSCdnSelectionPresetId';
const defaultCdnList = ['Edgecast'];
const defaultAccount = 'default';

@inject(CmsHttpClient, Acceo)
export class MultiCdnSettingService {
    public httpClient: HttpClient;
    public formURLEncoded: string;
    public getMultiCdnSettingsUrl: string;
    public saveMultiCdnSettingsUrl: string;
    public facebookPageListUrl: string;
    public error: string;
    public logger = logger;
    public data: any;
    public etag: string;

    constructor(public cmsHttpClient: CmsHttpClient, public acceo: Acceo) {
        this.httpClient = this.cmsHttpClient.httpClient;
        this.formURLEncoded = 'application/x-www-form-urlencoded; charset=UTF-8';
        this.getMultiCdnSettingsUrl = '/settings/multi-cdn/';
        this.saveMultiCdnSettingsUrl = '/settings/multi-cdn/save/';
    }

    public getSettings(): Promise<MultiCdnSettingsConfigWrapper> {
        return new Promise((resolve, reject) => {
            this.acceo
                .get(MultiCdnSettingsConfigWrapper)(this.getMultiCdnSettingsUrl, {
                    responseTransform: resp => {
                        const multiCdnSettingsConfigWrapper = new MultiCdnSettingsConfigWrapper();
                        // check if there is an error - return empty
                        if (resp.error_response && resp.error_response.msg.length > 0) {
                            multiCdnSettingsConfigWrapper.errorMessage = `'error: ${resp.error_response.status_code}: ${resp.error_response.msg}`;

                            multiCdnSettingsConfigWrapper.multiCdnSettingsConfig = new MultiCdnSettingsConfig();
                            return multiCdnSettingsConfigWrapper;
                        }
                        this.etag = resp.etag;
                        const accountSettings = this.getMultiCdnSettings(resp);

                        multiCdnSettingsConfigWrapper.multiCdnSettingsConfig = accountSettings;
                        return multiCdnSettingsConfigWrapper;
                    },
                })
                .then((multiCdnSettingsWrapper: MultiCdnSettingsConfigWrapper) => {
                    resolve(multiCdnSettingsWrapper);
                })
                .catch(reject);
        });
    }

    public createAccountConfiguration(accountConfiguration) {
        const multiCdnSettings = new MultiCdnSettingsConfig();

        // copy capacities
        this.createCdnCapacities(accountConfiguration, multiCdnSettings);

        // copy ruleSets
        this.createScenarios(accountConfiguration, multiCdnSettings);

        // copy profiles
        this.createProfiles(accountConfiguration, multiCdnSettings);

        // copy qosPresets
        this.createQosPresets(accountConfiguration, multiCdnSettings);

        return multiCdnSettings;
    }

    public saveConfiguration(config: MultiCdnSettingsConfig): Promise<MultiCdnSettingsConfigWrapper> {
        return new Promise((resolve, reject) => {
            this.acceo
                .post(MultiCdnSettingsConfigWrapper)(this.saveMultiCdnSettingsUrl, config, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'If-Match': this.etag,
                    },
                    requestTransform: (cfg: MultiCdnSettingsConfig) => this.createServerModel(cfg),
                    responseTransform: resp => {
                        const multiCdnSettingsConfigWrapper = new MultiCdnSettingsConfigWrapper();
                        if (resp.error_response && resp.error_response.msg.length > 0) {
                            multiCdnSettingsConfigWrapper.errorMessage = `'error: ${resp.error_response.status_code}: ${resp.error_response.msg}`;
                            return multiCdnSettingsConfigWrapper;
                        }
                        const cfg = this.getMultiCdnSettings(resp);
                        this.etag = resp.etag;
                        multiCdnSettingsConfigWrapper.multiCdnSettingsConfig = cfg;
                        return multiCdnSettingsConfigWrapper;
                    },
                })
                .then((multiCdnSettingsWrapper: MultiCdnSettingsConfigWrapper) => {
                    resolve(multiCdnSettingsWrapper);
                })
                .catch(reject);
        });
    }

    public getMultiCdnSettings(resp) {
        const accountSettings = this.createAccountConfiguration(resp.multiCdnSettings);
        const defaultSettings = this.createAccountConfiguration(
            resp.defaultMultiCdnSettings.defaultAccountConfiguration,
        );

        if (defaultSettings.profiles && defaultSettings.profiles.length > 0) {
            defaultSettings.profiles.forEach(profile => (profile.isDefault = true));
        }

        if (accountSettings.profiles && accountSettings.profiles.length > 0) {
            accountSettings.profiles.forEach(profile => (profile.isDefault = false));
        }

        accountSettings.profiles.push(...defaultSettings.profiles);

        let customerOnboardedCdns = null;
        const {extrinsicAccountSettings} = resp.defaultMultiCdnSettings;
        if (extrinsicAccountSettings != null) {
            customerOnboardedCdns = extrinsicAccountSettings.customerOnboardedCdns;
        }
        accountSettings.availablecdns = this.createAvailableCdnsList(
            customerOnboardedCdns,
            resp.multiCdnSettings.accountId,
        );

        return accountSettings;
    }

    private createAvailableCdnsList(onBoardedCdns, accountId) {
        const cdnList = defaultCdnList;

        if (onBoardedCdns == null || onBoardedCdns.cdnsListPerAccount == null || accountId == null) {
            return cdnList;
        }

        for (const item in onBoardedCdns.cdnsListPerAccount) {
            if (item === accountId) {
                return onBoardedCdns.cdnsListPerAccount[item];
            }
        }

        const defaultAccountCdnList = onBoardedCdns.cdnsListPerAccount[defaultAccount];

        if (defaultAccountCdnList != null) {
            return defaultAccountCdnList;
        }

        return cdnList;
    }

    private createProfiles(config, multiCdnSettings) {
        if (config.profiles == null) {
            return;
        }

        for (const profileConfig of config.profiles) {
            const profile = new Profile();
            profile.name = profileConfig.id;
            profile.priority = profileConfig.priority;
            profile.enabled = profileConfig.enabled;

            const action = new Action();
            action.overrideClientCdn = profileConfig.actions.overrideClientCdn;

            if (profileConfig.actions) {
                if (profileConfig.actions.hasOwnProperty(cdnDistributionsString)) {
                    if (
                        profileConfig.actions[cdnDistributionsString] != null &&
                        profileConfig.actions[cdnDistributionsString].length > 0
                    ) {
                        for (const cdnDist of profileConfig.actions[cdnDistributionsString]) {
                            const cdnDistrbution = new CdnDistribution();

                            cdnDistrbution.percent = cdnDist.percent;
                            cdnDistrbution.name = this.capitalizeFirstLetter(cdnDist.cdn);
                            action.distribution.push(cdnDistrbution);
                        }
                    }
                }
                if (
                    profileConfig.actions.hasOwnProperty(qoSCdnSelectionPresetIdString) &&
                    profileConfig.actions[qoSCdnSelectionPresetIdString] != null
                ) {
                    action.presetId = profileConfig.actions[qoSCdnSelectionPresetIdString];
                } else {
                    action.presetId = multiCdnSettings.defaultPreset;
                }
                profile.actions = action;
            }

            if (profileConfig.ruleSetIds != null && profileConfig.ruleSetIds.length > 0) {
                for (const ruleSet of profileConfig.ruleSetIds) {
                    const ruleSetId = new RuleSetId();
                    ruleSetId.name = ruleSet;
                    profile.ruleSetIds.push(ruleSetId);
                }
            }

            multiCdnSettings.profiles.push(profile);
        }
    }

    private createRulesSets(serverConfig, config) {
        for (const rules in serverConfig) {
            if (serverConfig.hasOwnProperty(rules)) {
                const ruleType = this.getRuleSetType(rules);
                const fields = serverConfig[rules];

                if (ruleType === 'usage') {
                    this.createUsageRule(ruleType, fields, config);
                } else {
                    // we assume that field and operator come in the right case
                    for (const field in fields) {
                        if (this.nonExistingField(field, ruleType)) {
                            continue;
                        }
                        if (fields.hasOwnProperty(field)) {
                            const rule = new Rule();
                            rule.ruleType = ruleType;
                            rule.field = field;
                            rule.operator = this.getOperator(fields[field].matchOperator);
                            rule.value = fields[field].valueToMatch == null ? '' : fields[field].valueToMatch;

                            if (ruleType === RuleType.Content) {
                                config.contentRules.push(rule);
                            }
                            if (ruleType === RuleType.Playback) {
                                config.playbackRules.push(rule);
                            }
                            if (ruleType === RuleType.Player) {
                                config.playerRules.push(rule);
                            }
                        }
                    }
                }
            }
        }
    }

    private createUsageRule(ruleType, fields, config) {
        if (fields == null) {
            return;
        }

        const rule = new Rule();
        rule.ruleType = ruleType;
        rule.field = 'usage';
        rule.operator = this.getOperator(fields[rule.field].matchOperator);
        rule.value = fields[rule.field].valueToMatch == null ? '' : fields[rule.field].valueToMatch;

        const usageRule = new UsageRule();
        usageRule.usage = rule;

        if (fields.cdn) {
            usageRule.cdn = this.capitalizeFirstLetter(fields.cdn);
        }
        usageRule.startTimestamp = fields.startTimestamp;
        usageRule.customPeriod = fields.customPeriod == null ? 0 : fields.customPeriod;
        usageRule.recurrence = this.getRecurrence(fields.recurrenceEnum);
        config.usageRules.push(usageRule);
    }

    private nonExistingField(field, ruleType) {
        if (ruleType === RuleType.Content) {
            if ((Object as any).values(ContentFields).includes(field)) {
                return false;
            }
        }
        if (ruleType === RuleType.Playback) {
            if ((Object as any).values(PlayBackFields).includes(field)) {
                return false;
            }
        }
        if (ruleType === RuleType.Player) {
            if ((Object as any).values(PlayerFields).includes(field)) {
                return false;
            }
        }
        return true;
    }

    private getOperator(operator) {
        if (!operator) {
            return Operator.stringEquals;
        }

        const operatorEnum = operator as Operator;

        if (operatorEnum) {
            return operatorEnum;
        }

        return Operator.stringEquals;
    }

    private getRecurrence(recurrence) {
        if (!recurrence) {
            return CutRecurrence.monthly;
        }

        const recurrenceEnum = recurrence as CutRecurrence;

        if (recurrenceEnum) {
            return recurrenceEnum;
        }

        return CutRecurrence.monthly;
    }

    private createScenarios(config, multiCdnSettings) {
        if (config.ruleSets == null) {
            return;
        }

        for (const ruleSet in config.ruleSets) {
            if (config.ruleSets.hasOwnProperty(ruleSet)) {
                const scenario = new RuleSet();
                scenario.name = ruleSet;
                this.createRulesSets(config.ruleSets[ruleSet], scenario);
                multiCdnSettings.ruleSets.push(scenario);
            }
        }
    }

    private createQosPresets(config, multiCdnSettings) {
        if (config.qoSCdnSelectionPresets == null) {
            return;
        }

        for (const preset in config.qoSCdnSelectionPresets) {
            if (config.qoSCdnSelectionPresets.hasOwnProperty(preset)) {
                const qosPreset = new QosPreset();
                qosPreset.name = preset;
                qosPreset.rttErrorThreshold = config.qoSCdnSelectionPresets[preset].rttErrorThreshold;
                qosPreset.availabilityErrorThreshold = config.qoSCdnSelectionPresets[preset].availabilityErrorThreshold;
                multiCdnSettings.qoSCdnSelectionPresets.push(qosPreset);
            }
        }
    }

    private createCdnCapacities(config, multiCdnSettings) {
        if (!config.cdnCapacities) {
            return;
        }

        for (const cdnCapacityConf in config.cdnCapacities) {
            if (!config.cdnCapacities.hasOwnProperty(cdnCapacityConf)) {
                continue;
            }
            const cdnCapacity = new CdnCapacity();
            cdnCapacity.name = this.capitalizeFirstLetter(cdnCapacityConf);
            const regionMap = config.cdnCapacities[cdnCapacityConf].capacityDefinitionPerRegionMap;
            for (const region in regionMap) {
                if (!regionMap.hasOwnProperty(region)) {
                    continue;
                }
                if (!multiCdnSettings.availableregions.includes(region)) {
                    // if region not allowed don't show it and dont save it
                    continue;
                }
                const regionCapacity = new RegionCapacity();
                regionCapacity.regionname = region.toUpperCase();
                regionCapacity.capacity = regionMap[region].maxCapacityMbps;
                regionCapacity.slow = regionMap[region].slowThresholdPercent;
                regionCapacity.stop = regionMap[region].stopThresholdPercent;
                cdnCapacity.regions.push(regionCapacity);
            }
            multiCdnSettings.cdns.push(cdnCapacity);
        }
    }

    private getRuleSetType(match: string) {
        if (match.toLowerCase() === 'contentrules') {
            return RuleType.Content;
        }
        if (match.toLowerCase() === 'playbacksessionrules') {
            return RuleType.Playback;
        }
        if (match.toLowerCase() === 'clientrules') {
            return RuleType.Player;
        }
        if (match.toLowerCase() === 'usagerules') {
            return RuleType.Usage;
        }
        return RuleType.Content;
    }

    private createServerModel(cfg: MultiCdnSettingsConfig) {
        // classToPlain does not do field mapping, so using classToClass and JSON.stringify to serialize.
        const data = classToClass(cfg);

        // copy cdn capacities
        const cdnCapacities = new Map<string, object>();
        this.createCdnCapacitiesForServer(cfg, cdnCapacities);

        // copy ruleSets

        const ruleSets = {};

        this.createRuleSetsForServer(cfg, ruleSets);

        // copy profiles
        const profiles = [];

        this.createProfilesForServer(cfg, profiles);

        // copy qos

        const qoSCdnSelectionPresets = {};

        this.createQosForServer(cfg, qoSCdnSelectionPresets);

        const data2 = {cdnCapacities, ruleSets, profiles, qoSCdnSelectionPresets};

        logger.info(`multiCdnSettings=${encodeURIComponent(JSON.stringify(data))}`);
        return `multiCdnSettings=${encodeURIComponent(JSON.stringify(data2))}`;
    }

    private createProfilesForServer(cfg: MultiCdnSettingsConfig, profiles) {
        // don't save the default profiles
        cfg.profiles
            .filter(profile => !profile.isDefault)
            .forEach(profile => {
                const actions = {};
                const cdnDistributions = [];

                if (profile.actions) {
                    if (profile.actions.distribution) {
                        profile.actions.distribution.forEach(dist => {
                            const cdnDistribution = {cdn: dist.name, enabled: true, percent: dist.percent};
                            cdnDistributions.push(cdnDistribution);
                        });
                    }
                    actions[cdnDistributionsString] = cdnDistributions;
                    actions[qoSCdnSelectionPresetIdString] =
                        profile.actions.presetId === cfg.defaultPreset ? null : profile.actions.presetId;
                    actions[overrideClientCdnString] = profile.actions.overrideClientCdn;
                }

                const ruleSetIds = [];
                profile.ruleSetIds.forEach(ruleSetId => {
                    ruleSetIds.push(ruleSetId.name);
                });
                profiles.push({
                    actions,
                    ruleSetIds,
                    enabled: profile.enabled,
                    id: profile.name,
                    priority: profile.priority,
                });
            });
    }

    private createRulesSetsByTypeForServer(config, content, playback, client) {
        config.contentRules.forEach(rule => {
            if (rule.ruleType === RuleType.Content) {
                content[rule.field] = {matchOperator: rule.operator, valueToMatch: rule.value};
            }
        });

        config.playbackRules.forEach(rule => {
            if (rule.ruleType === RuleType.Playback) {
                playback[rule.field] = {matchOperator: rule.operator, valueToMatch: rule.value};
            }
        });

        config.playerRules.forEach(rule => {
            if (rule.ruleType === RuleType.Player) {
                client[rule.field] = {matchOperator: rule.operator, valueToMatch: rule.value};
            }
        });
    }

    private createRuleSetsForServer(cfg: MultiCdnSettingsConfig, ruleSets) {
        function createUsageRulesForServer(scenario) {
            let usageRules = null;
            if (scenario.usageRules && scenario.usageRules.length === 1) {
                const usageRuleMatch = {
                    matchOperator: scenario.usageRules[0].usage.operator,
                    valueToMatch: scenario.usageRules[0].usage.value,
                };

                usageRules = {
                    cdn: scenario.usageRules[0].cdn,
                    customPeriod: scenario.usageRules[0].customPeriod,
                    recurrenceEnum: scenario.usageRules[0].recurrence,
                    startTimestamp: scenario.usageRules[0].startTimestamp,
                    usage: usageRuleMatch,
                };
            }
            return usageRules;
        }

        cfg.ruleSets.forEach(scenario => {
            const contentRules = new Map<string, object>();
            const playbackSessionRules = new Map<string, object>();
            const clientRules = new Map<string, object>();

            this.createRulesSetsByTypeForServer(scenario, contentRules, playbackSessionRules, clientRules);
            const usageRules = createUsageRulesForServer(scenario);

            const value = {contentRules, playbackSessionRules, clientRules, usageRules};

            ruleSets[scenario.name] = value;
        });
    }

    private createQosForServer(cfg: MultiCdnSettingsConfig, qoSCdnSelectionPresets) {
        cfg.qoSCdnSelectionPresets.forEach(preset => {
            const presetConf = {
                availabilityErrorThreshold: preset.availabilityErrorThreshold,
                rttErrorThreshold: preset.rttErrorThreshold,
            };

            qoSCdnSelectionPresets[preset.name] = presetConf;
        });
    }

    private createCdnCapacitiesForServer(cfg: MultiCdnSettingsConfig, cdnCapacities) {
        cfg.cdns.forEach(cdnCapacity => {
            const regions = new Map<string, object>();
            cdnCapacity.regions.forEach(region => {
                const regionConf = {
                    maxCapacityMbps: region.capacity,
                    slowThresholdPercent: region.slow,
                    stopThresholdPercent: region.stop,
                };

                regions[region.regionname] = regionConf;
            });

            const capacities = {capacityDefinitionPerRegionMap: regions};

            cdnCapacities[cdnCapacity.name] = capacities;
        });
    }

    private capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
}
