import {IsBoolean, IsNumber, IsOptional, IsString, IsUrl, ValidateNested} from 'class-validator';

import {Transform, Type} from 'class-transformer';

export interface AssetFindParams {
    search?: string;
    next_token?: number;
    type?: string;
    sort_col?: string;
    sort_dir?: string;
    library?: string;
    library_type?: string;
}

export class Asset {
    // camelcase fields are internal app state fields, not returned from server.
    public isOrphan: boolean;

    @IsString()
    public id: string;

    @IsString()
    public title: string;

    @IsOptional()
    @IsBoolean()
    public fairplay_enabled: boolean = false;

    @IsOptional()
    @IsBoolean()
    public dash_enabled: boolean = false;

    @IsOptional()
    @Transform(value => !!value, {toClassOnly: true})
    public require_drm: boolean = false;

    @IsOptional()
    @Transform(value => !!value, {toClassOnly: true})
    public require_studio_drm: boolean = false;

    @IsNumber()
    public dur: number;

    @IsString()
    public duration?: string;

    @IsOptional()
    @IsUrl()
    public poster_url: string;

    @IsOptional()
    @IsString()
    public poster_url_alt: string;

    @IsOptional()
    @IsUrl()
    public hls_url: string;

    @IsOptional()
    @IsString()
    public _class: string;
}

export class AssetFindResponse {
    @ValidateNested()
    @Type(() => Asset)
    public assets?: Asset[];

    @IsNumber()
    @IsOptional()
    public now?: number;

    @IsNumber()
    @IsOptional()
    public total?: number;

    @IsNumber()
    @IsOptional()
    public next_token?: number;

    @IsBoolean()
    @IsOptional()
    public more?: boolean;
}
