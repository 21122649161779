import {Account} from './account-access';

function setEq(a: Set<string>, b: Set<string>) {
  return a.size === b.size && Array.from(b).every((v: string) => a.has(v));
}

export class TrackedAccount extends Account {
  private _canonicalEntitlements: Set<string>;
  private _canonicalBillingWithMe: boolean;
  private _entitlements: string[];
  private _isDirty: boolean = false;
  private _allEntitlements: Set<string>;
  private _allAccess: boolean = false;
  private _billingWithMe: boolean = false;

  get isDirty() {
    return this._isDirty;
  }

  get billing_with_me() {
    return this._billingWithMe;
  }

  set billing_with_me(value: boolean) {
    if (!this.billing_allow) {
      return;
    }

    this._billingWithMe = value;

    this.checkState();
  }

  get cms_entitlements() {
    return this._entitlements;
  }

  set cms_entitlements(value: string[]) {
    this._entitlements = value;

    this.checkState();
  }

  get allAccess() {
    return this._allAccess;
  }

  set allAccess(value: boolean) {
    const entitlements = new Set(this._entitlements);

    if (value) {
      this._allEntitlements.forEach(e => entitlements.add(e));
    } else {
      this._allEntitlements.forEach(e => entitlements.delete(e));
    }

    this._entitlements = Array.from(entitlements);
    this.checkState();
  }

  constructor(args, allEntitlements: string[]) {
    super();

    this._canonicalEntitlements = new Set(args.cms_entitlements);
    this._allEntitlements = new Set(allEntitlements);
    this._canonicalBillingWithMe = args.billing_with_me;
    this._billingWithMe = args.billing_with_me;

    delete args.allAccess;

    Object.assign(this, args);
  }

  /**
   * Returns a set containing any new entitlements
   */
  public newEntitlements(): Set<string> {
    return new Set(this._entitlements.filter(e => !this._canonicalEntitlements.has(e)));
  }

  /**
   * Resets model to pristine state
   */
  public reset(): void {
    this._billingWithMe = this._canonicalBillingWithMe === true;
    this._entitlements = Array.from(this._canonicalEntitlements);

    this.checkState();
  }

  /**
   * Save currently "staged" values to canonical
   */
  public save(): void {
    if (this._canonicalBillingWithMe !== this.billing_with_me) {
      this._canonicalBillingWithMe = this.billing_with_me;
    }

    const entitlements = new Set(this._entitlements);

    if (!setEq(entitlements, this._canonicalEntitlements)) {
      this._canonicalEntitlements = entitlements;
    }

    this.checkState();
  }

  /**
   * verifies isDirty and allAccess state
   */
  private checkState(): void {
    const billingDirty = this.billing_allow && this._canonicalBillingWithMe !== this._billingWithMe;
    const entitlementsDirty = !setEq(this._canonicalEntitlements, new Set(this._entitlements));
    this._isDirty = billingDirty || entitlementsDirty;

    const entitlements = new Set(this._entitlements);
    this._allAccess = Array.from(this._allEntitlements).every(e => entitlements.has(e));
  }
}
