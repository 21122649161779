import {bindable} from 'aurelia-framework';

import {generateRandom} from '@bindable-ui/bindable';

import * as styles from './b-timeline-container.css';

export class BTimelineContainer {
  @bindable
  public currentTimeTop = -1;

  @bindable
  public loading = false;

  @bindable
  public id = generateRandom();

  public styles = styles;

  constructor() {
    this.styles = styles;
  }

  public attached() {
    if (typeof this.loading !== 'boolean') {
      this.loading = false;
    }
  }
}
