import {computedFrom, inject} from 'aurelia-framework';

import {SessionService} from 'services/session';

import {validEmail} from 'resources/valid-email';

import {IdsUtils} from './ids-utils';

/**
 * This is the class for IDS notice.  It gets called from login.js when user migration cookie
 *   is not set.
 *
 * This view model has one piece:
 *
 *   Notice page (default page)
 *
 * User can either login with IDS or reset IDS password.  Either case will set the cookie so
 *   this page will not be shown again.
 */

@inject(IdsUtils, SessionService)
export class IdsNotice {
  @computedFrom('authLinkInfo', 'authLinkError')
  get emailState() {
    if (this.authLinkInfo) {
      return 'info';
    }
    if (this.authLinkError) {
      return 'error';
    }
    return '';
  }

  public idsHostname: string[] = document.domain.includes('uplynk')
    ? ['id.vdms.io']
    : [
        'id-dev.vdms.io',
        'id-stg.vdms.io',
      ];

  public email: string = '';
  public authLinkInfo: string = '';
  public authLinkError: string = '';
  public sendingEmail: boolean = false;
  public resetTimeout: any = null;
  // @ts-ignore
  public authLinkMessage: string = '';
  // @ts-ignore
  public errorMessage: string = '';
  public emailInput: any;
  // @ts-ignore
  private magicLinkSent: boolean = false;
  // @ts-ignore
  private validEmail: boolean = true;
  public showMagicLink: boolean = false;
  public magicLinkAuthUrl: string = '';
  public loginWithMagicLink: boolean = false;
  public showMagicLinkLoginError: boolean = false;

  public year: number = new Date().getFullYear();

  constructor(public idsUtils: IdsUtils, public session: SessionService) {}

  public activate() {
    const errorCode = this.session.getQueryString('errorCode');
    const idsLogin = this.session.getQueryString('idsLogin');
    const authLinkFailure = this.session.getQueryString('auth_link_failure');
    const magicLinkAuthUrl = this.session.getQueryString('ml');
    if (magicLinkAuthUrl) {
      this.loginWithMagicLink = true;
      this.checkMagicLinkUrl(magicLinkAuthUrl);
    }
    /* check idsLogin is url with known ids domain */
    if (idsLogin) {
      let error = null;
      try {
        const u = new URL(decodeURIComponent(idsLogin));

        if (!u.hostname || !this.idsHostname.includes(u.hostname)) {
          /* istanbul ignore next */
          error = u.hostname || 'No hostname';
        } else if (this.session.sessionInfo == null) {
          this.session.sessionInfo = {idsLogin: u.href};
        }
      } catch (e) {
        error = idsLogin;
      }
      if (error) {
        this.errorMessage = `Invalid IDS hostname: ${error}`;
        return;
      }
    }

    if (errorCode) {
      /* error code here should correspond to cmsview session.py error codes */
      switch (errorCode) {
        case '1':
          this.errorMessage = 'Your IDS account has an invalid or missing Uplynk user id.';
          break;
        case '2':
          this.errorMessage = 'User session setup error';
          break;
        case '3':
          this.errorMessage = 'Invalid IDS hostname.';
          break;
        default:
          this.errorMessage =
            'We encountered an unknown error authenticating your account with IDS. ' +
            'Please wait a few minutes to try again or use the Magic Link method to sign in.';
      }
    }

    if (authLinkFailure) {
      this.authLinkMessage =
        'Sorry, your authentication link was invalid. ' +
        'Authentication links can only be used once and will expire after 1 hour.';
    }
  }

  /* istanbul ignore next */
  public attached() {
    // wire up enter key on email field
    const input = this.emailInput.shadowRoot.querySelector('input');
    if (input) {
      input.addEventListener('keyup', (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          this.sendLink();
        }
      });
    }
  }

  public checkMagicLinkUrl(url) {
    if (this.isAuthUrl(url)) {
      const mlUrl = new URL(url).href;
      this.magicLinkAuthUrl = mlUrl;
    } else {
      this.showMagicLinkLoginError = true;
    }
  }

  public isAuthUrl(url) {
    const urlPattern = /(cms(-[a-zA-Z0-9-]+)?\.(uplynk|downlynk)\.(localhost(:\d+)?|com))\/auth-link\/[0-9a-fA-F]{32}/;
    return urlPattern.test(url);
  }

  public sendLink() {
    if (this.sendingEmail) {
      return;
    }
    this.sendingEmail = true;
    if (this.emailState === 'info') {
      this.authLinkInfo = '';
      this.sendingEmail = false;
      return;
    }
    this.validateEmail();
    if (this.emailState === 'error') {
      this.sendingEmail = false;
      return;
    }
    this.authLinkInfo = `If the account exists, a single-use magic link will be sent to ${this.email}. This link will expire in 1 hour.

        If you cannot find your magic link, check your spam folder or wait a few minutes and try again.`;

    // Send email to API
    const http = new window.XMLHttpRequest();
    http.open('GET', `/api/v4/auth-link?email=${encodeURIComponent(this.email)}`, true);
    http.send();

    // Clear message after a set amount of time
    const clearInSeconds = 15;
    if (this.resetTimeout) {
      clearTimeout(this.resetTimeout);
    }
    this.resetTimeout = setTimeout(() => {
      this.authLinkInfo = '';
      this.authLinkError = '';
      this.email = '';
    }, clearInSeconds * 1000);

    this.sendingEmail = false;
  }

  public validateEmail() {
    this.authLinkError = '';

    if (!validEmail(this.email)) {
      this.authLinkError = 'A valid email address is required.';
    }
  }
}
