import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterConfiguration, RouteConfig} from 'aurelia-router';

@autoinject()
export class SsoIndex {
  public routes: RouteConfig[];
  public router: Router;

  constructor() {
    this.routes = [
      {
        route: [
          '',
          // 'integrations',
        ],
        name: 'integrationsList',
        moduleId: PLATFORM.moduleName('./list/index'),
        // nav: true,
        title: 'SSO Integrations',
      },
      {
        route: [
          ':id',
        ],
        name: 'integrationsSingle',
        moduleId: PLATFORM.moduleName('./single/index'),
        // nav: true,
        title: 'SSO Integration',
      },
    ];
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }
}

export default SsoIndex;
