import {ConfigErrors} from './config-errors';
import {ConfigInfo} from './config-info';
import {ConfigInput} from './config-input';
import {ConfigOptions} from './config-options';
import {ConfigState} from './config-state';
// import {IConfigSetting} from 'resources/config/models/config-setting';

export class Config {
  // this is a single param config
  public warnings = new ConfigErrors();
  public errors = new ConfigErrors();
  public info = new ConfigInfo();
  public input = new ConfigInput();
  public options = new ConfigOptions();
  public pristine = new ConfigInput();
  public values = {};
  public dirty = [];
  public state = new ConfigState(); // state of a single param
}
