/* eslint-disable max-classes-per-file */

import {Type} from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsIn,
  IsNotEmpty,
  IsOptional,
  IsString,
  IsUrl,
  Min,
  MinLength,
  ValidateNested,
  IsNumber,
} from 'class-validator';

import 'reflect-metadata';

export class IMarkerTemplates {
  @IsString()
  public name: string;

  @IsArray()
  public markers: string[];
}

export class IMarkerTemplatesNewSingle {
  @IsString()
  public type: string;

  @IsArray()
  public tags: string[];

  @IsString()
  public newTag?: string;

  @IsString()
  public newTagError?: string;
}

export class IMarkerTemplatesNew {
  @IsString()
  public name: string;

  @IsArray()
  @ValidateNested()
  @Type(() => IMarkerTemplatesNewSingle)
  public markers: IMarkerTemplatesNewSingle[];
}

export class LiveEvent {
  @IsNotEmpty()
  @Min(0)
  public auto_asset_expiry: number;

  @IsNotEmpty()
  @IsIn([
    -1,
    1,
  ])
  public auto_asset_expiry_option: number = -1;

  @IsArray()
  @MinLength(0, {
    each: true,
  })
  @IsString({
    each: true,
  })
  @IsOptional({
    each: true,
  })
  public available_slicers: string[];

  @IsNumber()
  public live_event_ad_break_warning: number;

  @IsString()
  public live_event_syndication_auto_start: string;

  @IsBoolean()
  public live_event_require_testing: boolean;

  @IsNotEmpty()
  @Min(0)
  public live_event_resume_buffer: number;

  @IsOptional()
  @IsString()
  public live_event_secret_display: string;

  @IsNotEmpty()
  @Min(0)
  public live_event_secret_type: number;

  @IsOptional()
  @IsUrl()
  public live_event_webhook: string;

  @IsNotEmpty()
  @Min(0)
  public post_hours: number;

  @IsNotEmpty()
  @Min(0)
  public post_minutes: number;

  @IsNotEmpty()
  @Min(0)
  public post_slate_duration: number;

  @IsNotEmpty()
  @IsIn([
    -1,
    0,
    1,
  ])
  public post_slate_duration_option: number;

  @IsNotEmpty()
  @Min(0)
  public pre_hours: number;

  @IsNotEmpty()
  @Min(0)
  public pre_minutes: number;

  @IsArray()
  @IsString({
    each: true,
  })
  public slicer_pool: string[];

  @IsNotEmpty()
  @Min(0)
  public vod_asset_expiry: number;

  @IsNotEmpty()
  @IsIn([
    -1,
    1,
  ])
  public vod_asset_expiry_option: number;

  @IsOptional()
  @ValidateNested()
  @Type(() => IMarkerTemplates)
  public marker_templates: IMarkerTemplates[];

  @IsOptional()
  @ValidateNested()
  @Type(() => IMarkerTemplates)
  public marker_templates_new: IMarkerTemplatesNew[];
}

export class LiveEventData extends LiveEvent {
  @IsNotEmpty()
  @Min(0)
  public live_event_max_resume_buffer: number;
}

export class LiveEventSettings {
  @IsNotEmpty()
  @ValidateNested()
  @Type(() => LiveEvent)
  public liveEvents: LiveEvent;

  @IsNotEmpty()
  @Min(0)
  public live_event_max_resume_buffer: number;
}

export class SecretTokenResponse {
  @IsString()
  public secret: string;
}
