import {autoinject, LogManager} from 'aurelia-framework';
import {serialize} from 'class-transformer';
import * as CryptoJS from 'crypto-js';
import {Acceo} from 'services/acceo';
import {KEYSMAP, KEYSMAP2} from '../models/account-access';

const logger = LogManager.getLogger('Account Access Service');

@autoinject
export class AccountAccessService {
    public logger = logger;
    constructor(public acceo: Acceo) {}

    public getAccountAccess(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.acceo
                .post()('/settings/accountaccess/', undefined)
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    this.logger.error(err);
                    reject(err);
                });
        });
    }

    public checkUser(data): Promise<any> {
        const dataString = [
            `addusername=${encodeURIComponent(data.addusername)}`,
            ...this.formatPasswordData(data.password),
        ].join('&');
        return new Promise((resolve, reject) => {
            this.acceo
                .post()('/settings/accountaccess/checkuser/', dataString, {isFormData: true})
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    this.logger.error(err);
                    reject(err);
                });
        });
    }

    public transformPayload(data) {
        const randomID = `tokenentry_${Date.now()}`; // borrowed this from TokenEntry
        const output = {};
        Object.keys(KEYSMAP).forEach(key => {
            output[key] = data.cms_entitlements.indexOf(KEYSMAP[key]) > -1;
        });
        Object.keys(KEYSMAP2).forEach(key => {
            if (key === 'randomID') {
                output[key] = randomID;
            } else {
                output[key] = _.get(data, KEYSMAP2[key]);
            }
        });
        return output;
    }

    public updateAccountAccess(data, password: string): Promise<any> {
        const payload = data.map(d => this.transformPayload(d));
        return new Promise((resolve, reject) => {
            const _data = {
                accounts: payload,
                checkingUsername: false,
                items: payload,
            };
            const dataString = [
                ...this.formatPasswordData(password),
                `item=${encodeURIComponent(serialize(_data))}`,
            ].join('&');

            this.acceo
                .post()('/settings/accountaccess/update/', dataString, {isFormData: true})
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    this.logger.error(err);
                    reject(err);
                });
        });
    }

    private formatPasswordData(password: string) {
        return [
            /* TODO: need update to only pass IDS when the system is fully migrated */
            `ownerpassword=${encodeURIComponent(CryptoJS.SHA1(password))}`,
            `idspassword=${encodeURIComponent(password)}`,
        ];
    }
}
