import {bindable} from 'aurelia-framework';
import {AdConfigResponse} from '../../../models/adconfig-response';
import {TestResponseItem} from '../../../models/test-response-collection';

export class ConfigInput {
  public adConfig: AdConfigResponse;
  public parameters: string = '';
  public position: string = 'preroll';
  @bindable public results: TestResponseItem[] = [];
  /*
  [
    {
      type: 'info',
      msg: 'Using networkID 151933',
    },
    {
      type: 'info',
      msg: 'Simulating request for 120 seconds of vod ads',
    },
    {
      type: 'ok',
      msg: 'Successfully contacted server',
    },
    {
      type: 'info',
      msg: 'Ad server returned 0 ad(s)',
    },
    {
      type: 'error',
      msg: "Error requesting ads: module 'cgi' has no attribute 'escape'",
    },
  ];
  */
  public url = '';
}
