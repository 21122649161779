import {autoinject, LogManager} from 'aurelia-framework';
import {classToPlain, serialize} from 'class-transformer';
import {Acceo} from 'services/acceo';

import {LiveEvent, LiveEventData, LiveEventSettings, SecretTokenResponse} from '../models/live-events-settings';

// TODO: need to add more typing here
const logger = LogManager.getLogger('Live Events Service');

const saveErrorMsg = 'Error updating live events.';

const GET_SETTING_URL = '/settings/live-events/';
const GENERATE_SECRET = '/api/v4/live-events/settings/secret-token';

@autoinject()
export class LiveEventsService {
  public getSettingUrl: string;
  public saveLiveEventsUrl: string;
  public formUrlEncoded: string;
  public data;
  public error: string;
  public logger = logger;
  public model;
  public origData;
  public dataPristine: LiveEvent;
  public cache;
  public liveEvents;
  public checkDirty: boolean;

  constructor(public acceo: Acceo) {}

  public fetchLiveEventsSettings(): Promise<LiveEventData> {
    return new Promise((resolve, reject) => {
      this.acceo
        .post(LiveEventSettings)(GET_SETTING_URL, undefined)
        .then((resp: LiveEventSettings) => {
          this.cache = _.get(resp, 'liveEvents');
          this.cache.live_event_max_resume_buffer = _.get(resp, 'live_event_max_resume_buffer');
          this.dataPristine = _.cloneDeep(this.cache);
          resolve(this.cache);
        })
        .catch(err => {
          this.logger.error(err);
          reject(err);
        });
    });
  }

  public getLiveEventsSettings(): Promise<LiveEventData> {
    if (this.cache) {
      return new Promise(resolve => {
        resolve(this.cache);
      });
    }

    return new Promise((resolve, reject) => {
      this.fetchLiveEventsSettings().then(resolve).catch(reject);
    });
  }

  // TODO: Using the legacy checkResponseForError function for now, might re-write later
  public checkResponseForError({error, msg}: {error?: number; msg?: string} = {}) {
    if (error) {
      if (msg) {
        this.error = msg;
      } else {
        this.error = 'An Unknown Error Has Occurred in SettingData';
      }
      if (error !== 0) {
        return true;
      }
    }
    return false;
  }

  public saveLiveEvents(data) {
    return new Promise((resolve, reject) => {
      delete data.live_event_max_resume_buffer;
      delete data.validationObj;
      const dataString = `item=${encodeURIComponent(serialize(data))}`;
      this.acceo
        .post()('/settings/live-events/update', dataString, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        })
        .then(res => {
          if (!this.checkResponseForError(res)) {
            this.cache = _.get(res, 'item');
            this.dataPristine = _.cloneDeep(this.cache);
            resolve(res);
          } else {
            resolve();
          }
        })
        .catch(err => {
          // Generates an AcceoError
          this.error = err.message;
          this.logError(reject);
          reject();
        });
    });
  }

  public testUrl(url) {
    return new Promise((resolve, reject) => {
      const dataString = `url=${url}`;
      this.acceo
        .post()('/settings/errors/test/', dataString, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
        })
        .then(res => {
          if (!this.checkResponseForError(res)) {
            this.data = _.get(res, 'items');
            resolve(this.data);
          } else {
            resolve();
          }
        })
        .catch(() => {
          this.logError(reject);
        });
    });
  }

  public validate(key, valid): void {
    if (!this.cache.validationObj) {
      this.cache.validationObj = {};
    }

    this.cache.validationObj[key] = valid;
  }

  public isValid(): boolean {
    if (!this.cache.validationObj) {
      return true;
    }
    return _.every(Object.values(this.cache.validationObj), v => v);
  }

  // eslint-disable-next-line class-methods-use-this
  public checkIsEqual(obj1, obj2): boolean {
    let isEqual = true;

    if (!obj1 || !obj2) {
      return true;
    }

    // eslint-disable-next-line consistent-return
    Object.keys(obj1).forEach(key => {
      let val1 = _.get(obj1, key);
      let val2 = _.get(obj2, key);
      if (typeof val1 === 'number') val1 = val1.toString();
      if (typeof val2 === 'number') val2 = val2.toString();
      // !!!both are necessary here due to need to compare array (_.isEqual) and string (!==)
      if (!_.isEqual(val1, val2) && val1 !== val2) {
        isEqual = false;
        return isEqual;
      }
    });
    return isEqual;
  }

  public clearCache() {
    this.cache = null;
    this.dataPristine = null;
  }

  public isCacheDirty(): boolean {
    // Convert to a regular javascript object.  This makes
    // it easier to compare changes.  There is some
    // oddities with IMarkerTemplatesNew where on dataPristine
    // it's just a standard javascript object, BUT on this.cache
    // it's actually a IMarkerTemplatesNew and when it compares
    // it will think it is dirty when it really isn't
    const cleanData: any = classToPlain(this.dataPristine);
    delete cleanData.live_event_max_resume_buffer;
    delete cleanData.validationObj;

    const dirtyData: any = classToPlain(this.cache);
    delete dirtyData.live_event_max_resume_buffer;
    delete dirtyData.validationObj;

    return !this.checkIsEqual(dirtyData, cleanData);
  }

  public logError(reject) {
    this.error = this.error || saveErrorMsg;
    this.logger.error('Settings Data', this.error);
    reject(this.error);
  }

  public async createSecret() {
    const message = {error: 0, message: '', secret: ''};

    try {
      const {secret} = await this.acceo.post(SecretTokenResponse)(GENERATE_SECRET, {proceed: true});
      message.secret = secret;
      return message;
    } catch (e) {
      message.error = 1;
      message.message = e.message;
      this.logger.error(e);
      return message;
    }
  }

  public async updateSecret() {
    const message = {error: 0, message: '', secret: ''};

    try {
      const {secret} = await this.acceo.patch(SecretTokenResponse)(`${GENERATE_SECRET}/proceed`, {proceed: true});
      message.secret = secret;
      return message;
    } catch (e) {
      message.error = 1;
      message.message = e.message;
      this.logger.error(e);
      return message;
    }
  }

  public async deleteSecret() {
    const message = {error: 0, message: '', secret: ''};
    try {
      await this.acceo.delete()(GENERATE_SECRET);
    } catch (e) {
      message.error = 1;
      message.message = e.message;
      this.logger.error(e);
      throw Error(e.message);
    }
  }
}
