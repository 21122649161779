import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, LogManager, singleton} from 'aurelia-framework';
import {classToPlain} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {SyndicationOwnerConfig} from 'services/models/syndication-owner-config';

export const SYNDICATION_OWNER_CONFIG_URL: string = '/api/v4/syndication-owner-config';

const log = LogManager.getLogger('syndication-owner-config-service');
@autoinject()
@singleton()
export class SyndicationOwnerConfigService {
  constructor(public acceo: Acceo, public notification: CToastsService) {}

  public getSyndicationOwnerConfig(): Promise<SyndicationOwnerConfig> {
    return new Promise((resolve, reject) => {
      this.acceo
        .get(SyndicationOwnerConfig)(`${SYNDICATION_OWNER_CONFIG_URL}`)
        .then((resp: SyndicationOwnerConfig) => {
          resolve(resp);
        })
        .catch(er => {
          const msg = 'GET Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public async saveSyndicationOwnerConfig(ownerConfig: SyndicationOwnerConfig): Promise<SyndicationOwnerConfig> {
    const data: any = classToPlain(ownerConfig);
    const errorMsg = 'Error saving the configuration. Please try again.';
    return new Promise((resolve, reject) => {
      this.acceo
        .put(SyndicationOwnerConfig)(`${SYNDICATION_OWNER_CONFIG_URL}`, data)
        .then((resp: SyndicationOwnerConfig) => {
          this.notification.success('Configuration was successfully saved.');
          resolve(resp);
        })
        .catch(e => {
          this.displayErrorInformation(e);
          log.error(errorMsg);
          reject(new Error(errorMsg));
        });
    });
  }

  public displayErrorInformation(errorInfo) {
    if (errorInfo.details !== undefined && errorInfo.details.length) {
      _.forEach(errorInfo.details, detail => {
        this.notification.error(detail);
      });
    } else if (errorInfo.description !== undefined) {
      this.notification.error(errorInfo.description);
    } else if (errorInfo.message !== undefined) {
      this.notification.error(errorInfo.message);
    }
  }
}
