import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {WebhookService} from '../services/profiles';

@autoinject()
export class WebhookSingle {
    public isLoading: boolean = false;
    public queryParams: any;

    public router: Router;
    public routes = [
        {
            moduleId: PLATFORM.moduleName('./config/index'),
            name: 'profileConfig',
            nav: true,
            route: '',
            title: 'Config',
        },
        // Removed log tab for now
        // {
        //     moduleId: PLATFORM.moduleName('./log/index'),
        //     name: 'profileLog',
        //     nav: true,
        //     route: 'log',
        //     title: 'Logs',
        // },
    ];

    constructor(private profileService: WebhookService) {}

    public async activate(params) {
        this.queryParams = params;
        const {id = null} = params;

        this.profileService.profileId = id;
        this.profileService.getSingleProfile(id);
    }

    public configureRouter(config, router) {
        this.router = router;
        config.map(this.routes);
    }

    public backToProfiles() {
        this.router.navigateToRoute('profilesList');
    }

    public async archiveProfile() {
        try {
            await this.profileService.archiveProfile(this.profileService.currentModel.getId());

            this.backToProfiles();
        } catch (err) {
            // Notification error.
        }
    }
}
