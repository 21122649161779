import {autoinject, singleton} from 'aurelia-framework';
import {classToClass} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {AdConfig} from '../models/ad-config';

export const MESSAGE_TYPE_MAP = {
    error: 'critical',
    info: 'info',
    ok: 'success',
};

@autoinject()
@singleton()
export class AdConfigService {
    constructor(public acceo: Acceo) {}

    public getAdConfigs(): Promise<AdConfig[]> {
        return new Promise((resolve, reject) => {
            this.acceo
                .get([AdConfig])('/settings/ad-config/', {
                    responseTransform: resp =>
                        resp.configs.map(c => {
                            c._orig_name = c.name;
                            return c;
                        }),
                })
                .then((adConfigs: AdConfig[]) => {
                    resolve(adConfigs);
                })
                .catch(reject);
        });
    }

    public createOrUpdateAdConfig(config: AdConfig, isCreate: boolean = false): Promise<AdConfig> {
        const url = `/settings/ad-config/${isCreate ? 'create-full' : 'update'}/`;
        return new Promise((resolve, reject) => {
            this.acceo
                .post(AdConfig)(url, config, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    requestTransform: (cfg: AdConfig) => this.flattenConfig(cfg),
                    responseTransform: resp => {
                        const cfg = resp.item;
                        cfg._orig_name = cfg.name;
                        return cfg;
                    },
                })
                .then((adConfig: AdConfig) => {
                    resolve(adConfig);
                })
                .catch(reject);
        });
    }

    public testAdConfig(config: AdConfig, urlParams: string, configType: string): Promise<object[]> {
        const data: any = {...config};
        if (urlParams) {
            data.urlParams = urlParams;
        }
        // Preroll, Midroll or Postroll
        if (configType && config.test_type) {
            data.configType = configType;
        }
        return new Promise((resolve, reject) => {
            this.acceo
                .post()('/settings/ad-config/test/', data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    requestTransform: (cfg: AdConfig) => this.flattenConfig(cfg),
                    responseTransform: resp => {
                        const {items} = resp;
                        if (items && items.length === 6) {
                            // swapping request and response message, showing response first.
                            const temp = items[4];
                            items[4] = items[5];
                            items[5] = temp;
                        }
                        return items.map(item => ({
                            msg: item.msg,
                            type: MESSAGE_TYPE_MAP[item.type.toLowerCase()] || 'info',
                        }));
                    },
                })
                .then(resolve)
                .catch(reject);
        });
    }

    public duplicateAdConfig(name: string, duplicateOf: string): Promise<AdConfig> {
        return new Promise((resolve, reject) => {
            const data = `name=${name}&dupName=${duplicateOf}`;
            this.acceo
                .post(AdConfig)('/settings/ad-config/duplicate/', data, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    prefix: 'configs',
                    responseTransform: resp => {
                        const c = resp.configs.find(cfg => cfg.name === resp.new_name);
                        c._orig_name = c.name;
                        return c;
                    },
                })
                .then((adConfig: AdConfig) => {
                    resolve(adConfig);
                })
                .catch(reject);
        });
    }

    public deleteAdConfig(config: AdConfig): Promise<undefined> {
        return new Promise((resolve, reject) => {
            const dataString = `names=${config.name}`;
            this.acceo
                .post()('/settings/ad-config/delete/', dataString, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;',
                    },
                })
                .then(resolve)
                .catch(reject);
        });
    }

    private flattenConfig(cfg: AdConfig) {
        // classToPlain does not do field mapping, so using classToClass and JSON.stringify to serialize.
        const data = classToClass(cfg);
        (data.required || []).forEach(r => {
            data[r.name] = r.value;
        });
        (data.optional || []).forEach(o => {
            data[o.name] = o.value;
        });
        delete data.required;
        delete data.optional;
        delete data.id;
        delete data.skipValidate;
        delete data.valid;
        delete data.fields;
        delete data.test_type;
        return `item=${encodeURIComponent(JSON.stringify(data))}`;
    }
}
