export class DnsFooter {
    public controller;
    public fModel: any;

    public activate(model) {
        this.controller = model.controller;
        this.fModel = model.footer;
    }

    public confirm_modal() {
        this.controller.ok(true);
    }

    public cancel_modal() {
        this.controller.cancel(false);
    }
}
