import {CTableCol} from '@bindable-ui/bindable';

interface Selectable {
    selected?: boolean;
    selectedState?: string;
}

export interface CTableColSelectable extends CTableCol, Selectable {}

export interface CTableRowSelectable extends Selectable {
    _class?: string;
    id: string;
    order?: number;
}

export enum CTableRowBehavior {
    CHECK = 'check',
    DEFAULT = 'default',
    DRAG = 'drag',
    DRAG_AND_CHECK = 'drag-and-check',
    DRAG_CHECK = 'drag-check',
    RADIO = 'radio',
}
