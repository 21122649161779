import {DialogController} from 'aurelia-dialog';
import {ProfileDefault} from '../../single/models';

export class Model {
    public controller: DialogController;
    public shared: Config;
}

export class Config {
    public input = new ConfigInput();
    public state = new ConfigState();
}

class ConfigInput {
    public profileId: string;
    public type: ProfileDefault;
}

class ConfigState {
    public canExportCSV: boolean;
    public canViewDynamicCols: boolean;
    public error: string;
    public isLoading: boolean;
}
