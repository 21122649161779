/* eslint-disable max-len */
import {ConfigInfo as ConfigInfoBase} from 'resources/config/import/models/config-info';
import {AdServerRoute} from './config-route';

export class ConfigInfo extends ConfigInfoBase {
  public configSettingParamUsage = 'How will your Parameter be used?';
  public configSettingParamUsageHelp = 'How will your Parameter be used?';
  public configSettingHelpCustom =
    'A manually defined ad request param key and value.<br>Specify "pt." for passthrough (i.e. non-uplynk params).<br>Invalid settings will be ignored.';

  public standardSettingHelpCustom = 'Provide a key and its corresponding value.';

  public configSettingMissingProviderHelp =
    'Looking for an ad provider not listed here? <br>' +
    'Send an email to ' +
    '<a href="mailto:streaming@edg.io?subject=New Ad Provider Request&body=Be sure to include the ad provider name and domain list here.">streaming@edg.io </a>' +
    'with ' +
    '"New Ad Provider Request" as the subject line. ' +
    'In the body, specify the ad provider with ad provider domains.<br> ';

  public configSettingLabelKey = 'Parameter Key';
  public configSettingLabelValue = 'Value';
  public configSettingLabelMacro = 'Macro';
  public configSettingLabelOptional = 'Setting';
  public configSettingLabelGeneric = `${this.configSettingLabelKey}`;
  public configSettingLabelCustom = `Ad ${this.configSettingLabelOptional}`;
  public configSettingLabelAddMacro = `Add ${this.configSettingLabelMacro}`;
  public configSettingLabelAddOptional = `Add ${this.configSettingLabelOptional}`;
  public configSettingLabelEditMacro = `Edit ${this.configSettingLabelMacro}`;
  public configSettingLabelEditOptional = `Edit ${this.configSettingLabelOptional}`;
  public documentationLabel = 'Help';
  public documentationUrl =
    'https://docs.edgecast.com/video/index.html#AdIntegration/Ad-Server-Integration.htm%3FTocPath%3DAds%7C_____0';

  public emptyAltConfigEditor = `No ${AdServerRoute.routeTitle} settings`;
  public emptyAltList = `No ${AdServerRoute.routeTitle}s`;
  public emptyMessageConfigEditor = `${AdServerRoute.routeTitle}s allow you to lorem ipsum dolor sit amet, consectetur adipiscing elit, mauris id imperdiet neque.`;
  public emptyMessageList = `${AdServerRoute.routeTitle}s allow you to control how the system contacts a third party ad decision server.`;
  public emptyTitleConfigEditor = `You haven't created any settings for this ${AdServerRoute.routeTitle} yet.`;
  public emptyTitleList = `No ${AdServerRoute.routeTitle}s found.`;
  public objectType = AdServerRoute.routeTitle;
  public nameHelp = `A unique identifier for this ${AdServerRoute.routeTitle} configuration. Expected to be a lowercase alphanumeric value.`;
  public nameLabel = 'Ad Configuration Name';
  public typeHelp = '';
  public typeLabel = 'Ad Server Type';
}
