var content = require("!!../../../node_modules/css-loader/index.js!./global.css");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {"attributes":{"id":"plaincss"}}

options.insert = function insertAtTop(element) {
                const parent = document.querySelector('head');
                const lastInsertedElement = window._lastElementInsertedByStyleLoader;

                if (!lastInsertedElement) {
                  parent.insertBefore(element, parent.firstChild);
                } else if (lastInsertedElement.nextSibling) {
                  parent.insertBefore(element, lastInsertedElement.nextSibling);
                } else {
                  parent.appendChild(element);
                }

                window._lastElementInsertedByStyleLoader = element;
              };
options.singleton = false;

var update = require("!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
