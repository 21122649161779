import {LocalStorageHelper} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {BasePlaylistSingle} from './base';
import {AssetType} from '../models';

@autoinject()
export class PlaylistSingle extends BasePlaylistSingle {
  public isLoading: boolean = false;
  public playlist: any;
  public playUrl: string;
  public textValue: string = _.toString(LocalStorageHelper.loadOrDefault('appliedParameters', ''));
  public router: Router;
  public routes = [
    {
      moduleId: PLATFORM.moduleName('./details/index'),
      name: 'playlistDetails',
      nav: true,
      route: '',
      title: 'Details',
    },
    {
      moduleId: PLATFORM.moduleName('./assets/index'),
      name: 'playlistAssets',
      nav: true,
      route: 'assets',
      title: 'Entries',
    },
    {
      moduleId: PLATFORM.moduleName('./playback/index'),
      name: 'playlistPlayback',
      nav: true,
      route: 'playback',
      title: 'Playback',
    },
  ];

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }

  public async activate(params: any) {
    const playlistId = params.id;
    if (playlistId) {
      this.playlist = await this.playlistService.getPlaylistFromCacheOrLoad(playlistId);
    }
    this.applyParameters();
  }

  public actionBack() {
    this.router.navigateToRoute('playlistsList');
  }

  public async archivePlaylist() {
    try {
      const deleteSingle = await this.playlistService.archivePlaylist([this.playlist.id]);
      if (deleteSingle) {
        this.router.navigateToRoute('playlistsList');
      }
    } catch (err) {
      this.notification.error('Error deleting playlist. Please try again.');
    }
  }

  @computedFrom('playlistService.assetTableData.length', 'playlistService.model.beam_break_duration')
  get playlistDuration(): number {
    if (this.playlistService.assetTableData.length) {
      let beamBreaks = 0;
      let totalDuration = 0;
      this.playlistService.assetTableData.forEach(asset => {
        beamBreaks += asset.break_offsets ? asset.break_offsets.length : 0;
        totalDuration += asset.duration;
      });
      totalDuration += beamBreaks * this.playlistService.model.beam_break_duration;
      return totalDuration;
    }
    return this.playlistService.playlist.total_duration || 0;
  }

  @computedFrom('playlistService.isSaving')
  get videoPlaybackButtonState() {
    return this.playlistService.playlist.playlist.some((pl: object) => AssetType.BEAM in pl);
  }

  public async applyParameters(id: string = this.playlistService.playlist.id) {
    try {
      if (this.textValue.length >= 3) {
        LocalStorageHelper.save('appliedParameters', _.toString(this.textValue));
        if (!this.textValue.startsWith('?')) {
          this.textValue = `?${this.textValue.replace(/\?/g, '')}`;
        }
        await this.playlistService.getPlayerURL(id, this.textValue);
      } else {
        this.textValue = '';
        LocalStorageHelper.save('appliedParameters', _.toString(this.textValue));
        await this.playlistService.getPlayerURL(id);
      }
    } catch (error) {
      this.notification.error(error);
    }
  }

  public duplicatePlaylistActions = {
    onHide: () => {
      if (this.playlistService.duplicatePlaylistForm) {
        this.playlistService.duplicatePlaylistForm.reset();
      }
      this.playlistService.createErrMsg = '';
      this.playlistService.newPlaylistName = '';
    },
  };
}
