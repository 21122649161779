import * as moment from 'moment';

import {
  // BindingEngine,
  computedFrom,
  autoinject,
  observable,
} from 'aurelia-framework';
import {Router} from 'aurelia-router';

// import {Acceo} from 'services/acceo';
import {BetaMode} from 'services/beta-mode';
import {CmsHttpClient} from 'services/cms-http-client';
import {SessionService} from 'services/session';
import {Notification} from 'resources/notification/service';
import {deepCopy} from 'resources/deep-copy';

import Field from './field';

const POLL_INTERVAL = 5000;
const ACCOUNT_DEFAULT_TEXT = 'Account Default';
const DEFAULT_PER_PAGE_LIMIT = 30;
const DEFAULT_SKIP = 0;

export const MANIFEST_PROFILES_URL = '/live-event/playback-profiles/get';
export const LIVE_EVENT_STATES = {
  pre: 'Pre Event',
  post: 'Post Event',
  live: 'Live (On Air)',
  resume: 'Resume',
  complete: 'Complete',
  testing: 'Testing',
};

function fetchPostOptions(obj) {
  return {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  };
}

function fieldIsValid(field: Field) {
  const {errors = []} = field;
  return errors.length === 0;
}

export interface IMeta {
  total: number;
  showing: number;
  limit: number;
}

export interface ITab {
  fields: string[];
  isValid: boolean;
  markedForDeletion?: any[];
  keysToDelete?: any[];
  podsToDelete?: any[];
  edits?: any;
  schema?: any;
}

@autoinject()
export class LiveEventsService {
  @observable()
  public origEvent = null;

  @observable()
  public model = null;

  @observable()
  public checkDirty = true;

  @observable()
  public data = [];

  @computedFrom('session.sessionInfo.entitlements', 'session.sessionInfo.perms')
  get sessionCreate(): boolean {
    if (this.session.sessionInfo.perms) {
      if (this.session.sessionInfo.perms.includes('admin')) {
        return true;
      }
    }

    if (this.session.sessionInfo.entitlements) {
      if (this.session.sessionInfo.entitlements.length) {
        if (this.session.sessionInfo.entitlements.includes('led')) {
          return true;
        }
      }
    }
    return false;
  }

  @computedFrom('session.sessionInfo.entitlements', 'origEvent.owner')
  get sessionWrite(): boolean {
    if (!this.origEvent) {
      return false;
    }

    return this.origEvent.owner === this.session.sessionInfo.ownerID && this.sessionCreate;
  }

  @computedFrom('session.sessionInfo.entitlements', 'origEvent.operator')
  get sessionOperate(): boolean {
    if (!this.origEvent) {
      return false;
    }

    return (
      this.origEvent.operator === this.session.sessionInfo.ownerID ||
      this.origEvent.operator === this.session.sessionInfo.origOwnerID ||
      this.sessionWrite
    );
  }

  /* ---------------------------------------------------------------------- *\
     * Field isValid
    \* ---------------------------------------------------------------------- */

  @computedFrom('fields.desc.errors.length')
  get descIsValid() {
    return fieldIsValid(this.fields.desc);
  }

  @computedFrom('fields.autoexpire_hours.errors.length')
  get autoexpireHoursIsValid() {
    return fieldIsValid(this.fields.autoexpire_hours);
  }

  @computedFrom('fields.vod_autoexpire_hours.errors.length')
  get vodAutoexpireHoursIsValid() {
    return fieldIsValid(this.fields.vod_autoexpire_hours);
  }

  @computedFrom('fields.expected_start.errors.length')
  get expectedStartIsValid() {
    return fieldIsValid(this.fields.expected_start);
  }

  @computedFrom('fields.expected_stop.errors.length')
  get expectedStopIsValid() {
    return fieldIsValid(this.fields.expected_stop);
  }

  @computedFrom('fields.auto_start_stop.errors.length')
  get autoStartStopIsValid() {
    return fieldIsValid(this.fields.auto_start_stop);
  }

  @computedFrom('fields.slicers.errors.length')
  get slicersIsValid() {
    return fieldIsValid(this.fields.slicers);
  }

  @computedFrom('fields.embed_domains.errors.length')
  get embedDomainsIsValid() {
    return fieldIsValid(this.fields.embed_domains);
  }

  /* ---------------------------------------------------------------------- *\
     * Field isDirty
    \* ---------------------------------------------------------------------- */

  @computedFrom('origEvent.desc', 'model.desc')
  get descIsDirty() {
    return this.evalFieldDirtyState('desc');
  }

  @computedFrom('origEvent.external_id', 'model.external_id')
  get externalIdIsDirty() {
    return this.evalFieldDirtyState('external_id');
  }

  @computedFrom('origEvent.expected_start', 'model.expected_start')
  get expectedStartIsDirty() {
    return this.evalFieldDirtyState('expected_start');
  }

  @computedFrom('origEvent.expected_stop', 'model.expected_stop')
  get expectedStopIsDirty() {
    return this.evalFieldDirtyState('expected_stop');
  }

  @computedFrom('origEvent.auto_start_stop', 'model.auto_start_stop')
  get autoStartStopIsDirty() {
    return this.evalFieldDirtyState('auto_start_stop');
  }

  @computedFrom('origEvent.is_managed', 'model.is_managed')
  get isManagedIsDirty() {
    return this.evalFieldDirtyState('is_managed');
  }

  @computedFrom('origEvent.marker_template', 'model.marker_template')
  get markerTemplateIsDirty() {
    return this.evalFieldDirtyState('marker_template');
  }

  @computedFrom('origEvent.operator', 'model.operator')
  get operatorIsDirty() {
    return this.evalFieldDirtyState('operator');
  }

  @computedFrom('origEvent.autoexpire_hours', 'model.autoexpire_hours')
  get autoexpireHoursIsDirty() {
    return this.evalFieldDirtyState('autoexpire_hours');
  }

  @computedFrom('origEvent.vod_autoexpire_hours', 'model.vod_autoexpire_hours')
  get vodAutoexpireHoursIsDirty() {
    return this.evalFieldDirtyState('vod_autoexpire_hours');
  }

  @computedFrom('origEvent.vod_replayable', 'model.vod_replayable')
  get vodReplayableIsDirty() {
    return this.evalFieldDirtyState('vod_replayable');
  }

  @computedFrom('origEvent.slate_as_vod', 'model.slate_as_vod')
  get slateAsVodIsDirty() {
    return this.evalFieldDirtyState('slate_as_vod');
  }

  @computedFrom('origEvent.low_latency', 'model.low_latency')
  get lowLatencyIsDirty() {
    return this.evalFieldDirtyState('low_latency');
  }

  @computedFrom('origEvent.require_drm', 'model.require_drm')
  get requireDrmIsDirty() {
    return this.evalFieldDirtyState('require_drm');
  }

  @computedFrom('origEvent.require_studio_drm', 'model.require_studio_drm')
  get requireStudioDrmIsDirty() {
    return this.evalFieldDirtyState('require_studio_drm');
  }

  @computedFrom('origEvent.mid_slate_library', 'model.mid_slate_library')
  get midSlateLibIsDirty() {
    return this.evalFieldDirtyState('mid_slate_library');
  }

  @computedFrom('origEvent.pre_slate', 'model.pre_slate')
  get preSlateIsDirty() {
    return this.evalFieldDirtyState('pre_slate');
  }

  @computedFrom('origEvent.post_slate', 'model.post_slate')
  get postSlateIsDirty() {
    return this.evalFieldDirtyState('post_slate');
  }

  @computedFrom('origEvent.ad_slate', 'model.ad_slate')
  get adSlateIsDirty() {
    return this.evalFieldDirtyState('ad_slate');
  }

  @computedFrom('origEvent.missing_content_slate', 'model.missing_content_slate')
  get missingContentSlateIsDirty() {
    return this.evalFieldDirtyState('missing_content_slate');
  }

  @computedFrom('origEvent.ad_break_warning', 'model.ad_break_warning')
  get addBreakIsDirty() {
    if (!this.origEvent || !this.model) {
      return false;
    }
    this.origEvent.ad_break_warning = this.origEvent.ad_break_warning
      ? this.origEvent.ad_break_warning.toString()
      : null;

    this.model.ad_break_warning = this.model.ad_break_warning ? this.model.ad_break_warning.toString() : null;

    if (!this.origEvent.ad_break_warning && +this.model.ad_break_warning === 0) {
      return false;
    }

    return this.evalFieldDirtyState('ad_break_warning');
  }

  @computedFrom('origEvent.syndication_auto_start', 'model.syndication_auto_start')
  get publishingAutoStartIsDirty() {
    return this.evalFieldDirtyState('syndication_auto_start');
  }

  @computedFrom('origEvent.playback_profile_id', 'model.playback_profile_id')
  get lowLatencyProfileIsDirty() {
    if (!this.betaMode.hasScope('low-latency-profiles')) {
      // this.fields.isDirty = false;
      return false;
    }
    if (!this.origEvent || !this.model) {
      return false;
    }

    this.origEvent.playback_profile_id = this.origEvent.playback_profile_id
      ? this.origEvent.playback_profile_id.toString()
      : null;

    this.model.playback_profile_id = this.model.playback_profile_id ? this.model.playback_profile_id.toString() : null;

    if (!this.origEvent.playback_profile_id && +this.model.playback_profile_id === 0) {
      return false;
    }

    return this.evalFieldDirtyState('playback_profile_id');
  }

  // @computedFrom('origEvent.meta', 'model.meta')
  // get metaIsDirty() {
  //     if (!this.origEvent || !this.model) { return false; }

  //     const isDirty = this.origEvent.meta !== this.model.meta;

  //     // Update field `isDirty` state.
  //     this.fields.meta.isDirty = isDirty;

  //     return isDirty;
  // }

  @computedFrom('origEvent.embed_domains', 'model.embed_domains')
  get embedDomainsIsDirty() {
    return this.evalFieldDirtyState('embed_domains');
  }

  @computedFrom('origEvent.enable_ad_prefetch', 'model.enable_ad_prefetch')
  get enableAdPreFetchIsDirty() {
    return this.evalFieldDirtyState('enable_ad_prefetch');
  }

  @computedFrom('origEvent.sandbox', 'model.sandbox')
  get sandboxIsDirty() {
    return this.evalFieldDirtyState('sandbox');
  }

  @computedFrom('fields.sandbox_expire.errors.length')
  get sandboxExpireIsValid() {
    return fieldIsValid(this.fields.sandbox_expire);
  }

  @computedFrom('origEvent.sandbox_expire', 'model.sandbox_expire')
  get sandboxExpireIsDirty() {
    return this.evalFieldDirtyState('sandbox_expire');
  }

  /* ---------------------------------------------------------------------- *\
     * Event Computed Properties
    \* ---------------------------------------------------------------------- */

  @computedFrom(
    'fields.desc.isDirty',
    'fields.enable_ad_prefetch.isDirty',
    'fields.external_id.isDirty',
    'fields.expected_start.isDirty',
    'fields.expected_stop.isDirty',
    'fields.auto_start_stop.isDirty',
    'fields.marker_template.isDirty',
    'fields.is_managed.isDirty',
    'fields.operator.isDirty',
    'fields.autoexpire_hours.isDirty',
    'fields.vod_autoexpire_hours.isDirty',
    'fields.vod_replayable.isDirty',
    'fields.low_latency.isDirty',
    'fields.syndication_auto_start',
    'fields.require_drm.isDirty',
    'fields.require_studio_drm.isDirty',
    'fields.slicers.isDirty',
    'fields.mid_slate_library.isDirty',
    'fields.pre_slate.isDirty',
    'fields.post_slate.isDirty',
    'fields.ad_slate.isDirty',
    'fields.missing_content_slate.isDirty',
    'fields.meta.isDirty',
    'fields.ad_pods.isDirty',
    'fields.delete_test_players.isDirty',
    'fields.embed_domains.isDirty',
    'fields.slate_as_vod.isDirty',
    'fields.ad_break_warning.isDirty',
    'fields.playback_profile_id.isDirty',
    'fields.sandbox.isDirty',
    'fields.sandbox_expire.isDirty',
    'fields.syndication_auto_start.isDirty',
  )
  get eventIsDirty() {
    if (Object.keys(this.tabs.liveEventMetadata.edits).length) {
      return true;
    }

    return Object.keys(this.fields).some(key => this.fields[key].isDirty);
  }

  @computedFrom('origEvent.actual_start')
  get hasStarted() {
    if (!this.origEvent) {
      return false;
    }

    const hasActualStarted = moment(this.origEvent.actual_start, 'x').isBefore(moment());

    return hasActualStarted;
  }

  @computedFrom('origEvent.actual_stop')
  get hasStopped() {
    if (!this.origEvent) {
      return false;
    }

    const hasActualStopped = moment(this.origEvent.actual_stop, 'x').isBefore(moment());

    return hasActualStopped;
  }

  @computedFrom('origEvent.low_latency')
  get lowLatency() {
    if (!this.origEvent) {
      return false;
    }

    return !!this.origEvent.low_latency;
  }

  @computedFrom(
    'model.conflicts',
    'model.conflicts.length',
    'model.slicers',
    'model.slicers.length',
    'model.expected_start',
    'model.expected_stop',
  )
  get conflicts() {
    if (!this.model || !this.model.slicers || !this.model.slicers.length) {
      return [];
    }

    const conflicts = deepCopy(this.model.conflicts);
    const slicers = deepCopy(this.model.slicers);

    conflicts.forEach(s => {
      // Build urls.
      s.conflicts.forEach(c => {
        c.href = `index.html#/live-events/${c.id}`;
      });
    });

    // Build tabular data.
    slicers.forEach(slicer => {
      const conflictIndex = conflicts.findIndex(c => c.slicer_id === slicer.id);

      slicer.conflicts = [];

      if (conflictIndex !== -1) {
        slicer.conflicts = conflicts[conflictIndex].conflicts;
      }
    });

    return slicers;
  }

  set conflicts(_value) {
    // return value;
  }

  @computedFrom('model.conflicts', 'model.conflicts.length', 'model.expected_start', 'model.expected_stop')
  get hasConflicts() {
    if (!this.model || !this.model.conflicts) {
      return false;
    }

    let hasConflicts = false;

    this.model.conflicts.forEach(c => {
      if (c.conflicts.length) {
        hasConflicts = true;
        return false;
      }
      return false;
    });

    return hasConflicts;
  }

  get accountDefault() {
    return ACCOUNT_DEFAULT_TEXT;
  }

  public compBackupSlicers: any[] = [];
  public fromCalendar: boolean = false;
  public getEventFetch: any;
  // deprecated
  public isFiltered: boolean = false;
  // deprecated
  public isEventLoading: boolean = false;
  public isLeavingNewRoute: boolean = false;
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public isNew: boolean = false;
  public libraries: any[] = [];
  public loadingConflicts: boolean = false;
  public markerTemplates: any[] = [];
  public meta: IMeta;
  public operators: any[] = [];
  public params;
  public primarySlicer;
  public recordIndex;
  public searchText: string = '';
  public slicers: any[] = [];
  public states = LIVE_EVENT_STATES;

  public _conflicts = [];

  private assetsLastUpdate = 0;
  private httpClient;
  private fetchLiveEventPromise: Promise<any>;
  private singlePollInterval = null;

  constructor(
    // private acceo: Acceo,
    private betaMode: BetaMode,
    private cmsHttpClient: CmsHttpClient,
    private notification: Notification,
    private session: SessionService,
    public router: Router,
  ) {
    this.httpClient = this.cmsHttpClient.httpClient;
    this.resetState();
    this.fetchLiveEventPromise = new Promise(resolve => {
      resolve();
    });
    this.getSanitizedBlankFilterParams();
  }

  /**
   * Validation rules and state for each Live Event field.
   *
   * Rules can either be RegExp patterns that have to pass a RegExp.test(`value`)
   * or they can be a callback function that takes `value` and returns true after
   * determining `value` is valid.
   */
  public fields: {[key: string]: Field} = {
    desc: new Field('desc', {
      meta: {
        tabName: 'liveEventDetails',
      },
      rules: [
        {
          rule: model => {
            const value = model.desc;
            return !!value.length;
          },
          message: 'You must enter an Event Name.',
        },
      ],
    }),
    enable_ad_prefetch: new Field('enable_ad_prefetch', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    external_id: new Field('external_id', {
      meta: {
        tabName: 'liveEventDetails',
      },
    }),
    expected_start: new Field('expected_start', {
      meta: {
        tabName: 'liveEventDetails',
      },
      rules: [
        {
          rule: model => {
            const value = model.expected_start;
            const autoStartStop = model.auto_start_stop;
            const hasValue = !!value;
            let isValid = true;

            if (hasValue && moment(value, 'x').isBefore(moment())) {
              isValid = false;
            }

            if (autoStartStop && !hasValue) {
              isValid = false;
            }

            return isValid;
          },
          message: 'You must enter a future date/time.',
        },
      ],
    }),
    expected_stop: new Field('expected_stop', {
      meta: {
        tabName: 'liveEventDetails',
      },
      rules: [
        {
          rule: model => {
            const value = model.expected_stop;
            const autoStartStop = model.auto_start_stop;
            const hasValue = !!value;
            let isValid = true;

            if (hasValue && moment(value, 'x').isBefore(moment())) {
              isValid = false;
            }

            if (autoStartStop && !hasValue) {
              isValid = false;
            }

            return isValid;
          },
          message: 'You must enter a future date/time.',
        },
      ],
    }),
    auto_start_stop: new Field('auto_start_stop', {
      meta: {
        tabName: 'liveEventDetails',
      },
      rules: [
        {
          rule: model => {
            const value = model.auto_start_stop;
            let expectedStop = model.expected_stop;
            if (expectedStop == null) {
              expectedStop = this.origEvent.expected_stop;
            }
            const hasExpectedStop = !!expectedStop;
            let isValid = true;

            if (value === true) {
              const stopHasPast = moment(expectedStop, 'x').isBefore(moment());
              if (!hasExpectedStop || stopHasPast) {
                isValid = false;
              }
            }

            return isValid;
          },
          message: 'Scheduled Stop Time must be a valid, future date/time to enable Auto Start/Stop.',
        },
      ],
    }),
    is_managed: new Field('is_managed', {
      meta: {
        tabName: 'liveEventDetails',
      },
    }),
    marker_template: new Field('marker_template', {
      meta: {
        tabName: 'liveEventDetails',
      },
    }),
    operator: new Field('operator', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    autoexpire_hours: new Field('autoexpire_hours', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
      rules: [
        {
          rule: model => {
            const value = model.autoexpire_hours;
            const hasValue = !!value;
            let isValid = true;

            if (hasValue) {
              if (value % 1 !== 0 || value < 0) {
                isValid = false;
              }
            }

            return isValid;
          },
          message: 'You must enter a positive integer.',
        },
      ],
    }),
    vod_autoexpire_hours: new Field('vod_autoexpire_hours', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
      rules: [
        {
          rule: model => {
            const value = model.vod_autoexpire_hours;
            const hasValue = !!value;
            let isValid = true;

            if (hasValue) {
              if (value % 1 !== 0 || value < 0) {
                isValid = false;
              }
            }

            return isValid;
          },
          message: 'You must enter a positive integer.',
        },
      ],
    }),
    vod_replayable: new Field('vod_replayable', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    slate_as_vod: new Field('slate_as_vod', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    low_latency: new Field('low_latency', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    syndication_auto_start: new Field('syndication_auto_start', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    ad_break_warning: new Field('ad_break_warning', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    require_drm: new Field('require_drm', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    require_studio_drm: new Field('require_studio_drm', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    slicers: new Field('slicers', {
      meta: {
        tabName: 'liveEventSlicers',
      },
    }),
    mid_slate_library: new Field('mid_slate_library', {
      meta: {
        tabName: 'liveEventSlate',
      },
    }),
    pre_slate: new Field('pre_slate', {
      meta: {
        tabName: 'liveEventSlate',
      },
    }),
    post_slate: new Field('post_slate', {
      meta: {
        tabName: 'liveEventSlate',
      },
    }),
    ad_slate: new Field('ad_slate', {
      meta: {
        tabName: 'liveEventSlate',
      },
    }),
    missing_content_slate: new Field('missing_content_slate', {
      meta: {
        tabName: 'liveEventSlate',
      },
    }),
    meta: new Field('meta', {
      meta: {
        tabName: 'liveEventMetadata',
      },
    }),
    meta_schema: new Field('meta_schema', {
      meta: {
        tabName: 'liveEventMetadata',
      },
    }),
    ad_pods: new Field('ad_pods', {
      meta: {
        tabName: 'liveEventPodFormat',
      },
    }),
    embed_domains: new Field('embed_domains', {
      meta: {
        tabName: 'liveEventEmbed',
      },
    }),
    delete_test_players: new Field('delete_test_players', {
      meta: {
        tabName: 'liveEventPlayback',
      },
    }),
    test_players: new Field('test_players', {
      meta: {
        tabName: 'liveEventPlayback',
      },
    }),
    publish: new Field('publish', {
      meta: {
        tabName: 'liveEventPublish',
      },
    }),
    playback_profile_id: new Field('playback_profile_id', {
      meta: {
        tabName: 'liveEventConfig',
      },
    }),
    sandbox: new Field('sandbox', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
    }),
    sandbox_expire: new Field('sandbox_expire', {
      meta: {
        tabName: 'liveEventEventConfig',
      },
      rules: [
        {
          rule: model => {
            const value = model.sandbox_expire;
            const hasValue = !!value;
            let isValid = true;

            if (hasValue) {
              if (value % 1 !== 0 || value < 0) {
                isValid = false;
              }
            }

            return isValid;
          },
          message: 'You must enter a positive integer.',
        },
      ],
    }),
  };

  public tabs: {[key: string]: ITab} = {
    liveEventDetails: {
      fields: [
        'desc',
        'external_id',
        'expected_start',
        'expected_stop',
        'auto_start_stop',
        'is_managed',
      ],
      isValid: null,
    },
    liveEventEventConfig: {
      fields: [
        'operator',
        'autoexpire_hours',
        'enable_ad_prefetch',
        'vod_autoexpire_hours',
        'vod_replayable',
        'low_latency',
        'syndication_auto_start',
        'require_drm',
        'require_studio_drm',
        'mid_slate_library',
        'pre_slate',
        'post_slate',
        'ad_slate',
        'missing_content_slate',
        'slate_as_vod',
        'sandbox',
        'sandbox_expire',
      ],
      isValid: null,
    },
    liveEventSlicers: {
      fields: ['slicers'],
      isValid: null,
      markedForDeletion: [],
    },
    liveEventSlate: {
      fields: [
        'mid_slate_library',
        'pre_slate',
        'post_slate',
        'ad_slate',
        'missing_content_slate',
        'slate_as_vod',
      ],
      isValid: null,
      markedForDeletion: [],
    },
    liveEventPodFormat: {
      fields: ['ad_pods'],
      isValid: null,
      podsToDelete: [],
    },
    liveEventMetadata: {
      fields: [
        'meta',
        'meta_schema',
      ],
      isValid: null,
      keysToDelete: [],
      edits: {},
      schema: null,
    },
    liveEventPlayback: {
      fields: [
        'embed_domains',
        'delete_test_players',
      ],
      isValid: null,
    },
    liveEventEventAssets: {
      fields: [],
      isValid: null,
    },
    liveEventLogs: {
      fields: [],
      isValid: null,
    },
    liveEventMarkerLogs: {
      fields: [],
      isValid: null,
    },
    liveEventPublish: {
      fields: [],
      isValid: null,
    },
  };

  /* ---------------------------------------------------------------------- *\
     * Bindable Changed
    \* ---------------------------------------------------------------------- */

  /**
   * Reset validation state when event changes.
   */
  origEventChanged(newVal) {
    if (newVal) {
      // this.clearValidationErrors();
    }
  }

  dataChanged(newVal) {
    if (!newVal) {
      return;
    }
    newVal.forEach(e => {
      const canDelete = this.canDelete(e, this.session);

      e.isSelectable = canDelete;
    });

    this.data = newVal;
  }

  /**
   * Cleans params that are null or undefined from `this.params` without
   * modifying `this.params`.
   *
   * @returns params {Object} -
   */
  sanitizeParams(params) {
    const newParams = {};

    Object.keys(params).forEach(key => {
      const param = params[key];

      if (param != null && param !== '') {
        // This is to allow blank Slicers and Operators search
        if (param === '--- None ---') {
          newParams[key] = null;
        } else {
          newParams[key] = param;
        }
      }
    });

    return newParams;
  }

  /**
   * Cleans params meant to be saved
   *
   * @returns params {Object}
   */
  sanitizeSavedParams(params) {
    const newParams = {};

    Object.keys(params).forEach(key => {
      const param = params[key];

      if (param != null && param !== '') {
        newParams[key] = param;
      }
    });

    return newParams;
  }

  resetState() {
    this.isLoading = false;
    this.isLoadingMore = false;
    this.isEventLoading = false;
    this.meta = {
      total: null,
      showing: null,
      limit: null,
    };
    this.data = [];
  }

  getSanitizedBlankFilterParams() {
    const blankParams = this.getBlankFilterParams();
    return this.sanitizeSavedParams(blankParams);
  }

  getBlankFilterParams() {
    return {
      r_start: null,
      r_stop: null,
      end_r_start: null,
      end_r_stop: null,
      created_r_start: null,
      created_r_stop: null,
      calendar: null,
      operator: null,
      slicer: null,
      order: '-created',
      limit: DEFAULT_PER_PAGE_LIMIT,
      skip: DEFAULT_SKIP,
      search: null,
      state: null,
      name: null,
      startRange: null,
      endRange: null,
      createdRange: null,
    };
  }

  cleanFilterParams() {
    this.params = this.getBlankFilterParams();
  }

  /**
   * Set params to a clean state
   *
   * @param {Object} params - A sanitized param obj
   */
  resetFilterParams(params) {
    this.params = params;
  }

  /**
   * Requests live-events. Uses `this.params` as body for search, sort, filter
   * criteria.
   *
   * @param params {Object} - Parameters used as request body.
   */
  getLiveEvents(params, isLoadMore = false) {
    if (!params.limit) {
      params.limit = DEFAULT_PER_PAGE_LIMIT;
    }
    if (this.getEventFetch && this.getEventFetch.readyState !== 4) {
      this.getEventFetch.abort(new Error('Request was cancelled'));
    }
    return new Promise((resolve, reject) => {
      this.params = deepCopy(params);

      if (!isLoadMore) {
        this.data = [];
        this.isLoading = true;
      } else {
        this.isLoadingMore = true;
      }

      this.getEventFetch = $.ajax({
        type: 'POST',
        url: '/live-event/list',
        data: JSON.stringify(params),
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Cms-Session-Token': this.session.sessionIdFingerprint,
        },
      })
        .done(res => {
          this.data = this.data.concat(res.events);
          this.params.since = res.now;

          this.meta.total = res.count;
          this.meta.showing = this.data.length;
          this.meta.limit = params.limit || null;
          this.setIsFiltered(params);

          resolve(this.data);
        })
        .fail(err => {
          reject(new Error(err.statusText));
        })
        .always(() => {
          this.isLoading = false;
          this.isLoadingMore = false;
        });
    });
  }

  getExport(params) {
    if (params) {
      this.notification.info('Please wait as we gather your data for export...');
      this.httpClient
        .fetch('/live-event/export-to-csv', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (!res.error) {
                const windowURL = window.URL || window.webkitURL;
                const blob = new Blob([res.csv_data], {type: 'text/csv'});
                const link = document.createElement('a');
                link.href = windowURL.createObjectURL(blob);

                const d = new Date();
                // eslint-disable-next-line max-len
                const filename = `schedule_${d.getFullYear()}_${
                  d.getMonth() + 1
                }_${d.getDate()}_${d.getHours()}_${d.getMinutes()}_${d.getSeconds()}.csv`;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.notification.success(
                  `${filename} has been downloaded successfully.
                                    Please check your download location.`,
                );
              }
            });
          }
        });
    }
  }

  // TODO: params is being stomped on used between this servers and the list.js file - we need to
  // refactor
  getEventUpdates() {
    const params = deepCopy(this.params);
    params.deleted = true;
    params.skip = 0;

    if (this.params) {
      this.httpClient
        .fetch('/live-event/list', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              // Loop through events, update existing and add any new
              res.events.forEach(event => {
                let updated = false;
                this.data.forEach((cachedEvent, idx) => {
                  if (event.id === cachedEvent.id) {
                    if (event.deleted) {
                      this.data.splice(idx, 1);
                    } else {
                      event.isSelectable = this.canDelete(event, this.session);
                      this.updateConflicts(this.getEventByID(event.id), event);
                      this.data.splice(idx, 1, event);
                    }
                    updated = true;
                  }
                });
                if (!updated && event.deleted === 0) {
                  this.data.unshift(event);
                }
              });
              this.params.since = res.now;
            });
          }
        });
      // There are cases where isSelectable gets unset. To ensure the property is set,
      // we check through all the events and default to true if not defined
      this.data.forEach(event => {
        event.isSelectable = event.isSelectable === undefined ? true : event.isSelectable;
      });
    }
  }

  /**
   * Polls events whose conflicts were changed.
   *
   * @param o {Object} - Old event (prior to change).
   * @param n {Object} - New event (post change).
   */
  updateConflicts(o, n) {
    const flatten = arr => arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatten(val) : val), []);

    const oldConflicts = flatten(o.conflicts.map(s => s.conflicts.map(c => c.id)));
    const newConflicts = flatten(n.conflicts.map(s => s.conflicts.map(c => c.id)));
    const conflicts = oldConflicts.concat(newConflicts);

    const effected = conflicts.filter(id => newConflicts.indexOf(id) < 0 || oldConflicts.indexOf(id) < 0);

    effected.forEach(id => {
      this.pollEvent(id);
    });
  }

  async getEvent(eventId) {
    const resp = await this.httpClient.fetch('/live-event/get', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id: eventId}),
    });

    if (!resp.ok) {
      return;
    }

    const res = await resp.json();

    if (res.error) {
      if (res.msg === 'Live event not found.') {
        this.model.id = null;
        this.stopSinglePoll();
      }

      return;
    }

    const event = deepCopy(res.events[0]);
    const idx = this.data.findIndex(r => r.id === event.id);

    if (idx < 0) {
      return;
    }

    if (event.deleted) {
      this.notification.info('This event has been deleted.');

      this.data.splice(idx, 1);
      return;
    }

    this.data.splice(idx, 1, event);

    // save deleted test players
    const deletedTestPlayers = [];
    this.model.test_players.forEach(tp => {
      if (tp.deleted) {
        deletedTestPlayers.push(tp.id);
      }
    });

    // only updates non-dirty fields
    Object.keys(event).forEach(key => {
      if (!(Object.prototype.hasOwnProperty.call(this.fields, key) && this.fields[key].isDirty)) {
        this.model[key] = deepCopy(event[key]);
      }
    });

    // set deleted test players
    if (deletedTestPlayers.length) {
      this.model.test_players.forEach((tp, index) => {
        if (deletedTestPlayers.indexOf(tp.id) > -1) {
          this.model.test_players[index].deleted = true;
        }
      });
    }
    this.setOrigEvent(event);
  }

  async getLowLatencyProfiles() {
    const ERROR_MESSAGE = 'Error retrieving list of profiles.';

    const resp = await this.httpClient.fetch(MANIFEST_PROFILES_URL, {
      method: 'post',
    });

    if (!resp.ok) {
      this.notification.error(`${ERROR_MESSAGE} ${resp.statusText}`);
      throw new Error(ERROR_MESSAGE);
    }

    const res = await resp.json();

    if (res.error) {
      this.notification.error(res.msg);
      throw new Error(res.msg);
    }

    return res.items;
  }

  async retriggerEventCallback(eventId) {
    const errorMsg = 'Error retriggering event.';
    const error = new Error(errorMsg);

    const resp = await this.httpClient.fetch('/live-event/retrigger-callback', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({id: eventId}),
    });

    if (!resp.ok) {
      this.notification.error('Server Error');
      throw error;
    }

    const res = await resp.json();

    if (res.error) {
      this.notification.error(res.msg);
      throw error;
    }

    this.notification.success('Live Event Data Retriggered');
  }

  pollEvent(eventId) {
    this.httpClient
      .fetch('/live-event/get', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id: eventId}),
      })
      .then(resp => {
        if (resp.ok) {
          resp.json().then(res => {
            if (!res.error) {
              const event = deepCopy(res.events[0]);
              this.data.forEach((cachedEvent, idx) => {
                if (event.id === cachedEvent.id) {
                  if (event.deleted) {
                    this.notification.info('This event has been deleted.');
                    this.data.splice(idx, 1);
                  } else {
                    this.data.splice(idx, 1, event);
                  }
                }
              });
            }
          });
        }
      });
  }

  startSinglePoll() {
    this.stopSinglePoll();
    if (!this.model) {
      return;
    }

    if (this.model.id) {
      this.getEvent(this.model.id);
    }

    this.singlePollInterval = setInterval(() => {
      if (this.model.id) {
        this.getEvent(this.model.id);
      }
    }, POLL_INTERVAL);
  }

  stopSinglePoll() {
    if (this.singlePollInterval) {
      clearInterval(this.singlePollInterval);
      this.singlePollInterval = null;
    }
  }

  setIsFiltered(params) {
    const ignoreKeys = [
      'order',
      'limit',
      'skip',
    ];
    const paramKeys = Object.keys(params);
    ignoreKeys.forEach(key => {
      const idx = paramKeys.indexOf(key);
      if (idx > -1) {
        paramKeys.splice(idx, 1);
      }
    });
    this.isFiltered = paramKeys.length > 0;
  }

  getEventByID(id) {
    return this.data.find(e => e.id === id) || false;
  }

  fetchLiveEvent(id) {
    this.fetchLiveEventPromise = new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/get', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({id}),
        })
        .then(resp => {
          if (resp.cache) {
            const event = resp.events[0];

            this.setOrigEvent(event);
            this.setModel(event);

            resolve(deepCopy(event));
          }
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                // handle API error.
                reject(res.msg);
              } else {
                const event = res.events[0];

                this.setOrigEvent(event);
                this.setModel(event);
                resolve(deepCopy(event));
              }
            });
          }
        })
        .catch(err => {
          this.notification.error(err);
          reject(err);
        });
    });
    return this.fetchLiveEventPromise;
  }

  public async getLiveEvent(id): Promise<void> {
    this.isEventLoading = true;
    let event;

    try {
      event = this.getEventByID(id);

      if (!event) {
        event = await this.fetchLiveEvent(id);
      }
    } finally {
      this.isEventLoading = false;
      this.fromCalendar = false;
    }

    this.setOrigEvent(event);
    this.setModel(event);
  }

  async getOperators() {
    return new Promise((resolve, reject) => {
      this.operators = [];
      this.httpClient
        .fetch('/live-event/operator/list', {
          method: 'post',
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                this.notification.error(res.msg);
                reject(res.msg);
              } else {
                res.owners.forEach(owner => {
                  this.operators.push(owner);
                });
                resolve(this.operators);
              }
            });
          } else {
            this.notification.error(`Error retrieving list of operators. ${resp.statusText}`);
            reject(new Error('Error retrieving list of operators'));
          }
        })
        .catch(err => {
          this.notification.error(`Error retrieving list of operators. ${err}`);
          reject(err);
        });
    });
  }

  getMarkerTemplates() {
    return new Promise((resolve, reject) => {
      this.markerTemplates = [''];
      this.httpClient
        .fetch('/live-event/marker-templates/list', {
          method: 'post',
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                this.notification.error(res.msg);
                reject(res.msg);
              } else {
                res.marker_templates_new.forEach(template => {
                  this.markerTemplates.push(template);
                });
                resolve(this.markerTemplates);
              }
            });
          } else {
            this.notification.error(`Error retrieving list of marker templates. ${resp.statusText}`);
            reject(new Error('Error retrieving list of marker templates'));
          }
        })
        .catch(err => {
          this.notification.error(`Error retrieving list of marker templates. ${err}`);
          reject(err);
        });
    });
  }

  getSlicers() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/slicer/list', {
          method: 'post',
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                this.notification.error(res.msg);
                reject(res.msg);
              } else {
                resolve(res.slicers);
              }
            });
          } else {
            this.notification.error(`Error retrieving list of slicers. ${resp.statusText}`);
            reject(new Error('Error retrieving list of slicers'));
          }
        })
        .catch(err => {
          this.notification.error(`Error retrieving list of slicers. ${err}`);
          reject(err);
        });
    });
  }

  getSlicerPool() {
    return new Promise((resolve, reject) => {
      this.slicers = [];
      this.httpClient
        .fetch('/live-event/slicers/pool', {
          method: 'post',
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                reject(res.msg);
              } else {
                this.slicers = res.slicers;
                resolve(this.slicers);
              }
            });
          } else {
            this.notification.error(`Error retrieving list of slicers. ${resp.statusText}`);
            reject(new Error('Error retrieving list of slicers'));
          }
        })
        .catch(err => {
          this.notification.error(`Error retrieving list of slicers. ${err}`);
          reject(err);
        });
    });
  }

  updateSlicerPool(slicers) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/slicers/pool/save', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({slicers: slicers.sort()}),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                this.notification.error(res.msg);
                reject(res.msg);
              } else {
                this.slicers = res.slicers;
                resolve(this.slicers);
              }
            });
          } else {
            this.notification.error(`Error retrieving list of slicers. ${resp.statusText}`);
            reject(new Error('Error retrieving list of slicers'));
          }
        })
        .catch(err => {
          this.notification.error(`Error retrieving list of slicers. ${err}`);
          reject(err);
        });
    });
  }

  getSlicerConflicts(model) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/slicer-conflicts', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({event: model}),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                this.notification.error(res.msg);
                reject(res.msg);
              } else {
                resolve(res.conflicts);
              }
            });
          } else {
            this.notification.error(`Error retrieving slicer conflicts. ${resp.statusText}`);
            reject(new Error('Error retrieving slicer conflicts.'));
          }
        })
        .catch(err => {
          this.notification.error(`Error retrieving slicer conflicts. ${err}`);
          reject(err);
        });
    });
  }

  getAncillaryAssets(id, getRecent = false) {
    let url = `/live-event/${id}/ancillary-assets`;

    if (getRecent) {
      if (this.assetsLastUpdate) {
        url = `${url}?since=${this.assetsLastUpdate}`;
      }
    }

    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch(url)
        .then(resp => {
          if (resp.ok) {
            resp.json().then(data => {
              if (data.now) {
                if (this.assetsLastUpdate) {
                  // If the data coming back is newer (race condition)
                  if (data.now > this.assetsLastUpdate) {
                    this.assetsLastUpdate = data.now;
                    resolve(data.assets);
                  }
                } else {
                  this.assetsLastUpdate = data.now;
                  resolve(data.assets);
                }
              }
            });
          } else {
            reject(new Error('bad response getting ancillary assets'));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getLibraries() {
    return new Promise((resolve, reject) => {
      this.libraries = [{id: null, desc: 'Account Default'}];
      this.httpClient
        .fetch('/libraries/list/', {
          method: 'post',
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                this.notification.error(res.msg);
                reject(res.msg);
              } else {
                res.incoming.forEach(lib => {
                  if (lib.id !== '') {
                    this.libraries.push(lib);
                  }
                });
                res.outgoing.forEach(lib => {
                  if (lib.id !== '') {
                    this.libraries.push(lib);
                  }
                });

                resolve(this.libraries);
              }
            });
          } else {
            this.notification.error(`Error retrieving list of libraries. ${resp.statusText}`);
            reject(new Error('Error retrieving list of libraries'));
          }
        })
        .catch(err => {
          this.notification.error(`Error retrieving list of libraries. ${err}`);
          reject(err);
        });
    });
  }

  deleteLiveEvent(id, override = null) {
    return new Promise((resolve, reject) => {
      const body: any = {id};

      if (override) {
        body.override = true;
      }

      this.httpClient
        .fetch('/live-event/delete', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.warning) {
                if (res.override) {
                  resolve(res);
                }
                reject(new Error(res.msg));
              } else {
                // remove from data and sessionStorage
                this.data.forEach((event, index) => {
                  if (event.id === id) {
                    this.data.splice(index, 1);
                  }
                });

                // Update meta.
                this.meta.showing = this.data.length;
                this.meta.total -= 1;

                resolve(res);
              }
            });
          }
        })
        .catch(err => {
          this.notification.error(err);
          reject(err);
        });
    });
  }

  deleteLiveEvents(ids, override = null) {
    return new Promise((resolve, reject) => {
      const body: any = {ids};
      if (override) {
        body.override = true;
      }

      this.httpClient
        .fetch('/live-event/delete', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                // handle API error.
                this.notification.error(res.msg);
                reject(res.msg);
              } else if (res.warning) {
                resolve(res);
              } else {
                this.notification.success(`${res.deleted.length} Event(s) were deleted.`);

                // remove from data and sessionStorage
                for (let i = this.data.length - 1; i >= 0; i -= 1) {
                  const event = this.data[i];
                  if (ids.includes(event.id)) {
                    this.data.splice(i, 1);
                  }
                }

                // Update meta.
                this.meta.showing = this.data.length;
                this.meta.total -= res.deleted.length;

                resolve(res);
              }
            });
          }
        })
        .catch(err => {
          this.notification.error(err);
          reject(err);
        });
    });
  }

  async duplicateLiveEvents(events) {
    const errorMessage = 'Could not duplicate event(s). Please try again.';
    const successMessage = `
            Event(s) duplicated.
            External IDs, scheduled time, auto start, and event state properties have been reset for duplicated event.
        `;

    const resp = await this.httpClient.fetch('/live-event/duplicate', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({events}),
    });

    if (!resp.ok) {
      this.notification.error(errorMessage);
      throw new Error(resp.msg);
    }

    const res = await resp.json();

    if (res.error) {
      this.notification.error(errorMessage);
      throw new Error(res.msg);
    }

    this.notification.success(successMessage, 'Success', 8000);
    return res.copied;
  }

  /**
   * Saves event.
   */
  saveLiveEvent(event, options: any = {}) {
    return new Promise((resolve, reject) => {
      let url = '/live-event/update';

      if (options.validateMetadata) {
        url += '?validate_metadata=true';
      }

      this.httpClient
        .fetch(url, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(event),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                // handle API Response Error.
                this.notification.error('Could not save event. Please try again.');
                reject(new Error(`${res.status}: ${res.msg}`));
              } else {
                this.updateConflicts(this.origEvent, deepCopy(res.event));
                this.notification.success(`${this.origEvent.desc} has been saved.`);
                this.setOrigEvent(res.event);
                this.setModel(res.event);

                this.model.test_players = res.event.test_players;
                this.data.forEach((_event, index) => {
                  if (_event.id === this.origEvent.id) {
                    this.data.splice(index, 1, this.origEvent);
                  }
                });

                if (res.event.conflicts.length > 0) {
                  let conflictCount = 0;

                  res.event.conflicts.forEach(c => {
                    conflictCount += c.conflicts.length;
                  });

                  if (conflictCount > 0) {
                    this.notification.warning(`Event/Slicer Conflicts: ${conflictCount}`);
                  }
                }

                resolve(res);
              }
            });
          }
        })
        .catch((/* err */) => {
          // APIError
          // this.notification.error(`[CATCH] ${err}`);
        });
    });
  }

  /**
   * Creates event.
   */
  createLiveEvent(event) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/create', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(event),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                // handle API Response Error.
                this.notification.error(`${res.status}: ${res.msg}`);
                reject(new Error(`${res.status}: ${res.msg}`));
              } else {
                this.notification.success(`${res.event.desc} was created.`);

                this.data.unshift(res.event);

                this.data = deepCopy(this.data);

                this.meta.showing += 1;
                this.meta.total += 1;

                if (res.event.conflicts.length > 0) {
                  this.notification.info(`Event/slicer conflicts: ${res.event.conflicts.length}`);
                }

                resolve(res);
              }
            });
          }
        })
        .catch((/* err */) => {
          // APIError
          // this.notification.error(`[CATCH] ${err}`);
        });
    });
  }

  addMeta(key, value) {
    const eventId = this.model.id;
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/add-meta', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({event_id: eventId, key, value}),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(data => {
              if (!data.error) {
                this.model.meta = data.meta;
                this.data.forEach((_event, index) => {
                  if (_event.id === this.model.id) {
                    this.data.splice(index, 1, this.model);
                  }
                });

                resolve(data);
              }
            });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  async addMetaList(metadata: {[key: string]: any}[]): Promise<void> {
    const eventId = this.model.id;

    const resp = await this.httpClient.fetch('/live-event/add-meta-list', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({event_id: eventId, metadata}),
    });

    if (!resp.ok) {
      throw new Error('Server Error');
    }

    const data = await resp.json();

    if (data.error) {
      throw new Error(data.msg);
    }

    this.model.meta = data.meta;
    const idx = this.data.findIndex(e => e.id === this.model.id);

    if (idx > -1) {
      this.data.splice(idx, 1, this.model);
    }
  }

  /**
   * Add slicer.
   */
  addSlicer(slicerId) {
    const model = {
      event_id: this.model.id,
      slicer_id: slicerId,
    };

    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/add-slicer', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(model),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                // handle API Response Error.
                this.notification.error(`${res.status}: ${res.msg}`);
                reject(new Error(`${res.status}: ${res.msg}`));
              } else {
                const updated = deepCopy(this.origEvent);
                updated.slicers = deepCopy(res.slicers);
                updated.conflicts = deepCopy(res.conflicts);

                this.updateConflicts(this.origEvent, updated);

                this.notification.success(`${slicerId} was added.`);

                this.setOrigEvent(updated);
                this.model.slicers = deepCopy(res.slicers);
                this.model.conflicts = deepCopy(res.conflicts);
                this.data.forEach((_event, index) => {
                  if (_event.id === this.origEvent.id) {
                    this.data.splice(index, 1, this.origEvent);
                  }
                });

                resolve(res);
              }
            });
          }
        })
        .catch((/* err */) => {
          // APIError
          // this.notification.error(`[CATCH] ${err}`);
        });
    });
  }

  setVodReplay(id, asset) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/vod-replay/update', fetchPostOptions({id, asset}))
        .then(resp => {
          if (resp.ok) {
            resp
              .json()
              .then(data => {
                if (!data.error) {
                  resolve(data.asset);
                } else {
                  reject(data.msg);
                }
              })
              .catch(err => {
                reject(err);
              });
          } else {
            reject(new Error(`Invalid response: ${resp}`));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  addTestPlayer(playerOptions) {
    const eventId = this.model.id;
    return this.httpClient
      .fetch('/live-event/add-test-player', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({event_id: eventId, ...playerOptions}),
      })
      .then(resp => {
        if (resp.ok) {
          resp.json().then(data => {
            if (!data.error) {
              const deleteTestPlayers = [];
              this.model.test_players.forEach(tp => {
                if (tp.deleted) {
                  deleteTestPlayers.push(tp.id);
                }
              });
              data.test_players.forEach(tp => {
                if (deleteTestPlayers.indexOf(tp.id) !== -1) {
                  tp.deleted = true;
                }
              });
              this.model.test_players = data.test_players;
            }
          });
        }
      })
      .catch(() => {});
  }

  embedCode(action) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch(`/live-event/embed-code/${action}`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({event_id: this.model.id}),
        })
        .then(resp => {
          if (resp.ok) {
            resp
              .json()
              .then(data => {
                if (!data.error) {
                  this.model.embed_player_url = data.event.embed_player_url;
                  resolve(data);
                } else {
                  reject(data.msg);
                }
              })
              .catch(err => {
                reject(err);
              });
          } else {
            reject(new Error(`Invalid response: ${resp}`));
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  /**
   * Removes non-whitelisted properties from event object.
   *
   * @param evt {Object} - Event object.
   * @param whitelist {Array} - Array of keys of properties to be preserved.
   * @return cleanEvt {Object} - Cleaned event object.
   */
  cleanEvent(evt) {
    if (!evt) {
      return {};
    }

    const cleanEvt = deepCopy(evt);

    Object.keys(cleanEvt).forEach(key => {
      if (Object.keys(this.fields).includes(key)) {
        if (!this.fields[key].isDirty) {
          delete cleanEvt[key];
        }
      } else if (key !== 'id') {
        delete cleanEvt[key];
      }
    });

    return cleanEvt;
  }

  validateEvent(evt, tab?: string) {
    let isValid = true;

    Object.keys(evt).forEach(key => {
      if (Object.keys(this.fields).includes(key)) {
        const field = this.fields[key];

        // Reset field errors.
        field.errors = [];

        // Validate against field rules.
        field.rules.forEach(r => {
          let didPass = true;

          // Handle RegExp rules.
          if (Object.prototype.toString.call(r.rule) === '[object RegExp]') {
            didPass = r.rule.test(evt);
          }

          // Handle Function rules.
          if (typeof r.rule === 'function') {
            didPass = r.rule(evt);
          }

          if (!didPass) {
            this.notification.error(r.message);
            field.errors.push(r.message);
            isValid = false;
          }
        });
      }
    });

    // Validatetab.
    if (tab) {
      this.validateTab(tab);
    }

    return isValid;
  }

  /**
   * Validates named tab.
   *
   * @param name {String} - Tab key.
   */
  validateTab(name) {
    const tab = this.tabs[name];
    let isValid = true;

    tab.fields.forEach(key => {
      const field = this.fields[key];

      if (field && field.errors.length > 0) {
        isValid = false;
      }
    });

    this.tabs[name].isValid = isValid;
  }

  clearValidationErrors() {
    // Clear Field Validation Errors.
    Object.keys(this.fields).forEach(key => {
      this.fields[key].errors = [];
      this.fields[key].isDirty = false;
    });

    // Clear Tab Validation Errors.
    Object.keys(this.tabs).forEach(key => {
      this.tabs[key].isValid = null;
    });
  }

  reduceSlicers() {
    let slicers = [];

    if (this.primarySlicer) {
      slicers.push(this.primarySlicer);
    }

    this.compBackupSlicers.forEach(name => {
      if (name !== '' && !slicers.includes(name)) {
        slicers.push(name);
      }
    });
    slicers = slicers.map(name => ({id: name}));

    return slicers;
  }

  blackoutSlicer(eventId, assetId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/blackout-slicer', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({event_id: eventId, asset_id: assetId}),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (!res.error) {
                if (res.data.marked) {
                  this.notification.success('Asset Marked As Complete');
                } else {
                  // Attempt a blackout call
                  const url = `https://${res.data.r}:${res.data.p}/blackout`;
                  const params = {
                    cnonce: res.data.c,
                    timestamp: res.data.t,
                    sig: res.data.s,
                    __upl_event_id: eventId,
                  };
                  const data = JSON.stringify(params);
                  $.ajax({
                    url,
                    method: 'POST',
                    timeout: 10000,
                    data,
                  })
                    .done(() => {
                      this.notification.success('Slicer Blackout Complete');
                    })
                    .fail(error => {
                      this.notification.error(`Error Calling Blackout: ${error.statusText}`);
                    });

                  resolve();
                }
              }
            });
          } else {
            this.notification.error('Error Authorizing Blackout');
            reject(new Error('Error Authorizing Blackout'));
          }
        })
        .catch(err => {
          this.notification.error(err);
          reject(err);
        });
    });
  }

  saveAsset(assetId) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch('/live-event/save-asset', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({asset_id: assetId}),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (!res.error) {
                this.notification.success('Asset saved');
                resolve(res);
              }
            });
          }
        })
        .catch(err => {
          this.notification.error(err);
          reject(err);
        });
    });
  }

  cleanupNewEvent() {
    this.isLeavingNewRoute = false;
    this.isNew = false;
    this.clearValidationErrors();
    this.origEvent = null;
    this.fromCalendar = false;

    this.tabs.liveEventSlicers.markedForDeletion = [];
    this.tabs.liveEventMetadata.keysToDelete = [];
    this.tabs.liveEventPodFormat.podsToDelete = [];
    this.tabs.liveEventMetadata.edits = {};
    this.tabs.liveEventMetadata.schema = null;
  }

  /**
   * Starts an interval that will query `getConflicts()` at every `POLL_EVERY_MS`.
   */
  pollSlicerConflicts() {
    return setInterval(() => {
      if (!this.model || !this.model.slicers || !this.model.slicers.length) {
        return;
      }

      this.getConflicts();
    }, POLL_INTERVAL);
  }

  /**
   * Get slicer conflicts for `this.model` and resolve them to `this._conflicts`.
   */
  getConflicts() {
    const p = this.getSlicerConflicts(this.model);

    p.then((data: any) => {
      this._conflicts = data;
      this.loadingConflicts = false;
    });

    return p;
  }

  /**
   * Determine if session has permissions to delete `event`.
   *
   * @param {Object} event
   * @param {Object} session
   * @return {Boolean} has permission or not.
   */
  canDelete(event, session) {
    // Can only view/operate if not event owner.
    if (session.sessionInfo.ownerID !== event.owner) {
      return false;
    }

    return this.sessionCreate;
  }

  setRecordIndex(eventId) {
    if (Array.isArray(this.data)) {
      this.data.forEach((event, index) => {
        if (event.id === eventId) {
          this.recordIndex = index;
        }
      });
    } else {
      this.data = [];
    }
  }

  setModel(model) {
    this.model = !model ? null : deepCopy(model);
  }

  setOrigEvent(event) {
    this.origEvent = !event ? null : deepCopy(event);
  }

  private evalFieldDirtyState(fieldName: string) {
    if (!this.origEvent || !this.model) {
      return false;
    }

    const field: Field = this.fields[fieldName];

    field.isDirty = this.origEvent[fieldName] !== this.model[fieldName];

    return field.isDirty;
  }
}
