import {AD_BREAK_WARNING_LIST_SIZE} from 'apps/cms/routes/constants';
import {BindingEngine} from 'aurelia-binding';
import {DialogService} from 'aurelia-dialog';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {InputValidationCustomAttribute} from 'custom-attributes/input-validation';
import {basicSetup} from 'decorators';
import {Notification} from 'resources/notification/service';
import {EDITOR_FIELD_NAMES, GENERAL_KEYS} from '../models/constants';
import {LiveEventsService} from '../services/live-events-service';

interface General {
  autoAssetExpiry: number;
  autoAssetExpiryOption: number;
  postSlateDuration: number;
  postSlateDurationOption: number;
  vodAssetExpiry: number;
  vodAssetExpiryOption: number;
  liveEventAdBreakWarning: number;
  liveEventSyndicationAutoStart: string;
  liveEventRequireTesting: boolean;
  liveEventSecretDisplay: string;
  liveEventSecretType: number;
  liveEventWebhook: string | null;
}

@basicSetup
@autoinject()
export class LiveEventsGeneral {
  readonly successCreatedSecret = 'Secret was successfully created';
  readonly successDeletedSecret = 'Secret was successfully deleted';
  readonly secretTypeNone = 0;
  readonly secretTypeAuthToken = 1;
  readonly secretTypeSignature256 = 2;

  public addBreakOptions: {text: string; value: number}[] = [];
  public syndicationAutoStartOptions = [
    'Off',
    'Test',
    'Live',
  ];

  public generalPristine: General;
  public isLoading: boolean;
  public isSaving: boolean;
  public liveEvents;
  public isDataDirty: boolean;
  public general: General = {
    autoAssetExpiry: 0,
    autoAssetExpiryOption: -1,
    liveEventAdBreakWarning: 0,
    liveEventSyndicationAutoStart: 'Off',
    liveEventRequireTesting: false,
    liveEventSecretDisplay: '',
    liveEventSecretType: 0,
    liveEventWebhook: null,
    postSlateDuration: 0,
    postSlateDurationOption: -1,
    vodAssetExpiry: 0,
    vodAssetExpiryOption: -1,
  };

  // todo: add the modal type, need namespace to type conversion
  public testUrlResultModal;
  public testUrlResultDialog: any;
  public testUrlResults;
  public checkDirty: boolean;
  public validationAttr: InputValidationCustomAttribute;
  public disableSecretButtons: boolean = false;
  public deleteTC: any;
  public regenerateTC: any;
  public adBreakSelection: string;
  public publishingAutoStartSelection: string;

  constructor(
    public bindingEngine: BindingEngine,
    public liveEventsService: LiveEventsService,
    public notification: Notification,
    protected dialogService: DialogService,
  ) {
    for (let i = 0; i < AD_BREAK_WARNING_LIST_SIZE; i++) {
      this.addBreakOptions.push({
        text: i === 0 ? 'None' : `${i}`,
        value: i,
      });
    }
  }

  public getData() {
    return new Promise((resolve, reject) => {
      this.liveEventsService
        .getLiveEventsSettings()
        .then(res => {
          this.liveEvents = res || {};
          GENERAL_KEYS.forEach(key => {
            this.general[`${_.camelCase(key)}`] = _.get(this.liveEvents, key);
          });
          this.generalPristine = _.cloneDeep(this.general);
          this.adBreakSelection = this.general.liveEventAdBreakWarning
            ? this.general.liveEventAdBreakWarning.toString()
            : '0';
          resolve();
        })
        .catch(err => {
          // show error on the page
          this.notification.error('Error loading live event generals');
          reject(err);
        });
    });
  }

  public isValidUrl(): void {
    const url = this.general.liveEventWebhook || '';
    let v = true;
    if (!url.length) {
      this.general.liveEventWebhook = null;
      v = true;
    } else {
      v = this.validationAttr.externalUrlValidation(this.general.liveEventWebhook);
    }
    this.liveEventsService.validate('liveEventWebhook', v);
  }

  public validate = (key): void => {
    const v = this.validationAttr.positiveNumValidation(_.get(this.general, key));
    this.liveEventsService.validate(key, v);
  };

  public validateSecretCreated(): void {
    let valid = true;
    if (this.general.liveEventSecretType) {
      valid = this.general.liveEventSecretDisplay.length > 0;
    }
    this.liveEventsService.validate('liveEventSecretType', valid);
  }

  public get canSave(): boolean {
    // necessary here: has to assign to a variable to work
    this.general.liveEventAdBreakWarning = parseInt(this.adBreakSelection, 10);
    const leData = !this.liveEventsService.checkIsEqual(
      this.liveEventsService.dataPristine,
      this.liveEventsService.cache,
    );
    const generalData = !this.liveEventsService.checkIsEqual(this.general, this.generalPristine);
    return (leData || generalData) && this.liveEventsService.isValid();
  }

  public testUrl() {
    this.liveEventsService
      .testUrl(this.general.liveEventWebhook)
      .then(resps => {
        this.testUrlResults = resps;
        this.testUrlResultDialog.show();
        // this.testUrlResultModal.open();
      })
      .catch(() => {
        // TODO: SHOW ERROR ON UI
        this.notification.error('There was an error testing url');
      });
  }

  public saveData(): Promise<any> {
    this.isSaving = true;
    return new Promise((resolve, reject) => {
      const isSetTimeSelected = this.general.postSlateDurationOption === 1;
      const isAutoExpirySetTimeSelected = this.general.autoAssetExpiryOption === 1;
      const isVODExpirySetTimeSelected = this.general.vodAssetExpiryOption === 1;
      const dataToSend = {...this.liveEvents};

      Object.keys(this.general).forEach(key => {
        dataToSend[_.snakeCase(key)] = _.get(this.general, key);
      });

      this.liveEventsService
        .saveLiveEvents({
          EDITOR_FIELD_NAMES,
          ...dataToSend,
          isAutoExpirySetTimeSelected,
          isSetTimeSelected,
          isVODExpirySetTimeSelected,
        })
        .then(() => {
          this.generalPristine = _.cloneDeep(this.general);
          this.notification.success('Changes saved successfully.');
          this.isDataDirty = false;
          resolve();
        })
        .catch(e => {
          const msg = `${e}`;
          this.notification.error(msg);
          reject(e);
        })
        .finally(() => {
          this.isSaving = false;
        });
    });
  }

  public canDeactivate() {
    // update live events data cache
    const dataToSend = {...this.liveEvents};
    Object.keys(this.general).forEach(key => {
      dataToSend[_.snakeCase(key)] = _.get(this.general, key);
    });

    if (this.liveEventsService.cache) {
      this.liveEventsService.cache = dataToSend;
    }
  }

  public async openSecretModal(secret) {
    let sharedModel: any;

    sharedModel = {secret};

    await this.dialogService
      .open({
        model: {
          sharedModel,
          bodyViewModel: PLATFORM.moduleName('apps/cms/routes/settings/live-events/modals/secret-body'),
          footerEnable: true,
          footerViewModel: PLATFORM.moduleName('apps/cms/routes/settings/live-events/modals/secret-footer'),
          keyboard: ['Escape'],
          size: 'medium',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed(() => {
        sharedModel = null;
      });
  }

  public async generateSecret() {
    try {
      const result = await this.liveEventsService.createSecret();
      if (result.error) {
        this.notification.error(result.message);
      } else {
        this.notification.success(this.successCreatedSecret);
        this.general.liveEventSecretDisplay = '********';
        this.validateSecretCreated();
        await this.openSecretModal(result.secret);
      }
    } catch (e) {
      this.notification.error(e.message);
    }
  }

  public async regenerateSecret() {
    try {
      this.disableSecretButtons = true;
      const result = await this.liveEventsService.updateSecret();
      if (result.error) {
        this.notification.error(result.message);
      } else {
        this.notification.success(this.successCreatedSecret);
        await this.openSecretModal(result.secret);
      }
    } catch (e) {
      this.notification.error(e.message);
    } finally {
      this.disableSecretButtons = false;
      if (this.regenerateTC) {
        this.regenerateTC.hide();
      }
    }
  }

  public async deleteSecret() {
    try {
      this.disableSecretButtons = true;
      await this.liveEventsService.deleteSecret();
      this.notification.success(this.successDeletedSecret);
      this.general.liveEventSecretDisplay = '';
      this.validateSecretCreated();
    } catch (e) {
      this.notification.error(e.message);
    } finally {
      this.disableSecretButtons = false;
      if (this.deleteTC) {
        this.deleteTC.hide();
      }
    }
  }
}
