import {computedFrom, inject} from 'aurelia-framework';
import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';
import {ParamExpansionSettingService} from './parameter-expansion/services/parameter-expansion-service';

@inject(Notification, ParamExpansionSettingService, SessionService)
export class BaseSettingsUtils {
    public isLoading: boolean;
    public isSaving: boolean;
    public model;
    public entitlements: string[];
    public isAdmin: boolean;

    constructor(
        public notification: Notification,
        public paramExpansionSettingService: ParamExpansionSettingService,
        public sessionService: SessionService,
    ) {
        this.isLoading = true;
        this.isSaving = false;

        // This is where the data from the /settings/all/ endpoint is stored
        this.model = undefined;
    }

    /* Inherit this class to load your properties */
    // tslint:disable-next-line:no-empty
    public initializeProperties() {}

    public loadData() {
        return this.paramExpansionSettingService
            .getSettings()
            .then(data => {
                this.model = data;
                return this.sessionService.check().then(resp => {
                    this.entitlements = _.get(resp, 'entitlements', []);
                    this.isAdmin = _.get(resp, 'perms', []).indexOf('admin') > -1;
                    this.initializeProperties();
                    this.isLoading = false;
                });
            })
            .catch(() => {
                this.notification.error('Loading Setting Error!');
            });
    }

    public attached() {
        return this.loadData();
    }

    /* ---------------------------------------------------------------------- *\
     * Computed Properties
    \* ---------------------------------------------------------------------- */

    @computedFrom('isSaving')
    get saveBtnText() {
        return this.isSaving ? 'Saving...' : 'Save';
    }
}
