import {LogManager} from 'aurelia-framework';
import {validate, ValidationError} from 'class-validator';
import {MultiCdnSettingsConfig} from '../models/multi-cdn-settings-config';

const log = LogManager.getLogger('multi-cdn-util');

export class MultiCdnUtil {
    public static defaultRuleSetName = 'catch_all';
    public static defaultPresetName = 'default';
    public static defaultCdn = 'edgecast';

    public static validate(config: MultiCdnSettingsConfig): Promise<any> {
        return new Promise((resolve, reject) => {
            validate(config, {skipMissingProperties: false})
                .then((errs: ValidationError[]) => {
                    if (errs.length) {
                        errs.forEach(err => log.error(`${err}`));
                    }
                    resolve(errs);
                })
                .catch(er => {
                    log.error('Validation Error:', er);
                    reject(new Error('Validation Error'));
                });
        });
    }
}
