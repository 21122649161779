import {copyToClipboard} from '@bindable-ui/bindable';
import {autoinject, bindable} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Notification} from 'resources/notification/service';
import {Util} from 'services/util';
import parameterViewerCss from './parameter-viewer.css';

@autoinject()
export class ParameterViewer {
    public id;
    public styles = parameterViewerCss;

    @bindable
    public label: string;

    @bindable
    public url: string;

    public fullUrl: string;
    public urlOnly: string;
    public rows = [];
    public cols = [
        {
            colClass: 't175',
            colHeadName: 'parameter',
            colHeadValue: 'Parameter',
        },
        {
            colClass: 't450',
            colHeadName: 'value',
            colHeadValue: 'Value',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
            ),
        },
    ];

    constructor(public notification: Notification) {
        this.id = Util.generateGuid();
        this.styles = parameterViewerCss;
    }

    public urlChanged(newVal: string) {
        this.urlOnly = '';
        if (newVal) {
            if (newVal.includes('?')) {
                // parses url such as /path?param1=val1&param2=val2
                this.parseURL(newVal, '&');
            } else if (newVal.includes(';')) {
                // parses url such as /path;param1=val1;param2=val2
                this.parseURL(newVal, ';');
            } else {
                this.urlOnly = decodeURIComponent(newVal);
                this.rows = [];
            }
        }
    }

    public parseURL(newVal: string, separator: string) {
        const urlAndParams = newVal.split(separator === '&' ? '?' : separator);
        this.urlOnly = urlAndParams[0];
        if (urlAndParams.length === 1) {
            return;
        }
        let params;
        // length is 2 in case of split by '?', splitting query string by '&'.
        if (urlAndParams.length === 2) {
            params = urlAndParams[1].split(separator);
        } else {
            params = urlAndParams.slice(1);
        }
        this.rows = params.sort().map(keyVal => {
            const tokens = keyVal.split('=');
            const parameter = decodeURIComponent(tokens[0]);
            const value = decodeURIComponent(tokens[1]).split(separator).join('\n');

            const row = {parameter, value};
            if (parameter === 'cust_params') {
                (row as any).valueTip = value;
                (row as any).valueContentMaxHeight = '500px';
            }
            return row;
        });
    }

    public copyRawRequestURL(url) {
        this.fullUrl = url;
        return copyToClipboard(this.fullUrl, this.notification.success('Request URL copied to clipboard'));
    }
}
