import {CToastsService, CTipActions, CTableCol} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {IdsClient} from '../model';
import {IdsSettingsService} from '../service';

@autoinject
export class RedirectUri {
    public redirectUrl: any[] = [];
    public clientSettings: IdsClient;
    public rowSelected: any[] = [];
    public redirecturis: any[] = [];
    public createTip: any;
    public redirectUrlDict: any[] = [];
    constructor(private notification: CToastsService, public IdsSetting: IdsSettingsService) {}

    public async activate() {
        await this.getRedirectUri();
    }

    public createErrMsg: string = '';
    public newUri: string = '';
    public urlCols: CTableCol[] = [
        {
            checkChanged: (row) => this.trackSelectedUri(row),
            colClass: 't30',
            colHeadName: 'selected',
            colHeadSelectedChanged: (isChecked) => this.toggleSelectAll(isChecked),
            colHeadSelectedVal: false,
            colHeadValue: 'Select',
            colWidth: 30,
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
        },
        {
            colHeadName: 'id',
            colHeadValue: 'Redirect URL',
        },
    ];

    public trackSelectedUri(row) {
        this.rowSelected = [];
        if (row.selected) {
            this.rowSelected.push(row.selected);
        }
    }

    public toggleSelectAll(isChecked) {
        this.urlRows.forEach(uri => {
            if (isChecked) {
                uri.selected = true;
            } else {
                uri.selected = false;
            }
        });
    }

    public createTipActions: CTipActions = {
        onHide: () => {
            this.createErrMsg = '';
            this.newUri = '';
        },
    };

    public uriWithRemovedUrl = [];

    public async removeUriSelected() {
        this.uriWithRemovedUrl = [];
        this.uriWithRemovedUrl = this.urlRows.filter((row) => row.selected === true);
        this.redirectUrl = [];
        for (const uri in this.uriWithRemovedUrl) {
            this.redirectUrl.push(this.uriWithRemovedUrl[uri]['id']);
        }
        if (this.redirectUrl.length) {
            this.redirectUrlDict = this.redirectUrl;
        }
        try {
            const resp = await this.IdsSetting.deleteIdsSettings(this.redirectUrlDict);
            // @ts-ignore
            if (resp) {
                this.notification.success('Removed');
            } else {
                this.notification.error('Not-Removed');
            }
        } catch (error) {
            this.createErrMsg = 'An error occurred.';
            this.notification.error(error);
        }
        await this.getRedirectUri();
    }

    public urlRows = [];

    public async addRedirectUri() {
        if (!this.newUri.length) {
            this.createErrMsg = 'URI is required';
            this.notification.error('URI is required');
            return;
        }

        this.redirecturis = this.newUri.split(/[,,;]/);

        try {
            const resp = await this.IdsSetting.postIdsSettings(this.redirecturis);
            if (resp) {
                this.notification.success('Added');
            } else {
                this.notification.error('An error occured.');
            }
        } catch (error) {
            this.createErrMsg = 'An error occurred.';
            this.notification.error(error);
        } finally {
            this.newUri = '';
            this.createTip.hide();
        }
        await this.getRedirectUri();
    }

    public async getRedirectUri() {
        try {
            await this.IdsSetting.getIdsSettings();
            this.redirectUrl = this.IdsSetting.clientSettings.redirect_uris;
            this.urlRows = [];
            for (const data in this.redirectUrl) {
                this.urlRows.push({
                    id: this.redirectUrl[data],
                });
            }
        } catch (error) {
            this.notification.error(error);
        }
    }
}
