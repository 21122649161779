import {Type} from 'class-transformer';
import {IsString, ValidateNested} from 'class-validator';

export class TypeConfigResponseItem {
  // list of Providers obtained from Services Endpoint

  @IsString()
  public text: string;

  @IsString()
  public value: string;
}

export class TypeConfigResponseCollection {
  @ValidateNested()
  @Type(() => TypeConfigResponseItem)
  public items: TypeConfigResponseItem[];
}
