import {bindable, computedFrom} from 'aurelia-framework';

export class RecordValue {
    @bindable
    prop;

    @bindable
    record;

    @bindable
    getValue;

    @computedFrom('prop', 'record')
    get val() {
        const {record} = this;

        return this.getValue({record});
    }
}
