import {MsToHmsValueConverter} from '@bindable-ui/bindable';
import moment from 'moment';

import {Column} from 'resources/data-table';

import {BaseEventSingle} from './base';

export class MarkerLogs extends BaseEventSingle {
    columns = [
        new Column('Type', 'type', {
            defaultValue: 'segment',
            truncate: true,
            width: 190,
            colClass: 't-190',
        }),
        new Column('Tag', 'tag'),
        new Column('Event Time', null, {
            isSortable: false,
            getValue: item => {
                let timeStart = 0;

                if (this.model.actual_start) {
                    timeStart = this.model.actual_start;
                } else if (this.model.testing_start) {
                    timeStart = this.model.testing_start;
                }

                return MsToHmsValueConverter.transform(item.ts - timeStart);
            },
            truncate: true,
            width: 120,
            colClass: 't-120',
        }),
        new Column('Date/Time', 'ts', {
            getValue(item) {
                if (item[this.key] !== undefined) {
                    return moment(item[this.key], 'x').format('MMM D, YYYY [at] h:mm A');
                }
                return this.defaultValue;
            },
            truncate: true,
            width: 215,
            colClass: 't-215',
        }),
    ];
}
