exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1R-mK table{max-width:100%!important}.eGgAk{cursor:pointer;float:right;font-size:12px}", ""]);

// exports
exports.locals = {
	"parameterViewerContent": "_1R-mK",
	"copy": "eGgAk"
};