import {CTableRowSelectable} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {PluginResponseItem} from '../../../models/plugin';

export class PluginListTableRow extends PluginResponseItem implements CTableRowSelectable {
  public id: string;
  public order: number;
  public selected?: boolean;
}

export enum PluginTableColHeadValue {
  creation_time = 'Date Created',
  slicer_plugin = 'Slicer Plugin ID',
  slicer_plugin_version = 'Slicer Plugin Version',
  status = 'Status',
}
