import {Type} from 'class-transformer';
import {IsInt, IsString, ValidateNested} from 'class-validator';
import {IHyperion, IMSODesc, IMSOId, IMSOSession, IMSOTotalItems} from './mso';

// Hosting Location common properties

interface IMSOHostingLocation {
    /**
     * The name label for this hosting location
     */
    name: string;

    /**
     * The location (or region) label for this hosting location
     */
    location: string;

    /**
     * The type for this hosting location (cloud/pop)
     */
    type: string;

    /**
     * The environment for this hosting location (dev/prod)
     */
    environment: string;
}

// Hosting Location response: get

export class MSOHostingLocationResponseItem implements IMSOHostingLocation, IMSODesc, IHyperion, IMSOId {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsString()
    public desc: string;

    @IsString()
    public id: string;

    @IsString()
    public name: string;

    @IsString()
    public location: string;

    @IsString()
    public type: string;

    @IsString()
    public environment: string;
}

export class MSOHostingLocationResponseItems implements IHyperion, IMSOTotalItems, IMSOSession {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsString()
    public cms_session_id_fingerprint: string;

    @ValidateNested()
    @Type(() => MSOHostingLocationResponseItem)
    public items: MSOHostingLocationResponseItem[];

    @IsInt()
    public total_items: number;
}
