import {autoinject, LogManager} from 'aurelia-framework';
import {Acceo} from 'services/acceo';

import {DEFAULT_PER_PAGE_LIMIT} from '../../live-channels/models/defaults';

import {FailoverGroupSlicer, FailoverGroupSlicersResponse, Meta, SlicerFilterParams} from '../models/failover-group';

const log = LogManager.getLogger('failover-group-slicer-service');

@autoinject()
export class FailoverGroupSlicerService {
  public meta: Meta = {};
  public params: SlicerFilterParams = {};

  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;

  public slicers: FailoverGroupSlicer[] = [];

  constructor(public acceo: Acceo) {}

  public async getMoreFailoverGroupSlicers() {
    const params = _.cloneDeep(this.params);
    delete params.since;

    return this.getFailoverGroupSlicers(params, true);
  }

  public async getFailoverGroupSlicers(
    params: SlicerFilterParams = null,
    isLoadMore: boolean = false,
  ): Promise<FailoverGroupSlicer[]> {
    if (this.isLoading || this.isLoadingMore) {
      return this.slicers;
    }

    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (!isLoadMore) {
      this.isLoading = true;
      this.slicers = [];
      delete params.page;
    } else {
      if (!this.meta.hasMore) {
        return this.slicers;
      }

      const {total = 0} = this.meta;

      if (params.page * params.page_size >= total) {
        return this.slicers;
      }

      this.isLoadingMore = true;
      params.page = (params.page || 1) + 1;
    }

    let slicers = [];
    this.params = _.cloneDeep(params);

    try {
      const url = `/api/v4/groups/slicers/?${$.param(params)}`;
      const res = await this.acceo.get(FailoverGroupSlicersResponse)(url);

      this.slicers = _.uniqBy(this.slicers.concat(res.items), 'id');
      slicers = this.slicers;

      this.meta.total = res.total_items;
      this.meta.showing = slicers.length;
      this.meta.limit = params.page_size || null;
      this.meta.hasMore = slicers.length < res.total_items;
    } catch (err) {
      log.error(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }

    return slicers;
  }

  /**
   * Search the slicers
   *
   * @param searchQuery Optional search term that comes from the sidebar
   */
  public async searchSlicers(searchQuery?: string) {
    const params: SlicerFilterParams = {};

    if (searchQuery) {
      params.search = searchQuery;
    }

    params.page = 1;

    return this.getFailoverGroupSlicers(params);
  }
}
