import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable, containerless, LogManager} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {Notification} from 'resources/notification/service';
import {CLIPPING_CONFIG_EVENT} from '.';
import {ClippingConfig} from './models/clipping-config';
import {ClippingSettingService} from './services/clipping-service';
import {ClippingUtil} from './services/clipping-util';

const log = LogManager.getLogger('clipping-profile-create');

@containerless
@autoinject()
export class ClippingProfileCreate {
    @bindable public configs: ClippingConfig[];
    public tabs;
    public isSaving = false;
    public addTip;
    public newUnsavedConfig: string;
    public config: ClippingConfig;
    public newConfig: ClippingConfig;
    public dupConfig: ClippingConfig;
    public subscriptions: Subscription[] = [];
    public newConfigErrors: ValidationError[];
    public dupConfigErrors: ValidationError[];

    constructor(
        public eventAggregator: EventAggregator,
        public notification: Notification,
        public clippingProfileService: ClippingSettingService,
    ) {
        this.tabs = [
            {id: 'clipping-profile-new', label: 'New', selected: true},
            {id: 'clipping-profile-dup', label: 'Duplicate'},
        ];
        this.formInit();
    }

    public attached() {
        this.subscriptions.push(
            this.eventAggregator.subscribe('tab.change-clipping-profile-new', () => {
                this.config = this.newConfig;
            }),
        );
        this.subscriptions.push(
            this.eventAggregator.subscribe('tab.change-clipping-profile-dup', () => {
                this.config = this.dupConfig;
            }),
        );
        this.subscriptions.push(
            this.eventAggregator.subscribe(CLIPPING_CONFIG_EVENT.NewConfigSaved, () => {
                this.newUnsavedConfig = null;
            }),
        );
    }

    public formInit() {
        this.newConfig = new ClippingConfig();
        this.newConfig.skipValidate = true;
        this.dupConfig = new ClippingConfig();
        this.config = this.newConfig;
    }

    public configsChanged(newValue: ClippingConfig[]) {
        if (newValue && newValue.length) {
            this.dupConfig.duplicateOf = newValue[0].id;
        }
    }

    public createDuplicate() {
        return new Promise((resolve, reject) => {
            ClippingUtil.validate(this.dupConfig)
                .then(errs => {
                    this.dupConfigErrors = errs;
                    if (errs.length) {
                        return resolve();
                    }
                    this.addTip.hide();
                    this.isSaving = true;
                    const {desc, duplicateOf} = this.dupConfig;
                    this.clippingProfileService
                        .duplicateProfile(desc, duplicateOf)
                        .then(cfg => {
                            this.eventAggregator.publish(CLIPPING_CONFIG_EVENT.ConfigCreated, {
                                cfg,
                                isNew: false,
                                desc,
                            });
                            this.notification.success('Duplicate created successfully.');
                            this.formInit();
                            resolve();
                        })
                        .catch(e => {
                            log.error(`Error creating duplicate config: ${e}`);
                            this.notification.error('Error creating a duplicate of this clipping profile.');
                            reject(e);
                        })
                        .finally(() => {
                            this.isSaving = false;
                        });
                })
                .catch(err => {
                    log.error(`Validation Error: ${err}`);
                    reject(err);
                });
        });
    }

    public createNew() {
        this.newConfig.skipValidate = false;
        return new Promise((resolve, reject) => {
            ClippingUtil.validate(this.newConfig)
                .then(errs => {
                    if (errs.length) {
                        this.newConfigErrors = errs;
                        return resolve();
                    }
                    this.addTip.hide();
                    this.isSaving = true;
                    const {desc} = this.newConfig;
                    this.clippingProfileService
                        .createProfile(desc)
                        .then(cfg => {
                            this.eventAggregator.publish(CLIPPING_CONFIG_EVENT.ConfigCreated, {
                                cfg,
                                isNew: false,
                                desc,
                            });
                            this.notification.success('Clipping Profile created successfully.');
                            this.formInit();
                            resolve();
                        })
                        .catch(e => {
                            log.error(`Error creating clipping profile: ${e}`);
                            this.notification.error('Error creating clipping profile.');
                            reject(e);
                        })
                        .finally(() => {
                            this.isSaving = false;
                        });
                })
                .catch(err => {
                    log.error(`Validation Error: ${err}`);
                    reject(err);
                });
        });
    }

    public detached() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }
}
