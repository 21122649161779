import 'fetch';

import {bindable, computedFrom, inject} from 'aurelia-framework';

import {CmsHttpClient} from '../../services/cms-http-client';

@inject(CmsHttpClient)
export class SlatePicker {
    @bindable title;
    @bindable dirty;
    @bindable slateId;
    @bindable slateLib;
    @bindable ownerId;
    @bindable NO_SLATE_OBJ;
    @bindable enabled = true;
    @bindable callbacks;

    constructor(cmsHttpClient) {
        this.httpClient = cmsHttpClient.httpClient;

        this.gettingSlateList = false;
        this.slateList = [];
        this.noSlate = true;
        this.NO_SLATE_OBJ = {
            id: -1,
            desc: 'Account Default Slate',
            poster_url: 'assets/images/_placeholder/no-slate.png',
        };

        this.LOAD_SLATE_OBJ = {
            id: -2,
            desc: 'Loading Slate...',
            poster_url: 'assets/images/_placeholder/no-slate.png',
        };

        this.ERROR_SLATE_OBJ = {
            id: -3,
            desc: 'Error Loading Slate',
            poster_url: 'assets/images/_placeholder/no-slate.png',
        };

        this.slate = this.NO_SLATE_OBJ;
    }

    attached() {
        if (!this.ownerId) {
            this.getSlateList();
        }
        this.uid = this.title.replace(' ', '-').toLowerCase();
    }

    ownerIdChanged() {
        if (this.ownerId) {
            this.getSlateList();
        }
    }

    // We need this isEnabled - so we can tell the difference between loading enabled and
    // component enabled binding variable - we don't want to change the binding override
    @computedFrom('slate', 'enabled')
    get isEnabled() {
        return !(this.slate === this.LOAD_SLATE_OBJ) && this.enabled;
    }

    @computedFrom('slate')
    get isLoading() {
        return this.slate === this.LOAD_SLATE_OBJ;
    }

    slateIdChanged() {
        this.setSlate();
    }

    getSlateList() {
        this.gettingSlateList = true;
        this.slate = this.LOAD_SLATE_OBJ;
        this.noSlate = true;
        this.slateList = [];

        const postData = {};
        postData.slate_type = this.slateLib;
        postData.owner = this.ownerId;

        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch('/live-event/slate/list', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData),
                })
                .then(resp => {
                    resp.json()
                        .then(res => {
                            // Workaround since we can't cancel a promise with httpClient -
                            // we just compare the result to see if it is what we were expecting
                            if (this.ownerId) {
                                // If we have an ownerId Bound
                                if (res.slate_owner !== this.ownerId) {
                                    // Don't process the results because it is the wrong return data
                                    return;
                                }
                            }

                            if (res.assets) {
                                // Load assets
                                this.slateList = res.assets;
                            }
                            if (res.default) {
                                // Set default poster url for account default slate
                                this.NO_SLATE_OBJ.poster_url = res.default.poster_url;
                            }
                            this.setSlate();
                            this.gettingSlateList = false;
                            resolve(res);
                        })
                        .catch(() => {
                            this.slate = this.ERROR_SLATE_OBJ;
                            this.noSlate = true;
                            this.slateList = [];
                            this.gettingSlateList = false;
                            reject(new Error('Error fetch slate list.'));
                        });
                });
        });
    }

    setSlate() {
        this.noSlate = true;
        this.slateList.forEach((slate, idx) => {
            if (slate.id === this.slateId) {
                this.slate = slate;
                this.slateId = slate.id;
                this.slateList[idx].active = true;
                this.noSlate = false;
                // No need to do anything else if we found it
            }
        });

        // If we didn't find a valid slate
        if (this.noSlate) {
            this.slate = this.NO_SLATE_OBJ;
        }
    }

    showModal(modalSlate) {
        if (this.isEnabled) {
            if (this.slate.id === -1) {
                this.noSlate = true;
            }
            this.slateList.forEach((slate, index) => {
                this.slateList[index].active = slate.id === this.slate.id;
            });
            this.getSlateList();
            modalSlate.open();
        }
    }

    setActive(index) {
        this.slateList.forEach((slate, idx) => {
            this.slateList[idx].active = false;
        });
        if (index >= 0) {
            this.noSlate = false;
            this.slateList[index].active = true;
        } else {
            this.noSlate = true;
        }
    }

    save(modalSlate) {
        if (this.noSlate) {
            this.slate = this.NO_SLATE_OBJ;
            this.slateId = null;
        } else {
            this.slateList.forEach(slate => {
                if (slate.active) {
                    this.slate = slate;
                    this.slateId = slate.id;
                }
            });
        }
        modalSlate.close();

        if (this.callbacks && this.callbacks.onSave) {
            this.callbacks.onSave(this.slateId);
        }
    }
}
