import {bindable, inject} from 'aurelia-framework';
import {Tip} from './tip';

@inject(Tip)
export class TipContent {
    @bindable
    afterHide;

    @bindable
    modifier = '';

    constructor(tip) {
        this.tip = tip;
        this.additionalClasses = '';
        this.hasLoaded = false;
    }

    bind(context) {
        this.context = context;
        if (this.modifier) {
            this.additionalClasses += this.modifier
                .split(' ')
                .map(mod => `tip--${mod}`)
                .join('  ');
        }
    }

    attached() {
        this.hasLoaded = true;
    }

    hide() {
        this.tip.hideContent(this.context, this.afterHide);
    }
}
