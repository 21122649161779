import {SharedNav} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterConfiguration} from 'aurelia-router';

export enum RouteName {
    SlicerIngestPointList = 'slicerIngestPointList',
    SlicerIngestPointSingle = 'slicerIngestPointSingle',
}

@autoinject()
export class SlicerIngestPointsIndex {
    public router: Router;
    public routes = [
        {
            moduleId: PLATFORM.moduleName('./list/index'),
            name: RouteName.SlicerIngestPointList,
            nav: false,
            route: '',
            title: 'Ingest Points',
        },
        {
            moduleId: PLATFORM.moduleName('./single/index'),
            name: RouteName.SlicerIngestPointSingle,
            nav: false,
            route: ':id',
            title: 'Ingest Point',
        },
    ];

    constructor(public sharedNav: SharedNav) {}

    public attached() {
        this.sharedNav.setNextText('Ingest Points List');
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        this.router = router;
        config.map(this.routes);
    }
}
