import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {
    ContentFields,
    FieldRepresentation,
    Operator,
    PlayBackFields,
    PlayerFields,
    RuleType,
} from '../models/fields-models';
import {MultiCdnSettingsConfig, Rule, RuleSet} from '../models/multi-cdn-settings-config';
import {MultiCdnUtil} from '../services/cdn-util';

const ERROR = {
    isDup: false,
};

export const ERROR_OBJ = {
    field: {...ERROR},
    hasErr: false,
};

interface ErrorObject {
    isDup?: boolean;
    hasErr?: boolean;
}

interface ErrorObjects {
    hasErr: boolean;
    field: ErrorObject;
}

interface ErrorMessages {
    [index: string]: ErrorObjects;
}

@autoinject()
export class RulesTable {
    @bindable
    public multiCdnSettings: MultiCdnSettingsConfig;

    @bindable
    public currentRuleSet: RuleSet;

    @bindable
    public fields: FieldRepresentation[];

    @bindable
    public ruleType: RuleType;

    @bindable
    public validationErrors: ValidationError[];

    @bindable
    public callbacks;

    @bindable
    public rules: Rule[];

    public ruleIsDuplicate: boolean = false;
    public operatorType = Operator;
    public newField: string;
    public newOperator: Operator = Operator.stringEquals;
    public hasErr: boolean;
    public ruleSetNewRule;
    public errorMessages: ErrorMessages = {};

    public addNewRule(): void {
        const newRule = new Rule();
        newRule.field = this.newField;
        newRule.operator = this.newOperator;
        newRule.value = '';
        newRule.ruleType = this.ruleType;

        this.ruleIsDuplicate = false;

        switch (this.ruleType) {
            case RuleType.Content: {
                for (const rule of this.currentRuleSet.contentRules) {
                    if (rule.field.toLowerCase() === this.newField.trim().toLowerCase()) {
                        this.ruleIsDuplicate = true;
                        return;
                    }
                }
                this.currentRuleSet.contentRules.push(newRule);
                this.newField = ContentFields.contentId;
                break;
            }
            case RuleType.Playback: {
                for (const rule of this.currentRuleSet.playbackRules) {
                    if (rule.field.toLowerCase() === this.newField.trim().toLowerCase()) {
                        this.ruleIsDuplicate = true;
                        return;
                    }
                }
                this.currentRuleSet.playbackRules.push(newRule);
                this.newField = PlayBackFields.sessionId;
                break;
            }
            case RuleType.Player: {
                for (const rule of this.currentRuleSet.playerRules) {
                    if (rule.field.toLowerCase() === this.newField.trim().toLowerCase()) {
                        this.ruleIsDuplicate = true;
                        return;
                    }
                }
                this.currentRuleSet.playerRules.push(newRule);
                this.newField = PlayerFields.countryCode;
                break;
            }
        }
        this.newOperator = Operator.stringEquals;
        this.ruleIsDuplicate = false;
        this.ruleSetNewRule.hide();
    }

    public deleteRule(index: number): void {
        switch (this.ruleType) {
            case RuleType.Content: {
                this.deleteSpecificRule(index, this.currentRuleSet.contentRules);
                break;
            }
            case RuleType.Playback: {
                this.deleteSpecificRule(index, this.currentRuleSet.playbackRules);
                break;
            }
            case RuleType.Player: {
                this.deleteSpecificRule(index, this.currentRuleSet.playerRules);
                break;
            }
        }
        this.callbacks.onChange();
    }

    public deleteSpecificRule(index: number, rules: Rule[]): void {
        rules.splice(index, 1);

        let ruleSetIndex = -1;

        this.multiCdnSettings.ruleSets.forEach(ruleSet => {
            if (ruleSet.name === this.currentRuleSet.name) {
                ruleSetIndex += 1;
            }
        });

        if (ruleSetIndex > 0) {
            this.multiCdnSettings.ruleSets[ruleSetIndex] = this.currentRuleSet;
        }
    }

    public checkIsValid(target: string, index: number, ruleType: string): void {
        let rules = [];

        if (ruleType === 'content') {
            rules = this.currentRuleSet.contentRules;
        }
        if (ruleType === 'playback') {
            rules = this.currentRuleSet.playbackRules;
        }
        if (ruleType === 'player') {
            rules = this.currentRuleSet.playerRules;
        }

        const value = _.get(rules[index], target);
        const itemError = {..._.cloneDeep(ERROR_OBJ)};
        this.errorMessages[index] = this.errorMessages[index] || {..._.cloneDeep(ERROR_OBJ)};
        const copy = _.cloneDeep(this.errorMessages[index]);
        this.errorMessages[index] = {
            ...copy,
            [target]: this.validateFieldName(target, value, itemError, rules, index)[target],
            hasErr: this.validateFieldName(target, value, itemError, rules, index).hasErr,
        };
        this.hasErr = this.checkHasErr(this.errorMessages);
    }

    public checkHasErr(errorMessages: ErrorMessages): boolean {
        if (errorMessages) {
            const test = !_.every(Object.keys(errorMessages) || [], index => !_.get(errorMessages[index], 'hasErr'));
            return !!test;
        }
        return false;
    }

    get isDefaultRuleSet() {
        if (this.currentRuleSet) {
            if (this.currentRuleSet.name === MultiCdnUtil.defaultRuleSetName) {
                return true;
            }
        }
        return false;
    }

    public validateFieldName(
        target: string,
        value: string,
        itemError: ErrorObjects,
        rules,
        index?: number,
    ): ErrorObjects {
        if (target === 'field') {
            const dupIndex = [];
            // when index is undefined
            rules.forEach((rule, idx) => {
                if (rule.field === value && idx !== index) {
                    dupIndex.push(idx);
                }
            });
            if (dupIndex.length) {
                dupIndex.forEach(i => {
                    // this if statement didnt initialize the errorObject
                    let ithErrorObj = this.errorMessages[i];
                    if (_.isEmpty(ithErrorObj)) {
                        ithErrorObj = {..._.cloneDeep(ERROR_OBJ)};
                    }
                    ithErrorObj.field.isDup = true;
                    ithErrorObj.hasErr = true;
                    this.errorMessages[i] = ithErrorObj;
                });
                // this line will take care of new param
                itemError.field.isDup = true;
                itemError.hasErr = true;
            } else {
                // this will also take care of the new param
                itemError.field.isDup = false;
                itemError.hasErr = false;
                const indexes = Object.keys(this.errorMessages);
                indexes.forEach(idx => {
                    const ithErrorObj = this.errorMessages[idx];
                    if (_.get(ithErrorObj, 'field.isDup', '')) {
                        ithErrorObj.field.isDup = false;
                        ithErrorObj.hasErr = false;
                    }
                    this.errorMessages[idx] = ithErrorObj;
                });
            }
        }
        return itemError;
    }
}
