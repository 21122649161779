import {Type} from 'class-transformer';
import {IsArray, IsDefined, IsInt, IsString, ValidateNested} from 'class-validator';
import {AdServerType} from '../../ad-server/models/ad-config';

export class AdConfigResponseFormattedValue {
  @IsArray()
  public warnings: string[];

  @IsArray()
  public errors: string[];

  @IsString()
  public info: string;

  @IsString()
  public input: string;

  @IsString()
  public key: string;

  @IsInt()
  public required: number;

  @IsString()
  public text: string;

  @IsString()
  public value: string;
}

export class AdConfigResponse {
  @IsArray()
  public warnings: string[];

  @IsArray()
  public errors: string[];

  @ValidateNested()
  @Type(() => AdConfigResponseFormattedValue)
  public formatted_values: AdConfigResponseFormattedValue[];

  @IsString()
  public name: string;

  @IsString()
  public provider: string;

  @IsString()
  public serverURL: string;

  @IsString()
  public type: AdServerType;

  @IsInt()
  public valid: number;

  @IsInt()
  public testable: number;

  @IsInt()
  public testType: number;

  @IsDefined()
  public values: object;
}
