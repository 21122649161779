import {autoinject, computedFrom} from 'aurelia-framework';
import {LynkDialog, LynkInput} from '@uplynk/lynk-design';
import {TemplatesService} from './services/templates-service';
import {Template, SIZE_OPTIONS} from './models/templates-model';

@autoinject()
export class VodUploaderTemplates {
  public templateDialog: LynkDialog;
  public deleteDialog: LynkDialog;
  public deleteSelectedDialog: LynkDialog;
  public templateName: LynkInput;
  public dialogModel: Template;
  public sizeOptions = SIZE_OPTIONS;
  public deleteing: boolean = false;

  constructor(public services: TemplatesService) {}

  @computedFrom('services.templates')
  public get allSelected() {
    const selected = this.services.templates.filter(x => x.selected === true).length;
    return selected === this.services.templates.length;
  }

  @computedFrom('services.templates')
  public get hasSelected() {
    return !!this.services.templates.filter(x => x.selected === true).length;
  }

  public async activate() {
    try {
      this.services.templates = await this.services.getTemplates();
    } catch (error) {
      this.services.templates = [];
    }

    try {
      this.services.libraries = await this.services.getLibraries();
    } catch (error) {
      this.services.libraries = [];
    }
  }

  public showCreateDialog() {
    this.dialogModel = new Template();
    this.templateDialog.show();
  }

  public showEditDialog(model: Template) {
    this.dialogModel = _.cloneDeep(model);
    this.templateDialog.show();
  }

  public showDeleteDialog(model: Template) {
    this.dialogModel = model;
    this.deleteDialog.show();
  }

  public async deleteTemplate(model: Template) {
    await this.services.deleteTemplate(model);
    this.deleteDialog.hide();
    this.services.templates = await this.services.getTemplates();
  }

  public async saveTempleate() {
    if (this.dialogModel.name.trim().length === 0) {
      this.templateName.state = 'error';
      this.templateName.helpText = 'Template name is missing';
      return;
    }

    const model = _.cloneDeep(this.dialogModel);

    if (!model.id) {
      await this.services.createTemplate(model);
    } else {
      await this.services.updateTemplate(model);
    }

    this.templateDialog.hide();
    this.services.templates = await this.services.getTemplates();
  }

  // eslint-disable-next-line class-methods-use-this
  public handleRequestClose(event: CustomEvent) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    }

    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  public handleClose(event: CustomEvent) {
    event.preventDefault();
    this.templateName.state = undefined;
    this.templateName.helpText = '';
  }

  public onChecked(event, template: Template) {
    const {checked} = event.target;
    template.selected = checked;
    this.services.templates = [...this.services.templates]; // trigger for templates change
  }

  public onCheckedAll(event) {
    const {checked} = event.target;
    this.services.templates.forEach(x => {
      x.selected = checked;
    });
    this.services.templates = [...this.services.templates]; // trigger for templates change
  }

  public async deleteSelected() {
    const selected = this.services.templates.filter(template => template.selected === true);

    this.deleteing = true;
    try {
      await Promise.all(
        selected.map(async template => {
          await this.services.deleteTemplate(template);
        }),
      );
    } catch (err) {
      this.deleteing = false;
    }

    this.deleteSelectedDialog.hide();
    this.deleteing = false;
    this.services.templates = await this.services.getTemplates();
  }
}
