export enum MetadataType {
    DropDown = 'dropdown',
    Text = 'text',
}

export enum IntegrationServiceType {
    Facebook = 'facebook',
    Kaltura = 'kaltura',
    Lakana = 'lakana',
    Twitter = 'twitter',
    YouTube = 'youtube',
}

export enum IntegrationNameType {
    Facebook = 'Facebook',
    Kaltura = 'Kaltura',
    Lakana = 'Lakana',
    Twitter = 'Twitter',
    YouTube = 'YouTube',
}
