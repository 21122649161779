import * as CTableCssJson from '@bindable-ui/bindable/dist/native-modules/components/tables/c-table/c-table.css.json';
import {PLATFORM} from 'aurelia-framework';
import {CTableSelectable} from 'resources/components/c-table-selectable/c-table-selectable';
import {
  CTableColSelectable,
  CTableRowBehavior,
} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {IEncodingProfileTableRow} from './table-models';

export class EncodingProfileTable extends CTableSelectable {
  public rows: IEncodingProfileTableRow[];

  public colsIgnore = [
    '@id',
    '@type',
    'descPill',
    'descPillColor',
    'descPillNoWrap',
    'descPillRight',
    'dr_ray',
    'is_default_live_profile',
    'is_default_system_profile',
    'is_default_vod_profile',
    'live_slicers',
    'lmsig',
    'rate_card_idPill',
    'rate_card_idPillColor',
    'rate_card_idPillNoWrap',
    'rate_card_idPillRight',
    'ray_0',
    'ray_1',
    'ray_2',
    'ray_3',
    'ray_4',
    'ray_5',
    'ray_6',
    'ray_7',
    'ray_8',
    'ray_9',
    'ray_10',
    'ray_11',
    'slicedur',
    'top_ray',
    'top_ray_fps',
    'top_ray_height',
    'top_ray_width',
    'vod_assets',
    '_class',
    'deprecated',
    'failrplay_keymap',
    'fairplay_keymap',
    'identifier',
    'in_prod',
    'parent',
    'part_duration',
    'public',
    'requires_brokers',
    'slicerdur',
    'suppressARayExceptOnIOS',
    'test',
    'version',
    'lastmod',
  ];

  protected colsSelectableDefault: CTableColSelectable[] = [
    {
      colHeadName: 'id',
      colHeadValue: 'ID',
      selected: false,
      sort: true,
    },
    {
      colClass: this.getClass(CTableCssJson.t215),
      colHeadName: 'rate_card_id',
      colHeadValue: 'Rate Card',
      colWidth: 215,
      selected: true,
      sort: true,
      valueConverter: 'defaultValue:"None"',
      view: PLATFORM.moduleName('resources/components/c-td-pill-multiple/c-td-pill-multiple.html'),
      viewModel: PLATFORM.moduleName('resources/components/c-td-pill-multiple/c-td-pill-multiple'),
    },
    {
      colClass: this.getClass(CTableCssJson.t215),
      colHeadName: 'desc',
      colHeadValue: 'Description',
      selected: true,
      sort: false,
      view: PLATFORM.moduleName('resources/components/c-td-pill-multiple/c-td-pill-multiple.html'),
      viewModel: PLATFORM.moduleName('resources/components/c-td-pill-multiple/c-td-pill-multiple'),
    },
    {
      colClass: this.getClass(CTableCssJson.t175),
      colHeadName: 'resolution',
      colHeadValue: 'Resolution',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t90),
      colHeadName: 'top_ray_bit_rate',
      colHeadValue: 'Vid kb/s',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t120),
      colHeadName: 'top_ray_gpu_flag',
      colHeadValue: 'GPU Encode',
      selected: true,
      sort: false,
      valueConverter: 'defaultValue:"Not Allowed" | capitalize:true',
    },
    {
      colClass: this.getClass(CTableCssJson.t90),
      colHeadName: 'top_ray_bframes_max',
      colHeadValue: 'B Frames',
      selected: true,
      valueConverter: 'booleanYesNo',
    },
    {
      colClass: this.getClass(CTableCssJson.t65),
      colHeadName: 'top_ray_passes',
      colHeadValue: 'Pass',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t65),
      colHeadName: 'top_ray_audio_rate',
      colHeadValue: 'Audio',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t65),
      colHeadName: 'slicedur_label',
      colHeadValue: 'Slice',
      selected: true,
      sort: false,
      valueConverter: 'defaultValue:"4"',
    },
    {
      colClass: this.getClass(CTableCssJson.t120),
      colHeadName: 'live_slicers_label',
      colHeadValue: 'Live',
      selected: true,
      sort: false,
      valueConverter: 'notApplicable',
    },
    {
      colClass: this.getClass(CTableCssJson.t120),
      colHeadName: 'vod_assets_label',
      colHeadValue: 'VOD',
      selected: true,
      sort: false,
      valueConverter: 'notApplicable',
    },
  ];

  constructor(protected rowBehavior: CTableRowBehavior, canViewAllCols: boolean = false) {
    super(rowBehavior);
    if (canViewAllCols) {
      this.colsSelectableDefault = this.colsSelectableDefault.concat([
        {
          colHeadName: 'created',
          colHeadValue: 'Date Created',
          selected: false,
          sort: true,
          valueConverter: 'timezoneTimeToStandardDateTimeMS',
        },

        /*
        {
          colHeadName: 'lastmod',
          colHeadValue: 'Date Modified',
          selected: false,
          sort: true,
          valueConverter: 'timezoneTimeToStandardDateTimeMS',
        },
        */
      ]);
    }
    this.colsSelectable = this.colsSelectableDefault;
  }
}
