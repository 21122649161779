import {DialogController} from 'aurelia-dialog';

export class YesNoCancel {
  static inject = [DialogController];

  constructor(controller) {
    this.controller = controller;
    this.defaultModel = {question: 'You have unsaved changes.  Save them before continuing?'};
  }

  activate(model) {
    if (model) {
      this.model = model;
    } else {
      this.model = this.defaultModel;
    }
  }

  handleRequestClose(event) {
    if (event.detail.source === 'overlay' || event.detail.source === 'close-button') {
      event.preventDefault();
    }
    return true;
  }
}
