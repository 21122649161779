import {ConfigLineType} from 'resources/config/models/config-line';
import {IConfigLineTableRow} from 'resources/config/models/config-line-table';
import {IConfigSetting} from 'resources/config/models/config-setting';
import {ConfigTable} from 'resources/config/table';

export class AdServerConfigTable extends ConfigTable {
  public configLineType = ConfigLineType;

  constructor(
    protected configSettings: IConfigSetting[],
    isDuplicateKeySupported: boolean = false,
    isReadOnly: boolean = false,
  ) {
    super(configSettings, isDuplicateKeySupported, isReadOnly);
  }

  protected getConfigSettings(row: IConfigLineTableRow) {
    return this.configSettings.find(setting => setting.key === row.key);
  }

  protected getKeyLabel(row: IConfigLineTableRow) {
    let behavior = '';
    const pfv = 'pfv.';
    const pt = 'pt.';
    if (row.key.substring(0, pfv.length) === pfv) {
      behavior = 'Pass First Valid';
    }
    if (row.key.substring(0, pt.length) === pt) {
      behavior = 'Pass Through';
    }
    if (row.type === ConfigLineType.MACRO) {
      return `${behavior} Macro`;
    }
    const configSetting = this.configSettings.find(setting => setting.key === row.key);
    if (configSetting) {
      return configSetting.text;
    }
    return `${behavior || 'Custom'} Parameter`;
  }

  protected getRowState(row: IConfigLineTableRow) {
    switch (row.type) {
      case ConfigLineType.KVP:
      case ConfigLineType.KVP_AND_COMMENT:
      case ConfigLineType.MACRO:
        return 'success';
      case ConfigLineType.CUSTOM:
        return 'warning';
      case ConfigLineType.MANAGED:
        return 'neutral';
      case ConfigLineType.REQUIRED:
        return 'primary';
      case ConfigLineType.DISALLOWED:
      case ConfigLineType.DUPLICATE:
      case ConfigLineType.UNSUPPORTED:
      default:
        return 'danger';
    }
  }

  protected getParamRowType(row: IConfigLineTableRow, type: string) {
    // cag fix the macro for legacy
    switch (row.type) {
      case ConfigLineType.KVP:
      case ConfigLineType.KVP_AND_COMMENT:
      case ConfigLineType.MACRO:
        if (type !== 'uac') {
          return 'Optional';
        }
        return 'Macro';
      case ConfigLineType.CUSTOM:
        if (type !== 'uac') {
          return 'Managed';
        }
        // TODO - enhance later to further specify the param type (e.g. )
        return 'Custom';
      case ConfigLineType.MANAGED:
        return 'Managed';
      case ConfigLineType.REQUIRED:
        return 'Required';
      case ConfigLineType.DISALLOWED:
      case ConfigLineType.DUPLICATE:
      case ConfigLineType.UNSUPPORTED:
      default:
        // set to Managed if it is not recognized
        return 'Managed';
    }
  }
}
