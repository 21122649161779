import {Type} from 'class-transformer';
import {IsBoolean, IsDefined, IsNotEmpty, IsString, ValidateNested} from 'class-validator';
import {IntegrationNameType} from './enums-config';

export const ERROR_MESSAGE_SELECT_OPTION = 'Facebook page is required';

export class FaceBookCredentials {
    @IsString()
    public expiresIn: string = '';

    @IsString()
    public userID: string = '';

    @IsString()
    public signedRequest: string = '';

    @IsString()
    public accessToken: string = '';
}

export class FaceBookMetaMap {
    @IsString()
    public profile_id: string = '';

    @IsString()
    public description: string = '';

    @IsString()
    public title: string = '';

    @IsDefined({message: ERROR_MESSAGE_SELECT_OPTION})
    @IsNotEmpty({message: ERROR_MESSAGE_SELECT_OPTION})
    @IsString()
    public page_id: string = '';
}

export class FaceBook {
    @ValidateNested()
    @Type(() => FaceBookCredentials)
    public credentials: FaceBookCredentials = new FaceBookCredentials();

    @ValidateNested()
    @Type(() => FaceBookMetaMap)
    public metamap: FaceBookMetaMap = new FaceBookMetaMap();

    @IsString()
    public name: string = IntegrationNameType.Facebook;

    @IsBoolean()
    public enabled: boolean = false;

    public newlyAdded: boolean = false;

    public constructor(init?: Partial<FaceBook>) {
        Object.assign(this, init);
    }
}

export class FaceBookPage {
    @IsString()
    public id: string = '';

    @IsString()
    public name: string = '';

    public constructor(init?: Partial<FaceBookPage>) {
        Object.assign(this, init);
    }
}
