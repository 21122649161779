import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationError} from 'class-validator';
import {dirtyCheckPrompt} from 'decorators';
import * as dragula from 'dragula';
import {Notification} from 'resources/notification/service';
import {OwnerMetaContext, SessionService} from 'services/session';
import {CLIPPING_BUMPERS_EVENT} from './bumpers/index';
import {ClippingConfig, UploadOverlay} from './models/clipping-config';
import {IntegrationNameType, IntegrationServiceType} from './models/enums-config';
import {ClippingSettingService} from './services/clipping-service';
import {ClippingUtil} from './services/clipping-util';

export const CLIPPING_CONFIG_EVENT = {
  ConfigCreated: 'ClippingConfigEvent:ConfigCreated',
  LoadFacebookPages: 'ClippingConfigEvent:LoadFacebookPages',
  NewConfigSaved: 'ClippingConfigEvent:NewConfigSaved',
};
const log = LogManager.getLogger('clipping-setting');

@dirtyCheckPrompt
@autoinject()
export class Clipping {
  public clippingConfigs: ClippingConfig[];
  public canDeactivate;
  public currentClippingConfigPristine: ClippingConfig;
  public displayList: ClippingConfig[] = [];
  public currentClippingConfig: ClippingConfig;
  public pendingConfigSwitch = false;
  public isNew = false;
  public isFormDirty = false;
  public isLoading = false;
  public loadingError = false;
  public isDeleting = false;
  public isSaving = false;
  public config: ClippingConfig;
  public routeName;
  public subscriptions: Subscription[] = [];
  public callbacks;
  public validationErrors: ValidationError[];
  public tabs = {
    details: {hasError: false, ref: {}},
    integrations: {hasError: false, ref: {}},
    metadata: {hasError: false, ref: {}},
  };

  public deleteTip;
  public sortedProfiles = [];
  public drake;

  private errorSavingChangesMessage = 'Error saving changes.';
  private debouncedDirtyCheck = _.debounce(this.doDirtyCheck, 300);

  constructor(
    public eventAggregator: EventAggregator,
    public router: Router,
    public notification: Notification,
    public clippingSettingService: ClippingSettingService,
    public session: SessionService,
  ) {
    this.callbacks = {
      onBlur: () => {
        this.checkTabError();
      },
      onChange: () => {
        this.debouncedDirtyCheck();
      },
    };
  }

  public activate({}, routeConfig) {
    this.routeName = routeConfig.name || '';
  }

  public attached(): Promise<any> {
    // only fetch once, not refetching between tab switches
    if (this.clippingConfigs) return Promise.resolve();
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        CLIPPING_CONFIG_EVENT.ConfigCreated,
        opt =>
          new Promise(resolve => {
            let selectedItem = 0;
            opt.cfg.forEach((profile, index) => {
              if (profile.desc === opt.desc) {
                selectedItem = index;
              }

              // make sure we have a sortedProfile for the new record (or any missing records)
              const found = _.includes(this.sortedProfiles, profile.id);
              if (!found) {
                this.sortedProfiles.push(profile.id);
              }
            });
            this.saveSortedProfiles();

            this.loadProfiles(opt.cfg, selectedItem);
            this.isNew = opt.isNew;
            this.setupDisplayList();
            resolve();
          }),
      ),
    );

    this.subscriptions.push(
      this.eventAggregator.subscribe(
        CLIPPING_CONFIG_EVENT.LoadFacebookPages,
        opt =>
          new Promise(resolve => {
            this.clippingSettingService.getFacebookPageList(opt.profileid).then(resp => {
              this.currentClippingConfig.facebook_page_list = resp;
              this.currentClippingConfigPristine.facebook_page_list = resp;
              resolve();
            });
          }),
      ),
    );

    this.loadingError = false;
    return new Promise((resolve, reject) => {
      this.isLoading = true;
      this.clippingSettingService
        .getSettings()
        .then(resp => {
          this.loadProfiles(resp, 0);
          resolve();
        })
        .catch(e => {
          this.loadingError = true;
          reject(e);
        })
        .finally(() => {
          this.isLoading = false;
          this.getSortedProfileIds();
        });
    });
  }

  // doSetPristine = false for new config. Since it is not yet saved, don't create pristine.
  public setCurrentConfig(config: ClippingConfig, doSetPristine = true) {
    return new Promise((resolve, reject) => {
      if (!config) {
        this.currentClippingConfigPristine = null;
        this.currentClippingConfig = null;
        return resolve();
      }
      if (config && config.integrations) {
        if (config.integrations.youtube && config.integrations.youtube.credentials.refresh_token) {
          config.youTubeAuthenticated = true;
        }
        if (config.integrations.twitter && config.integrations.twitter.credentials.oauth_token) {
          config.twitterAuthenticated = true;
        }
        if (config.integrations.facebook && config.integrations.facebook.credentials.accessToken) {
          config.facebookAuthenticated = true;
        }
      }
      if (doSetPristine) {
        this.currentClippingConfigPristine = config;
      } else {
        this.currentClippingConfigPristine = null;
      }

      this.currentClippingConfig = _.cloneDeep(config);
      this.eventAggregator.publish(CLIPPING_BUMPERS_EVENT.ConfigurationChanged, {
        default_intro: config.default_intro,
        default_library: config.default_library,
        default_outro: config.default_outro,
      });

      if (!this.currentClippingConfig.skipValidate) {
        this.validationErrors = null;
        ClippingUtil.validate(this.currentClippingConfig)
          .then(errs => {
            if (errs.length) {
              this.validationErrors = errs;
            }
            // check for validtion errors even if errs is [], there might be server errors.
            this.checkTabError();
            resolve();
          })
          .catch(err => {
            log.error(`Validation Error: ${err}`);
            reject(err);
          });
      } else {
        this.checkTabError();
        resolve();
      }
    });
  }

  public canSetCurrentConfig(cfg, doSetPristine) {
    // this is called by config nav item select or by new config create event.
    this.pendingConfigSwitch = true;
    return new Promise((resolve, reject) => {
      this.canDeactivate()
        .then(resp => {
          if (resp) {
            this.setCurrentConfig(cfg, doSetPristine);
          }
          this.pendingConfigSwitch = false;
          resolve();
        })
        .catch(e => {
          log.error(e);
          reject(e);
        });
    });
  }

  public uploadOverlay(overlayName: UploadOverlay) {
    return this.clippingSettingService
      .uploadOverlay(overlayName.overlayName, this.currentClippingConfig.id, overlayName.fileList[0])
      .then(() =>
        this.clippingSettingService.getClippingProfile(overlayName.clippingProfileId).then(data => {
          this.clippingConfigs = this.clippingConfigs.map(c => {
            if (c.id === this.currentClippingConfig.id) {
              return data;
            }
            return c;
          });
          this.currentClippingConfig.overlays = _.cloneDeep(data.overlays);
          this.currentClippingConfigPristine.overlays = _.cloneDeep(data.overlays);
        }),
      )
      .catch(e => {
        log.error(e);
        this.notification.error('There was an error uploading your file');
        this.notification.error('Please makes sure your file is less than 8MB and try again');
        throw new Error('Unable to upload file');
      });
  }

  public checkTabError() {
    setTimeout(() => {
      Object.keys(this.tabs).forEach(name => {
        if (this.tabs[name] && this.tabs[name].ref) {
          this.tabs[name].hasError = !!this.tabs[name].ref.querySelector('.form-error > .form-error__message');
        }
      });
    }, 0);
  }

  public isDirty(): boolean {
    if (!this.pendingConfigSwitch && window.location.hash.match(/^#\/settings\/clipping(\/[a-zA-Z0-9-_]*\/?)?$/)) {
      return false;
    }
    if (!this.currentClippingConfig) return false;
    return this.doDirtyCheck();
  }

  public doDirtyCheck(): boolean {
    const currentClippingProfileCleaned = _.cloneDeep(this.currentClippingConfig);
    const pristineClippingProfileCleaned = _.cloneDeep(this.currentClippingConfigPristine);

    // Remove bumpers
    if (pristineClippingProfileCleaned.bumpers) {
      delete pristineClippingProfileCleaned.bumpers;
      delete currentClippingProfileCleaned.bumpers;
    }

    // Facebook Page List (used for displaying a combo box)
    if (pristineClippingProfileCleaned.facebook_page_list) {
      delete pristineClippingProfileCleaned.facebook_page_list;
      delete currentClippingProfileCleaned.facebook_page_list;
    }

    // Remove runtime Integrations properties
    // deleting optional field with value undefined, keeping value [] as is.
    delete pristineClippingProfileCleaned.youTubeAuthenticated;
    delete pristineClippingProfileCleaned.youTubeAuthProcessing;
    delete pristineClippingProfileCleaned.twitterAuthenticated;
    delete pristineClippingProfileCleaned.twitterAuthProcessing;
    delete pristineClippingProfileCleaned.facebookAuthenticated;
    delete pristineClippingProfileCleaned.facebookAuthProcessing;
    delete pristineClippingProfileCleaned.skipValidate;
    delete pristineClippingProfileCleaned.duplicateOf;

    delete currentClippingProfileCleaned.youTubeAuthenticated;
    delete currentClippingProfileCleaned.youTubeAuthProcessing;
    delete currentClippingProfileCleaned.twitterAuthenticated;
    delete currentClippingProfileCleaned.twitterAuthProcessing;
    delete currentClippingProfileCleaned.facebookAuthenticated;
    delete currentClippingProfileCleaned.facebookAuthProcessing;
    delete currentClippingProfileCleaned.skipValidate;
    delete currentClippingProfileCleaned.duplicateOf;

    if (currentClippingProfileCleaned.integrations.facebook === undefined) {
      delete currentClippingProfileCleaned.integrations.facebook;
    }
    if (currentClippingProfileCleaned.integrations.kaltura === undefined) {
      delete currentClippingProfileCleaned.integrations.kaltura;
    }
    if (currentClippingProfileCleaned.integrations.lakana === undefined) {
      delete currentClippingProfileCleaned.integrations.lakana;
    }
    if (currentClippingProfileCleaned.integrations.twitter === undefined) {
      delete currentClippingProfileCleaned.integrations.twitter;
    }
    if (currentClippingProfileCleaned.integrations.youtube === undefined) {
      delete currentClippingProfileCleaned.integrations.youtube;
    }

    if (pristineClippingProfileCleaned.integrations.facebook === undefined) {
      delete pristineClippingProfileCleaned.integrations.facebook;
    }
    if (pristineClippingProfileCleaned.integrations.kaltura === undefined) {
      delete pristineClippingProfileCleaned.integrations.kaltura;
    }
    if (pristineClippingProfileCleaned.integrations.lakana === undefined) {
      delete pristineClippingProfileCleaned.integrations.lakana;
    }
    if (pristineClippingProfileCleaned.integrations.twitter === undefined) {
      delete pristineClippingProfileCleaned.integrations.twitter;
    }
    if (pristineClippingProfileCleaned.integrations.youtube === undefined) {
      delete pristineClippingProfileCleaned.integrations.youtube;
    }

    // check and see if the password has been modified.
    // we no longer send the password to the client to protect their information
    if (currentClippingProfileCleaned.integrations.kaltura !== undefined) {
      if (
        pristineClippingProfileCleaned.integrations.kaltura !== undefined &&
        pristineClippingProfileCleaned.integrations.kaltura.credentials.admin_secret.localeCompare(
          currentClippingProfileCleaned.integrations.kaltura.credentials.admin_secret,
        ) !== 0
      ) {
        this.currentClippingConfig.integrations.kaltura.credentials.password_modified = 'T';
      } else {
        this.currentClippingConfig.integrations.kaltura.credentials.password_modified = 'F';
      }
      delete currentClippingProfileCleaned.integrations.kaltura.credentials.password_modified;
    }
    if (currentClippingProfileCleaned.integrations.lakana !== undefined) {
      if (
        pristineClippingProfileCleaned.integrations.lakana !== undefined &&
        pristineClippingProfileCleaned.integrations.lakana.credentials.password.localeCompare(
          currentClippingProfileCleaned.integrations.lakana.credentials.password,
        ) !== 0
      ) {
        this.currentClippingConfig.integrations.lakana.credentials.password_modified = 'T';
      } else {
        this.currentClippingConfig.integrations.lakana.credentials.password_modified = 'F';
      }
      delete currentClippingProfileCleaned.integrations.lakana.credentials.password_modified;
    }

    delete currentClippingProfileCleaned.doValidate;
    this.isFormDirty = !_.isEqual(currentClippingProfileCleaned, pristineClippingProfileCleaned);

    return this.isFormDirty;
  }

  public saveIntegration(serviceName, integrationName, integrationObject) {
    return new Promise((resolve, reject) => {
      this.clippingSettingService
        .saveIntegration(this.currentClippingConfig.id, serviceName, integrationName, integrationObject)
        .then(() => {
          resolve();
        })
        .catch(e => {
          this.notification.error(e.message || this.errorSavingChangesMessage);
          reject(e);
        });
    });
  }

  public getPristineIntegration(serviceName) {
    if (
      serviceName === IntegrationServiceType.Facebook &&
      this.currentClippingConfigPristine.integrations &&
      this.currentClippingConfigPristine.integrations.facebook
    ) {
      return _.cloneDeep(this.currentClippingConfigPristine.integrations.facebook);
    }
    if (
      serviceName === IntegrationServiceType.Kaltura &&
      this.currentClippingConfigPristine.integrations &&
      this.currentClippingConfigPristine.integrations.kaltura
    ) {
      return _.cloneDeep(this.currentClippingConfigPristine.integrations.kaltura);
    }
    if (
      serviceName === IntegrationServiceType.Lakana &&
      this.currentClippingConfigPristine.integrations &&
      this.currentClippingConfigPristine.integrations.lakana
    ) {
      return _.cloneDeep(this.currentClippingConfigPristine.integrations.lakana);
    }
    if (
      serviceName === IntegrationServiceType.Twitter &&
      this.currentClippingConfigPristine.integrations &&
      this.currentClippingConfigPristine.integrations.twitter
    ) {
      return _.cloneDeep(this.currentClippingConfigPristine.integrations.twitter);
    }
    if (
      serviceName === IntegrationServiceType.YouTube &&
      this.currentClippingConfigPristine.integrations &&
      this.currentClippingConfigPristine.integrations.youtube
    ) {
      return _.cloneDeep(this.currentClippingConfigPristine.integrations.youtube);
    }
  }

  public save() {
    return new Promise((resolve, reject) => {
      ClippingUtil.validate(this.currentClippingConfig)
        .then(errs => {
          if (errs.length) {
            this.validationErrors = errs;
            this.notification.error('Please fix form error(s) and try again.');
            this.checkTabError();
            return resolve();
          }

          // save the integration data first
          this.isSaving = true;
          if (this.currentClippingConfig.integrations) {
            this.saveDirtyIntegration(
              IntegrationServiceType.Facebook,
              IntegrationNameType.Facebook,
              this.currentClippingConfig.integrations.facebook,
            );
            this.saveDirtyIntegration(
              IntegrationServiceType.Kaltura,
              IntegrationNameType.Kaltura,
              this.currentClippingConfig.integrations.kaltura,
            );
            this.saveDirtyIntegration(
              IntegrationServiceType.Lakana,
              IntegrationNameType.Lakana,
              this.currentClippingConfig.integrations.lakana,
            );
            this.saveDirtyIntegration(
              IntegrationServiceType.Twitter,
              IntegrationNameType.Twitter,
              this.currentClippingConfig.integrations.twitter,
            );
            this.saveDirtyIntegration(
              IntegrationServiceType.YouTube,
              IntegrationNameType.YouTube,
              this.currentClippingConfig.integrations.youtube,
            );
          }

          // Save the rest of the information
          this.clippingSettingService
            .saveProfiles(this.currentClippingConfig)
            .then(config => {
              this.eventAggregator.publish(CLIPPING_CONFIG_EVENT.NewConfigSaved);
              // if this config was new, turn off the flag now.
              this.isNew = false;
              this.clippingConfigs = this.clippingConfigs.map(c => {
                if (c.id === this.currentClippingConfig.id) {
                  return config;
                }
                return c;
              });
              this.setCurrentConfig(config);

              const index = _.findIndex(this.displayList, {id: this.currentClippingConfig.id});

              if (index > -1) {
                this.displayList.splice(index, 1, _.cloneDeep(this.currentClippingConfig));
              }

              this.notification.success('Changes saved successfully.');
              resolve();
            })
            .catch(e => {
              this.notification.error(e.message || this.errorSavingChangesMessage);
              reject(e);
            })
            .finally(() => {
              this.doDirtyCheck();
              this.isSaving = false;
            });
        })
        .catch(err => {
          reject(err);
          log.error(`Validation Error: ${err}`);
        });
    });
  }

  public delete(): Promise<any> {
    this.deleteTip.hide();
    this.isDeleting = true;
    return new Promise((resolve, reject) => {
      const deletedConfigName = this.currentClippingConfig.desc;
      this.clippingSettingService
        .deleteProfiles(this.currentClippingConfig.id)
        .then(() => {
          let deletedIndex = -1;
          let nextIndex = -1;
          this.clippingConfigs = this.clippingConfigs.filter((c, index) => {
            if (c.id === this.currentClippingConfig.id) {
              deletedIndex = index;

              // delete from sortedProfiles
              const idxValue = _.findIndex(
                this.sortedProfiles,
                profileId => profileId === this.currentClippingConfig.id,
              );
              if (idxValue >= 0) {
                this.sortedProfiles.splice(idxValue, 1);
                this.displayList.splice(idxValue, 1);
              }
              return false;
            }
            return true;
          });
          if (this.clippingConfigs.length) {
            if (deletedIndex === this.clippingConfigs.length) {
              nextIndex = deletedIndex - 1;
            } else {
              nextIndex = deletedIndex;
            }
          }
          this.saveSortedProfiles();
          this.setCurrentConfig(this.clippingConfigs[nextIndex]);
          this.notification.success(`${deletedConfigName} clipping config deleted successfully.`);
          resolve();
        })
        .catch(e => {
          this.notification.error('Error deleting this ad config.');
          reject(e);
        })
        .finally(() => {
          this.isDeleting = false;
        });
    });
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }

    if (this.drake) {
      this.drake.destroy();
      this.drake = null;
    }
  }

  public loadProfiles(profiles, selectedItem) {
    this.clippingConfigs = profiles;

    if (this.clippingConfigs && this.clippingConfigs.length) {
      this.setCurrentConfig(this.clippingConfigs[selectedItem]);
    }
  }

  /**
   * Get list of saved sorted profile ids
   */
  public async getSortedProfileIds() {
    try {
      const filters = await this.session.getOwnerMeta(OwnerMetaContext.CLIPPING_PROFILES, 'column_order');

      if (Array.isArray(filters)) {
        this.sortedProfiles = filters;
      } else {
        this.sortedProfiles = [];
      }

      // validate that we have a saved sorted profile id for each clipping config
      // if not, then save it to the OwnerMeta
      _.forEach(this.clippingConfigs, clippingProfile => {
        const found = _.includes(this.sortedProfiles, clippingProfile.id);
        if (!found) {
          this.sortedProfiles.push(clippingProfile.id);
          this.saveSortedProfiles();
        }
      });
    } catch (e) {
      this.sortedProfiles = [];
    }

    this.setupDisplayList();

    this.setupDragDrop();
  }

  /**
   * Save list of saved sorted profile ids
   */
  public saveSortedProfiles() {
    this.session.setOwnerMeta(OwnerMetaContext.CLIPPING_PROFILES, {column_order: this.sortedProfiles});
  }

  /**
   * Setup Dragula so we can drag and drop our profiles
   */
  public setupDragDrop() {
    if (this.drake) {
      this.drake.destroy();
    }

    this.drake = dragula([document.getElementById('list-sorted-profiles')], {
      delay: 200,
      moves: ({}, {}, handle) => handle.classList.contains('handle'),
      revertOnSpill: false,
    });

    this.drake.on('drop', ({}, target) => {
      this.sortedProfiles.splice(0, this.sortedProfiles.length);
      [].forEach.call(target.children, li => {
        this.sortedProfiles.push(li.getAttribute('data-profile-id'));
      });

      this.setupDisplayList();
      this.saveSortedProfiles();
    });
  }

  private setupDisplayList() {
    this.displayList.splice(0, this.displayList.length);

    _.forEach(this.sortedProfiles, profileId => {
      const index = _.findIndex(this.clippingConfigs, {id: profileId});
      if (index >= 0) {
        this.displayList.push(this.clippingConfigs[index]);
      }
    });
  }

  private saveDirtyIntegration(
    integrationType: IntegrationServiceType,
    integrationName: IntegrationNameType,
    integration,
  ) {
    // to disable we use /settings/integration/enable api with enabled:false
    if (integration && integration.enabled) {
      const currentIntegration = _.cloneDeep(integration);
      const pristineIntegration = this.getPristineIntegration(integrationType);
      const isDirty = !_.isEqual(currentIntegration, pristineIntegration);

      if (isDirty) {
        this.saveIntegration(integrationType, integrationName, currentIntegration);
      }
    }
  }
}
