import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {MSOSlicerResponse} from '../models/msoslicer';

@autoinject
export class MSOSlicerRestartService {
    private url: string = '/api/v4/mso/slicer/restart';

    constructor(private acceo: Acceo) {}

    public getById(id: string) {
        return this.acceo.get(MSOSlicerResponse)(`${this.url}/${id}`);
    }
}
