import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {MSOStreamProtocolResponseItems} from '../models/streamproto';

@autoinject
export class MSOStreamProtocolService {
    private url: string = '/api/v4/mso/streamproto';

    constructor(public acceo: Acceo) {}

    public get() {
        return this.acceo.get(MSOStreamProtocolResponseItems)(this.url);
    }
}
