import {autoinject, bindable, bindingMode} from 'aurelia-framework';

@autoinject()
export class Footer {
    @bindable({defaultBindingMode: bindingMode.twoWay})
    public value;

    public controller: any;
    public model: any;

    public activate(model) {
        this.model = model;
        this.controller = model.controller;
    }
}
