import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {IsString, IsArray, IsOptional} from 'class-validator';

const ERROR_MSG_BACKEND = 'Analytics system unavailable. If this problem persists, contact support.';
const ERROR_MSG_BAD_DATA = 'Error displaying analytics report';

@autoinject
export class DashboardIndex {
  constructor(private acceo: Acceo) {}
  public URL: any = '';
  public settings: any = {};
  public ownerNameDashboards = [];
  public billingWithDashboards = [];
  public currentRoute: string = '';
  public params = {};
  public error: string = '';

  public async activate(params: any, setting: any) {
    this.params = params;
    this.currentRoute = setting.route;
    await this.getEmbeddedUrl();
  }

  public async getEmbeddedUrl() {
    let dashboardTitle = 'usage-summary';
    if (this.currentRoute !== '') {
      dashboardTitle = this.currentRoute;
    }
    if (dashboardTitle === 'detailed-billing-with') {
      if (this.billingWithDashboards.length > 0) {
        this.URL = this.billingWithDashboards[0][dashboardTitle].url;
      } else {
        try {
          const res = await this.acceo.get(Response)(`/api/v4/analytics/url/${dashboardTitle}`);
          if (res && res.url && res.dashboards) {
            this.URL = res.url;
            this.settings = res.settings;
            this.billingWithDashboards = res.dashboards;
          } else {
            this.error = ERROR_MSG_BAD_DATA;
          }
        } catch (err) {
          this.error = ERROR_MSG_BACKEND;
        }
      }
    } else if (this.ownerNameDashboards.length > 0) {
      if (dashboardTitle in this.ownerNameDashboards[0]) {
        this.URL = this.ownerNameDashboards[0][dashboardTitle].url;
      }
    } else {
      try {
        const res = await this.acceo.get(Response)(`/api/v4/analytics/url/${dashboardTitle}`);
        if (res && res.url && res.dashboards) {
          this.error = '';
          this.URL = res.url;
          this.settings = res.settings;
          this.ownerNameDashboards = res.dashboards;
        } else {
          this.error = ERROR_MSG_BAD_DATA;
        }
      } catch (err) {
        this.error = ERROR_MSG_BACKEND;
      }
    }
  }
}

export class Response {
  @IsArray()
  public dashboards: [];

  @IsString()
  public url: string;

  @IsOptional()
  public settings: any;
}
