import * as moment from 'moment-timezone';
import {computedFrom, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import {deepCopy} from 'resources/deep-copy';

import {LiveEventsService} from '../../live-events/services/live-events';

import {CalendarService} from './calendar-service';
import {MonthService} from './month-service';
import {WeekService} from './week-service';
import {DayService} from './day-service';
import {eventRoute} from '../../live-events/single';

const EVENTS_CHECK_INTERVAL_MS = 60 * 1000; // 1 minutes in milliseconds

/**
 * Calendar Factory Class
 * ========================
 * Shared service where logic is common between month, week, and day components
 * Broken out into separate singletons where needed.
 *
 *
 * Also known as "destructure all the things"
 */

@inject(LiveEventsService, Router, CalendarService, MonthService, WeekService, DayService)
export class CalendarFactory {
    constructor(liveEvents, router, calendarService, monthService, weekService, dayService, type) {
        this.type = type;
        this.liveEvents = liveEvents;
        this.router = router;
        this.calendarService = calendarService;
        this.isLoading = false;
        this.trackingMoment = moment();
        this.updateInterval = null;
        this.currentTimeZone = moment().tz(moment.tz.guess()).format('z');
        this.cleanAll = true;

        this.blockElement = {
            modified: false,
            height: 0,
            timeout: null,
        };

        this.setCurrentMoment();

        if (this.calendarService.sharedParams) {
            this.filterParams = deepCopy(this.calendarService.sharedParams);
        } else {
            this.cleanFilter();
        }

        this.filterParams.limit = 5000;
        this.filterParams.include = [
            'id',
            'desc',
            'expected_start',
            'expected_stop',
            'state',
            'slicers',
            'actual_stop',
            'owner',
        ];
        this.filterParams.calendar = true;

        this.separateClass = null;

        switch (this.type) {
            case 'calendarMonth':
                this.separateClass = monthService;
                break;
            case 'calendarWeek':
                this.separateClass = weekService;
                break;
            default:
                // Day
                this.separateClass = dayService;
        }

        [this.formattedDate, this.shortDate] = this.separateClass.parseDate(this.trackingMoment);
        [this.filterParams.r_start, this.filterParams.r_stop] = this.separateClass.initFilter();
    }

    @computedFrom('filterParams.slicer', 'filterParams.operator', 'filterParams.state')
    get isFiltered() {
        return this.filterParams && (this.filterParams.slicer || this.filterParams.operator || this.filterParams.state);
    }

    cleanFilterParams(dirtyParams) {
        const params = deepCopy(dirtyParams);

        delete params.end_r_start;
        delete params.end_r_stop;
        delete params.create_r_start;
        delete params.create_r_stop;

        return params;
    }

    saveFilter(updatedParams) {
        this.filterParams = this.cleanFilterParams(updatedParams);

        this.calendarService.saveFilter(this.filterParams);
        this.getEvents();
    }

    setCurrentMoment() {
        this.currentMoment = moment();
        this.today = this.currentMoment.format('MM/DD/YYYY');
    }

    cleanEventSlate() {
        this.liveEvents.resetState(); // Reset liveEvents state

        if (this.cleanAll) {
            this.cleanFilter();
        }
    }

    cleanUp() {
        clearInterval(this.updateInterval);
        this.updateInterval = null;

        this.cleanEventSlate();
        this.isLoading = false;
    }

    cleanFilter() {
        this.filterParams = this.liveEvents.getSanitizedBlankFilterParams();
    }

    gotoEventOrTime(id, date, endDate) {
        this.isLoading = true;

        setTimeout(() => {
            // This allows the loading spinner to show
            if (id) {
                this.cleanFilter();
                this.liveEvents.cleanupNewEvent();
                this.liveEvents.fromCalendar = true;
                // this.router.navigateToRoute('liveEventSingle', {id});
                window.location.href = `#${eventRoute}/${id}`;
            } else if (date) {
                this.gotoDate(date, endDate);
            }
        });
    }

    gotoDate(date, endDate) {
        this.isLoading = true;

        setTimeout(() => {
            // This allows the loading spinner to show
            const tempParams = deepCopy(this.filterParams);
            this.cleanFilter();

            if (date && !endDate) {
                this.filterParams.r_start = date;
                this.filterParams.r_stop = moment(date).endOf('day').valueOf();
            } else if (date && endDate) {
                this.filterParams.r_start = date;
                this.filterParams.r_stop = endDate;
            } else {
                this.filterParams.r_start = tempParams.r_start;
                this.filterParams.r_stop = tempParams.r_stop;
            }

            const params = this.liveEvents.sanitizeParams(this.filterParams);
            this.liveEvents.resetFilterParams(params);
            this.cleanAll = false;
            this.router.navigateToRoute('liveEventsIndex');
        });
    }

    getEvents() {
        if (!this.updateInterval) {
            this.isLoading = true;
            this.error = false;
            this.updateInterval = setInterval(() => {
                this.getEvents();
                this.setCurrentMoment();
            }, EVENTS_CHECK_INTERVAL_MS);
        }

        const params = this.liveEvents.sanitizeParams(this.filterParams);
        this.liveEvents
            .getLiveEvents(params)
            .then(() => {
                [this.slots, this.totalEvents] = this.separateClass.assembleCalendar(
                    this.liveEvents.data,
                    this.filterParams.r_start,
                );
                this.isLoading = false;
            })
            .catch(err => {
                this.error = err;
            });
    }

    focusElement(element) {
        this.blockElement.timeout = setTimeout(() => {
            element.style.zIndex = '20';

            this.blockElement.height = element.style.height;

            // This allows for some extra height on blocks too short to display all information
            const {scrollHeight} = $(element)[0];
            const staticHeight = parseInt(element.style.height.slice(0, -2), 10);
            const tallestHeight = scrollHeight > staticHeight ? scrollHeight : staticHeight;

            element.style.height = `${tallestHeight}px`;

            this.blockElement.modified = true;
        }, 500);
    }

    loseFocus(element) {
        clearTimeout(this.blockElement.timeout);

        if (this.blockElement.modified) {
            element.style.height = this.blockElement.height;
            element.style.zIndex = '';

            this.blockElement.modified = false;
        }
    }

    nextButton() {
        clearInterval(this.updateInterval);
        this.updateInterval = null;

        [this.trackingMoment, this.filterParams.r_start, this.filterParams.r_stop] = this.separateClass.nextButton(
            this.trackingMoment,
        );

        [this.formattedDate, this.shortDate] = this.separateClass.parseDate(this.trackingMoment);
        this.getEvents();
    }

    prevButton() {
        clearInterval(this.updateInterval);
        this.updateInterval = null;

        [this.trackingMoment, this.filterParams.r_start, this.filterParams.r_stop] = this.separateClass.prevButton(
            this.trackingMoment,
        );

        [this.formattedDate, this.shortDate] = this.separateClass.parseDate(this.trackingMoment);
        this.getEvents();
    }
}
