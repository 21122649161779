import {Type} from 'class-transformer';
import {IsInt, IsString, ValidateNested} from 'class-validator';

export class OwnerNotificationNotifyee {
    @IsString()
    public desc: string;

    @IsString()
    public id: string;

    @IsString()
    public type: string;
}

export class OwnerNotificationNotifyeeCollection {
    @ValidateNested()
    @Type(() => OwnerNotificationNotifyee)
    public items: OwnerNotificationNotifyee[];

    @IsInt()
    public total_items: number;
}

export class OwnerNotificationNotifyeeAction {
    @IsString()
    public message: string;
}
