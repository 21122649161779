import * as moment from 'moment';

// Magic numbers!
export const WEEK_DAYS = 7; // Number of days in a week
export const SECOND = 1000; // Convert milliseconds to seconds
export const HOUR = 3600; // Convert seconds to hour
export const HALF_HOUR = 2; // Convert to half-hour increments
export const FORTY_EIGHT = 48; // 24 hours broken into half-hours;
const HALF_HOUR_HEIGHT = 25; // Height of half-hour div
const HALF_HOUR_SECONDS = 1800; // half-hour in seconds
const BLOCK_WIDTH = 105; // Width of event div
const INITIAL_PADDING = 5; // Inital left padding
const MINUTE = 1000 * 60; // Minute in milliseconds
const DAY = 86400000; // Day in milliseconds
const TOTAL_DAY_HEIGHT = FORTY_EIGHT * HALF_HOUR_HEIGHT; // Total height of the day column

const calculateClassesMultiple = events => {
    const classes = [];

    events.forEach(event => {
        if (event.state === 'live') {
            classes.push('cal-tile--live');
        }
        /* eslint-disable no-restricted-syntax */
        for (const slicer of event.conflicts) {
            if (slicer.conflicts.length) {
                classes.push('cal-tile--conflict');
                break;
            }
        }
        /* eslint-disable no-restricted-syntax */
    });

    if (classes.length) {
        const uniq = classes.reduce((x, y) => (x.includes(y) ? x : [...x, y]), []);
        return uniq.join(' ');
    }

    return '';
};

/**
 * Simple factories to handle formatting and tile data
 */

export function HalfHour(dayStart, index) {
    this.events = [];
    this.blocks = [];
    this.dayStart = dayStart;
    this.classes = '';
    this.index = index;

    this.blockStartTime = this.dayStart + index * (HALF_HOUR_SECONDS * SECOND);
}

HalfHour.prototype = {
    calculateTop(start) {
        const diff = start - this.blockStartTime;
        const diffPercentage = (diff / SECOND / HOUR) * HALF_HOUR;

        return Math.floor(diffPercentage * HALF_HOUR_HEIGHT);
    },
    calculateHeight(start, end) {
        const totalDiff = ((end - start) / SECOND / HOUR) * HALF_HOUR;
        const roundedDown = Math.floor(totalDiff);
        const roundDiff = totalDiff - roundedDown;

        const totalHeight = roundedDown * HALF_HOUR_HEIGHT + Math.floor(roundDiff * HALF_HOUR_HEIGHT);

        const maxHeight = (FORTY_EIGHT - this.index) * HALF_HOUR_HEIGHT;

        if (totalHeight < HALF_HOUR_HEIGHT) {
            return HALF_HOUR_HEIGHT;
        }
        if (totalHeight > maxHeight) {
            return maxHeight;
        }

        return totalHeight;
    },
    calculateClasses(event) {
        let classes = '';

        if (event.state === 'live') {
            classes += 'cal-tile--live ';
        }

        for (const slicer of event.conflicts) {
            if (slicer.conflicts.length) {
                classes += 'cal-tile--conflict';
                break;
            }
        }

        return classes;
    },
    calculateBlocks(columnsHeight) {
        const sortedEvents = this.events.sort((a, b) => a.expected_start - b.expected_start);

        sortedEvents.forEach(event => {
            for (let idx = 0; idx < columnsHeight.length; idx += 1) {
                if (columnsHeight[idx] <= this.index * HALF_HOUR_HEIGHT) {
                    const blockObj = {
                        height: this.calculateHeight(event.expected_start, event.expected_stop, this.index),
                        left: idx * BLOCK_WIDTH + INITIAL_PADDING,
                        desc: event.desc,
                        top: this.calculateTop(event.expected_start, this.index),
                        startTime: moment(event.expected_start).format('h:mm A'),
                        endTime: moment(event.expected_stop).format('h:mm A'),
                        classes: this.calculateClasses(event),
                        eventId: event.id,
                    };
                    this.blocks.push(blockObj);
                    columnsHeight[idx] = blockObj.height + this.index * HALF_HOUR_HEIGHT + blockObj.top;
                    break;
                }
            }
        });

        return columnsHeight;
    },
    calculateBlocksForWeek() {
        const sortedEvents = this.events.sort((a, b) => a.expected_start - b.expected_start);

        if (sortedEvents.length > 2) {
            const event = sortedEvents[0];
            const longestEvent = this.events.sort((a, b) => b.expected_stop - a.expected_stop)[0];

            const blockObj = {
                height: this.calculateHeight(event.expected_start, longestEvent.expected_stop, this.index),
                desc: `${sortedEvents.length} Events`,
                top: this.calculateTop(event.expected_start, this.index),
                startTime: moment(event.expected_start).format('h:mm A'),
                endTime: moment(longestEvent.expected_stop).format('h:mm A'),
                classes: calculateClassesMultiple(sortedEvents),
                eventId: null,
                unixStart: this.blockStartTime,
                unixEnd: moment(this.blockStartTime)
                    .add('29', 'minutes')
                    .add('59', 'seconds')
                    .add('999', 'milliseconds')
                    .valueOf(),
            };
            this.blocks.push(blockObj);
        } else if (sortedEvents.length === 2) {
            this.classes = 'week-hour-double';

            sortedEvents.forEach(event => {
                const blockObj = {
                    height: this.calculateHeight(event.expected_start, event.expected_stop, this.index),
                    desc: event.desc,
                    top: this.calculateTop(event.expected_start, this.index),
                    startTime: moment(event.expected_start).format('h:mm A'),
                    endTime: moment(event.expected_stop).format('h:mm A'),
                    classes: this.calculateClasses(event),
                    eventId: event.id,
                    unixStart: null,
                    unixEnd: null,
                };
                this.blocks.push(blockObj);
            });
        } else if (sortedEvents.length === 1) {
            const event = sortedEvents[0];
            const blockObj = {
                height: this.calculateHeight(event.expected_start, event.expected_stop, this.index),
                desc: event.desc,
                top: this.calculateTop(event.expected_start, this.index),
                startTime: moment(event.expected_start).format('h:mm A'),
                endTime: moment(event.expected_stop).format('h:mm A'),
                classes: this.calculateClasses(event),
                eventId: event.id,
                unixStart: null,
                unixEnd: null,
            };
            this.blocks.push(blockObj);
        }
    },
};

export function WeekDay(startDate, index) {
    this.day = moment(startDate).add(index, 'days');
    this.start = this.day.clone().startOf('day');
    this.startUnix = this.start.valueOf();
    this.end = this.day.clone().endOf('day');
    this.formattedDay = moment(this.day).format('dddd M/D');
    this.altFormattedDay = moment(this.day).format('MM/DD/YYYY');
    this.dayNum = moment(this.day).format('D');
    this.events = [];
    this.slots = new Array(FORTY_EIGHT).fill().map((a, ind) => new HalfHour(this.startUnix, ind));
    this.styles = '';
    this.eventClasses = '';

    this.handleCurrentTime();
    setInterval(() => this.handleCurrentTime(), MINUTE);
}

WeekDay.prototype = {
    handleCurrentTime() {
        const currentTime = moment().valueOf();
        const difference = currentTime - this.startUnix;

        if (DAY > difference && difference > 0) {
            const diffPercentage = difference / DAY;
            // To offset the height of the element
            this.currentTimeTop = Math.floor(TOTAL_DAY_HEIGHT * diffPercentage) - 2;
        } else {
            this.currentTimeTop = null;
        }
    },
    setEventClasses() {
        this.eventClasses = calculateClassesMultiple(this.events);
    },
};
