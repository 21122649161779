import {autoinject} from 'aurelia-framework';

import {Acceo} from 'services/acceo';
import {CToastsService} from '@bindable-ui/bindable';
import {playerAsset} from '../models/ad-server-debug-response';

@autoinject
export class PlayerUrlService {
  private url: string = '/api/v4/adproxy/ad-asset/';

  constructor(public acceo: Acceo, public notification: CToastsService) {}

  public async getPlayerUrl(uplynkId: string) {
    try {
      const resp = await this.acceo.get(playerAsset)(`${this.url}${uplynkId}`);
      return resp.testPlayer;
    } catch (error) {
      // API call failed, display notification
      return 'Error: The API call could not be completed successfully.';
    }
  }
}
