import {validate} from 'class-validator';
import {PrebidConfig} from '../models/prebid-config';

export class PrebidUtil {
    public static VALUE_MAX_LENGTH = 1000;
    public static URL_MAX_LENGTH = 10000;
    public static NETWORK_ID_MAX_LENGTH = 64;

    public static async validate(config: PrebidConfig) {
        const errors = await validate(config, {skipMissingProperties: true});
        return errors;
    }
}
