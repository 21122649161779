export enum Permission {
  READ = 'read',
  WRITE = 'write',
  OP = 'op',
  ADMIN = 'admin',
}

export enum PermissionSuffix {
  READ = 'ro',
  OP = 'op',
}

export enum Entitlement {
  AD_SERVER_DEBUG = 'ad_server_debug',
  UNIVERSAL_AD_CONFIG = 'universal_ad_config',
  ANALYTICS = 'analytics',
  BILLING = 'billing',
  CHANNEL_EDITOR = 'chanedit',
  CLIPPING = 'clipping',
  LIVE_EVENT_DASHBOARD = 'led',
  MONITORING = 'monitoring',
  NOC = 'noc',
  POST_CDN_MONITORING = 'postcdnmon',
  SETTINGS = 'settings',
  SLICER_MONITORING_2 = 'slicemon2',
  SLICERS_CLOUD = 'slicers_cloud',
  STREAM = 'stream',
  SYNDICATION = 'syndication',
  TEST_PLAYERS = 'testplayers',
}

export interface UserPermissions {
  ad_server_debug?: Permission[];
  universal_ad_config?: Permission[];
  analytics?: Permission[];
  billing?: Permission[];
  chanedit?: Permission[];
  clipping?: Permission[];
  led?: Permission[];
  monitoring?: Permission[];
  noc?: Permission[];
  postcdnmon?: Permission[];
  settings?: Permission[];
  slicemon2?: Permission[];
  slicers_cloud?: Permission[];
  stream?: Permission[];
  testplayers?: Permission[];
}
