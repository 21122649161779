import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {SlicerViewModel} from './index';
import {ProfilesResponseItem, ProfilesResponseItems} from '../../models/profiles';
import {ProfilesService} from '../../services/profiles-service';

@autoinject()
export class OwnedSlicersSingleGeneral {
  public encodingProfiles: ProfilesResponseItems = null;
  public encodingProfileDialog: any = null;
  // @bindable
  public selectedProfile: ProfilesResponseItem = null;

  constructor(public parentView: SlicerViewModel, public profilesService: ProfilesService) {}

  public async attached() {
    this.encodingProfiles = await this.profilesService.get();
    this.selectedProfile = this.parentView.slicer.profile;
  }

  public async onShowChangeProfileDialog() {
    this.encodingProfileDialog.show();
    this.selectedProfile = _.cloneDeep(this.parentView.slicer.profile);
  }

  public setProfileHandler = profile => {
    this.selectedProfile = profile;
  };

  public onChangeProfile() {
    this.parentView.slicer.profile = this.selectedProfile;
    this.encodingProfileDialog.hide();
  }

  canDeactivate() {
    if (this.parentView.isDirty) {
      const dirtyModel = {
        header: 'Are you sure you want to leave?',
        question: 'There are unsaved changes. If you leave before saving, your changes will be lost.',
        yes: 'Yes, Discard My Changes',
        hideNo: true,
        cancel: 'Cancel',
        footerClass: 'btn-split',
      };
      return this.parentView.dialogService
        .open({
          viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
          model: dirtyModel,
        })
        .whenClosed(response => {
          if (!response.wasCancelled) {
            this.parentView.slicer = _.cloneDeep(this.parentView.slicerCopy);
          }
          return !response.wasCancelled;
        });
    }

    return true;
  }
}
