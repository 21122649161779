import {computedFrom} from 'aurelia-binding';

import * as moment from 'moment';

import {AssetModel} from 'apps/cms/routes/content/models/asset-model';

import {DurationTools} from 'apps/cms/routes/live-channels/channels/single/utils/duration-tools';
import {ICsvImport} from 'apps/cms/routes/live-channels/channels/single/utils/interfaces';

import {ScheduledEntryContentType, ScheduledImportContentType} from './event-model';

export class ScheduleImport {
  public adBreaks: number[];
  public adBreakDuration: number;
  public assetDuration: number;
  public date: string;
  public duration: number;
  public errors: string[];
  public externalId: string;
  public id: string;
  public startTime: string;
  public state: string;
  public stateLabel: string;
  public stopTime: string;
  public thumbnailUrl: string;
  public title: string;
  public contentType: ScheduledEntryContentType;
  public warnings: string[] = [];

  private _asset: AssetModel;

  @computedFrom('_asset')
  public get asset() {
    return this._asset;
  }

  constructor(data: ICsvImport) {
    this.id = data.id;
    this.startTime = moment(data.start_time).toISOString();
    this.errors = [];
    this.contentType = data.type || ScheduledEntryContentType.AD;
    this.title = data.id;

    if (data.duration) {
      this.setDuration(data.duration);
      this.setStopTime();
    }

    this.verifyData();
  }

  private verifyData() {
    if (!ScheduledImportContentType.includes(this.contentType)) {
      this.errors.push('Invalid content type');
    }

    if (!this.startTime) {
      this.errors.push('Invalid date');
    }

    if (new Date(this.startTime).getTime() <= new Date().getTime()) {
      this.errors.push('Start date in past');
    }
  }

  public setAsset(asset: AssetModel) {
    this._asset = asset;
    this.title = asset.title;
    this.externalId = asset.external_id;
    this.assetDuration = Math.round(asset.duration);

    if (!this.duration) {
      this.duration = this.assetDuration;
      this.setStopTime();
    }

    this.setAdBreakDuration();
  }

  private setAdBreakDuration(): void {
    this.adBreakDuration = this.duration - this.assetDuration;
    // ignore ad duration in case <= 1
    if (this.adBreakDuration !== 0 && this.adBreakDuration <= 1) {
      this.adBreakDuration = 0;
      // this.warnings.push('The AD Break Duration has been removed. it was shorter than or equal to 1 second');
    }
    const {break_offsets = []} = this.asset;
    const ct = break_offsets.length;

    if (ct === 0 && this.adBreakDuration > 0) {
      this.errors.push('Cannot set ad break duration. No ad breaks are configured for this asset.');
      return;
    }

    if (this.adBreakDuration < 0) {
      this.errors.push('Ad break duration can"t be negative');
    }

    const breaks = DurationTools.EqualizedBreakOffsets(break_offsets, this.adBreakDuration);
    this.adBreaks = breaks.map(b => b.duration);
  }

  private setDuration(duration) {
    if (duration === null || duration === '') {
      return;
    }

    try {
      this.duration = DurationTools.ToSeconds(duration);
    } catch (e) {
      this.duration = 0;
    }
  }

  private setStopTime() {
    this.stopTime = moment(this.startTime).add(this.duration, 'seconds').toISOString();
  }
}
