import {SharedNav} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {Authorization, authorizationConstants} from 'services/authorization';
import {LiveEventsService} from './live-events/services/live-events';

@authorizationConstants
@autoinject()
export class LiveEvents {
  public routes: any;

  constructor(
    public liveEventsService: LiveEventsService,
    public router: Router,
    public sharedNav: SharedNav,
    public authorization: Authorization,
  ) {
    this.routes = [
      {
        redirect: 'events',
        route: '',
      },
      {
        moduleId: PLATFORM.moduleName('./live-events/index'),
        name: 'liveEventsIndex',
        nav: true,
        route: 'events',
        title: 'Live Events',
      },
      {
        moduleId: PLATFORM.moduleName('./calendar/index'),
        name: 'calendarIndex',
        nav: true,
        route: 'calendar',
        title: 'Calendar',
      },
    ];
  }

  public async attached() {
    this.sharedNav.initMainNav(this.router);
  }

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }

  public newEventLink() {
    this.router.navigateToRoute('liveEventSingle', {id: 'new'});
  }
}
