import * as CTableCssJson from '@bindable-ui/bindable/dist/native-modules/components/tables/c-table/c-table.css.json';
import {CTableSelectable} from 'resources/components/c-table-selectable/c-table-selectable';
import {
  CTableColSelectable,
  CTableRowBehavior,
} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {HostedSlicerTableColHeadValue, HostedSlicerTableRow} from './table-models';

export class HostedSlicerTable extends CTableSelectable {
  public rows: HostedSlicerTableRow[] = [];
  public defaultSortColumn: string = 'slicer_id';
  public sortColHeadName = this.defaultSortColumn;
  public sortReverse = false;
  protected colsSelectableDefault: CTableColSelectable[] = [
    {
      colHeadName: 'id',
      colHeadValue: HostedSlicerTableColHeadValue.id,
      selected: false,
      sort: true,
    },
    {
      colClass: this.getClass(CTableCssJson.t120),
      colHeadName: 'status',
      colHeadValue: HostedSlicerTableColHeadValue.status,
      selected: true,
      sort: true,
      valueConverter: 'capitalize:true',
    },
    {
      colHeadName: 'slicer_id',
      colHeadValue: HostedSlicerTableColHeadValue.slicerId,
      selected: true,
      sort: true,
    },
    {
      colHeadName: 'classification',
      colHeadValue: HostedSlicerTableColHeadValue.classification,
      selected: false,
      sort: true,
    },
    {
      colClass: 't120',
      colHeadName: 'protocol',
      colHeadValue: HostedSlicerTableColHeadValue.protocol,
      selected: true,
      sort: true,
    },
    {
      colClass: 't120',
      colHeadName: 'server_region_name',
      colHeadValue: HostedSlicerTableColHeadValue.serverRegionName,
      selected: true,
      sort: true,
    },
    {
      colClass: 't120',
      colHeadName: 'server_region_location',
      colHeadValue: HostedSlicerTableColHeadValue.serverRegionLocation,
      selected: false,
      sort: true,
    },
    {
      colHeadName: 'server_instance_id',
      colHeadValue: HostedSlicerTableColHeadValue.serverInstanceId,
      selected: false,
      sort: true,
      valueConverter: 'notApplicable',
    },
    {
      colHeadName: 'resolution',
      colHeadValue: HostedSlicerTableColHeadValue.resolution,
      selected: true,
      sort: true,
    },
    {
      colHeadName: 'quality',
      colHeadValue: HostedSlicerTableColHeadValue.quality,
      selected: false,
      sort: true,
    },
    {
      colHeadName: 'audio',
      colHeadValue: HostedSlicerTableColHeadValue.audio,
      selected: false,
      sort: true,
    },
    {
      colHeadName: 'encoder',
      colHeadValue: HostedSlicerTableColHeadValue.encoder,
      selected: false,
      sort: true,
    },
    {
      colHeadName: 'slicer_version',
      colHeadValue: HostedSlicerTableColHeadValue.slicerVersion,
      selected: false,
      sort: true,
      valueConverter: 'capitalize:true',
    },
    {
      colHeadName: 'slicer_software_version',
      colHeadValue: HostedSlicerTableColHeadValue.slicerSoftwareVersion,
      selected: false,
      sort: true,
      valueConverter: 'notApplicable',
    },
    {
      colClass: this.getClass(CTableCssJson.t450),
      colHeadName: 'slicer_stream_url',
      colHeadValue: HostedSlicerTableColHeadValue.streamUrl,
      selected: true,
      sort: true,
    },
    {
      colHeadName: 'slicer_secure_stream_url',
      colHeadValue: HostedSlicerTableColHeadValue.streamUrlSecure,
      selected: false,
      sort: true,
    },
    {
      colHeadName: 'slicer_stream_key',
      colHeadValue: HostedSlicerTableColHeadValue.streamKey,
      selected: false,
      sort: true,
    },
    {
      colHeadName: 'notes',
      colHeadValue: HostedSlicerTableColHeadValue.notes,
      selected: false,
      sort: true,
    },
    {
      colHeadName: 'created',
      colHeadValue: HostedSlicerTableColHeadValue.created,
      selected: false,
      sort: true,
      valueConverter: "timezoneTimeToStandardDateTimeMS:'MMM D, YYYY @ h:mm A'",
    },
    {
      colHeadName: 'lastmod',
      colHeadValue: HostedSlicerTableColHeadValue.modified,
      selected: false,
      sort: true,
      valueConverter: "timezoneTimeToStandardDateTimeMS:'MMM D, YYYY @ h:mm A'",
    },
    {
      colHeadName: 'deleted',
      colHeadValue: HostedSlicerTableColHeadValue.deleted,
      selected: false,
      sort: true,
      valueConverter: "timezoneTimeToStandardDateTimeMS:'MMM D, YYYY @ h:mm A'",
    },
  ];

  constructor(canViewClassification: boolean, canViewDeleted: boolean) {
    super(CTableRowBehavior.CHECK);
    let selectedCols = {};
    let sortDirection = 'ASC';
    this.tableStorage.setBaseTableDataKey('hosted-slicers-list');
    const tableDisplayData = this.tableStorage.getTableData();

    if (!_.isEmpty(tableDisplayData)) {
      selectedCols = this.tableStorage.getSelectedColumns();
      const savedSortColumn = this.tableStorage.getSortColumn(this.defaultSortColumn);
      if (savedSortColumn.length && savedSortColumn in selectedCols) {
        this.sortColHeadName = savedSortColumn;
      }
      sortDirection = this.tableStorage.getSortDirection();
    }

    this.colsSelectable = this.colsSelectableDefault.filter(col => {
      if (!_.isEmpty(selectedCols)) {
        if (selectedCols[col.colHeadName]) {
          col.selected = selectedCols[col.colHeadName];
        }
      }
      if (!canViewClassification && col.colHeadValue === HostedSlicerTableColHeadValue.classification) {
        return false;
      }
      if (!canViewDeleted && col.colHeadValue === HostedSlicerTableColHeadValue.deleted) return false;
      return true;
    });
    this.actions.getColClass = this.getColClass;
    this.actions.sortColumn = this.sortColumn.bind(this);
    this.defaultSortColumn = sortDirection === 'DESC' ? `-${this.sortColHeadName}` : `${this.sortColHeadName}`;
  }

  protected getColClass(row: HostedSlicerTableRow, col: CTableColSelectable) {
    if (col.colHeadValue === HostedSlicerTableColHeadValue.status) {
      switch (`${row.status}`.toUpperCase()) {
        case 'BUILDING':
        case 'CHECKIN':
        case 'CREATED':
        case 'INITIALIZED':
        case 'INITIALIZING':
        case 'IN PROGRESS':
        case 'NEW':
          return 'bgBuilding';
        case 'COMPLETED':
        case 'INACTIVE':
          return 'bgHealthy';
        case 'PENDING':
          return 'bgMajor';
        case 'RUNNING':
          return 'bgProcessing';
        case 'DELETED':
        case 'FAILED':
        case 'FAILED STOPPED':
        case 'OFFLINE':
        case 'RETRIEVING':
          return 'bgCritical';
        case 'DARK':
        case 'RESTARTING':
        case 'STOPPED':
          return 'bgWarning';
        default:
          return '';
      }
    }
    return col._class;
  }

  public get rowsActionable() {
    return this.rows.filter(row => row.selected && row.status !== 'DELETING' && row.status !== 'RESTARTING');
  }

  public get rowsNotDeleted() {
    return this.rows.filter(row => !row.deleted);
  }

  protected sortColumn(col: CTableColSelectable, reverse: boolean) {
    this.sortColHeadName = col.colHeadName;
    this.sortReverse = reverse;
    this.tableStorage.saveSortColumn(this.sortColHeadName);
    this.tableStorage.saveSortDirection(this.sortReverse ? 'DESC' : 'ASC');
  }
}
