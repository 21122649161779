import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {IsArray, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class IngestRoutingSetup {
    public rowAnnotate = {
        getColClass: (row, col) => {
            let cls = col._class;
            if (col.colHeadName === 'status') {
                if (row.status === 'Healthy') {
                    cls = 'bgHealthy';
                }
            }
            return cls;
        },
        getRowClass: row => {
            let cls = row._class || '';
            if (row.status === 'Unhealthy') {
                cls += 'bgCritical';
            }
            return cls;
        },
    };
    /*
     * Public Properties
     */

    public brokerHealthTableCols = [
        {
            colHeadName: 'zone',
            colHeadValue: 'Zone',
        },
        {
            colHeadName: 'box',
            colHeadValue: 'Box',
        },
        {
            colHeadName: 'broker_group',
            colHeadValue: 'Broker Groups',
        },
        {
            colAction: row => this.openIngestRoutingModal(row),
            colHeadName: 'action',
            colHeadValue: 'Action',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-button/c-td-button.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-button/c-td-button',
            ),
        },
    ];

    public brokerHealthTableRows = [];
    private brokerList: any[] = [];
    private drainConfirm = '';
    private drainBrokers = [];
    private fillConfirm = '';
    private fillBrokers = [];

    constructor(private acceo: Acceo, private notification: CToastsService, public dialogService: DialogService) {
        this.getBrokers();
    }

    /*
     * Public Methods
     */
    public async getBrokers() {
        // Call get brokers API
        const url: string = '/ops/get-brokers';
        // const params = {}
        try {
            const resp = await this.acceo.get(Response)(url);
            this.brokerList = resp.brokers;
        } catch (err) {
            this.notification.error(err);
        }

        // Populate table
        this.brokerHealthTableRows = this.brokerList;
    }

    public async saveBrokers(drainOrFill, brokerDesc) {
        // Decide if we're draining or filling
        if (drainOrFill === 'DRAIN') {
            const url: string = '/ops/manage-brokers';

            try {
                const resp = await this.acceo.post(Response)(url, {brokersDrain: this.drainBrokers});
                if (resp.save_response) {
                    this.notification.success(`Marked the following broker(s) to be drained: "${brokerDesc}"`);
                } else {
                    this.notification.error(`API returned an error: ${resp.save_response}`);
                }
            } catch (err) {
                this.notification.error(err);
            }
            this.getBrokers();
        } else if (drainOrFill === 'FILL') {
            const url: string = '/ops/manage-brokers';

            try {
                const resp = await this.acceo.post(Response)(url, {brokersFill: this.fillBrokers});
                if (resp.save_response) {
                    this.notification.success(`Marked the following broker(s) to be filled: "${brokerDesc}"`);
                } else {
                    this.notification.error(`API returned an error: ${resp.save_response}`);
                }
            } catch (err) {
                this.notification.error(err);
            }
            this.getBrokers();
        }
    }

    public async submitIngestRoutingModal(output, modalValue) {
        if (output) {
            if (modalValue.drain_confirm) {
                this.drainConfirm = modalValue.drain_confirm;
                this.drainBrokers = modalValue.broker_id;
                if (this.drainConfirm === 'DRAIN') {
                    this.saveBrokers(this.drainConfirm, modalValue.broker_desc);
                } else {
                    this.notification.error("Must type 'DRAIN' exactly");
                }
            } else if (modalValue.fill_confirm) {
                this.fillConfirm = modalValue.fill_confirm;
                this.fillBrokers = modalValue.broker_id;
                if (this.fillConfirm === 'FILL') {
                    this.saveBrokers(this.fillConfirm, modalValue.broker_desc);
                } else {
                    this.notification.error("Must type 'FILL' exactly");
                }
            }
        }
    }

    public async openIngestRoutingModal(row) {
        if (row.action.includes('Fill')) {
            // Fill modal
            let brokerDetails = [];
            if (row.zone_info) {
                brokerDetails = row.zone_info;
            } else {
                brokerDetails = [`${row.box} -- ${row.broker_group}`];
            }

            // Get dialog variables
            const modalValue = {
                broker_details: brokerDetails,
                broker_desc: row.box,
                broker_id: [row.broker_id],
                fill_confirm: '',
            };

            // Open dialog
            this.dialogService
                .open({
                    model: {
                        bodyModel: {
                            inputValues: modalValue,
                        },
                        bodyViewModel: PLATFORM.moduleName(
                            'apps/acuity/templates/components/modals/modal/ingest-routing/fill-body',
                        ),
                        footerEnable: true,
                        footerViewModel: PLATFORM.moduleName(
                            'apps/acuity/templates/components/modals/modal/ingest-routing/fill-footer',
                        ),
                        size: 'medium',
                        title: 'Are you sure?',
                    },
                    viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
                })
                .whenClosed()
                .then(response => {
                    this.submitIngestRoutingModal(response.output, modalValue);
                });
            // End of Fill modal
        } else {
            // Drain modal
            let borkerDetails = [];
            if (row.zone_info) {
                borkerDetails = row.zone_info;
            } else {
                borkerDetails = [`${row.box} -- ${row.broker_group}`];
            }

            // Get dialog variables
            const modalValue = {
                broker_details: borkerDetails,
                broker_desc: row.box,
                broker_id: [row.broker_id],
                drain_confirm: '',
            };

            // Open dialog
            this.dialogService
                .open({
                    model: {
                        bodyModel: {
                            inputValues: modalValue,
                        },
                        bodyViewModel: PLATFORM.moduleName(
                            'apps/acuity/templates/components/modals/modal/ingest-routing/drain-body',
                        ),
                        footerEnable: true,
                        footerViewModel: PLATFORM.moduleName(
                            'apps/acuity/templates/components/modals/modal/ingest-routing/drain-footer',
                        ),
                        size: 'medium',
                        title: 'Are you sure?',
                    },
                    viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
                })
                .whenClosed()
                .then(response => {
                    this.submitIngestRoutingModal(response.output, modalValue);
                });
            // End of Drain modal
        }
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsArray()
    public brokers: any[] = [];

    @IsArray()
    public save_response: any[] = [];
}
