import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';

import {IsNumber, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class RetokeniseURL {
    /*
     * Computed Properties
     */

    @computedFrom('state')
    get retokeniseBtnState() {
        return this.state === 'retokenising' ? 'disabled' : '';
    }

    public retokenised_url: string = '';
    public error: number = 0;

    /*
     * Private Properties
     */
    private state: string = 'idle';
    private playback_url: string = '';

    constructor(private acceo: Acceo, private notification: CToastsService) {}

    public async retokeniseURL() {
        this.state = 'retokenising';
        const url: string = '/ops/re-tokenise';
        try {
            const resp = await this.acceo.post(Response)(url, {
                playback_url: this.playback_url,
            });
            this.retokenised_url = resp.retokenised_url;
            this.error = resp.error;
            this.state = 'done';
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }
}

export class Response {
    @IsString()
    public cms_session_fingerprint: string;

    @IsString()
    public cms_session_id_fingerprint: string;

    @IsNumber()
    public error: any;

    @IsString()
    public retokenised_url: string;

    @IsNumber()
    public success: number;
}
