import {PLATFORM} from 'aurelia-pal';
import {activationStrategy} from 'aurelia-router';

export class MultiCdnRoute {
  public routes;

  constructor() {
    this.routes = [
      {
        activationStrategy: activationStrategy.invokeLifecycle,
        moduleId: PLATFORM.moduleName('./index'),
        name: 'ruleSets',
        nav: true,
        route: '',
        title: 'Rule Sets',
      },
      {
        activationStrategy: activationStrategy.invokeLifecycle,
        moduleId: PLATFORM.moduleName('./index'),
        name: 'profiles',
        nav: true,
        route: 'profiles',
        title: 'Profiles',
      },
      {
        activationStrategy: activationStrategy.invokeLifecycle,
        moduleId: PLATFORM.moduleName('./index'),
        name: 'capacities',
        nav: true,
        route: 'capacities',
        title: 'Capacities',
      },
    ];
  }

  public configureRouter(config) {
    config.map(this.routes);
  }
}
