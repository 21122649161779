import {autoinject} from 'aurelia-framework';
import {AdServerDebugItem, AdServerDebugType} from './models/ad-server-debug-item';
import {AdServerDebugService} from './services/ad-server-debug-service';

@autoinject()
export class AdServerDebug {
    public adDataModal;
    public adDebugItems: AdServerDebugItem[] = [
        {
            msg: 'message',
            type: AdServerDebugType.Error,
        },
    ];

    public debugError: string;
    public debugName: string;
    public formState: string;

    constructor(public adServerDebugService: AdServerDebugService) {}

    public async fetchAdData() {
        if (!this.debugName) {
            this.formState = 'error';
            return Promise.reject('No debug name value');
        }
        this.formState = null;
        this.debugError = null;
        this.adDebugItems = [];
        this.adDataModal.open();
        return this.adServerDebugService
            .getAdDebugItems(this.debugName)
            .then((debugItems: AdServerDebugItem[]) => {
                this.adDebugItems = debugItems;
            })
            .catch(err => {
                this.debugError = err || 'error';
            });
    }
}
