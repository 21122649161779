import {ITemplateListTableRow} from 'apps/cms/routes/slicers/templates/list/table-models';
import {Config} from 'resources/config/config';
import {ConfigFormat} from '../../models/config-format';
import {ConfigInput as ConfigInputBase} from '../../models/config-input';
import {ConfigImportMode, ConfigImportSource} from './config-import';

class ConfigInputImport {
  public fileValue: string = '';
  public mode: ConfigImportMode = ConfigImportMode.APPEND;
  public config = new Config();
  public source: ConfigImportSource = ConfigImportSource.FILE;
  // todo: move to hosted slicers package
  public template: ITemplateListTableRow;
}

export class ConfigInput extends ConfigInputBase {
  public exportFormat: ConfigFormat;
  public import = new ConfigInputImport();
}
