// eslint-disable-next-line max-classes-per-file
import {Expose, Transform, Type} from 'class-transformer';
import {IsBoolean, IsDefined, IsNumber, IsOptional, IsString} from 'class-validator';
import {ProfilesResponseItem} from './profiles';
/* tslint:disable:max-classes-per-file */

export function convertConfigurationStringToArray(configurationString: string = ''): {key: string; value: string}[] {
  const configuration = configurationString ? configurationString.trim().replace(/\r/g, '') : configurationString;

  if (!configuration) {
    return [];
  }

  // Array of config line items
  let configurationLinesList = configuration.split('\n');

  // Remove empty array elements and comment lines
  configurationLinesList = configurationLinesList.filter(c => c && !c.startsWith('#'));

  // List of objects of each config line - eg: "slicerID: abc" to {key: 'slicerID', value: 'abc'}
  const configurationObjectsList = configurationLinesList.map(c => {
    // Split the line by the first occurrence of ':'
    const [
      key,
      ...value
    ] = c.split(':');

    return {key: key.trim(), value: value.join(':').trim()};
  });

  // Filter out any config line items that don't have a config 'key'
  return configurationObjectsList.filter(_c => _c.key);
}

export function convertConfigurationArrayToString(configuration: {key: string; value: string}[] = []): string {
  return configuration
    ? configuration
        .filter(c => c.key && c.value) // Remove configuration items without a key or value
        .map(_v => `${Object.values(_v)[0]}:${Object.values(_v)[1]}`) // Create list of "key:value" strings
        .join('\n')
    : '';
}

export const stateLabelMapping = [
  'Inactive',
  'Slicing',
  'Ads',
  'Replacing content',
  'Blackout',
  'Shutting Down',
  'Shutdown',
];

export const stateToStatusMapping = [
  'neutral', // Inactive (gray)
  'slicer', // Slicing (green)
  'ad', // Ads (blue)
  'override', // Replacing content ()
  'neutral', // Blackout (black)
  'warning', // Shutting down (red)
  'danger', // Shutdown
];

export class Connection {
  public sourceUrl: string;
  public secureSourceUrl: string;
  public streamKey: string;
}

export class Ports {
  @IsNumber()
  @IsOptional()
  public port: number;

  @IsNumber()
  @IsOptional()
  public port_fec2: number;

  @IsNumber()
  @IsOptional()
  public port_fec4: number;
}

export class SlicerRestartResult {
  @IsString()
  public id: string;

  @IsBoolean()
  public result: boolean;
}

export class Task {
  @IsOptional()
  public id: string;

  @IsOptional()
  public operation: string;

  @IsOptional()
  public state: string;
}

export class ChangeLog {
  @IsString()
  public created: string;

  @IsString()
  public action: string;

  @IsString()
  public by_whom: string;
}

export class SlicerStatus {
  @IsNumber()
  public state: number = -1;

  @IsString()
  @IsOptional()
  public thumb_url: string;

  @IsString()
  @Transform((__, obj) => stateLabelMapping[_.isNumber(obj.state) ? obj.state + 1 : 0])
  @Expose()
  public stateLabel: string;

  @IsString()
  @Transform((__, obj) => stateToStatusMapping[_.isNumber(obj.state) ? obj.state + 1 : 0])
  @Expose()
  public stateColor: string;

  @IsOptional()
  @IsBoolean()
  public failover_healthy: boolean;
}

export class SlicerConfig {
  @IsDefined()
  public id: string;

  @IsString()
  public description: string;

  @IsString()
  @IsOptional()
  @Transform(val => (val ? 'On' : 'Off'))
  public debugoverlay: string;

  @IsString()
  @IsOptional()
  @Transform(val => _.startCase(val))
  public preview: string;

  @IsNumber()
  @IsOptional()
  public ssl_port: number;

  @IsOptional()
  public capture_delay: number;

  @IsOptional()
  public live_preview_max_viewers: number;

  @IsOptional()
  public ancillary_lines: string;

  @IsOptional()
  public audio_layout: string;

  @IsOptional()
  public capture_mode: string;

  @IsOptional()
  public SCTE104_SDID: string;

  @IsOptional()
  public SCTE104_DID: string;

  @IsOptional()
  public autoexpire_age: number;

  @IsOptional()
  public gain: string;

  @IsOptional()
  public captions_DID: string;

  @IsOptional()
  public captions_SDID: string;

  @IsOptional()
  public ancillary_scan: string;

  @IsOptional()
  public api_port: string;

  @IsOptional()
  public card: number;
}

export class StreamType {
  @IsString()
  @IsOptional()
  public id: string;

  @IsString()
  @IsOptional()
  public name: string;
}

export class Zone {
  @IsString()
  @IsOptional()
  public environment: string;

  @IsString()
  @IsOptional()
  public id: string;

  @IsString()
  @IsOptional()
  public location: string;

  @IsString()
  @IsOptional()
  public name: string;

  @IsString()
  @IsOptional()
  public type: string;

  @IsString()
  @IsOptional()
  public region: string;
}

export class Slicer {
  @IsString()
  public id: string;

  @IsString()
  @IsOptional()
  public type?: string = 'Owned';

  @IsOptional()
  public group?: any;

  @IsString()
  @IsOptional()
  public slicer_software_version?: string;

  @IsOptional()
  @Type(() => SlicerStatus)
  public status?: SlicerStatus | string;

  @IsOptional()
  @Type(() => ProfilesResponseItem)
  public profile?: ProfilesResponseItem;

  @IsString()
  @IsOptional()
  public encoding_profile_id?: string;

  @IsOptional()
  @Transform(v => convertConfigurationArrayToString(v), {toPlainOnly: true})
  @Transform(v => convertConfigurationStringToArray(v), {toClassOnly: true})
  public configuration?: any;

  @IsOptional()
  @Type(() => ChangeLog)
  public change_log?: ChangeLog[] = null;

  @IsString()
  @IsOptional()
  public hostname?: string;

  @IsOptional()
  public lastmod?: string;

  @IsOptional()
  public created?: string;

  @IsOptional()
  public deleted?: string;

  @IsOptional()
  public low_latency?: number;

  // runtime field, need not be serialized
  public selected: boolean = false;
  public isRestarting?: boolean = false;
}

export class IngestPoint {
  @IsString()
  public id: string;

  @IsString()
  @IsOptional()
  public endpoint_id: string;

  @IsString()
  @IsOptional()
  public domain_name: string;

  @IsString()
  public name: string;

  @IsOptional()
  @IsString()
  public description: string = '';

  @IsOptional()
  @Type(() => StreamType)
  public stream_type: StreamType;

  @IsOptional()
  @Type(() => Connection)
  public connection: Connection;

  @IsOptional()
  @Type(() => Zone)
  public zone: Zone;

  @IsOptional()
  public created: string;

  @IsString()
  @IsOptional()
  public slicerAccountName: string;

  @IsOptional()
  @Type(() => Ports)
  public ports: Ports;

  @IsOptional()
  public port: number;

  @IsOptional()
  public stream_type_name: string;

  @IsOptional()
  public region: string;

  @IsString()
  public status: string;

  @IsOptional()
  public active_slicer: string;

  // runtime field, need not be serialized
  public selected: boolean = false;
}
