import {Type} from 'class-transformer';
import {IsBoolean, IsNumber, IsOptional, IsString, IsUrl, ValidateNested} from 'class-validator';

export class MetaKeyValue {
  @IsString()
  public key: string;

  @IsString()
  public value: string;

  @IsBoolean()
  @IsOptional()
  public checkbox?: boolean = false;
}

export class TestPlayer {
  @IsString()
  @IsOptional()
  public url?: string;

  @IsString()
  @IsOptional()
  public url_html5?: string;

  @IsString()
  @IsOptional()
  public id?: string;

  @IsNumber()
  @IsOptional()
  public expire?: number;

  @IsString()
  @IsOptional()
  public desc?: string;

  @IsString()
  @IsOptional()
  public params?: string;

  @IsBoolean()
  @IsOptional()
  public whitelist?: boolean = false;

  @IsBoolean()
  @IsOptional()
  public checkbox?: boolean = false;

  @IsString()
  @IsOptional()
  public view?: string = 'View';
}

export class OverrideModel {
  @IsString()
  @IsOptional()
  public id?: string;

  @IsString()
  @IsOptional()
  public type?: string;

  @IsString()
  @IsOptional()
  public start?: string;
}

export class ChannelModel {
  @IsString()
  @IsOptional()
  public source_id?: string;

  @IsString()
  @IsOptional()
  public source_desc?: string;

  @IsNumber()
  @IsOptional()
  public use_chsched?: number;

  @IsBoolean()
  @IsOptional()
  public fast_channel?: boolean = false;

  @IsString()
  public desc: string;

  @IsString()
  public id: string;

  @IsString()
  @IsOptional()
  public status_type?: string;

  @ValidateNested()
  @Type(() => MetaKeyValue)
  public meta: MetaKeyValue[];

  @IsString()
  @IsOptional()
  public slicer_id: string;

  @IsString()
  @IsOptional()
  public override_slicer_id: string;

  @IsBoolean()
  @IsOptional()
  public has_slicer: boolean;

  @IsString()
  @IsOptional()
  public thumb_url?: string;

  @IsString()
  public external_id: string;

  @IsString()
  @IsOptional()
  public dash_url?: string;

  @IsNumber()
  @IsOptional()
  public live_delay?: number;

  @IsString()
  @IsOptional()
  public embed_html5_player_url?: string;

  @IsBoolean()
  @IsOptional()
  public require_studio_drm?: boolean;

  @IsString()
  @IsOptional()
  public hls_url?: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => TestPlayer)
  public test_players: TestPlayer[];

  @IsString()
  @IsOptional()
  public embed_domains?: string;

  @IsBoolean()
  @IsOptional()
  public require_drm?: boolean;

  @IsBoolean()
  @IsOptional()
  public failover_monitoring?: boolean;

  @IsString()
  @IsOptional()
  public failover_slicer_group?: string;

  @IsString({each: true})
  @IsOptional()
  public failover_slicer_ids?: string[];

  @IsString()
  @IsOptional()
  public failover_group_name?: string;

  @IsString()
  @IsOptional()
  public failover_group_id?: string;

  @IsString()
  @IsOptional()
  public ad_slate?: string;

  @IsString({each: true})
  @IsOptional()
  public ad_slate_library_set?: string[];

  @IsString()
  @IsOptional()
  public ad_slate_desc?: string;

  @IsUrl()
  @IsOptional()
  public ad_slate_poster?: string;

  @IsString()
  @IsOptional()
  public blackout_slate?: string;

  @IsString({each: true})
  @IsOptional()
  public blackout_slate_library_set?: string[];

  @IsString()
  @IsOptional()
  public blackout_slate_desc?: string;

  @IsUrl()
  @IsOptional()
  public blackout_slate_poster?: string;

  @IsString()
  @IsOptional()
  public missing_content_slate?: string;

  @IsString({each: true})
  @IsOptional()
  public missing_content_slate_library_set?: string[];

  @IsString()
  @IsOptional()
  public missing_content_slate_desc?: string;

  @IsUrl()
  @IsOptional()
  public missing_content_slate_poster?: string;

  @IsString()
  @IsOptional()
  public source_type?: string;

  @IsString()
  @IsOptional()
  public playback_profile_id?: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => OverrideModel)
  public override?: OverrideModel;
}
