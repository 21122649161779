import {ITimeEntryBasic} from '@bindable-ui/bindable';
import * as SWorker from 'simple-web-worker';

import {ScheduleEntry} from 'apps/cms/routes/live-channels/channels/single/models/event-model';

interface IScheduledEntryTimeEntry extends ITimeEntryBasic {
  child: boolean;
  model: ScheduleEntry;
  noSnap?: boolean;
  priority: number;
}

const actions = [
  {
    func: transformEntriesFn,
    message: 'transformEntries',
  },
  {
    func: checkActiveStatusFn,
    message: 'checkActiveStatus',
  },
];

let worker: any = null;

// Cleanup worker after it has been used
export const cleanupWorkers = () => {
  if (worker) {
    worker = null;
  }
};

const setupWorkers = () => {
  if (!worker) {
    worker = SWorker.create(actions);
  }
};

function transformEntriesFn(entries: any[], readOnly: boolean, tzOffset): ITimeEntryBasic[] {
  let uniqEntries = [];

  // https://stackoverflow.com/a/60141914
  const uniqBy = (arr, predicate) => {
    if (!Array.isArray(arr)) {
      return [];
    }

    const cb = typeof predicate === 'function' ? predicate : o => o[predicate];

    const pickedObjects = arr
      .filter(item => item)
      .reduce((map, item) => {
        const key = cb(item);

        if (!key) {
          return map;
        }

        return map.has(key) ? map : map.set(key, item);
      }, new Map())
      .values();

    return Array.from(pickedObjects);
  };

  const appendLeadingZeroes = n => {
    if (n <= 9) {
      return `0${n}`;
    }

    return n;
  };

  const formatHHmm = isoString => {
    const dateObj = new Date(new Date(isoString).getTime() + tzOffset * 60 * 1000);

    return `${appendLeadingZeroes(dateObj.getHours())}:${appendLeadingZeroes(dateObj.getMinutes())}`;
  };

  if (readOnly) {
    uniqEntries = uniqBy(entries, item => `${item.title}-${item.start}`);

    return uniqEntries.map(item => {
      const transformedItem: any = {
        duration: item.dur / 1000,
        end: new Date(item.stop).toISOString(),
        id: `${item.title}-${item.start}`,
        model: item,
        start: new Date(item.start).toISOString(),
        title: item.title,
        accentColor: 'var(--lynk-color-success)',
        icons: ['channel'],
      };

      if (item.live) {
        transformedItem.active = true;
        transformedItem.altTime = `Started at ${formatHHmm(new Date(item.start))}`;
        transformedItem.priority = 10;
      }

      return transformedItem;
    });
  }

  uniqEntries = uniqBy(entries, 'id');

  return uniqEntries.map(item => {
    const icons = [];
    const now = new Date().getTime();
    const itemStart = new Date(item.start).getTime();
    const itemEnd = new Date(item.end).getTime();

    let accentColor = null;
    let active = false;
    let background = null;
    const color = null;
    let sizeDay = 'small';
    let sizeWeek = 'expandable';
    let title = item.desc;
    let priority = null;
    let noSnap = null;
    let child = null;
    let altTime = null;

    item.title = item.desc;

    if (now > itemStart && now < itemEnd) {
      active = true;
    }

    if (item.isAdBreak) {
      accentColor = 'var(--lynk-color-sky-500)';
      icons.push('ad-break');
      sizeDay = null;
      sizeWeek = null;
    }

    if (item.isSlicer) {
      priority = 10;
      accentColor = 'var(--lynk-color-success)';
      icons.push('video-slicer');
      item.title = item.content_id;
      title = item.content_id;
      sizeDay = null;
      sizeWeek = null;
    }

    if (item.isAsset) {
      accentColor = 'var(--lynk-color-neutral-800)';
      icons.push('video-file');
      sizeDay = null;
      sizeWeek = null;
    }

    if (item.isRepeat) {
      accentColor = 'var(--lynk-color-primary)';
      icons.push('replay');
      sizeDay = null;
      sizeWeek = null;
    }

    if (item.isOverlay) {
      accentColor = 'var(--lynk-color-teal-600)';
      icons.push('graphic-overlay');
      sizeDay = 'small';
      sizeWeek = 'small';
    }

    if (item.isSlicerAsset) {
      accentColor = 'var(--lynk-color-success)';
      icons.push('channel');
      sizeDay = null;
      sizeWeek = null;
      noSnap = true;

      // Only show the slicing background if the asset has been slicing in the
      // time since the last poll window (80 seconds)
      if (now - itemEnd < 80000) {
        altTime = `Started at ${formatHHmm(new Date(item.start))}`;
        background =
          "#252525 url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23161616' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E\")";
      }

      if (item.from_schedule) {
        child = true;
      }

      if (item.slicer_id) {
        title = `${title} - from ${item.slicer_id}`;
      }
    }

    if (item.source && item.source.type === 'playlist') {
      icons.push('video-playlist');
    }

    if (item.isReplaced) {
      title = `${title} (REPLACED)`;
    }

    if (item.isMatchSignal) {
      background = '#111';
      icons.push('signal');
    }

    if (item.isMatchTime) {
      background = '#111';
      icons.push('time');
    }

    if (item.rules.length > 0) {
      icons.push('rules');
    }

    const entry: IScheduledEntryTimeEntry = {
      accentColor,
      active,
      altTime,
      background,
      child,
      color,
      icons,
      noSnap,
      priority,
      sizeDay,
      sizeWeek,
      title,
      duration: (item.dur || 0) / 1000,
      end: item.end,
      model: item,
      start: item.start,
    };

    return entry;
  });
}

function checkActiveStatusFn(entries: any[], tzOffset) {
  if (!entries || !entries.length) {
    return [];
  }

  const now = new Date().getTime();

  const appendLeadingZeroes = n => {
    if (n <= 9) {
      return `0${n}`;
    }

    return n;
  };

  const formatHHmm = isoString => {
    const dateObj = new Date(new Date(isoString).getTime() + tzOffset * 60 * 1000);

    return `${appendLeadingZeroes(dateObj.getHours())}:${appendLeadingZeroes(dateObj.getMinutes())}`;
  };

  return entries.map(item => {
    const itemStart = new Date(item.start).getTime();
    const itemEnd = new Date(item.end).getTime();

    if (now > itemStart && now < itemEnd) {
      item.active = true;
    } else {
      item.active = false;
    }

    if (item.isSlicerAsset) {
      item.altTime = `Started at ${formatHHmm(new Date(item.start))}`;
      // Only show the slicing background if the asset has been slicing in the
      // time since the last poll window (80 seconds)
      if (now - itemEnd < 80000) {
        item.background =
          "#252525 url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23161616' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E\")";
      } else {
        item.background = null;
      }
    }

    return item;
  });
}

export const transformEntries = async (
  entries: any[],
  readOnly: boolean,
  tzOffset: number,
): Promise<ITimeEntryBasic[]> => {
  let updatedEntries;

  if (readOnly) {
    updatedEntries = [...entries];
  } else {
    // Make computed properties static for the web worker
    updatedEntries = entries.map(item => {
      const entry = {...item};

      entry.isAdBreak = item.isAdBreak;
      entry.isAsset = item.isAsset;
      entry.isEditable = item.isEditable;
      entry.isLive = item.isLive;
      entry.isMatchSignal = item.isMatchSignal;
      entry.isMatchTime = item.isMatchTime;
      entry.isReplaced = item.isReplaced;
      entry.isSlicer = item.isSlicer;
      entry.isSlicerAsset = item.isSlicerAsset;
      entry.isRepeat = item.isRepeat;
      entry.isOverlay = item.isOverlay;

      return entry;
    });
  }

  if (window.Worker) {
    setupWorkers();

    // remove remove actions from entries so that they can be passed to the worker
    updatedEntries = updatedEntries.map(item => {
      delete item.actions;
      return item;
    });

    return worker.postMessage('transformEntries', [
      updatedEntries,
      readOnly,
      tzOffset,
    ]);
  }

  return transformEntriesFn(entries, readOnly, tzOffset);
};

export const checkActiveStatus = async (entries: any[], tzOffset: number): Promise<ITimeEntryBasic[]> => {
  if (window.Worker) {
    setupWorkers();

    // remove remove actions from entries so that they can be passed to the worker
    const cleanedEntries = entries.map(item => {
      delete item.actions;
      return item;
    });

    return worker.postMessage('checkActiveStatus', [
      cleanedEntries,
      tzOffset,
    ]);
  }

  return checkActiveStatusFn(entries, tzOffset);
};
