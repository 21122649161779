/* eslint-disable camelcase */
import {autoinject, BindingEngine, computedFrom, LogManager, observable} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {humanize} from 'inflection';

import {
  CFormAddRemoveActions,
  CTableActions,
  CTableCol,
  CToastsService,
  LocalStorageHelper,
  SecondsToHmsValueConverter,
} from '@bindable-ui/bindable';
import {BreakOffset} from 'apps/cms/routes/content/models/asset-model';
import {IContentFilterParams, Library} from 'apps/cms/routes/content/models/models';
import {ContentService, IPlayerParams} from 'apps/cms/routes/content/services/content';
import {LibraryService} from 'apps/cms/routes/content/services/library';
import {HyperionFilterParams} from 'apps/cms/routes/live-channels/models/models';
import {RuleService} from 'apps/cms/routes/live-channels/rules/services/rules';
import {SlicerService} from 'apps/cms/routes/slicers/services/slicer-service';
import {FieldValidation} from 'apps/cms/utils/field-validation';
import {FormValidator} from 'apps/cms/utils/form-validator';
import {DurationTools} from 'apps/cms/routes/live-channels/channels/single/utils/duration-tools';
import {NAV_TABS, ONE_HOUR, BreakType} from 'apps/cms/routes/live-channels/channels/single/utils/constants';
import {ISelectOption, IAssetRow, IAdBreakRow} from 'apps/cms/routes/live-channels/channels/single/utils/interfaces';
import * as moment from 'moment';
import {IDurationActions} from 'resources/components/forms/v-duration/v-duration';
import {
  IScheduledEntryListParams,
  IScheduledEntryParams,
  ScheduleEntryService,
} from '../../../services/scheduled-entry';
import {ChannelModel} from '../../models/channel-model';
import {
  ConflictResolution,
  ITrackedScheduledEntry,
  ScheduledEntryContentType,
  ScheduleEntry,
} from '../../models/event-model';

const ADD_BODY = 'add-body';
const DEFAULT_AD_BREAK_DESC = 'Ad Break';
const log = LogManager.getLogger('schedule-add-add-body-modal');
const TIME_REQUIRED = 'Time is required';
const VALID_DATE_TIME = 'Time must be a valid date time';
const FUTURE_30_DAYS = 'Time cannot be more than 30 days in the future';

@autoinject()
export class AddBody {
  // Allows access to enum in view
  ConflictResolution = ConflictResolution;

  @computedFrom('lastUpdatedAdBreaks', 'totalAdBreakDuration')
  get adBreakDuration() {
    const duration = this.getTotalAdBreakDuration();

    return duration;
  }

  @computedFrom('adBreaksNavState', 'model.content_id', 'isAsset', 'model.isEditable')
  get adBreakDurationHeaderVisible() {
    if (!this.model || !this.isAsset) {
      return false;
    }

    const {content_id, isEditable} = this.model;

    return isEditable && content_id && this.adBreaksNavState === 'active';
  }

  @computedFrom('durationDeltaIsValid', '_totalDurationOverride', 'adBreakData.length')
  get adBreaksNavIcon() {
    if (this.adBreakData.length === 0) {
      return '';
    }

    if (!this.durationDeltaIsValid || this._totalDurationOverride) {
      return 'warning';
    }

    return '';
  }

  @computedFrom('activeTab', 'isAsset')
  get adBreaksNavState() {
    if (!this.isAsset) {
      return 'hidden';
    }

    return this.activeTab === NAV_TABS.AD_BREAKS ? 'active' : '';
  }

  @computedFrom('contentService.currentModel')
  get assetDuration() {
    if (!this.contentService || !this.contentService.currentModel) {
      return 0;
    }

    return this.contentService.currentModel.duration * 1000;
  }

  @computedFrom('formValidator.errors.blackout_id', 'startTimeState')
  get blackoutState() {
    if (this.formValidator && this.formValidator.getErrorMsg('blackout_id')) {
      return 'error';
    }

    if (this.startTimeState === 'disabled') {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('activeTab')
  get conflictsNavState() {
    return this.activeTab === NAV_TABS.CONFLICTS ? 'active' : '';
  }

  @computedFrom('scheduleEntryService.gap')
  get trimRadioState() {
    return this.scheduleEntryService.hasGap ? '' : 'disabled';
  }

  @computedFrom('model.type', 'model.content_type')
  get descriptionCopy(): string {
    if (!this.model) {
      return '';
    }

    if (this.model.isAdBreak) {
      return 'A scheduled ad opportunity for server-side ad insertion';
    }

    if (this.model.isAsset) {
      return 'A video asset from your content library';
    }

    if (this.model.isSlicer) {
      return 'A scheduled slicer from your slicers';
    }

    if (this.model.isMatchSignal) {
      return 'A blackout that is triggered by signals read on slicer ingest';
    }

    if (this.model.isMatchTime) {
      return 'A scheduled-based blackout';
    }

    return 'unknown';
  }

  @computedFrom('formValidator.errors.desc', 'formValidator.errors.blackout_id', 'isValid')
  get detailsNavIcon() {
    if (!this.formValidator) {
      return '';
    }

    const {errors} = this.formValidator;

    if (errors.desc || errors.blackout_id) {
      return 'warning';
    }

    return '';
  }

  @computedFrom('activeTab', 'isAsset')
  get detailsNavState() {
    if (this.isAsset) {
      return 'hidden';
    }

    return this.activeTab === NAV_TABS.DETAILS ? 'active' : '';
  }

  @computedFrom('adBreaksAreEqual', 'durationDeltaIsValid')
  get distributeAdBreakState() {
    if (!this.adBreaksAreEqual || !this.durationDeltaIsValid) {
      return '';
    }

    return 'disabled';
  }

  @computedFrom('durationDelta')
  get durationDeltaHeaderText(): string {
    return this.durationDelta >= 1 ? 'Time Overage' : 'Time Deficit';
  }

  @computedFrom('durationDelta', 'isAsset', 'model.content_id')
  get durationDeltaIsValid(): boolean {
    if (!this.isAsset || !this.model.content_id) {
      return true;
    }

    return this.durationDelta > -1 && this.durationDelta < 1;
  }

  @computedFrom('durationDelta')
  get durationDeltaText(): string {
    const delta = this.durationDeltaIsValid;

    if (!this.durationDelta || delta) {
      return 'None';
    }

    return SecondsToHmsValueConverter.transform(this.durationDelta);
  }

  @computedFrom('model.start', 'model.dur', 'isAsset', 'formValidator.errors.end_ro', 'conflictMethodRadio')
  get endTime(): string {
    if (!this.model) {
      return '';
    }

    const {start} = this.model;

    if (!start) {
      return 'You must select a start time.';
    }

    if (this.isAsset && !this.contentService.currentModel) {
      return 'Select an Asset';
    }

    if (
      [
        ConflictResolution.TRIM_START,
        ConflictResolution.TRIM_END,
      ].includes(this.conflictMethodRadio)
    ) {
      return this.gapEndTime.format(this.timeFormat);
    }

    if (this.formValidator && this.formValidator.errors.end_ro) {
      return this.formValidator.errors.end_ro;
    }

    // Add duration to startTime
    const time = moment(start).add(this.model.dur, 'milliseconds');

    return time.format(this.timeFormat);
  }

  @computedFrom('model.start', 'scheduleEntryService.gap')
  get gapEndTime() {
    const {start} = this.model;

    // Add duration to startTime
    const time = moment(start).add(this.scheduleEntryService.gap, 'milliseconds');

    return time;
  }

  @computedFrom('model.isAdBreak')
  get isAdBreak() {
    if (this.model) {
      return this.model.isAdBreak;
    }

    return false;
  }

  @computedFrom('model.isAsset')
  get isAsset() {
    if (this.model) {
      return this.model.isAsset;
    }

    return false;
  }

  @computedFrom('model.isSlicer')
  get isSlicer() {
    if (this.model) {
      return this.model.isSlicer;
    }

    return false;
  }

  @computedFrom('model.isMatchSignal')
  get isMatchSignal() {
    if (this.model) {
      return this.model.isMatchSignal;
    }

    return false;
  }

  @computedFrom('model.isMatchTime')
  get isMatchTime() {
    if (this.model) {
      return this.model.isMatchTime;
    }

    return false;
  }

  @computedFrom('model.isRepeat')
  get isRepeat() {
    if (this.model) {
      return this.model.isRepeat;
    }

    return false;
  }

  @computedFrom('formValidator.errors.desc', 'startTimeState', 'isAsset')
  get matchNameState() {
    if (this.isAsset) {
      return 'hidden';
    }

    if (this.formValidator && this.formValidator.getErrorMsg('desc')) {
      return 'error';
    }

    if (this.startTimeState === 'disabled') {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('formValidator.errors.content_id', 'isSlicer')
  get slicerNameState() {
    if (!this.isSlicer) {
      return 'hidden';
    }

    if (this.formValidator && this.formValidator.getErrorMsg('content_id')) {
      return 'error';
    }

    return '';
  }

  @computedFrom('contentService.libraryId')
  get noAssetsMessage() {
    if (this.contentService.libraryId) {
      return 'No assets currently in this library.';
    }

    return 'There are no assets in your account.';
  }

  @computedFrom('activeTab')
  get rulesNavState() {
    if (this.isAdBreak) {
      return 'hidden';
    }

    return this.activeTab === NAV_TABS.RULES ? 'active' : '';
  }

  @computedFrom('startTimeState')
  get scheduledRulesState() {
    if (this.startTimeState === 'disabled') {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('formValidator.errors.content_id')
  get selectVodNavIcon() {
    if (!this.formValidator) {
      return '';
    }

    const {errors} = this.formValidator;

    if (errors && errors.content_id) {
      return 'warning';
    }

    return '';
  }

  @computedFrom('activeTab', 'model.isNew', 'isAsset')
  get selectVodNavState() {
    if ((this.model && !this.model.isNew) || !this.isAsset) {
      return 'hidden';
    }

    return this.activeTab === NAV_TABS.SELECT_VOD ? 'active' : '';
  }

  @computedFrom(
    'formValidator.errors.start',
    'model.start',
    'model.id',
    'model.replay_source_start',
    'model.replay_source_end',
  )
  get startTimeState() {
    // With replay, the replay end time is validated against the start time of the entry.
    // Validate the form to avoid any possible error messages that should be removed if the
    // start time is modified.
    if (
      this.formValidator &&
      this.model &&
      this.model.content_type === ScheduledEntryContentType.REPLAY &&
      this.model.replay_source_end
    ) {
      this.formValidator.validate();
    }
    if (this.formValidator && this.formValidator.getErrorMsg('start')) {
      return 'error';
    }

    if (this.model && this.model.id && moment(this.model.start).isBefore(moment())) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('formValidator.errors.replay_source_start', 'model.replay_source_start', 'model.id', 'model.start')
  get repeatStartTimeState() {
    if (this.formValidator && this.formValidator.getErrorMsg('replay_source_start')) {
      return 'error';
    }

    if (this.model && this.model.id && moment(this.model.start).isBefore(moment())) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('formValidator.errors.replay_source_end', 'model.replay_source_end', 'model.id', 'model.start')
  get repeatEndTimeState() {
    if (this.formValidator && this.formValidator.getErrorMsg('replay_source_end')) {
      return 'error';
    }

    if (this.model && this.model.id && moment(this.model.start).isBefore(moment())) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('_totalDuration', '_totalDurationOverride')
  get totalDuration(): string {
    return this._totalDurationOverride || this._totalDuration;
  }

  set totalDuration(value) {
    this._totalDuration = value;
    this._totalDurationOverride = null;
  }

  @computedFrom('_totalDurationErrorMsg', 'formValidator.errors.dur')
  get totalDurationErrorMsg() {
    if (this.formValidator && this.formValidator.getErrorMsg('dur')) {
      return this.formValidator.getErrorMsg('dur');
    }
    return this._totalDurationErrorMsg;
  }

  @computedFrom('totalDurationLock')
  get totalDurationIcon() {
    if (this.model && this.isAsset && this.model.isEditable) {
      return this.totalDurationLock ? 'lock' : 'unlocked';
    }

    return '';
  }

  @computedFrom(
    'breakOffsets',
    'totalDurationErrorMsg',
    'totalDurationLock',
    'startTimeState',
    'isAsset',
    'model.content_id',
  )
  get totalDurationTextState() {
    if (
      !this.model ||
      (this.isAsset && (!this.model.content_id || this.breakOffsets === 0)) ||
      this.startTimeState === 'disabled'
    ) {
      return 'disabled';
    }

    // if (this.isAsset && this.breakOffsets === 0) {
    //     return 'disabled';
    // }

    if (this.totalDurationErrorMsg) {
      return 'error';
    }

    if (this.totalDurationLock) {
      return 'info';
    }

    return '';
  }

  @computedFrom('contentService.meta.total', 'contentService.meta.maxResults')
  get totalResultsText() {
    const {maxResults, total} = this.contentService.meta;
    if (total > maxResults) {
      return `${maxResults}+`;
    }

    return `${total}`;
  }

  @computedFrom('conflictMethodRadio')
  get useTrimConflict() {
    return [
      ConflictResolution.TRIM_START,
      ConflictResolution.TRIM_END,
    ].includes(this.conflictMethodRadio);
  }

  @computedFrom('model.content_type')
  get humanizeContentType(): string {
    return this.model ? humanize(this.model.content_type, true) : '';
  }

  @computedFrom('model.content_type')
  get startTimeTip() {
    if (this.model?.content_type === ScheduledEntryContentType.REPLAY) {
      return 'Specifies the time at which the source content will begin to be replayed';
    }
    return `Start time of the ${this.humanizeContentType}`;
  }

  @computedFrom('model.content_type')
  get endTimeTip() {
    if (this.model?.content_type === ScheduledEntryContentType.REPLAY) {
      return 'Specifies the time at which the source content will stop being played';
    }
    return `End time of the ${this.humanizeContentType}`;
  }

  @computedFrom('model.content_type')
  get durationTip() {
    if (this.model?.content_type === ScheduledEntryContentType.REPLAY) {
      return 'Specifies the duration for which the source content will be replayed';
    }
    return 'Specifies the duration for which the content will be played';
  }

  @observable public conflictMethodRadio: ConflictResolution | null = null;

  public durationDelta: number = 0;

  public NAV_TABS = NAV_TABS;

  public activeTab: number;
  public adBreakData: IAdBreakRow[] = [];
  public breakOffsets = 0;
  public channelModel: ChannelModel;
  public clonedAdBreakDurationActions: IDurationActions = {
    buttonAction: durationInSeconds => {
      const adBreaks = this.adBreakData.concat();
      // eslint-disable-next-line no-return-assign
      adBreaks.forEach(b => (b.duration = durationInSeconds));

      this.adBreakData = adBreaks;

      this.updateAdBreaks();
      this.updateDurationWithAdBreakData();
    },
  };

  public currentAssetSort: string = '-created';
  public fieldValidations: any = {};
  public formValidator: FormValidator;
  public libraryActions = {
    onChange: () => {
      setTimeout(() => {
        // eslint-disable-next-line no-multi-assign
        this.contentService.libraryId = this.libraryService.activeId = this.selectedLibrary;
        LocalStorageHelper.save(ADD_BODY, this.selectedLibrary);
        this.loadContent();
      }, 0);
    },
    onScrollBottom: () => {
      setTimeout(() => {
        this.contentService.getMoreContent();
      }, 150);
    },
  };

  public libraryList: ISelectOption[];
  public listActions: CTableActions = {
    getColClass: (row, col): string => {
      let cls = col._class || '';

      if (col.colHeadName === 'duration' && row.state === 'slicing') {
        cls += ' bgProcessing';
      }

      return cls.trim();
    },
    getRowClass: row => {
      let cls = row._class || '';

      if (row.state === 'slicing') {
        cls += ' notAllowed';
      }

      return cls.trim();
    },
    onScrollBottom: async () => {
      if (this.contentService.isLoading || this.contentService.isLoadingMore) {
        return;
      }

      await this.contentService.getMoreContent();

      this.mapVodData();
    },
    rowClick: async (row: IAssetRow) => {
      if (row.state !== 'ready') {
        return;
      }

      await this.loadAsset(row.id);

      row._class = 'active';

      const {currentModel} = this.contentService;

      if (currentModel) {
        const {duration, id, title} = currentModel;

        this.model.content_id = id;
        this.totalDurationLock = false;

        if (title && title.length) {
          this.model.desc = title;
        }

        this.formatTotalDuration(Math.ceil(duration));
        this.generateAdBreaks();
      }
    },
    sortColumn: (col: CTableCol, reverse: boolean) => {
      this.currentAssetSort = `${reverse ? '-' : ''}${col.colHeadName}`;
      this.loadContent();
    },
  };

  public matchName: string = '';
  public model: ITrackedScheduledEntry;
  public params: IContentFilterParams = {};
  public playerUrl: string;
  public rulesLeftActions: CFormAddRemoveActions = {
    onScrollBottom: async () => {
      if (this.ruleService.isLoading || this.ruleService.isLoadingMore) {
        return;
      }

      await this.ruleService.getMoreRules();

      this.mapRuleSchedule();
    },
    // onSearch: async searchText => {
    //     await this.ruleService.onSearch(searchText);
    // },
  };

  public rulesRightActions = {
    onChange: () => {
      if (this.model) {
        this.model.rules = (this.scheduledRules || []).map(rule => rule.value);
      }
    },
  };

  public scheduleRules: ISelectOption[] = [];
  public scheduledRules: ISelectOption[] = [];
  public selectedLibrary: string;
  public timeFormat: string = 'MM/DD/YYYY HH:mm:ss';
  public totalAdBreakDuration: number;
  public totalDurationLock: boolean = false;
  // public totalDuration: any;
  public totalDurationEventListeners = {
    blur: () => {
      this.onTotalDurationUpdate();
    },
    keyup: event => {
      if (event.keyCode === 13) {
        this.onTotalDurationUpdate();
      }
    },
  };

  public vodData: IAssetRow[] = [];

  // Ad Break Table
  public adBreakCols = [
    {
      colClass: 't30',
      colHeadName: 'breakNumber',
      colHeadValue: '#',
      sort: false,
    },
    {
      colHeadName: 'offset',
      colHeadValue: 'Offset',
      sort: false,
      valueConverter: 'secondsToHms',
    },
    {
      _class: 'textInput',
      callback: (val, adBreak: IAdBreakRow) => {
        this.updateAdBreak(val, adBreak);
      },
      colClass: 't150',
      colHeadName: 'duration',
      colHeadValue: 'Duration',
      view: PLATFORM.moduleName('resources/components/forms/v-duration/v-duration.html'),
      viewModel: PLATFORM.moduleName('resources/components/forms/v-duration/v-duration'),
    },
  ];

  public tableActions = {
    getRowClass: row => {
      let cls = row._class || '';
      if (!row.enabled) {
        cls += ' bgWarning strike-through';
      }
      return cls;
    },
  };

  // Conflicts Table
  public conflictsCols = [
    {
      colHeadName: 'entryName',
      colHeadValue: 'Entry Name',
      sort: true,
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate'),
    },
    {
      colClass: 't105',
      colHeadName: 'type',
      colHeadValue: 'Type',
      sort: true,
    },
    {
      colClass: 't190',
      colHeadName: 'startTime',
      colHeadValue: 'Start Time',
      sort: true,
      valueConverter: 'isoToFormattedMoment',
      valueConverterFormat: this.timeFormat,
    },
  ];

  // Select VOD Table
  public vodCols = [
    {
      colHeadName: 'title',
      colHeadValue: 'Title',
      sort: true,
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate'),
    },
    {
      colClass: 't120',
      colHeadName: 'ad_breaks',
      colHeadValue: 'Ad Breaks',
    },
    {
      colClass: 't105',
      colHeadName: 'duration',
      colHeadValue: 'Duration',
      colWidth: 50,
      sort: true,
      view: PLATFORM.moduleName('resources/components/forms/v-duration-or-status/v-duration-or-status.html'),
      viewModel: PLATFORM.moduleName('resources/components/forms/v-duration-or-status/v-duration-or-status'),
    },
  ];

  public testOptions: any[] = [
    {
      text: 'Value 1',
      value: 'value1',
    },
    {
      text: 'Value 2',
      value: 'value2',
    },
    {
      text: 'Value 3',
      value: 'value3',
    },
    {
      text: 'Value 4',
      value: 'value4',
    },
    {
      text: 'Value 5',
      value: 'value5',
    },
  ];

  protected lastUpdatedAdBreaks: string = null;

  private _totalDuration: string;
  private _totalDurationErrorMsg: string = '';
  private _totalDurationOverride: string = null;

  private adBreaksAreEqual: boolean = false;
  private autoGeneratedAdBreaks: IAdBreakRow[] = [];
  private defaultFieldValidations = {
    blackout_id: [
      new FieldValidation({
        enabled: (model: ScheduleEntry) => model.isMatchSignal,
        message: 'Blackout ID is required',
        presence: true,
      }),
    ],
    content_id: [
      new FieldValidation({
        enabled: (model: ScheduleEntry) => model.isAsset || model.isSlicer,
        message: (model: ScheduleEntry) => {
          let type = '';

          if (model.isAsset) {
            type = 'Asset';
          }

          if (model.isSlicer) {
            type = 'Slicer';
          }

          return `${type} is required`;
        },
        presence: true,
      }),
      new FieldValidation({
        customValidator: (value: string) => /^([a-zA-Z0-9]{32}:)?[a-zA-Z0-9_]{1,100}$/.test(value),
        enabled: (model: ScheduleEntry) => model.isSlicer,
        message: () => 'A valid slicer ID is required',
      }),
    ],
    desc: [
      new FieldValidation({
        enabled: (model: ScheduleEntry) => !(model.isAdBreak || model.isAsset || model.isSlicer),
        message: 'Name is required',
        presence: true,
      }),
    ],
    dur: [
      new FieldValidation({
        message: 'Total Duration is required',
        presence: true,
      }),
      new FieldValidation({
        message: 'Duration must be greater than 00:00:00',
        range: {gt: 0},
      }),
      new FieldValidation({
        message: 'total duration max is 12 hours',
        range: {lte: ONE_HOUR * 12},
      }),
      new FieldValidation({
        customValidator: (value, _model: ScheduleEntry, scope: AddBody) => {
          const {currentModel} = scope.contentService;

          if (!currentModel) {
            return false;
          }

          return Math.floor(currentModel.duration) <= Math.floor(value / 1000);
        },
        enabled: (model: ScheduleEntry, scope: AddBody) => scope.contentService.currentModel && model.isAsset,
        message: 'Total Duration < Asset Duration',
      }),
    ],
    end_ro: [
      new FieldValidation({
        customValidator: value => moment(value).isAfter(moment()),
        message: 'End Time cannot be in the past',
      }),
    ],
    rules: [
      new FieldValidation({
        enabled: (model: ScheduleEntry) => !(model.isAdBreak || model.isAsset || model.isSlicer || model.isRepeat),
        length: {min: 1},
        message: 'Must have at least one rule selected',
      }),
    ],
    start: [
      new FieldValidation({
        message: `Start ${TIME_REQUIRED}`,
        presence: true,
      }),
      new FieldValidation({
        customValidator: value => moment(value).isValid(),
        message: `Start ${VALID_DATE_TIME}`,
        passive: true,
      }),
      new FieldValidation({
        customValidator: value => moment(value).isBefore(moment().add(30, 'days')),
        message: `Start ${FUTURE_30_DAYS}`,
      }),
    ],
    replay_source_start: [
      new FieldValidation({
        enabled: (model: ScheduleEntry) => model.isRepeat,
        message: `Replay Start ${TIME_REQUIRED}`,
        presence: true,
      }),
      new FieldValidation({
        enabled: (model: ScheduleEntry) => model.isRepeat,
        customValidator: value => moment(value).isValid(),
        message: `Replay Start ${VALID_DATE_TIME}`,
        passive: false,
      }),
      new FieldValidation({
        enabled: (model: ScheduleEntry) => model.isRepeat,
        customValidator: value => moment(value).isBefore(moment().add(30, 'days')),
        message: `Replay Start ${FUTURE_30_DAYS}`,
      }),
      new FieldValidation({
        customValidator: (value, _model: ScheduleEntry, scope: AddBody) =>
          moment(value).isBefore(scope.model.replay_source_end),
        enabled: (model: ScheduleEntry) => model.isRepeat,
        message: 'Replay Start Time must be before Replay End Time',
      }),
    ],
    replay_source_end: [
      new FieldValidation({
        enabled: (model: ScheduleEntry) => model.isRepeat,
        message: `Replay End ${TIME_REQUIRED}`,
        presence: true,
      }),
      new FieldValidation({
        enabled: (model: ScheduleEntry) => model.isRepeat,
        customValidator: value => moment(value).isValid(),
        message: `Replay End ${VALID_DATE_TIME}`,
        passive: false,
      }),
      new FieldValidation({
        enabled: (model: ScheduleEntry) => model.isRepeat,
        customValidator: value => moment(value).isBefore(moment().add(30, 'days')),
        message: `Replay End ${FUTURE_30_DAYS}`,
      }),
      new FieldValidation({
        customValidator: (value, _model: ScheduleEntry, scope: AddBody) => {
          const result = moment(value).isSameOrBefore(scope.model.start);
          return result;
        },
        enabled: (model: ScheduleEntry) => model.isRepeat,
        message: 'Replay End Time must be before Start Time',
      }),
      new FieldValidation({
        customValidator: (value, _model: ScheduleEntry, scope: AddBody) => {
          const result = moment(value).diff(scope.model.replay_source_start, 'hours') <= 5;
          return result;
        },
        enabled: (model: ScheduleEntry) => model.isRepeat,
        message: 'Replay cannot exceed five hours',
      }),
    ],
  };

  private modalController = null;
  private observers = [];
  private channelId: string = null;
  private removeEntry: () => boolean;
  private startTime: string = '';

  constructor(
    public contentService: ContentService,
    private libraryService: LibraryService,
    private bindingEngine: BindingEngine,
    private notificationService: CToastsService,
    private scheduleEntryService: ScheduleEntryService,
    private ruleService: RuleService,
    public _slicerService: SlicerService,
  ) {}

  /*
    Aurelia Hooks
  */

  public async activate(model) {
    const {controller, shared} = model;
    const {entry, channelId, activeTab, removeEntry} = shared;

    this.modalController = controller;
    this.model = entry;
    this.channelId = channelId;
    this.removeEntry = removeEntry;
    this.startTime = this.model.start;

    await this.initModel();
    if (activeTab) {
      this.setActiveTab(activeTab);
    }
    if (this.model.content_type === ScheduledEntryContentType.REPLAY) {
      this.scheduleEntryService.saveEnabled = true;
    }
  }

  public attached() {
    this.selectedLibrary = LocalStorageHelper.loadOrDefault(ADD_BODY, null);

    this.observers = [
      this.bindingEngine.propertyObserver(this, 'slicerNameState').subscribe(() => this.isValid()),
      this.bindingEngine.propertyObserver(this, 'durationDeltaIsValid').subscribe(() => this.isValid()),
      this.bindingEngine.propertyObserver(this, 'conflictMethodRadio').subscribe(() => this.isValid()),
      // this.bindingEngine.propertyObserver(this, 'endTime').subscribe(() => this.clearConflicts()),
      this.bindingEngine.propertyObserver(this, '_totalDuration').subscribe(() => this.clearConflicts()),
    ];

    this.formValidator = new FormValidator(this.model, this);
    this.formValidator.register({...this.defaultFieldValidations, ...this.fieldValidations}, this.bindingEngine, () =>
      this.isValid(),
    );
  }

  public detached() {
    this.scheduleEntryService.resetService();

    this.contentService.cleanParams();
    this.contentService.resetCurrentModel();
    this.contentService.resetSearch();

    this.model = null;
    this.conflictMethodRadio = null;

    this.observers.forEach(observer => {
      try {
        observer.dispose();
      } catch {
        // Do nothing
      }
    });

    this.observers = [];
  }

  public async conflictMethodRadioChanged() {
    if (!this.conflictMethodRadio || !this.model || !this.model.start) {
      return;
    }

    this.scheduleEntryService.conflictChosenMethod = this.conflictMethodRadio;
    this._totalDurationOverride = null;

    const isResolution = [
      ConflictResolution.TRIM_START,
      ConflictResolution.TRIM_END,
    ].includes(this.conflictMethodRadio);

    if (this.scheduleEntryService.hasGap && isResolution) {
      // load player url with trimming params or without
      const params: IPlayerParams = {};

      if (this.conflictMethodRadio === ConflictResolution.TRIM_START) {
        params.start = Math.round((this.model.dur - this.scheduleEntryService.gap) / 1000);
      } else if (this.conflictMethodRadio === ConflictResolution.TRIM_END) {
        params.stop = Math.round(this.scheduleEntryService.gap / 1000);
      }

      this.updateAdBreaks(params);

      this.loadPlayer(this.contentService.currentModel.id, params);

      if (params.start || params.stop) {
        this.updateTotalDurationOverride();
      }
    }
  }

  /*
    Public Methods
  */

  public distributeAdBreakDurationEvenly() {
    this.generateAutoAdBreakDurations();
    this.setAdBreakData(this.autoGeneratedAdBreaks);

    this.adBreaksAreEqual = true;

    this.setDurationDelta();
    this.setModelAdBreakDurations();
  }

  public formatTotalDuration(durationOverride = null): void {
    try {
      this._totalDurationErrorMsg = '';
      const duration = DurationTools.ToSeconds(durationOverride || this.totalDuration);
      this.updateDuration(duration);
    } catch (err) {
      this._totalDurationErrorMsg = err.message;
    }
  }

  public isValid(): boolean {
    if (!this.formValidator) {
      return false;
    }
    const formIsValid: boolean = this.formValidator.isValid() && this.durationDeltaIsValid;
    const isValid: boolean = this.totalDurationErrorMsg ? false : formIsValid;

    this.scheduleEntryService.saveEnabled = isValid;

    return isValid;
  }

  public async save(closeDialog = true): Promise<boolean> {
    this.formValidator.validate();

    if (!this.isValid()) {
      return false;
    }

    return this.saveScheduleEntry(closeDialog);
  }

  public async searchVOD(searchText): Promise<void> {
    if (this.contentService.isLoading) {
      return;
    }

    await this.contentService.search(searchText);

    this.mapVodData();
  }

  public setActiveTab(activeTab) {
    if (activeTab === NAV_TABS.RULES) {
      this.loadRules();
    }

    this.activeTab = activeTab;
  }

  public toggleLock() {
    this.totalDurationLock = !this.totalDurationLock;
  }

  /*
    Private Methods
  */

  private applyActiveRules() {
    const rules = this.model.get('@included') || [];

    this.scheduledRules = rules.map(r => ({
      text: r.desc,
      value: r.id,
    }));
  }

  private asIAssetRow(asset, id) {
    const row: IAssetRow = {
      _class: id === asset.id ? 'active' : '',
      ad_breaks: asset.ad_breaks,
      duration: asset.duration,
      id: asset.id,
      poster_url: asset.poster_url,
      state: asset.status_state,
      title: asset.title,
    };
    return row;
  }

  // private calculateAdBreakDuration(index, totalOffsets, duration) {
  //   if (index === totalOffsets - 1) {
  //     return duration + this.totalAdBreakDuration - duration * totalOffsets;
  //   }

  //   return duration;
  // }

  private checkAdBreakDurationValuesForEquality() {
    const {ad_breaks} = this.model;
    const adBreakMap = (this.adBreakData || []).map(b => b.duration);
    const autoAdBreakMap = (this.autoGeneratedAdBreaks || []).map(b => b.duration);
    const durationIsSame = (ad_breaks || []).every(v => v === ad_breaks[0]);
    const durationIsAutoGenerated = _.isEqual(adBreakMap, autoAdBreakMap);

    this.adBreaksAreEqual = durationIsSame || durationIsAutoGenerated;

    return this.adBreaksAreEqual;
  }

  private clearConflicts() {
    this.scheduleEntryService.clearConflicts();

    if (this.activeTab === NAV_TABS.CONFLICTS) {
      this.showDefaultTab();
    }

    this._totalDurationOverride = null;
    this.conflictMethodRadio = null;
  }

  private generateAdBreaks(): void {
    if (!this.model || !this.model.isAsset) {
      this.adBreakData = [];
      return;
    }

    if (this.model.isNew) {
      this.distributeAdBreakDurationEvenly();
    } else {
      this.generateAdBreaksFromModel();
    }
  }

  private generateAdBreaksFromModel(): void {
    const {currentModel} = this.contentService;

    if (!this.model || !currentModel || !this.model.isAsset) {
      this.adBreakData = [];
      return;
    }

    const {break_offsets} = currentModel;
    const {ad_breaks} = this.model;

    if (ad_breaks.length === 0) {
      this.adBreakData = [];
      return;
    }

    const breaks = break_offsets
      .filter(b => b.break_type === BreakType.AD)
      .map((offset, index) => this.mapAdBreakRow(offset, ad_breaks[index], index));

    this.setAdBreakData(breaks);

    // generate the auto set for comparing equality
    this.generateAutoAdBreakDurations();
    this.checkAdBreakDurationValuesForEquality();
  }

  private generateAutoAdBreakDurations() {
    const {currentModel} = this.contentService;

    if (!currentModel) {
      this.autoGeneratedAdBreaks = [];
      return;
    }

    const {isEditable = false} = this.model;

    this.autoGeneratedAdBreaks = DurationTools.EqualizedBreakOffsets(
      currentModel.break_offsets,
      this.totalAdBreakDuration,
      isEditable,
    );
  }

  private getTotalAdBreakDuration(): number {
    let duration = 0;

    // eslint-disable-next-line no-return-assign
    this.adBreakData.forEach(b => {
      if (b.enabled) {
        duration += b.duration;
      }
    });

    return duration;
  }

  private async initAdBreak(): Promise<void> {
    if (!this.model.isNew) {
      this.formatTotalDuration(this.model.dur / 1000);
    } else {
      this.model.desc = DEFAULT_AD_BREAK_DESC;
    }

    this.setActiveTab(NAV_TABS.DETAILS);

    this.setupScheduleEntryService('Ad Break');
  }

  private async initAsset(): Promise<void> {
    // eslint-disable-next-line no-multi-assign
    this.contentService.libraryId = this.libraryService.activeId = LocalStorageHelper.loadOrDefault(ADD_BODY, null);

    if (this.model.isNew) {
      this.loadLibraries();
      this.loadContent();

      this.setActiveTab(NAV_TABS.SELECT_VOD);
    } else {
      const {content_id} = this.model;

      await this.loadAsset(content_id);
      this.applyActiveRules();
      this.generateAdBreaks();
      this.formatTotalDuration(this.model.dur / 1000);

      this.setActiveTab(NAV_TABS.AD_BREAKS);
      this.totalDurationLock = true;
    }

    this.setupScheduleEntryService('Asset');
  }

  private async initModel(): Promise<void> {
    if (this.isAsset) {
      await this.initAsset();
    } else if (this.isAdBreak) {
      await this.initAdBreak();
    } else if (this.isSlicer) {
      await this.initSlicer();
    } else if (this.isMatchSignal || this.isMatchTime) {
      await this.initMatch();
    } else if (this.isRepeat) {
      await this.initRepeat();
    } else {
      throw new Error('Invalid model type.');
    }
  }

  private async initSlicer(): Promise<void> {
    if (!this.model.isNew) {
      this.formatTotalDuration(this.model.dur / 1000);
    }

    this.setActiveTab(NAV_TABS.DETAILS);

    this.setupScheduleEntryService('Slicer');
  }

  private async initMatch(): Promise<void> {
    if (!this.model.isNew) {
      this.applyActiveRules();
      this.formatTotalDuration(this.model.dur / 1000);
    }

    this.setActiveTab(NAV_TABS.DETAILS);

    this.setupScheduleEntryService(this.model.isMatchSignal ? 'Signal Blackout' : 'Timed Blackout');
  }

  private async initRepeat(): Promise<void> {
    if (!this.model.isNew) {
      this.formatTotalDuration(this.model.dur / 1000);
    }

    this.setActiveTab(NAV_TABS.DETAILS);

    this.setupScheduleEntryService('Replay');
  }

  private async loadAsset(assetId: string) {
    const {currentModel} = this.contentService;

    if (!currentModel || currentModel.id !== assetId) {
      // eslint-disable-next-line no-return-assign
      this.vodData.forEach(_asset => (_asset._class = ''));

      this.playerUrl = null;
      this.conflictMethodRadio = null;

      await this.contentService.getSingleAsset(assetId);

      if (!this.model.desc) {
        this.model.desc = this.contentService.currentModel.title;
      }

      await this.loadPlayer(assetId);
    }

    this.isValid();
  }

  private async loadConflicts() {
    const {dur, start} = this.model;

    const _params: IScheduledEntryListParams = {
      end: moment(start).add(dur, 'milliseconds').toISOString(),
      start: moment(start).toISOString(),
    };

    await this.scheduleEntryService.getConflicts(_params, this.channelId, this.model.id);
    this.setActiveTab(NAV_TABS.CONFLICTS);
  }

  private async loadContent() {
    await this.contentService.getContent({
      order: this.currentAssetSort,
    });

    this.mapVodData();
  }

  private async loadLibraries() {
    let sorted = [];

    try {
      await this.libraryService.loadLibraries();
      sorted = [
        ...this.mapLibraries(this.libraryService.libraries, l => l.desc),
        ...this.mapLibraries(this.libraryService.externalLibraries, l => `${l.desc} (${l.owner_name})`),
      ];
    } catch (ex) {
      log.error(ex);
      this.notificationService.error(`Unable to load libraries: ${ex.message}`);
    } finally {
      this.libraryList = [
        {
          text: 'My Content (All)',
          value: null,
        },
      ].concat(sorted);
    }
  }

  private async loadRules() {
    const params: HyperionFilterParams = {
      order: 'desc',
      page: 1,
      page_size: 30,
    };
    const rules = this.ruleService.rules || [];

    if (rules.length === 0) {
      await this.ruleService.getRules(params);
    }

    this.mapRuleSchedule();
  }

  private async loadPlayer(id, params?) {
    try {
      this.playerUrl = await this.contentService.getPlayURL(id, params);
    } catch {
      this.notificationService.error('Unable to load player url');
    }
  }

  private mapAdBreakRow(offset: BreakOffset, duration, index) {
    const breakNumber = index + 1;
    const {isEditable = false} = this.model;

    const row: IAdBreakRow = {
      breakNumber,
      duration,
      breakType: offset.break_type as BreakType,
      name: offset.name,
      offset: offset.offset,
      state: isEditable ? '' : 'disabled',
      enabled: true,
    };

    return row;
  }

  // eslint-disable-next-line class-methods-use-this
  private mapLibraries(libraries, getText: (library: Library) => string) {
    return libraries
      .map(l => ({
        text: getText(l),
        value: l.id,
      }))
      .sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
  }

  private mapRuleSchedule() {
    const scheduledRuleIds = (this.scheduledRules || []).map(rule => rule.value);
    const rules = (this.ruleService.rules || [])
      .concat()
      .filter(rule => !scheduledRuleIds.includes(rule.id))
      .map(rule => ({
        text: rule.desc,
        value: rule.id,
      }));

    this.scheduleRules = rules;
  }

  private mapVodData() {
    const {currentModel} = this.contentService;

    this.vodData = (this.contentService.assets || []).map(asset =>
      this.asIAssetRow(asset, currentModel ? currentModel.id : ''),
    );
  }

  private onTotalDurationUpdate() {
    if (this._totalDurationOverride) {
      return;
    }

    try {
      const prevDuration = `${this.totalDuration}`;

      this.formatTotalDuration();

      if (this.model.isAsset && this.adBreaksAreEqual) {
        this.generateAdBreaks();
      }

      this.setDurationDelta();

      if (this.model.isAsset && prevDuration !== this.totalDuration && !this.totalDurationLock) {
        this.totalDurationLock = true;
      }
    } catch (err) {
      this.notificationService.info(err.message);
    }
  }

  private resetModel(start) {
    Object.assign(this.model, {
      start,
      ad_breaks: null,
      blackout_id: null,
      content_id: null,
      // content_type: this.model.content_type,
      desc: this.model.isAdBreak ? DEFAULT_AD_BREAK_DESC : null,
      // dur: 0,
      // rules: [],
      id: null,
    });

    this.model.updateCanonical();

    if (this.isAsset) {
      this.model.dur = 0;
      this.contentService.currentModel = null;
      this.adBreakData = [];
      this.conflictMethodRadio = null;
      this._totalDuration = null;
      this._totalDurationOverride = null;
    }
  }

  private async saveScheduleEntry(closeDialog = true): Promise<boolean> {
    let ad_breaks = [];
    const {
      blackout_id,
      content_id,
      content_type,
      desc,
      dur,
      start,
      id,
      isAsset,
      rules,
      replay_source_start,
      replay_source_end,
    } = this.model;

    if (isAsset) {
      // this.setModelAdBreakDurations();
      ad_breaks = this.model.ad_breaks;
    }

    // If start time is a different day than the original start time, we need to delete the original entry
    if (this.startTime && moment(this.startTime).format('YYYY-MM-DD') !== moment(start).format('YYYY-MM-DD')) {
      if (this.removeEntry) {
        this.removeEntry();
      }
    }

    // Normalized for serializations
    const params: IScheduledEntryParams = {
      ad_breaks,
      blackout_id,
      content_id,
      content_type,
      desc,
      dur,
      rules,
      replay_source_start: moment(replay_source_start).toISOString(),
      replay_source_end: moment(replay_source_end).toISOString(),
      start: moment(start).toISOString(),
    };

    if (this.conflictMethodRadio) {
      params.conflict_resolution = this.conflictMethodRadio;
    }

    // We neither want or need the duration to be floating point (already in ms)
    if (params.dur) {
      params.dur = Math.floor(params.dur);
    }

    if (this.isSlicer && content_id.indexOf(':') > -1) {
      const idSplit = content_id.split(':');
      params.content_owner = idSplit[0];
      params.content_id = idSplit[1];
    }

    try {
      let entry: ScheduleEntry;

      if (id) {
        entry = await this.scheduleEntryService.updateScheduledEntry(id, params, this.channelId);

        this.notificationService.success('Updated');
      } else {
        entry = await this.scheduleEntryService.saveScheduledEntry(params, this.channelId);

        this.notificationService.success('Saved');
      }

      if (closeDialog) {
        this.resetModel(moment(start).toISOString());
        this.modalController.close();
      } else {
        this.clearConflicts();
        this.resetModel(entry.end);
        this.mapVodData();
        this.showDefaultTab();
      }

      return true;
    } catch (ex) {
      log.error(ex);

      if (ex.details && ex.details.length > 0) {
        await this.loadConflicts();
      }

      this.notificationService.error(ex.message);
    }

    return false;
  }

  private setAdBreakData(data: IAdBreakRow[]) {
    this.adBreakData = _.cloneDeep(data);
    this.lastUpdatedAdBreaks = moment().toISOString();
    this.updateBreakOffsets();
  }

  private setDurationDelta() {
    if (!this.model || !this.model.isAsset) {
      this.durationDelta = 0;
      return;
    }

    const sumDuration = this.assetDuration / 1000 + this.adBreakDuration;
    const durInSeconds = this.model.dur / 1000;

    this.durationDelta = sumDuration - durInSeconds;
  }

  /**
   * Updates model ad_breaks based on current adBreakData durations
   */
  private setModelAdBreakDurations() {
    let ad_breaks = [];

    if (this.model && this.model.isAsset) {
      ad_breaks = (this.adBreakData || []).map(adBreak => adBreak.duration);
    }

    this.model.ad_breaks = ad_breaks;
    this.checkAdBreakDurationValuesForEquality();
  }

  private setupScheduleEntryService(saveText): void {
    this.scheduleEntryService.allowOverwrite = true;
    this.scheduleEntryService.saveCallback = closeDialog => this.save(closeDialog);
    this.scheduleEntryService.saveEnabled = false;
    this.scheduleEntryService.saveText = saveText;
  }

  private showDefaultTab() {
    if (this.isAsset) {
      this.setActiveTab(NAV_TABS.SELECT_VOD);
    } else {
      this.setActiveTab(NAV_TABS.DETAILS);
    }
  }

  private updateAdBreak(val, adBreak: IAdBreakRow) {
    const _adBreak = this.adBreakData.find(b => b.breakNumber === adBreak.breakNumber);

    // do nothing if not found or duration is same
    if (!_adBreak || _adBreak.duration === val) {
      return;
    }

    _adBreak.duration = val;

    if (!this.totalDurationLock) {
      let totalDuration = this.getTotalAdBreakDuration();
      const {currentModel} = this.contentService;

      if (currentModel) {
        totalDuration += currentModel.duration;
      }

      this.updateDuration(totalDuration);
    }

    this.updateDurationWithAdBreakData();
  }

  private updateAdBreaks(params: IPlayerParams = {}) {
    let dur = 0;
    const {start, stop} = params;

    this.adBreakData.forEach(b => {
      dur += b.duration;
      if (start) {
        const totalDur = b.offset + dur;
        b.enabled = totalDur > start;
      } else if (stop) {
        const totalDur = b.offset + dur;
        b.enabled = totalDur < stop;
      } else {
        b.enabled = true;
      }
    });

    this.updateBreakOffsets();
  }

  private updateBreakOffsets() {
    this.breakOffsets = this.adBreakData.filter(b => b.enabled && b.breakType === BreakType.AD).length;
    this.getTotalAdBreakDuration();
    this.lastUpdatedAdBreaks = moment().toISOString();
  }

  private updateDuration(durationInSeconds = 0): void {
    const dur = durationInSeconds * 1000;

    if (this.totalDurationLock && dur !== this.model.dur) {
      throw new Error('Cannot update Total Duration while in "locked" state');
    }

    if (this.model) {
      this.model.dur = dur;
    }

    if (!this.model.isEditable) {
      this._totalDuration = SecondsToHmsValueConverter.transform(durationInSeconds);
      this.totalAdBreakDuration = this.getTotalAdBreakDuration();
      return;
    }

    this._totalDuration = SecondsToHmsValueConverter.transform(durationInSeconds);

    const {currentModel} = this.contentService;

    if (currentModel && this.isAsset) {
      const totalAdBreakDuration = durationInSeconds - Math.ceil(currentModel.duration);

      this.totalAdBreakDuration = totalAdBreakDuration > 0 ? totalAdBreakDuration : 0;
    }
  }

  private updateDurationWithAdBreakData(forceUpdate = false): void {
    if (!this.model || !this.model.isAsset) {
      return;
    }

    if (!this.totalDurationLock || forceUpdate) {
      let totalDuration = this.getTotalAdBreakDuration();
      const {currentModel} = this.contentService;

      if (currentModel) {
        totalDuration += currentModel.duration;
      }

      this.updateDuration(totalDuration);
    }

    this.setModelAdBreakDurations();
    this.setDurationDelta();
  }

  private updateTotalDurationOverride() {
    // let totalDuration = this.getTotalAdBreakDuration();
    // const {currentModel} = this.contentService;

    // if (currentModel) {
    //     totalDuration += currentModel.duration;
    // }

    const gap = this.scheduleEntryService.gap / 1000;

    this._totalDurationOverride = SecondsToHmsValueConverter.transform(gap);
  }
}
