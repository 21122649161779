import {bindable, autoinject, BindingEngine} from 'aurelia-framework';
import {FormValidator, IFieldValidations} from 'apps/cms/utils/form-validator';
import {FieldValidation} from 'apps/cms/utils/field-validation';
import {Overlay} from 'apps/cms/routes/settings/clipping/models/clipping-config';
import {ITrackedModel, trackedModel} from 'apps/cms/utils/tracked-model';
import {GraphicsService} from './services/graphics-service';
import {GraphicsUploaderFile} from './models/models';

export interface ITrackedOverlay extends Overlay, ITrackedModel {}

@autoinject
export class OverlayList {
  public searchInput: any;
  @bindable public searchText: string = '';
  @bindable public overlayModal: any;
  @bindable public deleteModal: any;
  public searchPopoverDisabled: boolean = true;
  public searchPopoverOpen: boolean = false;
  public searchTips: object[] = [];
  public overlayList: Overlay[];
  public viewOverlay: Overlay;
  public editOverlay: ITrackedOverlay;
  public inEditMode: boolean = false;
  public editSaveEnabled: boolean = false;
  public formValidator: FormValidator;
  private validators: IFieldValidations = {
    name: [
      new FieldValidation({
        presence: true,
        length: {min: 1, max: 120},
        message: 'Name is required',
      }),
    ],
  };

  public tabActive: string = 'local';
  public dropZone: any;
  public fileInput: any;
  public lastDropTarget: any;

  constructor(public graphicsService: GraphicsService, public bindingEngine: BindingEngine) {
    this.updateOverlayList();
  }

  public async attached() {
    window.addEventListener('dragenter', this.dragEnterHandler);
    window.addEventListener('dragleave', this.dragLeaveHandler);
    window.addEventListener('dragover', this.dragOverHandler);
    window.addEventListener('drop', this.dragDropHandler);
  }

  public detached() {
    window.removeEventListener('dragenter', this.dragEnterHandler);
    window.removeEventListener('dragleave', this.dragLeaveHandler);
    window.removeEventListener('dragover', this.dragOverHandler);
    window.removeEventListener('drop', this.dragDropHandler);
  }

  public dragEnterHandler = event => {
    if (this.tabActive !== 'local' || this.graphicsService.isUploading) {
      return;
    }
    this.lastDropTarget = event.target;
    this.dropZone.classList.add('is-active');
  };

  public dragOverHandler = event => {
    event.preventDefault();

    if (this.tabActive !== 'local' || this.graphicsService.isUploading) {
      return;
    }
    this.dropZone.classList.add('is-active');
  };

  public dragLeaveHandler = event => {
    event.preventDefault();

    if (this.tabActive !== 'local' || this.graphicsService.isUploading) {
      return;
    }
    if (event.target === this.lastDropTarget || event.target === document) {
      this.dropZone.classList.remove('is-active');
    }
  };

  public dragDropHandler = event => {
    event.preventDefault();

    if (this.tabActive !== 'local' || this.graphicsService.isUploading) {
      return;
    }
    const dt = event.dataTransfer;

    if (dt.files) {
      const files: any = Array.from(dt.files);
      this.addSelectedFiles(files);
    }

    this.dropZone.classList.remove('is-active');
  };

  public async uploadLocal() {
    await this.graphicsService.uploadFiles();

    this.removeUploadedQueuedFiles();
    this.updateOverlayList();
  }

  public async uploadHtml() {
    await this.graphicsService.uploadHtml();

    this.updateOverlayList();
  }

  public deleteOverlay(item: Overlay) {
    const drop = this.overlayList.splice(this.overlayList.indexOf(item), 1);
    this.graphicsService.deleteOverlay(drop[0]);
    this.deleteModal.hide();
  }

  public showOverlay(item: Overlay, editMode: boolean = false) {
    this.viewOverlay = item;
    this.inEditMode = editMode;

    if (this.inEditMode) {
      this.editOverlay = trackedModel({
        trackedKeys: [
          'name',
        ],
        SuperClass: Overlay,
        model: item,
      });

      this.formValidator = new FormValidator(this.editOverlay, this);
      this.formValidator.register(this.validators, this.bindingEngine, () => this.editFormIsValid());
    }

    this.overlayModal.addEventListener('on:request-close', event => {
      if (this.inEditMode && event.detail.source === 'overlay') {
        event.preventDefault();
      }
    });

    this.overlayModal.show();
  }

  public editFormIsValid(): boolean {
    if (!this.formValidator) {
      this.editSaveEnabled = false;
      return this.editSaveEnabled;
    }

    this.editSaveEnabled = this.formValidator.isValid();

    return this.editSaveEnabled;
  }

  public async saveOverlay() {
    try {
      const updated = await this.graphicsService.updateOverlay(this.editOverlay);
      if (updated) {
        this.overlayList.forEach(overlay => {
          if (overlay.id === this.editOverlay.id) {
            overlay.name = this.editOverlay.name;
          }
        });
        this.hideOverlay();
      }
    } catch (e) {
      // ignore
    }
  }

  public resetOverlay() {
    this.viewOverlay = null;
  }

  public showDelete(item: Overlay) {
    this.viewOverlay = item;
    this.deleteModal.show();
  }

  public hideOverlay() {
    this.overlayModal.hide();
  }

  public search() {
    this.updateOverlayList();
  }

  public resetSearch() {
    this.searchText = '';
    this.updateOverlayList();
  }

  public onSelectFiles(elm) {
    const files: any = Array.from(elm.files);
    this.addSelectedFiles(files);
    elm.value = null;
  }

  public addSelectedFiles(files: File[], index: number = 0) {
    const _files = files.map(file => new GraphicsUploaderFile(file));

    for (let i = index; i < _files.length; i++) {
      const file = _files[i];
      const fileNameExist =
        this.graphicsService.queuedFiles.filter(f => f.name === file.name && f.file.size === file.file.size).length > 0;
      if (!fileNameExist) {
        this.graphicsService.queuedFiles.unshift(file);
      }
    }
  }

  public removeAllQueuedFiles() {
    const {queuedFiles} = this.graphicsService;
    queuedFiles.splice(0, queuedFiles.length);
  }

  public removeUploadedQueuedFiles() {
    const {queuedFiles} = this.graphicsService;
    this.graphicsService.queuedFiles = queuedFiles.filter(f => !f.isUploaded);
  }

  public removeQueuedFile(file: GraphicsUploaderFile) {
    this.graphicsService.queuedFiles.splice(
      this.graphicsService.queuedFiles.findIndex(v => v === file),
      1,
    );
  }

  private async updateOverlayList() {
    this.overlayList = await this.graphicsService.getOverlayList(this.searchText);
  }
}
