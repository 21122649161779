import {Type} from 'class-transformer';
import {IsBoolean, IsString, ValidateNested} from 'class-validator';
import {IntegrationNameType} from './enums-config';

export class TwitterCredentials {
    @IsString()
    public oauth_token_secret: string = '';

    @IsString()
    public oauth_verifier: string = '';

    @IsString()
    public oauth_token: string = '';
}

export class TwitterMetaMap {
    @IsString()
    public profile_id: string = '';

    @IsString()
    public tweet: string = '';
}

export class Twitter {
    @ValidateNested()
    @Type(() => TwitterCredentials)
    public credentials: TwitterCredentials = new TwitterCredentials();

    @ValidateNested()
    @Type(() => TwitterMetaMap)
    public metamap: TwitterMetaMap = new TwitterMetaMap();

    @IsString()
    public name: string = IntegrationNameType.Twitter;

    @IsBoolean()
    public enabled: boolean = false;

    public newlyAdded: boolean = false;

    public constructor(init?: Partial<Twitter>) {
        Object.assign(this, init);
    }
}
