import {LogManager} from 'aurelia-framework';

const log = LogManager.getLogger('BasicSetup');

export function basicSetup<T extends {new (...args: any[]): {}}>(constructor: T) {
    return class BasicSetup extends constructor {
        public getData: () => Promise<any>;
        public saveData: () => Promise<any>;
        public isSaving: boolean;
        public isLoading: boolean;

        public activate() {
            if (typeof this.getData !== 'function') {
                log.error(
                    `[${this.constructor.name}] @basicSetup decorator used ` + 'but getData function is not defined.',
                );
                return true;
            }

            this.load();
        }

        public async load() {
            this.isLoading = true;
            await this.getData();
            this.isLoading = false;
        }

        public save() {
            if (typeof this.saveData !== 'function') {
                log.error(
                    `[${this.constructor.name}] @basicSetup decorator used ` + 'but saveData function is not defined.',
                );
                return true;
            }

            this.isSaving = true;

            return new Promise((resolve, reject) => {
                this.saveData()
                    .then(() => {
                        resolve();
                    })
                    .catch(reject)
                    .finally(() => {
                        this.isSaving = false;
                    });
            });
        }
    };
}
