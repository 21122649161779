import {bindable} from 'aurelia-framework';

import * as styles from './styles.css';

import {WorkspaceKeyRevision} from '../../models/workspace-key-revision';

export class RevisionPillGroup {
  @bindable()
  public revisions: WorkspaceKeyRevision[];

  @bindable()
  public selectedId: string;

  public styles = styles;
}
