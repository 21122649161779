export class Config {
    public input = new ConfigInput();
    public state = new ConfigState();
}

export class ConfigInput {
    public search: string;
    public searchKeys: string[] = [];
    public searchQuery: string;
}

class ConfigState {
    public canExportCSV: boolean;
    public error: string;
    public isDeleting: boolean;
    public isLoading: boolean;
}
