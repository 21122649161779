export default function testPlayerUpdate(modelTestPlayers = [], canonicalTestPlayers = []) {
    canonicalTestPlayers.forEach(testPlayer => {
        const findTp = modelTestPlayers.find(tp => tp.id === testPlayer.id);

        if (!findTp) {
            modelTestPlayers.push(testPlayer);
        } else {
            findTp.desc = testPlayer.desc;
            findTp.expire = testPlayer.expire;
            findTp.whitelist = testPlayer.whitelist;
            findTp.params = testPlayer.params;
        }
    });

    const canonicalIds = canonicalTestPlayers.map(tp => tp.id);

    return modelTestPlayers.filter(tp => canonicalIds.includes(tp.id));
}
