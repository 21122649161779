const DEFAULT_TIMEOUT = 5000;

export class Notification {
    constructor() {
        this.notifications = [];
    }

    add(type, message, title, timeout = DEFAULT_TIMEOUT, renderHtml = false) {
        const id = new Date().getTime();
        this.notifications.unshift({
            id,
            type,
            title,
            message,
            renderHtml,
        });
        this.setTimeout(id, timeout);
    }

    remove(notification) {
        const index = this.notifications.indexOf(notification);
        this.notifications.splice(index, 1);
    }

    keepAlive(id) {
        const notification = this.getNotificationById(id);
        clearTimeout(notification.disappearTimeout);
        notification.disappearTimeout = undefined;
    }

    getNotificationById(id) {
        return this.notifications.find(n => n.id === id);
    }

    setTimeout(id, timeout = DEFAULT_TIMEOUT) {
        const notification = this.getNotificationById(id);

        if (notification.removeTimeout) {
            clearTimeout(notification.disappearTimeout);
            notification.disappearTimeout = undefined;
        }

        // If you set the timeout to null, then the notification will stay until the
        // user clears the notification.  This is used in Live Events Dashboard to keep
        // error messages on the screen.
        // If you set the timeout to undefined, it will use DEFAULT_TIMEOUT
        if (timeout !== null) {
            notification.disappearTimeout = setTimeout(() => {
                this.remove(notification);
            }, timeout);
        }
    }

    success(message, title = 'Success', timeout = DEFAULT_TIMEOUT, renderHtml = false) {
        this.add('success', message, title, timeout, renderHtml);
    }

    warning(message, title = 'Warning', timeout = DEFAULT_TIMEOUT, renderHtml = false) {
        this.add('warning', message, title, timeout, renderHtml);
    }

    error(message, title = 'Error', timeout = DEFAULT_TIMEOUT, renderHtml = false) {
        this.add('error', message, title, timeout, renderHtml);
    }

    info(message, title = 'Info', timeout = DEFAULT_TIMEOUT, renderHtml = false) {
        this.add('info', message, title, timeout, renderHtml);
    }
}
