import {IEncodingProfileTableRow} from './table-models';

export class Config {
  public input = new ConfigInput();
  public options = new ConfigOptions();
  public state = new ConfigState();
}

export class ConfigInput {
  public defaultProfileLive: IEncodingProfileTableRow;
  public defaultProfileVod: IEncodingProfileTableRow;
  public rateCard: string;
  public search: string;
}

class ConfigOptions {
  public rateCard = [];
  public rateCardAll = [
    {
      text: 'All Rate Cards',
      value: undefined,
    },
  ];
}

class ConfigState {
  public canExportCSV: boolean;
  public canViewDeprecatedProfiles: boolean;
  public canViewDynamicCols: boolean;
  public error: string;
  public isLoading: boolean;
}
