import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {IdsClient, Rsponse} from '../model';

@autoinject
export class IdsSettingsService {
  public isLoading: boolean = false;
  public clientSettings: IdsClient;
  public saveBtnState: boolean = false;

  constructor(private acceo: Acceo) {}

  public async getIdsSettings(): Promise<IdsClient> {
    const url: string = '/ops/ids-settings/get';
    try {
      this.isLoading = true;
      const resp = await this.acceo.get(IdsClient)(url);
      this.clientSettings = resp;
      return this.clientSettings;
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  }

  public async deleteIdsSettings(params?: any[]) {
    const url: string = '/ops/ids-settings/delete';
    try {
      this.isLoading = true;
      const resp = await this.acceo.delete(Rsponse)(url, params);
      return resp;
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  }

  public async postIdsSettings(params?: any[]) {
    const url: string = '/ops/ids-settings/post';
    try {
      this.isLoading = true;
      const resp = await this.acceo.post(Rsponse)(url, params);
      return resp;
    } catch (error) {
      return error;
    } finally {
      this.isLoading = false;
    }
  }
}
