import {DialogService} from 'aurelia-dialog';
import {BindingEngine, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import {BetaMode} from 'services/beta-mode';
import {CToastsService} from '@bindable-ui/bindable';
import {LibraryService} from 'services/library-service';
import {SyndicationPlatformType, SyndicationScheduleContentType} from 'services/models/syndication';
import {SessionService} from 'services/session';
import {PublishController} from 'shared/syndication/publish-controller';

import {RuleService} from '../../rules/services/rules';

import {BlackoutService} from '../services/blackouts';
import {LiveChannelsService} from '../services/live-channels';
import {ScheduleEntryService} from '../services/scheduled-entry';

import {BaseChannelSingle} from './base';

@inject(PublishController)
export class ChannelPublishTargets extends BaseChannelSingle {
  public targetSearchText: string = '';
  public removeConfModal;

  // for some odd reason, the spread operator would not work (...rest) with the base class using @autoinject
  // could switch the base class to use @inject, but it's sixies.
  constructor(
    public publishController: PublishController,
    public bindingEngine: BindingEngine,
    public liveChannels: LiveChannelsService,
    public blackoutService: BlackoutService,
    public ruleService: RuleService,
    public notification: CToastsService,
    public dialogService: DialogService,
    public session: SessionService,
    public libraryService: LibraryService,
    public router: Router,
    public scheduleEntryService: ScheduleEntryService,
    public betaMode: BetaMode,
  ) {
    super(
      bindingEngine,
      liveChannels,
      blackoutService,
      ruleService,
      notification,
      dialogService,
      session,
      libraryService,
      router,
      scheduleEntryService,
      betaMode,
    );
  }

  public async activate(params) {
    super.activate(params);
    if (!this.model) {
      await super.getChannel();
    }
    this.publishController.init(
      SyndicationScheduleContentType.CHANNEL,
      this.channelId,
      this.liveChannels && this.liveChannels.currentModel && this.liveChannels.currentModel.require_studio_drm,
      this.model ? this.model.desc : '',
    );
  }

  public attached() {
    this.liveChannels.activeTab = 'publish';
    super.attached();
  }

  public deactivate() {
    this.publishController.deactivate();

    super.deactivate();
  }

  public async delete() {
    await this.publishController.deleteSelectedTargets();
    this.removeConfModal.hide();
  }

  public targetSearch() {
    this.publishController.searchText = this.targetSearchText;
  }

  get filteredTargets() {
    let targets = this.publishController.remainingTargets;
    if (!this.model.fast_channel || !this.session.hasFastAccess) {
      // Exclude Wurl targets
      targets = targets.filter(t => t.platform !== SyndicationPlatformType.WURL);
    }
    return targets;
  }
}
