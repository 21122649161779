import {DialogService} from 'aurelia-dialog';
import {autoinject, BindingEngine, computedFrom, LogManager} from 'aurelia-framework';

import {CToastsService, dirtyCheckPrompt} from '@bindable-ui/bindable';

import {Field, FieldMap} from 'resources/field/index';
import {authorizationConstants} from 'services/authorization';
import {SessionService} from 'services/session';

import metaUpdate from 'resources/meta-update';
import testPlayerUpdate from 'resources/test-player-update';
import {ContentService} from '../services/content';
import {LibraryService} from '../services/library';

import {AssetModel} from '../models/asset-model';
import {LibraryListResponse} from '../models/models';

const log = LogManager.getLogger('base-channel-service');

@authorizationConstants
@dirtyCheckPrompt
@autoinject()
export class BaseAssetSingle {
  @computedFrom('tabDirty', 'isSaving', 'model.mine')
  get saveButtonState() {
    if (this.model && !this.model.mine) {
      return 'disabled';
    }

    if (this.isSaving) {
      return 'thinking';
    }

    if (!this.isDirty()) {
      return 'disabled';
    }

    return '';
  }

  // Each tab must override this to enable dirty check.
  get tabDirty() {
    return false;
  }

  public assetId: string = null;
  public isSaving: boolean = false;
  public libraryId: string = null;
  /** Cloned and editable version of the current asset */
  public model: AssetModel = null;
  /** Polling Interval tracker */
  public pollInterval: any = null;
  public pollLibrary: any = null;
  public pollTimer: number = 1000 * 8;
  /** Fields for validation/dirty checking */
  public fields: FieldMap = {
    autoexpire: new Field('autoexpire'),
    embed_domains: new Field('embed_domains'),
    embed_html5_player_url: new Field('embed_html5_player_url'),
    external_id: new Field('external_id'),
    generate_staticm3u8: new Field('generate_staticm3u8'),
    meta: new Field('meta'),
    poster_url: new Field('poster_url'),
    require_drm: new Field('require_drm'),
    studio_drm_required: new Field('studio_drm_required'),
    test_players: new Field('test_players'),
    title: new Field('title'),
    source: new Field('source'),
    libraries: new Field('libraries'),
  };

  public nonTrackedFields: FieldMap = {
    export_mp4_job: new Field('export_mp4_job'),
    hd_exported_url: new Field('hd_exported_url'),
    slicer: new Field('slicer'),
    slicer_id: new Field('slicer_id'),
    staticm3u8_url: new Field('staticm3u8_url'),
  };

  /** Assigned on extended class */
  public tabFields: string[] = [];
  public isDeleting: boolean = false;
  public libraries: LibraryListResponse;

  constructor(
    public bindingEngine: BindingEngine,
    public content: ContentService,
    public notification: CToastsService,
    public dialogService: DialogService,
    public session: SessionService,
    public libraryService: LibraryService,
  ) {}

  public async activate(params) {
    const {id, libraryId} = params;
    this.assetId = id;
    this.libraryId = libraryId;

    await this.getAsset();
    this.getAssets();

    if (this.libraryId) {
      try {
        const lib = await this.libraryService.getLibraryFromCacheOrLoad(this.libraryId);

        this.content.updateSidebarTitles(`Search ${lib.desc}`, lib.desc);
      } catch (e) {
        this.content.updateSidebarTitles('Search Content', 'Content');
      }
    } else {
      this.content.updateSidebarTitles('Search Content', 'Content');
    }
  }

  public attached() {
    this.content.updateSubNavItems();
  }

  public deactivate() {
    this.stopAssetPolling();
  }

  public detached() {
    this.stopAssetPolling();
  }

  public async delete() {
    if (this.isDeleting) {
      return;
    }

    this.stopAssetPolling();

    this.isDeleting = true;

    if (await this.content.deleteContent(this.model.id)) {
      this.isDeleting = false;
      window.location.href = '#/content';
    } else {
      this.isDeleting = false;
      this.pollAsset();
    }
  }

  /**
   * Get the asset data
   */
  public async getAsset() {
    const {currentModel} = this.content;

    if (!currentModel || (currentModel && currentModel.id !== this.assetId)) {
      this.content.isAssetLoading = true;

      try {
        await this.content.getSingleAsset(this.assetId);

        this.model = _.cloneDeep(this.content.currentModel);
        await this.libraryService.getLibrary(this.model.libraries);
        this.pollAsset();
      } catch (e) {
        this.model = null;
        log.error(e);
      } finally {
        this.content.isAssetLoading = false;
      }
    } else if (currentModel && currentModel.id === this.assetId) {
      this.model = _.cloneDeep(currentModel);
      this.pollAsset();
    }
  }

  /**
   * Lazy-load assets in the background if it's a fresh page load
   */
  public async getAssets() {
    if (this.content.assets.length === 0) {
      try {
        await this.content.getContent({library: this.libraryId});

        this.setActive();
      } catch (e) {
        log.error(e);
      }
    } else {
      this.setActive();
    }
  }

  public async getPlayURL() {
    try {
      return await this.content.getPlayURL(this.assetId);
    } catch (e) {
      log.error(e);
      return undefined;
    }
  }

  public isDirty() {
    return this.tabDirty;
  }

  /**
   * Save the asset
   */
  public async save(): Promise<AssetModel | any> {
    if (!this.isDirty()) {
      return;
    }

    const dirtyFields = this.getDirtyFields() || [];

    if (!this.validateTab(dirtyFields)) {
      log.error('Failed validation.');
      this.notification.error('Error updating asset. Please make sure all fields are valid.');
      throw new Error('Validation Error'); // Bubble up to dirty checking modal
    }

    const updateModel = {
      id: this.model.id,
    };

    dirtyFields.forEach(field => {
      updateModel[field] = this.model[field];
    });

    this.isSaving = true;

    try {
      const res = await this.content.saveAsset(updateModel);
      if (res) {
        this.content.currentModel = res;
        this.model = _.cloneDeep(res);
        this.content.updateNavTitle();
      }
      this.isSaving = false;
      // eslint-disable-next-line consistent-return
      return res;
    } catch (e) {
      this.isSaving = false;
      log.error(e);
      this.notification.error(`Error updating asset: ${e.message}`);
      throw new Error(e); // Bubble up to dirty checking modal
    } finally {
      this.isSaving = false;
    }
  }

  /**
   * Makes sure that we don't fight over the navPage resource
   */
  public setActive() {
    if (this.pollLibrary) {
      return;
    }

    // 20 == 2 seconds
    const maxAttempts = 20;
    let pollCount = 0;

    this.pollLibrary = setInterval(() => {
      if (!this.libraryService.isLoading || pollCount >= maxAttempts) {
        this.content.setNavActive(this.assetId);
        this.stopLibraryPolling();
      }

      pollCount += 1;
    }, 100);
  }

  /**
   * Handle the asset data update without overriding a dirty field
   */
  public async gracefulUpdate() {
    try {
      await this.content.getSingleAsset(this.assetId, true);
      this.assignDirtyFields();
    } catch (e) {
      log.error(e);
    }
  }

  /**
   * Override the asset model except dirty fields
   */
  private assignDirtyFields() {
    const updatedAsset = _.cloneDeep(this.content.currentModel);
    const keys = new Set([
      ...Object.keys(updatedAsset),
      ...Object.keys(this.model),
    ]);

    // Do it this way so the table inputs won't lose focus
    keys.forEach(key => {
      if ((this.fields[key] && !this.fields[key].isDirty) || this.nonTrackedFields[key]) {
        if (key === 'meta') {
          this.model.meta = metaUpdate(this.model.meta, updatedAsset.meta);
        } else if (key === 'test_players') {
          this.model.test_players = testPlayerUpdate(this.model.test_players, updatedAsset.test_players);
        } else if (this.model[key] !== updatedAsset[key]) {
          this.model[key] = updatedAsset[key];
        }
      }
    });
  }

  /**
   * Get a list of dirty fields
   */
  private getDirtyFields(): string[] {
    const arr: string[] = [];

    this.tabFields.forEach(field => {
      if (this.fields[field].isDirty) {
        arr.push(field);
      }
    });

    return arr;
  }

  /**
   * Validate the data on the tab doesn't have any errors
   *
   * @param dirtyFields list of dirty fields
   */
  private validateTab(dirtyFields: string[]): boolean {
    let isValid = true;

    dirtyFields.forEach(dirtyField => {
      const field = this.fields[dirtyField];

      // Reset field errors.
      field.errors = [];

      // Validate against field rules.
      field.rules.forEach(r => {
        let didPass = true;

        // Handle RegExp rules.
        if (_.isRegExp(r.rule)) {
          didPass = r.rule.test(this.model[dirtyField]);
        }

        // Handle Function rules.
        if (_.isFunction(r.rule)) {
          didPass = r.rule(this.model[dirtyField]);
        }

        if (!didPass) {
          field.errors.push(r.message);
          isValid = false;
        }
      });
    });

    return isValid;
  }

  /**
   * Setup the polling to keep the asset fresh
   */
  private pollAsset() {
    this.stopAssetPolling();

    this.pollInterval = setInterval(() => {
      if (!document.hidden) {
        return this.gracefulUpdate();
      }
      return null;
    }, this.pollTimer);
  }

  private stopAssetPolling() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }

    this.pollInterval = null;
  }

  private stopLibraryPolling() {
    if (this.pollLibrary) {
      clearInterval(this.pollLibrary);
    }

    this.pollLibrary = null;
  }
}
