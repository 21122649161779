import {computedFrom} from 'aurelia-binding';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, containerless} from 'aurelia-framework';
import indexCss from './index.css';

export const enum Tab {
  Headers = 'headers',
  Callbacks = 'callbacks',
}

@autoinject()
@containerless()
export class CallbackHeaders {
  public styles;
  public selectedTab: Tab = Tab.Callbacks;

  constructor(public eventAggregator: EventAggregator) {
    this.styles = indexCss;
  }

  public switchTo(tab: Tab, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.selectedTab = tab;
  }

  public tabState(tab: Tab) {
    if (this.selectedTab === tab) return 'active';
    return '';
  }

  @computedFrom('selectedTab')
  public get callbacksTabState() {
    return this.tabState(Tab.Callbacks);
  }

  @computedFrom('selectedTab')
  public get headersTabState() {
    return this.tabState(Tab.Headers);
  }
}
