import {Transform, Type} from 'class-transformer';
import {
    ArrayNotEmpty,
    IsBoolean,
    IsDefined,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    Max,
    Min,
    ValidateNested,
} from 'class-validator';
import {Operator, RuleType, UsageRule} from './fields-models';

const enterValueString = 'You must enter a value.';

export class MultiCdnSettingsConfig {
    @IsNotEmpty({message: enterValueString})
    public availablecdns: string[] = ['Edgecast'];

    @IsNotEmpty({message: enterValueString})
    public defaultcdns: string[] = ['Edgecast', 'Akamai', 'Cloudfront', 'Limelight', 'Frontdoor'];

    @IsNotEmpty({message: enterValueString})
    public availableregions: string[] = ['APAC', 'EMEA', 'NORTH_AMERICA', 'LATAM', 'GLOBAL'];

    public etag: string = '';

    @IsString()
    @IsNotEmpty({message: enterValueString})
    public defaultPreset: string = 'Disabled';

    @ValidateNested()
    @Type(() => CdnCapacity)
    public cdns: CdnCapacity[] = [];

    @ValidateNested()
    @Type(() => RuleSet)
    public ruleSets: RuleSet[] = [];

    @ValidateNested()
    @Type(() => Profile)
    public profiles: Profile[] = [];

    @ValidateNested()
    @Type(() => QosPreset)
    public qoSCdnSelectionPresets: QosPreset[] = [];
}

export class CdnCapacity {
    @IsDefined({message: enterValueString})
    @IsNotEmpty({message: enterValueString})
    @IsString()
    public name: string;

    @ArrayNotEmpty()
    @ValidateNested()
    @Type(() => RegionCapacity)
    public regions: RegionCapacity[] = [];
}

export class RegionCapacity {
    @IsString()
    @IsNotEmpty({message: enterValueString})
    public regionname: string = '';

    @IsNumber()
    @IsNotEmpty({message: enterValueString})
    public capacity: number;

    @IsNumber()
    @IsNotEmpty({message: enterValueString})
    @Min(0)
    @Max(100)
    public slow: number;

    @IsNotEmpty({message: enterValueString})
    @IsNumber()
    @Min(0)
    @Max(100)
    public stop: number;
}

export class RuleSet {
    @IsString()
    public name: string = '';

    @ValidateNested()
    @Type(() => Rule)
    public contentRules: Rule[] = [];

    @ValidateNested()
    @Type(() => Rule)
    public playbackRules: Rule[] = [];

    @ValidateNested()
    @Type(() => Rule)
    public playerRules: Rule[] = [];

    @ValidateNested()
    @Type(() => UsageRule)
    public usageRules: UsageRule[] = [];
}

export class Rule {
    @IsEnum(RuleType)
    public ruleType: RuleType;

    @IsString()
    public field: string;

    @IsEnum(Operator)
    public operator: Operator;

    @IsString()
    public value: string = '';
}

export class Action {
    @Type(() => CdnDistribution)
    @ArrayNotEmpty()
    @ValidateNested()
    public distribution: CdnDistribution[] = [];

    @IsString()
    public presetId: string = '';

    @IsBoolean()
    @Transform(value => !!value, {toClassOnly: true})
    public overrideClientCdn: boolean;
}

export class QosPreset {
    @IsString()
    public name: string = '';

    @IsNumber()
    public rttErrorThreshold: number;

    @IsNumber()
    @Min(0)
    @Max(100)
    public availabilityErrorThreshold: number;
}

export class CdnDistribution {
    @IsString()
    @IsNotEmpty({message: enterValueString})
    public name: string = '';

    @IsNumber()
    @IsNotEmpty({message: enterValueString})
    @Min(0)
    @Max(100)
    public percent: number;
}

export class RuleSetId {
    @IsString()
    @IsNotEmpty({message: enterValueString})
    public name: string = '';
}

export class Profile {
    @IsString()
    @IsNotEmpty({message: enterValueString})
    public name: string = '';

    @IsNumber()
    @IsNotEmpty({message: enterValueString})
    @Min(0)
    @Max(999)
    public priority: number;

    @IsBoolean()
    @Transform(value => !!value, {toClassOnly: true})
    public enabled: boolean;

    @ValidateNested()
    @Type(() => Action)
    public actions: Action;

    @IsOptional()
    @IsBoolean()
    public isDefault: boolean;

    @ArrayNotEmpty({message: 'array empty'})
    @ValidateNested()
    @Type(() => RuleSetId)
    public ruleSetIds: RuleSetId[] = [];
}
