import {autoinject, bindable} from 'aurelia-framework';
import {File} from 'interfaces/file';

export interface IFileUploadActions {
  onFileUpload: (file: File) => Promise<void>;
}

export interface IFileUploadModel {
  actions: IFileUploadActions;
}

@autoinject()
export class FileUploader {
  @bindable()
  public dropText: string = 'Drop file here';

  @bindable()
  public accept: string;

  @bindable()
  public actions: IFileUploadActions;

  public files = null;
  public isProcessing: boolean = false;
  public isUploading: boolean = false;
  public isUploaded: boolean = false;
  public isPaused: boolean = false;

  public dropZone: HTMLElement;
  public lastDropTarget: any;
  public fileInput: any;

  constructor() {}

  /*
    Aurelia Hooks
  */

  public attached() {
    window.addEventListener('dragenter', this.dragEnterHandler);
    window.addEventListener('dragleave', this.dragLeaveHandler);
    window.addEventListener('dragover', this.dragOverHandler);
    window.addEventListener('drop', this.dragDropHandler);
  }

  public detached() {
    window.removeEventListener('dragenter', this.dragEnterHandler);
    window.removeEventListener('dragleave', this.dragLeaveHandler);
    window.removeEventListener('dragover', this.dragOverHandler);
    window.removeEventListener('drop', this.dragDropHandler);

    this.clearData();
  }

  /*
    Public Methods
  */

  public async uploadFile(elm: HTMLInputElement): Promise<void> {
    const files: any = Array.from(elm.files);

    try {
      await this.actions.onFileUpload(files[0]);
    } finally {
      this.fileInput.value = '';
    }
  }

  /*
    Private Methods
  */
  private clearData(): void {
    this.isUploaded = false;
    this.isProcessing = false;
  }

  private dragEnterHandler = event => {
    const targetElm = event.target.classList.contains('drag-point');
    if (targetElm) {
      this.dropZone.classList.add('is-active');
    } else {
      this.dropZone.classList.remove('is-active');
    }
  };

  private dragOverHandler = event => {
    event.preventDefault();
    const targetElm = event.target.classList.contains('drag-point');
    if (targetElm) {
      this.dropZone.classList.add('is-active');
    } else {
      this.dropZone.classList.remove('is-active');
    }
  };

  private dragLeaveHandler = event => {
    event.preventDefault();
    const targetElm = event.target.classList.contains('drag-point');
    if (!targetElm) {
      this.dropZone.classList.remove('is-active');
    }
  };

  private dragDropHandler = event => {
    event.preventDefault();
    const targetElm = event.target.classList.contains('drag-point');

    if (targetElm) {
      const dt = event.dataTransfer;
      this.uploadFile(dt);
    }

    this.dropZone.classList.remove('is-active');
  };
}
