import Player from 'video.js/dist/types/player';
import {autoinject, bindable} from 'aurelia-framework';
import videojs from 'video.js';
import 'videojs-contrib-dash';
import 'videojs-hotkeys';
import 'videojs-overlay';

import 'video.js/dist/video-js.css';
import 'videojs-overlay/dist/videojs-overlay.css';

const {protocol, hostname} = window.location;
const baseDomain = `${hostname.split('.').slice(-2)[0]}.com`;

const WIDEVINE_LICENSE_SERVER_URL = `${protocol}//content.${baseDomain}/wv`;
const PLAYREADY_LICENSE_SERVER_URL = `${protocol}//content.${baseDomain}/pr`;

// const AD_BREAK_MARKER_WIDTH = '3px';
// const AD_BREAK_MARKER_COLOR = 'red';

// See https://videojs.com/guides/options/ for options
export type PlayerOptions = {
  aspectRatio?: string;
  autoplay?: boolean;
  controls?: boolean;
  disablePictureInPicture?: boolean;
  enableDocumentPictureInPicture?: boolean;
  fluid?: boolean;
  fullscreen?: boolean;
  id?: string;
  liveui?: boolean;
  loop?: boolean;
  muted?: boolean;
  plugins?: any;
  preload?: 'auto' | 'metadata' | 'none';
  techOrder?: string[];
  [otherOptions: string]: any;
};

@autoinject
export class VideojsPlayer {
  @bindable public id: string;
  @bindable public src: string;
  @bindable public type: string;
  @bindable public options: PlayerOptions;
  @bindable public init: PlayerOptions; // for overriding initial default options
  @bindable public overlay: string;
  @bindable public adBreaks: number[];
  @bindable public controls = true;
  @bindable public autoplay: boolean = false;
  @bindable public liveui: boolean = false;
  @bindable public disablePictureInPicture: boolean = false;

  public player: Player;
  private initialVideoElement: string;

  constructor(private element: Element) {}

  public attached() {
    this.options = {
      autoplay: this.autoplay,
      controls: this.controls,
      responsive: true,
      liveui: this.liveui,
      disablePictureInPicture: this.disablePictureInPicture,
      controlBar: {
        volumePanel: {
          inline: false,
          vertical: true,
        },
      },
      // plugins: {
      //   hotkeys: {
      //     volumeStep: 0.1,
      //     seekStep: 5,
      //     enableModifiersForNumbers: false,
      //   },
      // },
    };
    // console.log('VideojsPlayer.attached()', 'options:', this.options);
    this.loadPlayer();
  }

  public loadPlayer() {
    if (this.src) {
      if (!this.type) {
        // if a type is not specified, try to infer it based on the src url
        const extensionMap = {
          m3u8: 'application/x-mpegURL',
          mpd: 'application/dash+xml',
          mp4: 'video/mp4',
        };

        // Extract the file extension from the URL
        const url = new URL(this.src);
        const path = url.pathname;
        const urlParts = path.split('.');
        const extension = urlParts[urlParts.length - 1];

        // Lookup the MIME type in the extension map
        const type = extensionMap[extension];

        if (!type) {
          // use default of m3u8
          this.type = 'application/x-mpegURL';
        }
        this.type = type;
      }

      // this will trigger a reload since the options have changed
      // console.log('options before', this.id, this.options);
      this.options = {
        ...this.options,
        ...this.init, // override initial default options
        sources: [
          {
            src: this.src,
            type: this.type,
            keySystemOptions: [
              {
                name: 'com.widevine.alpha',
                options: {
                  serverURL: WIDEVINE_LICENSE_SERVER_URL,
                },
              },
              {
                name: 'com.microsoft.playready',
                options: {
                  serverURL: PLAYREADY_LICENSE_SERVER_URL,
                },
              },
            ],
          },
        ],
      };
      // console.log('loadPlayer() -> options', this.options);

      if (!this.player) {
        this.reloadPlayer();
      }
    }
  }

  public detached() {
    if (this.player) {
      try {
        this.player.dispose();
      } catch (e) {
        this.player = null;
      }
    }
  }

  optionsChanged(newValue: PlayerOptions, oldValue: PlayerOptions) {
    // console.log('optionsChanged', this.id, newValue, oldValue);
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
      this.reloadPlayer();
    }
  }

  adBreaksChanged(newValue: number[], oldValue: number[]) {
    // console.log('adBreaksChanged', this.id, newValue, oldValue, this.player);
    if (newValue !== oldValue && this.player) {
      // console.log('setting up ad breaks');
      // this.setupAdBreaks();
    }
  }

  srcChanged(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      this.reloadPlayer();
    }
  }

  reloadPlayer() {
    // console.log('reloadPlayer', this.id, this.src);
    if (this.player) {
      try {
        this.player.dispose();
      } catch (e) {
        this.player = null;
      }
      this.element.querySelector('div.video-container').innerHTML = this.initialVideoElement;
    } else {
      this.initialVideoElement = this.element.querySelector('div.video-container').innerHTML;
    }

    const videoElement = this.element.querySelector('video');
    this.player = videojs(videoElement, this.options);

    this.player.ready(() => {
      // Set Default Volume To Something Reasonable
      // TODO: Make things like this save/load through local storage
      this.player.volume(0.6);

      // this.setupAdBreaks();
      this.setupOverlayImage();
      // this.setupKeyboardShortcuts();
    });

    this.loadPlayer();
  }

  setupOverlayImage() {
    // console.log('setupOverlayImage', this.overlay);
    if (!this.overlay) {
      return;
    }
    // @ts-ignore
    this.player.overlay({
      overlays: [
        {
          start: 0,
          content: `<img src="${this.overlay}"/>`,
          showBackground: false,
          class: 'overlay-full',
        },
      ],
    });

    // Overlays don't work with picture in picture - so disable it
    if (!this.options.disablePictureInPicture) {
      this.options.disablePictureInPicture = true;
    }
  }

  // setupAdBreaks() {
  //   // console.log('setupAdBreaks', this.id, this.adBreaks);
  //   if (!this.adBreaks) {
  //     return;
  //   }
  //   const adBreaksObjects = this.adBreaks.map(offset => ({
  //     time: offset,
  //     text: 'Ad Break',
  //   }));
  //   try {
  //     // @ts-ignore
  //     this.player.markers({
  //       markerStyle: {width: AD_BREAK_MARKER_WIDTH, 'background-color': AD_BREAK_MARKER_COLOR},
  //       markers: adBreaksObjects,
  //     });
  //   } catch (e) {
  //     // ignore error
  //   }
  // }

  setupKeyboardShortcuts() {
    // @ts-ignore
    this.player.hotkeys({
      volumeStep: 0.1,
      seekStep: 5,
      enableModifiersForNumbers: false,
    });
  }

  playPreview(start: number, end: number) {
    this.player.currentTime(start);
    this.player.play();

    this.player.on('timeupdate', () => {
      if (this.player.currentTime() >= end) {
        this.player.pause();
      }
    });
  }

  adjustPlaybackSpeed(rate: number) {
    this.player.playbackRate(rate);
  }
}
