import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
// import {PLATFORM} from 'aurelia-pal';
import {IsArray, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class Deadbeats {
    /*
     * Computed Properties
     */

    @computedFrom('state')
    get searchState() {
        return this.state === 'searching' ? 'disabled' : '';
    }

    /*
     * Properties
     */
    public detailCols = [
        {
            colHeadName: 'dValue',
            colHeadValue: 'Deadbeat',
            colOnChange: async data => this.changeState(data),
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle',
            ),
        },
        {
            colHeadName: 'uValue',
            colHeadValue: 'Username',
        },
        {
            colHeadName: 'bValue',
            colHeadValue: 'Balance',
        },
    ];

    public detailData = [];

    public selectedOwner: string = '';
    public origOwners: any[] = [];

    public selectedValue: string = '';
    private username: string = '';
    private owners: any[] = [];
    private state: string = 'idle';

    constructor(private acceo: Acceo, private notification: CToastsService, public dialogService: DialogService) {}

    /*
     * Public Methods
     */
    public async searchUsername() {
        this.state = 'searchState';
        if (!this.username) {
            this.username = '';
            this.state = 'idle';
            this.notification.error('No email/username entered.');
        } else {
            const url: string = '/ops/owners';
            const params = $.param({username: this.username, account_state: 'active'});
            try {
                const resp = await this.getAcceoResponse(url, params);
                this.owners = resp.owners;
                if (this.owners.length !== 0) {
                    this.state = this.owners.length ? 'hasData' : 'not-found';
                    this.notification.success(`Found account matches for ${this.username}`);
                    await this.showAccounts(this.owners);
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching owner for ${this.username}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async showAccounts(owners) {
        // Get & set data for selected account
        this.detailData = [];
        this.state = 'accountDataSelected';
        let deadbeat = false;
        owners.forEach(owner => {
            if (typeof owner.deadbeat === 'boolean') {
                deadbeat = owner.deadbeat;
            } else if (owner.deadbeat === 1) {
                deadbeat = true;
            }
            this.detailData.push({dValue: deadbeat, uValue: owner.username, bValue: owner.billing_balance});
        });
    }

    public async changeState(data) {
        const index = this.owners.findIndex(owner => owner.username === data.uValue);
        let sendValues = this.owners[index];
        if (sendValues.authorizedBy && sendValues.sncIncident) {
            delete sendValues[sendValues.authorizedBy];
            delete sendValues[sendValues.sncIncident];
        }
        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        inputValues: sendValues,
                    },
                    bodyViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/deadbeats/confirm',
                    ),
                    footerEnable: true,
                    footerViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/deadbeats/deadbeat-footer',
                    ),
                    size: 'auto',
                    title: 'Deadbeat Confirmation',
                    titleHelp: 'Please carefully read over this before agreeing',
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed()
            .then(async response => {
                if (response.output !== false && !response.wasCancelled) {
                    const url: string = '/ops/owners/deadbeatify';
                    try {
                        if (sendValues.authorizedBy && sendValues.sncIncident) {
                            const params = {
                                authorizer: sendValues.authorizedBy,
                                incident: sendValues.sncIncident,
                                owners: [
                                    {
                                        deadbeat: data.dValue,
                                        id: this.owners[index].id,
                                    },
                                ],
                            };
                            await this.postAcceoResponse(url, params);
                            if (data.dValue === 0) {
                                this.notification.success(`${data.uValue} has been removed from the Deadbeat list.`);
                            } else {
                                this.notification.success(`${data.uValue} has been Deadbeatified.`);
                            }
                            sendValues = this.owners[index];
                        }
                    } catch (err) {
                        this.notification.error(err);
                    }
                } else {
                    if (data.dValue === 0) {
                        data.dValue = 1;
                    } else {
                        data.dValue = 0;
                    }
                    if ('authorizedBy' in sendValues) {
                        delete sendValues.authorizedBy;
                    }
                    if ('sncIncident' in sendValues) {
                        delete sendValues.sncIncident;
                    }
                }
            });
        /* let index = this.owners.map(function (owner) { return owner.username; }).indexOf(data.uValue) // 0
        if (data.dValue){

        } */
    }

    private async getAcceoResponse(url, params) {
        return this.acceo.get(Response)([url, params].join('?'));
    }

    private async postAcceoResponse(url, params) {
        return this.acceo.post(Response)(url, params);
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsArray()
    public owners: any[] = [];
}
