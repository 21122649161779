import * as cTimeEntryCssJson from '@bindable-ui/bindable/dist/native-modules/components/timeline/c-time-entry/c-time-entry.css.json';
import {computedFrom} from 'aurelia-framework';
import * as moment from 'moment';

import {BaseTimeEntry} from './base';
import {ScheduledOverlaySourceType} from '../models/event-model';

enum ScheduledEntryText {
  AD_BREAK = 'Ad Break',
  VOD = 'Asset',
  MATCH_SIGNAL = 'Match Signal',
  MATCH_TIME = 'Match Time',
  SLICER = 'Slicer',
  SLICER_ASSET = 'Sliced Asset',
  REPLAY = 'Replay',
  OVERLAY = 'Overlay',
}

export class EditTimeEntry extends BaseTimeEntry {
  public style = cTimeEntryCssJson;
  public type: string = '';
  public typePrefix: string = '';
  public icon: string = 'play';
  public deletePlaylistModal;
  // public canEdit: boolean = false;
  public isHistoryView: boolean = false;

  public activate(model) {
    super.activate(model);

    this.setType();
  }

  public viewScheduledEntry() {
    const baseUrl = `${window.location.origin}${window.location.pathname}`;

    switch (this.type) {
      case ScheduledEntryText.VOD:
        this.popoverService.close();
        window.open(`${baseUrl}#/content/${this.model.content_id}`, '_blank');
        break;
      case ScheduledEntryText.SLICER_ASSET:
        this.popoverService.close();
        window.open(`${baseUrl}#/content/${this.model.content_id}`, '_blank');
        break;
      default:
        if (this.model.asset) {
          this.popoverService.close();
          window.open(`${baseUrl}#/content/${this.model.asset}`, '_blank');
        }
    }
  }

  public editScheduledEntry() {
    switch (this.type) {
      case ScheduledEntryText.AD_BREAK:
        this.showAdBreak();
        break;
      case ScheduledEntryText.MATCH_SIGNAL:
        this.showMatchSignal();
        break;
      case ScheduledEntryText.MATCH_TIME:
        this.showMatchTime();
        break;
      case ScheduledEntryText.VOD:
        this.showVOD();
        break;
      case ScheduledEntryText.SLICER:
        this.showSlicer();
        break;
      case ScheduledEntryText.REPLAY:
        this.showRepeat();
        break;
      case ScheduledEntryText.OVERLAY:
        this.showOverlay();
        break;
      default:
        if (this.model.asset) {
          this.popoverService.close();
          window.location.href = `#/content/${this.model.asset}`;
        }
    }
  }

  public addReplayEntry() {
    this.showRepeat(true);
  }

  public viewPlaylist() {
    if (this.isLinkedPlaylist) {
      const baseUrl = `${window.location.origin}${window.location.pathname}`;
      this.popoverService.close();
      window.open(`${baseUrl}#/content/playlists/${this.model.source.id}`, '_blank');
    }
  }

  @computedFrom('model.end', 'model.start')
  public get canEdit() {
    if (!this.model || !this.model.end || !this.model.id) {
      return false;
    }

    if (this.type === ScheduledEntryText.OVERLAY) {
      if (this.model.overlay_source_type === ScheduledOverlaySourceType.LIVE) {
        return moment(this.model.start).isAfter(moment().add(1, 'minutes'));
      }
      return moment(this.model.start).isAfter(moment().add(1, 'hours'));
    }

    return moment(this.model.end).isAfter(moment());
  }

  @computedFrom('model.end')
  public get canDelete() {
    if (!this.model || !this.model.end || !this.model.id) {
      return false;
    }
    return moment(this.model.end).isAfter(moment());
  }

  public async openPlaylistDelete() {
    if (!this.model.source.name) {
      try {
        this.model.source.name = await this.getPlaylistName();
      } catch (e) {
        this.model.source.name = 'Playlist';
      }
    }
    this.deletePlaylistModal.show();
  }

  @computedFrom('model.source')
  public get isLinkedPlaylist() {
    return this.model && this.model.source && this.model.source.type && this.model.source.type === 'playlist';
  }

  private setType() {
    const {isAdBreak, isAsset, isMatchSignal, isMatchTime, isSlicer, isSlicerAsset, isRepeat, isOverlay} = this.model;
    this.typePrefix = '';
    if (isAdBreak) {
      this.type = ScheduledEntryText.AD_BREAK;
      this.icon = 'ad-break';
    } else if (isOverlay) {
      this.type = ScheduledEntryText.OVERLAY;
      if (this.model.overlay_source_type === ScheduledOverlaySourceType.LIVE) {
        this.typePrefix = 'Live ';
      } else {
        this.typePrefix = `${this.model.overlay_source_type.toUpperCase()} `;
      }
      this.icon = 'graphic-overlay';
    } else if (isAsset) {
      this.type = ScheduledEntryText.VOD;
      this.icon = 'video-file';
    } else if (isMatchSignal) {
      this.type = ScheduledEntryText.MATCH_SIGNAL;
      this.icon = 'signal';
    } else if (isMatchTime) {
      this.type = ScheduledEntryText.MATCH_TIME;
      this.icon = 'time';
    } else if (isSlicer) {
      this.type = ScheduledEntryText.SLICER;
      this.icon = 'video-slicer';
    } else if (isSlicerAsset) {
      this.type = ScheduledEntryText.SLICER_ASSET;
      this.icon = 'video-slicer';
    } else if (isRepeat) {
      this.type = ScheduledEntryText.REPLAY;
      this.icon = 'replay';
    } else {
      this.type = 'Asset';
      this.icon = 'video-file';
    }
  }
}
