import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {bindable, inject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Acceo} from 'services/acceo';

type RegionNames =
    | 'us-east-1'
    | 'us-east-2'
    | 'us-west-1'
    | 'us-west-2'
    | 'ap-south-1'
    | 'ap-southeast-1'
    | 'eu-central-1'
    | 'eu-west-1'
    | 'sa-east-1';

export interface IWeightedRecord {
    _class?: string;
    _hidden?: boolean;
    HealthCheckId?: string;
    Name: string;
    ResourceRecords: [{Value: string}];
    SetIdentifier: string;
    TTL: number;
    Type: 'CNAME';
    Weight: number;
    weightOriginal?: number;
}

type IRegionList = Partial<Record<RegionNames, IWeightedRecord[]>>;
interface ISiblingList {
    [key: string]: IWeightedRecord[];
}

interface IStatusRespondse {
    id: string;
    status: 'PENDING' | 'INSYNC';
}

interface IStatusRespondseText {
    changeInfo: IStatusRespondse;
}
export interface IRecordResponse {
    recsByZone: IRegionList;
    siblings: ISiblingList;
}

@inject(DialogService, Acceo, CToastsService)
export class DnsWeightedRecords {
    public recordCols = [
        {
            colClass: 't350',
            colHeadName: 'Name',
            colHeadValue: 'Record Name  (source)',
            colWidth: 350,
            sort: true,
        },
        {
            colHeadName: '',
            colHeadValue: 'Resource  (Destination)',
        },
        {
            colClass: 't100',
            colHeadName: 'Weight',
            colHeadValue: 'Weight',
            sort: false,
        },
    ];

    @bindable
    public radioRegion = '';

    public selectedRecords: IWeightedRecord[] = [];
    public regionList: string[] = [];
    public recsByZone: IRegionList;
    public siblings: ISiblingList;

    public radioRegionActions = {
        onChange: () => this.regionChanged(),
    };

    public tableActions = {
        getColClass: (_row, col) => {
            const cls = col._class || '';
            return cls;
        },
        getColValue: (row, col) => {
            let colVal = '';
            if (col.colHeadValue === 'Resource  (Destination)') {
                colVal = Array.isArray(row.ResourceRecords) ? row.ResourceRecords[0].Value : '';
            }
            return colVal;
        },
        getRowClass: (row: IWeightedRecord) => {
            let cls = row._class || '';
            const dirtyRow = row.Weight !== row.weightOriginal;
            cls = dirtyRow ? 'warning' : '';
            return cls;
        },
    };

    private pollChangeId: number = 0;
    private savedTime: number = 0;
    private changeId: string = '';
    private creatingMap = false;
    private saveInProgress = false;
    private requestData = false;

    constructor(public dialogService: DialogService, private acceo: Acceo, private notification: CToastsService) {}

    public async attached() {
        await this.getWeightedRecords();
    }

    public regionChanged() {
        this.selectedRecords = this.recsByZone[this.radioRegion];
    }

    public deactivate() {
        this.clearStatusData();
        this.clearSelectionData();
    }

    public async getWeightedRecords() {
        if (this.requestData) {
            return;
        }
        this.requestData = true;
        const url = '/ops/dns-tooling/get-weighted-records';
        await this.acceo
            .get()(url)
            .then((response: IRecordResponse) => {
                this.recsByZone = this.parseRecords(response.recsByZone);
                this.siblings = this.parseRecords(response.siblings);
                this.regionList = Object.keys(this.recsByZone);
                if (this.regionList.length > 0) {
                    if (this.radioRegion.length === 0) {
                        this.radioRegion = this.regionList[0];
                    }
                    this.selectedRecords = this.recsByZone[this.radioRegion];
                }
            })
            .catch(err => {
                this.notification.error(err);
                this.clearStatusData();
            })
            .finally(() => {
                this.requestData = false;
            });
    }

    public b64toBlob(encodedB64: string) {
        const contentType = 'image/png';
        const sliceSize = 512;

        const decodeB64 = window.atob(encodedB64);
        const byteArrays = [];

        for (let offset = 0; offset < decodeB64.length; offset += sliceSize) {
            const slice = decodeB64.slice(offset, offset + sliceSize);

            // tslint:disable-next-line: prefer-array-literal
            const byteNumbers = new Array(slice.length);
            const sliceLength = slice.length;
            for (let i = 0; i < sliceLength; i += 1) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    public async getMap() {
        if (this.creatingMap) {
            this.notification.info('UML creating already in progress...', 'Map', 3000);
            return;
        }
        this.notification.info('Creating UML...', 'UML', 3000);
        this.creatingMap = true;
        const url = '/ops/dns-tooling/map';
        await this.acceo
            .post()(url, {})
            .then((resp: {img: string}) => {
                this.creatingMap = false;
                const {img} = resp;
                const blobImg = this.b64toBlob(img);
                const imgUrl = window.URL.createObjectURL(blobImg);
                const link = document.createElement('a');
                link.href = imgUrl;
                const env = window.location.href.indexOf('downlynk') >= 0 ? 'downlynk' : 'uplynk';
                link.setAttribute('download', `${env}_uml.jpg`);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(imgUrl);
                document.body.removeChild(link);
                this.creatingMap = false;
            })
            .catch(err => {
                this.notification.error(err);
                this.creatingMap = false;
            });
    }

    public adjustByPercent(percent: number = 10) {
        const copyRecs = JSON.parse(JSON.stringify(this.selectedRecords));
        copyRecs.forEach(rec => {
            rec.Weight = this.getPercentage(rec, percent);
        });
        this.selectedRecords = copyRecs;
        if (this.isDirty()) {
            const msg = `Are you sure you want to set region " ${this.radioRegion} " to ${percent} % ?`;
            this.openDialog(msg, 'Set Records', percent);
        } else {
            this.notification.info('Records are at original value', 'No Record Change', 2000);
        }
    }

    public warnDisable() {
        const copyRecs = JSON.parse(JSON.stringify(this.selectedRecords));
        copyRecs.forEach((rec: IWeightedRecord) => {
            rec.Weight = 0;
        });
        this.selectedRecords = copyRecs;

        if (this.isDirty()) {
            const msg = `Are you sure you want to disable region "${this.radioRegion}" ?`;
            this.openDialog(msg, `Disable "${this.radioRegion}"`, 0);
        } else {
            this.notification.info('Records are at original value', 'No Record Change', 2000);
        }
    }

    public openDialog(msg: string, btnName: string, percent: number) {
        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        data: {msg},
                    },
                    bodyViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/dns-tooling/confirm-body',
                    ),
                    footerEnable: true,
                    footerModel: {
                        data: {saveBtn: btnName},
                    },
                    footerViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/dns-tooling/footer',
                    ),
                    size: 'auto',
                    title: 'DNS Change Confirmation!',
                    titleHelp: 'Are you sure?',
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed()
            .then(r => {
                if (!r.wasCancelled) {
                    this.save(percent);
                } else {
                    this.regionChanged();
                }
            });
    }

    public async save(percent: number) {
        if (!this.saveInProgress && this.isDirty()) {
            this.saveInProgress = true;
            const clonedRecords = JSON.parse(JSON.stringify(this.selectedRecords));
            clonedRecords.forEach((r: IWeightedRecord) => {
                delete r._hidden;
                delete r._class;
                delete r.weightOriginal;
            });
            const originalRecords = JSON.parse(JSON.stringify(this.recsByZone[this.radioRegion]));
            originalRecords.forEach((r: IWeightedRecord) => {
                delete r._hidden;
                delete r._class;
                delete r.weightOriginal;
            });

            this.savedTime = 0;
            try {
                const url = '/ops/dns-tooling/save-weighted-records';
                await this.acceo
                    .post()(url, {
                        records: clonedRecords,
                        previous_records: originalRecords,
                        percent,
                        region: this.radioRegion,
                    })
                    .then((response: IStatusRespondseText) => {
                        this.savedTime = Date.now();
                        const {changeInfo} = response;
                        this.changeId = changeInfo.id;
                        const statusStr = 'Please allow up to 60 seconds for changes to take effect.';
                        this.notification.success(
                            `${statusStr} ${this.changeId}.  Status: ${changeInfo.status}.`,
                            'Changed sent successfully!',
                            4500,
                        );
                        this.pollChange();
                    });
            } catch (err) {
                this.notification.error(err);
                this.clearStatusData();
            }
        }
    }

    private parseRecords(data: ISiblingList | IRegionList) {
        const copyData: ISiblingList | IRegionList = JSON.parse(JSON.stringify(data));
        Object.values(copyData).forEach(value =>
            value.map(rec => {
                rec.weightOriginal = rec.Weight;
                return rec;
            }),
        );
        return copyData;
    }

    /** **************************************************************************************
        traffic is calculated as: percent = record weight / sum of all weights
        variable description:
        x is percentage (value range 0-1)
        a is record weight (which we are trying to figure out)
        b is sum of all other records
        now we can write the folrmula:
        x=a/(a+b) => a=x(a+b) => a=xa+xb => xb=a-xa => xb=a(1-x) => a=xb/(1-x) where x!=1
        for x=1 (100%) we will set record to max sibling value
        since x is % of total traffic and we are only trying to find percent of region
        ie for x=1 (100%) its not 100% of all traffic but 100% of region's share of traffic
        therefor we need to divide x by total siblings (with weight > 0).
     *************************************************************************************** */
    private getPercentage(rec: IWeightedRecord, p: number) {
        if (p === 0) {
            return 0;
        }
        let source = rec.Name.trim();
        if (source.slice(-1) === '.') {
            source = source.slice(0, -1);
        }
        const records: IWeightedRecord[] = this.siblings[source];
        if (p === 100) {
            return records.reduce((acc, r) => Math.max(acc, r.Weight), 0);
        }

        const recDestination = rec.ResourceRecords[0].Value;
        const siblingRecords = records.filter(r => r.ResourceRecords[0].Value !== recDestination);
        const activeSibling = siblingRecords.filter(r => r.Weight > 0);
        const totalActive = activeSibling.length + 1;

        let percent = p / 100;
        percent /= totalActive;

        let sum = 100;
        if (activeSibling.length > 0) {
            sum = activeSibling.reduce((acc, r) => acc + r.Weight, 0);
        }
        const newWeight = Math.ceil((percent * sum) / (1 - percent));
        return newWeight;
    }

    private isDirty(): boolean {
        return this.selectedRecords.some(rec => rec.Weight !== rec.weightOriginal);
    }

    private async checkChangeStatus() {
        if (this.changeId == null || this.changeId === '') {
            this.clearStatusData();
            return;
        }
        const change = this.changeId.replace('/change/', '');
        const url = `/ops/dns-tooling/get-change-status?change_id=${change}`;
        await this.acceo
            .get()(url)
            .then((response: IStatusRespondse) => {
                if (response.status === 'PENDING') {
                    const seconds = Math.ceil((Date.now() - this.savedTime) / 1000);
                    const remainingSeconds = 60 - seconds;
                    const remainingStr = `Polling...  Up to ${remainingSeconds} seconds left`;
                    this.notification.info(`${response.id}.  Status: ${response.status}.`, `${remainingStr}`, 4000);
                } else {
                    this.notification.success(
                        `${response.id} Status: ${response.status}.  Updating records...`,
                        'Records insync!',
                        6000,
                    );
                    this.changeId = '';
                    this.clearStatusData();
                    this.clearSelectionData(this.radioRegion);
                    this.getWeightedRecords();
                }
            })
            .catch(err => {
                this.notification.error(err);
                this.clearStatusData();
            });
    }

    private pollChange() {
        this.pollChangeId = window.setInterval(() => {
            this.checkChangeStatus();
        }, 5500);
    }

    private clearStatusData() {
        clearInterval(this.pollChangeId);
        this.creatingMap = false;
        this.saveInProgress = false;
    }

    private clearSelectionData(region = '') {
        this.regionList = [];
        this.selectedRecords = [];
        this.radioRegion = region;
    }
}
