import {generateRandom} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';
import {LynkDialogServiceModel} from 'resources/services/dialog';
import {Config} from './models';
import {PluginSelectionTable} from './table';
import {PluginListTableRow} from './table-models';

@autoinject
export class PluginSelection {
  public config: Config;
  public table = new PluginSelectionTable();
  protected searchKeys = [
    'creation_time',
    'slicer_plugin',
    'status',
  ];

  public activate(model: LynkDialogServiceModel) {
    this.config = model.settings.sharedModel.input.plugin;
    this.table.rows = this.config.options.plugin.map((item, index) => {
      const result = new PluginListTableRow();
      result.id = generateRandom();
      result.order = index;
      Object.assign(result, item);
      return result;
    });
    this.table.selected = model.settings.sharedModel.input.plugin.input.selection;
    model.settings.sharedModel.input.plugin.input.selectionReset = this.table.removeSelectedRadio.bind(this.table);
  }

  @computedFrom('table.rows', 'config.input.search')
  get filteredRows(): PluginListTableRow[] {
    return this.table.rows.filter(row => {
      let foundText = true;
      const foundSelection = true;
      if (this.config.input.search && this.config.input.search.length) {
        foundText = this.searchText(row, this.searchKeys);
      }
      return foundText && foundSelection;
    });
  }

  public searchText(row, queryKeys) {
    let foundMatch = false;
    if (!this.config.input.search) {
      return true;
    }
    const query = this.config.input.search.replace(/\+/g, '\\+');
    const regex = new RegExp(query, 'i');
    queryKeys.forEach(key => {
      if (typeof row[key] === 'string' && !foundMatch) {
        foundMatch = !!row[key].toLowerCase().match(regex);
      }
    });
    return foundMatch;
  }
}
