import {ProfilesResponseItem} from 'apps/cms/routes/slicers/models/profiles';

export class Config {
    public input = new ConfigInput();
    public options = new ConfigOptions();
    public state = new ConfigState();
}

export class ConfigInput {
    public profile: ProfilesResponseItem;
}

class ConfigOptions {
    public profileItems: ProfilesResponseItem[];
}

class ConfigState {
    public canExportCSV: boolean;
    public canUseDeprecatedProfiles: boolean;
    public canViewProfileRays: boolean;
    public error: string;
    public isLoading: boolean;
    public isProcessingDefaultLive: boolean;
    public isProcessingDefaultVod: boolean;
}

export const enum ProfileDefault {
    LIVE = 'live',
    VOD = 'vod',
}

export const enum ProfileNotification {
    ERROR_REQUEST_FAILED = 'Failed to set default Encoding Profile',
    SUCCESS_DEFAULT_LIVE = 'Successfully set Encoding Profile to Live default',
    SUCCESS_DEFAULT_VOD = 'Successfully set Encoding Profile to VoD default',
}
