import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject} from 'aurelia-framework';

import {PREBID_CONFIG_EVENT} from './index';

@autoinject()
export class PrebidConfigCreate {
    public newName: string;
    public subscriptions: Subscription[] = [];
    public displayTip: any;
    public enableCreate: boolean = true;

    constructor(public eventAggregator: EventAggregator) {}

    public attached() {
        this.subscriptions.push(
            this.eventAggregator.subscribe(PREBID_CONFIG_EVENT.EnableNewConfigCreate, () => {
                this.newName = '';
                this.enableCreate = true;
            }),
        );
    }

    public detached() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    public createNew() {
        const name = this.newName;
        this.eventAggregator.publish(PREBID_CONFIG_EVENT.ConfigCreated, {name, isNew: true});
        this.displayTip.hide();
        setTimeout(() => {
            this.enableCreate = false;
        }, 300);
    }
}
