import {Account} from './account-access';

export interface IPermissionOption {
  text: string;
  value: string;
  linkedValue?: string;
  includeInAll?: boolean;
  checkedValue?: (a: Account) => boolean;
  enabled?: (a: Account) => boolean;
}

export class PermissionOption implements IPermissionOption {
  /**
   * Display text
   */
  public text: string;

  /**
   * Value to set when checked
   */
  public value: string;

  /**
   * Additional value to toggle when base value is checked/unchecked
   */
  public linkedValue?: string;

  /**
   * Include when filtering for all options
   */
  public includeInAll?: boolean = true;

  /**
   * Conditions for enabling option
   */
  public enabled?: (account: Account) => boolean;

  /**
   * Overrides the check value when value is not in "values" collection
   */
  public checkedValue?: (account: Account) => boolean;

  constructor(args: IPermissionOption) {
    Object.assign(this, args);
  }

  /**
   * Determines if the option is checked
   * @param account {Account}
   * @param values {string[]}
   */
  public checked(account: Account, values: string[]) {
    if (this.checkedValue instanceof Function) {
      return this.checkedValue(account);
    }

    return values.includes(this.value);
  }

  /**
   * Disables based on 'enabled' method.
   *
   * Defaults to true
   *
   * @param account {Account} Owner account
   * @returns {boolean} default true
   */
  public disabled(account: Account) {
    if (this.enabled instanceof Function) {
      return !this.enabled(account);
    }

    return false;
  }
}
