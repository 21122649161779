import {bindable, computedFrom} from 'aurelia-framework';

export interface SubnavItem {
  title?: string;
  href?: string;
  active?: boolean;
  sectionTitle?: string;
  icon?: string;
  prefixIcon?: string;
  subText?: string;
}

export interface SubnavList {
  isLoading?: boolean;
  navs: SubnavItem[];
  title?: string;
}

export class SubnavMenu {
  public search: string = '';

  @bindable()
  public navs: SubnavList;

  @computedFrom('search', 'navs.navs')
  public get itemsFiltered() {
    return this.navs
      ? this.navs.navs.filter(item => _.includes(item.title.toLowerCase(), this.search.toLowerCase()))
      : [];
  }

  constructor() {}

  propertyChanged() {
    // reset search string
    this.search = '';
  }
}
