/* eslint-disable max-classes-per-file */

import {Type, Exclude} from 'class-transformer';
import {IsBoolean, IsNumber, IsOptional, IsString, ValidateNested} from 'class-validator';
import {IdsIntegration} from './ids-integration';

export interface ISsoDomainParams {
  order?: string;
  page?: number;
  page_size?: number;
  type?: string;
  search?: string;
}

export interface ISsoDomain {
  '@included'?: any[];
  desc?: string;
  domain: string;
  domain_binding?: boolean;
  id: string;
  ids_integration_id?: string;
  ids_tenant_id?: string;
  provider: string;
  title: string;
}

export class SsoDomain implements ISsoDomain {
  @IsString()
  @IsOptional()
  public created?: string;

  @IsString()
  @IsOptional()
  public deleted?: string;

  @IsOptional()
  @IsString()
  public desc: string;

  @IsOptional()
  @IsString()
  public domain: string;

  @IsOptional()
  @IsBoolean()
  public domain_binding?: boolean;

  @IsString()
  public id: string;

  @IsOptional()
  @IsString()
  public ids_tenant_id: string;

  @IsOptional()
  @IsString()
  public ids_integration_id: string;

  // @IsOptional()
  // @IsString()
  // public ids_tenant_id: string;

  @IsOptional()
  @IsString()
  public provider: string;

  // @IsBoolean()
  // @IsOptional()
  // public selected: boolean;

  @IsString()
  public title: string;

  @ValidateNested()
  @Type(() => IdsIntegration)
  @Exclude({toPlainOnly: true})
  public '@included'?: IdsIntegration[];
}

export class SsoDomainResponse {
  @ValidateNested()
  @Type(() => SsoDomain)
  public items: SsoDomain[];

  @IsNumber()
  public total_items: number;
}
