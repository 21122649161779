import {ConfigParser} from 'resources/config/parser';
import {ConfigFormat, ConfigFormatExt, ConfigFormatMimeType} from './models/config-format';
import {IConfigLine} from './models/config-line';
import {ConfigImportMode} from './import/models/config-import';

export class Config {
  protected model: IConfigLine[] = [];

  public exportString(format: ConfigFormat = ConfigFormat.KVP) {
    switch (format) {
      case ConfigFormat.JSON:
        return ConfigParser.stringifyJSON(this.model);
      case ConfigFormat.KVP:
      default:
        return ConfigParser.stringifyKVP(this.model);
    }
  }

  public exportFile(fileName: string, fileFormat: ConfigFormat) {
    const fileData = encodeURIComponent(this.exportString(fileFormat));
    const fileExt = ConfigFormatExt[fileFormat];
    const fileExtIndex = fileName.indexOf(`.${fileExt}`);
    const fileNameHasExt = fileExtIndex === fileName.length - `.${fileExt}`.length && fileExtIndex > 0;
    const fileMimeType = ConfigFormatMimeType[fileFormat];
    return new File([fileData], fileNameHasExt ? fileName : `${fileName}.${fileExt}`, {type: fileMimeType});
  }

  public async importFile(requestedFile: File, concat: boolean = true) {
    const result = await ConfigParser.parseFile(requestedFile);
    this.model = concat ? this.model.concat(result) : result;
  }

  public importLine(key?: string, value?: any, comment?: string) {
    let line = '';
    if (key) {
      line = line.concat(`${key.trim()}:`);
      if (value) {
        // make sure the value is a string before trimming
        if (typeof value === 'string') {
          line = line.concat(value.trim());
        } else if (typeof value === 'number') {
          line = line.concat(`${value}`);
        }
      }
    }
    if (comment) {
      if (comment.trim()[0] !== '#') {
        line = line.concat('# ');
      }
      line = line.concat(comment.trim());
    }
    this.model.push(ConfigParser.parseLine(line));
  }

  public importString(value: string, mode: ConfigImportMode = ConfigImportMode.APPEND) {
    const result = ConfigParser.parseString(value);
    switch (mode) {
      case ConfigImportMode.PREPEND:
        this.model = result.concat(this.model);
        break;
      case ConfigImportMode.REPLACE:
        this.model = result;
        break;
      case ConfigImportMode.MERGE:
        result.forEach(lineNew => {
          const lineMatch = this.model.find(lineOld => lineOld.key === lineNew.key);
          if (lineMatch) {
            Object.assign(lineMatch, lineNew);
          } else {
            this.model.push(lineNew);
          }
        });
        break;
      case ConfigImportMode.APPEND:
      default:
        this.model = this.model.concat(result);
        break;
    }
  }

  public removeAny(key: string) {
    this.model = this.model.filter(line => line.key !== key);
  }

  public validate() {
    ConfigParser.validate(this.model);
  }

  public getLastValue(key: string): any {
    const result = this.model.filter(line => line.key === key);
    return result.length ? result.pop().value : undefined;
  }
}
