import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

import {authorizationConstants} from 'services/authorization';

import {CToastsService} from '@bindable-ui/bindable';
import environment from 'environment';
import {SessionService} from 'services/session';
import {BetaMode} from 'services/beta-mode';
// import {ILibrarySelect, Library, LibraryType} from '../models/models';
import {Library} from '../models/models';
import {ContentService} from '../services/content';
import {LibraryService} from '../services/library';

@authorizationConstants
@autoinject()
export class AssetSingleIndex {
  public finishedCopy: boolean = false;
  public hasWriteAccess: boolean = false;
  public library: Library = null;

  public pollCopyStatus: any;
  public queryParams: any;
  public router: Router;
  public routes = [
    {
      moduleId: PLATFORM.moduleName('./details'),
      name: 'details',
      nav: true,
      route: '',
      title: 'Details',
    },
    {
      moduleId: PLATFORM.moduleName('./metadata'),
      name: 'metadata',
      nav: true,
      route: '/metadata',
      title: 'Metadata',
    },
    {
      moduleId: PLATFORM.moduleName('./epg'),
      name: 'programData',
      nav: this.betaMode.hasScope('Wurl'),
      route: '/program-data',
      title: 'Program Data',
    },
    {
      moduleId: PLATFORM.moduleName('./ad-breaks'),
      name: 'adBreaks',
      nav: true,
      route: '/breaks',
      title: 'Breaks',
    },
    {
      moduleId: PLATFORM.moduleName('./playback'),
      name: 'playback',
      nav: true,
      route: '/playback',
      title: 'Playback',
    },
    {
      moduleId: PLATFORM.moduleName('./export'),
      name: 'export',
      nav: true,
      route: '/export',
      title: 'Export',
    },
  ];

  // Private variables
  private isProcessing: boolean = false;
  private pollFailedAttempts: number = 0;
  private pollingInterval: number = 5 * 1000;

  constructor(
    public content: ContentService,
    public libraryService: LibraryService,
    private notification: CToastsService,
    private session: SessionService,
    private betaMode: BetaMode,
  ) {}

  public async activate(params) {
    this.queryParams = params;
    const {libraryId = null} = params;

    this.content.enableNav(true);

    if (libraryId) {
      this.content.libraryId = libraryId;
      this.library = await this.libraryService.getLibraryFromCacheOrLoad(libraryId);
    }
  }

  public detached() {
    this.content.enableNav(false);
    this.content.currentModel = null;
    this.stopPolling();
  }

  @computedFrom('content.currentModel.mine', 'content.currentModel.audio_only', 'content.currentModel.source')
  get isClippingButtonDisabled() {
    const {currentModel} = this.content;

    if (!currentModel || !currentModel.mine || currentModel.audio_only || currentModel.source === 'External') {
      return true;
    }

    return false;
  }

  @computedFrom('finishedCopy', 'isProcessing', 'pollCopyStatus')
  get isCopyButtonDisabled() {
    if (this.finishedCopy || this.isProcessing || this.pollCopyStatus) {
      return true;
    }

    return false;
  }

  /* Computed Properties */
  @computedFrom('content.currentModel.profile_info')
  get encoderProfileRows() {
    const {currentModel} = this.content;
    if (currentModel.profile_info) {
      return [
        {
          key: 'Id',
          value: currentModel.profile_info.id,
        },
        {
          key: 'Rate Card',
          value: currentModel.profile_info.rate_card_id,
        },
        {
          key: 'GPU Slicer',
          value: currentModel.profile_info.gpu_slicer,
        },
        {
          key: 'GPU Encoder',
          value: currentModel.profile_info.gpu_encoder,
        },
        {
          key: 'B-Frame Rays',
          value: currentModel.profile_info.b_frame_rays.join(', ') || 'N/A',
        },
        {
          key: 'IFO Rays',
          value: currentModel.profile_info.ifo_rays.join(', ') || 'N/A',
        },
      ];
    }
    return [];
  }

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }

  public async copyAsset() {
    const {currentModel} = this.content;
    this.isProcessing = true;

    const job = await this.content.copyAsset(currentModel.id);

    this.isProcessing = false;
    this.pollFailedAttempts = 0;

    if (job && !this.pollCopyStatus) {
      let isCheckingStatus = false;

      this.pollCopyStatus = setInterval(async () => {
        if (!isCheckingStatus) {
          isCheckingStatus = true;
          await this.getAssetCopyStatus(job.id);
          isCheckingStatus = false;
        }
      }, this.pollingInterval);
    }
  }

  public async deleteAsset() {
    const assetId = this.content.currentModel.id;

    if (await this.content.deleteContent(assetId)) {
      if (this.library) {
        this.router.navigate(`/content/library/${this.library.id}`);
      } else {
        this.router.navigate('/content');
      }
    }
  }

  public async getAssetCopyStatus(jobId: string) {
    try {
      const {currentModel} = this.content;
      const job = await this.content.copyStatus(currentModel.id, jobId);

      this.pollFailedAttempts = 0;

      if (job.state === 'done') {
        this.finishedCopy = true;
        this.notification.success('Copy Completed');
        this.stopPolling();
      }

      if (job.state === 'error') {
        this.notification.error(job.last_error);
        this.finishedCopy = true;
        this.stopPolling();
      }
    } catch (err) {
      this.pollFailedAttempts += 1;

      if (this.pollFailedAttempts >= 5) {
        this.finishedCopy = true;
        this.stopPolling();
      }
    }
  }

  public openStudio() {
    const studioUrl = `/static/cms2/uplynk-studio.html#/asset/${this.content.currentModel.id}`;
    window.open(studioUrl);
  }

  public openClipCreator() {
    const path = environment.debug ? 'app' : 'dist';
    const ids = this.session.isIdsEnabled() ? '&idsEnabled=true' : '';

    window.open(`/static/cms/clipping/${path}/index.html?asset=${this.content.currentModel.id}${ids}`, '_blank');
  }

  public switchTab(routeName: string) {
    this.router.navigateToRoute(routeName, {libraryId: this.queryParams.libraryId});
  }

  private stopPolling() {
    if (this.pollCopyStatus) {
      clearInterval(this.pollCopyStatus);
      this.pollCopyStatus = null;
    }
  }
}
