import {CToastsService, IVNavSliderPageList, LocalStorageHelper, SharedNav} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {classToPlain} from 'class-transformer';
// tslint:disable-next-line:import-name
import * as moment from 'moment-timezone';

// import {IVNavSliderPageList} from 'interfaces/c-nav-vertical-sliding-interfaces';
import {Acceo} from 'services/acceo';
import {BetaMode} from 'services/beta-mode';
import {DEFAULT_PER_PAGE_LIMIT} from '../../models/defaults';
import {
  ChannelFilterParams,
  ChannelMetadata,
  ChannelsListResponse,
  ChannelsMeta,
  SimpleChannel,
} from '../models/models';
import {ChannelModel, MetaKeyValue} from '../single/models/channel-model';

const log = LogManager.getLogger('live-channels-service');
export const CHANNELS_HREF: string = '#/live-channels/channels';
export const CHANNELS_URL: string = '/api/v4/channels';
export const CHANNEL_META_URL: string = '/api/v4/channel-metas';
export const MANIFEST_PROFILES_URL: string = '/api/v4/channels/playback-profiles';
export const SERVER_TIME_URL: string = '/api/v4/servertime';

@autoinject()
export class LiveChannelsService {
  public params: ChannelFilterParams = {};
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public searchLoading: boolean = false;
  public isChannelLoading: boolean = false;
  public isCreating: boolean = false;
  public isDeleting: boolean = false;
  public activeTab: string = '';
  public channels: SimpleChannel[] = [];
  public newChannelName: string = '';
  public newSlicerId: string = '';
  public newChannelScheduler: boolean = null;
  public newChannelIsFast: boolean = false;
  public meta: ChannelsMeta = {};
  public searchQuery: string = '';
  public searchQueryApplied: string = '';
  public selectedChannels: string[] = [];
  public currentModel: ChannelModel = null;
  public currentTimezone: string = null;
  public currentSchedule: any;
  public createChannelDialog: any;
  public createChannelForm: any;
  public channelNotFound: boolean = false;
  public channelStatusCodeError: number = 0;
  public channelSinglePoll: any = null;
  public createErrMsgName: string = null;
  public createErrMsgSlicerId: string = null;
  public currentMetadata: ChannelMetadata = null;
  public metadataNotFound: boolean = false;
  public routeCallback: () => any;
  public selectedDate;

  private isSaving: boolean = false;
  private currentIndex: string = null;

  private navPage: IVNavSliderPageList = {
    loadMore: () => this.getMoreChannels(),
    navs: [],
    prevText: 'Live Channels Menu',
    searchFn: query => this.search(query),
    searchPlaceholder: 'Search Channels',
    searchQuery: '',
    title: 'Channels',
  };

  constructor(
    private acceo: Acceo,
    private sharedNav: SharedNav,
    private notification: CToastsService,
    private dialogService: DialogService,
    private betaMode: BetaMode,
  ) {
    this.currentTimezone = LocalStorageHelper.loadOrDefault('channel-timezone', moment.tz.guess());
  }

  /**
   * Reset params to default
   */
  public cleanParams() {
    // Reset the params to be default
    this.params = {};
  }

  /**
   * Delete all the selected channels
   */
  public async deleteChannels(id?: string, forceDelete: boolean = false): Promise<boolean> {
    let deleteSuccessful = true;
    if (this.isDeleting) {
      return false;
    }

    if (id) {
      // this.selectedChannels = [id];
      this.selectedChannels = id.split(',');
    }

    this.isDeleting = true;
    const errors = [];
    const conflicts = [];
    const deleted = [];

    this.selectedChannels = await Promise.filter(this.selectedChannels, async chanId => {
      try {
        await this.acceo.delete()(`${CHANNELS_URL}/${chanId}${forceDelete ? '?force_delete=1' : ''}`);

        deleted.push(id);

        // Remove it from the channels array
        _.remove(this.channels, {id: chanId});

        // Remove it from the side nav
        const navItemIndex = _.findIndex(this.navPage.navs, (navItem: any) =>
          _.startsWith(navItem.href, `${CHANNELS_HREF}/${chanId}`),
        );
        if (navItemIndex > -1) {
          this.navPage.navs.splice(navItemIndex, 1);
        }

        this.meta.total -= 1;
        this.meta.showing = this.channels.length;

        return false; // Only keep errors
      } catch (e) {
        if (e.status_code === 409) {
          const updatedItem = _.find(this.channels, channel => channel.id === chanId);
          if (updatedItem) {
            conflicts.push({
              desc: updatedItem.desc,
              id: updatedItem.id,
              slicer_id: updatedItem.slicer_id,
            });
            // } else {
            //     console.log('unable to find item...');
          }
        } else {
          errors.push(chanId);
        }
        return true;
      }
    });

    if (errors.length) {
      this.notification.error(`Error removing channel${errors.length > 1 ? 's' : ''}. Please try again.`);
    }

    if (conflicts.length) {
      const allowForceDelete = await this.openSlicerActiveWarning(conflicts);
      if (allowForceDelete) {
        const items = _.map(conflicts, conflict => conflict.id).join(',');
        this.isDeleting = false;
        await this.deleteChannels(items, true);
      } else {
        deleteSuccessful = false;
      }
    }

    this.isDeleting = false;

    if (deleted.length > 0) {
      this.notification.success(`Deleted ${deleted.length} channel${deleted.length === 1 ? '' : 's'}`);
    }

    if (!this.selectedChannels.length) {
      this.selectedChannels = [];
    }

    return deleteSuccessful;
  }

  public async openSlicerActiveWarning(items = []): Promise<boolean> {
    let forceDeleteChannels = false;
    await this.dialogService
      .open({
        model: {
          bodyModel: {
            items,
          },
          bodyViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/channels/modals/delete-warning-body'),
          footerEnable: true,
          footerModel: {
            conflicts: items.map(i => i.id),
          },
          footerViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/channels/modals/delete-warning-footer'),
          sharedModel: {
            errMsg: '',
          },
          size: 'medium',
          title: 'Channels with Active Slicers',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed(response => {
        if (!response.wasCancelled) {
          // If you wanted the ability for the user to select which ones to delete,
          // you could use the response.output to return the set of id's
          // if (response.output) {
          forceDeleteChannels = true;
          // }
          return true;
        }
        return false;
      });
    return forceDeleteChannels;
  }

  /**
   * Search the channels
   *
   * @param searchQuery Optional search term that comes from the sidebar
   */
  public async search(searchQuery?: string) {
    this.navPage.searchQuery = searchQuery;
    this.searchQuery = searchQuery;
    this.searchQueryApplied = '';

    const params: ChannelFilterParams = {};
    if (searchQuery) {
      params.search = searchQuery;
    }

    this.searchLoading = true;

    try {
      await this.getChannels(params);
      this.searchQueryApplied = searchQuery;
    } catch (err) {
      log.error(err);
    } finally {
      this.searchLoading = false;
    }
  }

  /**
   * Create a new channel
   *
   * @param andEdit If you're going to navigate to it after creation
   */
  public async createChannel(andEdit: boolean = false) {
    const createParams = {
      desc: this.newChannelName,
      slicer_id: this.newSlicerId,
      use_chsched: this.newChannelScheduler ? 2 : 1,
      fast_channel: !!(this.newChannelScheduler && this.newChannelIsFast),
    };

    this.createErrMsgName = '';
    if (!this.newChannelName.length) {
      this.createErrMsgName = 'Name is required.';
      return;
    }

    this.isCreating = true;

    try {
      const res = await this.acceo.post(SimpleChannel)(CHANNELS_URL, createParams, {
        requestTransform: params => JSON.stringify(params),
      });
      this.notification.success('Channel created successfully.');
      this.channels.unshift(res);
      this.navPage.navs.unshift({
        active: false,
        href: `${CHANNELS_HREF}/${res.id}`,
        title: res.desc,
      });

      this.meta.total += 1;
      this.meta.showing = this.channels.length;

      this.newChannelName = '';
      this.newSlicerId = '';
      this.createChannelDialog.hide();
      this.createChannelForm.reset();

      if (andEdit) {
        document.location.href = `${CHANNELS_HREF}/${res.id}`;
      }
    } catch (e) {
      if (e.message) {
        this.notification.error(e.message);
        if (e.message.toLowerCase().includes('slicer id')) {
          this.createErrMsgSlicerId = 'Must be alphanumeric value.';
        }
      } else {
        this.notification.error('Error creating channel.');
      }
      log.error(e);
    } finally {
      this.isCreating = false;
    }
  }

  /**
   * Track a channel as it's toggled for deletion
   *
   * @param channel SimpleChannel object
   */
  public trackSelectedChannel(channel: SimpleChannel) {
    if (channel.selected) {
      this.selectedChannels.push(channel.id);
    } else {
      _.remove(this.selectedChannels, channelId => channelId === channel.id);
    }

    this.selectedChannels = _.uniq(this.selectedChannels);
  }

  /**
   * Get a list of channels based on a set of parameters
   *
   * @param params ChannelFilterParams object
   * @param isLoadMore Optional boolean for if it's a fresh load or it's loading more in the existing list
   */
  public async getChannels(params: ChannelFilterParams, isLoadMore: boolean = false) {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (this.searchQuery) {
      params.search = this.searchQuery;
    }

    if (!isLoadMore) {
      this.channels = [];
      this.isLoading = true;
      this.navPage.isLoading = true;
    } else {
      this.isLoadingMore = true;
      params.page = Math.floor((this.meta.total - (this.meta.total - this.channels.length)) / params.page_size) + 1;
    }

    this.params = _.cloneDeep(params);

    try {
      const res = await this.acceo.get(ChannelsListResponse)(`${CHANNELS_URL}?${$.param(this.params)}`, {
        responseTransform: resp => {
          _.forEach(resp.items, channel => {
            channel.descIcon = channel.use_chsched === 2 ? 'calendar' : null;
            channel.descIconSize = channel.use_chsched === 2 ? '1em' : null;
            channel.descIconColor = channel.use_chsched === 2 ? 'var(--c_lightGray)' : null;
          });
          return resp;
        },
      });

      this.channels = _.uniqBy(this.channels.concat(res.items), 'id');

      this.updateNav();

      this.params.since = res.now;

      this.meta.total = res.total_items;
      this.meta.showing = this.channels.length;
      this.meta.limit = params.page_size || null;
      this.meta.hasMore = this.channels.length < res.total_items;
    } catch (err) {
      log.error(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.navPage.isLoading = false;
    }
  }

  public async getChannelUpdates(ids: string[]) {
    try {
      const params = {
        ids,
        created_after: 'now-2m',
      };

      const res = await this.acceo.post(ChannelsListResponse)(`${CHANNELS_URL}/updates`, params);
      const {items = []} = res;

      items.forEach(channel => {
        const existingIndex = this.channels.findIndex(c => c.id === channel.id);

        if (existingIndex > -1) {
          delete channel.selected; // Don't overwrite the selected state
          this.channels[existingIndex] = Object.assign(this.channels[existingIndex], channel);
        } else if (!channel.deleted) {
          // Put new channels at the start of the list
          this.channels.unshift(channel);
          this.meta.total += 1;
        }
      });

      const itemIds = items.map(c => c.id);
      const removedIds = ids.filter(id => !itemIds.includes(id));
      const filteredChannels = this.channels.filter(c => !removedIds.includes(c.id) && !c.deleted);
      const filteredLength = filteredChannels.length;

      // Update the total if we removed channels
      if (filteredLength < this.channels.length) {
        this.meta.total -= this.channels.length - filteredLength;
      }

      this.channels = filteredChannels;
      this.meta.showing = this.channels.length;

      this.updateNav();
    } catch (e) {
      // Don't do anything for a failed update
    }
  }

  /**
   * Sets a channel active in the side nav
   *
   * @param id Channel ID
   */
  public setNavActive(id: string) {
    this.sharedNav.replacePage(this.navPage, 1);

    this.currentIndex = id;

    if (id) {
      _.forEach(this.channels, (channel, index) => {
        if (channel.id === id) {
          this.sharedNav.setActive(1, index);
          return false;
        }
        return true;
      });
    }
  }

  public updateNavTitle() {
    const currentIndex = _.findIndex(this.navPage.navs, {active: true});

    if (currentIndex > -1) {
      this.navPage.navs[currentIndex].title = this.currentModel.desc;
    }
  }

  public cleanUpActive() {
    this.currentModel = null;
    this.activeTab = '';
  }

  public updateSubNavItems() {
    const tabNames = [
      '/blackout',
      '/slate',
      '/playback',
      '/metadata',
      '/schedule',
      '/publish',
    ];

    _.forEach(this.navPage.navs, nav => {
      _.forEach(tabNames, tabName => {
        nav.href = nav.href.replace(tabName, '');
      });

      if (this.activeTab) {
        const idIndex = nav.href.lastIndexOf('/');
        const replaceText = nav.href.slice(idIndex);
        nav.href = nav.href.replace(replaceText, `${replaceText}/${this.activeTab}`);
      }
    });
  }

  /**
   * Gets more channels based on scrolling the table
   */
  public async getMoreChannels() {
    if (this.meta.showing < this.meta.total) {
      const params = _.cloneDeep(this.params);
      delete params.since;

      await this.getChannels(params, true);
    }
  }

  /**
   * Get a single channel by ID
   * @param id Channel ID
   */
  public async getSingleChannel(id: string, isPolling: boolean = false) {
    this.channelNotFound = false;
    this.channelStatusCodeError = 0;
    try {
      const res = await this.acceo.get(ChannelModel)(`${CHANNELS_URL}/${id}`, {
        responseTransform: this.channelResponseTransform,
      });

      // update the currentModel if not saving
      if (!this.isSaving) {
        this.currentModel = res;
      }
    } catch (err) {
      if (!isPolling) {
        this.channelNotFound = true;
        this.channelStatusCodeError = err.status_code;
      }
      log.error(err);
    }
  }

  public async getAssetPlayURL(id: string) {
    let playUrl;
    try {
      (playUrl as any) = await this.acceo.post()(
        '/asset/playurl/',
        {id},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          requestTransform: params => $.param(params),
          responseTransform: resp => resp.url,
        },
      );
    } catch (err) {
      playUrl = '';
      log.error(err);
      throw new Error(err.message);
    }

    return playUrl;
  }

  public async getPlayURL(id: string) {
    let playUrl;
    try {
      (playUrl as any) = await this.acceo.post()(
        '/channel/playurl/',
        {id},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          requestTransform: params => $.param(params),
          responseTransform: resp => resp.url,
        },
      );
    } catch (err) {
      playUrl = '';
      log.error(err);
      throw new Error(err.message);
    }

    return playUrl;
  }

  public async saveChannel(updatedModel: any): Promise<ChannelModel> {
    this.isSaving = true;
    try {
      const {id} = updatedModel;
      delete updatedModel.id;
      const res = await this.acceo.patch(ChannelModel)(`${CHANNELS_URL}/${id}`, updatedModel, {
        requestTransform: this.channelRequestTransform,
        responseTransform: this.channelResponseTransform,
      });
      this.notification.success('Changes saved successfully.');
      return res;
    } catch (err) {
      log.error(err);
      throw new Error(err.message);
    } finally {
      this.isSaving = false;
    }
  }

  /**
   * Toggle all visible channels as deletable
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.channels, channel => {
      if (isSelected) {
        channel.selected = true;
      } else {
        channel.selected = false;
      }
    });

    this.selectedChannels = _.map(
      _.filter(this.channels, channel => channel.selected),
      channel => channel.id,
    );
  }

  /**
   * Get metadata for Channel.  This is used to hide/show columns in the Channel List view
   */
  public async getMetadata() {
    try {
      const res = await this.acceo.get(ChannelMetadata)(CHANNEL_META_URL);

      this.currentMetadata = res;
    } catch (err) {
      if (err && err.status_code === 404) {
        this.metadataNotFound = true;
      }
      log.error(err);
      throw new Error(err);
    }
  }

  /**
   * Save metadata for Channel.
   */
  public async saveMetadata(metadata: any): Promise<ChannelMetadata> {
    try {
      const data: any = classToPlain(metadata);
      const res = await this.acceo.put(ChannelMetadata)(CHANNEL_META_URL, data);
      return res;
    } catch (err) {
      log.error(err);
      throw new Error(err.message);
    }
  }

  public async getServerTime(): Promise<string> {
    try {
      const time: any = await this.acceo.get()(SERVER_TIME_URL);
      return time.now;
    } catch (err) {
      log.error(err);
      throw new Error(err.message);
    }
  }

  public async getManifestProfiles(): Promise<any[]> {
    try {
      const rsp: any = await this.acceo.get()(MANIFEST_PROFILES_URL);
      return rsp.items;
    } catch (err) {
      log.error(err);
      throw new Error(err.message);
    }
  }

  private updateNav() {
    this.navPage.navs = _.map(this.channels, channel => ({
      active: channel.id === this.currentIndex,
      href: `${CHANNELS_HREF}/${channel.id}`,
      title: channel.desc,
    }));
    this.updateSubNavItems();
  }

  private channelRequestTransform(data) {
    // Convert meta from an array
    if (data.meta) {
      const metaObj = {};
      _.forEach(data.meta, m => {
        // not sending hidden i.e. soft deleted, metadata items.
        if (!m._hidden) {
          metaObj[m.key] = m.value;
        }
      });
      data.meta = metaObj;
    }

    // Remove checked indication from test players
    if (data.test_players) {
      _.forEach(data.test_players, tp => {
        delete tp.checkbox;
      });
    }

    // Only update what we need to
    if (_.hasIn(data, 'require_drm')) {
      if (data.require_drm) {
        data.require_drm = 1;
      } else {
        data.require_drm = 0;
      }
    }

    if (_.hasIn(data, 'require_studio_drm')) {
      if (data.require_studio_drm) {
        data.require_studio_drm = 1;
      } else {
        data.require_studio_drm = 0;
      }
    }

    return data;
  }

  private channelResponseTransform(resp) {
    // Convert meta into an array for easy table usage
    if (resp.meta) {
      const meta: MetaKeyValue[] = [];
      _.forOwn(resp.meta, (value, key) => {
        meta.push({key, value});
      });

      resp.meta = meta;
    } else {
      resp.meta = [];
    }

    _.forEach(resp.test_players, tp => {
      if (tp.expire) {
        tp.expire = _.toNumber(tp.expire);
      }
    });

    if (resp.require_drm) {
      resp.require_drm = true;
    } else {
      resp.require_drm = false;
    }

    if (resp.require_studio_drm) {
      resp.require_studio_drm = true;
    } else {
      resp.require_studio_drm = false;
    }

    return resp;
  }

  public isFastChannelAllowed() {
    return this.betaMode.hasScope('Wurl');
  }
}
