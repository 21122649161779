import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, LogManager} from 'aurelia-framework';
import {validate, ValidationError} from 'class-validator';
import {dirtyCheckPrompt} from 'decorators';
import {Notification} from 'resources/notification/service';
import {MetadataSchema} from '../models/metadata';
import {MetadataSchemaService} from '../services/metadata-schema-service';

export enum MetadataSchemaEvent {
  CREATE = 'LiveEventMetadataSchemaEvent:Create',
  SAVED = 'LiveEventMetadataSchemaEvent:Saved',
  KEY_DELETED = 'LiveEventMetadataSchemaEvent:KeyDeleted',
  CHANGED = 'LiveEventMetadataSchemaEvent:Changed',
}
const log = LogManager.getLogger('live-events-settings-metadata-schema');

@dirtyCheckPrompt
@autoinject()
export class MetadataSchemaConfig {
  public schemas: MetadataSchema[];
  public canDeactivate: () => Promise<boolean>;
  public currentSchemaPristine: MetadataSchema;
  public currentSchema: MetadataSchema;
  public pristineSchema: MetadataSchema;
  public isSchemaDirty = false;
  public isLoading = false;
  public loadingError = false;
  public isCreating = false;
  public isDeleting = false;
  public isSaving = false;
  public validationErrors: ValidationError[];
  public deleteTip;
  public items;
  private subscriptions: Subscription[] = [];

  constructor(
    public eventAggregator: EventAggregator,
    public notification: Notification,
    public metadataSchemaService: MetadataSchemaService,
  ) {}

  public attached(): Promise<void> {
    this.loadingError = false;
    this.isLoading = true;
    this.subscriptions.push(
      this.eventAggregator.subscribe(MetadataSchemaEvent.CREATE, this.onCreate.bind(this)),
      this.eventAggregator.subscribe(
        MetadataSchemaEvent.CHANGED,
        _.throttle(this.onChange.bind(this), 200, {leading: true, trailing: true}),
      ),
    );
    return this.metadataSchemaService
      .list()
      .then(schemas => {
        this.schemas = schemas;
        this.setCurrentSchema(schemas[0]);
      })
      .catch(e => {
        this.loadingError = true;
        log.error(e);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public isDirty(): boolean {
    this.isSchemaDirty = JSON.stringify(this.currentSchema) !== JSON.stringify(this.pristineSchema);
    return this.isSchemaDirty;
  }

  public async save(): Promise<void> {
    this.validationErrors = null;
    return validate(this.currentSchema, {skipMissingProperties: true}).then((errs: ValidationError[]) => {
      if (errs.length) {
        errs.forEach(err => log.error(String(err)));
        this.validationErrors = errs;
        this.notification.error('Validation Error. Ensure that all properties have the proper values.');
      } else {
        this.isSaving = true;
        return this.metadataSchemaService
          .update(this.currentSchema)
          .then((schema: MetadataSchema) => {
            this.setCurrentSchema(schema);
            this.notification.success('Schema saved successfully.');
          })
          .catch(err => {
            log.error(err);
            this.notification.error('Error saving schema. Please try again.');
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
      return null;
    });
  }

  public async delete(): Promise<void> {
    this.isDeleting = true;
    return this.metadataSchemaService
      .delete(this.currentSchema.id)
      .then(() => {
        const index = _.findIndex(this.schemas, {id: this.currentSchema.id});
        this.schemas.splice(index, 1);
        if (this.schemas.length) this.setCurrentSchema(this.schemas[0]);
        this.notification.success('Schema deleted successfully.');
      })
      .catch(err => {
        log.error(err);
        this.notification.error('Error deleting schema. Please try again.');
      })
      .finally(() => {
        this.isDeleting = false;
      });
  }

  public canCreate = () => this.canDeactivate();

  public async canSetCurrentSchema(schema: MetadataSchema): Promise<void> {
    return this.canDeactivate()
      .then((deactivate: boolean) => {
        if (deactivate) {
          this.schemas.splice(_.findIndex(this.schemas, {id: this.currentSchema.id}), 1, this.pristineSchema);
          this.setCurrentSchema(schema);
        }
      })
      .catch(e => {
        log.error(e);
      });
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }

  private onCreate(schema: MetadataSchema) {
    return this.canDeactivate()
      .then((canCreate: boolean) => {
        if (canCreate) {
          this.isCreating = true;
          return this.metadataSchemaService
            .create(schema)
            .then((newSchema: MetadataSchema) => {
              if (this.currentSchema && this.schemas.length) {
                this.schemas.splice(_.findIndex(this.schemas, {id: this.currentSchema.id}), 1, this.pristineSchema);
              }
              this.schemas.push(newSchema);
              this.setCurrentSchema(newSchema);
            })
            .catch(err => {
              this.notification.error('Error creating schema. Please try again.');
              log.error(err);
            })
            .finally(() => {
              this.isCreating = false;
            });
        }
        return null;
      })
      .error(log.error);
  }

  private onChange() {
    this.isDirty();
  }

  private setCurrentSchema(schema: MetadataSchema) {
    this.currentSchema = schema;
    this.pristineSchema = _.cloneDeep(schema);
    this.validationErrors = null;
    this.isDirty();
  }
}
