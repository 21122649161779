import {autoinject, bindable, bindingMode} from 'aurelia-framework';
import {authorizationConstants} from 'services/authorization';
import {SyndicationOwnerConfig} from 'services/models/syndication-owner-config';
import {SyndicationOwnerConfigService} from 'services/syndication-owner-config-service';

@authorizationConstants
@autoinject()
export class SnsConfig {
  @bindable({defaultBindingMode: bindingMode.twoWay})
  public showDialog;

  public isLoading: boolean = false;
  public loadingError: boolean = false;
  public ownerConfig: SyndicationOwnerConfig;
  public cleanOwnerConfig: SyndicationOwnerConfig;

  constructor(public syndicationOwnerConfigService: SyndicationOwnerConfigService) {}

  public attached() {
    this.init();
  }

  public async init() {
    this.isLoading = true;
    await this.syndicationOwnerConfigService
      .getSyndicationOwnerConfig()
      .then((ownerConfig: SyndicationOwnerConfig) => {
        this.ownerConfig = _.cloneDeep(ownerConfig);
        this.cleanOwnerConfig = _.cloneDeep(ownerConfig);
      })
      .catch(() => {
        this.loadingError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public async save() {
    const ownerConfig = new SyndicationOwnerConfig();
    ownerConfig.sns_topic = this.ownerConfig.sns_topic;
    try {
      await this.syndicationOwnerConfigService
        .saveSyndicationOwnerConfig(ownerConfig)
        .then((data: SyndicationOwnerConfig) => {
          this.ownerConfig = _.cloneDeep(data);
          this.cleanOwnerConfig = _.cloneDeep(data);
        });
      this.showDialog = false;
    } catch (e) {
      return false;
    }
    return true;
  }
}
