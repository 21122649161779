import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {ClippingConfig, DropdownValue, Metadata} from '../models/clipping-config';
import {MetadataType} from '../models/enums-config';

export const CLIPPING_METADATA_EVENT = {
  MetadataDelete: 'ClippingMetadataEvent:Delete',
};

@autoinject()
export class ClippingMetadata {
  @bindable public config: ClippingConfig;
  @bindable public validationErrors: ValidationError[];
  @bindable public callbacks;

  public subscriptions: Subscription[] = [];
  public tc;
  public hasError = false;
  public newMetadataKeyNameError = false;
  public newKeyName: string = '';
  public newKeyNameError: boolean = false;
  public newDisplayName: string = '';
  public newMetaKeyName: string = '';
  public tcNewMetaKey;
  public descInput;
  public metakeyIsDuplicate: boolean = false;
  public metakeyNameRequired: boolean = false;
  public hasFocus: boolean = true;

  constructor(public eventAggregator: EventAggregator) {}

  public attached() {
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        CLIPPING_METADATA_EVENT.MetadataDelete,
        opt =>
          new Promise(resolve => {
            this.deleteMetadata(opt.itemType);
            resolve();
          }),
      ),
    );
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }

  public deleteMetadata(keyvalue: string) {
    const index = this.config.metadata.findIndex(m => m.key === keyvalue);

    if (index === -1) {
      return;
    }

    this.config.metadata.splice(index, 1);
    this.callbacks.onChange();
  }

  // public deleteMetadata(event, keyvalue) {
  //     event.cancelBubble = true;
  //     const index = this.config.metadata.indexOf(keyvalue);
  //     this.config.metadata.splice(index, 1);
  //     this.callbacks.onChange();
  // }

  public deleteDropDownKey(event, metadata, keyvalue) {
    event.cancelBubble = true;
    const index = metadata.values.indexOf(keyvalue);
    metadata.values.splice(index, 1);
  }

  public addDropDownKey(event, metadata) {
    event.cancelBubble = true;
    if (this.newKeyName.length === 0) {
      this.newKeyNameError = true;
    } else {
      this.newKeyNameError = false;
      const dropDownValue = new DropdownValue();
      dropDownValue.key = this.newKeyName;
      dropDownValue.value = this.newDisplayName;
      metadata.values = metadata.values.concat([dropDownValue]);
      this.newKeyName = '';
      this.newDisplayName = '';
      this.callbacks.onChange();
    }
  }

  public createNewMetaKey() {
    this.metakeyNameRequired = false;
    this.metakeyIsDuplicate = false;
    if (this.newMetaKeyName.trim() === '') {
      this.metakeyNameRequired = true;
      return;
    }

    const idx = this.config.metadata.findIndex(m => m.key === this.newMetaKeyName.trim());

    if (idx > -1) {
      this.metakeyIsDuplicate = true;
      return;
    }

    this.metakeyIsDuplicate = false;
    const cfg: Metadata = new Metadata();
    cfg.key = this.newMetaKeyName.trim();
    cfg.input_type = MetadataType.Text;
    cfg.default_value = '';
    this.config.metadata.push(cfg);

    // clear out the values & close the tip
    this.metakeyIsDuplicate = false;
    this.metakeyNameRequired = false;
    this.newMetaKeyName = '';
    this.tcNewMetaKey.hide();
  }

  public descFocus() {
    this.descInput.focus();
  }
}
