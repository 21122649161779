import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject} from 'aurelia-framework';
import {CLIPPING_INTEGRATION_EVENT} from '.';

@autoinject()
export class IntegrationDeleteConfirm {
    public tcDeleteIntegration;

    constructor(public eventAggregator: EventAggregator) {}

    public buttonRemoveIntegration(itemType) {
        this.tcDeleteIntegration.hide();
        this.eventAggregator.publish(CLIPPING_INTEGRATION_EVENT.IntegrationDelete, {itemType});
    }
}
