import {autoinject, singleton} from 'aurelia-framework';
import {serialize} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {SlateSettingsWrapper} from '../models/slate-settings';

@autoinject()
@singleton()
export class SettingsService {
    constructor(public acceo: Acceo) {}

    public getSlateSettings(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.acceo
                .post(SlateSettingsWrapper)('/settings/slate/', undefined)
                .then((ssw: SlateSettingsWrapper) => resolve(ssw))
                .catch(reject);
        });
    }

    public saveSlateSettings(data): Promise<undefined> {
        return new Promise((resolve, reject) => {
            const dataString = `item=${encodeURIComponent(serialize(data))}`;
            this.acceo
                .post()('/settings/slate/update/', dataString, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                })
                .then(resolve)
                .catch(reject);
        });
    }
}
