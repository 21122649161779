import {IVNavSliderNavList, IVNavSliderPageList, SharedNav} from '@bindable-ui/bindable';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, singleton} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterConfiguration, RouterEvent} from 'aurelia-router';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {MSOSlicerGetByOwnerService} from '../services/mso-slicergetbyowner-service';

export enum RouteName {
  HostedSlicerList = 'hostedSlicerList',
  HostedSlicerSingle = 'hostedSlicerSingle',
}

@singleton()
@autoinject()
export class HostedSlicerMenu {
  protected navPage: IVNavSliderPageList = {
    navs: [],
    prevText: 'Slicers Menu',
    searchFn: this.search.bind(this),
    searchPlaceholder: 'Search Hosted Slicers',
    searchQuery: '',
    title: 'Hosted Slicers',
  };

  protected navs: IVNavSliderNavList[] = [];
  protected routerEventComplete: Subscription;
  protected routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: RouteName.HostedSlicerList,
      nav: false,
      route: '',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: RouteName.HostedSlicerSingle,
      nav: false,
      route: ':id',
    },
  ];

  protected slicerGetByOwnerService: MSOSlicerGetByOwnerService;

  constructor(
    protected acceo: Acceo,
    protected eventAggregator: EventAggregator,
    protected router: Router,
    protected sessionService: SessionService,
    protected sharedNav: SharedNav,
  ) {
    this.slicerGetByOwnerService = new MSOSlicerGetByOwnerService(acceo);
  }

  public attached() {
    this.routerEventComplete = this.eventAggregator.subscribe(
      RouterEvent.Complete,
      this.onRouterEventComplete.bind(this),
    );
    this.load();
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }

  public detached() {
    this.routerEventComplete.dispose();
    if (this.sharedNav.nav.pages.indexOf(this.navPage) !== -1) {
      this.sharedNav.nav.pages.splice(this.sharedNav.nav.pages.indexOf(this.navPage), 1);
    }
  }

  public async load() {
    if (this.router.currentInstruction.fragment) {
      this.sharedNav.replacePage(this.navPage, 1);
      this.sharedNav.setNextText('Hosted Slicer List');
    }
    this.navPage.isLoading = true;
    this.navs = await this.requestRead();
    this.updateNav();
    this.search();
    this.navPage.isLoading = false;
  }

  protected onRouterEventComplete() {
    this.updateNav();
  }

  protected async requestRead(): Promise<IVNavSliderNavList[]> {
    return this.slicerGetByOwnerService.get(this.sessionService.owner).then(value =>
      value.items
        .filter(item => !item.deleted)
        .sort((a, b) => (b.slicer_id > a.slicer_id ? -1 : 1))
        .map(item => ({
          active: false,
          href: `#/slicers/hosted/${item.id}`,
          title: item.slicer_id,
        })),
    );
  }

  protected search(query: string = '') {
    // eslint-disable-next-line no-return-assign
    return (this.navPage.navs = this.navs.filter(
      nav =>
        !query.length ||
        nav.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        nav.subText.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    ));
  }

  protected updateNav() {
    let hostedSlicerIndex = -1;
    this.navs.forEach((nav, index) => {
      nav.active =
        this.router.currentInstruction.fragment && nav.href.indexOf(this.router.currentInstruction.fragment) !== -1;
      if (nav.active) hostedSlicerIndex = index;
      return nav;
    }, this);
    if (hostedSlicerIndex === -1) return;
    if (this.sharedNav.nav.pages.indexOf(this.navPage) !== -1) {
      this.sharedNav.setActive(1, hostedSlicerIndex);
    } else {
      this.sharedNav.replacePage(this.navPage, 1);
      this.sharedNav.setNextText('Hosted Slicer List');
    }
  }
}
