import {bindable, bindingMode, autoinject} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {PLATFORM} from 'aurelia-pal';
import {CPopoverService} from '@bindable-ui/bindable';
import {debounce} from 'lodash';
import {DraggabillyService, IDraggabillyActions} from 'resources/timeline/services/draggabilly-service';
import {BLOCK_HEIGHT} from 'resources/timeline/b-timeline/b-timeline';

import * as styles from './b-time-entry.css';

const TIMELINE_BLOCK_MARGIN = 30;
const MOUSEOVER_DELAY = 300; // Initiate drag/drop only if mouseover lasts this long

@autoinject
export class BTimeEntry {
  @bindable({defaultBindingMode: bindingMode.twoWay})
  public item = null;

  public popoverOpen = false;
  public styles = styles;

  @bindable
  public actions: IDraggabillyActions;

  @bindable
  public readonly: boolean = false;

  public isDraggable: boolean = false;

  private mouseOverTimeout: number | undefined;

  constructor(
    public element: Element,
    private vPopoverService: CPopoverService,
    public draggabillyService: DraggabillyService,
    private dialogService: DialogService,
  ) {}

  attached() {
    this.element.addEventListener('mouseover', this.debouncedHandleMouseOver, {passive: true});
    this.element.addEventListener('mouseout', this.handleMouseOut, {passive: true});
  }

  detached() {
    this.element.removeEventListener('mouseover', this.debouncedHandleMouseOver);
    this.element.removeEventListener('mouseout', this.handleMouseOut);

    if (this.isDraggable) {
      this.draggabillyService.untrack(this.element);
      this.isDraggable = false;
    }
  }

  private handleMouseOver = () => {
    this.mouseOverTimeout = window.setTimeout(() => {
      if (this.allowDragAndDrop() && !this.isDraggable) {
        this.draggabillyService.track(this.item, this.element, this.actions, BLOCK_HEIGHT / 2);
        this.isDraggable = true;
      }
    }, MOUSEOVER_DELAY);
  };

  private debouncedHandleMouseOver = debounce(this.handleMouseOver, MOUSEOVER_DELAY);

  private handleMouseOut = () => {
    if (this.mouseOverTimeout) {
      clearTimeout(this.mouseOverTimeout);
      this.mouseOverTimeout = undefined;
    }
  };

  private allowDragAndDrop() {
    if (
      !this.item ||
      this.dialogService.hasOpenDialog ||
      this.item.placeholder ||
      this.item.model.isRepeat ||
      this.item.model.content_type === 'overlay'
    ) {
      return false;
    }
    const inThePast = new Date(this.item.end).getTime() < Date.now();
    return !this.item.active && !inThePast && !this.readonly;
  }

  public openPopover($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    if (!this.item || this.draggabillyService.preventCreateNewEntry) {
      return;
    }

    if (!this.item.contentViewModel) {
      // Relative to popover component
      this.item.contentViewModel = PLATFORM.moduleName('./b-time-entry-popover');
    }

    this.popoverOpen = true;

    this.vPopoverService.color = 'var(--c_gray)';

    if (!this.item.placeholder && this.item.accentColor) {
      this.vPopoverService.color = this.item.accentColor;
    }

    const {left, top} = this.getPopoverPosition($event);

    this.vPopoverService.open({
      left,
      top,
      contentViewModel: this.item.contentViewModel,
      data: this.item,
      onClose: () => {
        if (this.item && this.item.placeholder) {
          this.item = null;
          return;
        }

        this.popoverOpen = false;
      },
    });
  }

  // this needs to be rewritten, so for now, excluding from test coverage report
  /* istanbul ignore next */
  private getPopoverPosition(event) {
    const POPOVER_WIDTH = parseInt(
      window.getComputedStyle(document.documentElement, null).getPropertyValue('--w_popover'),
      10,
    );

    let top;

    let leftOffet = 0;

    const jqElem = $(this.element);

    const elementWidth = jqElem.children().outerWidth(true);
    const entryRight = parseInt(jqElem.children(`.${styles.entry}`).css('right'), 10);
    const timeBlockWidth = $('b-timeline-block').last().outerWidth(true) - TIMELINE_BLOCK_MARGIN;

    const offsets = jqElem.children().offset();
    const positions = jqElem.children().position();

    let topPosition = offsets.top - positions.top;
    let leftPosition = offsets.left - positions.left;

    if (offsets.left < POPOVER_WIDTH) {
      leftPosition += elementWidth + (TIMELINE_BLOCK_MARGIN + 5);
    } else {
      leftPosition -= POPOVER_WIDTH - (TIMELINE_BLOCK_MARGIN - 5);
    }

    if (elementWidth + POPOVER_WIDTH + leftPosition > window.outerWidth) {
      leftPosition = window.outerWidth / 2 - POPOVER_WIDTH / 2;
      topPosition += 25;
    }

    if (elementWidth !== timeBlockWidth) {
      leftOffet += timeBlockWidth - (elementWidth + entryRight);
    }

    const left = leftPosition + leftOffet;
    top = topPosition + this.item.top;

    if (event && event.clientY) {
      // Just work off where the click happens
      top = event.clientY - 10;
    }

    return {left, top};
  }
}
