import {SessionService} from 'services/session';
import {BindingEngine} from 'aurelia-binding';
import {inject} from 'aurelia-framework';
import {AuthorizeStep} from '../../services/auth';

@inject(SessionService, BindingEngine)
export class App {
    constructor(sessionService, bindingEngine) {
        this.session = sessionService;
        this.bindingEngine = bindingEngine;
    }

    attached() {
        this.session.sessionPing();
        this.usernameSubscription = this.bindingEngine
            .propertyObserver(this.session, 'username')
            .subscribe((newVal, oldVal) => {
                if (oldVal != null && newVal !== oldVal) {
                    // alert("Your CMS Account Session Has Changed");
                    window.location.reload();
                }
            });
    }

    detached() {
        if (this.usernameSubscription) {
            this.usernameSubscription.dispose();
        }
    }

    configureRouter(config, router) {
        config.addPipelineStep('authorize', AuthorizeStep);
        config.title = 'ESNI Audit Viewer';
        config.map([
            {
                route: '',
                name: 'esni-audit',
                moduleId: PLATFORM.moduleName('./audit/esni-audit', 'esni'),
                nav: true,
                title: 'Audit',
            },
            {
                route: 'media',
                name: 'esni-media',
                moduleId: PLATFORM.moduleName('./media/esni-media', 'esni'),
                nav: true,
                title: 'Media',
            },
            {
                route: 'settings',
                name: 'esni-settings',
                moduleId: PLATFORM.moduleName('./settings/esni-settings', 'esni'),
                nav: true,
                title: 'Settings',
            },
        ]);

        this.router = router;
    }
}
