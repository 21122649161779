// tslint:disable max-line-length
import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {MSOSlicerHealthMonitorResponseGet} from '../models/msoslicer';

@autoinject
export class MSOSlicerMonitorService {
    private url: string = '/api/v4/managed-slicer/slicer/monitor';

    constructor(private acceo: Acceo) {}

    public get(id: string) {
        return this.acceo.get(MSOSlicerHealthMonitorResponseGet)(`${this.url}/${id}`);
    }

    public getById(id: string) {
        /* FOR LOCALHOST TESTING ONLY! DO NOT COMMIT THE FOLLOWING LINE UNCOMMENTED */
        // return this.getByIdMock('rist');
        return this.acceo.get(MSOSlicerHealthMonitorResponseGet)(`${this.url}/${id}`);
    }
}
