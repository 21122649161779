import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom, LogManager} from 'aurelia-framework';

import {ScheduleEntryService} from '../../../services/scheduled-entry';
import {ITrackedScheduledEntry} from '../../models/event-model';

const log = LogManager.getLogger('schedule-add-add-body-modal');

@autoinject()
export class AddFooter {
  @computedFrom('isDeleting')
  get deleteBtnState() {
    if (this.isDeleting) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom(
    'model.isDirty',
    'scheduleEntryService.hasConflicts',
    'scheduleEntryService.conflictChosenMethod',
    'scheduleEntryService.isSaving',
    'scheduleEntryService.saveEnabled',
  )
  get saveButtonState() {
    const {hasConflicts, isSaving, saveEnabled, conflictChosenMethod} = this.scheduleEntryService;
    const overwriteConflicts = hasConflicts && conflictChosenMethod != null;
    const {isDirty} = this.model;

    if (isSaving || !saveEnabled || !isDirty) {
      return 'disabled';
    }

    if (overwriteConflicts || !hasConflicts) {
      return '';
    }

    return 'disabled';
  }

  @computedFrom('saveButtonState', 'model.isNew')
  get saveNextButtonState() {
    if (!this.model.isNew) {
      return 'hidden';
    }

    return this.saveButtonState;
  }

  public isDeleting: boolean = false;
  public model: ITrackedScheduledEntry = null;
  public overwriteSchedule: boolean = false;
  private controller;
  private channelId: string = null;

  constructor(public scheduleEntryService: ScheduleEntryService, private notificationService: CToastsService) {}

  public activate(model) {
    const {controller, shared} = model;
    const {entry, channelId} = shared;

    this.controller = controller;
    this.model = entry;
    this.channelId = channelId;
  }

  public async deleteScheduleEntry() {
    if (this.isDeleting) {
      return;
    }

    try {
      this.isDeleting = true;

      await this.scheduleEntryService.delete(this.model, this.channelId);

      this.notificationService.success('Deleted');
      this.controller.close();
    } catch (ex) {
      log.error(ex);
      this.notificationService.error(`Failed: ${ex.message}`);
    } finally {
      this.isDeleting = false;
    }
  }

  public async saveAndAddNextModel() {
    return this.scheduleEntryService.saveModal(false);
  }
}
