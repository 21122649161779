import {bindable, computedFrom} from 'aurelia-framework';

import {BaseEventSingle} from './base';

export class Slate extends BaseEventSingle {
  @bindable
  libraries;

  // this gets called when the event-config tab is selected.
  // it sets up our slateInVodChecked string value (either true or false)
  async activate(params) {
    await super.activate(params);
  }

  async attached() {
    try {
      this.libraries = await this.liveEvents.getLibraries();
    } catch (err) {
      // do nothing
    }
  }

  deactivate() {
    super.deactivate();

    if (this.liveEvents.isLeavingNewRoute) {
      return;
    }

    const current = this.router.currentInstruction;
    const tabModel = this.liveEvents.cleanEvent(this.model);

    this.liveEvents.validateEvent(tabModel, current.config.name);
  }

  @computedFrom('liveEvents.origEvent.mid_slate_library', 'libraries')
  get displayMidSlateLibrary() {
    const libraryID = this.liveEvents.origEvent.mid_slate_library;
    const lib =
      libraryID && libraryID !== '' && Array.isArray(this.libraries) && this.libraries.length
        ? this.libraries.find(o => o.id === libraryID).desc
        : this.liveEvents.accountDefault;

    return lib;
  }

  // this determines our state for our radio buttons
  // if the mid slate is SHARED, then the radio buttons will be disabled
  // if the mid slate is the customers library, then the radio buttons are enabled
  @computedFrom('model.mid_slate_library', 'model.mid_slate_shared', 'libraries')
  get slateInVodState() {
    const libraryID = this.model.mid_slate_library;
    let isShared;
    if (libraryID && libraryID !== '' && Array.isArray(this.libraries) && this.libraries.length) {
      isShared = this.libraries.find(o => o.id === libraryID && o.owner_name !== undefined);
    } else {
      // Need to get this from the backend to find out if the default mid slate library is shared
      // there is no way of knowing what the actual default mid slate library is
      isShared = this.model.mid_slate_shared;
    }
    return isShared ? 'disabled' : '';
  }
}
