import {autoinject, computedFrom} from 'aurelia-framework';
import {BaseAssetSingle} from './base';

export enum ProgramType {
  MOVIE = 'movie',
  EPISODE = 'episode',
  NONE = '',
}

interface Season {
  number: number;
  episode_number: number;
}

interface Series {
  title: string;
  description: string;
  season: Season;
}

interface ProgramData {
  title?: string;
  type?: ProgramType;
  series?: Series;
}

export enum ProgramDataKeys {
  TITLE = '_upl.programdata.title',
  TYPE = '_upl.programdata.type',
  SERIES_TITLE = '_upl.programdata.series.title',
  SERIES_DESCRIPTION = '_upl.programdata.series.description',
  SERIES_SEASON_NUMBER = '_upl.programdata.series.season.number',
  SERIES_SEASON_EPISODE_NUMBER = '_upl.programdata.series.season.episode_number',
}

@autoinject()
export class AssetEPG extends BaseAssetSingle {
  public programData: ProgramData | undefined;
  public tabFields: string[] = ['meta'];

  private cleanProgramData: ProgramData | undefined;

  private updateMetaData(key: ProgramDataKeys, value: string) {
    if (!this.model || !this.model.meta) {
      return;
    }

    const data = this.model.meta.find(meta => meta.key === (key as string));
    if (data) {
      data.value = value;
    } else {
      this.model.meta.unshift({
        key,
        value,
      });
    }
  }

  private updateProgramData() {
    if (!this.model || !this.model.meta) {
      return;
    }

    const getMetaValue = (key: string): string | undefined => {
      const data = this.model.meta.find(meta => meta.key === key);
      return data ? data.value : undefined;
    };

    this.programData = {
      title: getMetaValue(ProgramDataKeys.TITLE) || '',
      type: (getMetaValue(ProgramDataKeys.TYPE) as ProgramType) || ProgramType.NONE,
      series: {
        title: getMetaValue(ProgramDataKeys.SERIES_TITLE) || '',
        description: getMetaValue(ProgramDataKeys.SERIES_DESCRIPTION) || '',
        season: {
          number: parseInt(getMetaValue(ProgramDataKeys.SERIES_SEASON_NUMBER) || '0', 10),
          episode_number: parseInt(getMetaValue(ProgramDataKeys.SERIES_SEASON_EPISODE_NUMBER) || '0', 10),
        },
      },
    };

    this.cleanProgramData = _.cloneDeep(this.programData);
  }

  public async save() {
    const res = await super.save();
    if (res) {
      this.updateProgramData();
    }
  }

  public async activate(params: any) {
    await super.activate(params);
    this.updateProgramData();
  }

  @computedFrom('programData.title')
  public get title(): string {
    if (!this.programData) {
      return '';
    }

    return this.programData.title;
  }

  public set title(value: string) {
    this.updateMetaData(ProgramDataKeys.TITLE, value);
    this.programData!.title = value;
  }

  @computedFrom('progranData.type')
  public get type(): ProgramType {
    if (!this.programData) {
      return ProgramType.NONE;
    }
    return this.programData.type;
  }

  public set type(value: ProgramType) {
    this.programData!.type = value;
    this.updateMetaData(ProgramDataKeys.TYPE, value);
  }

  @computedFrom('programData.type')
  public get isEpisode(): boolean {
    return this.programData && this.programData.type === ProgramType.EPISODE;
  }

  @computedFrom('programData.series.title')
  public get seriesTitle(): string {
    if (!this.programData) {
      return '';
    }
    return this.programData.series.title || '';
  }

  public set seriesTitle(value: string) {
    this.programData!.series.title = value;
    this.updateMetaData(ProgramDataKeys.SERIES_TITLE, value);
  }

  @computedFrom('programData.series.description')
  public get seriesDescription(): string {
    if (!this.programData) {
      return '';
    }

    return this.programData.series.description || '';
  }

  public set seriesDescription(value: string) {
    this.programData!.series.description = value;
    this.updateMetaData(ProgramDataKeys.SERIES_DESCRIPTION, value);
  }

  @computedFrom('programData.series.season.number')
  public get seriesSeasonNumber(): number {
    if (!this.programData) {
      return 0;
    }

    return this.programData.series.season.number;
  }

  public set seriesSeasonNumber(value: number) {
    this.programData!.series.season.number = value;
    this.updateMetaData(ProgramDataKeys.SERIES_SEASON_NUMBER, value.toString());
  }

  @computedFrom('programData.series.season.episode_number')
  public get seriesEpisodeNumber(): number {
    if (!this.programData) {
      return 0;
    }

    return this.programData.series.season.episode_number;
  }

  public set seriesEpisodeNumber(value: number) {
    this.programData!.series.season.episode_number = value;
    this.updateMetaData(ProgramDataKeys.SERIES_SEASON_EPISODE_NUMBER, value.toString());
  }

  @computedFrom(
    'programData.title',
    'programData.type',
    'programData.series.title',
    'programData.series.description',
    'programData.series.season.number',
    'programData.series.season.episode_number',
  )
  public get tabDirty(): boolean {
    if (!this.programData || !this.cleanProgramData) {
      return false;
    }

    const isDirty = !_.isEqual(this.programData, this.cleanProgramData);
    this.fields.meta.isDirty = this.fields.meta.isDirty || isDirty;
    return isDirty;
  }
}
