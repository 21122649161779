import {autoinject, computedFrom} from 'aurelia-framework';
import {TextFile} from 'resources/file/text-file';
import * as dragula from 'dragula/dragula';
import {HostedSlicersSingle} from './index';
import {convertConfigurationStringToArray} from '../../models/slicer';

export interface Config {
  key: string;
  value: string;
  error?: string;
}

@autoinject()
export class HostedSlicersSingleAdvanced {
  public files: any;
  public routeName: string;
  public id: string;
  public fileInput: any;

  @computedFrom('parentView.slicer.configuration')
  get configs() {
    return this.parentView.slicer.configuration;
  }

  constructor(public parentView: HostedSlicersSingle) {
    _.defer(() => this.setupDragDrop());
  }

  public async actionExport() {
    const fileData = encodeURIComponent(
      this.parentView.slicer.configuration.map(c => `${c.key}:${c.value}`).join('\n'),
    );
    const file = new File([fileData], 'configuration.txt', {type: 'text/plain'});
    await TextFile.save(file);
  }

  public actionImport() {
    const file: File = this.files[0];
    const reader: FileReader = new FileReader();
    reader.onload = evt => {
      const contents: any = evt.target.result;
      this.parentView.slicer.configuration = convertConfigurationStringToArray(contents);
    };
    reader.readAsText(file);
    this.fileInput.value = '';
  }

  public addConfig() {
    this.parentView.slicer.configuration = _.concat([{key: '', value: ''}], this.parentView.slicer.configuration);
    this.configurationChangeDetected();
  }

  public removeConfig(index) {
    _.pullAt(this.parentView.slicer.configuration, index);
    this.configurationChangeDetected();
  }

  public configChanged(key, value, index) {
    this.parentView.slicer.configuration[index][key] = value;
    this.parentView.slicer.configuration = _.cloneDeep(this.parentView.slicer.configuration);
  }

  setupDragDrop() {
    dragula(
      [
        document.getElementById('configurations'),
      ],
      {
        moves: (_el, _source, handle) => handle.classList.contains('js--drag'),
      },
    ).on('drop', (_el, _target) => {
      [].forEach.call(_target.children, (li, index) => {
        if (this.parentView.slicer.configuration[index].key !== li.getAttribute('data-key')) {
          this.parentView.slicer.configuration[index].key = li.getAttribute('data-key');
        }
        if (this.parentView.slicer.configuration[index].value !== li.getAttribute('data-value')) {
          this.parentView.slicer.configuration[index].value = li.getAttribute('data-value');
        }
      });
      this.configurationChangeDetected();
    });
  }

  private configurationChangeDetected() {
    const confs = _.cloneDeep(this.parentView.slicer.configuration);
    this.parentView.slicer.configuration = null;
    _.defer(() => {
      this.parentView.slicer.configuration = _.cloneDeep(confs);
    });
    this.parentView.slicer.configuration = _.cloneDeep(this.parentView.slicer.configuration);
  }
}
