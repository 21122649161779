/**
 * For usage an example see:
 * http://cms.downlynk.localhost:8000/static/cms2/dummy.html#/authorization-examples
 */
import {inject} from 'aurelia-dependency-injection';
import {LogManager} from 'aurelia-framework';
import {BoundViewFactory, templateController, ViewSlot} from 'aurelia-templating';
import {ACCESS_TYPE, Authorization} from './index';
import {Entitlement, Permission} from './models';

const log = LogManager.getLogger('ACL');

enum STATE {
    DISABLED = 'disabled',
    ENABLED = 'enabled',
    HIDDEN = 'hidden',
}

class AclBase {
    public static inject = [Element, Authorization];
    protected element;
    protected inverse: boolean = false;
    protected isCustomComponent: boolean = false;
    protected isContainerLess: boolean = false;
    protected value;
    protected elementName: string = '';

    constructor(public elem, public auth: Authorization) {
        this.element = this.elem;
        if (!_.isEmpty(_.get(this.elem, 'au.controller'))) {
            this.isCustomComponent = true;
            const {behavior} = this.elem.au.controller;
            this.elementName = behavior.elementName;
            this.isContainerLess = behavior.containerless;
            if (this.isContainerLess) {
                this.element = this.elem._element;
            }
        }
    }

    public valueChanged() {
        this.inverse = this.element.getAttribute('acl-inverse') !== null;
    }

    protected parseValue<T>(val: any): T[] {
        if (Array.isArray(this.value)) {
            return val;
        }
        return val.split(',').map(str => _.trim(str));
    }

    protected enforceValue<T>(
        enforcer: (...val: T[]) => ACCESS_TYPE,
        noValueErrorMessage: string = 'A valid value is required.',
        valueRequired: boolean = true,
    ) {
        if (!this.value && valueRequired) {
            log.error(noValueErrorMessage);
            this.hideOrDisable();
            return;
        }
        const accessType = valueRequired ? enforcer(...this.parseValue<T>(this.value)) : enforcer();
        const hasAccess = accessType === ACCESS_TYPE.ALLOWED || accessType === ACCESS_TYPE.READ_ONLY;
        const readOnly = accessType === ACCESS_TYPE.READ_ONLY;

        if ((hasAccess && this.inverse) || (!hasAccess && !this.inverse)) {
            this.hideOrDisable();
        } else if (readOnly && !this.inverse) {
            this.makeElementVisible();
            this.hideOrDisable(true); // disable = true
        } else {
            this.makeElementVisible();
            this.makeElementEnabled();
        }
    }

    protected makeElementVisible() {
        if (this.isCustomComponent) {
            // make visible in disabled state
            this.setState(STATE.DISABLED);
        } else if (this.element.style.display === 'none') {
            this.element.style.display = '';
        }
    }

    protected makeElementEnabled() {
        if (this.isCustomComponent) {
            this.setState(STATE.ENABLED);
        } else {
            this.element.removeAttribute('disabled', 'disabled');
            this.element.classList.remove('acl-disabled');
        }
    }

    protected hideOrDisable(disable?) {
        // if acl-disable="false", then even for read-only entitlement component will be hidden.
        const aclDisableValue = this.element.getAttribute('acl-disable');
        let preferDisable = aclDisableValue !== null && aclDisableValue !== 'false';
        preferDisable = aclDisableValue === 'false' ? false : disable || preferDisable;

        const className = this.element.getAttribute('acl-add-class');
        if (this.isCustomComponent) {
            this.handleCustomElement(preferDisable);
        } else {
            this.handleNativeElement(preferDisable, className);
        }
    }

    protected handleCustomElement(preferDisable) {
        if (preferDisable) {
            this.setState(STATE.DISABLED);
        } else {
            this.setState(STATE.HIDDEN);
        }
    }

    protected handleNativeElement(preferDisable, className) {
        if (className) {
            className.split(' ').forEach(val => {
                this.element.classList.add(val);
            });
        } else if (preferDisable) {
            this.element.setAttribute('disabled', 'disabled');
            this.element.classList.add('acl-disabled');
        } else {
            this.element.style.display = 'none';
        }
    }

    protected setState(state: STATE) {
        try {
            const vm = this.elem.au.controller.viewModel;
            if (typeof vm.authState === 'undefined') {
                throw new Error(
                    `"${this.elementName}" component must use "@authState" decorator in ViewModel ` +
                        'in order to use acl.',
                );
            }
            vm.authState = state;
        } catch (e) {
            log.error(e);
        }
    }
}

@templateController
@inject(Authorization, BoundViewFactory, ViewSlot)
export class AclRemoveCustomAttribute extends AclBase {
    public viewFactory: BoundViewFactory;
    public viewSlot: ViewSlot;
    public view = null;
    public bindingContext = null;
    public overrideContext = null;
    public showing = false;

    constructor(auth: Authorization, viewFactory: BoundViewFactory, viewSlot: ViewSlot) {
        super(Element, auth);
        this.viewFactory = viewFactory;
        this.viewSlot = viewSlot;
    }

    public bind(bindingContext, overrideContext) {
        this.bindingContext = bindingContext;
        this.overrideContext = overrideContext;
        this.inverse = !!(Object as any)
            .values(_.get(this.viewFactory, 'viewFactory.template.childNodes[0].attributes', {}))
            .find(a => a.name === 'acl-inverse');
        let accessType = ACCESS_TYPE.NOT_ALLOWED;
        if (this.value) {
            accessType = this.auth.getAccessTypeForAnyEntitlement(this.value);
        } else {
            accessType = this.auth.getAccessTypeForAnyPermission(Permission.READ, Permission.WRITE);
        }
        const hasAccess = accessType === ACCESS_TYPE.ALLOWED || accessType === ACCESS_TYPE.READ_ONLY;

        if ((hasAccess && !this.inverse) || (!hasAccess && this.inverse)) {
            this.addView();
        }
    }

    public unbind() {
        if (this.view === null) {
            return;
        }
        this.view.unbind();

        if (!this.viewFactory.isCaching) {
            return;
        }

        if (this.showing) {
            this.showing = false;
            this.viewSlot.remove(this.view, /* returnToCache: */ true, /* skipAnimation: */ true);
        } else {
            this.view.returnToCache();
        }

        this.view = null;
    }

    protected addView() {
        if (this.showing) {
            if (!this.view.isBound) {
                this.view.bind(this.bindingContext, this.overrideContext);
            }
            return;
        }

        if (this.view === null) {
            this.view = this.viewFactory.create();
        }

        if (!this.view.isBound) {
            this.view.bind(this.bindingContext, this.overrideContext);
        }

        this.showing = true;
        return this.viewSlot.add(this.view);
    }
}

export class AclRestrictedCustomAttribute extends AclBase {
    protected value: string | boolean;

    public valueChanged() {
        super.valueChanged();
        if (this.value === 'false' || this.value === false) {
            return;
        }
        this.enforceValue(this.auth.getAccessTypeForCurrentRoute, null, false);
    }
}

export class AclEntitlementCustomAttribute extends AclBase {
    public valueChanged() {
        super.valueChanged();
        this.enforceValue<Entitlement>(
            this.auth.getAccessTypeForAnyEntitlement,
            '[ACL Any Entitlement] requires a valid entitlement.',
        );
    }
}

export class AclAllEntitlementsCustomAttribute extends AclBase {
    public valueChanged() {
        super.valueChanged();
        this.enforceValue<Entitlement>(
            this.auth.getAccessTypeForAllEntitlements,
            '[ACL All Entitlements] requires a valid entitlement.',
        );
    }
}

export class AclPermissionCustomAttribute extends AclBase {
    public valueChanged() {
        super.valueChanged();
        this.enforceValue<Permission>(
            this.auth.getAccessTypeForAnyPermission,
            '[ACL Any Permission] requires a valid permission.',
        );
    }
}

export class AclAllPermissionsCustomAttribute extends AclBase {
    public valueChanged() {
        super.valueChanged();
        this.enforceValue<Permission>(
            this.auth.getAccessTypeForAllPermissions,
            '[ACL All Permissions] requires a valid permission.',
        );
    }
}

export class AclModuleAccessCustomAttribute extends AclBase {
    public valueChanged() {
        super.valueChanged();
        this.enforceValue<string>(
            this.auth.getAccessTypeForAnyModule,
            '[ACL Any Module Access] requires a valid module name.',
        );
    }
}

export class AclAllModulesAccessCustomAttribute extends AclBase {
    public valueChanged() {
        super.valueChanged();
        this.enforceValue<string>(
            this.auth.getAccessTypeForAllModules,
            '[ACL All Modules Access] requires a valid module name.',
        );
    }
}
