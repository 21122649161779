import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterConfiguration, RouterEvent} from 'aurelia-router';
import {BetaMode} from 'services/beta-mode';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {SlicerService} from '../services/csl-slicer-service';
import {SubnavList} from '../components/subnav-menu';

// eslint-disable-next-line no-shadow
export enum RouteName {
  HostedSlicerList = 'hostedSlicerList',
  HostedSlicerSingle = 'hostedSlicerSingle',
}

@autoinject()
export class CloudSlicers {
  protected routerEventComplete: Subscription;
  protected navPage: SubnavList = {
    navs: [],
    title: 'Cloud Slicers List',
  };

  protected routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: RouteName.HostedSlicerList,
      nav: false,
      route: '',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: RouteName.HostedSlicerSingle,
      nav: false,
      route: ':id',
    },
  ];

  constructor(
    protected router: Router,
    public betaMode: BetaMode,
    protected service: SlicerService,
    protected eventAggregator: EventAggregator,
  ) {
    if (!this.isAllowed()) {
      this.router.navigate('/');
    }
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }

  public activate() {
    this.routerEventComplete = this.eventAggregator.subscribe(
      RouterEvent.Complete,
      this.onRouterEventComplete.bind(this),
    );
    this.load();
  }

  public async load() {
    this.navPage.isLoading = true;
    this.navPage.navs = (await this.service.getSlicers()).items.map(item => ({
      title: item.id,
      href: `#/slicers/cloud-slicers/${item.id}`,
      active: item.id === this.router.currentInstruction.params.id,
    }));
    this.navPage.isLoading = false;
  }

  protected onRouterEventComplete() {
    this.navPage.navs.forEach(item => {
      item.active = item.title === this.router.currentInstruction.params.id;
    });
  }

  public isAllowed(): boolean {
    return this.betaMode.hasScope('csl_v2');
  }
}
