import {IngestPointTableRow} from './table-models';

export class Config {
    public errors = new ConfigErrors();
    public input = new ConfigInput();
    public options = new ConfigOptions();
    public state = new ConfigState();
}

export class ConfigErrors {
    public description?: string;
    public hostingLocation?: string;
    public name?: string;
    public streamProtocol?: string;
}

class ConfigInput {
    public description: string;
    public hostingLocation: string;
    public name: string;
    public search: string;
    public selection = {} as IngestPointTableRow;
    public selectionReset: () => void;
    public streamProtocol: string;
}

class ConfigOptions {
    public hostingLocation: ISelectableOption[];
    public streamProtocol: ISelectableOption[];
    // todo: maybe add options for filtering by protocol, region, and/or status?
}

class ConfigState {
    public error: string;
    public isLoading: boolean;
    public isProcessing: boolean;
}

interface ISelectableOption {
    text: string;
    value: string;
}
