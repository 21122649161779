import {autoinject, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {AssetService} from 'services/asset';

const log = LogManager.getLogger('v-slate-picker/modals/body');

@autoinject()
export class Body {
    public libraries = [];
    public totalAssets = 0;
    public nextToken = 0;
    public tokensFetched = [];
    public loading: boolean = true;
    public isLoadingMore: boolean = false;
    public selectedLibrary = '';
    public streamUrl = '';
    public availableSlateCols: any;
    public slateList: any;
    public slateListActions: any;
    public controller: any;
    public model: any;

    constructor(private assetService: AssetService) {
        this.availableSlateCols = [
            {
                colClass: 't105',
                colHeadName: 'poster_url',
                colHeadValue: 'Poster',
                view: PLATFORM.moduleName(
                    '@bindable-ui/bindable/components/tables/td-contents/c-td-image/c-td-image.html',
                ),
                viewModel: PLATFORM.moduleName(
                    '@bindable-ui/bindable/components/tables/td-contents/c-td-image/c-td-image',
                ),
            },
            {
                colHeadName: 'title',
                colHeadValue: 'Title',
                sort: true,
            },
            {
                colClass: 't120',
                colHeadName: 'duration',
                colHeadValue: 'Duration',
                sort: true,
            },
        ];

        this.slateList = null;

        this.slateListActions = {
            onScrollBottom: () => {
                this.loadMore();
            },
            rowClick: async row => {
                row._class = 'active';
                this.model.shared.active = row;
                this.slateList.forEach(r => {
                    if (r.id !== row.id) {
                        r._class = '';
                    }
                });
                this.streamUrl = await this.assetService.getPlayerUrl(row.id);
            },
        };
    }

    public activate(model) {
        this.model = model;
        this.controller = model.controller;
        this.libraries = model.body.libraries;
        let selectedLibrary = '';
        _.forEach(this.libraries, l => {
            if (l.selected) {
                selectedLibrary = l.value;
                return false;
            }
        });
        this.libraryChanged(selectedLibrary);
    }

    public search(searchText) {
        this.loading = true;
        this.slateList = [];
        this.streamUrl = '';
        this.model.shared.active = {};
        this.totalAssets = 0;
        this.nextToken = 0;
        this.tokensFetched = [];
        this.fetchAssets(searchText);
    }

    public async fetchAssets(search: string = '') {
        if (!this.tokensFetched.includes(this.nextToken)) {
            // tokensFetched stores all the next_tokens so we don't fetch duplicates
            this.tokensFetched.push(this.nextToken);
            try {
                const resp = await this.assetService.find({
                    search,
                    library: this.selectedLibrary,
                    next_token: this.nextToken,
                    sort_col: 'created',
                    sort_dir: 'desc',
                });
                const {assets} = resp;
                assets.forEach(async asset => {
                    asset.poster_url_alt = `Poster for ${asset.title}`;
                    if (asset.id === this.model.body.slateId) {
                        asset._class = 'active';
                        this.model.shared.active = asset;
                    } else {
                        asset._class = '';
                    }
                    this.slateList.push(asset);
                    if (asset._class === 'active') {
                        this.streamUrl = await this.assetService.getPlayerUrl(asset.id);
                    }
                });
                /* istanbul ignore else */
                if (resp.total) {
                    this.totalAssets = resp.total;
                }
                this.nextToken = resp.next_token;
            } catch (err) {
                log.error(err);
            } finally {
                this.loading = false;
                this.isLoadingMore = false;
            }
        }
    }

    private libraryChanged(selectedLibrary) {
        this.loading = true;
        this.selectedLibrary = selectedLibrary;
        this.slateList = [];
        this.streamUrl = '';
        this.model.shared.active = {};
        this.totalAssets = 0;
        this.nextToken = 0;
        this.tokensFetched = [];
        this.fetchAssets();
    }

    private loadMore() {
        /* istanbul ignore else */
        if (this.totalAssets > this.slateList.length) {
            this.isLoadingMore = true;
            this.fetchAssets();
        }
    }
}
