import {bindable, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {computedFrom} from 'aurelia-binding';
import {SessionService} from 'services/session';
import {HttpClient} from 'aurelia-fetch-client';
import {AccountData} from './switch-account/services/account-data';

import styles from './index.css';

@inject(Router, SessionService, AccountData, HttpClient)
export class UserMenu {
  @bindable
  notificationCount;

  constructor(router, sessionService, accountData, httpClient) {
    this.router = router;
    this.session = sessionService;
    this.accountData = accountData;
    this.styles = styles;
    this.httpClient = httpClient;
    this.newsItems = [];
    this.newsLoading = true;
    this.newsError = false;
  }

  attached() {
    // Listening for click events inside news posts to force embedded links to open in a new window
    this.newsDrawer.addEventListener(
      'click',
      e => {
        if (e.target.nodeName.toUpperCase() === 'A' && e.target.href) {
          e.target.target = '_blank';
        }
      },
      true,
    );
  }

  detached() {
    this.newsDrawer.removeEventListener('click');
  }

  // Open switch account dialog
  switchAccount() {
    this.switchAccountDialog.show();
  }

  /**
   * This allows the user to switch back to the original owner of the account.
   */
  cancelSwitchAccount() {
    window.localStorage.removeItem('LiveEvents');
    this.accountData.doSwitchUserP(this.session.sessionInfo.origUsername).then(response => {
      if (response) {
        this.router.navigateToRoute(this.router.currentInstruction.config.name);
        window.location.reload();
      }
    });
  }

  /**
   * Lets us know if a user has switched accounts
   */
  @computedFrom('session.sessionInfo.origUsername', 'session.username')
  get isOtherUser() {
    return this.session.username !== this.session.sessionInfo.origUsername;
  }

  openNotifications() {
    this.notificationsDrawer.show();
  }

  resetNewsViewed() {
    // Only make the call if we think we have unviewed news
    if (this.session.sessionInfo.news_unviewed) {
      this.httpClient.fetch('/session/news/markviewed/', {
        method: 'post',
        body: {},
      });
      // Just pretend the above request is successful so the notification goes away
      this.session.sessionInfo.news_unviewed = 0;
    }
    return true;
  }

  getNewsFeed() {
    this.newsDrawer.show();

    return this.httpClient
      .fetch('/session/news/loadmore/', {
        method: 'post',
      })
      .then(resp => {
        if (resp.ok) {
          resp.json().then(data => {
            this.newsItems = data.items;
            this.newsLoading = false;
          });
        } else {
          this.newsError = true;
        }
      })
      .catch(() => {
        this.newsError = true;
      });
  }
}
