import {ProfileResponseItems} from 'apps/cms/routes/slicers/models/profile';
import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {ProfileService} from '../../../services/profile-service';
import {ProfileDefault} from '../../single/models';
import {ProfileUsageModal} from './modal';
import {Model} from './models';
import {ProfileUsageTable} from './table';
import {ProfileTableRow} from './table-models';

@autoinject
export class ProfileModalBody extends ProfileUsageModal {
    public table: ProfileUsageTable;
    protected service: ProfileService;

    constructor(protected acceo: Acceo, protected sessionService: SessionService) {
        super();
        this.service = new ProfileService(acceo);
    }

    public activate(model: Model) {
        super.activate(model);
        if (this.sessionService.hasHostedSlicersAccess) {
            model.shared.state.canExportCSV = true;
            model.shared.state.canViewDynamicCols = true;
        }
        this.load();
    }

    protected async load() {
        this.config.state.isLoading = true;
        try {
            this.table = new ProfileUsageTable(this.config.input.type);
            this.table.rows = await this.requestRead(this.config.input.type);
            if (this.config.state.canViewDynamicCols) {
                this.table.addColsFromRows(['@id', '@type', 'lmsig'], false, true);
            }
        } catch (reason) {
            this.config.state.error = reason;
        }
        this.config.state.isLoading = false;
    }

    private async requestRead(type: ProfileDefault): Promise<ProfileTableRow[]> {
        let response: ProfileResponseItems;
        if (type === ProfileDefault.LIVE) {
            response = await this.service.getLiveSlicers(this.config.input.profileId);
        } else if (type === ProfileDefault.VOD) {
            response = await this.service.getVodAssets(this.config.input.profileId);
        } else {
            response = {items: []} as ProfileResponseItems;
        }
        return response.items.map(item => item as ProfileTableRow);
    }
}
