import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {
    ContentFields,
    ContentFieldsRepresentation,
    FieldRepresentation,
    PlayBackFields,
    PlayBackFieldsRepresentation,
    PlayerFields,
    PlayerFieldsRepresentation,
    RuleType,
} from '../models/fields-models';
import {MultiCdnSettingsConfig, RuleSet} from '../models/multi-cdn-settings-config';

@autoinject()
export class RuleSetContent {
    @bindable
    public multiCdnSettings: MultiCdnSettingsConfig;

    @bindable
    public currentRuleSet: RuleSet;

    @bindable
    public callbacks;

    @bindable
    public validationErrors: ValidationError[];

    public scenariotabs;
    public contentFields: FieldRepresentation[];
    public contentRuleType: RuleType;
    public playbackFields: FieldRepresentation[];
    public playbackRuleType: RuleType;
    public playerFields: FieldRepresentation[];
    public playerRuleType: RuleType;
    public identifier: string = 'scenarios';

    constructor() {
        this.scenariotabs = [
            {id: 'scenarios-rules-content', label: 'Content Rules ', selected: true},
            {id: 'scenarios-rules-playback', label: 'Playback Rules'},
            {id: 'scenarios-rules-player', label: 'Client Rules'},
            {id: 'scenarios-rules-usage', label: 'Usage Rules'},
        ];

        this.contentFields = [];
        for (const f of Object.values(ContentFields)) {
            const newRep = new FieldRepresentation();
            newRep.value = f;
            newRep.representation = ContentFieldsRepresentation.map.get(f);
            this.contentFields.push(newRep);
        }

        this.contentRuleType = RuleType.Content;

        this.playbackFields = [];
        for (const f of Object.values(PlayBackFields)) {
            const newRep = new FieldRepresentation();
            newRep.value = f;
            newRep.representation = PlayBackFieldsRepresentation.map.get(f);
            this.playbackFields.push(newRep);
        }

        this.playbackRuleType = RuleType.Playback;
        this.playerFields = [];

        for (const f of Object.values(PlayerFields)) {
            const newRep = new FieldRepresentation();
            newRep.value = f;
            newRep.representation = PlayerFieldsRepresentation.map.get(f);
            this.playerFields.push(newRep);
        }

        this.playerRuleType = RuleType.Player;
    }
}
