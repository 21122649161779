import {bindable, inject} from 'aurelia-framework';
import * as dragula from 'dragula';

import {OwnerMetaContext, SessionService} from 'services/session';
import {LiveEventsService} from '../services/live-events';

/*
 * @param params {Object} - Object that has different filter params
 * @param updateFilter {Function} - Function in parent VM that will update list from params selected
 */

@inject(SessionService, LiveEventsService)
export class EventsFilterSaved {
    @bindable
    params;

    @bindable
    updateFilter;

    constructor(sessionService, liveEventsService) {
        this.session = sessionService;
        this.liveEvents = liveEventsService;
        this.filters = [];

        this.globalFilters = [
            {
                name: 'Today',
                startRange: 'Today',
            },
        ];
    }

    attached() {
        this.getSavedFilters();
    }

    detached() {
        if (this.drake) {
            this.drake.destroy();
        }
    }

    /**
     * Get list of saved filters
     */
    getSavedFilters() {
        this.session
            .getOwnerMeta('live-events', 'saved-filters')
            .then(filters => {
                if (Array.isArray(filters)) {
                    this.filters = filters;
                } else {
                    this.filters = [];
                }

                this.setupDragDrop();
            })
            .catch(() => {
                this.filters = [];
            });
    }

    /**
     * Sets up dragula
     *
     * IGNORING TEST COVERAGE UNTIL
     * I CAN FIND A BETTER WAY TO MOCK
     * DRAGULA
     *
     */
    /* istanbul ignore next */
    setupDragDrop() {
        if (this.drake) {
            this.drake.destroy();
        }

        this.drake = dragula([document.getElementById('list-filters')], {
            moves: (el, source, handle) => handle.classList.contains('handle'),
        });

        this.drake.on('drop', (el, target, source, sibling) => {
            const fromIndex = _.findIndex(this.filters, filter => filter.name === el.getAttribute('data-name'));
            const toIndex = _.findIndex(this.filters, filter => filter.name === sibling.getAttribute('data-name'));

            if (fromIndex >= 0 && toIndex >= 0) {
                const movedFilter = this.filters[fromIndex];
                this.filters.splice(fromIndex, 1);
                this.filters.splice(toIndex, 0, movedFilter);

                this.session.setOwnerMeta(OwnerMetaContext.LIVE_EVENTS, {'saved-filters': this.filters});
            }
        });
    }

    /**
     * Get a clean slate for the filter
     */
    getCleanParams() {
        const currentParams = this.params;

        const params = this.liveEvents.getSanitizedBlankFilterParams();

        if (currentParams.calendar) {
            params.r_start = currentParams.r_start;
            params.r_stop = currentParams.r_stop;
            params.calendar = currentParams.calendar;
            params.limit = currentParams.limit;
            params.include = currentParams.include;
        }

        if (currentParams.search) {
            params.search = currentParams.search;
        }

        return params;
    }

    /**
     * Clear the filter.
     */
    clearFilter() {
        const params = this.getCleanParams();
        this.updateFilter({params});
    }

    /**
     * Verify filter name hasn't been taken yet.
     * @param name {String} - Filter name
     */
    canUseName(name) {
        const isNameTaken =
            _.find(this.filters, filter => filter.name === name) ||
            _.find(this.globalFilters, filter => filter.name === name);

        if (isNameTaken) {
            return false;
        }

        return true;
    }

    /**
     * Apply a saved filter
     * @param index {Integer} - Index of the filter being applied
     */
    applyFilter(index, isSystem) {
        if (isSystem && index > -1 && this.globalFilters[index]) {
            const cleanParams = this.getCleanParams();
            const params = Object.assign(cleanParams, this.globalFilters[index]);
            this.updateFilter({params});
        } else if (index > -1 && this.filters[index]) {
            const cleanParams = this.getCleanParams();
            const params = Object.assign(cleanParams, this.filters[index]);
            this.updateFilter({params});
        }
    }

    /**
     * Add a new filter to the list
     * @param params {Object} - A object used to filter the live event list
     */
    saveFilter(params) {
        if (params.calendar) {
            delete params.calendar;
            delete params.r_start;
            delete params.r_stop;
        }

        delete params.order;
        delete params.limit;
        delete params.skip;
        delete params.include;
        delete params.since;
        delete params.deleted;

        const sanitizedParams = this.liveEvents.sanitizeSavedParams(params);
        this.filters.push(sanitizedParams);
        this.session.setOwnerMeta(OwnerMetaContext.LIVE_EVENTS, {'saved-filters': this.filters});
        this.setupDragDrop();
    }

    /**
     * Remove a filter from the list
     * @param index {Integer} - Index of the filter being removed
     */
    removeFilter(index) {
        if (index > -1 && this.filters[index]) {
            this.filters.splice(index, 1);
            this.session.setOwnerMeta(OwnerMetaContext.LIVE_EVENTS, {'saved-filters': this.filters});
            this.setupDragDrop();
        }
    }
}
