import {CToastsService} from '@bindable-ui/bindable';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, BindingEngine, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {orderBy} from 'lodash';
import dragula from 'dragula';
import {LiveChannelsService} from '../../../live-channels/channels/services/live-channels';
import {FailoverGroup, FailoverGroupSlicer} from '../../models/failover-group';
import {FailoverGroupService} from '../../services/failover-group-service';
import {SlicerService} from '../../services/slicer-service';
import {FailoverGroupSlicerService} from '../../services/failover-group-slicer-service';
import {BaseGroup} from './base';

@autoinject()
export class Configuration extends BaseGroup {
  @bindable
  public group: FailoverGroup;

  public failoverGroupSlicers: FailoverGroupSlicer[];
  private observers = [];

  // Slicer Control Properties
  public addedSlicers: any[] = [];
  public allSlicers: any[] = [];
  public slicersToBeAdded: string[] = [];
  public addSlicersDialog: any;
  public selectedSlicers: string[] = [];
  public searchSlicersQuery: string = null;
  public isLoadingSlicers: boolean = false;
  public isLoadingMoreSlicers: boolean = false;
  public showUnavailableSlicers: boolean = false;
  private drake: dragula.Drake;
  public slicersTable: any;

  // Channel Control Properties
  public unavailableChannels: string[];
  public allChannels: any[];
  public addedChannels: any[];
  public selectedChannels: string[] = [];
  public channelsToBeAdded: string[] = [];
  public addChannelsDialog: any;
  public searchChannelsQuery: string = null;
  public isLoadingChannels: boolean = false;
  public isLoadingMoreChannels: boolean = false;
  public showUnavailableChannels: boolean = false;

  constructor(
    public slicerService: SlicerService,
    public failoverGroupService: FailoverGroupService,
    public channelService: LiveChannelsService,
    public eventAggregator: EventAggregator,
    public toastsService: CToastsService,
    public router: Router,
    public bindingEngine: BindingEngine,
    public failoverGroupSlicerService: FailoverGroupSlicerService,
  ) {
    super();
  }

  public async attached() {
    super.attached();

    try {
      this.observers.push(
        this.bindingEngine.propertyObserver(this.group, 'active').subscribe(() => {
          this.setGroupSlicersStatuses();
        }),
        this.bindingEngine.propertyObserver(this.group, 'mode').subscribe(() => {
          this.modeChangedHandler();
        }),
      );
    } catch (e) {
      // do nothing
    }

    this.sortSlicersByOrder();
    this.setGroupSlicersStatuses();
  }

  public detached() {
    while (this.observers.length) {
      try {
        this.observers.pop().dispose();
      } catch {
        // Do nothing
      }
    }

    if (this.drake) {
      this.drake.destroy();
    }
  }

  public setupDragDrop(): void {
    if (this.drake) {
      this.drake.destroy();
      this.drake = null;
    }

    const {slicerList} = this;
    if (!slicerList) return;

    this.drake = dragula([slicerList], {
      moves: (_, __, handle) => handle.classList.contains('js--drag'),
    });

    this.drake.on('drop', (_, target) => {
      if (!(target instanceof HTMLElement)) {
        return;
      }

      const slicerOrder = [];
      Array.from(target.children).forEach((li, index) => {
        const slicerId = li.getAttribute('data-id');
        slicerOrder.push({id: slicerId, order: index + 1}); // Assuming order starts from 1
      });

      // Update the order of slicers in group.slicers
      this.group.slicers.forEach(slicer => {
        const newOrder = slicerOrder.find(s => s.id === slicer.id)?.order;
        if (newOrder !== undefined) {
          slicer.order = newOrder;
        }
      });
      this.sortSlicersByOrder();
      this.setGroupSlicersStatuses();
      this.groupChanged(this.group, this.originalGroup);
    });
  }

  public async groupChanged(_newGroup: FailoverGroup, _oldGroup: FailoverGroup) {
    if (_newGroup && _oldGroup) {
      this.dirtyCheck(_newGroup, _oldGroup);
    }
  }

  public modeChangedHandler() {
    // Check if 'this.group' and 'this.group.slicers' are defined
    if (!this.group || !this.group.slicers) {
      return;
    }

    // Transform 'this.group.slicers' array based on group mode
    if (this.group.mode === 'prioritized') {
      // Sort slicers based off current order
      this.sortSlicersByOrder();
      // Reorder slicers if mode is 'prioritized'
      this.group.slicers = this.reorder(_.cloneDeep(this.group.slicers));
      // Perform additional setup if mode is 'prioritized'
      this.setupDragDrop();
    } else if (this.group.mode === 'custom') {
      // Revert to original slicers if mode is 'custom'
      this.group.slicers = _.cloneDeep(this.originalGroup.slicers);
    } else {
      // Set order to 1 for each slicer if mode is 'flat'
      this.group.slicers = _.map(_.cloneDeep(this.group.slicers), s => {
        s.order = 1;
        return s;
      });
    }

    // Set 'auto_failback' based on group mode and original group properties
    if (this.group.mode === 'flat') {
      this.group.auto_failback = false;
    } else if (this.group.mode === 'prioritized' || this.group.mode === 'custom') {
      // Set 'auto_failback' to true if mode changed from 'flat' to 'prioritized' or 'custom'
      this.group.auto_failback = true;
    } else {
      // Otherwise, set 'auto_failback' based on original group property
      this.group.auto_failback = this.originalGroup.auto_failback;
    }

    this.groupChanged(this.group, this.originalGroup);
  }

  public customOrderChanged(slicer, event) {
    const v: number = +event.target.value;
    slicer.order = v;

    this.setGroupSlicersStatuses();
    this.groupChanged(this.group, this.originalGroup);
  }

  get hotWarmOptions() {
    const opts = [{text: 'Hot-Warm', value: '1'}];
    if (this.group && this.group.slicers && this.group.slicers.length > 2) {
      let plural = '';
      // tslint:disable-next-line:no-increment-decrement
      for (let i = 2; i < this.group.slicers.length; i++) {
        opts.push({text: `${i - 1} Hot Backup${plural}`, value: `${i}`});
        plural = 's';
      }
    }
    opts.push({text: 'All Hot', value: '0'});
    return opts;
  }

  public getSlicerState(s) {
    const label = s?.status?.stateLabel;

    const stateMap = {
      Slicing: 'slicing',
      Ads: 'ad',
      'Replacing content': 'override',
      Blackout: 'blackout',
      'Shutting Down': 'warning',
      Shutdown: 'neutral',
    };

    return label ? stateMap[label] || null : null;
  }

  public getSlicerHotWarmStateDesc(s, hotSlicersCount) {
    if (!s.enabled) {
      return 'disabled';
    }
    if (!_.get(s, 'status.failover_healthy', false) || s.blacklist_until - new Date().getTime() > 0) {
      return 'unhealthy';
    }
    if (this.group.active && s.id === this.group.active.id) {
      return 'active';
    }
    if (this.group.hot_warm === 0 || hotSlicersCount <= this.group.hot_warm) {
      return 'hot';
    }
    return 'warm';
  }

  public hotWarmStateChange(value) {
    this.group.hot_warm = Number(value);
    this.setGroupSlicersStatuses();
    this.groupChanged(this.group, this.originalGroup);
  }

  public slicerEnabledChange(slicer: FailoverGroupSlicer, checked: boolean) {
    slicer.enabled = checked;
    this.setGroupSlicersStatuses();
    this.groupChanged(this.group, this.originalGroup);
  }

  public setGroupSlicersStatuses() {
    if (this.group && this.group.slicers) {
      let hotSlicersCount = this.group.active ? 1 : 0;
      this.group.slicers = _.map(this.group.slicers, s => {
        if (s.enabled && s.blacklist_until === 0 && s.id !== _.get(this.group, 'active.id', '')) {
          hotSlicersCount += 1;
        }
        s.slicer_state = this.getSlicerState(s);
        s.hot_warm_state = this.getSlicerHotWarmStateDesc(s, hotSlicersCount);
        return s;
      });
    }
  }

  public changeFailback(value: boolean) {
    this.group.auto_failback = value;
    this.groupChanged(this.group, this.originalGroup);
  }

  /**
   * Slicer Controls
   */

  // Toggle a selected slicer
  public toggleSelectSlicer(slicer: FailoverGroupSlicer) {
    slicer.selected = !slicer.selected;
    this.trackSelectedSlicers(slicer);
  }

  // Toggle all slicers as removable
  public toggleSelectAllSlicers(isSelected: boolean) {
    _.forEach(this.group.slicers, slicer => {
      slicer.selected = isSelected;
      this.trackSelectedSlicers(slicer);
    });
  }

  // Track a slicer as it's toggled for removal
  public trackSelectedSlicers(slicer: FailoverGroupSlicer) {
    if (slicer.selected && !this.selectedSlicers.includes(slicer.id)) {
      this.selectedSlicers.push(slicer.id);
    } else if (!slicer.selected) {
      _.remove(this.selectedSlicers, id => id === slicer.id);
    }
  }

  public async attachSlicersDialog() {
    this.isLoadingSlicers = true;
    this.failoverGroupSlicers = await this.failoverGroupSlicerService.getFailoverGroupSlicers({});
    this.addedSlicers = _.map(this.group.slicers, s => ({value: s.id, text: s.id}));
    this.allSlicers = this.formatSlicers();
    this.isLoadingSlicers = false;
  }

  public detachSlicersDialog() {
    if (this.addSlicersDialog) {
      this.addSlicersDialog.hide();
    }
    this.failoverGroupSlicers = [];
    this.slicersToBeAdded = [];
    this.allSlicers = [];
    this.searchSlicersQuery = null;
  }

  public handleDialogSlicerSelection(item: any): void {
    if (item.checked) {
      if (!this.slicersToBeAdded.includes(item.value)) {
        this.slicersToBeAdded.push(item.value);
      }
    } else {
      const index = this.slicersToBeAdded.indexOf(item.value);
      if (index !== -1) {
        this.slicersToBeAdded.splice(index, 1);
      }
    }
  }

  public async searchSlicers() {
    this.isLoadingSlicers = true;
    this.failoverGroupSlicers = await this.failoverGroupSlicerService.searchSlicers(this.searchSlicersQuery);
    this.allSlicers = this.formatSlicers();
    this.isLoadingSlicers = false;
  }

  public async loadMoreSlicers() {
    if (!this.isLoadingMoreSlicers) {
      this.isLoadingMoreSlicers = true;
      this.failoverGroupSlicers = await this.failoverGroupSlicerService.getMoreFailoverGroupSlicers();
      this.allSlicers = this.formatSlicers();
      this.isLoadingMoreSlicers = false;
    }
  }

  public async addSlicersToGroup(): Promise<void> {
    const slicers = _.union(this.group.slicers, this.failoverGroupSlicers);
    const slicersToAdd = this.slicersToBeAdded
      .map(value => slicers.find(slicer => slicer.id === value))
      .filter(Boolean);

    this.group.slicers.push(...slicersToAdd);

    if (!_.isEqual(this.group, this.originalGroup)) {
      const sortedSlicers = orderBy(this.reorder(_.cloneDeep(this.group.slicers)), ['order'], ['asc']);
      this.group.slicers = sortedSlicers.map(slicer =>
        Object.assign(new FailoverGroupSlicer(), JSON.parse(JSON.stringify(slicer))),
      );
    }

    this.setGroupSlicersStatuses();
    await this.detachSlicersDialog();
    this.groupChanged(this.group, this.originalGroup);
    // this.dirtyCheck(this.group, this.originalGroup);
  }

  public removeSlicers() {
    const group = _.cloneDeep(this.group);
    _.remove(group.slicers, s => _.get(s, 'selected'));
    group.slicers = this.reorder(_.cloneDeep(group.slicers));
    this.group = group;
    this.setGroupSlicersStatuses();
  }

  private formatSlicers() {
    const usedSlicers = this.getUsedSlicers();
    const availableSlicers = this.failoverGroupSlicers;

    // Combine and filter out duplicates
    const uniqueSlicers = [
      ...availableSlicers.map(slicer => ({
        checked: this.slicersToBeAdded.includes(slicer.id),
        disabled: false,
        text: slicer.id,
        value: slicer.id,
      })),
      ...usedSlicers.map(slicer => ({
        checked: false,
        disabled: true,
        text: `${slicer.id} (Already in use)`,
        value: slicer.id,
      })),
    ].filter(slicer => !this.addedSlicers.some(selected => selected.value === slicer.value));

    return _.uniqBy(uniqueSlicers, 'value');
  }

  private getUsedSlicers() {
    return _.flatten(this.groups.filter(group => group.id !== this.group.id).map(group => group.slicers));
  }

  private reorder(slicers, order = 1) {
    let _order = order;
    _.each(slicers, s => {
      s.order = _order;
      _order += 1;
    });
    return slicers;
  }

  @computedFrom('group.slicers.length', 'group.mode', 'slicersTable')
  private get slicerList(): HTMLElement | null {
    const list = document.getElementById('slicer-list');
    return list && (this.group.slicers.length > 1 || this.group.mode === 'prioritized') ? list : null;
  }

  private sortSlicersByOrder(): void {
    this.group.slicers.sort((a, b) => a.order - b.order);
  }

  /**
   * Channel Controls.
   */

  // Toggle a selected channel
  public toggleSelectChannel(channel: any) {
    channel.checked = !channel.checked;
    this.trackSelectedChannels(channel);
  }

  // Toggle all channels as removable
  public toggleSelectAllChannels(isSelected: boolean) {
    _.forEach(this.group.channels, channel => {
      channel.checked = isSelected;
      this.trackSelectedChannels(channel);
    });
  }

  // Track a channel as it's toggled for removal
  public trackSelectedChannels(channel: any) {
    if (channel.checked && !this.selectedChannels.includes(channel.id)) {
      this.selectedChannels.push(channel.id);
    } else if (!channel.checked) {
      _.remove(this.selectedChannels, id => id === channel.id);
    }
  }

  public async attachChannelsDialog() {
    this.isLoadingChannels = true;
    // Get all unavailable channels
    this.unavailableChannels = _.flattenDeep(
      _.map(
        _.filter(this.groups, _g => _g.id !== this.group.id),
        g => _.map(g.channels, 'id'),
      ),
    );
    // Currently added channels in group
    this.addedChannels = _.map(this.group.channels, c => ({text: c.desc, value: c.id}));
    // Get channels (retrieve first 30)
    await this.channelService.getChannels({});
    this.formatChannels();
    this.isLoadingChannels = false;
  }

  public detachChannelsDialog() {
    if (this.addChannelsDialog) {
      this.addChannelsDialog.hide();
    }
    this.unavailableChannels = [];
    this.channelsToBeAdded = [];
    this.allChannels = [];
    this.searchChannelsQuery = null;
  }

  public handleDialogChannelSelection(item: any): void {
    if (item.checked) {
      if (!this.channelsToBeAdded.includes(item.value)) {
        this.channelsToBeAdded.push(item.value);
      }
    } else {
      const index = this.channelsToBeAdded.indexOf(item.value);
      if (index !== -1) {
        this.channelsToBeAdded.splice(index, 1);
      }
    }
  }

  private formatChannels() {
    this.allChannels = _.map(_.cloneDeep(this.channelService.channels), c => ({
      checked: this.channelsToBeAdded.includes(c.id),
      disabled: _.includes(this.unavailableChannels, c.id),
      text: _.includes(this.unavailableChannels, c.id) ? `${c.desc} (Already in use)` : c.desc,
      value: c.id,
    }));
    _.remove(this.allChannels, c => _.includes(_.map(this.addedChannels, 'value'), c.value));
  }

  public async searchChannels() {
    this.isLoadingChannels = true;
    await this.channelService.search(this.searchChannelsQuery);
    this.formatChannels();
    this.isLoadingChannels = false;
  }

  public async loadMoreChannels() {
    if (!this.isLoadingMoreChannels) {
      this.isLoadingMoreChannels = true;
      await this.channelService.getMoreChannels();
      this.formatChannels();
      this.isLoadingMoreChannels = false;
    }
  }

  public async mapChannelsToGroup(): Promise<void> {
    const channelsToAdd = this.channelsToBeAdded
      .map(value => {
        const channel = this.allChannels.find(channel => channel.value === value);
        return channel ? {desc: channel.text, id: channel.value, checked: false} : null;
      })
      .filter(Boolean);

    this.group.channels.push(...channelsToAdd);

    if (!_.isEqual(this.group, this.originalGroup)) {
      this.group.channels = _.orderBy(this.group.channels, ['desc']);
    }

    await this.detachChannelsDialog();
    this.groupChanged(this.group, this.originalGroup);
  }

  public removeChannels() {
    const group = _.cloneDeep(this.group);
    _.remove(group.channels, 'checked');
    this.group = group;
  }
}
