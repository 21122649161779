import {CToastsService} from '@bindable-ui/bindable';
import {ProfilesResponseItem} from 'apps/cms/routes/slicers/models/profiles';
import {OwnerDefaultProfilesService} from 'apps/cms/routes/slicers/services/default-profiles-service';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {ProfilesService} from '../../services/profiles-service';
import {Config as SharedModel} from '../modals/usage/models';
import {Config, ProfileDefault, ProfileNotification} from './models';
import {EncodingProfileRayTable} from './table';
import {EncodingProfileRayTableRow} from './table-models';

@autoinject
export class ProfileDetails {
  @computedFrom('config.input.profile', 'config.state.isProcessingDefaultLive')
  get defaultProfileStateLive() {
    if (!this.config.input.profile) return 'disabled';
    if (this.config.input.profile.deprecated && !this.config.state.canUseDeprecatedProfiles) return 'disabled';
    if (this.config.state.isProcessingDefaultLive) return 'thinking';
    if (this.config.input.profile.is_default_live_profile) return 'disabled';
    return '';
  }

  @computedFrom('config.input.profile', 'config.state.isProcessingDefaultVod')
  get defaultProfileStateVod() {
    if (!this.config.input.profile) return 'disabled';
    if (this.config.input.profile.deprecated && !this.config.state.canUseDeprecatedProfiles) return 'disabled';
    if (this.config.state.isProcessingDefaultVod) return 'thinking';
    if (this.config.input.profile.is_default_vod_profile) return 'disabled';
    return '';
  }

  public config = new Config();
  public table: EncodingProfileRayTable;
  protected modalBodyViewModel: string = PLATFORM.moduleName(
    'apps/cms/routes/slicers/encoding-profiles/modals/usage/body',
  );

  private ownerDefaultProfilesService: OwnerDefaultProfilesService;
  private profilesService: ProfilesService;

  constructor(
    protected acceo: Acceo,
    protected dialogService: DialogService,
    protected notification: CToastsService,
    protected router: Router,
    protected sessionService: SessionService,
  ) {
    this.profilesService = new ProfilesService(acceo);
    this.ownerDefaultProfilesService = new OwnerDefaultProfilesService(acceo);
  }

  public activate(params?: any) {
    this.load(params.id);
  }

  public actionBack() {
    const url = this.router.baseUrl.substr(0, this.router.baseUrl.length - 1);
    this.router.navigate(url);
  }

  public actionSelectDefaultLive() {
    this.actionSelectDefault(ProfileDefault.LIVE);
  }

  public actionSelectDefaultVod() {
    this.actionSelectDefault(ProfileDefault.VOD);
  }

  public actionViewUsageLive() {
    const model = new SharedModel();
    model.input.profileId = this.config.input.profile.id;
    model.input.type = ProfileDefault.LIVE;
    this.dialogService.open({
      model: {
        bodyViewModel: this.modalBodyViewModel,
        sharedModel: model,
        size: 'large',
        title: 'Encoding Profile Live Slicers',
      },
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
    });
  }

  public actionViewUsageVod() {
    const model = new SharedModel();
    model.input.profileId = this.config.input.profile.id;
    model.input.type = ProfileDefault.VOD;
    this.dialogService.open({
      model: {
        bodyViewModel: this.modalBodyViewModel,
        sharedModel: model,
        size: 'large',
        title: 'Encoding Profile VoD Assets',
      },
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
    });
  }

  public showHideColumns(col) {
    col.selected = !col.selected;
    this.table.trackSelectedCol(col);
  }

  protected async actionSelectDefault(type: ProfileDefault) {
    try {
      if (type === ProfileDefault.LIVE) {
        this.config.state.isProcessingDefaultLive = true;
        await this.ownerDefaultProfilesService.postOwnerDefaultProfiles({
          default_live_profile: this.config.input.profile.id,
        });
        this.config.input.profile.is_default_live_profile = 1;
        this.config.state.isProcessingDefaultLive = false;
        this.notification.success(ProfileNotification.SUCCESS_DEFAULT_LIVE);
      } else if (type === ProfileDefault.VOD) {
        this.config.state.isProcessingDefaultVod = true;
        await this.ownerDefaultProfilesService.postOwnerDefaultProfiles({
          default_profile: this.config.input.profile.id,
        });
        this.config.input.profile.is_default_vod_profile = 1;
        this.config.state.isProcessingDefaultVod = false;
        this.notification.success(ProfileNotification.SUCCESS_DEFAULT_VOD);
      } else {
        throw new Error(ProfileNotification.ERROR_REQUEST_FAILED);
      }
    } catch (reason) {
      this.notification.error(reason);
    }
  }

  protected async load(profileId: string) {
    this.config.state.error = undefined;
    this.config.state.isLoading = true;
    if (this.sessionService.hasHostedSlicersAccess) {
      this.config.state.canExportCSV = true;
      this.config.state.canUseDeprecatedProfiles = true;
      this.config.state.canViewProfileRays = true;
    }
    try {
      this.config.input.profile = await this.requestRead(profileId);
      // this.config.options.profileItems =
      //     response.items.filter(item =>
      //         !item.deprecated ||
      //         this.config.state.canUseDeprecatedProfiles ||
      //         item.is_default_live_profile ||
      //         item.is_default_vod_profile,
      //     );
      if (this.config.state.canViewProfileRays) {
        this.table = new EncodingProfileRayTable();
        this.table.rows = this.getTableRows(this.config.input.profile);
        this.table.addColsFromRows(this.table.colsIgnore, false, false);
      }
    } catch (reason) {
      this.config.state.error = reason;
    }
    this.config.state.isLoading = false;
  }

  protected getTableRows(profile: ProfilesResponseItem) {
    return (
      Object.keys(profile)
        // hide profiles include a “Dr Ray”
        .filter(key => !(key.indexOf('dr_ray') !== -1))
        .filter(key => key.indexOf('ray') !== -1 && typeof profile[key] === 'object')
        .sort()
        .map(key => {
          const row = profile[key] as EncodingProfileRayTableRow;
          row.profile_key = key.replace('_', ' ');
          row.resolution = `${row.maxW}x${row.maxH}${row.fps ? ` @${row.fps} fps` : ''}`;
          return row;
        })
    );
  }

  protected requestRead(profileId: string) {
    return this.profilesService.getById(profileId);
  }
}
