import {SharedNav} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

@autoinject()
export class CmsAnalytics {
  public router: Router;

  public routes = [
    {
      moduleId: PLATFORM.moduleName('./dashboards/index'),
      name: 'analyticsDashboardsIndex',
      nav: true,
      route: ['', 'dashboards'],
      title: 'Dashboards',
    },
    {
      moduleId: PLATFORM.moduleName('./settings/index'),
      name: 'analyticsSettingsIndex',
      nav: true,
      route: 'settings',
      title: 'Settings',
    },
  ];

  constructor(public sharedNav: SharedNav) {}

  public attached() {
    this.sharedNav.initMainNav(this.router);
  }

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }
}
