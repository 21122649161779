import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable, computedFrom} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {Notification} from 'resources/notification/service';
import {CLIPPING_CONFIG_EVENT} from '../index';
import {ClippingConfig} from '../models/clipping-config';
import {IntegrationNameType, IntegrationServiceType} from '../models/enums-config';
import {FaceBook, FaceBookPage} from '../models/facebook-config';
import {Kaltura} from '../models/kaltura-config';
import {Lakana} from '../models/lakana-config';
import {Twitter} from '../models/twitter-config';
import {Youtube} from '../models/youtube-config';
import {ClippingSettingService} from '../services/clipping-service';
import {FacebookApi} from './components/facebook-api';
import {YoutubeApi} from './components/youtube-api';

export const CLIPPING_INTEGRATION_EVENT = {
    IntegrationDelete: 'ClippingIntegrationEvent:Delete',
};

@autoinject()
export class ClippingIntegrations {
    @bindable
    public config: ClippingConfig;

    @bindable
    public validationErrors: ValidationError[];

    @bindable
    public callbacks;

    public vmAddIntegrations;
    public selectedIntegrations = [];
    public facebookPageList: FaceBookPage[] = [];
    public configChanged = new Date();

    public subscriptions: Subscription[] = [];

    public gapiAuthError = 'There was an error authenticating with Google';
    public twitterAuthError = 'There was an error authencating with Twitter';

    private errorKey = 'error';

    constructor(
        public clippingSettingService: ClippingSettingService,
        public notification: Notification,
        public facebook: FacebookApi,
        public youtube: YoutubeApi,
        public eventAggregator: EventAggregator,
    ) {}

    public attached() {
        this.subscriptions.push(
            this.eventAggregator.subscribe(
                CLIPPING_INTEGRATION_EVENT.IntegrationDelete,
                opt =>
                    new Promise(resolve => {
                        this.buttonRemoveIntegration(opt.itemType);
                        resolve();
                    }),
            ),
        );
    }

    public detached() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    public loginWithGoogle(): Promise<any> {
        let googleTimer;
        this.config.youTubeAuthProcessing = true;
        return this.youtube
            .login()
            .then(() => {
                googleTimer = setInterval(() => {
                    try {
                        if (this.youtube.responseCode) {
                            clearInterval(googleTimer);
                            this.youTubeSigninCallback(this.youtube.responseCode);
                        } else if (this.youtube.errorCode) {
                            clearInterval(googleTimer);
                            this.notification.error(this.youtube.errorCode.message);
                            this.config.youTubeAuthProcessing = false;
                        }
                    } catch (e) {
                        /**/
                    }
                }, 500);
            })
            .catch(err => {
                this.notification.error(err);
                this.config.youTubeAuthProcessing = false;
            });
    }

    private youTubeSigninCallback(responseCode) {
        this.clippingSettingService.googleAuthentication(responseCode, this.config.id).then(data => {
            if (data[this.errorKey]) {
                this.notification.error(data[this.errorKey]);
            } else {
                this.config.youTubeAuthenticated = true;
            }
            this.config.youTubeAuthProcessing = false;
        });
    }

    public logoutFromGoogle(): Promise<any> {
        this.config.youTubeAuthProcessing = true;
        return this.clippingSettingService
            .googleAuthenticationRevoke(this.config.id)
            .then(data => {
                if (data[this.errorKey]) {
                    this.notification.error(this.gapiAuthError);
                } else {
                    this.config.youTubeAuthenticated = false;
                }
                this.config.youTubeAuthProcessing = false;
            })
            .catch(() => {
                this.config.youTubeAuthProcessing = false;
            });
    }

    public buttonRemoveIntegration(serviceName): void {
        if (serviceName === IntegrationServiceType.Facebook) {
            // integrations.xxxx.newlyAdded is to manage dirty state properly
            if (this.config.integrations.facebook.newlyAdded) {
                this.config.integrations.facebook = undefined;
            } else {
                this.config.integrations.facebook.enabled = false;
            }
        } else if (serviceName === IntegrationServiceType.Kaltura) {
            if (this.config.integrations.kaltura.newlyAdded) {
                this.config.integrations.kaltura = undefined;
            } else {
                this.config.integrations.kaltura.enabled = false;
            }
        } else if (serviceName === IntegrationServiceType.Lakana) {
            if (this.config.integrations.lakana.newlyAdded) {
                this.config.integrations.lakana = undefined;
            } else {
                this.config.integrations.lakana.enabled = false;
            }
        } else if (serviceName === IntegrationServiceType.Twitter) {
            if (this.config.integrations.twitter.newlyAdded) {
                this.config.integrations.twitter = undefined;
            } else {
                this.config.integrations.twitter.enabled = false;
            }
        } else if (serviceName === IntegrationServiceType.YouTube) {
            if (this.config.integrations.youtube.newlyAdded) {
                this.config.integrations.youtube = undefined;
            } else {
                this.config.integrations.youtube.enabled = false;
            }
        }
        this.configChanged = new Date();
        this.callbacks.onChange();
    }

    get hasIntegrations() {
        let found = false;
        if (
            this.config &&
            this.config.integrations &&
            (this.config.integrations.facebook ||
                this.config.integrations.kaltura ||
                this.config.integrations.lakana ||
                this.config.integrations.twitter ||
                this.config.integrations.youtube)
        ) {
            found = true;
        }
        return found;
    }

    public loginWithTwitter(): Promise<any> {
        const key = 'auth_url';
        this.config.twitterAuthProcessing = true;
        let twitterTimer;
        const twitterWindow = window.open(
            '',
            '_blank',
            'toolbar=yes, scrollbars=yes, resizable=yes, width=725, height=640',
        );

        return this.clippingSettingService
            .getTwitterAuthenticationUrl()
            .then(data => {
                if (!data[this.errorKey]) {
                    twitterWindow.location.href = data[key];

                    twitterTimer = setInterval(
                        () => {
                            try {
                                const url = twitterWindow.location.href;

                                if (url.indexOf('oauth_verifier') !== -1) {
                                    clearInterval(twitterTimer);
                                    const queryString = url.substring(url.indexOf('?') + 1);
                                    this.twitterSigninCallback(queryString).then(() => {
                                        twitterWindow.close();
                                    });
                                }
                            } catch (e) {
                                /**/
                            }
                            if (twitterWindow.closed) {
                                clearInterval(twitterTimer);
                                this.config.twitterAuthProcessing = false;
                            }
                        },
                        // tslint:disable-next-line:align
                        500,
                    );
                }
            })
            .catch(err => {
                this.notification.error(err);
                this.config.twitterAuthProcessing = false;
            });
    }

    public logoutFromTwitter(): Promise<any> {
        const key = 'success';
        this.config.twitterAuthProcessing = true;
        return this.clippingSettingService
            .twitterRevokeAutentication(this.config.id)
            .then(data => {
                if (data[key]) {
                    this.config.twitterAuthenticated = false;
                } else {
                    this.notification.error(this.gapiAuthError);
                }
                this.config.twitterAuthProcessing = false;
            })
            .catch(() => {
                this.config.twitterAuthProcessing = false;
            });
    }

    public loginWithFacebook(): Promise<any> {
        this.config.facebookAuthProcessing = true;
        return this.facebook
            .login()
            .then(data => {
                if (data.status === 'connected') {
                    // Send token to store in DB
                    this.clippingSettingService
                        .facebookAutentication(
                            this.config.id,
                            data.authResponse.accessToken,
                            data.authResponse.expiresIn,
                            data.authResponse.signedRequest,
                            data.authResponse.userID,
                        )
                        .then(() => {
                            this.config.facebookAuthenticated = true;
                            this.config.facebookAuthProcessing = false;
                            this.eventAggregator.publish(CLIPPING_CONFIG_EVENT.LoadFacebookPages, {
                                profileid: this.config.id,
                            });
                        });
                } else {
                    this.config.facebookAuthProcessing = false;
                }
            })
            .catch(() => {
                this.config.facebookAuthProcessing = false;
            });
    }

    public logoutFromFacebook(): Promise<any> {
        const key = 'success';
        this.config.facebookAuthProcessing = true;
        return this.clippingSettingService
            .facebookRevokeAutentication(this.config.id)
            .then(data => {
                if (data[key]) {
                    this.config.facebookAuthenticated = false;
                } else {
                    this.notification.error(this.gapiAuthError);
                }
                this.config.facebookAuthProcessing = false;
            })
            .catch(() => {
                this.config.facebookAuthProcessing = false;
            });
    }

    @computedFrom('config.integrations', 'configChanged')
    get integrationsAvailable() {
        const availableIntegrations = [];
        if (this.config && this.config.integrations) {
            if (!(this.config.integrations.facebook && this.config.integrations.facebook.enabled)) {
                availableIntegrations.push({
                    name: IntegrationNameType.Facebook,
                    service: IntegrationServiceType.Facebook,
                });
            }
            if (!(this.config.integrations.kaltura && this.config.integrations.kaltura.enabled)) {
                availableIntegrations.push({
                    name: IntegrationNameType.Kaltura,
                    service: IntegrationServiceType.Kaltura,
                });
            }
            if (!(this.config.integrations.lakana && this.config.integrations.lakana.enabled)) {
                availableIntegrations.push({
                    name: IntegrationNameType.Lakana,
                    service: IntegrationServiceType.Lakana,
                });
            }
            if (!(this.config.integrations.twitter && this.config.integrations.twitter.enabled)) {
                availableIntegrations.push({
                    name: IntegrationNameType.Twitter,
                    service: IntegrationServiceType.Twitter,
                });
            }
            if (!(this.config.integrations.youtube && this.config.integrations.youtube.enabled)) {
                availableIntegrations.push({
                    name: IntegrationNameType.YouTube,
                    service: IntegrationServiceType.YouTube,
                });
            }
        } else {
            availableIntegrations.push({name: IntegrationNameType.Facebook, service: IntegrationServiceType.Facebook});
            availableIntegrations.push({name: IntegrationNameType.Kaltura, service: IntegrationServiceType.Kaltura});
            availableIntegrations.push({name: IntegrationNameType.Lakana, service: IntegrationServiceType.Lakana});
            availableIntegrations.push({name: IntegrationNameType.Twitter, service: IntegrationServiceType.Twitter});
            availableIntegrations.push({name: IntegrationNameType.YouTube, service: IntegrationServiceType.YouTube});
        }
        return availableIntegrations;
    }

    public addIntegration() {
        this.vmAddIntegrations.hide();
        for (const serviceInfo of this.selectedIntegrations) {
            // when adding an integration, we need to set service.newlyAdded = true to help
            // maintain dirty state if this same integration is deleted.  This is only done
            // if the integration object is created.  If it has already been created, then
            // we leave newlyAdded as is (which should be false)
            if (serviceInfo.service === IntegrationServiceType.Facebook) {
                if (this.config.integrations.facebook && !this.config.integrations.facebook.enabled) {
                    this.config.integrations.facebook.enabled = true;
                } else {
                    const service = new FaceBook({enabled: true});
                    service.newlyAdded = true;
                    this.config.integrations.facebook = service;
                }
                this.configChanged = new Date();
            } else if (serviceInfo.service === IntegrationServiceType.Kaltura) {
                if (this.config.integrations.kaltura && !this.config.integrations.kaltura.enabled) {
                    this.config.integrations.kaltura.enabled = true;
                } else {
                    const service = new Kaltura({enabled: true});
                    service.newlyAdded = true;
                    this.config.integrations.kaltura = service;
                }
                this.configChanged = new Date();
            } else if (serviceInfo.service === IntegrationServiceType.Lakana) {
                if (this.config.integrations.lakana && !this.config.integrations.lakana.enabled) {
                    this.config.integrations.lakana.enabled = true;
                } else {
                    const service = new Lakana({enabled: true});
                    service.newlyAdded = true;
                    this.config.integrations.lakana = service;
                }
                this.configChanged = new Date();
            } else if (serviceInfo.service === IntegrationServiceType.Twitter) {
                if (this.config.integrations.twitter && !this.config.integrations.twitter.enabled) {
                    this.config.integrations.twitter.enabled = true;
                } else {
                    const service = new Twitter({enabled: true});
                    service.newlyAdded = true;
                    this.config.integrations.twitter = service;
                }
                this.configChanged = new Date();
            } else if (serviceInfo.service === IntegrationServiceType.YouTube) {
                if (this.config.integrations.youtube && !this.config.integrations.youtube.enabled) {
                    this.config.integrations.youtube.enabled = true;
                } else {
                    const service = new Youtube({enabled: true});
                    service.newlyAdded = true;
                    this.config.integrations.youtube = service;
                }
                this.configChanged = new Date();
            }
        }
        this.selectedIntegrations = [];
    }

    private twitterSigninCallback(queryString): Promise<any> {
        const key = 'success';
        this.config.twitterAuthProcessing = true;
        return this.clippingSettingService.twitterCallBackAuthentication(queryString, this.config.id).then(data => {
            if (data[key]) {
                this.config.twitterAuthenticated = true;
            } else {
                this.notification.error(this.twitterAuthError);
            }
            this.config.twitterAuthProcessing = false;
        });
    }
}
