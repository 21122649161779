// eslint-disable-next-line max-classes-per-file
import {ITrackedModel, trackedModel} from 'apps/cms/utils/tracked-model';
import {computedFrom} from 'aurelia-framework';
import {Exclude, Type} from 'class-transformer';
import {IsArray, IsBoolean, IsDateString, IsNumber, IsOptional, IsString, ValidateNested} from 'class-validator';
import * as moment from 'moment';
import {Rule} from '../../../rules/models/models';

// eslint-disable-next-line no-shadow
export enum ScheduledEntryContentType {
  CHANNEL = 'channel',
  SLICER = 'slicer',
  ASSET = 'asset',
  AD = 'ad',
  SLICER_ASSET = 'slicer-asset',
  PLAYLIST = 'Playlist',
  REPLAY = 'replay_source',
  OVERLAY = 'overlay',
}

export enum ScheduledOverlaySourceType {
  VOD = 'vod',
  LIVE = 'live',
}

export const ScheduledImportContentType = [
  ScheduledEntryContentType.ASSET,
  ScheduledEntryContentType.AD,
  ScheduledEntryContentType.SLICER,
];

// eslint-disable-next-line no-shadow
export enum MATCH_TYPES {
  SIGNAL = 'Signal',
  TIME = 'Time',
}

// eslint-disable-next-line no-shadow
export enum ConflictResolution {
  REPLACE = 'replace',
  TRIM_START = 'trim-start',
  TRIM_END = 'trim-end',
}

export class ScheduleEntrySource {
  @IsString()
  @IsOptional()
  public id?: string;

  @IsString()
  @IsOptional()
  public type?: string;

  @IsString()
  @IsOptional()
  public name?: string;
}

export interface IScheduleEntryBase {
  ad_breaks?: number[];
  blackout_id?: string;
  channel?: string;
  created?: string;
  content_id?: string;
  content_type?: string;
  content_owner?: string;
  deleted?: string;
  desc?: string;
  dur: number;
  end?: string;
  external_id?: string;
  id?: string;
  live?: boolean;
  replay_source_end?: string;
  replay_source_start?: string;
  replaced_by?: string;
  rules: any[];
  start?: string;
  source?: ScheduleEntrySource;
  type?: string;
  overlay_source_type?: string;
}

export interface IScheduleEntry extends IScheduleEntryBase {
  '@included'?: any[];
  readonly isAdBreak: boolean;
  readonly isAsset: boolean;
  readonly isSlicer: boolean;
  readonly isEditable: boolean;
  readonly isLive: boolean;
  readonly isMatchSignal: boolean;
  readonly isMatchTime: boolean;
  readonly isReplaced: boolean;
  readonly end_ro: string;
  readonly isRepeat: boolean;
  readonly isOverlay: boolean;
}

export interface ITrackedScheduledEntry extends IScheduleEntry, ITrackedModel {}

export class ScheduleEntry implements IScheduleEntry {
  @IsOptional()
  public readonly ad_breaks?: number[];

  @IsString()
  @IsOptional()
  public blackout_id?: string;

  @IsString()
  @IsOptional()
  public channel?: string;

  @IsString()
  @IsOptional()
  public content_id?: string;

  @IsString()
  @IsOptional()
  public content_type?: string;

  @IsString()
  @IsOptional()
  public content_owner?: string;

  @IsDateString()
  @IsOptional()
  public created?: string;

  @IsString()
  @IsOptional()
  public deleted?: string = null;

  @IsString()
  @IsOptional()
  public desc?: string;

  @IsNumber()
  public dur: number;

  @IsDateString()
  @IsOptional()
  public end?: string;

  @IsString()
  @IsOptional()
  public external_id?: string;

  @IsString()
  public id: string;

  @IsBoolean()
  @IsOptional()
  public live?: boolean;

  @IsBoolean()
  @IsOptional()
  public from_schedule?: boolean;

  @IsString()
  @IsOptional()
  public replay_source_end?: string;

  @IsString()
  @IsOptional()
  public replay_source_start?: string;

  @IsString()
  @IsOptional()
  public replaced_by?: string;

  @IsArray()
  public rules: string[] = [];

  @IsDateString()
  @IsOptional()
  public start?: string;

  @IsString()
  @IsOptional()
  public state?: string;

  @ValidateNested()
  @IsOptional()
  @Type(() => ScheduleEntrySource)
  public source?: ScheduleEntrySource;

  @IsString()
  @IsOptional()
  public type?: string = '';

  @IsString()
  @IsOptional()
  public overlay_source_type?: string = ScheduledOverlaySourceType.LIVE;

  @ValidateNested()
  @Type(() => Rule)
  @Exclude({toPlainOnly: true})
  public '@included'?: any[];

  @computedFrom('isEditable', 'id')
  get canDelete() {
    return this.id && this.isEditable;
  }

  @computedFrom('content_type')
  get isAdBreak() {
    return this.content_type === ScheduledEntryContentType.AD;
  }

  @computedFrom('content_type')
  get isAsset() {
    return this.content_type === ScheduledEntryContentType.ASSET;
  }

  @computedFrom('content_type')
  get isSlicer() {
    return this.content_type === ScheduledEntryContentType.SLICER;
  }

  @computedFrom('content_type')
  get isSlicerAsset() {
    return this.content_type === ScheduledEntryContentType.SLICER_ASSET;
  }

  @computedFrom('content_type')
  get isRepeat() {
    return this.content_type === ScheduledEntryContentType.REPLAY;
  }

  @computedFrom('content_type')
  get isOverlay() {
    return this.content_type === ScheduledEntryContentType.OVERLAY;
  }

  @computedFrom('start')
  get isEditable() {
    return moment(this.start).isSameOrAfter(moment()) || !this.id;
  }

  @computedFrom('start', 'end', 'id')
  get isLive() {
    return this.id && moment().isBetween(moment(this.start), moment(this.end));
  }

  @computedFrom('type')
  get isMatchSignal() {
    return this.type === MATCH_TYPES.SIGNAL;
  }

  @computedFrom('type', 'content_type')
  get isMatchTime() {
    return this.type === MATCH_TYPES.TIME && !this.content_type;
  }

  @computedFrom('replaced_by')
  get isReplaced() {
    const replacedBy = this.replaced_by || '';

    return replacedBy.length > 0;
  }

  @computedFrom('start', 'dur')
  get end_ro() {
    if (!this.start || !this.dur) {
      return '';
    }

    return moment(this.start).add(this.dur, 'milliseconds').toISOString();
  }

  @computedFrom('start')
  get start_time() {
    return this.start;
  }

  @computedFrom('dur')
  get duration() {
    return this.dur / 1000;
  }
}

export class ScheduleEntryListResponse {
  @ValidateNested()
  @Type(() => ScheduleEntry)
  public items?: ScheduleEntry[];

  @ValidateNested()
  @Type(() => ScheduleEntry)
  public '@included'?: ScheduleEntry[];

  // TODO: Made this optional for now - due to changes on the api for time series
  @IsOptional()
  public total_items: number;

  @IsString()
  @IsOptional()
  public start?: string;

  @IsString()
  @IsOptional()
  public end?: string;
}

export function trackedScheduleEntry(model: ScheduleEntry): ITrackedScheduledEntry {
  return trackedModel({
    model,
    SuperClass: ScheduleEntry,
    trackedKeys: [
      'ad_breaks',
      'blackout_id',
      'desc',
      'dur',
      'end',
      'start',
      'rules',
      'content_id',
      'replay_source_end',
      'replay_source_start',
    ],
  });
}
