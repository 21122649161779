import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';
import {PLATFORM} from 'aurelia-pal';

import {CToastsService} from '@bindable-ui/bindable';

import {HyperionPolling} from 'services/hyperion-polling';
import {SessionService} from 'services/session';

import {ILazyLoaderActions} from 'components/lazy-loader';
import {WorkspaceKeyService} from './services/workspace-key-service';
import {WorkspaceKey} from './models/workspace-key';

function sorted(key: string, value: string): number {
  switch (value) {
    case `-${key}`:
      return 1;
    case key:
      return -1;
    default:
      return 0;
  }
}

@autoinject()
export class ApiKeys {
  public pollTracker: HyperionPolling;
  public pollInterval: number = 10 * 1000;

  public selected: WorkspaceKey = null;

  public modalView = null;
  public modalModel = null;
  public showModal = false;

  public trackedRevisions: Set<string>;

  public loaderActions: ILazyLoaderActions = {
    getMore: () => this.keyService.getMore(),
  };

  protected logger: Logger;

  @computedFrom('keyService.isLoading', 'keyService.isLoadingMore')
  public get isLoading(): boolean {
    return this.keyService.isLoading || this.keyService.isLoadingMore;
  }

  @computedFrom('keyService.order')
  public get sortedCreated(): number {
    return sorted('created', this.keyService.order);
  }

  @computedFrom('keyService.order')
  public get sortedLastmod(): number {
    return sorted('lastmod', this.keyService.order);
  }

  @computedFrom('keyService.order')
  public get sortedTitle(): number {
    return sorted('search', this.keyService.order);
  }

  constructor(
    public keyService: WorkspaceKeyService,
    public notificationService: CToastsService,
    public sessionService: SessionService,
  ) {
    this.logger = LogManager.getLogger('Settings | Api Keys');
    const {sessionInfo = {}} = this.sessionService;

    this.keyService.workspaceId = sessionInfo.ownerID;

    this.pollTracker = new HyperionPolling({
      callbackFn: res => this.keyService.processPollData(res),
      getParams: () => this.keyService.params,
      ms: this.pollInterval,
      promiseFn: args => {
        if (document.hidden) {
          return null;
        }

        return this.keyService.pollRecords(args);
      },
      useAfter: true,
    });
  }

  /* ******************* */
  /*    Aurelia Hooks    */
  /* ******************* */

  public async attached(): Promise<void> {
    if (this.keyService) {
      const params = {};
      await this.keyService.getKeys(params);

      this.selected = this.keyService.keys[0];
    }

    if (this.pollTracker) {
      this.pollTracker.start();
    }

    this.trackedRevisions = new Set();
  }

  public detached(): void {
    if (this.pollTracker) {
      this.pollTracker.stop();
      this.pollTracker = null;
    }
  }

  /* ******************** */
  /*    Public Methods    */
  /* ******************** */

  public async deleteKeys(): Promise<void> {
    if (this.keyService.isProcessing || this.trackedRevisions.size === 0) {
      return;
    }

    const deleted: string[] = [];
    const ids = Array.from(this.trackedRevisions);

    for (let i = 0; i < ids.length; i++) {
      try {
        const key = ids[i];

        // eslint-disable-next-line no-await-in-loop
        await this.keyService.deleteKey(key);
        deleted.push(key);
      } catch (err) {
        this.logger.error(err.message);
      }
    }

    if (deleted.length !== ids.length) {
      this.notificationService.error('Completed with errors');
    } else {
      this.notificationService.success('Done');
    }

    deleted.forEach(id => this.trackedRevisions.delete(id));
  }

  /**
   * Handles table row click events
   * @param event
   */
  public rowClicked(event: any) {
    event.preventDefault();

    const tr = event.target.closest('lynk-tr');
    const keyId = tr.dataset.id;

    if (!keyId) {
      return;
    }

    const td = event.target.closest('lynk-td');

    if (td?.classList.contains('row-select')) {
      this.trackRevision(keyId);
    } else {
      let revId = null;
      const li = event.target.closest('li');

      if (li) {
        revId = li.dataset.id;
      }

      this.showEditDialog(keyId, revId);
    }
  }

  public showCreateDialog() {
    this.modalModel = {
      actions: {
        onClose: () => {
          this.closeDialog();
        },
        onSave: () => {
          this.closeDialog();
          this.showKeyInfoDialog();
        },
      },
    };

    this.modalView = PLATFORM.moduleName('apps/cms/routes/settings/api-keys/modals/create-key/index');
    this.showModal = true;
  }

  public showDebuggerDialog() {
    this.modalModel = {
      actions: {
        onClose: () => {
          this.closeDialog();
        },
      },
    };

    this.modalView = PLATFORM.moduleName('apps/cms/routes/settings/api-keys/modals/jwt-debugger/index');
    this.showModal = true;
  }

  public async showEditDialog(keyId: string, revisionId?: string) {
    if (this.modalModel) {
      return;
    }

    let model: WorkspaceKey;

    try {
      model = await this.keyService.getKey(keyId);
    } catch (err) {
      this.logger.error(err);
      this.notificationService.error('Failed to load key');
      return;
    }

    this.modalModel = {
      model,
      revisionId,
      actions: {
        onClose: () => {
          this.closeDialog();
          this.showKeyInfoDialog();
        },
      },
    };

    this.modalView = PLATFORM.moduleName('apps/cms/routes/settings/api-keys/modals/edit-key/index');
    this.showModal = true;
  }

  public showKeyInfoDialog() {
    if (!this.keyService.hasKeyInfo) {
      return;
    }

    this.modalModel = {
      model: this.keyService.key,
      actions: {
        onClose: () => {
          this.closeDialog();
          this.keyService.clearKey();
        },
      },
    };

    this.modalView = PLATFORM.moduleName('apps/cms/routes/settings/api-keys/modals/key-info/index');
    this.showModal = true;
  }

  public async sortRecords(key: string, defaultSortDirection: string = null) {
    const {order} = this.keyService;
    let newOrder = '';

    if (order.replace(/^-/, '') === key) {
      newOrder = /^-/.test(order) ? key : `-${key}`;
    } else {
      newOrder = defaultSortDirection || key;
    }

    this.keyService.getKeys({order: newOrder});
  }

  public trackRevision(id: string) {
    if (this.trackedRevisions.has(id)) {
      this.trackedRevisions.delete(id);
    } else {
      this.trackedRevisions.add(id);
    }

    // Aurelia can't track a set, so replace it to force rendering updates
    this.trackedRevisions = new Set(this.trackedRevisions);
  }

  /* ********************* */
  /*    Private Methods    */
  /* ********************* */

  private closeDialog() {
    this.showModal = false;
    this.modalModel = null;
    this.modalView = null;
  }
}
