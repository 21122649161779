import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationError} from 'class-validator';
import {MULTI_CDN_SETTINGS_CONFIG_EVENT} from '../index';
import {Action, MultiCdnSettingsConfig, Profile} from '../models/multi-cdn-settings-config';

export const MULTI_CDN_SETTINGS_PROFILE_DELETE_EVENT = {
  ProfileDelete: 'MultiCdnSettingsProfiles:Delete',
};

@autoinject()
export class MultiCdnSettingsProfiles {
  @bindable
  public multiCdnSettings: MultiCdnSettingsConfig;

  @bindable
  public validationErrors: ValidationError[];

  @bindable
  public callbacks;

  @bindable
  public initialError;

  @bindable
  public advancedStreamRoutingFeaturesEnabled;

  public currentProfile;
  public canDeactivate;
  public pendingConfigSwitch = false;
  public subscriptions: Subscription[] = [];
  public deleteTip;
  public hasError = false;
  public newProfileName: string = '';
  public tcNewProfile;
  public profileIsDuplicate: boolean = false;
  public profileNameRequired: boolean = false;
  public profileScoreTooltipText: string = 'Profiles with lower priority are considered first';

  constructor(public eventAggregator: EventAggregator, public router: Router) {}

  public attached() {
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        MULTI_CDN_SETTINGS_CONFIG_EVENT.NewConfigSaved,
        () =>
          new Promise(resolve => {
            resolve();
          }),
      ),
    );
    if (!this.currentProfile && this.multiCdnSettings && this.multiCdnSettings.profiles) {
      this.currentProfile = this.multiCdnSettings.profiles[0];
    }
  }

  public setCurrentConfig(profile) {
    // this is called by config nav item select or by new config create event.

    this.currentProfile = profile;
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }

  public deleteProfile() {
    let profileToDelete;
    let deletedIndex = -1;
    let nextIndex = -1;

    for (const profile of this.multiCdnSettings.profiles) {
      deletedIndex += 1;
      if (profile.name === this.currentProfile.name) {
        profileToDelete = profile;
      }
    }
    const index = this.multiCdnSettings.profiles.indexOf(profileToDelete);
    this.multiCdnSettings.profiles.splice(index, 1);

    if (this.multiCdnSettings.profiles.length) {
      if (deletedIndex === this.multiCdnSettings.profiles.length) {
        nextIndex = deletedIndex - 1;
      } else {
        nextIndex = deletedIndex;
      }
      this.setCurrentConfig(this.multiCdnSettings.profiles[nextIndex]);
    } else {
      this.currentProfile = null;
    }
    this.callbacks.onChange();
  }

  public createNewProfile() {
    this.profileNameRequired = false;
    this.profileIsDuplicate = false;
    if (this.newProfileName.trim() === '') {
      this.profileNameRequired = true;
      return;
    }

    for (const prof of this.multiCdnSettings.profiles) {
      if (prof.name === this.newProfileName.trim()) {
        this.profileIsDuplicate = true;
        return;
      }
    }

    this.profileIsDuplicate = false;
    const profile = new Profile();
    profile.name = this.newProfileName.trim();
    profile.enabled = false;
    profile.priority = 0;
    profile.isDefault = false;

    const action = new Action();
    action.overrideClientCdn = false;
    action.distribution = [];
    profile.actions = action;

    this.currentProfile = profile;

    this.multiCdnSettings.profiles.push(profile);

    // clear out the values & close the tip
    this.profileIsDuplicate = false;
    this.profileNameRequired = false;
    this.newProfileName = '';
    this.callbacks.onChange();
    this.tcNewProfile.hide();
  }
}
