import 'fetch';

import {inject, computedFrom, LogManager} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import {EventAggregator} from 'aurelia-event-aggregator';
import {LocalStorageHelper, MsToHmsValueConverter} from '@bindable-ui/bindable';
import {SessionService} from 'services/session';

import {HyperionPolling} from 'services/hyperion-polling';
import {SlicerService} from './services/slicer';
import {CmsHttpClient} from '../../services/cms-http-client';
import {Notification} from '../../resources/notification/service';

const logger = LogManager.getLogger('led-event');
const MINIMUM_SLICER_VERSION = '22020701';
const MINIMUM_LIVE_PREVIEW_AUDIO_SLICER_VERSION = '22083100';
const STATUS_REFRESH_INTERVAL = 5000;

// TODO: Move this to a better common location
function newPromise() {
  let resolve;
  let reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  promise.resolve = resolve;
  promise.reject = reject;

  return promise;
}
@inject(CmsHttpClient, BindingSignaler, Notification, EventAggregator, SlicerService, SessionService)
export class Event {
  constructor(cmsHttpClient, bindingSignaler, notification, eventAggregator, slicerService, sessionService) {
    this.httpClient = cmsHttpClient.httpClient;
    this.bindingSignaler = bindingSignaler;
    this.notification = notification;
    this.eventAggregator = eventAggregator;
    this.slicerService = slicerService;
    this.session = sessionService;

    this.STATES = {
      pre: 'pre',
      live: 'live',
      post: 'post',
      complete: 'complete',
      none: null,
      testing: 'testing',
      tested: 'tested',
      started: 'started',
      ended: 'ended',
    };
    this.DISPLAY_STATUSES = {
      off_air: 'Off Air',
      testing: 'Testing',
      on_air: 'On Air',
    };

    this.activeSlicerHealth = 'neutral';
    this.activeSlicerDisplay = {
      neutral: false,
      healthy: 'Healthy',
      warning: 'Warning',
      critical: 'Critical',
    };

    this.event = {};
    this._activeSlicerIndex = null;
    this.slicers = [];
    this.connectedSlicerCount = 0;
    this.activeSlicerState = {state: -1};
    this.switcherIndex = -1;
    this.breakSeq = -1;
    this.getEventPromise = newPromise();
    this.displayState = this.DISPLAY_STATUSES.off_air;
    this.msTillStart = 0;
    this.msTillStop = 0;
    // This is set to be less than 0 on purpose for events not using the resume concept
    this.msTillResume = 0;
    this.msTillStartSign = '';
    this.msDuration = 0;
    this.durationInterval = null;
    // we will get this from the initialize-dashboard api
    this.minimumSlicerVersion = '99999999';
    this.minimumSlicerVersionAdPrefetch = '99999999';
    this.minimumSlicerVersionPreviewLiveAudio = '99999999';
    this.versionWarnings = [];
    this.versionWarningsAdPrefetch = [];
    this.allowEnter = false;
    this.entered = false;

    this.failedTimeout = null;
    this.authToken = null;

    this.requireTesting = true;
    this.resumeInterval = null;

    this.sourceViewStorageKey = 'source-view';
    this.sourceViewEnabled = LocalStorageHelper.loadOrDefault(this.sourceViewStorageKey, true);

    this.audienceViewStorageKey = 'audience-view';
    this.audienceViewEnabled = LocalStorageHelper.loadOrDefault(this.audienceViewStorageKey, true);

    this.syndicationPanelStorageKey = 'syndication-panel';
    this.syndicationPanelEnabled = LocalStorageHelper.loadOrDefault(this.syndicationPanelStorageKey, true);

    this.sourceConnectError = null;
    this.correlator = null;

    this.pollTracker = new HyperionPolling({
      ms: STATUS_REFRESH_INTERVAL,
      promiseFn: async () => this.getDashboardStatus(),
      useAfter: true,
    });
  }

  getEvent(guid) {
    return this.httpClient
      .fetch('/live-event/initialize-dashboard', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id: guid}),
      })
      .then(resp => {
        resp.json().then(
          res => {
            this.error = null;
            if (res.error) {
              const err = new Error();
              err.message = res.msg;
              this.getEventPromise.reject(err);
            } else {
              [this.event] = res.events;
              if (this.event.resume_buffer) {
                this.setResumeTimer(this.event.resume_buffer);
              }
              this.minimumSlicerVersion = res.slicer_min_version;
              this.minimumSlicerVersionAdPrefetch = MINIMUM_SLICER_VERSION;
              this.minimumSlicerVersionPreviewLiveAudio = MINIMUM_LIVE_PREVIEW_AUDIO_SLICER_VERSION;

              this.slicers = this.event.slicers;
              this.event.midSlates = res.mid_slates;
              this.authToken = res.authToken;
              this.slicer_conflicts = [];
              this.slateAsVod = res.slateAsVod;
              this.adBreakWarning = this.event.ad_break_warning;

              if (res.slicer_conflicts) {
                this.slicer_conflicts = res.slicer_conflicts;
              }

              if (this.event.pod_id && this.event.pod_id.length) {
                // _.forEach(this.event.pod_id, (pod, currentIndex) => {
                _.forEach(this.event.pod_id, pod => {
                  if (
                    this.event.ad_pods &&
                    this.event.ad_pods[pod.index] &&
                    ((this.event.actual_start && pod.start > this.event.actual_start) ||
                      (!this.event.actual_start && this.event.testing_start && pod.start > this.event.testing_start)) &&
                    !pod.prefetch
                  ) {
                    this.event.ad_pods[pod.index].hasUsed = true;
                  }
                });
              }

              this.marker_list = [];

              if (res.marker_list_new) {
                this.marker_list = _.map(res.marker_list_new, type => ({
                  tags: _.map(type.tags, tag => ({name: tag, timesUsed: 0})),
                  type: type.type,
                }));
              } else if (res.marker_list) {
                this.marker_list = [
                  {
                    tags: [],
                    type: 'segment',
                  },
                ];

                this.marker_list[0].tags = _.map(res.marker_list, tag => ({
                  name: tag,
                  timesUsed: 0,
                }));
              }

              if (this.event.markers) {
                this.parseMarkerLog(this.event.markers);
              }

              // Set countdown stuffs
              if (this.event.expected_start) {
                setInterval(() => {
                  const msTillStart = this.event.expected_start - Date.now();
                  if (msTillStart < 0) {
                    this.msTillStartSign = '-';
                  }
                  this.msTillStart = Math.abs(msTillStart);
                }, 1000);
              }
              if (this.event.actual_start && this.event.expected_stop) {
                setInterval(() => {
                  const msTillStop = this.event.expected_stop - Date.now();
                  this.msTillStop = Math.abs(msTillStop);
                }, 1000);
              }

              // Update event duration every second
              this.setEventTimer();
              this.pollTracker.start();

              // If they have already been working in this event, skip the connection
              // stage
              if (!(this.event.state === 'pre' && !this.event.testing_start)) {
                this.entered = true;
              }

              this.requireTesting = res.require_testing;

              this.bindingSignaler.signal('state-changed');

              if (res.lastState) {
                // If we have a last state - need to set that
                this.slicerService.lastSlicerState = {
                  state: res.lastState.state,
                  time: res.lastState.timeStamp,
                };

                if (res.lastState.state === 1) {
                  this.slicerService.lastSlicerState.duration = res.lastState.duration;
                  this.slicerService.lastSlicerState.pod = res.lastState.pod;
                  this.slicerService.lastSlicerState.prefetch = res.lastState.prefetch;
                }
                if (this.slicerService.lastSlicerState.prefetch) {
                  // eslint-disable-next-line
                  this.slicerService.lastSlicerState.ad_prefetch_remaining =
                    this.slicerService.lastSlicerState.duration;
                  // this.event.ad_pods is what is bound to the pod-row component (HTML)
                  // in order to get the display to update correctly, we need to set hasUsed to false
                  // and set the queue to 1 to indicate we are in prefetch mode for this adPod
                  // This is in case the user refreshes their screen.
                  this.event.ad_pods[res.lastState.pod].hasUsed = false;
                  this.event.ad_pods[res.lastState.pod].queued = 1;
                } else {
                  this.slicerService.lastSlicerState.ad_prefetch_remaining = 0;
                }
              }

              if (_.has(res, 'break_seq')) {
                this.breakSeq = res.break_seq;
              }

              if (this.event.https) {
                this.https = true;
              }

              this.getEventPromise.resolve(true);
            }
          },
          () => {
            const msg = 'Error getting event data';
            this.error = msg;
            this.getEventPromise.reject(msg);
          },
        );
      });
  }

  setEventTimer() {
    clearInterval(this.durationInterval);
    if (this.event.actual_start) {
      if (this.event.actual_stop) {
        this.msDuration = this.event.actual_stop - this.event.actual_start;
      } else {
        this.msDuration = Date.now() - this.event.actual_start;
        this.durationInterval = setInterval(() => {
          this.msDuration = Date.now() - this.event.actual_start;
        }, 1000);
      }
    }
  }

  setResumeTimer(time) {
    clearInterval(this.resumeInterval);
    this.msTillResume = time;
    this.resumeInterval = setInterval(() => {
      if (this.msTillResume < 0) {
        // Don't let the interval keep running
        clearInterval(this.resumeInterval);
      }
      this.msTillResume -= 1000;
    }, 1000);
  }

  getDashboardStatus() {
    this.httpClient
      .fetch(`/live-event/${this.event.id}/dashboard-status`)
      .then(resp => {
        resp.json().then(res => {
          if (res.status) {
            if (res.status.current_state && res.status.current_state.state === 'live' && this.event.state !== 'live') {
              this.event.testing_complete = res.status.current_state.testing_complete;
              this.event.actual_start = res.status.current_state.actual_start;
              this.event.state = res.status.current_state.state;
              this.setEventTimer();
              this.pollTracker.start();
              this.bindingSignaler.signal('state-changed');
            } else if (
              res.status.current_state &&
              [
                'complete',
                'post',
                'resume',
              ].indexOf(res.status.current_state.state) !== -1 &&
              this.event.state === 'live'
            ) {
              clearInterval(this.durationInterval);

              // If we are in resume
              if (res.status.current_state.state === 'resume') {
                this.setResumeTimer(res.status.current_state.resume_buffer);
              } else {
                // otherwise, stop the pollTracker
                this.pollTracker.stop();
              }
              if (res.status.current_state.slate_id != null) {
                this.event.slate_id = res.status.current_state.slate_id;
              }
              this.event.state = res.status.current_state.state;
              this.bindingSignaler.signal('state-changed');
            }
            if (res.status.slicer_id) {
              // if we haven't entered an event, but we have a slicer_id, then someone
              // started it either another dashboard OR via API Call
              if (!this.event.entered) {
                this.entered = true;
              }
              for (let i = 0; i < this.event.slicers.length; i += 1) {
                if (this.event.slicers[i].id) {
                  if (this.event.slicers[i].id === res.status.slicer_id.id) {
                    if (!this.event.slicers[i].owner) {
                      // If owner id is blank - we assume it is the event owner
                      this.event.slicers[i].owner = this.event.owner;
                    }
                    if (this.event.slicers[i].owner === res.status.slicer_id.owner) {
                      // If we find a match - this is the source we were connected to
                      // last
                      this._activeSlicerIndex = i;
                      break;
                    }
                  }
                }
              }
            }
            if (res.status.current_state && res.status.current_state.testing_start) {
              this.event.testing_start = res.status.current_state.testing_start;
              this.event.testing_complete = null;
              if (res.status.current_state.testing_complete) {
                this.event.testing_complete = res.status.current_state.testing_complete;
              }
            }
            if (res.status.lastState && res.status.lastState.state !== null) {
              // If we have a last state - need to set that
              this.slicerService.lastSlicerState = {
                state: res.status.lastState.state,
                time: res.status.lastState.timeStamp,
              };

              if (res.status.lastState.state === 1) {
                this.slicerService.lastSlicerState.duration = res.status.lastState.duration;
                this.slicerService.lastSlicerState.pod = res.status.lastState.pod;
                this.slicerService.lastSlicerState.prefetch = res.status.lastState.prefetch;
              }
              if (this.slicerService.lastSlicerState.prefetch) {
                // eslint-disable-next-line
                this.slicerService.lastSlicerState.ad_prefetch_remaining = this.slicerService.lastSlicerState.duration;
                // this.event.ad_pods is what is bound to the pod-row component (HTML)
                // in order to get the display to update correctly, we need to set hasUsed to false
                // and set the queue to 1 to indicate we are in prefetch mode for this adPod
                // This is in case the user refreshes their screen.
                this.event.ad_pods[res.status.lastState.pod].hasUsed = false;
                this.event.ad_pods[res.status.lastState.pod].queued = 1;
              } else {
                this.slicerService.lastSlicerState.ad_prefetch_remaining = 0;
              }
            }
            if (res.status.slate_id) {
              this.event.slate_id = res.status.slate_id;
            }
            if (res.status.log) {
              res.status.log.forEach(x => this.event.log.push(x));
            }
          }
        });
      })
      .catch(err => {
        this.notification.error(`Error getting dashboard status. ${err}`, undefined, null);
      });
  }

  // Get the current "source" based on what was last set in slicer_id and matching that
  // with the current list of available sources
  @computedFrom('event.slicer_id', 'event.slicers')
  get activeSlicerIndex() {
    this._activeSlicerIndex = null;
    // Else look up what was set last
    const _eventOwner = this.event.owner;

    // If we have slicer_id data in the Event
    if (this.event.slicer_id) {
      // Double check that this data is an array -
      if (Array.isArray(this.event.slicer_id)) {
        // See if we are empty
        if (this.event.slicer_id.length > 0) {
          const source = this.event.slicer_id[this.event.slicer_id.length - 1];
          // This shouldn't happen, but If we don't have an owner and slicer_id don't
          // bother checking
          if (!source.owner || !source.id) {
            return this._activeSlicerIndex;
          }

          // Check to see if the source exists in our list of available sources
          for (let i = 0; i < this.event.slicers.length; i += 1) {
            if (this.event.slicers[i].id) {
              if (this.event.slicers[i].id === source.id) {
                if (!this.event.slicers[i].owner) {
                  // If owner id is blank - we assume it is the event owner
                  this.event.slicers[i].owner = _eventOwner;
                }
                if (this.event.slicers[i].owner === source.owner) {
                  // If we find a match - this is the source we were connected to
                  // last
                  this._activeSlicerIndex = i;
                  break;
                }
              }
            }
          }
        }
      }
    }

    return this._activeSlicerIndex;
  }

  // The last mid slate in the list - if it doesn't have an "end" value is the active mid slate
  @computedFrom('event.slate_id')
  get activeMidSlate() {
    // Check to see if our slate_id is an array - before we try to do fancy things
    if (this.event.slate_id.constructor === Array) {
      const index = this.event.slate_id.length;
      if (index > 0) {
        const midSlate = this.event.slate_id[index - 1];
        if (!midSlate.end) {
          if (midSlate.id) {
            return midSlate.id;
          }
        }
      }
    }

    return null;
  }

  @computedFrom('slicerService.lastSlicerState')
  get switcherStatus() {
    if (this.slicerService.lastSlicerState.state === 3) {
      return 'Slate';
    }
    if (this.slicerService.lastSlicerState.state === 0) {
      return 'Slicer';
    }
    if (this.slicerService.lastSlicerState.state === 1 && !this.slicerService.lastSlicerState.duration) {
      return 'Ad Break';
    }
    if (this.slicerService.lastSlicerState.state === 1 && this.slicerService.lastSlicerState.duration) {
      return 'Ad Pod';
    }

    return null;
  }

  @computedFrom('slicerService.lastSlicerState')
  get activeAdPod() {
    if (this.slicerService.lastSlicerState.state === 1 && this.slicerService.lastSlicerState.pod > -1) {
      return this.slicerService.lastSlicerState.pod;
    }

    return -1;
  }

  @computedFrom('event.state')
  get allowPreSlateChange() {
    if (this.event.state) {
      return this.event.state === this.STATES.pre;
    }
    return true;
  }

  @computedFrom('event.testing_start', 'event.testing_complete')
  get isTesting() {
    return this.event.testing_start && !this.event.testing_complete;
  }

  @computedFrom('event.low_latency')
  get isLowLatency() {
    return !!this.event.low_latency;
  }

  @computedFrom('event.enable_ad_prefetch', '_activeSlicerIndex')
  get allowAdPrefetch() {
    return (
      this._activeSlicerIndex !== null &&
      !!this.event.enable_ad_prefetch &&
      this.slicers[this._activeSlicerIndex].version >= this.minimumSlicerVersionAdPrefetch
    );
  }

  @computedFrom('_activeSlicerIndex')
  get liveSlicerAudioEnabled() {
    return this._activeSlicerIndex !== null && this.slicers[this._activeSlicerIndex].livePreviewAudioEnabled;
  }

  setPreEventSlate(slateId = '') {
    if (this.event && slateId !== this.event.pre_slate) {
      this.httpClient
        .fetch(`/live-event/${this.event.id}/new-pre-slate`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pre_slate_id: slateId,
          }),
        })
        .then(resp => {
          resp.json().then(data => {
            if (data.error) {
              // logger.error(data.msg);
            } else {
              this.replaceLog(data.event.log);
              this.event.pre_slate = slateId;
            }
          });
        });
    }
  }

  startTest = _.debounce(
    () => {
      if (!this.slicerService.slicerConnected(this)) {
        this.notification.add('error', 'Active slicer must be connected to start test.', 'Start Test', null);
        return;
      }

      if (!this.event || !this.event.id) {
        return;
      }

      this.httpClient
        .fetch(`/live-event/${this.event.id}/start-test`)
        .then(resp => {
          resp.json().then(data => {
            if (data.slicer_conflicts) {
              let message = `The following slicer${
                data.slicer_conflicts.length === 1 ? ' is' : 's are'
              } currently in use:<ul>`;
              data.slicer_conflicts.forEach(conflict => {
                // eslint-disable-next-line max-len
                message += `<li> ${conflict.slicer_id} in <a href="live-event-dashboard.html?event=${conflict.event_id}">${conflict.event_desc}</li>`;
              });
              message += '</ul>';
              this.notification.add('error', message, 'Slicer conflicts', null);
            } else {
              if (data.event.slate_id != null) {
                this.event.slate_id = data.event.slate_id;
              }
              this.event.testing_start = data.event.testing_start;
              this.event.testing_complete = data.event.testing_complete;
              this.replaceLog(data.event.log);
              this.switchToSlicer(data.correlator);
              this.bindingSignaler.signal('state-changed');
            }

            this.resetMarkerLog();
            this.resetAdPodUsed();
            this.resetBreakSeq();
          });
        })
        .catch(err => {
          logger.error(err);
        });
    },
    1000,
    {leading: true, trailing: false},
  );

  completeTest = _.debounce(
    async () => {
      if (!this.event || !this.event.id) {
        return;
      }

      this.slicerService.lastSlicerState = {
        state: this.slicerService.SLICERSTATES.UNKNOWN,
        time: Math.floor(Date.now()),
      };

      try {
        const responce = await this.httpClient.fetch(`/live-event/${this.event.id}/complete-test`);
        const data = await responce.json();
        this.pollTracker.stop();
        if (data.event.slate_id != null) {
          this.event.slate_id = data.event.slate_id;
        }
        this.event.testing_complete = data.event.testing_complete;
        this.replaceLog(data.event.log);
        this.bindingSignaler.signal('state-changed');

        this.resetBreakSeq();
        this.slicerBlackout();
      } catch (err) {
        logger.error(err);
      }
    },
    1000,
    {leading: true, trailing: false},
  );

  resetMarkerLog() {
    // Reset marker log
    this.event.marker_log = [];
    _.forEach(this.marker_list, type => {
      _.forEach(type.tags, tag => {
        tag.timesUsed = 0;
      });
    });
  }

  resetBreakSeq() {
    if (this.breakSeq > -1) {
      this.breakSeq = 0;
    }
  }

  resetAdPodUsed() {
    _.forEach(this.event.ad_pods, pod => {
      pod.hasUsed = false;
    });
  }

  startEvent = _.debounce(
    () => {
      if (!this.slicerService.slicerConnected(this)) {
        this.notification.add('error', 'Active slicer must be connected to start event.', 'Start Event', null);
        return;
      }

      if (!this.event || !this.event.id) {
        return;
      }

      this.httpClient
        .fetch(`/live-event/${this.event.id}/start`)
        .then(resp => {
          resp.json().then(data => {
            if (data.slicer_conflicts) {
              let message = `The following slicer${
                data.slicer_conflicts.length === 1 ? ' is' : 's are'
              } currently in use:<ul>`;
              data.slicer_conflicts.forEach(conflict => {
                // eslint-disable-next-line max-len
                message += `<li> ${conflict.slicer_id} in <a target="_blank" href="live-event-dashboard.html?event=${conflict.event_id}">${conflict.event_desc}</a></li>`;
              });
              message += '</ul>';
              this.notification.add('error', message, 'Slicer conflicts', null);
            } else {
              if (data.event.slate_id != null) {
                this.event.slate_id = data.event.slate_id;
              }
              this.event.testing_complete = data.event.testing_complete;
              this.event.actual_start = data.event.actual_start;
              this.event.state = data.event.state;
              this.setEventTimer();
              this.pollTracker.start();
              this.replaceLog(data.event.log);
              this.switchToSlicer(data.correlator);
              this.bindingSignaler.signal('state-changed');
            }

            this.resetMarkerLog();
            this.resetAdPodUsed();
            this.resetBreakSeq();
          });
        })
        .catch(err => {
          this.notification.error(`Error starting event. ${err}`, undefined, null);
        });
    },
    1000,
    {leading: true, trailing: false},
  );

  stopEvent = _.debounce(
    () => {
      if (!this.event || !this.event.id) {
        return;
      }

      this.switcherIndex = -1;
      this.httpClient.fetch(`/live-event/${this.event.id}/end`).then(resp => {
        resp.json().then(data => {
          clearInterval(this.durationInterval);
          this.pollTracker.stop();

          // If resume - we do a content start
          if (data.event.state === 'resume') {
            this.setResumeTimer(data.event.resume_buffer);
            this.switchToSlicer(data.correlator);
          } else {
            // stop slicers
            this.slicerBlackout();
          }
          if (data.event.slate_id != null) {
            this.event.slate_id = data.event.slate_id;
          }
          this.event.state = data.event.state;
          this.replaceLog(data.event.log);
          // Why do we want to stop polling :) - we should keep polling - but if just in
          // case this.slicerService.stopPollActiveState(this);
          this.bindingSignaler.signal('state-changed');
        });
      });
    },
    1000,
    {leading: true, trailing: false},
  );

  resumeEvent = _.debounce(
    () => {
      if (!this.event || !this.event.id) {
        return;
      }

      this.httpClient
        .fetch(`/live-event/${this.event.id}/resume`)
        .then(resp => {
          resp.json().then(data => {
            if (data.error) {
              this.notification.error(`Error resuming event: ${data.msg}`, undefined, null);
            } else {
              if (data.event.slate_id != null) {
                this.event.slate_id = data.event.slate_id;
              }
              this.event.state = data.event.state;
              this.event.actual_stop = data.event.actual_stop;
              this.replaceLog(data.event.log);
              this.setEventTimer();
              this.pollTracker.start();
              this.bindingSignaler.signal('state-changed');
            }
          });
        })
        .catch(err => {
          logger.error(err);
        });
    },
    1000,
    {leading: true, trailing: false},
  );

  refreshStream(params) {
    if (!this.event || !this.event.id) {
      return;
    }

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const bodyParams = {
      suffix: 'm3u8',
      isSafari,
    };
    this.httpClient
      .fetch(`/live-event/${this.event.id}/refresh_player${params}`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyParams),
      })
      .then(resp => {
        resp.json().then(data => {
          if (data.error) {
            this.notification.error(`Error getting stream URL: ${data.msg}`, undefined, null);
          } else {
            this.event.stream_url = data.stream_url;
          }
        });
      })
      .catch(err => {
        logger.error(err);
      });
  }

  @computedFrom('event.testing_start', 'event.testing_complete', 'event.state')
  get displayStatus() {
    if (this.event.testing_start != null && this.event.testing_complete === null) {
      return this.DISPLAY_STATUSES.testing;
    }
    if (this.event.state === 'live') {
      return this.DISPLAY_STATUSES.on_air;
    }
    return this.DISPLAY_STATUSES.off_air;
  }

  switchToSlicer(correlator) {
    this.slicerService.contentStart(this, correlator);
    this.slicerService.lastSlicerState = {
      state: this.slicerService.SLICERSTATES.SOURCE,
      time: Math.floor(Date.now()),
    };
    this.switcherIndex = 0;
  }

  slicerBlackout() {
    const prom = this.slicerService.blackout(this);
    if (prom !== null) {
      prom
        // eslint-disable-next-line no-empty-function
        .done(() => {})
        .fail(() => {
          this.notification.error(
            'Error on slicer blackout. You may have to black out your slicers manually.',
            undefined,
            null,
          );
          this.slicerService.logThrottle(this.event.id, 'Could not switch to slicer blackout: Slicer disconnected');
        });
    }
    this.switcherIndex = -1;
    return prom;
  }

  replaceLog(newLog) {
    // simply re-assigning the new log will wipe out binding engine watchers,
    // so we need to clear the array and re-add items
    // http://stackoverflow.com/questions/1232040/how-do-i-empty-an-array-in-javascript
    this.event.log.length = 0;
    newLog.forEach(item => {
      this.event.log.push(item);
    });
  }

  setSlicer(slicerId, ownerId) {
    return this.httpClient
      .fetch(`/live-event/${this.event.id}/switch-slicer`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          slicer_id: slicerId,
          owner_id: ownerId,
        }),
      })
      .then(resp =>
        resp.json().then(data => {
          if (!data.error) {
            this.replaceLog(data.event.log);
            this.event.slicer_id = data.event.slicer_id;
          }
          return data;
        }),
      );
  }

  addMarker(marker) {
    return this.httpClient
      .fetch(`/live-event/${this.event.id}/set-marker`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tag: marker.tag,
          type: marker.type,
        }),
      })
      .then(resp =>
        resp.json().then(data => {
          if (!data.error) {
            this.parseMarkerLog(data.event.markers);
            this.replaceLog(data.event.log);

            return this.slicerService.slicersApi(this, '/timedmeta', 'POST', {
              key: `${data.event.type || 'segment'}:${data.event.tag}`,
              value: `${data.event.timestamp}`,
            });
          }

          return Promise.reject();
        }),
      );
  }

  parseMarkerLog(data) {
    _.forEach(this.marker_list, type => {
      _.forEach(type.tags, tag => {
        tag.timesUsed = 0;
      });
    });

    let timeStart = 0;

    if (this.event.actual_start) {
      timeStart = this.event.actual_start;
    } else if (this.event.testing_start) {
      timeStart = this.event.testing_start;
    }

    this.event.marker_log = _.reverse(
      _.map(data, (logEntry, index) => {
        const markerLog = {
          index,
          name: logEntry.tag,
          type: logEntry.type || 'segment',
          when: MsToHmsValueConverter.transform(logEntry.ts - timeStart),
        };

        const typeIndex = _.findIndex(this.marker_list, template => template.type === markerLog.type);

        if (typeIndex > -1) {
          const markerIndex = _.findIndex(this.marker_list[typeIndex].tags, tag => tag.name === markerLog.name);

          if (markerIndex > -1) {
            this.marker_list[typeIndex].tags[markerIndex].timesUsed += 1;
          } else {
            this.marker_list[typeIndex].tags.push({
              name: markerLog.name,
              timesUsed: 0,
            });
          }
        } else {
          this.marker_list.push({
            tags: [
              {
                name: markerLog.name,
                timesUsed: 0,
              },
            ],
            type: markerLog.type,
          });
        }

        return markerLog;
      }),
    );
  }

  // Determine if we are in a "Live" state
  @computedFrom('displayStatus')
  get isLive() {
    const enabledStatuses = [
      this.DISPLAY_STATUSES.on_air,
      this.DISPLAY_STATUSES.testing,
    ];
    return enabledStatuses.indexOf(this.displayStatus) >= 0;
  }

  // Determine if we are in are only in the "Live" state
  @computedFrom('displayStatus')
  get isReallyLive() {
    const enabledStatuses = [this.DISPLAY_STATUSES.on_air];
    return enabledStatuses.indexOf(this.displayStatus) >= 0;
  }

  isEnded() {
    return (
      [
        'post',
        'complete',
      ].indexOf(this.event.state) !== -1 ||
      (this.event.state === 'resume' && this.msTillResume < 0)
    );
  }

  saveSourceView(value) {
    LocalStorageHelper.save(this.sourceViewStorageKey, value);
  }

  saveAudienceView(value) {
    LocalStorageHelper.save(this.audienceViewStorageKey, value);
  }

  saveSyndicationPanel(value) {
    LocalStorageHelper.save(this.syndicationPanelStorageKey, value);
  }
}
