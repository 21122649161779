import {Type} from 'class-transformer';
import {IsBoolean, IsDefined, IsNotEmpty, IsNumber, IsString, ValidateNested} from 'class-validator';
import {IntegrationNameType} from './enums-config';

export const ERROR_MESSAGE_SELECT_OPTION = 'You must choose an option';

export class YoutubeCredentials {
    @IsString()
    public access_token: string = '';

    @IsString()
    public id_token: string = '';

    @IsNumber()
    public expires_in: number = 0;

    @IsString()
    public token_type: string = '';

    @IsString()
    public token_expiry: string = '';

    @IsString()
    public refresh_token: string = '';
}

export class YoutubeMetaMap {
    @IsString()
    public profile_id: string = '';

    @IsString()
    public category: string = '';

    @IsDefined({message: ERROR_MESSAGE_SELECT_OPTION})
    @IsNotEmpty({message: ERROR_MESSAGE_SELECT_OPTION})
    @IsString()
    public description: string = '';

    @IsDefined({message: ERROR_MESSAGE_SELECT_OPTION})
    @IsNotEmpty({message: ERROR_MESSAGE_SELECT_OPTION})
    @IsString()
    public title: string = '';

    @IsString()
    public privacyStatus: string = '';

    @IsString()
    public keywords: string = '';
}

export class Youtube {
    @ValidateNested()
    @Type(() => YoutubeCredentials)
    public credentials: YoutubeCredentials = new YoutubeCredentials();

    @ValidateNested()
    @Type(() => YoutubeMetaMap)
    public metamap: YoutubeMetaMap = new YoutubeMetaMap();

    @IsString()
    public name: string = IntegrationNameType.YouTube;

    @IsBoolean()
    public enabled: boolean = false;

    public newlyAdded: boolean = false;

    public constructor(init?: Partial<Youtube>) {
        Object.assign(this, init);
    }
}
