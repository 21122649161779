import {Config as ConfigBase} from 'resources/config/import/models/config';
import {ConfigInput as ConfigInputBase} from 'resources/config/import/models/config-input';

export class Config extends ConfigBase {
    public input = new ConfigInput();
}

export class ConfigInput extends ConfigInputBase {
    get description() {
        return this.table.configTableData.getLastValue('description');
    }

    get input() {
        return this.table.configTableData.getLastValue('input');
    }

    get slicerID() {
        return this.table.configTableData.getLastValue('slicerID');
    }

    public username: string;
}
