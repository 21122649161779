import {autoinject, computedFrom} from 'aurelia-framework';
import {HyperionPolling} from 'services/hyperion-polling';
import {Router} from 'aurelia-router';
import {BetaMode} from 'services/beta-mode';
import {authorizationConstants} from 'services/authorization';
import {VodJobService} from '../services/jobs';
import {DEFAULT_PAGE_SIZE_OPTIONS, VIDEO_EXTENSIONS, CONF_EXTENSIONS} from '../models/defaults';
import {JobVOD, VodUploaderFile} from '../models/models';
import {
  TemplateOverride,
  Template,
  KeyValue,
  OffsetKeyValue,
} from '../../settings/vod-uploader-templates/models/templates-model';
import CfgParser from './cfg-parser';

@authorizationConstants
@autoinject()
export class Uploads {
  public isAuthorized = true;
  public isLoading: boolean = false;

  /** Indicates the user is waiting for the previous page of jobs to load */
  public isLoadingPrev: boolean = false;

  /** Indicates the user is waiting for the next page of jobs to load */
  public isLoadingNext: boolean = false;

  public isPopoverClearOpen = false;
  public wasHidden: boolean = false;
  public lastDropTarget: any;

  public pollTracker: HyperionPolling = null;
  public pollInterval: number = 8 * 1000;

  public media_url: string = '';
  public key: string = '';
  public secret: string = '';
  public jobs_overwrite: boolean = true;

  public defaultPageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;

  /** The scrollable content area which contains the table of jobs */
  public pageContent: any;
  public dropZone: any;
  public fileInput: any;
  public folderInput: any;
  public tabActive: string = 'local';
  public dialogFileExsit: any;
  public duplicateIndex: any = {
    index: 0,
    files: [],
  };

  public dialogCancelJob: any;
  public selectedJob: JobVOD;
  public templates: Template[] = [];
  public overrideDialog: any;
  public dialogOverrideModel: {file: VodUploaderFile; template: TemplateOverride} = {
    file: undefined,
    template: undefined,
  };

  public templateSelected: string = '';

  public async onChangeTemplateSelected(event: any) {
    const {value} = event.target;
    let temp: Template;
    if (value === '') {
      temp = this.templates.find(template => template.id === this.templateSelected);
      temp.is_default = false;
    } else {
      temp = this.templates.find(template => template.id === value);
      temp.is_default = true;
    }
    await this.service.updateTemplate(temp);
    this.templateSelected = value;
  }

  public cfg: CfgParser;

  constructor(public service: VodJobService, protected router: Router, public betaMode: BetaMode) {
    if (betaMode.hasScope('vod_uploader')) {
      this.pollTracker = new HyperionPolling({
        callbackFn: res => this.service.processJobs(res, true),
        getParams: () => ({include_deleted: false}),
        ms: this.pollInterval,
        includeDeleted: false,
        // eslint-disable-next-line consistent-return
        promiseFn: params => {
          if (!document.hidden) {
            const p = this.wasHidden ? {} : params;
            this.wasHidden = false;
            return this.service.getJobs(p);
          }

          this.wasHidden = true;
        },
        useAfter: true,
      });
    } else {
      this.isAuthorized = false;
    }
  }

  public addAdBreak() {
    this.dialogOverrideModel.template.breaks.push('');
  }

  public removeAdBreak(index: number) {
    const {breaks} = this.dialogOverrideModel.template;
    breaks.splice(index, 1);
  }

  public addMetadata() {
    this.dialogOverrideModel.template.meta.push(new KeyValue());
  }

  public removeMetadata(item: KeyValue) {
    const {meta} = this.dialogOverrideModel.template;
    _.remove(meta, m => m === item);
  }

  public addTimedMetadata() {
    this.dialogOverrideModel.template.timedmeta.push(new OffsetKeyValue());
  }

  public removeTimedMetadata(item: OffsetKeyValue) {
    const {timedmeta} = this.dialogOverrideModel.template;
    _.remove(timedmeta, m => m === item);
  }

  @computedFrom('service.selectedFiles.length')
  get uploadQueueCount() {
    return this.service.selectedFiles.filter(x => !x.isHidden).length;
  }

  @computedFrom('media_url', 'key', 'secret')
  get isKeySecretRequired() {
    const {media_url} = this;

    if (media_url && media_url.length && media_url.includes('s3://')) {
      return true;
    }

    return false;
  }

  public acceptFiles() {
    const allExts = VIDEO_EXTENSIONS.concat(CONF_EXTENSIONS);
    return `,.${allExts.join(',.')}`;
  }

  // eslint-disable-next-line class-methods-use-this
  public isFileValid(file: VodUploaderFile) {
    const regexVideo = new RegExp(`.(${VIDEO_EXTENSIONS.join('|')})$`);
    const regexConf = new RegExp(`.(${CONF_EXTENSIONS.join('|')})$`);

    if (VIDEO_EXTENSIONS.length && file.name.match(regexVideo)) {
      file.isVideo = true;
      return true;
    }

    if (CONF_EXTENSIONS.length && file.name.match(regexConf)) {
      return true;
    }
    return false;
  }

  @computedFrom('service.jobs')
  get showingFromTo() {
    const recordsPriorPages = (this.service.meta.page - 1) * this.service.meta.limit;
    const from = recordsPriorPages + 1;
    const to = recordsPriorPages + this.service.jobs.length;
    return `${from} - ${to}`;
  }

  get isFilesUploading() {
    const {selectedFiles, isUploading} = this.service;
    return isUploading || selectedFiles.filter(file => file.http && file.http.isRequesting).length > 0;
  }

  public get HasMorePages() {
    const totalPages = Math.ceil(this.service.meta.total / this.service.meta.limit);
    return this.service.meta.page < totalPages;
  }

  public changePageSize(pageSize: number) {
    this.service.meta.limit = pageSize;
    this.service.meta.page = 1;

    this.service.reloadJobs();
  }

  public async prevPage() {
    if (this.service.meta.page <= 1) {
      return;
    }
    this.service.meta.page -= 1;
    this.isLoadingPrev = true;
    await this.service.reloadJobs();
    this.isLoadingPrev = false;
    this.pageContent.scrollToTop();
  }

  public async nextPage() {
    if (this.HasMorePages === false) {
      return;
    }
    this.service.meta.page += 1;
    this.isLoadingNext = true;
    await this.service.reloadJobs();
    this.isLoadingNext = false;
    this.pageContent.scrollToTop();
  }

  public get clearableJobs(): string[] {
    const result = this.service.jobs
      .filter(job => job.state !== 'assigned' && job.state !== 'waiting')
      .map(job => job.id);
    if (!result.length) this.isPopoverClearOpen = false;
    return result;
  }

  public async attached() {
    if (!this.isAuthorized) return;

    window.addEventListener('dragenter', this.dragEnterHandler);
    window.addEventListener('dragleave', this.dragLeaveHandler);
    window.addEventListener('dragover', this.dragOverHandler);
    window.addEventListener('drop', this.dragDropHandler);

    const res = await this.service.getJobs({}, true);
    this.service.jobs = [];
    this.service.processJobs(res);

    try {
      this.templates = await this.service.getTemplates();

      // set template selected
      const sel = this.templates.find(x => x.is_default === true);
      this.templateSelected = (sel && sel.id) || '';
    } catch (error) {
      this.templates = [];
    }

    if (this.pollTracker) {
      this.pollTracker.start();
    }

    this.cfg = new CfgParser(this.service.selectedFiles);
  }

  public detached() {
    if (!this.isAuthorized) return;

    window.removeEventListener('dragenter', this.dragEnterHandler);
    window.removeEventListener('dragleave', this.dragLeaveHandler);
    window.removeEventListener('dragover', this.dragOverHandler);
    window.removeEventListener('drop', this.dragDropHandler);

    this.pollTracker.stop();
    this.pollTracker = null;
  }

  public deleteJob(job: JobVOD) {
    this.service.deleteJob(job);
  }

  public onCancelJob(job: JobVOD) {
    this.selectedJob = job;
    this.dialogCancelJob.show();
  }

  public cancelJob(jod: JobVOD) {
    this.service.cancelJob(jod);
    this.dialogCancelJob.hide();
  }

  public async createCFGFiles() {
    const template = this.templates.find(x => x.id === this.templateSelected);
    await this.cfg.createFiles(template);
  }

  public async doUploadFiles() {
    await this.createCFGFiles();
    this.service.uploadFiles(this.service.selectedFiles);
  }

  public async doUpload() {
    const data = {
      call_params: {
        media_url: this.media_url,
        // desc: "Superhero.mp4",
        s3: {key: this.key, secret: this.secret},
        recreate_duplicates: this.jobs_overwrite,
      },
    };

    this.isLoading = true;
    try {
      await this.service.uploadVODUrl(data);
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
    }
  }

  public dragEnterHandler = event => {
    if (this.tabActive !== 'local' || this.isFilesUploading) {
      return;
    }
    this.lastDropTarget = event.target;
    this.dropZone.classList.add('is-active');
  };

  public dragOverHandler = event => {
    event.preventDefault();

    if (this.tabActive !== 'local' || this.isFilesUploading) {
      return;
    }
    this.dropZone.classList.add('is-active');
  };

  public dragLeaveHandler = event => {
    event.preventDefault();

    if (this.tabActive !== 'local' || this.isFilesUploading) {
      return;
    }
    if (event.target === this.lastDropTarget || event.target === document) {
      this.dropZone.classList.remove('is-active');
    }
  };

  public dragDropHandler = event => {
    event.preventDefault();

    if (this.tabActive !== 'local' || this.isFilesUploading) {
      return;
    }
    const dt = event.dataTransfer;

    if (dt.files) {
      const files: any = Array.from(dt.files);
      this.addSelectedFiles(files);
    }

    this.dropZone.classList.remove('is-active');
  };

  public tabChange(event) {
    const {detail} = event;
    this.tabActive = detail.name;
  }

  public onSelectFiles(elm) {
    const files: any = Array.from(elm.files);
    this.addSelectedFiles(files);
    elm.value = null;
  }

  public yesButtonHandler(event) {
    event.preventDefault();
    const {files, index} = this.duplicateIndex;
    this.service.selectedFiles.unshift(files[index]);
    this.dialogFileExsit.hide();
  }

  public onCloseDuplicateDialog(event) {
    event.preventDefault();
    const {files, index} = this.duplicateIndex;
    this.addSelectedFiles(files, index + 1);
  }

  public addSelectedFiles(files: File[], index: number = 0) {
    const _files = files.map(file => new VodUploaderFile(file, this.service.selectedFiles));
    const validFiles = _files.filter((file: VodUploaderFile) => this.isFileValid(file));

    for (let i = index; i < validFiles.length; i++) {
      const file = validFiles[i];
      const fileNameExist =
        this.service.selectedFiles.filter(f => f.name === file.name && f.file.size === file.size).length > 0;

      this.duplicateIndex.index = i;
      this.duplicateIndex.files = validFiles;

      if (!fileNameExist) {
        this.service.selectedFiles.unshift(file);
      } else {
        this.dialogFileExsit.show();
        break;
      }
    }
  }

  public onUploadCancel(file: VodUploaderFile) {
    this.service.uploadAbort(file);
  }

  public async onUploadRestart(file: VodUploaderFile) {
    // await this.createCFGFiles();
    this.service.uploadFiles([file]);
  }

  public onRemoveFile(file: VodUploaderFile) {
    this.service.uploadAbort(file);
    this.service.removeSelectedFile(file);
  }

  public openOverrideDialog(file: VodUploaderFile) {
    this.dialogOverrideModel = {
      file: file as VodUploaderFile,
      template: _.cloneDeep(file.template),
    };

    this.overrideDialog.show();
  }

  public onOverrideSave() {
    this.dialogOverrideModel.file.template = this.dialogOverrideModel.template;
    this.overrideDialog.hide();
  }

  public onOverrideCancel() {
    this.overrideDialog.hide();
  }

  public removeAllselectedFiles() {
    const {selectedFiles} = this.service;
    selectedFiles.splice(0, selectedFiles.length);
  }

  // eslint-disable-next-line class-methods-use-this
  public handleRequestClose(event: CustomEvent) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    }

    return true;
  }
}
