exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._7_dJB{color:var(--inline-clock-text-color);display:inline-block;font-family:var(--ff_mono);font-size:var(--inline-clock-font-size);height:var(--inline-clock-height)}", ""]);

// exports
exports.locals = {
	"time": "_7_dJB"
};