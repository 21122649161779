import {CTableActions, CTableCol, CToastsService} from '@bindable-ui/bindable';

import {DialogService} from 'aurelia-dialog';
import {Subscription} from 'aurelia-event-aggregator';
import {autoinject, BindingEngine, computedFrom, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {PLATFORM} from 'aurelia-pal';

import {HyperionPolling} from 'services/hyperion-polling';
import {LibraryService} from 'services/library-service';
import {SessionService} from 'services/session';
import {BetaMode} from 'services/beta-mode';

import {RuleService} from '../../rules/services/rules';

import {BlackoutService} from '../services/blackouts';
import {LiveChannelsService} from '../services/live-channels';
import {ScheduleEntryService} from '../services/scheduled-entry';

import {Blackout} from '../models/models';
import {HyperionFilterParams} from '../../models/models';
import {BaseChannelSingle} from './base';

const log = LogManager.getLogger('live-channels/channels/single/blackout');

@autoinject()
export class ChannelBlackout extends BaseChannelSingle {
  /*
   * Computed Properties
   */

  @computedFrom('blackoutService.isSaving', 'isLoading', 'scheduleEnabled')
  get addButtonDisabled() {
    return this.blackoutService.isSaving || this.isLoading || this.scheduleEnabled;
  }

  @computedFrom('blackoutService.blackouts.length', 'stagedForDelete.length', 'stagedForSave.length')
  get blackouts(): Blackout[] {
    const {blackouts} = this.blackoutService;
    const saveIds = this.stagedForSave.map(b => b.id);
    const filteredStagedForSave = this.stagedForSave.filter(b => !this.stagedForDelete.includes(b.id));

    return blackouts
      .filter(b => !this.stagedForDelete.includes(b.id))
      .filter(b => !saveIds.includes(b.id))
      .concat(filteredStagedForSave);
  }

  @computedFrom('selectedItems.length', 'isLoading', 'scheduleEnabled')
  get deleteButtonDisabled() {
    return this.selectedItems.length === 0 || this.isLoading || this.scheduleEnabled;
  }

  @computedFrom('blackouts.length')
  get hasBlackouts(): boolean {
    return this.blackouts && this.blackouts.length > 0;
  }

  @computedFrom('liveChannels.isLoading', 'blackoutService.isLoading')
  get isLoading(): boolean {
    return this.liveChannels.isLoading || this.blackoutService.isLoading;
  }

  @computedFrom('isSaving', 'tabDirty', 'isLoading')
  get saveButtonState() {
    if (this.isSaving) {
      return 'thinking';
    }

    if (!this.tabDirty || this.isLoading) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('liveChannels.currentModel', 'liveChannels.currentModel.use_chsched')
  get scheduleEnabled(): boolean {
    if (this.betaMode.hasScope('le2-blackouts')) {
      return false;
    }

    return this.liveChannels.currentModel && this.liveChannels.currentModel.use_chsched === 2;
  }

  @computedFrom('stagedForSave.length', 'stagedForDelete.length')
  get tabDirty(): boolean {
    if (this.stagedForSave.length > 0 || this.stagedForDelete.length > 0) {
      return true;
    }
    return false;
  }

  /*
   * Public Properties
   */

  public blackout: Blackout;
  // public blackouts: Blackout[];
  public blackoutsChangeTracker: number;
  public blackoutModel: any = {};
  public isSaving: boolean = false;
  public stagedForSave: Blackout[] = [];
  public stagedForDelete: string[] = [];
  public selectedItems: string[] = [];
  public subscriptions: Subscription[] = [];
  public blackoutCols: CTableCol[] = [
    {
      checkChanged: row => this.select(row),
      colClass: 't30',
      colHeadName: 'selected',
      colHeadSelectedChanged: isChecked => this.selectAll(isChecked),
      colHeadSelectedVal: false,
      colHeadValue: 'Select',
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
    },
    {
      colHeadName: 'blackout_id',
      colHeadValue: 'Blackout ID',
      sort: true,
    },
    {
      colHeadName: 'desc',
      colHeadValue: 'Description',
      sort: true,
    },
    {
      colClass: 't120',
      colHeadName: 'rules',
      colHeadValue: 'Rule Count',
      valueConverter: 'count',
    },
  ];

  public blackoutsPollTracker: HyperionPolling = new HyperionPolling({
    callbackFn: res => {
      this.blackoutService.processPollData(res);
      // this.blackouts = this.blackoutService.blackouts;
    },
    ms: 10000,
    promiseFn: async params => {
      if (this.isPolling) {
        return null;
      }

      if (document.hidden) {
        return null;
      }

      let res = null;
      this.isPolling = true;

      try {
        res = await this.blackoutService.pollRecords(params);
      } catch (err) {
        log.error(err);
      } finally {
        this.isPolling = false;
      }

      return res;
    },
    useAfter: true,
  });

  public actions: CTableActions = {
    onScrollBottom: () => this.blackoutService.getMore(),
    rowClick: row => this.openBlackoutId(row),
  };

  /*
   * Private Properties
   */

  private tmpCt: number = 0;

  /*
   * Aurelia Hooks
   */

  public isPolling: boolean = false;

  constructor(
    public bindingEngine: BindingEngine,
    public liveChannels: LiveChannelsService,
    public blackoutService: BlackoutService,
    public ruleService: RuleService,
    public notification: CToastsService,
    public dialogService: DialogService,
    public session: SessionService,
    public libraryService: LibraryService,
    public router: Router,
    public scheduleEntryService: ScheduleEntryService,
    public betaMode: BetaMode,
  ) {
    super(
      bindingEngine,
      liveChannels,
      blackoutService,
      ruleService,
      notification,
      dialogService,
      session,
      libraryService,
      router,
      scheduleEntryService,
      betaMode,
    );
  }

  public attached() {
    this.liveChannels.activeTab = 'blackout';

    super.attached();
  }

  public async activate(params) {
    await super.activate(params);

    this.init();
  }

  public deactivate() {
    this.clearSubscriptions();

    if (this.blackoutsPollTracker) {
      this.blackoutsPollTracker.stop();
    }

    super.deactivate();
  }

  /*
   * Public Methods
   */

  public async getMoreRules() {
    this.blackoutModel.isLoadingMoreRules = true;

    await this.ruleService.getMoreRules();

    this.blackoutModel.isLoadingMoreRules = false;

    if (!_.isEmpty(this.ruleService.latestBatchRules)) {
      let latestBatchRules = this.ruleService.latestBatchRules.map(r => ({text: r.desc, value: r.id}));
      if (this.blackout) {
        latestBatchRules = latestBatchRules.filter(r => !this.blackout.rules.includes(r.value));
      }
      this.ruleService.latestBatchRules = [];
      // updating model.availableRules makes it possible to hold onto rules that were moved from right to left.
      if (!_.isEmpty(latestBatchRules)) {
        this.blackoutModel.availableRules = _.uniqBy(
          this.blackoutModel.availableRules.concat(latestBatchRules),
          'value',
        );
      }
    }
  }

  // overrides base save method, since blackout crud is done via separate API.
  public async save() {
    this.isSaving = true;
    const toDelete = this.stagedForDelete.filter(id => !id.match(/^tmp_/));
    const toSave = this.stagedForSave.filter(b => !this.stagedForDelete.includes(b.id));

    this.stagedForDelete = await Promise.filter(toDelete, async id => {
      try {
        await this.blackoutService.deleteBlackout(id);
        return false;
      } catch (e) {
        return true;
      }
    });

    this.stagedForSave = await Promise.filter(toSave, async blackout => {
      try {
        if (blackout.id.match(/^tmp_/)) {
          blackout.id = null;
        }

        await this.blackoutService.saveBlackout(blackout);

        return false;
      } catch (e) {
        return true;
      }
    });

    if (!this.tabDirty) {
      this.notification.success('Changes saved successfully.');
    }

    this.isSaving = false;
  }

  public select(row) {
    if (!row.id.match(/^tmp_/)) {
      this.blackoutService.trackRecord();
    }

    if (row.selected) {
      this.selectedItems.push(row.id);
    } else {
      const index = this.selectedItems.findIndex(id => id === row.id);
      this.selectedItems.splice(index, 1);
    }
  }

  public selectAll(selected: boolean = false) {
    const selectAll = r => {
      r.selected = selected;
      this.select(r);
    };

    this.stagedForSave.forEach(selectAll);
    this.blackoutService.blackouts.forEach(selectAll);
  }

  public stageBlackoutDelete() {
    if (!this.selectedItems || this.selectedItems.length === 0) {
      return;
    }

    this.stagedForSave = this.stagedForSave.filter(b => !b.selected);

    const deletedIds = new Set(this.stagedForDelete.concat(this.blackoutService.selected));

    this.stagedForDelete = Array.from(deletedIds);
    this.selectAll(false);
  }

  // this is called by blackout ID modal done button.
  public stageBlackoutSave(model) {
    this.validateBlackout(model);

    if (model.error) {
      return false;
    }

    const b = new Blackout();

    if (model.id) {
      b.id = model.id;
    } else {
      b.id = `tmp_${this.tmpCt}`;
      this.tmpCt += 1;
    }

    b.blackout_id = model.blackout_id;
    b.desc = model.desc;
    b.rules = model.appliedRules.map(r => r.value);

    // using the same order here as in appliedRules b/c order matters.
    b['@included'] = model.appliedRules.map(r => this.ruleService.rules.find(rule => rule.id === r.value));

    this.stagedForSave.push(b);
    return true;
  }

  /*
   * Private Methods
   */

  private availableRulesChanged(newValue) {
    // if availableRules (i.e fetched rules - appliedRules) is less than 15, we need to force getMore b/c
    // in the UI scrollbar won't appear so onScrollBottom never gets triggered.
    if (newValue && newValue.length < 15) {
      this.getMoreRules();
    }
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(s => {
      s.dispose();
    });

    this.subscriptions = [];
  }

  private async getAppliedRules() {
    if (_.get(this.blackout, 'id') && !_.get(this.blackout, '@included')) {
      // service.getBlackout() gives a full version of blackout with full rule object lists.
      try {
        await this.blackoutService.getBlackout(this.blackout.id);

        if (_.get(this.blackoutService.blackout, '@included')) {
          this.blackoutModel.appliedRules = this.blackoutService.blackout['@included'].map(rule => ({
            text: rule.desc,
            value: rule.id,
          }));

          this.blackoutModel.appliedRulesPristine = _.cloneDeep(this.blackoutModel.appliedRules);
        }
      } catch (e) {
        const err = JSON.parse(e.message);
        if (err.status_code === 404) {
          // Remove from service and local arrays
          this.blackoutService.deleteBlackout(this.blackout.id);
          // _.remove(this.blackouts, {id: this.blackout.id});
        } else {
          throw Error('Cannot load Blackout ID. Please make sure it exists.');
        }
      }
    } else if (_.get(this.blackout, '@included')) {
      // this is for newly added but not saved blackouts that don't have id yet.
      this.blackoutModel.appliedRules = this.blackout['@included'].map(rule => ({
        text: rule.desc,
        value: rule.id,
      }));
      this.blackoutModel.appliedRulesPristine = _.cloneDeep(this.blackoutModel.appliedRules);
    }
  }

  private async getAvailableRules(searchText?) {
    const params: HyperionFilterParams = {};
    if (searchText) {
      params.search = searchText;
    }
    this.blackoutModel.isLoadingRules = true;
    try {
      await this.ruleService.getRules(params);

      let availableRules = _.cloneDeep(this.ruleService.rules);

      if (this.blackout) {
        availableRules = availableRules.filter(r => !this.blackout.rules.includes(r.id));
      }

      this.blackoutModel.availableRules = availableRules.map(r => ({text: r.desc, value: r.id}));
    } catch (e) {
      throw Error('Cannot load available Rules. Please try again.');
    } finally {
      this.blackoutModel.isLoadingRules = false;
    }
  }

  private async init() {
    await this.blackoutService.getBlackouts();

    this.blackoutsPollTracker.start();
  }

  private openBlackoutId(blackout) {
    this.blackout = blackout;
    this.blackoutModel = {
      appliedRules: [],
      appliedRulesPristine: [],
      availableRules: [],
      blackoutService: this.blackoutService,
      blackout_id: '',
      blackout_id_pristine: '',
      desc: '',
      descPristine: '',
      error: '',
      getMoreRules: () => this.getMoreRules(),
      id: '',
      isLoading: false,
      onDone: model => this.stageBlackoutSave(model),
      onSearch: searchText => this.getAvailableRules(searchText),
    };

    // for edit
    if (blackout) {
      this.blackoutModel.blackout_id = blackout.blackout_id;
      this.blackoutModel.blackout_id_pristine = blackout.blackout_id;
      this.blackoutModel.id = blackout.id;
      this.blackoutModel.desc = blackout.desc;
      this.blackoutModel.descPristine = blackout.desc;
    }

    this.subscriptions.push(
      this.bindingEngine
        .propertyObserver(this.blackoutModel, 'availableRules')
        .subscribe(newValue => this.availableRulesChanged(newValue)),
    );

    // gets appliedRules and availableRules
    this.prepareDataForModal();
    this.dialogService
      .open({
        model: {
          bodyViewModel: PLATFORM.moduleName(
            'apps/cms/routes/live-channels/channels/single/modals/blackout/blackout-id-body',
          ),
          footerEnable: true,
          footerText: 'footer',
          footerViewModel: PLATFORM.moduleName(
            'apps/cms/routes/live-channels/channels/single/modals/blackout/blackout-id-footer',
          ),
          sharedModel: this.blackoutModel,
          size: 'auto',
          title: blackout ? `Edit ${blackout.blackout_id}` : 'Add Blackout ID',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed(() => {
        this.clearSubscriptions();
      });
  }

  private async prepareDataForModal() {
    this.ruleService.searchQuery = '';
    this.blackoutModel.isLoading = true;

    try {
      await Promise.all([
        this.getAppliedRules(),
        this.getAvailableRules(),
      ]);
    } catch (e) {
      this.notification.error(e.message);
      this.dialogService.closeAll();
    }

    this.blackoutModel.isLoading = false;
  }

  private validateBlackout(model) {
    model.error = '';

    if (!model.blackout_id) {
      model.error = 'Blackout ID is required.';
    }

    if (model.blackout_id === model.blackout_id_pristine) {
      return;
    }

    const conflictErrMsg = 'This Blackout ID is already used. Please try a different one.';

    let conflictWithDeleteStaged = false;
    const conflictWithExistingAndNewStaged = this.blackouts.findIndex(b => b.blackout_id === model.blackout_id) > -1;

    for (let i = 0; i < this.stagedForDelete.length; i++) {
      const id = this.stagedForDelete[i];
      const blackout = this.blackoutService.blackouts.find(b => b.id === id);

      if (blackout.blackout_id === model.blackout_id) {
        conflictWithDeleteStaged = true;
        break;
      }
    }

    if (conflictWithExistingAndNewStaged || conflictWithDeleteStaged) {
      model.error = conflictErrMsg;
    }
  }
}
