import {CToastsService} from '@bindable-ui/bindable';
import {AcceoError} from 'services/acceo';

export class AcceoErrorToast {
  constructor(private toast: CToastsService) {}

  public show(error: AcceoError) {
    let title = 'Error';
    let message = error.toString();

    if (error.details && error.details.length) {
      // title
      if (error.status_code) {
        title = `Error Code ${error.status_code}`;
      } else if (error.message) {
        title = error.message;
      } else {
        title = error.toString();
      }

      // message
      if (typeof error.details === 'string') {
        message = error.details;
      } else {
        // unfortunately Bindable toasts are only capable of displaying one error string
        // this will be resolved when we switch over to Lynk
        message = error.details[0];
      }
    } else if (error.message) {
      if (error.status_code) {
        title = `Error Code ${error.status_code}`;
        message = error.message;
      } else {
        message = error.message;
      }
    } else if (error.status_code) {
      message = `Status Code ${error.status_code}`;
    }
    this.toast.error(message, title);
  }
}
