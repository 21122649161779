exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3AtCI{color:#d7d7d7;color:var(--c_marshmellow);display:inline-block;font-family:var(--ff_mono);font-size:var(--s0);margin-top:var(--s-2);text-decoration:underline}", ""]);

// exports
exports.locals = {
	"timezone": "_3AtCI"
};