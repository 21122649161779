import {DialogService} from 'aurelia-dialog';
import {autoinject, LogManager} from 'aurelia-framework';

import {CToastsService, IVNavSliderPageList, SharedNav} from '@bindable-ui/bindable';

// import {IVNavSliderPageList} from 'interfaces/c-nav-vertical-sliding-interfaces';

import {Acceo} from 'services/acceo';
import {CONTENT_TYPE} from 'services/constants';

import * as moment from 'moment';
import {AssetModel, MetaKeyValue} from '../models/asset-model';
import {DEFAULT_PER_PAGE_LIMIT} from '../models/defaults';
import {
  ContentListResponse,
  ContentMetadata,
  ContentType,
  IContentFilterParams,
  IContentMeta,
  Job,
  SimpleAsset,
} from '../models/models';

export const ASSETS_HREF: string = '#/content';
export const ASSETS_URL: string = '/api/v4/assets';

export interface IPlayerParams {
  start?: number;
  stop?: number;
}

/**
 * There is a bug with search right now, you can only
 * use order with fields other than created when search is enabled
 * @param params
 */
function preventQuery(params: IContentFilterParams): boolean {
  const noSearch = !params.search || params.search.length === 0;
  const notCreatedBySort = !/^(-)?created/.test(params.order);

  return noSearch && notCreatedBySort;
}

const log = LogManager.getLogger('content-service');

@autoinject()
export class ContentService {
  public activeTab: string = '';
  public assets: SimpleAsset[] = [];
  public contentType: ContentType = ContentType.All;
  public copyTip: any;
  public createErrMsg: string = null;
  public createTip: any;
  public currentMetadata: ContentMetadata = null;
  public currentModel: AssetModel = null;
  public currentSchedule: any;
  public deleteTip: any;
  public errorMessage: string = null;
  public isAssetLoading: boolean = false;
  public isCreating: boolean = false;
  public isDeleting: boolean = false;
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public libraryId: string = null;
  public meta: IContentMeta = {};
  public metadataNotFound: boolean = false;
  public newAssetName: string = '';
  public newSlicerId: string = '';
  public params: IContentFilterParams = {};
  public savedSearchQuery: string = '';
  public searchLoading: boolean = false;
  public searchQuery: string = '';
  public searchQueryApplied: string = '';
  public searchVersion: number = 0;
  public selectedContent: string[] = [];

  get navEnabled(): boolean {
    return this._navEnabled;
  }

  private _navEnabled: boolean = false;
  private navPage: IVNavSliderPageList = {
    loadMore: () => this.getMoreContent(),
    navs: [],
    prevText: 'Content Menu',
    searchFn: query => this.search(query),
    searchPlaceholder: 'Search Content',
    searchQuery: '',
    title: 'Content',
  };

  constructor(
    private acceo: Acceo,
    private sharedNav: SharedNav,
    private notification: CToastsService,
    public dialog: DialogService,
  ) {}

  /**
   * Reset params to default
   */
  public cleanParams() {
    // Reset the params to be default
    this.params = {};
  }

  public cleanUpActive() {
    this.currentModel = null;
    this.activeTab = '';
  }

  public async copyAsset(assetId: string) {
    const url = `${ASSETS_URL}/copy`;
    const params = {asset_id: assetId};

    try {
      const asset = await this.acceo.get(SimpleAsset)(`${ASSETS_URL}/${assetId}`);
      const job = await this.acceo.post(Job)(url, params);

      if (this.copyTip) {
        this.copyTip.hide();
      }

      this.notification.success(`Copying ${asset.title} to your account`);

      return job;
    } catch (err) {
      this.notification.error(`Cannot copy asset: ${err.message}`);
      return null;
    }
  }

  public copyStatus(assetId: string, jobId: string) {
    const params = $.param({
      asset_id: assetId,
      job_id: jobId,
    });
    const url = `${ASSETS_URL}/copy?${params}`;

    return this.acceo.get(Job)(url);
  }

  /**
   * Delete all the selected assets
   */
  public async deleteContent(id?: string) {
    if (this.isDeleting) {
      return;
    }

    if (id) {
      this.selectedContent = [id];
    }

    this.isDeleting = true;
    const errors = [];
    const deleted = [];

    this.selectedContent = await Promise.filter(this.selectedContent, async assetId => {
      try {
        await this.acceo.delete()(`${ASSETS_URL}/${assetId}`);

        this.removeAsset(assetId);

        deleted.push(assetId);

        return false;
      } catch (err) {
        errors.push(err);
        return true;
      }
    });

    this.isDeleting = false;

    if (deleted.length > 0) {
      this.notification.success(`Deleted ${deleted.length} asset${deleted.length === 1 ? '' : 's'}`);
    }

    if (errors.length) {
      errors.forEach(e => {
        const msg = e.message || 'Error removing the asset. Please try again';
        this.notification.error(msg);
      });
      // eslint-disable-next-line consistent-return
      return false;
    }

    if (!this.selectedContent.length) {
      if (this.deleteTip) {
        this.deleteTip.hide();
      }

      this.selectedContent = [];
    }

    // eslint-disable-next-line consistent-return
    return true;
  }

  public async deleteMp4Export(assetId: string) {
    try {
      const url = `${ASSETS_URL}/${assetId}/mp4`;

      await this.acceo.delete()(url);

      this.notification.success('Deleted');

      return true;
    } catch (err) {
      this.notification.error('An error has occurred while attempting to delete the mp4');

      return false;
    }
  }

  public enableNav(enabled: boolean) {
    this._navEnabled = enabled;
  }

  public async exportMp4(assetId: string) {
    try {
      const url = `${ASSETS_URL}/${assetId}/mp4`;
      const asset = await this.acceo.post(AssetModel)(url, {cleanup: false});

      this.notification.success('Export started');

      return asset;
    } catch (err) {
      this.notification.error('Export failed');
      return null;
    }
  }

  public async getAsset(assetId: string, isPolling: boolean = false) {
    let res;

    this.errorMessage = null;

    // Prevent screen flicker when polling
    if (!isPolling) {
      this.isAssetLoading = true;
    }

    try {
      res = await this.acceo.get(AssetModel)(`${ASSETS_URL}/${assetId}`, {
        responseTransform: this.assetResponseTransform,
      });

      this.currentModel = res;
    } catch (err) {
      this.currentModel = null;
      this.errorMessage = err.message;
      this.isAssetLoading = false;

      log.error(err);
      throw new Error(err.message);
    } finally {
      this.isAssetLoading = false;
    }

    return res;
  }

  public async getAssets(assetIds: string[]) {
    const assets = [];
    const errors = [];

    for (let i = 0; i < assetIds.length; i++) {
      const id = assetIds[i];

      try {
        // eslint-disable-next-line no-await-in-loop
        const asset = await this.getAsset(id);
        assets.push(asset);
      } catch (err) {
        errors.push({
          id,
          message: err.message,
        });
      }

      assets.push(this.getAsset(id));
    }

    return {assets, errors};
  }

  /**
   * Get a list of assets based on a set of parameters
   *
   * @param params IContentFilterParams object
   * @param isLoadMore Optional boolean for if it's a fresh load or it's loading more in the existing list
   */
  public async getContent(params: IContentFilterParams = {}, isLoadMore: boolean = false, newSearch: boolean = false) {
    if (this.isLoading || this.isLoadingMore) {
      return Promise.resolve(this.assets);
    }
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (!params.order) {
      params.order = '-created';
    } else {
      params.order = params.order.replace('status_desc', 'state');
    }

    if (this.searchQuery) {
      params.search = this.searchQuery;
      if (newSearch) {
        params.order = '-created';
      }
    }

    if (preventQuery(params)) {
      this.notification.warning('Sorting is only available when searching on results.');
      return null;
    }

    if (!isLoadMore) {
      this.assets = [];
      this.isLoading = true;
      this.navPage.isLoading = true;
      params.page = 1;
    } else {
      if (!this.meta.hasMore) {
        return Promise.resolve(this.assets);
      }

      this.isLoadingMore = true;
      params.page = (params.page || 1) + 1;
      // params.next_token = this.meta.showing;
    }

    params.job_type = this.contentType;

    if (this.libraryId) {
      params.library = this.libraryId;
    } else {
      delete params.library;
    }

    this.params = _.cloneDeep(params);

    try {
      const urlParams = $.param(this.params);
      const url = `${ASSETS_URL}?${urlParams}`;
      const resp = await this.acceo.get(ContentListResponse)(url, {
        responseTransform: this.assetsResponseTransform,
      });

      this.assets = _.uniqBy(this.assets.concat(resp.items), 'id');

      this.updateNav();

      this.meta.maxResults = resp.max_results;
      this.meta.total = resp.total_items;
      this.meta.showing = this.assets.length;
      this.meta.limit = params.page_size || null;
      this.searchVersion = resp.search_version || 0;

      // Safety check to prevent query spamming
      const totalPages = Math.ceil(resp.total_items / params.page_size);
      this.meta.hasMore = params.page < totalPages;
      return Promise.resolve(this.assets);
    } catch (err) {
      log.error(err);
      return Promise.reject(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.navPage.isLoading = false;
    }
  }

  /**
   * Gets more assets based on scrolling the table
   */
  public async getMoreContent() {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }

    if (this.meta.hasMore) {
      const params = _.cloneDeep(this.params);

      await this.getContent(params, true);
    }
  }

  public async getPlayURL(id: string, params: IPlayerParams = {}) {
    let playUrl;
    const body = {...params, id};
    try {
      (playUrl as any) = await this.acceo.post()('/asset/playurl/', body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        requestTransform: _params => $.param(_params),
        responseTransform: resp => resp.url,
      });
    } catch (err) {
      playUrl = '';
      log.error(err);
      throw new Error(err.message);
    }

    return playUrl;
  }

  /**
   * Get a single asset by ID
   * @param id Asset ID
   * @deprecated use getAsset
   */
  public async getSingleAsset(id: string, isPolling: boolean = false) {
    return this.getAsset(id, isPolling);
  }

  /**
   * Checks for recent updates to assets
   */
  public async pollAssets(params): Promise<ContentListResponse> {
    const paramsNew = _.cloneDeep(params);
    delete paramsNew.page_size;
    delete paramsNew.page;
    delete paramsNew.order;

    const urlParams = $.param(paramsNew);

    return this.acceo.get(ContentListResponse)(`${ASSETS_URL}?${urlParams}`);
  }

  public processPollUpdate(changes) {
    if (!changes || changes.total_items === 0) {
      return false;
    }

    changes.items.forEach(item => this.updateAssetData(item));
    this.assets = _.uniqBy(this.assets.concat(), 'id');
    this.selectedContent = this.getSelectedContent();

    // Force lazy load if we think we need it
    // TODO: This is temp fix - since it won't work on larger displays
    // TODO: Need to rework how we determine if we lazy load more into the browser
    if (this.meta.total > DEFAULT_PER_PAGE_LIMIT && this.meta.showing < DEFAULT_PER_PAGE_LIMIT) {
      this.getMoreContent();
    }

    this.updateNav();

    return true;
  }

  public removeSelectedContent() {
    this.selectedContent.forEach(id => this.removeAsset(id));

    this.selectedContent = [];
  }

  public resetCurrentModel() {
    this.currentModel = null;
  }

  public resetSearch() {
    this.searchQuery = '';
    this.searchQueryApplied = '';
  }

  public async saveAsset(updatedModel: any): Promise<AssetModel> {
    try {
      const {id} = updatedModel;
      delete updatedModel.id;
      const res = await this.acceo.patch(AssetModel)(`${ASSETS_URL}/${id}`, updatedModel, {
        requestTransform: this.assetRequestTransform,
        responseTransform: this.assetResponseTransform,
      });
      this.notification.success('Saved');
      return res;
    } catch (err) {
      this.notification.error(err.message || 'Save failed. Please try again.');
      log.error(err);
      return null;
    }
  }

  /**
   * Search the assets
   *
   * @param searchQuery Optional search term that comes from the sidebar
   */
  public async search(searchQuery?: string) {
    this.navPage.searchQuery = searchQuery;
    this.searchQuery = searchQuery;
    this.searchQueryApplied = '';

    const params: IContentFilterParams = Object.assign(this.params, {page: 1});

    if (searchQuery) {
      params.search = searchQuery;
    } else {
      delete params.search;
      params.order = '-created';
    }

    this.searchLoading = true;

    try {
      await this.getContent(params, false, true);
      this.searchQueryApplied = searchQuery;
    } catch (err) {
      log.error(err);
    } finally {
      this.searchLoading = false;
    }
  }

  /**
   * Sets a asset active in the side nav
   *
   * @param id Asset ID
   */
  public setNavActive(id: string) {
    if (!this.navEnabled) {
      return;
    }

    this.sharedNav.replacePage(this.navPage, 1);

    this.assets.forEach((asset, index) => {
      if (asset.id === id) {
        this.sharedNav.setActive(1, index);
        return false;
      }
      return true;
    });
  }

  /**
   * Track a asset as it's toggled for deletion
   *
   * @param asset SimpleAsset object
   */
  public trackSelectedAsset(asset: SimpleAsset) {
    if (asset.selected) {
      this.selectedContent.push(asset.id);
    } else {
      _.remove(this.selectedContent, assetId => assetId === asset.id);
    }

    this.selectedContent = _.uniq(this.selectedContent);

    if (this.selectedContent.length === 0 && this.deleteTip) {
      this.deleteTip.hide();
    }
  }

  /**
   * Toggle all visible assets as deletable
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    this.assets.forEach(asset => {
      if (isSelected) {
        asset.selected = true;
      } else {
        asset.selected = false;
      }
    });

    this.selectedContent = this.getSelectedContent();

    if (this.selectedContent.length === 0 && this.deleteTip) {
      this.deleteTip.hide();
    }
  }

  public updateNavTitle() {
    const currentIndex = _.findIndex(this.navPage.navs, {active: true});

    if (currentIndex > -1) {
      this.navPage.navs[currentIndex].title = this.currentModel.title;
    }
  }

  public updateSubNavItems() {
    const {navs = []} = this.navPage;
    const tabNames = [
      '/breaks',
      '/details',
      '/export',
      '/metadata',
      '/playback',
    ];

    navs.forEach(nav => {
      tabNames.forEach(tabName => {
        nav.href = nav.href.replace(tabName, '');
      });

      if (this.activeTab) {
        const replaceRX = /(\/content\/[a-z0-9]*)(\/(details|breaks|export|metadata|playback|))?/;
        nav.href = nav.href.replace(replaceRX, `$1/${this.activeTab}`);
      }
    });
  }

  public async uploadThumbnailImage(assetId, images: File[]) {
    const url = `${ASSETS_URL}/${assetId}/thumbnail_upload`;
    const formData = new FormData();

    // for (let i = 0; i < images.length; i++) {
    //     formData.append('images', images[i]);
    // }

    formData.append('file', images[0]);

    try {
      const resp = await this.acceo.post()(url, formData, {
        headers: {
          'Content-Type': CONTENT_TYPE.SET_BY_BROWSER,
        },
      });

      return resp;
    } catch (err) {
      log.error(err);
      throw new Error('Failed to upload image');
    }
  }

  public async resetThumbnailImage(assetId) {
    const url = `${ASSETS_URL}/${assetId}/thumbnail_reset`;

    try {
      const resp = await this.acceo.patch()(url, {asset_id: assetId}, {});

      return resp;
    } catch (err) {
      this.notification.error('Failed to reset image');
      log.error(err);
      return null;
    }
  }

  public async reprocess(assetId, insertBreaksList, preserveBreakIndexesList, assetTitle) {
    const url = `${ASSETS_URL}/${assetId}/reprocess`;
    try {
      const resp = await this.acceo.post()(
        url,
        {insert_breaks: insertBreaksList, preserve_breaks: preserveBreakIndexesList, description: assetTitle},
        {},
      );
      return resp;
    } catch (err) {
      this.notification.error('Failed to start reprocess job');
      log.error(err);
      return null;
    }
  }

  public updateSidebarTitles(searchPlaceholder: string, title: string) {
    this.navPage.searchPlaceholder = searchPlaceholder;
    this.navPage.title = title;
  }

  // eslint-disable-next-line class-methods-use-this
  private assetRequestTransform(data) {
    const {meta} = data;
    const testPlayers = data.test_players;

    // Convert meta from an array
    if (meta) {
      const metaObj = {};
      meta.forEach(m => {
        // not sending hidden i.e. soft deleted, metadata items.
        if (!m._hidden) {
          metaObj[m.key] = m.value;
        }
      });

      data.meta = metaObj;
    }

    // Remove checked indication from test players
    if (testPlayers) {
      testPlayers.forEach(tp => {
        delete tp.checkbox;
      });
    }

    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  private assetResponseTransform(resp) {
    // eslint-disable-next-line camelcase
    const {cloudsliced = 0, meta = {}, test_players = []} = resp;
    // Convert meta into an array for easy table usage
    const metaObj: MetaKeyValue[] = Object.entries(meta).map(
      ([
        key,
        value,
      ]: [
        string,
        string,
      ]) => ({
        key,
        value,
      }),
    );

    resp.meta = metaObj;

    // eslint-disable-next-line camelcase
    test_players.forEach(tp => {
      if (tp.expire) {
        tp.expire = _.toNumber(tp.expire);
      }
    });

    // eslint-disable-next-line camelcase
    resp.test_players = test_players;
    resp.cloudsliced = !!cloudsliced;

    return resp;
  }

  // eslint-disable-next-line class-methods-use-this
  private assetsResponseTransform(resp) {
    const {items = []} = resp;

    items.forEach(asset => {
      const {break_offsets: breakOffsets = []} = asset;
      asset.ad_breaks = breakOffsets.length;
    });

    return resp;
  }

  private getSelectedContent() {
    return this.assets.filter(asset => asset.selected).map(asset => asset.id);
  }

  /**
   * Removes asset by id
   * @param {string} assetId
   */
  private removeAsset(assetId: string) {
    // Remove from assets list
    const index = this.assets.findIndex(asset => asset.id === assetId);

    if (index > -1) {
      this.assets.splice(index, 1);

      // Update Meta
      this.meta.total -= 1;
      this.meta.showing = this.assets.length;
    }

    // Remove from nav
    const {navs} = this.navPage;
    const navItemIndex = navs.findIndex(navItem => navItem.href === `${ASSETS_HREF}/${assetId}`);

    if (navItemIndex > -1) {
      navs.splice(navItemIndex, 1);
    }
  }

  private updateAssetData(asset: SimpleAsset) {
    const {id} = asset;
    const {assets} = this;

    const index = assets.findIndex(_asset => _asset.id === id);
    const created = moment(asset.created);
    const then = moment().subtract(2, 'minutes');
    const isNew = created.isSameOrAfter(then);

    if (index > -1) {
      if (asset.deleted) {
        // If deleted - remove
        this.meta.total -= 1;
        this.meta.showing -= 1;
        this.assets.splice(index, 1);
      } else {
        asset.selected = assets[index].selected;
        assets[index] = asset;
      }
    } else if (isNew) {
      this.meta.total += 1;
      this.meta.showing += 1;
      assets.unshift(asset);
    }
  }

  private updateNav() {
    this.navPage.navs = this.assets.map(asset => {
      let href = `${ASSETS_HREF}/${asset.id}`;

      if (this.params.library) {
        href = `${href}/?libraryId=${this.params.library}`;
      }

      return {
        href,
        active: false,
        title: asset.title,
      };
    });

    this.updateSubNavItems();
  }
}
