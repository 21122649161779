/* eslint-disable max-classes-per-file */
import {ITrackedModel} from 'apps/cms/utils/tracked-model';
import {computedFrom} from 'aurelia-binding';
import {Exclude, Expose, Type} from 'class-transformer';
import {IsArray, IsBoolean, IsNumber, IsOptional, IsString, IsUrl, MaxLength, ValidateNested} from 'class-validator';
import {TemplateOverride} from '../../settings/vod-uploader-templates/models/templates-model';

/*
 * enums
 */
export enum LibraryType {
  OWNER = 'owner',
  SHARED = 'shared',
}

export enum ContentType {
  All = 'all',
  File = 'file',
  Clip = 'clip',
  Live = 'live',
  Event = 'event',
}

/*
 * interfaces
 */

export interface IContentFilterParams extends IHyperionFilterParams {
  library?: string;
  job_type?: ContentType;
}

export interface IContentMeta {
  hasMore?: boolean;
  limit?: number;
  maxResults?: number;
  nextToken?: number;
  showing?: number;
  total?: number;
}

export interface IHyperionFilterParams {
  after?: string;
  page_size?: number;
  page?: number;
  include_deleted?: boolean;
  search?: string;
  order?: string;
}

export interface IHyperionMeta {
  total?: number;
  showing?: number;
  limit?: number;
  page?: number;
  hasMore?: boolean;
}

export interface ILibrarySelect {
  actions: any;
  options: Library[];
  meta: IHyperionMeta;
  params: ILibraryFilterParams;
  searchQuery: string;
}

export interface ILibraryFilterParams extends IHyperionFilterParams {
  library_type?: LibraryType;
}

export interface ILibrary {
  allow_copy?: boolean;
  desc: string;
  href?: string;
  id?: string;
  index?: number;
  is_ad?: boolean;
  library_type?: LibraryType;
  owner_name?: string;
  shared_owner_ids?: string[];
  '@included'?: any[];
}

export class VodUploaderFile {
  name: string;
  size: number = 0;
  percentage?: number = 0;
  loaded: number = 0;
  http?: any;
  error?: string = '';
  isVideo?: boolean = false;
  template: TemplateOverride = new TemplateOverride();
  isGenerated: boolean = false;
  fileParts: this[] = [];
  upload_id: string = null;

  constructor(public file: File, private selectedFiles: VodUploaderFile[] = []) {
    this.name = file.name;
    this.size = file.size;
  }

  @computedFrom('selectedFiles.length')
  get hasCFGFile() {
    const res = !!this.selectedFiles.find(x => x.name === `${this.name}.cfg` && !x.isGenerated);
    return res;
  }

  @computedFrom('selectedFiles.length')
  get CFGText() {
    const file = this.selectedFiles.find(x => x.name === `${this.name}.cfg`);
    return (file && file.file.text()) || false;
  }

  @computedFrom('selectedFiles.length')
  get hasVideoFile() {
    const filename = this.name.substring(0, this.name.lastIndexOf('.'));
    const res = !!(!this.isVideo && this.selectedFiles.find(x => x.name === filename));
    return res;
  }

  @computedFrom('selectedFiles.length')
  get isHidden() {
    const filename = this.name.substring(0, this.name.lastIndexOf('.'));
    const hasVideo = !this.isVideo && !!this.selectedFiles.find(x => x.name === filename);
    return hasVideo;
  }

  @computedFrom('selectedFiles.length', 'percentage', 'http', 'http.isRequesting', 'isVideo')
  get isOverrideAllowed() {
    return (
      this.isVideo && (!this.http || (this.http && !this.http.isRequesting) || this.error) && this.percentage !== 100
    );
  }

  public removeCFGfiles() {
    const cfgName = `${this.name}.cfg`;
    const cfgExist = this.selectedFiles.filter(x => x.name === cfgName);
    cfgExist.forEach(file => {
      _.remove(this.selectedFiles, file);
    });
  }
}

export interface AdBreakOffset {
  offset: number;
  existingIndex: number;
  checked: boolean;
}

export interface ITrackedLibrary extends Library, ITrackedModel {}

/*
 * Models
 */
export class ContentListResponse {
  @ValidateNested()
  @Type(() => SimpleAsset)
  public items?: SimpleAsset[];

  @IsNumber()
  public total_items: number;

  @IsNumber()
  public max_results: number;

  @IsNumber()
  @IsOptional()
  public search_version?: number;
}

export class ContentMetadata {
  @Expose({name: 'hidden-columns'})
  @MaxLength(20, {each: true})
  public hiddenColumns: string[] = [];
}

export class PresignedURL {
  @IsUrl()
  public url: string;

  @IsNumber()
  public part_number: number;
}

export class VODUploaderPreURL {
  @IsString()
  public file_name: string;

  @ValidateNested()
  @Type(() => PresignedURL)
  public urls: PresignedURL[];

  @IsString()
  @IsOptional()
  public upload_id: string | null;
}

export class VODUploaderPreURLList {
  @ValidateNested()
  @Type(() => VODUploaderPreURL)
  public items?: VODUploaderPreURL[];

  @IsNumber()
  public total_items: number;
}

export class Job {
  @IsString()
  @IsOptional()
  public asset?: string;

  @IsNumber()
  public attempts: number;

  @IsNumber()
  public finished: number;

  @IsString()
  public id: string;

  @IsString()
  public last_error: string;

  @IsNumber()
  public last_start: number;

  @IsString()
  public owner: string;

  @IsString()
  public progress: string;

  public source: any;

  @IsString()
  public state: string;
}
export class JobVODSource {
  @IsOptional()
  @IsString()
  public api_key: string;

  @IsOptional()
  @IsString()
  public api_secret: string;

  @IsString()
  public url: string;
}

export class JobVODArgs {
  @IsString()
  public description: string;

  @IsOptional()
  @IsString()
  public trim: string;
}

export class JobVOD {
  @ValidateNested()
  @Type(() => JobVODArgs)
  public args: JobVODArgs;

  @IsString()
  @IsOptional()
  public asset?: string;

  @IsNumber()
  public attempts: number;

  @IsString()
  @IsOptional()
  public finished: string;

  @IsString()
  public id: string;

  @IsString()
  public last_error: string;

  @IsString()
  public created: string;

  @IsString()
  public last_start: string;

  @IsString()
  public vod_checksum: string;

  @IsString()
  public owner: string;

  @IsString()
  public progress: string;

  @IsNumber()
  public progress_percent: number;

  @ValidateNested()
  @Type(() => JobVODSource)
  public source: JobVODSource;

  @IsString()
  public state: string;

  @IsBoolean()
  public selected: boolean = false;

  @IsBoolean()
  @IsOptional()
  public isDeleting?: boolean = false;

  @IsBoolean()
  @IsOptional()
  public isCanceling?: boolean = false;

  @IsString()
  @computedFrom('args')
  get title(): string {
    return this.args ? this.args.description : '';
  }

  @IsString()
  @computedFrom('state', 'progress')
  get status_str(): string {
    if (this.state === 'assigned' && this.progress.length) {
      // return `${this.progress_percent}% ${this.progress}`;
      return this.progress;
    }
    return this.state;
  }
}

class DuplicateJob {
  @IsString()
  public bucket: string;

  @IsString()
  public desc: string;

  @IsString()
  public key_name: string;

  @IsString()
  public owner: string;

  @IsString()
  public uri: string;
}
export class JobsVODListResponse {
  @ValidateNested()
  @Type(() => JobVOD)
  public items?: JobVOD[];

  @IsNumber()
  public total_items: number;

  @ValidateNested()
  @Type(() => DuplicateJob)
  public duplicates?: DuplicateJob[];
}

export class Library implements ILibrary {
  @IsBoolean()
  public allow_copy?: boolean = false;

  @IsString()
  public desc: string;

  @IsString()
  public id?: string;

  @IsBoolean()
  public is_ad?: boolean = false;

  @IsString()
  public library_type?: LibraryType = LibraryType.OWNER;

  @IsString()
  public owner_name?: string;

  @IsArray()
  public shared_owner_ids?: string[] = [];

  @IsOptional()
  @computedFrom('@included')
  public get shared_owners(): SimpleOwner[] {
    return this['@included'] || [];
  }

  @ValidateNested()
  @Type(() => SimpleOwner)
  @Exclude({toPlainOnly: true})
  public '@included'?: SimpleOwner[];
}

export class LibraryListResponse {
  @ValidateNested()
  @Type(() => Library)
  public items?: Library[];

  @IsNumber()
  public total_items: number;
}

export class SimpleAsset {
  @IsNumber()
  @IsOptional()
  public ad_breaks?: number;

  @IsOptional()
  public autoexpire?: any;

  @IsNumber()
  @IsOptional()
  public cloudsliced?: number;

  @IsString()
  @IsOptional()
  public created?: string;

  @IsUrl()
  @IsOptional()
  public default_poster_url?: string;

  @IsString()
  @IsOptional()
  public deleted?: string;

  @IsNumber()
  @IsOptional()
  public duration: number;

  @IsString()
  public id: string;

  @IsString()
  @IsOptional()
  public job_type?: string;

  @IsBoolean()
  @IsOptional()
  public mine?: boolean;

  @IsUrl()
  @IsOptional()
  public poster_url?: string;

  @IsBoolean()
  public selected: boolean = false;

  @IsString()
  public source?: string;

  @IsString()
  @IsOptional()
  public status_desc?: string;

  @IsString()
  @IsOptional()
  public status_state?: string;

  @IsString()
  public title?: string;

  @IsString()
  @IsOptional()
  public _class?: string;

  @IsString()
  @IsOptional()
  public adBreaksTipArrowPosition?: string;

  @IsString()
  @IsOptional()
  public adBreaksTipSide?: string;

  @IsArray()
  @IsOptional()
  public break_offsets?: object[];

  @IsString()
  @IsOptional()
  public adBreaksTipSize?: string;

  @IsString()
  @IsOptional()
  public adBreaksTipTriggerText?: string;

  @IsString()
  @IsOptional()
  public adBreaksTipViewModel?: string;

  @IsString()
  @IsOptional()
  public _status?: string;

  @IsString()
  @IsOptional()
  public titleIconSize: string = '1em';

  @computedFrom('autoexpire')
  get titleIcon(): string {
    return this.autoexpire ? 'time' : '';
  }
}

export class SimpleOwner {
  @IsString()
  public username: string;

  @IsString()
  public id?: string;
}
