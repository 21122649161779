import {autoinject, computedFrom} from 'aurelia-framework';
import {CToastsService} from '@bindable-ui/bindable';
import {Acceo} from 'services/acceo';

import {AnalyticsSystemGetResponse, AnalyticsSystemPostResponse} from '../models/model';

const URL = '/ops/cms-analytics';

@autoinject
export class analyticsSettingsIndex {
  /*
   * Computed Properties
   */

  @computedFrom('loading')
  get createBtnState() {
    return this.loading ? 'thinking' : '';
  }

  /*
   * Public Properties
   */

  public custom_msg_enabled: boolean = false;
  public custom_msg_text: string = '';
  public custom_msg_style: string = '';
  public isSaving: boolean = false;
  public isLoading: boolean = false;
  public selectedStyle: string = '0';
  public alertStylesLabels = [
    'Info (purple)',
    'Warning (yellow)',
    'Danger (red)',
  ];

  private alertStyles = [
    'info',
    'warning',
    'danger',
  ];

  /*
   * Private Properties
   */

  private loading: boolean = false;

  constructor(private acceo: Acceo, private notification: CToastsService) {}

  /*
   * Public Methods
   */

  public async activate() {
    await this.getAnalyticsSettings();
  }

  public async getAnalyticsSettings() {
    const params = $.param({});
    this.isLoading = true;
    try {
      const resp = await this.acceo.get(AnalyticsSystemGetResponse)(
        [
          URL,
          params,
        ].join('?'),
      );
      this.custom_msg_enabled = resp.custom_msg_enabled;
      this.custom_msg_text = resp.custom_msg_text;
      this.custom_msg_style = resp.custom_msg_style;
      this.selectedStyle = this.alertStyles.indexOf(resp.custom_msg_style).toString();
    } catch (err) {
      this.notification.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public async save() {
    const url = `${URL}/save`;
    const data = {
      custom_msg_enabled: this.custom_msg_enabled,
      custom_msg_text: this.custom_msg_text,
      custom_msg_style: this.alertStyles[this.selectedStyle],
    };
    this.isSaving = true;
    try {
      await this.acceo.post(AnalyticsSystemPostResponse)(url, data);
      this.notification.success('Settings saved');
    } catch (err) {
      this.notification.error(err.message);
    } finally {
      this.isSaving = false;
    }
  }

  public enableCustomMsg() {
    this.custom_msg_enabled = !this.custom_msg_enabled;
  }
}
