exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ZcU9R{align-items:center;border-radius:2px;display:flex;flex-shrink:0;font-size:12px;justify-content:center;margin-right:5px;position:relative}._3Ajgd{padding:5px 0 5px 10px}.ZcU9R svg{flex:0 0 36px}._6v7Yr{padding:8px 12px 8px 8px;white-space:nowrap}._1An4R,._1An4R c-tip,._1An4R c-tip>div,._1An4R c-tip>div>span:first-child{cursor:default;height:100%;position:absolute;width:100%}._3c2UY,._21lxg{background:#14263f;background:var(--c_subOneDark)}._3c2UY,._21lxg svg{color:#8ad6f7;color:var(--c_subOneLighter)}._3gLBy{background:#312810;background:var(--c_subTwoDark)}._3gLBy svg{color:#e9c558;color:var(--c_subTwoLight)}._1I1wn{background:#5d3a33}._1I1wn svg{color:#e23c2f}._1fUmA{background:#2a513a}._1fUmA svg{color:#00ac3e}._2rc6H{background:#2a513a}._2rc6H svg{color:#bbbfbf}", ""]);

// exports
exports.locals = {
	"statusBox": "ZcU9R",
	"statusIcon": "_3Ajgd",
	"content": "_6v7Yr",
	"tipContent": "_1An4R",
	"pending": "_3c2UY",
	"processing": "_21lxg",
	"warning": "_3gLBy",
	"fail": "_1I1wn",
	"complete": "_1fUmA",
	"completeWithWarning": "_2rc6H"
};