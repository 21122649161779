import 'fetch';

import {inject} from 'aurelia-framework';
import {SessionService} from 'services/session';

import {AuthorizeStep} from '../../services/auth';
import {getQueryParams} from '../../services/get-query-params';

import {Event} from './event';

const USE_WEBSOCKETS = false;

@inject(Event, SessionService, USE_WEBSOCKETS)
export class App {
  constructor(event, sessionService, useWebSockets) {
    this.event = event;
    this.session = sessionService;
    this.USE_WEBSOCKETS = useWebSockets;

    document.documentElement.classList.add('lynk-theme-dark');

    const query = getQueryParams();
    this.eventId = query.event;

    this.event.esocket = null;

    if (this.USE_WEBSOCKETS) {
      // connect to socket to listen for event changes
      // console.debug('connect to live event socket');
      this.event.esocket = new WebSocket(`ws://cms.uplynk.localhost:8000/live-events/socket/${this.eventId}`);

      this.event.esocket.addEventListener('message', evt => {
        if (evt.data === '1') {
          // console.debug('[WEBSOCKET]', 'Connected Successfully');
          return;
        }

        // console.debug('onmessage', evt);
        // console.debug('event', JSON.parse(evt.data));
        // console.debug('event id', this.eventId);
        this.event.event = JSON.parse(evt.data);
        this.event.bindingSignaler.signal('state-changed');
      });
    }
  }

  attached() {
    this.session.sessionPing();
    return this.event.getEvent(this.eventId);
  }

  detached() {
    if (this.USE_WEBSOCKETS) {
      this.event.esocket.close();
    }
  }

  // disable no-param-reassign until we figure out alt ways with config.title
  configureRouter(config, router) {
    config.addPipelineStep('authorize', AuthorizeStep);
    config.title = 'Live Events Dashboard';
    config.map([
      {
        route: [
          '',
          'dashboard',
        ],
        name: 'dashboard',
        moduleId: PLATFORM.moduleName('./dashboard', 'live-event-dashboard'),
        nav: true,
        title: 'Source',
      },
      {
        route: 'details',
        name: 'details',
        moduleId: PLATFORM.moduleName('./details', 'live-event-dashboard'),
        nav: true,
        title: 'Event Details',
      },
      {
        route: 'external-player',
        name: 'external-player',
        moduleId: PLATFORM.moduleName('./external-player', 'live-event-dashboard'),
        nav: false,
        title: 'Audience View',
      },
      {
        route: 'live-preview',
        name: 'live-preview',
        moduleId: PLATFORM.moduleName('./live-preview', 'live-event-dashboard'),
        nav: false,
        title: 'Live Preview',
      },
    ]);

    this.router = router;
  }
}
