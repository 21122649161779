import {CTableActions, CTableCol} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {HyperionPolling} from 'services/hyperion-polling';
import {SyndicationRegion} from 'services/models/syndication';
import {SyndicationJob} from 'services/models/syndication-job';
import {SyndicationJobService} from 'services/syndication-job-service';

export const CHANNELS_HREF: string = '#/live-channels/channels';
export const EVENTS_HREF: string = '#/live-events/events';
const STATUS_REFRESH_INTERVAL = 8000;

@autoinject()
export class PublishingJobs {
    public routeName;
    public assetTypes = [
        {label: 'All', id: 'assetType_all'},
        {label: 'Channel', id: 'assetType_channel'},
        {label: 'Live Event', id: 'assetType_liveEvent'},
    ];

    public contentType: string = 'assetType_all';
    public statusTypes = [
        {label: 'active', id: 'statusType_active'},
        {label: 'scheduled', id: 'statusType_scheduled'},
        {label: 'stopped', id: 'statusType_stopped'},
        {label: 'error', id: 'statusType_error'},
    ];

    public statusTypeDic = {
        statusType_active: true,
        statusType_error: true,
        statusType_scheduled: true,
        statusType_stopped: true,
    };

    public jobs: SyndicationJob[] = [];
    public isLoading: boolean = false;
    public isLoadingMore: boolean = false;
    public loadingError: boolean = false;
    public pollTracker: HyperionPolling;
    public syndRegions: SyndicationRegion[] = [];
    public jobsCols: CTableCol[] = [
        {
            colClass: 't270',
            colHeadName: 'id',
            colHeadValue: 'Job ID',
        },
        {
            colClass: 't130',
            colHeadName: 'content_type',
            colHeadValue: 'Type',
        },
        {
            colAction: job => this.openContent(job),
            colClass: 't190',
            colHeadName: 'content_description',
            colHeadValue: 'Name',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action',
            ),
        },
        {
            colClass: 't120',
            colHeadName: 'platform',
            colHeadValue: 'Platform',
        },
        {
            colClass: 't120',
            colHeadName: 'target_protocol',
            colHeadValue: 'Protocol',
        },
        {
            colHeadName: 'description',
            colHeadValue: 'Target',
        },
        {
            colClass: 't150',
            colHeadName: '',
            colHeadValue: 'Job Status',
        },
        {
            colClass: 't150',
            colHeadName: '',
            colHeadValue: 'Region',
        },
        {
            colClass: 't160',
            colHeadName: 'start',
            colHeadValue: 'Last Start',
        },
        {
            colClass: 't160',
            colHeadName: 'stop',
            colHeadValue: 'Last End',
        },
    ];

    public tableActions: CTableActions;

    constructor(public syndicationJobService: SyndicationJobService, public router: Router) {
        this.router = router;
        this.tableActions = {
            getColClass: (row, col) => {
                if (col.colHeadValue === 'Job Status') {
                    if (row.status === 'active') {
                        return 'bgProcessing';
                    }
                    if (row.status === 'error') {
                        return 'bgCritical';
                    }
                }
                return col._class;
            },
            getColValue: (row, col) => {
                if (col.colHeadValue === 'Job Status') {
                    return row.status.charAt(0).toUpperCase() + row.status.slice(1);
                }
                if (col.colHeadValue === 'Region') {
                    if (row.region) {
                        return this.syndRegions.find(region => region.value === row.region).text;
                    }
                    return '';
                }
            },
            onScrollBottom: () => {
                if (!this.isLoading && !this.isLoadingMore && this.syndicationJobService.meta.hasMore) {
                    this.isLoadingMore = true;
                    this.syndicationJobService.getMoreJobs();
                    this.loadJobs(false);
                    this.isLoading = false;
                    this.isLoadingMore = false;
                }
            },
        };

        this.pollTracker = new HyperionPolling({
            callbackFn: () => {
                this.jobsTableFormat();
            },
            ms: STATUS_REFRESH_INTERVAL,
            promiseFn: async () => await this.getElementsInFocus(),
            useAfter: true,
        });
    }

    public async getElementsInFocus() {
        let scrollElem: JQuery;
        let jobItemElems: JQuery;

        let visibleJobs: string[] = [];

        scrollElem = $('#jobs-table');
        jobItemElems = scrollElem.find('tbody').children();

        // Make sure we have something
        if (!scrollElem.children().length || !jobItemElems.length) {
            return;
        }

        const scrollElemRect = scrollElem[0].getBoundingClientRect();

        jobItemElems.each((_i, elem) => {
            const elemRect = elem.getBoundingClientRect();

            if (!(elemRect.top > scrollElemRect.bottom || elemRect.bottom < scrollElemRect.top)) {
                visibleJobs.push(elem.id);
            }
        });

        // Remove anything that isn't in the current groups list
        _.remove(visibleJobs, jobId => !_.find(this.jobs, {id: jobId}));

        // Make sure values are unique
        visibleJobs = _.uniq(visibleJobs);

        return await this.syndicationJobService.getSyndicationJobsUpdates(visibleJobs);
    }

    public activate({}, routeConfig) {
        this.routeName = routeConfig.name || '';
        this.syndicationJobService.updateParams({page: 1});
        this.isLoading = true;
        this.getRegions();
        this.loadJobs(true);
    }

    public async getRegions() {
        try {
            const ans = await this.syndicationJobService.getSyndicationRegions();
            if (ans) {
                this.syndRegions = this.syndicationJobService.syndicationRegions;
            }
        } catch (error) {
            this.loadingError = true;
        }
    }

    public async loadJobs(overrideJobs = false) {
        try {
            const ans = await this.syndicationJobService.getSyndicationJobs(
                this.contentType,
                this.statusTypeDic,
                overrideJobs,
            );
            if (ans) {
                this.jobsTableFormat();
            }
        } catch (error) {
            this.loadingError = true;
        }
        this.pollTracker.start();
        this.isLoading = false;
    }

    public jobsTableFormat() {
        this.jobs = _.cloneDeep(this.syndicationJobService.syndicationJobs);
        this.jobs.forEach(job => {
            if (job.start) {
                job.start = new Date(parseInt(job.start, 10)).toLocaleString();
            }
            if (job.stop) {
                job.stop = new Date(parseInt(job.stop, 10)).toLocaleString();
            }

            if (job.status === 'active' || job.status === 'scheduled') {
                job.stop = '';
            }

            if (job.content_type === 'c') {
                job.content_type = 'Channel';
            } else if (job.content_type === 'e') {
                job.content_type = 'Live Event';
            }
        });
    }

    public filterJobs() {
        this.syndicationJobService.updateParams({page: 1});
        this.loadJobs(true);
    }

    public openContent(job) {
        this.isLoading = true;
        if (job.content_type === 'Channel') {
            this.router.navigate(`${CHANNELS_HREF}/${job.content_id}/publish`);
        } else if (job.content_type === 'Live Event') {
            this.router.navigate(`${EVENTS_HREF}/${job.content_id}/publish`);
        }
    }

    public detached() {
        this.pollTracker.stop();
    }
}
