import {CToastsService, sorting} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom, LogManager, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ConfigSettingsLiveSlicer, DocumentationUrlLiveSlicer} from 'resources/config/settings/live-slicer';
import {ConfigTable} from 'resources/config/table';
import {Acceo} from 'services/acceo';
import {HyperionPolling} from 'services/hyperion-polling';
import {SessionService} from 'services/session';
import {AcceoErrorToast} from 'resources/acceo-error-toast';
import {LynkDialogServiceSettings} from 'resources/services/dialog';
import {MSOSlicerClassification} from '../../models/msoslicer';
import {MSOHostingLocationService} from '../../services/mso-hostingloc-service';
import {MSOSlicerRestartService} from '../../services/mso-slicer-restart-service';
import {MSOSlicerService} from '../../services/mso-slicer-service';
import {MSOSlicerGetByOwnerService} from '../../services/mso-slicergetbyowner-service';
import {MSOStreamProtocolService} from '../../services/mso-streamproto-service';
import {Config as ModelShared} from '../single/models';
import {Config} from './models';
import {HostedSlicerTable} from './table';
import {HostedSlicerTableRow} from './table-models';

const logger = LogManager.getLogger('HostedSlicers');

@autoinject
export class HostedSlicerList {
  public config = new Config();
  public table: HostedSlicerTable;
  public logger = logger;
  public filterMenu;
  protected hostingLocationService: MSOHostingLocationService;
  protected slicerService: MSOSlicerService;
  protected slicerRestartService: MSOSlicerRestartService;
  protected slicerGetByOwnerService: MSOSlicerGetByOwnerService;
  protected streamProtocolService: MSOStreamProtocolService;
  protected hyperionPolling: HyperionPolling;
  protected hyperionPollingInterval = 5000;
  protected acceoErrorToast: AcceoErrorToast;

  @computedFrom('sessionService.hasSlicersCloudWriteAccess')
  get createButtonState() {
    if (!this.sessionService.hasSlicersCloudWriteAccess) {
      return 'disabled';
    }
    return '';
  }

  @computedFrom('config.state.isDeleting', 'sessionService.hasSlicersCloudWriteAccess', 'table.rowsSelected.length')
  get deleteButtonState() {
    if (!this.sessionService.hasSlicersCloudWriteAccess) return 'disabled';
    if (this.config.state.isDeleting) return 'thinking';
    return this.table.rowsActionable.length > 0 ? '' : 'disabled';
  }

  @computedFrom(
    'config.input.classification',
    'config.input.showDeleted',
    'config.input.search',
    'table.rows',
    'table.sortColHeadName',
    'table.sortReverse',
  )
  get filteredRows(): HostedSlicerTableRow[] {
    return this.table.rows
      .filter(row => {
        // Show all undeleted slicers
        // Show slicers which have deleted timestamp set
        //   - if status is "DELETING"
        //   - if slicer admin/manager has checked "Show deleted" checkbox
        if (row.deleted) {
          if (row.status === 'DELETING') return true;
          if (!this.config.state.canViewDeleted) return false;
          return this.config.input.showDeleted;
        }
        return true;
      })
      .filter(row => {
        let foundText = true;
        let foundClassification = true;
        if (this.config.input.search && this.config.input.search.length) {
          this.config.input.searchQuery = this.config.input.search;
          foundText = this.searchText(row, this.config.input.searchKeys);
        }
        if (
          this.config.state.canViewClassification &&
          this.config.input.classification &&
          this.config.input.classification.length
        ) {
          this.config.input.searchQuery = this.config.input.classification;
          foundClassification = this.searchText(row, ['classification']);
        }
        return foundText && foundClassification;
      })
      .sort(sorting(this.table.sortColHeadName, this.table.sortReverse));
  }

  @computedFrom('config.state.isRestarting', 'sessionService.hasSlicersCloudWriteAccess', 'table.rowsActionable.length')
  get restartButtonState() {
    if (!this.sessionService.hasSlicersCloudWriteAccess) return 'disabled';
    if (this.config.state.isRestarting) return 'thinking';
    if (this.table.rowsActionable.length <= 0) return 'disabled';
    return '';
  }

  constructor(
    protected acceo: Acceo,
    protected dialogService: DialogService,
    protected notification: CToastsService,
    protected router: Router,
    protected sessionService: SessionService,
  ) {
    if (this.sessionService.hasHostedSlicersAccess) {
      this.config.state.canExportCSV = true;
      this.config.state.canViewClassification = true;
      this.config.state.canViewDeleted = true;
    }
    this.acceoErrorToast = new AcceoErrorToast(notification);
    this.hostingLocationService = new MSOHostingLocationService(acceo);
    this.slicerService = new MSOSlicerService(acceo);
    this.slicerGetByOwnerService = new MSOSlicerGetByOwnerService(acceo);
    this.slicerRestartService = new MSOSlicerRestartService(acceo);
    this.streamProtocolService = new MSOStreamProtocolService(acceo);
    this.table = new HostedSlicerTable(this.config.state.canViewClassification, this.config.state.canViewDeleted);
    this.table.actions.rowClick = this.rowClick.bind(this);
    this.config.input.searchKeys = this.table.colsSelectable.map(col => col.colHeadName);
    this.hyperionPolling = new HyperionPolling({
      callbackFn: this.mergeTableRows.bind(this),
      includeDeleted: this.config.state.canViewDeleted,
      ms: this.hyperionPollingInterval,
      promiseFn: this.requestReadInterval.bind(this),
      useAfter: true,
    });

    this.config.input.search = '';
  }

  activate() {
    this.load();
  }

  deactivate() {
    this.hyperionPolling.stop();
  }

  public actionCreate() {
    this.hyperionPolling.stop();
    const shared = new ModelShared();
    shared.state.canSetProtocol = true;
    shared.state.canSetRegion = true;
    shared.input.slicerConfiguration.info.documentationUrl = DocumentationUrlLiveSlicer;
    shared.input.slicerConfiguration.input.table = new ConfigTable(ConfigSettingsLiveSlicer, false, false);
    shared.input.slicerConfiguration.options.configSetting = ConfigSettingsLiveSlicer;
    shared.input.slicerConfiguration.input.username = this.sessionService.username;
    const model = new LynkDialogServiceSettings();
    model.bodyViewModel = PLATFORM.moduleName('apps/cms/routes/slicers/hosted-slicers/single/modal');
    model.footer = 'none';
    model.sharedModel = shared;
    model.size = 'full';
    model.submitLabel = 'Create';
    model.title = 'Create Hosted Slicer';
    this.dialogService
      .open({
        model,
        viewModel: PLATFORM.moduleName('resources/services/dialog'),
      })
      .whenClosed()
      .then(result => {
        if (!result.wasCancelled) {
          this.load();
        } else {
          this.hyperionPolling.start();
        }
      });
  }

  public async actionDelete() {
    this.config.state.isDeleting = true;
    try {
      await this.requestDelete(this.table.rowsSelected);
      await this.load();
      this.notification.success(
        `Deleted ${this.table.rowsSelected.length} Hosted Slicer${this.table.rowsSelected.length === 1 ? '' : 's'}`,
      );
      this.table.selectAllRows(false);
    } catch (error) {
      this.acceoErrorToast.show(error);
    }
    this.config.state.isDeleting = false;
  }

  public async actionRestart() {
    this.config.state.isRestarting = true;
    try {
      await this.requestRestart(this.table.rowsSelected);
      this.notification.success(
        `Restarting ${this.table.rowsSelected.length} Hosted Slicer${this.table.rowsSelected.length === 1 ? '' : 's'}`,
      );
      await this.load();
      this.table.selectAllRows(false);
    } catch (error) {
      this.acceoErrorToast.show(error);
    }
    this.config.state.isRestarting = false;
  }

  public setClassificationFilter(option) {
    if (option !== 'showDeleted') {
      this.config.input.classification = option;
    }
  }

  public showHideColumns(col) {
    col.selected = !col.selected;
    this.table.trackSelectedCol(col);
  }

  protected async load() {
    this.config.state.isLoading = true;
    try {
      this.hyperionPolling.stop();
      await this.requestRead();
      await this.requestReadInterval().then(this.mergeTableRows.bind(this));
      this.hyperionPolling.start();
    } catch (error) {
      this.acceoErrorToast.show(error);
    }
    this.config.state.isLoading = false;
  }

  protected mergeTableRows(rows: HostedSlicerTableRow[]) {
    if (rows) {
      rows.forEach(row => {
        const matchingRow = this.table.rows.find(tableRow => tableRow.id === row.id);
        // merge existing row
        if (matchingRow) {
          matchingRow.audio = row.audio;
          matchingRow.change_log = row.change_log;
          matchingRow.channel_id = row.channel_id;
          matchingRow.classification = row.classification;
          matchingRow.created = row.created;
          matchingRow.deleted = row.deleted;
          matchingRow.encoder = row.encoder;
          matchingRow.lastmod = row.lastmod;
          matchingRow.notes = row.notes;
          matchingRow.profile = row.profile;
          matchingRow.protocol = row.protocol;
          matchingRow.quality = row.quality;
          matchingRow.resolution = row.resolution;
          matchingRow.server_instance_id = row.server_instance_id;
          matchingRow.server_region_id = row.server_region_id;
          matchingRow.server_region_location = row.server_region_location;
          matchingRow.server_region_name = row.server_region_name;
          matchingRow.slicer_id = row.slicer_id;
          matchingRow.slicer_name = row.slicer_name;
          matchingRow.slicer_secure_stream_url = row.slicer_secure_stream_url;
          matchingRow.slicer_software_version = row.slicer_software_version;
          matchingRow.slicer_stream_key = row.slicer_stream_key;
          matchingRow.slicer_stream_url = row.slicer_stream_url;
          matchingRow.slicer_version = row.slicer_version;
          matchingRow.stream_type_id = row.stream_type_id;
          if (matchingRow.status !== row.status) {
            // ugly hack to trigger CTable's getColClass action
            const index = this.table.rows.indexOf(matchingRow);
            row.selected = matchingRow.selected;
            row.selectedState = matchingRow.selectedState;
            this.table.rows.splice(index, 1);
            this.table.rows.splice(index, 0, row);
          }
        }
        // add a new row
        else {
          this.table.rows.push(row);
        }
      });
      // remove table rows no longer in server response
      this.table.rows = this.table.rows.filter(tableRow => {
        const matches = rows.filter(row => row.id === tableRow.id);
        return matches.length;
      });
    }
  }

  protected async requestDelete(ids: string[]) {
    return Promise.all(ids.map(id => this.slicerService.delete(id)));
  }

  protected async requestRead() {
    return Promise.all([
      this.hostingLocationService.get(),
      this.streamProtocolService.get(),
    ]).then(responses => {
      this.config.options.serverLocation = responses[0].items.map(item => ({
        text: item.name,
        value: item.id,
      }));

      this.config.options.serverRegion = responses[0].items.map(item => ({
        text: item.location,
        value: item.id,
      }));

      this.config.options.streamProtocol = responses[1].items.map(item => ({
        text: item.name,
        value: item.id,
      }));
    });
  }

  protected async requestReadInterval() {
    const end_states = [
      'DELETED',
      'FAILED',
      'FAILED_STOPPED',
      'STOPPED',
    ];

    // Get all slicers belonging to this owner
    const response = await this.slicerGetByOwnerService.get(this.sessionService.owner, this.config.input.showDeleted);

    // Filter to show slicers
    //   - that aren't deleted (running status)
    //   - that are being deleted (deleting status)
    //   - that can be viewed by slicer admins/managers (deleted status)
    const rows = response.items
      .filter(row => !row.deleted || row.status === 'DELETING' || this.config.state.canViewDeleted)
      .map((item, index) => {
        const result = item as HostedSlicerTableRow;

        switch (result.classification) {
          case MSOSlicerClassification.DEMO:
            result.classification = 'Demo';
            break;
          case MSOSlicerClassification.DEVELOPMENT:
            result.classification = 'Development';
            break;
          case MSOSlicerClassification.PRODUCTION:
            result.classification = 'Production';
            break;
          case MSOSlicerClassification.PROOF_OF_CONCEPT:
            result.classification = 'Proof of Concept';
            break;
          default:
            result.classification = 'Unknown';
            break;
        }
        result.order = index;

        const protocol = this.config.options.streamProtocol.find(proto => proto.value === result.stream_type_id);
        result.protocol = protocol ? protocol.text : 'N/A';

        const serverLocation = this.config.options.serverLocation.find(
          location => location.value === result.server_region_id,
        );
        result.server_region_location = serverLocation ? serverLocation.text : 'N/A';

        const serverRegion = this.config.options.serverRegion.find(region => region.value === result.server_region_id);
        result.server_region_name = serverRegion ? serverRegion.text : 'N/A';

        const profile = JSON.parse(result.profile);
        if (profile && profile.aKbps && profile.aMaxChannels) {
          result.audio = `${profile.aKbps} kb/s ${profile.aMaxChannels} channels`;
        } else {
          result.audio = 'N/A';
        }
        if (profile && profile.vKbps && profile.vPasses) {
          result.quality = `${profile.vKbps} kb/s ${profile.vPasses > 1 ? 'Multi' : 'Single'}-pass`;
        } else {
          result.quality = 'N/A';
        }
        if (profile && profile.encoder) {
          result.encoder = `${profile.encoder}`;
        } else {
          result.encoder = 'N/A';
        }
        if (profile && profile.maxW && profile.maxH && profile.fps) {
          result.resolution = `${profile.maxW}x${profile.maxH} @${profile.fps}fps`;
        } else {
          result.resolution = 'N/A';
        }

        // If the slicer is deleted (visible only to slicer admins/managers)
        // Show status as DELETED instead of STOPPED
        // Set the state of the row to unselectable (disabled)
        if (result.deleted) {
          if (result.status === 'DELETING') {
            // Need to display for everybody
            // Show status as deleting
            // Disable the row
            result.selectedState = 'disabled';
          } else if (this.config.state.canViewDeleted) {
            result.status = 'DELETED';
            result.selectedState = 'disabled';
          }
        }
        return result;
      });

    const promises: any = [];
    // Only update slicers that aren't deleted
    rows
      .filter(r => !end_states.includes(r.status))
      .forEach(row => {
        // Display deleting slicers until reconcilation process confirms slicer's deletion
        if (row.status === 'DELETING') {
          return;
        }
        const slicer = this.slicerService.getById(row.id).catch(message => {
          this.logger.error(message);
        });
        if (slicer) {
          promises.push(slicer);
        }
      });

    return Promise.all(promises).then(result => {
      const slicerResults: any = [];
      // Save updates in slicerResults array
      for (let i = 0, j = 0; i < result.length; i++) {
        if (result[i] !== undefined) {
          // @ts-ignore
          result[i].slicer.status = result[i].slicer.status.replace('_', ' ');
          slicerResults[j] = result[i];
          j += 1;
        }
      }

      // Only update status of slicers that aren't deleted
      rows
        .filter(r => !end_states.includes(r.status))
        .forEach(row => {
          // Find slicer whose status has changed
          const res = slicerResults.find(r => r.slicer.server_instance_id === row.server_instance_id);
          try {
            // Update status of the slicer with result from the update (promise)
            if (res && res.slicer) {
              row.status = res.slicer.status.replace('_', ' ');
            }

            // If slicer was created (status will be completed), display label as "INACTIVE" instead
            if (row.status === 'COMPLETED') {
              row.status = 'INACTIVE';
            }
          } catch (error) {
            // If we see any error and the slicer is not deleted, display status as "RETRYING"
            if (row.status !== 'DELETED') {
              row.status = 'RETRYING';
            }
          }
        });

      return Promise.resolve(rows);
    });
  }

  protected async requestRestart(ids: string[]) {
    return Promise.all(ids.map(id => this.slicerRestartService.getById(id)));
  }

  protected rowClick(slicer: HostedSlicerTableRow) {
    if (slicer.deleted) return;
    this.router.navigate(`/slicers/hosted/${slicer.id}`);
  }

  protected searchText(row, queryKeys) {
    let foundMatch = false;
    if (!this.config.input.searchQuery) {
      return true;
    }
    const query = this.config.input.searchQuery.replace(/\+/g, '\\+');
    const regex = new RegExp(query, 'i');
    queryKeys.forEach(key => {
      if (typeof row[key] === 'string' && !foundMatch) {
        foundMatch = !!row[key].toLowerCase().match(regex);
      }
    });
    return foundMatch;
  }
}
