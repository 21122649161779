import {LynkRange} from '@uplynk/lynk-design';
import {autoinject, bindable, observable, computedFrom} from 'aurelia-framework';
import Hls from 'hls.js';

export const PLAYER_SETTINGS_KEY = 'plyr'; // legacy key

@autoinject
export class HlsjsPlayer {
  @bindable public src: string;
  @bindable public currentTime: number = 0;
  @bindable public duration: number = 0;
  @bindable public adBreakOffsets: any = [];
  @bindable public enableKeyboardShortcuts: boolean = false;
  @bindable public progressbar: any;
  @observable public volume: number = 100;

  public seeking = false;
  public seekWasPlaying = false;

  private lastVolume: number = 100;
  private videoPlayer: HTMLVideoElement;
  private hls: Hls;
  private playerSettings: any = {
    muted: false,
    volume: 10,
  };

  @computedFrom('videoPlayer.volume', 'videoPlayer.muted')
  get volumeIcon() {
    const volume = this.videoPlayer.volume * 100;

    if (volume === 0 || this.videoPlayer.muted) {
      return 'volume-mute-fill';
    }
    if (volume > 0 && volume <= 50) {
      return 'volume-down-fill';
    }
    return 'volume-up-fill';
  }

  @computedFrom('videoPlayer.currentTime', 'videoPlayer.duration')
  get progress() {
    let percentagePlayed = this.videoPlayer.currentTime / this.videoPlayer.duration;

    if (percentagePlayed > 1) {
      percentagePlayed = 1;
    } else if (percentagePlayed < 0) {
      percentagePlayed = 0;
    }
    return percentagePlayed * 100;
  }

  @computedFrom('adBreakOffsets.length', 'videoPlayer.duration')
  get ticks() {
    return this.adBreakOffsets.map(({offset}) => ({
      value: (offset / this.videoPlayer.duration) * 100,
      label: 'Ad',
      selected: true,
    }));
  }

  constructor() {}

  attached() {
    this.loadVideo();

    // Add event listeners to the video element
    if (this.videoPlayer) {
      this.videoPlayer.addEventListener('timeupdate', this.updateCurrentTime.bind(this));
      this.videoPlayer.addEventListener('loadedmetadata', this.updateDuration.bind(this));
    }

    // Add keyboard shortcuts if enabled
    if (this.enableKeyboardShortcuts) {
      document.addEventListener('keydown', this.keyDownHandler.bind(this));
    }
  }

  detached() {
    if (this.videoPlayer) {
      this.videoPlayer.removeEventListener('timeupdate', this.updateCurrentTime.bind(this));
      this.videoPlayer.removeEventListener('loadedmetadata', this.updateDuration.bind(this));
    }

    // Remove keyboard shortcuts if they were added
    if (this.enableKeyboardShortcuts) {
      document.removeEventListener('keydown', this.keyDownHandler.bind(this));
    }

    this.destroyHls();
  }

  srcChanged() {
    this.loadVideo();
  }

  public playPause() {
    /* istanbul ignore else */
    if (this.videoPlayer) {
      if (this.videoPlayer.paused) {
        this.play();
      } else {
        this.pause();
      }
    }
  }

  public play() {
    /* istanbul ignore else */
    if (this.videoPlayer) {
      this.videoPlayer.play();
    }
  }

  public pause() {
    /* istanbul ignore else */
    if (this.videoPlayer) {
      this.videoPlayer.pause();
    }
  }

  public getPlayerSettings() {
    const playerSettingsString = localStorage.getItem(PLAYER_SETTINGS_KEY);
    if (playerSettingsString) {
      this.playerSettings = JSON.parse(playerSettingsString);
    }
  }

  public setPlayerSettings() {
    this.getPlayerSettings();
    if (this.videoPlayer) {
      this.playerSettings.muted = this.videoPlayer.muted;
      if (this.playerSettings.muted || this.videoPlayer.volume === 0) {
        this.playerSettings.volume = this.lastVolume / 10;
      } else {
        this.playerSettings.volume = this.videoPlayer.volume * 10;
      }
      localStorage.setItem(PLAYER_SETTINGS_KEY, JSON.stringify(this.playerSettings));
    }
  }

  public toggleVolume() {
    /* istanbul ignore else */
    if (this.videoPlayer) {
      if (this.videoPlayer.muted) {
        this.volume = this.lastVolume;
        this.videoPlayer.muted = false;
      } else {
        this.lastVolume = this.volume;
        this.volume = 0;
        this.videoPlayer.muted = true;
      }
      this.setPlayerSettings();
    }
  }

  public volumeChanged(newVol) {
    if (this.videoPlayer && newVol !== 0) {
      this.videoPlayer.muted = false;
      this.videoPlayer.volume = newVol / 100;
    }
    this.setPlayerSettings();
  }

  public seek(value) {
    const position = value / 100;
    this.seeking = true;
    this.seekWasPlaying = !this.videoPlayer.paused;
    this.pause();
    this.videoPlayer.currentTime = this.videoPlayer.duration * position;
    if (this.seekWasPlaying && position < 1) {
      this.play();
    }
  }

  private loadVideo() {
    this.destroyHls(); // Destroy the previous HLS instance if it exists

    if (!this.src) {
      return; // Exit if the source is not defined
    }

    // If Hls.js is supported (for browsers without native HLS support)
    if (Hls.isSupported()) {
      this.hls = new Hls();
      this.hls.loadSource(this.src);
      this.hls.attachMedia(this.videoPlayer);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // this.videoPlayer.play();
      });

      // Set volume from local storage
      this.getPlayerSettings();
      this.lastVolume = this.playerSettings.volume * 10;
      this.videoPlayer.muted = this.playerSettings.muted;
      if (this.playerSettings.muted) {
        this.volume = 0;
      } else {
        this.videoPlayer.volume = this.playerSettings.volume / 10;
        this.volume = this.videoPlayer.volume * 100;
      }

      this.hls.on(Hls.Events.ERROR, (_, data) => {
        // console.error('HLS Error:', data.type, data.details, data.fatal);
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // console.log('fatal network error encountered, trying to recover...');
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // console.log('fatal media error encountered, trying to recover...');
              this.hls.recoverMediaError();
              break;
            default:
              // console.log('non-recoverable error occurred. destroying...');
              this.destroyHls();
              break;
          }
        }
      });
    }
    // If the browser has native support for HLS
    else if (this.videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
      this.videoPlayer.src = this.src;
    } else {
      // console.warn('HLS not supported on this platform!');
    }
  }

  public setCurrentTime(newTime: number) {
    /* istanbul ignore else */
    if (this.videoPlayer) {
      this.videoPlayer.currentTime = newTime;
    }
  }

  private updateCurrentTime() {
    this.currentTime = this.videoPlayer.currentTime;
  }

  private updateDuration() {
    this.duration = this.videoPlayer.duration;
  }

  private keyDownHandler(event: KeyboardEvent) {
    // Ignore the key press if it originates from input, textarea, or select
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLTextAreaElement ||
      event.target instanceof HTMLSelectElement
    ) {
      return;
    }
    const originalEventTarget = event.composedPath()[0];
    if (
      (!(event.target instanceof LynkRange) && originalEventTarget instanceof HTMLInputElement) ||
      originalEventTarget instanceof HTMLTextAreaElement ||
      originalEventTarget instanceof HTMLSelectElement
    ) {
      return;
    }

    // If j key is pressed go back 5 seconds
    if (event.key === 'j' && this.videoPlayer) {
      const newTime = this.videoPlayer.currentTime - 5;
      this.videoPlayer.currentTime = newTime < 0 ? 0 : newTime;
    }

    // If k key is pressed toggle play/pause
    if (event.key === 'k' && this.videoPlayer) {
      // play/pause
      if (this.videoPlayer.paused) {
        this.play();
      } else {
        this.pause();
      }
    }

    // If l key is pressed go forward 5 seconds
    if (event.key === 'l' && this.videoPlayer) {
      const newTime = this.videoPlayer.currentTime + 5;
      this.videoPlayer.currentTime = newTime > this.videoPlayer.duration ? this.videoPlayer.duration : newTime;
    }

    // If space bar is pressed toggle play/pause
    if (event.key === ' ' && !(event.target instanceof HTMLVideoElement)) {
      // play/pause
      if (this.videoPlayer.paused) {
        this.play();
      } else {
        this.pause();
      }
    }

    // If m key is pressed toggle mute/unmute
    if (event.key === 'm' && this.videoPlayer) {
      this.toggleVolume();
    }

    // If number key is pressed seek to that value
    if (/^[0-9]$/i.test(event.key) && this.videoPlayer) {
      const p = +event.key * 10;
      this.seek(p);
    }

    // If Home is pressed go to start
    if (event.key === 'Home' && this.videoPlayer) {
      this.seek(0);
    }

    // If End is pressed go to end
    if (event.key === 'End' && this.videoPlayer) {
      this.seek(100);
    }

    // Frame by frame with arrow keys
    // Assuming 24fps by default.
    // TODO: get actual FPS from video
    const frameDuration = 1 / 24;

    // If right arrow key is pressed
    if (event.key === 'ArrowRight' && this.videoPlayer) {
      event.preventDefault();
      if (!this.videoPlayer.paused) {
        this.pause();
      }
      const newTime = this.videoPlayer.currentTime + frameDuration;
      this.videoPlayer.currentTime = newTime > this.videoPlayer.duration ? this.videoPlayer.duration : newTime;
    }
    // If left arrow key is pressed
    if (event.key === 'ArrowLeft' && this.videoPlayer) {
      event.preventDefault();
      if (!this.videoPlayer.paused) {
        this.pause();
      }
      const newTime = this.videoPlayer.currentTime - frameDuration;
      this.videoPlayer.currentTime = newTime < 0 ? 0 : newTime;
    }

    // If up arrow key is pressed turn up volume 5%
    if (event.key === 'ArrowUp' && this.videoPlayer) {
      event.preventDefault();
      const newVolume = this.volume + 5;
      this.volume = newVolume > 100 ? 100 : newVolume;
    }
    // If down arrow key is pressed turn down volume 5%
    if (event.key === 'ArrowDown' && this.videoPlayer) {
      event.preventDefault();
      const newVolume = this.volume - 5;
      this.volume = newVolume < 0 ? 0 : newVolume;
    }
  }

  private destroyHls() {
    if (this.hls) {
      this.hls.detachMedia();
      this.hls.destroy();
      this.hls = null;
    }
  }
}
