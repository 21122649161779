export enum ConfigLineType {
  // cag TODO maybe add a type for Operational/ Uplynk vs. Ad Server?  Note Macro is noted below...
  COMMENT,
  CUSTOM,
  DISALLOWED,
  DUPLICATE,
  EMPTY,
  KVP,
  KVP_AND_COMMENT,
  MACRO,
  MANAGED,
  REQUIRED,
  UNSUPPORTED,
}

export interface IConfigLine {
  comment?: string;
  error?: string;
  key?: string;
  original: string;
  type: ConfigLineType;
  value?: string;
  provider?: string;
}
