/* eslint-disable max-classes-per-file */

import {Type} from 'class-transformer';
import {IsNumber, IsOptional, IsString, ValidateNested} from 'class-validator';
import {IKeyValue} from 'interfaces/key-value';

export interface ISsoDomainUser {
  created?: string;
  deleted?: string;
  email?: string;
  id: string;
  ids_email: string;
  ids_username?: string;
  owner_id: string;
  sso_domain_id: string;
}

export class SsoDomainUser implements ISsoDomainUser {
  @IsString()
  @IsOptional()
  public created?: string;

  @IsString()
  @IsOptional()
  public deleted?: string;

  @IsString()
  public id: string;

  @IsString()
  public ids_email: string;

  @IsOptional()
  @IsString()
  public ids_username: string;

  @IsString()
  public owner_id: string;

  @IsString()
  public sso_domain_id: string;
}

export class SsoDomainUserResponse {
  @ValidateNested()
  @Type(() => SsoDomainUser)
  public items: SsoDomainUser[];

  @IsNumber()
  public total_items: number;
}

export class SsoDomainUserUpdateResponse {
  @IsOptional()
  @IsString()
  public message: string;

  @IsOptional()
  public errors?: IKeyValue<string>;
}
