import {CTableRowSelectable} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {MSOSlicerResponseGetByOwnerItem} from '../../models/msoslicer';

export class HostedSlicerTableRow extends MSOSlicerResponseGetByOwnerItem implements CTableRowSelectable {
    public audio: string;
    public encoder: string;
    public order: number;
    public protocol: string;
    public quality: string;
    public resolution: string;
    public selected?: boolean;
    public selectedState?: string;
    public server_region_location: string;
    public server_region_name: string;
}

export enum HostedSlicerTableColHeadValue {
    audio = 'Audio',
    created = 'Date Created',
    classification = 'Classification',
    deleted = 'Date Deleted',
    encoder = 'Encoder',
    id = 'GUID',
    modified = 'Date Modified',
    notes = 'Notes',
    protocol = 'Protocol',
    quality = 'Quality',
    resolution = 'Resolution',
    serverInstanceId = 'Server Instance ID',
    serverRegionLocation = 'Location',
    serverRegionName = 'Region',
    slicerId = 'Slicer ID',
    slicerSoftwareVersion = 'Software Version',
    slicerVersion = 'Slicer Version',
    status = 'Status',
    streamKey = 'Stream Key',
    streamUrl = 'Stream URL',
    streamUrlSecure = 'Stream URL (Secure)',
}
