import {autoinject} from 'aurelia-framework';
import {HostedSlicersSingle} from '.';
import {SlicerPlugin} from '../../models/csl-slicers';

@autoinject()
export class HostedSlicersSinglePlugins {
  public isLoading: boolean = false;

  constructor(public parentView: HostedSlicersSingle) {}

  public async attached() {
    if (this.parentView.plugins === undefined) {
      try {
        this.isLoading = true;
        this.parentView.plugins = await this.parentView.service.getPlugins();
      } catch (error) {
        this.parentView.notification.error('Failed to load plugins');
      } finally {
        this.isLoading = false;
      }
    }
  }

  public onCkeckPlugin(event) {
    const {value, checked} = event.target;
    let plugin: SlicerPlugin;

    if (checked) {
      plugin = {
        id: value.id,
        version: value.versions[value.versions.length - 1].version,
      };
    }

    this.parentView.slicer.plugin = plugin;
  }
}
