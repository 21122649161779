import {autoinject, computedFrom} from 'aurelia-framework';
import {basicSetup} from 'decorators';
import {Notification} from 'resources/notification/service';
import {AccountSettings as AccountSettingsModel} from './models/account-settings';
import {AccountSettingsService, IdsFieldType} from './services/account-settings-service';

interface DirtyFields {
  newEmail?: boolean;
  newEmailConfirm?: boolean;
  newIdsUsername?: boolean;
  newIdsUsernameConfirm?: boolean;
}

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@basicSetup
@autoinject()
export class AccountSettings {
  public email: string;
  public ownerId: string;
  public ids_email: string;
  public ids_username: string;
  public ids_id: string;
  public ids_domain_id: string;
  public password: string;
  public newEmail: string;
  public newEmailConfirm: string;
  public newIdsUsername: string;
  public newIdsUsernameConfirm: string;
  public newPassword: string;
  public newPasswordConfirm: string;
  public isSsoEnabled: boolean = false;
  public isSaving: boolean;
  public isLoading: boolean = false;
  public changeEmailModal;
  public changeIdsUsernameModal;
  public incorrectPwEmail: boolean;
  public incorrectPwIdsUsername: boolean;
  public incorrectPwPassword: boolean;
  public dirtyFields: DirtyFields = {};
  public idsResetPasswordUrl: string = `https://id${
    window.location.hostname.includes('uplynk') ? '' : '-dev'
  }.vdms.io/password-reset`;

  @computedFrom('password', 'newEmail', 'newEmailConfirm')
  public get canSaveEmail(): boolean {
    return (
      this.checkExistence('Email') &&
      this.password &&
      this.newEmail.toLowerCase().trim() === this.newEmailConfirm.toLowerCase().trim()
    );
  }

  @computedFrom('password', 'newIdsUsername', 'newIdsUsernameConfirm')
  public get canSaveIdsUsername(): boolean {
    return (
      this.checkExistence('IdsUsername') &&
      this.password &&
      this.newIdsUsername.toLowerCase().trim() === this.newIdsUsernameConfirm.toLowerCase().trim()
    );
  }

  @computedFrom('newEmail', 'newEmailConfirm', 'dirtyFields.newEmailConfirm')
  public get emailNotMatch(): boolean {
    return (
      this.checkExistence('Email') && this.newEmail.toLowerCase().trim() !== this.newEmailConfirm.toLowerCase().trim()
    );
  }

  /**
   * Determine whether or not the owner is the Original Owner or if they have switched accounts.
   * @returns {Boolean}
   */
  @computedFrom('accountSettingsService.session.sessionInfo.origOwnerID', 'ownerID')
  public get isOrigOwner() {
    return this.accountSettingsService.session.sessionInfo.origOwnerID === this.ownerId;
  }

  @computedFrom('newEmail', 'dirtyFields.newEmail')
  public get notValidEmail(): boolean {
    const emailIsValid = EMAIL_REGEX.test(this.newEmail);
    return !emailIsValid && !!this.newEmail && this.dirtyFields.newEmail;
  }

  @computedFrom('newIdsUsername', 'dirtyFields.newIdsUsername')
  public get notValidIdsUsername(): boolean {
    return this.dirtyFields.newIdsUsername && !this.newIdsUsername;
  }

  @computedFrom('newIdsUsername', 'newIdsUsernameConfirm', 'dirtyFields.newIdsUsernameConfirm')
  public get usernameNotMatch(): boolean {
    return (
      this.checkExistence('IdsUsername') &&
      this.newIdsUsername.toLowerCase().trim() !== this.newIdsUsernameConfirm.toLowerCase().trim()
    );
  }

  @computedFrom('isSaving')
  public get saveBtnText() {
    return this.isSaving ? 'Saving...' : 'Save';
  }

  constructor(public notification: Notification, public accountSettingsService: AccountSettingsService) {}

  public changeModalOpen(target: IdsFieldType) {
    const updateType = target === IdsFieldType.EMAIL ? 'Email' : target;
    this.password = '';
    this.resetDirtyFields();
    this[`new${updateType}`] = '';
    this[`new${updateType}Confirm`] = '';
    this[`incorrectPw${updateType}`] = false;
    this[`change${updateType}Modal`].show();
  }

  public async getData(): Promise<AccountSettingsModel> {
    try {
      this.isLoading = true;
      const accountSettings = await this.accountSettingsService.getAccountInfo();

      this.email = accountSettings.username;
      this.ownerId = accountSettings.ownerID;
      this.ids_email = accountSettings.ids_email;
      this.ids_username = accountSettings.ids_username;
      this.ids_id = accountSettings.ids_id;
      this.isSsoEnabled = !!accountSettings.ids_domain_id;

      return accountSettings;
    } finally {
      this.isLoading = false;
    }
  }

  public setFieldDirty(key: string) {
    this.dirtyFields[key] = true;
  }

  public async update(target: string): Promise<void> {
    if (target === 'Email' && !this.canSaveEmail) {
      return;
    }

    this.isSaving = true;

    try {
      let updateType = target;

      if (target === 'Email') {
        updateType = IdsFieldType.EMAIL;
      }

      const newValue = this[`new${target}`].trim();
      const confirmValue = this[`new${target}Confirm`].trim();

      await this.accountSettingsService.updateAccount(updateType, this.password, newValue, confirmValue);

      if (updateType === IdsFieldType.EMAIL) {
        this.ids_email = newValue;
      } else if (updateType === IdsFieldType.USERNAME) {
        this.ids_username = newValue;
      }

      this.notification.success('Changes saved successfully. You will now be logged out');
      this[`change${target}Modal`].hide();

      setTimeout(() => {
        this.accountSettingsService.session.logout();
      }, 1000);
    } catch (err) {
      const msg = err.message;
      this.notification.error(msg);

      if (msg.toLowerCase().includes('password')) {
        this[`incorrectPw${target}`] = true;
      }
    } finally {
      this.isSaving = false;
      this.password = undefined;
    }
  }

  private checkExistence(target: string) {
    return !!(this[`new${target}`] && this[`new${target}Confirm`]);
  }

  private resetDirtyFields() {
    this.dirtyFields = {};
  }
}
