import {bindable} from 'aurelia-framework';

const iframeUrl: string = 'embedded-player.html';

export class UplynkPlayer {
  @bindable public streamUrl: string;
  @bindable public autostart: boolean = false;
  public playerWrapper: HTMLIFrameElement;

  public setupPlayer(): void {
    this.resetPlayer();
    if (
      !this.streamUrl ||
      !this.streamUrl.length ||
      (this.streamUrl.indexOf('.m3u8') === -1 && this.streamUrl.indexOf('.mpd') === -1)
    ) {
      return;
    }

    if (this.playerWrapper) {
      this.playerWrapper.innerHTML = `
            <iframe src="${iframeUrl}"
                style="border:none"
                data-autostart="${this.autostart}"
                data-url="${this.zonedStreamURL()}"
                allow="encrypted-media;fullscreen;autoplay;"
                allowfullscreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
            ></iframe>`;
    }
  }

  public resetPlayer(): void {
    if (this.playerWrapper) {
      this.playerWrapper.innerHTML = '';
    }
  }

  public streamUrlChanged(): void {
    this.setupPlayer();
  }

  public attached(): void {
    this.setupPlayer();
  }

  public detached(): void {
    this.resetPlayer();
  }

  public zonedStreamURL(): string {
    const streamUrl: string = `${this.streamUrl}`;
    const [subdomain]: string[] = window.location.hostname.split('.');
    const zoneRegEx: RegExp = /^http[s]?:\/\/\w*((-\w*)+)\.(uplynk|downlynk)/;
    const zoneMatch: RegExpMatchArray | null = subdomain.match(/\w*((-\w*)+)/);

    if (zoneMatch) {
      const [
        ,
        zone,
      ]: string[] = zoneMatch;
      const streamUrlHasZone: boolean = !!streamUrl.match(zoneRegEx);

      if (!streamUrlHasZone) {
        return streamUrl.replace(/^(^http[s]?:\/\/\w*)\./, `$1${zone}.`);
      }
    }

    return streamUrl;
  }
}
