import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {SessionService} from 'services/session';
import {ClippingConfig} from '../models/clipping-config';

@autoinject()
export class ClippingDetails {
    @bindable public config: ClippingConfig;
    @bindable public validationErrors: ValidationError[];
    @bindable public callbacks;
    public hasError = false;

    constructor(public session: SessionService) {}
}
