import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {PLATFORM} from 'aurelia-pal';

@autoinject
export class Overlay {
  public isLoading: boolean;

  public routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: 'list',
      nav: true,
      route: '',
      title: 'Images',
    },
  ];

  constructor(public router: Router) {
    this.isLoading = false;
  }

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }
}
