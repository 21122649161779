import * as CTableCssJson from '@bindable-ui/bindable/dist/native-modules/components/tables/c-table/c-table.css.json';
import {PLATFORM} from 'aurelia-framework';
import {CTableSelectable} from 'resources/components/c-table-selectable/c-table-selectable';
import {
  CTableColSelectable,
  CTableRowBehavior,
} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {ConfigTableData} from './table-data';
import {IConfigLineTableRow} from './models/config-line-table';
import {IConfigSetting} from './models/config-setting';
import {ConfigFormat} from './models/config-format';
import {ConfigImportMode} from './import/models/config-import';
import {ConfigLineType} from './models/config-line';

export class ConfigTable extends CTableSelectable {
  public rows: IConfigLineTableRow[] = [];
  public configTableData: ConfigTableData;
  protected colsSelectableDefault: CTableColSelectable[] = [
    {
      colClass: this.getClass(CTableCssJson.t215),
      colHeadName: 'key',
      colHeadValue: 'Key',
      selected: true,
      sort: false,
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate'),
    },
    {
      colHeadName: 'value',
      colHeadValue: 'Value',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t190),
      colHeadName: undefined,
      colHeadValue: 'Support',
      selected: true,
      sort: false,
    },
  ];

  constructor(configSettings: IConfigSetting[], isDuplicateKeySupported: boolean, isReadOnly: boolean) {
    super(isReadOnly ? CTableRowBehavior.DEFAULT : CTableRowBehavior.DRAG_CHECK);
    this.actions.getColClass = this.getColClass;
    this.actions.getColValue = this.getColValue;
    this.actions.getRowClass = this.getRowClass;
    this.colsSelectable = this.colsSelectableDefault;
    this.configTableData = new ConfigTableData(configSettings, isDuplicateKeySupported);
  }

  public editLine(requestedId: string, key?: string, value?: string, comment?: string) {
    this.rows = this.configTableData.editLine(requestedId, key, value, comment);
  }

  public exportFile(fileName: string, fileFormat: ConfigFormat) {
    return this.configTableData.exportFile(fileName, fileFormat);
  }

  public exportString(format: ConfigFormat = ConfigFormat.KVP) {
    return this.configTableData.exportString(format);
  }

  public importLine(key?: string, value?: string, comment?: string) {
    if (this.rowBehavior !== CTableRowBehavior.DEFAULT) this.cols[0].colHeadSelectedVal = false;
    this.rows = this.configTableData.importLine(key, value, comment);
  }

  public importString(value: string, mode: ConfigImportMode = ConfigImportMode.APPEND) {
    if (this.rowBehavior !== CTableRowBehavior.DEFAULT) this.cols[0].colHeadSelectedVal = false;
    this.rows = this.configTableData.importString(value, mode);
  }

  public removeLines(requestedIds: string[]) {
    this.rows = this.configTableData.removeLines(requestedIds);
  }

  protected getColClass(row: IConfigLineTableRow, col: CTableColSelectable) {
    if (col.colHeadValue === 'Support') {
      switch (row.type) {
        case ConfigLineType.DISALLOWED:
        case ConfigLineType.DUPLICATE:
          return 'bgWarning';
        case ConfigLineType.MANAGED:
          return 'bgMajor';
        case ConfigLineType.UNSUPPORTED:
          return 'bgCritical';
        default:
          return col._class;
      }
    }
    return col._class;
  }

  protected getColValue(row: IConfigLineTableRow, col: CTableColSelectable) {
    if (col.colHeadValue === 'Support') {
      switch (row.type) {
        case ConfigLineType.CUSTOM:
          return 'Advanced Setting';
        case ConfigLineType.DISALLOWED:
          return 'Not Allowed';
        case ConfigLineType.DUPLICATE:
          return 'Duplicate Key';
        case ConfigLineType.KVP:
        case ConfigLineType.KVP_AND_COMMENT:
          return 'Standard Setting';
        case ConfigLineType.MANAGED:
          return 'Managed Automatically';
        case ConfigLineType.UNSUPPORTED:
          return 'Unsupported Format';
        default:
          return row[col.colHeadName];
      }
    }
    return row[col.colHeadName];
  }

  protected getRowClass(row: IConfigLineTableRow) {
    let returnValue: string;
    if (row.type === ConfigLineType.COMMENT) {
      returnValue = 'bgInfo';
    }
    return returnValue;
  }
}
