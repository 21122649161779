// eslint-disable-next-line max-classes-per-file
import {DialogController} from 'aurelia-dialog';

export class LynkDialogServiceViewModel {
  public static inject = [DialogController];
  public controller: DialogController;
  public settings: LynkDialogServiceSettings;
  protected thinking = false;

  constructor(controller: DialogController) {
    this.controller = controller;
    this.settings = this.controller.settings.model;
    if (this.settings.cachePristineModels) {
      this.cloneModels('dirty');
    }
  }

  protected cloneModels(sources: 'dirty' | 'pristine') {
    const modelsDirty = [
      'bodyModel',
      'footerModel',
      'sharedModel',
    ];
    const modelsPristine = modelsDirty.map(modelName => `${modelName}Pristine`);
    const modelSources = sources === 'dirty' ? modelsDirty : modelsPristine;
    const modelTargets = sources === 'dirty' ? modelsPristine : modelsDirty;
    modelTargets.forEach((targetModelName, i) => {
      const modelSource = this.settings[modelSources[i]];
      this.settings[targetModelName] = this.settings[targetModelName] || {};
      Object.keys(modelSource).forEach(key => {
        this.settings[targetModelName][key] = _.cloneDeep(modelSource[key]);
      }, this);
    }, this);
  }

  protected handleRequestClose(event: CustomEvent) {
    switch (event.detail.source) {
      case 'keyboard':
        if (this.controller.settings.keyboard) {
          this.handleReset();
        } else {
          event.preventDefault();
        }
        break;
      case 'overlay':
        if (!this.controller.settings.overlayDismiss) {
          event.preventDefault();
        }
        break;
      default:
        this.handleReset();
        break;
    }
    return true;
  }

  protected handleReset(event?: Event) {
    if (event) event.stopPropagation();
    if (this.settings.cachePristineModels) {
      this.cloneModels('pristine');
    }
    if (this.settings.resetAction) {
      this.controller.cancel(this.settings.resetAction());
    } else {
      this.controller.cancel();
    }
  }

  protected async handleSubmit(event: Event) {
    event.preventDefault();
    let valid = false;
    this.thinking = true;
    // always perform constraint validation first
    valid = this.settings.form.reportValidity();
    // maybe perform custom validation
    if (valid && this.settings.reportValidity) {
      valid = await this.settings.reportValidity();
    }
    if (valid) {
      if (this.settings.submitAction) {
        const output = await this.settings.submitAction();
        if (output) {
          this.controller.ok(output);
        }
      } else {
        this.controller.ok();
      }
    }
    this.thinking = false;
  }
}

export class LynkDialogServiceModel {
  public controller: DialogController;
  public settings = new LynkDialogServiceSettings();
}

export class LynkDialogServiceSettings {
  /**
   * Specifies which type of body to use for this dialog.
   * 'text' will only render the specified text within the dialog
   * 'view' should be used if you wish to specify a custom view
   * 'view-model' should be used if you wish to specify a custom viewModel
   */
  body?: 'text' | 'view' | 'view-model';

  /**
   * A model provided to only to the dialog's body view-model upon activation
   */
  bodyModel?: any = {};
  bodyModelPristine?: any = {};

  /**
   * If you just need text in your dialog you can place it here in a string.
   */
  bodyText?: string;

  bodyView?: string;

  /**
   * If you need a more complex dialog you can make a new view and then pass
   * in the path to that view here. It will put that template in the body of the dialog.
   */
  bodyViewModel?: string;

  /**
   * Enabling this will automatically clone the bodyModel, footerModel, and sharedModels
   * to their 'Pristine' version when the dialog is opened. When the dialog is
   * "canceled" (such as by pressing the close button), the 'Pristine' models are
   * automatically copied back to their original version.
   */
  cachePristineModels = false;

  /**
   * Specifies which type of footer to use for this dialog.
   * 'standard' will enable the built-in submit/cancel buttons
   * 'view' should be used if you wish to specify a custom view
   * 'view-model' should be used if you wish to specify a custom viewModel
   */
  footer?: 'none' | 'standard' | 'view' | 'view-model' = 'none';

  /**
   * A model provided to only to the dialog's footer view-model upon activation
   */
  footerModel?: any = {};
  footerModelPristine?: any = {};

  /**
   * If your footer is more complex you can create a template and add the path to that template here.
   */
  footerViewModel?: string;

  /**
   * A reference to the form element constructed by this dialog service
   */
  form: HTMLFormElement;

  /**
   * An optional async function which may be used for
   * client or server form data validation
   */
  reportValidity: () => Promise<boolean>;

  /**
   * A function to call when this dialog's form is reset.
   * If cachePristine is enabled, any body, footer, and shared models will be
   * deep cloned to their pristine counterparts before triggering this callback.
   * Both of the above will occur when the user presses the 'Cancel' button
   * displayed when the footer is set to 'standard'.
   */
  resetAction?: () => any;

  /**
   * A model provided to all dialog view-models upon activation
   */
  sharedModel?: any = {};
  sharedModelPristine?: any = {};

  /**
   * The dialog's size. Defaults to 'auto'.
   */
  size?: 'fitted' | 'full' | 'auto';

  /** A function to call when this dialog's form is submitted. */
  submitAction?: () => any;

  /**
   * The color of the submit button displayed in the footer when no footerText
   * or footerViewModel is defined. Defaults to 'primary'.
   */
  submitColor?: string;

  /**
   * The label for the submit button displayed in the footer when no footerText
   * or footerViewModel is defined. Defaults to 'Save'.
   */
  submitLabel?: string;

  /**
   * Set what will be displayed as the title of the dialog.
   */
  title?: string;
}
