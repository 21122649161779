import {CToastsService} from '@bindable-ui/bindable';
import {TemplatesAccess, TemplatesRequest} from 'apps/cms/routes/slicers/models/template';
import {DialogService} from 'aurelia-dialog';
import {autoinject, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ConfigFormat} from 'resources/config/models/config-format';
import {ConfigImportSource} from 'resources/config/import/models/config-import';
import {ConfigSettingsLiveSlicer, DocumentationUrlLiveSlicer} from 'resources/config/settings/live-slicer';
import {ConfigTable} from 'resources/config/table';
import {ValidateHelper} from 'resources/validate-helper';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {TemplateMenu} from '..';
import {TemplatesService} from '../../services/template-service';
import {Config as SharedModel} from '../list/modal/models';
import {MSSConfigEditor} from './editor';
import {Config} from './models';

@autoinject
export class TemplateDetails extends MSSConfigEditor {
  public config: Config;
  public templatesService: TemplatesService;

  constructor(
    protected acceo: Acceo,
    protected dialogService: DialogService,
    protected notification: CToastsService,
    protected router: Router,
    protected sessionService: SessionService,
    protected templateMenu: TemplateMenu,
  ) {
    super(acceo, dialogService, sessionService);
    this.templatesService = new TemplatesService(acceo);
  }

  public activate(params?: any) {
    this.config = new Config();
    this.load(params.id);
  }

  public actionBack() {
    const url = this.router.baseUrl.substr(0, this.router.baseUrl.length - 1);
    this.router.navigate(url);
  }

  public async actionDelete() {
    this.config.state.isDeleting = true;
    try {
      await this.requestDelete(this.config.input.id);
      this.notification.success('Template deleted successfully');
      await this.templateMenu.load();
      this.actionBack();
    } catch (reason) {
      this.notification.error(reason.message);
    }
    this.config.state.isDeleting = false;
  }

  public async actionExport() {
    await super.actionExport(this.config.input.name || this.config.input.description || 'template');
  }

  public async actionImport() {
    if (!this.actionImportValidate()) return;
    switch (this.config.input.import.source) {
      case ConfigImportSource.FILE:
        this.config.input.table.importString(
          this.config.input.import.config.exportString(),
          this.config.input.import.mode,
        );
        break;
      case ConfigImportSource.TEMPLATE:
        this.config.input.table.importString(
          JSON.stringify(this.config.input.import.template.config),
          this.config.input.import.mode,
        );
        break;
      default:
        break;
    }
    this.tipImport.hide();
  }

  public async actionImportTemplate() {
    const model = new SharedModel();
    this.config.input.import.template = model.input.selection;
    this.dialogService
      .open({
        model: {
          bodyViewModel: PLATFORM.moduleName('apps/cms/routes/slicers/templates/list/modal/body'),
          footerEnable: true,
          sharedModel: model,
          size: 'medium',
          title: 'Template Selection',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed(() => {
        this.actionImportValidate();
      });
  }

  public async actionUpdate() {
    const request = this.transformRequestInput();
    const valid = await ValidateHelper.validate(request, this.config.errors);
    if (!valid) return;
    this.config.state.isUpdating = true;
    try {
      await this.requestUpdate(request);
      await this.templateMenu.load();
      this.notification.success('Template updated successfully');
      this.actionBack();
    } catch (reason) {
      this.notification.error(reason.message);
    }
    this.config.state.isUpdating = false;
  }

  public async onChangeConfig(files: FileList) {
    this.config.errors.import.fileValue = undefined;
    this.config.errors.import.config = undefined;
    try {
      await this.config.input.import.config.importFile(files[0], false);
      this.config.input.import.config.validate();
    } catch (error) {
      this.config.errors.import.config = error;
    }
  }

  protected actionImportValidate() {
    switch (this.config.input.import.source) {
      case ConfigImportSource.FILE:
        this.config.errors.import.fileValue = !this.config.input.import.fileValue ? 'File is required' : undefined;
        break;
      case ConfigImportSource.TEMPLATE:
        this.config.errors.import.template = !this.config.input.import.template.name
          ? 'Template is required'
          : undefined;
        break;
      default:
        break;
    }
    return !this.config.errors.import.fileValue && !this.config.errors.import.template;
  }

  protected async load(id?: string) {
    this.config.state.error = undefined;
    this.config.state.isLoading = true;
    this.config.state.canImportTemplates = true;
    if (this.sessionService.hasHostedSlicersAccess) {
      this.config.state.canSetParent = true;
      this.config.state.canSetPublic = true;
    }
    try {
      if (id) {
        const template = await this.requestReadTemplate(id);
        this.config.input.accessPublic = template.access === TemplatesAccess.PUBLIC;
        this.config.input.default = template.default;
        this.config.input.description = template.desc;
        this.config.input.favorite = template.favorite;
        this.config.input.id = template.id;
        this.config.input.name = template.name;
        this.config.input.parent = template.parent;
        this.config.state.isReadOnly = this.sessionService.owner !== template.owner;
        this.config.input.table = new ConfigTable(ConfigSettingsLiveSlicer, false, this.config.state.isReadOnly);
        this.config.input.table.importString(JSON.stringify(template.config));
        this.config.options.configSetting = ConfigSettingsLiveSlicer;
        this.config.info.documentationUrl = DocumentationUrlLiveSlicer;
      }
      if (this.config.state.canSetParent) {
        const templates = await this.requestReadTemplates();
        this.config.options.parent = [
          {
            text: 'None',
            value: undefined,
          },
        ].concat(
          templates.items
            .filter(item => item.id !== id)
            .map(item => ({
              text: item.name,
              value: item.id,
            })),
        );
      }
    } catch (reason) {
      this.config.state.error = reason;
    }
    this.config.state.isLoading = false;
  }

  protected async requestCreate(templatesRequest: TemplatesRequest) {
    return this.templatesService.post(templatesRequest);
  }

  protected async requestDelete(id: string) {
    return this.templatesService.delete(id);
  }

  protected requestReadTemplate(templateId: string) {
    return this.templatesService.getById(templateId);
  }

  protected requestReadTemplates() {
    return this.templatesService.get();
  }

  protected async requestUpdate(config: TemplatesRequest) {
    return this.templatesService.patch(this.config.input.id, config);
  }

  protected transformRequestInput(): TemplatesRequest {
    const request = new TemplatesRequest();
    request.access = this.config.input.accessPublic ? TemplatesAccess.PUBLIC : TemplatesAccess.PRIVATE;
    request.config = this.config.input.table.exportString(ConfigFormat.JSON);
    request.default = this.config.input.default;
    request.desc = this.config.input.description;
    request.favorite = this.config.input.favorite;
    request.name = this.config.input.name;
    request.parent = this.config.input.parent;
    return request;
  }
}
