import {CToastsService} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {authorizationConstants} from 'services/authorization';
import {HyperionPolling} from 'services/hyperion-polling';
import {NotifyeeNotificationService} from './services/notifyee-notification-service';

@authorizationConstants
@autoinject()
export class NotificationsIndex {
    private processingNotification: boolean = false;
    private pollTracker: HyperionPolling = null;

    constructor(public notifyeeService: NotifyeeNotificationService, public notification: CToastsService) {
        this.pollTracker = new HyperionPolling({
            includeDeleted: false,
            ms: 30 * 1000,
            promiseFn: () => {
                if (!document.hidden) {
                    return this.notifyeeService.getNotifications();
                }
            },
            useAfter: true,
        });
    }

    public async activate() {
        await this.notifyeeService.getNotifications();
        if (this.pollTracker) {
            this.pollTracker.start();
        }
    }

    public async processNotification(id: string, mode: string) {
        if (this.processingNotification) {
            return;
        }
        this.processingNotification = true;

        this.notifyeeService.processNotification(id, mode);
        await new Promise(resolve => setTimeout(resolve, 300));
        this.processingNotification = false;
    }

    public detached() {
        this.pollTracker.stop();
        this.pollTracker = null;
    }
}
