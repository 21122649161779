import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {MSOHostingLocationResponseItems} from '../models/hostingloc';

@autoinject
export class MSOHostingLocationService {
    private url: string = '/api/v4/mso/hostingloc';

    constructor(public acceo: Acceo) {}

    public get() {
        return this.acceo.get(MSOHostingLocationResponseItems)(this.url);
    }
}
