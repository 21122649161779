import {bindable} from 'aurelia-framework';
import {authState} from 'decorators';

@authState
export class ConfirmationBase {
  /** A function to call when the confirmation is cancelled. */
  @bindable public cancelAction: () => void;

  /** The color of the cancel button in the confirmation prompt. */
  @bindable public cancelColor: 'default' | 'primary' | 'success' | 'neutral' | 'warning' | 'danger' = 'neutral';

  /** The text label for the cancel button in the confirmation prompt. */
  @bindable public cancelLabel = 'Cancel';

  /** For standard confirmation prompts which perform an operation on an
   * *array* of objects, this is the count of objects which will be displayed
   * within the trigger button label and prompt message. */
  @bindable public objectCount = 0;

  /** For standard confirmation prompts which perform an operation on
   * *one object or an array of objects*, this text describes the action to be performed,
   * and will be displayed within the trigger button label, prompt message,
   * and also the submit button label. */
  @bindable public objectAction = '';

  /** For standard confirmation prompts which perform an operation on
   * *one object or an array of objects*, this text describes the type of objects which will be
   * displayed within the trigger button label and prompt message. */
  @bindable public objectType = '';

  /** The text displayed in the header of the confirmation prompt. */
  @bindable public promptHeader = 'Are you sure?';

  /** The text displayed in the body of the confirmation prompt. */
  @bindable public promptMessage = '';
  protected get _promptMessage() {
    if (this.promptMessage) return this.promptMessage;
    if (this.objectAction && this.objectType) return this._promptMessageStandard;
    return '';
  }

  protected get _promptMessageStandard() {
    let result = `You are about to ${this.objectAction.toLowerCase()} `;
    result = result.concat(`${this.objectCount || 'this'} `);
    result = result.concat(`${_.startCase(this.objectType.toLowerCase())}${this.objectCount > 1 ? 's' : ''}. `);
    result = result.concat('This action cannot be undone.');
    return result;
  }

  /** A function to call when the confirmation is submitted. */
  @bindable public submitAction: () => void;

  /** The color of the submit button in the confirmation prompt. */
  @bindable public submitColor: 'default' | 'primary' | 'success' | 'neutral' | 'warning' | 'danger' = 'danger';

  /** The text label for the submit button in the confirmation prompt. */
  @bindable public submitLabel = '';
  protected get _submitLabel() {
    if (this.submitLabel) return this.submitLabel;
    if (this._submitLabelStandard) return this._submitLabelStandard;
    return '';
  }

  protected get _submitLabelStandard() {
    return `${_.startCase(this.objectAction.toLowerCase())}`;
  }

  /** The color of the button which triggers the confirmation prompt to appear. */
  @bindable public triggerColor: 'default' | 'primary' | 'success' | 'neutral' | 'warning' | 'danger' = 'danger';

  /** Draws the trigger button in the outline color variant */
  @bindable public triggerOutline = false;

  /** The size of the button which triggers the confirmation prompt to appear. */
  @bindable public triggerSize: 'tiny' | 'small' | 'medium' | 'large' = 'medium';

  /** Draws the trigger button as a square. Only compatible with icon label */
  @bindable public triggerSquare = false;

  /** Draws the trigger button in a disabled state. */
  @bindable public triggerDisabled = false;

  /** The text label of the button which triggers the confirmation prompt to appear. */
  @bindable public triggerLabel = '';
  protected get _triggerLabel() {
    if (this.triggerLabel) return this.triggerLabel;
    if (this.objectAction && this.objectType) return this._triggerLabelStandard;
    return '';
  }

  protected get _triggerLabelStandard() {
    let result = `${_.startCase(this.objectAction.toLowerCase())} `;
    if (this.objectCount > 1) result = result.concat(`${this.objectCount} `);
    result = result.concat(`${_.startCase(this.objectType.toLowerCase())}${this.objectCount > 1 ? 's' : ''}`);
    return result;
  }

  /** The name of the icon to display in the button which triggers the confirmation prompt to appear. */
  @bindable public triggerIcon = '';

  /** The slot to position the icon displayed in the button which triggers the confirmation prompt to appear. */
  @bindable public triggerIconSlot: 'prefix' | 'suffix' | 'label' = 'prefix';

  /** Draws the trigger button in a thinking state. */
  @bindable public triggerThinking = false;

  public handleCancel() {
    if (this.cancelAction) {
      this.cancelAction();
    }
  }

  public handleSubmit(event: Event) {
    event.preventDefault();
    if (this.submitAction) {
      this.submitAction();
    }
  }
}
