import {autoinject} from 'aurelia-framework';
import {ConfigSettingInputType, IConfigSetting} from 'resources/config/models/config-setting';
import {Acceo} from 'services/acceo';
import {ParamConfigResponseCollection, ParamConfigResponseItem} from '../models/paramconfig-response-collection';

@autoinject
export class ParamConfigService {
  private url: string = '/api/v4/paramconfig';

  constructor(public acceo: Acceo) {}

  private getParams(response: ParamConfigResponseCollection) {
    // strip off params that are not to be used and set the formElement for provider
    const newResponse: ParamConfigResponseCollection = response;
    let items: ParamConfigResponseItem[] = [];

    response.items.forEach(item => {
      if (item.key !== 'type' && item.key !== 'name') {
        // set the param attributes based on the data
        if (item.key === 'provider' && item.options && item.options.length > 0) {
          item.formElement = 'select';
        }
        items = items.concat(item);
      }
    });

    newResponse.items = items;
    return newResponse;
  }

  public async getConfigSettings(type: string, provider: string) {
    // this method gets the parameters based on a given type and provider
    // if this is a legacy type (e.g. 'gg', 'spotx', 'fw', etc...) there is no specified provider so provider is set to the type
    try {
      let response: ParamConfigResponseCollection;
      // we should always have the type... and use the type as the provider when provider is not provided :)
      if (!provider || provider === '' || provider === undefined) {
        // use the type when no provider
        response = this.getParams(await this.acceo.get(ParamConfigResponseCollection)(`${this.url}/${type}/${type}/`));
      } else {
        response = this.getParams(
          await this.acceo.get(ParamConfigResponseCollection)(`${this.url}/${type}/${provider}/`),
        );
      }
      const result: IConfigSetting[] = response.items.map(item => ({
        disallowed: item.disallowed === 1,
        key: item.key,
        info: item.info,
        input: item.formElement as ConfigSettingInputType,
        managed: item.managed === 1,
        options: item.options ? item.options.map(option => ({text: option.text, value: option.value})) : undefined,
        required: item.required === 1,
        text: item.text || item.key,
      }));
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
