import {bindable, inject, transient} from 'aurelia-framework';
import * as dragula from 'dragula';

export class EnsureArrayValueConverter {
    public toView(value: string | string[]) {
        if (!Array.isArray(value)) {
            return [value];
        }
    }
}

@transient()
@inject(Element)
export class DragDropTable {
    @bindable
    public columns: string[];

    @bindable
    public data: [string[]];

    @bindable
    public callbacks: {
        onChange: (data: [string[]]) => {};
    };

    public drake;

    constructor(public element: Element) {}

    public attached() {
        this.init();
    }

    public detached() {
        if (this.drake) {
            this.drake.destroy();
            this.drake = null;
        }
    }

    public init() {
        if (this.drake) {
            this.drake.destroy();
            this.drake = null;
        }

        this.drake = dragula([this.element.querySelector('.drag-drop-table tbody')], {
            delay: 200,
            moves: ({}, {}, handle: HTMLElement) => handle.classList.contains('td-drag'),
            revertOnSpill: false,
        });

        this.drake.on('drop', (el: HTMLElement, __: HTMLElement, ___: HTMLElement, sibling: HTMLElement) => {
            const prevIndex: number = parseInt(el.getAttribute('data-index'), 10);
            let newIndex: number = sibling ? parseInt(sibling.getAttribute('data-index'), 10) : this.data.length - 1;

            // if new index is greater than prev index, need to compensate for removed position of prev index
            // unless it's the last item, in which case sibling will be null since it's the item after where
            // the moved item is placed
            if (newIndex > prevIndex && sibling) {
                newIndex -= 1;
            }

            const moved: string[] = this.data.splice(prevIndex, 1)[0];
            this.data.splice(newIndex, 0, moved);

            if (this.callbacks) _.attempt(this.callbacks.onChange, this.data);
        });
    }

    public deleteRow(event: MouseEvent, index: number) {
        event.cancelBubble = true;
        this.data.splice(index, 1);
        if (this.callbacks) _.attempt(this.callbacks.onChange, this.data);
    }
}
