import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {CutRecurrence, Operator, RuleType, UsageRule} from '../models/fields-models';
import {MultiCdnSettingsConfig, Rule, RuleSet} from '../models/multi-cdn-settings-config';
import {MultiCdnUtil} from '../services/cdn-util';

@autoinject()
export class UsageRules {
    get isDefaultRuleSet() {
        if (this.currentRuleSet) {
            if (this.currentRuleSet.name === MultiCdnUtil.defaultRuleSetName) {
                return true;
            }
        }
        return false;
    }

    @bindable
    public multiCdnSettings: MultiCdnSettingsConfig;

    @bindable
    public currentRuleSet: RuleSet;

    @bindable
    public callbacks;

    @bindable
    public validationErrors: ValidationError[];

    @bindable
    public rules: UsageRule[];

    public identifier: string = 'scenarios';
    public cutRecurrenceType = CutRecurrence;
    public operatorType = Operator;

    public pickerId = '_picker';

    public startTimeCallbacks = {
        /**
         * Updates model with newValue.
         */
        onChange: timestamp => {
            if (timestamp === '') {
                this.rules[0].startTimestamp = 0;
            } else {
                this.rules[0].startTimestamp = Number(timestamp);
                this.callbacks.onChange();
            }
        },
    };

    public addRule(): void {
        if (this.rules != null && this.rules.length === 0) {
            const rule = new Rule();
            rule.value = '0';
            rule.operator = Operator.lessOrEquals;
            rule.ruleType = RuleType.Usage;
            rule.field = 'usage';

            const newUsageRule = new UsageRule();
            newUsageRule.usage = rule;
            newUsageRule.cdn = this.multiCdnSettings.availablecdns[0];
            newUsageRule.startTimestamp = Date.now();
            newUsageRule.customPeriod = 0;
            newUsageRule.recurrence = CutRecurrence.monthly;

            this.rules.push(newUsageRule);
        }
    }

    public deleteRule(): void {
        this.rules.splice(0, 1);
        this.callbacks.onChange();
    }

    get shouldShowUsageRule() {
        if (this.currentRuleSet && this.rules && this.rules.length === 1) {
            return true;
        }
        return false;
    }

    get getPickerId() {
        return this.currentRuleSet.name + this.pickerId;
    }
}
