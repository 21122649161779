import {IngestPoint} from 'apps/cms/routes/slicers/models/slicer';
import {CTableRowSelectable} from 'resources/components/c-table-selectable/c-table-selectable-models';

export class IngestPointTableRow extends IngestPoint implements CTableRowSelectable {
    public connectionSourceUrl: string;
    public connectionSecureSourceUrl: string;
    public connectionStreamKey: string;
    public order?: number;
    public portsPort: number;
    public portsPortFec2: number;
    public portsPortFec4: number;
    public selected = false;
    public selectedState?: string;
    public slicerAccountName: string;
    public statusIcon?: string;
    public statusIconColor?: string;
    public statusIconSize?: string;
    public streamTypeName: string;
    public zoneRegion: string;
}

export enum IngestPointTableColHeadValue {
    connectionStreamKey = 'Stream Key',
    connectionSecureSourceUrl = 'Secure Stream URL',
    connectionSourceUrl = 'Stream URL',
    created = 'Creation Time',
    description = 'Description',
    domainName = 'Domain',
    endpointId = 'Endpoint ID',
    name = 'Name',
    portsPort = 'Port',
    portsPortFec2 = 'Port FEC 2',
    portsPortFec4 = 'Port FEC 4',
    slicerAccountName = 'Slicer Account Name',
    status = 'Status',
    streamTypeName = 'Protocol',
    zoneRegion = 'Region',
}
