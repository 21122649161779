import {IsDefined, IsNotEmpty} from 'class-validator';
import {ConfigTable} from '../table';

export class ConfigSettingInput {
  public id?: string;

  @IsDefined({message: 'Key is required'})
  @IsNotEmpty({message: 'Key is required'})
  public key: string;

  @IsDefined({message: 'Value is required'})
  @IsNotEmpty({message: 'Value is required'})
  public value: boolean | number | string;
}

export class ConfigInput {
  public configSetting = new ConfigSettingInput();
  public table: ConfigTable;
}
