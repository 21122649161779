import {LynkAlert} from '@uplynk/lynk-design';
import * as DOMPurify from 'dompurify';

export class Toast {
  private static DEFAULT_DURATION = 5000;

  private static async createAlert(
    message: string,
    type: 'primary' | 'info' | 'success' | 'neutral' | 'warning' | 'danger',
    duration: number,
  ) {
    // Jest isn't cooperating with the following syntax:
    // const alert = new LynkAlert();
    // ...so for now we have to use this syntax instead:
    const alert = document.createElement('lynk-alert') as LynkAlert;
    alert.closable = true;
    alert.duration = duration;
    alert.innerHTML = DOMPurify.sanitize(message);
    alert.type = type;
    document.body.append(alert);
    return alert.toast();
  }

  public static danger(message: string, duration = Toast.DEFAULT_DURATION) {
    return Toast.createAlert(message, 'danger', duration);
  }

  public static info(message: string, duration = Toast.DEFAULT_DURATION) {
    return Toast.createAlert(message, 'info', duration);
  }

  public static neutral(message: string, duration = Toast.DEFAULT_DURATION) {
    return Toast.createAlert(message, 'neutral', duration);
  }

  public static primary(message: string, duration = Toast.DEFAULT_DURATION) {
    return Toast.createAlert(message, 'primary', duration);
  }

  public static success(message: string, duration = Toast.DEFAULT_DURATION) {
    return Toast.createAlert(message, 'success', duration);
  }

  public static warning(message: string, duration = Toast.DEFAULT_DURATION) {
    return Toast.createAlert(message, 'warning', duration);
  }
}
