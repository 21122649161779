import {CToastsService} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import environment from 'environment';

export interface IDownloadType {
    filename: string;
    id: string;
    url: string;
}

@autoinject()
export class DownloadService {
    public ids: string[] = [];

    constructor(private notification: CToastsService) {}

    public async downloadFileFromURL(file: IDownloadType) {
        if (!this.addID(file)) {
            return;
        }

        const {filename, id, url} = file;

        this.notification.info(`Download started for "${filename}"`);

        try {
            const resourceUrl = environment.debug ? url : url.replace(/^http:/, 'https:');
            const resp = await fetch(resourceUrl);
            const blob = await resp.blob();
            const a = document.createElement('a');

            a.href = URL.createObjectURL(blob);
            a.setAttribute('download', filename.replace(/ /g, '_'));
            a.click();
            this.notification.success(`"${filename}" download complete`);
        } catch (err) {
            this.notification.error(`Failed to download file "${filename}"`);
        }

        this.removeID(id);
    }

    private addID(file: IDownloadType) {
        const {filename, id} = file;

        if (this.ids.includes(id)) {
            this.notification.error(`Download of "${filename}" is already in progress`);
            return false;
        }

        this.ids.push(id);

        return true;
    }

    private removeID(id: string) {
        const index = this.ids.indexOf(id);

        if (index > -1) {
            this.ids.splice(index, 1);
        }
    }
}
