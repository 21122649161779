import {computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {copyToClipboard, CTableActions, CTableCol, LocalStorageHelper} from '@bindable-ui/bindable';

import {BaseAssetSingle} from './base';

export class AssetPlayback extends BaseAssetSingle {
  /*
   * Computed Properties
   */

  @computedFrom(
    'requireDrmDirty',
    'requireStudioDrmDirty',
    'embedDomainsDirty',
    'embedHTML5PlayerURLDirty',
    'testPlayersDirty',
  )
  get tabDirty() {
    return (
      this.requireDrmDirty ||
      this.requireStudioDrmDirty ||
      this.embedDomainsDirty ||
      this.embedHTML5PlayerURLDirty ||
      this.testPlayersDirty
    );
  }

  @computedFrom('isDeletingTestPlayers', 'selectedTestPlayers.length', 'model.mine')
  get deleteButtonState() {
    if (!this.model || !this.model.mine || !this.selectedTestPlayers.length) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('model.source')
  get missingDRMCopy() {
    if (this.model && this.model.source && this.model.source === 'External') {
      return 'DRM is not yet supported for externally sourced assets.';
    }

    return 'There are no DRM types enabled on this asset. These are set during slicing and cannot be edited.';
  }

  // Need one for every field on the tab + one for the tab
  @computedFrom('model.embed_domains')
  get embedDomainsDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.content.currentModel.embed_domains !== this.model.embed_domains;

    // Update field `isDirty` state
    this.fields.embed_domains.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.embed_html5_player_url')
  get embedHTML5PlayerURLDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.content.currentModel.embed_html5_player_url !== this.model.embed_html5_player_url;

    // Update field `isDirty` state
    this.fields.embed_html5_player_url.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.require_drm')
  get requireDrmDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.content.currentModel.require_drm !== this.model.require_drm;

    // Update field `isDirty` state
    this.fields.require_drm.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.studio_drm_required')
  get requireStudioDrmDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.content.currentModel.studio_drm_required !== this.model.studio_drm_required;

    // Update field `isDirty` state
    this.fields.studio_drm_required.isDirty = isDirty;

    return isDirty;
  }

  // Used for iframe element generation
  @computedFrom('embedHeight')
  get safeEmbedHeight() {
    if (!this.embedHeight || !_.toNumber(this.embedHeight)) {
      return LocalStorageHelper.loadOrDefault('channelEmbedHeight', 480);
    }

    LocalStorageHelper.save('channelEmbedHeight', _.toNumber(this.embedHeight));
    return _.toNumber(this.embedHeight);
  }

  // Used for iframe element generation
  @computedFrom('embedWidth')
  get safeEmbedWidth() {
    if (!this.embedWidth || !_.toNumber(this.embedWidth)) {
      return LocalStorageHelper.loadOrDefault('channelEmbedWidth', 640);
    }

    LocalStorageHelper.save('channelEmbedWidth', _.toNumber(this.embedWidth));
    return _.toNumber(this.embedWidth);
  }

  // Aurelia won't track an array, so we have track when a value is changed
  @computedFrom('model.test_players', 'testPlayersChangeTracker')
  get testPlayersDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    let isDirty = false;

    if (this.content.currentModel.test_players.length !== this.model.test_players.length) {
      isDirty = true;
    }

    if (!isDirty) {
      _.forEach(this.content.currentModel.test_players, tp => {
        const otherTp = _.find(this.model.test_players, {id: tp.id});

        if (
          !otherTp ||
          otherTp.desc !== tp.desc ||
          otherTp.expire !== tp.expire ||
          otherTp.whitelist !== tp.whitelist ||
          otherTp.params !== tp.params
        ) {
          isDirty = true;
          return false; // Break out of loop
        }
        return true;
      });
    }

    // Update field `isDirty` state
    this.fields.test_players.isDirty = isDirty;

    return isDirty;
  }

  /*
   * Public Properties
   */

  // Don't let strings get stuck into localstorage
  public embedHeight: number = _.toNumber(LocalStorageHelper.loadOrDefault('channelEmbedHeight', 480)) || 480;
  public embedWidth: number = _.toNumber(LocalStorageHelper.loadOrDefault('channelEmbedWidth', 640)) || 640;

  public addTestPlayerTip: any;
  public expirationDate: number;
  public expireEmbedTip: any;
  public isDeletingTestPlayers: boolean = false;
  public selectedTestPlayers: any[] = [];
  public showExpiredHelpText: boolean = false;
  public testPlayersChangeTracker: number;
  public testPlayerName: string = '';
  public testPlayersError: string = null;
  public whitelist: boolean = false;

  public tabFields: string[] = [
    'embed_domains',
    'embed_html5_player_url',
    'require_drm',
    'studio_drm_required',
    'test_players',
  ];

  public testPlayersColsRO: CTableCol[] = [
    {
      colHeadName: 'desc',
      colHeadValue: 'Name',
      sort: true,
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate'),
    },
    {
      colClass: 't175',
      colHeadName: 'expire',
      colHeadValue: 'Exp Date',
      sort: true,
      valueConverter: 'altTimestampToDMY',
    },
    {
      colClass: 't50',
      colHeadName: 'whitelist',
      colHeadValue: 'Allowlist',
      sort: true,
      valueConverter: 'booleanYesNo',
    },
    {
      colAction: row => this.runTestPlayer(row),
      colClass: 't50',
      colHeadName: 'view',
      colHeadValue: 'View',
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action'),
    },
    {
      colAction: row => this.copyTestPlayerURL(row),
      colClass: 't120',
      colHeadName: 'copy',
      colHeadValue: 'Copy',
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action'),
    },
  ];

  public testPlayersCols: CTableCol[] = [
    {
      checkChanged: row => this.trackSelectedTestPlayer(row),
      colClass: 't30',
      colHeadName: 'checkbox',
      colHeadSelectedChanged: isChecked => this.selectAllTestPlayers(isChecked),
      colHeadSelectedVal: false,
      colHeadValue: 'Select',
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
    },
    ...this.testPlayersColsRO,
  ];

  public testPlayerActions: CTableActions = {
    rowClick: row => {
      if (this.session.hasTestPlayersWriteAccess) {
        this.openTestPlayer(row);
      }
    },
  };

  /*
   *    Aurelia Events
   */

  public attached() {
    this.content.activeTab = 'playback';
    super.attached();
  }

  /*
   * Public Methods
   */

  public addTestPlayer() {
    this.testPlayersError = null;
    if (_.find(this.model.test_players, {desc: this.testPlayerName})) {
      this.testPlayersError = 'Test Player already exists';
      return;
    }

    this.model.test_players.push({
      desc: this.testPlayerName,
      expire: this.expirationDate,
      params: '',
      url: 'A URL will be generated on save',
      url_html5: 'A URL (HTML5) will be generated on save',
      whitelist: this.whitelist,
    });

    this.testPlayerName = '';
    this.expirationDate = null;
    this.whitelist = false;

    if (this.addTestPlayerTip) {
      this.addTestPlayerTip.hide();
    }

    this.testPlayersChangeTracker = Date.now();
  }

  public async deleteTestPlayers() {
    _.remove(this.model.test_players, tp => tp.checkbox);
    this.selectedTestPlayers = [];
    this.testPlayersCols[0].colHeadSelectedVal = false;
    this.testPlayersChangeTracker = Date.now();
  }

  public expireEmbedCode() {
    this.model.embed_html5_player_url = '';
    this.showExpiredHelpText = true;

    if (this.expireEmbedTip) {
      this.expireEmbedTip.hide();
    }

    this.notification.success('New Embedded HTML will be requested upon Save');
  }

  public openTestPlayer(tp) {
    if (!tp.id) {
      this.notification.error('Test player must be saved before you can edit it.');
      return;
    }

    const activeTpIndex = _.findIndex(this.model.test_players, {id: tp.id});
    const editTp = _.cloneDeep(tp);

    this.dialogService.open({
      model: {
        bodyModel: {
          testPlayer: editTp,
        },
        bodyViewModel: PLATFORM.moduleName(
          'apps/cms/routes/live-channels/channels/single/modals/test-player/test-player-body',
        ),
        footerEnable: true,
        footerModel: {
          updateTestPlayer: () => this.updateTestPlayer(editTp, activeTpIndex),
        },
        footerViewModel: PLATFORM.moduleName(
          'apps/cms/routes/live-channels/channels/single/modals/test-player/test-player-footer',
        ),
        sharedModel: {
          errMsg: '',
        },
        size: 'medium',
        title: `Edit ${tp.desc}`,
      },
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
    });
  }

  public runTestPlayer(row) {
    window.open(row.url_html5);
  }

  public copyTestPlayerURL(row) {
    copyToClipboard(row.url_html5, this.notification.success('Copied to clipboard'));
  }

  public updateTestPlayer(tp, index) {
    let err = null;

    const clonedTp = _.cloneDeep(tp);

    let dupeCheck = [];
    const descChanged = _.find(this.model.test_players, {desc: clonedTp.desc, url_html5: clonedTp.url_html5});
    if (!descChanged) {
      dupeCheck = _.filter(this.model.test_players, {desc: clonedTp.desc});
    }

    if (dupeCheck.length > 0) {
      err = 'Test player must have a unique name';
      return err;
    }

    if (!clonedTp.desc) {
      err = 'Test player must have a name';
      return err;
    }

    this.model.test_players.splice(index, 1, clonedTp);
    this.testPlayersChangeTracker = Date.now();

    return err;
  }

  /*
   *   Private Methods
   */

  private trackSelectedTestPlayer(tp) {
    if (tp.checkbox) {
      this.selectedTestPlayers.push(tp.id);
      this.selectedTestPlayers = _.uniq(this.selectedTestPlayers);
    } else {
      _.remove(this.selectedTestPlayers, tpId => tpId === tp.id);
    }
  }

  /**
   * Select/Deselect all meta items
   *
   * @param isSelected
   */
  private selectAllTestPlayers(isSelected: boolean) {
    _.forEach(this.model.test_players, tp => {
      tp.checkbox = isSelected;
    });
  }
}
