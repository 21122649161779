import {Type} from 'class-transformer';
import {IsNumber, IsString, ValidateNested} from 'class-validator';

export class TestResponseItem {
  @IsString()
  public msg: string;

  @IsString()
  public type: string;
}

export class TestResponseCollection {
  @IsNumber()
  public error: number;

  @ValidateNested()
  @Type(() => TestResponseItem)
  public items: TestResponseItem[];
}
