import {RuleType} from '../models/fields-models';
import {Rule} from '../models/multi-cdn-settings-config';

export class FilterRuleTypeValueConverter {
    public toView(rules: Rule[], ruleType: RuleType) {
        if (rules === undefined || rules === null || ruleType === null) {
            return rules;
        }

        return rules.filter(rule => rule.ruleType === ruleType);
    }
}
