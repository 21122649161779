export interface IModalActions {
  onSave: (password: string) => boolean;
  onClose: () => void;
}

export default class PasswordVerificationModal {
  public actions: IModalActions;
  public errorMessage: string = null;
  public isProcessing: boolean = false;
  public origUsername: string;
  public password: string;
  public username: string;

  public activate({origUsername, username, actions}) {
    this.reset();

    this.origUsername = origUsername;
    this.username = username;
    this.actions = actions;
  }

  public deactivate() {
    this.reset();
  }

  /*
    Public Methods
  */

  public closeDialog(event?: any) {
    event?.preventDefault();

    const isOverlay = event?.detail?.source === 'overlay';

    if (isOverlay || this.isProcessing) {
      return;
    }

    this.actions.onClose();
  }

  public async save() {
    this.errorMessage = null;

    this.isProcessing = true;
    let canClose = false;

    try {
      canClose = await this.actions.onSave(this.password);
    } catch (err) {
      this.errorMessage = err.message;
    } finally {
      this.isProcessing = false;

      if (canClose) {
        this.closeDialog();
      }
    }
  }

  /*
    Private Methods
  */

  public reset() {
    this.actions = null;
    this.origUsername = null;
    this.username = null;
    this.errorMessage = null;
    this.password = null;
    this.isProcessing = false;
  }
}
