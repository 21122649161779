import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

@autoinject()
export class SyndicationTargetsIndex {
  public router: Router;
  public routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: 'SyndicationTargetsList',
      nav: false,
      route: '',
      title: 'Syndication Targets',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: 'SyndicationTargetSingle',
      nav: false,
      route: ':id',
      title: 'Syndication Target',
    },
  ];

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }
}
