import {noView} from 'aurelia-templating';

@noView()
export class YoutubeApi {
    public gapiClientId = '520665538900-ehk00938gt218kf0b56qdq7g34fl4u4t.apps.googleusercontent.com'; // Production
    public gapiScope =
        'https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtube.upload';

    private _scriptPromise = null;
    private _gapi;
    private _gauth2;
    public responseCode = '';
    public errorCode = null;

    constructor() {
        this.getScript();
    }

    public getScript() {
        // Script has already been set, so return promise straight away
        if (this._scriptPromise !== null) {
            return this._scriptPromise;
        }

        if ((window as any).gapi === undefined) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = 'https://accounts.google.com/gsi/client';

            // Lets setup the promise so that it resolves when the callback is called
            // This needs to be done FIRST (according to google for the callback, ie: onload=youtubeAsyncInit)
            this._scriptPromise = new Promise((resolve, reject) => {
                (window as any).youtubeAsyncInit = () => {
                    this._gapi = (window as any).google;

                    this._gauth2 = this._gapi.accounts.oauth2.initCodeClient({
                        client_id: this.gapiClientId,
                        scope: this.gapiScope,
                        ux_mode: 'popup',
                        callback: response => {
                            this.responseCode = response.code;
                        },
                        error_callback: errorResponse => {
                            this.errorCode = errorResponse;
                        },
                    });
                };

                script.onerror = error => {
                    reject(error);
                };
                resolve();
            });

            script.onload = () => {
                (window as any).youtubeAsyncInit();
            };

            // now that our callback is setup, we can load the script.
            // google sdk has not been defined yet
            document.body.appendChild(script);

            return this._scriptPromise;
        }
        return false;
    }

    public login(): Promise<any> {
        this.responseCode = '';
        this.errorCode = '';

        return this.getScript().then(
            () =>
                new Promise(resolve => {
                    this._gauth2.requestCode();
                    resolve('success');
                }),
        );
    }
}
