import {Transform, Type} from 'class-transformer';
import {IsBoolean, IsNumber, IsOptional, IsString, ValidateNested} from 'class-validator';
import {ChangeLog, Slicer} from './slicer';

const thresholdMapping = values => {
  const ignoredKeys = [
    'failover_audio_high',
    'failover_audio_loss_low',
    'failover_audio_loss_high',
    'failover_audio_low',
    'failover_blackness_high',
    'failover_dropped_low',
    'failover_proc_q_low',
    'failover_queue_low',
    'failover_video_loss_low',
    'failover_video_loss_high',
    'failover_video_high',
    'failover_video_low',
    'failover_tr_101_290_stats_P1_errors_low',
    'failover_tr_101_290_stats_P2_errors_low',
  ];
  const defaultIfNotExists: object = {
    failover_tr_101_290_stats_P1_errors_low: 0,
    failover_tr_101_290_stats_P1_errors_high: 1,
    failover_tr_101_290_stats_P1_errors_fault_duration: 0,
    failover_tr_101_290_stats_P1_errors_recovery_duration: 30,
    failover_tr_101_290_stats_P2_errors_low: 0,
    failover_tr_101_290_stats_P2_errors_high: 1,
    failover_tr_101_290_stats_P2_errors_fault_duration: 0,
    failover_tr_101_290_stats_P2_errors_recovery_duration: 30,
    failover_involuntary_blackout: 0,
  };
  const keyMap = new Map();
  keyMap.set('Audio Loss', [
    'failover_audio_loss_fault_duration',
    'failover_audio_loss_recovery_duration',
    // 'failover_audio_loss_high',
    // 'failover_audio_loss_low',
  ]);
  keyMap.set('Black Screen', [
    'failover_blackness_fault_duration',
    // 'failover_blackness_high',
    'failover_blackness_low',
    'failover_blackness_recovery_duration',
  ]);
  keyMap.set('CC Last Seen', [
    'failover_cc_last_seen_fault_duration',
    'failover_cc_last_seen_recovery_duration',
  ]);
  keyMap.set('Dropped Frames', [
    'failover_dropped_fault_duration',
    'failover_dropped_recovery_duration',
    'failover_dropped_high',
    // 'failover_dropped_low',
  ]);
  keyMap.set('Input Loss', [
    'failover_input_fault_duration',
    'failover_input_recovery_duration',
  ]);
  keyMap.set('Involuntary Blackout', [
    'failover_involuntary_blackout',
  ]);
  keyMap.set('Nielsen Last Seen', [
    'failover_nielsen_last_seen_fault_duration',
    'failover_nielsen_last_seen_recovery_duration',
  ]);
  keyMap.set('Processing Queue', [
    'failover_proc_q_fault_duration',
    'failover_proc_q_recovery_duration',
    'failover_proc_q_high',
    // 'failover_proc_q_low',
  ]);
  keyMap.set('SCTE Last Seen', [
    'failover_scte_last_seen_fault_duration',
    'failover_scte_last_seen_recovery_duration',
  ]);
  keyMap.set('Static Audio', [
    // 'failover_audio_high',
    // 'failover_audio_low',
    'failover_static_audio_fault_duration',
    'failover_static_audio_recovery_duration',
  ]);
  keyMap.set('Static Video', [
    'failover_static_video_fault_duration',
    'failover_static_video_recovery_duration',
    // 'failover_video_high',
    // 'failover_video_low',
  ]);
  keyMap.set('Upload Queue', [
    'failover_queue_fault_duration',
    'failover_queue_high',
    // 'failover_queue_low',
    'failover_queue_recovery_duration',
  ]);
  keyMap.set('Video Loss', [
    'failover_video_loss_fault_duration',
    'failover_video_loss_recovery_duration',
    // 'failover_video_loss_low',
    // 'failover_video_loss_high',
  ]);
  keyMap.set('TR 101 290 P1 Errors', [
    'failover_tr_101_290_stats_P1_errors_fault_duration',
    'failover_tr_101_290_stats_P1_errors_recovery_duration',
    // 'failover_tr_101_290_stats_P1_errors_low',
    'failover_tr_101_290_stats_P1_errors_high',
  ]);
  keyMap.set('TR 101 290 P2 Errors', [
    'failover_tr_101_290_stats_P2_errors_fault_duration',
    'failover_tr_101_290_stats_P2_errors_recovery_duration',
    // 'failover_tr_101_290_stats_P2_errors_low',
    'failover_tr_101_290_stats_P2_errors_high',
  ]);

  const result = {};
  keyMap.forEach((value, key) => {
    const data = {};

    _.each(value, k => {
      if (!_.includes(ignoredKeys, k)) {
        if (
          !Object.prototype.hasOwnProperty.call(values, `_${k}`) &&
          !Object.prototype.hasOwnProperty.call(values, k) &&
          Object.prototype.hasOwnProperty.call(defaultIfNotExists, k)
        ) {
          /**
           * in case we add new thresholds, they don't exist on existing failover groups DB config property.
           * so this is the way to fix non-exist thresholds and add them as not active with the default values.
           * even if it's kind of duplication of the server side, fix it here instead of running DB script migration.
           */
          values[`_${k}`] = defaultIfNotExists[k];
        }

        data[k] = {
          label: getLabel(k),
          value: values[`_${k}`] || values[k] || 0,
        };
      }
    });

    _.set(result, key, {
      data,
      status: !_.isEmpty(values) && _.every(_.map(value, v => !_.includes(Object.keys(values), `_${v}`))),
    });
  });
  return result;
};

const getLabel = k =>
  _.startCase(
    k
      .replace('failover_tr_101_290_stats_P1_errors_high', 'Number of errors')
      .replace('failover_tr_101_290_stats_P2_errors_high', 'Number of errors')
      .replace(
        /failover_static_audio|failover_static_video|failover_audio_loss|failover_video_loss|failover_queue|failover_dropped|failover_blackness|failover_cc_last_seen|failover_scte_last_seen|failover_proc_q|failover_nielsen_last_seen|failover_input|failover_tr_101_290_stats_P1|failover_tr_101_290_stats_P2/gi,
        '',
      )
      .replace(/errors_low|errors_high|low|high/gi, 'Set Threshold')
      .replace(/errors_fault_duration|fault_duration/gi, 'Set Duration')
      .replace(/errors_recovery_duration|fault_recovery|recovery_duration/gi, 'Clear Duration'),
  );

export class FailoverGroupSlicer extends Slicer {
  @IsOptional()
  public blacklist_until?: number;

  @IsOptional()
  public enabled?: boolean = true;

  @IsBoolean()
  @IsOptional()
  public active?: boolean = true;

  @IsOptional()
  public order?: number;

  @IsString()
  @IsOptional()
  public hot_warm_state?: string;

  @IsString()
  @IsOptional()
  public slicer_state?: string;
}

export class FailoverGroupEventLog {
  @IsOptional()
  public timestamp: any;

  @IsString()
  public message: string;

  @IsString()
  public module: string;
}

export class FailoverGroup {
  @IsString()
  public id: string;

  @IsString()
  public name: string;

  @IsOptional()
  @Type(() => FailoverGroupSlicer)
  public active: FailoverGroupSlicer;

  @IsString()
  @IsOptional()
  public deleted?: string;

  @IsOptional()
  @Transform(value => thresholdMapping(value))
  public thresholds: any;

  @IsNumber()
  @IsOptional()
  public count: number = 0;

  @IsNumber()
  public hot_warm: number = 0;

  @IsString()
  public mode: string = 'prioritized';

  @IsBoolean()
  public auto_failback: boolean = true;

  @IsOptional()
  @Type(() => FailoverGroupSlicer)
  public slicers: FailoverGroupSlicer[] = [];

  @IsOptional()
  public channels: any[];

  @IsOptional()
  public failover_time: any;

  @IsOptional()
  @Type(() => ChangeLog)
  public change_log: ChangeLog[];

  @IsOptional()
  @Type(() => FailoverGroupEventLog)
  public event_log: FailoverGroupEventLog[];

  // runtime field, need not be serialized
  public selected: boolean = false;
}

export interface SlicerFilterParams {
  page_size?: number;
  since?: number;
  page?: number;
  include_deleted?: boolean;
  search?: string;
  order?: string;
  minimal?: boolean;
}

export interface Meta {
  total?: number;
  showing?: number;
  limit?: number;
  nextToken?: number;
  hasMore?: boolean;
}

export class FailoverGroupSlicersResponse {
  @ValidateNested()
  @Type(() => FailoverGroupSlicer)
  public items?: FailoverGroupSlicer[];

  @IsNumber()
  @IsOptional()
  public now?: number;

  @IsNumber()
  @IsOptional()
  public total_items?: number;
}

export class FailoverGroupsResponse {
  @ValidateNested()
  @Type(() => FailoverGroup)
  public items?: FailoverGroup[];

  @IsNumber()
  @IsOptional()
  public now?: number;

  @IsNumber()
  @IsOptional()
  public total_items?: number;
}
