import * as moment from 'moment';

import {SECOND, HOUR, HALF_HOUR, FORTY_EIGHT, WeekDay} from './block-factories';

export class DayService {
    initFilter() {
        return [moment().startOf('day').valueOf(), moment().endOf('day').valueOf()];
    }

    parseDate(currentMoment) {
        return [currentMoment.format('dddd, MMM. Do, YYYY'), currentMoment.format('M/D/YYYY')];
    }

    nextButton(currentMoment) {
        const currentClone = currentMoment.clone();
        currentClone.add(1, 'day');

        return [
            currentClone,
            currentClone.clone().startOf('day').valueOf(),
            currentClone.clone().endOf('day').valueOf(),
        ];
    }

    prevButton(currentMoment) {
        const currentClone = currentMoment.clone();
        currentClone.subtract(1, 'day');

        return [
            currentClone,
            currentClone.clone().startOf('day').valueOf(),
            currentClone.clone().endOf('day').valueOf(),
        ];
    }

    assembleCalendar(events, dayStart) {
        const day = new WeekDay(dayStart, 0);
        let totalEvents = 0;

        events.forEach(event => {
            const halfHourIndex = Math.floor(((event.expected_start - dayStart) / SECOND / HOUR) * HALF_HOUR);
            if (halfHourIndex && halfHourIndex < FORTY_EIGHT && halfHourIndex >= 0) {
                day.slots[halfHourIndex].events.push(event);
                totalEvents += 1;
            }
        });

        let columnsHeight = new Array(events.length).fill(0); // The max number of columns you can have

        day.slots.forEach(halfHour => {
            columnsHeight = halfHour.calculateBlocks(columnsHeight);
        });

        return [day, totalEvents];
    }
}
