import {CTableActions, CTableCol, CToastsService} from '@bindable-ui/bindable';
import {RouteName} from 'apps/cms/routes/slicers/ingest-points';
import {IngestPoint} from 'apps/cms/routes/slicers/models/slicer';
import {SlicerService} from 'apps/cms/routes/slicers/services/slicer-service';
import {DialogService} from 'aurelia-dialog';
import {autoinject, bindable, computedFrom, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {HyperionPolling} from 'services/hyperion-polling';
import {TableStorage} from 'resources/table-storage/table-storage';

const logger = LogManager.getLogger('IngestPoint');
const STATUS_REFRESH_INTERVAL = 8000;

@autoinject()
export class SlicerIngestPoints {
  @bindable
  public slicerIngestPoint: IngestPoint = new IngestPoint();

  public isLoading: boolean = true;
  public isCreating: boolean = false;
  public isDeleting: boolean = false;
  public searchQuery: string;
  public unfilteredSlicerIngestPoints: IngestPoint[];
  public slicerIngestPoints: IngestPoint[];
  public selectedSlicerIngestPoints: string[] = [];
  public columns: CTableCol[];
  public pollTracker: HyperionPolling;
  public sortOrder: string = 'desc';
  public sortReverse: boolean = true;
  public reverseSort: boolean;
  public sortedColumn: string = 'created';
  public tableStorage = new TableStorage('ingest-points-list');
  public defaultSortCol: string = 'created';
  private protocols: {text: any; value: any}[];
  private regions: {text: any; value: any}[];

  public tableActions: CTableActions = {
    // eslint-disable-next-line consistent-return
    getColValue: (row, col) => {
      row.selectedState = row.status.toUpperCase() === 'IN_USE' ? 'disabled' : '';
      row.statusIcon = 'circle';
      row.statusIconSize = '0.75em';
      if (row.status.toUpperCase() === 'IN_USE') {
        row.statusIconColor = 'var(--c_secondaryMain)';
      } else if (row.status.toUpperCase() === 'NOT_IN_USE') {
        row.statusIconColor = 'var(--c_subThreeMain)';
      } else if (row.status.toUpperCase() === 'INITIALIZING') {
        row.statusIconColor = 'var(--c_subOneMain)';
      } else if (row.status.toUpperCase() === 'FAILED') {
        row.statusIconColor = 'var(--c_primaryLight)';
      } else {
        row.statusIconColor = 'var(--c_gray)';
      }
      if (col.colHeadValue === 'Protocol') {
        return row.stream_type.name;
      }
      if (col.colHeadValue === 'Region') {
        return row.zone.region;
      }
      if (col.colHeadValue === 'Port') {
        return row.ports.port;
      }
    },
    rowClick: row => this.onRowClick(row),
    sortColumn: (_col: CTableCol, _reverse: boolean) => {
      this.sortedColumn = _col.colHeadName === '' ? 'stream_type_name' : _col.colHeadName; // hack so we can
      // sort on Protocol
      this.sortOrder = _reverse ? 'desc' : 'asc';
      this.sortReverse = _reverse;
      logger.info(`SORT COLUMN selected column ${this.sortedColumn}, direction ${this.sortOrder}`);
      this.tableStorage.saveSortColumn(this.sortedColumn);
      this.tableStorage.saveSortDirection(this.sortOrder.toUpperCase());
      this.slicerIngestPoints = this.sortColumns(this.slicerIngestPoints, this.sortedColumn, this.sortOrder);
    },
  };

  private ingestPointsLimit: number = 100;

  // eslint-disable-next-line default-param-last
  public sortColumns(data, field = 'created', order): any[] {
    logger.info(`SORT COLUMNS sortedColumn ${this.sortedColumn}, sortOrder ${this.sortOrder}`);
    logger.info(`SORT COLUMNS orderBy field ${field}, order ${order}`);
    const result = _.orderBy(data, field, order);
    return result;
  }

  @computedFrom('selectedSlicerIngestPoints.length', 'isDeleting')
  get deleteBtnState() {
    if (this.slicerIngestPoints && this.isDeleting) {
      return 'thinking';
    }
    if (this.slicerIngestPoints && this.selectedSlicerIngestPoints.length) {
      return '';
    }
    return 'disabled';
  }

  @computedFrom('isCreating', 'ingestPointsLimit', 'slicerIngestPoints.length')
  get createBtnState() {
    if (this.isCreating) {
      return 'thinking';
    }
    if (this.slicerIngestPoints.length >= this.ingestPointsLimit) {
      return 'disabled';
    }
    return '';
  }

  constructor(
    public slicerService: SlicerService,
    private vToastsService: CToastsService,
    public dialogService: DialogService,
    public router: Router,
  ) {
    this.tableStorage = new TableStorage('ingest-points-list');
    const tableDisplayData = this.tableStorage.getTableData();
    let sortDirection = 'asc';
    if (!_.isEmpty(tableDisplayData)) {
      const savedSortColumn = this.tableStorage.getSortColumn(this.sortedColumn);
      if (savedSortColumn.length) {
        this.sortedColumn = savedSortColumn;
      }
      sortDirection = this.tableStorage.getSortDirection();
    }
    this.sortOrder = sortDirection === 'DESC' ? 'desc' : 'asc';

    this.columns = [
      {
        checkChanged: row => this.trackSelectedIngestPoints(row),
        colClass: 't30',
        colHeadName: 'selected',
        colHeadSelectedChanged: isChecked => this.toggleSelectAll(isChecked),
        colHeadSelectedVal: false,
        colHeadValue: '',
        view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
      },
      {
        colClass: 't150',
        colHeadName: 'status',
        colHeadValue: 'Status',
        sort: true,
        view: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
        ),
        viewModel: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
        ),
      },
      {
        colClass: 't350',
        colHeadName: 'name',
        colHeadValue: 'Name',
        sort: true,
        view: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
        ),
        viewModel: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
        ),
      },
      {
        colClass: 't120',
        colHeadName: '', // must be empty or status column breaks!
        colHeadValue: 'Protocol',
        sort: true,
      },
      {
        colClass: 't120',
        colHeadName: 'region',
        colHeadValue: 'Region',
        sort: true,
      },
      {
        colClass: 't85',
        colHeadName: 'port',
        colHeadValue: 'Port',
        sort: true,
      },
      {
        colClass: 't270',
        colHeadName: 'description',
        colHeadValue: 'Description',
        view: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
        ),
        viewModel: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
        ),
      },
      {
        colHeadName: 'created',
        colHeadValue: 'Creation Time',
        sort: true,
        valueConverter: 'timezoneTimeToStandardDateTimeMS:"MMM D, YYYY @ h:mm A" | notApplicable',
      },
    ];

    this.pollTracker = new HyperionPolling({
      callbackFn: updatedIngestPoints => {
        if (!_.isEqual(updatedIngestPoints, this.unfilteredSlicerIngestPoints)) {
          this.unfilteredSlicerIngestPoints = _.cloneDeep(updatedIngestPoints);
        }
        this.filterIngestPoints();
      },
      includeDeleted: false,
      ms: STATUS_REFRESH_INTERVAL,
      promiseFn: async () => {
        const allIngestPoints = await this.slicerService.getIngestPoints();
        const buildingIngestPoints = _.filter(allIngestPoints, {status: 'INITIALIZING'});
        _.each(buildingIngestPoints, ingestPoint => {
          this.slicerService.getIngestPoints(ingestPoint.id); // triggers ingest point status refresh
        });

        this.unfilteredSlicerIngestPoints = this.sortColumns(allIngestPoints, this.sortedColumn, this.sortOrder);

        return this.unfilteredSlicerIngestPoints;
      },
      useAfter: true,
    });
    this.defaultSortCol = this.sortOrder === 'desc' ? `-${this.sortedColumn}` : `${this.sortedColumn}`;
  }

  public async attached() {
    try {
      this.unfilteredSlicerIngestPoints = await this.slicerService.getIngestPoints();
      const buildingIngestPoints = _.filter(this.unfilteredSlicerIngestPoints, {status: 'INITIALIZING'});
      _.each(buildingIngestPoints, ingestPoint => {
        this.slicerService.getIngestPoints(ingestPoint.id);
      });

      this.unfilteredSlicerIngestPoints = this.sortColumns(
        this.unfilteredSlicerIngestPoints,
        this.sortedColumn,
        this.sortOrder,
      );

      this.protocols = _.map(await this.slicerService.getSlicerTypes(), p => ({value: p.id, text: p.name}));
      this.regions = _.map(await this.slicerService.getZones(), z => ({value: z.id, text: z.name}));

      this.filterIngestPoints();
      this.pollTracker.start();
      this.isLoading = false;
    } catch (e) {
      logger.error(e.message);
    }
  }

  public search(query: string) {
    this.searchQuery = query;
    this.filterIngestPoints();
  }

  public filterIngestPoints() {
    try {
      _.each(this.unfilteredSlicerIngestPoints, r => {
        const endpoint = _.find(this.slicerIngestPoints, f => f.id === r.id);
        r.selected = endpoint && endpoint.selected ? endpoint.selected : false;
      });
      if (!this.searchQuery) {
        this.slicerIngestPoints = this.unfilteredSlicerIngestPoints;
        return;
      }
      const query = this.searchQuery.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
      const regex = new RegExp(query, 'i');
      this.slicerIngestPoints = this.unfilteredSlicerIngestPoints.filter(
        e => !!e.name.toLowerCase().match(regex) || !!e.description.toLowerCase().match(regex),
      );
    } catch (e) {
      logger.error(e.message);
    }
  }

  public trackSelectedIngestPoints(slicerIngestPoint: IngestPoint) {
    if (slicerIngestPoint.selected) {
      this.selectedSlicerIngestPoints.push(slicerIngestPoint.id);
    } else {
      _.remove(this.selectedSlicerIngestPoints, rId => rId === slicerIngestPoint.id);
    }
  }

  public onRowClick(slicerIngestPoint: IngestPoint) {
    const routeObj = this.router.routes.find(x => x.name === RouteName.SlicerIngestPointSingle);
    routeObj.settings.data = {
      slicerIngestPoint,
      index: _.findIndex(this.slicerIngestPoints, {id: slicerIngestPoint.id}),
      slicerIngestPoints: this.slicerIngestPoints,
    };
    this.router.navigate(`${slicerIngestPoint.id}`);
  }

  public async createIngestPoint() {
    try {
      const sharedModel = {
        creationError: '',
        slicerIngestPoint: this.slicerIngestPoint,
        slicerIngestPoints: this.slicerIngestPoints,
        types: this.protocols,
        zones: this.regions,
      };

      await this.dialogService
        .open({
          model: {
            sharedModel,
            bodyViewModel: PLATFORM.moduleName('apps/cms/routes/slicers/ingest-points/modals/ingest-point-modal'),
            footerEnable: true,
            footerViewModel: PLATFORM.moduleName(
              'apps/cms/routes/slicers/ingest-points/modals/ingest-point-modal-footer',
            ),
            size: 'medium',
            title: 'Create Ingest Point',
          },
          viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        })
        .whenClosed(async response => {
          try {
            if (!response.wasCancelled) {
              this.isCreating = true;
              this.slicerIngestPoint = await this.slicerService.createIngestPoints(this.slicerIngestPoint);
              this.slicerIngestPoints.push(this.slicerIngestPoint);
              this.vToastsService.success('Slicer ingest point created successfully');
            }
          } catch (e) {
            this.vToastsService.error('Error creating slicer ingest point');
            logger.error(e.message);
          } finally {
            this.isCreating = false;
          }
          this.slicerIngestPoint = new IngestPoint();
        });
    } catch (e) {
      logger.error(e.message);
    }
  }

  public async deleteIngestPoint() {
    const selectedCount = this.selectedSlicerIngestPoints.length;
    // @ts-ignore
    await Promise.filter(this.selectedSlicerIngestPoints, async selectedIngestPointId => {
      try {
        this.isDeleting = true;
        await this.slicerService.deleteIngestPoint(selectedIngestPointId);
        _.remove(this.slicerIngestPoints, r => r.id === selectedIngestPointId);
      } catch (e) {
        this.vToastsService.error(e.details);
        logger.error(e.details);
      } finally {
        this.selectedSlicerIngestPoints = [];
        this.isDeleting = false;
      }
      return true;
    });

    if (this.selectedSlicerIngestPoints.length === 0) {
      this.selectedSlicerIngestPoints = [];
      this.vToastsService.success(`Deleted ${selectedCount} slicer ingest point${selectedCount === 1 ? '' : 's'}`);
    }

    return true;
  }

  public detached() {
    this.pollTracker.stop();
  }

  /**
   * Toggle all visible NOT_IN_USE ingest points as deletable
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    _.each(this.slicerIngestPoints, r => {
      r.selected = r.status !== 'IN_USE' ? isSelected : false;
    });
  }
}
