import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {CTableRowBehavior} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {ProfilesService} from '../../services/profiles-service';
import {Config} from './models';
import {EncodingProfileTable} from './table';
import {IEncodingProfileTableRow} from './table-models';
import {EncodingProfileTableRowFormatter} from './table-row';

@autoinject
export class EncodingProfileList {
  public config = new Config();
  public table: EncodingProfileTable;
  private profilesService: ProfilesService;
  private searchQuery: string;

  constructor(protected acceo: Acceo, protected router: Router, protected sessionService: SessionService) {
    this.profilesService = new ProfilesService(acceo);
    this.table = new EncodingProfileTable(CTableRowBehavior.DEFAULT, this.sessionService.hasHostedSlicersAccess);
    this.table.actions.rowClick = row => this.router.navigate(row.id);
  }

  public activate() {
    this.load();
    this.config.input.search = '';
  }

  public showHideColumns(col) {
    col.selected = !col.selected;
    this.table.trackSelectedCol(col);
  }

  @computedFrom('table.rows', 'config.input.rateCard', 'config.input.search')
  get filteredRows(): IEncodingProfileTableRow[] {
    return this.table.rows.filter(row => {
      let foundProfileText = true;
      let foundRateCard = true;
      if (this.config.input.search && this.config.input.search.length) {
        this.searchQuery = this.config.input.search;
        foundProfileText = this.searchProfileText(row, [
          'id',
          'rate_card_id',
          'desc',
          'resolution',
          'op_ray_bit_rate',
        ]);
      }
      if (this.config.input.rateCard && this.config.input.rateCard.length) {
        this.searchQuery = this.config.input.rateCard;
        foundRateCard = this.searchProfileText(row, ['rate_card_id']);
      }
      return foundProfileText && foundRateCard;
    });
  }

  protected async load() {
    this.config.state.error = undefined;
    this.config.state.isLoading = true;
    if (this.sessionService.hasHostedSlicersAccess) {
      this.config.state.canExportCSV = true;
      this.config.state.canViewDeprecatedProfiles = true;
      this.config.state.canViewDynamicCols = true;
    }
    try {
      const rows = await this.requestRead();
      this.table.rows = _.sortBy(rows, 'rate_card_id');
      if (this.config.state.canViewDynamicCols) {
        this.table.addColsFromRows(this.table.colsIgnore, false, false);
      }
      this.config.input.defaultProfileLive = rows.find(row => row.is_default_live_profile);
      this.config.input.defaultProfileVod = rows.find(row => row.is_default_vod_profile);
      this.config.options.rateCard = this.config.options.rateCardAll.concat(
        _.uniq(rows.map(row => row.rate_card_id))
          .sort()
          .map(rate_card_id => ({text: rate_card_id, value: rate_card_id})),
      );
    } catch (reason) {
      this.config.state.error = reason;
    }
    this.config.state.isLoading = false;
  }

  private async requestRead(): Promise<IEncodingProfileTableRow[]> {
    return this.profilesService.get().then(value =>
      value.items
        // Hide deprecated profiles with 0 live slicers and 0 VOD assets
        .filter(item => !(item.deprecated && item.vod_assets === 0 && item.live_slicers === 0))
        .filter(
          item =>
            !item.deprecated ||
            this.config.state.canViewDeprecatedProfiles ||
            item.is_default_live_profile ||
            item.is_default_vod_profile,
        )
        .map(item => EncodingProfileTableRowFormatter.format(item as IEncodingProfileTableRow)),
    );
  }

  private searchProfileText(row, queryKeys) {
    let foundMatch = false;
    if (!this.searchQuery) {
      return true;
    }
    const query = this.searchQuery.replace(/\+/g, '\\+');
    const regex = new RegExp(query, 'i');
    queryKeys.forEach(key => {
      if (typeof row[key] === 'string' && !foundMatch) {
        foundMatch = !!row[key].toLowerCase().match(regex);
      }
    });
    return foundMatch;
  }
}
