import {computedFrom} from 'aurelia-binding';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, containerless} from 'aurelia-framework';
import {NavigationInstruction, Router} from 'aurelia-router';
import {AD_DEBUG_EVENT} from '../index';
import {
  AdServerDebugItem,
  Callback,
  Transaction,
  TransactionsTreeItem,
  Ad,
  Slot,
} from '../models/ad-server-debug-response';

export const enum Tab {
  TransactionsTree = 'transactionsTree',
  Transactions = 'transactions',
  Callbacks = 'callbacks',
  Ads = 'ads',
}

@autoinject()
@containerless()
export class JobWithTxCb {
  @bindable()
  public job: AdServerDebugItem;

  public selectedTab: Tab;
  public canExport: boolean;

  public transactions: Transaction[];
  public transactionsTreeItems: TransactionsTreeItem[];
  public callbacks: Callback[];
  public ads: Ad[] = [];

  public transactionDetailRef: any;
  public callbackDetailRef: any;
  public adDetailRef: any;

  constructor(public eventAggregator: EventAggregator, public router: Router) {}

  public backToResults() {
    this.eventAggregator.publish(AD_DEBUG_EVENT.BackToResults);
  }

  public switchTo(tab: Tab) {
    this.selectedTab = tab;
  }

  public tabState(tab: Tab) {
    if (this.selectedTab === tab) return 'active';
    return '';
  }

  @computedFrom('selectedTab')
  public get transactionsTreeTabState() {
    return this.tabState(Tab.TransactionsTree);
  }

  @computedFrom('selectedTab')
  public get transactionsTabState() {
    return this.tabState(Tab.Transactions);
  }

  @computedFrom('selectedTab')
  public get adsTabState() {
    return this.tabState(Tab.Ads);
  }

  @computedFrom('selectedTab')
  public get callbacksTabState() {
    return this.tabState(Tab.Callbacks);
  }

  @computedFrom('job')
  public get jobStatusLabel(): string {
    const status: string = `${this.job.status}`;
    let label: string = `${status}`;
    if (status === 'complete') label += 'd';
    if (status === 'fail') label += 'ed';
    if (this.job.hasWarnings) label += ' with warnings';
    return label.toUpperCase();
  }

  @computedFrom('job')
  public get jobStatusBadgeType(): string {
    const {status} = this.job;

    if (status === 'complete') {
      return 'success';
    }
    if (status === 'fail') {
      return 'danger';
    }
    if (this.job.hasWarnings) {
      return 'warning';
    }
    return 'neutral';
  }

  public jobChanged() {
    if (this.job) {
      this.updateJob();
      this.updateQueryString();
    }
  }

  public updateJob() {
    this.transactionsTreeItems = this.job.transactionsTreeItems || [];
    this.transactions = this.job.transactions || [];
    this.callbacks = this.job.callbacks || [];
    this.selectedTab = Tab.Transactions;
    this.ads = [];
    this.setSelectedAds(this.job.slots);
    this.setNotSelectedAds(this.job.slots);
    this.canExport = this.transactions.length > 0 || this.callbacks.length > 0;
  }

  public setSelectedAds(slots: Slot[]): void {
    let ads: Ad[] = [];

    slots.forEach((slot, slotIndex) => {
      const selectedAds = slot.selectedAds.map((selectedAd, adIndex) => {
        const ad = new Ad();
        ad.breakIndex = slotIndex;
        ad.adIndex = adIndex;
        ad.selected = selectedAd.selected;
        ad.adId = selectedAd.adId;
        ad.creativeId = selectedAd.creativeId;
        ad.duration = selectedAd.duration;
        ad.uplynkId = selectedAd.uplynkId;
        ad.uplynkStatus = selectedAd.uplynkStatus;
        ad.isFallback = selectedAd.isFallback;
        ad.initialAdId = selectedAd.initialAdId;
        return ad;
      });
      ads = ads.concat(selectedAds);
    });

    this.ads = this.ads.concat(ads);
  }

  public setNotSelectedAds(slots: Slot[]): void {
    let ads: Ad[] = [];

    slots.forEach((slot, slotIndex) => {
      const selectedAds = slot.notSelectedAds.map((notSelectedAd, adIndex) => {
        const ad = new Ad();
        ad.breakIndex = slotIndex;
        ad.adIndex = adIndex;
        ad.selected = notSelectedAd.selected;
        ad.adId = notSelectedAd.adId;
        ad.creativeId = notSelectedAd.creativeId;
        ad.duration = notSelectedAd.duration;
        ad.uplynkId = notSelectedAd.uplynkId;
        ad.uplynkStatus = notSelectedAd.uplynkStatus;
        ad.isFallback = notSelectedAd.isFallback;
        ad.initialAdId = notSelectedAd.initialAdId;
        return ad;
      });
      ads = ads.concat(selectedAds);
    });

    this.ads = this.ads.concat(ads);
  }

  private updateQueryString() {
    const instruction: NavigationInstruction = this.router.currentInstruction;
    if (!instruction) return;
    const {queryParams} = instruction;
    queryParams.job_id = this.job.id ? this.job.id : this.job.pk;
    const queryString = Object.getOwnPropertyNames(queryParams)
      .map(n => `${n}=${queryParams[n]}`)
      .join('&');
    let {route} = instruction.config;
    route = Array.isArray(route) ? route[0] : route;
    this.router.navigate(`${route}?${queryString}`);
  }
}
