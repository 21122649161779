import {autoinject, singleton} from 'aurelia-framework';
import {serialize} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {DRMConfig} from '../models/drm-config';

const baseURL = '/api/v3/drm/';

@autoinject()
@singleton()
export class DRMConfigService {
    constructor(public acceo: Acceo) {}

    public getDRMConfigs(): Promise<DRMConfig[]> {
        return new Promise((resolve, reject) => {
            this.acceo
                .get([DRMConfig])(`${baseURL}policies`, {
                    responseTransform: resp =>
                        resp.configs.map(cfg => {
                            cfg.origName = cfg.policy_name;
                            this.convertOldtoNewFormat(cfg);
                            return cfg;
                        }),
                })
                .then((cfgs: DRMConfig[]) => {
                    resolve(cfgs);
                })
                .catch(reject);
        });
    }

    public deleteDRMConfig(policyId): Promise<DRMConfig> {
        return new Promise((resolve, reject) => {
            this.acceo
                .delete()(`${baseURL}policy/${policyId}`)
                .then(() => {
                    resolve();
                })
                .catch(reject);
        });
    }

    public createOrUpdateDRMConfig(config: DRMConfig): Promise<DRMConfig[]> {
        // assigning {} to get around the partial update issue in the service.
        if (!config.fairplay) {
            (config as any).fairplay = {};
        }
        if (!config.playready) {
            (config as any).playready = {};
        }
        if (!config.widevine) {
            (config as any).widevine = {};
        }
        return new Promise((resolve, reject) => {
            this.acceo
                .post([DRMConfig])(`${baseURL}policies/create-or-update`, config, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    requestTransform: (cfg: DRMConfig) => this.flattenConfig(cfg),
                    responseTransform: resp =>
                        resp.configs.map(cfg => {
                            cfg.origName = cfg.policy_name;
                            return cfg;
                        }),
                })
                .then((cfgs: DRMConfig[]) => {
                    resolve(cfgs);
                })
                .catch(reject);
        });
    }

    private convertOldtoNewFormat(cfg) {
        // TODO: remove this method once all old configs are in new format
        // either by saving each config via UI or by data migration.
        let fp = cfg.fairplay;
        const fpRestrictionFields = ['allow_airplay', 'allow_av_adapter', 'hdcp_enforcement'];
        const fpRestrictions = _.pick(fp, fpRestrictionFields);

        if (_.isEmpty(fp.sd) && _.isEmpty(fp.hd) && _.isEmpty(fp.uhd1) && _.isEmpty(fp.uhd2)) {
            fp.sd = {...fpRestrictions};
            fp.hd = {...fpRestrictions};
            fp.uhd1 = {...fpRestrictions};
            fp.uhd2 = {...fpRestrictions};
            // remove restriction fields outside track type, cleaning up old config.
            fp = _.omit(fp, fpRestrictionFields);
        }

        let pr = cfg.playready;
        const prRestrictionFields = [
            'play_enabler',
            'security_level',
            'digital_video_protection_level',
            'require_hdcp_type_1',
            'analog_video_protection_level',
            'uncompressed_digital_audio_protection_level',
            'compressed_digital_audio_protection_level',
        ];
        const prRestrictions = _.pick(pr, prRestrictionFields);

        if (_.isEmpty(pr.sd) && _.isEmpty(pr.hd) && _.isEmpty(pr.uhd1) && _.isEmpty(pr.uhd2)) {
            pr.sd = {...prRestrictions};
            pr.hd = {...prRestrictions};
            pr.uhd1 = {...prRestrictions};
            pr.uhd2 = {...prRestrictions};
            // remove restriction fields outside track type, cleaning up old config.
            pr = _.omit(pr, prRestrictionFields);
        }
    }

    private flattenConfig(cfg: DRMConfig) {
        delete cfg.skipValidate;
        delete cfg.origName;
        return `item=${serialize(cfg)}`;
    }
}
