import {computedFrom, inject} from 'aurelia-framework';
import {dirtyCheckPrompt} from 'decorators';
import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';
import {SettingsData} from '../services/settings-data';
import {BaseSettings} from '../base';

@dirtyCheckPrompt
@inject(Notification, SettingsData, SessionService)
export class LogPushing extends BaseSettings {
    constructor(...rest) {
        super(...rest);
        this.isTesting = false;

        // Properites used in the model
        this.originallogsKey = '';
        this.originallogSecret = '';
        this.originallogsBucket = '';
        this.originallogsPrefix = '';
        this.logsKey = '';
        this.logSecret = '';
        this.logsBucket = '';
        this.logsPrefix = '';
        this.hasSecret = false;
        this.changeSecret = false;
    }

    initializeProperties() {
        if (this.model.logs.length) {
            this.originallogsKey = this.model.logs[0].key;
            this.logsKey = this.model.logs[0].key;
            this.originallogSecret = this.model.logs[0].secret;
            this.logSecret = this.model.logs[0].secret;
            this.originallogsBucket = this.model.logs[0].bucket;
            this.logsBucket = this.model.logs[0].bucket;
            this.originallogsPrefix = this.model.logs[0].prefix;
            this.logsPrefix = this.model.logs[0].prefix;
            this.hasSecret = this.model.logs[0].has_secret;
            this.changeSecret = false;
        }
    }

    queryData() {
        return {
            key: this.logsKey,
            secret: this.logSecret,
            bucket: this.logsBucket,
            prefix: this.logsPrefix,
        };
    }

    toggleChangeSecret() {
        this.changeSecret = !this.changeSecret;
    }

    testSettings() {
        this.isTesting = true;
        this.settingsData
            .testLogSettings(this.queryData())
            .then(data => {
                if (data.error) {
                    this.notification.error('Log pushing test with S3 failed.');
                    this.notification.error('Please check your settings and try again.');
                } else {
                    this.notification.success('The log pushing test succeeded.');
                    this.notification.success(`There should now be a small test file named "${data.result}"
                        in your S3 bucket.`);
                }
            })
            .finally(() => {
                this.isTesting = false;
            });
    }

    saveSettings() {
        this.isSaving = true;
        this.settingsData
            .updateLogSettings(this.queryData())
            .then(data => {
                if (data.error === 0) {
                    this.model.logs = data.item;
                    this.initializeProperties();
                    this.notification.success('Push log settings have been saved.');
                }
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    isDirty() {
        return this.isModified;
    }
    /* ---------------------------------------------------------------------- *\
     * Computed Properties
    \* ---------------------------------------------------------------------- */

    @computedFrom(
        'originallogsKey',
        'logsKey',
        'originallogSecret',
        'logSecret',
        'originallogsBucket',
        'logsBucket',
        'originallogsPrefix',
        'logsPrefix',
    )
    get isModified() {
        return (
            this.originallogsKey !== this.logsKey ||
            this.originallogSecret !== this.logSecret ||
            this.originallogsBucket !== this.logsBucket ||
            this.originallogsPrefix !== this.logsPrefix
        );
    }
}
