import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AnalyticsService} from '../../services/analytics';
import {toolTips} from '../tool-tips';

@autoinject()
export class DashboardSingle {
  public queryParams: any;
  public toolTips = toolTips;

  constructor(protected router: Router, public analytics: AnalyticsService) {}

  public async activate(params) {
    this.queryParams = params;
    this.initSettings();
  }

  public async initSettings() {
    this.analytics.isLoading = true;
    await this.analytics.getSystemAnalytics();
    this.analytics.currentDashboard = this.analytics.currentModel.dashboards.find(d => d.id === this.queryParams.id);
    this.analytics.savedDashboardTitle = this.analytics.currentDashboard.title;
    this.analytics.isLoading = false;
  }

  public backToDashboards() {
    this.router.navigate('/cms-analytics/dashboards');
  }
}
