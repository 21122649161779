/* eslint max-classes-per-file: 0 */

import {Type} from 'class-transformer';
import {IsBoolean, IsNumber, IsOptional, IsString, ValidateNested, IsArray} from 'class-validator';

import {WorkspaceKeyRevision} from './workspace-key-revision';

export interface IWorkspaceKeyParams {
  desc?: string;
  domain?: string;
  invalid_after?: string;
  scope: string[];
  title: string;
  user_id?: string;
}

export interface IWorkspaceKeyUpdatableParams {
  desc?: string;
  title?: string;
}

export interface IWorkspaceKeyFilterParams {
  page_size?: number;
  since?: number;
  page?: number;
  include_deleted?: boolean;
  search?: string;
  order?: string;
}

export interface IWorkspaceKeyChangeLog {
  details: string;
  username: string;
  timestamp: number;
}

export class WorkspaceKeyChangeLog implements IWorkspaceKeyChangeLog {
  @IsString()
  public details: string;

  @IsString()
  public username: string;

  @IsNumber()
  public timestamp: number;
}

export class WorkspaceKey {
  @IsString()
  @IsOptional()
  public created: string;

  @IsString()
  @IsOptional()
  public desc?: string = '';

  @IsString()
  public id: string;

  @IsString()
  @IsOptional()
  public deleted?: string;

  @IsString()
  @IsOptional()
  public lastmod?: string;

  @IsString()
  @IsOptional()
  public key?: string;

  @IsBoolean()
  @IsOptional()
  public selected: boolean;

  @IsString()
  public title: string;

  @IsString()
  @IsOptional()
  public user_id?: string;

  @IsString()
  public workspace_id: string;

  @ValidateNested()
  @Type(() => WorkspaceKeyRevision)
  public revisions: WorkspaceKeyRevision[];

  @ValidateNested()
  @Type(() => WorkspaceKeyChangeLog)
  public change_logs: WorkspaceKeyChangeLog[];
}

export interface IJwtHeader {
  alg: string;
  kid: string;
  typ: string;
}

export interface IJwtPayload {
  sub: string;
  scp: string[];
}

export interface IJwtSettings {
  headers: IJwtHeader;
  payload: IJwtPayload;
}

export interface IKeySettings {
  key: string;
  jwt_settings: IJwtSettings;
}

export class JwtHeader implements IJwtHeader {
  @IsString()
  public alg: string;

  @IsString()
  public kid: string;

  @IsString()
  public typ: string;
}

export class JwtPayload implements IJwtPayload {
  @IsString()
  public sub: string;

  @IsArray()
  public scp: string[];
}

export class JwtSettings implements IJwtSettings {
  @ValidateNested()
  @Type(() => JwtHeader)
  public headers: JwtHeader;

  @ValidateNested()
  @Type(() => JwtPayload)
  public payload: JwtPayload;
}

export class WorkspaceKeyCreateResponse extends WorkspaceKey implements IKeySettings {
  @IsString()
  public id: string;

  @IsString()
  public key: string;

  @ValidateNested()
  @Type(() => JwtSettings)
  public jwt_settings: JwtSettings;

  @ValidateNested()
  @Type(() => WorkspaceKeyRevision)
  public revisions: WorkspaceKeyRevision[];

  @IsString()
  @IsOptional()
  public message: string;

  @IsString({each: true})
  @IsOptional()
  public errors?: string[];
}

export class WorkspaceKeyResponse {
  @ValidateNested()
  @Type(() => WorkspaceKey)
  public items: WorkspaceKey[];

  @IsNumber()
  public total_items: number;
}
