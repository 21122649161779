import {autoinject, computedFrom} from 'aurelia-framework';

import {IsNumber, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

import {validEmail} from 'resources/valid-email';

@autoinject
export class CreateAccount {
    /*
     * Computed Properties
     */

    @computedFrom('loading')
    get createBtnState() {
        return this.loading ? 'thinking' : '';
    }

    /*
     * Public Properties
     */

    public email_input: string = '';
    public results: any[] = [];

    public resultCols = [
        {
            colClass: 't270',
            colHeadName: 'username',
            colHeadValue: 'E-mail',
            colWidth: 300,
        },
        {
            colHeadName: 'result',
            colHeadValue: 'Result',
            colWidth: 1000,
        },
    ];

    public resultsTable = {
        getColClass: (row, col) => {
            let cls = col._class || '';
            if (col.colHeadName === 'username') {
                if (row._status === 1) {
                    cls += 'bgHealthy';
                } else {
                    cls += 'bgCritical';
                }
            }
            return cls;
        },
    };
    /*
     * Private Properties
     */

    private loading: boolean = false;

    constructor(private acceo: Acceo) {}

    /*
     * Public Methods
     */

    public async createAccount() {
        // convert every account request to a list
        // split on comma
        // ignore empty/undefined fields
        this.loading = true;
        const email_list = this.email_input
            .split(/,/)
            .filter(notNull => notNull)
            .map(e => e.trim());
        const url: string = '/ops/create-account';
        for await (const email_address of email_list) {
            if (!validEmail(email_address)) {
                this.results.push({
                    _status: 0,
                    result: 'Invalid email address',
                    username: email_address,
                });
                continue;
            }
            try {
                const resp = await this.acceo.post(Response)(url, {email: email_address, username: null});
                if (resp.success) {
                    this.results.push({
                        _status: 1,
                        result: 'Account successfully created',
                        username: email_address,
                    });
                } else {
                    this.results.push({
                        _status: 0,
                        result: `API Error: ${resp.error}`,
                        username: email_address,
                    });
                }
            } catch (err) {
                this.results.push({
                    _status: 0,
                    result: `${err}`,
                    username: email_address,
                });
            }
        }
        this.loading = false;
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsNumber()
    public success: number;

    @IsNumber()
    public error: any;
}
