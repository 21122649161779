import {autoinject} from 'aurelia-framework';

import {InputValidationCustomAttribute} from 'custom-attributes/input-validation';
import {dirtyCheckPrompt} from 'decorators';
import {Notification} from 'resources/notification/service';
import {TestPlayersSettings} from './models/test-players';
import {TestPlayersService} from './services/test-player-settings';

@dirtyCheckPrompt
@autoinject()
export class TestPlayers {
    public settings: TestPlayersSettings;
    public originalSettings: TestPlayersSettings;

    public newAddress: string;
    public isSaving: boolean = false;
    public isLoading: boolean = false;
    public canSave: boolean = false;

    constructor(
        public notification: Notification,
        public testPlayersService: TestPlayersService,
        public validationAttr: InputValidationCustomAttribute,
    ) {}

    public async attached() {
        this.isLoading = true;
        this.settings = await this.testPlayersService.getData();
        this.originalSettings = _.cloneDeep(this.settings);
        this.isLoading = false;
    }

    public removeAddress(index) {
        this.settings.whitelist.splice(index, 1);
        this.checkDirty();
    }

    public validateIP(newAddress): boolean {
        return (
            newAddress &&
            this.validationAttr.ipAddressValidation(newAddress) &&
            this.settings.whitelist.indexOf(newAddress) === -1
        );
    }

    public addIPAddress() {
        if (!this.newAddress.length || !this.validateIP(this.newAddress)) {
            return;
        }

        this.settings.whitelist.push(this.newAddress);

        this.newAddress = undefined;
        this.checkDirty();
    }

    public async save() {
        this.isSaving = true;

        try {
            await this.testPlayersService.update(this.settings);
            this.originalSettings = _.cloneDeep(this.settings);
            this.notification.success('Successfully updated Test Players settings.');
        } catch (e) {
            this.notification.error('Error saving settings. Please try again.');
        }

        this.checkDirty();
        this.isSaving = false;
    }

    public isDirty(): boolean {
        return this.canSave;
    }

    private checkDirty() {
        if (
            this.settings.preventAutomatic !== this.originalSettings.preventAutomatic ||
            !_.isEqual(this.settings.whitelist, this.originalSettings.whitelist)
        ) {
            this.canSave = true;
            return;
        }

        this.canSave = false;
    }
}
