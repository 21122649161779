import {inject} from 'aurelia-framework';
import {Shared} from './shared-data';

@inject(Shared)
export class Confirm {
    public controller;
    public modelData: any;
    public shared;
    public authState;
    public incState;

    constructor(shared) {
        this.shared = shared;
        this.shared.auth = '';
        this.shared.inc = '';
    }

    public activate(model) {
        this.controller = model.controller;
        this.modelData = model.body;
        this.authState = 'error';
        this.incState = 'error';
    }

    public watchAuth() {
        if (this.shared.auth !== '') {
            this.authState = '';
        } else {
            this.authState = 'error';
        }
        this.modelData.inputValues.authorizedBy = this.shared.auth;
    }

    public watchInc() {
        this.incState = 'error';
        if (this.shared.inc !== '') {
            this.incState = '';
        } else {
            this.incState = 'error';
        }
        this.modelData.inputValues.sncIncident = this.shared.inc;
    }
}
