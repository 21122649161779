import {IConfigSetting} from './config-setting';

export class ConfigOptions {
  public configSetting: IConfigSetting[] = [];

  public get configSettingAllowed() {
    return this.configSetting.filter(setting => !setting.disallowed && !setting.managed && !setting.required);
  }

  public get configSettingSelectable() {
    return this.configSettingAllowed.map(setting => ({
      text: setting.text,
      value: setting.key,
    }));
  }
}
