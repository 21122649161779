import {bindable, bindingMode} from 'aurelia-framework';

import * as styles from './v-uplynk-player.css';

export class VUplynkPlayer {
  @bindable
  public playerUrl: string;

  @bindable
  public getPlayerUrl: () => Promise<string>;

  @bindable
  public altText: string = '';

  @bindable
  public color: string;

  @bindable
  public disablePlayerText: string = 'Player Disabled';

  @bindable
  public isVod: boolean = false;

  @bindable
  public playerAutoStart: boolean = false;

  @bindable({defaultBindingMode: bindingMode.twoWay})
  public displayPlayer: boolean = true;

  @bindable({defaultBindingMode: bindingMode.twoWay})
  public disablePlayer: boolean = false;

  public styles = styles;
  public streamUrl: string = null; // What the player actually uses
  public playerUrlLoading: boolean = false;

  public attached() {
    this.build();
  }

  public playerUrlChanged() {
    this.build();
  }

  public toggle() {
    if (!this.displayPlayer) {
      this.getPlayer();
      // Bring player into DOM
      this.displayPlayer = !this.displayPlayer;

      // Remove player from DOM
      _.delay(() => {
        this.displayPlayer = !this.displayPlayer;
      }, 300);
    }
  }

  private build() {
    this.displayPlayer = true;
    this.getPlayer();
  }

  private async getPlayer() {
    if (this.disablePlayer) {
      return;
    }
    // If there isn't a playerUrl passed in, try and get one
    if (!this.playerUrl && _.isFunction(this.getPlayerUrl)) {
      this.playerUrlLoading = true;
      try {
        this.streamUrl = await this.getPlayerUrl();
      } catch (e) {
        this.streamUrl = null;
      } finally {
        this.playerUrlLoading = false;
      }
    } else {
      this.streamUrl = this.playerUrl;
    }
  }
}
