import {generateRandom} from '@bindable-ui/bindable';
import {MSOSlicerVersionResponseItem} from 'apps/cms/routes/slicers/models/version';
import {MSOSlicerVersionService} from 'apps/cms/routes/slicers/services/mso-slicer-version-service';
import {autoinject, computedFrom} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {LynkDialogServiceModel} from 'resources/services/dialog';
import {ConfigMessages} from '../models';
import {Config} from './models';
import {SlicerVersionSelectionTable} from './table';
import {ISlicerVersionTableRow} from './table-models';

@autoinject
export class SlicerVersionSelection {
  public config = new Config();
  public table = new SlicerVersionSelectionTable();
  protected searchKeys = [
    'creation_time',
    'description',
    // todo: re-enable this once MSO adds support for it
    // 'dockerTag',
    'slicer_version',
    'version_id',
  ];

  protected searchQuery: string;
  protected slicerVersionService: MSOSlicerVersionService;

  constructor(acceo: Acceo) {
    this.slicerVersionService = new MSOSlicerVersionService(acceo);
  }

  public activate(model: LynkDialogServiceModel) {
    this.config = model.settings.sharedModel.input.slicerVersion;
    if (!this.config.state.isAssigned) {
      this.config.input.regionId = model.settings.sharedModel.input.ingestPoint.input.selection.zone.id;
      this.config.input.regionLabel = model.settings.sharedModel.input.ingestPoint.input.selection.zone.region;
    }
    this.table.selected = model.settings.sharedModel.input.slicerVersion.input.selection;
    model.settings.sharedModel.input.slicerVersion.input.selectionReset = this.table.removeSelectedRadio.bind(
      this.table,
    );
    this.load();
    this.config.input.search = '';
  }

  @computedFrom('table.rows', 'config.input.search', 'config.input.ingestPoint.input.selection.zone.id')
  get filteredRows(): ISlicerVersionTableRow[] {
    return this.table.rows.filter(row => {
      let foundText = true;
      const foundSelection = true;
      if (this.config.input.search && this.config.input.search.length) {
        this.searchQuery = this.config.input.search;
        foundText = this.searchText(row, this.searchKeys);
      }
      return foundText && foundSelection;
    });
  }

  protected async load() {
    this.config.state.error = undefined;
    this.config.state.isLoading = true;
    try {
      this.table.rows = await this.requestRead();
    } catch (error) {
      this.config.state.error = ConfigMessages.SLICER_VERSION_READ_ERROR;
    }
    this.config.state.isLoading = false;
  }

  protected async requestRead(): Promise<ISlicerVersionTableRow[]> {
    return this.slicerVersionService.get(this.config.input.regionId).then(value =>
      value.items
        .sort((a, b) => (b.slicer_version > a.slicer_version ? 1 : -1))
        .map((item: MSOSlicerVersionResponseItem, index: number): ISlicerVersionTableRow => {
          const result = item as ISlicerVersionTableRow;
          result.id = generateRandom();
          result.order = index;
          result.region = this.config.input.regionId;
          return result;
        }),
    );
  }

  protected searchText(row, queryKeys) {
    let foundMatch = false;
    if (!this.searchQuery) {
      return true;
    }
    const query = this.searchQuery.replace(/\+/g, '\\+');
    const regex = new RegExp(query, 'i');
    queryKeys.forEach(key => {
      if (typeof row[key] === 'string' && !foundMatch) {
        foundMatch = !!row[key].toLowerCase().match(regex);
      }
    });
    return foundMatch;
  }

  public async loadRegionVersions(regionId) {
    let slicerVersionOptions = {};
    this.config.input.regionId = regionId;
    this.config.state.error = undefined;
    this.config.state.isLoading = true;
    try {
      this.table.rows = await this.requestRead();
      slicerVersionOptions = this.createVersionOptions(this.table.rows);
    } catch (error) {
      this.config.state.error = ConfigMessages.SLICER_VERSION_READ_ERROR;
    }
    this.config.state.isLoading = false;
    return slicerVersionOptions;
  }

  protected createVersionOptions(slicerVersions: any) {
    const slicerVersionOptions = [];
    let latestVersion = '';
    let stableVersion = '';
    let versionText = '';
    const versionCounter = {};

    _.each(slicerVersions, v => {
      if (v.version_id === 'LATEST') {
        latestVersion = v.slicer_version;
      } else if (v.version_id === 'STABLE') {
        stableVersion = v.slicer_version;
      }
      versionCounter[v.slicer_version] =
        versionCounter[v.slicer_version] !== undefined ? versionCounter[v.slicer_version] + 1 : 1;
    });
    _.each(slicerVersions, v => {
      if (versionCounter[v.slicer_version] > 1) {
        versionText = `${v.slicer_version} (${v.version_id})`;
      } else {
        versionText = v.slicer_version;
      }
      if (v.version_id !== 'LATEST' && v.version_id !== 'STABLE') {
        if (v.slicer_version === latestVersion) {
          versionText += ' LATEST';
        } else if (v.slicer_version === stableVersion) {
          versionText += ' STABLE';
        }
        slicerVersionOptions.push({text: versionText, value: v.version_id, slicer_version: v.slicer_version});
      }
    });
    return slicerVersionOptions;
  }
}
