import {PLATFORM} from 'aurelia-pal';
import * as moment from 'moment';

export class DeleteWarningBody {
  public controller: any;
  public model: any;
  public shared: any;
  public moment: any;
  public channelActiveSlicerCols: any;

  constructor() {
    this.channelActiveSlicerCols = [
      {
        colHeadName: 'desc',
        colHeadValue: 'Channel Name',
        view: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
        ),
        viewModel: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
        ),
      },
      {
        colHeadName: 'slicer_id',
        colHeadValue: 'Slicer ID',
        view: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
        ),
        viewModel: PLATFORM.moduleName(
          '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
        ),
      },
    ];
  }

  public activate(model) {
    this.model = model.body;
    this.controller = model.controller;
    this.shared = model.shared;
    this.moment = moment;
  }
}
