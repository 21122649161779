import {SessionService} from 'services/session';

import {KEYSMAP, KEYSMAP2} from '../models/account-access';
import {PermissionOption} from '../models/permission-option';

export interface IPermission {
  class?: string;
  enabled?: (session: SessionService) => boolean;
  key: string;
  text: string;
  options: PermissionOption[];
}

export const PERMISSIONS: IPermission[] = [
  {
    class: 't-110',
    key: 'live_slicers',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.slicersLiveRO}),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.slicersLiveRW,
        linkedValue: KEYSMAP.slicersLiveRO,
      }),
    ],
    text: 'Live Slicers',
  },
  {
    class: 't-150',
    enabled: s => s.hasSlicersCloudAccess,
    key: 'cloud_slicers',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.slicersCloudRO}),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.slicersCloudRW,
        linkedValue: KEYSMAP.slicersCloudRO,
      }),
      new PermissionOption({
        text: 'manage',
        value: KEYSMAP.slicerCloudMe,
        includeInAll: false,
      }),
    ],
    text: 'Cloud Slicers',
  },
  {
    class: 't-110',
    key: 'streaming',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.streamingTabsRO}),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.streamingTabsRW,
        linkedValue: KEYSMAP.streamingTabsRO,
      }),
    ],
    text: 'Streaming',
  },
  {
    class: 't-120',
    key: 'test_players',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.testPlayersRO}),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.testPlayersRW,
        linkedValue: KEYSMAP.testPlayersRO,
      }),
    ],
    text: 'Test Players',
  },
  {
    class: 't-110',
    key: 'monitoring',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.monitoringTabRO}),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.monitoringTabRW,
        linkedValue: KEYSMAP.monitoringTabRO,
      }),
    ],
    text: 'Monitoring',
  },
  {
    class: 't-110',
    enabled: s => s.hasNocEntitlement,
    key: 'noc',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.nocTabRO}),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.nocTabRW,
        linkedValue: KEYSMAP.nocTabRO,
      }),
    ],
    text: 'NOC',
  },
  {
    class: 't-90',
    key: 'analytics',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.analyticsTab}),
    ],
    text: 'Analytics',
  },
  {
    class: 't-150',
    key: 'billing',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.billingTabRO}),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.billingTabRW,
        linkedValue: KEYSMAP.billingTabRO,
      }),
      new PermissionOption({
        text: 'manage',
        value: KEYSMAP2.billing_with_me,
        enabled: a => a.billing_allow,
        checkedValue: a => a.billing_with_me,
        includeInAll: false,
      }),
    ],
    text: 'Billing',
  },
  {
    class: 't-90',
    key: 'settings',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.settingsTabRO}),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.settingsTabRW,
        linkedValue: KEYSMAP.settingsTabRO,
      }),
    ],
    text: 'Settings',
  },
  {
    class: 't-120',
    key: 'clipping',
    options: [
      new PermissionOption({text: 'enable', value: KEYSMAP.clippingTool}),
    ],
    text: 'Clipping Tool',
  },
  {
    class: 't-215',
    key: 'live_events',
    options: [
      new PermissionOption({
        text: 'read',
        value: KEYSMAP.liveEventDashboardRO,
        includeInAll: false,
      }),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.liveEventDashboardRW,
        linkedValue: KEYSMAP.liveEventDashboardRO,
        includeInAll: false,
      }),
      new PermissionOption({
        text: 'operator',
        value: KEYSMAP.liveEventDashboardOP,
        includeInAll: false,
      }),
    ],
    text: 'Live Events Dashboard',
  },
  {
    class: 't-110',
    enabled: s => s.isSyndicationEnabled(),
    key: 'syndication',
    options: [
      new PermissionOption({text: 'read', value: KEYSMAP.syndicationRO}),
      new PermissionOption({
        text: 'write',
        value: KEYSMAP.syndicationRW,
        linkedValue: KEYSMAP.syndicationRO,
      }),
    ],
    text: 'Syndication',
  },
  {
    class: 't-175',
    key: 'ad_server_debug',
    options: [
      new PermissionOption({
        text: 'read',
        value: KEYSMAP.adServerDebugRO,
        includeInAll: false,
      }),
    ],
    text: 'Ad Server Debug',
  },
];
