import {LogManager} from 'aurelia-framework';

const logger = LogManager.getLogger('TableStorage');

export class TableStorage {
    readonly baseTableStorageKey = 'tableData';
    private tableStorageData = {};
    public baseTableDataKey = '';
    readonly displayColumnsKey = 'displayColumns';
    readonly sortKey = 'sortColumn';
    readonly sortDirectionKey = 'sortDirection';

    constructor(baseTableDataKey: string = '') {
        this.setBaseTableDataKey(baseTableDataKey);
    }

    private setTableStorage() {
        const tableStorageData = JSON.parse(localStorage.getItem(this.baseTableStorageKey));
        this.tableStorageData = tableStorageData || {};
    }

    private saveTableStorage() {
        localStorage.setItem(this.baseTableStorageKey, JSON.stringify(this.tableStorageData));
    }

    public setBaseTableDataKey(baseTableDataKey) {
        if (baseTableDataKey.trim().length) {
            this.baseTableDataKey = baseTableDataKey.trim();
        }
    }

    public getTableData() {
        let tableData = {};
        if (this.baseTableDataKey.length) {
            this.setTableStorage();
            if (!_.isEmpty(this.tableStorageData) && !_.isEmpty(this.tableStorageData[this.baseTableDataKey])) {
                tableData = this.tableStorageData[this.baseTableDataKey];
            }
        }
        return tableData;
    }

    public saveTableData(tableData: object) {
        if (this.baseTableDataKey.length) {
            this.tableStorageData[this.baseTableDataKey] = tableData;
            this.saveTableStorage();
        }
    }

    public getSelectedColumns() {
        let selectedColumns = {};
        const tableData = this.getTableData();
        if (!_.isEmpty(tableData)) {
            if (this.displayColumnsKey in tableData) {
                selectedColumns = tableData[this.displayColumnsKey];
            }
        }
        return selectedColumns;
    }

    public saveSelectedColumns(tableColumns: any) {
        const func = 'saveSelectedColumns';

        if (this.baseTableDataKey.length) {
            const tableData = this.getTableData();
            const selectedColumns = {};
            if (tableColumns) {
                tableColumns.forEach(col => {
                    selectedColumns[col.colHeadName] = col.selected;
                });
            }
            if (_.isEmpty(this.tableStorageData[this.baseTableDataKey])) {
                this.tableStorageData[this.baseTableDataKey] = {};
            }
            tableData[this.displayColumnsKey] = selectedColumns;
            this.saveTableData(tableData);
        } else {
            logger.info(`${func} - baseTableDataKey NOT defined`);
        }
    }

    public getSortColumn(defaultSortColumn) {
        let sortColumn = defaultSortColumn.trim();
        const tableData = this.getTableData();
        if (!_.isEmpty(tableData)) {
            if (this.sortKey in tableData && tableData[this.sortKey].length) {
                sortColumn = tableData[this.sortKey];
            }
        }
        return sortColumn;
    }

    public saveSortColumn(sortColumn) {
        const tableData = this.getTableData();
        tableData[this.sortKey] = sortColumn.trim();
        this.saveTableData(tableData);
    }

    public getSortDirection() {
        let sortDirection = 'ASC';
        const tableData = this.getTableData();
        if (!_.isEmpty(tableData)) {
            if (this.sortDirectionKey in tableData && tableData[this.sortDirectionKey].length) {
                sortDirection = tableData[this.sortDirectionKey] === 'ASC' ? 'ASC' : 'DESC';
            }
        }
        return sortDirection;
    }

    public saveSortDirection(sortDirection) {
        const tableData = this.getTableData();
        const newSortDirection = sortDirection === 'DESC' ? 'DESC' : 'ASC';
        tableData[this.sortDirectionKey] = newSortDirection;
        this.saveTableData(tableData);
    }
}
