import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Toast} from 'resources/toast/toast';
import {AuthorizeStep} from 'services/auth';
import {SessionService} from 'services/session';
import {ContentService} from 'apps/cms/routes/content/services/content';
import {LiveChannelsService} from 'apps/cms/routes/live-channels/channels/services/live-channels';
import {GraphicsService} from 'apps/cms/routes/content/graphics/list/services/graphics-service';
import {Acceo} from 'services/acceo';
import {HlsjsPlayer} from 'resources/components/hlsjs-player';

export const ASSETS_URL: string = '/api/v4/assets';

type AdBreak = {
  offset: number;
  timestamp: string;
  existingIndex: number;
};

@autoinject
export class App {
  public searchText: string = '';
  public searchAssetsLibraryText: string = '';
  public activePlayer: string = '';
  public searchGraphicLibraryText: string = '';
  public searchChannelText: string = '';

  public channel: any = null;
  public asset: any = null;
  public source_model: any = null;
  public output_model: any = null;
  public graphicList: any[] = [];
  public newAssetDialog: any;
  public publishAssetDialog: any;

  // Player variables
  public sourcePlayer: HlsjsPlayer;
  public playHeadTime: number = 0; // two-way binding with player
  public videoDuration: number = 0; // two-way binding with player

  // Controlled by Ad Breaks panel
  public adBreaksViewModel: any;
  public existingAdBreakOffsets: AdBreak[] = [];
  public adBreakOffsets: AdBreak[] = [];
  public addedAdBreaks: AdBreak[] = [];
  public removedAdBreaks: AdBreak[] = [];

  public routes: any[];

  public router: any;

  constructor(
    public session: SessionService,
    public content: ContentService,
    public channels: LiveChannelsService,
    public graphics: GraphicsService,
    public acceo: Acceo,
  ) {
    this.routes = [
      {
        route: [
          '',
          'default',
        ],
        name: 'default',
        moduleId: PLATFORM.moduleName('./panels/default'),
        nav: false,
        title: 'Uplynk Studio',
      },
      {
        route: 'asset/:id',
        name: 'asset',
        moduleId: PLATFORM.moduleName('./panels/default'),
        nav: false,
        title: 'Asset',
      },
      {
        route: 'channel/:id',
        name: 'channel',
        moduleId: PLATFORM.moduleName('./panels/default'),
        nav: false,
        title: 'Channel',
      },
    ];

    const sortTitle = (a: any, b: any) => a.title.localeCompare(b.title);
    this.routes.sort(sortTitle);
  }

  get isLoadDisabled(): boolean {
    return this.asset === null && this.channel === null;
  }

  configureRouter(config: any, router: any): void {
    this.router = router;
    config.addPipelineStep('authorize', AuthorizeStep);
    config.map(this.routes);
    config.fallbackRoute('default');
  }

  async attached(): Promise<void> {
    this.session.sessionPing();

    const routeConfigName = this.router.currentInstruction.config.name;
    if (routeConfigName === 'asset') {
      const assetId = this.router.currentInstruction.params.id;
      const asset = await this.content.getAsset(assetId);
      this.source_model = asset;
      this.initOutputModel();
    }
    if (routeConfigName === 'channel') {
      // const channelId = this.router.currentInstruction.params.id;
      // TODO: get channel and load it
    }
  }

  search(query: string): void {
    this.content.search(query);
  }

  searchChannels(query: string): void {
    this.channels.search(query);
  }

  detached(): void {
    this.content.resetSearch();
  }

  resetSearch(): void {
    if (this.content.searchQueryApplied) {
      this.searchText = '';
      this.searchAssetsLibraryText = '';
      this.content.resetSearch();
    }
  }

  resetChannelSearch(): void {
    if (this.content.searchQueryApplied) {
      this.searchChannelText = '';
    }
  }

  resetChanges(): void {
    this.initOutputModel();
    this.adBreakOffsets = _.cloneDeep(this.existingAdBreakOffsets);
  }

  closePopovers(): void {
    const popovers = document.querySelectorAll('lynk-popover');
    popovers.forEach(popover => {
      popover.hide();
    });
  }

  navigateToDocs(): void {
    window.open(
      'https://docs.edgecast.com/video/index.html#Ingestion/Reprocessing.htm%3FTocPath%3DBasic%2520Setup%7COn-Demand%2520Content%7C_____3',
      '_blank',
    );
  }

  selectAsset(asset: any): void {
    this.asset = asset;
  }

  selectChannel(channel: any): void {
    this.channel = channel;
  }

  loadAssetOrChannel(): void {
    const {asset, channel} = this;
    let routeName = 'default';
    let params = {};

    if (channel !== null) {
      this.source_model = channel;
      routeName = 'channel';
      params = {id: channel.id};
    } else if (asset !== null) {
      this.source_model = asset;
      routeName = 'asset';
      params = {id: asset.id};
    }

    // Update the route with the selected asset or channel ID
    this.router.navigateToRoute(routeName, params);

    this.initOutputModel();
    this.newAssetDialog.hide();
    this.asset = null;
    this.channel = null;
  }

  async searchGraphicLibraryList(query: string): Promise<void> {
    this.graphicList = await this.graphics.getOverlayList(query);
  }

  // eslint-disable-next-line class-methods-use-this
  generateNewTitle(oldTitle: string): string {
    const regex = /\(Copy(?: (\d+))?\)$/;
    const match = regex.exec(oldTitle);

    if (match) {
      const copyNumber = match[1] ? parseInt(match[1], 10) + 1 : 1;
      return oldTitle.replace(regex, ` (Copy ${copyNumber})`);
    }

    return `${oldTitle} (Copy)`;
  }

  async initOutputModel(): Promise<void> {
    this.output_model = {...this.source_model};
    this.output_model.title = this.generateNewTitle(this.output_model.title);
    this.output_model.external_id = null;
  }

  resetGraphicLibrarySearch(): void {
    this.searchGraphicLibraryText = '';
    this.searchGraphicLibraryList(this.searchGraphicLibraryText);
  }

  prePublish(): void {
    // Ad Breaks
    this.addedAdBreaks = this.adBreakOffsets.filter(b => b.existingIndex === null);
    this.removedAdBreaks = this.existingAdBreakOffsets.filter(
      b => !this.adBreakOffsets.some(a => a.offset === b.offset),
    );

    this.publishAssetDialog.show();
  }

  publish() {
    // If the only changes are Ad Break related, call the reprocessAdBreaks method
    const newOffsets: number[] = [];
    const keepIndexes: number[] = [];
    this.adBreakOffsets.forEach(b => {
      if (b.existingIndex !== null) {
        keepIndexes.push(b.existingIndex);
      } else {
        newOffsets.push(Math.round(b.offset * 1000));
      }
    });
    this.reprocessAdBreaks(
      this.source_model.id,
      newOffsets,
      keepIndexes,
      this.output_model.title,
      this.output_model.external_id,
    );
    this.publishAssetDialog.hide();
  }

  async reprocessAdBreaks(assetId, insertBreaksList, preserveBreakIndexesList, assetTitle, externalId) {
    const url = `${ASSETS_URL}/${assetId}/reprocess`;
    try {
      const resp = await this.acceo.post()(
        url,
        {
          insert_breaks: insertBreaksList,
          preserve_breaks: preserveBreakIndexesList,
          description: assetTitle,
          external_id: externalId,
        },
        {},
      );
      Toast.success(
        `
          <strong>Asset added to reprocessing queue</strong><br />
          The new asset will appear in your All Content list once the reprocessing job has started.
        `,
        10000,
      );
      return resp;
    } catch (err) {
      // TODO: ADD NOTIFICATION AND LOGGING
      // this.notification.error('Failed to start reprocess job');
      // log.error(err);
      Toast.danger('There was an error while attempting to start the reprocess job.', 10000);
      return null;
    }
  }
}
