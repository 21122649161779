import {LogManager} from 'aurelia-framework';
import {Entitlement} from './models';

/*
TODO: once we convert route/index files to TS,
use an enum instead of strings for entitlement to module mapping
*/
export const entitlementToModuleMapping = {
  [Entitlement.AD_SERVER_DEBUG]: ['adServerDebugIndex'],
  [Entitlement.UNIVERSAL_AD_CONFIG]: [
    'accountSettings',
    'details',
  ],
  [Entitlement.ANALYTICS]: ['analyticsIndex'],
  [Entitlement.BILLING]: [
    'billingIndex',
    'authorization-billing-example',
  ],
  [Entitlement.CHANNEL_EDITOR]: [],
  [Entitlement.CLIPPING]: [],
  [Entitlement.LIVE_EVENT_DASHBOARD]: [
    'dashboard',
    'liveEventsIndex',
  ],
  [Entitlement.MONITORING]: [
    'authorization-monitoring-example',
    'monitoring-admin',
    'monitoring-channel-player',
    'monitoring-channel-tiles',
    'monitoring-channel-view',
    'monitoring-log',
    'monitoring-settings',
    'monitoring-slicer',
    'monitoring-slicer-list',
  ],
  [Entitlement.NOC]: [],
  [Entitlement.POST_CDN_MONITORING]: [],
  [Entitlement.SETTINGS]: [
    'settingsIndex',
    'authorization-settings-example',
  ],
  [Entitlement.SLICER_MONITORING_2]: [],
  [Entitlement.SLICERS_CLOUD]: [
    'slicerList',
    'slicerReservationsList',
    'slicerReservationSingle',
  ],
  [Entitlement.STREAM]: [
    'allContentIndex',
    'authorization-custom-attributes',
    'authorization-examples',
    'authorization-overview',
    'authorization-singleton',
    'hostedSlicers-reservations',
    'liveChannelsIndex',
    'slicersIndex',
  ],
  [Entitlement.SYNDICATION]: [
    'liveEventPublish',
    'publish',
    'publishDetails',
  ],
  [Entitlement.TEST_PLAYERS]: [],
};

export const moduleToEntitlementMapping = (() => {
  const obj = {};
  Object.keys(entitlementToModuleMapping).forEach(entitlement => {
    entitlementToModuleMapping[entitlement].forEach(module => {
      obj[module] = entitlement;
    });
  });
  return obj;
})();

// Ensure that entitlements in module mapping and Entitlement enum match
const nonMatchingEntitlements = _.difference(Object.values(Entitlement), Object.keys(entitlementToModuleMapping));
const log = LogManager.getLogger('entitlement-mapping');
if (nonMatchingEntitlements.length > 0) {
  log.error(
    'Entitlements in entitlementToModuleMapping and Entitlement enum must match. ' +
      `Entitlements not matching: [${nonMatchingEntitlements}]`,
  );
}
