import {computedFrom, inject} from 'aurelia-framework';
import * as papa from 'papaparse';
import * as dragula from 'dragula';

import {SecondsToHmsValueConverter} from '@bindable-ui/bindable';

import {SessionService} from 'services/session';

import {BaseEventSingle} from './base';

@inject(SessionService)
export class PodFormat extends BaseEventSingle {
  constructor(sessionService, router, notification, liveEventsService, bindingEngine, dialogService) {
    super(router, notification, liveEventsService, bindingEngine, dialogService);

    this.isAdding = false;
    this.hasMoved = false;
    this.csvFiles = [];

    this.name = null;
    this.duration = null;

    this.session = sessionService;

    this.podSingle = {};

    // This is only used for temp storage
    this._adPods = [];
  }

  activate(params) {
    super.activate(params);
    if (this.eventPromise) {
      this.eventPromise.then(() => {
        this.setupDragDrop();
      });
    }
  }

  saveEvent() {
    this.isDirty();

    const modelClone = _.cloneDeep(this.model);

    if (this._adPods.length) {
      modelClone.ad_pods = _.cloneDeep(this._adPods);
      this._adPods = [];
    }

    if (this.hasMoved) {
      this.hasMoved = false;
    }

    super.saveEvent(modelClone).finally(() => {
      this.isDirty();
      this.isSaving = true;

      // Hard re-init of array
      // A hack. Causes a flicker
      const podsClone = _.cloneDeep(this.model.ad_pods);
      this.model.ad_pods = null;
      this.podsUpdate = Date.now();

      _.defer(() => {
        this.model.ad_pods = _.cloneDeep(podsClone);
        this.podsUpdate = Date.now();
        this.isSaving = false;

        _.defer(() => this.setupDragDrop());
      });
    });
  }

  setupDragDrop() {
    if (this.drake) {
      this.drake.destroy();
      this.drake = null;
    }

    this.drake = dragula([document.getElementById('ad-pods-list')], {
      moves: (el, source, handle) => handle.classList.contains('js--drag'),
    });

    this.drake.on('drop', (el, target) => {
      const podOrder = [];
      [].forEach.call(target.children, li => {
        podOrder.push(li.getAttribute('data-name'));
      });

      this.adPods = _.map(podOrder, podName => _.find(this.model.ad_pods, {name: podName}));

      this.hasMoved = true;

      this.saveEvent();
    });
  }

  openEditModal(adPod, index) {
    // Stop event polling so we don't get conflicts
    this.liveEvents.stopSinglePoll();

    this.selectedPodIndex = index;
    this.selectedAdPod = this.transformActivePod(adPod);

    this.podSingle.metaKey = '';
    this.podSingle.metaValue = '';

    this.podDialog.show();
    // this.podSingle.open();
  }

  transformActivePod(adPod) {
    const selectedPod = _.cloneDeep(adPod);

    selectedPod.duration = SecondsToHmsValueConverter.transform(selectedPod.duration);

    selectedPod.metasArr = [];
    _.forOwn(selectedPod.metas, (value, key) => {
      selectedPod.metasArr.push({key, value});
    });

    return selectedPod;
  }

  saveActivePod() {
    const selectedPod = _.cloneDeep(this.selectedAdPod);

    this.showActiveNameRequired = false;
    this.uniqueActiveNameRequired = false;
    this.showActiveDurationRequired = false;
    this.showActiveDurationError = false;

    // Error on empty name
    if (!selectedPod.name) {
      this.showActiveNameRequired = true;
      return;
    }

    // Eror on non-unique name
    if (selectedPod.nameDirty && _.some(this.adPods, adPod => adPod.name === selectedPod.name)) {
      this.uniqueActiveNameRequired = true;
      return;
    }

    // Error on empty duration
    if (!selectedPod.duration) {
      this.showActiveDurationRequired = true;
      return;
    }

    // Error on invalid duration
    if (this.selectedAdPod.durationDirty && this.convertFromFormattedTime(selectedPod.duration) <= 0) {
      this.showActiveDurationError = true;
      return;
    }

    _.forEach(selectedPod.metasArr, meta => {
      if (meta.isDirty) {
        selectedPod.metas[meta.key] = meta.value;
      }
      if (meta.isRemoving) {
        delete selectedPod.metas[meta.key];
      }
    });

    selectedPod.duration = this.convertFromFormattedTime(selectedPod.duration);

    delete selectedPod.metasArr;
    delete selectedPod.nameDirty;
    delete selectedPod.durationDirty;

    this.model.ad_pods.splice(this.selectedPodIndex, 1, selectedPod);

    this.saveEvent();

    this.closeModal();
  }

  closeModal() {
    this.podSingle.metaKey = '';
    this.podSingle.metaValue = '';

    this.selectedPodIndex = null;
    this.selectedAdPod = null;

    this.podDialog.hide();

    this.showActiveNameRequired = false;
    this.uniqueActiveNameRequired = false;
    this.showActiveDurationRequired = false;
    this.showActiveDurationError = false;

    // Start polling again
    this.liveEvents.startSinglePoll();
  }

  countMetas(metasObj) {
    let numOfMetas = 0;

    _.forOwn(metasObj, () => {
      numOfMetas += 1;
    });

    return numOfMetas;
  }

  convertFromFormattedTime(formattedTime) {
    // converts 'hh:mm:ss' or 'mm:ss' to seconds.
    // If no colon (:) is found we just return the seconds
    const chunks = formattedTime.toString().split(':');
    let seconds = 0;
    let secondMultiplier = 1;
    try {
      for (let i = chunks.length - 1; i >= 0; i -= 1) {
        if (
          _.isNaN(parseInt(chunks[i], 10)) ||
          (chunks.length > 1 && (chunks[i].length > 2 || chunks[i].length === 0 || parseInt(chunks[i], 10) > 59))
        ) {
          throw new Error('invalid input');
        }
        seconds += parseInt(chunks[i], 10) * secondMultiplier;
        secondMultiplier *= 60;
      }
    } catch (e) {
      return -1;
    }
    return seconds;
  }

  addAdPod() {
    if (this.isAdding) {
      return;
    }

    this.showNameRequired = false;
    this.uniqueNameRequired = false;
    this.showDurationRequired = false;
    this.showDurationError = false;

    if (!this.name) {
      this.showNameRequired = true;
      return;
    }

    if (_.some(this.adPods, adPod => adPod.name === this.name)) {
      this.uniqueNameRequired = true;
      return;
    }

    if (!this.duration) {
      this.showDurationRequired = true;
      return;
    }

    if (this.convertFromFormattedTime(this.duration) <= 0) {
      this.showDurationError = true;
      return;
    }

    this.isAdding = true;

    this.adPods = _.concat(this.adPods, [
      {
        name: this.name,
        duration: this.convertFromFormattedTime(this.duration),
        metas: {},
      },
    ]);

    this.saveEvent();

    this.name = '';
    this.duration = '';

    this.isAdding = false;
  }

  importCSVError(msg) {
    this.notification.error(msg, 'CSV Error', 15000);
  }

  dirtyCheckAdPod(field, metaField) {
    if (field === 'meta') {
      if (_.hasIn(this.selectedAdPod.metas, metaField.key)) {
        if (this.selectedAdPod.metas[metaField.key] === metaField.value) {
          metaField.isDirty = false;
        } else {
          metaField.isDirty = true;
        }
      } else {
        metaField.isDirty = true;
      }
    } else if (field === 'name') {
      if (this.selectedAdPod.name === this.adPods[this.selectedPodIndex].name) {
        this.selectedAdPod.nameDirty = false;
      } else {
        this.selectedAdPod.nameDirty = true;
      }
    } else if (field === 'duration') {
      const intDuration = this.convertFromFormattedTime(this.selectedAdPod.duration);
      if (intDuration === this.adPods[this.selectedPodIndex].duration) {
        this.selectedAdPod.durationDirty = false;
      } else {
        this.selectedAdPod.durationDirty = true;
      }
    }
  }

  deleteMeta(metaField) {
    metaField.isRemoving = !metaField.isRemoving;
  }

  toggleMarkForDelete(adPod) {
    adPod.isRemoving = !adPod.isRemoving;

    if (adPod.isRemoving) {
      if (this.liveEvents.tabs.liveEventPodFormat.podsToDelete.indexOf(adPod.name) === -1) {
        this.liveEvents.tabs.liveEventPodFormat.podsToDelete.push(adPod.name);
      }
    } else {
      this.liveEvents.tabs.liveEventPodFormat.podsToDelete.splice(
        this.liveEvents.tabs.liveEventPodFormat.podsToDelete.indexOf(adPod.name),
        1,
      );
      delete adPod.isRemoving;
    }

    this.isDirty();
  }

  addMeta() {
    this.showKeyError = false;
    this.showKeyRequired = false;
    this.showKeyValueRequired = false;

    if (!this.podSingle.metaKey || this.podSingle.metaKey.length === 0) {
      this.showKeyRequired = true;
      return;
    }

    if (!this.podSingle.metaValue || this.podSingle.metaValue.length === 0) {
      this.showKeyValueRequired = true;
      return;
    }

    if (
      _.hasIn(this.selectedAdPod.metas, this.podSingle.metaKey) ||
      _.some(this.selectedAdPod.metasArr, meta => meta.key === this.podSingle.metaKey)
    ) {
      this.showKeyError = true;
      return;
    }

    this.model.ad_pods[this.selectedPodIndex].metas[this.podSingle.metaKey] = this.podSingle.metaValue;

    this.saveEvent();

    const updatedAdPod = this.transformActivePod(this.adPods[this.selectedPodIndex]);
    this.selectedAdPod.metasArr = _.unionBy(this.selectedAdPod.metasArr, updatedAdPod.metasArr, 'key');
    this.selectedAdPod.metas = updatedAdPod.metas;

    this.podSingle.metaKey = '';
    this.podSingle.metaValue = '';
  }

  isDirty() {
    if (!this.liveEvents.origEvent) {
      this.liveEvents.fields.ad_pods.isDirty = false;
    } else if (!this.model.ad_pods) {
      this.liveEvents.fields.ad_pods.isDirty = true;
    } else {
      const isDirty =
        this.liveEvents.tabs.liveEventPodFormat.podsToDelete.length ||
        (this._adPods.length > 0 && this.model.ad_pods.length !== this._adPods.length) ||
        JSON.stringify(this.model.ad_pods) !== JSON.stringify(this.liveEvents.origEvent.ad_pods) ||
        this.hasMoved;

      this.liveEvents.fields.ad_pods.isDirty = isDirty;
    }
  }

  importCSV() {
    if (!this.csvFiles.length) {
      this.importCSVError('Please select a file to import.');
      return;
    }

    papa.parse(this.csvFiles[0], {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        const {data} = results;
        const {fields} = results.meta;

        if (!data.length) {
          this.importCSVError('There was an error importing the CSV. Nothing in the file.');
          return;
        }

        // Check to make sure there aren't any duplicate meta names
        const uniqFields = _.uniq(fields);
        if (uniqFields.length !== fields.length) {
          this.importCSVError('There was an error importing the CSV. There are duplicate column names in the file.');
          return;
        }

        // Check to make sure meta name isn't an empty string
        const isTruthy = _.every(fields);
        if (!isTruthy) {
          this.importCSVError('There was an error importing the CSV. Empty names for column headers are not allowed.');
          return;
        }

        // Check for 'duration' and 'name' keys
        const durationColumn = _.findKey(data[0], (value, key) => _.trim(key.toLowerCase()) === 'duration');
        const nameColumn = _.findKey(data[0], (value, key) => _.trim(key.toLowerCase()) === 'name');

        if (!durationColumn || !nameColumn) {
          this.importCSVError(
            'There was an error importing the CSV. There needs to be a column for "Name" and "Duration".',
          );
          return;
        }

        let hasErr = null;

        const newAdPods = _.map(data, row => {
          const rowName = row[nameColumn];
          const rowDuration = this.convertFromFormattedTime(row[durationColumn]);

          // Check to make sure that names aren't being duplicated
          if (_.some(this.adPods, adPod => adPod.name === rowName)) {
            hasErr = 'There was an error importing the CSV. There are duplicate pod names.';
          }

          // Make sure duration is valid number
          if (!_.isFinite(rowDuration)) {
            hasErr = 'There was an error importing the CSV. Duration values need to be numbers.';
          }

          if (rowDuration <= 0) {
            hasErr = 'Durations must be greater than zero.';
          }

          const rowData = {
            name: rowName,
            duration: rowDuration,
            metas: {},
          };

          _.forOwn(row, (value, key) => {
            if (key !== durationColumn && key !== nameColumn && !_.isNil(value) && value !== '') {
              rowData.metas[key] = value;
            }
          });

          return rowData;
        });

        // Throw notification and bail if there was errors from above
        if (hasErr) {
          this.importCSVError(hasErr);
          return;
        }

        // Check to make sure that names aren't being duplicated inside csv
        if (_.uniqBy(newAdPods, 'name').length !== newAdPods.length) {
          this.importCSVError('There was an error importing the CSV. There are duplicate pod names.');
          return;
        }

        this.adPods = _.concat(this.adPods, newAdPods);
        this.saveEvent();
      },
      error: () => {
        this.importCSVError('There was an error importing the CSV. Please check the file validity.');
      },
    });
  }

  /* ---------------------------------------------------------------------- *\
     * Computed Properties
    \* ---------------------------------------------------------------------- */
  @computedFrom('session.isAdPreFetchEnabled', 'session.adPreFetchOverrideEnabled')
  get enableAdPrefetch() {
    if (this.session.isAdPreFetchEnabled || this.session.adPreFetchOverrideEnabled) {
      return true;
    }
    return false;
  }

  @computedFrom('model.ad_pods', 'liveEvents.tabs.liveEventPodFormat.podsToDelete.length', 'podsUpdate')
  get adPods() {
    if (!this.model || this.model.ad_pods == null) {
      return [];
    }

    const mappedPods = _.map(this.model.ad_pods, adPod => {
      if (!adPod.metas) {
        adPod.metas = {};
      }

      if (this.liveEvents.tabs.liveEventPodFormat.podsToDelete.indexOf(adPod.name) !== -1) {
        adPod.isRemoving = true;
      }

      return adPod;
    });

    return mappedPods;
  }

  set adPods(pods) {
    this._adPods = _.cloneDeep(pods);
  }
}
