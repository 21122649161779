// tslint:disable max-line-length
import {AcceoErrorToast} from 'resources/acceo-error-toast';
import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ConfigSettingsLiveSlicer, DocumentationUrlLiveSlicer} from 'resources/config/settings/live-slicer';
import {ConfigTable} from 'resources/config/table';
import {ValidateHelper} from 'resources/validate-helper';
import {Acceo} from 'services/acceo';
import {HyperionPolling} from 'services/hyperion-polling';
import {SessionService} from 'services/session';
import {LynkDialogServiceSettings} from 'resources/services/dialog';
import {HostedSlicerMenu as Menu} from '..';
import {
  MSOSlicerClassification,
  MSOSlicerHealthMonitor,
  MSOSlicerRequestPatch,
  MSOSlicerRequestPatchErrors,
} from '../../models/msoslicer';
import {MSOHostingLocationService} from '../../services/mso-hostingloc-service';
import {MSOSlicerRestartService} from '../../services/mso-slicer-restart-service';
import {MSOSlicerService} from '../../services/mso-slicer-service';
import {MSOSlicerVersionService} from '../../services/mso-slicer-version-service';
import {MSOSlicerGetByOwnerService} from '../../services/mso-slicergetbyowner-service';
import {MSOStreamProtocolService} from '../../services/mso-streamproto-service';
import {PluginService} from '../../services/plugin-service';
import {ProfilesService} from '../../services/profiles-service';
import {Config, ConfigMessages, CustomizeModal} from './models';
import {ISlicerVersionTableRow} from './slicer-version/table-models';
import {MSOSlicerMonitorService} from '../../services/mso-slicer-monitor-service';
import {TextFile} from '../../../../../../resources/file/text-file';

@autoinject
export class HostedSlicerDetails {
  public id;
  public config: Config;
  public monitor: MSOSlicerHealthMonitor;
  public sessionStreamStats: MSOSlicerHealthMonitor;
  public recentStreamStats: MSOSlicerHealthMonitor;
  public isSlicerSet = false;
  protected hostingLocationService: MSOHostingLocationService;
  protected pluginService: PluginService;
  protected profilesService: ProfilesService;
  protected slicerService: MSOSlicerService;
  protected slicerRestartService: MSOSlicerRestartService;
  protected slicerGetByOwnerService: MSOSlicerGetByOwnerService;
  protected slicerVersionService: MSOSlicerVersionService;
  protected slicerMonitorService: MSOSlicerMonitorService;
  protected streamProtocolService: MSOStreamProtocolService;
  protected hyperionPolling: HyperionPolling;
  protected hyperionPollingInterval = 10000;
  protected acceoErrorToast: AcceoErrorToast;
  public streamStatus: string;
  public statusColor: string;

  constructor(
    protected acceo: Acceo,
    protected dialogService: DialogService,
    protected menu: Menu,
    protected notification: CToastsService,
    protected router: Router,
    protected sessionService: SessionService,
  ) {
    this.hostingLocationService = new MSOHostingLocationService(acceo);
    this.pluginService = new PluginService(acceo);
    this.profilesService = new ProfilesService(acceo);
    this.slicerService = new MSOSlicerService(acceo);
    this.slicerRestartService = new MSOSlicerRestartService(acceo);
    this.slicerGetByOwnerService = new MSOSlicerGetByOwnerService(acceo);
    this.slicerMonitorService = new MSOSlicerMonitorService(acceo);
    this.slicerVersionService = new MSOSlicerVersionService(acceo);
    this.streamProtocolService = new MSOStreamProtocolService(acceo);
    this.acceoErrorToast = new AcceoErrorToast(notification);
  }

  activate(params?: any) {
    this.config = new Config();
    if (this.sessionService.hasHostedSlicersAccess) {
      this.config.state.canSetClassification = true;
      this.config.state.canViewAdvanced = true;
    }
    // load dependencies
    this.load(params.id).then(async () => {
      // try to preselect slicer version
      if (this.isSlicerSet) {
        await this.loadSlicerVersion();
        await this.loadSlicerMonitor();
        // start polling
        this.hyperionPolling = new HyperionPolling({
          ms: this.hyperionPollingInterval,
          promiseFn: this.requestReadInterval.bind(this),
        });
        this.hyperionPolling.start();
      }
    });
  }

  deactivate() {
    if (this.hyperionPolling) this.hyperionPolling.stop();
  }

  protected updatePristine(slicerData) {
    Object.keys(slicerData).forEach(k => {
      const key = _.camelCase(k);
      if (key in this.config.pristine) {
        this.config.pristine[key] = slicerData[k];
      }
    });
  }

  public actionBack() {
    const url = this.router.baseUrl.substr(0, this.router.baseUrl.length - 1);
    this.router.navigate(url);
  }

  public actionCustomize(target: CustomizeModal) {
    this.config.state.isCustomizing = true;
    const model = new LynkDialogServiceSettings();
    model.cachePristineModels = true;
    model.footer = 'standard';
    model.sharedModel = this.config;
    model.size = 'fitted';
    // eslint-disable-next-line default-case
    switch (target) {
      case CustomizeModal.ENCODING_PROFILE:
        model.bodyViewModel = PLATFORM.moduleName(
          'apps/cms/routes/slicers/hosted-slicers/single/encoding-profile/index',
        );
        model.title = 'Encoding Profile Selection';
        break;
      case CustomizeModal.INGEST_POINT:
        model.bodyViewModel = PLATFORM.moduleName('apps/cms/routes/slicers/hosted-slicers/single/ingest-point/index');
        model.title = 'Ingest Point Selection';
        break;
      case CustomizeModal.PLUGIN:
        model.bodyViewModel = PLATFORM.moduleName('apps/cms/routes/slicers/hosted-slicers/single/plugin/index');
        model.title = 'Slicer Plugin Selection';
        break;
      case CustomizeModal.SLICER_CONFIGURATION:
        model.bodyViewModel = PLATFORM.moduleName(
          'apps/cms/routes/slicers/hosted-slicers/single/slicer-configuration/index',
        );
        model.title = 'Hosted Slicer Configuration';
        break;
      case CustomizeModal.SLICER_VERSION:
        model.bodyViewModel = PLATFORM.moduleName('apps/cms/routes/slicers/hosted-slicers/single/slicer-version/index');
        model.title = 'Slicer Version Selection';
        break;
    }
    this.dialogService
      .open({
        keyboard: true,
        model,
        viewModel: PLATFORM.moduleName('resources/services/dialog'),
      })
      .whenClosed()
      .then(() => {
        this.validate();
        this.config.state.isCustomizing = false;
      });
  }

  public async actionDelete() {
    this.config.state.isDeleting = true;
    try {
      await this.requestDelete(this.config.pristine.id);
      await this.menu.load();
      this.notification.success(ConfigMessages.SLICER_DELETE_SUCCESS);
      this.actionBack();
    } catch (error) {
      this.acceoErrorToast.show(error);
    }
    this.config.state.isDeleting = false;
  }

  public async actionUpdate() {
    const request = this.transformRequestPatchInput();
    const valid = await ValidateHelper.validate(request, this.config.errors);
    if (!valid) return;
    this.config.state.isProcessing = true;
    try {
      await this.requestUpdate(request);
      await this.menu.load();
      this.notification.success(ConfigMessages.SLICER_UPDATE_SUCCESS);
    } catch (error) {
      this.acceoErrorToast.show(error);
    }
    this.config.state.isProcessing = false;
  }

  public actionRemoveEncodingProfile() {
    Object.keys(this.config.input.encodingProfile.input.selection).forEach(key => {
      this.config.input.encodingProfile.input.selection[key] = undefined;
    }, this);
    this.config.input.encodingProfile.input.selectionReset();
  }

  public actionRemoveIngestPoint() {
    Object.keys(this.config.input.ingestPoint.input.selection).forEach(key => {
      this.config.input.ingestPoint.input.selection[key] = undefined;
    }, this);
    this.config.input.ingestPoint.input.selectionReset();
    this.actionRemoveSlicerVersion();
    this.validate();
  }

  public actionRemovePlugin() {
    Object.keys(this.config.input.plugin.input.selection).forEach(key => {
      this.config.input.plugin.input.selection[key] = undefined;
    }, this);
    if (this.config.input.plugin.input.selectionReset) this.config.input.plugin.input.selectionReset();
  }

  public actionRemoveSlicerVersion() {
    Object.keys(this.config.input.slicerVersion.input.selection).forEach(key => {
      this.config.input.slicerVersion.input.selection[key] = undefined;
    }, this);
    if (this.config.input.slicerVersion.input.selectionReset) this.config.input.slicerVersion.input.selectionReset();
  }

  public async actionRestart() {
    this.config.state.isRestarting = true;
    try {
      await this.requestRestart(this.config.pristine.id);
      this.config.pristine.status = 'RESTARTING'; // the next poll interval should reset this
      this.notification.success(ConfigMessages.SLICER_RESTART_SUCCESS);
    } catch (error) {
      this.acceoErrorToast.show(error);
    }
    this.config.state.isRestarting = false;
  }

  public actionViewEncodingProfile() {
    this.router.navigate(`/slicers/encoding-profiles/${this.config.pristine.profileId}`);
  }

  public get isDirty() {
    const request = this.transformRequestPatchInput();
    if (request.classification !== this.config.pristine.classification) return true;
    if (request.configuration !== this.config.pristine.configuration) return true;
    if (request.notes !== this.config.pristine.notes) return true;
    if (request.slicer_version !== this.config.pristine.version) return true;
    return request.managed !== this.config.pristine.managed;
  }

  protected async load(id: string) {
    this.id = id;
    this.config.state.error = undefined;
    this.config.state.isLoading = true;
    try {
      this.config.options.protocol = (await this.streamProtocolService.get()).items;
      this.config.options.region = (await this.hostingLocationService.get()).items;
      this.config.pristine.id = id; // set no matter what for polling
      if (id) {
        const slicerGetByOwner = (await this.slicerGetByOwnerService.get(this.sessionService.owner)).items.find(
          item => item.id === id,
        );
        // map editable data to inputs
        const response = await this.slicerService.getById(id);
        this.isSlicerSet = true;
        this.config.input.classification = response.meta.classification as MSOSlicerClassification;
        this.config.input.notes = response.meta.notes;
        this.config.input.managed = response.meta.managed;
        this.config.input.slicerConfiguration.info.documentationUrl = DocumentationUrlLiveSlicer;
        this.config.input.slicerConfiguration.input.table = new ConfigTable(ConfigSettingsLiveSlicer, false, false);
        this.config.input.slicerConfiguration.options.configSetting = ConfigSettingsLiveSlicer;
        this.config.input.slicerConfiguration.input.table.importString(response.slicer.slicer_config);
        this.config.input.slicerConfiguration.input.username =
          this.config.input.slicerConfiguration.input.table.configTableData.getLastValue('username');
        // pristine, original data
        this.config.pristine.accountName = response.slicer.slicer_account_name;
        this.config.pristine.apiUrl = response.slicer.slicer_api_url;
        this.config.pristine.ec2Machine = response.slicer.ec2_machine;
        this.config.pristine.changeLog = slicerGetByOwner.change_log;
        this.config.pristine.classification = response.meta.classification;
        this.config.pristine.managed = response.meta.managed;
        this.config.pristine.configuration = this.config.input.slicerConfiguration.input.table.exportString();
        this.config.pristine.dateCreated = response.slicer.creation_time;
        this.config.pristine.dateDeleted = slicerGetByOwner.deleted;
        this.config.pristine.dateModified = slicerGetByOwner.lastmod;
        this.config.pristine.dockerTag = response.slicer.docker_tag || 'N/A';
        this.config.pristine.domain = response.slicer.slicer_domain_name;
        this.config.pristine.id = id;
        this.config.pristine.imageId = response.slicer.server_image_id;
        this.config.pristine.ingestPointId = slicerGetByOwner.ingest_point_id;
        this.config.pristine.instanceId = response.slicer.server_instance_id;
        this.config.pristine.notes = response.meta.notes;
        this.config.pristine.profileId = response.meta.profile_id;
        this.config.pristine.protocol = this.config.options.protocol
          .filter(item => item.id === response.slicer.stream_type_id)
          .map(item => item.name)[0];
        this.config.pristine.serverRegion = response.slicer.server_region;
        this.config.pristine.serverRegionId = response.slicer.server_region_id;
        this.config.pristine.slicerId = response.slicer.slicer_id;
        this.config.pristine.slicerPlugin = response.slicer.slicer_plugin;
        this.config.pristine.slicerPluginVersion = response.slicer.slicer_plugin_version;
        this.config.pristine.status = response.slicer.status;
        this.config.pristine.streamingKey = response.slicer.slicer_stream_key;
        this.config.pristine.streamingUrlSecure = response.slicer.slicer_secure_stream_url;
        this.config.pristine.streamingUrl = response.slicer.slicer_stream_url;
        this.config.pristine.source = response.slicer.source_ip_address;
        this.config.pristine.version = response.slicer.slicer_version;
        this.config.input.slicerVersion.state.isAssigned = true;
        // encoding profile summary
        const profile = JSON.parse(response.slicer.profile);
        if (profile.aKbps && profile.aMaxChannels) {
          this.config.pristine.audio = `${profile.aKbps} kb/s ${profile.aMaxChannels} channels`;
        }
        if (profile.vKbps && profile.vPasses) {
          this.config.pristine.quality = `${profile.vKbps} kb/s ${profile.vPasses > 1 ? 'Multi' : 'Single'}-pass`;
        }
        if (profile.encoder) {
          this.config.pristine.encoder = `${profile.encoder}`;
        }
        if (profile.maxW && profile.maxH && profile.fps) {
          this.config.pristine.resolution = `${profile.maxW}x${profile.maxH} @${profile.fps}fps`;
        }
      }
    } catch (error) {
      this.isSlicerSet = false;
      if (error.status_code === 404) {
        this.config.state.error = `${error.details} (${error.status_code})`;
      } else {
        this.acceoErrorToast.show(error);
      }
    }
    this.config.state.isLoading = false;
  }

  protected async loadSlicerVersion() {
    if (this.isSlicerSet) {
      this.config.input.slicerVersion.state.error = undefined;
      this.config.input.slicerVersion.state.isLoading = true;
      try {
        const slicerVersion = (await this.slicerVersionService.get(this.config.pristine.serverRegionId)).items.find(
          item => item.version_id === this.config.pristine.version,
        );
        if (slicerVersion) {
          this.config.input.slicerVersion.input.selection = slicerVersion as ISlicerVersionTableRow;
          this.config.input.slicerVersion.input.selection.region = this.config.pristine.serverRegionId;
          this.config.input.slicerVersion.input.regionId = this.config.pristine.serverRegionId;
          this.config.input.slicerVersion.input.regionLabel = this.config.pristine.serverRegion;
        }
      } catch (error) {
        this.config.input.slicerVersion.state.error = ConfigMessages.SLICER_VERSION_READ_ERROR;
      }
      this.config.input.slicerVersion.state.isLoading = false;
    }
  }

  protected async loadSlicerMonitor() {
    if (this.config.pristine.id && this.config.pristine.protocol === 'SRT') {
      const response = await this.slicerMonitorService.get(this.config.pristine.id);
      this.sessionStreamStats = response.session;
      this.recentStreamStats = response.recent;
      this.streamStatus = this.sessionStreamStats.link_uptime ? 'Healthy' : 'No Stream';
      this.statusColor = this.sessionStreamStats.statusColor;
    }
  }

  public onChangeActions = {
    onChange: () => {
      this.validate();
    },
  };

  protected async requestDelete(id: string) {
    return this.slicerService.delete(id);
  }

  protected async requestReadInterval() {
    const response = await this.slicerService.getById(this.config.pristine.id);
    this.updatePristine(response.slicer);
    this.config.pristine.status = response.slicer.status;
    await this.loadSlicerMonitor();
  }

  protected async requestRestart(id: string) {
    return this.slicerRestartService.getById(id);
  }

  protected async requestUpdate(body: MSOSlicerRequestPatch) {
    const result = await this.slicerService.patch(this.config.pristine.id, body);
    if (result) {
      this.updatePristine(body);
    }
    return result;
  }

  protected transformRequestPatchErrors(errors: MSOSlicerRequestPatchErrors) {
    return {
      classification: errors.classification,
      configuration: errors.configuration,
      notes: errors.notes,
      slicer_version: errors.slicer_version,
    };
  }

  protected transformRequestPatchInput() {
    const request = new MSOSlicerRequestPatch();
    request.configuration = this.config.input.slicerConfiguration.input.table.exportString();
    if (this.config.state.canSetClassification) {
      request.classification = this.config.input.classification || '';
    } else {
      request.classification = MSOSlicerClassification.PRODUCTION;
    }
    request.managed = this.config.input.managed;
    request.notes = this.config.input.notes;
    request.slicer_version = this.config.input.slicerVersion.input.selection.version_id;
    return request;
  }

  protected async validate() {
    const errors = new MSOSlicerRequestPatchErrors();
    const request = this.transformRequestPatchInput();
    let valid = await ValidateHelper.validate(request, errors);
    this.config.errors = this.transformRequestPatchErrors(errors);
    // manually validate selected slicer version
    if (request.slicer_version) {
      if (this.config.input.slicerVersion.input.selection.region !== this.config.pristine.serverRegionId) {
        this.config.errors.slicerVersion = ConfigMessages.SLICER_VERSION_INVALID_REGION;
        valid = false;
      }
    }
    return valid;
  }

  public exportLogFile(fileName: string, logData: string) {
    const fileData = logData;
    const fileMimeType = 'text/plain';
    return new File([fileData], fileName, {type: fileMimeType});
  }

  public async downloadLogFile() {
    try {
      const logFile = await this.slicerService.getSlicerLog(this.id);
      const fileName = `slicer_log${this.id}.txt`;
      const file = this.exportLogFile(fileName, logFile.slicerlog);
      await TextFile.save(file);
    } catch (error) {
      this.acceoErrorToast.show(error);
    }
  }

  public async onChangeSlicerVersion(event: any) {
    const {value} = event.target;
    this.config.input.slicerVersion.input.selection.version_id = value;
    this.config.input.slicerVersion.input.selection.slicer_version = event.target.displayLabel;
  }
}
