// eslint-disable-next-line max-classes-per-file
import {Exclude, Expose, Type, Transform} from 'class-transformer';
import {IsBoolean, IsString, IsNumber, ValidateNested, IsOptional, IsArray, IsInt} from 'class-validator';
import {computedFrom} from 'aurelia-binding';
import {convertConfigurationArrayToString, convertConfigurationStringToArray} from './slicer';

export const STATUS_MAPPING = {
  ACTIVE: 'Running',
  INACTIVE: 'Stopped',
  ACTIVATING: 'Initializing',
  DEACTIVATING: 'Stopping',
  RETRY_AFTER_FAILUR: 'Retrying',
  RETRYING_AFTER_FAILURE: 'Retrying',
  RESTARTING: 'Restarting',
};

export const stateLabelMapping = [
  'Running', // -1
  'Capturing', // 0
  'Ad Break', // 1
  'Replacing Content', // 2
  'Blackout', // 3
  'Shutting Down',
  'Shutdown',
];

export const stateToStatusMapping = [
  'running', // -1 Running (green)
  'capturing', // 0 Capturing (green + pulse)
  'ad-break', // Ads (blue + pulse)
  'replacing', // Replacing content (purple)
  'blackout', // Blackout (green)
  'warning', // Shutting down (red)
  'danger', // Shutdown
];

export class SlicerStreamStats {
  @IsInt()
  @IsOptional()
  @Transform(val => (val ? `${val} kbps` : ''))
  public stream_bitrate: number;

  @IsInt()
  @IsOptional()
  // @Transform(val => val.toLocaleString())
  public packets_inbound: number;

  @IsInt()
  @IsOptional()
  public packets_outbound: number;

  @IsInt()
  @IsOptional()
  public packets_errored: number;

  @IsInt()
  @IsOptional()
  @Transform(val => (val ? `${val}%` : ''))
  public percent_link_success: number;

  @IsInt()
  @IsOptional()
  @Transform(val => (val ? `${val} ms` : ''))
  public round_trip_time: number;

  @IsInt()
  @IsOptional()
  @Transform(val => (val ? `${val} hrs` : ''))
  public link_uptime: number;

  @IsInt()
  @IsOptional()
  @Transform(val => (val ? `${val} ms` : ''))
  public latency_setting: number;

  @IsInt()
  @IsOptional()
  @Transform(val => (val ? `${val}%` : ''))
  public percent_errored: number;

  @IsInt()
  @IsOptional()
  public packets_FEC_corrected: number;

  @IsInt()
  @IsOptional()
  @Transform(val => (val ? `${val}%` : ''))
  public percent_fixed_by_FEC: number;

  @IsInt()
  @IsOptional()
  public packets_ARQ_corrected: number;

  @IsInt()
  @IsOptional()
  @Transform(val => (val ? `${val}%` : ''))
  public percent_fixed_by_ARQ: number;

  @IsInt()
  @IsOptional()
  public ARQ_requests: number;

  @IsInt()
  @IsOptional()
  public ARQ_fails: number;

  @IsInt()
  @IsOptional()
  @Transform(val => (val ? `${val}%` : ''))
  public percent_failed_ARQ: number;

  public get statusColor(): string {
    let colorVar: string = 'primary';
    const packetsRepaired: number = this.packets_ARQ_corrected + this.packets_FEC_corrected;
    const packetsUnrepaired: number = this.packets_errored - packetsRepaired;
    let packetsRepairedRate: number = 0;
    let packetsUnrepairedRate: number = 0;

    if (this.link_uptime) {
      if (packetsRepaired > 0 && this.packets_errored > 0) {
        packetsRepairedRate = (packetsRepaired / this.packets_errored) * 100;
      }
      if (packetsUnrepaired > 0 && this.packets_errored > 0) {
        packetsUnrepairedRate = (packetsUnrepaired / this.packets_inbound) * 100;
      }
      if (packetsUnrepairedRate > 0.1) {
        colorVar = 'danger'; // red - Unhealthy
      } else if (packetsRepairedRate > 2) {
        colorVar = 'warning'; // yellow - Sketch
      } else {
        colorVar = 'success'; // green - Healthy
      }
      return colorVar;
    }
    return 'neutral';
  }
}

export class SlicerStatus {
  @IsString()
  public state: string;

  @IsOptional()
  public stream_monitor: {
    recent: SlicerStreamStats;
    session: SlicerStreamStats;
  };

  @IsOptional()
  public stream_stats_report: {
    from_start: any;
    internal_timestamp: number;
    last_interval: any;
    latency_microseconds: number;
    rtt_microseconds: number;
  };

  @IsOptional()
  @ValidateNested()
  @Type(() => SlicerState)
  public slicer_api_status: SlicerState;

  @computedFrom(
    'stream_stats_report',
    'stream_monitor',
    'stream_stats_report.last_interval.measurement_interval_end_ms_since_epoch',
    'stream_monitor.session.link_uptime',
  )
  public get isStreaming() {
    const {last_interval: lastInterval = {}} = (this.stream_stats_report as any) ?? {};
    const {session = {}} = (this.stream_monitor as any) ?? {};
    const {measurement_interval_end_ms_since_epoch: intervalEndMs = 0} = lastInterval;
    const {link_uptime: linkUptime = 0} = session;
    const lastTimeUpdate = new Date().getTime() - intervalEndMs;

    return linkUptime && !(lastTimeUpdate > 60000);
  }
}

export class SlicerState {
  @IsOptional()
  @IsInt()
  public error: number;

  @IsOptional()
  public status: any; // Full status object from the server response

  @computedFrom('status.state')
  @Expose()
  public get stateLabel(): string {
    const stateIndex = typeof this.status?.state === 'number' ? this.status.state : -1;
    return stateLabelMapping[stateIndex + 1] || 'Unknown'; // Safe fallback in case of out-of-bound state
  }

  @computedFrom('status.state')
  @Expose()
  public get stateColor(): string {
    const stateIndex = typeof this.status?.state === 'number' ? this.status.state : -1;
    return stateToStatusMapping[stateIndex + 1] || 'neutral'; // Safe fallback
  }
}

export class SlicerPlugin {
  @IsString()
  public id: string;

  @IsString()
  public version: string;
}

export class Slicer {
  @Exclude({toPlainOnly: true})
  @IsString()
  public id: string = '';

  @IsString()
  public slicer_id: string = '';

  @IsOptional()
  @Transform(v => convertConfigurationArrayToString(v), {toPlainOnly: true})
  @Transform(v => convertConfigurationStringToArray(v), {toClassOnly: true})
  public configuration: any;

  @IsString()
  @IsOptional()
  public description: string = '';

  @IsString()
  public slicer_version: string;

  @IsString()
  public source_ip: string = '0.0.0.0';

  @IsString()
  public protocol: string;

  @IsString()
  public region: string;

  @IsString()
  @Exclude({toPlainOnly: true})
  public encoding_profile: string;

  @IsString()
  public encoding_profile_id: string;

  @IsString()
  public target_state: string;

  @IsString()
  @IsOptional()
  @Exclude({toPlainOnly: true})
  public passphrase?: string | any;

  @ValidateNested()
  @Type(() => SlicerPlugin)
  @IsOptional()
  public plugin: SlicerPlugin | null = null;

  @ValidateNested()
  @Type(() => SlicerStatus)
  @Exclude({toPlainOnly: true})
  public status: SlicerStatus;

  @IsBoolean()
  @Exclude({toPlainOnly: true})
  public validated: boolean = true;

  @IsString()
  @Exclude({toPlainOnly: true})
  public streaming_url: string;

  @IsString()
  @Exclude({toPlainOnly: true})
  public slicer_api_url: string;

  @IsString()
  @Exclude({toPlainOnly: true})
  public created_at: string;

  @Exclude()
  @IsBoolean()
  public selected?: boolean = false;

  @Exclude()
  @IsBoolean()
  public loading?: boolean = false;

  @IsString()
  @Exclude({toPlainOnly: true})
  public thumb_url: string = '';

  // Status reflects the Slicer's overall operational condition.
  @IsString()
  @computedFrom('status')
  get status_label() {
    const state = this.status ? this.status.state : '';
    return STATUS_MAPPING[state];
  }

  @IsString()
  @computedFrom('status')
  get status_color() {
    let s = '';
    const status = this.status ? this.status.state : '';

    switch (status) {
      case 'ACTIVE':
        s = 'success';
        break;
      case 'INACTIVE':
        s = 'neutral';
        break;
      case 'ACTIVATING':
      case 'DEACTIVATING':
      case 'RESTARTING':
      case 'RETRY_AFTER_FAILUR':
      case 'RETRYING_AFTER_FAILURE':
        s = 'warning';
        break;
      default:
        break;
    }

    return s;
  }

  // State describes the Slicers specific activity while it is Running
  @IsString()
  @computedFrom('status')
  get state_label() {
    const stateIndex = _.isNumber(this.status?.slicer_api_status?.status?.state)
      ? this.status.slicer_api_status.status.state
      : -1;
    return stateLabelMapping[stateIndex + 1] || 'Unknown';
  }

  @IsString()
  @computedFrom('status')
  get state_color() {
    const stateIndex = _.isNumber(this.status?.slicer_api_status?.status?.state)
      ? this.status.slicer_api_status.status.state
      : -1;
    return stateToStatusMapping[stateIndex + 1] || 'neutral';
  }

  @computedFrom('target_state', 'status.state')
  get isRunning() {
    return this.target_state === 'ACTIVE' && this.status.state === 'ACTIVE';
  }

  @computedFrom('target_state', 'status.state')
  get isStopped() {
    return this.target_state === 'INACTIVE' && this.status.state === 'INACTIVE';
  }

  @computedFrom('target_state', 'status.state')
  get isInitializing() {
    return this.target_state !== this.status.state;
  }
}

export class SlicerListResponse {
  @ValidateNested()
  @Type(() => Slicer)
  public items?: Slicer[];

  @IsNumber()
  public total_items: number;
}

export class Region {
  @IsString()
  public name: string;

  @IsString()
  public code: string;
}

export class SlicerVersion {
  @IsString()
  public created_at: string;

  @IsString()
  public id: string;

  @IsArray()
  public tags: string[];

  @IsString()
  get name() {
    const tagName = this.tags && this.tags.find(ver => this.id !== ver);
    return `${this.id}${tagName ? ` (${tagName.toUpperCase()})` : ''}`;
  }
}

export class PluginVersion {
  @IsString()
  public version: string;

  @IsString()
  public created_at: string;
}

export class Plugin {
  @IsString()
  public id: string;

  @ValidateNested()
  @Type(() => PluginVersion)
  public versions?: PluginVersion[];
}
