import {autoinject} from 'aurelia-framework';
import {TemplateList} from '..';
import {Config, Model} from './models';
import {TemplateListTable} from './table';

@autoinject
export class TemplateListModalBody extends TemplateList {
    public config = new Config();
    public model: Model;
    public table: TemplateListTable;

    public activate(model: Model) {
        this.model = model;
        this.config = model.shared;
        this.load();
    }

    protected async load() {
        this.table = new TemplateListTable(row => this.router.navigate(row.id));
        this.table.selected = this.config.input.selection;
        await super.load();
    }
}
