import {CToastsService, SharedNav} from '@bindable-ui/bindable';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterEvent} from 'aurelia-router';

import {HyperionPolling} from 'services/hyperion-polling';

import {LibraryService} from './services/library';

const log = LogManager.getLogger('content-service');

@autoinject()
export class ContentIndex {
  public libraryName: string = '';
  public libraryNameFocus: boolean = false;
  public libraryError: string = '';
  public hasLibraryNameError: boolean = false;
  public router: Router;
  private routerEventComplete: Subscription;
  private routeNames = {
    allContentIndex: 'allContentIndex',
    library: 'library',
    playlists: 'playlists',
    single: 'single',
    uploads: 'uploads',
    graphics: 'graphics',
  };

  public routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: this.routeNames.allContentIndex,
      nav: true,
      route: '',
      title: 'My Content',
    },
    {
      moduleId: PLATFORM.moduleName('./playlists/index'),
      name: this.routeNames.playlists,
      nav: true,
      route: this.routeNames.playlists,
      title: 'Playlists',
    },
    {
      moduleId: PLATFORM.moduleName('./graphics/index'),
      name: this.routeNames.graphics,
      nav: true,
      route: this.routeNames.graphics,
      title: 'Graphics',
    },
    {
      moduleId: PLATFORM.moduleName('./uploads/index'),
      name: this.routeNames.uploads,
      nav: true,
      route: this.routeNames.uploads,
      title: 'VOD Uploader',
    },
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: this.routeNames.library,
      nav: false,
      route: 'library/:id',
      title: 'Library List',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: this.routeNames.single,
      nav: false,
      route: ':id',
      title: 'Single',
    },
  ];

  public createLibraryActions = {
    onHide: () => {
      if (this.library.createLibraryForm) {
        this.library.createLibraryForm.reset();
      }
      this.libraryName = '';
      this.libraryError = '';
      this.hasLibraryNameError = false;
    },
  };

  private pollTracker: HyperionPolling;

  constructor(
    public sharedNav: SharedNav,
    private library: LibraryService,
    private notification: CToastsService,
    protected eventAggregator: EventAggregator,
  ) {
    this.pollTracker = new HyperionPolling({
      includeDeleted: false,
      ms: 30 * 1000,
      // eslint-disable-next-line consistent-return
      promiseFn: paramsArg => {
        if (!document.hidden) {
          return this.library.pollLibraries(paramsArg);
        }
      },

      useAfter: true,
    });
  }

  /*
   * Aurelia Hooks
   */

  public async attached() {
    this.library.enableNav(true);
    this.sharedNav.initMainNav(this.router);
    this.routerEventComplete = this.eventAggregator.subscribe(
      RouterEvent.Complete,
      this.onRouterEventComplete.bind(this),
    );
    await this.init();
  }

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }

  public detached() {
    this.library.enableNav(false);
    this.pollTracker.stop();
    this.pollTracker = null;
    this.routerEventComplete.dispose();
  }

  /*
   * Public Methods
   */

  public async createLibrary() {
    try {
      this.libraryError = '';
      this.hasLibraryNameError = false;
      if (!this.libraryName.length) {
        this.libraryError = 'Name is required.';
        this.hasLibraryNameError = true;
        return;
      }

      await this.library.createLibrary(this.libraryName);

      this.libraryName = '';
      /* istanbul ignore if */
      if (this.library.createLibraryDialog) {
        this.library.createLibraryDialog.hide();
      }
    } catch (err) {
      this.libraryError = err.message;
      this.hasLibraryNameError = true;
      log.error(err);
    }
  }

  private async init() {
    try {
      await this.library.loadLibraries();
      this.loadLibraryNav();

      if (this.pollTracker) {
        this.pollTracker.start();
      }
    } catch (err) {
      this.notification.error(`A problem occurred while attempting to load your libraries: ${err.message}`);
    }
  }

  private loadLibraryNav() {
    this.library.loadLibraryNav();
  }

  private onRouterEventComplete() {
    const routeUploads = this.routes.find(route => route.name === this.routeNames.uploads);
    const navUploads = this.sharedNav.nav.pages[0].navs.find(nav => nav.title === routeUploads.title);
    if (this.router.currentInstruction.fragment === this.routeNames.uploads) {
      this.sharedNav.setActive(0, this.sharedNav.nav.pages[0].navs.indexOf(navUploads));
    }
  }
}
