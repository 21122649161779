import {autoinject, computedFrom} from 'aurelia-framework';

import * as moment from 'moment';

import {IFileUploadActions} from 'components/file-uploader';
import {File} from 'interfaces/file';
import {IKeyValue} from 'interfaces/key-value';
import {IOption} from 'interfaces/options';
import {API_SCOPE_OPTIONS, asOptions} from 'services/authorization/api-scopes';

import {IEnvParams} from '../../utils/key-download-utils';
import {IXAuthDetails, IXAuthHeaders, IXAuthPayload, asJsonString, XAuthUtils} from '../../utils/x-auth-utils';

import {DialogBase} from '../base';

export interface IActions {
  onClose: () => void;
}

export interface IJwtDebuggerModel {
  actions: IActions;
}

export interface IJwtClaim {
  enabled: boolean;
  value: any;
}

const SCOPE: IOption[] = asOptions(API_SCOPE_OPTIONS) as any;

function filterOptions(options: IOption[], scp: string[] = null): IOption[] {
  return scp.reduce((_options, scope) => {
    if (/:.*$/.test(scope)) {
      _options.push(options.find(o => o.value === scope));
      return _options;
    }

    return _options.concat(options.filter(o => o.value.includes(scope)));
  }, []);
}

@autoinject()
export class JwtDebugger extends DialogBase {
  public actions: IActions;

  public headers: string;
  public payload: string;

  public xAuthDetails: IXAuthDetails;

  public isDisabled: boolean = false;
  public privateKey: string = '';
  public privateKeyDecoded: string = '';
  public publicKey: string = '';
  public publicKeyDecoded: string = '';

  public token: string = null;

  public scpClaims: string[] = [];
  public scopes: IOption[] = [];

  public claims: IKeyValue<IJwtClaim> = {
    // iat: {enabled: true, value: null},
    // exp: {enabled: true, value: null},
    scp: {enabled: true, value: []},
    nbf: {enabled: false, value: null},
    net: {enabled: false, value: true},
  };

  public uploaderActions: IFileUploadActions;

  @computedFrom('parsedToken')
  public get headersString() {
    return asJsonString(this.parsedToken.headers);
  }

  @computedFrom('token')
  public get parsedToken() {
    try {
      return XAuthUtils.parseToken(this.token);
    } catch {
      return {headers: {}, payload: {}};
    }
  }

  @computedFrom('parsedToken')
  public get payloadString() {
    return asJsonString(this.parsedToken.payload);
  }

  /*
    Aurelia Hooks
  */

  public activate(model: IJwtDebuggerModel) {
    this.actions = model.actions;

    this.uploaderActions = {
      onFileUpload: async (file: File) => {
        try {
          await this.processFile(file);
        } catch (err) {
          this.token = null;
          throw err;
        }
      },
    };
  }

  /*
    Public Methods
  */

  public closeDialog(e?) {
    e?.preventDefault();

    if (e?.detail?.source === 'overlay') {
      return;
    }

    this.actions.onClose();
  }

  public readToken(e?, token: string = '') {
    e?.preventDefault();

    try {
      this.token = token;
      this.isDisabled = true;
      const {headers, payload} = XAuthUtils.parseToken(token);

      this.xAuthDetails = {
        headers,
        payload,
      };

      const claims = Object.keys(payload);

      claims.forEach(claim => {
        if (!(claim in this.claims)) {
          return;
        }

        const _claim = this.claims[claim];
        _claim.enabled = true;
        _claim.value = payload[claim];
      });

      this.setScope();
    } catch (err) {
      this.token = null;
      this.isDisabled = false;
    }
  }

  public toggleClaim(claim: string, enabled: boolean) {
    if (!(claim in this.claims) || claim === 'scp') {
      return;
    }

    this.claims[claim].enabled = enabled;

    this.updateToken();
  }

  public updateClaim(claim, value) {
    if (!(claim in this.claims) || claim === 'net') {
      return;
    }

    const _claim = this.claims[claim];

    _claim.value = value;

    if (_claim.enabled) {
      this.updateToken();
    }
  }

  /*
    Private Methods
  */

  private async processFile(file: File) {
    const txt = await file.text();
    const config: IEnvParams = txt.split('\n').reduce(
      (cfg, str) => {
        const _match = str.match(/^([A-Z]+(?:_B64)?)="(.*)"/);
        if (_match) {
          const [
            ,
            key,
            value,
          ] = _match;

          if (key.toLowerCase() === 'scp') {
            cfg[key] = (value || '').split(',').map(v => v.trim());
          } else {
            cfg[key] = value;
          }
        }
        return cfg;
      },
      {
        PRIVATE_B64: '',
        PUBLIC_B64: '',
        ALG: '',
        KID: '',
        SUB: '',
        SCP: [],
      },
    );

    this.privateKey = config.PRIVATE_B64;
    this.privateKeyDecoded = XAuthUtils.atob(config.PRIVATE_B64);
    this.publicKey = config.PUBLIC_B64;
    this.publicKeyDecoded = XAuthUtils.atob(config.PUBLIC_B64);

    const headers: IXAuthHeaders = {
      alg: config.ALG,
      kid: config.KID,
      typ: 'JWT',
    };

    const payload: IXAuthPayload = {
      sub: config.SUB,
      scp: config.SCP,
    };

    if (config.ISS) {
      payload.iss = config.ISS;
    }

    this.xAuthDetails = {
      headers,
      payload,
    };

    this.setScope();

    this.updateToken();
  }

  private setScope() {
    if (!this.xAuthDetails) {
      this.scopes = [];
      this.claims.scp.value = [];
      return;
    }

    const {
      payload: {scp = []},
    } = this.xAuthDetails;

    this.scopes = filterOptions(SCOPE, scp);
    this.claims.scp.value = this.scopes.map(o => o.value);
  }

  private updateToken() {
    try {
      const {headers, payload} = this.xAuthDetails;
      const _payload = _.cloneDeep(payload);

      const keys = Object.keys(this.claims);

      keys.forEach(claim => {
        const {enabled, value} = this.claims[claim];
        if (!enabled || !value) {
          return;
        }

        if (
          [
            'iat',
            'exp',
            'nbf',
          ].includes(claim)
        ) {
          _payload[claim] = Number(moment(value).format('X'));
        } else {
          _payload[claim] = value;
        }
      });

      this.token = XAuthUtils.generateToken(this.privateKey, headers, _payload);
    } catch (err) {
      this.token = `Invalid private key: ${err.message}`;
    }
  }
}
