import {DialogController} from 'aurelia-dialog';
import {autoinject} from 'aurelia-framework';
import {ValidateHelper} from 'resources/validate-helper';
import {TemplateDetails} from '.';
import {ModalModel} from './models';

@autoinject
export class TemplateDetailsModal extends TemplateDetails {
    protected controller: DialogController;

    public activate(params?: ModalModel) {
        this.config = params.shared;
        this.controller = params.controller;
        this.load();
    }

    public async actionCreate() {
        const request = this.transformRequestInput();
        const valid = await ValidateHelper.validate(request, this.config.errors);
        if (!valid) return;
        this.config.state.isCreating = true;
        try {
            await this.requestCreate(request);
            await this.templateMenu.load();
            this.notification.success('Template created successfully');
            this.controller.close(true);
        } catch (reason) {
            this.notification.error(reason.message);
        }
        this.config.state.isCreating = false;
    }
}
