import {IsNotEmpty} from 'class-validator';

import 'reflect-metadata';

export class StreamDomainData {
    @IsNotEmpty()
    public stream_domain_list: string[];

    @IsNotEmpty()
    public stream_domain_type: string;
}
