import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationError} from 'class-validator';
import {dirtyCheckPrompt} from 'decorators';
import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';
import {MultiCdnSettingsConfig, QosPreset, RuleSet} from './models/multi-cdn-settings-config';
import {MultiCdnSettingService} from './services/cdn-service';
import {MultiCdnUtil} from './services/cdn-util';

export const MULTI_CDN_SETTINGS_CONFIG_EVENT = {
    ConfigCreated: 'MultiCdnSettingsEvent:ConfigCreated',
    NewConfigSaved: 'MultiCdnSettingsEvent:NewConfigSaved',
};
const log = LogManager.getLogger('multi-cdn-setting');

@dirtyCheckPrompt
@autoinject()
export class MultiCdnSettings {
    public multiCdnSettings: MultiCdnSettingsConfig;
    public etag: string;
    public pristine: MultiCdnSettingsConfig;
    public canDeactivate;
    public isFormDirty = false;
    public isLoading = false;
    public loadingError = false;
    public isDeleting = false;
    public isSaving = false;
    public routeName;
    public advancedStreamRoutingFeaturesEnabled = false;
    public subscriptions: Subscription[] = [];
    public callbacks;
    public validationErrors: ValidationError[];
    public initialError = false;
    public tabs = {
        capacities: {hasError: false, ref: {}},
        profiles: {hasError: false, ref: {}},
        qos: {hasError: false, ref: {}},
        scenarios: {hasError: false, ref: {}},
    };

    public deleteTip;
    private errorSavingChangesMessage = 'Error saving changes.';
    private debouncedDirtyCheck = _.debounce(this.doDirtyCheck, 100);

    private rttErrorThreshold = 300;

    private availabilityErrorThreshold = 90;

    constructor(
        public eventAggregator: EventAggregator,
        public router: Router,
        public notification: Notification,
        public multiCdnSettingService: MultiCdnSettingService,
        public session: SessionService,
    ) {
        this.callbacks = {
            onBlur: () => {
                this.checkTabError();
            },
            onChange: () => {
                this.debouncedDirtyCheck();
            },
        };
        this.advancedStreamRoutingFeaturesEnabled = session.isAdvancedStreamRoutingFeaturesEnabled();
    }

    public checkTabError() {
        setTimeout(() => {
            Object.keys(this.tabs).forEach(name => {
                if (this.tabs[name] && this.tabs[name].ref && this.tabs[name].ref.querySelector) {
                    this.tabs[name].hasError = !!this.tabs[name].ref.querySelector(
                        '.form-error > .form-error__message',
                    );
                }
            });
        }, 0);
    }

    public activate({}, routeConfig) {
        this.routeName = routeConfig.name || '';
    }

    public attached(): Promise<any> {
        // only fetch once, not refetching between tab switches
        if (this.multiCdnSettings) return Promise.resolve();

        this.loadingError = false;
        return new Promise((resolve, reject) => {
            this.isLoading = true;
            this.multiCdnSettingService
                .getSettings()
                .then(resp => {
                    // there is an error
                    if (resp.errorMessage && resp.errorMessage.length > 0) {
                        this.initialError = true;
                        this.notification.error(resp.errorMessage, 'error', 30000);
                        this.loadCdnConfiguration(resp.multiCdnSettingsConfig);
                    } else {
                        this.loadCdnConfiguration(resp.multiCdnSettingsConfig);
                        this.createDefaultRuleSet(); // this should be here since we want pristine to not have it
                        this.createDefaultQoSPreset();
                        this.eventAggregator.publish(MULTI_CDN_SETTINGS_CONFIG_EVENT.ConfigCreated);
                    }
                    resolve();
                })
                .catch(e => {
                    this.loadingError = true;
                    this.initialError = true;
                    this.loadCdnConfiguration(new MultiCdnSettingsConfig());
                    this.notification.error(e.toString(), 'error', 30000);
                    reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    // in multi cdn we only want to prompt "discard changes" when leaving the whole screen to moving between inner tabs
    public isDirty(): boolean {
        if (window.location.hash.match(/^#\/settings\/multi-cdn-settings(\/[a-zA-Z0-9-_]*\/?)?$/)) {
            return false;
        }
        if (!this.multiCdnSettings) return false;
        return this.doDirtyCheck();
    }

    public doDirtyCheck(): boolean {
        const currentCdnSettingsCleaned = _.cloneDeep(this.multiCdnSettings);
        const pristineCdnSettingsleaned = _.cloneDeep(this.pristine);

        this.deleteDefaults(currentCdnSettingsCleaned);
        this.deleteDefaults(pristineCdnSettingsleaned);

        // dont compare cdn lists
        if (currentCdnSettingsCleaned.defaultcdns) {
            delete currentCdnSettingsCleaned.defaultcdns;
        }
        if (pristineCdnSettingsleaned.defaultcdns) {
            delete pristineCdnSettingsleaned.defaultcdns;
        }

        // dont compare cdn lists
        if (currentCdnSettingsCleaned.availablecdns) {
            delete currentCdnSettingsCleaned.availablecdns;
        }
        if (pristineCdnSettingsleaned.availablecdns) {
            delete pristineCdnSettingsleaned.availablecdns;
        }

        this.isFormDirty = !_.isEqual(currentCdnSettingsCleaned, pristineCdnSettingsleaned);
        return this.isFormDirty;
    }

    public save() {
        return new Promise((resolve, reject) => {
            MultiCdnUtil.validate(this.multiCdnSettings)
                .then(errs => {
                    if (errs.length) {
                        this.validationErrors = errs;
                        this.notification.error('Please fix form error(s) and try again.');
                        this.checkTabError();
                        return resolve();
                    }

                    // save the integration data first
                    this.isSaving = true;

                    // Save the rest of the information
                    this.multiCdnSettingService
                        .saveConfiguration(this.multiCdnSettings)
                        .then(resp => {
                            if (resp.errorMessage && resp.errorMessage.length > 0) {
                                this.notification.error(resp.errorMessage, 'error', 30000);
                            } else {
                                this.eventAggregator.publish(MULTI_CDN_SETTINGS_CONFIG_EVENT.NewConfigSaved);

                                this.pristine = _.cloneDeep(resp.multiCdnSettingsConfig);
                                //  this.setCurrentConfig(config);
                                this.notification.success('Changes saved successfully.');
                            }
                            resolve();
                        })
                        .catch(e => {
                            this.notification.error(e.message || this.errorSavingChangesMessage);
                            reject(e);
                        })
                        .finally(() => {
                            this.doDirtyCheck();
                            this.isSaving = false;
                        });
                })
                .catch(err => {
                    reject(err);
                    log.error(`Validation Error: ${err}`);
                });
        });
    }

    public detached() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    public loadCdnConfiguration(multiCdnConfig) {
        // const multiCdnConfig = this.createCurrentMultiCdnSettings(accountConfiguration);
        this.multiCdnSettings = _.cloneDeep(multiCdnConfig);
        this.pristine = _.cloneDeep(multiCdnConfig);
    }

    public setCurrentConfig(config: MultiCdnSettingsConfig) {
        return new Promise((resolve, reject) => {
            if (!config) {
                this.multiCdnSettings = null;
                this.pristine = null;
                return resolve();
            }

            this.multiCdnSettings = _.cloneDeep(config);
            this.validationErrors = null;
            MultiCdnUtil.validate(this.multiCdnSettings)
                .then(errs => {
                    if (errs.length) {
                        this.validationErrors = errs;
                    }
                    // check for validtion errors even if errs is [], there might be server errors.
                    this.checkTabError();
                    resolve();
                })
                .catch(err => {
                    log.error(`Validation Error: ${err}`);
                    reject(err);
                });
        });
    }

    private createDefaultRuleSet() {
        const defaultRuleSet = this.multiCdnSettings.ruleSets.filter(
            ruleSet => ruleSet.name === MultiCdnUtil.defaultRuleSetName,
        );

        if (!defaultRuleSet || defaultRuleSet.length === 0) {
            // create the default rule set
            const scenario = new RuleSet();
            scenario.name = MultiCdnUtil.defaultRuleSetName;
            this.multiCdnSettings.ruleSets.push(scenario);
        }
    }

    private createDefaultQoSPreset() {
        const defaultPreset = this.multiCdnSettings.qoSCdnSelectionPresets.filter(
            preset => preset.name === MultiCdnUtil.defaultPresetName,
        );

        if (!defaultPreset || defaultPreset.length === 0) {
            // create the default preset
            const preset = new QosPreset();
            preset.name = MultiCdnUtil.defaultPresetName;
            preset.rttErrorThreshold = this.rttErrorThreshold;
            preset.availabilityErrorThreshold = this.availabilityErrorThreshold;
            this.multiCdnSettings.qoSCdnSelectionPresets.push(preset);
        }
    }

    private deleteDefaults(currentCdnSettingsCleaned: MultiCdnSettingsConfig) {
        const profilesToKeep = [];
        if (currentCdnSettingsCleaned.profiles && currentCdnSettingsCleaned.profiles.length > 0) {
            currentCdnSettingsCleaned.profiles.forEach(profile => {
                if (!profile.isDefault) {
                    profilesToKeep.push(profile);
                }
            });

            currentCdnSettingsCleaned.profiles = profilesToKeep;
        }
    }
}
