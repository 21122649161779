import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {RouteConfig, Router, RouterConfiguration} from 'aurelia-router';

@autoinject()
export class PublishingSettings {
  public isLoading: boolean;
  public routes: RouteConfig[];
  constructor(public router: Router) {
    this.router = router;

    this.routes = [
      {
        redirect: 'targets',
        route: '',
      },
      {
        moduleId: PLATFORM.moduleName('./targets/index'),
        name: 'targets',
        nav: true,
        route: 'targets',
        title: 'Targets',
      },
      {
        moduleId: PLATFORM.moduleName('./jobs/index'),
        name: 'jobs',
        nav: true,
        route: 'jobs',
        title: 'Jobs',
      },
      {
        moduleId: PLATFORM.moduleName('./configuration/index'),
        name: 'configuration',
        nav: true,
        route: 'configuration',
        title: 'SNS',
      },
    ];
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }
}
