import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {AnalyticsService} from '../../services/analytics';
import {Dashboard} from '../../models/model';
import {toolTips} from '../tool-tips';

@autoinject()
export class DashboardList {
  constructor(public router: Router, public analytics: AnalyticsService) {}

  public async attached() {
    window.addEventListener('dragstart', this.dragStartHandler);
    window.addEventListener('dragenter', this.dragEnter);
    window.addEventListener('dragover', this.dragOver);
    window.addEventListener('dragleave', this.dragLeave);
    window.addEventListener('drop', this.drop);
    this.analytics.isLoading = true;
    await this.analytics.getSystemAnalytics();
    this.analytics.isLoading = false;
  }

  public detached() {
    window.removeEventListener('dragstart', this.dragStartHandler);
    window.removeEventListener('dragenter', this.dragEnter);
    window.removeEventListener('dragover', this.dragOver);
    window.removeEventListener('dragleave', this.dragLeave);
    window.removeEventListener('drop', this.drop);
  }

  public dragTarget: string = '';
  public draggedItem: Dashboard = null;
  public queryParams: any;
  public toolTips = toolTips;

  public dragStartHandler = event => {
    event.dataTransfer.effectAllowed = 'move';
    const id = event.target.id.split('|')[1];
    this.draggedItem = this.analytics.currentModel.dashboards.find(d => d.id === id);
  };

  public dragEnter = event => {
    event.preventDefault();
    this.dragTarget = event.target.offsetParent.id.split('|')[1];
    const targetElement = document.getElementById(event.target.offsetParent.id);
    if (targetElement) {
      targetElement.setAttribute('active', 'true');
      targetElement.style.border = '2px dashed gray';
      const children = targetElement.querySelectorAll('td');
      children.forEach(child => {
        child.setAttribute('state', 'neutral');
        child.setAttribute('barberpole', 'true');
      });
    }
  };

  public dragOver = event => {
    event.preventDefault();
    const targetElement = document.getElementById(event.target.offsetParent.id);
    if (targetElement) {
      targetElement.setAttribute('active', 'true');
    }
  };

  public dragLeave = event => {
    event.preventDefault();
    const targetElement = document.getElementById(event.target.offsetParent.id);
    if (targetElement) {
      targetElement.removeAttribute('active');
    }
  };

  public drop = event => {
    event.stopPropagation();
    event.preventDefault();
    const dashboards =
      this.draggedItem.view === 'user' ? this.analytics.userDashboards : this.analytics.adminDashboards;
    const draggedIndex = dashboards.findIndex(d => d.guid === this.draggedItem.guid);
    const targetId = event.target.offsetParent.id.split('|')[1];
    const targetItem = dashboards.find(d => d.id === targetId);
    const targetIndex = dashboards.findIndex(d => d.guid === targetItem.guid);
    if (draggedIndex !== targetIndex) {
      if (this.draggedItem.view === 'user') {
        this.analytics.userDashboards.splice(draggedIndex, 1);
        this.analytics.userDashboards.splice(targetIndex, 0, this.draggedItem);
      } else {
        this.analytics.adminDashboards.splice(draggedIndex, 1);
        this.analytics.adminDashboards.splice(targetIndex, 0, this.draggedItem);
      }
    }
    const dragTarget = document.getElementById(`db|${this.dragTarget}`);
    dragTarget.removeAttribute('active');
    this.analytics.didReorderChangeList();
  };

  public routeToSingle(row) {
    this.router.navigate(`/cms-analytics/dashboards/${row.id}`);
  }
}
