import {PLATFORM} from 'aurelia-pal';
import {activationStrategy} from 'aurelia-router';

export class PrebidConfigRoute {
    public routes;

    constructor() {
        this.routes = [
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'details',
                nav: true,
                route: '',
                title: 'Details',
            },
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'optionalParameters',
                nav: true,
                route: 'optional-parameters',
                title: 'Optional Parameters',
            },
        ];
    }

    public configureRouter(config) {
        config.map(this.routes);
    }
}
