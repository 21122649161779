import {Status} from '../models/ad-server-debug-response';

export class StatusLabelValueConverter {
    private mapping = {
        [Status.Fail]: 'Failed',
        [Status.Complete]: 'Completed',
        [Status.Pending]: Status.Pending,
        [Status.Processing]: Status.Processing,
    };

    public toView(status: Status) {
        return _.capitalize(this.mapping[status] || status);
    }
}
