import {PLATFORM} from 'aurelia-pal';
import {activationStrategy} from 'aurelia-router';

export class ClippingRoute {
    public routes;

    constructor() {
        this.routes = [
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'details',
                nav: true,
                route: '',
                title: 'Details',
            },
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'metadata',
                nav: true,
                route: 'metadata',
                title: 'Metadata',
            },
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'integrations',
                nav: true,
                route: 'integrations',
                title: 'Integrations',
            },
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'overlays',
                nav: true,
                route: 'overlays',
                title: 'Overlays',
            },
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'bumpers',
                nav: true,
                route: 'bumpers',
                title: 'Bumpers',
            },
        ];
    }

    public configureRouter(config) {
        config.map(this.routes);
    }
}
