import {IsOptional, IsString} from 'class-validator';

export class OwnerDefaultProfiles {
    @IsOptional()
    @IsString()
    public default_live_profile?: string;

    @IsOptional()
    @IsString()
    public default_profile?: string;
}
