/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* istanbul ignore file */
import {Exclude, Type} from 'class-transformer';
import {
    IsArray,
    IsBoolean,
    IsDefined,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    ValidateNested,
} from 'class-validator';
import {BreakOffset} from '../models/asset-model';

const MESSAGE_REQUIRED = 'This is required.';

export interface IPlaylist {
    desc: string;
    id: string;
    repeat: number;
    active: number;
    beam_break_duration: number;
    ad_slate_fill: number;
    playlist: any[];
    total_duration: number;
    skip_drm: number;
    studio_drm_required: number;
    created: string;
    '@included'?: any[];
}

export class Playlist implements IPlaylist {
    @IsDefined({message: MESSAGE_REQUIRED})
    @IsNotEmpty({message: MESSAGE_REQUIRED})
    @IsString()
    public desc: string = '';

    @IsString()
    public id: string = '';

    @IsNumber()
    public repeat: number = -1;

    @IsNumber()
    public active: number = 1;

    @IsNumber()
    public beam_break_duration: number = 0;

    @IsNumber()
    public ad_slate_fill: number = 1;

    @IsArray()
    public playlist: any[] = [];

    @IsNumber()
    public total_duration: number = 0;

    @IsNumber()
    public skip_drm: number = 0;

    @IsNumber()
    public studio_drm_required: number = 0;

    @IsString()
    public created: string = '';

    @IsString()
    @IsOptional()
    public poster_url?: string;

    @IsString()
    @IsOptional()
    public external_id?: string;

    @IsString()
    @IsOptional()
    public dash_url?: string;

    @IsNumber()
    @IsOptional()
    public live_delay?: number;

    @IsString()
    @IsOptional()
    public embed_html5_player_url?: string;

    @IsString()
    @IsOptional()
    public hls_url?: string;

    @IsString()
    @IsOptional()
    public deleted?: string;

    @ValidateNested()
    @IsOptional()
    @Type(() => TestPlayer)
    public test_players?: TestPlayer[];

    @IsString()
    @IsOptional()
    public embed_domains?: string;

    @ValidateNested()
    @Type(() => Asset)
    @Exclude({toPlainOnly: true})
    public '@included'?: any[];

    // runtime fields, need not be serialized
    public checkbox: boolean = false;

    // generated fields
    public entries: number = 0;
}

export class TestPlayer {
    @IsString()
    @IsOptional()
    public url?: string;

    @IsString()
    @IsOptional()
    public url_html5?: string;

    @IsString()
    @IsOptional()
    public id?: string;

    @IsNumber()
    @IsOptional()
    public expire?: number;

    @IsString()
    @IsOptional()
    public desc?: string;

    @IsString()
    @IsOptional()
    public params?: string;

    @IsBoolean()
    @IsOptional()
    public whitelist?: boolean = false;

    @IsBoolean()
    @IsOptional()
    public checkbox?: boolean = false;

    @IsString()
    @IsOptional()
    public view?: string = 'View';
}

export class PlaylistListResponse {
    @ValidateNested()
    @Type(() => Playlist)
    public items?: Playlist[] = [];

    @IsNumber()
    @IsOptional()
    public now?: number;

    @IsNumber()
    public total_items?: number;
}

export interface IAsset {
    break_offsets?: object[];
    created?: string;
    duration: number;
    id: string;
    poster_url?: string;
    test_players?: object[];
    title: string;
}

export class Asset implements IAsset {
    @IsArray() public break_offsets?: BreakOffset[];
    @IsString() public created?: string;
    @IsNumber() public duration: number;
    @IsString() public id: string;
    @IsString() public poster_url?: string;
    @IsArray() public test_players?: object[];
    @IsString() public title: string;
}

export enum AssetType {
    AD = 'ad',
    BEAM = 'beam',
}
