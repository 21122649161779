import * as moment from 'moment';

import {IFieldValidations} from 'apps/cms/utils/form-validator';
import {FieldValidation} from 'apps/cms/utils/field-validation';

import {IWorkspaceKeyParams} from './workspace-key';

export const KeyFieldValidators: IFieldValidations = {
  desc: [
    new FieldValidation({
      length: {max: 1048},
    }),
  ],
  title: [
    new FieldValidation({
      presence: true,
      length: {max: 120},
    }),
  ],
};

export const RevisionFieldValidators: IFieldValidations = {
  domain: [
    new FieldValidation({
      enabled: (model: IWorkspaceKeyParams) => model.domain && model.domain.length > 0,
      format: /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/,
      length: {max: 256},
    }),
  ],
  invalid_after: [
    new FieldValidation({
      enabled: (model: IWorkspaceKeyParams) => model.invalid_after?.length > 0,
      customValidator: value => moment(value).isAfter(moment()),
      message: 'Cannot be in the past',
    }),
  ],
  scope: [
    new FieldValidation({
      length: {min: 1},
      message: 'Must have at least one scope selected',
    }),
  ],
};
