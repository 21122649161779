import {Subscription} from 'aurelia-event-aggregator';
import {BindingEngine, autoinject} from 'aurelia-framework';

import {HlsjsPlayer} from 'resources/components/hlsjs-player/index';
import {CmsHttpClient} from 'services/cms-http-client';
import {App} from '../app';

@autoinject
export class PlayerSource {
  public videoPlayer: HlsjsPlayer;
  public videoSrc: string = ''; // 'https://content.uplynk.com/d937abd1b9da44b6b74d5995b2371851.m3u8';

  private subscription: Subscription;

  constructor(public app: App, private bindingEngine: BindingEngine, private cmsHttpClient: CmsHttpClient) {}

  attached() {
    this.subscription = this.bindingEngine.propertyObserver(this.app, 'source_model').subscribe(() => {
      this.load();
    });
    this.load();
    this.app.sourcePlayer = this.videoPlayer;
  }

  detached() {
    this.subscription.dispose();
  }

  async load() {
    if (!this.app.source_model) {
      return;
    }

    const url = `/clip/player/url?beamId=${this.app.source_model.id}`;

    const response = await this.cmsHttpClient.httpClient.fetch(url);
    const data = await response.json();

    this.videoSrc = data.url;
  }
}
