import {Expose, Type} from 'class-transformer';
import {IsNotEmpty, IsOptional, IsString, IsUrl, ValidateIf, ValidateNested} from 'class-validator';
import {LibraryAsset} from 'services/models/library';

export class DefaultSlates {
    @IsNotEmpty()
    @ValidateNested()
    @Type(() => LibraryAsset)
    public ad_slate: LibraryAsset;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => LibraryAsset)
    public blackout_slate: LibraryAsset;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => LibraryAsset)
    public default_slate: LibraryAsset;

    @IsOptional()
    @ValidateNested()
    @Type(() => LibraryAsset)
    public mid_slate: LibraryAsset;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => LibraryAsset)
    public missing_content_slate: LibraryAsset;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => LibraryAsset)
    public post_slate: LibraryAsset;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => LibraryAsset)
    public pre_slate: LibraryAsset;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => LibraryAsset)
    public soon_slate: LibraryAsset;
}

export class SlateSettings {
    @IsString()
    public ad_slate_asset: string;

    @IsString()
    public ad_slate_desc: string;

    @IsString()
    public ad_slate_library: string;

    @IsOptional()
    @IsString()
    public ad_slate_msg: string;

    @ValidateIf(o => !!o.ad_slate_poster_url)
    @IsUrl()
    @Expose({toClassOnly: true})
    public ad_slate_poster_url: string;

    @IsString()
    public blackout_slate_asset: string;

    @IsString()
    public blackout_slate_desc: string;

    @IsString()
    public blackout_slate_library: string;

    @IsOptional()
    @IsString()
    public blackout_slate_msg: string;

    @ValidateIf(o => !!o.blackout_slate_poster_url)
    @IsUrl()
    @Expose({toClassOnly: true})
    public blackout_slate_poster_url: string;

    @IsString()
    public default_slate_desc: string;

    @IsString()
    public default_slate_asset: string;

    @IsOptional()
    @IsString()
    public default_slate_asset_resolved: string;

    @IsString()
    public default_slate_library: string;

    @IsOptional()
    public default_slate_msg: string;

    @ValidateIf(o => !!o.default_slate_poster_url)
    @IsUrl()
    @Expose({toClassOnly: true})
    public default_slate_poster_url: string;

    @IsString()
    public post_slate_asset: string;

    @IsString()
    public post_slate_desc: string;

    @IsString()
    public post_slate_library: string;

    @IsOptional()
    @IsString()
    public post_slate_msg: string;

    @ValidateIf(o => !!o.post_slate_poster_url)
    @IsUrl()
    @Expose({toClassOnly: true})
    public post_slate_poster_url: string;

    @IsString()
    public pre_slate_asset: string;

    @IsString()
    public pre_slate_desc: string;

    @IsString()
    public pre_slate_library: string;

    @IsOptional()
    @IsString()
    public pre_slate_msg: string;

    @ValidateIf(o => !!o.pre_slate_poster_url)
    @IsUrl()
    @Expose({toClassOnly: true})
    public pre_slate_poster_url: string;

    @IsString()
    public mid_slate_library: string;

    @IsString()
    public missing_content_slate_asset: string;

    @IsString()
    public missing_content_slate_desc: string;

    @IsString()
    public missing_content_slate_library: string;

    @IsOptional()
    public missing_content_slate_msg: string;

    @ValidateIf(o => !!o.missing_content_slate_poster_url)
    @IsUrl()
    @Expose({toClassOnly: true})
    public missing_content_slate_poster_url: string;

    @IsString()
    public soon_slate_asset: string;

    @IsString()
    public soon_slate_desc: string;

    @IsString()
    public soon_slate_msg: string;

    @ValidateIf(o => !!o.soon_slate_poster_url)
    @IsUrl()
    @Expose({toClassOnly: true})
    public soon_slate_poster_url: string;
}

export class SlateSettingsWrapper {
    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DefaultSlates)
    public default_slates: DefaultSlates;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => SlateSettings)
    public slate_settings: SlateSettings;
}
