import {autoinject, bindable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Toast} from 'resources/toast/toast';
import {HyperionPolling} from 'services/hyperion-polling';
import {SyndicationJob} from 'services/models/syndication-job';
import {SyndicationJobService} from 'services/syndication-job-service';
import {SyndicationScheduleService} from 'services/syndication-schedule-service';
import {TableColumn, TableSortingTools} from 'utils/table-sorting-tools';
import {LiveChannelsService} from 'apps/cms/routes/live-channels/channels/services/live-channels';
import {LiveEventsService} from 'apps/cms/routes/live-events/live-events/services/live-events';
import {SyndicationTargetService} from 'services/syndication-target-service';
import {SyndicationRegion, SyndicationSchedule, SyndicationScheduleContentType} from 'services/models/syndication';

export const CHANNELS_HREF: string = '#/live-channels/channels';
export const EVENTS_HREF: string = '#/live-events/events';
const STATUS_REFRESH_INTERVAL = 8000;

@autoinject()
export class SyndicationJobsList {
  public sourceTypes = [
    {label: 'Channel', value: 'assetType_channel', checked: false},
    {label: 'Live Event', value: 'assetType_liveEvent', checked: false},
  ];

  public filteredSourceType: string = 'assetType_all';

  public statusTypes = [
    {label: 'Active', value: 'statusType_active', checked: false},
    {label: 'Scheduled', value: 'statusType_scheduled', checked: false},
    {label: 'Stopped', value: 'statusType_stopped', checked: false},
    {label: 'Error', value: 'statusType_error', checked: false},
  ];

  public filteredStatusType = {
    statusType_active: false,
    statusType_error: false,
    statusType_scheduled: false,
    statusType_stopped: false,
  };

  public pageLayoutElem: any;
  public isStatusFiltered: boolean = false;
  public isSearchFiltered: boolean = false;

  public jobs: SyndicationJob[] = [];
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public isDeleting: boolean = false;
  public loadingError: boolean = false;
  public intervalPaused: boolean = false;
  public pollTracker: HyperionPolling;
  public syndRegions: SyndicationRegion[] = [];

  public searchText: string = '';
  public searchInput: any;
  public hiddenColumns: any[] = [];
  public selectedJobs: any[] = [];
  public currentAssetSort: string = '';

  public createJobDialog: any;
  public isCreating: boolean = false;

  private SAFE_TO_DELETE: string[] = [
    'stopped',
    'error',
    'scheduled',
  ];

  @bindable
  public selectedSource: any = null;

  @bindable
  public sourceType: string = 'channel';

  @bindable
  public sourceSearchText: string = '';

  public filteredSourceList: any[] = [];
  public isLoadingSourceList: boolean = false;

  @bindable
  public selectedTargets: any[] = [];

  @bindable
  public targetSearchText: string = '';

  public filteredTargetList: any[] = [];
  public isLoadingTargetList: boolean = false;

  public tableColumns: TableColumn[] = [
    new TableColumn('Source', 'content_description', {isHideable: false}),
    new TableColumn('Target', 'description', {isHideable: false}),
    new TableColumn('Status', 'status', {isSortable: true, isHideable: false}),
    new TableColumn('Platform', 'platform'),
    new TableColumn('Protocol', 'target_protocol'),
    new TableColumn('Region', 'region'),
    new TableColumn('Last Start', 'start', {isSortable: true, isVisible: false}),
    new TableColumn('Last Stop', 'stop', {isSortable: true, isVisible: false}),
    new TableColumn('ID', 'id'),
  ];

  constructor(
    public syndicationJobService: SyndicationJobService,
    public syndicationTargetService: SyndicationTargetService,
    public syndicationScheduleService: SyndicationScheduleService,
    public liveChannels: LiveChannelsService,
    public liveEvents: LiveEventsService,
    public router: Router,
  ) {
    this.router = router;

    this.pollTracker = new HyperionPolling({
      callbackFn: () => {
        this.jobsTableFormat();
      },
      ms: STATUS_REFRESH_INTERVAL,
      promiseFn: () => this.getVisibleJobs(),
      useAfter: true,
    });
  }

  public activate() {
    this.syndicationJobService.updateParams({page: 1});
    this.isLoading = true;
    this.loadJobs(true);
  }

  public async loadJobs(overrideJobs = false) {
    try {
      const ans = await this.syndicationJobService.getSyndicationJobs(
        this.filteredSourceType,
        this.filteredStatusType,
        overrideJobs,
        this.currentAssetSort,
      );
      if (ans) {
        this.jobsTableFormat();
      }
    } catch (error) {
      this.loadingError = true;
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.pollTracker.start();
    }
  }

  public pauseInterval() {
    this.intervalPaused = true;
  }

  public resumeInterval() {
    this.intervalPaused = false;
  }

  public async sortEvents(key: string, defaultSortDirection: string = null) {
    const currentOrder = this.currentAssetSort || '';
    const newOrder = TableSortingTools.sort(key, currentOrder, defaultSortDirection);

    this.currentAssetSort = newOrder;
    this.syndicationJobService.updateParams({page: 1});
    await this.loadJobs(true);
  }

  public async getVisibleJobs() {
    if (this.intervalPaused) {
      return;
    }
    let scrollElem: Element | null = null;
    let visibleJobs: string[] = [];

    if (this.pageLayoutElem) {
      scrollElem = this.pageLayoutElem.shadowRoot.querySelector('.lynk-page-layout__main');
    }
    const jobItemElems = document.getElementsByClassName('job-row');

    // Make sure we have something
    if (!scrollElem || !jobItemElems.length) {
      return;
    }

    const scrollElemRect = scrollElem.getBoundingClientRect();
    Array.from(jobItemElems).forEach(elem => {
      const elemRect = (elem as HTMLElement).getBoundingClientRect();

      if (!(elemRect.top > scrollElemRect.bottom || elemRect.bottom < scrollElemRect.top)) {
        visibleJobs.push((elem as HTMLElement).id);
      }
    });

    // Remove anything that isn't in the current groups list
    _.remove(visibleJobs, jobId => !_.find(this.jobs, {id: jobId}));

    // Make sure values are unique
    visibleJobs = _.uniq(visibleJobs);

    await this.syndicationJobService.getSyndicationJobsUpdates(visibleJobs);
  }

  public jobsTableFormat() {
    this.jobs = _.cloneDeep(this.syndicationJobService.syndicationJobs);

    this.jobs.forEach(job => {
      job.selected = !!this.selectedJobs.find(id => job.id === id);

      if (job.start && job.start !== '0') {
        job.start = new Date(parseInt(job.start, 10)).toLocaleString();
      } else {
        job.start = '';
      }
      if (job.stop && job.stop !== '0') {
        job.stop = new Date(parseInt(job.stop, 10)).toLocaleString();
      } else {
        job.stop = '';
      }

      if (job.status === 'active' || job.status === 'scheduled') {
        job.stop = '';
      }

      if (job.content_type === 'c') {
        job.content_type = 'Channel';
      } else if (job.content_type === 'e') {
        job.content_type = 'Live Event';
      }
    });
  }

  public async filterJobs(item) {
    if (this.sourceTypes.some(st => st.value === item.value)) {
      this.updateFilteredSourceType(item.value, item.checked);
    } else if (this.statusTypes.some(st => st.value === item.value)) {
      this.updateFilteredStatusType(item.value, item.checked);
    } else {
      // console.error('Item does not belong to sourceTypes or statusTypes arrays');
    }
    this.syndicationJobService.updateParams({page: 1});
    await this.loadJobs(true);
  }

  private updateFilteredSourceType(value: string, checked: boolean): void {
    // Check if the item belongs to the sourceTypes array
    const sourceTypeItem = this.sourceTypes.find(st => st.value === value);

    if (!sourceTypeItem) {
      return;
    }

    // Update the checked value of the matching object in the sourceTypes array
    sourceTypeItem.checked = checked;

    // Count the number of checked items
    const checkedCount = this.sourceTypes.filter(st => st.checked).length;

    // Get the value of the last checked item
    const lastCheckedValue = this.sourceTypes.find(st => st.checked)?.value || '';

    // Determine the filteredSourceType
    if (checkedCount === this.sourceTypes.length) {
      this.filteredSourceType = 'assetType_all';
    } else if (checkedCount === 1) {
      this.filteredSourceType = lastCheckedValue;
    } else {
      this.filteredSourceType = 'assetType_all';
    }
  }

  private updateFilteredStatusType(value: string, checked: boolean): void {
    const statusTypeItem = this.statusTypes.find(st => st.value === value);

    if (!statusTypeItem) {
      return;
    }

    statusTypeItem.checked = checked;

    // Update filteredStatusType to reflect the current state of statusTypes
    this.filteredStatusType = {
      statusType_active: this.statusTypes.find(st => st.value === 'statusType_active')?.checked || false,
      statusType_error: this.statusTypes.find(st => st.value === 'statusType_error')?.checked || false,
      statusType_scheduled: this.statusTypes.find(st => st.value === 'statusType_scheduled')?.checked || false,
      statusType_stopped: this.statusTypes.find(st => st.value === 'statusType_stopped')?.checked || false,
    };

    // Update isStatusFiltered to true if any items are unchecked
    this.isStatusFiltered = Object.values(this.filteredStatusType).some(checked => !checked);
  }

  public async clearFilter(value) {
    if (this.sourceTypes.some(st => st.value === value)) {
      this.updateFilteredSourceType(value, false);
    } else if (this.statusTypes.some(st => st.value === value)) {
      this.updateFilteredStatusType(value, false);
    } else {
      // console.error('Item does not belong to sourceTypes or statusTypes arrays');
    }
    this.syndicationJobService.updateParams({page: 1});
    await this.loadJobs(true);
  }

  public sourceClickHandler(job, event) {
    event.preventDefault();
    event.stopPropagation();
    this.isLoading = true;
    if (job.content_type === 'Channel') {
      this.router.navigate(`${CHANNELS_HREF}/${job.content_id}/publish`);
    } else if (job.content_type === 'Live Event') {
      this.router.navigate(`${EVENTS_HREF}/${job.content_id}/publish`);
    }
  }

  public detached() {
    this.pollTracker.stop();
    this.syndicationJobService.resetSearch();
  }

  public findColumnByKey(key: string): TableColumn | undefined {
    return this.tableColumns.find(column => column.key === key);
  }

  public showHideColumn(col) {
    col.isVisible = !col.isVisible;

    if (col.isVisible) {
      const idx = this.hiddenColumns.indexOf(col.key);
      if (idx !== -1) {
        this.hiddenColumns.splice(idx, 1);
      }
    } else {
      this.hiddenColumns.push(col.key);
    }
  }

  /**
   * Route to the single for the job that was clicked.
   */
  public goToSingle(id) {
    this.router.navigate(`/syndication/jobs/${id}`);
  }

  public toggleJobSelected(job) {
    job.selected = !job.selected;
    this.trackSelectedEvent(job);
  }

  /**
   * Track an job as it's toggled for deletion or duplication
   */
  public trackSelectedEvent(job) {
    if (job.selected) {
      this.selectedJobs.push(job.id);
    } else {
      _.remove(this.selectedJobs, jobId => jobId === job.id);
    }

    this.selectedJobs = _.uniq(this.selectedJobs);
  }

  /**
   * Toggle all visible jobs for deletion or duplication
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.jobs, job => {
      if (isSelected) {
        job.selected = true;
      } else {
        job.selected = false;
      }
    });

    this.selectedJobs = _.map(
      _.filter(this.jobs, job => job.selected),
      job => job.id,
    );
  }

  public async onCreateJobDialogInit() {
    this.isLoadingSourceList = true;
    this.getTargets();

    if (window.localStorage.getItem('defaultSyndicationSourceType')) {
      this.sourceType = window.localStorage.getItem('defaultSyndicationSourceType');
    }

    if (this.sourceType === 'channel') {
      await this.getChannels();
    }

    if (!this.liveChannels.channels.length || this.sourceType === 'event') {
      this.sourceType = 'event'; // Change to 'event' if no channels
      await this.getEvents();
    }

    this.updateFilteredSourceList();
    this.isLoadingSourceList = false; // Reset loading state
  }

  public resetCreateJobDialog() {
    this.selectedSource = null;
    this.selectedTargets = [];
  }

  public async updateSourceListData() {
    try {
      if (this.sourceType === 'channel') {
        this.isLoadingSourceList = true;
        await this.getChannels();
        this.updateFilteredSourceList();
        this.isLoadingSourceList = false;
      } else if (this.sourceType === 'event') {
        this.isLoadingSourceList = true;
        await this.getEvents();
        this.updateFilteredSourceList();
        this.isLoadingSourceList = false;
      }
    } catch (e) {
      // Handle error notification
      this.isLoadingSourceList = false; // Reset loading state in case of error
    }
  }

  private async getChannels() {
    try {
      await this.liveChannels.search(this.sourceSearchText);
    } catch (e) {
      // Handle error notification
    }
  }

  private async getEvents() {
    try {
      await this.liveEvents.getLiveEvents({search: this.sourceSearchText});
    } catch (e) {
      // Handle error notification
    }
  }

  private async getTargets() {
    this.isLoadingTargetList = true;
    try {
      await this.syndicationTargetService.getSyndicationTargets();
      this.filteredTargetList = _.differenceWith(
        this.syndicationTargetService.syndicationTargets,
        this.jobs,
        (target, job) => target.id === job.syndication_target_id,
      );
    } catch (e) {
      // Handle error notification
    }
    this.isLoadingTargetList = false;
  }

  public async resetSearch() {
    if (this.syndicationJobService.searchQueryApplied) {
      this.searchText = '';
      this.searchInput = '';
      this.syndicationJobService.resetSearch();
      this.isSearchFiltered = false;
      this.syndicationJobService.updateParams({page: 1});
      await this.loadJobs(true);
    }
  }

  public async search() {
    this.isLoading = true;
    this.searchInput = this.searchText;
    this.isSearchFiltered = !!this.searchText;
    this.syndicationJobService.search(this.searchText);
    this.syndicationJobService.updateParams({page: 1});
    await this.loadJobs(true);
    this.syndicationJobService.searchQueryApplied = this.searchText;
  }

  public async sourceTypeChanged(newValue?) {
    if (window.localStorage) {
      window.localStorage.setItem('defaultSyndicationSourceType', newValue);
    }

    await this.updateSourceListData();
  }

  public async sourceSearchTextChanged() {
    await this.updateSourceListData();
  }

  private updateFilteredSourceList() {
    if (this.sourceType === 'channel') {
      this.filteredSourceList = this.liveChannels.channels;
    } else if (this.sourceType === 'event') {
      this.filteredSourceList = this.liveEvents.data;
    }
  }

  public async targetSearchTextChanged() {
    const filtered = this.syndicationTargetService.syndicationTargets.filter(
      target => target.description.toLowerCase().indexOf(this.targetSearchText.toLowerCase()) >= 0,
    );
    this.filteredTargetList = filtered;
  }

  public handleSourceListSelect(source) {
    if (source.checked) {
      this.selectedSource = source.value;
    } else {
      this.selectedSource = null;
    }
  }

  public handleTargetListSelect(target) {
    if (target.checked) {
      if (this.selectedTargets.length < 10 && !this.selectedTargets.includes(target.value.id)) {
        this.selectedTargets.push(target.value.id);
      } else if (!this.selectedTargets.includes(target.value.id)) {
        Toast.danger('You can only select up to 10 targets.');
      }
    } else {
      this.selectedTargets = this.selectedTargets.filter(id => id !== target.value.id);
    }
  }

  public async loadMoreJobs() {
    if (this.isLoading || this.isLoadingMore || !this.syndicationJobService.meta.hasMore) {
      return;
    }

    this.isLoadingMore = true;
    this.syndicationJobService.getMoreJobs();
    await this.loadJobs(false);
  }

  public async createJob(editAfterCreate: boolean = false) {
    let andEdit = editAfterCreate;

    if (!Array.isArray(this.selectedTargets) || this.selectedTargets.length === 0) {
      Toast.danger('Please select at least one target.');
      return;
    }

    if (this.selectedTargets.length > 1) {
      andEdit = false;
    }

    this.isCreating = true;

    try {
      // Create jobs in parallel
      const jobPromises = this.selectedTargets.map(async targetId => {
        const job = new SyndicationSchedule();

        job.content_id = this.selectedSource.id;
        job.syndication_target_id = targetId;

        job.content_type =
          this.sourceType === 'channel'
            ? SyndicationScheduleContentType.CHANNEL
            : SyndicationScheduleContentType.LIVE_EVENT;

        delete (job as Partial<SyndicationSchedule>).err_msg;
        delete (job as Partial<SyndicationSchedule>).source_stream;
        delete (job as Partial<SyndicationSchedule>).status;
        delete (job as Partial<SyndicationSchedule>).type;
        delete (job as Partial<SyndicationSchedule>).is_new;

        try {
          const res = await this.syndicationScheduleService.saveSyndicationSchedule(job);
          return {success: true, res};
        } catch (e) {
          // console.error(`Failed to create job for targetId: ${targetId}`, e);
          return {success: false, error: e};
        }
      });

      const results = await Promise.all(jobPromises);

      // Count successes and handle results
      const successfulJobs = results.filter(result => result.success);
      const failedJobs = results.filter(result => !result.success);

      if (successfulJobs.length > 0) {
        Toast.success(`${successfulJobs.length} job${successfulJobs.length > 1 ? 's' : ''} created successfully.`);
      }

      if (failedJobs.length > 0) {
        Toast.danger(`${failedJobs.length} job${failedJobs.length > 1 ? 's' : ''} failed to create.`);
      }

      // Redirect if editing and only one job was successfully created
      if (andEdit && successfulJobs.length === 1) {
        const firstSuccessfulJob = successfulJobs[0];
        document.location.href = `#/syndication/jobs/${firstSuccessfulJob.res.id}`;
      } else {
        await this.loadJobs(true);
        this.createJobDialog?.hide();
      }
    } catch (e) {
      Toast.danger(e.message || 'An error occurred while creating jobs.');
    } finally {
      this.isCreating = false;
    }
  }

  public deleteJob(job: SyndicationJob): Promise<void> {
    const jobTitle = `${job.content_description} (${job.description})`;
    return new Promise((resolve, reject) => {
      // Check if the job is scheduled and prevent deletion
      if (job.status === 'scheduled') {
        // eslint-disable-next-line max-len
        const errorMessage = `Unable to delete a ${jobTitle} scheduled job. Please stop it first and try again.`;
        Toast.warning(errorMessage);
        reject(new Error(errorMessage)); // Reject with error
        return;
      }

      // Check if the job's status is in the SAFE_TO_DELETE array
      if (!this.SAFE_TO_DELETE.includes(job.status)) {
        // eslint-disable-next-line max-len
        const errorMessage = `Unable to delete a ${jobTitle} job with status ${job.status}. The schedule must be fully stopped before deleting.`;
        Toast.warning(errorMessage);
        reject(new Error(errorMessage)); // Reject with error
        return;
      }

      // If the job has an ID, attempt to delete it
      this.syndicationScheduleService
        .deleteSchedule(job.id)
        .then(() => {
          Toast.info(`Job ${jobTitle} deleted`);
          _.remove(this.selectedJobs, jobId => jobId === job.id);
          resolve(); // Resolve on success
        })
        .catch(e => {
          Toast.danger(e.message || `Failed to delete the job ${jobTitle}`);
          reject(e); // Reject the promise on error
        });
    });
  }

  public async deleteJobs() {
    const jobs = this.jobs.filter(job => job.selected);
    const promises = jobs.map(job => this.deleteJob(job));

    this.isDeleting = true;
    try {
      await Promise.all(promises);
      this.getVisibleJobs();
    } catch (error) {
      // Toast.warning(`Failed to Delete Job: ${error}`);
    }

    await this.loadJobs(true);
    this.isDeleting = false;
  }
}
