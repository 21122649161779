import {autoinject, computedFrom} from 'aurelia-framework';
import {PlaylistService} from '../../../services/schedule-playlist';
import {ScheduleEntryService} from '../../../services/scheduled-entry';
import {ITrackedScheduledEntry} from '../../models/event-model';

@autoinject()
export class AddFooter {
  @computedFrom(
    'model.isDirty',
    'overwriteSchedule',
    'scheduleEntryService.conflictChosenMethod',
    'scheduleEntryService.hasConflicts',
    'scheduleEntryService.saveEnabled',
    'playlistService.isSaving',
    'playlistService.isLoading',
  )
  get saveButtonState() {
    const {hasConflicts, saveEnabled, conflictChosenMethod} = this.scheduleEntryService;
    const {isLoading, isSaving} = this.playlistService;
    const overwriteConflicts = hasConflicts && conflictChosenMethod;
    const {isDirty} = this.model;

    if (isLoading || isSaving || !saveEnabled || !isDirty) {
      return 'disabled';
    }

    if (overwriteConflicts || !hasConflicts) {
      return '';
    }

    return 'disabled';
  }

  @computedFrom('saveButtonState', 'model.isNew')
  get saveNextButtonState() {
    if (!this.model.isNew) {
      return 'hidden';
    }

    return this.saveButtonState;
  }

  public model: ITrackedScheduledEntry = null;
  public overwriteSchedule: boolean = false;
  public controller;

  constructor(public scheduleEntryService: ScheduleEntryService, public playlistService: PlaylistService) {}

  public activate(model) {
    const {controller, shared} = model;

    this.controller = controller;
    this.model = shared.entry;
  }

  public async saveAndAddNextModel() {
    return this.scheduleEntryService.saveModal(false);
  }

  public close() {
    this.controller.close();
  }
}
