import {Type} from 'class-transformer';
import {IsBoolean, IsString, ValidateNested} from 'class-validator';
import {IntegrationNameType} from './enums-config';

export class KalturaCredentials {
    @IsString()
    public username: string = '';

    @IsString()
    public admin_secret: string = '';

    @IsString()
    public partner_id: string = '';

    public password_modified: string = '';
}

export class KalturaMetaMap {
    @IsString()
    public profile_id: string = '';

    @IsString()
    public name: string = '';

    @IsString()
    public tags: string = '';

    @IsString()
    public adminTags: string = '';

    @IsString()
    public categories: string = '';

    @IsString()
    public description: string = '';
}

export class Kaltura {
    @ValidateNested()
    @Type(() => KalturaCredentials)
    public credentials: KalturaCredentials = new KalturaCredentials();

    @ValidateNested()
    @Type(() => KalturaMetaMap)
    public metamap: KalturaMetaMap = new KalturaMetaMap();

    @IsString()
    public name: string = IntegrationNameType.Kaltura;

    @IsBoolean()
    public enabled: boolean = false;

    public newlyAdded: boolean = false;

    public constructor(init?: Partial<Kaltura>) {
        Object.assign(this, init);
    }
}
