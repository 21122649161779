import {HttpClient} from 'aurelia-fetch-client';
import {inject, LogManager} from 'aurelia-framework';
import {CmsHttpClient} from 'services/cms-http-client';

// TODO: need to add more typing here
const logger = LogManager.getLogger('Setting Service');

const getErrorMsg = 'Error retrieving settings.';
const saveErrorMsg = 'Error saving expanded parameters.';

export interface ExpandedParameters {
    [propName: string]: string;
}

@inject(CmsHttpClient)
export class ParamExpansionSettingService {
    public httpClient: HttpClient;
    public getSettingUrl: string;
    public saveParamsExpandsionUrl: string;
    public formUrlEncoded: string;
    public data: ExpandedParameters;
    public error: string;
    public logger = logger;

    constructor(public cmsHttpClient: CmsHttpClient) {
        this.httpClient = this.cmsHttpClient.httpClient;
        this.getSettingUrl = '/settings/all/';
        this.saveParamsExpandsionUrl = '/settings/expansionparams/update/';
        this.formUrlEncoded = 'application/x-www-form-urlencoded';
    }

    public getSettings() {
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.getSettingUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'post',
                })
                .then(resp => {
                    if (resp.ok) {
                        resp.json()
                            .then(res => {
                                this.data = res.expansionParams;
                                resolve(this.data);
                            })
                            .catch(err => {
                                this.logger.error(err);
                                reject(err);
                            });
                    } else {
                        this.logger.error(getErrorMsg);
                        reject('Error getting setting data due response is not ok');
                    }
                })
                .catch(err => {
                    this.logger.error(err);
                    reject(err);
                });
        });
    }

    // TODO: Using the legacy checkResponseForError function for now, might re-write later
    public checkResponseForError(res) {
        if ('error' in res) {
            if ('msg' in res) {
                this.error = res.msg;
            } else {
                this.error = 'An Unknown Error Has Occurred in SettingData';
            }
            if (res.error !== 0) {
                return true;
            }
        }
        return false;
    }

    public saveParameters(paramsObj) {
        const data = {
            expansionParamsMaxed: Object.keys(paramsObj).length > 100,
            params: paramsObj,
        };
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.saveParamsExpandsionUrl, {
                    body: `item=${encodeURIComponent(JSON.stringify(data))}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    method: 'post',
                })
                .then(resp => {
                    if (resp.ok) {
                        resp.json()
                            .then(res => {
                                if (!this.checkResponseForError(res)) {
                                    this.data = res.item.params;
                                    resolve(this.data);
                                } else {
                                    this.logError(reject);
                                }
                            })
                            .catch(() => {
                                this.logError(reject);
                            });
                    } else {
                        this.logError(reject);
                    }
                })
                .catch(() => {
                    this.logError(reject);
                });
        });
    }

    public logError(reject) {
        this.logger.error('Settings Data', this.error || saveErrorMsg);
        reject(new Error(saveErrorMsg));
    }
}
