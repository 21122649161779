import {CFormAddRemoveActions} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';

@autoinject()
export class BlackoutIdBody {
  public model: any;

  public actions: CFormAddRemoveActions = {
    onScrollBottom: async () => {
      await this.model.getMoreRules();
    },
    onSearch: async searchText => {
      await this.model.onSearch(searchText);
    },
  };

  public activate(model) {
    this.model = model.shared;
  }
}
