import {CTableSelectable} from 'resources/components/c-table-selectable/c-table-selectable';
import {
    CTableColSelectable,
    CTableRowBehavior,
} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {IngestPointTableColHeadValue, IngestPointTableRow} from './table-models';

export class IngestPointSelectionTable extends CTableSelectable {
    public rows: IngestPointTableRow[];
    public selected: IngestPointTableRow;
    protected colsSelectableDefault: CTableColSelectable[] = [
        {
            colHeadName: 'status',
            colHeadValue: IngestPointTableColHeadValue.status,
            selected: true,
            sort: true,
            valueConverter: 'defaultValue: "N/A" | capitalize:true',
        },
        {
            colHeadName: 'endpoint_id',
            colHeadValue: IngestPointTableColHeadValue.endpointId,
            selected: false,
            sort: true,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'name',
            colHeadValue: IngestPointTableColHeadValue.name,
            selected: true,
            sort: true,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'zoneRegion',
            colHeadValue: IngestPointTableColHeadValue.zoneRegion,
            selected: true,
            sort: false,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'domain_name',
            colHeadValue: IngestPointTableColHeadValue.domainName,
            selected: true,
            sort: true,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'streamTypeName',
            colHeadValue: IngestPointTableColHeadValue.streamTypeName,
            selected: true,
            sort: false,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'description',
            colHeadValue: IngestPointTableColHeadValue.description,
            selected: false,
            sort: true,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'connectionSourceUrl',
            colHeadValue: IngestPointTableColHeadValue.connectionSourceUrl,
            selected: false,
            sort: false,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'connectionSecureSourceUrl',
            colHeadValue: IngestPointTableColHeadValue.connectionSecureSourceUrl,
            selected: false,
            sort: false,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'connectionStreamKey',
            colHeadValue: IngestPointTableColHeadValue.connectionStreamKey,
            selected: false,
            sort: false,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'portsPort',
            colHeadValue: IngestPointTableColHeadValue.portsPort,
            selected: false,
            sort: false,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'portsPortFec2',
            colHeadValue: IngestPointTableColHeadValue.portsPortFec2,
            selected: false,
            sort: false,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'portsPortFec4',
            colHeadValue: IngestPointTableColHeadValue.portsPortFec4,
            selected: false,
            sort: false,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'slicerAccountName',
            colHeadValue: IngestPointTableColHeadValue.slicerAccountName,
            selected: false,
            sort: true,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'created',
            colHeadValue: IngestPointTableColHeadValue.created,
            selected: true,
            sort: true,
            valueConverter: "timezoneTimeToStandardDateTimeMS:'MMM D, YYYY @ h:mm A'",
        },
    ];

    constructor() {
        super(CTableRowBehavior.RADIO);
        this.colsSelectable = this.colsSelectableDefault;
        this.actions.getBarColor = this.getBarColor;
        this.actions.rowClick = this.rowClickRadio.bind(this);
    }

    protected getBarColor(row: IngestPointTableRow, col: CTableColSelectable) {
        if (col.colHeadName === 'status') {
            switch (row.status.toUpperCase()) {
                case 'IN_USE':
                    return 'var(--c_subThreeMain)';
                case 'NOT_IN_USE':
                    return 'var(--c_secondaryMain)';
                case 'INITIALIZING':
                    return 'var(--c_subOneMain)';
                case 'FAILED':
                    return 'var(--c_primaryMain)';
            }
        }
        return '';
    }

    protected getColClass(row: IngestPointTableRow, col: CTableColSelectable) {
        if (row && col.colHeadValue === IngestPointTableColHeadValue.status) {
            return 'bar';
        }
    }

    protected trackSelectedRadio() {
        super.trackSelectedRadio();
        const item = this.rows.filter(row => row.id === this.rowsSelected[0])[0];
        Object.assign(this.selected, item);
    }
}
