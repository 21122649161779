import {inject} from 'aurelia-framework';

import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';
import {BaseSettings} from '../base';
import {SettingsData} from '../services/settings-data';

export const INVALID_APP_NAME = {
  DUPLICATE: 'Duplicate name.',
  INVALID_CHAR: 'Value can contain only letters, numbers, and underscore characters.',
  MAX_LENGTH: 'Must be 15 characters or less.',
  REQUIRED: 'You must enter a value.',
};

@inject(Notification, SettingsData, SessionService)
export class PlaybackTokens extends BaseSettings {
  public invalidAppName: boolean = false;
  public invalidAppNameMessage: string;
  public appName: string = '';
  public callbackURL: string = '';
  public subownersKeys: string[] = [];
  public isLoadingSubowners: boolean = true;
  public tokens;
  public subowners;
  public testURLResults;
  public testUrlResultDialog: any;

  public selectedAppKeys = [];

  public isDeleting: boolean = false;

  constructor(...rest) {
    super(...rest);
  }

  public initializeProperties() {
    this.initializeTokens();
    this.settingsData
      .getSubowners()
      .then(data => {
        if (data.error === 0) {
          this.subowners = data.subowners;
          this.parseSubownersForKeys();
        } else {
          this.notification.error('Failed to get subowners.');
        }
      })
      .catch(() => {
        this.notification.error('Failed to get subowners.');
      });
  }

  public initializeTokens() {
    this.tokens = this.model.tokens;
  }

  /**
   * Parses through the this.subowners list of subowner objects, and populates the
   * this.subownersKeys list with key objects, such that each individual key + info
   * can be easily accessed by the repeater (repeat.for) in Aurelia.
   */
  public parseSubownersForKeys() {
    const subownersKeysList = [];
    // Using this.subowners vs. pass in subowner list
    this.subowners.forEach(data => {
      Object.keys(data.all_api_keys).forEach(keyObj => {
        subownersKeysList.push({
          api_key: keyObj,
          enabled: data.all_api_keys[keyObj].enabled,
          permissions: data.permissions.join(', '),
          subowner_id: data.id,
          username: data.username,
        });
      });
    });
    this.subownersKeys = subownersKeysList;
    this.isLoadingSubowners = false;
  }

  public saveSettings(): Promise<any> {
    this.isSaving = true;
    return this.settingsData
      .updateTokenSettings(this.tokens)
      .then(data => {
        if (data.error === 0) {
          this.model.tokens = data.item;
          this.initializeTokens();
          this.notification.success('Integration keys have been updated.');
        }
      })
      .finally(() => {
        this.isSaving = false;
        this.isDeleting = false;
      });
  }

  public clickSaveSettings(): Promise<any> {
    return this.saveSettings();
  }

  public addAppName() {
    this.invalidAppNameMessage = this.validateAppName();
    this.invalidAppName = !!this.invalidAppNameMessage;
    if (this.invalidAppName) {
      return;
    }

    this.tokens.app_keys.push({name: this.appName});
    this.saveSettings().then(() => {
      this.appName = '';
    });
  }

  public validateAppName(): string {
    this.invalidAppNameMessage = null;
    if (this.appName.length > 15) {
      return INVALID_APP_NAME.MAX_LENGTH;
    }
    if (!this.appName) {
      return INVALID_APP_NAME.REQUIRED;
    }
    if (!/^[a-zA-Z0-9_]*$/.test(this.appName)) {
      return INVALID_APP_NAME.INVALID_CHAR;
    }
    if (this.tokens.app_keys.some(data => data.name.toUpperCase() === this.appName.toUpperCase())) {
      return INVALID_APP_NAME.DUPLICATE;
    }
    return null;
  }

  public deleteAppKeys(): Promise<any> {
    this.isDeleting = true;
    _.remove(this.tokens.app_keys, (key: any) => this.selectedAppKeys.indexOf(key.value) >= 0);

    return this.saveSettings().then(() => {
      this.selectedAppKeys = [];
    });
  }

  public testURL() {
    this.settingsData.testPlaybackTokens(this.callbackURL).then(data => {
      this.testURLResults = data.items;
      this.testUrlResultDialog?.show();
    });
  }

  /**
   * Track a key as it's toggled for deletion or duplication
   */
  public toggleKeySelected(key) {
    key.selected = !key.selected;
    this.trackSelected(key);
  }

  public trackSelected(key) {
    if (key.selected) {
      this.selectedAppKeys.push(key.value);
    } else {
      _.remove(this.selectedAppKeys, keyValue => keyValue === key.value);
    }

    this.selectedAppKeys = _.uniq(this.selectedAppKeys);
  }

  /**
   * Toggle all visible keys for deletion or duplication
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.tokens.app_keys, key => {
      if (isSelected) {
        key.selected = true;
      } else {
        key.selected = false;
      }
    });

    this.selectedAppKeys = _.map(
      _.filter(this.tokens.app_keys, key => key.selected),
      key => key.value,
    );
  }
}
