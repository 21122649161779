import {CTableCol, CToastsService} from '@bindable-ui/bindable';

import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

import {ILazyLoaderActions} from 'components/lazy-loader';
import {HyperionPolling} from 'services/hyperion-polling';
import {getElementsInFocus} from 'utils/visibility';

import {TableSortingTools} from 'utils/table-sorting-tools';
import {FailoverGroup} from '../../models/failover-group';

import {FailoverGroupService} from '../../services/failover-group-service';
import {FailoverGroupSlicerService} from '../../services/failover-group-slicer-service';
import {FailoverGroupSNSService} from '../../services/failover-group-sns-service';

const logger = LogManager.getLogger('Failover Groups');
const STATUS_REFRESH_INTERVAL = 8000;

@autoinject()
export class FailoverGroupsList {
  @computedFrom('trackedRows.size', 'failoverGroupService.groups.length')
  public get allSelected(): boolean {
    const ids = new Set(this.failoverGroupService.groups.map(g => g.id));

    if (!ids || !this.trackedRows || this.trackedRows.size === 0) {
      return false;
    }

    return ids.size === this.trackedRows.size && Array.from(ids).every(id => this.trackedRows.has(id));
  }

  @computedFrom('trackedRows.size', 'failoverGroupService.isProcessing')
  public get deleteBtnState() {
    if (this.failoverGroupService.isProcessing) {
      return 'thinking';
    }
    if (this.trackedRows && this.trackedRows.size > 0) {
      return '';
    }

    return 'disabled';
  }

  @computedFrom('failoverGroupService.isLoading', 'failoverGroupService.isLoadingMore')
  public get isLoading(): boolean {
    return this.failoverGroupService.isLoading || this.failoverGroupService.isLoadingMore;
  }

  @computedFrom('failoverGroupService.order')
  public get sortedName(): number {
    return TableSortingTools.sorted('desc', this.failoverGroupService.order);
  }

  public columns: CTableCol[];
  public createGroupDialog: any;
  public createGroupForm: any;

  public searchText: string = '';
  public isSearching: boolean = false;

  public newFailoverGroupName: string = '';
  public isCreating: boolean = false;
  public createErrMsgName: string = '';

  public isDeleting: boolean = false;
  public trackedRows: Set<string>;

  public pollTracker: HyperionPolling;
  public snsTopic: string = '';
  public snsId: string = '';

  public logger = logger;

  public createGroupActions = {
    onHide: () => {
      this.newFailoverGroupName = '';
      this.createErrMsgName = '';
      this.createGroupForm.reset();
    },
  };

  public loaderActions: ILazyLoaderActions = {
    getMore: async () => {
      try {
        await this.failoverGroupService.getMore();
      } catch (err) {
        logger.error(err);
      }
    },
  };

  constructor(
    private vToastsService: CToastsService,
    public dialogService: DialogService,
    public failoverGroupService: FailoverGroupService,
    public slicerService: FailoverGroupSlicerService,
    public snsService: FailoverGroupSNSService,
    public router: Router,
  ) {
    this.trackedRows = new Set();
    this.pollTracker = new HyperionPolling({
      callbackFn: res => this.failoverGroupService.processPollData(res),
      getParams: () => this.failoverGroupService.params,
      ms: STATUS_REFRESH_INTERVAL,
      promiseFn: args => {
        if (document.hidden) {
          return null;
        }

        const ids = getElementsInFocus('[data-id=slicer-groups] lynk-tr');

        return this.failoverGroupService.pollRecords(ids, args);
      },
      useAfter: true,
    });
  }

  public activate() {
    Promise.all([
      this.initFailoverGroupsData(),
      this.initSNSData(),
    ]);

    this.trackedRows = new Set();
  }

  public detached() {
    this.pollTracker.stop();
  }

  public async configureSNS() {
    const sharedModel = {snsTopic: this.snsTopic, snsId: this.snsId};
    try {
      await this.dialogService
        .open({
          model: {
            sharedModel,
            bodyViewModel: PLATFORM.moduleName('apps/cms/routes/slicers/groups/modals/sns-modal'),
            footerEnable: true,
            footerViewModel: PLATFORM.moduleName('apps/cms/routes/slicers/groups/modals/sns-modal-footer'),
            size: 'medium',
            title: 'Configure SNS Push Notifications',
          },
          viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        })
        .whenClosed(async response => {
          try {
            if (!response.wasCancelled) {
              if (sharedModel.snsTopic && !sharedModel.snsTopic.includes('us-west-2')) {
                const errorMsg = 'Sns topic must be in aws us-west-2 region.';
                this.vToastsService.error(errorMsg);
              } else {
                this.snsTopic = sharedModel.snsTopic
                  ? await this.snsService.updateSNSTopic(sharedModel.snsTopic)
                  : await this.snsService.deleteSNSTopic(sharedModel.snsId);
                this.vToastsService.success(`Successfully ${sharedModel.snsTopic ? 'updated' : 'deleted'} SNS topic`);
                this.snsTopic = sharedModel.snsTopic;
              }
            }
          } catch (e) {
            this.vToastsService.error(`Error ${sharedModel.snsTopic ? 'updating' : 'deleting'} SNS topic`);
            this.logger.error(e);
          }
        });
    } catch (e) {
      this.logger.error(e);
    }
  }

  public async createFailoverGroup(andEdit: boolean = false) {
    const createParams = {
      name: this.newFailoverGroupName,
    };

    this.createErrMsgName = '';
    if (!this.newFailoverGroupName || !this.newFailoverGroupName.length) {
      this.createErrMsgName = 'Failover Group Name is required.';
      return;
    }

    // TODO: Move this logic to the API
    if (
      _.findIndex(this.failoverGroupService.groups, [
        'name',
        this.newFailoverGroupName,
      ]) !== -1
    ) {
      this.createErrMsgName = 'Failover Group Name already in use.';
      return;
    }

    this.isCreating = true;

    try {
      const res = await this.failoverGroupService.addFailoverGroup(createParams);

      if (!res) {
        throw new Error('Failed to create group');
      }

      this.vToastsService.success('Slicer Group created successfully.');

      this.newFailoverGroupName = '';
      this.createGroupDialog.hide();

      if (andEdit) {
        this.navigateToGroup(res);
      }
    } catch (e) {
      this.vToastsService.error('Error creating group.');
      logger.error(e);
    } finally {
      this.isCreating = false;
    }
  }

  public async deleteFailoverGroups() {
    const selectedCount = this.trackedRows.size;

    const groups = Array.from(this.trackedRows);

    this.isDeleting = true;

    await Promise.all(groups.map(async id => this.deleteFailoverGroup(id)));

    this.isDeleting = false;

    if (this.trackedRows.size === 0) {
      this.trackedRows = new Set();
      this.vToastsService.success(`Deleted ${selectedCount} Slicer Group${selectedCount === 1 ? '' : 's'}`);
    }

    return true;
  }

  /**
   * Handles table row click events
   * @param event
   */
  public rowClicked(event: any) {
    event.preventDefault();

    const tr = event.target.closest('lynk-tr');
    const rowId = tr.dataset.id;

    if (!rowId) {
      return;
    }

    const td = event.target.closest('lynk-td');

    if (td?.classList.contains('row-select')) {
      this.trackRow(rowId);
    } else {
      const group = this.failoverGroupService.groups.find(g => g.id === rowId);
      this.navigateToGroup(group);
    }
  }

  public async search(query: string) {
    this.searchText = query;

    const params = {active: true, channels: true, slicers: true, thresholds: true};
    await this.failoverGroupService.searchGroups(query, params);
  }

  public async sortRecords(key: string, defaultSortDirection: string = null) {
    const {order} = this.failoverGroupService;
    const newOrder = TableSortingTools.sort(key, order, defaultSortDirection);

    const params = _.cloneDeep(this.failoverGroupService.params);
    params.order = newOrder;
    params.page = 1;

    return this.failoverGroupService.getFailoverGroups('', params);
  }

  public trackAll(event) {
    event.preventDefault();

    const ids = new Set(this.failoverGroupService.groups.map(g => g.id));

    if (!this.allSelected) {
      this.trackedRows = ids;
    } else {
      this.trackedRows = new Set();
    }
  }

  public trackRow(id: string) {
    if (this.trackedRows.has(id)) {
      this.trackedRows.delete(id);
    } else {
      this.trackedRows.add(id);
    }

    // Aurelia can't track a set, so replace it to force rendering updates
    this.trackedRows = new Set(this.trackedRows);
  }

  private async deleteFailoverGroup(id: string) {
    try {
      await this.failoverGroupService.removeFailoverGroup(id);

      this.trackedRows.delete(id);
    } catch (err) {
      logger.error(err);
      this.vToastsService.error(`Failed to delete group id: ${id}`);
    }
  }

  private async initFailoverGroupsData() {
    try {
      await this.failoverGroupService.getFailoverGroups('', {
        active: true,
        channels: true,
        page: 1,
        slicers: true,
      });

      this.pollTracker.start();
    } catch (err) {
      this.vToastsService.error('Error loading Failover groups');
      logger.error(err);
    }
  }

  private async initSNSData() {
    try {
      const snsDetails = await this.snsService.getSNSTopic();
      this.snsTopic = _.get(snsDetails, 'topic');
      this.snsId = _.get(snsDetails, 'sns_id');
    } catch (err) {
      this.vToastsService.error('Error fetching SNS topic');
      logger.error(err);
    }
  }

  private navigateToGroup(failoverGroup: FailoverGroup) {
    // const routeObj = this.router.routes.find(x => x.name === RouteName.FailoverGroupsList);
    // routeObj.settings.data = {
    //   failoverGroup,
    //   failoverGroups: this.failoverGroupService.groups,
    //   index: _.findIndex(this.failoverGroupService.groups, {id: failoverGroup.id}),
    // };
    this.router.navigate(`/slicers/groups/${failoverGroup.id}`);
  }
}
