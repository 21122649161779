import {noView} from 'aurelia-templating';

@noView()
export class FacebookApi {
    private _scriptPromise = null;
    private _fb;

    constructor() {
        this.getScript();
    }

    public getScript() {
        // Script has already been set, so return promise straight away
        if (this._scriptPromise !== null) {
            return this._scriptPromise;
        }

        if ((window as any).FB === undefined) {
            // facebook sdk has not been defined yet
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.defer = true;
            script.src = '//connect.facebook.net/en_US/sdk.js';
            document.body.appendChild(script);

            // Lets setup the promise so that it resolves when the callback is called
            // We are using an OLD version
            this._scriptPromise = new Promise((resolve, reject) => {
                (window as any).fbAsyncInit = () => {
                    this._fb = (window as any).FB;
                    this._fb.init({
                        appId: '138193789879412', // Production
                        version: 'v7.0',
                        xfbml: true,
                    });
                    resolve();
                };

                script.onerror = error => {
                    reject(error);
                };
            });

            return this._scriptPromise;
        }
        return false;
    }

    public login(): Promise<any> {
        return this.getScript().then(
            () =>
                new Promise((resolve, reject) => {
                    this._fb.login(
                        response => {
                            if (response.authResponse) {
                                resolve(response);
                            } else {
                                reject(response);
                            }
                        },
                        {
                            scope: 'pages_show_list, pages_manage_posts, pages_read_engagement, pages_read_user_content',
                        },
                    );
                }),
        );
    }
}
