import {EncodingProfileTable} from 'apps/cms/routes/slicers/encoding-profiles/list/table';
import {IEncodingProfileTableRow} from 'apps/cms/routes/slicers/encoding-profiles/list/table-models';
import {CTableRowBehavior} from 'resources/components/c-table-selectable/c-table-selectable-models';

export class EncodingProfileSelectionTable extends EncodingProfileTable {
    public selected: IEncodingProfileTableRow;

    constructor() {
        super(CTableRowBehavior.RADIO);
        this.colsSelectable = this.colsSelectableDefault;
        this.actions = {
            rowClick: this.rowClickRadio.bind(this),
        };
    }

    protected trackSelectedRadio() {
        super.trackSelectedRadio();
        const item = this.rows.filter(row => row.id === this.rowsSelected[0])[0];
        Object.assign(this.selected, item);
    }
}
