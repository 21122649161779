export class AssetExpireValueConverter {
    public toView(value, defaultValue) {
        if (defaultValue) {
            if (value || value === 0) {
                return value;
            }
            return defaultValue;
        }
        return value;
    }
}
