import {ITemplateListTableRow} from 'apps/cms/routes/slicers/templates/list/table-models';
import {ConfigFormat} from '../../models/config-format';
import {ConfigOptions as ConfigOptionsBase} from '../../models/config-options';
import {ConfigImportMode, ConfigImportSource} from './config-import';

class ConfigOptionsImport {
  public mode = [
    {
      text: 'Append after configuration settings',
      value: ConfigImportMode.APPEND,
    },
    {
      text: 'Prepend before configuration settings',
      value: ConfigImportMode.PREPEND,
    },
    {
      text: 'Replace all configuration settings',
      value: ConfigImportMode.REPLACE,
    },
  ];

  public source = [
    {
      text: 'File',
      value: ConfigImportSource.FILE,
    },
    {
      text: 'Template',
      value: ConfigImportSource.TEMPLATE,
    },
  ];

  public template: ITemplateListTableRow[] = [];
}

export class ConfigOptions extends ConfigOptionsBase {
  public exportFormat = [
    {
      text: 'JSON',
      value: ConfigFormat.JSON,
    },
    {
      text: 'Key/Value Pairs',
      value: ConfigFormat.KVP,
    },
  ];

  public import = new ConfigOptionsImport();
}
