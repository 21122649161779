import {SecondsToHmsValueConverter} from '@bindable-ui/bindable';
import {bindable, computedFrom} from 'aurelia-framework';

export interface IDurationActions {
    buttonAction: (durationInSeconds: number) => void;
}

export class VDuration {
    @computedFrom('errorMessage', '_errorMessage')
    get durationErrorMessage() {
        return this._errorMessage || this.errorMessage;
    }

    @bindable
    public actions: IDurationActions;

    @bindable
    public buttonText: string;

    @bindable
    public duration: any;

    @bindable
    public durationState: string;

    @bindable
    public errorMessage: string;

    @bindable
    public label: string;

    @bindable
    public onChange: (durationInSeconds: number) => any;

    public durationEventListeners = {
        blur: () => {
            this.formatDuration();
        },
        keyup: event => {
            if (event.keyCode === 13) {
                this.formatDuration();
            }
        },
    };

    private _errorMessage: string;
    private durationInSeconds: number = 0;
    private hhMMRegEx = /^\d{1,2}(:[0-5][0-9]){0,2}$/;
    private hmRegEx = /^((\d{1,2})h)?\s?((\d{1,3})m)?\s?((\d{1,4})s)?$/;

    public activate(model) {
        const {col, row} = model;

        if (row) {
            this.duration = row.duration;
            this.durationState = row.state;
            this.formatDuration();
        }

        if (col && col.callback) {
            this.onChange = val => col.callback(val, row);
        }

        this.formatDuration(true);
    }

    public onClick() {
        if (!this.actions) {
            return;
        }

        const {buttonAction} = this.actions;

        if (_.isFunction(buttonAction)) {
            buttonAction(this.durationInSeconds);
        }
    }

    private formatDuration(skipCallback = false) {
        let durationInSeconds = 0;
        const {duration} = this;
        const durationAsNumber = Number(duration);

        this._errorMessage = null;

        let hours = '00';
        let minutes = '00';
        let seconds = '00';

        if (durationAsNumber >= 0) {
            durationInSeconds = durationAsNumber;
        } else if (/:/.test(duration)) {
            if (this.hhMMRegEx.test(duration)) {
                [hours = '00', minutes = '00', seconds = '00'] = duration.split(':');
            } else {
                this._errorMessage = 'Must be formatted as HH:MM or HH:MM:SS';
            }
        } else if (/(\d*h)|(\d*m)|(\d*s)/.test(duration)) {
            if (this.hmRegEx.test(duration)) {
                // [FullText, HourText, Hours, MinuteText, Minutes, SecondText, Seconds]
                [, , hours = '00', , minutes = '00', , seconds = '00'] = duration.match(this.hmRegEx);
            } else {
                this._errorMessage = 'Must be formatted as HM (example: 2h 10m)';
            }
        } else {
            this._errorMessage = 'Not a valid time format';
        }

        if (this._errorMessage) {
            return;
        }

        if (!durationAsNumber) {
            durationInSeconds = parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
        }

        this.duration = SecondsToHmsValueConverter.transform(durationInSeconds);
        this.durationInSeconds = durationInSeconds;

        if (_.isFunction(this.onChange) && !skipCallback) {
            this.onChange(durationInSeconds);
        }
    }
}
