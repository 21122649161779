import * as moment from 'moment';

import {SECOND, HOUR, HALF_HOUR, WEEK_DAYS, FORTY_EIGHT, WeekDay} from './block-factories';

export class WeekService {
    initFilter() {
        return [moment().startOf('week').valueOf(), moment().endOf('week').valueOf()];
    }

    parseDate(currentMoment) {
        const startMoment = moment(currentMoment).startOf('week');
        const endMoment = startMoment.clone().endOf('week');

        const formattedStart = startMoment.format('MMM. Do');
        const formattedEnd = endMoment.format('MMM. Do, YYYY');
        const formattedShortStart = startMoment.format('M/D');
        const formattedShortEnd = endMoment.format('M/D/YY');

        return [`${formattedStart} - ${formattedEnd}`, `${formattedShortStart}-${formattedShortEnd}`];
    }

    nextButton(currentMoment) {
        const currentClone = currentMoment.clone();
        currentClone.add(1, 'week');

        return [
            currentClone,
            currentClone.clone().startOf('week').valueOf(),
            currentClone.clone().endOf('week').valueOf(),
        ];
    }

    prevButton(currentMoment) {
        const currentClone = currentMoment.clone();
        currentClone.subtract(1, 'week');

        return [
            currentClone,
            currentClone.clone().startOf('week').valueOf(),
            currentClone.clone().endOf('week').valueOf(),
        ];
    }

    assembleCalendar(events, weekStart) {
        const weekSetup = new Array(WEEK_DAYS).fill().map((a, index) => new WeekDay(weekStart, index));
        let totalEvents = 0;

        events.forEach(event => {
            /* eslint-disable no-restricted-syntax */
            for (const day of weekSetup) {
                const isInDay = moment(event.expected_start).isBetween(day.start, day.end, null, '[)');
                if (isInDay) {
                    day.events.push(event);
                    break;
                }
            }
            /* eslint-enable no-restricted-syntax */
        });

        weekSetup.forEach(day => {
            day.events.forEach(event => {
                const halfHourIndex = Math.floor(((event.expected_start - day.startUnix) / SECOND / HOUR) * HALF_HOUR);
                if (halfHourIndex && halfHourIndex < FORTY_EIGHT && halfHourIndex >= 0) {
                    day.slots[halfHourIndex].events.push(event);
                    totalEvents += 1;
                }
            });

            day.slots.forEach(halfHour => {
                halfHour.calculateBlocksForWeek();
            });
        });

        return [weekSetup, totalEvents];
    }
}
