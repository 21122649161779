export class MultipleTdPill {
    public group;
    public tags = [];

    public activate(model) {
        this.group = model.row;
        if (!_.isEmpty(this.group.tags)) {
            this.tags = _.map(this.group.tags, s => ({
                color: `var(--${this.getPillColor()})`,
                id: s,
            }));
        }
    }

    public getPillColor() {
        const colorList = ['c_secondaryMain'];

        return colorList[Math.floor(Math.random() * colorList.length)];
    }
}
