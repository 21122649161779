import {singleton, inject, LogManager} from 'aurelia-framework';
import 'fetch';
import {HttpClient} from 'aurelia-fetch-client';
import {Notification} from 'resources/notification/service';
import {SessionService} from './session';

const logger = LogManager.getLogger('cms-http-client');

@inject(HttpClient, Notification, SessionService)
@singleton()
export class CmsHttpClient {
    constructor(httpClient, notification, session) {
        const context = this;
        this.session_fingerprint = undefined;
        this.session = session;
        this.callbacks = {};
        this.httpClient = httpClient;

        this.interceptor = {
            response(response) {
                const r = response.clone();
                // Check for Session Yank - when Rights are Changed
                if (response.status === 403) {
                    // Old Legacy Response -> Assume Session Is Bad and Reload
                    // Body/Response is not returned so we can only rely on status code
                    window.location.reload();
                }

                if (response.status < 400) {
                    response
                        .json()
                        .then(data => {
                            // Check the session on every call
                            if (data.error && data.msg === 'Invalid session') {
                                if (session.isIdsEnabled(data) && data.idsLogin) {
                                    session.logout();
                                    return;
                                }
                                const cur = window.location.href;
                                logger.debug('Invalid session; redirect to login');
                                window.location.href = `${data.login}?login_redirect=${encodeURIComponent(cur)}`;
                            }
                            if (data.error) {
                                const errMsg = `API Error: ${data.msg}`;
                                logger.error(errMsg);
                                notification.error(errMsg);
                            }

                            if (data.cms_session_fingerprint) {
                                if (
                                    context.session_fingerprint &&
                                    context.session_fingerprint !== data.cms_session_fingerprint
                                ) {
                                    // re-fetch session data:
                                    context.session.check().then(sessionInfo => {
                                        const callback = context.callbacks.onSessionChanged;
                                        if (callback !== undefined) {
                                            callback(sessionInfo, data.cms_session_fingerprint);
                                        } else {
                                            // If we don't have a callback already defined - just reload
                                            localStorage.clear();
                                            window.location.reload();
                                        }
                                    });
                                }
                                context.session_fingerprint = data.cms_session_fingerprint;
                            }
                        })
                        .catch(err => {
                            // Console log the error so we know what "file" it is coming from
                            logger.error(err);
                        });
                }
                return r;
            },
        };

        this.httpClient.configure(config => {
            config
                .withDefaults({
                    credentials: 'same-origin',
                    headers: {
                        'Cms-Session-Token': session.sessionIdFingerprint,
                    },
                })
                .withInterceptor(this.interceptor);
        });

        this.httpClient.fetchAndUpdateClockDelta = function fetchAndUpdateClockDelta(
            endpoint,
            updateClientServerClockDelta,
        ) {
            const tRequest = new Date().valueOf();
            return this.fetch(endpoint).then(r => {
                const tServer = Date.parse(r.headers.get('Date'));
                updateClientServerClockDelta(tRequest, tServer);
                return r.json();
            });
        }.bind(this.httpClient);
    }
}
