import {autoinject, computedFrom} from 'aurelia-framework';
import {CToastsService} from '@bindable-ui/bindable';

import * as papa from 'papaparse';

import {ContentService, LibraryService} from 'apps/cms/routes/content/services';
import {File} from 'apps/cms/routes/live-channels/channels/single/utils/interfaces';

import {ScheduleEntryService} from '../../../services';
import {IScheduledEntryParams} from '../../../services/scheduled-entry';

import {ScheduleImport} from '../../models/schedule-import';
import {ScheduledEntryContentType, ConflictResolution} from '../../models/event-model';

export interface IImportActions {
  onClose: () => void;
  onSave: () => void;
}

export interface IImportModel {
  actions: IImportActions;
  channelId: string;
}

@autoinject()
export class BulkImport {
  public files = null;
  public isProcessing: boolean = false;
  public isUploading: boolean = false;
  public isUploaded: boolean = false;
  public isPaused: boolean = false;
  public rows: ScheduleImport[] = [];
  public importedRows: number = 0;
  public failedRows: number = 0;
  // public errors = [];

  public dropZone: any;
  public lastDropTarget: any;
  public fileInput: any;
  public importDialog: any;

  private actions: IImportActions;
  private channelId;

  public headers = [
    // {key: 'id', title: 'ID'},
    {key: 'title', title: 'Title'},
    {key: 'contentType', title: 'Type'},
    {key: 'startTime', title: 'Start'},
    {key: 'stopTime', title: 'End'},
    {key: 'assetDuration', title: 'Asset Duration'},
    {key: 'adBreakDuration', title: 'Ad Duration'},
    {key: 'duration', title: 'Total Duration'},
    {key: '', title: 'Status'},
  ];

  @computedFrom('rows.length')
  public get totalErrors(): number {
    return this.rows.reduce((total, obj) => total + obj.errors.length, 0);
  }

  @computedFrom('importedRows', 'failedRows', 'rows.length')
  public get completionPercentage(): number {
    const totalProcessed = this.importedRows + this.failedRows;
    const totalRows = this.rows.length;
    if (totalRows === 0) {
      return 0;
    }
    return (totalProcessed / totalRows) * 100;
  }

  constructor(
    public libraryService: LibraryService,
    public contentService: ContentService,
    public notificationService: CToastsService,
    public scheduleEntryService: ScheduleEntryService,
  ) {}

  /*
    Aurelia Hooks
  */

  public async activate(model: IImportModel) {
    this.channelId = model.channelId;
    this.actions = model.actions;
  }

  public attached() {
    window.addEventListener('dragenter', this.dragEnterHandler);
    window.addEventListener('dragleave', this.dragLeaveHandler);
    window.addEventListener('dragover', this.dragOverHandler);
    window.addEventListener('drop', this.dragDropHandler);
  }

  public detached() {
    window.removeEventListener('dragenter', this.dragEnterHandler);
    window.removeEventListener('dragleave', this.dragLeaveHandler);
    window.removeEventListener('dragover', this.dragOverHandler);
    window.removeEventListener('drop', this.dragDropHandler);
  }

  /*
    Public Methods
  */
  public clearData() {
    this.rows = [];
    this.isPaused = false;
    this.isUploaded = false;
    this.isProcessing = false;
    this.importedRows = 0;
    this.failedRows = 0;
  }

  public closeDialog() {
    if (this.isProcessing || this.isUploading) {
      return;
    }
    this.clearData();
    this.actions.onClose();
  }

  public async importRecords(): Promise<void> {
    this.isUploading = true;
    this.importDialog.hide();

    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];

      if (this.isPaused) {
        break;
      }

      if (row.state !== 'success') {
        row.state = 'active';
        row.stateLabel = 'Importing...';

        try {
          const data: IScheduledEntryParams = {
            ad_breaks: row.adBreaks || [],
            content_id: row.id,
            content_type: row.contentType,
            desc: row.title,
            dur: Math.floor(row.duration * 1000),
            start: row.startTime,
            conflict_resolution: ConflictResolution.REPLACE,
          };

          if (row.asset && row.asset.external_id) {
            data.external_id = row.asset.external_id;
          } else {
            data.external_id = row.title;
          }

          // eslint-disable-next-line no-await-in-loop
          await this.scheduleEntryService.saveScheduledEntry(data, this.channelId);

          row.state = 'success';
          row.stateLabel = 'Complete';
          this.importedRows += 1;
        } catch (err) {
          row.errors.push(err.message);
          row.state = 'danger';
          row.stateLabel = 'Error';
          this.failedRows += 1;
        }
      }
    }

    this.isUploading = false;

    if (!this.isPaused) {
      if (this.failedRows > 0) {
        this.notificationService.warning('Completed with errors');
      } else {
        this.notificationService.success('Done');
        this.isUploaded = true;
      }
    }
  }

  public async uploadCSV(elm): Promise<void> {
    const files: any = Array.from(elm.files);
    if (!files || !files.length || files[0].type !== 'text/csv') {
      this.notificationService.error('Unable to process file');
      return;
    }

    try {
      this.isProcessing = true;

      await this.processCSV(files[0]);
      // this.notificationService.success('Processed file successfully');
    } catch (err) {
      this.notificationService.error('Unable to process file');
    } finally {
      this.isProcessing = false;
      this.fileInput.value = '';
    }
  }

  public importPaused() {
    this.isPaused = !this.isPaused;

    if (this.isPaused) {
      this.notificationService.success('Import Paused');
    } else {
      this.notificationService.success('Import Resumed');
    }

    if (!this.isPaused) {
      this.importRecords();
    }
  }
  /*
    Private Methods
  */

  private async mapRows(results): Promise<void> {
    const {data, errors} = results;

    this.rows = [];
    // this.errors = [];

    const skip = errors.map(e => e.row);
    let previousEndTime = null;

    for (let i = 0; i < data.length; i++) {
      if (!this.isProcessing) {
        this.rows = [];
        break;
      }

      const row = data[i];

      if (!row.start_time) {
        row.start_time = previousEndTime;
      }

      const record = new ScheduleImport(row);

      if (skip.includes(i)) {
        const err = errors.find(e => e.row === i);
        record.errors.push(err.message);
      }

      try {
        if (record.contentType === ScheduledEntryContentType.ASSET) {
          // eslint-disable-next-line no-await-in-loop
          const asset = await this.contentService.getAsset(record.id);
          record.setAsset(asset);
        }
      } catch (err) {
        // record.state = 'error';
        // this.errors.push(`Failed to import row ${i}: ${err.message}`);
        record.errors.push(`Asset Error: ${err.message}`);
      }

      previousEndTime = record.stopTime;

      if (this.rows.length && record.startTime < this.rows[this.rows.length - 1].stopTime) {
        record.errors.push(`Time conflicts - The start time cannot be smaller than the previous end time.`);
      }
      this.rows.push(record);
    }
  }

  private async processCSV(file: File): Promise<any> {
    papa.parse(file, {
      skipEmptyLines: 'greedy',
      header: true,
      complete: async results => {
        this.isProcessing = true;
        await this.mapRows(results);
        this.isProcessing = false;
      },
      error: err => {
        this.notificationService.error(`Failed to parse CSV: ${err.message}`);
      },
    });
  }

  public dragEnterHandler = event => {
    const targetElm = event.target.classList.contains('drag-point');
    if (targetElm) {
      this.dropZone.classList.add('is-active');
    } else {
      this.dropZone.classList.remove('is-active');
    }
  };

  public dragOverHandler = event => {
    event.preventDefault();
    const targetElm = event.target.classList.contains('drag-point');
    if (targetElm) {
      this.dropZone.classList.add('is-active');
    } else {
      this.dropZone.classList.remove('is-active');
    }
  };

  public dragLeaveHandler = event => {
    event.preventDefault();
    const targetElm = event.target.classList.contains('drag-point');
    if (!targetElm) {
      this.dropZone.classList.remove('is-active');
    }
  };

  public dragDropHandler = event => {
    event.preventDefault();
    const targetElm = event.target.classList.contains('drag-point');

    if (targetElm) {
      const dt = event.dataTransfer;
      this.uploadCSV(dt);
    }

    this.dropZone.classList.remove('is-active');
  };

  public removeRow(row: any) {
    _.remove(this.rows, row);
  }
}
