import {HttpClient} from 'aurelia-fetch-client';
import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, LogManager, singleton} from 'aurelia-framework';
import * as moment from 'moment';

import {Acceo} from 'services/acceo';
import {
    AdServerDebugItem,
    AdServerDebugResponse,
    IAdServerDebugMeta,
    PlaybackType,
} from '../models/ad-server-debug-response';
import {DebugSearchCriteria} from '../models/debug-search-criteria';
import {DEFAULT_PER_PAGE_LIMIT, DEFAULT_MAX_PAGE_LIMIT} from '../models/defaults';

const logger = LogManager.getLogger('Ad Server Debug Service 2');

@autoinject()
@singleton()
export class AdServerDebugService {
    public adServerDebugResponse: AdServerDebugResponse = {
        items: [],
        total_items: 0,
    };

    public criteria: DebugSearchCriteria;
    public isLoading: boolean = false;
    public meta: IAdServerDebugMeta = {};
    public searchQueryString: string = '';

    private url: string = '/api/v4/adproxy/ad-debug/jobs';

    constructor(public acceo: Acceo, public httpClient: HttpClient, private notification: CToastsService) {}

    // public async getListMock() {
    //     const mockReponse = {
    //         start_adjob_date: '2022-06-28T18:08:00.000Z',
    //         end_adjob_date: '2022-06-28T19:09:00.000Z',
    //         items: [
    //             {
    //                 pk: 'bdac1dbb-5c23-475d-a92f-300208d1b56e',
    //                 zone: 'ause2-dt-ap1',
    //                 channel_guid: 'fa2b36af0fb84a57af3836d6ac4fbc2e',
    //                 viewer_guid: 'e715d23043fd4d43b5901b3ad8c2f79f',
    //                 video_guid: 'e11dac2b94e04810a12cf924e4b036f7',
    //                 playback_type: 'live',
    //                 debug_name: this.searchQueryString,
    //                 status: 'complete',
    //                 created_at: '2022-06-28T19:06:22.854819+00:00',
    //                 transaction_count: 4,
    //                 beacon_count: 163,
    //                 failed_transaction_count: 1,
    //             } as AdServerDebugItem,
    //             {
    //                 pk: '95566f54-18c2-42e6-8b72-fe606aaf48a8',
    //                 zone: 'ause2-dt-ap1',
    //                 channel_guid: 'fa2b36af0fb84a57af3836d6ac4fbc2e',
    //                 viewer_guid: '74182344ee614fcabcfcc28db65aad26',
    //                 video_guid: 'e11dac2b94e04810a12cf924e4b036f7',
    //                 playback_type: 'live',
    //                 debug_name: this.url,
    //                 status: 'complete',
    //                 created_at: '2022-06-28T19:06:22.850782+00:00',
    //                 transaction_count: 8,
    //                 beacon_count: 97,
    //                 failed_transaction_count: 4,
    //             } as AdServerDebugItem,
    //             {
    //                 pk: '9737d12f-9cdc-4642-9295-870786d15364',
    //                 zone: 'ause2-dt-ap1',
    //                 channel_guid: 'fa2b36af0fb84a57af3836d6ac4fbc2e',
    //                 viewer_guid: '475da659a7424076b610411099c00af5',
    //                 video_guid: 'e11dac2b94e04810a12cf924e4b036f7',
    //                 playback_type: 'live',
    //                 debug_name: '',
    //                 status: 'complete',
    //                 created_at: '2022-06-28T19:06:22.838654+00:00',
    //                 transaction_count: 9,
    //                 beacon_count: 77,
    //                 failed_transaction_count: 3,
    //             } as AdServerDebugItem,
    //         ],
    //         total_items: 3,
    //         cms_session_fingerprint: 'f9ed9afeae5efb753dfa3e12a046223f936ac150',
    //         cms_session_id_fingerprint: 'b149774d57cbb64c1b4a267dedd8a4c35ae4efa2',
    //     } as AdServerDebugResponse;
    //     return mockReponse;
    // } // getListMock

    public async getList() {
        /* FOR LOCALHOST TESTING ONLY! DO NOT COMMIT THE FOLLOWING LINE UNCOMMENTED */
        // return this.getListMock();
        /* FOR LOCALHOST TESTING ONLY! DO NOT COMMIT THE ABOVE LINES UNCOMMENTED */

        return this.acceo.get(AdServerDebugResponse)(`${this.url}?${this.searchQueryString}`);
    }

    public async getAdDebugItems(criteria: DebugSearchCriteria): Promise<AdServerDebugResponse> {
        if (this.isLoading) {
            return Promise.resolve(this.adServerDebugResponse);
        }
        this.isLoading = true;

        this.adServerDebugResponse = {
            items: [],
            total_items: 0,
        };

        // since page and page_size can be provided on the URI, check to be sure they are valid
        criteria.page = +criteria.page;
        criteria.page_size = +criteria.page_size;
        if (Number.isNaN(criteria.page_size) || criteria.page_size > DEFAULT_MAX_PAGE_LIMIT) {
            criteria.page_size = DEFAULT_PER_PAGE_LIMIT;
        }
        if (Number.isNaN(criteria.page)) {
            criteria.page = 1;
        }

        // get the search criteria string... and save it for later...
        try {
            this.searchQueryString = await criteria.toQueryString();
            if (!this.searchQueryString) {
                this.notification.error('Criteria not provided.');
            }
        } catch (reason) {
            this.notification.error(reason);
        }

        this.criteria = criteria;

        if (this.searchQueryString) {
            // get the data using the search criteria string
            try {
                this.adServerDebugResponse = await this.getList();
                // if live and items returned get the channel name
                if (criteria.playback_type === PlaybackType.LIVE && this.adServerDebugResponse.items.length) {
                    this.resolveLiveAssetNames(this.adServerDebugResponse.items);
                }
                this.adServerDebugResponse.items.sort((item1: AdServerDebugItem, item2: AdServerDebugItem) =>
                    moment(item1.created_at) > moment(item2.created_at) ? -1 : 1,
                );
            } catch (reason) {
                this.notification.error(reason);
            } finally {
                this.isLoading = false;
                this.meta.showing = this.adServerDebugResponse.items.length;
            }
        }
        return this.adServerDebugResponse;
    }

    private resolveLiveAssetNames(debugItems: AdServerDebugItem[]) {
        const grouped = _.groupBy(debugItems, 'channel_guid');
        const ids = _.keys(grouped);
        this.acceo
            .post()('/api/v3/adproxy/ad-debug/live-asset-name-mapping', {ids})
            .then(({mapping}) => {
                _.forEach(grouped, (items: AdServerDebugItem[], id: string) => {
                    items.forEach((i: AdServerDebugItem) => (i.channel_name = mapping[id] || 'N/A'));
                });
            })
            .catch(err => {
                _.forEach(grouped, (items: AdServerDebugItem[], id: string) => {
                    items.forEach((i: AdServerDebugItem) => (i.channel_name = id));
                    logger.error(`Could not fetch channel with ID: ${id}`, err);
                });
            });
    }
}
