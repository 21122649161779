/* eslint-disable camelcase */
/* eslint-disable no-dupe-class-members */
import {AureliaConfiguration} from 'aurelia-configuration';
import {HttpClient} from 'aurelia-fetch-client';
import {inject, LogManager, NewInstance, singleton} from 'aurelia-framework';
import {plainToClass, serialize} from 'class-transformer';
import {validate, ValidationError} from 'class-validator';
import {CONTENT_TYPE} from 'services/constants';
import {SessionService} from 'services/session';

import {DateToMsValueConverter} from '@bindable-ui/bindable';

const log = LogManager.getLogger('acceo');

interface RequestOptions {
  path: string;
  method: string;
  config: any;
}

export class AcceoError extends Error {
  public status_code: number;
  public details: any[];

  // eslint-disable-next-line default-param-last
  constructor(message: string, status_code: number = 0, details?: any[]) {
    super(message);
    this.name = 'AcceoError';
    this.status_code = status_code;
    this.details = details || [];
  }
}

@inject(NewInstance.of(HttpClient), AureliaConfiguration, SessionService)
@singleton()
export class Acceo {
  private session_fingerprint = '';
  constructor(public httpClient: HttpClient, public config: AureliaConfiguration, private session: SessionService) {
    this.httpClient.configure(params =>
      params
        .withDefaults({
          credentials: 'include',
          headers: {
            'Cms-Session-Token': this.session.sessionIdFingerprint,
          },
        })
        .withInterceptor({
          response: response => {
            const r = response.clone();
            if (response.status !== 204) {
              // No content status errors on json()
              response
                .json()
                .then(data => {
                  [
                    this.sessionInterceptor,
                    this.fingerprintInterceptor,
                  ].every(f => f(response.status, data));
                })
                .catch(err => {
                  log.error(err);
                });
            }
            return r;
          },
        }),
    );
  }

  public get<T>(cls?: new () => T): (path: string, config?: object) => Promise<T>;
  public get<T>(cls?: [new () => T]): (path: string, config?: object) => Promise<T[]>;
  public get<T>(cls?: (new () => T) | [new () => T]): (path: string, config?: object) => Promise<T | T[]> {
    return (path, config): Promise<T | T[]> => {
      /* (Array.isArray(cls)) check is used to let ts compiler know that cls could be type or [type] */
      if (Array.isArray(cls)) {
        return this.request({path, config, method: 'GET'}, cls);
      }
      return this.request({path, config, method: 'GET'}, cls);
    };
  }

  public patch<T, V>(cls?: new () => T): (path: string, body: V, config?: object) => Promise<T> {
    return (path, body, config): Promise<T> => this.request({path, config, method: 'PATCH'}, cls, body);
  }

  public post<T, V>(cls?: new () => T): (path: string, body: V, config?: object) => Promise<T>;
  public post<T, V>(cls?: [new () => T]): (path: string, body: V, config?: object) => Promise<T[]>;
  public post<T, V>(cls?: (new () => T) | [new () => T]): (path: string, body: V, config?: object) => Promise<T | T[]> {
    return (path, body, config): Promise<T | T[]> => {
      /* (Array.isArray(cls)) check is used to let ts compiler know that cls could be type or [type] */
      if (Array.isArray(cls)) {
        return this.request({path, config, method: 'POST'}, cls, body);
      }
      return this.request({path, config, method: 'POST'}, cls, body);
    };
  }

  public put<T, V>(cls?: new () => T): (path: string, body: V, config?: object) => Promise<T> {
    return (path, body, config): Promise<T> => this.request({path, config, method: 'PUT'}, cls, body);
  }

  public delete<T, V>(cls?: new () => T): (path: string, body?: V, config?: object) => Promise<T>;
  public delete<T, V>(cls?: new () => T): (path: string, body?: V, config?: object) => Promise<T[]>;
  public delete<T, V>(
    cls?: (new () => T) | [new () => T],
  ): (path: string, body?: V, config?: object) => Promise<T | T[]> {
    return (path, body, config): Promise<T | T[]> => {
      if (Array.isArray(cls)) {
        return this.request({path, config, method: 'DELETE'}, cls, body);
      }
      return this.request({path, config, method: 'DELETE'}, cls, body);
    };
  }

  public instantiate<T>(cls: new () => T, data): Promise<T>;
  public instantiate<T>(cls: [new () => T], data): Promise<T[]>;
  public instantiate<T>(cls: (new () => T) | [new () => T], data): Promise<T | T[]> {
    return new Promise((resolve, reject) => {
      if (Array.isArray(cls)) {
        const instances: T[] = plainToClass(cls[0], data as T[]);
        const promises = instances.map(i =>
          validate(i, {
            whitelist: true,
          }),
        );
        Promise.all(promises)
          .then(errs => {
            const errsFlattened: ValidationError[] = _.flatten(errs);
            if (errsFlattened.length && this.parseErrors(errsFlattened)) {
              const clazz = instances[0].constructor.name;
              reject(new AcceoError(`${clazz} Model Validation Error`));
            } else {
              resolve(instances);
            }
          })
          .catch(reject);
      } else {
        const instance: T = plainToClass(cls, data as object);
        validate(instance, {whitelist: true})
          .then(errs => {
            if (errs.length && this.parseErrors(errs)) {
              const clazz = instance.constructor.name;
              reject(new AcceoError(`${clazz} Model Validation Error`));
            } else {
              resolve(instance);
            }
          })
          .catch(reject);
      }
    });
  }

  private request<T, V>(options: RequestOptions, cls?: new () => T, body?: V): Promise<T>;
  private request<T, V>(options: RequestOptions, cls?: [new () => T], body?: V): Promise<T[]>;
  private request<T, V>(options: RequestOptions, cls?: (new () => T) | [new () => T], body?: V): Promise<T | T[]> {
    options.config = options.config || {};
    const {prefix, requestTransform, responseTransform, isFormData} = options.config;
    let payload: any = body;
    if (typeof requestTransform === 'function') {
      payload = requestTransform(body);
    }
    return new Promise((resolve, reject) => {
      const headers = {
        'Content-Type': isFormData ? CONTENT_TYPE.FORM : CONTENT_TYPE.JSON,
        ...options.config.headers,
      };
      const isFileUpload = headers['Content-Type'] === CONTENT_TYPE.SET_BY_BROWSER;
      if (isFileUpload) {
        delete headers['Content-Type'];
      }
      this.httpClient
        .fetch(options.path, {
          headers,
          body: !payload || typeof payload === 'string' || isFileUpload ? payload : serialize(payload),
          method: options.method,
        })
        .then(resp => {
          if (resp.ok) {
            if (resp.status === 204) {
              // No content
              return resolve();
            }
            return resp.json().then(content => {
              let data = content;

              // "now" is used in our response - if it isn't there hyperion is sending back
              // a date-timestamp in the "Last-Modified" header
              if (!data.now) {
                if (resp.headers.get('Last-Modified')) {
                  data.now = parseInt(DateToMsValueConverter.transform(resp.headers.get('Last-Modified')), 10);
                }
              }

              if (data && data.error) {
                const msg = data.msg || '';
                log.error(`API ${resp.status} Error: ${msg}`);
                return reject(new AcceoError(msg, data.status_code || 500, data.details || []));
              }

              if (typeof responseTransform === 'function') {
                // it is the responsibility of responseTransform to also do get prefix.
                data = responseTransform(content, payload);
              } else {
                data = prefix ? _.get(data, prefix) : content;
              }
              if (cls) {
                // if block below is there just to shut up typescript compiler.
                if (Array.isArray(cls)) {
                  return this.instantiate(cls, data).then(resolve).catch(reject);
                }
                return this.instantiate(cls, data).then(resolve).catch(reject);
              }
              // if no cls given, ignore schema return json data
              return resolve(data);
            });
          }
          return resp.text().then(text => {
            try {
              const data = JSON.parse(text);
              reject(
                new AcceoError(data.msg || data.description || data.title, data.status_code || 500, data.details || []),
              );
            } catch (err) {
              // If our session got yanked, reload to revalidate session
              if (text === 'Invalid cms session') {
                window.location.reload();
              }
              log.error(text);
              reject(text);
            }
          });
        })
        .catch(err => {
          log.error(err);
          reject(err);
        });
    });
  }

  // @ts-ignore to silence ts error on unused status parameter
  private fingerprintInterceptor = (status, data) => {
    if (this.session_fingerprint && this.session_fingerprint !== data.cms_session_fingerprint) {
      // DOTO: comment two lines below for ad-server-debug dev work
      window.localStorage.clear();
      window.location.reload();
    }
    this.session_fingerprint = data.cms_session_fingerprint;
    return true;
  };

  // eslint-disable-next-line class-methods-use-this
  private sessionInterceptor = (status, data) => {
    if (status < 400) {
      if (data.error && data.msg === 'Invalid session') {
        log.error('Invalid session; redirect to login');
        window.location.href = `${data.login}?login_redirect=${encodeURIComponent(window.location.href)}`;
        return false;
      }
    }
    return true;
  };

  private parseErrors(rawErrs: ValidationError[], errMap = {}, path = ''): boolean {
    rawErrs.forEach(e => {
      const {constraints} = e;
      const {children} = e;
      // e.target.constructor.name gives the instance Model name.
      const p = path ? `${path} > ${e.property}` : `${e.target.constructor.name} > ${e.property}`;
      if (constraints) {
        Object.keys(constraints).forEach(key => {
          const msgKey = `${p}: ${constraints[key]}`;
          if (!errMap[msgKey]) errMap[msgKey] = 0;
          errMap[msgKey] += 1;
        });
      }
      if (children && children.length) {
        this.parseErrors(children, errMap, p);
      }
    });
    // only log from the highest level.
    if (!path) {
      const errors = Object.keys(errMap).map(msg => `${errMap[msg] > 1 ? `(${errMap[msg]}x) ` : ''}${msg}`);
      if (this.config.is('development')) {
        errors.forEach(err => log.error(err));
        // return true to break the app, which could be desirable in dev environment.
        return false;
      }
      errors.forEach(err => log.error(err));
      return false;
    }
    return true;
  }
}
