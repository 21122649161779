import {CmsHttpClient} from 'services/cms-http-client';
import {inject, LogManager} from 'aurelia-framework';
import {json} from 'aurelia-fetch-client';

const log = LogManager.getLogger('settings-data');

const SETTINGS_ERROR_ALL = 'Error getting settings';
const SETTINGS_ERROR_TEST_ERROR_URL = 'Error testing callback service URL';
const SETTINGS_ERROR_UPDATE_ERROR_URL = 'Error saving callback service URL';
const SETTINGS_ERROR_TEST_LOG_SETTINGS = 'Error testing log pushing settings';
const SETTINGS_ERROR_UPDATE_LOG_SETTINGS = 'Error saving log pushing settings';
const SETTINGS_ERROR_UPDATE_TOKEN_SETTINGS = 'Error saving playback tokens settings';
const SETTINGS_ERROR_TEST_TOKEN_URL = 'Error testing playback tokens';
const SETTINGS_ERROR_GET_SUBOWNERS = 'Error getting subowners';

@inject(CmsHttpClient)
export class SettingsData {
    constructor(cmsHttpClient) {
        this.httpClient = cmsHttpClient.httpClient;

        // URL's
        this.formURLEncoded = 'application/x-www-form-urlencoded; charset=UTF-8';
        this.settingsAllUrl = '/settings/all/';
        this.settingsErrorsTestURL = '/settings/errors/test/';
        this.settingsErrorsUpdateURL = '/settings/errors/update/';
        this.settingsLogsTest = '/settings/pushlogs/test/';
        this.settingsLogsUpdate = '/settings/pushlogs/update/';
        this.settingsTokensUpdate = '/settings/tokens/update/';
        this.settingsTokensTest = '/settings/tokens/test/';
        this.settingsTokensGetSubowners = '/settings/tokens/subowners/';

        // Properties
    }

    getSettings() {
        const promise = new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.settingsAllUrl, {
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(SETTINGS_ERROR_ALL));
                    }
                })
                .catch(e => {
                    log.error(e);
                    reject(new Error(SETTINGS_ERROR_ALL));
                });
        });
        return promise;
    }

    testErrorCallBack(url) {
        const params = `url=${encodeURIComponent(url)}`;
        const promise = new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.settingsErrorsTestURL, {
                    method: 'post',
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    type: this.formURLEncoded,
                    body: params,
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(SETTINGS_ERROR_TEST_ERROR_URL));
                    }
                })
                .catch(e => {
                    log.error(e);
                    reject(new Error(SETTINGS_ERROR_TEST_ERROR_URL));
                });
        });
        return promise;
    }

    saveErrorCallBack(item) {
        const params = `item=${json(item)}`;
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.settingsErrorsUpdateURL, {
                    method: 'post',
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    body: params,
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            resolve(result);
                        });
                    } else {
                        reject(new Error(SETTINGS_ERROR_UPDATE_ERROR_URL));
                    }
                })
                .catch(e => {
                    log.error(e);
                    reject(new Error(SETTINGS_ERROR_UPDATE_ERROR_URL));
                });
        });
    }

    testLogSettings(settings) {
        const params = `item=${encodeURIComponent(json(settings))}`;
        const promise = new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.settingsLogsTest, {
                    method: 'post',
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    body: params,
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            resolve(result);
                        });
                    } else {
                        reject(new Error(SETTINGS_ERROR_TEST_LOG_SETTINGS));
                    }
                })
                .catch(e => {
                    log.error(e);
                    reject(new Error(SETTINGS_ERROR_TEST_LOG_SETTINGS));
                });
        });
        return promise;
    }

    updateLogSettings(settings) {
        const params = `item=${encodeURIComponent(json(settings))}`;
        const promise = new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.settingsLogsUpdate, {
                    method: 'post',
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    body: params,
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            resolve(result);
                        });
                    } else {
                        reject(new Error(SETTINGS_ERROR_UPDATE_LOG_SETTINGS));
                    }
                })
                .catch(e => {
                    log.error(e);
                    reject(new Error(SETTINGS_ERROR_UPDATE_LOG_SETTINGS));
                });
        });
        return promise;
    }

    updateTokenSettings(settings) {
        const params = `item=${encodeURIComponent(json(settings))}`;
        const promise = new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.settingsTokensUpdate, {
                    method: 'post',
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    body: params,
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            resolve(result);
                        });
                    } else {
                        reject(new Error(SETTINGS_ERROR_UPDATE_TOKEN_SETTINGS));
                    }
                })
                .catch(e => {
                    log.error(e);
                    reject(new Error(SETTINGS_ERROR_UPDATE_TOKEN_SETTINGS));
                });
        });
        return promise;
    }

    testPlaybackTokens(url) {
        const params = `url=${encodeURIComponent(url)}`;
        const promise = new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.settingsTokensTest, {
                    method: 'post',
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    type: this.formURLEncoded,
                    body: params,
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            resolve(result);
                        });
                    } else {
                        reject(new Error(SETTINGS_ERROR_TEST_TOKEN_URL));
                    }
                })
                .catch(e => {
                    log.error(e);
                    reject(new Error(SETTINGS_ERROR_TEST_TOKEN_URL));
                });
        });
        return promise;
    }

    getSubowners() {
        const promise = new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.settingsTokensGetSubowners, {
                    method: 'get',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(SETTINGS_ERROR_GET_SUBOWNERS));
                    }
                })
                .catch(e => {
                    log.error(e);
                    reject(new Error(SETTINGS_ERROR_GET_SUBOWNERS));
                });
        });
        return promise;
    }
}
