import {autoinject} from 'aurelia-framework';
import {authorizationConstants} from 'services/authorization';
import {SessionService} from 'services/session';

const ENVS = ['downlynk', 'uplynk'];
// anything containing localhost in the domain
// or if neither downlynk or uplynk is found will default to localhost ENV
const ENV = ENVS.find(str => window.location.hostname.indexOf(str) >= 0) || 'localhost';

@authorizationConstants
@autoinject()
export class ServicesIndex {
    public oneReporting = {
        downlynk: 'https://analytics.downlynk.com',
        uplynk: 'https://analytics.uplynk.com',
    }[ENV];

    public newMonitoring = {
        downlynk: 'https://monitor.downlynk.com',
        uplynk: 'https://monitor.uplynk.com',
    }[ENV];

    constructor(public session: SessionService) {}
}
