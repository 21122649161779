import {AuthorizeStep} from 'services/auth';

export class App {
  configureRouter(config, router) {
    config.addPipelineStep('authorize', AuthorizeStep);
    this.router = router;
    config.title = 'Pattern Library';
    config.map([
      {
        route: '',
        name: 'intro',
        moduleId: PLATFORM.moduleName('./patterns/intro/index', 'pattern-library'),
        nav: true,
        title: 'Intro',
      },
      {
        route: 'accordions',
        name: 'accordions',
        moduleId: PLATFORM.moduleName('./patterns/accordions/index', 'pattern-library'),
        nav: true,
        title: 'Accordions',
      },
      {
        route: 'audio-levels',
        name: 'audio-levels',
        moduleId: PLATFORM.moduleName('./patterns/audio-levels/index', 'pattern-library'),
        nav: true,
        title: 'Audio Levels',
      },
      {
        route: 'boxes',
        name: 'boxes',
        moduleId: PLATFORM.moduleName('./patterns/boxes/index', 'pattern-library'),
        nav: true,
        title: 'Boxes',
      },
      {
        route: 'branding',
        name: 'branding',
        moduleId: PLATFORM.moduleName('./patterns/branding/index', 'pattern-library'),
        nav: true,
        title: 'Branding',
      },
      {
        route: 'buttons',
        name: 'buttons',
        moduleId: PLATFORM.moduleName('./patterns/buttons/index', 'pattern-library'),
        nav: true,
        title: 'Buttons',
      },
      {
        route: 'colors',
        name: 'colors',
        moduleId: PLATFORM.moduleName('./patterns/colors/index', 'pattern-library'),
        nav: true,
        title: 'Colors',
      },
      {
        route: 'copy',
        name: 'copy',
        moduleId: PLATFORM.moduleName('./patterns/copy/index', 'pattern-library'),
        nav: true,
        title: 'Copy',
      },
      {
        route: 'flex',
        name: 'flex',
        moduleId: PLATFORM.moduleName('./patterns/flex/index', 'pattern-library'),
        nav: true,
        title: 'Flex',
      },
      {
        route: 'forms',
        name: 'forms',
        moduleId: PLATFORM.moduleName('./patterns/forms/index', 'pattern-library'),
        nav: true,
        title: 'Forms',
      },
      {
        route: 'grids',
        name: 'grids',
        moduleId: PLATFORM.moduleName('./patterns/grids/index', 'pattern-library'),
        nav: true,
        title: 'Grids',
      },
      {
        route: 'icons',
        name: 'icons',
        moduleId: PLATFORM.moduleName('./patterns/icons/index', 'pattern-library'),
        nav: true,
        title: 'Icons',
      },
      {
        route: 'layouts',
        name: 'layouts',
        moduleId: PLATFORM.moduleName('./patterns/layouts/index', 'pattern-library'),
        nav: true,
        title: 'Layouts',
      },
      {
        route: 'lists',
        name: 'lists',
        moduleId: PLATFORM.moduleName('./patterns/lists/index', 'pattern-library'),
        nav: true,
        title: 'Lists',
      },
      {
        route: 'media-objects',
        name: 'media-objects',
        moduleId: PLATFORM.moduleName('./patterns/media-objects/index', 'pattern-library'),
        nav: true,
        title: 'Media Objects',
      },
      {
        route: 'modals',
        name: 'modals',
        moduleId: PLATFORM.moduleName('./patterns/modals/index', 'pattern-library'),
        nav: true,
        title: 'Modals',
      },
      {
        route: 'navs',
        name: 'navs',
        moduleId: PLATFORM.moduleName('./patterns/navs/index', 'pattern-library'),
        nav: true,
        title: 'Navs',
      },
      {
        route: 'notifications',
        name: 'notifications',
        moduleId: PLATFORM.moduleName('./patterns/notifications/index', 'pattern-library'),
        nav: true,
        title: 'Notifications',
      },
      {
        route: 'spinners',
        name: 'spinners',
        moduleId: PLATFORM.moduleName('./patterns/spinners/index', 'pattern-library'),
        nav: true,
        title: 'Spinners',
      },
      {
        route: 'tables',
        name: 'tables',
        moduleId: PLATFORM.moduleName('./patterns/tables/index', 'pattern-library'),
        nav: true,
        title: 'Tables',
      },
      {
        route: 'tips',
        name: 'tips',
        moduleId: PLATFORM.moduleName('./patterns/tips/index', 'pattern-library'),
        nav: true,
        title: 'Tips',
      },
      {
        route: 'type',
        name: 'type',
        moduleId: PLATFORM.moduleName('./patterns/type/index', 'pattern-library'),
        nav: true,
        title: 'Type',
      },
      {
        route: 'utilities',
        name: 'utilities',
        moduleId: PLATFORM.moduleName('./patterns/utilities/index', 'pattern-library'),
        nav: true,
        title: 'Utilities',
      },
    ]);
  }
}
