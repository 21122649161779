import {CTableActions, CTableCol} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';

import {AUDIENCE_TYPE} from '../../../audiences/models/models';
import {AudiencesService} from '../../../audiences/services/audiences';
import {HyperionFilterParams} from '../../../models/models';

@autoinject()
export class ChangeAudience {
    public AUDIENCE_TYPE = AUDIENCE_TYPE;
    public searchText: string = '';
    public isLoadingAudience: boolean = false;
    public usingSearch: boolean = false;

    public actions: CTableActions = {
        onScrollBottom: () => this.service.getMoreAudiences(),
        rowClick: row => {
            this.service.audiences.forEach(r => {
                if (r.id !== row.id) {
                    r._class = '';
                }
            });
            row._class = 'active';
            // TODO: ask Luke to see if active row can be set by the component itself on row click.
            this.setCurrentAudience(row);
        },
        sortColumn: (col: CTableCol, reverse: boolean) => {
            this.getAudiences({
                order: `${reverse ? '-' : ''}${col.colHeadName}`,
                search: this.searchText,
            });
        },
    };

    public model: any;
    public service: AudiencesService;
    public tableColumns: any[] = [
        {
            colHeadName: 'desc',
            colHeadValue: 'Name',
            sort: true,
        },
        {
            colHeadName: 'type',
            colHeadValue: 'Type',
            sort: true,
        },
        {
            colHeadName: 'match_type',
            colHeadValue: 'Match',
            sort: true,
        },
    ];

    public activate(model) {
        this.model = model.body;
        this.service = this.model.audiencesService;
        this.service.searchQuery = '';
        this.getAudiences({order: 'desc'});
    }

    public async setCurrentAudience(audience) {
        if (audience.type === AUDIENCE_TYPE.MULTIPLE && audience.sub_audiences.length) {
            this.isLoadingAudience = true;
            await this.service.getAudience(audience.id);
            this.isLoadingAudience = false;
        } else {
            this.service.currentModel = audience;
        }
    }

    public async getAudiences(params: HyperionFilterParams = {}) {
        await this.service.getAudiences(params);
        this.service.currentModel = null;
        if (this.model.audienceId) {
            const audience = _.find(this.service.audiences, a => a.id === this.model.audienceId);
            if (audience) {
                audience._class = 'active';
                this.setCurrentAudience(audience);
            }
        }
    }

    public async search(textValue = '') {
        this.usingSearch = !!textValue;
        const params: HyperionFilterParams = {};
        if (textValue) {
            params.search = textValue;
        }
        this.searchText = textValue;
        await this.getAudiences(params);
    }
}
