import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {ProfilesResponseItem, ProfilesResponseItems} from '../models/profiles';

@autoinject
export class ProfilesService {
  private url: string = '/api/v4/profiles';

  constructor(public acceo: Acceo) {}

  public get() {
    return this.acceo.get()(this.url) as Promise<ProfilesResponseItems>;
  }

  public getById(id: string) {
    return this.acceo.get(ProfilesResponseItem)(`${this.url}/${id}`);
  }

  public getDefault() {
    return this.acceo.get()(`${this.url}/default`) as Promise<ProfilesResponseItems>;
  }
}
