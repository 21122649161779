import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {RuleType} from '../models/fields-models';
import {MultiCdnSettingsConfig, Profile, RuleSetId} from '../models/multi-cdn-settings-config';

const ERROR = {
    isDup: false,
};

export const ERROR_OBJ = {
    hasErr: false,
    name: {...ERROR},
};

interface ErrorObject {
    isDup?: boolean;
    hasErr?: boolean;
}

interface ErrorObjects {
    name: ErrorObject;
    hasErr: boolean;
}

interface ErrorMessages {
    [index: string]: ErrorObjects;
}

@autoinject()
export class RuleSetTable {
    @bindable
    public multiCdnSettings: MultiCdnSettingsConfig;

    @bindable
    public currentProfile: Profile;

    @bindable
    public ruleSetIds: RuleSetId[];

    @bindable
    public fields: string[];

    @bindable
    public ruleType: RuleType;

    @bindable
    public callbacks;

    @bindable
    public validationErrors: ValidationError[];

    public hasErr: boolean;
    public errorMessages: ErrorMessages = {};
    public ruleSetIsDuplicate: boolean = false;
    public ruleSetNameRequired: boolean = false;
    public newRuleSetName: string;

    public addNewRuleSet(): void {
        this.ruleSetNameRequired = false;
        this.ruleSetIsDuplicate = false;
        if (this.newRuleSetName.trim() === '') {
            this.ruleSetNameRequired = true;
            return;
        }

        for (const ruleSet of this.ruleSetIds) {
            if (ruleSet.name === this.newRuleSetName.trim()) {
                this.ruleSetIsDuplicate = true;
                return;
            }
        }

        this.ruleSetIsDuplicate = false;
        const setId = new RuleSetId();
        setId.name = this.newRuleSetName.trim();
        this.ruleSetIds.push(setId);

        // clear out the values & close the tip
        this.ruleSetIsDuplicate = false;
        this.ruleSetNameRequired = false;
        this.newRuleSetName = this.multiCdnSettings.ruleSets[0].name;
        this.callbacks.onChange();
    }

    public deleteRuleSet(index: number): void {
        if (this.currentProfile.isDefault) {
            return;
        }

        this.ruleSetIds.splice(index, 1);
        this.callbacks.onChange();
    }

    // validate if not disable save, show error
    public checkIsValid(target: string, index: number): void {
        const value = _.get(this.ruleSetIds[index], target);
        const itemError = {..._.cloneDeep(ERROR_OBJ)};
        this.errorMessages[index] = this.errorMessages[index] || {..._.cloneDeep(ERROR_OBJ)};
        const copy = _.cloneDeep(this.errorMessages[index]);
        this.errorMessages[index] = {
            ...copy,
            [target]: this.validateRuleSetName(target, value, itemError, index)[target],
            hasErr: this.validateRuleSetName(target, value, itemError, index).hasErr,
        };
        this.hasErr = this.checkHasErr(this.errorMessages);

        this.callbacks.onChange();
    }

    public checkHasErr(errorMessages: ErrorMessages): boolean {
        if (errorMessages) {
            const test = !_.every(Object.keys(errorMessages) || [], index => !_.get(errorMessages[index], 'hasErr'));
            return !!test;
        }
        return false;
    }

    public validateRuleSetName(target: string, value: string, itemError: ErrorObjects, index?: number): ErrorObjects {
        if (target === 'name') {
            const dupIndex = [];
            // when index is undefined
            this.ruleSetIds.forEach((ruleSet, idx) => {
                if (ruleSet.name === value && idx !== index) {
                    dupIndex.push(idx);
                }
            });
            if (dupIndex.length) {
                dupIndex.forEach(i => {
                    // this if statement didnt initialize the errorObject
                    let ithErrorObj = this.errorMessages[i];
                    if (_.isEmpty(ithErrorObj)) {
                        ithErrorObj = {..._.cloneDeep(ERROR_OBJ)};
                    }
                    ithErrorObj.name.isDup = true;
                    ithErrorObj.hasErr = true;
                    this.errorMessages[i] = ithErrorObj;
                });
                // this line will take care of new param
                itemError.name.isDup = true;
                itemError.hasErr = true;
            } else {
                // this will also take care of the new param
                itemError.name.isDup = false;
                itemError.hasErr = false;
                const indexes = Object.keys(this.errorMessages);
                indexes.forEach(idx => {
                    const ithErrorObj = this.errorMessages[idx];
                    if (_.get(ithErrorObj, 'name.isDup', '')) {
                        ithErrorObj.name.isDup = false;
                        ithErrorObj.hasErr = false;
                    }
                    this.errorMessages[idx] = ithErrorObj;
                });
            }
        }
        return itemError;
    }

    get isDefaultProfile() {
        if (this.currentProfile) {
            if (this.currentProfile.isDefault) {
                return true;
            }
        }
        return false;
    }
}
