export class SnsModal {
    public shared: any;
    public topic: any;

    public activate(model) {
        this.shared = model.shared;
        this.topic = model.shared.snsTopic;
    }

    public detached() {
        this.shared.snsTopic = this.topic;
    }
}
