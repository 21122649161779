import {Type} from 'class-transformer';
import {IsBoolean, IsDefined, IsInt, IsNotEmpty, IsOptional, IsString, ValidateNested} from 'class-validator';
import {IHyperion, IMSODesc, IMSOId, IMSOOwner, IMSOSession, IMSOTimestamp, IMSOTotalItems} from './mso';

// Templates common properties

export enum TemplatesAccess {
    PRIVATE = 'private',
    PUBLIC = 'public',
}

interface ITemplatesAccess {
    /**
     * The access restrictions associated with this Template (private/public)
     */
    access: TemplatesAccess | string;
}

interface ITemplatesConfig {
    config: string;
}

interface ITemplatesDefault {
    /**
     * Indicates the user designated this Templates object as their "default", meaning
     * it will be automatically selected unless specified otherwise in Slicer create/update UI
     */
    default: boolean;
}

interface ITemplatesFavorite {
    /**
     * Indicates the user designated this Templates object as a "favorite",
     * meaning it should always display at the top of a Templates list
     */
    favorite: boolean;
}

interface ITemplatesName {
    name: string;
}

interface ITemplatesParent {
    /**
     * The ID of another Templates object to inherit configurations from
     * when this Template is applied to a Slicer
     */
    parent: string;
}

// Templates request: patch/post

interface ITemplatesRequest
    extends IMSODesc,
        ITemplatesAccess,
        ITemplatesConfig,
        ITemplatesDefault,
        ITemplatesFavorite,
        ITemplatesName,
        ITemplatesParent {}

export class TemplatesRequest implements ITemplatesRequest {
    @IsDefined()
    @IsNotEmpty()
    @IsString()
    public access: TemplatesAccess;

    @IsDefined()
    @IsNotEmpty()
    public config: string;

    @IsBoolean()
    public default: boolean;

    @IsString()
    public desc: string;

    @IsBoolean()
    public favorite: boolean;

    @IsDefined({message: 'Template name is required'})
    @IsNotEmpty({message: 'Template name is required'})
    @IsString()
    public name: string;

    @IsOptional()
    @IsString()
    public parent: string;
}

export class TemplatesRequestErrors {
    public access: string;
    public config: string;
    public default: string;
    public desc: string;
    public favorite: string;
    public name: string;
    public parent: string;
}

// Templates response: get/getById

export interface ITemplatesResponseItem
    extends IMSODesc,
        IHyperion,
        IMSOId,
        IMSOOwner,
        IMSOTimestamp,
        ITemplatesAccess,
        ITemplatesConfig,
        ITemplatesDefault,
        ITemplatesFavorite,
        ITemplatesName,
        ITemplatesParent {}

export class TemplatesResponseItem implements ITemplatesResponseItem {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsDefined()
    @IsNotEmpty()
    @IsString()
    public access: TemplatesAccess;

    @IsDefined()
    public config: string;

    @IsDefined()
    @IsNotEmpty()
    @IsString()
    public created: string;

    @IsBoolean()
    @IsDefined()
    public default: boolean;

    @IsOptional()
    @IsString()
    public desc: string;

    @IsBoolean()
    @IsOptional()
    public favorite: boolean;

    @IsDefined()
    @IsNotEmpty()
    @IsString()
    public id: string;

    @IsDefined()
    @IsNotEmpty()
    @IsString()
    public lastmod: string;

    @IsDefined()
    @IsNotEmpty()
    @IsString()
    public name: string;

    @IsDefined()
    @IsNotEmpty()
    @IsString()
    public owner: string;

    @IsOptional()
    @IsString()
    public parent: string;
}

export class TemplatesResponseItems implements IHyperion, IMSOSession, IMSOTotalItems {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsString()
    public cms_session_id_fingerprint: string;

    @ValidateNested()
    @Type(() => TemplatesResponseItem)
    public items: TemplatesResponseItem[];

    @IsDefined()
    @IsInt()
    public total_items: number;
}

// Templates response: delete

export class TemplateDeleteResponse implements IHyperion, IMSOSession {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public message: string;
}
