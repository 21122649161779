export interface IFieldRule {
    rule: (model: any) => boolean;
    message: string;
}

export interface IFieldParams {
    errors?: any[];
    isDirty?: boolean;
    isDisabled?: boolean;
    meta?: any;
    rules?: IFieldRule[];
}

export default class Field {
    public key: string;
    public errors = [];
    public isDirty: boolean = false;
    public isDisabled: boolean = false;
    public meta = {};
    public rules = [];

    /**
     * Constructs Field instance with provided attributes.
     *
     * @param key {String} (required) - Field slug. Should match model key.
     * @param errors {Array} - List of error message strings.
     * @param isDirty {Boolean} - `true` if field value has changed.
     * @param isDisabled {Boolean} - `true` if field input should be disabled.
     * @param meta {Object} - Container for arbitrary information related to the field.
     * @param rules {Array} - List of validation rules used to determine if field is valid.
     *
     * Example rule for required text field:
     *   {
     *       rule(model) {
     *           const value = model.<field.key>;
     *           let isValid = true;
     *
     *           if (!value || !value.length) {
     *               isValid = false;
     *           }
     *
     *           return isValid;
     *       },
     *       message: 'This field is required.',
     *   }
     */
    constructor(key, {errors = [], isDirty = false, isDisabled = false, meta = {}, rules = []}: IFieldParams = {}) {
        this.key = key;
        this.errors = errors;
        this.isDirty = isDirty;
        this.isDisabled = isDisabled;
        this.meta = meta;
        this.rules = rules;
    }
}
