import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable, computedFrom, LogManager, PLATFORM} from 'aurelia-framework';
import {validate, ValidationError} from 'class-validator';
import {MetadataSchema, SchemaProperty, SchemaPropertyRestriction, SchemaPropertyType} from '../models/metadata';
import {MetadataSchemaEvent} from '.';

const log = LogManager.getLogger('live-events-settings-metadata-schema-panel');

@autoinject()
export class MetadataSchemaPanel {
  @bindable
  public schema: MetadataSchema;

  @bindable
  public validationErrors: ValidationError[];

  public readonly SchemaPropertyType = SchemaPropertyType;
  public readonly SchemaPropertyRestriction = SchemaPropertyRestriction;
  public newKey: SchemaProperty;
  public newKeyErrors: ValidationError[];
  public newKeys: {[keyName: string]: boolean} = {};
  public callbacks: {[funcName: string]: () => void} = {
    onChange: () => {
      this.eventAggregator.publish(MetadataSchemaEvent.CHANGED);
    },
  };

  public newKeyPopover: any;
  public metadataKeyInput: HTMLInputElement;
  public keyDeleteConfirm = PLATFORM.moduleName('apps/cms/routes/settings/live-events/metadata/key-delete-confirm');

  private subscriptions: Subscription[] = [];

  constructor(public eventAggregator: EventAggregator) {}

  public attached() {
    this.subscriptions.push(
      this.eventAggregator.subscribe(MetadataSchemaEvent.KEY_DELETED, this.onKeyDeleted.bind(this)),
    );
  }

  public async createMetadataKey(event: MouseEvent) {
    event.preventDefault();
    return validate(this.newKey, {skipMissingProperties: true}).then((errs: ValidationError[]) => {
      if (errs.length) {
        errs.forEach(err => log.error(String(err)));
        this.newKeyErrors = errs;
      } else {
        this.schema.keys.push(this.newKey);
        this.newKeys[this.newKey.name] = true;
        this.newKeyPopover.hide();
        this.eventAggregator.publish(MetadataSchemaEvent.CHANGED);
      }
    });
  }

  public initNewKey() {
    this.newKey = new SchemaProperty();
    this.newKey.existingKeyNames = this.schema.keys.map(_ => _.name);
    this.newKeyErrors = null;
  }

  public onSelectRequireClick(key: SchemaProperty, restriction: SchemaPropertyRestriction) {
    key.restriction = restriction;
    this.callbacks.onChange();
  }

  @computedFrom('validationErrors')
  public get keyValidationErrors() {
    if (!Array.isArray(this.validationErrors)) return;
    const errs = this.validationErrors.reduce((agg, val) => {
      if (val.property === 'keys') {
        if (val.children.length) {
          val.children.forEach(value => {
            agg[value.property] = value.children;
          });
        }
      }
      return agg;
    }, {});
    return errs;
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }

  private onKeyDeleted(keyIndex: number) {
    this.schema.keys.splice(keyIndex, 1);
    this.eventAggregator.publish(MetadataSchemaEvent.CHANGED);
  }
}
