import {computedFrom, inject} from 'aurelia-framework';
import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';
import {SettingsData} from '../services/settings-data';
import {BaseSettings} from '../base';

@inject(Notification, SettingsData, SessionService)
export class ErrorReporting extends BaseSettings {
    constructor(...rest) {
        super(...rest);

        // Properites used in the model
        this.originalCallBackURL = '';
        this.callbackURL = '';
        this.testURLResultsItems = [];
    }

    initializeProperties() {
        this.originalCallBackURL = this.model.errors.cb_url;
        this.callbackURL = this.model.errors.cb_url;
    }

    saveSettings() {
        this.isSaving = true;
        const modifiedData = {cb_url: this.callbackURL};
        this.settingsData
            .saveErrorCallBack(modifiedData)
            .then(data => {
                if (data.error === 0) {
                    this.originalCallBackURL = data.item.cb_url;
                    this.callbackURL = data.item.cb_url;
                    this.notification.success('Error callback service URL updated');
                }
            })
            .catch(() => {})
            .then(() => {
                this.isSaving = false;
            });
    }

    testURL() {
        this.settingsData.testErrorCallBack(this.callbackURL).then(data => {
            this.testURLResults = data.items;
            this.testUrlResultModal.open();
        });
    }

    /* ---------------------------------------------------------------------- *\
     * Computed Properties
    \* ---------------------------------------------------------------------- */

    @computedFrom('originalCallBackURL', 'callbackURL')
    get isModified() {
        return this.originalCallBackURL !== this.callbackURL;
    }
}
