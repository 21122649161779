import {Type, Exclude} from 'class-transformer';
import {IsString, IsNumber, IsBoolean, IsOptional, ValidateNested} from 'class-validator';

import {IdsIdentity} from './ids-identity';
import {IdsTenant} from './ids-tenant';
import {IdsPolicy} from './ids-policy';

export class IdsUser {
  @IsBoolean()
  public disable_mfa: boolean;

  @IsBoolean()
  public disable_password_expiration: boolean;

  @IsString()
  public email: string;

  @IsOptional()
  @IsString()
  public first_name: string;

  @IsBoolean()
  public force_mfa: boolean;

  @IsBoolean()
  public force_password_reset: boolean;

  @IsString()
  public id: string;

  @IsBoolean()
  public is_email_verified: boolean;

  @IsOptional()
  @IsString()
  public last_name: string;

  @IsOptional()
  @IsString()
  public username: string;

  @IsOptional()
  public metadata: {[key: string]: any};

  @ValidateNested()
  @Type(() => IdsIdentity)
  @Exclude({toPlainOnly: true})
  public identities: IdsIdentity[];

  @ValidateNested()
  @Type(() => IdsTenant)
  @Exclude({toPlainOnly: true})
  public tenants: IdsTenant[];

  @ValidateNested()
  @Type(() => IdsPolicy)
  @Exclude({toPlainOnly: true})
  public policies: IdsPolicy[];
}

export class IdsUsersResponse {
  @ValidateNested()
  @Type(() => IdsUser)
  public items: IdsUser[];

  @IsNumber()
  public total_items: number;
}
