import {autoinject, computedFrom, observable, BindingEngine} from 'aurelia-framework';
import {CToastsService} from '@bindable-ui/bindable';

import * as moment from 'moment';

import {IFieldValidations, FormValidator} from 'apps/cms/utils/form-validator';
import {ITrackedModel, trackedModel} from 'apps/cms/utils/tracked-model';

import {IToggleGroup} from 'components/toggle-group/c-toggle-group';

import {RevisionFieldValidators, KeyFieldValidators} from '../../models/validators';
import {WorkspaceKey} from '../../models/workspace-key';
import {WorkspaceKeyRevision, IWorkspaceRevisionParams} from '../../models/workspace-key-revision';

import {WorkspaceKeyService, RevisionAction} from '../../services/workspace-key-service';
import {DialogBase} from '../base';

export interface IActions {
  onClose: () => void;
}

export interface IKeyInfoModel {
  model: WorkspaceKey;
  revisionId?: string;
  actions: IActions;
}

export interface ITrackedWorkspaceKey extends WorkspaceKey, ITrackedModel {}
export interface ITrackedWorkspaceRevision extends WorkspaceKeyRevision, ITrackedModel {}

@autoinject()
export class EditKey extends DialogBase {
  public actions: IActions;
  public model: ITrackedWorkspaceKey;
  public revision: ITrackedWorkspaceRevision;

  public createRevisionEnabled: boolean;
  public saveEnabled: boolean;

  public keyFormValidator: FormValidator;
  public revFormValidator: FormValidator;

  public groups: IToggleGroup[];
  public invalidAfter;
  public isPast: boolean;

  @observable()
  public selectedRevision: string;

  private keyValidators: IFieldValidations = {
    desc: KeyFieldValidators.desc,
    title: KeyFieldValidators.title,
  };

  private revisionValidators: IFieldValidations = {
    domain: RevisionFieldValidators.domain,
    invalid_after: RevisionFieldValidators.invalid_after,
    scope: RevisionFieldValidators.scope,
  };

  @computedFrom('model.isDirty', 'revision.isDirty', 'keyService.isProcessing', 'saveEnabled')
  public get saveDisabled() {
    const isDirty = this.model?.isDirty || this.revision?.isDirty;
    return !this.saveEnabled || !isDirty || this.keyService.isProcessing;
  }

  @computedFrom('keyFormValidator.errors.title', 'keyFormValidator.errors.desc')
  public get hasDetailsErrors() {
    if (!this.keyFormValidator?.errors) {
      return false;
    }

    const {title, desc} = this.keyFormValidator.errors;

    return !!title || !!desc;
  }

  @computedFrom('revFormValidator.errors.scope')
  public get hasScopeErrors() {
    if (!this.revFormValidator?.errors) {
      return false;
    }

    const {scope} = this.revFormValidator.errors;

    return !!scope;
  }

  @computedFrom('revFormValidator.errors.domain', 'revFormValidator.errors.invalid_after')
  public get hasAdvancedErrors() {
    if (!this.revFormValidator?.errors) {
      return false;
    }

    const {domain, invalid_after} = this.revFormValidator.errors;

    return !!domain || !!invalid_after;
  }

  constructor(
    public bindingEngine: BindingEngine,
    public keyService: WorkspaceKeyService,
    public notificationService: CToastsService,
  ) {
    super(notificationService);
  }

  /*
    Aurelia Hooks
  */

  public async activate(data: IKeyInfoModel) {
    const {actions, model, revisionId} = data;

    this.actions = actions;
    this.model = trackedModel({
      trackedKeys: [
        'desc',
        'title',
      ],
      SuperClass: WorkspaceKey,
      model,
    });

    this.keyFormValidator = new FormValidator(this.model);
    this.keyFormValidator.register(this.keyValidators, this.bindingEngine, () => this.isValid());

    const {revisions = []} = this.model;

    this.saveEnabled = false;
    this.createRevisionEnabled = false;

    this.groups = [...this.keyService.scopes];

    // will call selectedRevisionChanged
    this.selectedRevision = revisionId || revisions[0]?.id;
    this.initRevision();
  }

  /*
    Public Methods
  */

  public onRevisionClick(event: any) {
    event.preventDefault();

    let revId = null;
    const li = event.target.closest('li');

    if (li) {
      revId = li.dataset.id;
    }

    if (!revId || revId === this.selectedRevision) {
      return;
    }

    this.selectedRevision = revId;
    this.initRevision();
  }

  public async activateRevision() {
    try {
      await this.keyService.performRevisionAction(this.model.id, [this.revision.id], RevisionAction.Activate);
      this.revision.deleted = null;
      this.notificationService.success('Activated');
      const rev = this.model.revisions.find(r => r.id === this.revision.id);
      rev.deleted = this.revision.deleted;
    } catch (err) {
      this.notificationService.error(err.message);
    }
  }

  public closeDialog(e?, discard = false) {
    e?.preventDefault();

    if (e?.detail?.source === 'overlay') {
      return;
    }

    if (!discard && (this.model.isDirty || this.revision.isDirty)) {
      this.notificationService.warning('You have unsaved changes.');
      return;
    }

    this.actions.onClose();
  }

  public async deactivateRevision() {
    try {
      await this.keyService.performRevisionAction(this.model.id, [this.revision.id], RevisionAction.Deactivate);
      this.revision.deleted = moment().toISOString();
      this.notificationService.success('Deactivated');
      const rev = this.model.revisions.find(r => r.id === this.revision.id);
      rev.deleted = this.revision.deleted;
    } catch (err) {
      this.notificationService.error(`Unable to deactivate revision: ${err.message}`);
    }
  }

  public async saveChanges() {
    if (this.keyService.isProcessing) {
      return;
    }

    if (this.model.isDirty) {
      this.keyFormValidator.validate();
    }

    if (this.revision.isDirty) {
      this.revFormValidator.validate();
    }

    if (!this.isValid()) {
      this.notificationService.error('Please fix any issues before saving');
      return;
    }

    try {
      if (this.model.isDirty) {
        await this.keyService.updateKey(this.model.id, this.model.dirtyParams());
        this.model.updateCanonical();
      }

      if (this.revision.isDirty) {
        const params: IWorkspaceRevisionParams = {
          domain: this.revision.domain,
          invalid_after: this.revision.invalid_after,
          scope: this.revision.scope,
        };

        await this.keyService.createRevision(this.model.id, params);
        this.revision.updateCanonical();
      }

      this.notificationService.success('Saved!');
      this.closeDialog();
    } catch (err) {
      this.notificationService.error(err.message);
    }
  }

  public updateInvalidAfter() {
    this.revision.invalid_after = this.invalidAfter.length > 0 ? moment(this.invalidAfter).toISOString() : null;
  }

  /*
    Private Methods
  */

  private initRevision() {
    this.revision = null;

    const revision = this.model.revisions.find(r => r.id === this.selectedRevision);

    if (!revision) {
      this.notificationService.error('Invalid revision');
      return;
    }

    this.revision = trackedModel({
      trackedKeys: [
        'domain',
        'invalid_after',
        'scope',
      ],
      SuperClass: WorkspaceKeyRevision,
      model: revision,
    });

    const {invalid_after: invalidAfter} = this.revision;

    this.invalidAfter = invalidAfter ? moment(invalidAfter).format('YYYY-MM-DDTHH:mm:ss') : null;
    this.isPast = invalidAfter ? moment().isAfter(moment(invalidAfter)) : false;
    this.revFormValidator = new FormValidator(this.revision);
    this.revFormValidator.register(this.revisionValidators, this.bindingEngine, () => this.isValid());
  }

  private isValid(): boolean {
    if (!this.keyFormValidator || !this.revFormValidator) {
      this.saveEnabled = false;

      return this.saveEnabled;
    }

    this.saveEnabled = this.keyFormValidator.isValid() && this.revFormValidator.isValid();

    return this.saveEnabled;
  }
}
