export class TestPlayerBody {
  public controller: any;
  public model: any;
  public shared: any;

  public activate(model) {
    this.model = model.body;
    this.controller = model.controller;
    this.shared = model.shared;
  }
}
