import {autoinject, bindable, computedFrom} from 'aurelia-framework';
import {DownloadService} from 'services/download';

@autoinject()
export class VFileDownload {
    @bindable
    public appendExt: boolean = false;

    @bindable
    public fileExt: string;

    @bindable
    public fileName: string;

    @bindable
    public fileUrl: string;

    @bindable
    public label: string;

    @bindable
    public id: string;

    constructor(public downloadService: DownloadService) {}

    @computedFrom('downloadService.ids.length', 'id', 'fileExt')
    get isDownloading(): boolean {
        const {ids = []} = this.downloadService;
        return ids.includes(`${this.id}_${this.fileExt}`);
    }

    @computedFrom('fileUrl', 'appendExt')
    get downloadLink(): string {
        if (this.appendExt) {
            return `${this.fileUrl}.${this.fileExt.toLocaleLowerCase()}`;
        }

        return this.fileUrl;
    }

    public downloadFile() {
        const id = `${this.id}_${this.fileExt}`;
        const url = this.downloadLink;
        const ext = url.match(/.mp4(.*)+$/)[0];
        const filename = `${this.fileName}${ext}`.replace(/ /g, '_');

        this.downloadService.downloadFileFromURL({filename, id, url});
    }
}
