import {computedFrom} from 'aurelia-framework';

export class BlackoutIdFooter {
  public controller: any;
  public model: any;

  public activate(model) {
    this.model = model.shared;
    this.controller = model.controller;
  }

  public done() {
    const isValid = this.model.onDone(this.model);
    if (isValid) {
      this.controller.cancel();
    }
  }

  @computedFrom('model.appliedRules', 'model.blackout_id', 'model.desc')
  get isDirty() {
    const {model} = this;
    if (
      model.blackout_id !== model.blackout_id_pristine ||
      model.desc !== model.descPristine ||
      !_.isEqual(model.appliedRules, model.appliedRulesPristine)
    ) {
      return true;
    }

    return false;
  }

  @computedFrom('isDirty')
  get doneButtonState() {
    const {model} = this;
    // disable if not dirty or if both lists are empty.
    if ((_.isEmpty(model.appliedRules) && _.isEmpty(model.availableRules)) || !this.isDirty) {
      return 'disabled';
    }

    return '';
  }
}
