import {BreakOffset} from 'apps/cms/routes/content/models/asset-model';
import {PlaylistAdBreakTooltipModel} from './models';

export class PlaylistAdBreakTooltip {
    public breakOffsets: BreakOffset[];

    public activate(model: PlaylistAdBreakTooltipModel) {
        this.breakOffsets = model.row.break_offsets;
    }
}
