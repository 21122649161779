import {CTableActions, SharedNav} from '@bindable-ui/bindable';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, computedFrom, containerless} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {Notification} from 'resources/notification/service';
import {WebhookProfile} from '../models/models';
import {WebhookService} from '../services/profiles';

@autoinject()
@containerless()
export class WebhookProfiles {
    @computedFrom('newInputModified')
    get profileNameFocus() {
        if (this.newInputModified !== undefined) {
            return true;
        }
        return false;
    }

    @computedFrom('selectedProfiles.length')
    get deleteBtnState() {
        if (!this.selectedProfiles.length) {
            return 'disabled';
        }

        return '';
    }

    public isLoading: boolean = false;
    public isCreating: boolean = false;
    public isDeleting: boolean = false;
    public newInputModified;
    public selectedProfiles: string[] = [];
    public deleteTip: any;
    public createTip: any;
    public newProfile: WebhookProfile;
    public hasProfileNameError: boolean = false;
    public hasProfileTargetURLError: boolean = false;
    public createNameError: string = 'Must provide a name.';
    public createURLError: string = 'Must be valid https:// URL.';
    /* ---------------------------------------------------------------------- *\
     *  Data-Table Actions
    \* ---------------------------------------------------------------------- */
    public tableActions: CTableActions = {
        onScrollBottom: () => this.profileService.getMoreProfiles(),
        /**
         * Link to the profile single for the record that was clicked.
         *
         * @param record {Object} - Record object that was clicked.
         */
        rowClick: record => {
            const id = record.getId();
            this.router.navigateToRoute('profilesSingle', {id});
        },
    };

    public webhookCols = [
        {
            checkChanged: row => this.trackSelectedProfiles(row),
            colClass: 't30',
            colHeadName: 'checkbox',
            colHeadSelectedChanged: isChecked => this.selectAllWebhooks(isChecked),
            colHeadSelectedVal: false,
            colHeadValue: '',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
        },
        {
            colClass: 't150',
            colHeadName: 'name',
            colHeadValue: 'Name',
            colWidth: 150,
        },
        {
            colHeadName: 'target_url',
            colHeadValue: 'Target URL',
            colWidth: 500,
        },
        {
            colClass: 't240',
            colHeadName: 'status',
            colHeadValue: 'Status',
            colOnChange: profile => this.toggleStatus(profile),
            colWidth: 240,
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle',
            ),
        },
    ];

    constructor(
        private profileService: WebhookService,
        public sharedNav: SharedNav,
        private router: Router,
        public eventAggregator: EventAggregator,
        public notification: Notification,
    ) {}

    public activate() {
        this.init();
    }

    public trackSelectedProfiles(profile) {
        if (profile.checkbox) {
            this.selectedProfiles.push(profile.getId());
        } else {
            _.remove(this.selectedProfiles, item => item === profile.getId());
        }
    }

    public async focusCreateInput() {
        this.newInputModified = undefined;
        await new Promise(resolve => setTimeout(() => resolve(), 100)).then(() => {
            this.newInputModified = new Date();
        });

        this.newProfile = new WebhookProfile();
    }

    public selectAllWebhooks(isSelected: boolean) {
        _.forEach(this.profileService.profiles, profile => {
            profile.checkbox = isSelected;
        });
    }

    public async loadProfiles() {
        try {
            await this.profileService.getProfiles();
        } catch (e) {
            this.notification.error('There as a problem loading Webhook Profiles.');
        }
    }

    public async archiveProfiles() {
        await this.profileService.archiveProfiles(this.selectedProfiles);
        this.loadProfiles();
    }

    public async toggleStatus(profile) {
        try {
            await this.profileService.saveProfile(profile);
        } catch (e) {
            this.notification.error('There was a problem saving profile.');
        }
    }

    public async createProfile(andEdit = false) {
        this.resetCreateValidation();
        let errors = 0;

        if (!this.validateName(this.newProfile.name)) {
            this.hasProfileNameError = true;
            errors += 1;
        }

        if (!this.validateURL(this.newProfile.target_url)) {
            this.hasProfileTargetURLError = true;
            errors += 1;
        }

        if (errors > 0) {
            return false;
        }

        const created = await this.profileService.createProfile(this.newProfile);
        if (!created) {
            this.notification.error('There was a problem creating profile.');
            return;
        }

        this.notification.success(`${this.newProfile.name} was created successfuly.`);

        if (andEdit) {
            this.router.navigateToRoute('profilesSingle', {id: created.getId()});
            return;
        }

        this.loadProfiles();
    }

    public validateName(str = '') {
        return !!(str.length > 0);
    }

    public validateURL(path = '') {
        const r = /^(https):\/\/[^ "]+$/;

        return r.test(path);
    }

    public resetCreateValidation() {
        this.hasProfileNameError = false;
        this.hasProfileTargetURLError = false;
    }

    private async init() {
        this.loadProfiles();
    }
}
