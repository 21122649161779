/* eslint-disable max-classes-per-file */
import {PluginResponseItem} from '../../../models/plugin';
import {PluginListTableRow} from './table-models';

class ConfigInput {
  public search: string;
  public selection = {} as PluginListTableRow;
  public selectionReset: () => void;
}

class ConfigOptions {
  public plugin: PluginResponseItem[] = [];
}

class ConfigState {
  public error: string;
  public isAssigned: boolean;
  public isLoading: boolean;
  public isHidden: boolean = true;
}

export class Config {
  public input = new ConfigInput();
  public options = new ConfigOptions();
  public state = new ConfigState();
}
