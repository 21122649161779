import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {CToastsService} from '@bindable-ui/bindable';

import {ILazyLoaderActions} from 'components/lazy-loader';

import {HyperionPolling} from 'services/hyperion-polling';

import {SsoDomain} from '../../models/sso-domain';
import {
  ISsoDomainUser,
  // SsoDomainUserUpdateResponse,
} from '../../models/sso-domain-user';

import {SsoDomainService} from '../../services/sso-domain';
import {OwnerService} from '../../services/owners';
import {SsoDomainUserService} from '../../services/sso-domain-user';

function sorted(key: string, value: string): number {
  switch (value) {
    case `-${key}`:
      return 1;
    case key:
      return -1;
    default:
      return 0;
  }
}

export interface IActions {
  onClose: () => void;
  onSave: () => void;
}

@autoinject()
export class IntegrationSingleIndex {
  public model: SsoDomain = null;
  public pollTracker: HyperionPolling;
  public pollInterval: number = 10 * 1000;

  public modalView = null;
  public modalModel = null;
  public showModal = false;

  public records: ISsoDomainUser[] = [];

  public trackedUsers: Set<string>;

  public isProcessing: boolean = false;
  public isReady: boolean = false;

  public ownerSearchTxt: string = null;

  private domainId: string = null;

  public loaderActions: ILazyLoaderActions = {
    getMore: () => this.userService.getMore(this.domainId),
  };

  public searchActions = {
    onSearch: async (search: string) =>
      this.userService.getRecords(this.domainId, {search: search.toLocaleLowerCase()}),
    onClear: () => this.userService.getRecords(this.domainId),
  };

  @computedFrom('ssoService.order')
  public get sortedSearch(): number {
    return sorted('search', this.userService.order);
  }

  constructor(
    public domainService: SsoDomainService,
    public ownerService: OwnerService,
    public userService: SsoDomainUserService,
    public notificationService: CToastsService,
  ) {
    this.reset();

    this.pollTracker = new HyperionPolling({
      callbackFn: res => this.userService.processPollData(res),
      getParams: () => this.userService.params,
      ms: this.pollInterval,
      promiseFn: args => {
        if (document.hidden) {
          return null;
        }

        return this.userService.pollRecords(this.domainId, args);
      },
      useAfter: true,
    });
  }

  /*
    Aurelia Hooks
  */

  public activate({id}) {
    this.domainId = id;

    this.init();
  }

  public deactivate() {
    this.reset();
  }

  /*
    Public Methods
  */

  public async removeUsers(): Promise<void> {
    if (this.isProcessing) {
      this.notificationService.warning('Please wait');
      return;
    }

    const ids = Array.from(this.trackedUsers);

    if (ids.length === 0) {
      return;
    }

    try {
      this.isProcessing = true;

      const ct = ids.length;

      await this.userService.removeUsersFromDomain(this.domainId, ids);

      this.userService.purgeRecords(ids);

      this.trackedUsers = new Set();
      this.notificationService.success(`Removed ${ct} user${ct === 1 ? '' : 's'}`);
    } catch (err) {
      this.notificationService.error(err.message);
    } finally {
      this.isProcessing = false;
    }
  }

  /**
   * Handles table row click events
   * @param event
   */
  public rowClicked(event: any) {
    event.preventDefault();

    const tr = event.target.closest('lynk-tr');
    const {id} = tr.dataset;

    if (!id) {
      return;
    }

    this.trackUser(id);
  }

  public showAddUserModal() {
    if (this.userService.isLoading) {
      return;
    }

    this.modalModel = {
      domainId: this.domainId,
      actions: {
        onClose: () => {
          this.closeDialog();
        },
      },
    };

    this.modalView = PLATFORM.moduleName('apps/acuity/panels/identity/sso-integrations/modals/domain-users/index');
    this.showModal = true;
  }

  public async sortRecords(key: string, defaultSortDirection: string = null) {
    const {order} = this.userService;
    let newOrder = '';

    if (order.replace(/^-/, '') === key) {
      newOrder = /^-/.test(order) ? key : `-${key}`;
    } else {
      newOrder = defaultSortDirection || key;
    }

    await this.userService.getRecords(this.domainId, {order: newOrder});
  }

  /*
    Private Methods
  */

  private closeDialog() {
    this.showModal = false;
    this.modalModel = null;
    this.modalView = null;

    this.pollTracker.manualPoll();
  }

  private async init() {
    this.model = await this.domainService.getRecord(this.domainId);
    await this.userService.getRecords(this.domainId);

    if (this.pollTracker) {
      this.pollTracker.start();
    }

    this.isReady = true;
  }

  private reset() {
    this.isReady = false;
    this.records = [];
    this.model = null;
    this.trackedUsers = new Set();

    this.ownerService.clear();

    if (this.pollTracker) {
      this.pollTracker.stop();
      this.pollTracker = null;
    }
  }

  private trackUser(id: string) {
    const collection = this.trackedUsers;

    if (collection.has(id)) {
      collection.delete(id);
    } else {
      collection.add(id);
    }

    // Aurelia can't track a set, so replace it to force rendering updates
    this.trackedUsers = new Set(collection);
  }
}

export default IntegrationSingleIndex;
