import {autoinject, LogManager} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {CToastsService} from '@bindable-ui/bindable';
import {Template, TemplatesListResponse} from '../models/templates-model';

export const VOD_TEMPLEATES_URL: string = '/settings/vod_uploader/templates/';
export const LIBRARIES_URL: string = '/api/v4/libraries';

const log = LogManager.getLogger('Templeates Service');

@autoinject
export class TemplatesService {
  public templates: Template[] = [];
  public libraries: any = [];

  constructor(public acceo: Acceo, private notification: CToastsService) {}

  public async getLibraries() {
    try {
      const res: any = await this.acceo.get()(LIBRARIES_URL);
      return res.items;
    } catch (err) {
      this.notification.error('Failed To Get Libraries');
      log.error(err);
      throw new Error(err);
    }
  }

  public async getTemplates() {
    try {
      const res = await this.acceo.get(TemplatesListResponse)(VOD_TEMPLEATES_URL);
      return res.items;
    } catch (err) {
      this.notification.error('Failed To Get Templates');
      log.error(err);
      throw new Error(err);
    }
  }

  public async getTemplate(model: Template) {
    try {
      const res = await this.acceo.get(Template)(`${VOD_TEMPLEATES_URL}${model.id}`);
      return res;
    } catch (err) {
      this.notification.error('Failed To Get Template');
      log.error(err);
      throw new Error(err);
    }
  }

  public async deleteTemplate(model: Template) {
    try {
      const res = await this.acceo.delete(Template)(`${VOD_TEMPLEATES_URL}${model.id}`);
      return res;
    } catch (err) {
      this.notification.error(`Failed To Delete Template "${model.name}"`);
      log.error(err);
      throw new Error(err);
    }
  }

  public async createTemplate(model: Template) {
    try {
      const res = await this.acceo.post(Template)(VOD_TEMPLEATES_URL, model);
      return res;
    } catch (err) {
      this.notification.error('Failed To Create Template');
      log.error(err);
      throw new Error(err);
    }
  }

  public async updateTemplate(model: Template) {
    const data = _.cloneDeep(model);
    delete data.id;

    try {
      const res = await this.acceo.patch(Template)(`${VOD_TEMPLEATES_URL}${model.id}`, data);
      return res;
    } catch (err) {
      this.notification.error('Failed To Update Template');
      log.error(err);
      throw new Error(err);
    }
  }
}
