import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';
import {LibraryService} from '../services/library';

@autoinject()
export class LibraryDetailsFooter {
  public controller: any;
  public shared: any;

  constructor(private libraryService: LibraryService, private notification: CToastsService) {}

  public activate(model) {
    this.controller = model.controller;
    this.shared = model.shared;
  }

  public async deleteLibrary() {
    const name = this.shared.desc;
    const libraryId = this.shared.id;
    this.shared.isDeleting = true;
    try {
      await this.libraryService.deleteLibrary(libraryId);
      this.notification.success(`Library "${name}" has been deleted.`);
      window.location.href = '#/content';
      this.controller.close();
    } catch (err) {
      this.notification.error(`Unable to delete library "${name}". Please try again.`);
    }
    this.shared.isDeleting = false;
  }

  public async saveLibrary() {
    if (await this.libraryService.updateLibrary(this.shared)) {
      this.controller.close();
    }
  }

  @computedFrom('shared.isDirty', 'shared.desc')
  get saveButtonState() {
    return !this.shared.isDirty || this.shared.isDeleting || !this.shared.desc ? 'disabled' : '';
  }
}
