// tslint:disable max-line-length
import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {MSOSlicerResponseGetByOwner} from '../models/msoslicer';

@autoinject
export class MSOSlicerGetByOwnerService {
  private url: string = '/api/v4/mso/slicergetbyowner';

  constructor(private acceo: Acceo) {}

  /** TODO: Deprecate this once GET /slicer is working */
  public get(owner: string, showDeleted = false) {
    let urlPath = `${this.url}/${owner}`;
    if (showDeleted !== undefined && showDeleted) {
      urlPath += '?include_deleted=true';
    }
    return this.acceo.get(MSOSlicerResponseGetByOwner)(urlPath);
  }
}
