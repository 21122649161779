import {CTableCol} from '@bindable-ui/bindable';
import {computedFrom} from 'aurelia-binding';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {BaseAssetSingle} from './base';

@autoinject()
export class AssetAdBreaks extends BaseAssetSingle {
    public showOffsetsWithAdDurations: boolean = false;
    public canShowOffsetToggle: boolean = false;
    public showAdMetaCols: any;
    public isAdMeta: any = [];
    public adMetaCondBtn: boolean = false;
    public adBreakCols: CTableCol[] = [
        {
            colClass: 't30',
            colHeadName: 'breakNumber',
            colHeadValue: '#',
            sort: false,
        },
        {
            colHeadName: this.showOffsetsWithAdDurations ? 'break_duration_cumulative' : 'offset',
            colHeadValue: 'Time Offset',
            sort: false,
            valueConverter: 'secondsToHms',
        },
        {
            colHeadName: 'break_type',
            colHeadValue: 'Type',
            sort: false,
            valueConverter: 'capitalize',
            valueConverterFormat: true,
        },
        {
            colHeadName: 'break_duration',
            colHeadValue: 'Duration',
            sort: false,
        },
        {
            colHeadName: 'name',
            colHeadValue: 'Name',
            sort: false,
        },
    ];

    public adBreakColsInfo: CTableCol[] = [
        {
            colClass: 't30',
            colHeadName: 'breakNumber',
            colHeadValue: '#',
            sort: false,
        },
        {
            colHeadName: this.showOffsetsWithAdDurations ? 'break_duration_cumulative' : 'offset',
            colHeadValue: 'Time Offset',
            sort: false,
            valueConverter: 'secondsToHms',
        },
        {
            colHeadName: 'break_type',
            colHeadValue: 'Type',
            sort: false,
            valueConverter: 'capitalize',
            valueConverterFormat: true,
        },
        {
            colHeadName: 'break_duration',
            colHeadValue: 'Duration',
            sort: false,
        },
        {
            colHeadName: 'name',
            colHeadValue: 'Name',
            sort: false,
        },
        {
            colHeadName: 'action',
            colHeadValue: 'Ad Meta',
            sort: false,
            colAction: row => this.testModal(row),
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-button/c-td-button.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-button/c-td-button',
            ),
        },
    ];

    public testModal(row) {
        this.dialogService.open({
            model: {
                title: 'Ad Meta Data',
                size: 'large',
                bodyText: '',
                bodyViewModel: PLATFORM.moduleName('resources/components/content/ad-meta'),
                bodyModel: {
                    stuff: row.ad_meta,
                },
                footerEnable: true,
                footerViewModel: PLATFORM.moduleName('resources/components/content/footer'),
            },
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        });
    }

    public boundaryCols: CTableCol[] = [
        {
            colClass: 't30',
            colHeadName: 'breakNumber',
            colHeadValue: '#',
            sort: false,
        },
        {
            colHeadName: 'offset',
            colHeadValue: 'Boundary',
            sort: false,
            valueConverter: 'secondsToHms',
        },
        {
            colHeadName: 'name',
            colHeadValue: 'Name',
            sort: false,
        },
    ];

    public attached() {
        this.content.activeTab = 'breaks';
        super.attached();
    }

    public switchOffsetDisplay() {
        this.adBreakCols[1].colHeadName = this.showOffsetsWithAdDurations ? 'break_duration_cumulative' : 'offset';
        this.adBreakColsInfo[1].colHeadName = this.showOffsetsWithAdDurations ? 'break_duration_cumulative' : 'offset';
    }

    @computedFrom('model.break_offsets')
    get adBreaks() {
        this.canShowOffsetToggle = false;
        if (!this.model || !this.model.break_offsets) {
            return [];
        }
        const {break_offsets = []} = this.model;
        let adDurations = 0;
        let adBreakCount = 0;
        let isAdMetaCond = false;
        this.isAdMeta = [];
        const breakOffsets = break_offsets.map((b, i) => {
            const durationWithAds = b.offset + adDurations;
            if (b.break_type === 'AD BREAK') {
                adDurations += b.duration;
                adBreakCount += 1;
            }
            if (b.ad_meta && Object.keys(b.ad_meta).length !== 0) {
                this.adMetaCondBtn = true;
            } else {
                this.adMetaCondBtn = false;
                this.isAdMeta.push(false);
            }
            return {
                breakNumber: i + 1,
                break_duration: (b.duration || 0.0).toFixed(2),
                break_duration_cumulative: durationWithAds,
                break_type: b.break_type,
                name: b.name,
                offset: b.offset,
                ad_meta: b.ad_meta,
                action: 'View',
                actionButtonState: this.adMetaCondBtn ? '' : 'hidden',
            };
        });
        if (this.isAdMeta.length !== break_offsets.length) {
            isAdMetaCond = true;
        }
        this.showAdMetaCols = isAdMetaCond ? this.adBreakColsInfo : this.adBreakCols;

        // Show offset toggle only if ad breaks are in a position to alter the reamining timeline.
        if (adBreakCount > 0) {
            if (
                adBreakCount > 1 ||
                (adBreakCount === 1 && breakOffsets.length > 1 && breakOffsets.slice(-1)[0].break_type !== 'AD BREAK')
            ) {
                this.canShowOffsetToggle = true;
            }
        }
        return breakOffsets;
    }
}
