import {bindable} from 'aurelia-framework';
import {Modal} from 'resources/modal';

export interface IEventDuplicate {
  id: string;
  desc: string;
  meta: {[key: string]: string};
}

interface ExcludedMeta {
  [eventId: string]: {[key: string]: boolean};
}

export class DuplicateEventsModal {
  @bindable
  public events: IEventDuplicate[];

  @bindable
  public onDuplicate: () => void;

  public modal: Modal;
  public excludedMeta: ExcludedMeta = {};

  public duplicate() {
    _.keys(this.excludedMeta).forEach(eventId => {
      const event = this.events.find(evt => evt.id === eventId);
      if (this.excludedMeta[eventId]) {
        _.keys(this.excludedMeta[eventId]).forEach(key => {
          if (this.excludedMeta[eventId][key]) delete event.meta[key];
        });
      }
    });
    _.attempt(this.onDuplicate);
    this.modal.close();
  }

  public openModal() {
    this.excludedMeta = {};
    this.modal.open();
  }

  public toggleExcludeMeta(event: IEventDuplicate, key: string, $event: MouseEvent) {
    $event.preventDefault();
    if (_.get(this.excludedMeta, `${event.id}.${key}`)) {
      this.excludedMeta[event.id][key] = false;
    } else {
      this.excludedMeta[event.id] = {
        ...this.excludedMeta[event.id],
        [key]: true,
      };
    }
  }

  public hasMeta(event: IEventDuplicate) {
    return _.keys(event.meta).length;
  }
}
