/**
 * Validates that:
 *      - emailAddress is not empty.
 *      - emailAddress is a valid email address. (Email Validation RegEx taken from: http://emailregex.com/)
 *
 * @param emailAddress {String} - Self explanatory.
 *
 * @return isValid {Boolean} - Whether or not emailAddress passed validation.
 */
export function validEmail(emailAddress) {
    let isValid = true;
    // eslint-disable-next-line max-len
    const emailRE =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailAddress === '' || emailAddress === undefined) {
        isValid = false;
    } else if (!emailRE.test(emailAddress)) {
        isValid = false;
    }
    return isValid;
}
