import {computedFrom} from 'aurelia-framework';
import {BaseAssetSingle} from './base';

export class AssetExport extends BaseAssetSingle {
  public deleteMP4Tip: any;

  @computedFrom(
    'model.mine',
    'model.state',
    'model.studio_drm_required',
    'model.export_mp4_job',
    'model.hd_exported_url',
    'model.export_mp4_error',
    'isExporting',
  )
  get exportMp4ButtonState() {
    if (!this.model || this.model.state === 'slicing' || this.model.source === 'External') {
      return 'disabled';
    }

    const isProcessingJob = this.model.export_mp4_job && !this.model.hd_exported_url && !this.model.export_mp4_error;
    if (isProcessingJob || this.isExporting) {
      return 'thinking';
    }

    const {mine = true, studio_drm_required = false} = this.model;

    if (!mine || studio_drm_required) {
      return 'disabled';
    }

    return '';
  }

  @computedFrom('model.hd_exported_urls')
  get additionalExports() {
    if (!this.model) {
      return undefined;
    }

    const {hd_exported_urls = {}} = this.model;

    return Object.keys(hd_exported_urls).map(key => ({key, value: hd_exported_urls[key][0]}));
  }

  /*
   * Private Properties
   */

  private isExporting: boolean = false;

  /*
   *    Aurelia Events
   */

  public attached() {
    this.content.activeTab = 'export';
    super.attached();
  }

  /*
   *  Public Methods
   */

  public async deleteMp4Export() {
    if (await this.content.deleteMp4Export(this.model.id)) {
      if (this.deleteMP4Tip) {
        this.deleteMP4Tip.hide();
      }
    }
  }

  public async exportMp4() {
    if (this.isExporting) {
      return;
    }

    this.model.export_mp4_error = false;
    this.isExporting = true;

    const asset = await this.content.exportMp4(this.model.id);

    if (asset) {
      this.model.export_mp4_job = asset.export_mp4_job;
    }

    this.isExporting = false;
  }
}
