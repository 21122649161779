import {bindable, inject} from 'aurelia-framework';
import {Validator} from 'class-validator';
// have to inject Element separates because it is defined as an interface,
// it doesn't get written into the metadata by TypeScript and thus can't be auto-injected.

@inject(Element)
export class InputValidationCustomAttribute {
  @bindable public input;
  @bindable public type;
  @bindable public max;
  @bindable public min;

  constructor(private element) {}

  public inputChanged(newValue: string): void {
    if (this.input !== undefined) {
      this.input = newValue;
      this.validate(this.type, this.max, this.min);
    }
  }

  public maxChanged(newValue: number, oldValue: number): void {
    this.max = newValue || oldValue;
  }

  public minChanged(newValue: number, oldValue: number): void {
    this.min = newValue || oldValue;
  }

  public validate(type, max?: number, min?: number) {
    const v = this[`${type}Validation`](this.input, max, min);
    this.toggleClass(v);
    this.toggleState(v);
  }

  public domainNameValidation(input: string): boolean {
    // const validator = new Validator();
    if (input) {
      const _input = input.startsWith('.') ? input.slice(1) : input;
      // const re = validator.isURL(_input);
      // currently just use a domain name validation
      // not URL validation for backward compatibility
      // https://stackoverflow.com/questions/26093545/how-to-validate-domain-name-using-regex
      // const re = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(_input);
      const re = /^[a-zA-Z0-9.:]+$/.test(_input);
      return re;
    }
    return true;
  }

  public ipAddressValidation(input: string): boolean {
    if (input) {
      // https://stackoverflow.com/a/40430102/1215220
      const re = /^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
      return re.test(input);
    }

    return true;
  }

  public positiveNumValidation(input: string, max?: number, min?: number) {
    return /^\+?([1-9]\d*)$/.test(input) && this.checkMax(input, max) && this.checkMin(input, min);
  }

  public wholeNumValidation(input, max?: number, min?: number) {
    return /^[+]?\d+$/.test(input) && this.checkMax(input, max) && this.checkMin(input, min);
  }

  public externalUrlValidation(input: string) {
    const validator = new Validator();
    if (input) {
      const re = validator.isURL(input);
      // on top of this test url should start w 'http' or 'https'
      const start = input.toLowerCase().startsWith('http://') || input.toLowerCase().startsWith('https://');
      return start && re;
    }
    return true;
  }

  public passwordValidation(input) {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/gi.test(input);
  }

  public checkMax(input, max) {
    if (max === undefined) {
      return true;
    }
    return parseInt(input, 10) < max;
  }

  public checkMin(input, min) {
    if (min === undefined) {
      return true;
    }
    return parseInt(input, 10) > min;
  }

  public toggleClass(v) {
    this.element.classList[`${v ? 'remove' : 'add'}`]('form-error');
  }

  public toggleState(v) {
    this.element.setAttribute('state', `${v ? 'default' : 'error'}`);
  }
}
