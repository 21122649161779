/* eslint-disable max-len */
/* tslint:disable: variable-name */

import {ConfigSettingInputType, IConfigSetting} from '../models/config-setting';

export const ConfigSettingsLiveSlicer: IConfigSetting[] = [
  {
    key: 'api_port',
    managed: true,
  },
  {
    key: 'apikey',
    managed: true,
  },
  {
    info: 'The number of hours that assets will be retained before being deleted. Zero indicates that assets should not be deleted.',
    input: ConfigSettingInputType.number,
    key: 'autoexpire_age',
    text: 'Auto Expire Age',
  },
  {
    info: 'Determines whether the Live Slicer will store content on an alternate cloud storage.',
    input: ConfigSettingInputType.radio,
    key: 'backup',
    options: [
      {
        text: 'The Live Slicer will upload content to an alternate path on an alternate origin storage.',
        value: 'true',
      },
      {
        text: 'The Live Slicer will upload content to the default origin storage.',
        value: 'false',
      },
    ],
    text: 'Backup',
  },
  {
    disallowed: true,
    key: 'call_sign',
  },
  {
    disallowed: true,
    info: 'Determines the length of the delay, in seconds, that will be added to facilitate timecode matching.',
    input: ConfigSettingInputType.text,
    key: 'capture_delay',
    text: 'Capture Delay',
  },
  {
    info: 'Determines whether closed captions will be filtered.',
    input: ConfigSettingInputType.radio,
    key: 'cc_filter',
    options: [
      {
        text: 'Disable filtering CEA-608/708 captions on all channels with the exception of channel 1.',
        value: 'on',
      },
      {
        text: 'Filter CEA-608/708 captions on all channels.',
        value: 'off',
      },
    ],
    text: 'CC Filter',
  },
  {
    disallowed: true,
    key: 'channel_group',
  },
  {
    info: 'Defines a description to be assigned to new assets.',
    input: ConfigSettingInputType.text,
    key: 'description',
    text: 'Description',
  },
  {
    info: 'Determines how a Live Slicer will handle expired API requests.',
    input: ConfigSettingInputType.radio,
    key: 'drop_expired_breaks',
    options: [
      {
        text: 'The Live Slicer will ignore expired API requests.',
        value: 'on',
      },
      {
        text: 'The Live Slicer will perform the action defined within an API request once it has been retained for the duration defined within the "future_break_expiration_minutes" setting. This occurs regardless of whether the specified timecode has elapsed.',
        value: 'off',
      },
    ],
    text: 'Drop Expired Breaks',
  },
  {
    info: 'Assigns a failover group to the Live Slicer. Identify the desired failover group by its system-defined ID. Live Slicer failover also requires that you add this Live Slicer to the failover group identified by this setting. Requires Slicer version 20081700 or higher.',
    input: ConfigSettingInputType.text,
    key: 'failover_id',
    text: 'Failover ID',
  },
  {
    info: 'Determines the number of minutes that the Live Slicer will retain an API request for a scheduled break before flagging it as eligible for expiration. Note: the "drop_expired_breaks" setting determines how the Live Slicer handles expired API requests.',
    input: ConfigSettingInputType.number,
    key: 'future_break_expiration_minutes',
    text: 'Future Break Expiration Minutes',
  },
  {
    info: 'Determines whether the Live Slicer may resolve timecodes, which may be defined within an API request, up to 12 hours in the future.',
    input: ConfigSettingInputType.radio,
    key: 'future_timecodes',
    options: [
      {
        text: 'Allow timecodes up to 12 hours in the future.',
        value: 'on',
      },
      {
        text: 'Require the Live Slicer to resolve timecodes within the capture delay buffer.',
        value: 'off',
      },
    ],
    text: 'Future Timecodes',
  },
  {
    info: 'Adjusts the amount of gain, in decibels, that will be applied to the audio. Positive values will increase gain. Negative values will decrease gain.',
    input: ConfigSettingInputType.number,
    key: 'gain',
    text: 'Gain',
  },
  {
    disallowed: true,
    key: 'group',
  },
  {
    info: "Determines the signal's input source.",
    input: ConfigSettingInputType.radio,
    key: 'input',
    options: [
      {
        text: 'RTMP',
        value: 'rtmp',
      },
      {
        text: 'RTP',
        value: 'rtp',
      },
      {
        text: 'UDP',
        value: 'udp',
      },
    ],
    text: 'Input',
  },
  {
    key: 'manual_ip',
    managed: true,
  },
  {
    info: 'Defines metadata that will be assigned to assets generated by the Live Slicer. Add this setting for each desired metadata field. Each instance of this setting should be specified on a separate line.',
    input: ConfigSettingInputType.text,
    key: 'meta',
    text: 'Meta',
  },
  {
    disallowed: true,
    key: 'multicast',
  },
  {
    info: 'Allow the Live Slicer to scan for Nielsen audio watermarks.',
    input: ConfigSettingInputType.radio,
    key: 'nielsen',
    options: [
      {
        text: 'Scan for Nielsen audio watermarks',
        value: '1',
      },
      {
        text: 'Do not scan for Nielsen audio watermarks',
        value: '0',
      },
    ],
    text: 'Nielsen',
  },
  {
    info: 'Defines the Nielsen breakout code. Defaults to "00"',
    input: ConfigSettingInputType.radio,
    key: 'nielsen_ad_mode',
    options: [
      {
        text: '00',
        value: '00',
      },
      {
        text: '03',
        value: '03',
      },
      {
        text: '07',
        value: '07',
      },
      {
        text: '09',
        value: '09',
      },
    ],
    text: 'Nielsen Ad Mode',
  },
  {
    info: 'Defines the Nielsen distributor for use with Nielsen audio watermarks and ID3 tags assigned to assets generated by the Live Slicer. Defaults to "www.uplynk.com"',
    input: ConfigSettingInputType.text,
    key: 'nielsen_distributor',
    text: 'Nielsen Distributor',
  },
  {
    disallowed: true,
    key: 'no_signal_pad',
  },
  {
    disallowed: true,
    key: 'no_signal_image',
  },
  {
    info: 'Identifies the set of audio/video tracks that will be consumed by the Live Slicer. Configure this setting to a comma-separated list of PIDs that correspond to the desired audio/video tracks. All other audio/video tracks will be ignored by the Live Slicer.',
    input: ConfigSettingInputType.text,
    key: 'pids',
    text: 'Packet Identifiers',
  },
  {
    info: "By default, the Live Slicer leverages a stream's Extended Data Services (XDS) data, when present, to update the current asset's title and rating information. Disable this functionality by configuring this setting.",
    input: ConfigSettingInputType.radio,
    key: 'parse_XDS',
    options: [
      {
        text: 'Disable XDS Parsing',
        value: 'no',
      },
      {
        text: 'Enable XDS Parsing',
        value: 'yes',
      },
    ],
    text: 'Parse XDS',
  },
  {
    key: 'port',
    managed: true,
  },
  {
    info: 'Identifies the program in a multi-program transport stream that will be consumed by the Live Slicer. Configure this setting to the ID of the desired program. If the pids setting has not been defined, then all audio tracks in the program will be consumed.',
    input: ConfigSettingInputType.number,
    key: 'progID',
    text: 'Program ID',
  },
  {
    managed: true,
    key: 'rtmp_url',
  },
  {
    disallowed: true,
    key: 'rtp_backlog_dur',
  },
  {
    key: 'rtp_headers',
    managed: true,
  },
  {
    disallowed: true,
    key: 'rtp_readahead_dur',
  },
  {
    info: 'A case-sensitive alphanumeric value by which this Live Slicer will be identified.',
    input: ConfigSettingInputType.text,
    key: 'slicerID',
    text: 'Slicer ID',
  },
  {
    key: 'ssl_port',
    managed: true,
  },
  {
    info: 'Determines whether the Live Slicer will start in blackout mode.',
    input: ConfigSettingInputType.radio,
    key: 'start_blackout',
    options: [
      {
        text: 'Start in blackout mode.',
        value: 'yes',
      },
      {
        text: 'Resume its previous mode upon being started. For example, if the Live Slicer was stopped in blackout mode, then it will resume blackout mode the next time that it is started.',
        value: 'sticky',
      },
    ],
    text: 'Start Blackout',
  },
  {
    info: 'By default, our service passes through the source video feed. Enable this option to upscale the source video feed to the highest quality ray in your encoding profile. Requires Slicer version 21070801 or higher.',
    input: ConfigSettingInputType.radio,
    key: 'upscale',
    options: [
      {
        text: 'Enable Upscale',
        value: 'yes',
      },
      {
        text: 'Disable Upscale',
        value: 'no',
      },
    ],
    text: 'Upscale',
  },
  {
    key: 'username',
    managed: true,
  },
  {
    info: 'Sets the log data verbosity level.',
    input: ConfigSettingInputType.radio,
    key: 'verbosity',
    options: [
      {
        text: 'Default level. Logs error conditions and informational messages.',
        value: '1',
      },
      {
        text: 'Logs error conditions, informational messages, and debug messages.',
        value: '3',
      },
    ],
    text: 'Verbosity',
  },
  {
    info: 'Adds an ID3 tag based on timestamp to each slice.',
    input: ConfigSettingInputType.radio,
    key: 'wallclock',
    options: [
      {
        text: 'Enable Wallclock',
        value: 'true',
      },
      {
        text: 'Disable Wallclock',
        value: 'true',
      },
    ],
    text: 'Wallclock',
  },
];

export const DocumentationUrlLiveSlicer =
  'https://docs.edgecast.com/video/index.html#Slicer/Live-Slicer-Setup.htm#ConfiguringtheLiveSlicer';
