import {autoinject, bindable, containerless} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
import {copyToClipboard} from '@bindable-ui/bindable';
import {AdServerDebugItem} from '../models/ad-server-debug-response';
import {AD_DEBUG_EVENT} from '../index';

@containerless()
@autoinject()
export class JobDetail {
  @bindable
  public job: AdServerDebugItem;

  constructor(public eventAggregator: EventAggregator, public dialogService: DialogService) {}

  public copy(text: string) {
    let notification: Notification;
    copyToClipboard(text, notification);
  }

  public exportJobDetails() {
    const data = {
      jobId: this.job.id,
      slots: this.job.slots,
      transactions: this.job.transactions,
      callbacks: this.job.callbacks,
    };

    this.eventAggregator.publish(AD_DEBUG_EVENT.ExportAllDetails, data);
  }
}
