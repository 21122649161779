import {Expose, Type} from 'class-transformer';
import {IsArray, IsBoolean, IsDefined, IsNumber, IsOptional, IsString} from 'class-validator';
import {DebugSearchCriteria} from './debug-search-criteria';

/*
 * interfaces
 */
export interface IAdServerDebugFilterParams {
  criteria?: DebugSearchCriteria;
}

export interface IAdServerDebugMeta {
  hasMore?: boolean;
  limit?: number;
  maxResults?: number;
  nextToken?: number;
  showing?: number;
  recordsFrom?: number;
  recordsTo?: number;
  total?: number;
}

export enum Status {
  Complete = 'complete',
  Fail = 'fail',
  Pending = 'pending',
  Processing = 'processing',
  Unknown = '',
}

export enum PlaybackType {
  VOD = 'vod',
  LIVE = 'live',
}

export enum DeliveryStatus {
  Success = 'success',
  Error = 'error',
  Unknown = '',
}

export class AdServerDebugItem {
  @IsString()
  public id: string;

  @IsDefined()
  public status: Status = Status.Unknown;

  @IsNumber()
  public ad_request_index: number = 0;

  @IsNumber()
  @IsOptional()
  public unfulfilled_requests: number = 0;

  @IsString()
  public owner_guid: string = '';

  @IsString()
  public channel_guid: string = '';

  @IsString()
  public channel_name: string = '';

  @IsString()
  public created_at: string = null;

  @IsString()
  public updated_at: string = null;

  @IsString()
  public viewer_guid: string = '';

  @IsString()
  public pk: string = '';

  @IsString()
  public zone: string = '';

  @Expose()
  public playback_type: PlaybackType = PlaybackType.VOD;

  @IsString()
  public video_guid: string = '';

  @IsString()
  public debug_name: string = '';

  @IsArray()
  @Type(() => Callback)
  public callbacks: Callback[] = [];

  @IsArray()
  @Type(() => Transaction)
  public transactions: Transaction[] = [];

  @Expose()
  public hasWarnings: boolean = false;

  @Expose()
  public beacon_count: number = 0;

  @Expose()
  public transaction_count: number = 0;

  @Expose()
  public failed_transaction_count: number = 0;

  @Expose()
  public warningsCount: number = 0;

  @Expose()
  public canExport: boolean = false;

  @IsString()
  public failure_reason: string;

  @IsString()
  public failure_type: string;

  @IsArray()
  @Type(() => Slot)
  public slots: Slot[] = [];

  @IsOptional()
  public storageDataLocation: string = '';

  @IsArray()
  @Type(() => TransactionsTreeItem)
  @Expose({name: 'transaction_tree'})
  public transactionsTreeItems: TransactionsTreeItem[] = [];
}

export class Callback {
  @IsString()
  public name: string = '';

  @IsString()
  public beacon_type: string = '';

  @IsString()
  public event_callback_type: string = '';

  @IsBoolean()
  public show_browser: boolean = false;

  @IsString()
  public url: string = '';

  @IsString()
  public created_at: string = null;

  @IsString()
  public fired_at: string = null;

  @IsString()
  public updated_at: string = null;

  @IsString()
  public error: string = '';

  @IsString()
  public id: string = '';

  @IsOptional()
  public pod_slot: string = 'None';

  @IsNumber()
  public response_code: number;

  public status: DeliveryStatus;

  @IsNumber()
  public breakIndex: number = null;

  @IsNumber()
  public adIndex: number = null;

  @IsString()
  public ad_id: string = null;

  @IsOptional()
  public initial_ad_id: string = null;

  @IsString()
  public creative_id: string = null;

  public headers: any = {};
}

export function objectToStringTree(obj: object, strTree: string = '', depth: number = 0) {
  if (!obj) return strTree;
  if (depth > 100) return strTree;
  let str = strTree;

  const names = Object.getOwnPropertyNames(obj);
  names.forEach((name: string) => {
    const value = obj[name];
    str += Array(depth + 1).join('    ');
    if (typeof value === 'object' && !Array.isArray(value)) {
      str += `${name}: \n`;
      str = objectToStringTree(value, str, depth + 1);
    } else {
      str += `${name}: ${value}\n`;
    }
  });

  return str;
}

export class TransactionsTreeItem {
  @IsString()
  public id: string;

  @Type(() => Transaction)
  public transaction: Transaction;

  @IsOptional()
  @Type(() => TransactionsTreeItem)
  public children: TransactionsTreeItem[];
}

export class Transaction {
  // transaction request properties
  @IsOptional()
  public level: number;

  @IsOptional()
  public numChildren: number;

  @IsOptional()
  public numChildrenFailed: number = 0;

  @IsString()
  public id: string;

  @IsNumber()
  public body_download_time: number;

  @IsNumber()
  public connection_time: number;

  @IsString()
  public created_at: string = null;

  @IsString()
  public updated_at: string = null;

  @IsString()
  public failure_type: string;

  @IsString()
  public failure_reason: string;

  @IsNumber()
  public header_request_time: number;

  @IsBoolean()
  public is_wrapper: boolean;

  @IsString()
  @IsOptional()
  @Expose({name: 'type_field'})
  public typeField: string;

  @IsString()
  public pod_location: string;

  @IsArray()
  public redirects: string[];

  public request_headers: any;

  @IsBoolean()
  public request_succeeded: boolean;

  @IsString()
  public request_url: string;

  @IsNumber()
  public total_elapsed_request_time: number;

  // transaction response properties
  @IsArray()
  public errors: string[];

  @IsNumber()
  public num_ads: number;

  @IsNumber()
  public num_beacons: number;

  @IsNumber()
  public num_wrappers: number;

  @IsString()
  public raw_response: string;

  public warnings: any = {};

  @IsNumber()
  @IsOptional()
  public status_code: number;

  @IsDefined()
  public status: Status = Status.Unknown;

  // used to indicate that there is no true response because the request was unfulfilled
  @IsOptional()
  @Expose({name: 'unfulfilled_request'})
  public unfulfilledRequest: boolean = false;

  @IsNumber()
  @Expose({name: 'transaction_index'})
  public transIndex: number;

  @IsString()
  @Expose({name: 'wrapper_depth'})
  public wrapperDepth: number;

  @IsOptional()
  @Expose({name: 'parent_id'})
  public parentId: string;

  @IsOptional()
  @Expose({name: 'initial_ad_id'})
  public initialAdId: string;

  @IsOptional()
  public numTransactionsSpawned: number;

  @IsOptional()
  public numWrapperEnds: number;
}

export class Slot {
  @IsArray()
  @Type(() => Ad)
  @Expose({name: 'selected_ads'})
  public selectedAds: Ad[] = [];

  @IsArray()
  @Type(() => Ad)
  @Expose({name: 'not_selected_ads'})
  public notSelectedAds: Ad[] = [];

  @IsArray()
  @Type(() => Ad)
  public ads: Ad[] = [];
}

export class Ad {
  @IsNumber()
  public breakIndex: number;

  @IsNumber()
  public adIndex: number;

  @IsString()
  @Expose({name: 'ad_id'})
  public adId: string;

  @IsString()
  @Expose({name: 'initial_ad_id'})
  public initialAdId: string;

  @IsString()
  @Expose({name: 'creative_id'})
  public creativeId: string;

  @IsNumber()
  public duration: number;

  @IsString()
  @Expose({name: 'is_fallback'})
  public isFallback: string;

  @IsString()
  public selected: string;

  @IsString()
  @Expose({name: 'uplynk_id'})
  public uplynkId: string;

  @IsString()
  public playerUrl: string;

  @IsString()
  @Expose({name: 'uplynk_status'})
  public uplynkStatus: string;
}

export class playerAsset {
  @IsString()
  @Expose({name: 'test_player'})
  public testPlayer: string;

  @IsString()
  @Expose({name: 'cms_session_fingerprint'})
  public cmsSessionFingerprint: string;

  @IsString()
  @Expose({name: 'cms_session_id_fingerprint'})
  public cmsSessionIdFingerprint: string;
}

export class AdServerDebugResponse {
  @IsArray()
  @Type(() => AdServerDebugItem)
  public items: AdServerDebugItem[] = [];

  @IsNumber()
  public total_items: number = 0;
}

export class Channel {
  @IsString()
  public id: string;

  @IsString()
  public desc: string;
}
