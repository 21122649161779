import {DialogService} from 'aurelia-dialog';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {CToastsService} from '@bindable-ui/bindable';

import {IsArray, IsNumber, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

export const API_SEARCH_ENABLEMENT_GET: string = '/ops/search-enablement';
export const API_SEARCH_ENABLEMENT_POST: string = '/ops/search-enablement/update';
const ALL_ZONES_TITLE: string = 'All Zones';

class Response {
    @IsArray()
    public zones: any[] = [];

    @IsNumber()
    public error: any;

    @IsString()
    public msg: string = '';
}

@autoinject
export class SearchService {
    public zones: any[] = [];
    public zoneData: any[] = [];
    public cleanData: any[] = [];
    public saveState: string = 'disabled';
    public isLoading: boolean = false;
    public firstZone: any = {
        _barColor: 'var(--c_subTwoMain)',
        _class: 'bgInfo',
        _status: 'bar',
        id: ALL_ZONES_TITLE,
        atlas: false,
        elasticsearch: false,
        es_domain: '',
        es_dark: false,
        mongo: false,
    };

    public columns = [
        {
            colHeadName: 'id',
            colHeadValue: 'Zone',
            sort: true,
        },
        {
            colHeadName: 'atlas',
            colHeadValue: 'Atlas Search',
            colOnChange: row => this.atlasSearchChanged(row),
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle',
            ),
        },
        {
            colHeadName: 'elasticsearch',
            colHeadValue: 'Elasticsearch',
            colOnChange: row => this.elasticsearchChanged(row),
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle',
            ),
        },
        {
            colHeadName: 'es_domain',
            colHeadValue: 'ES Domain',
            _class: 'textInput',
            rowValChanged: row => this.domainChanged(row),
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-text-input/c-td-text-input.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-text-input/c-td-text-input',
            ),
        },
        {
            colHeadName: 'es_dark',
            colHeadValue: 'ES Dark Mode',
            colOnChange: row => this.darkModeChanged(row),
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle',
            ),
        },
        {
            colHeadName: 'mongo',
            colHeadValue: 'Mongo Classic',
            colOnChange: row => this.mongoClassicChanged(row),
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-toggle/c-td-toggle',
            ),
        },
    ];

    constructor(public acceo: Acceo, public notification: CToastsService, public dialogService: DialogService) {}

    public attached() {
        this.getSearchEnablement();
    }

    public async getSearchEnablement() {
        this.isLoading = true;
        this.zoneData = [this.firstZone];
        const resp = await this.acceo.get(Response)(API_SEARCH_ENABLEMENT_GET);
        this.zoneData = [this.firstZone].concat(resp.zones);
        this.cleanData = _.cloneDeep(this.zoneData);
        this.isLoading = false;
    }

    public validate() {
        let valid = true;
        this.zoneData.forEach(zone => {
            if (zone.id !== 'All Zones') {
                if (zone.elasticsearch && !zone.es_domain) {
                    this.notification.warning(
                        `In order to enabled Elasticsearch, you must specify a domain for zone ${zone.id}.`,
                    );
                    valid = false;
                }
            }
        });
        return valid;
    }

    public async save() {
        if (!this.validate()) {
            return;
        }

        this.isLoading = true;
        this.saveState = 'thinking';
        const saveData: any[] = [];
        let zone: any = null;
        for (let i = 1; i < this.zoneData.length; i++) {
            zone = this.zoneData[i];
            saveData.push({
                id: zone.id,
                atlas: zone.atlas,
                elasticsearch: zone.elasticsearch,
                es_domain: zone.es_domain,
                es_dark: zone.es_dark,
                mongo: zone.mongo,
            });
        }
        const response: any = await this.acceo.post()(API_SEARCH_ENABLEMENT_POST, saveData);
        if (response.error) {
            this.notification.error('An error occurred while saving.');
            this.saveState = '';
        }
        if (response.zones) {
            this.zoneData = [this.firstZone].concat(response.zones);
            this.cleanData = _.cloneDeep(this.zoneData);
            this.saveState = 'disabled';
        }
        this.isLoading = false;
    }

    public reset() {
        this.zoneData = _.cloneDeep(this.cleanData);
        this.saveState = 'disabled';
    }

    public enableAll() {
        this.zones.forEach(z => {
            z.elasticsearch = true;
        });
    }

    public disableAll() {
        this.zones.forEach(z => {
            z.elasticsearch = false;
        });
    }

    public tableActions = {
        getColClass: (row, col) => {
            let cls = col._class || '';
            /* istanbul ignore else */
            if (col.colHeadName === 'name') {
                cls += ` ${row._status}`;
            }
            return cls;
        },

        getBarColor: row => {
            const color = row._barColor || '';
            return color;
        },
    };

    public atlasSearchChanged(row) {
        if (row.atlas) {
            row.mongo = false;
            row.elasticsearch = false;
            row.es_dark = false;
        } else {
            row.mongo = true;
        }
        this.anythingChanged(row);
    }

    public elasticsearchChanged(row) {
        if (row.elasticsearch) {
            row.mongo = false;
            row.atlas = false;
            row.es_dark = false;
        } else {
            row.atlas = true;
        }
        this.anythingChanged(row);
    }

    public domainChanged(row) {
        if (row.id === ALL_ZONES_TITLE) {
            this.zoneData.forEach(zone => {
                if (zone.id !== ALL_ZONES_TITLE) {
                    zone.es_domain = row.es_domain;
                }
            });
        }
        this.anythingChanged(row, true);
    }

    public darkModeChanged(row) {
        if (row.es_dark) {
            row.elasticsearch = false;
            row.mongo = true;
        } else {
            row.atlas = true;
        }
        this.anythingChanged(row);
    }

    public mongoClassicChanged(row) {
        if (row.mongo) {
            row.elasticsearch = false;
            row.atlas = false;
        } else {
            row.atlas = true;
        }
        this.anythingChanged(row);
    }

    public anythingChanged(row, isDomain: boolean = false) {
        if (row.id === ALL_ZONES_TITLE && !isDomain) {
            this.zoneData.forEach(zd => {
                zd.atlas = row.atlas;
                zd.elasticsearch = row.elasticsearch;
                zd.mongo = row.mongo;
                zd.es_dark = row.es_dark;
            });
        }
        if (this.isClean()) {
            this.saveState = 'disabled';
        } else {
            this.saveState = '';
        }
    }

    public isClean() {
        const fields = ['atlas', 'elasticsearch', 'es_domain', 'es_dark', 'mongo'];
        let clean = true;
        let cleanZone = true;

        for (let i = 1; i < this.zoneData.length; i++) {
            cleanZone = true;
            for (let j = 0; j < fields.length; j++) {
                if (this.zoneData[i][fields[j]] !== this.cleanData[i][fields[j]]) {
                    // console.log(fields[j], this.zoneData[i][fields[j]]);
                    // console.log(this.cleanData[i][fields[j]]);
                    clean = false;
                    cleanZone = false;
                }
            }
            if (cleanZone) {
                this.zoneData[i]._class = '';
            } else {
                this.zoneData[i]._class = 'bgWarning';
                this.zoneData[i]._barColor = 'var(--c_primaryLight)';
                this.zoneData[i]._status = 'bar';
            }
        }

        return clean;
    }
}
