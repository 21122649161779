import {
    IsDefined,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    Matches,
    MaxLength,
    ValidateNested,
} from 'class-validator';

import {Exclude, Expose, Type} from 'class-transformer';

import {HyperionFilterParams} from '../../models/models';

export interface AudienceFilterParams extends HyperionFilterParams {
    type: AUDIENCE_TYPE;
}

/* Each octet should be 0-255 and mask 0-32. */
/* tslint:disable-next-line: max-line-length variable-name */
const IPOrNetmaskRegex =
    /^(25[0-5]\.|2[0-4]\d\.|1\d{2}\.|[1-9]\d\.|\d\.){3}(25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d|\d)($|\/3[0-2]$|\/[1-2]\d$|\/\d$)/;
const MESSAGE = {
    COUNTRY_CODE: 'Invalid country code.',
    DMA_CODE: 'Must be a valid DMA code.',
    IP_NETMASK: 'Must be a valid IP v4 or Netmask.',
    MAX_LENGTH: 'Must be $constraint1 characters or less.',
    REQUIRED: 'You must enter a value.',
    ZIP_CODE: 'Must be a valid zip code.',
};
const MESSAGE_REQUIRED = 'This is required.';

export const DELETED_AUDIENCE = '*****DELETED*****';

export enum AUDIENCE_TYPE {
    MULTIPLE = 'Multiple',
    SINGLE = 'Single',
}

export class AudienceMetadata {
    @Expose({name: 'hidden-columns'})
    @MaxLength(20, {each: true})
    public hiddenColumns: string[] = [];
}

export class SimpleAudience {
    @Matches(/^[a-z]{2}$/i, {each: true, message: MESSAGE.COUNTRY_CODE})
    public country_codes?: string[] = [];

    @IsDefined({message: MESSAGE_REQUIRED})
    @IsNotEmpty({message: MESSAGE_REQUIRED})
    @IsString()
    public desc: string = '';

    @MaxLength(20, {each: true})
    public devices: string[] = [];

    @MaxLength(15, {each: true, message: MESSAGE.MAX_LENGTH})
    @Matches(/^\d+$/, {each: true, message: MESSAGE.DMA_CODE})
    public dma_codes: string[] = [];

    @IsString()
    public external_id: string = '';

    @IsString()
    @IsOptional()
    @Exclude({toPlainOnly: true})
    public id: string = '';

    @Matches(IPOrNetmaskRegex, {each: true, message: MESSAGE.IP_NETMASK})
    public ip_networks: string[] = [];

    @IsString()
    public match_type: string = '';

    @MaxLength(32, {each: true})
    public sub_audiences: string[] = [];

    @IsString()
    public type: string = '';

    @Matches(/^\d{5}$/, {each: true, message: MESSAGE.ZIP_CODE})
    public zip_codes: string[] = [];

    @ValidateNested()
    @Type(() => SimpleAudience)
    @Exclude({toPlainOnly: true})
    public '@included': SimpleAudience[];

    // runtime field, need not be serialized
    public selected: boolean = false;

    // used by c-table for 'active' row style.
    public _class: string = '';
}

export class AudiencesListResponse {
    @ValidateNested()
    @Type(() => SimpleAudience)
    public items: SimpleAudience[] = [];

    @IsNumber()
    public total_items: number;
}
