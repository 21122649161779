import {SecondsToHmsValueConverter} from '@bindable-ui/bindable';

export class VDurationOrStatus {
    public textValue;

    public activate(model) {
        const {row} = model;

        if (row) {
            if (row.state === 'ready') {
                this.textValue = SecondsToHmsValueConverter.transform(row.duration);
            } else {
                this.textValue = row.state;
            }
        }
    }
}
