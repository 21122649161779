import {CToastsService} from '@bindable-ui/bindable';
import {TemplatesResponseItem} from 'apps/cms/routes/slicers/models/template';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom, PLATFORM} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ConfigSettingsLiveSlicer, DocumentationUrlLiveSlicer} from 'resources/config/settings/live-slicer';
import {ConfigTable} from 'resources/config/table';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {TemplateMenu} from '..';
import {TemplatesService} from '../../services/template-service';
import {Config as SharedModel} from '../single/models';
import {Config} from './models';
import {TemplateListTable} from './table';
import {ITemplateListTableRow} from './table-models';

@autoinject
export class TemplateList {
  public config: Config;
  public table: TemplateListTable;
  protected templatesService: TemplatesService;

  constructor(
    protected acceo: Acceo,
    protected dialogService: DialogService,
    protected notification: CToastsService,
    protected router: Router,
    protected sessionService: SessionService,
    protected templateMenu: TemplateMenu,
  ) {
    this.templatesService = new TemplatesService(acceo);
  }

  public activate(params?) {
    if (!params || !Object.keys(params).length) {
      this.config = new Config();
      this.load();
    }
    this.config.input.search = '';
  }

  @computedFrom('table.rows', 'config.input.search')
  get filteredRows(): ITemplateListTableRow[] {
    return this.table.rows.filter(row => {
      let foundText = true;
      if (this.config.input.search && this.config.input.search.length) {
        this.config.input.searchQuery = this.config.input.search;
        foundText = this.searchText(row, this.config.input.searchKeys);
      }
      return foundText;
    });
  }

  public actionCreate() {
    const shared = new SharedModel();
    shared.info.documentationUrl = DocumentationUrlLiveSlicer;
    shared.input.table = new ConfigTable(ConfigSettingsLiveSlicer, false, false);
    shared.options.configSetting = ConfigSettingsLiveSlicer;
    shared.state.canImportTemplates = true;
    this.dialogService
      .open({
        model: {
          bodyViewModel: PLATFORM.moduleName('apps/cms/routes/slicers/templates/single/body'),
          footerEnable: true,
          footerText: 'footer',
          footerViewModel: PLATFORM.moduleName('apps/cms/routes/slicers/templates/single/footer'),
          sharedModel: shared,
          size: 'full',
          title: 'Create Template',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed()
      .then(result => {
        if (!result.wasCancelled) {
          this.load();
        }
      });
  }

  public async actionDelete() {
    this.config.state.isDeleting = true;
    try {
      await this.requestDelete(this.table.rowsSelected);
      this.notification.success(
        `Deleted ${this.table.rowsSelected.length} template${this.table.rowsSelected.length === 1 ? '' : 's'}`,
      );
      await Promise.all([
        this.templateMenu.load(),
        this.load(),
      ]);
    } catch (reason) {
      this.notification.error(reason.message);
    }
    this.config.state.isDeleting = false;
  }

  public showHideColumns(col) {
    col.selected = !col.selected;
    this.table.trackSelectedCol(col);
  }

  protected async load() {
    this.config.state.error = undefined;
    this.config.state.isLoading = true;
    this.config.state.canExportCSV = this.sessionService.hasHostedSlicersAccess;
    this.table = new TemplateListTable(row => this.router.navigate(row.id));
    this.config.input.searchKeys = this.table.colsSelectable.map(col => col.colHeadName);
    try {
      this.table.rows = await this.requestRead();
    } catch (reason) {
      this.config.state.error = reason;
    }
    this.config.state.isLoading = false;
  }

  protected async requestDelete(ids: string[]) {
    return Promise.all(ids.map(id => this.templatesService.delete(id)));
  }

  protected async requestRead(): Promise<ITemplateListTableRow[]> {
    return this.templatesService.get().then(value =>
      value.items
        .map(this.requestReadRow.bind(this))
        .map(this.table.addRowProperties)
        .sort((itemA, itemB) => {
          if (!itemA.isReadOnly && !itemB.isReadOnly) {
            if (itemA.default && !itemB.default) {
              return -1;
            }
            if (!itemA.default && itemB.default) {
              return 1;
            }
            if (itemA.favorite && !itemB.favorite) {
              return -1;
            }
            if (!itemA.favorite && itemB.favorite) {
              return 1;
            }
          }
          if (!itemA.isReadOnly && itemB.isReadOnly) {
            return -1;
          }
          if (itemA.isReadOnly && !itemB.isReadOnly) {
            return 1;
          }
          const nameA = itemA.name.toUpperCase();
          const nameB = itemB.name.toUpperCase();
          // eslint-disable-next-line no-nested-ternary
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        }),
    );
  }

  protected requestReadRow(item: TemplatesResponseItem, index: number): ITemplateListTableRow {
    // todo: add row state for 'deleted'
    return {
      '@id': item['@id'],
      '@type': item['@type'],
      access: item.access,
      config: item.config,
      created: item.created,
      default: item.default,
      desc: item.desc,
      favorite: item.favorite,
      id: item.id,
      isReadOnly: this.sessionService.owner !== item.owner,
      lastmod: item.lastmod,
      name: item.name,
      order: index,
      owner: item.owner,
      parent: item.parent,
      selected: false,
    };
  }

  protected searchText(row, queryKeys) {
    let foundMatch = false;
    if (!this.config.input.searchQuery) {
      return true;
    }
    const query = this.config.input.searchQuery.replace(/\+/g, '\\+');
    const regex = new RegExp(query, 'i');
    queryKeys.forEach(key => {
      if (typeof row[key] === 'string' && !foundMatch) {
        foundMatch = !!row[key].toLowerCase().match(regex);
      }
    });
    return foundMatch;
  }
}
