import {Type} from 'class-transformer';
import {IsNumber, IsOptional, IsString, ValidateNested} from 'class-validator';

export class ProviderConfigResponseItem {
  // list of Providers obtained from Services Endpoint
  @IsOptional()
  @IsString()
  public provider_name?: string;

  @IsOptional()
  @IsNumber()
  public testable?: number;

  @IsOptional()
  @IsNumber()
  public testType?: number;

  @IsString()
  public text: string;

  @IsString()
  public info: string;

  @IsString()
  public value: string;
}

export class ProviderConfigResponseCollection {
  @ValidateNested()
  @Type(() => ProviderConfigResponseItem)
  public items: ProviderConfigResponseItem[];
}
