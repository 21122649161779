import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, containerless} from 'aurelia-framework';
import * as moment from 'moment';
import {Notification} from 'resources/notification/service';
import {AD_DEBUG_EVENT} from '../index';
import {PlaybackType, Status} from '../models/ad-server-debug-response';
import {DebugSearchCriteria} from '../models/debug-search-criteria';
import {DEFAULT_PER_PAGE_LIMIT} from '../models/defaults';
import {AdServerDebugService} from '../services/ad-server-debug-service';

export enum Mode {
  Main = 'main',
  Sidebar = 'sidebar',
}

export function dateDelta(hoursDelta: number = 0, minutesDelta: number = 1) {
  /*
     Returns the current date/time minus 1 minute when no params are passed to override this behavior.
     - set to 1 minute behind now() to ensure api does not choke on now()
    */
  return Number(moment().subtract({hours: hoursDelta, minutes: minutesDelta}));
}

@containerless()
@autoinject()
export class AdServerDebugQuery {
  @bindable
  public mode = Mode.Main;

  @bindable
  public criteria: DebugSearchCriteria;

  public start_adjob_date: number;
  public end_adjob_date: number;
  public status: string;
  public playback_type: PlaybackType;
  public channel_guid: string = null;
  public viewer_guid: string = null;
  public video_guid: string = null;
  public debug_name: string = null;
  public page: number;
  public page_size: number;

  public ad_debug_request_url_config = [];

  public debugError: string;
  public adServerDebugService: AdServerDebugService;
  public includes = [];

  public playback_types = [
    {label: 'LIVE Channel/Event', value: PlaybackType.LIVE},
    {label: 'VOD', value: PlaybackType.VOD},
  ];

  public statusOptions = [
    {value: '', text: ''},
    {value: Status.Complete, text: 'Completed'},
    {value: Status.Fail, text: 'Failed'},
    {value: Status.Pending, text: 'Pending'},
    {value: Status.Processing, text: 'Processing'},
  ];

  public startDateCallback = {
    onChange: ts => {
      let dateTime = ts;
      if (ts === '') {
        dateTime = this.start_adjob_date;
      }
      const newTimestamp = dateTime ? Number(dateTime) : null;
      if (this.start_adjob_date !== newTimestamp && newTimestamp) {
        this.start_adjob_date = newTimestamp;
      }
    },
  };

  public endDateCallback = {
    onChange: ts => {
      let dateTime = ts;
      if (ts === '') {
        dateTime = this.end_adjob_date;
      }
      const newTimestamp = dateTime ? Number(dateTime) : null;
      if (this.end_adjob_date !== newTimestamp && newTimestamp) {
        this.end_adjob_date = newTimestamp;
      }
    },
  };

  constructor(public eventAggregator: EventAggregator, public notification: Notification) {
    this.init();
  }

  public attached() {
    // call init for main mode.
    if (!this.criteria) {
      this.init();
    }
  }

  public init() {
    this.start_adjob_date = dateDelta(12, 1);
    this.end_adjob_date = dateDelta();
    this.playback_type = PlaybackType.LIVE;
    this.includes = [
      {label: 'Transactions', id: 'transactions', value: true},
      {label: 'Beacons', id: 'callbacks', value: true},
    ];

    this.ad_debug_request_url_config = [
      {
        provider: 'GAM',
        title: 'Google Ad Manager',
        params: [
          {
            label: 'AUTH P',
            parm_key: 'authp',
            value: '',
            id: 'gam-authp',
          },
          {
            label: 'ID TYPE',
            parm_key: 'idtype',
            value: '',
            id: 'gam-idtype',
          },
          {
            label: 'IU',
            parm_key: 'iu',
            value: '',
            id: 'gam-iu',
          },
          {
            label: 'PPID',
            parm_key: 'ppid',
            value: '',
            id: 'gam-ppid',
          },
          {
            label: 'RDID',
            parm_key: 'rdid',
            value: '',
            id: 'gam-rdid',
          },
          {
            label: 'SWID',
            parm_key: 'swid',
            value: '',
            id: 'gam-swid',
          },
          {
            label: 'VID',
            parm_key: 'vid',
            value: '',
            id: 'gam-vid',
          },
        ],
      },
      {
        provider: 'INVIDI',
        title: 'INVIDI',
        params: [
          {
            label: 'Household Identifier',
            parm_key: 'hhid',
            value: '',
            id: 'invidi-hhid',
          },
          {
            label: 'Targeting ID',
            parm_key: 'devid',
            value: '',
            id: 'invidi-devid',
          },
          {
            label: 'Device Type',
            parm_key: 'dev',
            value: '',
            id: 'invidi-dev',
          },
        ],
      },
    ];

    this.page = 1;
    this.page_size = DEFAULT_PER_PAGE_LIMIT;
  }

  public criteriaChanged(criteria: DebugSearchCriteria) {
    if (criteria) {
      const c = criteria;
      if (!c.start_adjob_date) {
        c.start_adjob_date = dateDelta(12, 1);
      }
      if (!c.end_adjob_date) {
        c.end_adjob_date = dateDelta();
      }
      this.start_adjob_date = c.start_adjob_date;
      this.end_adjob_date = c.end_adjob_date;
      this.status = c.status;
      this.includes.forEach(i => {
        i.value = c.includes.indexOf(i.id) >= 0;
      });
      this.playback_type = c.playback_type;
      this.channel_guid = c.channel_guid;
      this.viewer_guid = c.viewer_guid;
      this.video_guid = c.video_guid;
      this.debug_name = c.debug_name;
      this.ad_debug_request_url_config = c.ad_debug_request_url_config;
      if (!c.page) {
        c.page = 1;
      }
      if (!c.page_size) {
        c.page_size = DEFAULT_PER_PAGE_LIMIT;
      }
      this.page = c.page;
      this.page_size = c.page_size;
    }
  }

  public fetchAdData() {
    const criteria = this.populate();
    this.eventAggregator.publish(AD_DEBUG_EVENT.Fetch, {criteria});
  }

  public populate() {
    const criteria: DebugSearchCriteria = new DebugSearchCriteria();
    // default start and end dates when they are not present on the URI
    if (!this.start_adjob_date) {
      this.start_adjob_date = dateDelta(12, 1);
    }
    if (!this.end_adjob_date) {
      this.end_adjob_date = dateDelta();
    }
    criteria.start_adjob_date = this.start_adjob_date;
    criteria.end_adjob_date = this.end_adjob_date;
    criteria.status = this.status;
    criteria.includes = this.includes
      .filter(i => i.value)
      .map(i => i.id)
      .join(',');
    criteria.playback_type = this.playback_type;
    criteria.channel_guid = this.playback_type === PlaybackType.LIVE ? this.channel_guid : null;
    criteria.viewer_guid = this.viewer_guid;
    criteria.video_guid = this.video_guid;
    criteria.debug_name = this.debug_name;
    criteria.ad_debug_request_url_config = this.ad_debug_request_url_config;
    criteria.request_url = criteria.formatAdDebugRequestUrlParams(this.ad_debug_request_url_config);
    if (this.criteria) {
      criteria.order = this.criteria.order;
      if (this.criteria.page) {
        criteria.page = this.criteria.page;
        criteria.page_size = this.criteria.page_size;
      }
    }

    return criteria;
  }

  public quickPickTimeChange(t) {
    const quickPickBtnClickedVal = t;
    this.start_adjob_date = dateDelta(0, quickPickBtnClickedVal);
    this.end_adjob_date = dateDelta();
  }
}
