import * as CTableCssJson from '@bindable-ui/bindable/dist/native-modules/components/tables/c-table/c-table.css.json';
import {CTableSelectable} from 'resources/components/c-table-selectable/c-table-selectable';
import {
  CTableColSelectable,
  CTableRowBehavior,
} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {PluginListTableRow, PluginTableColHeadValue} from './table-models';

export class PluginSelectionTable extends CTableSelectable {
  public rows: PluginListTableRow[];
  public selected: PluginListTableRow;
  protected colsSelectableDefault: CTableColSelectable[] = [
    {
      colClass: this.getClass(CTableCssJson.t175),
      colHeadName: 'status',
      colHeadValue: PluginTableColHeadValue.status,
      selected: true,
      sort: true,
      valueConverter: 'defaultValue: N/A',
    },
    {
      colClass: this.getClass(CTableCssJson.t270),
      colHeadName: 'slicer_plugin',
      colHeadValue: PluginTableColHeadValue.slicer_plugin,
      selected: true,
      sort: true,
      valueConverter: 'defaultValue: N/A',
    },
    {
      colClass: this.getClass(CTableCssJson.t270),
      colHeadName: 'slicer_plugin_version',
      colHeadValue: PluginTableColHeadValue.slicer_plugin_version,
      selected: true,
      sort: true,
      valueConverter: 'defaultValue: N/A',
    },
    {
      colClass: this.getClass(CTableCssJson.t240),
      colHeadName: 'creation_time',
      colHeadValue: PluginTableColHeadValue.creation_time,
      selected: true,
      sort: true,
      valueConverter: "timezoneTimeToStandardDateTimeMS:'MMM D, YYYY @ h:mm A'",
    },
  ];

  constructor() {
    super(CTableRowBehavior.RADIO);
    this.colsSelectable = this.colsSelectableDefault;
    this.actions.getColClass = PluginSelectionTable.getColClass;
    this.actions.rowClick = this.rowClickRadio.bind(this);
  }

  protected static getColClass(row: PluginListTableRow, col: CTableColSelectable) {
    if (col.colHeadValue === PluginTableColHeadValue.status) {
      switch (`${row.status}`.toUpperCase()) {
        case 'OPERATIONAL':
          return 'bgHealthy';
        case 'DELETED':
          return 'bgCritical';
        case 'DELETED_IN_USE':
          return 'bgWarning';
        default:
          return '';
      }
    }
    return col._class;
  }

  protected trackSelectedRadio() {
    super.trackSelectedRadio();
    const item = this.rows.filter(row => row.id === this.rowsSelected[0])[0];
    Object.assign(this.selected, item);
  }
}
