import {autoinject, LogManager} from 'aurelia-framework';
import {Notification} from 'resources/notification/service';
import {Acceo} from 'services/acceo';

import {DEFAULT_PER_PAGE_LIMIT} from '../models/defaults';
import {ProfileFilterParams, ProfilesMeta, WebhookProfile, WebhookProfilesListResponse} from '../models/models';

const log = LogManager.getLogger('webhook-service');
export const WEBHOOK_PROFILES_URL: string = '/api/v4/webhook-profiles';
export const WEBHOOK_PROFILES_BULK_DELETE_URL: string = '/api/v4/delete-webhook-profiles';

@autoinject()
export class WebhookService {
    public profiles: WebhookProfile[] = [];
    public profileId: string;
    public profileNotFound: boolean;
    public profileStatusCodeError: number;
    public currentModel: WebhookProfile;
    public changeset: WebhookProfile;
    public deleteTip: any;
    public createTip: any;
    public meta: ProfilesMeta = {};
    public isLoading: boolean = false;
    public isLoadingMore: boolean = false;
    public params: ProfileFilterParams = {};

    constructor(private acceo: Acceo, public notification: Notification) {}

    /**
     * Get a list of webhook profiles based on a set of parameters
     *
     * @param params ChannelFilterParams object
     * @param isLoadMore Optional boolean for if it's a fresh load or it's loading more in the existing list
     */
    public async getProfiles(params: ProfileFilterParams = {}, isLoadMore: boolean = false) {
        if (this.isLoading || isLoadMore) {
            return;
        }
        if (!params.page_size) {
            params.page_size = DEFAULT_PER_PAGE_LIMIT;
        }

        if (!isLoadMore) {
            this.profiles = [];
            this.isLoading = true;
        } else {
            this.isLoadingMore = true;
            params.page =
                Math.floor((this.meta.total - (this.meta.total - this.profiles.length)) / params.page_size) + 1;
        }

        params.archived = false;
        this.params = _.cloneDeep(params);

        try {
            const res = await this.acceo.get(WebhookProfilesListResponse)(`${WEBHOOK_PROFILES_URL}?${$.param(params)}`);

            this.profiles = _.uniqBy(this.profiles.concat(res.items), '@id');
            this.meta.total = res.total_items;
            this.meta.showing = this.profiles.length;
            this.meta.limit = params.page_size || null;
            this.meta.hasMore = this.profiles.length < res.total_items;
        } catch (err) {
            log.error(err);
            throw new Error(err.message);
        } finally {
            this.isLoading = false;
            this.isLoadingMore = false;
        }
    }

    /**
     * Gets more channels based on scrolling the table
     */
    public getMoreProfiles() {
        if (this.meta.showing < this.meta.total) {
            const params = _.cloneDeep(this.params);

            this.getProfiles(params, true);
        }
    }

    public async createProfile(newModel: WebhookProfile): Promise<WebhookProfile> {
        try {
            delete newModel.checkbox;
            const res = await this.acceo.post(WebhookProfile)(`${WEBHOOK_PROFILES_URL}`, newModel);

            return res;
        } catch (err) {
            log.error(err);
            throw new Error(err.message);
        }
    }

    /**
     * Get a single webhook profile by ID
     * @param id Profile ID
     */
    public async getSingleProfile(id: string, isPolling: boolean = false) {
        this.profileNotFound = false;
        this.profileStatusCodeError = 0;
        try {
            const res = await this.acceo.get(WebhookProfile)(`${WEBHOOK_PROFILES_URL}/${id}`);

            this.currentModel = res;
        } catch (err) {
            if (!isPolling) {
                this.profileNotFound = true;
                this.profileStatusCodeError = err.status_code;
            }
            log.error(err);
            throw new Error(err.message);
        }
    }

    public async saveProfile(updatedModel: WebhookProfile): Promise<WebhookProfile> {
        const mod = _.cloneDeep(updatedModel);
        try {
            const id = mod.getId();
            delete mod.checkbox;
            delete mod.created_at;
            delete mod['@id'];

            const res = await this.acceo.patch(WebhookProfile)(`${WEBHOOK_PROFILES_URL}/${id}`, mod);
            this.notification.success('Changes saved successfully.');

            // Update profile in list after updating (if in list).
            this.profiles.map(p => {
                if (p['@id'] === updatedModel['@id']) {
                    return res;
                }
                return p;
            });

            return res;
        } catch (err) {
            log.error(err);
            throw new Error(err.message);
        }
    }

    public async archiveProfile(id: string) {
        try {
            await this.acceo.delete()(`${WEBHOOK_PROFILES_URL}/${id}`);

            this.currentModel = null;
        } catch (err) {
            log.error(err);
            throw new Error(err.message);
        }
    }

    public async archiveProfiles(ids: string[]) {
        try {
            await this.acceo.post()(WEBHOOK_PROFILES_BULK_DELETE_URL, {ids});
            this.notification.success(`${ids.length} profile(s) were deleted.`);
            this.deleteTip.hide();
        } catch (err) {
            log.error(err);
            this.notification.error(`There was a problem deleting the profile(s): ${err}`);
            throw new Error(err.message);
        }
    }
}
