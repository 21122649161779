import {DialogService, DialogSettings} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, containerless, PLATFORM} from 'aurelia-framework';
import * as moment from 'moment';

import {Status, Transaction} from '../models/ad-server-debug-response';

export interface TransactionRow {
  level: number;
  created_at: string;
  request_succeeded: boolean;
  is_wrapper: boolean;
  total_elapsed_request_time: number;
  id: string;
  warnings: string;
  failure_type: string;
  failure_reason: string;
  status: Status;
  transIndex: number;
  wrapperDepth: number;
  parentId: string;
  initialAdId: string;
  show: boolean;
  numChildren: number;
  numChildrenFailed: number;
  childrenShowing: boolean;
}

const DATE_FIELDS = [
  'created_at',
];

const NUMERIC_FIELDS = [
  'total_elapsed_request_time',
  'transIndex',
  'wrapperDepth',
];

@autoinject()
@containerless()
export class TransactionDetail {
  @bindable
  public transactions: Transaction[] = [];

  public transactionRows: TransactionRow[] = [];

  public columns: string[];
  public export_columns = [];
  public order: string = 'created_at';

  constructor(public eventAggregator: EventAggregator, public dialogService?: DialogService) {
    this.columns = [
      'level',
      'status',
      'created_at',
      'typeField',
      'wrapperDepth',
      'total_elapsed_request_time',
      'id',
      'initialAdId',
    ];
    this.export_columns = [
      {
        colHeadName: 'status',
        colHeadValue: 'Status',
      },
      {
        colHeadName: 'numChildren',
        colHeadValue: 'Wrapper Chains',
      },
      {
        colHeadName: 'created_at',
        colHeadValue: 'Date Created',
      },
      {
        colHeadName: 'typeField',
        colHeadValue: 'Type',
      },
      {
        colHeadName: 'wrapperDepth',
        colHeadValue: 'Wrapper Depth',
      },
      {
        colHeadName: 'total_elapsed_request_time',
        colHeadValue: 'Total Elapsed Time',
      },
      {
        colHeadName: 'id',
        colHeadValue: 'Transaction ID',
      },
      {
        colHeadName: 'initialAdId',
        colHeadValue: 'Initial Ad Id',
      },
    ];
  }

  public getRowState(status: string) {
    // return a state based on the status of the transaction
    if (status === Status.Complete) {
      return 'success';
    }
    if (status === Status.Fail) {
      return 'danger';
    }
    return 'neutral';
  }

  public getTreeState(transaction: Transaction, stateSwitch: string) {
    if (transaction.numChildrenFailed === 0) {
      return 'success';
    }

    switch (stateSwitch) {
      case 'numChildren':
        if (!transaction.parentId && transaction.numChildrenFailed < this.transactionRows.length) {
          return 'warning';
        }
        if (transaction.numChildrenFailed < transaction.numChildren) {
          return 'warning';
        }
        if (transaction.numChildrenFailed === transaction.numChildren) {
          return 'danger';
        }
        return 'neutral';
      case 'numTransactionsSpawned':
        if (transaction.numChildrenFailed < transaction.numTransactionsSpawned) {
          return 'warning';
        }
        if (transaction.numChildrenFailed === transaction.numTransactionsSpawned) {
          return 'danger';
        }
        return 'neutral';
      case 'numWrapperEnds':
        if (transaction.numChildrenFailed < transaction.numWrapperEnds) {
          return 'warning';
        }
        if (transaction.numChildrenFailed === transaction.numWrapperEnds) {
          return 'danger';
        }
        return 'neutral';
      default:
        return 'neutral';
    }

    // return a state based on the status of the transaction tree,
    // taking into consideration if this is the root parent
  }

  public sortColumn($event) {
    // TODO get rid of this method
    // get the id of the item we are sorting and manage sort order
    const sortById = $event.target.id;

    if (!sortById) {
      return;
    }

    const key = this.order.replace('-', '');
    if (sortById === key) {
      //  we have the same key - just flip the order
      const reverse = this.order.indexOf('-') > -1;
      this.order = reverse ? key : `-${this.order}`;
      this.sortItems(key, reverse);
    } else {
      // reset the current sort order key
      let colHeadElement: HTMLElement;
      this.order = `-${sortById}`;

      this.sortItems(sortById, false);
      this.columns.forEach(col => {
        if (col !== sortById) {
          colHeadElement = document.getElementById(col);
          colHeadElement.removeAttribute('sort-direction');
        }
      });
    }
  }

  public sortItems(key: string, reverse: boolean) {
    const numberSorting = NUMERIC_FIELDS.includes(key);
    const dateSorting = DATE_FIELDS.includes(key);

    this.transactionRows.sort((a, b) => {
      let aVal = a[key];
      let bVal = b[key];

      if (numberSorting) {
        // coerce negative value when undefined
        if (Number.isNaN(parseInt(aVal, 10))) aVal = -1;
        if (Number.isNaN(parseInt(aVal, 10))) bVal = -1;
      } else if (dateSorting) {
        // convert to sortable date value IF IT is defined
        if (aVal && aVal !== undefined && aVal !== null) {
          aVal = moment(aVal);
        } else {
          aVal = '';
        }
        if (bVal && bVal !== undefined && bVal !== null) {
          bVal = moment(bVal);
        } else {
          bVal = '';
        }
      } else {
        // coerce all other values as string values handling undefined
        // and also convert to lowercase to ensure proper sorting
        aVal = `${aVal}`.toLowerCase();
        bVal = `${bVal}`.toLocaleLowerCase();
      }

      if (!reverse) {
        return bVal > aVal ? -1 : 1;
      }
      return bVal > aVal ? 1 : -1;
    });
  }

  private hideChildrenShowing(tr: TransactionRow) {
    // hide all the children under this parent node
    tr.childrenShowing = false;

    this.transactionRows.forEach(t => {
      if (t.parentId === tr.id) {
        t.show = false;
        // t.childrenShowing = tr.childrenShowing;
        if (t.numChildren > 0) {
          t.childrenShowing = false;
          this.hideChildrenShowing(t);
        }
      }
    });
  }

  public showAllRows() {
    this.transactionRows.forEach(tr => {
      tr.show = true;
      if (tr.numChildren > 0) {
        tr.childrenShowing = true;
      }
    });
  }

  public hideAllRows() {
    this.transactionRows.forEach(tr => {
      tr.show = !(tr.level > 1);
      if (tr.numChildren > 0 && tr.level > 0) {
        tr.childrenShowing = false;
      }
    });
  }

  public toggleRows(row: TransactionRow) {
    // toggle show/hide children

    if (row.level < 1 || row.numChildren === 0) {
      // never hide children of primary transaction
      // if there are no children, there is nothing to show or hide
      return;
    }

    row.childrenShowing = !row.childrenShowing;

    this.transactionRows.forEach(tr => {
      // tr.show = tr.parentId === row.id ? row.childrenShowing : tr.show;
      if (tr.parentId === row.id) {
        // found the parent, let's show or hide based on what we want to do...
        tr.show = row.childrenShowing;
        if (tr.numChildren > 0 && !row.childrenShowing) {
          // close all the children under this parent
          this.hideChildrenShowing(tr);
        }
      }
    });
  }

  public getColor(row: TransactionRow) {
    if (row.childrenShowing && row.numChildren > 0) {
      return 'success';
    }
    return 'warning';
    // return 'success' ? (!row.childrenShowing && (row.numChildren > 0)) : 'neutral';
  }

  public transactionsChanged() {
    if (this.transactions) {
      this.transactionRows = this.transactions.map(t => ({
        level: Number(t.level),
        created_at: t.created_at,
        failure_type: t.failure_type,
        failure_reason: t.failure_reason,
        id: t.id,
        is_wrapper: t.is_wrapper,
        request_succeeded: t.request_succeeded,
        status: t.status,
        total_elapsed_request_time: Number(t.total_elapsed_request_time.toFixed(3)),
        warnings: t.warnings,
        typeField: t.typeField,
        transIndex: t.transIndex,
        wrapperDepth: t.wrapperDepth,
        parentId: t.parentId,
        initialAdId: t.initialAdId,
        show: t.level <= 1,
        numChildren: t.numChildren,
        numChildrenFailed: t.numChildrenFailed,
        numTransactionsSpawned: t.numTransactionsSpawned,
        numWrapperEnds: t.numWrapperEnds,
        childrenShowing: t.level === 0,
      }));
    }
  }

  public onTransactionClick(tr: TransactionRow) {
    const transaction: Transaction = this.transactions.find(t => t.id === tr.id);
    this.transactionModal.model.bodyModel = transaction;
    this.transactionModal.model.title = `Transaction ID ${transaction.id}`;
    this.dialogService.open(this.transactionModal);
  }

  public statusMapping = {
    [Status.Fail]: 'Failed',
    [Status.Complete]: 'Completed',
    [Status.Pending]: Status.Pending,
    [Status.Processing]: Status.Processing,
  };

  public prettyStatus(status: string) {
    return _.capitalize(this.statusMapping[status] || status);
  }

  private transactionModal: DialogSettings = {
    model: {
      bodyViewModel: PLATFORM.moduleName('apps/cms/routes/ad-server-debug/transaction/index'),
      size: 'full',
    },
    viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
  };
}
