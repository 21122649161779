import {Type} from 'class-transformer';
import {IsOptional, IsString, ValidateNested} from 'class-validator';

export class MacroConfigResponseItem {
  @IsOptional()
  @IsString()
  public formElement?: string;

  @IsOptional()
  @IsString()
  public info?: string;

  @IsOptional()
  @IsString()
  public input?: string;

  @IsString()
  public key: string;

  @IsOptional()
  public options?: {text: string; value: string}[];

  @IsOptional()
  @IsString()
  public text?: string;
}

export class MacroConfigResponseCollection {
  @ValidateNested()
  @Type(() => MacroConfigResponseItem)
  public items: MacroConfigResponseItem[];
}
