export const GENERAL_KEYS = [
  'auto_asset_expiry',
  'auto_asset_expiry_option',
  'post_slate_duration',
  'post_slate_duration_option',
  'vod_asset_expiry',
  'vod_asset_expiry_option',
  'live_event_ad_break_warning',
  'live_event_syndication_auto_start',
  'live_event_require_testing',
  'live_event_secret_display',
  'live_event_secret_type',
  'live_event_webhook',
];

export const BUFFERS_KEYS = [
  'live_event_resume_buffer',
  'post_hours',
  'post_minutes',
  'pre_hours',
  'pre_minutes',
];

export const SLICERS_KEYS = [
  'slicer_pool',
  'available_slicers',
];

export const LIVE_EVENT_KEYS = [
  ...GENERAL_KEYS,
  ...BUFFERS_KEYS,
  ...SLICERS_KEYS,
];

export const EDITOR_FIELD_NAMES = [
  'pre_hours',
  'pre_minutes',
  'post_hours',
  'post_minutes',
  'live_event_resume_buffer',
  'live_event_secret_type',
  'live_event_webhook',
  'post_slate_duration',
  'post_slate_duration_option',
  'auto_asset_expiry',
  'auto_asset_expiry_option',
  'vod_asset_expiry',
  'vod_asset_expiry_option',
  'live_event_require_testing',
  'live_event_syndication_auto_start',
  'available_slicers',
  'slicer_pool',
];
