import {bindable, customAttribute, inject} from 'aurelia-framework';
import 'select2';

@customAttribute('select2')
@inject(Element)
export class Select2Attribute {
    @bindable
    tags = false;

    @bindable
    allowClear = false;

    @bindable
    placeholder = '';

    @bindable
    selectChanged = 0; // Flag to signal a change has happened

    @bindable
    searchInputPlaceholder = 'Filter';

    @bindable
    tagAdd;

    @bindable
    maximumInputLength = 0;

    constructor(element) {
        this.element = element;
    }

    attached() {
        $(this.element)
            .select2({
                allowClear: this.allowClear,
                placeholder: this.placeholder,
                tags: this.tags,
                maximumInputLength: this.maximumInputLength,
            })
            .on('change', event => {
                if (event.originalEvent) return; // http://stackoverflow.com/a/34121891/4354884
                this.selectChanged = Date.now(); // Flag to signal change upstream
                this.element.dispatchEvent(new Event('change'));

                // Callback for adding tags
                // Make sure you add the new tag to the array and select it yourself
                const isNew = $(this.element).find('[data-select2-tag="true"]');
                if (isNew.length && this.tagAdd && _.isFunction(this.tagAdd)) {
                    const tag = isNew.val();
                    this.tagAdd({tag});
                    $(isNew).remove();
                }
            })
            .on('select2:open', () => {
                if (this.maximumInputLength > 0) {
                    // Workaround to limit like an input box
                    $('.select2-search__field').attr('maxlength', this.maximumInputLength);
                }
                $('.select2-search__field').attr('placeholder', this.searchInputPlaceholder);
            })
            .on('select2:closing', () => {
                $('.select2-search__field').attr('placeholder', '');
            })
            .on('select2:close', () => {
                $(':focus').blur();
            });
    }

    detached() {
        $(this.element).select2('destroy');
    }
}
