/* eslint-disable indent */
import {Exclude, Transform} from 'class-transformer';
import {IsArray, IsNumber, IsOptional, IsString} from 'class-validator';
import moment from 'moment';
import {PlaybackType} from './ad-server-debug-response';
import {DEFAULT_PER_PAGE_LIMIT} from './defaults';

export class DebugSearchCriteria {
    @IsNumber()
    @Transform(value => (typeof value === 'number' ? value : Number(moment(value).format('x'))), {toClassOnly: true})
    public start_adjob_date: number = 0;

    @IsNumber()
    @Transform(value => (typeof value === 'number' ? value : Number(moment(value).format('x'))), {toClassOnly: true})
    public end_adjob_date: number = 0;

    @IsString()
    public status: string = '';

    @IsString()
    public includes: string = '';

    @IsOptional()
    public playback_type: PlaybackType = PlaybackType.LIVE;

    @IsString()
    public order: string = '-created_at';

    @IsString()
    public channel_guid: string = '';

    @IsString()
    public viewer_guid: string = '';

    @IsString()
    public video_guid: string = '';

    @IsString()
    public debug_name: string = '';

    @IsString()
    public request_url: string = '';

    @IsArray()
    public ad_debug_request_url_config = [
        {
            provider: 'GAM',
            title: 'Google Ad Manager',
            params: [
                {
                    label: 'AUTH P',
                    parm_key: 'authp',
                    value: '',
                    id: 'gam-authp',
                },
                {
                    label: 'ID TYPE',
                    parm_key: 'idtype',
                    value: '',
                    id: 'gam-idtype',
                },
                {
                    label: 'IU',
                    parm_key: 'iu',
                    value: '',
                    id: 'gam-iu',
                },
                {
                    label: 'PPID',
                    parm_key: 'ppid',
                    value: '',
                    id: 'gam-ppid',
                },
                {
                    label: 'RDID',
                    parm_key: 'rdid',
                    value: '',
                    id: 'gam-rdid',
                },
                {
                    label: 'SWID',
                    parm_key: 'swid',
                    value: '',
                    id: 'gam-swid',
                },
                {
                    label: 'VID',
                    parm_key: 'vid',
                    value: '',
                    id: 'gam-vid',
                },
            ],
        },
        {
            provider: 'INVIDI',
            title: 'INVIDI',
            params: [
                {
                    label: 'Household Identifier',
                    parm_key: 'hhid',
                    value: '',
                    id: 'invidi-hhid',
                },
                {
                    label: 'Targeting ID',
                    parm_key: 'devid',
                    value: '',
                    id: 'invidi-devid',
                },
                {
                    label: 'Device Type',
                    parm_key: 'dev',
                    value: '',
                    id: 'invidi-dev',
                },
            ],
        },
    ];

    @IsOptional()
    public time_zone: string = '';

    @IsOptional()
    public page: number = 1;

    @IsOptional()
    public page_size: number = DEFAULT_PER_PAGE_LIMIT;

    @Exclude()
    public job_id: string = '';

    private getParamCSV(params_array) {
        // return a csv string of provider parameters having values
        let results = '';
        params_array.forEach(param => {
            if (Object.entries(param.value).length !== 0) {
                if (results !== '') {
                    results += ',';
                }
                results += `${param.parm_key}%3D${param.value}`;
            }
        });
        return results;
    }

    public formatAdDebugRequestUrlParams(ad_debug_request_url_config) {
        let pcsv = '';
        ad_debug_request_url_config.forEach(provider => {
            // send the params array for each provider
            const csv = this.getParamCSV(provider.params);
            if (csv.length > 0) {
                if (pcsv.length > 0) {
                    pcsv += ',';
                }
                pcsv += csv;
            }
        });
        return pcsv;
    }

    public toQueryString(): string {
        return Object.getOwnPropertyNames(this)
            .filter(n => !n.includes('_config') && !n.startsWith('__') && !!this[n])
            .map(n => {
                if (n === 'start_adjob_date' || n === 'end_adjob_date') {
                    let t = this[n];
                    if (typeof t === 'string') {
                        t = parseInt(t, 10);
                    }
                    return `${n}=${new Date(t).toISOString().split('.')[0]}Z`;
                }
                if (n === 'request_url') {
                    return `${n}=${this.formatAdDebugRequestUrlParams(this.ad_debug_request_url_config)}`;
                }
                return `${n}=${this[n]}`;
            })
            .join('&');
    }
}
