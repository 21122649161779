import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {LynkDialog, LynkInput} from '@uplynk/lynk-design';
import {Router} from 'aurelia-router';
import {SessionService} from 'services/session';
import {Toast} from 'resources/toast/toast';
import {AcceoErrorUtil} from '../components/acceo-error-util';
import {AdConfigResponseItem} from '../models/adconfig-response-collection';
import {AdServerMenu} from '..';
import {AdConfigService} from '../services/ad-config';
import {AdConfigListTable} from './table';
import {AdConfigListTableRow} from './table-models';
import {AdServerType} from '../../ad-server/models/ad-config';
import {Config} from '../models/config';
import {ConfigInput} from '../models/config-input';
import {ParamConfigService} from '../services/param-config';
import {ProviderConfigService} from '../services/provider-config';
import {AdServerRoute} from '../models/config-route';
import {TypeConfigService} from '../services/type-config';

const UPLYNK_AD_SERVER_DOMAINS = [
  '@protoven.com',
  '@uplynk.com',
  '@verizon.com',
  '@verizondigitalmedia.com',
  '@verizonmedia.com',
  '@oath.com',
];

@autoinject
export class AdConfigList {
  public config = new Config();
  public table: AdConfigListTable;
  public dialogCreate: LynkDialog;
  public formCreate: HTMLFormElement;
  public providerConfigService: ProviderConfigService;
  public typeConfigService: TypeConfigService;
  protected adConfigService: AdConfigService;
  protected paramConfigService: ParamConfigService;

  constructor(
    protected acceo: Acceo,
    protected router: Router,
    protected sessionService: SessionService,
    protected adConfigMenu: AdServerMenu,
  ) {
    this.adConfigService = new AdConfigService(acceo);
    this.paramConfigService = new ParamConfigService(acceo);
    this.providerConfigService = new ProviderConfigService(acceo);
    this.typeConfigService = new TypeConfigService(acceo);
    // do type config here?
    this.load();
  }

  public async actionCreate(edit = false) {
    this.config.state.isCreating = true;
    try {
      await this.adConfigService.post(this.config.input.type, this.config.input.name, this.config.input.fields);
      this.config.errors.name = undefined;
      this.dialogCreate.hide();
      this.config.state.isCreating = false;
      Toast.success(`${this.config.info.objectType} created successfully`);
      await this.adConfigMenu.load();
      if (edit) {
        this.router.navigate(this.config.input.name);
      } else {
        this.load();
      }
    } catch (error) {
      Toast.danger(AcceoErrorUtil.getHTML(error));
      this.config.state.isCreating = false;
    }
  }

  public async actionDelete(rowName = '') {
    this.config.state.isDeleting = true;
    if (rowName !== '') {
      try {
        await this.adConfigService.delete(rowName);
        Toast.success(`${this.config.info.objectType} deleted successfully`);
      } catch (error) {
        Toast.danger(AcceoErrorUtil.getHTML(error));
      } finally {
        this.config.state.isDeleting = false;
        await Promise.all([
          this.adConfigMenu.load(),
          this.load(),
        ]);
      }
      this.config.state.isDeleting = false;
    } else {
      // TODO once we bring multiple delets back, we will use this so leave it for now
      try {
        this.table.rows
          .filter(row => row.selected)
          .forEach(async row => {
            const response = await this.adConfigService.delete(row.name);
            if (response && response.message) {
              Toast.warning(response.message);
            }
          });
      } catch (error) {
        Toast.danger(AcceoErrorUtil.getHTML(error));
      } finally {
        this.config.state.isDeleting = false;
        await Promise.all([
          this.adConfigMenu.load(),
          this.load(),
        ]);
      }
    }
  }

  public getTypeLabel(types: any, lookupValue: string) {
    // using a set of types obtained from the providerconfig services and reformatted for this purpose,
    // lookup the value passed

    if (lookupValue === '' || lookupValue === undefined) {
      return 'Invalid Type';
    }

    const foundType = (types || []).find(type => type.value === lookupValue);
    if (foundType) {
      return foundType.text;
    }
    return `${lookupValue} not found`;
  }

  public getProviderLabel(providers: any, lookupValue: string) {
    // using a set of providers obtained from the providerconfig services and reformatted for this purpose,
    // lookup the value passed
    if (lookupValue === '' || lookupValue === undefined) {
      return 'Missing Provider';
    }

    const foundProvider = (providers || []).find(provider => provider.value === lookupValue);
    if (foundProvider) {
      return foundProvider.text;
    }
    return `${lookupValue} not found`;
  }

  public sortItems(array: any[], key: string, reverse: boolean) {
    if (!array) {
      return [];
    }
    return array.sort((a, b) => {
      const aVal = a[key];
      const bVal = b[key];
      if (!reverse) {
        return bVal > aVal ? -1 : 1;
      }
      return bVal > aVal ? 1 : -1;
    });
  }

  protected async load() {
    // this loads the initial list of data from the ad_configs settings
    this.config.state.error = undefined;
    this.config.state.isLoading = true;
    this.config.state.hasChanges = false;
    this.table = new AdConfigListTable();
    try {
      const typeConfig = await this.typeConfigService.get();
      this.config.options.type = (typeConfig.items || [])
        .filter(item => this.showAdConfigType(item.value))
        .map(item => ({
          value: item.value,
          text: item.text,
        }));
      this.table.rows = await this.requestRead();
      const responseProviderConfig = await this.providerConfigService.get();
      const providers = (responseProviderConfig.items || []).map(item => ({
        text: item.text,
        value: item.value,
        info: item.info,
      }));
      // iterate over each row and set the row.providerLabel using the api providerconfig data
      this.table.rows.forEach(row => {
        row.typeLabel = this.getTypeLabel(this.config.options.type, row.type);
        if (row.type !== 'uac') {
          row.providerLabel = this.getProviderLabel(providers, row.type);
        } else {
          row.providerLabel = this.getProviderLabel(providers, row.provider);
        }
      });
    } catch (error) {
      this.config.state.error = AcceoErrorUtil.getHTML(error);
    } finally {
      this.sortItems(this.table.rows, 'name', false);
      this.config.state.isLoading = false;
    }
  }

  protected async loadParams() {
    // used when creating a new ad config - loads params for the form - seems like mostly for legacy?
    this.config.state.isLoadingParams = true;
    if (this.config.input.type !== 'uac') {
      this.config.input.provider = this.config.input.type;
    }
    try {
      const responseProviderConfig = await this.providerConfigService.get();
      // the second param should be provider unless it is not specified or it is legacy
      // see type if statement (above) setting provider to type if legacy for this purpose
      const allConfigSettings = await this.paramConfigService.getConfigSettings(
        this.config.input.type,
        this.config.input.provider,
      );

      this.config.options.configSetting = allConfigSettings.filter(configSetting => configSetting.required);
      this.config.options.configSetting.forEach(configSetting => {
        this.config.input.fields[configSetting.key] = '';
        // now that we have restrictions on providers, always get a list of valid providers from the endpoint
        if (configSetting.key === 'provider') {
          const providers = (responseProviderConfig.items || []).map(item => ({
            text: item.text,
            value: item.value,
            info: item.info,
          }));
          configSetting.options = this.sortItems(providers, 'text', false);
        }
      });

      this.dialogCreate.show();
    } catch (error) {
      Toast.danger(AcceoErrorUtil.getHTML(error));
    }
    this.config.state.isLoadingParams = false;
  }

  public showAdConfigType(type: any) {
    const uplynkNoCreate = [
      'aol',
      'adtech',
    ];
    const uplynkCheckDomain = [
      'uplynkvod',
      'uplynklive',
      'vrm',
    ];

    if (uplynkNoCreate.includes(type)) {
      return false;
    }

    const {sessionInfo} = this.sessionService;
    const {username, flags} = sessionInfo;

    if (uplynkCheckDomain.includes(type)) {
      if (UPLYNK_AD_SERVER_DOMAINS.find(domain => username.endsWith(domain)) || flags.uplynk_ad_servers) {
        return true;
      }
      return false;
    }
    return true;
  }

  public onMenuItemClickType(type: AdServerType) {
    this.formCreate.reset();
    this.config.input = new ConfigInput();
    this.config.input.type = type;
    this.config.input.typeLabel = this.config.options.type.find(setting => setting.value === type).text;
    this.loadParams();
  }

  public onRequestCloseDialog(event: CustomEvent) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
      return false;
    }
    return true;
  }

  protected async requestRead(): Promise<AdConfigListTableRow[]> {
    // get the list of items for the list view
    // find a way to map these to the nav vs. a second, redundant get for nav
    try {
      return this.adConfigService
        .get()
        .then(response =>
          !response ? [] : response.items.filter(item => item.name).map(AdConfigList.requestReadRow.bind(this)),
        );
    } catch (err) {
      // this.config.state.error = true;
      if (err.response_code === 404) {
        return [];
      }
      return [];
    }
  }

  protected static requestReadRow(item: AdConfigResponseItem, index: number): AdConfigListTableRow {
    const row = {} as AdConfigListTableRow;
    row.id = item.name; // todo: use a real guid!
    row.order = index;
    Object.assign(row, item);
    return row;
  }

  public validateName(target: LynkInput) {
    let customError: string;

    // check for duplicate
    if (this.table.rows && this.table.rows.find(row => row.name === target.value)) {
      customError = `Conflicts with an existing ${AdServerRoute.routeTitle} configuration`;
    }

    this.config.errors.macro.key = customError;
    target.setCustomValidity(customError || '');
    target.reportValidity();
  }
}
