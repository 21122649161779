import {DialogService} from 'aurelia-dialog';
import {Container, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

const log = LogManager.getLogger('DirtyCheckPrompt');
const promptMessages = {
    header: 'Are you sure you want to leave?',
    question: 'There are unsaved changes. If you leave before saving, your changes will be lost.',
    yes: 'Yes, Discard My Changes',
};

export function dirtyCheckPrompt<T extends {new (...args: any[]): {}}>(constructor: T) {
    return class DirtyCheckPrompt extends constructor {
        public isDirty: () => boolean;
        public onDirtyPromptStay: () => void;
        public onDirtyPromptLeave: () => void;
        public canDeactivate(): Promise<boolean> {
            if (typeof this.isDirty !== 'function') {
                log.error(
                    `[${this.constructor.name}] @dirtyCheckPrompt decorator used ` +
                        'but isDirty function not defined.',
                );
                return Promise.resolve(true);
            }
            if (this.isDirty()) {
                const dirtyModel = {
                    cancel: 'Cancel',
                    footerClass: 'btn-split',
                    header: promptMessages.header,
                    hideNo: true,
                    question: promptMessages.question,
                    yes: promptMessages.yes,
                };
                return Container.instance
                    .get(DialogService)
                    .open({
                        model: dirtyModel,
                        viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
                    })
                    .whenClosed(response => {
                        if (!response.wasCancelled) {
                            if (typeof this.onDirtyPromptLeave === 'function') {
                                this.onDirtyPromptLeave();
                            }
                        } else if (typeof this.onDirtyPromptStay === 'function') {
                            this.onDirtyPromptStay();
                        }
                        return !response.wasCancelled;
                    });
            }
            if (typeof this.onDirtyPromptLeave === 'function') {
                this.onDirtyPromptLeave();
            }
            return Promise.resolve(true);
        }
    };
}
