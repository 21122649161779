import {autoinject, LogManager} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';

import {Acceo} from 'services/acceo';

import {Owner, OwnerLimited, OwnerLimitedResponse, OwnerResponse} from '../models/owner';
// import {
//   SsoDomainUser,
//   // SsoDomainUserResponse,
// } from '../models/sso-domain-user';

export const BASE_URL = '/api/v4/ops/owners';
export const BASE_SSO_URL = '/api/v4/ops/sso/owners';

@autoinject()
export class OwnerService {
  public isLoading: boolean = false;
  public records: Owner[];

  private logger: Logger;

  constructor(protected acceo: Acceo) {
    this.logger = LogManager.getLogger('Owner Service');
    this.records = [];
  }

  public clear() {
    this.records = [];
  }

  /**
   * List users that do not have the ids_domain set on the record
   * @param username
   */
  public async listNonSsoBoundOwners(username: string): Promise<OwnerLimited[]> {
    if (this.isLoading || username === '') {
      return [];
    }

    this.isLoading = true;
    let records = [];

    try {
      const url = `${BASE_SSO_URL}?username=${username}`;
      const {items = []} = await this.acceo.get(OwnerLimitedResponse)(url);

      records = items;
    } catch (err) {
      this.logger.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }

    return records;
  }

  public async list(username: string): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    try {
      const url = `${BASE_URL}?username=${username}`;
      const {owners = []} = await this.acceo.get(OwnerResponse)(url);

      this.records = owners;
    } catch (err) {
      this.logger.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }
  }
}
