import {Config, Model} from './models';

export class ProfileUsageModal {
    public config: Config;
    public model: Model;

    public activate(model) {
        this.model = model;
        this.config = this.model.shared;
    }
}
