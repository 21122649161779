import * as moment from 'moment';

import {groupBy} from 'resources/group-by';
import {WEEK_DAYS, WeekDay} from './block-factories';

export class MonthService {
    initFilter() {
        return [moment().startOf('month').valueOf(), moment().endOf('month').valueOf()];
    }

    parseDate(currentMoment) {
        return [currentMoment.format('MMM. YYYY'), currentMoment.format('M/YYYY')];
    }

    nextButton(currentMoment) {
        const currentClone = currentMoment.clone();
        currentClone.add(1, 'month');

        return [
            currentClone,
            currentClone.clone().startOf('month').valueOf(),
            currentClone.clone().endOf('month').valueOf(),
        ];
    }

    prevButton(currentMoment) {
        const currentClone = currentMoment.clone();
        currentClone.subtract(1, 'month');

        return [
            currentClone,
            currentClone.clone().startOf('month').valueOf(),
            currentClone.clone().endOf('month').valueOf(),
        ];
    }

    assembleCalendar(events, monthStart) {
        const momentMonthStart = moment(monthStart);
        const momentMonthEnd = momentMonthStart.clone().endOf('month');
        const startOfFirstWeek = momentMonthStart.clone().startOf('week');
        const endOfLastWeek = momentMonthEnd.clone().endOf('week');
        const numOfDays = Math.ceil(endOfLastWeek.diff(startOfFirstWeek, 'days', true));

        let totalEvents = 0;

        const monthSetup = new Array(numOfDays).fill().map((a, index) => {
            const weekIndex = Math.floor(index / WEEK_DAYS);
            const dayWeekIndex = index % WEEK_DAYS;

            const weekStart = startOfFirstWeek.clone().add(weekIndex, 'week').startOf('week');
            return new WeekDay(weekStart, dayWeekIndex);
        });

        const groupedEvents = groupBy(events, event => moment(event.expected_start).format('MM/DD/YYYY'));

        monthSetup.forEach(day => {
            const isInMonth = moment(day.start).isBetween(momentMonthStart, momentMonthEnd, null, '[)');
            if (!isInMonth) {
                day.styles = 'month-grid__not-current-month';
                return;
            }

            if (groupedEvents[day.altFormattedDay]) {
                day.events = groupedEvents[day.altFormattedDay];
                totalEvents += day.events.length;
            }

            day.setEventClasses();
        });

        return [monthSetup, totalEvents];
    }
}
