import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, containerless, LogManager} from 'aurelia-framework';
import {dirtyCheckPrompt} from 'decorators';
import {Notification} from 'resources/notification/service';
import {MAX_FILE_SIZE} from 'services/constants';
import {FPSService} from './services/fps-service';

const log = LogManager.getLogger('drm-settings-page');

const APPLICATION_SECRET_KEY_LENGTH = 32;

@containerless
@dirtyCheckPrompt
@autoinject()
export class FPSPage {
    public formError;
    public inputKey: boolean;
    public cert;
    public certUploadRef;
    public hasKey: boolean;
    public isLoading: boolean;
    public isFormDirty: boolean;
    public isFormSubmitted: boolean;
    public isSaving: boolean;
    public passphrase: string;
    public privateKey: string;
    public secretKey: string;
    public certificateURL: string;
    public certificateExpDate: string;

    constructor(
        public eventAggregator: EventAggregator,
        public notification: Notification,
        public fpsService: FPSService,
    ) {
        this.formInit();
    }

    public formInit() {
        this.isFormDirty = false;
        this.isFormSubmitted = false;
        this.passphrase = '';
        this.privateKey = '';
        this.secretKey = '';
        this.certificateURL = '';
        this.certificateExpDate = '';
        this.cert = [];
        try {
            this.certUploadRef.value = null;
        } catch (e) {
            /**/
        }
        this.formErrorInit();
    }

    public formErrorInit() {
        this.formError = {
            cert: {
                msg: 'Application Public Key Certificate is required.',
                show: false,
            },
            passphrase: {
                msg: 'Private Key Passphrase is required.',
                show: false,
            },
            privateKey: {
                msg: 'Private Key is required.',
                show: false,
            },
            secretKey: {
                msg: '',
                show: false,
            },
        };
    }

    public attached() {
        this.isLoading = true;
        const promises = [this.fpsService.hasCert().reflect(), this.fpsService.hasKey().reflect()];
        return new Promise((resolve, reject) => {
            Promise.all(promises)
                .then(resp => {
                    let hasCert = false;
                    let hasKey = false;
                    if (resp[0].isFulfilled()) {
                        hasCert = resp[0].value();
                    } else {
                        this.notification.error('Error loading Fairplay cert.');
                        log.error(resp[0].reason());
                    }

                    if (resp[1].isFulfilled()) {
                        hasKey = resp[1].value();
                    } else {
                        this.notification.error('Error loading Fairplay key.');
                        log.error(resp[1].reason());
                    }
                    // show form if either cert missing or keys missing.
                    this.hasKey = hasKey && hasCert;

                    // only call the endpoint when a key has been uploaded
                    if (this.hasKey) {
                        this.fpsService
                            .getFPSCertInfo()
                            .then(info => {
                                this.certificateURL = info.fairplay_cert_url;
                                this.certificateExpDate = info.fairplay_cert_exp_date;
                            })
                            .catch(e => {
                                log.error(e);
                            });
                    }
                    resolve();
                })
                .catch(e => {
                    this.notification.error('Error loading Fairplay cert, key.');
                    log.error(e);
                    reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }).catch(() => {
            /**/
        });
    }

    public validate() {
        this.formErrorInit();
        if (!this.cert || !this.cert.length) {
            this.formError.cert.show = this.isFormSubmitted;
        }
        if (!this.passphrase) {
            this.formError.passphrase.show = this.isFormSubmitted;
        }
        if (!this.privateKey) {
            this.formError.privateKey.show = this.isFormSubmitted;
        }
        if (!this.secretKey) {
            this.formError.secretKey = {
                msg: 'Secret Key is required.',
                show: this.isFormSubmitted,
            };
        } else if (this.secretKey.length !== APPLICATION_SECRET_KEY_LENGTH) {
            this.formError.secretKey = {
                msg: `Secret Key must be ${APPLICATION_SECRET_KEY_LENGTH} characters.`,
                show: this.isFormSubmitted,
            };
        }
        if ((this.cert && this.cert.length) || this.passphrase || this.privateKey || this.secretKey) {
            this.isFormDirty = true;
        } else {
            this.isFormDirty = false;
        }
    }

    public save() {
        this.isFormSubmitted = true;
        this.validate();
        const errs = Object.values(this.formError)
            .filter(val => (val as any).show)
            .map(val => (val as any).msg);
        return new Promise((resolve, reject) => {
            if (errs.length) {
                log.error(JSON.stringify(errs));
                resolve();
            } else {
                this.isSaving = true;
                this.fpsService
                    .uploadCertAndKeys(this.cert[0], this.privateKey, this.passphrase, this.secretKey)
                    .then(() => {
                        this.hasKey = true;
                        this.isSaving = false;
                        this.notification.success('Certificate and keys uploaded successfully.');
                        this.clearInput();
                        resolve();
                    })
                    .catch(e => {
                        this.isSaving = false;
                        this.notification.error('Error uploading certificate and keys.');
                        log.error(e);
                        reject(e);
                    });
            }
        });
    }

    public clearInput() {
        this.inputKey = false;
        this.formInit();
    }

    public changeKey() {
        this.inputKey = true;
    }

    public onFocus(field) {
        this.formError[field].show = false;
    }

    public onFileSelect() {
        if (!this.cert || !this.cert.length) return;
        if (this.cert[0].size > MAX_FILE_SIZE) {
            this.formError.cert = {
                msg: 'File size is too large. Are you sure you selected the right certificate file?',
                show: true,
            };
        } else {
            this.validate();
        }
    }

    public isDirty(): boolean {
        return this.isFormDirty;
    }
}
