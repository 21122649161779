import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {
    TemplateDeleteResponse,
    TemplatesRequest,
    TemplatesResponseItem,
    TemplatesResponseItems,
} from '../models/template';

@autoinject
export class TemplatesService {
    private url: string = '/api/v4/slicer/config/templates';

    constructor(public acceo: Acceo) {}

    public delete(id: string) {
        return this.acceo.delete(TemplateDeleteResponse)(`${this.url}/${id}?force_delete=true`);
    }

    public get() {
        return this.acceo.get(TemplatesResponseItems)(this.url);
    }

    public getById(id: string) {
        return this.acceo.get(TemplatesResponseItem)(`${this.url}/${id}`);
    }

    public patch(id: string, body: TemplatesRequest) {
        return this.acceo.patch(TemplatesResponseItem)(`${this.url}/${id}`, body);
    }

    public post(body: TemplatesRequest) {
        return this.acceo.post(TemplatesResponseItem)(this.url, body);
    }
}
