import {Transform} from 'class-transformer';
import {IsString} from 'class-validator';
import {MESSAGE_TYPE_MAP} from '../../ad-server/services/ad-config-service';

export enum AdServerDebugType {
    Error = 'critical',
    Info = 'info',
    Ok = 'success',
}

export class AdServerDebugItem {
    @IsString()
    public msg: string;

    @IsString()
    @Transform(value => MESSAGE_TYPE_MAP[value])
    public type: AdServerDebugType;
}
