import {bindable, inject, TaskQueue} from 'aurelia-framework';

@inject(TaskQueue)
export class Tip {
    @bindable
    modifier = '';

    @bindable
    clickOut = true;

    constructor(taskQueue) {
        this.additionalClasses = '';
        this.contentVisible = false;
        this.taskQueue = taskQueue;
    }

    bind() {
        if (this.modifier) {
            this.additionalClasses += this.modifier
                .split(' ')
                .map(mod => `tip-container--${mod}`)
                .join('  ');
        }
    }

    toggleVisible() {
        this.contentVisible = !this.contentVisible;
    }

    showContent(context, afterShow) {
        this.contentVisible = true;

        if (afterShow) {
            // our CSS transition effects prevent focus (http://stackoverflow.com/a/28597736)
            // so let's wait a half a second before calling an afterShow() event
            setTimeout(() => {
                context[afterShow]();
            }, 350);
        }
    }

    hideContent(context, afterHide) {
        this.contentVisible = false;
        if (afterHide) {
            setTimeout(() => {
                context[afterHide]();
            }, 350);
        }
    }
}
