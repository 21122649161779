import {autoinject, computedFrom} from 'aurelia-framework';
import {isEqual} from 'lodash';
import {authorizationConstants} from 'services/authorization';
import {SyndicationPlatformType, SyndicationTargetProtocolType} from 'services/models/syndication';

@autoinject()
@authorizationConstants
export class PublishTargetFooter {
  public controller: any;
  public job: any;

  public activate(model) {
    this.job = model.shared;
    this.controller = model.controller;
  }

  public async startSyndication() {
    const success = await this.job.startSchedule(this.job.id);
    if (success) {
      this.controller.cancel();
    }
  }

  public async stopSyndication() {
    const success = await this.job.stopSchedule(this.job.id);
    if (success) {
      this.controller.cancel();
    }
  }

  public async onSave() {
    const success = await this.job.saveSchedule(this.job);
    if (success) {
      this.job.resetTargetModel();
    }
  }

  public async testTarget() {
    await this.job.testTarget(this.job.syndication_target_id);
  }

  get isHls() {
    return (
      this.job.target_protocol === SyndicationTargetProtocolType.HLS_PUSH ||
      this.job.target_protocol === SyndicationTargetProtocolType.HLS_PULL
    );
  }

  get isIntegrated() {
    return (
      this.job.target_info.platform_config.integrated &&
      (this.job.target_info.platform === 'YouTube' || this.job.target_info.platform === 'Facebook')
    );
  }

  get isSrt() {
    return this.job.target_protocol === SyndicationTargetProtocolType.SRT;
  }

  public dictsEqual(dict1, dict2) {
    if (JSON.stringify(dict1) === JSON.stringify(dict2)) {
      return true;
    }
    return false;
  }

  @computedFrom(
    'job.video_quality',
    'job.video_quality_pristine',
    'job.scte35_enabled',
    'job.scte35_enabled_pristine',
    'job.region',
    'job.region_pristine',
    'job.target_platform_config.channel_id',
    'job.target_platform_config.channel_id_pristine',
    'job.target_platform_config.rays',
    'job.target_platform_config.rays_pristine',
    'job.target_platform_config.integrated_title',
    'job.target_platform_config.integrated_title_pristine',
    'job.target_platform_config.integrated_description',
    'job.target_platform_config.integrated_description_pristine',
    'job.target_platform_config.integrated_yt_privacy',
    'job.target_platform_config.integrated_yt_privacy_pristine',
    'job.target_platform_config.integrated_yt_made_for_kids',
    'job.target_platform_config.integrated_yt_made_for_kids_pristine',
    'job.target_platform_config.scte35_type',
    'job.target_platform_config.scte35_type_pristine',
    'job.target_platform_config.srt_latency',
    'job.target_platform_config.srt_latency_pristine',
    'job.target_platform_config.fast_channel_id',
    'job.target_platform_config.fast_channel_id_pristine',
    'job.source_url_params',
    'job.source_url_params_pristine',
    'job.url_params_active',
    'job.url_params_active_pristine',
  )
  get isDirty() {
    const {job} = this;
    // only for HLS, verify user selected at least one ray
    if (this.isHls && job.target_platform_config.rays.length === 0) {
      return false;
    }
    if (this.isIntegrated && job.target_platform_config.integrated_title === '') {
      return false;
    }
    return (
      job.video_quality !== job.video_quality_pristine ||
      job.scte35_enabled !== job.scte35_enabled_pristine ||
      job.region !== job.region_pristine ||
      job.url_params_active !== job.url_params_active_pristine ||
      job.target_platform_config.channel_id !== job.target_platform_config.channel_id_pristine ||
      (job.target_platform_config.rays.length > 0 &&
        !isEqual(job.target_platform_config.rays, job.target_platform_config.rays_pristine)) ||
      job.target_platform_config.integrated_title !== job.target_platform_config.integrated_title_pristine ||
      // tslint:disable-next-line:max-line-length
      job.target_platform_config.integrated_description !==
        job.target_platform_config.integrated_description_pristine ||
      // tslint:disable-next-line:max-line-length
      job.target_platform_config.integrated_yt_privacy !== job.target_platform_config.integrated_yt_privacy_pristine ||
      // tslint:disable-next-line:max-line-length
      job.target_platform_config.integrated_yt_made_for_kids !==
        job.target_platform_config.integrated_yt_made_for_kids_pristine ||
      // tslint:disable-next-line:max-line-length
      job.target_platform_config.srt_latency !== job.target_platform_config.srt_latency_pristine ||
      !this.dictsEqual(job.source_url_params, job.source_url_params_pristine) ||
      job.target_platform_config.scte35_type !== job.target_platform_config.scte35_type_pristine ||
      job.target_platform_config.fast_channel_id !== job.target_platform_config.fast_channel_id_pristine
    );
  }

  @computedFrom(
    'job.is_new',
    'job.video_quality',
    'job.video_quality_pristine',
    'job.scte35_enabled',
    'job.scte35_enabled_pristine',
    'job.region',
    'job.region_pristine',
    'job.target_platform_config.channel_id',
    'job.target_platform_config.channel_id_pristine',
    'job.target_platform_config.rays',
    'job.target_platform_config.rays_pristine',
    'job.target_platform_config.integrated_title',
    'job.target_platform_config.integrated_title_pristine',
    'job.target_platform_config.integrated_description',
    'job.target_platform_config.integrated_description_pristine',
    'job.target_platform_config.integrated_yt_privacy',
    'job.target_platform_config.integrated_yt_privacy_pristine',
    'job.target_platform_config.integrated_yt_made_for_kids',
    'job.target_platform_config.integrated_yt_made_for_kids_pristine',
    'job.target_platform_config.scte35_type',
    'job.target_platform_config.scte35_type_pristine',
    'job.target_platform_config.srt_latency',
    'job.target_platform_config.srt_latency_pristine',
    'job.target_platform_config.fast_channel_id',
    'job.target_platform_config.fast_channel_id_pristine',
    'job.source_url_params',
    'job.source_url_params_pristine',
    'job.url_params_active',
    'job.url_params_active_pristine',
  )
  get saveButtonState() {
    const {job, isDirty, isHls, isIntegrated, isSrt} = this;
    // for HLS we want to validate and require at least one ray selected,
    // so it must be "dirty" and not pass the if "is_new" part
    if (isHls && !isDirty) {
      return 'disabled';
    }
    // for YT/FB integrated we want the title to be not empty
    if (isIntegrated && !isDirty) {
      return 'disabled';
    }
    // for SRT validate srt latency is valid number and allowed range
    if (isSrt) {
      const srtLatency: any = job.target_platform_config.srt_latency;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(srtLatency) || srtLatency < 120 || srtLatency > 15000) {
        return 'disabled';
      }
    }
    if (isDirty || job.is_new) {
      return '';
    }
    return 'disabled';
  }

  @computedFrom('job.is_new', 'job.isDirty', 'job.status')
  get disableStart() {
    const {job, isDirty} = this;
    return job.is_new || isDirty || this.job.status !== 'stopped' || this.wurlWithoutAccess;
  }

  @computedFrom('job.status')
  get disableStop() {
    return this.job.status !== 'active' || this.wurlWithoutAccess;
  }

  @computedFrom('job.target_info.platform', 'job.session')
  get wurlWithoutAccess() {
    return this.job.target_info.platform === SyndicationPlatformType.WURL && !this.job.session.hasFastAccess;
  }
}
