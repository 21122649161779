/* eslint-disable max-classes-per-file */
import {Expose, Transform, Type} from 'class-transformer';
import {
  Allow,
  IsBoolean,
  IsDefined,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsString,
  MaxLength,
  registerDecorator,
  ValidateNested,
  ValidationArguments,
  ValidationOptions,
  Validator,
} from 'class-validator';
import {Util} from 'services/util';
import {AdConfigUtil} from '../services/ad-config-util';

const validator = new Validator();

export enum AdServerLabel {
  AdTech = 'AdTech - Deprecated',
  AOLOneVideo = 'AOL One Video - Deprecated',
  DoubleClick = 'Google Ad Manager',
  Freewheel = 'FreeWheel',
  Invidi = 'INVIDI',
  SpotXchange = 'SpotX',
  UplynkDummy = 'Verizon Media Promo Carousel',
  Volcano = 'Verizon Media SSP',
  UniversalAdConfig = 'Universal',
  UplynkVOD = 'upLynk Ad Server - VOD',
  UplynkLIVE = 'upLynk Ad Server - LIVE',
}

export enum AdServerType {
  AdTech = 'adtech',
  AOLOneVideo = 'aol',
  DoubleClick = 'gg',
  Freewheel = 'fw',
  Invidi = 'invidi',
  SpotXchange = 'spotx',
  UplynkDummy = 'dummy',
  Volcano = 'vrm',
  UniversalAdConfig = 'uac',
  UplynkVOD = 'uplynkvod',
  UplynkLIVE = 'uplynklive',
}

export class AdConfig {
  @IsString()
  @Transform(value => value, {toClassOnly: true})
  public id: string = Util.generateGuid();

  // if {skipMissingProperties: true} in validate function, @isDefined() fields are still checked.
  @IsDefined({message: 'You must enter a value.'})
  @IsNotEmpty({message: 'You must enter a value.'})
  @IsString()
  @MaxLength(24, {message: 'Name must be $constraint1 characters or less.'})
  public name: string;

  // _orig_name is used as a primary key in server side code.
  @IsString()
  @Expose({name: '_orig_name'})
  public origName: string;

  @IsNumber()
  public testable: number;

  @IsNumber()
  @Expose({name: 'testType'})
  public test_type: number;

  @IsEnum(AdServerType)
  public type: AdServerType;

  @IsString()
  public type_name: string;

  @IsBoolean()
  @Transform(value => !!value, {toClassOnly: true})
  public valid: boolean;

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => RequiredField)
  public required: RequiredField[];

  @IsNotEmpty()
  @ValidateNested()
  @Type(() => OptionalField)
  public optional: OptionalField[];

  @Allow()
  @Transform(value => JSON.stringify(value), {toClassOnly: true})
  public fields: string;

  // runtime field, need not be serialized
  public duplicateOf: string;

  // runtime field, need not be serialized
  public skipValidate: boolean;

  // runtime field, need not be serialized
  public doValidate?: boolean;
}

export class RequiredField {
  @IsDefined({message: 'Name is required.'})
  @IsNotEmpty({message: 'Name is required.'})
  @IsString()
  public name: string;

  @IsNotEmpty({message: 'You must enter a value.'})
  @IsValidForFieldName('name')
  public value: string = '';

  @IsString()
  public help: string;

  @IsString()
  public error: string;
}

export class OptionalField {
  @IsDefined({message: 'Name is required.'})
  @IsNotEmpty({message: 'Name is required.'})
  @IsString()
  public name: string;

  @IsValidForFieldName('name')
  public value: string = '';

  @IsString()
  public help: string;

  @IsString()
  public error: string;
}

export function defaultMessageForDecorator(args: ValidationArguments) {
  let msg = `Value must be ${AdConfigUtil.VALUE_MAX_LENGTH} characters or less.`;
  const [relatedPropertyName] = args.constraints;
  const relatedValue = (args.object as any)[relatedPropertyName].toLowerCase();
  if (relatedValue.endsWith('url')) {
    msg = `Value must be ${AdConfigUtil.URL_MAX_LENGTH} characters or less.`;
    if (!validator.isURL(args.value)) {
      msg = 'You must enter a valid URL';
    }
  } else if (relatedValue.endsWith('networkid')) {
    msg = `Value must be ${AdConfigUtil.NETWORK_ID_MAX_LENGTH} characters or less.`;
  }
  return msg;
}

export function validatorForDecorator(value: any, args: ValidationArguments) {
  if (!value) return true;
  const [relatedPropertyName] = args.constraints;
  const relatedValue = (args.object as any)[relatedPropertyName].toLowerCase();
  if (relatedValue.endsWith('url')) {
    return validator.maxLength(value, AdConfigUtil.URL_MAX_LENGTH) && validator.isURL(value);
  }
  if (relatedValue.endsWith('networkid')) {
    return validator.maxLength(value, AdConfigUtil.NETWORK_ID_MAX_LENGTH);
  }
  return validator.maxLength(value, AdConfigUtil.VALUE_MAX_LENGTH);
}

/* tslint:disable-next-line:function-name */
export function IsValidForFieldName(property: string, validationOptions: ValidationOptions = {}) {
  return (object: object, propertyName: string) => {
    registerDecorator({
      propertyName,
      constraints: [property],
      name: 'IsValidForFieldName',
      options: validationOptions,
      target: object.constructor,
      validator: {
        defaultMessage: defaultMessageForDecorator,
        validate: validatorForDecorator,
      },
    });
  };
}
