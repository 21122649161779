import {bindable, containerless} from 'aurelia-framework';
import * as datetimepicker from 'eonasdan-bootstrap-datetimepicker';
import * as moment from 'moment';
import * as $ from 'jquery'; // A unit trst requires this even though it's already global

$.fn.extend({
    datetimepicker,
});

/**
 * @param id {String} - Element ID.
 * @param timestamp {Number} - Unix timestamp.
 * @param callbacks {Object} - Dictionary of callbacks. Currently supported cbs:
 *        onChange(timestamp) - Hook that executes on value change with new timestamp.
 */
@containerless
export class DateTimePicker {
    @bindable
    id;

    @bindable
    timestamp; // Can add other date formats too if needed in the future

    @bindable
    callbacks;

    @bindable
    hideInput;

    @bindable
    inline;

    @bindable
    disabled;

    @bindable
    timeofday;

    attached() {
        if (this.hideInput == null) {
            this.hideInput = false;
        }
        if (this.inline == null) {
            this.inline = false;
        }

        this.datetimepicker = $(`#${this.id}`).datetimepicker({
            sideBySide: true,
            showClose: true,
            useCurrent: false,
            minDate: moment('2016-01-01'),
            showTodayButton: true,
            inline: this.inline,
        });

        this.setTimestamp(this.timestamp);

        // This resets the time picker when it is opened and a time isn't already set.
        // There is a bug in the picker, otherwise .viewDate(this.calcViewDate()) should work instead.
        this.datetimepicker.on('dp.show', () => {
            if (!this.timestamp) {
                this.datetimepicker.data('DateTimePicker').date(this.calcViewDate());
                this.datetimepicker.data('DateTimePicker').date(null);
            }
        });

        this.datetimepicker.on('dp.change', (/* event */) => {
            this.updateDate();
        });
    }

    calcViewDate() {
        if (this.timeofday === 'start') {
            return moment().startOf('day');
        }
        if (this.timeofday === 'end') {
            return moment().endOf('day');
        }

        return moment();
    }

    updateDate() {
        // UP-10723 : Timestamps should be at the beginning of a minute to avoid issues with relative time
        let newTimestamp = moment(this.datetimepicker.data('DateTimePicker').date()).startOf('minute').format('x');
        if (newTimestamp === 'Invalid date') {
            newTimestamp = '';
        }

        this.timestamp = newTimestamp;

        if (this.callbacks && this.callbacks.onChange) {
            this.callbacks.onChange(newTimestamp);
        }
    }

    timestampChanged(newValue) {
        this.setTimestamp(newValue);
    }

    setTimestamp(newValue) {
        if (this.datetimepicker) {
            if (newValue) {
                this.datetimepicker.data('DateTimePicker').date(moment(newValue, 'x'));
            } else {
                this.datetimepicker.data('DateTimePicker').clear();
            }
        }
    }

    detached() {
        this.datetimepicker.off('dp.show');
        this.datetimepicker.off('dp.change');
        this.datetimepicker.data('DateTimePicker').destroy();
        this.datetimepicker = null;
    }
}
