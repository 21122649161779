import * as Draggabilly from 'draggabilly';
import {ITimeEntry} from '../b-timeline/b-timeline-interfaces';

export interface IDraggiePosition {
  x: number;
  y: number;
}

export interface IDraggieSize {
  width: number;
  height: number;
}

export interface IDraggieOptions {
  axis?: string;
  containment?: HTMLElement | string | boolean;
  grid?: number[];
  handle?: HTMLElement | HTMLElement[] | string;
}

export interface IDraggie {
  containSize: IDraggieSize;
  dragPoint?: IDraggiePosition;
  element: HTMLElement;
  handles?: HTMLElement[];
  isDragging: boolean;
  isEnabled: boolean;
  isPointerDown?: boolean;
  options: IDraggieOptions;
  position: IDraggiePosition;
  relativePosition?: IDraggiePosition;
  startPoint?: IDraggiePosition;
  startPosition?: IDraggiePosition;
}

export interface IDraggabillyActions {
  onDragStart: (entry: ITimeEntry, draggie: IDraggie) => void;
  onDragMove: (event: any, entry: ITimeEntry, draggie: IDraggie) => void;
  onDragEnd: (entry: ITimeEntry, draggie: IDraggie) => Promise<boolean>;
}

export class DraggabillyService {
  public preventCreateNewEntry: boolean = false;
  public isDragging: boolean = false;
  private tracked = [];

  public track(entry: ITimeEntry, el: Element, actions: IDraggabillyActions, h: number) {
    const parent = el.closest('.day-container');
    const w = parent.clientWidth;

    const element = el.children[0];

    const draggie = new Draggabilly(element, {
      containment: '.week-container',
      grid: [
        w,
        h,
      ],
    });

    draggie.on('dragStart', () => {
      this.isDragging = true;
      this.preventCreateNewEntry = true;
      actions.onDragStart(entry, draggie);
    });

    draggie.on('dragMove', (event: any) => {
      actions.onDragMove(event, entry, draggie);
    });

    draggie.on('dragEnd', async () => {
      this.isDragging = false;
      const updated = await actions.onDragEnd(entry, draggie);

      if (updated) {
        setTimeout(() => {
          const {_el} = draggie;
          const draggies = _.remove(this.tracked, {_el});

          while (draggies.length) {
            draggies.pop().destroy();
          }

          draggie.element.remove();
        }, 10);
      }
    });

    draggie.on('pointerUp', () => {
      // Prevents a popup after you release the mouse button
      setTimeout(() => {
        this.preventCreateNewEntry = false;
      }, 500);
    });

    this.tracked.push(draggie);
  }

  public untrack(el) {
    const element = el.children[0];
    const draggies = _.remove(this.tracked, {element});
    while (draggies.length) {
      draggies.pop().destroy();
    }
  }

  public removeAll() {
    this.tracked.forEach(d => d.destroy());
    this.tracked = [];
  }

  private onResize() {
    this.tracked.forEach(d => {
      const el = d.element;
      const parent = el.closest('.day-container');
      const width = parent.clientWidth;
      const [
        ,
        h,
      ] = d.options.grid;
      d.options.grid = [
        width,
        h,
      ];
    });
  }

  public debounceOnResize = _.debounce(() => {
    this.onResize();
  }, 150);

  public handleKeyDown = e => {
    if (e.key === 'Escape') {
      const dragging = _.filter(this.tracked, draggie => draggie.isDragging);

      if (dragging.length) {
        dragging[0].setPosition(dragging[0].startPosition.x, dragging[0].startPosition.y);
        dragging[0].pointerUp();
      }
    }
  };

  public addPageListener() {
    window.addEventListener('resize', this.debounceOnResize);
    document.addEventListener('keydown', this.handleKeyDown);
    const sidebar = document.querySelector('lynk-page-sidebar');
    if (sidebar) {
      sidebar.addEventListener('on:hide', this.debounceOnResize);
    }
  }

  public removePageListener() {
    window.removeEventListener('resize', this.debounceOnResize);
    document.removeEventListener('keydown', this.handleKeyDown);
    const sidebar = document.querySelector('lynk-page-sidebar');
    if (sidebar) {
      sidebar.removeEventListener('on:hide', this.debounceOnResize);
    }
  }
}
