import {MSSConfigEditor} from 'apps/cms/routes/slicers/templates/single/editor';
import {autoinject} from 'aurelia-framework';
import {LynkDialogServiceModel} from 'resources/services/dialog';

@autoinject
export class MSSConfigEditorModal extends MSSConfigEditor {
  public activate(model: LynkDialogServiceModel) {
    this.config = model.settings.sharedModel.input.slicerConfiguration;
    this.config.state.canImportTemplates = true;

    if (this.sessionService.hasHostedSlicersAccess) {
      this.config.state.canViewAdvanced = true;
    }
  }
}
