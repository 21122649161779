import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

@autoinject()
export class SyndicationJobsIndex {
  public router: Router;
  public routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: 'SyndicationJobsList',
      nav: false,
      route: '',
      title: 'Syndication Jobs',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: 'SyndicationJobsSingle',
      nav: false,
      route: ':id',
      title: 'Syndication Job',
    },
  ];

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }
}
