import {ISlicerVersionTableRow} from './table-models';

export class Config {
    public input = new ConfigInput();
    public state = new ConfigState();
}

class ConfigInput {
    public regionId: string;
    public regionLabel: string;
    public search: string;
    public selection = {} as ISlicerVersionTableRow;
    public selectionReset: () => void;
}

class ConfigState {
    public error: string;
    public isAssigned: boolean;
    public isLoading: boolean;
}
