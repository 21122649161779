import {autoinject} from 'aurelia-framework';
import {LynkDialog, LynkTabGroup} from '@uplynk/lynk-design';
import {Acceo} from 'services/acceo';
import {AcceoErrorUtil} from '../acceo-error-util';
import {Config} from './models/config';
import {AdConfigService} from '../../services/ad-config';

@autoinject
export class TestSettings {
  public adConfigService: AdConfigService;
  public config: Config;
  public dialog: LynkDialog;
  public tabGroup: LynkTabGroup;

  constructor(acceo: Acceo) {
    this.adConfigService = new AdConfigService(acceo);
  }

  public async actionTest() {
    this.config.state.errorTesting = undefined;
    this.config.state.isTesting = true;
    this.config.input.results = [];
    this.tabGroup.show('results');
    try {
      const response = await this.adConfigService.test(
        this.config.input.adConfig,
        this.config.input.parameters,
        this.config.input.adConfig.testType > 0 ? this.config.input.position : '',
      );
      if (response.error) {
        this.config.state.errorTesting = 'An unknown server error occurred.';
      } else {
        this.config.input.results = response.items;
      }
    } catch (error) {
      this.config.state.errorTesting = AcceoErrorUtil.getHTML(error);
    }
    this.tabGroup.show('results');
    this.config.state.isTesting = false;
  }

  public getResultAlertType(value: string) {
    switch (value) {
      case 'error':
        return 'danger';
      case 'ok':
        return 'success';
      case 'info':
        return value;
      default:
        return 'neutral';
    }
  }

  public onRequestCloseDialog(event: CustomEvent) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
      return false;
    }
    return true;
  }

  public async load(name: string) {
    this.config = new Config();
    this.tabGroup.show('options');
    this.dialog.show();
    try {
      const response = await this.adConfigService.getByName(name);
      if (!response) throw new Error(`Failed to load ${name}`);
      this.config.input.adConfig = response;

      // construct test URL from defined params
      this.config.input.url = this.config.input.adConfig.serverURL;
      [
        'backfill',
        'serverURL',
        'testable',
        'testType',
        'type',
        'valid',
        '_orig_name',
        // maybe also remove _orig_name and name?
      ].forEach(key => {
        delete this.config.input.adConfig.values[key];
      });
      Object.keys(this.config.input.adConfig.values).forEach((key, index) => {
        if (this.config.input.adConfig.values[key]) {
          const param = `${index === 0 && this.config.input.url.indexOf('?') === -1 ? '?' : '&'}${key}=${
            this.config.input.adConfig.values[key]
          }`;
          this.config.input.url = this.config.input.url.concat(param);
        }
      });
    } catch (error) {
      this.config.state.error = AcceoErrorUtil.getHTML(error);
    }
    this.config.state.isLoading = false;
  }
}
