import * as moment from 'moment-timezone';

import {DialogService} from 'aurelia-dialog';
import {LogManager, computedFrom, autoinject, bindable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {PLATFORM} from 'aurelia-pal';

import {SharedNav} from '@bindable-ui/bindable';
import {LynkDialog, LynkInput} from '@uplynk/lynk-design';

import {OwnerMetaContext, SessionService} from 'services/session';
import {deepCopy} from 'resources/deep-copy';

import {TableSortingTools, TableColumn} from 'utils/table-sorting-tools';
import {LiveEventsService} from '../services/live-events';
import {DuplicateEventsModal} from '../single/duplicate-events-modal';
import {EventsFilter} from '../single/events-filter';

export interface ISearchTip {
  field: string;
  preText: string;
  postText: string;
  searchText: string;
  operator: string;
}

export interface IParams {
  calendar?: boolean;
  createdRange?: any;
  created_r_start?: number;
  created_r_stop?: number;
  deleted?: any;
  endRange?: string;
  end_r_start?: number;
  end_r_stop?: number;
  limit?: number;
  name?: string;
  operator?: string;
  order?: string;
  r_start?: number;
  r_stop?: number;
  search?: string;
  since?: any;
  skip?: number;
  slicer?: any;
  startRange?: string;
  state?: string;
}

const EVENTS_CHECK_INTERVAL_MS = 5000; // How often we query to check session.
const DEFAULT_PER_PAGE_LIMIT = 30;
const DEFAULT_SKIP = 0;
const MAX_CSV_EXPORT = 5000;

const logger = LogManager.getLogger('Live Events | List | Index');

@autoinject()
export class LiveEventsListIndex {
  @bindable
  public searchText: string;

  public MAX_CSV_EXPORT = MAX_CSV_EXPORT;

  public createEventDialog: LynkDialog;
  public eventsFilter: EventsFilter;
  public searchInput: LynkInput;
  public duplicateEventsModal: DuplicateEventsModal;

  public colsLoading: boolean = false;
  public tableColumns: TableColumn[];

  public eventsToDuplicate: any[];
  public errorCreate: boolean = false;
  public hiddenColumns: TableColumn[];
  public csvIncludeDeleted: boolean;
  public csvIncludeTimezone: boolean;
  public id: string;
  public isCopying: boolean = false;
  public isDeleting: boolean = false;
  public isSaving: boolean = false;
  public model;
  public onDuplicate = () => this.duplicateEvents();
  public params: IParams = {};
  public searchPopoverDisabled: boolean = true;
  public searchPopoverOpen: boolean = true;
  public searchTips: ISearchTip[] = [];
  public selectedRecords: string[] = [];
  public export_popover: any;

  /*
    Private Properties
   */

  // This list must be in order of most-difficult-to-match first (human-readable side)
  private liveEventQueryFilters = {
    external_id: 'externalId',
    search_meta_pairs: 'metaKey|Value',
    search_meta_keys: 'metaKey',
    search_meta_vals: 'metaValue',
    desc: 'title',
  };

  private updateTimeout = null;

  /*
    Computed Properties
   */

  /**
   * Determines if there are more events to be loaded.
   *
   * @return {Boolean} - Whether all events are showing or not.
   */
  @computedFrom('liveEvents.meta.total', 'liveEvents.meta.showing')
  get canLoadMore() {
    return this.liveEvents.meta.showing < this.liveEvents.meta.total;
  }

  /**
   * Pagination Metadata for Live Events.
   *
   * @return {Object} - Pagination meta data.
   */
  @computedFrom('liveEvents.meta')
  get pagination() {
    return {
      meta: this.liveEvents.meta,
    };
  }

  @computedFrom('liveEvents.isLoadingMore')
  get loadMoreBtnText() {
    const btnText = this.liveEvents.isLoadingMore ? 'Loading More...' : 'Load More';

    return btnText;
  }

  @computedFrom(
    'params.r_start',
    'params.r_stop',
    'params.slicer',
    'params.operator',
    'params.state',
    'params.name',
    'params.startRange',
    'params.endRange',
    'params.createdRange',
  )
  get isFiltered() {
    return (
      this.params &&
      (this.params.r_start ||
        this.params.r_stop ||
        this.params.slicer ||
        this.params.operator ||
        this.params.state ||
        this.params.name ||
        this.params.startRange ||
        this.params.endRange ||
        this.params.createdRange)
    );

    // return true;
  }

  constructor(
    public liveEvents: LiveEventsService,
    public session: SessionService,
    public router: Router,
    public dialogService: DialogService,
    public sharedNav: SharedNav,
  ) {
    this.isDeleting = false;
    this.colsLoading = false;
    this.searchText = this.liveEvents.searchText || '';

    this.createEventDialog = null;

    // Handling the new Lynkified searched
    this.searchPopoverDisabled = true;
    this.searchPopoverOpen = false;
    this.searchTips = [];

    this.tableColumns = [
      new TableColumn('Title', 'desc', {isHideable: false, isSortable: true}),
      new TableColumn('Primary Slicer', 'slicers', {isSortable: true}),
      new TableColumn('Operator', 'operator_username', {isSortable: true}),
      new TableColumn('External ID', 'external_id', {isSortable: true}),
      new TableColumn('Event Status', 'status', {isSortable: true}),
      new TableColumn('Scheduled Start', 'expected_start', {isSortable: true}),
      new TableColumn('Scheduled Stop', 'expected_stop', {isSortable: true}),
      new TableColumn('Actual Start', 'actual_start', {isSortable: true}),
      new TableColumn('Actual Stop', 'actual_stop', {isSortable: true}),
      new TableColumn('Owner', 'owner_username', {isSortable: true}),
      new TableColumn('Created', 'created', {isSortable: true}),
    ];

    this.hiddenColumns = [];
    this.csvIncludeDeleted = false;
    this.csvIncludeTimezone = true;
  }

  /*
    Aurelia Hooks
   */

  public async activate() {
    if (this.liveEvents.params) {
      const eventsParams = deepCopy(this.liveEvents.params);
      eventsParams.since = null; // UP-7673

      if (eventsParams.operator === null) {
        eventsParams.operator = '--- None ---';
      }

      if (eventsParams.slicer === null) {
        eventsParams.slicer = '--- None ---';
      }

      if (eventsParams.calendar) {
        this.params = this.liveEvents.getSanitizedBlankFilterParams();
      } else {
        this.params = this.liveEvents.sanitizeParams(eventsParams);
      }
    }

    this.colsLoading = true;

    if (!this.liveEvents.data || this.liveEvents.data.length === 0) {
      try {
        const params = this.liveEvents.sanitizeParams(this.params);
        await this.liveEvents.getLiveEvents(params);

        this.updateEvents();
      } catch (ex) {
        logger.error(ex.message);
      }
    } else {
      this.updateEvents();
    }

    await Promise.all([
      this.getHiddenColumns(),
    ]);

    this.colsLoading = false;

    this.sharedNav.setActive(0, 0);
  }

  public deactivate() {
    this.liveEvents.getEventFetch.abort(new Error('Request was cancelled'));
    clearInterval(this.updateTimeout);
    this.updateTimeout = null;
  }

  /*
    Public Methods
  */

  public searchTextChanged(newVal) {
    const query = newVal;

    if (!query || query.includes(':')) {
      this.clearSearchPopover();
    } else {
      const operators = [
        {
          field: '',
          postText: 'in any search field',
          operator: '@',
        },
        {
          field: 'title: ',
          postText: 'in the Event Name',
          operator: 'title:@',
        },
        {
          field: 'externalId: ',
          postText: 'in the External ID',
          operator: 'externalId:@',
        },
        {
          field: 'metaKey: ',
          postText: 'in any Metadata Keys',
          operator: 'metaKey:@',
        },
        {
          field: 'metaKey|Value: ',
          preText: 'Find Metadata Values with',
          postText: 'as the Metadata Key',
          operator: 'metaKey|Value:@',
        },
        {
          field: 'metaValue: ',
          postText: 'in any Metadata Values',
          operator: 'metaValue:@',
        },
      ];
      this.searchTips = [];
      operators.forEach(o => {
        this.searchTips.push({
          field: o.field,
          preText: o.preText || 'Find',
          postText: o.postText,
          searchText: query,
          operator: o.operator.replace('@', query),
        });
      });
      this.searchPopoverOpen = true;
      this.searchPopoverDisabled = false;
    }
  }

  public resetSearch() {
    this.searchText = '';
    this.liveEvents.searchText = this.searchText;
    this.search('');
  }

  public openFilterGrid() {
    this.eventsFilter.openModal();
  }

  public clearAppliedFilters() {
    const currentParams = this.params;
    const params: any = this.liveEvents.getSanitizedBlankFilterParams();

    if (currentParams.search) {
      params.search = currentParams.search;
    }

    this.updateFilterGrid(params);
  }

  public async createEvent(edit: boolean = false): Promise<void> {
    if (this.isSaving) {
      return;
    }

    if (!this.model || !this.model.desc) {
      this.errorCreate = true;
      return;
    }

    this.errorCreate = false;
    this.isSaving = true;
    this.createEventDialog.hide();

    try {
      const res: any = await this.liveEvents.createLiveEvent(this.model);
      this.model = null;

      if (edit) {
        this.liveEvents.setOrigEvent(null);

        this.router.navigateToRoute('liveEventSingle', {id: res.event.id});
      }
    } catch (ex) {
      logger.error(ex.message);
    } finally {
      this.isSaving = false;
    }
  }

  public async deleteSelectedEvents(override: boolean = false): Promise<void> {
    if (this.isDeleting) {
      return;
    }

    this.isDeleting = true;

    try {
      const data: any = await this.liveEvents.deleteLiveEvents(this.selectedRecords, override);

      if (!data?.warning) {
        this.toggleSelectAll(false);
      }

      this.showWarningDialog(data);
    } catch (ex) {
      logger.error(ex.message);
    } finally {
      this.isDeleting = false;
    }
  }

  public async duplicateEvents(): Promise<void> {
    if (this.isCopying) {
      return;
    }

    this.isCopying = true;

    try {
      await this.liveEvents.duplicateLiveEvents(this.eventsToDuplicate);

      this.toggleSelectAll(false);
      this.updateEvents();
    } catch (ex) {
      logger.error(ex.message);
    } finally {
      this.isCopying = false;
    }
  }

  public async duplicateSelectedEvents() {
    this.eventsToDuplicate = this.selectedRecords.map(id => this.mapToDuplicateEvent(id));

    const hasMeta = this.eventsToDuplicate.some(e => e.meta && Object.entries(e.meta).length);

    if (hasMeta) {
      this.duplicateEventsModal.openModal();
    } else {
      this.duplicateEvents();
    }
  }

  public exportToCSV() {
    const params: any = this.liveEvents.sanitizeParams(this.params);
    params.columns = [];
    params.columns.push({key: 'id', value: 'Event Id'});

    if (this.csvIncludeDeleted) {
      params.columns.push({key: 'deleted', value: 'Deleted'});
    }

    this.tableColumns.forEach(col => {
      if (col.isVisible) {
        params.columns.push({key: col.key, value: col.label});
      }
    });

    params.columns.push({key: 'testing_start', value: 'Testing Start'});
    params.columns.push({key: 'testing_complete', value: 'Testing Complete'});

    params.csv_include_deleted = this.csvIncludeDeleted;

    if (this.csvIncludeTimezone) {
      params.csv_include_timezone = moment.tz.guess();
    }

    // Get events.
    this.liveEvents.getExport(params);

    // Hide Tip Content
    this.export_popover?.hide();
  }

  /**
   * Link to the event single for the record that was clicked.
   *
   * @param record {Object} - Record object that was clicked.
   */
  public rowClickHandler(record) {
    if (this.liveEvents.model?.id !== record.id) {
      this.liveEvents.setModel(record);
    }

    this.router.navigateToRoute('liveEventSingle', {id: record.id});
  }

  public getRowState(record): string {
    if (record == null) return '';

    // Check for conflicts
    if (record.conflicts && record.conflicts.some(c => c.conflicts.length > 0)) {
      return 'warning';
    }

    // Check live status
    if (record.state === 'live') {
      return 'success';
    }

    return '';
  }

  public async sortEvents(key: string, defaultSortDirection: string = null) {
    const currentOrder = this.params.order || '';
    const newOrder = TableSortingTools.sort(key, currentOrder, defaultSortDirection);

    this.params.order = newOrder;

    this.params.skip = DEFAULT_SKIP;
    // Reset to as if you loaded the page - lazy loading will kick in don't worry
    this.params.limit = DEFAULT_PER_PAGE_LIMIT;

    const params = this.liveEvents.sanitizeParams(this.params);

    this.liveEvents.getLiveEvents(params);
  }

  public findColumnByKey(key: string): TableColumn | undefined {
    return this.tableColumns.find(column => column.key === key);
  }

  public scrollBottomHandler() {
    if (this.canLoadMore && !this.liveEvents.isLoadingMore) {
      this.loadMoreEvents();
    }
  }

  /**
   * Sets correct params. Then loads more live events.
   */
  public async loadMoreEvents(): Promise<any> {
    const {meta} = this.liveEvents;

    if (!this.canLoadMore) {
      return null;
    }

    this.params.skip = meta.showing;
    this.params.limit = DEFAULT_PER_PAGE_LIMIT;
    this.params.since = null;
    this.params.deleted = null;

    const params = this.liveEvents.sanitizeParams(this.params);

    return this.liveEvents.getLiveEvents(params, true);
  }

  public search(searchText) {
    const parsed = this.parseQueryFilters(searchText);

    this.params.skip = DEFAULT_SKIP;
    this.params.since = null;
    this.liveEvents.searchText = searchText;

    // Prevent search string from being saved on this.params obj
    const paramsObj = Object.assign(this.params, {search: parsed});
    const params = this.liveEvents.sanitizeParams(paramsObj);

    this.liveEvents.getLiveEvents(params).finally(() => {
      this.selectedRecords.splice(0, this.selectedRecords.length);
    });

    this.searchPopoverOpen = false;
  }

  public searchReplaceTip(operator) {
    const replaceQueryText = operator;
    this.searchText = replaceQueryText;

    this.searchInput?.focus();

    this.clearSearchPopover();
  }

  public showHideColumn(col) {
    col.isVisible = !col.isVisible;

    if (col.isVisible) {
      const idx = this.hiddenColumns.indexOf(col.key);
      if (idx !== -1) {
        this.hiddenColumns.splice(idx, 1);
      }
    } else {
      this.hiddenColumns.push(col.key);
    }
    this.session.setOwnerMeta(OwnerMetaContext.LIVE_EVENTS, {'hidden-columns': this.hiddenColumns});
  }

  public toggleEventSelected(record) {
    record.selected = !record.selected;
    this.trackSelectedEvent(record);
  }

  /**
   * Track an record as it's toggled for deletion or duplication
   */
  public trackSelectedEvent(record) {
    if (record.selected) {
      this.selectedRecords.push(record.id);
    } else {
      _.remove(this.selectedRecords, recordId => recordId === record.id);
    }

    this.selectedRecords = _.uniq(this.selectedRecords);
  }

  /**
   * Toggle all visible records for deletion or duplication
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.liveEvents.data, record => {
      if (isSelected) {
        record.selected = true;
      } else {
        record.selected = false;
      }
    });

    this.selectedRecords = _.map(
      _.filter(this.liveEvents.data, record => record.selected),
      record => record.id,
    );
  }

  /**
   * Fetches live events with the updated params.
   */
  public async updateFilterGrid(params) {
    this.params = deepCopy(params);
    this.setDateParams();

    const sanitizedParams = this.liveEvents.sanitizeParams(this.params);

    try {
      await this.liveEvents.getLiveEvents(sanitizedParams);
      this.selectedRecords.splice(0, this.selectedRecords.length);
    } catch (err) {
      logger.error(err.message);
    }
  }

  /*
    Private Methods
   */

  private clearSearchPopover() {
    this.searchTips = [];
    this.searchPopoverDisabled = true;
    this.searchPopoverOpen = false;
  }

  private async getHiddenColumns(): Promise<void> {
    try {
      this.hiddenColumns = [];
      const hiddenColumns = await this.session.getOwnerMeta('live-events', 'hidden-columns');

      if (!Array.isArray(hiddenColumns)) {
        return;
      }

      this.hiddenColumns = hiddenColumns;

      hiddenColumns.forEach(key => {
        this.tableColumns.forEach((column, index) => {
          if (column.key === key) {
            this.tableColumns[index].isVisible = false;
          }
        });
      });
    } catch (ex) {
      logger.error(ex.message);
    }
  }

  private mapToDuplicateEvent(eventId) {
    const event = this.liveEvents.data.find(evt => evt.id === eventId);
    return {
      id: event.id,
      meta: event.meta,
      desc: event.desc,
    };
  }

  /**
   * Parses the reader-friendly keys back to model keys in preparation for query.
   *
   * @param {String} searchText - Search string query.
   * @returns {String} - Parsed search string query.
   */
  private parseQueryFilters(searchText: string): string {
    const keys = this.liveEventQueryFilters;
    let parsed = deepCopy(searchText);

    Object.keys(keys).forEach(k => {
      parsed = parsed.replace(keys[k], k);
    });

    return parsed;
  }

  /**
   * Looks at the params object and adds the appropriate date fields
   */
  private setDateParams() {
    if (this.params.startRange === 'Today') {
      this.params.r_start = Number(moment().startOf('day').format('x'));
      this.params.r_stop = Number(moment().endOf('day').format('x'));
    } else if (this.params.startRange === 'Last 24 Hours') {
      this.params.r_start = Number(moment().subtract(1, 'day').format('x'));
      this.params.r_stop = Number(moment().format('x'));
    } else if (this.params.startRange === 'Next 24 Hours') {
      this.params.r_start = Number(moment().format('x'));
      this.params.r_stop = Number(moment().add(1, 'day').format('x'));
    } else if (this.params.startRange === 'Tomorrow') {
      const tomorrow = moment().add(1, 'day');
      this.params.r_start = Number(tomorrow.startOf('day').format('x'));
      this.params.r_stop = Number(tomorrow.endOf('day').format('x'));
    }

    if (this.params.endRange === 'Today') {
      this.params.end_r_start = Number(moment().startOf('day').format('x'));
      this.params.end_r_stop = Number(moment().endOf('day').format('x'));
    } else if (this.params.endRange === 'Last 24 Hours') {
      this.params.end_r_start = Number(moment().subtract(1, 'day').format('x'));
      this.params.end_r_stop = Number(moment().format('x'));
    } else if (this.params.endRange === 'Next 24 Hours') {
      this.params.end_r_start = Number(moment().format('x'));
      this.params.end_r_stop = Number(moment().add(1, 'day').format('x'));
    } else if (this.params.endRange === 'Tomorrow') {
      const tomorrow = moment().add(1, 'day');
      this.params.end_r_start = Number(tomorrow.startOf('day').format('x'));
      this.params.end_r_stop = Number(tomorrow.endOf('day').format('x'));
    }

    if (this.params.createdRange === 'Today') {
      this.params.created_r_start = Number(moment().startOf('day').format('x'));
      this.params.created_r_stop = Number(moment().endOf('day').format('x'));
    } else if (this.params.createdRange === 'Last 24 Hours') {
      this.params.created_r_start = Number(moment().subtract(1, 'day').format('x'));
      this.params.created_r_stop = Number(moment().format('x'));
    }
  }

  private showWarningDialog(data) {
    const warnModel = {
      header: 'Warning',
      question: `We are currently building the final VOD for one or more of the events you selected.
                      If you delete such an event now, the final VOD will be broken.`,
      yes: "That's fine. Delete anyway.",
      no: 'Never mind. ',
      hideCancel: true,
      footerClass: 'btn-split',
    };

    let showModal = false;

    if (data.msg === 'Event is live') {
      warnModel.question = `One or more selected events is current LIVE.
                      Deleting now would keep your slicer running detached from an event.`;
      warnModel.no += "I'll end the event first";
      showModal = true;
    } else if (data.msg === 'VOD being created') {
      warnModel.no += "I'll wait.";
      showModal = true;
    }

    if (showModal) {
      this.dialogService
        .open({
          viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
          model: warnModel,
        })
        .whenClosed(d2 => {
          if (d2.output === true) {
            this.deleteSelectedEvents(true);
          }
        });
    }
  }

  private updateEvents() {
    if (this.updateTimeout) {
      clearInterval(this.updateTimeout);
    }

    this.updateTimeout = setInterval(() => {
      this.liveEvents.getEventUpdates();
    }, EVENTS_CHECK_INTERVAL_MS);
  }
}
