import {autoinject, bindable, bindingMode} from 'aurelia-framework';
import {LynkInput} from '@uplynk/lynk-design';
import * as DOMPurify from 'dompurify';
import * as _ from 'lodash';

export interface KeyValPair {
  key: string;
  value: string;
  errorText?: string;
  errorState?: string;
}

@autoinject
export class KeyValueManager {
  @bindable({defaultBindingMode: bindingMode.twoWay}) model: {[key: string]: string} = {};
  @bindable({defaultBindingMode: bindingMode.twoWay}) disabled: boolean = false;

  private keyValData: KeyValPair[] = [];
  private newPairKey: string = '';
  private newPairValue: string = '';
  public newPairErrorState: string = '';
  public newPairErrorText: string = '';
  public newPairKeyInput: LynkInput;

  constructor() {}

  public attached(): void {
    this.initKeyValuePairs();
  }

  public modelChanged(): void {
    this.initKeyValuePairs();
  }

  public initKeyValuePairs(): void {
    this.clearKeyValueData();

    Object.entries(this.model).forEach(
      ([
        key,
        value,
      ]) => {
        this.keyValData.push({key, value});
      },
    );
  }

  public removeKeyValue(key: string): void {
    const index = this.keyValData.findIndex(x => x.key === key);
    if (index > -1) {
      this.keyValData.splice(index, 1);
      this.updateModel(key, null);
    }
  }

  public addKeyValue(): void {
    if (this.disabled) return;

    if (this.isEmptyKeyValuePair()) {
      return;
    }

    if (this.isDuplicateKey()) {
      this.setError(null, 'Duplicate key!', 'k_error');
      return;
    }

    this.sanitizeInputs();

    this.keyValData = this.keyValData.concat([{key: this.newPairKey, value: this.newPairValue}]);

    this.updateModel(this.newPairKey, this.newPairValue);

    this.clearInputs();

    if (this.newPairKeyInput) {
      this.newPairKeyInput.focus();
    }
  }

  public validateKeyValue(pair: KeyValPair): void {
    this.resetError(pair);

    if (this.isDuplicateKey(pair.key, pair)) {
      this.setError(pair, 'Duplicate key!', 'k_error');
    }

    if (pair.key === '') {
      this.setError(pair, 'Key is required.', 'k_error');
    } else if (pair.value === '') {
      this.setError(pair, 'Value is required.', 'v_error');
    }

    if (!pair.errorState) {
      this.model = _.cloneDeep(this.convertArrayToModel());
    }
  }

  private clearKeyValueData(): void {
    this.keyValData = [];
  }

  private isEmptyKeyValuePair(): boolean {
    const isEmpty = this.newPairKey === '' || this.newPairValue === '';
    if (isEmpty) {
      this.resetError(null);
    }
    return isEmpty;
  }

  private isDuplicateKey(key?: string, currentPair?: KeyValPair): boolean {
    const duplicateKey = key || this.newPairKey;
    return this.keyValData.some(pair => pair.key === duplicateKey && pair !== currentPair);
  }

  private sanitizeInputs(): void {
    this.newPairKey = DOMPurify.sanitize(this.newPairKey);
    this.newPairValue = DOMPurify.sanitize(this.newPairValue);
  }

  private updateModel(key: string, value: string | null): void {
    const updatedParams = _.cloneDeep(this.model);

    if (value === null) {
      delete updatedParams[key];
    } else {
      updatedParams[key] = value;
    }

    this.model = _.cloneDeep(updatedParams);
  }

  private setError(pair: KeyValPair | null, errorText: string, errorState: string): void {
    if (pair) {
      pair.errorText = errorText;
      pair.errorState = errorState;
    } else {
      this.newPairErrorText = errorText;
      this.newPairErrorState = errorState;
    }
  }

  private resetError(pair: KeyValPair | null): void {
    if (pair) {
      pair.errorText = '';
      pair.errorState = '';
    } else {
      this.newPairErrorText = '';
      this.newPairErrorState = '';
    }
  }

  private clearInputs(): void {
    this.newPairKey = '';
    this.newPairValue = '';
    this.resetError(null);
  }

  private convertArrayToModel(): {[key: string]: string} {
    return this.keyValData.reduce((acc, pair) => {
      acc[pair.key] = pair.value;
      return acc;
    }, {} as {[key: string]: string});
  }
}
