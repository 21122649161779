import {Type} from 'class-transformer';
import {IsOptional, IsString, ValidateNested} from 'class-validator';
import {MultiCdnSettingsConfig} from './multi-cdn-settings-config';

export class MultiCdnSettingsConfigWrapper {
    @ValidateNested()
    @Type(() => MultiCdnSettingsConfig)
    public multiCdnSettingsConfig: MultiCdnSettingsConfig;

    @IsString()
    @IsOptional()
    public errorMessage: string;
}
