import {bindable} from 'aurelia-framework';
import * as styles from './b-timeline-week-container.css';

export class BTimelineWeekContainer {
  @bindable
  public days;

  @bindable
  public dayClick;

  @bindable
  public timeView: string = 'week';

  public styles = styles;

  public changeDate(date) {
    if (this.dayClick && _.isFunction(this.dayClick) && this.timeView !== 'day') {
      this.dayClick({date});
    }
  }
}
