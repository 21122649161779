import {BindingEngine} from 'aurelia-binding';
import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {plainToClass} from 'class-transformer';
import {dirtyCheckPrompt} from 'decorators';
import {Notification} from 'resources/notification/service';
import {AssetService} from 'services/asset';
import {LibraryService} from 'services/library-service';
import {Library, LibraryAsset} from 'services/models/library';
import {DefaultSlates, SlateSettings} from './models/slate-settings';
import {SettingsService} from './services/settings-service';
import {slateConfigs} from './slate-configs';

@dirtyCheckPrompt
@autoinject()
export class Slate {
    public currentSlateType;
    public defaultSlate: LibraryAsset;
    public defaultSlates: DefaultSlates;
    public isFormDirty = false;
    public isLoading = false;
    public isLoadingAssets = false;
    public isSaving = false;
    public libraries: Library[];
    public playerModal;
    public routeName;
    public slateAssets: LibraryAsset[];
    public slateConfigs = slateConfigs;
    public slateModal;
    public slateSettings: SlateSettings;
    public slateSettingsPristine: SlateSettings;
    public streamUrl: string;
    public subscriptions = [];

    constructor(
        public router: Router,
        public notification: Notification,
        public bindingEngine: BindingEngine,
        public settingsService: SettingsService,
        public libraryService: LibraryService,
        public assetService: AssetService,
    ) {}

    /* tslint:disable: variable-name */
    public activate(_params, routeConfig) {
        /* tslint:enable: variable-name */
        this.routeName = routeConfig.name || '';
    }

    public attached() {
        const requests = [this.settingsService.getSlateSettings(), this.libraryService.getLibraries()];

        return new Promise((resolve, reject) => {
            this.isLoading = true;
            Promise.all(requests)
                .then(resp => {
                    this.slateSettings = resp[0].slate_settings;
                    Object.keys(this.slateSettings).forEach(key => {
                        this.subscriptions.push(
                            this.bindingEngine.propertyObserver(this.slateSettings, key).subscribe(() => {
                                this.isFormDirty = !_.isEqual(this.slateSettings, this.slateSettingsPristine);
                            }),
                        );
                    });
                    this.defaultSlates = resp[0].default_slates;
                    this.verifyDefaultSlateExists(this.defaultSlates);
                    this.slateSettingsPristine = _.cloneDeep(this.slateSettings);
                    this.libraries = resp[1];
                    resolve();
                })
                .catch(e => {
                    this.notification.error('Error loading slate settings.');
                    reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    public addCurrentIfMissing(type, assets = []) {
        const slateId = this.slateSettings[`${type.slateKey}_asset`];
        if (!slateId) return assets;
        let currentSlate = assets.find(sa => sa.id === slateId);
        if (!currentSlate) {
            currentSlate = plainToClass(LibraryAsset, {
                id: slateId,
                isOrphan: true,
                poster_url: this.slateSettings[`${type.slateKey}_poster_url`],
                title: this.slateSettings[`${type.slateKey}_desc`],
            });
            assets.unshift(currentSlate);
        }
        return assets;
    }

    public detached() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    public isDirty(): boolean {
        return !_.isEqual(this.slateSettings, this.slateSettingsPristine);
    }

    public openAssetModal(type): Promise<any> {
        this.currentSlateType = {
            assetId: this.slateSettings[`${type.slateKey}_asset`],
            ...type,
        };
        this.defaultSlate = this.defaultSlates[type.slateKey];
        this.slateModal.open();
        const libId = this.slateSettings[`${type.slateKey}_library`];
        return new Promise((resolve, reject) => {
            if (libId) {
                this.isLoadingAssets = true;
                this.libraryService
                    .getLibraryAssets(libId)
                    .then(assets => {
                        this.slateAssets = this.addCurrentIfMissing(type, assets);
                        resolve();
                    })
                    .catch(e => {
                        const library = this.libraries.find(lib => lib.id === libId);
                        this.notification.error(`Error loading assets for library: ${library.desc}`);
                        reject(e);
                    })
                    .finally(() => {
                        this.isLoadingAssets = false;
                    });
            } else {
                this.slateAssets = this.addCurrentIfMissing(type);
                resolve();
            }
        });
    }

    public openPlayerModal(type): Promise<any> {
        this.streamUrl = '';
        let assetId = this.slateSettings[`${type.slateKey}_asset`];
        let assetDesc = this.slateSettings[`${type.slateKey}_desc`];
        if (!assetId) {
            const defaultSlate = this.defaultSlates[type.slateKey];
            assetId = defaultSlate.id;
            assetDesc = defaultSlate.title;
            this.streamUrl = defaultSlate.hls_url;
        }
        this.currentSlateType = {
            ...type,
            assetDesc,
            assetId,
        };

        this.playerModal.open();
        if (this.streamUrl) return Promise.resolve();
        return new Promise((resolve, reject) => {
            this.assetService
                .getPlayerUrl(assetId)
                .then(playerUrl => {
                    this.streamUrl = playerUrl;
                    resolve();
                })
                .catch(e => {
                    this.notification.error(`Error getting player url for ${assetDesc}`);
                    reject(e);
                });
        });
    }

    public save(): Promise<any> {
        this.isSaving = true;
        return new Promise((resolve, reject) => {
            this.settingsService
                .saveSlateSettings(this.slateSettings)
                .then(() => {
                    this.notification.success('Changes saved successfully.');
                    this.slateSettingsPristine = _.cloneDeep(this.slateSettings);
                    this.isFormDirty = false;
                    resolve();
                })
                .catch(e => {
                    this.notification.error('There was an error saving your changes.');
                    reject(e);
                })
                .finally(() => {
                    this.isSaving = false;
                });
        });
    }

    @computedFrom('isSaving')
    get saveBtnText() {
        return this.isSaving ? 'Saving...' : 'Save';
    }

    public slateSelect(): void {
        const type = this.currentSlateType.slateKey;
        const currentSlateId = this.slateSettings[`${type}_asset`];
        const selectedSlatedId = this.currentSlateType.assetId;
        const selectedAsset = this.slateAssets.find(asset => asset.id === selectedSlatedId);
        let title = '';
        if (selectedSlatedId !== currentSlateId) {
            if (!selectedAsset) {
                title = 'System slate';
            } else {
                title = selectedAsset.title;
            }
            // slate library is automatically updated by the model binding in select component, so not doing it here.
            this.slateSettings[`${type}_asset`] = selectedSlatedId;
            if (`${type}_asset_resolved` in this.slateSettings) {
                this.slateSettings[`${type}_asset_resolved`] = selectedSlatedId;
            }
            this.slateSettings[`${type}_desc`] = title;
        }
        this.slateModal.close();
    }

    private verifyDefaultSlateExists(defaultSlates) {
        const slatTypes = _.flatMap(slateConfigs, config => config.types);
        slatTypes.forEach(st => {
            const type = st.slateKey;
            if (!(type in defaultSlates)) {
                defaultSlates[type] = plainToClass(LibraryAsset, {
                    id: '',
                    poster_url: 'assets/images/_placeholder/no-slate.png',
                    title: 'Account Default Slate',
                });
            }
        });
    }
}
