import {LocalStorageHelper} from '@bindable-ui/bindable';
import {bindable, bindingMode} from 'aurelia-framework';

// tslint:disable-next-line:import-name
import * as moment from 'moment-timezone';

import * as styles from './v-timezone-picker.css';

interface ITimezone {
    abbreviation: string;
    display?: string;
    offset: string;
    title: string;
}

interface ITimezoneObject {
    data: ITimezone;
    text: string;
    value: string;
}

export interface ITimezoneActions {
    onChange?: (timezone) => any;
}

export class VTimezonePicker {
    @bindable
    public actions: ITimezoneActions;

    @bindable({defaultBindingMode: bindingMode.twoWay})
    public timezone = null;

    public styles = styles;
    public timezoneDisplay: string = null;
    public timezoneDisplayDetails: ITimezoneObject = null;
    public timezoneList: ITimezoneObject[] = [];
    private tipRef: any = null;

    constructor() {
        const usaTzs = [];
        const worldTzs = [];

        _.forEach(moment.tz.names(), tzName => {
            const momentObj = moment.tz(tzName);

            const tz: ITimezone = {
                abbreviation: momentObj.format('z'),
                offset: `${momentObj.format('ZZ').slice(0, 3)}:${momentObj.format('ZZ').slice(3)}`,
                title: tzName,
            };
            const tzObj: ITimezoneObject = {
                data: _.cloneDeep(tz),
                text: `(GMT${tz.offset}) ${tz.title}`,
                value: tz.title,
            };
            switch (tzName) {
                case 'US/Eastern':
                    usaTzs[0] = tzObj;
                    break;
                case 'US/Central':
                    usaTzs[1] = tzObj;
                    break;
                case 'US/Mountain':
                    usaTzs[2] = tzObj;
                    break;
                case 'US/Pacific':
                    usaTzs[3] = tzObj;
                    break;
                default:
                    worldTzs.push(tzObj);
            }
        });

        this.timezoneList = [...usaTzs, ...worldTzs];
    }

    public timezoneChanged() {
        if (this.timezone) {
            const tzInfo = _.find(this.timezoneList, tz => tz.value === this.timezone);
            if (tzInfo) {
                this.timezoneDisplayDetails = _.cloneDeep(tzInfo);
                _.defer(() => (this.timezoneDisplay = tzInfo.value));
            }

            if (this.actions && _.isFunction(this.actions.onChange)) {
                return this.actions.onChange(this.timezone);
            }
        }
    }

    public saveTz() {
        this.timezone = this.timezoneDisplay;
        LocalStorageHelper.save('channel-timezone', this.timezone);

        if (this.tipRef) {
            this.tipRef.hide();
        }
    }
}
