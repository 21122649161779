import {TextReader} from '../file/text-reader';
import {ConfigLineType, IConfigLine} from './models/config-line';

enum ConfigParserError {
  CONFIG_EMPTY = 'Config is empty',
  CONFIG_UNSUPPORTED = 'Unsupported config format',
}

export class ConfigParser {
  public static accepted = [
    '.js',
    '.json',
    '.conf',
    '.txt',
    '.text',
  ];

  public static async parseFile(requestedFile: File) {
    return ConfigParser.parseString(await new TextReader().load(requestedFile));
  }

  public static parseString(value: string) {
    try {
      return ConfigParser.parseJSON(JSON.parse(value));
    } catch (error) {
      return ConfigParser.parseKVP(value);
    }
  }

  public static stringifyJSON(config: IConfigLine[]) {
    const result = {};
    config
      .filter(
        line =>
          line &&
          line.type !== ConfigLineType.DISALLOWED &&
          line.type !== ConfigLineType.COMMENT &&
          line.type !== ConfigLineType.EMPTY &&
          line.type !== ConfigLineType.MANAGED &&
          line.type !== ConfigLineType.UNSUPPORTED,
      )
      .forEach(line => {
        result[line.key] = line.value || '';
      });
    return JSON.stringify(result);
  }

  public static stringifyKVP(config: IConfigLine[]) {
    return config
      .filter(line => line && line.type !== ConfigLineType.MANAGED && line.type !== ConfigLineType.UNSUPPORTED)
      .map(line => {
        switch (line.type) {
          case ConfigLineType.KVP:
            return `${line.key}${line.value ? ':'.concat(line.value) : ''}`;
          case ConfigLineType.KVP_AND_COMMENT:
            return `${line.key}:${line.value} # ${line.comment}`;
          default:
            return line.original;
        }
      })
      .join('\r\n');
  }

  public static validate(config: IConfigLine[]) {
    config.forEach((line, index) => {
      if (line.type === ConfigLineType.UNSUPPORTED) {
        throw new Error(`${ConfigParserError.CONFIG_UNSUPPORTED} on line ${index + 1}`);
      }
    });
    const validLines = config.filter(
      line =>
        line.type === ConfigLineType.KVP ||
        line.type === ConfigLineType.KVP_AND_COMMENT ||
        line.type === ConfigLineType.COMMENT,
    );
    if (!validLines.length) {
      throw new Error(ConfigParserError.CONFIG_EMPTY);
    }
  }

  public static parseLine(line: string): IConfigLine {
    const firstColon = line.indexOf(':');
    const firstHash = line.indexOf('#');
    const result = {original: line} as IConfigLine;

    // empty line
    if (!line.trim().length) {
      // preserve any original white spaces in the value
      result.type = ConfigLineType.EMPTY;
      return result;
    }

    // comment-only line
    if (line.trim()[0] === '#') {
      if (line.substring(firstHash + 1).trim().length) {
        result.comment = line.substring(firstHash + 1).trim();
        result.type = ConfigLineType.COMMENT;
        return result;
      }
    }

    // lines with both a hash and a colon
    if (firstHash > -1 && firstColon > -1 && firstHash > firstColon) {
      result.comment = line.substring(firstHash + 1).trim();
      result.key = line.substring(0, firstColon).trim();
      result.type = ConfigLineType.KVP_AND_COMMENT;
      // some keys don't have a value, such as 'remote:'
      if (line.substring(firstColon + 1, firstHash).trim().length) {
        result.value = line.substring(firstColon + 1, firstHash).trim();
      }
      return result;
    }

    // kvp-only line
    if (firstHash === -1 && firstColon > -1) {
      result.key = line.substring(0, firstColon).trim();
      result.type = ConfigLineType.KVP;
      // some keys don't have a value, such as 'remote:'
      // if (line.substring(firstColon + 1).trim().length) {
      result.value = line.substring(firstColon + 1).trim();
      // }
      return result;
    }

    // default to unsupported
    result.type = ConfigLineType.UNSUPPORTED;
    return result;
  }

  private static parseJSON(value: object): IConfigLine[] {
    if (!value) return [];
    return Object.keys(value).map(objectKey => ({
      key: objectKey,
      original: `${objectKey}:${value[objectKey]}`,
      type: ConfigLineType.KVP,
      value: value[objectKey],
    }));
  }

  private static parseKVP(value: string) {
    if (!value) return [];
    return value
      .split(/\r?\n/)
      .map(ConfigParser.parseLine)
      .filter(line => line.type !== ConfigLineType.EMPTY);
  }
}
