/**
 * For usage an example see:
 * http://cms.downlynk.localhost:8000/static/cms2/dummy.html#/authorization-examples
 */

import {computedFrom} from 'aurelia-binding';
import {inject, LogManager, singleton} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {NavigationInstruction, RouteConfig, Router} from 'aurelia-router';
import {SessionService} from 'services/session.js';
import {entitlementToModuleMapping, moduleToEntitlementMapping} from './entitlement-mapping';
import {Entitlement, Permission, PermissionSuffix, UserPermissions} from './models';

export * from './models';
export * from './decorators';

const log = LogManager.getLogger('authorization');

export enum ACCESS_TYPE {
    ALLOWED = 'allowed',
    NOT_ALLOWED = 'not_allowed',
    READ_ONLY = 'read_only',
}

const notAuthorizedView = PLATFORM.moduleName('apps/cms/not-authorized.html');
const notAuthorizedSwitchedUserView = PLATFORM.moduleName('apps/cms/not-authorized-switched-user.html');
const MAX_LEVEL_INSTRUCTION_SEARCH = 10;

@singleton()
@inject(Router, SessionService)
export class Authorization {
    public isAdmin: boolean = false;

    private permissions: UserPermissions;
    // full entitlements includes full set, e.g., settings, settingsro, led, ledro, ledop
    private fullEntitlements: Entitlement[];
    // entitlements only includes normalized set, e.g, settings, led
    private entitlements: string[];

    constructor(public router: Router, public sessionService: SessionService) {
        this.fullEntitlements = _.get(this.sessionService, 'sessionInfo.entitlements', []);
        this.entitlements = this.getSimplifiedEntitlements(this.fullEntitlements);
        this.isAdmin = _.get(this.sessionService, 'sessionInfo.perms', '').indexOf('admin') >= 0;
        this.permissions = this.getPermissions();
    }

    @computedFrom('session.sessionInfo.origUsername', 'this.sessionService.sessionInfo.username')
    get isOtherUser() {
        return (
            this.sessionService.sessionInfo !== null &&
            this.sessionService.sessionInfo.username !== undefined &&
            this.sessionService.sessionInfo.origUsername !== undefined &&
            this.sessionService.sessionInfo.username !== this.sessionService.sessionInfo.origUsername
        );
    }

    /**
     * Returns ACCESS_TYPE.ALLOWED if the user has given permission, ACCESS_TYPE.NOT_ALLOWED otherwise.
     * @example
     * getAccessTypeForPermission(Permission.WRITE);
     * @param {Permission} permission
     * @returns {ACCESS_TYPE} 'allowed' or 'not_allowed'
     */
    public getAccessTypeForPermission = (permission: Permission): ACCESS_TYPE => {
        if (this.isAdmin) {
            return ACCESS_TYPE.ALLOWED;
        }
        return this.getModulePermissions().indexOf(permission) >= 0 ? ACCESS_TYPE.ALLOWED : ACCESS_TYPE.NOT_ALLOWED;
    };

    /**
     * Returns ACCESS_TYPE.ALLOWED if the user has ANY of the listed permissions, ACCESS_TYPE.NOT_ALLOWED otherwise.
     * @example
     * // using list of parameters
     * getAccessTypeForAnyPermission(Permission.WRITE, Permission.OP);
     * // using array
     * const permissions = [Permission.WRITE, Permission.OP];
     * getAccessTypeForAnyPermission(...permissions);
     * @param {...Permission} permission List of permission parameters.
     * @returns {ACCESS_TYPE} 'allowed' or 'not_allowed'
     */
    public getAccessTypeForAnyPermission = (...permissions: Permission[]): ACCESS_TYPE => {
        if (this.isAdmin) {
            return ACCESS_TYPE.ALLOWED;
        }
        return _.intersection(permissions, this.getModulePermissions()).length > 0
            ? ACCESS_TYPE.ALLOWED
            : ACCESS_TYPE.NOT_ALLOWED;
    };

    /**
     * Returns ACCESS_TYPE.ALLOWED if the user has ALL of the listed permissions, ACCESS_TYPE.NOT_ALLOWED otherwise.
     * @example
     * // using list of parameters
     * getAccessTypeForAllPermissions(Permission.WRITE, Permission.OP);
     * // using array
     * const permissions = [Permission.WRITE, Permission.OP];
     * getAccessTypeForAllPermissions(...permissions);
     * @param {...Permission} permission List of permission parameters.
     * @returns {ACCESS_TYPE} 'allowed' or 'not_allowed'
     */
    public getAccessTypeForAllPermissions = (...permissions: Permission[]): ACCESS_TYPE => {
        if (this.isAdmin) {
            return ACCESS_TYPE.ALLOWED;
        }
        return _.intersection(permissions, this.getModulePermissions()).length === permissions.length
            ? ACCESS_TYPE.ALLOWED
            : ACCESS_TYPE.NOT_ALLOWED;
    };

    /**
     * Returns highest access level for a user entitlement..
     * @example
     * getAccessTypeForEntitlement(Entitlement.SETTINGS);
     * @param {Entitlement} entitlement
     * @returns {ACCESS_TYPE} 'allowed', 'not_allowed' or 'read_only'
     */
    public getAccessTypeForEntitlement = (entitlement: Entitlement): ACCESS_TYPE => {
        if (this.isAdmin) {
            return ACCESS_TYPE.ALLOWED;
        }

        if (this.entitlements.indexOf(entitlement) === -1) {
            return ACCESS_TYPE.NOT_ALLOWED;
        }

        return this.modulePermissionsToAccessType(this.permissions[entitlement]);
    };

    /**
     * Returns highest access level among all entitlement's access levels.
     * @example
     * // using list of parameters
     * getAccessTypeForAnyEntitlement(Entitlement.SETTINGS, Entitlement.BILLING, Entitlement.ANALYTICS);
     * // using array
     * const entitlements = [Entitlement.SETTINGS, Entitlement.BILLING, Entitlement.ANALYTICS];
     * getAccessTypeForAnyEntitlement(...entitlements);
     * @param {...Entitlement} entitlements List of entitlement parameters.
     * @returns {ACCESS_TYPE} 'allowed', 'not_allowed' or 'read_only'
     */
    public getAccessTypeForAnyEntitlement = (...entitlements: Entitlement[]): ACCESS_TYPE => {
        if (this.isAdmin) {
            return ACCESS_TYPE.ALLOWED;
        }
        if (_.intersection(entitlements, this.entitlements).length === 0) {
            return ACCESS_TYPE.NOT_ALLOWED;
        }

        const accessSet: Set<ACCESS_TYPE> = new Set();
        entitlements.forEach(e => {
            accessSet.add(this.modulePermissionsToAccessType(this.permissions[e]));
        });
        return this.getHighestAccessType(accessSet);
    };

    public getEntitlementPermissions = (entitlement: Entitlement): Permission[] => this.permissions[entitlement];

    /**
     * Returns lowest access level among all entitlement's access levels.
     * @example
     * // check on list of parameters
     * getAccessTypeForAllEntitlements(Entitlement.SETTINGS, Entitlement.BILLING, Entitlement.ANALYTICS);
     * // check on array
     * const entitlements = [Entitlement.SETTINGS, Entitlement.BILLING, Entitlement.ANALYTICS];
     * getAccessTypeForAllEntitlements(...entitlements);
     * @param {...Entitlement} entitlements List of entitlement parameters.
     * @returns {ACCESS_TYPE} 'allowed', 'not_allowed' or 'read_only'
     */
    public getAccessTypeForAllEntitlements = (...entitlements: Entitlement[]): ACCESS_TYPE => {
        if (this.isAdmin) {
            return ACCESS_TYPE.ALLOWED;
        }

        if (_.intersection(entitlements, this.entitlements).length !== entitlements.length) {
            return ACCESS_TYPE.NOT_ALLOWED;
        }

        const accessSet: Set<ACCESS_TYPE> = new Set();
        entitlements.forEach(e => {
            accessSet.add(this.modulePermissionsToAccessType(this.permissions[e]));
        });
        return this.getLowestAccessType(accessSet);
    };

    /**
     * Returns ACCESS_TYPE.ALLOWED if the user has given permission, ACCESS_TYPE.NOT_ALLOWED otherwise.
     * @example
     * getAccessTypeForCurrentRoute();
     * @returns {ACCESS_TYPE} 'allowed' or 'not_allowed'
     */
    public getAccessTypeForCurrentRoute = (): ACCESS_TYPE =>
        this.modulePermissionsToAccessType(this.getModulePermissions());

    /**
     * Returns highest access level for a module based on module to entitlement map.
     * @example
     * // check on a module
     * getAccessTypeForModule('settingsIndex');
     * @param {string} module Route (module) name. The route name is the one
     *   configured in the route configuration object
     * @returns {ACCESS_TYPE} 'allowed', 'not_allowed' or 'read_only'
     */
    public getAccessTypeForModule = (module: string): ACCESS_TYPE =>
        this.getAccessTypeForEntitlement(moduleToEntitlementMapping[module]);

    /**
     * Returns highest access level among all module's access levels.
     * @example
     * // check on single module
     * getAccessTypeForAnyModule('settingsIndex');
     * // check on multiple modules
     * getAccessTypeForAnyModule('settingsIndex', 'billingIndex', 'analyticsIndex');
     * // check on multiple modules using array
     * const modules = ['settingsIndex', 'billingIndex', 'analyticsIndex'];
     * getAccessTypeForAnyModule(...modules);
     * @param {...string} modules Route (module) names. The route name is the one
     *   configured in the route configuration object
     * @returns {ACCESS_TYPE} 'allowed', 'not_allowed' or 'read_only'
     */
    public getAccessTypeForAnyModule = (...modules: string[]): ACCESS_TYPE =>
        this.getAccessTypeForAnyEntitlement(...modules.map(module => moduleToEntitlementMapping[module]));

    /**
     * Returns lowest access level among all module's access levels.
     * @example
     * // check on single module
     * getAccessTypeForAllModules('settingsIndex');
     * // check on multiple modules
     * getAccessTypeForAllModules('settingsIndex', 'billingIndex', 'analyticsIndex');
     * // check on multiple modules using array
     * const modules = ['settingsIndex', 'billingIndex', 'analyticsIndex'];
     * getAccessTypeForAllModules(...modules);
     * @param {...string} modules Route (module) names. The route name is the one
     *   configured in the route configuration object
     * @returns {ACCESS_TYPE} 'allowed', 'not_allowed' or 'read_only'
     */
    public getAccessTypeForAllModules = (...modules: string[]): ACCESS_TYPE =>
        this.getAccessTypeForAllEntitlements(...modules.map(module => moduleToEntitlementMapping[module]));

    /**
     * Must be invoked after activate lifecycle step for it to work properly
     * as it requires knowledge of the current route instruction.
     * Method looks at the currently logged in user's permissions per
     * entitlement to determine the type of access the user has
     * to a particular route by doing a reverse lookup on the
     * entitlementToModuleMapping found in entitlement-mapping.ts.
     * @returns {Permission[]} Permissions of the currently logged in user
     */
    public getModulePermissions = (): Permission[] => {
        let modulePermissions = [];
        if (!this.router.currentInstruction) {
            log.error(
                'Could not get Authorization Module Permission,' +
                    'getModulePermissions must be called on bind or after.',
            );
            return [];
        }
        const modules = this.getCurrentModules(this.router.currentInstruction);
        modules.find(module => {
            if (this.permissions[moduleToEntitlementMapping[module]]) {
                modulePermissions = this.permissions[moduleToEntitlementMapping[module]];
                return true;
            }
            return false;
        });

        return modulePermissions;
    };

    /**
     * Returns permission denied view if the user
     * does not have required entitlement for the route moduleId.
     * @param {RouteConfig} route A route config object.
     * @returns {RouteConfig} The same config object that was passed in
     * swapping moduleId if permission is denied.
     */
    public restrictRoute = (route: RouteConfig): RouteConfig => {
        if (this.getAccessTypeForModule(route.name) === ACCESS_TYPE.NOT_ALLOWED) {
            if (this.isOtherUser) {
                route.moduleId = notAuthorizedSwitchedUserView;
            } else {
                route.moduleId = notAuthorizedView;
            }
        }
        return route;
    };

    /**
     * Returns permission denied view for each route config if the user
     * does not have required entitlement for the route moduleId.
     * @param {RouteConfig[]} routes An array of route config objects.
     * @returns {RouteConfig[]} The same config array that was passed in
     * swapping moduleId if permission is denied for each config.
     */
    public restrictRoutes = (routes: RouteConfig[]): RouteConfig[] => routes.map(route => this.restrictRoute(route));

    /**
     * Recursively traverses through the route instruction to return an array
     * of all the route names in the route instruction hierarchy,
     * this is used to allow permissions to be set only at the parent level if desired.
     * @param {NavigationInstruction} instruction Current navigation instruction
     * @param {string[]} modules Route module names collected during recursion
     * @param {number} [level=0] Recursion level used to prevent infinite recursion
     * @returns {string[]} List of module names in the hierarchy found from child to top most parent
     */
    private getCurrentModules(instruction: NavigationInstruction, modules: string[] = [], level: number = 0): string[] {
        if (level >= MAX_LEVEL_INSTRUCTION_SEARCH) {
            log.error('Exceeded max depth in instruction base fragment search');
        } else if (instruction.parentInstruction) {
            modules.push(instruction.config.name);
            return this.getCurrentModules(instruction.parentInstruction, modules, level + 1);
        }
        modules.push(instruction.config.name);
        return modules;
    }

    /**
     * Returns the permissions for the currently logged in user for each entitlement
     * the permissions are derived from the entitlement's suffix.
     * The mapping of entitlement suffix to permission is as follows:
     * ro: read
     * op: op
     * none: write
     * If the user has admin permission in the session object, they will also get the
     * admin permission.
     * @returns {UserPermissions} Object mapping entitlments to permisson based
     *   on user's current session object.
     */
    private getPermissions(): UserPermissions {
        const entitlements: string[] = this.fullEntitlements;
        const permissions: UserPermissions = {};
        Object.keys(entitlementToModuleMapping).forEach((entitlement: Entitlement) => {
            permissions[entitlement] = [];
            if (entitlements.indexOf(entitlement) >= 0) {
                permissions[entitlement] = [Permission.READ, Permission.WRITE];
            } else if (entitlements.indexOf(`${entitlement}${PermissionSuffix.READ}`) >= 0) {
                permissions[entitlement] = [Permission.READ];
            } else if (entitlements.indexOf(`${entitlement}_${PermissionSuffix.READ}`) >= 0) {
                permissions[entitlement] = [Permission.READ];
            }

            if (entitlements.indexOf(`${entitlement}${PermissionSuffix.OP}`) >= 0) {
                permissions[entitlement].push(Permission.OP);
            } else if (entitlements.indexOf(`${entitlement}_${PermissionSuffix.OP}`) >= 0) {
                permissions[entitlement].push(Permission.OP);
            }

            if (this.isAdmin) {
                permissions[entitlement].push(Permission.ADMIN);
            }
        });

        return permissions;
    }

    /**
     * 'write' or 'ops' or 'admin' -> 'allowed'
     * 'read' -> 'read_only'
     * @example
     * const permissions = [Permission.WRITE, Permission.OP];
     * modulePermissionsToAccessType(permissions);
     * @param {Permission[]} permission An array Permission.
     * @returns {ACCESS_TYPE} 'allowed', 'not_allowed' or 'read_only'
     */
    private modulePermissionsToAccessType(permissions: Permission[]): ACCESS_TYPE {
        let accessType = ACCESS_TYPE.NOT_ALLOWED;
        if (_.isEmpty(permissions)) {
            return accessType;
        }

        if (
            permissions.indexOf(Permission.ADMIN) >= 0 ||
            permissions.indexOf(Permission.WRITE) >= 0 ||
            permissions.indexOf(Permission.OP) >= 0
        ) {
            accessType = ACCESS_TYPE.ALLOWED;
        } else if (permissions.indexOf(Permission.READ) >= 0) {
            accessType = ACCESS_TYPE.READ_ONLY;
        }

        return accessType;
    }

    /**
     * ['allowed', 'not_allowed', 'read_only'] -> 'allowed'
     * @example
     * const accesses = new Set([ACCESS_TYPE.ALLOWED, ACCESS_TYPE.READ_ONLY]);
     * getHighestAccessType(accesses);
     * @param {Set<ACCESS_TYPE>} accesses A Set of ACCESS_TYPE.
     * @returns {ACCESS_TYPE} 'allowed', 'not_allowed' or 'read_only'
     */
    private getHighestAccessType(accesses: Set<ACCESS_TYPE>): ACCESS_TYPE {
        if (_.isEmpty(accesses)) {
            return ACCESS_TYPE.NOT_ALLOWED;
        }

        if (accesses.has(ACCESS_TYPE.ALLOWED)) {
            return ACCESS_TYPE.ALLOWED;
        }

        if (accesses.has(ACCESS_TYPE.READ_ONLY)) {
            return ACCESS_TYPE.READ_ONLY;
        }

        // for invalid permission values return not_allowed
        return ACCESS_TYPE.NOT_ALLOWED;
    }

    /**
     * ['allowed', 'not_allowed', 'read_only'] -> 'not_allowed'
     * ['allowed', 'read_only'] -> 'read_only'
     * @example
     * const accesses = new Set([ACCESS_TYPE.ALLOWED, ACCESS_TYPE.READ_ONLY]);
     * getLowestAccessType(accesses);
     * @param {Set<ACCESS_TYPE>} accesses A Set of ACCESS_TYPE.
     * @returns {ACCESS_TYPE} 'allowed', 'not_allowed' or 'read_only'
     */
    private getLowestAccessType(accesses: Set<ACCESS_TYPE>): ACCESS_TYPE {
        if (_.isEmpty(accesses)) {
            return ACCESS_TYPE.NOT_ALLOWED;
        }

        if (accesses.has(ACCESS_TYPE.NOT_ALLOWED)) {
            return ACCESS_TYPE.NOT_ALLOWED;
        }

        if (accesses.has(ACCESS_TYPE.READ_ONLY)) {
            return ACCESS_TYPE.READ_ONLY;
        }

        if (accesses.has(ACCESS_TYPE.ALLOWED)) {
            return ACCESS_TYPE.ALLOWED;
        }
        // for invalid permission values return not_allowed
        return ACCESS_TYPE.NOT_ALLOWED;
    }

    /**
     * Returns a set of entitlements for the currently logged in user
     * without the suffix, e.g., ro, op.
     * These are based on the keys found in entitlementToModuleMapping from entitlement-mappings.ts.
     * If the session object contains an entitlement that has not been
     * configured in entitlementToModuleMapping, it will be ignored.
     * @param {string[]} entitlements Full list of entitlements from user's session object
     */
    private getSimplifiedEntitlements(entitlements: string[]): Entitlement[] {
        const entitlementString = entitlements.join('-');
        return Object.keys(entitlementToModuleMapping).reduce((aggregate, val) => {
            if (entitlementString.indexOf(val) >= 0) {
                aggregate.push(val);
            }
            return aggregate;
        }, []);
    }
}
