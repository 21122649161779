import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

@autoinject()
export class AnalyticsDashboardIndex {
  public router: Router;

  public routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: 'DashboardList',
      nav: false,
      route: '',
      title: 'Dashboard List',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: 'DashboardSingle',
      nav: false,
      route: ':id',
      title: 'Dashboard Single',
    },
  ];

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }
}