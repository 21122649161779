import {autoinject} from 'aurelia-framework';
import {PlayerOptions} from '../../../../resources/components/videojs-player';

const {protocol, hostname} = window.location;
const baseDomain = `${hostname.split('.').slice(-2)[0]}.com`;

const WIDEVINE_LICENSE_SERVER_URL = `${protocol}//content.${baseDomain}/wv`;
const PLAYREADY_LICENSE_SERVER_URL = `${protocol}//content.${baseDomain}/pr`;

@autoinject
export class PlayerTester {
  public videoUrl: string = 'https://content.uplynk.com/f8fd4904908247eeb6d6fafa9b1a7579.m3u8';
  public playerOptions: PlayerOptions;

  constructor() {
    this.playerOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      liveui: true,
      restoreEl: true,
      playbackRates: [
        0.5,
        1,
        1.5,
        2,
      ],
      plugins: {
        hotkeys: {
          volumeStep: 0.1,
          seekStep: 5,
          enableModifiersForNumbers: false,
        },
      },
    };
  }

  attached() {
    this.loadVideo();
  }

  loadVideo() {
    const extensionMap = {
      m3u8: 'application/x-mpegURL',
      mpd: 'application/dash+xml',
      mp4: 'video/mp4',
    };

    // Extract the file extension from the URL
    const url = new URL(this.videoUrl);
    const path = url.pathname;
    const urlParts = path.split('.');
    const extension = urlParts[urlParts.length - 1];

    // Lookup the MIME type in the extension map
    const type = extensionMap[extension];

    // Alert an error if the extension is not found
    if (!type) {
      // eslint-disable-next-line no-alert
      alert(`Unknown extension: ${extension}`);
    }

    this.playerOptions = {
      ...this.playerOptions,
      sources: [
        {
          src: this.videoUrl,
          type, // Use the MIME type from the extension map
          keySystemOptions: [
            {
              name: 'com.widevine.alpha',
              options: {
                serverURL: WIDEVINE_LICENSE_SERVER_URL,
              },
            },
            {
              name: 'com.microsoft.playready',
              options: {
                serverURL: PLAYREADY_LICENSE_SERVER_URL,
              },
            },
          ],
        },
      ],
    };
  }
}
