import {Type} from 'class-transformer';
import {IsDefined, IsInt, IsNumber, IsOptional, IsString, ValidateNested} from 'class-validator';
import {IHyperion, IMSOSession, IMSOTotalItems} from './mso';

export class ProfilesResponseItem {
    @IsDefined()
    public created: string;

    @IsDefined()
    public desc: string;

    @IsInt()
    public deprecated: number;

    @IsOptional()
    @Type(() => ProfilesRay)
    public dr_ray?: ProfilesRay;

    @IsDefined()
    public id: string;

    @IsOptional()
    public identifier?: string;

    @IsDefined()
    public in_prod: number;

    @IsInt()
    public is_default_live_profile: number;

    @IsInt()
    public is_default_system_profile: number;

    @IsInt()
    public is_default_vod_profile: number;

    @IsInt()
    public is_default_live_rate_card: number;

    @IsInt()
    public is_default_vod_rate_card: number;

    @IsDefined()
    public lastmod: string;

    @IsInt()
    public live_slicers: number;

    @IsDefined()
    public lmsig: string;

    @IsOptional()
    public parent?: string;

    @IsOptional()
    public public?: number;

    @IsDefined()
    public rate_card_id: string;

    @IsOptional()
    @Type(() => ProfilesRay)
    public ray_0?: ProfilesRay;

    @IsOptional()
    @Type(() => ProfilesRay)
    public ray_1?: ProfilesRay;

    @IsOptional()
    @Type(() => ProfilesRay)
    public ray_2?: ProfilesRay;

    @IsOptional()
    @Type(() => ProfilesRay)
    public ray_3?: ProfilesRay;

    @IsOptional()
    @Type(() => ProfilesRay)
    public ray_4?: ProfilesRay;

    @IsOptional()
    @Type(() => ProfilesRay)
    public ray_5?: ProfilesRay;

    @IsOptional()
    @Type(() => ProfilesRay)
    public ray_6?: ProfilesRay;

    @IsOptional()
    public requires_brokers?: number;

    @IsOptional()
    public slicedur?: number;

    @IsOptional()
    public suppressARayExceptOnIOS?: number;

    @IsOptional()
    @Type(() => ProfilesRay)
    public top_ray?: ProfilesRay;

    @IsInt()
    public top_ray_audio_rate: number;

    @IsInt()
    public top_ray_bframes_max: number;

    @IsInt()
    public top_ray_bit_rate: number;

    @IsNumber()
    public top_ray_fps: number;

    @IsString()
    public top_ray_gpu_flag: string;

    @IsInt()
    public top_ray_height: number;

    @IsInt()
    public top_ray_passes: number;

    @IsInt()
    public top_ray_width: number;

    @IsOptional()
    public version?: number;

    @IsInt()
    public vod_assets: number;
}

export class ProfilesRay {
    @IsOptional()
    public profile: string;

    @IsOptional()
    public aoKbps: number;

    @IsOptional()
    public skipF: number;

    @IsOptional()
    public fps: number;

    @IsOptional()
    public vKbps: number;

    @IsOptional()
    public aKbps: number;

    @IsOptional()
    public maxW: number;

    @IsOptional()
    public aMaxChannels: number;

    @IsOptional()
    public isHD: false;

    @IsOptional()
    public max_bframes?: number;

    @IsOptional()
    public maxH: number;

    @IsOptional()
    public nvenc?: string;

    @IsOptional()
    public preset: string;

    @IsOptional()
    public vPasses: number;

    @IsOptional()
    public encryptionKey: number;
}

export class ProfilesResponseItems implements IHyperion, IMSOSession, IMSOTotalItems {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsString()
    public cms_session_id_fingerprint: string;

    @ValidateNested()
    @Type(() => ProfilesResponseItem)
    public items: ProfilesResponseItem[];

    @IsInt()
    public total_items: number;
}
