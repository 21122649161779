import {bindable, computedFrom} from 'aurelia-framework';

export class EmptyState {
  @bindable public padding = '';
  @bindable public height = '';
  @bindable public img = '';
  @bindable public imgAlt = '';

  @computedFrom('padding')
  get styleObject() {
    return {
      '--background': 'var(--lynk-color-black-a25)',
      '--color': 'var(--lynk-color-neutral-800)',
      '--text-align': 'center',
      '--height': this.height ? this.height : 'auto',
      '--padding': this.padding ? this.padding : 'var(--lynk-spacing-3x-large)',
      '--border-radius': 'var(--lynk-border-radius-large)',
      'font-size': 'var(--lynk-font-size-medium)',
    };
  }
}
