import {IOption, INestedOption} from 'interfaces/options';
import {ISessionInfo} from 'interfaces/session-info';

export const API_SCOPE_OPTIONS: INestedOption[] = [
  {
    help: '',
    text: 'Acuity Ops',
    value: 'video.ops',
    visible: (sessionInfo: ISessionInfo) => sessionInfo.perms?.includes('admin'),
    options: [
      {
        help: '',
        value: 'video.ops.acuity:admin',
        text: 'Admin',
      },
    ],
  },
  {
    help: 'Full access for Ads APIs.',
    text: 'Ads',
    value: 'video.services.ads',
    options: [
      {
        help: 'Read access for Ads APIs.',
        value: 'video.services.ads:read',
        text: 'Read',
      },
      {
        help: 'Write access for Ads APIs.',
        syncValue: 'video.services.ads:read',
        value: 'video.services.ads:write',
        text: 'Write',
      },
    ],
  },
  {
    help: 'Full access to assets and content library APIs.',
    text: 'Assets',
    value: 'video.services.assets',
    options: [
      {
        help: 'Read access for assets APIs.',
        value: 'video.services.assets:read',
        text: 'Read',
      },
      {
        help: 'Write access for assets APIs.',
        syncValue: 'video.services.assets:read',
        value: 'video.services.assets:write',
        text: 'Write',
      },
      {
        help: 'Read access for content library APIs.',
        value: 'video.services.assets.libraries:read',
        text: 'Libraries - Read',
      },
      {
        help: 'Write access for content library APIs.',
        syncValue: 'video.services.assets.libraries:read',
        value: 'video.services.assets.libraries:write',
        text: 'Libraries - Write',
      },
      {
        help: 'User management for content library APIs.',
        value: 'video.services.assets.libraries.users:admin',
        text: 'Libraries - Shared Users Admin',
      },
    ],
  },
  {
    help: 'Full access to linear channels APIs.',
    text: 'Channels',
    value: 'video.services.channels',
    options: [
      {
        help: 'Read access for linear channels APIs.',
        value: 'video.services.channels:read',
        text: 'Read',
      },
      {
        help: 'Write access for linear channels APIs.',
        syncValue: 'video.services.channels:read',
        value: 'video.services.channels:write',
        text: 'Write',
      },
      {
        help: 'Read access for managing blackouts on linear channels APIs.',
        value: 'video.services.channels.blackout:read',
        text: 'Blackouts - Read',
      },
      {
        help: 'Write access for managing blackouts on linear channels APIs.',
        syncValue: 'video.services.channels.blackout:read',
        value: 'video.services.channels.blackout:write',
        text: 'Blackouts - Write',
      },
      {
        help: 'Read access for Channel Scheduler APIs.',
        value: 'video.services.channels.schedule:read',
        text: 'Schedules - Read',
      },
      {
        help: 'Write access for Channel Scheduler APIs.',
        syncValue: 'video.services.channels.schedule:read',
        value: 'video.services.channels.schedule:write',
        text: 'Schedules - Write',
      },
    ],
  },
  {
    help: 'Full access to clipping APIs.',
    text: 'Clipping',
    value: 'video.services.clipping',
    options: [
      {
        help: 'Read access for clipping APIs.',
        value: 'video.services.clipping:read',
        text: 'Read',
      },
      {
        help: 'Write access for clipping APIs.',
        syncValue: 'video.services.clipping:read',
        value: 'video.services.clipping:write',
        text: 'Write',
      },
    ],
  },
  {
    help: 'Full access to IDS Identity Proxy APIs.',
    text: 'IDS Ops',
    value: 'video.identity',
    visible: (sessionInfo: ISessionInfo) => sessionInfo.perms?.includes('identity'),
    options: [
      {
        help: 'Read access for integrations APIs.',
        value: 'video.identity.integrations:read',
        text: 'Integrations - Read',
      },
      {
        help: 'Write access for integrations APIs.',
        syncValue: 'video.identity.integrations:read',
        value: 'video.identity.integrations:write',
        text: 'Integrations - Write',
      },
      {
        help: 'Read access for users APIs.',
        value: 'video.identity.users:read',
        text: 'Users - Read',
      },
      {
        help: 'Write access for users APIs.',
        syncValue: 'video.identity.users:read',
        value: 'video.identity.users:write',
        text: 'Users - Write',
      },
      {
        help: 'Read access for tenants APIs.',
        value: 'video.identity.tenants:read',
        text: 'Tenants - Read',
      },
      {
        help: 'Write access for tenants APIs.',
        syncValue: 'video.identity.tenants:read',
        value: 'video.identity.tenants:write',
        text: 'Tenants - Write',
      },
    ],
  },
  {
    help: 'Full access for ingest APIs. (on-premise & cloud-based)',
    text: 'Ingest',
    value: 'video.services.ingest',
    options: [
      {
        help: 'Read access to APIs responsible for controlling slicer APIs. (on-premise & cloud-based)',
        value: 'video.services.ingest.slicer:read',
        text: 'Slicer - Read',
      },
      {
        help: 'Write access to APIs responsible for controlling slicer APIs. (on-premise & cloud-based)',
        syncValue: 'video.services.ingest.slicer:read',
        value: 'video.services.ingest.slicer:write',
        text: 'Slicer - Write',
      },
      {
        help: 'Used for token based authentication in slicer configuration files. (on-premise)',
        value: 'video.services.ingest.slicer:ops',
        text: 'Slicer - Operator',
      },
      {
        help: 'Read access for linear slicer APIs. (cloud-based)',
        value: 'video.services.ingest.slicer.cloudslicer.live:read',
        text: 'Cloud Slicers Live - Read',
      },
      {
        help: 'Write access for linear slicer APIs. (cloud-based)',
        syncValue: 'video.services.ingest.slicer.cloudslicer.live:read',
        value: 'video.services.ingest.slicer.cloudslicer.live:write',
        text: 'Cloud Slicers Live - Write',
      },
      {
        help: 'Read access for VOD slicer APIs. (cloud-based)',
        value: 'video.services.ingest.slicer.cloudslicer.vod:read',
        text: 'Cloud Slicers VOD - Read',
      },
      {
        help: 'Write access for VOD slicer APIs. (cloud-based)',
        syncValue: 'video.services.ingest.slicer.cloudslicer.vod:read',
        value: 'video.services.ingest.slicer.cloudslicer.vod:write',
        text: 'Cloud Slicers VOD - Write',
      },
      {
        help: 'Read access to APIs responsible for controlling Slicer Failover.',
        value: 'video.services.ingest.failover-groups:read',
        text: 'Failover Groups - Read',
      },
      {
        help: 'Write access to APIs responsible for controlling Slicer Failover.',
        syncValue: 'video.services.ingest.failover-groups:read',
        value: 'video.services.ingest.failover-groups:write',
        text: 'Failover Groups - Write',
      },
      {
        help: '',
        visible: (sessionInfo: ISessionInfo) => sessionInfo.flags?.beta?.includes('byoe'),
        value: 'video.services.ingest.external.live:read',
        text: 'External Live - Read',
      },
      {
        help: '',
        visible: (sessionInfo: ISessionInfo) => sessionInfo.flags?.beta?.includes('byoe'),
        syncValue: 'video.services.ingest.external.live:read',
        value: 'video.services.ingest.external.live:write',
        text: 'External Live - Write ',
      },
      {
        help: '',
        visible: (sessionInfo: ISessionInfo) => sessionInfo.flags?.beta?.includes('byoe'),
        value: 'video.services.ingest.external.vod:read',
        text: 'External VOD - Read - Moo',
      },
      {
        help: '',
        visible: (sessionInfo: ISessionInfo) => sessionInfo.flags?.beta?.includes('byoe'),
        syncValue: 'video.services.ingest.external.vod:read',
        value: 'video.services.ingest.external.vod:write',
        text: 'External VOD - Write',
      },
    ],
  },
  {
    help: 'Full access to Linear Playlists APIs',
    text: 'Linear Playlists',
    value: 'video.services.linear-playlists',
    options: [
      {
        help: 'Read access to Linear Playlists APIs',
        value: 'video.services.linear-playlists:read',
        text: 'Read',
      },
      {
        help: 'Write access to Linear Playlists APIs',
        syncValue: 'video.services.linear-playlists:read',
        value: 'video.services.linear-playlists:write',
        text: 'Write',
      },
      {
        help: 'Smartstart API access. (Requires a specialized version of the Virtual Linear Playlist)',
        value: 'video.services.linear-playlists.smartstart:write',
        text: 'Smartstart',
      },
    ],
  },
  {
    help: 'Full access to Live Events APIs',
    text: 'Live Events',
    value: 'video.services.live-events',
    options: [
      {
        help: 'Read access to Live Events APIs',
        value: 'video.services.live-events:read',
        text: 'Read',
      },
      {
        help: 'Write access to Live Events APIs',
        syncValue: 'video.services.live-events:read',
        value: 'video.services.live-events:write',
        text: 'Write',
      },
      {
        help: 'Access to Live Events Operator APIs',
        value: 'video.services.live-events:ops',
        text: 'Operator',
      },
    ],
  },
  {
    help: 'Full access to owner management APIs',
    text: 'Owners',
    value: 'video.services.owners',
    visible: (sessionInfo: ISessionInfo) => sessionInfo.perms?.includes('admin'),
    options: [
      {
        help: 'Allows access to Integration Key management APIs',
        value: 'video.services.owners.integration-keys:admin',
        text: 'Integration Keys',
      },
      {
        help: 'Allows access to Sub Owner management APIs',
        value: 'video.services.owners.sub-owners:admin',
        text: 'Sub Owners',
      },
    ],
  },
  {
    help: '',
    text: 'Tokens',
    value: 'video.services.tokens',
    visible: (sessionInfo: ISessionInfo) => sessionInfo.perms?.includes('admin'),
    options: [
      {
        help: '',
        value: 'video.services.token:read',
        text: 'Tokens - Read',
      },
    ],
  },
  {
    help: 'Full Access to Workspace APIs',
    text: 'Workspace',
    value: 'video.services.workspace',
    visible: (sessionInfo: ISessionInfo) => sessionInfo.flags?.beta?.includes('jwt-api-access'),
    options: [
      {
        help: 'Read access to Workspace API Key APIs',
        value: 'video.services.workspace.api-key:read',
        text: 'Scoped API Keys - Read',
      },
      {
        help: 'Update access to Workspace API Key APIs',
        value: 'video.services.workspace.api-key:update',
        text: 'Scoped API Keys - Update',
      },
      {
        help: 'Create access to Workspace API Key APIs',
        value: 'video.services.workspace.api-key:admin',
        text: 'Scoped API Keys - Admin',
      },
      // {
      //   value: 'video.services.workspace.scopes:read',
      //   text: 'Owner API Scopes',
      // },
    ],
  },
];

export function filterScope(scope: INestedOption, sessionInfo: ISessionInfo) {
  const {visible = () => true, options} = scope;

  if (!visible(sessionInfo)) {
    return null;
  }

  if (!options || options.length === 0) {
    return scope;
  }

  scope.options = options.filter(_option => filterScope(_option, sessionInfo));

  if (!scope.options || scope.options.length === 0) {
    return null;
  }

  return scope;
}

export function getApiScopes(sessionInfo: ISessionInfo): INestedOption[] {
  return API_SCOPE_OPTIONS.reduce((options, option) => {
    const scope = filterScope(option, sessionInfo);

    if (scope) {
      options.push(scope);
    }

    return options;
  }, []);
}

export function asOptions(groups: INestedOption[]): IOption[] {
  return groups.reduce((col, g) => col.concat(g.options.map(o => ({text: `${g.text} ${o.text}`, value: o.value}))), []);
}
