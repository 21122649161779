import {PLATFORM} from 'aurelia-pal';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject} from 'aurelia-framework';

import {SharedNav} from '@bindable-ui/bindable';

import {SessionService} from 'services/session';
import {BetaMode} from 'services/beta-mode';
import {AdServerRoute} from './ad-servers/models/config-route';

const DRM_CONFIG_ROUTE_NAME = 'drm-policy-config';
const APPLE_FAIRPLAY_STREAMING = 'apple-fairplay-streaming';

export const DRM_ROUTES = [
  DRM_CONFIG_ROUTE_NAME,
  APPLE_FAIRPLAY_STREAMING,
];

export const MULTI_CDN_SETTINGS_ROUTE = 'multi-cdn-settings';
export const PUBLISHING_ROUTE = 'publishing';
export const WEBHOOK_ROUTE = 'webhooks';

@autoinject()
export class SettingsIndex {
  public routes;
  public router;
  public routeSubscription;

  constructor(
    public session: SessionService,
    public betaMode: BetaMode,
    public sharedNav: SharedNav,
    public eventAggregator: EventAggregator,
  ) {
    this.sharedNav = sharedNav;
    this.eventAggregator = eventAggregator;

    this.routes = [
      {
        route: [
          '',
          'user-settings',
        ],
        name: 'accountSettings',
        moduleId: PLATFORM.moduleName('./user-settings/index'),
        nav: true,
        title: 'User Settings',
      },
      {
        route: ['account-access'],
        name: 'accountAccess',
        moduleId: PLATFORM.moduleName('./account-access/index'),
        nav: true,
        title: 'Account Access',
      },
      {
        route: 'integration-keys',
        name: 'playbackTokens',
        moduleId: PLATFORM.moduleName('./integration-keys/index'),
        nav: true,
        title: 'Integration Keys',
      },
      {
        route: 'api-keys',
        name: 'apiKeys',
        moduleId: PLATFORM.moduleName('./api-keys/index'),
        nav: betaMode.hasScope('jwt-api-access'),
        // nav: true,
        title: 'Scoped API Keys',
        badge: 'Beta',
      },
      {
        route: 'parameter-expansion',
        name: 'paramterExpansion',
        moduleId: PLATFORM.moduleName('./parameter-expansion/index'),
        nav: true,
        title: 'Parameter Expansion',
      },
      {
        route: AdServerRoute.routeFragment,
        name: AdServerRoute.routeName,
        moduleId: PLATFORM.moduleName('./ad-servers/index'),
        nav: true,
        title: `${AdServerRoute.routeTitle}s`,
      },
      {
        route: 'prebid-server',
        name: 'prebidServer',
        moduleId: PLATFORM.moduleName('./prebid-server/route'),
        nav: true,
        title: 'Prebid Server',
      },
      {
        route: 'ad-server-debug',
        name: 'adServerDebug',
        moduleId: PLATFORM.moduleName('./ad-server-debug/index'),
        nav: true,
        title: 'Ad Server Debug',
      },
      {
        route: 'ad-server-debug-2',
        name: 'adServerDebug-2',
        moduleId: PLATFORM.moduleName('./ad-server-debug-2/index'),
        // nav: betaMode.hasScope('ad-debug'),
        nav: false,
        title: 'Ad Server Debug 2',
      },
      {
        route: DRM_CONFIG_ROUTE_NAME,
        name: 'drmPolicyConfig',
        moduleId: PLATFORM.moduleName('./drm-policy-config/index'),
        nav: true,
        title: 'DRM Policy Config',
      },
      {
        route: APPLE_FAIRPLAY_STREAMING,
        name: 'appleFairPlayStreaming',
        moduleId: PLATFORM.moduleName('./apple-fairplay-streaming/index'),
        nav: true,
        title: 'Apple FairPlay Streaming',
      },
      {
        route: 'playback-restrictions',
        name: 'playbackRestrictions',
        moduleId: PLATFORM.moduleName('./playback-restrictions/index'),
        nav: true,
        title: 'Playback Restrictions',
      },
      {
        route: 'test-players',
        name: 'testPlayers',
        moduleId: PLATFORM.moduleName('./test-players/index'),
        nav: true,
        title: 'Test Player Restrictions',
      },
      {
        route: 'slate',
        name: 'slate',
        moduleId: PLATFORM.moduleName('./slate/route'),
        nav: true,
        title: 'Slate',
      },
      {
        route: 'log-pushing',
        name: 'logPushing',
        moduleId: PLATFORM.moduleName('./log-pushing/index'),
        nav: true,
        title: 'Log Pushing',
      },
      {
        route: 'error-reporting',
        name: 'errorReporting',
        moduleId: PLATFORM.moduleName('./error-reporting/index'),
        nav: true,
        title: 'Error Reporting',
      },
      {
        route: 'clipping',
        name: 'clipping',
        moduleId: PLATFORM.moduleName('./clipping/route'),
        nav: true,
        title: 'Clipping',
      },
      {
        route: 'live-events',
        name: 'liveEvents',
        moduleId: PLATFORM.moduleName('./live-events/index'),
        nav: true,
        title: 'Live Events',
      },
      {
        route: 'publishing',
        name: 'publishing',
        moduleId: PLATFORM.moduleName('./publishing/index'),
        nav: true,
        title: 'Publishing',
      },
      {
        route: 'multi-cdn-settings',
        name: 'multiCdnSettings',
        moduleId: PLATFORM.moduleName('./multi-cdn-settings/route'),
        nav: true,
        title: 'Multi CDN Settings',
      },
      {
        route: 'vod-uploader-templates',
        name: 'vodUploaderTemplates',
        moduleId: PLATFORM.moduleName('./vod-uploader-templates/index'),
        nav: true,
        title: 'VOD Uploader Templates',
      },
      {
        route: 'webhooks',
        name: 'webhooks',
        moduleId: PLATFORM.moduleName('./webhooks/profiles/index'),
        nav: true,
        title: 'Webhooks',
      },
      {
        route: 'unreleased',
        name: 'unreleased',
        moduleId: PLATFORM.moduleName('./unreleased/index'),
        nav: true,
        title: 'Unreleased',
      },
    ];
  }

  configureRouter(config, router) {
    this.router = router;

    // could have done: add if present, but removing if absent lets us keep the page order.
    if (!this.session.isFairplayAndDashEnabled()) {
      this.routes = this.routes.filter(r => !DRM_ROUTES.includes(r.route));
    }

    if (!this.session.isMultiCdnEnabled()) {
      this.routes = this.routes.filter(r => !MULTI_CDN_SETTINGS_ROUTE.includes(r.route));
    }

    if (!this.session.isSyndicationEnabled()) {
      this.routes = this.routes.filter(r => !PUBLISHING_ROUTE.includes(r.route));
    }

    if (!this.session.hasWebhookAccess) {
      this.routes = this.routes.filter(r => !WEBHOOK_ROUTE.includes(r.route));
    }

    // fallback to account settings page.
    config.mapUnknownRoutes({redirect: this.routes[0].route[1]});
    config.map(this.routes);
  }

  attached() {
    this.sharedNav.initMainNav(this.router);
    this.setNav(this.router.currentInstruction);
    // eslint-disable-next-line max-len
    this.routeSubscription = this.eventAggregator.subscribe('router:navigation:success', ({instruction}) =>
      this.setNav(instruction),
    );
  }

  setNav(instruction) {
    const {fragment} = instruction;
    const fragments = fragment.replace('/settings/', '').split('/');
    const index = _.findIndex(
      this.routes.filter(r => r.nav),
      ({route}) => {
        const routes = Array.isArray(route) ? route : [route];
        return routes.some(r => fragments.includes(r));
      },
    );
    this.sharedNav.setActive(0, index);
  }

  detached() {
    this.routeSubscription.dispose();
  }
}
