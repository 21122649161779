import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

import {SharedNav} from '@bindable-ui/bindable';

@autoinject()
export class RulesIndex {
    public router: Router;
    public routes = [
        {
            moduleId: PLATFORM.moduleName('./list/index'),
            name: 'rulesList',
            nav: false,
            route: '',
            title: 'Rules',
        },
        {
            moduleId: PLATFORM.moduleName('./single/index'),
            name: 'rulesSingle',
            nav: false,
            route: ':id',
            title: 'Rule',
        },
    ];

    constructor(public sharedNav: SharedNav) {}

    public attached() {
        this.sharedNav.setNextText('Rules List');
    }

    public configureRouter(config, router) {
        this.router = router;
        config.map(this.routes);
    }
}
