import {Exclude, Expose, Type} from 'class-transformer';
import {IsBoolean, IsNumber, IsOptional, IsString, MaxLength, ValidateNested} from 'class-validator';
import {Rule} from '../../rules/models/models';

export interface ChannelFilterParams {
  page_size?: number;
  since?: number;
  page?: number;
  include_deleted?: boolean;
  search?: string;
  order?: string;
  minimal?: boolean;
}

export interface ChannelsMeta {
  total?: number;
  showing?: number;
  limit?: number;
  nextToken?: number;
  hasMore?: boolean;
}

export class SimpleChannel {
  @IsString()
  @IsOptional()
  public source_desc?: string;

  @IsString()
  public desc: string;

  @IsString()
  public id: string;

  @IsString()
  @IsOptional()
  public slicer_id: string;

  @IsString()
  @IsOptional()
  public thumb_url?: string;

  @IsString()
  @IsOptional()
  public test_player_url?: string;

  @IsString()
  @IsOptional()
  public status_type?: string;

  @IsString()
  public external_id: string;

  @IsBoolean()
  public selected: boolean = false;

  @IsString()
  @IsOptional()
  public _status?: string;

  @IsString()
  @IsOptional()
  public _class?: string;

  @IsString()
  @IsOptional()
  public deleted?: string;

  @IsNumber()
  @IsOptional()
  public use_chsched?: number;

  @IsBoolean()
  @IsOptional()
  public fast_channel?: boolean = false;

  @IsString()
  @IsOptional()
  public descPill?: string;

  @IsString()
  @IsOptional()
  public descPillColor?: string;

  @IsString()
  @IsOptional()
  public descIcon?: string;

  @IsString()
  @IsOptional()
  public descIconSize?: string;

  @IsString()
  @IsOptional()
  public descIconColor?: string;

  @IsString()
  @IsOptional()
  public source_type?: string;
}

export class ChannelsListResponse {
  @ValidateNested()
  @Type(() => SimpleChannel)
  public items?: SimpleChannel[];

  @IsNumber()
  @IsOptional()
  public now?: number;

  @IsNumber()
  @IsOptional()
  public total_items?: number;
}

export class ChannelItem {
  @IsString()
  @IsOptional()
  public title: string;

  @IsString()
  public poster: string;

  @IsString()
  @IsOptional()
  public asset?: string;

  @IsNumber()
  public start: number;

  @IsNumber()
  public stop: number;

  @IsNumber()
  public dur: number;

  @IsBoolean()
  @IsOptional()
  public live?: boolean;
}

export class ChannelItems {
  @ValidateNested()
  @Type(() => ChannelItem)
  public assets: ChannelItem[];

  @IsNumber()
  @IsOptional()
  public start: number;

  @IsNumber()
  @IsOptional()
  public stop: number;
}

export class Blackout {
  @IsString()
  @IsOptional()
  public deleted?: string;

  @IsString()
  public blackout_id: string;

  @IsString()
  public desc: string;

  @IsString()
  @IsOptional()
  public external_id?: string;

  @IsString()
  @IsOptional()
  public id?: string;

  @IsString({each: true})
  @IsOptional()
  public rules?: string[];

  @IsString()
  @IsOptional()
  public owner?: string;

  @ValidateNested()
  @Type(() => Rule)
  @Exclude({toPlainOnly: true})
  public '@included'?: Rule[];

  // seems like since this is initialized we need to apply @Exclude tag to skip this field.
  @Exclude()
  public checkbox?: boolean = false;

  @IsBoolean()
  @IsOptional()
  public selected?: boolean;
}

export class ChannelBlackoutResponse {
  @ValidateNested()
  @Type(() => Blackout)
  public items: Blackout[];

  @IsNumber()
  public total_items: number;
}

export class ChannelMetadata {
  @Expose({name: 'hidden-columns'})
  @MaxLength(20, {each: true})
  public hiddenColumns: string[] = [];
}
