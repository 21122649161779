/* eslint-disable max-classes-per-file */
import {MSOHostingLocationResponseItem} from '../../models/hostingloc';
import {MSOSlicerChangeLog, MSOSlicerClassification} from '../../models/msoslicer';
import {MSOStreamProtocolResponseItem, MSOStreamProtocolSlicerInputs} from '../../models/streamproto';
import {Config as ConfigEncodingProfile} from './encoding-profile/models';
import {Config as ConfigIngestPoint} from './ingest-point/models';
import {Config as ConfigLiveSlicer} from './slicer-configuration/models';
import {Config as ConfigPlugin} from './plugin/models';
import {Config as ConfigSlicerVersion} from './slicer-version/models';

export class Config {
  public errors = new ConfigErrors();
  public info = new ConfigInfo();
  public input = new ConfigInput();
  public options = new ConfigOptions();
  public pristine = new ConfigPristine();
  public state = new ConfigState();
  public styles = new ConfigStyles();
}

class ConfigErrors {
  public classification?: string;
  public encodingProfile?: string;
  public notes?: string;
  public ingestPointRequired?: boolean;
  public ingestPointStatus?: string;
  public slicerConfiguration?: string;
  public slicerVersion?: string;
  public source?: string;
}

export enum ConfigMessages {
  INGEST_POINT_CREATE_ERROR = 'Ingest Point creation failed',
  INGEST_POINT_CREATE_SUCCESS = 'Creating Ingest Point',
  INGEST_POINT_READ_ERROR = 'Failed to load Ingest Points',
  INGEST_POINT_INVALID_NAME = 'Name is required',
  INGEST_POINT_INVALID_PROTOCOL = 'Protocol is required',
  INGEST_POINT_INVALID_REGION = 'Region is required',
  INGEST_POINT_INVALID_STATUS = 'Ingest Point is currently not available for use',
  PLUGIN_READ_ERROR = 'Failed to load Slicer Plugins',
  PROFILE_DEFAULT_ERROR = 'Failed to load the default Encoding Profile',
  SLICER_CREATE_ERROR = 'Failed to create Hosted Slicer',
  SLICER_CREATE_SUCCESS = 'Creating Hosted Slicer. This may take up to six minutes to complete.',
  SLICER_DELETE_ERROR = 'Failed to delete Hosted Slicer',
  SLICER_DELETE_SUCCESS = 'Hosted Slicer deleted successfully',
  SLICER_READ_ERROR = 'Failed to load Hosted Slicer data',
  SLICER_RESTART_ERROR = 'Failed to restart Hosted Slicer',
  SLICER_RESTART_SUCCESS = 'Restarting Hosted Slicer',
  SLICER_UPDATE_ERROR = 'Failed to update Hosted Slicer',
  SLICER_UPDATE_SUCCESS = 'Updating Hosted Slicer',
  SLICER_VERSION_READ_ERROR = 'Failed to load Slicer Version',
  SLICER_VERSION_INVALID_REGION = 'Slicer Version region must match the selected Ingest Point region',
  SOURCE_REQUIRED = 'Source IP address is required for the selected Ingest Point protocol',
}

class ConfigInfo {
  public classification = [
    'Classification ensures correct Slicer operability and billing.',
  ];

  public encodingProfile = [
    'The encoding profile determines how this Slicer will encode audio and video.',
  ];

  public notes = ['A description or supplemental notes for this Hosted Slicer.'];

  public plugin = 'Plugins are available for this account. Select customize to choose the desired plugin.';

  public protocol = [
    'Select the protocol for communication between an encoder and this Hosted Slicer.',
    'The set of available ingest points will be restricted to the selected protocol.',
  ];

  public region = [
    'Select the region where your Hosted Slicer will be hosted. We recommend that you create redundant Hosted Slicers close to your encoder.',
    'The set of available ingest points will be restricted to the selected location.',
  ];

  public ingestPoint = [
    'The ingest point identifies the URL and ports through which a Hosted Slicer will ingest your audio/video feed.',
  ];

  public ingestPointRequired = [
    'No ingest point selected. Press the "Select Ingest Point" button to create or select an existing ingest point.',
  ];

  public slicerConfiguration = ["Define this Hosted Slicer's configuration settings."];
  public slicerVersion = [
    'The version of the Live Slicer software that will be applied to this Hosted Slicer. We recommend using the latest version unless you would like to match previously deployed Hosted Slicers.',
  ];

  public slicerVersionIngestPoint = 'An ingest point must be selected before a slicer version may be selected.';
  public slicerVersionUndefined = 'No slicer version selected. Press the "Select Version" button to select one.';
  public source = [
    'This is the source IP address that will generate the stream received by the slicer. When the slicer is deleted, the security rule for this slicer will be deleted as well.',
  ];
}

class ConfigInput {
  public classification: MSOSlicerClassification;
  public encodingProfile = new ConfigEncodingProfile();
  public ingestPoint = new ConfigIngestPoint();
  public managed: boolean;
  public notes: string = '';
  public plugin = new ConfigPlugin();
  public protocol: string;
  public region: string;
  public slicerConfiguration = new ConfigLiveSlicer();
  public slicerId: string;
  public slicerVersion = new ConfigSlicerVersion();
  public source: string = '0.0.0.0';
}

class ConfigPristine {
  public accountName: string;
  public apiUrl: string;
  public audio: string;
  public changeLog: MSOSlicerChangeLog[];
  public classification: string | MSOSlicerClassification;
  public configuration: string;
  public dateCreated: string;
  public dateDeleted: string;
  public dateModified: string;
  public dockerTag: string;
  public domain: string;
  public ec2Machine: string;
  public encoder: string;
  public quality: string;
  public id: string;
  public imageId: string;
  public ingestPointId: string;
  public instanceId: string;
  public managed: boolean;
  public notes: string;
  public profileId: string;
  public protocol: string;
  public resolution: string;
  public serverRegion: string;
  public serverRegionId: string;
  public slicerId: string;
  public slicerPlugin: string;
  public slicerPluginVersion: string;
  public status: string;
  public get statusColor(): string {
    switch (this.status) {
      case 'RUNNING':
        return 'success';
      case 'FAILED_STOPPED':
      case 'FAILED':
      case 'STOPPED':
        return 'danger';
      default:
        return 'neutral';
    }
  }

  public get statusPulseSpeed(): boolean {
    switch (this.status) {
      case 'RUNNING':
        return true;
      default:
        return false;
    }
  }

  public streamingKey: string;
  public streamingUrl: string;
  public streamingUrlSecure: string;
  public source: string;
  public version: string;
}

export class ConfigOptions {
  public classification = [
    {
      text: 'Demo',
      value: MSOSlicerClassification.DEMO,
    },
    {
      text: 'Development',
      value: MSOSlicerClassification.DEVELOPMENT,
    },
    {
      text: 'Production',
      value: MSOSlicerClassification.PRODUCTION,
    },
    {
      text: 'Proof of Concept',
      value: MSOSlicerClassification.PROOF_OF_CONCEPT,
    },
  ];

  public profile = [
    {
      text: 'Default Profile',
      value: undefined,
    },
  ];

  public protocol: MSOStreamProtocolResponseItem[] = [];
  public region: MSOHostingLocationResponseItem[] = [];
  public ingestPoint = [
    {
      text: 'None',
      value: undefined,
    },
  ];

  public protocolSlicerInputs(protocolId: string, parameter: string) {
    const options = this.protocol.filter(item => item.id === protocolId);
    const inputs = options.length === 1 ? options[0].slicer_inputs : [];
    return inputs.indexOf(parameter) !== -1;
  }

  public protocolRequiresSource(protocolId: string) {
    if (!protocolId) return false;
    return this.protocolSlicerInputs(protocolId, MSOStreamProtocolSlicerInputs.SOURCE_IP);
  }
}

class ConfigState {
  public canSetClassification: boolean;
  public canSetProtocol: boolean;
  public canSetRegion: boolean;
  public canViewAdvanced: boolean;
  public error: string;
  public isCustomizing: boolean;
  public isDeleting: boolean;
  public isLoading: boolean;
  public isProcessing: boolean;
  public isRestarting: boolean;
}

class ConfigStyles {
  public gridAutoSizing: string = 'auto-fit';
  public gridMinSize: string = '40rem';
  public mainStackSpacing: string = 'var(--s0)';
  public subStackSpacing: string = 'var(--s-5)';
  public subLabelStyle: string = 'min-width: 9rem;';
}

export enum CustomizeModal {
  ENCODING_PROFILE = 'encodingProfile',
  INGEST_POINT = 'ingestPoint',
  PLUGIN = 'plugin',
  SLICER_CONFIGURATION = 'slicerConfiguration',
  SLICER_VERSION = 'slicerVersion',
}

export class HealthMonitor {
  public status: string;
  public lastms: number;
  public packsent: number;
  public packloss: number;
  public packretrans: number;
  public upbytes: number;
  public downbytes: number;
  public viewers: number;
  public buffer: number;
  public jitter: number;
  public maxKeepAway: number;
  public get statusColor(): string {
    let colorVar: string = 'var(--c_secondaryMain)';
    const packetsRepaired: number = this.packretrans;
    const packetsUnrepaired: number = this.packloss - this.packretrans;
    let packetsRepairedRate: number = 0;
    let packetsUnrepairedRate: number = 0;

    switch (this.status) {
      case 'Online':
        if (packetsRepaired > 0 && this.packloss > 0) {
          packetsRepairedRate = (packetsRepaired / this.packloss) * 100;
        }
        if (packetsUnrepaired > 0 && this.packloss > 0) {
          packetsUnrepairedRate = (packetsUnrepaired / this.packsent) * 100;
        }
        if (packetsUnrepairedRate > 0.1) {
          colorVar = 'var(--c_dangerMain)'; // red
        } else if (packetsRepairedRate > 2) {
          colorVar = 'var(--c_subTwoLight)'; // yellow
        } else {
          colorVar = 'var(--c_secondaryMain)'; // green
        }
        return colorVar;
      case 'Waiting for data':
        return 'neutral';
      case 'No Stream':
        return 'warning';
      default:
        return 'primary';
    }
  }

  public get statusPulseSpeed(): boolean {
    switch (this.status) {
      case 'No Stream':
        return true;
      default:
        return false;
    }
  }

  public appendMilliseconds(value): string {
    if (!value) {
      return '';
    }
    return `${value} ms`;
  }

  public numberToUnits(value): string {
    if (!value) {
      return '';
    }
    let units = '';
    let numberValue = value;
    if (value > 1000000000) {
      numberValue = value / 1000000000;
      units = 'G';
    } else if (value > 1000000) {
      numberValue = value / 1000000;
      units = 'M';
    } else {
      return `${numberValue} ${units}`;
    }
    return `${numberValue.toFixed(3)} ${units}`;
  }

  public bytesToUnits(bytes): string {
    if (!bytes) {
      return '';
    }
    let units = 'B';
    let byteValue = bytes;
    if (bytes > 1000000000) {
      byteValue = bytes / 1000000000;
      units = 'GB';
    } else if (bytes > 1000000) {
      byteValue = bytes / 1000000;
      units = 'MB';
    } else if (bytes > 1000) {
      byteValue = bytes / 1000;
      units = 'KB';
    } else {
      return `${byteValue} ${units}`;
    }
    return `${byteValue.toFixed(3)} ${units}`;
  }

  public get packetsSent(): string {
    return this.numberToUnits(this.packsent);
  }

  public get packetsLossPercent(): string {
    let packloss_percent = '';
    if (this.packloss > 0) {
      packloss_percent = `(${parseFloat(String((this.packloss / this.packsent) * 100)).toFixed(2)}%)`;
    }
    return packloss_percent;
  }

  public get packetsRetransPercent(): string {
    let packretrans_percent = '';
    if (this.packretrans > 0 && this.packloss > 0) {
      packretrans_percent = `(${parseFloat(String((this.packretrans / this.packloss) * 100)).toFixed(2)}%)`;
    }
    return packretrans_percent;
  }

  public get upBytes(): string {
    return this.bytesToUnits(this.upbytes);
  }

  public get downBytes(): string {
    return this.bytesToUnits(this.downbytes);
  }

  public get bufferWithUnits(): string {
    return this.bytesToUnits(this.buffer);
  }

  public get jitterInMs(): string {
    return this.appendMilliseconds(this.jitter);
  }
}
