/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
import {IsDefined, IsInt, IsNotEmpty, IsPositive, Max} from 'class-validator';
import {AD_BREAK_DURATION_LIMIT} from 'apps/cms/routes/constants';

export class AdBreak {
    public errors = new AdBreakErrors();
    public input = new AdBreakInput();
}

export class AdBreakInput {
    @IsInt({message: 'Duration must be an integer'})
    @IsPositive({message: 'Duration must be positive'})
    @Max(AD_BREAK_DURATION_LIMIT, {message: 'Duration exceeds 12 hours'})
    get durationAsNumber(): number {
        return Number(this.durationAsString || 0);
    }

    @IsDefined({message: 'Duration is required'})
    @IsNotEmpty({message: 'Duration is required'})
    public durationAsString: string = '';
}

export class AdBreakErrors {
    public durationAsNumber?: string;
    public durationAsString?: string;
}
