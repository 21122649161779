import {IngestPoint} from 'apps/cms/routes/slicers/models/slicer';
import {autoinject} from 'aurelia-dependency-injection';

@autoinject()
export class SlicerIngestPointModalFooter {
    public shared: any;
    public zones: any;
    public types: any;
    public slicerIngestPoints: IngestPoint[];
    public slicerIngestPoint: IngestPoint;

    public activate(model) {
        ({
            slicerIngestPoint: this.slicerIngestPoint,
            slicerIngestPoints: this.slicerIngestPoints,
            types: this.types,
            zones: this.zones,
        } = model.shared);
        this.shared = model.shared;
    }

    public validate(controller) {
        if (!this.slicerIngestPoint.name) {
            this.shared.creationError = 'Endpoint name is required.';
            return false;
        }
        if (_.map(this.slicerIngestPoints, 'name').includes(this.slicerIngestPoint.name)) {
            this.shared.creationError = 'Endpoint name already in use.';
            return false;
        }

        controller.ok();
    }
}
