import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, LogManager, singleton} from 'aurelia-framework';
import {classToPlain} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {PrebidConfig, PrebidConfigCollection} from '../models/prebid-config';

export const PREBID_CONFIG_TARGET_URL: string = '/api/v4/prebid_config/';

const log = LogManager.getLogger('prebid-service');
@autoinject()
@singleton()
export class PrebidService {
  public prebidConfigs: PrebidConfig[] = [];

  constructor(public acceo: Acceo, public notification: CToastsService) {}

  public getPrebidConfigs(): Promise<PrebidConfig[]> {
    return new Promise((resolve, reject) => {
      this.acceo
        .get(PrebidConfigCollection)(PREBID_CONFIG_TARGET_URL)
        .then((resp: PrebidConfigCollection) => {
          this.prebidConfigs = resp.items;
          resolve(this.prebidConfigs);
        })
        .catch(er => {
          const msg = 'GET Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public async deletePrebidConfig(id) {
    try {
      await this.acceo.delete()(`${PREBID_CONFIG_TARGET_URL}${id}`);
    } catch (e) {
      log.error(e);
      this.notification.error(e.message);
      throw Error(e.message);
    }
  }

  public async savePrebidConfig(prebidConfig: PrebidConfig): Promise<PrebidConfig[]> {
    const data: any = classToPlain(prebidConfig);
    const {id} = prebidConfig;
    delete data.id;

    try {
      if (id) {
        const resp = await this.acceo.patch(PrebidConfig)(`${PREBID_CONFIG_TARGET_URL}${id}`, data);
        this.prebidConfigs = this.prebidConfigs.map(item => (item.id === id ? resp : item));
      } else {
        const resp = await this.acceo.post(PrebidConfig)(PREBID_CONFIG_TARGET_URL, data);
        this.prebidConfigs.push(resp);
      }
      return this.prebidConfigs;
    } catch (e) {
      const msg = 'Save Error';
      this.notification.error(e.message);
      log.error(`${msg}:`, e);
      throw new Error(msg);
    }
  }
}
