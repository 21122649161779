import {autoinject, LogManager} from 'aurelia-framework';
import {OwnerMetaContext, SessionService} from 'services/session';

import {TestPlayersSettings} from '../models/test-players';

const logger = LogManager.getLogger('Test Players Settings Service');

@autoinject
export class TestPlayersService {
    public logger = logger;

    constructor(public session: SessionService) {}

    public async getData(): Promise<TestPlayersSettings> {
        const settings = new TestPlayersSettings();

        try {
            settings.whitelist = (await this.session.getOwnerMeta(OwnerMetaContext.TEST_PLAYER, 'whitelist')) || [];
            settings.preventAutomatic =
                (await this.session.getOwnerMeta(OwnerMetaContext.TEST_PLAYER, 'prevent-automatic')) || false;
        } catch (e) {
            /* empty */
        }

        return settings;
    }

    public update(dict: any): Promise<any> {
        return this.session.setOwnerMeta(OwnerMetaContext.TEST_PLAYER, {
            'prevent-automatic': dict.preventAutomatic,
            whitelist: dict.whitelist,
        });
    }
}
