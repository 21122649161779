import {copyToClipboard, CTableActions, CTableCol, CToastsService, SharedNav} from '@bindable-ui/bindable';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, containerless, PLATFORM} from 'aurelia-framework';
import {AdServerDebugService} from '../services/ad-server-debug-service';
import {JobTransactionCallbackService} from '../services/job-transaction-callback-service';

import {AD_DEBUG_EVENT} from '../index';
import {AdServerDebugItem, AdServerDebugResponse, PlaybackType, Status} from '../models/ad-server-debug-response';
import {DebugSearchCriteria} from '../models/debug-search-criteria';
import {AdServerDebugQuery} from '../query';
import {AdServerDebugTableRowFormatter} from './table-row';
import {IAdServerDebugTableRow} from './table-models';
import {DEFAULT_PAGE_SIZE_OPTIONS} from '../models/defaults';

export const tableStatusMapping = {
  [Status.Complete]: 'healthy',
  [Status.Fail]: 'critical',
  [Status.Processing]: 'info',
  [Status.Pending]: 'info',
  [Status.Unknown]: 'neutral',
};

export class CriteriaAndReponse {
  criteria: DebugSearchCriteria;
  response: AdServerDebugResponse;
}

@autoinject()
@containerless()
export class AdServerDebugList {
  public states = [
    Status.Complete,
    Status.Fail,
    Status.Processing,
    Status.Pending,
  ];

  @bindable
  public response: AdServerDebugResponse;

  public recordsPriorPages: number = 0;
  public filteredItems: AdServerDebugItem[] = [];
  public selectedStates: Status[] = [];
  @bindable
  public criteria: DebugSearchCriteria;

  public allColumns: CTableCol[];
  public columns: CTableCol[];
  public queryBuilder: AdServerDebugQuery;

  @bindable
  public isLoadingJobDetails: boolean = false;

  @bindable
  public isUpdating: boolean = false;

  public defaultPageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  public tableActions: CTableActions = {
    getColClass: (row, col) => {
      let cls = col._class;
      if (col.colHeadName === 'status') {
        cls = ` ${tableStatusMapping[row.status]}`;
      }
      return cls;
    },
    rowClick: row => this.sendRow(row),
    sortColumn: (col: CTableCol, reverse: boolean) => {
      this.criteria.order = `${reverse ? '-' : ''}${col.colHeadName}`;
      this.updateQuery();
    },
  };

  public queryParams: any = {};

  constructor(
    public sharedNav: SharedNav,
    public eventAggregator: EventAggregator,
    public adServerDebugService: AdServerDebugService,
    public jobTransactionCallbackService: JobTransactionCallbackService,
    private notification: CToastsService,
  ) {
    this.allColumns = [
      {
        colAction: job => this.onJobClick(job),
        colClass: 't150',
        colHeadName: 'status',
        colHeadValue: 'Status',
        view: PLATFORM.moduleName('apps/cms/routes/ad-server-debug/table-views/job-status.html'),
        viewModel: PLATFORM.moduleName('apps/cms/routes/ad-server-debug/table-views/job-status'),
      },
      {
        colClass: 't240',
        colHeadName: 'created_at',
        colHeadValue: 'Date Created',
        // sort: true,
        valueConverter: 'timezoneTimeToStandardDateTimeMS',
      },
      {
        colClass: 't240',
        colHeadName: 'channel_name',
        colHeadValue: 'Channel/Event',
      },
      {
        colClass: 't240',
        colHeadName: 'pk',
        colHeadValue: 'Job Id',
      },
      {
        colClass: 't240',
        colHeadName: 'viewer_guid',
        colHeadValue: 'Session ID',
      },
      {
        colClass: 't50',
        colHeadName: 'transaction_count',
        colHeadValue: 'T#',
      },
      {
        colClass: 't50',
        colHeadName: 'beacon_count',
        colHeadValue: 'B#',
      },
      {
        colClass: 't50',
        colHeadName: 'failed_transaction_count',
        colHeadValue: 'F#',
      },
    ];
  }

  public responseChanged() {
    // use the page size to determine how many we have already presented the user:
    if (!this.criteria) {
      this.criteria = this.adServerDebugService.criteria;
    }

    this.recordsPriorPages = (this.criteria.page - 1) * this.criteria.page_size;

    // meta:
    this.adServerDebugService.meta.recordsFrom = this.recordsPriorPages + 1;
    this.adServerDebugService.meta.recordsTo = this.recordsPriorPages + this.adServerDebugService.meta.showing;
    this.adServerDebugService.meta.showing = this.response.items.length;
    this.adServerDebugService.meta.total = this.response.total_items || 0;
    this.adServerDebugService.meta.hasMore =
      this.adServerDebugService.meta.recordsTo < this.adServerDebugService.meta.total;

    this.filteredItems =
      this.selectedStates.length > 0
        ? this.response.items.filter(i => this.selectedStates.includes(i.status))
        : this.response.items;
  }

  public getRowState(status: string) {
    // return a state based on the status of the transaction
    if (status === Status.Complete) {
      return 'success';
    }
    if (status === Status.Fail) {
      return 'danger';
    }
    return 'neutral';
  }

  public getRowStyle(id: string) {
    // Return a style for the job row, adding opacity based on
    // the ID, indicating we've already clicked on this job.
    if (id) {
      return 'opacity: 0.5';
    }
    return 'opacity: 1.0';
  }

  private async getDetails(job: AdServerDebugItem) {
    if (job.storageDataLocation) {
      const storageObj = JSON.parse(job.storageDataLocation);
      const {baseFolder, filePath} = storageObj;
      return this.jobTransactionCallbackService.getById(job.zone, job.pk, baseFolder, filePath);
    }
    return null;
  }

  public async sendRow(job: AdServerDebugItem) {
    // determine if we already clicked a row for details

    if (this.isLoadingJobDetails) {
      this.notification.info('Please wait - still fetching transactions / ads / callbacks.', 'Processing Request');
      return;
    }

    // if we did not previously view this job, go get the details
    if (!job.id) {
      job.id = job.pk;
      this.isLoadingJobDetails = true;
      try {
        const jobDetailResponse = await this.getDetails(job);

        if (!jobDetailResponse) {
          this.notification.info(
            `Please Check Back for transactions / callbacks for job ${job.pk}`,
            'Still Processing',
          );
        } else {
          const jobDetail = AdServerDebugTableRowFormatter.format(jobDetailResponse as IAdServerDebugTableRow);
          job.updated_at = jobDetail.updated_at || '';
          job.callbacks = jobDetail.callbacks;
          job.transactions = jobDetail.transactions;
          job.slots = jobDetail.slots;
          job.transactionsTreeItems = jobDetail.transactionsTreeItems;
          job.unfulfilled_requests = jobDetail.transactions.filter(t => t.status === Status.Fail).length;
          job.failure_reason = jobDetailResponse.failure_reason;
          job.failure_type = jobDetailResponse.failure_type;
          job.canExport = jobDetailResponse.canExport;
        }
      } catch (reason) {
        this.notification.error('Unable to retrieve data at this time.', 'Job Detail Retrieval Error');
      } finally {
        this.isLoadingJobDetails = false;
      }
    }
    // details mapped to the class are presented to the user
    // blah blah blah
    this.eventAggregator.publish(AD_DEBUG_EVENT.SelectJob, {job});
  }

  public attached() {
    this.selectedStates = [
      Status.Complete,
      Status.Fail,
      Status.Processing,
      Status.Pending,
    ];
    this.sharedNav.setActive(0, 6);
  }

  public criteriaChanged(newCriteria: DebugSearchCriteria) {
    if (newCriteria) {
      const omitCallbacks = this.criteria.includes.indexOf('callbacks') < 0;
      const omitTransactions = this.criteria.includes.indexOf('transactions') < 0 && !omitCallbacks;
      const omitted = [];
      if (omitCallbacks) omitted.push('callbacks');
      if (omitTransactions) omitted.push('transactions');
      if (this.criteria.playback_type === PlaybackType.VOD) omitted.push('channel_name');
      this.columns = this.allColumns.filter((col: CTableCol) => !omitted.includes(col.colHeadName));
    }
  }

  public changePageSize(pageSize: number) {
    // prevent unnecessary api calls
    // by checking the pageSize selected and totals
    if (
      this.criteria.page_size === pageSize ||
      (this.adServerDebugService.meta.total < pageSize && !this.adServerDebugService.meta.hasMore)
    ) {
      return;
    }
    this.criteria.page_size = pageSize;
    this.queryBuilder.criteria.page_size = pageSize;
    this.updateQuery();
  }

  public prevPage() {
    this.criteria.page -= 1;
    this.retrieveTableData();
  }

  public nextPage() {
    this.criteria.page += 1;
    this.retrieveTableData();
  }

  public copyQueryUrl() {
    const urlCopy = window.location.href;
    return copyToClipboard(urlCopy, this.notification.success('Copied to clipboard'));
  }

  public async retrieveTableData() {
    this.isUpdating = true;
    try {
      const resp: AdServerDebugResponse = await this.adServerDebugService.getAdDebugItems(this.criteria);
      this.response = resp;
    } catch (reason) {
      this.notification.error(reason);
    } finally {
      const criteriaAndReponseParam: CriteriaAndReponse = {criteria: this.criteria, response: this.response};
      this.eventAggregator.publish(AD_DEBUG_EVENT.UpdateQueryparams, criteriaAndReponseParam);
      this.isUpdating = false;
    }
  }

  public updateQuery() {
    if (this.isUpdating) {
      return;
    }
    this.isUpdating = true;

    // update to page 1 whenever the user modifies query
    this.queryBuilder.criteria.page = 1;
    this.queryBuilder.criteria.page_size = this.criteria.page_size;
    this.criteria = this.queryBuilder.populate();
    this.criteria.page = 1;
    this.adServerDebugService.criteria = this.criteria;
    this.retrieveTableData();
  }

  public resetQuery() {
    this.eventAggregator.publish(AD_DEBUG_EVENT.Reset);
  }

  public onJobClick(job: AdServerDebugItem) {
    this.sendRow(this.response.items.find(j => j.pk === job.pk));
  }

  public exportAsCSV() {
    const items = this.filteredItems.map(i => {
      (i as any).transactions = i.transactions.length;
      (i as any).callbacks = i.callbacks.length;
      return i;
    });
    this.eventAggregator.publish(AD_DEBUG_EVENT.ExportJobs, {columns: this.columns, items});
  }
}
