import {IsString, IsOptional} from 'class-validator';

export class IdsIdentity {
  @IsOptional()
  @IsString()
  public external_id: string;

  @IsString()
  public id: string;

  @IsOptional()
  @IsString()
  public id_format: string;

  @IsOptional()
  @IsString()
  public source: string;
}
