import {autoinject} from 'aurelia-framework';
import {ValidateHelper} from 'resources/validate-helper';
import {LynkDialogServiceModel} from 'resources/services/dialog';
import {HostedSlicerDetails} from '.';
import {IEncodingProfileTableRow} from '../../encoding-profiles/list/table-models';
import {EncodingProfileTableRowFormatter} from '../../encoding-profiles/list/table-row';
import {MSOSlicerClassification, MSOSlicerRequestPost, MSOSlicerRequestPostErrors} from '../../models/msoslicer';
import {ConfigMessages} from './models';
import {SlicerVersionSelection} from './slicer-version';

@autoinject
export class HostedSlicerCreateModal extends HostedSlicerDetails {
  private _selectedRegion: string;
  private _defaultSlicerVersionId: string;

  public model: LynkDialogServiceModel;
  public is_new: boolean = true;
  public stepTabs: any;
  public stepPanels: any;
  protected slicerVersionSelection: SlicerVersionSelection;
  public versionSpinner: any;

  public attached() {
    this.stepPanels = document.querySelectorAll('.panel');

    this.stepPanels.forEach(panel => {
      panel.addEventListener('click', () => {
        this.handleNav(panel.id);
      });
      // Sometimes click events don't bubble, so we listen for focusin as well
      panel.addEventListener('focusin', () => {
        this.handleNav(panel.id);
      });
    });
  }

  public get selectedRegion() {
    return this._selectedRegion;
  }

  public set selectedRegion(region) {
    this._selectedRegion = region;
    this.config.input.slicerVersion.input.regionLabel = this._selectedRegion;
    this.config.input.slicerVersion.input.selection.region = this._selectedRegion;
  }

  public get defaultSlicerVersionId() {
    return this._defaultSlicerVersionId;
  }

  public set defaultSlicerVersionId(slicerVersion: string) {
    this._defaultSlicerVersionId = slicerVersion;
  }

  protected setSlicerVersionSelection(selectedVersion: any) {
    this.config.input.slicerVersion.input.selection.version_id = selectedVersion.value;
    this.config.input.slicerVersion.input.selection.slicer_version = selectedVersion.slicer_version;
  }

  public detached() {
    this.stepPanels.forEach(panel => {
      panel.removeEventListener('click');
      panel.removeEventListener('focusin');
    });
  }

  public activate(model: LynkDialogServiceModel) {
    this.model = model;
    this.config = model.settings.sharedModel;
    this.model.settings.reportValidity = this.validate.bind(this);
    this.model.settings.submitAction = this.actionCreate.bind(this);
    if (this.sessionService.hasHostedSlicersAccess) {
      this.config.state.canSetClassification = true;
      if (!this.config.input.classification) {
        this.config.input.classification = MSOSlicerClassification.PRODUCTION;
      }
    }
    // load mandatory dependencies
    this.load('').then(async () => {
      // show the footer buttons
      model.settings.footer = 'standard';
      // try to preselect default encoding profile
      this.loadDefaultProfile();
      // try to load the list of plugins available
      this.loadPlugins();
    });
  }

  public actionCancel() {
    this.model.controller.cancel();
  }

  public handleNav(panelId) {
    const panel = document.getElementById(panelId);
    this.stepPanels.forEach(p => p.classList.remove('is-active'));
    this.stepTabs.show(panelId);
    panel.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
    panel.classList.add('is-active');
  }

  public async actionCreate() {
    try {
      const output = await this.slicerService.post(this.transformRequestPostInput());
      this.notification.success(ConfigMessages.SLICER_CREATE_SUCCESS);
      return output;
    } catch (error) {
      this.acceoErrorToast.show(error);
      return false;
    }
  }

  protected async loadDefaultProfile() {
    this.config.input.encodingProfile.state.error = undefined;
    this.config.input.encodingProfile.state.isLoading = true;
    try {
      const profileDefault = (await this.profilesService.getDefault()).items.find(item => item.is_default_live_profile);
      if (profileDefault) {
        this.config.input.encodingProfile.input.selection = EncodingProfileTableRowFormatter.format(
          profileDefault as IEncodingProfileTableRow,
        );
      }
    } catch (error) {
      this.config.input.encodingProfile.state.error = ConfigMessages.PROFILE_DEFAULT_ERROR;
    }
    this.config.input.encodingProfile.state.isLoading = false;
  }

  public async loadPlugins() {
    this.config.input.plugin.state.error = undefined;
    this.config.input.plugin.state.isLoading = true;
    try {
      const response = await this.pluginService.get();
      this.config.input.plugin.options.plugin = response.items;
      this.config.input.plugin.state.isHidden = response.items.length === 0;
    } catch (error) {
      this.config.input.plugin.state.error = `${ConfigMessages.PLUGIN_READ_ERROR}: ${error}`;
    }
    this.config.input.plugin.state.isLoading = false;
  }

  protected transformRequestPostErrors(errors: MSOSlicerRequestPostErrors) {
    return {
      classification: errors.classification,
      configuration: errors.configuration,
      managed: errors.managed,
      notes: errors.notes,
      protocol: errors.stream_type_id,
      region: errors.server_region_id,
      slicerId: errors.slicer_id,
      source: errors.source_ip,
    };
  }

  protected transformRequestPostInput() {
    const request = new MSOSlicerRequestPost();
    request.configuration = this.config.input.slicerConfiguration.input.table.exportString();
    if (this.config.state.canSetClassification) {
      request.classification = this.config.input.classification || '';
    } else {
      request.classification = MSOSlicerClassification.PRODUCTION;
    }
    request.managed = this.config.input.managed;
    request.notes = this.config.input.notes;
    request.profile_id = this.config.input.encodingProfile.input.selection.id;
    request.ingest_point_id = this.config.input.ingestPoint.input.selection.id;
    request.server_region_id = this.config.input.ingestPoint.input.selection.id
      ? this.config.input.ingestPoint.input.selection.zone.id
      : undefined;
    request.slicer_plugin = this.config.input.plugin.input.selection.slicer_plugin;
    request.slicer_plugin_version = this.config.input.plugin.input.selection.slicer_plugin_version;
    request.stream_type_id = this.config.input.ingestPoint.input.selection.id
      ? this.config.input.ingestPoint.input.selection.stream_type.id
      : undefined;
    if (this.config.options.protocolRequiresSource(request.stream_type_id)) {
      request.source_ip = this.config.input.source || '';
    }
    request.version_id = this.config.input.slicerVersion.input.selection.version_id;
    this.selectedRegion = request.server_region_id;
    return request;
  }

  protected async validate() {
    const errors = new MSOSlicerRequestPostErrors();
    const request = this.transformRequestPostInput();
    let valid = await ValidateHelper.validate(request, errors);
    this.config.errors = this.transformRequestPostErrors(errors);
    // manually validate selected ingest point
    if (request.ingest_point_id) {
      if (this.config.input.ingestPoint.input.selection.status !== 'NOT_IN_USE') {
        this.config.errors.ingestPointStatus = ConfigMessages.INGEST_POINT_INVALID_STATUS;
        valid = false;
      }
    } else {
      this.config.errors.ingestPointRequired = true;
      valid = false;
    }
    // manually validate selected slicer version - should never fail with region setter
    if (request.version_id) {
      if (this.config.input.slicerVersion.input.selection.region !== request.server_region_id) {
        this.config.errors.slicerVersion = ConfigMessages.SLICER_VERSION_INVALID_REGION;
        valid = false;
      }
    }
    this.versionSpinner = await this.loadVersions();
    // identify first actual version
    _.each(this.versionSpinner, v => {
      if (!this.defaultSlicerVersionId && v.text.match(/^\d/) && v.text.match(/LATEST/)) {
        this.defaultSlicerVersionId = v.value;
        this.setSlicerVersionSelection(v);
        valid = true;
      }
    });
    if (!this.config.input.slicerVersion.input.selection.version_id) {
      this.config.input.slicerVersion.input.selection.version_id = this.defaultSlicerVersionId;
    }
    return valid;
  }

  public async loadVersions() {
    let slicerVersionOptions = {};

    try {
      this.slicerVersionSelection = new SlicerVersionSelection(this.acceo);
      slicerVersionOptions = await this.slicerVersionSelection.loadRegionVersions(this.selectedRegion);
    } catch (error) {
      this.config.input.plugin.state.error = `${ConfigMessages.PLUGIN_READ_ERROR}: ${error}`;
    }
    this.config.input.plugin.state.isLoading = false;
    return slicerVersionOptions;
  }
}
