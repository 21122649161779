import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';

import {IsNumber, IsString} from 'class-validator';

import {Acceo} from 'services/acceo';

@autoinject
export class BoxManage {
    /*
     * Computed Properties
     */

    @computedFrom('state')
    get decryptBtnState() {
        return this.state === 'decrypting' ? 'disabled' : '';
    }

    /*
     * Public Properties
     */

    public bounce_input: string = '';
    public bounceLength: string = 'warning';
    public bounceErrorMsg: string = '';
    public bounceWarningMsg: string = 'Do not bounce a bunch of boxes from the same zone all at once.';

    public kill_input: string = '';
    public killLength: string = 'warning';
    public killErrorMsg: string = '';
    public killWarningMsg: string = 'Wait 5 minutes before killing each box.';

    public lb_input: string = '';
    public lbLength: string = 'warning';
    public lbErrorMsg: string = '';
    public lbWarningMsg: string = 'Do not use this option unless an Edgecast Streaming Developer asks you to.';

    public results: any[] = [];

    public duplicates: any[] = [];

    /*
     * Private Properties
     */
    public state: string = 'idle';

    constructor(private acceo: Acceo, public notification: CToastsService) {}

    public splitInput(input) {
        return input.split(/[ ,]+/).filter(notNull => notNull);
    }

    public findDuplicates(input) {
        return input.filter((item, index) => input.indexOf(item) !== index);
    }

    public async boxBounceMonitor() {
        let bounce_list_mon = this.bounce_input.split(/[ ,]+/);
        bounce_list_mon = bounce_list_mon.map(v => v.toLowerCase());
        if (bounce_list_mon.length > 5) {
            this.bounceLength = 'error';
            this.bounceErrorMsg = 'Cannot Exceed 5 Boxes at a time.';
        } else if (bounce_list_mon.some(res => res.includes('broker'))) {
            this.bounceLength = 'warning';
            this.bounceWarningMsg = `There is definite risk in bouncing brokers. \
                    Slicers have to be "drained" off of the broker, and only DEV/VSA team can do this. \
                    Ask Team Slicer and VSA first.`;
            // May need to open a modal here to confirm in the future.
        } else {
            this.bounceLength = 'warning';
            this.bounceWarningMsg = 'Do not bounce a bunch of boxes from the same zone all at once.';
        }
    }

    public async boxKillMonitor() {
        const kill_list_mon = this.kill_input.split(/[ ,]+/);
        if (kill_list_mon.length > 5) {
            this.killLength = 'error';
            this.killErrorMsg = 'Cannot Exceed 5 Box at a time.';
        } else {
            this.killLength = 'warning';
            this.killErrorMsg = 'Wait 5 minutes before killing boxes in the same zone.';
        }
    }

    public async boxRmLBMonitor() {
        const lb_list_mon = this.lb_input.split(/[ ,]+/);
        if (lb_list_mon.length > 1) {
            this.lbLength = 'error';
            this.lbErrorMsg = 'Cannot Exceed 1 Box at a time.';
        } else {
            this.lbLength = 'warning';
            this.lbErrorMsg = 'Do not use this option unless an Edgecast Streaming Developer asks you to.';
        }
    }

    public async boxBounce() {
        this.duplicates = [];
        this.state = 'bouncing';
        let bounce_list = this.splitInput(this.bounce_input);
        bounce_list = bounce_list.map(v => v.toLowerCase());
        const url: string = '/ops/boxbounce';
        try {
            // const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
            this.duplicates = this.findDuplicates(bounce_list);
            if (this.duplicates.length > 0) {
                this.bounceLength = 'warning';
                this.bounceWarningMsg = `Found duplicate box ids / server names (${this.duplicates}). Please remove duplicates and re-submit.`;
                this.notification.warning(`Please remove the following duplicates ${this.duplicates}`);
            } else {
                const params = $.param({box_filter: bounce_list.join(',')});
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                if (resp.error === 0) {
                    this.notification.success(resp.msg);
                } else {
                    this.notification.error(resp.msg);
                }
                this.bounceLength = 'warning';
                this.bounceWarningMsg = 'Do not bounce a bunch of boxes from the same zone all at once.';
                this.bounce_input = '';
                this.state = 'done';
            }
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }

    public async boxKill() {
        this.duplicates = [];
        this.state = 'killing';
        let kill_list = this.splitInput(this.kill_input);
        kill_list = kill_list.map(v => v.toLowerCase());
        const url: string = '/ops/boxkill';
        try {
            this.duplicates = this.findDuplicates(kill_list);
            if (this.duplicates.length > 0) {
                this.killLength = 'warning';
                this.killWarningMsg = `Found duplicate box ids / server names (${this.duplicates}). Please remove duplicates and re-submit.`;
                this.notification.warning(`Please remove the following duplicates ${this.duplicates}`);
            } else {
                const params = $.param({box_filter: kill_list.join(',')});
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                if (resp.error === 0) {
                    this.notification.success(resp.msg);
                } else {
                    this.notification.error(resp.msg);
                }
                this.killLength = 'warning';
                this.killWarningMsg = 'Wait 5 minutes before killing boxes in the same zone.';
                this.kill_input = '';
                this.state = 'done';
            }
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }

    public async boxRmLB() {
        /*
         * Only allows one box to be removed from the LB at a time.
         * No need to check for duplicates and/or length of input.
         */
        this.state = 'removing_from_lb';
        const url: string = '/ops/boxremovelb';
        try {
            const params = $.param({box_filter: this.lb_input});
            const resp = await this.acceo.get(Response)([url, params].join('?'));
            if (resp.error === 0) {
                this.notification.success(resp.msg);
            } else {
                this.notification.error(resp.msg);
            }
            this.lbLength = 'warning';
            this.lbWarningMsg = 'Do not use this option unless an Edgecast Streaming Developer asks you to.';
            this.lb_input = '';
            this.state = 'done';
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsNumber()
    public error: number;

    @IsString()
    public msg: string;
}
