/* eslint-disable no-dupe-class-members */
import {DialogService} from 'aurelia-dialog';
import {autoinject, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {BetaMode} from 'services/beta-mode';

import {CToastsService, IVNavSliderNavDropzone, IVNavSliderPageDropzoneList, SharedNav} from '@bindable-ui/bindable';

import {Acceo} from 'services/acceo';

import {trackedModel} from 'apps/cms/utils/tracked-model';
import {DEFAULT_PAGE_NUM, DEFAULT_PER_PAGE_LIMIT} from '../models/defaults';

import {
  IHyperionMeta,
  ILibrary,
  ILibraryFilterParams,
  ITrackedLibrary,
  Library,
  LibraryListResponse,
  LibraryType,
  SimpleAsset,
} from '../models/models';

const log = LogManager.getLogger('library-service');

export const LIBRARIES_HREF: string = '#/content/library';
export const LIBRARIES_URL: string = '/api/v4/libraries';
export const OWNER_NOTIFICATION_URL: string = '/api/v4/owner_notification/';

@autoinject()
export class LibraryService {
  // Public Params
  public currentSchedule: any;
  public deleteTip: any;
  public shareLibraryPopover: any;
  public createLibraryDialog: any;
  public createLibraryForm: any;

  public assetStatusCodeError: number = 0;

  public isCreating: boolean = false;
  public isDeleting: boolean = false;
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public metadataNotFound: boolean = false;
  public searchLoading: boolean = false;

  public activeTab: string = '';
  public createErrMsg: string = null;
  public searchQuery: string = '';
  public searchQueryApplied: string = '';

  public selectedContent: string[] = [];

  public libraries: Library[] = [];
  public externalLibraries: Library[] = [];
  public currentLibraries: Library[] = [];

  public params: ILibraryFilterParams = {};
  public externalParams: ILibraryFilterParams = {library_type: LibraryType.SHARED};

  public meta: IHyperionMeta = {};
  public externalMeta: IHyperionMeta = {};

  public activeId: string = null;

  get navEnabled(): boolean {
    return this._navEnabled;
  }

  // Private Params
  private MY_CONTENT: string = 'All Content';
  private MY_PLAYLISTS: string = 'Playlists';
  private MY_LIBRARIES: string = 'Libraries';
  private MY_GRAPHICS: string = 'Graphics';
  private SHARED_LIBRARIES: string = 'Shared With Me';
  private UPLOADS: string = 'VOD Uploader';

  private _navEnabled: boolean = false;
  private navPage: IVNavSliderPageDropzoneList = {
    navs: [],
    nextText: 'Assets List',
    prevText: '',
    searchPlaceholder: null,
  };

  private libraryCollection = [
    {libraryKey: 'libraries', metaKey: 'meta', paramsKey: 'params'},
    {libraryKey: 'externalLibraries', metaKey: 'externalMeta', paramsKey: 'externalParams'},
  ];

  constructor(
    private acceo: Acceo,
    private sharedNav: SharedNav,
    private notification: CToastsService,
    public dialog: DialogService,
    public betaMode: BetaMode,
  ) {}

  private isVodUploaderAllowed(): boolean {
    return this.betaMode.hasScope('vod_uploader');
  }

  private isGraphicsAllowed(): boolean {
    return this.betaMode.hasScope('graphic_overlay');
  }

  public async addAssetToLibrary(libraryId: string, asset: SimpleAsset) {
    try {
      let url = `${LIBRARIES_URL}/${libraryId}`;
      const library = await this.acceo.get(Library)(url);

      url = `${LIBRARIES_URL}/${libraryId}/assets`;

      await this.acceo.patch(Library)(url, {asset_id: asset.id});

      this.notification.success(`Added ${asset.title} to ${library.desc}`);
    } catch (err) {
      this.notification.error(`Unable to add asset to library: ${err.message}`);
      log.error(err);
    }
    return asset;
  }

  public async removeAssetsFromLibrary(libraryId: string, assetIds: string[]) {
    try {
      const url = `${LIBRARIES_URL}/${libraryId}/assets?ids=${assetIds.join(',')}`;

      await this.acceo.delete()(url);

      this.notification.success('Removed!');

      if (this.deleteTip) {
        this.deleteTip.hide();
      }

      return true;
    } catch (err) {
      this.notification.error(`Unable to remove asset: ${err.message}`);
      log.error(err);
      return false;
    }
  }

  /**
   * Shares a library with a user
   * @param {string} libraryId
   * @param {string} username
   */
  public async addUserToLibrary(libraryId: string, username: string) {
    const params = {
      username,
      shared_library_id: libraryId,
      type: 'shared_library',
    };

    const owner = await this.acceo.post()(OWNER_NOTIFICATION_URL, params);

    if (this.shareLibraryPopover) {
      this.shareLibraryPopover.hide();
    }

    return owner;
  }

  /**
   * Gets libraries and assigns them to the given keys
   *
   * @param params ContentFilterParams object
   * @param libraryKey class key
   * @param metaKey class key
   * @param isLoadMore Optional boolean for if it's a fresh load or it's loading more in the existing list
   */
  public async assignLibraries(libraryKey: string, metaKey: string, paramsKey: string, isLoadMore: boolean = false) {
    if ((this.isLoading || this.isLoadingMore) && !isLoadMore) {
      return;
    }

    try {
      this.isLoading = true;

      if (isLoadMore) {
        this.navPage.isLoading = true;
      }

      const libraries: Library[] = this[libraryKey];
      const params: ILibraryFilterParams = this[paramsKey];

      const resp = await this.getLibraries(libraries, params, this.searchQuery, isLoadMore);

      this[libraryKey] = resp.libraries;
      this[metaKey] = resp.meta;
      this[paramsKey] = resp.params;

      if (this[metaKey].hasMore) {
        await this.assignLibraries(libraryKey, metaKey, paramsKey, true);
      }
    } catch (err) {
      this.notification.error('Unable to load libraries list');
      log.error(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.navPage.isLoading = false;
    }
  }

  /**
   * Reset params to default
   */
  public cleanParams() {
    // Reset the params to be default
    this.params = {};
    this.externalParams = {
      library_type: LibraryType.SHARED,
    };
  }

  /**
   * Creates a library and adds it to collection and nav
   * @param desc
   *
   * @returns {Library}
   */
  public async createLibrary(desc: string, callback?) {
    const params: any = {
      desc,
    };

    const library = await this.acceo.post(Library)(LIBRARIES_URL, params);

    this.libraries.push(library);

    const {navs = []} = this.navPage;

    navs.forEach(nav => {
      if (nav.title === this.MY_LIBRARIES) {
        const subNavs = (nav.navs || []).concat();
        const node = this.createNode(library, callback);
        subNavs.push(node);
        nav.navs = _.sortBy(subNavs, n => n.title.toLowerCase());
      }
    });

    return library;
  }

  /**
   * Deletes library and removes from collections and nav
   * @param {string} libraryId
   */
  public async deleteLibrary(libraryId: string) {
    const url = `${LIBRARIES_URL}/${libraryId}`;

    await this.acceo.delete()(url);

    this.deleteLibraryFromNav(libraryId);
  }

  public deleteLibraryFromNav(libraryId: string) {
    this.libraryCollection.forEach(l => {
      let index = this[l.libraryKey].findIndex(library => library.id === libraryId);

      if (index > -1) {
        this[l.libraryKey].splice(index, 1);
      }

      const {navs = []} = this.navPage;

      navs.forEach(nav => {
        const subNavs = nav.navs || [];
        index = subNavs.findIndex(_nav => _nav.href === `${LIBRARIES_HREF}/${libraryId}`);

        if (index > -1) {
          subNavs.splice(index, 1);
        }
      });
    });
  }

  public enableNav(enabled: boolean) {
    this._navEnabled = enabled;
  }

  /**
   * Get a list of libraries based on params
   */
  public async getLibraries(
    libraries: Library[] = [],
    params: ILibraryFilterParams = {},
    searchQuery: string = null,
    isLoadMore: boolean = false,
  ) {
    if (!params.page) {
      params.page = DEFAULT_PAGE_NUM;
    }

    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    // Enable once we have confirmed indexing in Mongo
    // if (!params.order) {
    //     params.order = 'search';
    // }

    if (searchQuery) {
      params.search = searchQuery;
    }

    let _libraries: Library[] = [];

    if (!isLoadMore) {
      // this.navPage.isLoading = true;
      params.page = 1;
    } else {
      // make a copy of the libraries
      _libraries = _libraries.concat(libraries);
      params.page = (params.page || 1) + 1;
    }

    // this[paramsKey] = params;
    const urlParams = $.param(params);
    const res = await this.acceo.get(LibraryListResponse)(`${LIBRARIES_URL}?${urlParams}`);

    _libraries = _.uniqBy(_libraries.concat(res.items), 'id');

    const meta: IHyperionMeta = {
      hasMore: _libraries.length < res.total_items,
      limit: params.page_size || DEFAULT_PER_PAGE_LIMIT,
      page: params.page || 1,
      showing: _libraries.length,
      total: res.total_items,
    };

    return {
      meta,
      params,
      libraries: _libraries,
    };
  }

  public async getLibrary(id: string[]): Promise<Library[]>;
  public async getLibrary(id: string): Promise<Library>;
  public async getLibrary(id: string | string[]): Promise<Library | Library[]> {
    let items = null;

    try {
      let url: string;
      let resp: Library | LibraryListResponse;

      this.currentLibraries = [];

      if (!id || (Array.isArray(id) && id.length === 0)) {
        return [];
      }

      if (Array.isArray(id)) {
        url = `${LIBRARIES_URL}?ids=${id.join(',')}`;
        resp = await this.acceo.get(LibraryListResponse)(url);
        this.currentLibraries = resp.items;
        items = resp.items;
      } else {
        url = `${LIBRARIES_URL}/${id}`;
        resp = await this.acceo.get(Library)(url);
        this.currentLibraries = [resp];
        items = resp;
      }
    } catch (err) {
      this.notification.error('An error occurred while attempting to load your library. Please try again.');
      log.error(err);
    }

    return items;
  }

  public async getLibraryFromCacheOrLoad(id: string): Promise<Library> {
    let lib = this.currentLibraries.find(l => l.id === id);

    if (!lib) {
      lib = await this.getLibrary([id])[0];
    }

    return lib;
  }

  /**
   * Gets more assets based on scrolling the table
   */
  public async getMore() {
    const promises = [];
    this.libraryCollection.forEach(l => {
      const meta: IHyperionMeta = this[l.metaKey];
      if (meta.hasMore) {
        promises.push(this.assignLibraries(l.libraryKey, l.metaKey, l.paramsKey, true));
      }
    });
    await Promise.all(promises);
  }

  public async loadLibraries() {
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const l of this.libraryCollection) {
        // eslint-disable-next-line no-await-in-loop
        await this.assignLibraries(l.libraryKey, l.metaKey, l.paramsKey);
      }
    } catch (err) {
      this.notification.error('An error occurred while attempting to access your libraries. Please try again.');
      log.error('Could not load library', err);
    }
  }

  private get playlistIndex(): number {
    let index = 1;
    if (this.isVodUploaderAllowed()) {
      index += 1;
    }

    if (this.isGraphicsAllowed()) {
      index += 1;
    }
    return index;
  }

  public loadLibraryNav() {
    if (!this.navEnabled) {
      return;
    }

    this.navPage.navs = [];

    const allContent: ILibrary = {
      desc: this.MY_CONTENT,
      href: '#/content',
      index: 0,
    };

    const playlists: ILibrary = {
      desc: this.MY_PLAYLISTS,
      href: '#/content/playlists',
      index: this.playlistIndex,
    };

    const uploads: ILibrary = {
      desc: this.UPLOADS,
      href: '#/content/uploads',
      index: 1,
    };

    const graphics: ILibrary = {
      desc: this.MY_GRAPHICS,
      href: '#/content/graphics',
      index: this.isVodUploaderAllowed() ? 2 : 1,
    };

    this.updateNav([allContent]);
    // eslint-disable-next-line no-unused-expressions
    this.isVodUploaderAllowed() && this.updateNav([uploads]);
    // eslint-disable-next-line no-unused-expressions
    this.isGraphicsAllowed() && this.updateNav([graphics]);
    this.updateNav([playlists]);
    this.updateNav(this.libraries, this.MY_LIBRARIES);
    this.updateNav(this.externalLibraries, this.SHARED_LIBRARIES);

    this.sharedNav.replacePage(this.navPage, 0);

    this.setNavActive(this.getActiveNavIndex(uploads, playlists, graphics));
  }

  private getActiveNavIndex(uploads: ILibrary, playlists: ILibrary, graphics: ILibrary): number {
    let idx = 0;
    if (this.isVodUploaderAllowed() && window.location.hash.startsWith(uploads.href)) {
      idx = 1;
    }

    if (window.location.hash.startsWith(playlists.href)) {
      idx = playlists.index;
    }

    if (this.isGraphicsAllowed() && window.location.hash.startsWith(graphics.href)) {
      idx = graphics.index;
    }

    return idx;
  }

  public async openLibraryModal(libraryId: string) {
    const model: Library = await this.getLibrary(libraryId);

    if (!model) {
      return;
    }

    const sharedModel = trackedModel({
      model,
      SuperClass: Library,
      trackedKeys: [
        'allow_copy',
        'desc',
        'is_ad',
      ],
    });

    this.dialog.open({
      model: {
        sharedModel,
        bodyViewModel: PLATFORM.moduleName('apps/cms/routes/content/modals/library-details-body'),
        footerEnable: true,
        footerViewModel: PLATFORM.moduleName('apps/cms/routes/content/modals/library-details-footer'),
        size: 'large',
        title: 'Edit Library',
      },
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
    });
  }

  public async pollLibraries(params): Promise<boolean> {
    let hasChanges = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const l of this.libraryCollection) {
      const paramsNew = Object.assign(params, _.cloneDeep(this[l.paramsKey]));

      delete paramsNew.page;
      delete paramsNew.page_size;

      const urlParams = $.param(paramsNew);

      // eslint-disable-next-line no-await-in-loop
      const changes = await this.acceo.get(LibraryListResponse)(`${LIBRARIES_URL}?${urlParams}`);
      if (changes.total_items > 0) {
        hasChanges = true;

        changes.items.forEach(i => this.updateLibraryData(l.libraryKey, i));
      }
    }
    return hasChanges;
  }

  public removeUserFromLibrary(libraryId: string, ids: string[]) {
    const url = `${LIBRARIES_URL}/${libraryId}/users?ids=${ids}`;

    return this.acceo.delete()(url);
  }

  /**
   * Search the assets
   *
   * @param searchQuery Optional search term that comes from the sidebar
   */
  public async search(searchQuery?: string) {
    this.navPage.searchQuery = searchQuery;
    this.searchQuery = searchQuery;
    this.searchQueryApplied = '';
    this.searchLoading = true;

    await this.loadLibraries();

    this.searchQueryApplied = searchQuery;
    this.searchLoading = false;
  }

  /**
   * Sets a library active in the side nav
   *
   * @param id Asset ID
   */
  public setNavActive(index?: number) {
    const {navs = []} = this.navPage;
    const i = index || 0;

    if (this.activeId) {
      navs.forEach(nav => {
        const subNavs = nav.navs || [];
        subNavs.forEach(node => {
          node.active = node.href === `${LIBRARIES_HREF}/${this.activeId}`;
        });
      });
      this.sharedNav.setActive(0, -1);
    } else {
      navs.forEach(nav => {
        const subNavs = nav.navs || [];
        subNavs.forEach(node => {
          node.active = false;
        });
      });
      this.sharedNav.setActive(0, i);
    }
  }

  public async updateLibrary(library: ITrackedLibrary) {
    try {
      const params = library.dirtyParams();

      await this.acceo.patch()(`${LIBRARIES_URL}/${library.id}`, params);

      library.updateCanonical();

      this.libraryCollection.forEach(l => {
        this.updateLibraryData(l.libraryKey, library);
      });

      this.notification.success('Updated');

      return true;
    } catch (err) {
      this.notification.error('Update failed. Please try again.');
      log.error(err);

      return false;
    }
  }

  /*
   *  Private methods
   */

  private createNode(library: ILibrary, callback?) {
    const {id} = library;
    let href = library.href || '#/content';
    const node: IVNavSliderNavDropzone = {
      href,
      active: false,
      clickAction: () => {
        this.activeId = null;
        this.setNavActive(library.index);

        if (callback && _.isFunction(callback)) {
          callback();
        }

        return href;
      },
      dropzone: false,
      title: library.desc,
    };

    if (id) {
      href = `${LIBRARIES_HREF}/${id}`;

      node.href = href;
      node.clickAction = () => {
        this.activeId = id;
        this.setNavActive();

        if (callback && _.isFunction(callback)) {
          callback(id);
        }

        return href;
      };

      if (library.library_type === LibraryType.OWNER) {
        node.icon = 'cog';
        node.iconAction = () => this.openLibraryModal(id);
        node.dropzone = true;
        node.dropzoneActions = {
          onDragEnter: event => {
            event.target.classList.add('drag--over');
            return false;
          },
          onDragLeave: event => {
            event.target.classList.remove('drag--over');
            return false;
          },
          onDrop: (event, data) => {
            this.addAssetToLibrary(id, data.id);
            event.srcElement.classList.remove('drag--over');
            return false;
          },
        };
      } else {
        node.subText = `Shared by: ${library.owner_name}`;
      }
    }

    return node;
  }

  /**
   * Updates library in collection and nav
   * @param {string} libraryKey
   * @param {Library} library
   */
  private updateLibraryData(libraryKey: string, library: Library) {
    const {desc, id} = library;
    const libraries = this[libraryKey];
    let index = libraries.findIndex(_library => _library.id === id);

    if (index > -1) {
      libraries[index] = library;
    }

    const {navs = []} = this.navPage;

    navs.forEach(nav => {
      const subNavs = nav.navs || [];
      index = subNavs.findIndex(_nav => _nav.href === `${LIBRARIES_HREF}/${id}`);

      if (index > -1) {
        subNavs[index].title = desc;
      }
    });
  }

  private updateNav(libraries: ILibrary[], sectionTitle?: string) {
    const {navs} = this.navPage;

    if (sectionTitle) {
      const subNav = libraries.map(library => this.createNode(library));

      navs.push({
        accordionState: 'closed',
        isAccordion: true,
        navs: _.sortBy(subNav, n => n.title.toLowerCase()),
        title: sectionTitle,
      });
    } else {
      const nodes = libraries.map(library => this.createNode(library));
      this.navPage.navs = navs.concat(nodes);
    }
  }
}
