import {HttpClient} from 'aurelia-fetch-client';
import {inject, LogManager} from 'aurelia-framework';
import {classToClass} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {CmsHttpClient} from 'services/cms-http-client';
import {ClippingConfig} from '../models/clipping-config';
import {FaceBookPage} from '../models/facebook-config';

// TODO: need to add more typing here
const logger = LogManager.getLogger('Clipping Service');

// const getClippingProfileErrorMsg = 'Error retrieving settings.';
// const saveClippingProfileErrorMsg = 'Error saving clipping profiles.';

@inject(CmsHttpClient, Acceo)
export class ClippingSettingService {
    public httpClient: HttpClient;
    public formURLEncoded: string;
    public getClippingSettingsUrl: string;
    public saveClippingSettingsUrl: string;
    public createClippingProfileUrl: string;
    public duplicateClippingProfileUrl: string;
    public deleteClippingProfilesUrl: string;
    public googleAuthenticationUrl: string;
    public googleAuthenticationRevokeUrl: string;
    public twitterAuthenticationUrl: string;
    public twitterCallBackAuthenticationUrl: string;
    public twitterAuthenticationRevokeUrl: string;
    public facebookAuthenticationUrl: string;
    public facebookAuthenticationRevokeUrl: string;
    public facebookPageListUrl: string;
    public uploadOverlayUrl: string;
    public saveIntegrationUrl: string;
    public formUrlEncoded: string;
    public error: string;
    public logger = logger;
    public data: any;

    constructor(public cmsHttpClient: CmsHttpClient, public acceo: Acceo) {
        this.httpClient = this.cmsHttpClient.httpClient;
        this.formURLEncoded = 'application/x-www-form-urlencoded; charset=UTF-8';

        // This is used in two functions:
        // 1. to get all clipping profiles (getSettings)
        // 2. To get a single profile by tacking on the profileid (getClippingProfile)
        this.getClippingSettingsUrl = '/settings/clipping/';
        this.saveClippingSettingsUrl = '/settings/clipping/save/';
        this.createClippingProfileUrl = '/settings/clipping/create-profile/';
        this.duplicateClippingProfileUrl = '/settings/clipping/duplicate-profile/';
        this.deleteClippingProfilesUrl = '/settings/clipping/delete-profile/';
        this.googleAuthenticationUrl = '/settings/clipping/google-auth/';
        this.googleAuthenticationRevokeUrl = '/settings/clipping/google-auth/revoke/';
        this.twitterAuthenticationUrl = '/settings/clipping/twitter/auth-url/';
        this.twitterCallBackAuthenticationUrl = '/settings/clipping/twitter/auth/';
        this.twitterAuthenticationRevokeUrl = '/settings/clipping/twitter/auth/revoke/';
        this.facebookAuthenticationUrl = '/settings/clipping/facebook/auth/';
        this.facebookAuthenticationRevokeUrl = '/settings/clipping/facebook/auth/revoke/';
        this.facebookPageListUrl = '/settings/clipping/facebook/pagelist/';
        this.uploadOverlayUrl = '/settings/upload-overlay/';
        this.saveIntegrationUrl = '/settings/save-integration/';
        this.formUrlEncoded = 'application/x-www-form-urlencoded';
    }

    public getFacebookPageList(clippingProfileId): Promise<FaceBookPage[]> {
        const url = `${this.facebookPageListUrl}${clippingProfileId}/`;
        return new Promise((resolve, reject) => {
            this.acceo
                .get([FaceBookPage])(url, {
                    prefix: 'pagelist',
                })
                .then((pageList: FaceBookPage[]) => {
                    resolve(pageList);
                })
                .catch(reject);
        });
    }

    public getSettings(): Promise<ClippingConfig[]> {
        return new Promise((resolve, reject) => {
            this.acceo
                .get([ClippingConfig])(this.getClippingSettingsUrl, {
                    prefix: 'profiles',
                    responseTransform: resp =>
                        resp.profiles.map(profile => ({
                            ...profile,
                            metadata: profile.metadata.map(data => ({
                                ...data,
                                values: data.values.map(val => ({
                                    key: val[0],
                                    value: val[1],
                                })),
                            })),
                        })),
                })
                .then((clippingConfigs: ClippingConfig[]) => {
                    resolve(clippingConfigs);
                })
                .catch(reject);
        });
    }

    public getClippingProfile(clippingProfileId): Promise<ClippingConfig> {
        return new Promise((resolve, reject) => {
            const url = `${this.getClippingSettingsUrl}${clippingProfileId}/`;
            this.acceo
                .get(ClippingConfig)(url, {
                    prefix: 'item',
                    responseTransform: resp => {
                        const cfg = resp.item;
                        cfg.metadata = resp.item.metadata.map(data => ({
                            ...data,
                            values: data.values.map(val => ({
                                key: val[0],
                                value: val[1],
                            })),
                        }));
                        return cfg;
                    },
                })
                .then((clippingConfig: ClippingConfig) => {
                    resolve(clippingConfig);
                })
                .catch(reject);
        });
    }

    public saveProfiles(config: ClippingConfig): Promise<ClippingConfig> {
        return new Promise((resolve, reject) => {
            this.acceo
                .post(ClippingConfig)(this.saveClippingSettingsUrl, config, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    requestTransform: (cfg: ClippingConfig) => this.flattenProfile(cfg),
                    responseTransform: resp => {
                        const cfg = resp.item;
                        cfg.metadata = resp.item.metadata.map(data => ({
                            ...data,
                            values: data.values.map(val => ({
                                key: val[0],
                                value: val[1],
                            })),
                        }));
                        return cfg;
                    },
                })
                .then((clippingConfig: ClippingConfig) => {
                    resolve(clippingConfig);
                })
                .catch(reject);
        });
    }

    public createProfile(name): Promise<ClippingConfig[]> {
        const params = `name=${name}`;
        return new Promise((resolve, reject) => {
            this.acceo
                .post([ClippingConfig])(this.createClippingProfileUrl, params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    prefix: 'profiles',
                    responseTransform: resp =>
                        resp.profiles.map(profile => ({
                            ...profile,
                            metadata: profile.metadata.map(data => ({
                                ...data,
                                values: data.values.map(val => ({
                                    key: val[0],
                                    value: val[1],
                                })),
                            })),
                        })),
                })
                .then((clippingConfigs: ClippingConfig[]) => {
                    resolve(clippingConfigs);
                })
                .catch(reject);
        });
    }

    public duplicateProfile(name, clippingProfileId): Promise<ClippingConfig[]> {
        const params = `name=${name}&dupe_from=${clippingProfileId}`;

        return new Promise((resolve, reject) => {
            this.acceo
                .post([ClippingConfig])(this.duplicateClippingProfileUrl, params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                    prefix: 'profiles',
                    responseTransform: resp =>
                        resp.profiles.map(profile => ({
                            ...profile,
                            metadata: profile.metadata.map(data => ({
                                ...data,
                                values: data.values.map(val => ({
                                    key: val[0],
                                    value: val[1],
                                })),
                            })),
                        })),
                })
                .then((clippingConfigs: ClippingConfig[]) => {
                    resolve(clippingConfigs);
                })
                .catch(reject);
        });
    }

    public deleteProfiles(ids) {
        const API_ERROR = 'Error deleting profile(s)';
        const params = `ids=${ids}`;
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.deleteClippingProfilesUrl, {
                    body: params,
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            resolve(result);
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public googleAuthentication(code, clippingProfileId) {
        const API_ERROR = 'Error authenticating to google';
        const params = `code=${code}&profile_id=${clippingProfileId}`;
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.googleAuthenticationUrl, {
                    body: params,
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public googleAuthenticationRevoke(clippingProfileId) {
        const API_ERROR = 'Error revoking google authentication';
        const params = `profile_id=${clippingProfileId}`;
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.googleAuthenticationRevokeUrl, {
                    body: params,
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public getTwitterAuthenticationUrl(): Promise<any> {
        const API_ERROR = 'Error getting twitter authentication URL';
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.twitterAuthenticationUrl, {
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public twitterCallBackAuthentication(queryString, clippingProfileId): Promise<any> {
        const API_ERROR = 'Error twitter callback authentication';
        const url = `${this.twitterCallBackAuthenticationUrl}?${queryString}&profile_id=${clippingProfileId}`;
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(url, {
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    method: 'get',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public twitterRevokeAutentication(clippingProfileId) {
        const API_ERROR = 'Error revoking twitter authentication';
        const params = `profile_id=${clippingProfileId}`;
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.twitterAuthenticationRevokeUrl, {
                    body: params,
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public facebookAutentication(clippingProfileId, accessToken, expiresIn, signedRequest, userID) {
        const API_ERROR = 'Error authenticating to facebook';
        // tslint:disable-next-line:max-line-length
        const params = `profile_id=${clippingProfileId}&accessToken=${accessToken}&expiresIn=${expiresIn}&signedRequest=${signedRequest}&userID=${userID}`;
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.facebookAuthenticationUrl, {
                    body: params,
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public facebookRevokeAutentication(clippingProfileId) {
        const API_ERROR = 'Error revoking authenticating to facebook';
        const params = `profile_id=${clippingProfileId}`;
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.facebookAuthenticationRevokeUrl, {
                    body: params,
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public uploadOverlay(overlayName, clippingProfileId, overlayFile) {
        const API_ERROR = 'Error uploading overlay file';
        const formData = new FormData();
        formData.append('overlayFile', overlayFile);
        formData.append('overlayName', overlayName);
        formData.append('profile_id', clippingProfileId);
        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.uploadOverlayUrl, {
                    body: formData,
                    headers: new Headers(),
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public saveIntegration(clippingProfileId, serviceName, integrationName, integrationObject) {
        const API_ERROR = 'Error saving integration';
        let params = `profile_id=${clippingProfileId}&__service__=${serviceName}&__name__=${integrationName}`;

        // for lakana & kaltura, we need to save the credential information
        if (serviceName === 'lakana' || serviceName === 'kaltura') {
            for (const i in integrationObject.credentials) {
                if (i !== '__metadata__') {
                    params += `&cred__${i}=${integrationObject.credentials[i]}`;
                }
            }
        }

        for (const i in integrationObject.metamap) {
            // we've already incuded the profile_id above.  They match, so don't send again.
            if (i !== 'profile_id' && i !== '__metadata__') {
                params += `&${i}=${integrationObject.metamap[i]}`;
            }
        }

        return new Promise((resolve, reject) => {
            this.httpClient
                .fetch(this.saveIntegrationUrl, {
                    body: params,
                    headers: {
                        'Content-Type': this.formURLEncoded,
                    },
                    method: 'post',
                })
                .then(response => {
                    if (response.ok) {
                        response.json().then(result => {
                            if ('error' in result && result.error !== 0) {
                                reject(new Error(result.msg));
                            } else {
                                resolve(result);
                            }
                        });
                    } else {
                        reject(new Error(API_ERROR));
                    }
                })
                .catch(() => {
                    reject(new Error(API_ERROR));
                });
        });
    }

    public flattenProfile(cfg: ClippingConfig) {
        const data = classToClass(cfg);

        const metadata = data.metadata.map(item => ({
            ...item,
            values: item.values.map(val => [val.key, val.value]),
        }));

        (data as any).metadata = metadata;
        const newData = {
            metadata,
            default_drm: data.default_drm,
            default_intro: data.default_intro === '' ? null : data.default_intro,
            default_library: data.default_library === '' ? null : data.default_library,
            default_outro: data.default_outro === '' ? null : data.default_outro,
            default_overlay: data.default_overlay === '' ? null : data.default_overlay,
            default_studio_drm: data.default_studio_drm,
            desc: data.desc,
            disabled_integrations: [],
            id: data.id,
            overlays: data.overlays,
            show_ext_id: data.show_ext_id,
        };

        // removed integrations
        Object.entries(cfg.integrations).forEach(integration => {
            if (integration[1] !== undefined && integration[1].enabled === false) {
                newData.disabled_integrations.push(integration[0]);
            }
        });

        return `item=${encodeURIComponent(JSON.stringify(newData))}`;
    }
}
