import {ConfigOptions as ConfigOptionsBase} from 'resources/config/import/models/config-options';
// import {AdServerRoute} from './config-route';

export class ConfigOptions extends ConfigOptionsBase {
  public type: any = [];

  public paramType = [
    {
      text: 'Operational Parameters',
      value: 'uplynk',
      menuOptions: [
        {text: 'Operational', value: 'uplynkParam'},
      ],
    },
    {
      text: 'Ad Server Parameters',
      value: 'adserverSetting',
      menuOptions: [
        {text: 'Custom', value: 'customParam'},
        {text: 'Macro', value: 'macroParam'},
      ],
    },
  ];

  public behavior = [
    {
      text: 'Pass Through',
      value: 'pt',
    },
    {
      text: 'Pass First Valid',
      value: 'pfv',
    },
  ];

  public get configSettingMacro() {
    return this.configSettingAllowed
      .filter(setting => setting.macro)
      .map(setting => ({
        text: setting.text,
        value: setting.macro,
      }));
  }
}
