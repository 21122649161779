import {computedFrom} from 'aurelia-framework';
// import {PLATFORM} from 'aurelia-pal';

// import {CTableCol} from '@bindable-ui/bindable';

import {BaseChannelSingle} from './base';

export class ChannelDetails extends BaseChannelSingle {
  public isDeletingMeta: boolean = false;
  public addMetaTip: any;
  public selectedMeta: any[] = [];
  public metaKey: string = '';
  public metaValue: string = '';
  public metaKeyError: string = '';
  public metaChangeTracker: number;
  public lowLatencyProfiles: any[];

  public tabFields: string[] = [
    'desc',
    'slicer_id',
    'external_id',
    'playback_profile_id',
  ];

  public async activate(params) {
    await super.activate(params);
    const isv2 = this.liveChannels.currentModel.use_chsched === 2;
    this.hasLowLatency = this.betaMode.hasScope('low-latency-profiles') && isv2;
    this.lowLatencyProfiles = await this.liveChannels.getManifestProfiles().map(profile => ({
      text: profile.name,
      value: profile.id,
    }));

    this.lowLatencyProfiles.unshift({
      text: 'Default',
      value: this.hasLowLatency ? '' : 'Default',
    });
  }

  public async attached() {
    this.liveChannels.activeTab = '';
    super.attached();
  }

  @computedFrom('model.playback_profile_id', 'hasLowLatency', 'lowLatencyProfiles')
  public get playbackProfileId() {
    let id = this.model.playback_profile_id || '';
    if (id === '' && !this.hasLowLatency) {
      id = 'Default';
    }
    return id;
  }

  public set playbackProfileId(value) {
    if (!this.hasLowLatency) {
      return;
    }
    this.model.playback_profile_id = value;
  }

  // Need one for every field on the tab + one for the tab
  @computedFrom('model.desc')
  get descDirty() {
    if (!this.liveChannels.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.liveChannels.currentModel.desc !== this.model.desc;

    // Update field `isDirty` state
    this.fields.desc.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.external_id')
  get externalIdDirty() {
    if (!this.liveChannels.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.liveChannels.currentModel.external_id !== this.model.external_id;

    // Update field `isDirty` state
    this.fields.external_id.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.slicer_id')
  get slicerIdDirty() {
    if (!this.liveChannels.currentModel || !this.model) {
      return false;
    }

    const isDirty = this.liveChannels.currentModel.slicer_id !== this.model.slicer_id;

    // Update field `isDirty` state
    this.fields.slicer_id.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('model.playback_profile_id')
  get lowLatencyProfileIsDirty() {
    if (!this.liveChannels.currentModel || !this.model || !this.hasLowLatency) {
      return false;
    }

    const testId = this.model.playback_profile_id ? this.model.playback_profile_id : 0;

    if (!this.liveChannels.currentModel.playback_profile_id && testId === 0) {
      return false;
    }

    const isDirty = this.liveChannels.currentModel.playback_profile_id !== this.model.playback_profile_id;
    this.fields.playback_profile_id.isDirty = isDirty;
    return isDirty;
  }

  @computedFrom('descDirty', 'slicerIdDirty', 'externalIdDirty', 'lowLatencyProfileIsDirty')
  get tabDirty() {
    return this.slicerIdDirty || this.descDirty || this.externalIdDirty || this.lowLatencyProfileIsDirty;
  }
}
