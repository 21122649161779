import {Type} from 'class-transformer';
import {IsNumber, IsString, ValidateNested} from 'class-validator';

export class Wares {
  @IsString()
  url: string;

  @IsString()
  desc: string;

  @IsString()
  version: string;
}

export class IWares {
  @ValidateNested()
  @Type(() => Wares)
  slicer_linux_64?: Wares;

  @ValidateNested()
  @Type(() => Wares)
  slicer_mac_64?: Wares;

  @ValidateNested()
  @Type(() => Wares)
  slicer_mac_arm64?: Wares;

  @ValidateNested()
  @Type(() => Wares)
  slicer_windows_64?: Wares;
}

export class WaresResponse {
  @ValidateNested()
  @Type(() => IWares)
  public items?: IWares[];

  @IsNumber()
  public total_items: number;
}
