import {CTableActions} from '@bindable-ui/bindable';
import {autoinject, computedFrom, containerless} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {IContentFilterParams, ILibrarySelect} from 'apps/cms/routes/content/models/models';
import {ContentService} from 'apps/cms/routes/content/services/content';
import {LibraryService} from 'services/library-service';
import {Library, LibraryAsset} from 'services/models/library';

@autoinject()
@containerless()
export class AddAssetBody {
  @computedFrom('selectedAssets.length')
  get saveBtnState() {
    if (!this.selectedAssets.length) {
      return 'disabled';
    }

    return '';
  }

  public controller: any;
  public model: any;
  public selectedAssets: LibraryAsset[] = [];
  public selectedAssetsDuration: number = 0;
  public libraries: Library[];
  public params: IContentFilterParams = {};
  public selectedLibrary: string = null;
  public assetList: any[] = [];
  public isLoading: boolean = true;
  public assetThumbnailShow: boolean[];
  public assetPlayerShow: boolean[];
  public searchQuery: string = null;
  public scrollToLoad: boolean = true;

  public librarySelect: ILibrarySelect = {
    actions: {
      onChange: /* istanbul ignore next */ () => {
        /* istanbul ignore next */
        process.nextTick(() => this.librarySelected());
      },
    },
    meta: {},
    options: [],
    params: {
      order: 'desc',
      page: 1,
    },
    searchQuery: '',
  };

  /* ---------------------------------------------------------------------- *\
     *  Data-Table Actions
    \* ---------------------------------------------------------------------- */
  public tableActions: CTableActions = {
    onScrollBottom: /* istanbul ignore next */ async () => {
      await this.contentService.getMoreContent();
      this.assetList = this.contentService.assets;
      this.assetList.forEach(a => {
        a.adBreaksTipTriggerText = a.break_offsets.length.toString();
        a.adBreaksTipArrowPosition = 'leftEdge';
        a.adBreaksTipSide = 'top';
        a.adBreaksTipSize = 'small';
        a.adBreaksTipViewModel = PLATFORM.moduleName('resources/components/playlists/ad-break-tooltip/index');
      });
    },
    // rowClick: /* istanbul ignore next */ () => {
    //     /* istanbul ignore next */
    // },
  };

  public assetCols = [
    {
      checkChanged: /* istanbul ignore next */ row => /* istanbul ignore next */ this.trackSelectedAssets(row),
      colClass: 't30',
      colHeadName: 'selected',
      colHeadSelectedChanged: /* istanbul ignore next */ isChecked =>
        /* istanbul ignore next */ this.selectAllAssets(isChecked),
      colHeadSelectedVal: false,
      colHeadValue: '',
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
    },
    {
      colHeadName: 'title',
      colHeadValue: 'Name',
      colWidth: 270,
    },
    {
      colClass: 't120',
      colHeadName: 'adBreaks',
      colHeadValue: 'Ad Breaks',
      colWidth: 120,
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-tip/c-td-tip'),
    },
    {
      colClass: 't120',
      colHeadName: 'duration',
      colHeadValue: 'Duration',
      colWidth: 120,
      valueConverter: 'secondsToHms',
    },
  ];

  constructor(private libraryService: LibraryService, private contentService: ContentService) {}

  @computedFrom('libraries.length')
  get librarySelectMap() {
    if (this.libraries == null) return [];
    const filtered = this.libraries.map(library => ({
      disabled: false,
      text: library.desc,
      value: library.id,
    }));

    filtered.unshift({text: '───────────', value: null, disabled: true});
    filtered.unshift({text: 'All Content', value: null, disabled: false});
    return filtered;
  }

  @computedFrom('selectedAssets.length')
  get selectedAssetList() {
    const assets = _.filter(this.assetList, {selected: true});
    this.selectedAssetsDuration = assets.reduce((total, asset) => total + asset.duration, 0);
    this.assetThumbnailShow = Array(this.selectedAssets.length).fill(true);
    this.assetPlayerShow = Array(this.selectedAssets.length).fill(false);
    return assets;
  }

  public async activate(model) {
    this.libraries = await this.libraryService.getLibraries(true);
    this.isLoading = false;
    this.controller = model.controller;
    this.model = model.shared;
    this.model.selectedAssets = this.selectedAssets;
    this.librarySelected(this.params);
  }

  public trackSelectedAssets(asset) {
    if (asset.selected) {
      this.selectedAssets.push(asset);
    } else {
      _.remove(this.selectedAssets, item => item === asset);
    }
  }

  public selectAllAssets(isSelected: boolean) {
    _.forEach(this.assetList, asset => {
      asset.selected = isSelected;
    });
  }

  public async getPlayUrl(index: number) {
    const asset: LibraryAsset = this.selectedAssets[index];
    return this.contentService.getPlayURL(asset.id);
  }

  public async search(text: string) {
    this.scrollToLoad = false;
    this.params.search = text;
    const resp = await this.librarySelected(this.params);
    this.scrollToLoad = true;
    return resp;
  }

  private async librarySelected(params: IContentFilterParams = {}) {
    this.isLoading = true;
    this.assetList = await this.contentService.getContent(params);
    this.assetList.forEach(a => {
      a.adBreaksTipTriggerText = a.break_offsets.length.toString();
      a.adBreaksTipArrowPosition = 'leftEdge';
      a.adBreaksTipSide = 'top';
      a.adBreaksTipSize = 'small';
      a.adBreaksTipViewModel = PLATFORM.moduleName('resources/components/playlists/ad-break-tooltip/index');
    });
    this.isLoading = false;
  }
}
