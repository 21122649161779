import {ConfigParser} from 'resources/config/parser';
import {TextFile} from 'resources/file/text-file';
import {ConfigImportMode} from './models/config-import';
import {ConfigEditor as ConfigEditorBase} from '../editor';
import {Config} from './models/config';

export class ConfigEditor extends ConfigEditorBase {
  public config: Config;
  public tipExport: any;

  public async actionExport(fileName: string = 'config') {
    const file = this.config.input.table.exportFile(fileName, this.config.input.exportFormat);
    await TextFile.save(file);
    if (this.tipExport) this.tipExport.hide();
  }

  public async actionImport(mode: ConfigImportMode = ConfigImportMode.APPEND) {
    // retain the name of the item we have so that the import does not overwrite
    let name: string = '';
    if (
      this.config.input.table.rows !== null ||
      this.config.input.table.rows !== undefined ||
      this.config.input.table.rows.length > 0
    ) {
      const configDict = this.config.input.table.rows.find(param => param.key === 'name');
      if (configDict) {
        name = configDict.value;
      }
    }

    const value = await TextFile.open(ConfigParser.accepted);
    this.config.input.table.importString(value, mode);

    // retain the name of the item we have so that the import does not overwrite
    if (name !== '') {
      this.config.input.table.rows.every(row => {
        if (row.key === 'name') {
          row.value = name;
          return false; // break here
        }
        // keep looking for name...
        return true;
      });
    }
  }
}
