import {copyToClipboard} from '@bindable-ui/bindable';
import {DialogService, DialogSettings} from 'aurelia-dialog';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, bindable, containerless, PLATFORM} from 'aurelia-framework';
import * as moment from 'moment';
import {Toast} from 'resources/toast/toast';

import {Callback, DeliveryStatus} from '../models/ad-server-debug-response';

const DATE_FIELDS = [
  'created_at',
  'updated_at',
  'fired_at',
];
const NUMERIC_FIELDS = [
  'adIndex',
  'breakIndex',
];

@autoinject()
@containerless()
export class CallbackDetail {
  @bindable
  public callbacks: Callback[] = [];

  public displayColumns: any[];
  public downloadColumns: any[];
  public order: string = 'created_at';

  constructor(public eventAggregator: EventAggregator, public dialogService?: DialogService) {
    this.displayColumns = [
      {
        colHeadName: 'status',
        colHeadValue: 'Delivered',
        display: true,
      },
      {
        colHeadName: 'created_at',
        colHeadValue: 'Date Created',
        display: true,
        isDate: true,
        class: 'col-min-width-21',
      },
      {
        colHeadName: 'fired_at',
        colHeadValue: 'Date Fired',
        display: true,
        class: 'col-min-width-21',
        isDate: true,
      },
      {
        colHeadName: 'name',
        colHeadValue: 'Name',
        display: true,
      },
      {
        colHeadName: 'url',
        colHeadValue: 'Beacon URL',
        display: true,
        class: 'col-min-width-12 col-max-width-14',
        allowCopy: true,
        truncateDisplay: true,
      },
      {
        colHeadName: 'headers',
        colHeadValue: 'Headers',
        display: true,
        class: 'col-min-width-8 col-max-width-12',
      },
      {
        colHeadName: 'breakIndex',
        colHeadValue: 'Break #',
        display: true,
        class: 'col-min-width-9',
      },
      {
        colHeadName: 'adIndex',
        colHeadValue: 'Ad #',
        display: true,
        class: 'col-min-width-8',
      },
      {
        colHeadName: 'creative_id',
        colHeadValue: 'Creative Id',
        display: true,
        class: 'col-min-width-14',
      },
      {
        colHeadName: 'ad_id',
        colHeadValue: 'Ad Id',
        display: true,
        class: 'col-min-width-9',
      },
      {
        colHeadName: 'initial_ad_id',
        colHeadValue: 'Initial Ad Id',
        display: true,
        class: 'col-min-width-10',
      },
      {
        colHeadName: 'beacon_type',
        colHeadValue: 'Type',
        display: true,
      },
      {
        colHeadName: 'response_code',
        colHeadValue: 'Code',
        display: true,
        class: 'col-min-width-9',
      },
    ];
    this.downloadColumns = [
      {
        colHeadName: 'status',
        colHeadValue: 'Delivered',
      },
      {
        colHeadName: 'created_at',
        colHeadValue: 'Date Created',
      },
      {
        colHeadName: 'fired_at',
        colHeadValue: 'Date Fired',
      },
      {
        colHeadName: 'name',
        colHeadValue: 'Name',
      },
      {
        colHeadName: 'url',
        colHeadValue: 'Beacon URL',
      },
      {
        colHeadName: 'headers',
        colHeadValue: 'Headers',
      },
      {
        colHeadName: 'breakIndex',
        colHeadValue: 'Break #',
      },
      {
        colHeadName: 'adIndex',
        colHeadValue: 'Ad #',
      },
      {
        colHeadName: 'creative_id',
        colHeadValue: 'Creative Id',
      },
      {
        colHeadName: 'ad_id',
        colHeadValue: 'Ad Id',
      },
      {
        colHeadName: 'initial_ad_id',
        colHeadValue: 'Initial Ad Id',
      },
      {
        colHeadName: 'beacon_type',
        colHeadValue: 'Type',
      },
      {
        colHeadName: 'response_code',
        colHeadValue: 'Code',
      },

      {
        colHeadName: 'job_id',
        colHeadValue: 'Job Id',
      },
      {
        colHeadName: 'channel_guid',
        colHeadValue: 'Channel Id',
      },
      {
        colHeadName: 'session_id',
        colHeadValue: 'Session Id',
      },
      {
        colHeadName: 'asset_id',
        colHeadValue: 'Asset Id',
      },
      {
        colHeadName: 'id',
        colHeadValue: 'Beacon Id',
      },
    ];
  }

  public getRowState(status: string) {
    // return a state based on the status of the beacon
    if (status === DeliveryStatus.Success) {
      return 'success';
    }
    if (status === DeliveryStatus.Error) {
      return 'danger';
    }
    return 'neutral';
  }

  public copyText(copyTextValue: string) {
    return copyToClipboard(copyTextValue, Toast.success('Copied to clipboard'));
  }

  public viewHeaders(headers: any) {
    this.headersModal.model.bodyModel = headers;
    this.headersModal.model.keys = Object.keys(headers);
    this.headersModal.model.title = `Beacon Request Headers`;
    this.dialogService.open(this.headersModal);
  }

  public showHideColumns($event) {
    const colHead = $event.detail.item.id;
    this.displayColumns.forEach(dlc => {
      if (dlc.colHeadName === colHead) {
        dlc.display = !dlc.display;
      }
    });
  }

  public sortColumn($event) {
    // get the id of the item we are sorting and manage sort order
    const sortById = $event.target.id;

    if (!sortById) {
      return;
    }

    const key = this.order.replace('-', '');
    if (sortById === key) {
      //  we have the same key - just flip the order
      const reverse = this.order.indexOf('-') > -1;
      this.order = reverse ? key : `-${this.order}`;
      this.sortItems(key, reverse);
    } else {
      // reset the current sort order key
      let colHeadElement: HTMLElement;
      this.order = `-${sortById}`;

      this.sortItems(sortById, false);
      this.displayColumns.forEach(col => {
        if (col.display && col.colHeadName !== sortById) {
          colHeadElement = document.getElementById(col.colHeadName);
          colHeadElement.removeAttribute('sort-direction');
        }
      });
    }
  }

  public sortItems(key: string, reverse: boolean) {
    const numberSorting = NUMERIC_FIELDS.includes(key);
    const dateSorting = DATE_FIELDS.includes(key);

    this.callbacks.sort((a, b) => {
      let aVal = a[key];
      let bVal = b[key];

      if (numberSorting) {
        // coerce negative value when undefined
        if (Number.isNaN(parseInt(aVal, 10))) aVal = -1;
        if (Number.isNaN(parseInt(aVal, 10))) bVal = -1;
      } else if (dateSorting) {
        // convert to sortable date value IF IT is defined
        if (aVal && aVal !== undefined && aVal !== null) {
          aVal = moment(aVal);
        } else {
          aVal = '';
        }
        if (bVal && bVal !== undefined && bVal !== null) {
          bVal = moment(bVal);
        } else {
          bVal = '';
        }
      } else {
        // coerce all other values as string values handling undefined
        // and also convert to lowercase to ensure proper sorting
        aVal = `${aVal}`.toLowerCase();
        bVal = `${bVal}`.toLocaleLowerCase();
      }

      if (!reverse) {
        return bVal > aVal ? -1 : 1;
      }
      return bVal > aVal ? 1 : -1;
    });
  }

  public statusMapping = {
    [DeliveryStatus.Error]: 'Failed',
    [DeliveryStatus.Success]: 'Completed',
    [DeliveryStatus.Unknown]: 'Pending',
  };

  public prettyStatus(status: string) {
    return _.capitalize(this.statusMapping[status] || status);
  }

  private headersModal: DialogSettings = {
    model: {
      bodyViewModel: PLATFORM.moduleName('apps/cms/routes/ad-server-debug/table-views/callback-headers'),
      size: 'auto',
    },
    viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
  };
}
