import {computedFrom, BindingEngine, Container} from 'aurelia-framework';

export interface ITrackedModel {
  isDirty: boolean;
  isNew: boolean;
  isValid?: boolean;
  checkDirty: () => boolean;
  dirtyParams: () => any;
  get: (key) => any;
  getCanonical: (key) => any;
  reset: () => void;
  updateCanonical: () => void;
}

export function trackedModel<T>({SuperClass, model, trackedKeys}): T {
  class TrackedModel extends SuperClass implements ITrackedModel {
    private _canonical: any = {};
    private _trackedKeys: string[] = [];
    private _isDirty: boolean = false;
    private bindingEngine: BindingEngine;

    constructor(_model: any, ..._trackedKeys: string[]) {
      super();

      const container = new Container();
      this.bindingEngine = container.get(BindingEngine);

      this._trackedKeys = _trackedKeys.concat('id');

      this.assignValues(_model);
      this.updateCanonical();
      this.bindObservers();
    }

    @computedFrom('_isDirty', 'isNew')
    public get isDirty(): boolean {
      return this._isDirty || this.isNew;
    }

    @computedFrom('_canonical.id')
    public get isNew(): boolean {
      return !this._canonical.id;
    }

    public checkDirty(): boolean {
      const tracked = this.trackedValues(this);
      const isDirty = !_.isEqual(tracked, this._canonical);

      this._isDirty = isDirty;
      return this._isDirty;
    }

    public dirtyParams() {
      const diff = {};

      if (this.isDirty) {
        const current = this.trackedValues(this);

        this._trackedKeys.forEach(k => {
          if (!_.isEqual(current[k], this._canonical[k])) {
            diff[k] = current[k];
          }
        });
      }

      return diff;
    }

    public get(key) {
      return this[key];
    }

    public getCanonical(key) {
      return this._canonical[key];
    }

    public reset() {
      const keys = Object.keys(this._canonical);

      keys.forEach(key => {
        this[key] = this._canonical[key];
      });
    }

    public updateCanonical() {
      this._canonical = this.trackedValues(this);
      this.checkDirty();
    }

    private assignValues(_model: any) {
      Object.assign(this, _model);
    }

    private bindObservers() {
      this._trackedKeys.forEach(key => {
        this.bindingEngine.propertyObserver(this, key).subscribe(() => this.checkDirty());
      });
    }

    private trackedValues(obj: any) {
      const canonical = {};

      this._trackedKeys.concat(['id']).forEach(key => {
        let value = obj[key];

        if (Array.isArray(value)) {
          value = value.sort();
        }

        canonical[key] = value;
      });

      return canonical;
    }
  }

  return new TrackedModel(model, ...trackedKeys) as any;
}
