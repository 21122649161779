import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

import {SharedNav} from '@bindable-ui/bindable';

@autoinject()
export class RealTimeEventsIndex {
  public router: Router;
  public routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: 'liveEventList',
      nav: false,
      route: '',
      title: 'Live Events',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: 'liveEventSingle',
      nav: false,
      route: ':id',
      title: 'Live Event',
    },
  ];

  constructor(public sharedNav: SharedNav) {}

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }
}
