export function getQueryParams() {
    const qs = document.location.search.split('+').join(' ');

    const params = {};
    const re = /[?&]?([^=]+)=([^&]*)/g;
    let tokens = re.exec(qs);

    while (tokens) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        tokens = re.exec(qs);
    }

    return params;
}

export function getQueryParamsW() {
    const qs = window.location.search.split('+').join(' ');

    const params = {};
    const re = /[?&]?([^=]+)=([^&]*)/g;
    let tokens = re.exec(qs);

    while (tokens) {
        params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        tokens = re.exec(qs);
    }

    return params;
}
