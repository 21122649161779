import {BreakOffset} from 'apps/cms/routes/content/models/asset-model';
import {BreakType} from './constants';
import {IAdBreakRow} from './interfaces';

const hhMMRegEx = /^\d{1,2}(:[0-5][0-9]){0,2}$/;
const hmRegEx = /^((\d{1,2})h)?\s?((\d{1,3})m)?\s?((\d{1,4})s)?$/;

function calculateAdBreakDuration(index, totalAdBreakDuration, totalOffsets, duration) {
  if (index === totalOffsets - 1) {
    return Math.floor(duration + totalAdBreakDuration - duration * totalOffsets);
  }

  return duration;
}

function mapAdBreakRow(offset: BreakOffset, duration, isEditable, index) {
  const breakNumber = index + 1;

  const row: IAdBreakRow = {
    breakNumber,
    duration,
    breakType: offset.break_type as BreakType,
    name: offset.name,
    offset: offset.offset,
    state: isEditable ? '' : 'disabled',
    enabled: true,
  };

  return row;
}

export class DurationTotalError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DurationTotalError';
  }
}

export class DurationTools {
  static ToSeconds(duration): number {
    let durationInSeconds = 0;
    const durationAsNumber = Number(duration);

    let hours = '00';
    let minutes = '00';
    let seconds = '00';

    if (durationAsNumber >= 0) {
      durationInSeconds = durationAsNumber;
    } else if (/:/.test(duration)) {
      if (hhMMRegEx.test(duration)) {
        [
          hours = '00',
          minutes = '00',
          seconds = '00',
        ] = duration.split(':');
      } else {
        throw new DurationTotalError('Format as HH:MM or HH:MM:SS');
      }
    } else if (/(\d*h)|(\d*m)|(\d*s)/.test(duration)) {
      if (hmRegEx.test(duration)) {
        [
          ,
          ,
          hours = '00',
          ,
          minutes = '00',
          ,
          seconds = '00',
        ] = duration.match(hmRegEx); // [FullText, HourText, Hours, MinuteText, Minutes, SecondText, Seconds]
      } else {
        throw new DurationTotalError('Format as HM (example: 2h 10m)');
      }
    } else {
      throw new DurationTotalError('Invalid time format');
    }

    if (!durationAsNumber) {
      durationInSeconds = parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
    }

    return durationInSeconds;
  }

  static EqualizedBreakOffsets(
    breakOffsets: BreakOffset[] = [],
    totalAdBreakDuration: number = 0,
    isEditable = false,
  ): IAdBreakRow[] {
    const breakOffsetCount = breakOffsets.length;

    if (breakOffsetCount === 0) {
      return [];
    }

    const duration = Math.floor(totalAdBreakDuration / breakOffsetCount);

    return breakOffsets
      .filter(b => b.break_type === BreakType.AD)
      .map((offset, index) =>
        mapAdBreakRow(
          offset,
          calculateAdBreakDuration(index, totalAdBreakDuration, breakOffsetCount, duration),
          isEditable,
          index,
        ),
      );
  }
}
