export function elementIsVisibleInViewport(el, partiallyVisible = false): boolean {
  const {top, left, bottom, right} = el.getBoundingClientRect();
  const {innerHeight, innerWidth} = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
}

export function getElementsInFocus(selector): string[] {
  const rows = document.querySelectorAll(selector);
  const visibleGroups = [];

  rows.forEach((r: any) => {
    if (elementIsVisibleInViewport(r)) {
      visibleGroups.push(r.dataset.id);
    }
  });

  return visibleGroups;
}
