/* eslint prefer-promise-reject-errors: "warn" */

import {CmsHttpClient} from 'services/cms-http-client';
import {Notification} from 'resources/notification/service';
import {inject} from 'aurelia-framework';

/**
 * This class makes the necessary API calls to allow switching of accounts.  There are the
 * following API calls:
 *
 *      getSwitchUsersListP - /session/switchusers/list/
 *      doSwitchUserP - /session/switchusers/switch/'
 */
@inject(CmsHttpClient, Notification)
export class AccountData {
  constructor(cmsHttpClient, notification) {
    this.httpClient = cmsHttpClient.httpClient;
    this.notification = notification;
    this.urlSwitchUsersList = '/session/switchusers/list/';
    this.urlSwitchUser = '/session/switchusers/switch/';
    this.form_url_encoded = 'application/x-www-form-urlencoded';
    this.dataSwitchUsersList = null;
  }

  /**
   * This function handles any errors from API calls.
   */
  checkResponseForError(res) {
    if ('error' in res) {
      if ('msg' in res) {
        this.error = res.msg;
        this.notification.error(res.msg);
      } else {
        this.error = 'An Unknown Error Has Occurred in AccountData';
      }
      if (res.error !== 0) {
        return true;
      }
    }
    return false;
  }

  /**
   * This gets the entire list of users that the end user has access to.
   */
  getSwitchUsersListP() {
    const params = 'minimal=true';
    const errMsg = 'Error retrieving switch users list.';
    const promise = new Promise((resolve, reject) => {
      this.httpClient
        .fetch(this.urlSwitchUsersList, {
          method: 'post',
          headers: {
            'Content-Type': this.form_url_encoded,
          },
          type: this.form_url_encoded,
          body: params,
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (!this.checkResponseForError(res)) {
                this.dataSwitchUsersList = res;
                resolve(this.dataSwitchUsersList);
              } else {
                this.notification.error(errMsg, 'AccountData (response)');
                reject();
              }
            });
          } else {
            this.notification.error(errMsg, 'AccountData (error)');
            reject();
          }
        })
        .catch(error => {
          this.notification.error(errMsg, 'AccountData (catch)');
          reject(error);
        });
    });
    return promise;
  }

  /**
   * This will switch to the appropriate account.  The CMS API only needs either username or id.
   * When switching back to the original owner, we don't have the id, so we just pass the
   * username.  In all other cases, we pass both the username and password.
   */
  doSwitchUserP(username, id) {
    const promise = new Promise((resolve, reject) => {
      let params = '';
      if (username && id) {
        params = `id=${encodeURIComponent(id)}&username=${encodeURIComponent(username)}`;
      } else {
        params = `username=${encodeURIComponent(username)}`;
      }

      window.localStorage.removeItem('LiveEvents');
      this.httpClient
        .fetch(this.urlSwitchUser, {
          method: 'post',
          headers: {
            'Content-Type': this.form_url_encoded,
          },
          type: this.form_url_encoded,
          body: params,
        })
        .then(resp => {
          if (resp.ok) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
    return promise;
  }
}
