import 'fetch';

import {DialogService} from 'aurelia-dialog';
import {autoinject, bindable, bindingMode} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

@autoinject()
export class VSlatePicker {
    @bindable
    public title: string;

    @bindable({defaultBindingMode: bindingMode.twoWay})
    public slateId: string;

    @bindable({defaultBindingMode: bindingMode.oneWay})
    public slateLibrarySet: string[];

    @bindable({defaultBindingMode: bindingMode.twoWay})
    public slateDesc: string = 'Account Default Slate';

    @bindable({defaultBindingMode: bindingMode.twoWay})
    public slatePoster: string;

    @bindable({defaultBindingMode: bindingMode.oneWay})
    public libraries: object[];

    @bindable
    public isEnabled: boolean = true;

    @bindable
    public getPlayerUrl: () => Promise<string>;

    @bindable({defaultBindingMode: bindingMode.twoWay})
    public displayThumbnail: boolean = true;

    @bindable({defaultBindingMode: bindingMode.twoWay})
    public showingThumbnail: boolean = true;

    @bindable({defaultBindingMode: bindingMode.twoWay})
    public displayPlayer: boolean = false;

    constructor(private dialogService: DialogService) {}

    public async playerUrlFn() {
        if (_.isFunction(this.getPlayerUrl)) {
            return await this.getPlayerUrl();
        }

        return Promise.reject();
    }

    public getLibrariesWithSelected() {
        const libraries = _.cloneDeep(this.libraries);
        if (libraries.length && this.slateLibrarySet.length) {
            // reset all selected to false
            libraries.forEach(l => {
                (l as any).selected = false;
            });

            libraries.forEach(l => {
                // set the first match as selected library.
                if (this.slateLibrarySet.indexOf((l as any).value) > -1) {
                    (l as any).selected = true;
                    return false;
                }
            });
        }
        return libraries;
    }

    /* istanbul ignore next */
    // Ignoring in unit tests because this is a 3rd party service that includes tests.
    public slatePickerModal() {
        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        libraries: this.getLibrariesWithSelected(),
                        slateDesc: this.slateDesc,
                        slateId: this.slateId,
                        slatePoster: this.slatePoster,
                    },
                    bodyText: 'Modal Body',
                    bodyViewModel: PLATFORM.moduleName('resources/components/v-slate-picker/modals/body'),
                    footerEnable: true,
                    footerModel: {},
                    footerText: 'footer',
                    footerViewModel: PLATFORM.moduleName('resources/components/v-slate-picker/modals/footer'),
                    sharedModel: {},
                    size: 'full',
                    title: `Change ${this.title}`,
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed(response => {
                if (response.output && response.output.id) {
                    this.slateId = response.output.id;
                    this.slateDesc = response.output.title;
                    this.slatePoster = response.output.poster_url;
                } else if (!response.wasCancelled) {
                    this.slateId = null;
                    this.slateDesc = '[Account Setting]';
                    this.slatePoster = '';
                } else {
                    return;
                }

                // Reset to thumbnail
                this.displayPlayer = false;
                this.displayThumbnail = true;
                this.showingThumbnail = true;
            });
    }
}
