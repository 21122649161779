import {customElement} from 'aurelia-templating';
import {LynkDialog} from '@uplynk/lynk-design';
import {ConfirmationBase} from './base';

@customElement('confirmation-dialog')
export class ConfirmationDialog extends ConfirmationBase {
    public dialog: LynkDialog;

    public handleCancel() {
        this.dialog.hide();
        super.handleCancel();
    }

    public handleRequestClose(event: CustomEvent) {
        if (event.detail.source === 'overlay') {
            event.preventDefault();
        }
        return true;
    }

    public handleSubmit(event: Event) {
        this.dialog.hide();
        super.handleSubmit(event);
    }

    public handleTrigger() {
        this.dialog.show();
    }
}
