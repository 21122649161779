import {inject} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';

import {LiveEventsService} from '../services/live-events';

@inject(LiveEventsService, BindingSignaler)
export class EventAssetsActions {
    constructor(liveEventsService, bindingSignaler) {
        this.bindingSignaler = bindingSignaler;
    }

    activate(model) {
        this.asset = model.record;
    }

    toggleMarkForDelete(asset) {
        asset.deleted = !asset.deleted;
        this.bindingSignaler.signal('data-table--tr-class');
    }
}
