import {LogManager} from 'aurelia-framework';
import {validate, ValidationError} from 'class-validator';
import {ClippingConfig} from '../models/clipping-config';

const log = LogManager.getLogger('clipping-util');

export class ClippingUtil {
    public static VALUE_MAX_LENGTH = 1000;
    public static URL_MAX_LENGTH = 10000;
    public static NETWORK_ID_MAX_LENGTH = 64;

    public static validate(config: ClippingConfig): Promise<any> {
        return new Promise((resolve, reject) => {
            validate(config, {skipMissingProperties: true})
                .then((errs: ValidationError[]) => {
                    if (errs.length) {
                        errs.forEach(err => log.error(`${err}`));
                    }
                    resolve(errs);
                })
                .catch(er => {
                    log.error('Validation Error:', er);
                    reject(new Error('Validation Error'));
                });
        });
    }
}
