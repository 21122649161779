import {ConfigErrors} from './config-errors';
import {ConfigInfo} from './config-info';
import {ConfigInput} from './config-input';
import {ConfigOptions} from './config-options';

export class Config {
  public errors = new ConfigErrors();
  public info = new ConfigInfo();
  public input = new ConfigInput();
  public options = new ConfigOptions();
}
