import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import {LocalStorageHelper, SharedNav} from '@bindable-ui/bindable';

import {TableSortingTools} from 'utils/table-sorting-tools';
import {LiveChannelsService} from '../services/live-channels';

const LOCAL_STORAGE_KEY = 'live-channel-default-view';

@autoinject()
export class ChannelsList {
  public hiddenColumns: string[] = [];
  public viewStyle: string = LocalStorageHelper.loadOrDefault(LOCAL_STORAGE_KEY, 'grid');
  public currentSort: string = '';
  public colModified;
  public showSchedulingInfoAlert: boolean = false;
  public pageLayoutElem: any;
  public availableCols: any[] = [
    {
      colHeadName: 'source_desc',
      colHeadValue: 'Source Description',
      selected: true,
    },
    {
      colHeadName: 'external_id',
      colHeadValue: 'External ID',
      selected: true,
    },
  ];

  public createChannelActions = {
    onHide: () => {
      this.liveChannels.createErrMsgName = '';
      this.liveChannels.createErrMsgSlicerId = '';
      this.liveChannels.newChannelName = '';
      this.liveChannels.newSlicerId = '';
      this.liveChannels.newChannelScheduler = null;
      this.liveChannels.newChannelIsFast = false;
      this.liveChannels.createChannelForm.reset();
    },
  };

  private pollTracker;

  constructor(private liveChannels: LiveChannelsService, private router: Router, private sharedNav: SharedNav) {}

  public activate() {
    if (this.liveChannels.params && this.liveChannels.params.order) {
      this.currentSort = this.liveChannels.params.order;
    }

    this.init();
  }

  public attached() {
    this.sharedNav.setActive(0, 0);
    this.liveChannels.cleanUpActive();
  }

  public detached() {
    if (this.pollTracker) {
      clearInterval(this.pollTracker);
    }
    this.liveChannels.selectedChannels = [];
  }

  public toggleView() {
    if (this.viewStyle === 'grid') {
      this.viewStyle = 'list';
    } else {
      this.viewStyle = 'grid';
    }

    LocalStorageHelper.save(LOCAL_STORAGE_KEY, this.viewStyle);
  }

  public goToSingle(id) {
    this.router.navigate(`/live-channels/channels/${id}`);
  }

  public async getMetadata() {
    await this.liveChannels.getMetadata();
    if (this.liveChannels.currentMetadata) {
      this.hiddenColumns = this.liveChannels.currentMetadata.hiddenColumns;
      this.hiddenColumns.forEach(key => {
        this.availableCols.forEach((column, index) => {
          if (column.colHeadName === key) {
            this.availableCols[index].selected = false;
          }
        });
      });
    }
  }

  public showHideColumn(col) {
    col.selected = !col.selected;

    if (col.selected) {
      const idx = this.hiddenColumns.indexOf(col.colHeadName);
      if (idx !== -1) {
        this.hiddenColumns.splice(idx, 1);
      }
    } else {
      this.hiddenColumns.push(col.colHeadName);
    }

    this.liveChannels.currentMetadata.hiddenColumns = this.hiddenColumns;
    this.liveChannels.saveMetadata(this.liveChannels.currentMetadata);
    this.colModified = new Date();
  }

  public toggleChannelSelected(channel) {
    channel.selected = !channel.selected;
    this.liveChannels.trackSelectedChannel(channel);
  }

  public async sortChannels(key: string, defaultSortDirection: string = null) {
    const {order} = this.liveChannels.params;

    const newOrder = TableSortingTools.sort(key, order, defaultSortDirection);

    this.currentSort = newOrder;
    this.liveChannels.getChannels({order: newOrder});
  }

  private async init() {
    try {
      await this.liveChannels.getChannels({order: this.currentSort});
      await this.getMetadata();

      this.pollTracker = setInterval(async () => {
        if (!document.hidden) {
          await this.channelsPoll();
        }
      }, 8000);
    } catch (e) {
      // Throw an error notification or something
    }
  }

  private async channelsPoll() {
    let scrollElem: Element | null = null;
    let channelItemElems: HTMLCollectionOf<Element>;

    if (this.pageLayoutElem) {
      scrollElem = this.pageLayoutElem.shadowRoot.querySelector('.lynk-page-layout__main');
    }

    let visibleChannels: string[] = [];

    if (this.viewStyle === 'grid') {
      channelItemElems = document.getElementsByClassName('channel-card');
    } else {
      channelItemElems = document.getElementsByClassName('channel-row');
    }

    // Make sure we have something
    if (!scrollElem || !channelItemElems.length) {
      return;
    }

    const scrollElemRect = scrollElem.getBoundingClientRect();

    Array.from(channelItemElems).forEach(elem => {
      const elemRect = (elem as HTMLElement).getBoundingClientRect();

      if (!(elemRect.top > scrollElemRect.bottom || elemRect.bottom < scrollElemRect.top)) {
        visibleChannels.push((elem as HTMLElement).id);
      }
    });

    // Remove anything that isn't in the current channels list
    _.remove(visibleChannels, channelId => !_.find(this.liveChannels.channels, {id: channelId}));

    // Make sure values are unique
    visibleChannels = _.uniq(visibleChannels);

    await this.liveChannels.getChannelUpdates(visibleChannels);
  }

  @computedFrom('liveChannels.selectedChannels.length', 'liveChannels.isDeleting')
  get deleteBtnState() {
    if (this.liveChannels && this.liveChannels.isDeleting) {
      return 'thinking';
    }
    if (this.liveChannels && this.liveChannels.selectedChannels.length) {
      return '';
    }

    return 'disabled';
  }

  @computedFrom('currentSort')
  public get sortedDesc(): number {
    return TableSortingTools.sorted('desc', this.currentSort);
  }

  @computedFrom('currentSort')
  public get sortedExid(): number {
    return TableSortingTools.sorted('external_id', this.currentSort);
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showSourceCol(): boolean {
    return !this.hiddenColumns.includes('source_desc');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showExidCol(): boolean {
    return !this.hiddenColumns.includes('external_id');
  }
}
