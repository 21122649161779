exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Kx1o{text-align:left;z-index:900}@media screen and (max-width:1024px){._3ww0B{display:none}}@media screen and (min-width:1024px){._3mcNq{display:none}}", ""]);

// exports
exports.locals = {
	"userMenu": "_2Kx1o",
	"userMenu__username": "_3ww0B",
	"userMenu__menuUsername": "_3mcNq"
};