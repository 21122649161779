import {bindable, computedFrom} from 'aurelia-framework';
import {Status} from '../models/ad-server-debug-response';
import statusBoxCss from './status-box.css';

const iconStatusMapping = {
    [Status.Complete]: 'checkmark',
    [Status.Fail]: 'warning',
    completeWithWarning: 'warning',
    warning: 'warning',
    [Status.Processing]: 'log',
    [Status.Pending]: 'log',
    [Status.Unknown]: '',
};

const tipBarColorMapping = {
    [Status.Complete]: 'var(--c_secondaryMain)',
    [Status.Fail]: 'var(--c_primaryLight)',
    completeWithWarning: 'var(--c_secondaryMain)',
    warning: 'var(--c_subTwoMain)',
    [Status.Processing]: 'var(--c_subOneMain)',
    [Status.Pending]: 'var(--c_subOneMain)',
    [Status.Unknown]: 'var(--c_subOneMain)',
};

export class StatusBox {
    public styles = statusBoxCss;
    @bindable public status: string = Status.Complete;
    @bindable public tipContent: string;

    @computedFrom('status')
    public get icon(): string {
        return iconStatusMapping[this.status];
    }

    @computedFrom('status')
    public get tipBarColor(): string {
        return tipBarColorMapping[this.status];
    }
}
