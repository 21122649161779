import {autoinject, computedFrom} from 'aurelia-framework';
import * as dragula from 'dragula';

import {basicSetup} from 'decorators';

import {Notification} from 'resources/notification/service';
import {IMarkerTemplatesNew} from '../models/live-events-settings';
import {LiveEventsService} from '../services/live-events-service';

const SELECTOR = '.drag-drop-table tbody';

@basicSetup
@autoinject()
export class MarkerTemplates {
  public markerTemplates: IMarkerTemplatesNew[] = [];
  public activeTemplateIndex = null;
  public newMarkerTemplateError: string = null;
  public newMarkerTemplate: string = null;
  public newType: string = null;
  public newTypeError: string = null;
  public addTemplatePopover: any;
  public addTypePopover: any;
  public changedAt = null;
  public loadingError: boolean = false;
  public lastModified: number = -1;
  public lastModifiedParent: number = -1;

  private drake: any[] = [];

  constructor(private liveEventsService: LiveEventsService, public notification: Notification) {}

  public deactivate() {
    if (this.drake.length) {
      _.forEach(this.drake, dragndrop => dragndrop.destroy());
    }
  }

  public async getData() {
    try {
      const res = await this.liveEventsService.getLiveEventsSettings();
      if (res.marker_templates_new && res.marker_templates_new.length) {
        this.markerTemplates = _.cloneDeep(res.marker_templates_new);
        if (this.markerTemplates.length) {
          this.setTemplateActive(0);
        }
      } else {
        this.markerTemplates = [];
      }
    } catch (e) {
      this.loadingError = true;
    }
  }

  public async saveData() {
    let hasDupes = false;
    let hasTypeDupes = false;
    let emptyString = false;

    _.forEach(this.markerTemplates, template => {
      if (template.markers.length !== _.uniqBy(template.markers, 'type').length) {
        hasTypeDupes = true;
        return false;
      }
      _.forEach(template.markers, marker => {
        if (!_.every(marker.tags)) {
          emptyString = true;
          return false;
        }
        if (marker.tags.length !== _.uniq(marker.tags).length) {
          hasDupes = true;
          return false;
        }
        return false;
      });
      return false;
    });

    if (hasTypeDupes) {
      this.notification.error('A marker template has duplicate type names.');
      return;
    }

    if (hasDupes) {
      this.notification.error('A marker template has duplicate tag names.');
      return;
    }

    if (emptyString) {
      this.notification.error('Tag values cannot be empty.');
      return;
    }

    const clonedTemplates = _.cloneDeep(this.markerTemplates);

    _.forEach(clonedTemplates, template => {
      _.forEach(template.markers, marker => {
        delete marker.newTag;
        delete marker.newTagError;
      });
    });

    try {
      const eventsData = _.cloneDeep(this.liveEventsService.cache);
      eventsData.marker_templates_new = _.cloneDeep(clonedTemplates);
      await this.liveEventsService.saveLiveEvents(eventsData);

      let activeTemplateName = null;

      if (this.activeTemplateIndex > -1 && this.markerTemplates[this.activeTemplateIndex]) {
        activeTemplateName = this.markerTemplates[this.activeTemplateIndex].name;
      }

      this.markerTemplates = _.cloneDeep(this.liveEventsService.cache.marker_templates_new);

      if (activeTemplateName) {
        const index = _.findIndex(this.markerTemplates, {name: activeTemplateName});

        if (index > -1) {
          this.setTemplateActive(index);
        }
      }

      this.notification.success('Changes saved successfully.');
    } catch (e) {
      this.notification.error(`${e}`);
    }
  }

  public initNewTemplate() {
    this.newMarkerTemplateError = null;
    this.newMarkerTemplate = null;
  }

  public createTemplate() {
    this.newMarkerTemplateError = null;

    this.newMarkerTemplate = _.trim(this.newMarkerTemplate);

    if (!this.newMarkerTemplate) {
      this.newMarkerTemplateError = 'A template name is required.';
      return;
    }

    const isDupe = _.find(this.markerTemplates, {name: this.newMarkerTemplate});

    if (isDupe) {
      this.newMarkerTemplateError = 'That template name is already used.';
      return;
    }

    if (this.newMarkerTemplate.length > 32) {
      this.newMarkerTemplateError = 'Template names must be less than 32 characters';
      return;
    }

    this.markerTemplates.push({
      markers: [],
      name: this.newMarkerTemplate,
    });

    let activeTemplateName = null;

    if (this.activeTemplateIndex > -1 && this.markerTemplates[this.activeTemplateIndex]) {
      activeTemplateName = this.markerTemplates[this.activeTemplateIndex].name;
    }

    this.markerTemplates = _.sortBy(this.markerTemplates, template => template.name.toLowerCase());

    if (activeTemplateName) {
      const index = _.findIndex(this.markerTemplates, {name: activeTemplateName});

      if (index > -1) {
        this.setTemplateActive(index);
      }
    }

    this.changedAt = new Date();
    if (this.addTypePopover) {
      this.addTemplatePopover.hide();
    }
  }

  public setTemplateActive(index) {
    this.activeTemplateIndex = index;
    this.lastModified = -1;
    this.lastModifiedParent = -1;

    _.forEach(this.markerTemplates[index].markers, marker => {
      marker.newTag = null;
      marker.newTagError = null;
    });

    this.setupDragDrop();

    // Stupid hack to prevent last input to get focus
    _.defer(() => $('input').blur());
  }

  /* istanbul ignore next */
  public setupDragDrop() {
    if (this.drake.length) {
      _.forEach(this.drake, dragndrop => dragndrop.destroy());
    }

    _.defer(() => {
      _.forEach(this.markerTemplates[this.activeTemplateIndex].markers, (_type, index) => {
        const drake = dragula([document.querySelector(`[id='${index}-table'] > ${SELECTOR}`)], {
          moves: ({}, {}, handle) => window.getComputedStyle(handle, ':before').getPropertyValue('cursor') === 'grab',
        });

        drake.on('drop', (_el, target) => {
          const keyOrder = [];
          [].forEach.call(target.children, li => {
            keyOrder.push(li.getAttribute('data-name'));
          });

          this.lastModified = -1;
          this.lastModifiedParent = -1;

          this.markerTemplates[this.activeTemplateIndex].markers[index].tags.splice(
            0,
            this.markerTemplates[this.activeTemplateIndex].markers[index].tags.length,
            ...keyOrder,
          );
          this.changedAt = new Date();
        });

        this.drake.push(drake);
      });
    });
  }

  public tagChanged(tag, index, parentIndex) {
    // Check to see if this tag really changed - because it is jacking with cursor jumping when tab is pressed
    // Also - this will tone down the changed events from firing for every silly key
    if (this.markerTemplates[this.activeTemplateIndex].markers[parentIndex].tags[index] === tag) {
      return;
    }
    this.markerTemplates[this.activeTemplateIndex].markers[parentIndex].tags.splice(index, 1, tag);
    this.lastModified = index;
    this.lastModifiedParent = parentIndex;
    this.changedAt = new Date();
  }

  public deleteTemplate() {
    this.markerTemplates.splice(this.activeTemplateIndex, 1);
    this.activeTemplateIndex = null;

    this.changedAt = new Date();
  }

  public initNewType() {
    this.newTypeError = null;
    this.newType = null;
  }

  public createType() {
    this.newTypeError = null;

    this.newType = _.trim(this.newType);

    if (!this.newType) {
      this.newTypeError = 'A type name is required.';
      return;
    }

    const isDupe = _.find(
      this.markerTemplates[this.activeTemplateIndex].markers,
      marker => marker.type === this.newType,
    );

    if (isDupe) {
      this.newTypeError = 'That type name is already used.';
      return;
    }

    if (this.newType.length > 16) {
      this.newTypeError = 'Type names must be less than 16 characters.';
      return;
    }

    this.markerTemplates[this.activeTemplateIndex].markers.push({
      tags: [],
      type: this.newType,
    });

    this.markerTemplates[this.activeTemplateIndex].markers = _.sortBy(
      this.markerTemplates[this.activeTemplateIndex].markers,
      template => template.type.toLowerCase(),
    );

    this.markerTemplates[this.activeTemplateIndex].markers[0].newTag = '';
    this.changedAt = new Date();

    if (this.addTypePopover) {
      this.addTypePopover.hide();
    }

    this.setupDragDrop();
  }

  public removeType(index) {
    this.markerTemplates[this.activeTemplateIndex].markers.splice(index, 1);
    this.changedAt = new Date();
  }

  public createTag(index) {
    this.markerTemplates[this.activeTemplateIndex].markers[index].newTagError = null;
    this.markerTemplates[this.activeTemplateIndex].markers[index].newTag = _.trim(
      this.markerTemplates[this.activeTemplateIndex].markers[index].newTag,
    );

    if (!this.markerTemplates[this.activeTemplateIndex].markers[index].newTag) {
      this.markerTemplates[this.activeTemplateIndex].markers[index].newTagError = 'A tag name is required.';
      return;
    }

    const isDupe = _.find(
      this.markerTemplates[this.activeTemplateIndex].markers[index].tags,
      tag => tag === this.markerTemplates[this.activeTemplateIndex].markers[index].newTag,
    );

    if (isDupe) {
      this.markerTemplates[this.activeTemplateIndex].markers[index].newTagError = 'That tag name is already used.';
      return;
    }

    if (this.markerTemplates[this.activeTemplateIndex].markers[index].newTag.length > 96) {
      this.markerTemplates[this.activeTemplateIndex].markers[index].newTagError =
        'Tag names must be less than 96 characters.';
      return;
    }

    this.markerTemplates[this.activeTemplateIndex].markers[index].tags.push(
      this.markerTemplates[this.activeTemplateIndex].markers[index].newTag,
    );
    this.markerTemplates[this.activeTemplateIndex].markers[index].newTag = '';
    this.changedAt = new Date();

    this.setupDragDrop();

    // Using this to push to the bottom of the call stack because it was fighting
    // with the list item focus - TODO: This is so lame there must be a better way
    // but the objects are on a repeater so it makes it tricky - I'll chat with Joe about it later
    setTimeout(() => {
      const el = document.getElementById(`${index}-addTagInput`);

      if (el) {
        el.focus();
      }
    }, 0);
  }

  public removeTag(index, parentIndex) {
    this.markerTemplates[this.activeTemplateIndex].markers[parentIndex].tags.splice(index, 1);
    this.changedAt = new Date();
  }

  @computedFrom('liveEventsService.cache.marker_templates_new', 'markerTemplates', 'changedAt')
  public get isDirty() {
    if (
      !this.liveEventsService ||
      !this.liveEventsService.cache ||
      !this.liveEventsService.cache.marker_templates_new
    ) {
      return false;
    }

    const clonedTemplates = _.cloneDeep(this.markerTemplates);

    _.forEach(clonedTemplates, template => {
      _.forEach(template.markers, marker => {
        delete marker.newTag;
        delete marker.newTagError;
      });
    });

    return !_.isEqual(this.liveEventsService.cache.marker_templates_new, clonedTemplates);
  }
}
