import {CTableCol} from '@bindable-ui/bindable';
import {autoinject, bindable, LogManager} from 'aurelia-framework';
import {FailoverGroup} from '../../models/failover-group';
import {SlicerService} from '../../services/slicer-service';

const logger = LogManager.getLogger('SlicerChangeLog');

@autoinject()
export class ChangeLog {
    @bindable
    public group: FailoverGroup;

    public columns: CTableCol[];
    public isLoading: boolean = true;
    public logger = logger;

    public constructor(private slicerService: SlicerService) {
        this.columns = [
            {
                colHeadName: 'action',
                colHeadValue: 'Action',
                sort: true,
            },
            {
                colHeadName: 'created',
                colHeadValue: 'Date/Time',
                sort: true,
                valueConverter: 'timezoneTimeToStandardDateTimeMS',
            },
            {
                colHeadName: 'by_whom',
                colHeadValue: 'Who',
                sort: true,
            },
        ];
    }

    public async attached() {
        try {
            this.group.change_log = await this.slicerService.getSlicerChangeLog('failover_group', this.group.id);
        } catch (e) {
            logger.error(e);
        }
        this.isLoading = false;
    }
}
