import {customElement} from 'aurelia-templating';
import {bindable, computedFrom} from 'aurelia-framework';
import {ProfilesResponseItem, ProfilesResponseItems} from '../../../apps/cms/routes/slicers/models/profiles';

@customElement('encoding-profile')
export class EncodingProfile {
  public profilesFilter: string = '';
  public ratesFilter = [];

  @bindable
  public profiles: ProfilesResponseItems;

  @bindable
  public onSelectProfile;

  @bindable
  public profile: ProfilesResponseItem;

  @computedFrom('profiles.items.length', 'profilesFilter', 'ratesFilter')
  public get profilesFiltered() {
    if (!(this.profiles && this.profiles.items)) {
      return [];
    }
    return this.profiles && this.profiles.items
      ? this.profiles.items
          .filter(profile => !(profile.deprecated && profile.vod_assets === 0 && profile.live_slicers === 0))
          .filter(profile => {
            const rateCard = profile.rate_card_id ? profile.rate_card_id.trim().split(' ').join('_') : '';
            const text = profile.desc.toLowerCase().includes(this.profilesFilter.toLowerCase());
            const id = profile.id.toLowerCase().includes(this.profilesFilter.toLowerCase());
            const rate = this.ratesFilter.length ? this.ratesFilter.includes(rateCard) : true;

            return (text || id) && rate;
          })
      : [];
  }

  @computedFrom('profiles.items.length')
  public get allRates() {
    const rates = [];

    if (this.profiles && this.profiles.items) {
      this.profiles.items.forEach(profile => {
        const rate = (profile.rate_card_id && profile.rate_card_id.trim()) || '';
        if (!rates.includes(rate)) {
          rates.push(rate);
        }
      });
    }
    return rates;
  }

  constructor() {}

  public onSelect(profile) {
    if (typeof this.onSelectProfile === 'function') {
      this.onSelectProfile(profile);
    }
  }
}
