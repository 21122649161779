import {ConfigOptions as ConfigOptionsBase} from 'resources/config/import/models/config-options';

export class ConfigOptions extends ConfigOptionsBase {
  public position = [
    {
      text: 'Pre-Roll',
      value: 'preroll',
    },
    {
      text: 'Mid-Roll',
      value: 'midroll',
    },
    {
      text: 'Post-Roll',
      value: 'postroll',
    },
  ];
}
