exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "div._1E5n->div._2mqDc{display:none}._3yIap{background-color:#3d3c3c;background-color:var(--c_ash);overflow-wrap:break-word;padding:1px 20px 20px 15px}._2yL85{color:#fdfdfd;font-family:monospace;font-size:13px;margin-left:12px;margin-top:1em}._3is7c{display:none}._2boI-{margin-left:2em}.kRfac{white-space:pre}.s9nO7{background-position:0 0;background-repeat:no-repeat;cursor:pointer;display:inline-block;margin-left:-10px;-webkit-user-select:none;vertical-align:bottom;width:10px}._2OcOJ{background:url('data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"%23909090\" width=\"10\" height=\"10\"><path d=\"M0 0h8L4 7Z\"/></svg>')}._2OcOJ,._3cMXf{height:9px;vertical-align:middle}._3cMXf{background:url('data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"%23909090\" width=\"10\" height=\"10\"><path d=\"M0 0v8l7-4Z\"/></svg>')}._2Q1iF{padding:0 5px!important}._2_7ec{background-color:#ff6b6b;border:2px solid #ff4f4f}._2m668{color:#d5aff1}._3KZNL{color:#f7b178}._3xZFz{color:#89b8f3}._3rqqM,._3iF4X{color:#8080fb}._3rqqM{text-decoration:none}._3rqqM:hover{text-decoration:underline}._1xUg9{color:#236e25}._3-1Pb{color:silver}._2qiSN{color:red;font-weight:700}", ""]);

// exports
exports.locals = {
	"collapsible": "_1E5n-",
	"hidden": "_2mqDc",
	"xmlViewerContent": "_3yIap",
	"prettyPrint": "_2yL85",
	"sourceXml": "_3is7c",
	"collapsibleContent": "_2boI-",
	"comment": "kRfac",
	"button": "s9nO7",
	"collapseButton": "_2OcOJ",
	"expandButton": "_3cMXf",
	"lineContent": "_2Q1iF",
	"highlight": "_2_7ec",
	"htmlTag": "_2m668",
	"htmlAttributeName": "_3KZNL",
	"htmlAttributeValue": "_3xZFz",
	"htmlExternalLink": "_3rqqM",
	"htmlResourceLink": "_3iF4X",
	"htmlComment": "_1xUg9",
	"htmlDoctype": "_3-1Pb",
	"htmlEndOfFile": "_2qiSN"
};