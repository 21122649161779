import {IVNavSliderNavList, IVNavSliderPageList, SharedNav} from '@bindable-ui/bindable';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, singleton} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterConfiguration, RouterEvent} from 'aurelia-router';
import {Acceo} from 'services/acceo';
import {Toast} from 'resources/toast/toast';
import {AcceoErrorUtil} from './components/acceo-error-util';
import {AdConfigService} from './services/ad-config';
import {AdServerRoute} from './models/config-route';
import {TypeConfigService} from '../ad-servers/services/type-config';

@singleton()
@autoinject()
export class AdServerMenu {
  public typeConfigService: TypeConfigService;

  protected navPage: IVNavSliderPageList = {
    navs: [],
    prevText: 'Settings Menu',
    searchFn: this.search.bind(this),
    searchPlaceholder: '',
    searchQuery: '',
    title: `${AdServerRoute.routeTitle}s`,
  };

  protected navs: IVNavSliderNavList[] = [];
  protected routerEventComplete: Subscription;
  protected routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: 'adConfigList',
      nav: false,
      route: '',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: 'adConfigSingle',
      nav: false,
      route: ':id',
    },
  ];

  protected service: AdConfigService;

  constructor(
    protected acceo: Acceo,
    protected eventAggregator: EventAggregator,
    protected router: Router,
    protected sharedNav: SharedNav,
  ) {
    this.service = new AdConfigService(acceo);
    this.typeConfigService = new TypeConfigService(acceo);
  }

  public attached() {
    this.routerEventComplete = this.eventAggregator.subscribe(
      RouterEvent.Complete,
      this.onRouterEventComplete.bind(this),
    );
    this.load();
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }

  public detached() {
    this.routerEventComplete.dispose();
    if (this.sharedNav.nav.pages.indexOf(this.navPage) !== -1) {
      this.sharedNav.nav.pages.splice(this.sharedNav.nav.pages.indexOf(this.navPage), 1);
    }
  }

  public async load() {
    // we should first check that we have data before doing this nav stuff
    this.navPage.isLoading = true;
    try {
      this.navs = await this.requestRead();
      if (this.router.currentInstruction.fragment) {
        this.sharedNav.replacePage(this.navPage, 1);
        this.sharedNav.setNextText(`${AdServerRoute.routeTitle} List`);
      }
    } catch (error) {
      Toast.danger(AcceoErrorUtil.getHTML(error));
    }
    this.updateNav();
    this.search();
    this.navPage.isLoading = false;
  }

  protected onRouterEventComplete() {
    this.updateNav();
  }

  protected async requestRead(): Promise<IVNavSliderNavList[]> {
    // get the nav items.. (we do not need this second get - consider refactoring)
    try {
      const response = await this.service.get();
      if (!response) return Promise.resolve([]);
      const typeConfig = await this.typeConfigService.get();
      const typeConfigDictArray = (typeConfig.items || []).map(item => ({
        text: item.text,
        value: item.value,
      }));
      return Promise.resolve(
        response.items.map(item => ({
          active: false,
          href: `#/settings/${AdServerRoute.routeFragment}/${item.name}`,
          subText: typeConfigDictArray.find(typeConfigItem => typeConfigItem.value === item.type).text
            ? typeConfigDictArray.find(typeConfigItem => typeConfigItem.value === item.type).text
            : item.type,
          title: item.name,
        })),
      );
    } catch (err) {
      if (err && err.status_code === 404) {
        return [];
      }
      return Promise.reject(err);
    }
  }

  protected search(query: string = '') {
    this.navPage.navs = this.navs.filter(
      nav =>
        !query.length ||
        nav.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        nav.subText.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    );
    return this.navPage.navs;
  }

  protected updateNav() {
    let adConfigIndex = -1;
    this.navs.forEach((nav, index) => {
      nav.active = this.router.currentInstruction.fragment && nav.title === this.router.currentInstruction.fragment;
      if (nav.active) adConfigIndex = index;
      return nav;
    }, this);
    if (adConfigIndex === -1) return;
    if (this.sharedNav.nav.pages.indexOf(this.navPage) !== -1) {
      this.sharedNav.setActive(1, adConfigIndex);
    } else {
      this.sharedNav.replacePage(this.navPage, 1);
      this.sharedNav.setNextText(`${AdServerRoute.routeTitle} List`);
    }
  }
}
