import {computedFrom} from 'aurelia-binding';
import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject, containerless} from 'aurelia-framework';
import indexCss from './index.css';

import {AD_DEBUG_EVENT} from '../index';

export const enum Tab {
  Request = 'request',
  Response = 'response',
}

@autoinject()
@containerless()
export class Transaction {
  public styles;
  public selectedTab: Tab = Tab.Request;

  constructor(public eventAggregator: EventAggregator) {
    this.styles = indexCss;
  }

  public switchTo(tab: Tab, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.selectedTab = tab;
  }

  public tabState(tab: Tab) {
    if (this.selectedTab === tab) return 'active';
    return '';
  }

  @computedFrom('selectedTab')
  public get requestTabState() {
    return this.tabState(Tab.Request);
  }

  @computedFrom('selectedTab')
  public get responseTabState() {
    return this.tabState(Tab.Response);
  }

  public downloadRequestResponse(transaction) {
    this.eventAggregator.publish(AD_DEBUG_EVENT.ExportTransaction, {transaction});
  }
}
