import {Type} from 'class-transformer';
import {IsArray, IsNumber, IsOptional, IsString, IsUrl, ValidateNested} from 'class-validator';

export class Library {
    @IsString()
    public id: string;

    @IsString()
    public desc: string;

    @IsString()
    @IsOptional()
    public owner_name: string;
}

export class LibraryCollection {
    @ValidateNested()
    @Type(() => Library)
    public incoming: Library[];

    @ValidateNested()
    @Type(() => Library)
    public outgoing: Library[];
}

export class LibraryAsset {
    // camelcase fields are internal app state fields, not returned from server.
    public isOrphan: boolean;

    @IsString()
    public id: string;

    @IsString()
    public title: string;

    @IsOptional()
    @IsUrl()
    public poster_url: string;

    @IsOptional()
    @IsUrl()
    public hls_url: string;

    @IsArray()
    public break_offsets: object[];

    @IsNumber()
    public dur: number;

    @IsNumber()
    @IsOptional()
    public duration: number;
}
