import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom, LogManager, observable} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

import {CToastsService, dirtyCheckPrompt} from '@bindable-ui/bindable';
import {Util} from 'services/util';

import {AUDIENCE_TYPE, SimpleAudience} from '../../audiences/models/models';
import {AudiencesService} from '../../audiences/services/audiences';
import {RuleService} from '../services/rules';
import {ALT_CONTENT_TYPE, ALT_CONTENT_TYPE_VALUES, Rule} from '../models/models';

const log = LogManager.getLogger('live-channels-rules-single');

@dirtyCheckPrompt
@autoinject()
export class RulesSingle {
    @observable
    public alternateContentType: ALT_CONTENT_TYPE;

    public ALT_CONTENT_TYPE_OPTIONS = ALT_CONTENT_TYPE_VALUES.map(opt => ({text: _.capitalize(opt), value: opt}));
    public AUDIENCE_TYPE = AUDIENCE_TYPE;
    public error: object;

    public audience: SimpleAudience;
    public rule: Rule;
    public rulePristine: Rule;
    public isRuleLoading: boolean = false;
    public isAudienceLoading: boolean = false;

    constructor(
        private ruleService: RuleService,
        private audiencesService: AudiencesService,
        private dialogService: DialogService,
        private notification: CToastsService,
        private router: Router,
    ) {}

    public activate(params) {
        this.init(params.id);
    }

    public backToRules() {
        this.router.navigate('/live-channels/rules');
    }

    public async init(id) {
        await Promise.all([this.getRules(), this.getRuleAndAudience(id)]);
        this.ruleService.setNavActive(id);
    }

    public openAudienceModal() {
        const model = {
            audienceId: this.rule.audience,
            audiencesService: this.audiencesService,
        };
        this.dialogService.open({
            model: {
                bodyModel: model,
                bodyViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/rules/single/modals/change-audience'),
                footerEnable: true,
                footerModel: {
                    ...model,
                    onSelect: () => {
                        this.audience = this.audiencesService.currentModel;
                        this.rule.audience = this.audiencesService.currentModel.id;
                    },
                },
                footerViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/rules/single/modals/change-audience-footer',
                ),
                size: 'full',
                title: 'Select Audience',
            },
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        });
    }

    public async getRules() {
        if (!_.isEmpty(this.ruleService.rules)) {
            return;
        }
        await this.ruleService.getRules();
    }

    public async getRuleAndAudience(id: string) {
        this.isRuleLoading = true;
        try {
            this.rule = await this.ruleService.getRule(id);
            this.rulePristine = _.cloneDeep(this.rule);
            this.alternateContentType = this.rule.alternate_content_type;
            if (this.rule.audience) {
                this.isAudienceLoading = true;
                await this.audiencesService.getAudience(this.rule.audience);
                // if audience is list type, fetch the sub_audiences here
                this.audience = this.audiencesService.currentModel;
            }
        } catch (e) {
            log.error(e);
        } finally {
            this.isAudienceLoading = false;
            this.isRuleLoading = false;
        }
    }

    public isDirty() {
        return this.formDirty;
    }

    public removeAudience() {
        this.rule.audience = '';
    }

    public async save() {
        if (!this.formDirty) {
            return;
        }
        this.error = {};
        const err = await Util.validate(this.rule);
        if (err) {
            this.error = err;
            log.error('Validation Error', err);
            this.notification.error('Error updating rule. Please make sure all fields are valid.');
            throw new Error('Validation Error'); // Bubble up to dirty checking modal
        }
        const updateRule = _.cloneDeep(this.rule);
        delete updateRule.created;
        delete updateRule.selected;

        const descDirty = updateRule.desc !== this.rulePristine.desc;

        try {
            this.rule = await this.ruleService.saveRule(updateRule);
            this.rulePristine = _.cloneDeep(this.rule);
            if (descDirty) {
                this.ruleService.setNavText(this.rulePristine.id, this.rulePristine.desc);
            }
        } catch (e) {
            if (e.message.toLowerCase().includes('external id')) {
                this.error = {
                    external_id: e.message,
                };
            }
            throw e; // Bubble up to dirty checking modal
        }
    }

    public alternateContentTypeChanged() {
        if (this.rule) {
            this.rule.alternate_content_type = this.alternateContentType;
            if (this.rule.alternate_content_type === ALT_CONTENT_TYPE.SLATE && !this.rule.alternate_content_loop) {
                this.rule.alternate_content_loop = true;
                // we don't want dirty check modal to trigger here, so modifying pristine too.
                this.rulePristine.alternate_content_loop = true;
            }
        }
    }

    public toggleAllViewers() {
        this.rule.audience = null;
    }

    @computedFrom('rule.alternate_content_type')
    get alternateContentLabelTooltip() {
        if (!this.rule) {
            return '';
        }

        switch (this.rule.alternate_content_type) {
            case 'slicer':
                return 'The ID of the slicer';
            case 'channel':
                return 'The ID or external ID for the channel';
            case 'asset':
                return 'The ID or external ID for the asset';
            default:
                return '(optional)';
        }
    }

    @computedFrom('rule.alternate_content_id')
    get alternateContentIDDirty() {
        if (this.rule) {
            if (this.rule.alternate_content_id !== this.rulePristine.alternate_content_id) {
                return true;
            }
        }
        return false;
    }

    @computedFrom('rule.alternate_content_loop')
    get alternateContentLoopDirty() {
        if (this.rule) {
            if (this.rule.alternate_content_loop !== this.rulePristine.alternate_content_loop) {
                return true;
            }
        }
        return false;
    }

    @computedFrom('rule.alternate_content_type')
    get alternateContentTypeDirty() {
        if (this.rule) {
            if (this.rule.alternate_content_type !== this.rulePristine.alternate_content_type) {
                return true;
            }
        }
        return false;
    }

    @computedFrom('rule.audience')
    get audienceDirty() {
        if (this.rule) {
            if (this.rule.audience !== this.rulePristine.audience) {
                return true;
            }
        }
        return false;
    }

    @computedFrom('rule.desc')
    get descDirty() {
        if (this.rule) {
            if (this.rule.desc !== this.rulePristine.desc) {
                return true;
            }
        }
        return false;
    }

    @computedFrom('rule.external_id')
    get externalIDDirty() {
        if (this.rule) {
            if (this.rule.external_id !== this.rulePristine.external_id) {
                return true;
            }
        }
        return false;
    }

    @computedFrom('rule.all_viewers')
    get allViewersDirty() {
        if (this.rule) {
            if (this.rule.all_viewers !== this.rulePristine.all_viewers) {
                return true;
            }
        }
        return false;
    }

    @computedFrom(
        'alternateContentIDDirty',
        'alternateContentLoopDirty',
        'alternateContentTypeDirty',
        'audienceDirty',
        'descDirty',
        'externalIDDirty',
        'allViewersDirty',
    )
    get formDirty() {
        return (
            this.alternateContentIDDirty ||
            this.alternateContentLoopDirty ||
            this.alternateContentTypeDirty ||
            this.audienceDirty ||
            this.descDirty ||
            this.externalIDDirty ||
            this.allViewersDirty
        );
    }

    @computedFrom('formDirty', 'ruleService.isSaving')
    get saveButtonState() {
        if (this.ruleService.isSaving) {
            return 'thinking';
        }
        if (!this.formDirty) {
            return 'disabled';
        }
        return '';
    }
}
