import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {CToastsService} from '@bindable-ui/bindable';
import {IWares, Wares, WaresResponse} from './model';

@autoinject()
export class DownloadSlicer {
  private url = '/api/v4/wares-download';
  public swSlicerMac: Wares;
  public swSlicerWindows: Wares;
  public swSlicerLinux: Wares;
  public swSlicerMacArm: Wares;
  public waresDownload: IWares;
  public waresResponse: WaresResponse;
  constructor(private acceo: Acceo, private notification: CToastsService) {}

  public async activate() {
    await this.getData();
  }

  public async getData() {
    try {
      const resp = await this.acceo.get(WaresResponse)(this.url);
      this.swSlicerMac = resp.items.find(w => w.slicer_mac_64).slicer_mac_64;
      this.swSlicerLinux = resp.items.find(w => w.slicer_linux_64).slicer_linux_64;
      this.swSlicerWindows = resp.items.find(w => w.slicer_windows_64).slicer_windows_64;
      this.swSlicerMacArm = resp.items.find(w => w.slicer_mac_arm64).slicer_mac_arm64;
    } catch (err) {
      this.notification.error('Error occurred while retrieving link for downloads');
    }
  }
}
