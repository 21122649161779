import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {PluginsResponseItems} from '../models/plugin';

@autoinject
export class PluginService {
  private url: string = '/api/v4/managed-slicer/slicer/plugins';

  constructor(public acceo: Acceo) {}

  public get() {
    return this.acceo.get(PluginsResponseItems)(this.url);
  }
}
