export enum ConfigImportMode {
  APPEND = 'APPEND',
  MERGE = 'MERGE',
  PREPEND = 'PREPEND',
  REPLACE = 'REPLACE',
}

export enum ConfigImportSource {
  FILE = 'FILE',
  TEMPLATE = 'TEMPLATE',
}
