import {classToPlain} from 'class-transformer';
import {VodUploaderFile} from '../models/models';
import {Template} from '../../settings/vod-uploader-templates/models/templates-model';

export default class CfgParser {
  constructor(private files: VodUploaderFile[]) {}

  // eslint-disable-next-line class-methods-use-this
  private getTemplateObj(template: Template) {
    return (template && classToPlain(template.template)) || {};
  }

  private async getFileObj(file: VodUploaderFile) {
    if (file.CFGText) {
      const content = await file.CFGText;
      return this.parseFile(content);
    }

    return {};
  }

  // eslint-disable-next-line class-methods-use-this
  private getOverrideObj(file: VodUploaderFile) {
    return (file.template && classToPlain(file.template)) || {};
  }

  protected addFile(file: VodUploaderFile, content: string, isGenerated: boolean) {
    const cfgName = `${file.name}.cfg`;
    const f = new File([new Blob([content], {type: 'text/plain'})], cfgName);
    const vodFile = new VodUploaderFile(f, this.files);
    vodFile.isGenerated = isGenerated;
    this.files.push(vodFile);
  }

  public async createFiles(template: Template) {
    return new Promise<string>(resolve => {
      const {files} = this;
      if (files) {
        const temp = this.getTemplateObj(template);
        files
          .filter(f => f.isVideo === true)
          .forEach(async file => {
            const cfg = await this.getFileObj(file);
            const override = this.getOverrideObj(file);
            const all = {...temp, ...override, ...cfg};

            if (Object.keys(all).filter(x => all[x] !== undefined).length !== 0) {
              const content = this.convertToFile(all);
              const isGenerated = !file.hasCFGFile;
              file.removeCFGfiles();
              this.addFile(file, content, isGenerated);
            }
          });
      }
      resolve();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public parseFile(contents: string): Record<string, string> {
    const result: Record<string, string> = {};

    const lines = contents.split('\n');
    lines.forEach(line => {
      const trimmedLine = line.trim();
      if (!trimmedLine || trimmedLine.startsWith(';')) {
        return;
      }

      const separatorIndex = trimmedLine.indexOf('=');
      if (separatorIndex !== -1) {
        const key = trimmedLine.slice(0, separatorIndex).trim();
        const value = trimmedLine.slice(separatorIndex + 1).trim();
        result[key] = value;
      }
    });

    return result;
  }

  // eslint-disable-next-line class-methods-use-this
  public convertToFile(parsedIni: object): string {
    let iniContent = '';

    Object.keys(parsedIni).forEach(key => {
      if (parsedIni[key] !== undefined) {
        const value = parsedIni[key];
        iniContent += `${key} = ${value}\n`;
      }
    });

    return iniContent.trim();
  }
}
