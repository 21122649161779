import {autoinject, computedFrom} from 'aurelia-framework';

import {CToastsService} from '@bindable-ui/bindable';

import {OwnerLimited} from '../../../models/owner';
import {SsoDomain} from '../../../models/sso-domain';

import {SsoDomainService} from '../../../services/sso-domain';
import {OwnerService} from '../../../services/owners';
import {SsoDomainUserService} from '../../../services/sso-domain-user';
import {ISearchableItem, ISearchActions} from '../../../../../../../components/search-stack';

export interface IActions {
  onClose: () => void;
}

function mapToSearchableItem(record: OwnerLimited) {
  return {
    value: record.id,
    text: record.ids_email,
    disabled: false,
  };
}

function mapToSearchableItems(records: OwnerLimited[]) {
  return records.map(mapToSearchableItem);
}

@autoinject()
export class DomainUsers {
  public model: SsoDomain = null;
  public records: OwnerLimited[] = [];

  public userSearchTxt: string = null;

  private _users: ISearchableItem[] = [];
  private actions: IActions;
  private domainId: string = null;

  public ownerSearchActions: ISearchActions = {
    onSearch: async (search: string) => {
      this.records = await this.ownerService.listNonSsoBoundOwners(search);
    },
    onClear: () => {
      this.records = [];
    },
    onSelect: (id: string) => {
      this.addUser(id);
    },
  };

  public userSearchActions: ISearchActions = {
    onClear: () => {
      this.userSearchTxt = null;
    },
    onSearch: (search: string) => {
      this.userSearchTxt = search;
    },
    onSelect: (id: string) => {
      this.removeUser(id);
    },
  };

  @computedFrom('model.title')
  public get modalTitle() {
    const title = 'User Management';

    if (!this.model) {
      return title;
    }

    return `${this.model.title} ${title}`;
  }

  @computedFrom('records.length', '_users.length')
  public get owners(): ISearchableItem[] {
    const excludeIds = (this._users || []).map(r => r.value);

    return mapToSearchableItems(this.records).filter(i => !excludeIds.includes(i.value));
  }

  @computedFrom('_users.length', 'userSearchTxt')
  public get users(): ISearchableItem[] {
    const users = this._users || [];

    if (this.userSearchTxt?.length > 0) {
      return users.filter(u => u.text.toLocaleLowerCase().includes(this.userSearchTxt.toLocaleLowerCase()));
    }

    return users;
  }

  constructor(
    public domainService: SsoDomainService,
    public notificationService: CToastsService,
    public ownerService: OwnerService,
    public userService: SsoDomainUserService,
  ) {
    this.reset();
  }

  /*
    Aurelia Hooks
  */

  public activate({actions, domainId}) {
    this.actions = actions;
    this.domainId = domainId;

    this.init();
  }

  public deactivate() {
    this.reset();
  }

  /*
    Public Methods
  */

  public addUser(id: string): void {
    const record = this.records.find(o => o.id === id);
    this._users.push(mapToSearchableItem(record));
  }

  public removeUser(id: string): void {
    this._users = this._users.filter(u => u.value !== id);
  }

  /**
   * Closes the dialog and performs any post processing
   * @param e
   * @param preventClose
   */
  public closeDialog(e?: Event, preventClose: boolean = false) {
    e?.preventDefault();

    if (preventClose) {
      return;
    }

    if (this.userService.isProcessing) {
      this.notificationService.warning('Processing, please wait.');
      return;
    }

    this.reset();

    this.actions.onClose();
  }

  public async saveChanges(): Promise<void> {
    if (this.userService.isProcessing) {
      return;
    }

    try {
      const ids = this._users.map(o => o.value);

      await this.userService.addUsersToDomain(this.domainId, ids);
      this.notificationService.success('Success');
      this.closeDialog();
    } catch (err) {
      this.notificationService.error(`Failed - ${err.message}`);
    }
  }

  /*
    Private Methods
  */

  private async init() {
    this.model = await this.domainService.getRecord(this.domainId);
  }

  private reset() {
    this.records = [];
    this._users = [];
    this.model = null;
    this.domainId = null;
  }
}
