import {Transform, Type} from 'class-transformer';
import {
    IsBoolean,
    IsDefined,
    IsEnum,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    MaxLength,
    ValidateIf,
    ValidateNested,
} from 'class-validator';
import {MetadataType} from './enums-config';
import {FaceBook, FaceBookPage} from './facebook-config';
import {Kaltura} from './kaltura-config';
import {Lakana} from './lakana-config';
import {Twitter} from './twitter-config';
import {Youtube} from './youtube-config';

export class ClippingConfig {
    @IsString()
    @Transform(value => value, {toClassOnly: true})
    // public id: string = Util.generateGuid();
    public id: string = '-1';

    // if {skipMissingProperties: true} in validate function, @isDefined() fields are still checked.
    @IsDefined({message: 'You must enter a value.'})
    @IsNotEmpty({message: 'You must enter a value.'})
    @IsString()
    @MaxLength(100, {message: 'Name must be $constraint1 characters or less.'})
    public desc: string;

    @IsOptional()
    @IsString()
    @Transform(value => (value === null ? '' : value), {toClassOnly: true})
    @Transform(value => (value === '' ? null : value), {toPlainOnly: true})
    public default_intro: string;

    @IsOptional()
    @IsString()
    @Transform(value => (value === null ? '' : value), {toClassOnly: true})
    @Transform(value => (value === '' ? null : value), {toPlainOnly: true})
    public default_library: string;

    @IsOptional()
    @IsString()
    @Transform(value => (value === null ? '' : value), {toClassOnly: true})
    @Transform(value => (value === '' ? null : value), {toPlainOnly: true})
    public default_outro: string;

    @IsOptional()
    @IsString()
    @Transform(value => (value === null ? '' : value), {toClassOnly: true})
    @Transform(value => (value === '' ? null : value), {toPlainOnly: true})
    public default_overlay: string;

    @IsBoolean()
    public default_drm: boolean;

    @IsBoolean()
    public default_studio_drm: boolean;

    @IsBoolean()
    public show_ext_id: boolean;

    @ValidateNested()
    @Type(() => Bumper)
    public bumpers: Bumper[] = [];

    @ValidateNested()
    @Type(() => Metadata)
    public metadata: Metadata[] = [];

    @ValidateNested()
    @Type(() => Overlay)
    public overlays: Overlay[] = [];

    @ValidateNested()
    @Type(() => Integrations)
    public integrations: Integrations = new Integrations();

    @ValidateNested()
    @Type(() => FaceBookPage)
    public facebook_page_list: FaceBookPage[] = [];

    // runtime field, need not be serialized
    // Youtube
    public youTubeAuthenticated: boolean = false;
    public youTubeAuthProcessing: boolean = false;

    // Twitter
    public twitterAuthenticated: boolean = false;
    public twitterAuthProcessing: boolean = false;

    // Facebook
    public facebookAuthenticated: boolean = false;
    public facebookAuthProcessing: boolean = false;

    public skipValidate: boolean;
    public duplicateOf: string;

    public doValidate?: boolean;
}

export class Bumper {
    @IsString()
    public desc: string;

    @IsNumber()
    public duration: number;

    @IsString()
    public id: string;

    @IsString()
    public posterUrl: string;
}

export class Metadata {
    @IsOptional()
    @IsString()
    public default_value: string = '';

    // -1 means that this is a new record and will generate a new GUID on save (endpoint)
    @IsString()
    public id: string = '-1';

    @IsBoolean()
    public include_free_form: boolean = false;

    @IsBoolean()
    public include_null: boolean = true;

    @IsEnum(MetadataType)
    public input_type: MetadataType = MetadataType.Text;

    @IsDefined({message: 'You must enter a value.'})
    @IsNotEmpty({message: 'You must enter a value.'})
    @IsString()
    @MaxLength(100, {message: 'Name must be $constraint1 characters or less.'})
    public key: string = '';

    @IsNumber()
    public sortOrder: number = 99;

    // @IsString()
    // public value: string = "";

    @ValidateNested()
    @Type(() => DropdownValue)
    public values: DropdownValue[] = [];

    public constructor(init?: Partial<Metadata>) {
        Object.assign(this, init);
    }
}

export class DropdownValue {
    @IsString()
    public key: string = '';

    @IsString()
    public value: string = '';
}

export class Overlay {
    @IsString()
    public filename: string = '';

    @IsString()
    public id: string = '';

    @IsString()
    public name: string = '';

    @IsString()
    public resolution: string = '';

    @IsString()
    public url: string = '';
}

export class UploadOverlay {
    public clippingProfileId: string = '';
    public overlayName: string = '';
    public fileList: FileList;
}

export class Integrations {
    @ValidateIf(o => _.get(o, 'facebook.enabled'))
    @ValidateNested()
    @Type(() => FaceBook)
    public facebook: FaceBook;

    @ValidateIf(o => _.get(o, 'twitter.enabled'))
    @ValidateNested()
    @Type(() => Twitter)
    public twitter: Twitter;

    @ValidateIf(o => _.get(o, 'youtube.enabled'))
    @ValidateNested()
    @Type(() => Youtube)
    public youtube: Youtube;

    @ValidateIf(o => _.get(o, 'kaltura.enabled'))
    @ValidateNested()
    @Type(() => Kaltura)
    public kaltura: Kaltura;

    @ValidateIf(o => _.get(o, 'lakana.enabled'))
    @ValidateNested()
    @Type(() => Lakana)
    public lakana: Lakana;
}
