import {bindable} from 'aurelia-framework';

export class Accordion {
    @bindable
    closeOthers = true;

    constructor() {
        this.items = [];

        // $elements = $('.accordion__body');

        // for ($element in $elements) {
        //     console.log('hi');
        // }
    }
}
