import {computedFrom, inject} from 'aurelia-framework';
import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';
import {SettingsData} from './services/settings-data';

@inject(Notification, SettingsData, SessionService)
export class BaseSettings {
    constructor(notification, settingsData, sessionService) {
        this.notification = notification;
        this.settingsData = settingsData;
        this.session = sessionService;

        // Properites
        this.isLoading = true;
        this.isSaving = false;

        // This is where the data from the /settings/all/ endpoint is stored
        this.model = undefined;
    }

    /* Inherit this class to load your properties */
    initializeProperties() {}

    loadData() {
        this.settingsData.getSettings().then(data => {
            this.model = data;
            this.initializeProperties();
            this.isLoading = false;
        });
    }

    attached() {
        this.loadData();
    }

    /* ---------------------------------------------------------------------- *\
     * Computed Properties
    \* ---------------------------------------------------------------------- */

    @computedFrom('isSaving')
    get saveBtnText() {
        return this.isSaving ? 'Saving...' : 'Save';
    }
}
