export const ONE_HOUR = 60 * 60 * 1000;
export const SCHEDULES_URL: string = '/api/v4/schedules';

export enum NAV_TABS {
  SELECT_VOD = 1,
  DETAILS,
  AD_BREAKS,
  RULES,
  CONFLICTS,
  SELECT_PLAYLIST,
}

export enum BreakType {
  AD = 'AD BREAK',
}
