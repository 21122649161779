import {bindable} from 'aurelia-framework';

export class DynamicInput {
  @bindable public disabled: boolean;
  @bindable public helpText: string;
  @bindable public helpTip: string;
  @bindable public input: string;
  @bindable public label: string;
  @bindable public name: string;
  @bindable public options: {disabled?: boolean; text: string; value: string};
  @bindable public pattern: string;
  @bindable public readonly: boolean;
  @bindable public required: boolean;
  @bindable public state: string;
  @bindable public value: string;
}
