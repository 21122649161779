import * as CTableCssJson from '@bindable-ui/bindable/dist/native-modules/components/tables/c-table/c-table.css.json';
import {CTableSelectable} from 'resources/components/c-table-selectable/c-table-selectable';
import {
  CTableColSelectable,
  CTableRowBehavior,
} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {EncodingProfileRayTableRow} from './table-models';

export class EncodingProfileRayTable extends CTableSelectable {
  public rows: EncodingProfileRayTableRow[] = [];
  public colsIgnore = [
    'fps',
    'maxH',
    'maxW',
    'threads',
    'skipF',
    'profile',
    'preset',
    'isHD',
    'gop',
    'encoder',
    'EncryptionKey',
    'ifo',
  ];

  protected colsSelectableDefault: CTableColSelectable[] = [
    {
      colClass: this.getClass(CTableCssJson.t50),
      colHeadName: 'id',
      colHeadValue: 'ID',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t105),
      colHeadName: 'profile_key',
      colHeadValue: 'Description',
      selected: true,
      sort: false,
      valueConverter: 'capitalize:true',
    },
    {
      colClass: this.getClass(CTableCssJson.t175),
      colHeadName: 'resolution',
      colHeadValue: 'Resolution',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t90),
      colHeadName: 'vKbps',
      colHeadValue: 'Vid kb/s',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t120),
      colHeadName: 'nvenc',
      colHeadValue: 'GPU Encode',
      selected: true,
      sort: false,
      valueConverter: 'defaultValue:"Not Allowed" | capitalize:true',
    },
    {
      colClass: this.getClass(CTableCssJson.t90),
      colHeadName: 'max_bframes',
      colHeadValue: 'B Frames',
      selected: true,
      valueConverter: 'booleanYesNo',
    },
    {
      colClass: this.getClass(CTableCssJson.t65),
      colHeadName: 'vPasses',
      colHeadValue: 'Pass',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t65),
      colHeadName: 'aKbps',
      colHeadValue: 'Audio',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t120),
      colHeadName: 'aMaxChannels',
      colHeadValue: 'Audio Channels',
      selected: false,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t150),
      colHeadName: 'aoKbps',
      colHeadValue: 'Audio Overlay kb/s',
      selected: false,
      sort: false,
      valueConverter: 'notApplicable',
    },
    /*
    {
      colClass: this.getClass(CTableCssJson.t90),
      colHeadName: 'preset',
      colHeadValue: 'Preset',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t90),
      colHeadName: 'profile',
      colHeadValue: 'Profile',
      selected: true,
      sort: false,
    },
    {
      colClass: this.getClass(CTableCssJson.t50),
      colHeadName: 'isHD',
      colHeadValue: 'HD',
      selected: true,
      sort: false,
      valueConverter: 'booleanYesNo',
    },
    {
        colHeadName: 'encoder',
        colHeadValue: 'Encoder',
        selected: false,
        sort: false,
    },
    {
        colClass: this.getClass(CTableCssJson.t120),
        colHeadName: 'EncryptionKey',
        colHeadValue: 'Encryption Key',
        selected: false,
        sort: false,
        valueConverter: 'notApplicable',
    },
    {
        colClass: this.getClass(CTableCssJson.t50),
        colHeadName: 'gop',
        colHeadValue: 'GOP',
        selected: false,
        sort: false,
    },
    {
        colClass: this.getClass(CTableCssJson.t50),
        colHeadName: 'ifo',
        colHeadValue: 'IFO',
        selected: false,
        sort: false,
    },
    {
        colClass: this.getClass(CTableCssJson.t105),
        colHeadName: 'skipF',
        colHeadValue: 'Skip Frames',
        selected: false,
        sort: false,
    },
    {
        colClass: this.getClass(CTableCssJson.t105),
        colHeadName: 'threads',
        colHeadValue: 'Threads',
        selected: false,
        sort: false,
    },
    */
  ];

  constructor() {
    super(CTableRowBehavior.DEFAULT);
    this.colsSelectable = this.colsSelectableDefault;
  }
}
