import {SecondsToHmsValueConverter} from '@bindable-ui/bindable';
import {autoinject, singleton} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {AssetFindParams, AssetFindResponse} from './models/asset';

@autoinject()
@singleton()
export class AssetService {
    constructor(public acceo: Acceo) {}

    public getPlayerUrl(id): Promise<string> {
        return new Promise((resolve, reject) => {
            this.acceo
                .get()(`/clip/player/url?beamId=${id}`, {
                    prefix: 'url',
                })
                .then(resolve)
                .catch(reject);
        });
    }

    public async find(data): Promise<AssetFindResponse> {
        return await this.acceo.post(AssetFindResponse)('/assets/find/', data, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            requestTransform: (postParams: AssetFindParams) => $.param(postParams),
            responseTransform: (resp: AssetFindResponse) => {
                _.forEach(resp.assets, asset => {
                    if (asset.dur) {
                        asset.duration = SecondsToHmsValueConverter.transform(asset.dur);
                    }
                });
                return resp;
            },
        });
    }
}
