import {autoinject} from 'aurelia-framework';

import {WorkspaceKey} from '../../models/workspace-key';
import {WorkspaceKeyRevision} from '../../models/workspace-key-revision';
import {DialogBase} from '../base';

export interface IActions {
  onClose: () => void;
}

export interface IKeyInfoModel {
  model: WorkspaceKey;
  actions: IActions;
}

// eslint-disable-next-line no-shadow
export enum FileFormat {
  ENV = 'env',
  PEM = 'pem',
}

@autoinject()
export class KeyInfo extends DialogBase {
  public actions: IActions;
  public model: WorkspaceKey;
  public revision: WorkspaceKeyRevision;

  public headers: string;
  public payload: string;

  public acceptCopy: boolean;

  /*
    Aurelia Hooks
  */

  public activate(data: IKeyInfoModel) {
    const {actions, model} = data;

    this.actions = actions;
    this.model = model;

    const {revisions} = this.model;

    this.revision = revisions[0];

    this.acceptCopy = false;
  }

  /*
    Public Methods
  */

  public closeDialog(e?, preventClose: boolean = false) {
    e?.preventDefault();

    if (preventClose) {
      return;
    }

    if (!this.acceptCopy) {
      this.notificationService.error('You must accept the terms below first.');
      return;
    }

    this.actions.onClose();
  }
}
