import * as moment from 'moment-timezone';

/**
 * Return the offset in minutes from the selected timezone to the browser timezone
 */
export const getTzOffset = () => {
  const browserOffset = moment.tz.zone(moment.tz.guess()).utcOffset(moment().valueOf());
  const offset = moment().utcOffset();

  return browserOffset + offset;
};

const appendLeadingZeroes = n => {
  if (n <= 9) {
    return `0${n}`;
  }

  return n;
};

export const formatHHmm = (isoString, tzOffset) => {
  const dateObj = new Date(new Date(isoString).getTime() + tzOffset * 60 * 1000);
  return `${appendLeadingZeroes(dateObj.getHours())}:${appendLeadingZeroes(dateObj.getMinutes())}`;
};

export const formatHHmmss = (isoString, tzOffset) => {
  const dateObj = new Date(new Date(isoString).getTime() + tzOffset * 60 * 1000);
  return `${appendLeadingZeroes(dateObj.getHours())}:${appendLeadingZeroes(dateObj.getMinutes())}:${appendLeadingZeroes(
    dateObj.getSeconds(),
  )}`;
};
