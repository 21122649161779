exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2OJzD{align-items:center;background-color:var(--lynk-color-black);border-radius:4px;box-shadow:0 0 0 1px var(--lynk-color-neutral-100);display:flex;justify-content:center;overflow:hidden;position:relative;text-align:center;transition:box-shadow .3s ease-in-out;transition:box-shadow var(--trans_main)}._2OJzD ._2sHaZ,._2OJzD img,._2OJzD lynk-button{position:absolute}._2OJzD:hover lynk-button{opacity:1}._2OJzD lynk-button{align-self:flex-start;left:50%;margin-top:8px;opacity:0;transform:translateX(-50%);z-index:8}._2OJzD img,._2OJzD lynk-button{transition:opacity .3s ease-in-out;transition:opacity var(--trans_main)}._2OJzD img{width:100%;z-index:7}._2sHaZ{width:100%;z-index:6}._2OJzD uplynk-player>div{height:0;padding-top:56.25%;position:relative}._2OJzD uplynk-player>div iframe{height:100%;left:0;position:absolute;top:0;width:100%}.Rxv1u{height:0;padding-top:56.25%}._30wex{opacity:0}._2cCIy{opacity:1}", ""]);

// exports
exports.locals = {
	"container": "_2OJzD",
	"playerContainer": "_2sHaZ",
	"spacer": "Rxv1u",
	"goodbye": "_30wex",
	"hello": "_2cCIy"
};