import {Type} from 'class-transformer';
import {IsEnum, IsNumber, IsString, ValidateNested} from 'class-validator';
import {Rule} from './multi-cdn-settings-config';

export enum ContentFields {
    contentId = 'contentId',
    isLive = 'isLive',
}

export class ContentFieldsRepresentation {
    public static map = new Map([
        [ContentFields.contentId, 'Content Id'],
        [ContentFields.isLive, 'Is Live'],
    ]);
}

export enum PlayBackFields {
    sessionId = 'sessionId',
}

export class PlayBackFieldsRepresentation {
    public static map = new Map([[PlayBackFields.sessionId, 'Session Id']]);
}

export enum PlayerFields {
    isp = 'isp',
    countryCode = 'countryCode',
    state = 'state',
    city = 'city',
    userAgent = 'userAgent',
    os = 'os',
    osVersion = 'osVersion',
}

export class PlayerFieldsRepresentation {
    public static map = new Map([
        [PlayerFields.isp, 'Isp'],
        [PlayerFields.countryCode, 'Country Code'],
        [PlayerFields.state, 'State'],
        [PlayerFields.city, 'City'],
        [PlayerFields.userAgent, 'User Agent'],
        [PlayerFields.os, 'OS'],
        [PlayerFields.osVersion, 'OS Version'],
    ]);
}

export enum RuleType {
    Content = 'content',
    Playback = 'playback',
    Player = 'player',
    Usage = 'usage',
}

export class FieldRepresentation {
    @IsString()
    public value: string;

    @IsString()
    public representation: string;
}

export enum Operator {
    stringEquals = 'stringEquals',
    stringNotEquals = 'stringNotEquals',
    stringContains = 'stringContains',
    stringNotContains = 'stringNotContains',
    numericEquals = 'numericEquals',
    numericNotEquals = 'numericNotEquals',
    lessThan = 'lessThan',
    lessOrEquals = 'lessOrEquals',
    greaterThan = 'greaterThan',
    greaterOrEquals = 'greaterOrEquals',
    matches = 'matchesRegex',
    inList = 'inList',
    notInList = 'notInList',
}

export enum CutRecurrence {
    weekly = 'WEEKLY',
    monthly = 'MONTHLY',
    quarterly = 'QUARTERLY',
    yearly = 'YEARLY',
    custom = 'CUSTOM',
}

export class UsageRule {
    @ValidateNested()
    @Type(() => Rule)
    public usage: Rule;

    @IsEnum(CutRecurrence)
    public recurrence: CutRecurrence;

    @IsNumber()
    public startTimestamp: number;

    @IsString()
    public cdn: string = '';

    @IsNumber()
    public customPeriod: number;
}
