import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationError} from 'class-validator';
import {CdnCapacity, MultiCdnSettingsConfig} from '../models/multi-cdn-settings-config';

export const MULTI_CDN_SETTINGS_CDN_DELETE_EVENT = {
  CdnDelete: 'MultiCdnSettingsCapacities:Delete',
};

@autoinject()
export class MultiCdnSettingsCapacities {
  @bindable
  public multiCdnSettings: MultiCdnSettingsConfig;

  @bindable
  public validationErrors: ValidationError[];

  @bindable
  public callbacks;

  @bindable
  public initialError;

  @bindable
  public advancedStreamRoutingFeaturesEnabled;

  public subscriptions: Subscription[] = [];
  public tc;
  public hasError = false;
  public newCdnName: string = '';
  public tcNewCdn;
  public cdnIsDuplicate: boolean = false;
  public cdnNameRequired: boolean = false;

  constructor(public eventAggregator: EventAggregator, public router: Router) {}

  public attached() {
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        MULTI_CDN_SETTINGS_CDN_DELETE_EVENT.CdnDelete,
        opt =>
          new Promise(resolve => {
            this.deleteCdn(opt.itemTitle);
            resolve();
          }),
      ),
    );
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }

  public deleteCdn(cdnName) {
    let cdnToDelete;
    for (const cdn of this.multiCdnSettings.cdns) {
      if (cdn.name === cdnName) {
        cdnToDelete = cdn;
      }
    }
    const index = this.multiCdnSettings.cdns.indexOf(cdnToDelete);
    this.multiCdnSettings.cdns.splice(index, 1);
    this.callbacks.onChange();
  }

  public createNewCdnCapacity() {
    this.cdnNameRequired = false;
    this.cdnIsDuplicate = false;
    if (this.newCdnName.trim() === '') {
      this.cdnNameRequired = true;
      return;
    }

    for (const cdn of this.multiCdnSettings.cdns) {
      if (cdn.name.toLowerCase() === this.newCdnName.trim().toLowerCase()) {
        this.cdnIsDuplicate = true;
        return;
      }
    }

    this.cdnIsDuplicate = false;
    const cfg: CdnCapacity = new CdnCapacity();
    cfg.name = this.newCdnName.trim();
    this.multiCdnSettings.cdns.push(cfg);

    // clear out the values & close the tip
    this.cdnIsDuplicate = false;
    this.cdnNameRequired = false;
    this.newCdnName = this.multiCdnSettings.availablecdns[0];
    this.tcNewCdn.hide();
  }
}
