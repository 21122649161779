import {Acceo} from 'services/acceo';
import {PrebidConfigCollection} from '../../prebid-server/models/prebid-config';

export class PrebidConfigService {
  private url = '/api/v4/prebid_config/';

  constructor(protected acceo: Acceo) {}

  public get() {
    return this.acceo.get(PrebidConfigCollection)(this.url);
  }
}
