exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3y9lb{display:flex;flex-direction:column}._3y9lb>:last-child{margin-bottom:auto}.VqCfp{background:var(--timeline-week-dates-background);display:flex;position:sticky;top:0;z-index:var(--z_weekTimelineDates)}.VqCfp>:first-child{flex:1 0 300px;padding-left:50px}.VqCfp>*{background:var(--timeline-week-dates-background);border-bottom:var(--timeline-week-dates-border-bottom);flex:1 0 250px;padding-bottom:10px;text-align:center}.VqCfp a{color:var(--timeline-week-dates-link-color);text-decoration:none;transition:color .3s ease-in-out;transition:color var(--trans_main)}.VqCfp a:hover{color:var(--timeline-week-dates-link-color-hover)}._2GOW2{display:flex}._2GOW2>:first-child{background:var(--timeline-week-dates-background);left:0;position:sticky;width:50px;z-index:var(--z_weekTimelineHours)}._2GOW2>:first-child b-timeline-block>div{border-right:var(--timeline-week-dates-first-border-right)}._2GOW2>:nth-child(2)>:first-child{border-left:none}._2GOW2>:last-child{flex:1 1 auto}._1oQDG{display:flex}._1oQDG>*{border-left:var(--timeline-week-content-border-left);flex:1 0 250px;transform:unset}._1oQDG .timeline-week>div,._1oQDG b-time-entry>div{width:calc(100% - 30px)}._25I2M{position:relative}._25I2M:after{background:var(--timeline-week-dates-today-color);bottom:-4px;content:\"\";height:2px;left:0;position:absolute;width:100%}", ""]);

// exports
exports.locals = {
	"wrapper": "_3y9lb",
	"dates": "VqCfp",
	"days": "_2GOW2",
	"content": "_1oQDG",
	"today": "_25I2M"
};