import {bindable, inject, customElement} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';

@customElement('tabs')
@inject(Element, EventAggregator)
export class Tabs {
    @bindable
    tabs = null;

    @bindable
    modifier = '';

    @bindable
    identifier = '';

    constructor(element, ea) {
        this.element = element;
        this.ea = ea;
    }

    bind() {
        this.additionalClasses = `nav--tabs--${this.modifier}`;
    }

    attached() {
        // const sections = document.getElementsByClassName('tab-sections__tab-section');

        this.element.addEventListener('click', e => {
            if (e.target && e.target.tagName === 'A') {
                const sectionId = e.target.getAttribute('href').replace('#', '');
                const section = document.getElementById(`_tabbed-section-${sectionId}`);

                if (section) {
                    this.ea.publish(`tab.change-${sectionId}`, {});
                    const selectedTabs = document.getElementsByClassName('nav--tabs__tab--active');

                    if (selectedTabs[0]) {
                        if (this.identifier === '') {
                            selectedTabs[0].classList.remove('nav--tabs__tab--active');
                        } else {
                            let i = 0;
                            for (i = 0; i < selectedTabs.length; i += 1) {
                                if (selectedTabs[i].parentElement.parentElement.classList.contains(this.identifier)) {
                                    selectedTabs[i].classList.remove('nav--tabs__tab--active');
                                }
                            }
                        }
                    }

                    e.target.parentElement.classList.add('nav--tabs__tab--active');

                    const selectedSections = document.getElementsByClassName('tab-sections__tab-section--active');
                    if (this.identifier === '') {
                        selectedSections[0].classList.remove('tab-sections__tab-section--active');
                    } else {
                        let i = 0;
                        for (i = 0; i < selectedSections.length; i += 1) {
                            if (selectedSections[i].parentElement.classList.contains(this.identifier)) {
                                selectedSections[i].classList.remove('tab-sections__tab-section--active');
                            }
                        }
                    }
                    section.classList.add('tab-sections__tab-section--active');
                }
            }
            e.preventDefault();
        });
    }
}
