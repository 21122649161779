import {bindable, computedFrom} from 'aurelia-framework';

import {IOption} from 'interfaces/options';

import * as styles from './c-toggle-group.css';

// eslint-disable-next-line no-shadow
export enum ToggleType {
  Checkbox = 'checkbox',
  Switch = 'switch',
}

export interface IToggleOption extends IOption {
  enabled?: boolean;
  syncValue?: string;
}

export interface IToggleGroup extends IOption {
  enabled?: boolean;
  indeterminate?: boolean;
  options: IToggleOption[];
  permissions?: string[];
}

function hasSearch(option: IOption, searchText: string) {
  const search = searchText.toLowerCase();
  return option.text.toLowerCase().includes(search) || option.value.includes(search);
}

export class CToggleGroup {
  @bindable()
  public groups: IToggleGroup[];

  @bindable()
  public value: string[];

  @bindable()
  public disabled: boolean = false;

  @bindable()
  public type: ToggleType = ToggleType.Switch;

  @bindable()
  public showValue: boolean = false;

  public styles = styles;
  public searchText: string = '';

  // private source: IToggleGroup[];
  private tracked: Set<string>;

  @computedFrom('type')
  public get isCheckbox() {
    return this.type === ToggleType.Checkbox;
  }

  @computedFrom('type')
  public get isSwitch() {
    return this.type === ToggleType.Switch;
  }

  @computedFrom('groups', 'searchText', 'tracked.size')
  public get filteredGroups() {
    if (this.searchText.length === 0) {
      return this.groups;
    }

    const groups = _.cloneDeep(this.groups);

    return groups.filter(group => {
      group.options = group.options.filter(o => hasSearch(o, this.searchText));
      const hasOptions = group.options.length > 0;

      return hasSearch(group, this.searchText) || hasOptions;
    });
  }

  /*
    Aurelia Hooks
  */

  public valueChanged(value) {
    this.renderGroups(value);
  }

  /*
    Public Methods
  */

  public toggleOption(value: string) {
    for (let i = 0; i < this.groups.length; i++) {
      const group = this.groups[i];

      if (!value.includes(group.value)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      this.enableOption(group, value);

      const selected = group.options.filter(o => o.enabled === true);

      group.enabled = group.options.length === selected.length;
      group.indeterminate = !group.enabled && selected.length > 0;
    }

    this.updateValue();
  }

  public toggleGroup(value: string) {
    for (let i = 0; i < this.groups.length; i++) {
      const group = this.groups[i];

      if (group.value !== value) {
        // eslint-disable-next-line no-continue
        continue;
      }

      group.enabled = !group.enabled;
      group.indeterminate = false;

      group.options.forEach(o => {
        o.enabled = group.enabled;

        this.updateTrackedValue(o.value, group.enabled);
      });
    }

    this.updateValue();
  }

  /*
    Private Methods
  */

  private enableOption(group: IToggleGroup, value: string, forceEnabled: boolean = false) {
    const option = group.options.find((t: IToggleOption) => t.value === value);

    if (option) {
      option.enabled = forceEnabled || !option.enabled;

      this.updateTrackedValue(value, option.enabled);

      if (option.enabled && !!option.syncValue) {
        this.enableOption(group, option.syncValue, true);
      }
    }
  }

  private renderGroups(value: string[]) {
    this.tracked = new Set(value);

    for (let i = 0; i < this.groups.length; i++) {
      const group = this.groups[i];
      group.enabled = this.tracked.has(group.value);

      group.options.forEach((o: IToggleOption) => {
        o.enabled = this.tracked.has(o.value) || group.enabled;
      });

      const selected = group.options.filter(o => o.enabled === true);

      group.indeterminate = !group.enabled && selected.length > 0;
    }
  }

  private updateTrackedValue(value, enabled) {
    if (enabled === true) {
      this.tracked.add(value);
    } else {
      this.tracked.delete(value);
    }
  }

  private updateValue() {
    const _scopes = this.groups.reduce((scopes, group) => {
      let options = [];

      if (group.enabled) {
        options = [group.value];
      } else {
        options = group.options.filter(o => o.enabled).map(o => o.value);
      }

      return scopes.concat(options);
    }, []);

    this.value = _scopes;
  }
}
