exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3aXZ4{display:block;height:100%;min-width:var(--timeline-month-min-width)}._3aXZ4>div>div{border-right:var(--timeline-month-border)}._31VUC{display:inherit;position:sticky;top:0;z-index:var(--z_weekTimelineDates)}._31VUC span{background:var(--timeline-month-header-background);color:var(--timeline-month-header-color);padding:var(--timeline-month-header-padding);position:relative;text-align:center}._31VUC span:after{border:var(--timeline-month-border);border-right:none;content:\"\";display:inline-block;height:100%;left:0;position:absolute;top:0;width:100%}", ""]);

// exports
exports.locals = {
	"container": "_3aXZ4",
	"header": "_31VUC"
};