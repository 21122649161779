import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Router)
export class AppHeader {
    constructor(router) {
        this.router = router;
    }

    toggleMobileNav() {
        document.getElementById('js_MobileNav').classList.toggle('nav-responsive--active');
    }
}
