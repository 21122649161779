import {autoinject, computedFrom, observable, BindingEngine, LogManager} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';

import {CToastsService} from '@bindable-ui/bindable';

import {FormValidator, IFieldValidations} from 'apps/cms/utils/form-validator';
import {ITrackedModel, trackedModel} from 'apps/cms/utils/tracked-model';

import {ISelectOption} from 'interfaces/select';

import {SSO_PROVIDERS, PROVIDER_DEFAULTS, PROTOCOL_BINDINGS} from '../../../constants';

import {ISsoDomain, SsoDomain} from '../../../models/sso-domain';
import {IIntegrationNew, IdsIntegrationNew, IdsIntegration} from '../../../models/ids-integration';
import {SsoIntegrationValidators, SsoDomainValidators, validatorHasErrors} from '../../../models/validators';

import {SsoDomainService} from '../../../services/sso-domain';

export interface IActions {
  onClose: () => void;
  onSave: () => void;
}

export interface ITrackedDomain extends SsoDomain, ITrackedModel {}
export interface ITrackedIntegration extends IdsIntegrationNew, ITrackedModel {}

export const TRACKED_DOMAIN_FIELDS = [
  'desc',
  'domain',
  'domain_binding',
  'provider',
  'title',
];

@autoinject()
export class DomainCreate {
  @observable()
  public selectedProvider: string;

  public isLoading: boolean = false;
  public isValid: boolean = true;

  public model: ITrackedDomain;
  public integrationModel: ITrackedIntegration = null;

  public ssoProviders: ISelectOption[] = SSO_PROVIDERS;
  public ssoProtocolBinding: ISelectOption[] = PROTOCOL_BINDINGS;

  public domainValidator: FormValidator;
  public integrationValidator: FormValidator;

  protected logger: Logger;

  private actions: IActions;
  private trackValidation: boolean;

  private domainValidators: IFieldValidations = SsoDomainValidators;
  private integrationValidators: IFieldValidations = SsoIntegrationValidators;

  @computedFrom('model.id', 'isLoading')
  public get dialogTitle() {
    if (this.isLoading) {
      return 'Loading...';
    }

    const prefix = this.model?.id ? 'Update' : 'Create';
    return `${prefix} SSO Domain Integration`;
  }

  @computedFrom('model.id', 'model.isDirty', 'integrationModel.isDirty', 'isValid')
  public get saveDisabled() {
    if (!this.model?.id) {
      return !this.isValid;
    }

    const {isDirty: modelIsDirty = false} = this.model;
    const {isDirty: integrationIsDirty = false} = this.integrationModel;

    const isDirty = modelIsDirty || integrationIsDirty;
    return !this.isValid || !isDirty;
  }

  @computedFrom(
    'trackValidation',
    'domainValidator.errors.desc',
    'domainValidator.errors.domain',
    'domainValidator.errors.title',
  )
  public get hasDomainErrors(): boolean {
    return this.trackValidation ? validatorHasErrors(this.domainValidator) : false;
  }

  @computedFrom(
    'trackValidation',
    'integrationValidator.errors.certificate',
    'integrationValidator.errors.entity',
    'domainValidator.errors.sign_in_uri',
    'domainValidator.errors.user_email_attribute',
    'domainValidator.errors.user_id_attribute',
  )
  public get hasIntegrationErrors(): boolean {
    return this.trackValidation ? validatorHasErrors(this.integrationValidator) : false;
  }

  constructor(
    public bindingEngine: BindingEngine,
    public domainService: SsoDomainService,
    public notificationService: CToastsService,
  ) {
    this.logger = LogManager.getLogger('Acuity | Identity | SSO | Domain Modal');
  }

  /*
    Aurelia Hooks
  */

  public activate({actions, domainId}) {
    this.actions = actions;

    this.trackValidation = false;

    if (domainId) {
      // Existing SsoDomain
      this.loadDomainModel(domainId);
    } else {
      // New SsoDomain
      this.initDomain();
    }
  }

  public deactivate() {
    this.domainValidator = null;
    this.model = null;

    this.integrationValidator = null;
    this.integrationModel = null;
  }

  public selectedProviderChanged(value: string) {
    this.model.provider = value;
    this.initProvider(value);
  }

  /*
    Public Methods
  */

  public closeDialog(e?: Event, preventClose: boolean = false) {
    e?.preventDefault();

    if (preventClose) {
      return;
    }

    if (this.domainService.isProcessing) {
      this.notificationService.warning('Processing, please wait.');
      return;
    }

    this.actions.onClose();
  }

  public async save() {
    if (this.domainService.isProcessing) {
      this.notificationService.warning('Processing... Try again later.');
      return;
    }

    this.domainValidator.validate();
    this.integrationValidator.validate();
    this.trackValidation = true;
    this.validate();

    if (!this.isValid) {
      this.notificationService.error('Please fix issues and try again.');
      return;
    }

    let mode = 'create';

    try {
      if (this.model.id) {
        mode = 'update';
        await this.updateSsoDomain();

        this.notificationService.success('Updated');
      } else {
        await this.createSsoDomain();

        this.notificationService.success('Created');
      }

      this.actions.onSave();
    } catch (err) {
      this.notificationService.error(`Failed to ${mode} config: ${err.message}`);
    }
  }

  /*
    Private Methods
  */

  private async createSsoDomain(): Promise<void> {
    const config = PROVIDER_DEFAULTS[this.model.provider];

    const integrationKeys = Object.keys(config);

    const domain: any = TRACKED_DOMAIN_FIELDS.reduce((obj, k) => {
      obj[k] = this.model[k];
      return obj;
    }, {});

    const integration: any = integrationKeys.reduce((obj, k) => {
      obj[k] = this.integrationModel[k];
      return obj;
    }, {});

    await this.domainService.createRecord(domain, integration);
  }

  private initDomain() {
    const model = {
      desc: '',
      domain: '',
      domain_binding: false,
      id: null,
      provider: null,
      title: '',
    };

    this.trackDomainModel(model);

    // will call selectedProviderChanged and init the integrationModel
    this.selectedProvider = this.ssoProviders[0].value;
  }

  private initProvider(provider: string): void {
    const defaults = PROVIDER_DEFAULTS[provider];

    if (!defaults) {
      throw new Error('Unable to load provider defaults');
    }

    this.trackIntegrationModel(_.cloneDeep(defaults));
  }

  private validate(): void {
    // We don't want to run the validations unless the user
    // has clicked the save button
    if (!this.trackValidation) {
      return;
    }

    if (!this.domainValidator || !this.integrationValidator) {
      this.isValid = false;
      return;
    }

    const domainIsValid = this.domainValidator.isValid();
    const integrationIsValid = this.integrationValidator.isValid();

    this.isValid = domainIsValid && integrationIsValid;
  }

  private async loadDomainModel(domainId): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    try {
      const model = await this.domainService.getRecord(domainId, true);

      this.trackDomainModel(model);

      const integration: IdsIntegration = model['@included'][0];
      const {name, value} = integration.certificates[0];

      this.trackIntegrationModel({...integration, certificate: value, certificate_name: name});
    } catch (err) {
      this.logger.error(err.message);
      this.notificationService.error('Failed to load');
    } finally {
      this.isLoading = false;
    }
  }

  private trackDomainModel(model: ISsoDomain) {
    this.model = trackedModel({
      model,
      SuperClass: SsoDomain,
      trackedKeys: TRACKED_DOMAIN_FIELDS,
    });

    if (model.id) {
      this.selectedProvider = this.model.provider;
    }

    this.domainValidator = new FormValidator(this.model, this);
    this.domainValidator.register(this.domainValidators, this.bindingEngine, () => this.validate());
  }

  private trackIntegrationModel(model: IIntegrationNew) {
    if (this.integrationValidator) {
      this.integrationValidator.cleanup();
      this.integrationValidator = null;
    }

    this.integrationModel = trackedModel({
      model,
      SuperClass: IdsIntegrationNew,
      trackedKeys: [
        'certificate',
        'digest',
        'encrypt_assertion',
        'entity',
        'protocol_binding',
        'sign_assertion',
        'sign_authentication_request',
        'sign_in_uri',
        // 'sign_request',
        'sign_response',
        'signature',
      ],
    });

    this.integrationValidator = new FormValidator(this.integrationModel, this);
    this.integrationValidator.register(this.integrationValidators, this.bindingEngine, () => this.validate());
  }

  private async updateSsoDomain(): Promise<void> {
    const domain = this.model.dirtyParams();
    const integration = this.integrationModel.dirtyParams();

    await this.domainService.updateRecord(this.model.id, {domain, integration});
  }
}
