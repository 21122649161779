import {CTableActions, CTableCol, LocalStorageHelper} from '@bindable-ui/bindable';
import {autoinject, bindable, computedFrom, containerless} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {BasePlaylistSingle} from '../base';

@autoinject()
@containerless()
/* istanbul ignore next */
export class PlaylistPlayback extends BasePlaylistSingle {
    public embedHeight: number = _.toNumber(LocalStorageHelper.loadOrDefault('playlistEmbedHeight', 480)) || 480;
    public embedWidth: number = _.toNumber(LocalStorageHelper.loadOrDefault('playlistEmbedWidth', 640)) || 640;

    public selectedTestPlayers: any[] = [];
    public addTestPlayerTip: any;
    public isDeletingTestPlayers: boolean;
    public testPlayersChangeTracker: number;
    public showExpiredHelpText: boolean = false;
    public testPlayerName: string = '';
    public testPlayersError: string = null;
    public expirationDate: number;
    public expireEmbedTip: any;
    public whitelist: boolean = false;
    @bindable public skip_drm: boolean = false;
    @bindable public studio_drm_required: boolean = false;

    public tabFields: string[] = [
        'embed_domains',
        'embed_html5_player_url',
        'skip_drm',
        'studio_drm_required',
        'test_players',
    ];

    public testPlayersCols: CTableCol[] = [
        {
            checkChanged: row => this.trackSelectedTestPlayer(row),
            colClass: 't30',
            colHeadName: 'checkbox',
            colHeadSelectedChanged: isChecked => this.selectAllTestPlayers(isChecked),
            colHeadSelectedVal: false,
            colHeadValue: 'Select',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
        },
        {
            colHeadName: 'desc',
            colHeadValue: 'Name',
            sort: true,
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
            ),
        },
        {
            colClass: 't175',
            colHeadName: 'expire',
            colHeadValue: 'Exp Date',
            sort: true,
            valueConverter: 'altTimestampToDMY',
        },
        {
            colClass: 't50',
            colHeadName: 'whitelist',
            colHeadValue: 'Allowlist',
            sort: true,
            valueConverter: 'booleanYesNo',
        },
        {
            colAction: row => this.runTestPlayer(row),
            colClass: 't50',
            colHeadName: 'view',
            colHeadValue: 'View',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action',
            ),
        },
    ];

    public testPlayerActions: CTableActions = {
        rowClick: row => {
            if (this.session.hasTestPlayersWriteAccess) {
                this.openTestPlayer(row);
            }
        },
    };

    // eslint-disable-next-line class-methods-use-this
    public runTestPlayer(row) {
        window.open(row.url_html5);
    }

    public openTestPlayer(tp) {
        if (!tp.id) {
            this.notification.error('Test player must be saved before you can edit it.');
            return;
        }

        const activeTpIndex = _.findIndex(this.model.test_players, {id: tp.id});
        const editTp = _.cloneDeep(tp);

        this.dialogService.open({
            model: {
                bodyModel: {
                    testPlayer: editTp,
                },
                bodyViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/channels/single/modals/test-player/test-player-body',
                ),
                footerEnable: true,
                footerModel: {
                    updateTestPlayer: /* istanbul ignore next */ () => this.updateTestPlayer(editTp, activeTpIndex),
                },
                footerViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/channels/single/modals/test-player/test-player-footer',
                ),
                sharedModel: {
                    errMsg: '',
                },
                size: 'medium',
                title: `Edit ${tp.desc}`,
            },
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        });
    }

    public updateTestPlayer(tp, index) {
        let err = null;

        const clonedTp = _.cloneDeep(tp);

        let dupeCheck = [];
        const descChanged = _.find(this.model.test_players, {desc: clonedTp.desc, url_html5: clonedTp.url_html5});
        if (!descChanged) {
            dupeCheck = _.filter(this.model.test_players, {desc: clonedTp.desc});
        }

        if (dupeCheck.length > 0) {
            err = 'Test player must have a unique name';
            return err;
        }

        if (!clonedTp.desc) {
            err = 'Test player must have a name';
            return err;
        }

        this.model.test_players.splice(index, 1, clonedTp);
        this.testPlayersChangeTracker = Date.now();

        return err;
    }

    public attached() {
        if (!this.model.skip_drm) {
            this.skip_drm = true;
        } else {
            this.skip_drm = false;
        }

        if (this.model.studio_drm_required) {
            this.studio_drm_required = true;
        } else {
            this.studio_drm_required = false;
        }
    }

    public addTestPlayer() {
        this.testPlayersError = null;

        if (!this.testPlayerName) {
            this.testPlayersError = 'Name required';
            return;
        }

        if (_.find(this.model.test_players, {desc: this.testPlayerName})) {
            this.testPlayersError = 'Test Player already exists';
            return;
        }

        this.model.test_players.push({
            desc: this.testPlayerName,
            expire: this.expirationDate,
            params: '',
            url: 'A URL will be generated on save',
            url_html5: 'A URL (HTML5) will be generated on save',
            whitelist: this.whitelist,
        });

        this.testPlayerName = '';
        this.expirationDate = null;
        this.whitelist = false;
        if (this.addTestPlayerTip) {
            this.addTestPlayerTip.hide();
        }

        this.testPlayersChangeTracker = Date.now();
    }

    public async deleteTestPlayers() {
        _.remove(this.model.test_players, tp => tp.checkbox);
        this.selectedTestPlayers = [];
        this.testPlayersChangeTracker = Date.now();
    }

    public expireEmbedCode() {
        this.model.embed_html5_player_url = '';
        this.showExpiredHelpText = true;
        if (this.expireEmbedTip) {
            this.expireEmbedTip.hide();
        }
        this.notification.success('New Embedded HTML will be requested upon Save');
    }

    public skip_drmChanged() {
        const numSkipDrm = Number(this.skip_drm);
        this.model.skip_drm = numSkipDrm === 1 ? 0 : 1;
    }

    public studio_drm_requiredChanged() {
        this.model.studio_drm_required = Number(this.studio_drm_required);
    }

    private trackSelectedTestPlayer(tp) {
        if (tp.checkbox) {
            this.selectedTestPlayers.push(tp.id);
            this.selectedTestPlayers = _.uniq(this.selectedTestPlayers);
        } else {
            _.remove(this.selectedTestPlayers, tpId => tpId === tp.id);
        }
    }

    /**
     * Select/Deselect
     *
     * @param isSelected
     */
    private selectAllTestPlayers(isSelected: boolean) {
        _.forEach(this.model.test_players, tp => {
            tp.checkbox = isSelected;
        });
    }

    // Used for iframe element generation
    @computedFrom('embedHeight')
    get safeEmbedHeight() {
        if (!this.embedHeight || !_.toNumber(this.embedHeight)) {
            return LocalStorageHelper.loadOrDefault('playlistEmbedHeight', 480);
        }

        LocalStorageHelper.save('playlistEmbedHeight', _.toNumber(this.embedHeight));
        return _.toNumber(this.embedHeight);
    }

    // Used for iframe element generation
    @computedFrom('embedWidth')
    get safeEmbedWidth() {
        if (!this.embedWidth || !_.toNumber(this.embedWidth)) {
            return LocalStorageHelper.loadOrDefault('playlistEmbedWidth', 640);
        }

        LocalStorageHelper.save('playlistEmbedWidth', _.toNumber(this.embedWidth));
        return _.toNumber(this.embedWidth);
    }

    @computedFrom('embedHeight')
    // Used for iframe element validation
    get noBlankHeight() {
        if (!this.embedHeight) {
            return 'error';
        }
        return '';
    }

    @computedFrom('embedWidth')
    // Used for iframe element validation
    get noBlankWidth() {
        if (!this.embedWidth) {
            return 'error';
        }
        return '';
    }

    @computedFrom('model.hls_url', 'model.studio_drm_required')
    get hlsUrl() {
        if (this.model.studio_drm_required) {
            return `${this.model.hls_url}?rmt=fps`;
        }

        return this.model.hls_url;
    }

    // Need one for every field on the tab + one for the tab
    @computedFrom('model.embed_domains')
    get embedDomainsDirty() {
        if (!this.playlistService.playlist || !this.model) {
            return false;
        }

        const isDirty = this.playlistService.playlist.embed_domains !== this.model.embed_domains;

        // Update field `isDirty` state
        this.fields.embed_domains.isDirty = isDirty;

        return isDirty;
    }

    @computedFrom('model.embed_html5_player_url')
    get embedHTML5PlayerURLDirty() {
        if (!this.playlistService.playlist || !this.model) {
            return false;
        }

        const isDirty = this.playlistService.playlist.embed_html5_player_url !== this.model.embed_html5_player_url;

        // Update field `isDirty` state
        this.fields.embed_html5_player_url.isDirty = isDirty;

        return isDirty;
    }

    @computedFrom('model.skip_drm')
    get requireDrmDirty() {
        if (!this.playlistService.playlist || !this.model) {
            return false;
        }

        const isDirty = this.playlistService.playlist.skip_drm !== this.model.skip_drm;

        // Update field `isDirty` state
        this.fields.skip_drm.isDirty = isDirty;

        return isDirty;
    }

    @computedFrom('model.studio_drm_required')
    get requireStudioDrmDirty() {
        if (!this.playlistService.playlist || !this.model) {
            return false;
        }

        const isDirty = this.playlistService.playlist.studio_drm_required !== this.model.studio_drm_required;

        // Update field `isDirty` state
        this.fields.studio_drm_required.isDirty = isDirty;

        return isDirty;
    }

    // Aurelia won't track an array, so we have track when a value is changed
    @computedFrom('model.test_players', 'testPlayersChangeTracker')
    get testPlayersDirty() {
        if (!this.playlistService.playlist || !this.model) {
            return false;
        }

        let isDirty = false;

        if (this.playlistService.playlist.test_players && this.model.test_players) {
            if (this.playlistService.playlist.test_players.length !== this.model.test_players.length) {
                isDirty = true;
            }
        }

        if (!isDirty) {
            _.forEach(this.playlistService.playlist.test_players, tp => {
                const otherTp = _.find(this.model.test_players, {id: tp.id});

                if (
                    !otherTp ||
                    otherTp.desc !== tp.desc ||
                    otherTp.expire !== tp.expire ||
                    otherTp.whitelist !== tp.whitelist ||
                    otherTp.params !== tp.params
                ) {
                    isDirty = true;
                    return false; // Break out of loop
                }
                return true;
            });
        }

        // Update field `isDirty` state
        this.fields.test_players.isDirty = isDirty;

        return isDirty;
    }

    @computedFrom(
        'embedDomainsDirty',
        'embedHTML5PlayerURLDirty',
        'requireDrmDirty',
        'requireStudioDrmDirty',
        'testPlayersDirty',
    )
    get tabDirty() {
        return (
            this.embedDomainsDirty ||
            this.embedHTML5PlayerURLDirty ||
            this.requireDrmDirty ||
            this.requireStudioDrmDirty ||
            this.testPlayersDirty
        );
    }

    @computedFrom('isDeletingTestPlayers', 'selectedTestPlayers.length')
    get deleteButtonState() {
        if (!this.selectedTestPlayers.length) {
            return 'disabled';
        }

        return '';
    }
}
