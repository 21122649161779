import {autoinject, LogManager} from 'aurelia-framework';
import * as _ from 'lodash';

import {CToastsService} from '@bindable-ui/bindable';
import {Acceo} from 'services/acceo';
import {DEFAULT_PER_PAGE_LIMIT} from '../models/defaults';

import {HyperionFilterParams, HyperionMeta} from '../../live-channels/models/models';
import {AssetType, Playlist, PlaylistListResponse} from './models';
import {IPlaylistAssetsTableRow} from './single/assets/table-models';

const log = LogManager.getLogger('linear-playlists-service');

@autoinject()
export class PlaylistService {
  public createErrMsg: string = null;
  public createPlaylistDialog: any;
  public createPlaylistForm: any;
  public duplicatePlaylistDialog: any;
  public duplicatePlaylistForm: any;
  public isCreating: boolean;
  public isCreatingWithEdit: boolean;
  public isDeleting: boolean;
  public isLoading: boolean;
  public searchLoading: boolean;
  public isLoadingMore: boolean;
  public playUrl: string;
  public newPlaylistName: string = '';
  public playlists: Playlist[] = [];
  public playlist: Playlist = null;
  public model: Playlist = null;
  public meta: HyperionMeta = {};
  public params: HyperionFilterParams = {};
  public viewModel: any;
  public isSaving: boolean;
  public searchQuery: string = '';
  public searchQueryApplied: string = '';
  public assetTableData: IPlaylistAssetsTableRow[] = [];
  public cleanAssetTableData: IPlaylistAssetsTableRow[] = [];
  public selectedPlaylists: string[] = [];
  private linearPlaylistApiUrl = '/api/v4/linear-playlist';

  constructor(private acceo: Acceo, private notification: CToastsService) {}

  /**
   * Reset params to default
   */
  public cleanParams() {
    // Reset the params to be default
    this.params = {};
  }

  /**
   * Creates a new Linear Playlist
   *
   * @param andEdit If you're going to navigate to it after creation
   */
  public async createPlaylist(andEdit: boolean = false) {
    this.createErrMsg = null;
    if (!this.newPlaylistName.length) {
      this.createErrMsg = 'Name is required.';
      return null;
    }

    this.isCreating = true;
    this.isCreatingWithEdit = andEdit;
    const createParams = {desc: this.newPlaylistName, playlist: []};
    try {
      const res = await this.acceo.post(Playlist)(this.linearPlaylistApiUrl, createParams);
      this.notification.success('Playlist created successfully.');
      res.entries = 0;
      this.playlists.unshift(res);

      this.meta.total += 1;
      this.meta.showing = this.playlists.length;
      this.newPlaylistName = '';
      this.createPlaylistDialog.hide();
      if (andEdit) {
        // Was getting errors with navigateToRoute so doing it the old-fashioned way...
        window.location.href = `#/content/playlists/${res.id}`;
      }
      return res;
    } catch (err) {
      this.createErrMsg = 'An error occurred.';
      this.notification.error(`An error occurred creating a new playlist: ${err}`, 'Playlist Creation Error');
      log.error(err);
    } finally {
      this.isCreating = false;
      this.isCreatingWithEdit = false;
    }
    return null;
  }

  /**
   * Deletes a list of Linear Playlists
   * @param ids An array of ids of linear playlist to delete.
   */
  public async archivePlaylist(ids: string[]): Promise<string[]> {
    if (this.isDeleting) {
      return [];
    }

    this.isDeleting = true;
    try {
      await Promise.all(
        ids.map(async id => {
          await this.acceo.delete()(`${this.linearPlaylistApiUrl}/${id}`);
          // Remove the playlist from the array
          this.playlists = this.playlists.filter(playlist => playlist.id !== id);

          // Remove the playlist ID from selectedPlaylists
          const index = this.selectedPlaylists.indexOf(id);
          if (index !== -1) {
            this.selectedPlaylists.splice(index, 1);
          }
        }),
      );
      this.isDeleting = false;
      return ids;
    } catch (err) {
      this.isDeleting = false;
      throw err;
    }
  }

  /**
   * Track a playlist as it's toggled for deletion
   *
   * @param playlist Playlist object
   */
  public trackSelectedPlaylist(playlist: Playlist) {
    if (playlist.checkbox) {
      this.selectedPlaylists.push(playlist.id);
    } else {
      _.remove(this.selectedPlaylists, playlistId => playlistId === playlist.id);
    }
  }

  /**
   * Toggle all visible playlists as deletable
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.playlists, playlist => {
      if (isSelected) {
        playlist.checkbox = true;
      } else {
        playlist.checkbox = false;
      }
    });

    this.selectedPlaylists = _.map(
      _.filter(this.playlists, playlist => playlist.checkbox),
      playlist => playlist.id,
    );
  }

  /**
   * Get a list of Linear playlists
   * @param params HyperionFilterParams object
   * @param isLoadMore Optional Boolean for if it's a fresh load or it's loading more in the existing list
   * @returns Promise with resolve or reject
   */
  public async getPlaylists(params: HyperionFilterParams, isLoadMore: boolean = false) {
    if (this.isLoading || this.isLoadingMore) {
      return Promise.resolve(this.playlists);
    }
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (this.searchQuery) {
      params.search = this.searchQuery;
    }
    if (!params.order) {
      params.order = 'desc';
    }
    if (!isLoadMore) {
      this.playlists = [];
      this.isLoading = true;
    } else {
      this.isLoadingMore = true;
      params.page = Math.floor((this.meta.total - (this.meta.total - this.playlists.length)) / params.page_size) + 1;
    }

    this.params = _.cloneDeep(params);

    try {
      const res = await this.acceo.get(PlaylistListResponse)(`${this.linearPlaylistApiUrl}?${$.param(this.params)}`, {
        responseTransform: (resp: PlaylistListResponse) => resp,
      });
      /* Since we don't have polling for linear playlists
            Sometimes our server cache returns the deleted items that we just remove
            */
      for (let i = 0; i < res.items.length; i++) {
        if (res.items[i].deleted) {
          /* istanbul ignore next */
          res.items.splice(i, 1);
        }
      }
      this.playlists = _.uniqBy(this.playlists.concat(res.items), 'id');
      this.params.since = res.now;
      this.meta.total = res.total_items;
      this.meta.showing = this.playlists.length;
      this.meta.hasMore = this.playlists.length < res.total_items;
      return Promise.resolve(this.playlists);
    } catch (err) {
      log.error(err);
      this.notification.error(`Error loading playlists: ${err}`);
      return Promise.reject(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  public async getMorePlaylists() {
    if (this.meta.showing < this.meta.total) {
      const params = _.cloneDeep(this.params);
      delete params.since;
      return this.getPlaylists(params, true);
    }
    return Promise.resolve(this.playlists);
  }

  /**
   * Get a playlist based on ID. If it is in the list of playlists, return it, otherwise
   * retrieve using the API.
   * @param playlistId ID of the playlist to retrieve
   */
  public async getPlaylistFromCacheOrLoad(playlistId) {
    let playlist: Playlist = this.playlists.find(pl => pl.id === playlistId);
    if (!playlist) {
      playlist = await this.getPlaylist(playlistId);
      this.playlists.push(playlist);
    } else if (this.playlistHasAssets(playlist) && !('@included' in playlist)) {
      playlist = await this.getPlaylist(playlistId);
      // replace updated playlist in the list
      this.replacePlaylistInList(playlist);
    }

    this.playlist = playlist;
    this.model = _.cloneDeep(this.playlist);
    return playlist;
  }

  /**
   * Updates the playlist in the main list of playlists. This keeps the cache up to date and reduces API calls
   */
  public replacePlaylistInList(playlist) {
    const idx: number = this.playlists.findIndex(pl => pl.id === playlist.id);
    if (idx === -1) {
      this.playlists.push(playlist);
    } else {
      this.playlists.splice(idx, 1, playlist);
    }
  }

  /**
   * Check to see if a playlist has any assets
   * @param playlist Playlist object
   * @returns boolean
   */
  // eslint-disable-next-line class-methods-use-this
  public playlistHasAssets(playlist: Playlist) {
    return playlist.playlist.some((pl: object) => AssetType.BEAM in pl);
  }

  /**
   * Get a playlist by ID
   * @param id ID of a single playlist to display.
   * @returns Single playlist.
   */
  public async getPlaylist(id: string) {
    try {
      return await this.acceo.get(Playlist)(`${this.linearPlaylistApiUrl}/${id}`);
    } catch (err) {
      log.error(err);
      this.notification.error(`Error getting playlist by ID: ${err}`);
      return null;
    }
  }
  /**
   * Search Linear Playlist
   * @param searchQuery Query or playlist name to search from Linear Playlist.
   * @returns Promise resolved or reject
   */

  public async search(searchQuery?: string) {
    this.searchQuery = searchQuery;
    this.searchQueryApplied = '';
    const params: HyperionFilterParams = {};
    /* istanbul ignore next */
    if (searchQuery) {
      params.search = searchQuery;
    }
    this.searchLoading = true;
    try {
      await this.getPlaylists(params);
      this.searchQueryApplied = searchQuery;
      return Promise.resolve(this.playlists);
    } catch (err) {
      log.error(err);
      return Promise.reject(err);
    } finally {
      this.searchLoading = false;
    }
  }

  public async getPlayerURL(id: string, appliedParams: string = '') {
    try {
      (this.playUrl as any) = await this.acceo.post()(
        `/playlist/playurl${appliedParams}`,
        {id},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          requestTransform: params => $.param(params),
          responseTransform: resp => resp.url,
        },
      );
    } catch (err) {
      this.playUrl = '';
      log.error(err);
      throw new Error(err.message);
    }

    return this.playUrl;
  }

  /**
   * Save playlist
   */
  public async savePlaylist(payload: any) {
    this.isSaving = true;
    try {
      const {id} = payload;
      delete payload.id;
      const res = await this.acceo.patch(Playlist)(`${this.linearPlaylistApiUrl}/${id}`, payload);
      this.playlist = res;
      // patch result doesn't include side-loaded assets, but maybe it should
      const included = this.model['@included'];
      this.model = _.cloneDeep(this.playlist);
      this.model['@included'] = included;
      this.replacePlaylistInList(this.playlist);
      this.cleanAssetTableData = _.clone(this.assetTableData);
      this.notification.success('Saved playlist sucessfully');
      return res;
    } catch (err) {
      log.error(err);
      this.notification.error(`Error saving playlist: ${err}`);
      return null;
    }
  }

  public async duplicatePlaylist(edit: boolean = false) {
    this.isSaving = true;
    try {
      const playlist = _.cloneDeep(this.model);
      playlist.desc = this.newPlaylistName;

      const notAllowedAttrs = [
        'created',
        'dash_url',
        'deleted',
        'embed_domains',
        'embed_html5_player_url',
        'hls_url',
        'id',
        'poster_url',
        'total_duration',
        'test_players',
      ];

      notAllowedAttrs.forEach(attr => {
        playlist[attr] = undefined;
      });

      const res = await this.acceo.post(Playlist)(this.linearPlaylistApiUrl, playlist);
      if (edit) {
        window.location.href = `#/content/playlists/${res.id}`;
      }
      this.notification.success('Playlist duplication succeeded');
      this.isSaving = false;
      this.newPlaylistName = '';
      this.duplicatePlaylistDialog.hide();
      return res;
    } catch (err) {
      log.error(err);
      this.notification.error(`Error duplicating playlist: ${err}`);
      this.isSaving = false;
      throw new Error(err.message);
    }
  }
}
