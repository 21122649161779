// import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import {SharedNav} from '@bindable-ui/bindable';

import {TableSortingTools} from 'utils/table-sorting-tools';
import {AUDIENCE_TYPE} from '../models/models';
import {AudiencesService} from '../services/audiences';

@autoinject()
export class AudiencesList {
  public hiddenColumns = [];
  public audienceInputRef;
  public colModified;
  public audienceRadioGroup: any;
  public createInputHasFocus: boolean = false;
  public availableCols: any[] = [
    {
      colHeadName: 'type',
      colHeadValue: 'Type',
      selected: true,
    },
    {
      colHeadName: 'match_type',
      colHeadValue: 'Match',
      selected: true,
    },
    {
      colHeadName: 'ip_networks',
      colHeadValue: 'IP Networks',
      selected: true,
    },
    {
      colHeadName: 'zip_codes',
      colHeadValue: 'Zip Codes',
      selected: true,
    },
    {
      colHeadName: 'dma_codes',
      colHeadValue: 'DMAs',
      selected: true,
    },
    {
      colHeadName: 'country_codes',
      colHeadValue: 'Country Codes',
      selected: true,
    },
    {
      colHeadName: 'devices',
      colHeadValue: 'Devices',
      selected: true,
    },
  ];

  public audienceType: string = AUDIENCE_TYPE.SINGLE;
  public currentSort: string = '';

  public createDialogActions = {
    onHide: () => {
      this.liveAudiences.createErrMsg = '';
      this.liveAudiences.newAudienceName = '';
      this.audienceType = AUDIENCE_TYPE.SINGLE;
    },
  };

  // constructor(private sharedNav: SharedNav, private dialogService: DialogService) {}
  constructor(private liveAudiences: AudiencesService, private router: Router, private sharedNav: SharedNav) {}

  public async activate() {
    if (this.liveAudiences.params && this.liveAudiences.params.order) {
      this.currentSort = this.liveAudiences.params.order;
    }

    this.init();
    this.liveAudiences.clearSelected();
  }

  public async init() {
    await Promise.all([
      this.liveAudiences.getAudiences({order: this.currentSort}),
      this.getMetadata(),
    ]);
  }

  public async getMetadata() {
    await this.liveAudiences.getMetadata();
    if (this.liveAudiences.currentMetadata) {
      this.hiddenColumns = this.liveAudiences.currentMetadata.hiddenColumns;
      this.hiddenColumns.forEach(key => {
        this.availableCols.forEach((column, index) => {
          if (column.colHeadName === key) {
            this.availableCols[index].selected = false;
          }
        });
      });
    }
  }

  public attached() {
    // Setting active left navigation for Audiences
    this.sharedNav.setActive(0, 2);
  }

  public detached() {
    this.liveAudiences.clearSelected();
  }

  // public importAudiencesModal() {
  //     this.dialogService.open({
  //         model: {
  //             bodyModel: {
  //                 stuff: 'here',
  //             },
  //             bodyViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/modals/import'),
  //             footerEnable: true,
  //             footerModel: {
  //                 stuff: 'here',
  //             },
  //             footerText: 'footer',
  //             footerViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/modals/import-footer'),
  //             size: 'medium',
  //             title: 'Import Audiences',
  //         },
  //         viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
  //     });
  // }

  public showHideColumn(col) {
    col.selected = !col.selected;

    if (col.selected) {
      const idx = this.hiddenColumns.indexOf(col.colHeadName);
      if (idx !== -1) {
        this.hiddenColumns.splice(idx, 1);
      }
    } else {
      this.hiddenColumns.push(col.colHeadName);
    }

    this.liveAudiences.currentMetadata.hiddenColumns = this.hiddenColumns;
    this.liveAudiences.saveMetadata(this.liveAudiences.currentMetadata);
    this.colModified = new Date();
  }

  public async sortAudiences(key: string, defaultSortDirection: string = null) {
    const {order} = this.liveAudiences.params;

    const newOrder = TableSortingTools.sort(key, order, defaultSortDirection);

    this.currentSort = newOrder;
    this.liveAudiences.getAudiences({order: newOrder});
  }

  public goToSingle(id) {
    this.router.navigate(`/live-channels/audiences/${id}`);
  }

  public toggleAudienceSelected(audience) {
    audience.selected = !audience.selected;
    this.liveAudiences.trackSelectedAudience(audience);
  }

  @computedFrom('liveAudiences.selectedAudiences.length', 'liveAudiences.isDeleting')
  get deleteBtnState() {
    if (this.liveAudiences && this.liveAudiences.isDeleting) {
      return 'thinking';
    }
    if (this.liveAudiences && this.liveAudiences.selectedAudiences.length) {
      return '';
    }

    return 'disabled';
  }

  @computedFrom('currentSort')
  public get sortedName(): number {
    return TableSortingTools.sorted('desc', this.currentSort);
  }

  @computedFrom('currentSort')
  public get sortedType(): number {
    return TableSortingTools.sorted('type', this.currentSort);
  }

  @computedFrom('currentSort')
  public get sortedMatch(): number {
    return TableSortingTools.sorted('match_type', this.currentSort);
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showTypeCol(): boolean {
    return !this.hiddenColumns.includes('type');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showMatchCol(): boolean {
    return !this.hiddenColumns.includes('match_type');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showIPCol(): boolean {
    return !this.hiddenColumns.includes('ip_networks');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showZipCol(): boolean {
    return !this.hiddenColumns.includes('zip_codes');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showDmaCol(): boolean {
    return !this.hiddenColumns.includes('dma_codes');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showCountryCol(): boolean {
    return !this.hiddenColumns.includes('country_codes');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showDevicesCol(): boolean {
    return !this.hiddenColumns.includes('devices');
  }
}
