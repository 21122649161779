import {Type} from 'class-transformer';
import {IsBoolean, IsString, ValidateNested} from 'class-validator';
import {IntegrationNameType} from './enums-config';

export class LakanaCredentials {
    @IsString()
    public username: string = '';

    @IsString()
    public application_key: string = '';

    @IsString()
    public password: string = '';

    public password_modified: string = '';
}

export class LakanaMetaMap {
    @IsString()
    public profile_id: string = '';

    @IsString()
    public content_name: string = '';

    @IsString()
    public content_path: string = '';

    @IsString()
    public teaser_text: string = '';

    @IsString()
    public keywords: string = '';

    @IsString()
    public teaser_title: string = '';
}

export class Lakana {
    @ValidateNested()
    @Type(() => LakanaCredentials)
    public credentials: LakanaCredentials = new LakanaCredentials();

    @ValidateNested()
    @Type(() => LakanaMetaMap)
    public metamap: LakanaMetaMap = new LakanaMetaMap();

    @IsString()
    public name: string = IntegrationNameType.Lakana;

    @IsBoolean()
    public enabled: boolean = false;

    public newlyAdded: boolean = false;

    public constructor(init?: Partial<Lakana>) {
        Object.assign(this, init);
    }
}
