import {autoinject, LogManager, computedFrom} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';

import {CToastsService} from '@bindable-ui/bindable';

import {TargetType} from '../constants';
import {IValidationError} from '../interfaces';
import {IdsUser} from '../models/ids-user';
import {IdsUserService} from '../services/ids-user';

@autoinject()
export class MergeUsers {
  protected logger: Logger;
  private domain: string;

  public sourceUser: IdsUser = null;
  public targetUser: IdsUser = null;

  public sourceErrors: IValidationError[] = [];
  public targetErrors: IValidationError[] = [];

  public getUserCallback = (t: TargetType, v: string) => this.getUser(t, v);
  public clearUserCallback = (t: TargetType) => this.clearUser(t);

  @computedFrom('sourceErrors')
  public get sourceIsValid(): boolean {
    return this.sourceUser && this.sourceErrors.length === 0;
  }

  @computedFrom('targetErrors')
  public get targetIsValid(): boolean {
    return this.targetUser && this.targetErrors.length === 0;
  }

  @computedFrom('sourceIsValid', 'targetIsValid')
  public get mergeEnabled() {
    return this.sourceIsValid && this.targetIsValid;
  }

  constructor(public idsUserService: IdsUserService, public notification: CToastsService) {
    this.logger = LogManager.getLogger('Acuity | Identity | Merge Users');

    this.domain = window?.location?.host.indexOf('downlynk') > -1 ? 'downlynk' : 'uplynk';
  }

  public clearUser(target: TargetType): void {
    const userVar = `${target.toLowerCase()}User`;

    this[userVar] = null;

    this.validateUserModel(target);
  }

  /**
   * Get user based on value for target
   * @param {TargetType} target
   * @param {string} value
   */
  public async getUser(target: TargetType, value: string): Promise<void> {
    const userVar = `${target.toLowerCase()}User`;
    let user: IdsUser = null;

    if (target.trim().length > 0 && value.trim().length > 0) {
      user = await this.idsUserService.findUser(value);
    }

    this[userVar] = user;

    this.validateUserModel(target);
  }

  public async mergeUsers(): Promise<void> {
    try {
      await this.idsUserService.mergeUsers(this.sourceUser, this.targetUser);

      this.sourceUser = null;
      this.targetUser = null;

      this.notification.success('Users merged!');
    } catch (err) {
      this.notification.error(err.message);
    }
  }

  /**
   * Validates user model based on target value
   * @param {TargetType} target
   */
  private validateUserModel(target: TargetType): void {
    switch (target) {
      case 'source':
        this.validateSourceUser();
        break;
      case 'target':
        this.validateTargetUser();
        break;
      default:
        throw new Error('Invalid target');
    }
  }

  /**
   * Perform model validations for source user model
   */
  private validateSourceUser(): void {
    const errors: IValidationError[] = [];

    if (!this.sourceUser) {
      this.sourceErrors = [];
      return;
    }

    const {identities = []} = this.sourceUser;

    if (identities.filter(i => i.source === this.domain).length === 0) {
      errors.push({
        field: 'identities',
        message: `Must have a valid ${this.domain} identity`,
      });
    }

    this.sourceErrors = errors;
  }

  /**
   * Perform model validations for target user model
   */
  private validateTargetUser(): void {
    const errors: IValidationError[] = [];

    if (!this.targetUser) {
      this.targetErrors = [];
      return;
    }

    const {identities = []} = this.targetUser;

    if (identities.filter(i => i.source === this.domain).length > 0) {
      errors.push({
        field: 'identities',
        message: `Has an existing ${this.domain} identity`,
      });
    }

    this.targetErrors = errors;
  }
}
