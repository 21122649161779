import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject} from 'aurelia-framework';
import {MULTI_CDN_SETTINGS_CDN_DELETE_EVENT} from '.';

@autoinject()
export class CdnDeleteConfirm {
    public tcDeleteCdn;

    constructor(public eventAggregator: EventAggregator) {}

    public buttonRemoveCdn(itemTitle) {
        this.tcDeleteCdn.hide();
        this.eventAggregator.publish(MULTI_CDN_SETTINGS_CDN_DELETE_EVENT.CdnDelete, {itemTitle});
    }
}
