export interface DurationSegments {
  hours: number;
  minutes: number;
  seconds: number;
}

export class DurationTools {
  // Takes a humanized time string or a number and returns a value in milliseconds
  static toMilliseconds(duration: string): number {
    const durationRegex = /^[0-9hms.: ]+$/i;
    const timeRegex = /(?:(?<hours>\d+):)?(?<minutes>\d+(\.\d+)?)(?::(?<seconds>\d+(\.\d+)?))?/;
    const unitRegex = /(?<value>\d+(\.\d+)?)(?<unit>[hHmMsS])/g;

    if (!durationRegex.test(duration)) {
      throw new Error(`Enter a valid format like '1h 30m 20s', '90.5s', 'MM:SS', or 'HH:MM:SS'.`);
    }

    const durationSegments: DurationSegments = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    const matchTime = duration.match(timeRegex);
    if (matchTime?.groups?.hours && matchTime?.groups?.minutes) {
      const {hours = '0', minutes, seconds = '0'} = matchTime.groups;

      durationSegments.hours = parseFloat(hours);
      durationSegments.minutes = parseInt(minutes, 10);
      durationSegments.seconds = parseFloat(seconds || '0');
    } else {
      let match = unitRegex.exec(duration);

      while (match !== null) {
        const {value, unit} = match.groups as {value: string; unit: string};
        const parsedValue = parseFloat(value);
        if (!Number.isNaN(parsedValue)) {
          if (unit === 'h' || unit === 'H') {
            durationSegments.hours = parsedValue;
          } else if (unit === 'm' || unit === 'M') {
            durationSegments.minutes = parsedValue;
          } else if (unit === 's' || unit === 'S') {
            durationSegments.seconds = parsedValue;
          }
        }

        match = unitRegex.exec(duration);
      }

      if (durationSegments.hours === 0 && durationSegments.minutes === 0 && durationSegments.seconds === 0) {
        const parsedNumber = parseFloat(duration);
        if (!Number.isNaN(parsedNumber)) {
          durationSegments.seconds = parsedNumber;
        } else {
          throw new Error(`Enter a valid format like '1h 30m 20s', '90.5s', 'MM:SS', or 'HH:MM:SS'.`);
        }
      }
    }

    const milliseconds =
      durationSegments.hours * 60 * 60 * 1000 + durationSegments.minutes * 60 * 1000 + durationSegments.seconds * 1000;

    return milliseconds;
  }

  // Takes a humanized time string or a number and returns a value rounded to the closest seconds ()
  static toSeconds(duration): number {
    const seconds = Math.ceil(this.toMilliseconds(duration) / 1000);
    return seconds;
  }

  // Takes a number of milliseconds and returns the value in HH:MM:SS format
  static millisecondsToHms(duration: number): string {
    if (duration === null || duration === undefined) {
      return '00:00:00';
    }

    const isNegativeTime = duration < 0;
    const time = Math.abs(Math.round(duration) / 1000);

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const HH = hours < 10 ? `0${hours}` : `${hours}`;
    const MM = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const SS = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${isNegativeTime ? '-' : ''}${HH}:${MM}:${SS}`;
  }

  // Takes a number of seconds and returns the value in HH:MM:SS format
  static secondsToHms(duration: number): string {
    if (duration === null || duration === undefined) {
      return '00:00:00';
    }

    const isNegativeTime = duration < 0;
    const time = Math.abs(Math.round(duration));

    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time - (hours * 3600 + minutes * 60);

    const HH = hours < 10 ? `0${hours}` : `${hours}`;
    const MM = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const SS = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${isNegativeTime ? '-' : ''}${HH}:${MM}:${SS}`;
  }
}
