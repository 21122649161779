import {autoinject, singleton} from 'aurelia-framework';
import {SessionService} from 'services/session';

enum BetaSource {
    Account = 'Account',
    LocalStorage = 'Local Storage',
    QueryParam = 'Query Param',
}

interface ActiveMode {
    global: boolean;
    scopes: string[];
    source?: BetaSource;
}

const WARNINGS = {
    [BetaSource.Account]: 'ensure your account does not have the beta flag',
    [BetaSource.LocalStorage]: 'delete localStorage.CMS_BETA_MODE',
    [BetaSource.QueryParam]: 'remove ?beta query param from url',
};

@singleton()
@autoinject()
export class BetaMode {
    private activeMode: ActiveMode = {
        global: false,
        scopes: [],
    };

    // beta mode will use the following priority to determine beta mode
    // For example, if the account has a "beta" in the user's account,
    // but if the url has a ?beta=ad-proxy param, it will use the scoped mode instead
    // of the global one
    // 1. Query Param
    // 2. Local Storage
    // 3. Flags (cms_flags from Owner object)
    constructor(public sessionService: SessionService) {}

    public get inBetaMode(): boolean {
        return this.activeMode.global;
    }

    public get inScopedBetaMode(): boolean {
        return !this.inBetaMode && this.activeMode.scopes && this.activeMode.scopes.length > 0;
    }

    public get scopes(): string[] {
        return this.activeMode.scopes;
    }

    /**
     * Returns true if the user has given beta scope.
     * @example
     * hasScope('feature1');
     * @param {string} scope
     * @returns {boolean} Whether or not user has scope
     */
    public hasScope(scope: string): boolean {
        return this.activeMode.scopes.includes(scope);
    }

    /**
     * Returns true if the user has ALL of the listed beta scopes.
     * Essentially a AND operation between scopes.
     * @example
     * // check on single scope
     * hasScopes('feature1');
     * // check on list of parameters
     * hasScopes('feature1', 'feature2', 'feature3');
     * // check on array
     * const scopes = ['feature1', 'feature2', 'feature3'];
     * hasScopes(...scopes);
     * @param {...string[]} scopes List of scopes parameters.
     * @returns {boolean} Whether or not the user has ALL of the listed scopes
     */
    public hasScopes(...scopes: string[]): boolean {
        return _.intersection(this.activeMode.scopes, scopes).length === scopes.length;
    }

    /**
     * Returns true if the user has ANY of the listed beta scopes.
     * Essentially an OR operation between scopes.
     * @example
     * // using list of parameters
     * hasAnyOfScopes('feature1', 'feature2', 'feature3');
     * // using array
     * const scopes = ['feature1', 'feature2', 'feature3'];
     * hasAnyOfScopes(...scopes);
     * @param {...string[]} scopes List of scope parameters.
     * @returns {boolean} Whether or not the user has ANY of the listed scopes
     */
    public hasAnyOfScopes(...scopes: string[]): boolean {
        return _.intersection(this.activeMode.scopes, scopes).length > 0;
    }

    public setBetaMode() {
        const queryValue = this.sessionService.getQueryString('beta') || this.sessionService.getQueryString('preview');
        const localStorageValue = ((window.localStorage || {}) as any).CMS_BETA_MODE;
        const accountValue = _.get(this.sessionService.sessionInfo, 'flags.beta');

        if (queryValue) {
            this.activeMode.source = BetaSource.QueryParam;
        } else if (localStorageValue) {
            this.activeMode.source = BetaSource.LocalStorage;
        } else if (accountValue) {
            this.activeMode.source = BetaSource.Account;
        }

        this.processModeValues(queryValue || localStorageValue || accountValue);

        let warnMsg = `[In ${this.inScopedBetaMode ? 'Scoped ' : ''}Beta Mode] [SOURCE: ${this.activeMode.source}]`;
        if (this.inScopedBetaMode) {
            warnMsg += ` [SCOPES: ${this.activeMode.scopes.join(',')}]`;
        }

        if (this.inBetaMode || this.inScopedBetaMode) {
            /* tslint:disable-next-line no-console */
            console.warn(
                `${warnMsg}
To disable, ${WARNINGS[this.activeMode.source]}.`,
            );
        }
    }

    private processModeValues(value: string) {
        if (!value) return;
        if (value === 'true') {
            this.activeMode.global = true;
        } else {
            this.activeMode.scopes = value
                .split(',')
                .filter(s => !!s)
                .map(s => s.trim());
        }
    }
}
