/* eslint-disable max-classes-per-file */
import {Exclude, Type} from 'class-transformer';
import {
    IsArray,
    IsBoolean,
    IsDefined,
    IsEnum,
    IsNotEmpty,
    IsOptional,
    IsString,
    MaxLength,
    registerDecorator,
    ValidateNested,
    ValidationArguments,
    ValidationOptions,
} from 'class-validator';

export enum SchemaPropertyType {
    STRING = 'string',
    ENUM = 'enum',
}

export enum SchemaPropertyRestriction {
    REQUIRED = 'required',
    OPTIONAL = 'optional',
    READ_ONLY = 'readonly',
}

export class MetadataSchema {
    @IsString()
    @MaxLength(32)
    @IsOptional()
    public id: string;

    @IsString()
    @MaxLength(100)
    @IsNotEmpty({
        message: 'You must enter a name',
    })
    @IsDefined({
        message: 'You must enter a name',
    })
    @IsUniqueName('existingSchemaNames')
    public name: string;

    @IsArray()
    @Type(() => SchemaProperty)
    @ValidateNested()
    public keys: SchemaProperty[] = [];

    // validation specific properties
    @Exclude()
    public readonly skipValidate: boolean = true;

    @Exclude()
    public existingSchemaNames: string[] = [];
}

export class SchemaProperty {
    @MaxLength(100)
    @IsNotEmpty({
        message: 'You must enter a name',
    })
    @IsDefined({
        message: 'You must enter a name',
    })
    @IsUniqueName('existingKeyNames')
    public name: string;

    @MaxLength(200)
    @IsOptional()
    @IsString()
    @IsRequiredWhen(
        [
            ['restriction', SchemaPropertyRestriction.READ_ONLY],
            ['type', SchemaPropertyType.STRING],
        ],
        {
            message: 'Default value is required when restriction is Read Only',
        },
    )
    public default_value: string;

    @IsEnum(SchemaPropertyType)
    @IsDefined()
    public type: SchemaPropertyType = SchemaPropertyType.STRING;

    @IsEnum(SchemaPropertyRestriction)
    @IsDefined()
    public restriction: SchemaPropertyRestriction = SchemaPropertyRestriction.REQUIRED;

    // used for when using an enumerator (dropdown) to include a none option
    @IsOptional()
    @IsBoolean()
    public include_none_option: boolean = false;

    // used when using an enum type
    @IsOptional()
    @IsArray()
    public option_values: string[] = [];

    // validation specific properties
    @Exclude()
    public readonly skipValidate: boolean = true;

    @Exclude()
    public existingKeyNames: string[] = [];
}

export class NewKeyOption {
    @MaxLength(100)
    @IsNotEmpty({
        message: 'You must enter a key value',
    })
    @IsDefined({
        message: 'You must enter a key value',
    })
    @IsUniqueName('existingKeyOptionValues', {
        message: 'Key Value must be unique',
    })
    public name: string;

    // validation specific properties
    @Exclude()
    public readonly skipValidate: boolean = true;

    public existingKeyOptionValues: string[] = [];
}

/* tslint:disable-next-line:function-name */
export function IsUniqueName(property: string, validationOptions: ValidationOptions = {}) {
    return (object: object, propertyName: string) => {
        registerDecorator({
            propertyName,
            constraints: [property],
            name: 'IsUniqueName',
            options: validationOptions,
            target: object.constructor,
            validator: {
                defaultMessage: () => 'Name must be unique',
                validate(value: string, args: ValidationArguments) {
                    const [relatedPropertyName] = args.constraints;
                    const relatedValue = (args.object as any)[relatedPropertyName];
                    return !Array.isArray(relatedValue) || relatedValue.indexOf(value) < 0;
                },
            },
        });
    };
}

/* tslint:disable-next-line:function-name */
export function IsRequiredWhen(constraints: string[][], validationOptions: ValidationOptions = {}) {
    return (object: object, propertyName: string) => {
        registerDecorator({
            constraints,
            propertyName,
            name: 'IsRequiredWhen',
            options: validationOptions,
            target: object.constructor,
            validator: {
                defaultMessage(args: ValidationArguments) {
                    return `Required when ${args.constraints
                        .map(([property, propertyValue]) => `${property} is ${propertyValue}`)
                        .join(' and ')}`;
                },
                validate(value: string, args: ValidationArguments) {
                    const matchesConstraints = args.constraints.every(
                        ([property, propertyValue]) => args.object[property] === propertyValue,
                    );
                    if (matchesConstraints && !value) return false;
                    return true;
                },
            },
        });
    };
}
