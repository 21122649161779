import {CToastsService, IVNavSliderNavList, IVNavSliderPageList, SharedNav} from '@bindable-ui/bindable';
import {IngestPoint, Task} from 'apps/cms/routes/slicers/models/slicer';
import {SlicerService} from 'apps/cms/routes/slicers/services/slicer-service';
import {Subscription} from 'aurelia-event-aggregator';
import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {dirtyCheckPrompt} from 'decorators';
import {HyperionPolling} from 'services/hyperion-polling';

const logger = LogManager.getLogger('IngestPoint');

const STATUS_REFRESH_INTERVAL = 8000;

@dirtyCheckPrompt
@autoinject()
export class IngestPointViewModel {
  @computedFrom('isModified', 'isSaving')
  public get saveButtonState(): string {
    if (this.isSaving) return 'thinking';
    if (!this.isModified) return 'disabled';
    return '';
  }

  @computedFrom('slicerIngestPoint.stream_type.name')
  get isRTMP() {
    return _.get(this.slicerIngestPoint, 'stream_type.name') === 'RTMP';
  }

  @computedFrom(
    'originalIngestPoint.name',
    'slicerIngestPoint.name',
    'originalIngestPoint.description',
    'slicerIngestPoint.description',
  )
  get isModified() {
    return (
      this.originalIngestPoint &&
      this.slicerIngestPoint &&
      (this.originalIngestPoint.name !== this.slicerIngestPoint.name ||
        this.originalIngestPoint.description !== this.slicerIngestPoint.description)
    );
  }

  @computedFrom('slicerIngestPoint', 'slicerIngestPoint.status', 'isDeleting', 'slicerIngestPoints')
  get deleteBtnState() {
    if (this.slicerIngestPoints && this.isDeleting) {
      return 'thinking';
    }

    if (this.slicerIngestPoint && this.slicerIngestPoint.status === 'IN_USE') {
      return 'disabled';
    }

    return '';
  }

  public logger = logger;
  public slicerIngestPoint: IngestPoint;
  public originalIngestPoint: IngestPoint;
  public slicerIngestPoints: IngestPoint[];
  public slicerIngestPointId: string;
  public subscriptions: Subscription[] = [];
  public isSaving: boolean = false;
  public isDeleting: boolean = false;
  public statusPillColor: string;
  public task: Task;
  public createErrMsgName: string = '';

  public navPage: IVNavSliderPageList = {
    navs: [],
    prevText: 'Ingest Points',
    searchFn: this.searchIngestPoints.bind(this),
    searchPlaceholder: 'Search Ingest Points',
    searchQuery: '',
    title: 'All Ingest Points',
  };

  public isLoading: boolean;
  public pollTracker: HyperionPolling;

  public constructor(
    public slicerService: SlicerService,
    public sharedNav: SharedNav,
    public router: Router,
    protected vToastsService: CToastsService,
  ) {}

  public async activate(params: any) {
    this.slicerIngestPointId = params.id;
    this.createErrMsgName = '';

    if (this.slicerIngestPoints) {
      this.slicerIngestPoint = this.slicerIngestPoints.find(s => s.id === this.slicerIngestPointId);
      this.setActiveNavItem();
      this.statusPillColor = this.slicerIngestPoint.status === 'IN_USE' ? 'success' : 'danger';
      this.originalIngestPoint = _.cloneDeep(this.slicerIngestPoint);
    }
  }

  public async attached() {
    this.isLoading = true;

    this.sharedNav.replacePage(this.navPage, 1);
    this.sharedNav.setNextText('All Ingest Points');

    const data = _.get(this.router, 'currentInstruction.config.settings.data');
    if (data) {
      this.slicerIngestPoint = data.slicerIngestPoint;
      this.slicerIngestPoints = data.slicerIngestPoints;
    } else {
      try {
        this.slicerIngestPoints = await this.slicerService.getIngestPoints();
        this.slicerIngestPoint = _.cloneDeep(_.find(this.slicerIngestPoints, {id: this.slicerIngestPointId}));
      } catch (err) {
        logger.error(err);
      }
    }
    this.originalIngestPoint = _.cloneDeep(this.slicerIngestPoint);

    this.setNavItems();

    this.statusPillColor = this.slicerIngestPoint.status === 'IN_USE' ? 'success' : 'danger';
    this.pollTracker = new HyperionPolling({
      callbackFn: updatedIngestPoints => {
        this.slicerIngestPoints = _.cloneDeep(updatedIngestPoints);
        if (!this.isModified) {
          const updatedIngestPoint = updatedIngestPoints.find(s => s.id === this.slicerIngestPointId);
          this.slicerIngestPoint = _.cloneDeep(updatedIngestPoint);
          this.originalIngestPoint = _.cloneDeep(updatedIngestPoint);
        }
        this.setNavItems();
      },
      includeDeleted: false,
      ms: STATUS_REFRESH_INTERVAL,
      promiseFn: async () => this.slicerService.getIngestPoints(),
      useAfter: true,
    });

    this.pollTracker.start();
    this.isLoading = false;
  }

  public searchIngestPoints(query: string) {
    this.setNavItems(query);
  }

  public setNavItems(filter?: string) {
    if (this.slicerIngestPoints) {
      this.navPage.navs = this.slicerIngestPoints
        .map(
          (slicerIngestPoint: IngestPoint): IVNavSliderNavList => ({
            active: false,
            href: `#${this.router.baseUrl}${slicerIngestPoint.id}`,
            title: slicerIngestPoint.name,
          }),
        )
        .filter((item: IVNavSliderNavList) => {
          if (filter) {
            return item.title.toLowerCase().includes(filter.toLowerCase());
          }
          return item;
        });
      this.setActiveNavItem();
      // @ts-ignore
      this.sharedNav.clearPageActive(0);
    }
  }

  public backToContent() {
    const url = this.router.baseUrl.substr(0, this.router.baseUrl.length - 1);
    this.router.navigate(url);
  }

  public async saveIngestPoint() {
    try {
      this.isSaving = true;
      this.createErrMsgName = '';

      if (!this.slicerIngestPoint.name) {
        this.createErrMsgName = 'Endpoint name is required.';
        return false;
      }
      if (
        _.map(
          _.filter(this.slicerIngestPoints, s => s.id !== this.slicerIngestPointId),
          'name',
        ).includes(this.slicerIngestPoint.name)
      ) {
        this.createErrMsgName = 'Endpoint name already in use.';
        return false;
      }

      const saveResult = await this.slicerService.updateIngestPoint(this.slicerIngestPointId, {
        desc: this.slicerIngestPoint.description,
        endpoint_name: this.slicerIngestPoint.name,
      });

      if (saveResult) {
        this.vToastsService.success('Slicer ingest point saved successfully');
        this.slicerIngestPoint = _.cloneDeep(saveResult);
        this.originalIngestPoint = _.cloneDeep(this.slicerIngestPoint);
        const i = _.findIndex(this.slicerIngestPoints, [
          'id',
          this.slicerIngestPointId,
        ]);
        this.slicerIngestPoints[i] = _.cloneDeep(this.slicerIngestPoint);
        this.setNavItems();
      }
    } catch (err) {
      logger.error(err);
      this.vToastsService.error('Slicer ingest point save could not be performed. Please try again.');
      return false;
    } finally {
      this.isSaving = false;
    }
    return true;
  }

  public async deleteIngestPoint() {
    try {
      this.isDeleting = true;
      const deleteResult = await this.slicerService.deleteIngestPoint(this.slicerIngestPointId);
      this.task = _.get(deleteResult, 'task');
      if (_.get(this.task, 'state') === 'COMPLETED') {
        this.vToastsService.success('Slicer ingest point deleted successfully.');
      } else {
        this.vToastsService.info('Slicer ingest point delete operation started');
      }
    } catch (err) {
      logger.error(err);
      this.vToastsService.error('Slicer ingest point delete could not be performed. Please try again.');
    } finally {
      this.isDeleting = false;
      this.backToContent();
    }
  }

  private setActiveNavItem() {
    if (this.slicerIngestPoints) {
      const index = _.findIndex(this.slicerIngestPoints, {id: this.slicerIngestPointId});
      if (!this.isModified) {
        this.slicerIngestPoint = _.cloneDeep(this.slicerIngestPoints[index]);
      }
      this.sharedNav.setActive(1, index);
    }
  }

  public detached() {
    this.pollTracker.stop();
  }
}
