import {ObserverLocator} from 'aurelia-binding';
import {autoinject} from 'aurelia-framework';

import {basicSetup} from 'decorators';
// tslint:disable-next-line:import-name
import * as DragSelect from 'dragselect';
import {Notification} from 'resources/notification/service';
import {EDITOR_FIELD_NAMES, SLICERS_KEYS} from '../models/constants';
import {LiveEventsService} from '../services/live-events-service';

interface Slicers {
  slicerPool: string[]; // live event slicers
  availableSlicers: string[]; // account slicers
}

interface SlicerObj {
  id: string;
  selected: boolean;
}

@basicSetup
@autoinject()
export class LiveEventsSlicers {
  public slicersObserver;
  public slicersPristine;
  public isLoading: boolean;
  public isSaving: boolean;
  public liveEvents;
  public subscription;
  public isDataDirty: boolean;
  public slicers: Slicers = {
    availableSlicers: [],
    slicerPool: [],
  };

  public liveEventSlicers: SlicerObj[];
  public liveEventFilteredSlicers: SlicerObj[];
  public accountSearchText: string = '';
  public liveEventSearchText: string = '';
  public accountSlicers: SlicerObj[];
  public accountFilteredSlicers: SlicerObj[];
  public accountSlicerDs;
  public liveEventDs;
  constructor(
    public liveEventsService: LiveEventsService,
    public notification: Notification,
    public observerLocator: ObserverLocator,
  ) {}

  public getData() {
    return new Promise((resolve, reject) => {
      this.liveEventsService
        .getLiveEventsSettings()
        .then(res => {
          this.liveEvents = res || {};
          SLICERS_KEYS.forEach(key => {
            this.slicers[`${_.camelCase(key)}`] = _.get(this.liveEvents, key);
          });

          this.slicersPristine = _.cloneDeep(this.slicers);
          this.updateSlicerUI();
          resolve();
        })
        .catch(() => {
          // or show errors on the page
          this.notification.error('Error loading live event slicers');
          reject();
        });
    });
  }

  public attached() {
    this.setDragSelect();
    this.searchBothSlicers();
    const les = this.observerLocator.getObserver(this, 'liveEventFilteredSlicers');
    les.subscribe(this.lesValueChanged);

    const afs = this.observerLocator.getObserver(this, 'accountFilteredSlicers');
    afs.subscribe(this.afsValueChanged);
  }

  public updateSlicerUI() {
    this.liveEventSlicers = this.slicers.slicerPool.map(slicer => ({
      id: slicer,
      selected: false,
    }));

    this.accountSlicers = this.slicers.availableSlicers.map(slicer => ({
      id: slicer,
      selected: false,
    }));
    this.searchBothSlicers();
  }

  public search = slicerType => {
    // tslint:disable-next-line:max-line-length
    // set all selected is false: is a work around for the
    // dragSelect couldnt track index of selected element after multiple instances
    if (this[`${slicerType}SearchText`].length) {
      this[`${slicerType}FilteredSlicers`] = this[`${slicerType}Slicers`].filter(
        slicer => slicer.id.toLowerCase().indexOf(this[`${slicerType}SearchText`].toLowerCase()) >= 0,
      );
    } else {
      this[`${slicerType}FilteredSlicers`] = [...this[`${slicerType}Slicers`]];
    }
  };

  public lesValueChanged = (newVal, oldVal) => {
    if (newVal.length !== oldVal.length) {
      this.liveEventFilteredSlicers = newVal;
      this.updateDragSelectInstance('liveEvent');
    }
  };

  public afsValueChanged = (newVal, oldVal) => {
    if (newVal.length !== oldVal.length) {
      this.accountFilteredSlicers = newVal;
      this.updateDragSelectInstance('account');
    }
  };

  public updateDragSelectInstance(slicerType) {
    if (this[`${slicerType}Ds`]) {
      this[`${slicerType}Ds`].removeSelectables();
      this[`${slicerType}Ds`].clearSelection();
      this[`${slicerType}Ds`].addSelectables(document.querySelectorAll(`.${_.kebabCase(slicerType)}-slicer`));
    }
  }

  public setDragSelect = (slicerType?: string) => {
    // this will cause loss selected items once search,
    // but fix the bug of selected ghosted item after moving slicers
    const updateSlicers = slicerType
      ? [`${slicerType}`]
      : [
          'liveEvent',
          'account',
        ];
    updateSlicers.forEach(type => {
      const paramCasedType = _.kebabCase(type);
      this[`${type}Ds`] = new DragSelect({
        area: document.getElementById(`${paramCasedType}-slicers-box`),
        selectables: document.querySelectorAll(`.${paramCasedType}-slicer`),
      });
    });
  };

  public updateSlicers = () => {
    this.slicers.slicerPool = this.liveEventSlicers.map(s => s.id);
    this.slicers.availableSlicers = this.accountSlicers.map(s => s.id);
  };

  public moveSlicers = (from, to) => {
    const selectedNodes = this[`${from}Ds`].getSelection();
    selectedNodes.forEach(node => {
      const s = node.getAttribute('id');

      this[`${to}Slicers`].push({
        id: s,
        selected: false,
      });
      this[`${from}Slicers`].splice(
        this[`${from}Slicers`].findIndex(e => e.id === s),
        1,
      );
    });
    this.searchBothSlicers();
    this.updateSlicers();
  };

  public searchBothSlicers() {
    this.search('liveEvent');
    this.search('account');
  }

  public get canSave(): boolean {
    const leData = !this.liveEventsService.checkIsEqual(
      this.liveEventsService.dataPristine,
      this.liveEventsService.cache,
    );
    const slicerData = !this.liveEventsService.checkIsEqual(this.slicers, this.slicersPristine);
    return (leData || slicerData) && this.liveEventsService.isValid();
  }

  public saveData(): Promise<any> {
    return new Promise((resolve, reject) => {
      const dataToSend = {...this.liveEvents};

      Object.keys(this.slicers).forEach(key => {
        dataToSend[_.snakeCase(key)] = _.get(this.slicers, key);
      });

      this.liveEventsService
        .saveLiveEvents({
          EDITOR_FIELD_NAMES,
          ...dataToSend,
        })
        .then(() => {
          this.slicersPristine = _.cloneDeep(this.slicers);
          this.notification.success('Changes saved successfully.');
          this.isDataDirty = false;
          resolve();
        })
        .catch(e => {
          const msg = `${e}`;
          this.notification.error(msg);
          reject(e);
        });
    });
  }

  public canDeactivate() {
    // update live events data cache
    const dataToSend = {...this.liveEvents};
    Object.keys(this.slicers).forEach(key => {
      dataToSend[_.snakeCase(key)] = _.get(this.slicers, key);
    });

    if (this.liveEventsService.cache) {
      this.liveEventsService.cache = dataToSend;
    }
  }
}
