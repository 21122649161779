import {bindable, computedFrom} from 'aurelia-framework';

import * as moment from 'moment';

import {WorkspaceKeyRevision} from '../../models/workspace-key-revision';

export class RevisionPill {
  @bindable()
  public model: WorkspaceKeyRevision;

  @bindable()
  public selected: boolean = false;

  @computedFrom('model.invalid_after')
  public get isPast() {
    const {invalid_after: invalidAfter} = this.model;

    if (invalidAfter && moment().isAfter(invalidAfter)) {
      return true;
    }

    return false;
  }

  @computedFrom('selected')
  public get pillSize(): string {
    if (this.selected) {
      return 'medium';
    }

    return 'small';
  }

  @computedFrom('model.deleted', 'isPast')
  public get pillType() {
    if (this.model.deleted) {
      return 'danger';
    }

    if (this.isPast) {
      return 'warning';
    }

    return 'success';
  }

  @computedFrom('model.deleted', 'isPast')
  public get pillHelpText() {
    if (this.model.deleted) {
      return 'Deactivated';
    }

    if (this.isPast) {
      return 'Expired';
    }

    return 'Active';
  }
}
