export const DEFAULT_PAGE_NUM: number = 1;
export const DEFAULT_PER_PAGE_LIMIT: number = 40;
export const LIST_NAV_INDEX: number = 1;
export const DEFAULT_MAX_PAGE_LIMIT: number = 100;
export const DEFAULT_PAGE_SIZE_OPTIONS: number[] = [
  DEFAULT_PER_PAGE_LIMIT,
  50,
  75,
  DEFAULT_MAX_PAGE_LIMIT,
];

export const VIDEO_EXTENSIONS = [
  'webm',
  'mpg',
  'mp2',
  'mpeg',
  'mpe',
  'mpv',
  'ogg',
  'mp4',
  'm4p',
  'm4v',
  'avi',
  'wmv',
  'mov',
  'qt',
  'flv',
  'swf',
  'avchd',
  'ts',
  'mkv',
];

export const CONF_EXTENSIONS = [
  'cfg',
  'ttml',
  'scc',
];

export const VOD_UPLOADER_FILE_PART_SIZE = 2 * 1024 * 1024 * 1024; // 2 GB in bytes
