import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, LogManager} from 'aurelia-framework';
import {classToPlain} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {IPaginationMeta, IHyperionFilterParams} from 'interfaces/hyperion';

import {Blackout, ChannelBlackoutResponse} from '../models/models';

const log = LogManager.getLogger('blackout-service');

const CHANNELS_URL: string = '/api/v4/channels';

@autoinject()
export class BlackoutService {
  public channelId: string;
  public blackout: Blackout;
  public blackouts: Blackout[] = [];
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public isSaving: boolean = false;

  public changeSet = {};
  public meta: IPaginationMeta = {};
  public selected: string[] = [];
  public isPolling: boolean = false;

  public params: IHyperionFilterParams = {};

  constructor(public acceo: Acceo, public notification: CToastsService) {}

  public async deleteBlackout(id) {
    let shouldRemove = true;

    try {
      const url = `${CHANNELS_URL}/${this.channelId}/blackouts/${id}`;
      await this.acceo.delete()(url);
    } catch (e) {
      if (e.status_code === 404) {
        // We should remove the blackout from the array since it's already been removed
        return;
      }

      shouldRemove = false;
      log.error(e);
      this.notification.error(`Could not delete Blackout ID: ${id}. Please try again.`);
      throw Error(e.message);
    } finally {
      if (shouldRemove) {
        _.remove(this.blackouts, {id});
      }
    }
  }

  public async getBlackout(id) {
    try {
      const url = `${CHANNELS_URL}/${this.channelId}/blackouts/${id}`;
      this.blackout = await this.acceo.get(Blackout)(url);
    } catch (e) {
      log.error(e);
      throw Error(e.message);
    }
  }

  public async getBlackouts(params: IHyperionFilterParams = {}, isLoadMore = false) {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }

    if (!params.page_size) {
      params.page_size = 20;
    }

    if (!params.order) {
      params.order = '-created';
    }

    try {
      this.isLoading = true;

      if (!isLoadMore) {
        this.blackouts = [];
        this.isLoading = true;
        // this.navPage.isLoading = true;
        params.page = 1;
      } else {
        if (!this.meta.hasMore) {
          return;
        }

        this.isLoadingMore = true;
        params.page = (params.page || 1) + 1;
      }

      this.params = _.cloneDeep(params);

      const urlParams = $.param(this.params);
      const url = `${CHANNELS_URL}/${this.channelId}/blackouts?${urlParams}`;

      const resp = await this.acceo.get(ChannelBlackoutResponse)(url);

      this.blackouts = _.uniqBy(this.blackouts.concat(resp.items), 'id');

      this.meta.total = resp.total_items;
      this.meta.showing = this.blackouts.length;
      this.meta.limit = params.page_size || null;

      // Safety check to prevent query spamming
      const totalPages = Math.ceil(resp.total_items / params.page_size);
      this.meta.hasMore = params.page < totalPages;
    } catch (e) {
      log.error(e);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  public async getMore() {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }

    if (this.meta.hasMore) {
      const params = _.cloneDeep(this.params);
      await this.getBlackouts(params, true);
    }
  }

  public async pollRecords(params): Promise<ChannelBlackoutResponse> {
    if (this.isPolling) {
      return null;
    }

    try {
      const paramsNew = _.cloneDeep(params);

      delete paramsNew.page_size;
      delete paramsNew.page;
      delete paramsNew.order;

      const urlParams = $.param(paramsNew);
      const url = `${CHANNELS_URL}/${this.channelId}/blackouts?${urlParams}`;

      const resp = await this.acceo.get(ChannelBlackoutResponse)(url);

      return resp;
    } catch (ex) {
      log.error(ex);
    } finally {
      this.isPolling = false;
    }

    return null;
  }

  public processPollData(changes: ChannelBlackoutResponse): boolean {
    this.changeSet = {};

    if (!changes) {
      return false;
    }

    let hasChanges = false;

    if (changes.total_items > 0) {
      hasChanges = true;
      changes.items.forEach(item => this.updateRecordData(item));
      this.blackouts = _.uniqBy(this.blackouts.concat(), 'id');

      this.updateSelected();
    }

    return hasChanges;
  }

  /**
   * Creates/Updates a Blackout
   *
   * @param blackout Instance of Blackout.
   */
  public async saveBlackout(blackout: Blackout) {
    const {id} = blackout;
    const errorMsg = `Error ${id ? 'sav' : 'creat'}ing Blackout ID: ${blackout.blackout_id}. Please try again.`;
    const data: any = classToPlain(blackout);
    delete data.id;

    try {
      this.isSaving = true;
      let savedBlackout: Blackout;
      const url = `${CHANNELS_URL}/${this.channelId}/blackouts`;

      if (id) {
        savedBlackout = await this.acceo.patch(Blackout)(`${url}/${id}`, data);
        this.blackouts = this.blackouts.map(b => (b.id === id ? savedBlackout : b));
      } else {
        savedBlackout = await this.acceo.post(Blackout)(url, data);
        this.blackouts = [savedBlackout].concat(this.blackouts);
      }
    } catch (e) {
      log.error(e);
      // const err = JSON.parse(e);
      if (e.status_code === 409) {
        this.notification.error(
          `Error creating Blackout ID: ${blackout.blackout_id}. Blackout with that name already exists.`,
        );
      } else {
        this.notification.error(errorMsg);
      }
      throw Error(e.message);
    } finally {
      this.isSaving = false;
    }
  }

  public setChannelId(channelId: string) {
    this.channelId = channelId;
  }

  public selectAll(selectAll: boolean = false) {
    this.blackouts.forEach(r => {
      r.selected = selectAll;
    });

    this.updateSelected();
  }

  public trackRecord() {
    this.updateSelected();
  }

  private addOrUpdateRecord(blackout: Blackout) {
    const index = this.blackouts.findIndex(r => r.id === blackout.id);
    const canonicalRecord = this.blackouts[index];
    const trackedKeys = [
      'blackout_id',
      'desc',
      'rules',
    ];

    if (canonicalRecord) {
      const changes = {};

      trackedKeys.forEach(key => {
        let value = null;

        if (canonicalRecord[key] !== blackout[key]) {
          value = blackout[key];
          canonicalRecord[key] = value;

          changes[key] = value;
        }
      });

      this.changeSet[blackout.id] = changes;
    } else {
      this.meta.total += 1;
      this.meta.showing += 1;
      this.blackouts.unshift(blackout);
    }
  }

  private removeRecord(id: string) {
    const index = this.blackouts.findIndex(r => r.id === id);

    if (index > -1) {
      this.meta.total -= 1;
      this.meta.showing -= 1;
      this.blackouts.splice(index, 1);
    }
  }

  private updateRecordData(record: Blackout) {
    if (record.deleted) {
      this.removeRecord(record.id);
    } else {
      this.addOrUpdateRecord(record);
    }
  }

  private updateSelected() {
    // this.selected = this.blackouts.filter(e => e.selected).map(e => e.id);
    this.selected = this.blackouts.filter(e => e.selected).map(e => e.id);
  }
}
