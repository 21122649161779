import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Acceo} from 'services/acceo';
import {ConfigFormat} from 'resources/config/models/config-format';
import {ConfigSettingInputType, IConfigSetting} from 'resources/config/models/config-setting';
import {Toast} from 'resources/toast/toast';
import {deepCopy} from 'resources/deep-copy';
import {AcceoErrorUtil} from '../components/acceo-error-util';
import {AdConfigRequest} from '../models/adconfig-request';
import {AdServerMenu} from '..';
import {AdConfigService} from '../services/ad-config';
import {MacroConfigService} from '../services/macro-config';
import {ParamConfigService} from '../services/param-config';
import {PrebidConfigService} from '../services/prebid-config';
import {ProviderConfigService} from '../services/provider-config';
import {TypeConfigService} from '../services/type-config';
import {AdConfigEditor} from '../components/config-editor/editor';
import {Config} from '../models/config';
import {AdServerConfigTable} from '../components/config-editor/table';
import {AdServerRoute} from '../models/config-route';
import {AdConfig, AdServerType} from '../../ad-server/models/ad-config';

@autoinject
export class AdServerDetails extends AdConfigEditor {
  public config: Config;
  public adConfigService: AdConfigService;
  public macroConfigService: MacroConfigService;
  public paramConfigService: ParamConfigService;
  public prebidConfigService: PrebidConfigService;
  public providerConfigService: ProviderConfigService;
  public typeConfigService: TypeConfigService;
  public route = AdServerRoute;

  constructor(protected acceo: Acceo, protected router: Router, protected adConfigMenu: AdServerMenu) {
    super();
    this.adConfigService = new AdConfigService(acceo);
    this.macroConfigService = new MacroConfigService(acceo);
    this.paramConfigService = new ParamConfigService(acceo);
    this.prebidConfigService = new PrebidConfigService(acceo);
    this.providerConfigService = new ProviderConfigService(acceo); // TODO probably can remove this
    this.typeConfigService = new TypeConfigService(acceo);
  }

  protected showErrorsWarnings() {
    // display any warnings or errors in the event they were closed...
    const errors = document.getElementById('config-errors');
    const warnings = document.getElementById('config-warnings');
    if (errors) {
      errors.setAttribute('open', 'true');
    }
    if (warnings) {
      warnings.setAttribute('open', 'true');
    }
  }

  public activate(params?: any) {
    this.config = new Config();
    this.load(params.id);
  }

  public async actionDelete() {
    this.config.state.isDeleting = true;
    try {
      await this.adConfigService.delete(this.config.pristine.name);
      Toast.success(`${this.config.info.objectType} deleted successfully`);
      await this.adConfigMenu.load();
      this.router.navigate(`#/settings/${this.route.routeFragment}`);
    } catch (error) {
      Toast.danger(AcceoErrorUtil.getHTML(error));
    }
    this.config.state.isDeleting = false;
  }

  public async actionUpdate() {
    if (!this.isDirty()) {
      Toast.warning('No Changes Made');
      this.showErrorsWarnings();
      return;
    }
    this.config.state.isUpdating = true;
    // set the name in case it was updated...
    this.config.input.name = this.config.input.table.rows.find(row => row.key === 'name').value;

    let dupName: boolean = false;

    try {
      if (this.config.input.name !== this.config.pristine.name) {
        // get the list of ad configs we have to check for duplicates if the name is being changed
        const response = await this.adConfigService.get();
        if (response) {
          dupName = response.items.some(row => row.name === this.config.input.name);
        }
        if (dupName) {
          Toast.danger(`Unable to save - duplicate Ad Server Configuration found for ${this.config.input.name}`);
          this.config.state.isUpdating = false;
          return;
        }
      }
      await this.requestUpdate();
      this.config.dirty = [];
      if (this.config.input.name !== this.config.pristine.name) {
        // only navigate if/when we changed the name
        this.config.input.table.rows = [];
        this.router.navigate(this.config.input.name);
      }
      // load the menu and the data
      await this.adConfigMenu.load();
      this.load(this.config.input.name);
      Toast.success(`${this.config.info.objectType} updated successfully`);
    } catch (error) {
      Toast.danger(AcceoErrorUtil.getHTML(error));
    } finally {
      this.config.state.isUpdating = false;

      // reopen any warnings and errors
      this.showErrorsWarnings();
    }
  }

  protected async load(name: string) {
    this.config.state.error = undefined;
    this.config.state.isLoading = true;

    try {
      const response = await this.adConfigService.getByName(name);
      if (!response) throw new Error(`Failed to load the requested ${this.config.info.objectType}`);
      const typeConfig = await this.typeConfigService.get();
      const typeConfigDictArray = (typeConfig.items || []).map(item => ({
        text: item.text,
        value: item.value,
      }));
      this.config.values = response.values;
      this.config.input.name = response.name;
      this.config.input.type = response.type;

      this.config.input.typeLabel = typeConfigDictArray.find(
        typeConfigItem => typeConfigItem.value === this.config.input.type,
      ).text;
      this.config.state.isValid = !!response.valid;
      this.config.input.errors = response.errors;
      this.config.input.warnings = response.warnings;

      // remove any unrelated keys
      [
        'backfill',
        'testable',
        'testType',
        'valid',
        '_orig_name',
      ].forEach(key => {
        delete response.values[key];
      });

      let settings: IConfigSetting[] = [
        {
          info: this.config.info.typeHelp,
          input: ConfigSettingInputType.select,
          formElement: ConfigSettingInputType.select,
          key: 'type',
          managed: true,
          options: typeConfigDictArray,
          required: true,
          text: this.config.input.typeLabel,
        },
        {
          info: this.config.info.nameHelp,
          input: ConfigSettingInputType.text,
          formElement: ConfigSettingInputType.text,
          key: 'name',
          pattern: '[a-z0-9_]+',
          required: true,
          text: this.config.info.nameLabel,
        },
      ];

      settings = settings.concat(await this.paramConfigService.getConfigSettings(response.type, response.provider));

      // check for prebid - should only check for gg and fw?
      if (response.type === AdServerType.Freewheel || response.type === AdServerType.DoubleClick) {
        if (settings.find(setting => setting.key === 'prbd')) {
          try {
            const responsePrebidConfig = await this.prebidConfigService.get();

            const prebids = (responsePrebidConfig.items || []).map(item => ({
              text: item.name,
              value: item.id,
            }));
            settings.find(setting => setting.key === 'prbd').options = prebids;
          } catch (error) {
            Toast.danger(AcceoErrorUtil.getHTML(error));
          }
        }
      }

      if (response.type === AdServerType.UniversalAdConfig) {
        settings = settings.concat(await this.macroConfigService.get());
        this.config.options.configSetting = _.sortBy(
          settings.filter(setting => !setting.required),
          'text',
        );
      }

      this.config.input.table = new AdServerConfigTable(settings);
      const valuesRequired = {
        name: this.config.input.name,
      };

      Object.keys(response.values)
        .filter(key => !!this.config.input.table.configTableData.keysRequired.find(required => required === key))
        .forEach(key => {
          valuesRequired[key] = _.unescape(response.values[key]);
        });

      const valuesManaged = {
        // type: this.config.input.type,
        // this is not necessary as long as the managed param attribute is present and set to 1 properly in the uac_params
      };

      Object.keys(response.values)
        .filter(key => !!this.config.input.table.configTableData.keysManaged.find(managed => managed === key))
        .forEach(key => {
          valuesManaged[key] = response.values[key];
          delete valuesRequired[key];
        });
      Object.keys(valuesRequired)
        .sort()
        .forEach(key => this.config.input.table.importLine(key, valuesRequired[key]));
      Object.keys(valuesManaged)
        .sort()
        .forEach(key => this.config.input.table.importLine(key, valuesManaged[key]));
      const valuesImported = {};
      this.config.input.table.rows.forEach(row => {
        valuesImported[row.key] = row.value;
      });
      Object.keys(response.values)
        .sort()
        .forEach(key => {
          if (!valuesImported[key]) this.config.input.table.importLine(key, response.values[key]);
        });
      response.formatted_values
        .filter(item => item.errors.length)
        .forEach(item => {
          const row = this.config.input.table.rows.find(row => row.key === item.key);
          if (row) row.error = item.errors[0];
        });

      this.config.pristine = _.cloneDeep(this.config.input);
    } catch (error) {
      this.config.state.error = AcceoErrorUtil.getHTML(error);
    } finally {
      this.config.state.isLoading = false;
      this.config.dirty = deepCopy(this.config.input.table.rows);
    }
  }

  protected async requestUpdate() {
    return this.config.input.type === AdServerType.UniversalAdConfig
      ? this.adConfigService.patch(this.config.pristine.name, this.transformRequestInput())
      : this.adConfigService.patchLegacy(this.transformRequestInputLegacy());
  }

  protected transformRequestInput(): AdConfigRequest {
    const request = new AdConfigRequest();
    request.values = JSON.parse(this.config.pristine.table.exportString(ConfigFormat.JSON));
    Object.keys(request.values).forEach(key => {
      request.values[key] = '';
    });
    Object.assign(request.values, JSON.parse(this.config.input.table.exportString(ConfigFormat.JSON)));
    return request;
  }

  protected transformRequestInputLegacy(): AdConfig {
    const request = new AdConfig();
    request.origName = this.config.pristine.name;
    request.type = this.config.pristine.type;
    request.name = this.config.input.name;
    const values = JSON.parse(this.config.pristine.table.exportString(ConfigFormat.JSON));
    Object.keys(values).forEach(key => {
      values[key] = '';
    });
    Object.assign(values, JSON.parse(this.config.input.table.exportString(ConfigFormat.JSON)));
    // the legacy service flattens 'required' & 'optional' so it
    // doesn't matter if 'optional' settings go in the 'required' object
    request.required = Object.keys(values).map(key => ({error: '', help: '', name: key, value: values[key]}));
    return request;
  }
}
