import {CTableRowBehavior} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {TemplateListTable as TemplateListTableBase} from '../table';
import {ITemplateListTableRow} from '../table-models';

export class TemplateListTable extends TemplateListTableBase {
    public selected: ITemplateListTableRow;

    constructor(
        rowClick: (row: ITemplateListTableRow) => void,
        rowBehavior: CTableRowBehavior = CTableRowBehavior.RADIO,
    ) {
        super(rowClick, rowBehavior);
        this.actions.rowClick = this.rowClickRadio.bind(this);
        this.colsSelectable = this.colsSelectableDefault.map(col => {
            if (col.colHeadName !== 'name') {
                col.selected = false;
            }
            return col;
        });
    }

    protected trackSelectedRadio() {
        super.trackSelectedRadio();
        const item = this.rows.filter(row => row.id === this.rowsSelected[0])[0];
        Object.assign(this.selected, item);
    }
}
