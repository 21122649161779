import {autoinject, LogManager} from 'aurelia-framework';
import {serialize} from 'class-transformer';
import {Acceo} from 'services/acceo';

import {StreamDomainData} from '../models/stream-domain-data';

const logger = LogManager.getLogger('Stream Domain Settings Service');

@autoinject
export class StreamDomainService {
    public logger = logger;

    constructor(public acceo: Acceo) {}

    public getData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.acceo
                .post(StreamDomainData)('/settings/streamdomains/', undefined)
                .then((resp: StreamDomainData) => {
                    resolve(resp);
                })
                .catch(err => {
                    this.logger.error(err);
                    reject(new Error(err || 'Error getting data'));
                });
        });
    }

    public update(data): Promise<any> {
        return new Promise((resolve, reject) => {
            const dataString = `item=${encodeURI(serialize(data))}`;
            this.acceo
                .post()('/settings/streamdomains/save/', dataString, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    },
                })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    this.logger.error(err);
                    reject(new Error(err || 'Error saving data'));
                });
        });
    }
}
