import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {ContentFields, PlayBackFields, PlayerFields, RuleType} from '../models/fields-models';
import {MultiCdnSettingsConfig, Profile} from '../models/multi-cdn-settings-config';
import {MULTI_CDN_SETTINGS_PRESET_DELETE_EVENT} from '../qos';
import {MULTI_CDN_SETTINGS_SCENARIO_DELETE_EVENT} from '../ruleSets';

@autoinject()
export class ProfileContent {
    @bindable
    public multiCdnSettings: MultiCdnSettingsConfig;

    @bindable
    public currentProfile: Profile;

    @bindable
    public callbacks;

    @bindable
    public validationErrors: ValidationError[];

    @bindable
    public advancedStreamRoutingFeaturesEnabled;

    public profileTabs;
    public subscriptions: Subscription[] = [];
    public contentFields: string[];
    public contentRuleType: RuleType;
    public playbackFields: string[];
    public playbackRuleType: RuleType;
    public playerFields: string[];
    public playerRuleType: RuleType;
    public identifier: string = 'profiles';

    constructor(public eventAggregator: EventAggregator) {
        this.profileTabs = [
            {id: 'profiles-rules-content', label: 'Content Rules', selected: true},
            {id: 'profiles-rules-playback', label: 'Playback Rules'},
            {id: 'profiles-rules-player', label: 'Player Rules'},
        ];

        this.contentFields = Object.values(ContentFields);
        this.contentRuleType = RuleType.Content;
        this.playbackFields = Object.values(PlayBackFields);
        this.playbackRuleType = RuleType.Playback;
        this.playerFields = Object.values(PlayerFields);
        this.playerRuleType = RuleType.Player;
    }

    public attached() {
        this.subscriptions.push(
            this.eventAggregator.subscribe(
                MULTI_CDN_SETTINGS_SCENARIO_DELETE_EVENT.ScenarioDelete,
                opt =>
                    new Promise(resolve => {
                        this.deleteRuleSetFromAllProfiles(opt.deletedScenarioName);
                        resolve();
                    }),
            ),
            this.eventAggregator.subscribe(
                MULTI_CDN_SETTINGS_PRESET_DELETE_EVENT.PresetDelete,
                opt =>
                    new Promise(resolve => {
                        this.deletePresetFromAllProfiles(opt.qosPresetName);
                        resolve();
                    }),
            ),
        );
    }

    public detached() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    public deleteRuleSetFromAllProfiles(ruleSetName) {
        if (!this.multiCdnSettings.profiles) {
            return;
        }

        this.multiCdnSettings.profiles.forEach(profile => {
            let ruleSetToDelete;
            for (const ruleSet of profile.ruleSetIds) {
                if (ruleSet.name === ruleSetName) {
                    ruleSetToDelete = ruleSet;
                }
            }

            const index = profile.ruleSetIds.indexOf(ruleSetToDelete);

            if (index < 0) {
                return;
            }
            profile.ruleSetIds.splice(index, 1);
        });

        this.callbacks.onChange();
    }

    private deletePresetFromAllProfiles(qosPresetName) {
        if (!this.multiCdnSettings.profiles) {
            return;
        }

        this.multiCdnSettings.profiles.forEach(profile => {
            if (profile.actions != null && profile.actions.presetId === qosPresetName) {
                profile.actions.presetId = this.multiCdnSettings.defaultPreset;
            }
        });

        this.callbacks.onChange();
    }
}
