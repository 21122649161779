import {inject} from 'aurelia-framework';

import {copyToClipboard} from '@bindable-ui/bindable';
import {Notification} from 'resources/notification/service';

import {SessionService} from 'services/session';

import {LiveEventsService} from '../services/live-events';

@inject(Notification, LiveEventsService, SessionService)
export class EventAssetsActions {
    constructor(notification, liveEventsService, sessionService) {
        this.notification = notification;
        this.liveEvents = liveEventsService;
        this.session = sessionService;
    }

    activate(model) {
        this.asset = model.record;
    }

    saveAsAsset(assetId, tipContent) {
        this.liveEvents.saveAsset(assetId).then(() => {
            this.asset.event_save = true;
        });
        tipContent.hide();
    }

    openClippingTool(assetId, tipContent) {
        let idsQueryString = '';
        if (this.session.isIdsEnabled()) {
            idsQueryString = '&idsEnabled=true';
        }
        window.open(`/static/cms/clipping/dist/index.html?asset=${assetId}${idsQueryString}`, '_blank');
        tipContent.hide();
    }

    launchTestPlayer(url, tipContent) {
        window.open(`${url}?fpt=v`, '_blank');
        tipContent.hide();
    }

    copyGuidToClipboard(assetId, tipContent) {
        copyToClipboard(assetId, this.notification).finally(() => {
            tipContent.hide();
        });
    }

    triggerSlicerBlackout(eventId, slicerId, tipContent) {
        this.liveEvents.blackoutSlicer(eventId, slicerId).then(() => {
            this.asset.event_save = true;
        });
        tipContent.hide();
    }
}
