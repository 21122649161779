import {autoinject} from 'aurelia-framework';
import {CToastsService} from '@bindable-ui/bindable';
import {Asset} from 'apps/cms/routes/content/playlists/models';
import {PlaylistService} from 'apps/cms/routes/content/playlists/service';
import {LibraryAsset} from 'services/models/library';
import {ContentService} from 'apps/cms/routes/content/services/content';
import {PLAYLIST_ASSETS_LIMIT} from 'apps/cms/routes/constants';

@autoinject()
export class AddAssetFooter {
    public controller: any;
    public model: any;
    public content: any;

    constructor(
        private playlistService: PlaylistService,
        private contentService: ContentService,
        public notification: CToastsService,
    ) {}

    public activate(model) {
        this.controller = model.controller;
        this.model = model.shared;
        this.content = this.contentService;
    }

    public addToPlaylist() {
        const playlist: Asset[] = this.model.selectedAssets.map((a: LibraryAsset) => ({
            beam: {
                created: null,
                duration: a.dur,
                test_players: [],
            } as Asset,
        }));
        const maxLimit = this.playlistService.assetTableData.length + playlist.length;
        if (maxLimit > PLAYLIST_ASSETS_LIMIT) {
            this.notification.error(`Cannot add more than ${PLAYLIST_ASSETS_LIMIT} assets`);
            return;
        }
        this.playlistService.model.playlist = this.playlistService.model.playlist.concat(playlist);
        this.controller.ok(true);
    }
}
