import {Type} from 'class-transformer';
import {IsArray, IsInt, IsString, ValidateNested} from 'class-validator';
import {IHyperion, IMSODesc, IMSOId, IMSOSession, IMSOTotalItems} from './mso';

// Stream Protocol common properties

interface IMSOStreamProtocol {
    /**
     * The name label for this Stream Protocol
     */
    name: string;

    /**
     * The MSO Slicer properties required while using this Stream Protocol
     */
    slicer_inputs: string[];

    /**
     * The MSO Slicer properties defined by using this Stream Protocol
     */
    slicer_outputs: string[];
}

// Stream Protocol response: get

export class MSOStreamProtocolResponseItem implements IMSOStreamProtocol, IMSODesc, IHyperion, IMSOId {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsString()
    public desc: string;

    @IsString()
    public id: string;

    @IsString()
    public name: string;

    @IsArray()
    public slicer_inputs: string[];

    @IsArray()
    public slicer_outputs: string[];
}

export class MSOStreamProtocolResponseItems implements IHyperion, IMSOTotalItems, IMSOSession {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsString()
    public cms_session_id_fingerprint: string;

    @ValidateNested()
    @Type(() => MSOStreamProtocolResponseItem)
    public items: MSOStreamProtocolResponseItem[];

    @IsInt()
    public total_items: number;
}

export enum MSOStreamProtocolSlicerInputs {
    INGEST_POINT_ID = 'ingest_point_id',
    SOURCE_IP = 'source_ip',
}

export enum MSOStreamProtocolSlicerOutputs {
    DEST_IP = 'dest_ip',
    DEST_PORT = 'dest_port',
    SLICER_SECURE_STREAM_URL = 'slicer_secure_stream_url',
    SLICER_STREAM_URL = 'slicer_stream_url',
    SLICER_STREAM_KEY = 'slicer_stream_key',
}
