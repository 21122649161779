import {inject, singleton} from 'aurelia-framework';
import {AureliaCookie} from 'aurelia-cookie';
import {SessionService} from 'services/session';

@singleton()
@inject(SessionService)
export class IdsUtils {
    constructor(sessionService) {
        this.session = sessionService;

        this.IDS_FIRST_WARN_SHOWN_COOKIE_NAME = 'ids-user-migration-done';
        this.IDS_ALWAYS_COOKIE_NAME = 'always-use-ids';
        this.idsFirstWarnShownCookieValue = AureliaCookie.get(this.IDS_FIRST_WARN_SHOWN_COOKIE_NAME);
        this.alwaysUseIds = false;

        if (this.session.sessionInfo) {
            // coming from cms2
            this.idsLoginUrl = this.session.sessionInfo.idsLogin;
        } else {
            // coming from cms as redirect and idsLogin in query param
            this.idsLoginUrl = decodeURIComponent(this.session.getQueryString('idsLogin'));
        }
        this.idsPasswordResetUrl = document.domain.includes('uplynk')
            ? 'https://id.vdms.io/password-reset'
            : 'https://id-dev.vdms.io/password-reset';
    }

    setIDSFirstWarnCookie(value) {
        // Had to calculate ten years because the aurelia-cookie module doesn't
        // work the way it should, expiry: -1 should never expire
        const tenYears = 10 * 365 * 24;
        const domain = `.${window.location.hostname.split('.').slice(-2).join('.')}`;
        AureliaCookie.set(this.IDS_FIRST_WARN_SHOWN_COOKIE_NAME, value, {
            path: '/',
            expiry: tenYears,
            domain,
            secure: true,
        });
    }

    setIDSPreferenceCookie() {
        const domain = `.${window.location.hostname.split('.').slice(-2).join('.')}`;
        AureliaCookie.set(this.IDS_ALWAYS_COOKIE_NAME, true, {
            path: '/',
            expiry: 180 * 24, // expire in 180 days
            domain,
            secure: true,
        });
    }

    loginWithIDS() {
        /* set firstTimeIdsWarningShown cookie to true */
        this.setIDSFirstWarnCookie(true);
        if (this.alwaysUseIds) {
            this.setIDSPreferenceCookie();
        }
        window.location.href = this.idsLoginUrl;
    }

    resetIDSPassword() {
        /* set firstTimeIdsWarningShown cookie to true */
        this.setIDSFirstWarnCookie(true);
        window.location.href = this.idsPasswordResetUrl;
    }
}
