export enum ConfigSettingInputType {
  datetime = 'datetime-local',
  number = 'number',
  radio = 'radio',
  select = 'select',
  text = 'text',
  url = 'url',
}

export interface IConfigSetting {
  /** Indicates this config setting should be removed any time the config model is exported */
  disallowed?: boolean;

  /** Additional information to help describe what this config setting does  */
  info?: string;

  /** The type of UI form input this config setting should map to  */
  formElement?: ConfigSettingInputType;

  /** The type of UI form input this config setting should map to  */
  input?: ConfigSettingInputType;

  /** The 'key' of the key value pair this config setting represents */
  key?: string;

  /** todo */
  macro?: string;

  /** Indicates this config setting is allowed to exist in the config model but will not be included during export */
  managed?: boolean;

  /** An array of options to use when the 'input' is set to 'radio' or 'select' */
  options?: {
    /** An option which should not be selectable, however may already be defined  */
    disabled?: boolean;

    /** A human-friendly label to display for this UI option */
    text: string;

    /** The 'value' which this key value pair will be set to when the user selects this config setting option */
    value: number | string;
  }[];

  /** A regex pattern to validate this config setting with */
  pattern?: string;

  /** Indicates this config setting is expected to exist within every config model */
  required?: boolean;

  /** A short, human-friendly label to describe this config setting  */
  text?: string;
}
