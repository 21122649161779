import {bindable, inject} from 'aurelia-framework';
import {Tip} from './tip';

@inject(Tip)
export class TipTrigger {
    @bindable
    afterShow;

    // Use disabled to match up with button attribute
    @bindable
    disabled = false;

    constructor(tip) {
        this.tip = tip;
        this.additionalClasses = '';
    }

    bind(context) {
        this.context = context;
    }

    show() {
        if (!this.disabled && !this.tip.modifier.includes('turn-off-trigger-content-show')) {
            this.tip.showContent(this.context, this.afterShow);
        }
    }
}
