/**
 * This is an Aurelia wrapper component for the library: facingsea/xmlTreeViewer
 * facingsea/xmlTreeViewer located at: https://github.com/facingsea/xmlTreeViewer
 */
/* istanbul ignore file */

import {bindable} from 'aurelia-framework';

import {Util} from 'services/util';
import xmlViewerCss from './xml-viewer.css';

export class XmlViewer {
    public id;
    public styles = xmlViewerCss;
    public processorsMap = {};
    public errMsg = '';

    public xmlNode = null;
    public nodeParentPairs = [];
    public htmlNode;

    @bindable
    public label: string;

    @bindable
    public rawXml: string;

    constructor() {
        this.id = Util.generateGuid();
        this.processorsMap[Node.PROCESSING_INSTRUCTION_NODE] = this.processProcessingInstruction.bind(this);
        this.processorsMap[Node.ELEMENT_NODE] = this.processElement.bind(this);
        this.processorsMap[Node.COMMENT_NODE] = this.processComment.bind(this);
        this.processorsMap[Node.TEXT_NODE] = this.processText.bind(this);
        this.processorsMap[Node.CDATA_SECTION_NODE] = this.processCDATA.bind(this);
    }

    public attached() {
        document.getElementById(`xml-viewer-${this.id}`).appendChild(this.htmlNode);
    }

    public rawXmlChanged(newVal, oldVal) {
        if (!newVal) {
            if (!oldVal) {
                return;
            }
        }
        this.xmlNode = this.parseXML(newVal);
        if (this.xmlNode) {
            // populates this.htmlNode
            this.generateHTMLNode();
        }
    }

    public parseXML(data: string) {
        let xml;
        let tmp;
        this.errMsg = '';
        try {
            if (window.DOMParser) {
                tmp = new window.DOMParser();
                xml = tmp.parseFromString(data, 'text/xml');
            } else {
                // IE
                xml = new (window as any).ActiveXObject('Microsoft.XMLDOM');
                xml.async = 'false';
                xml.loadXML(data);
            }
        } catch (e) {
            xml = undefined;
        }

        if (!xml || !xml.documentElement || xml.getElementsByTagName('parsererror').length) {
            this.errMsg = xml.getElementsByTagName('parsererror')[0].textContent;
        }
        return xml;
    }

    public generateHTMLNode() {
        this.htmlNode = this.createHTMLElement('div');
        this.htmlNode.classList.add(_.get(this.styles, 'prettyPrint'));
        for (let child = this.xmlNode.firstChild; child; child = child.nextSibling) {
            this.nodeParentPairs.push({parentElement: this.htmlNode, node: child});
        }
        for (const npp of this.nodeParentPairs) {
            this.processNode(npp.parentElement, npp.node);
        }

        this.initButtons();
    }

    public processNode(parentElement, node) {
        const map = this.processorsMap;
        if (map[node.nodeType]) {
            map[node.nodeType](parentElement, node);
        }
    }

    public processElement(parentElement, node) {
        if (!node.firstChild) {
            this.processEmptyElement(parentElement, node);
        } else {
            const child = node.firstChild;
            if (child.nodeType === Node.TEXT_NODE && this.isShort(child.nodeValue) && !child.nextSibling) {
                this.processShortTextOnlyElement(parentElement, node);
            } else {
                this.processComplexElement(parentElement, node);
            }
        }
    }

    public processEmptyElement(parentElement, node) {
        const line = this.createLine();
        line.appendChild(this.createTag(node, false, true));
        parentElement.appendChild(line);
    }

    public processShortTextOnlyElement(parentElement, node) {
        const line = this.createLine();
        line.appendChild(this.createTag(node, false, false));
        for (let child = node.firstChild; child; child = child.nextSibling) {
            line.appendChild(this.createText(child.nodeValue));
        }

        line.appendChild(this.createTag(node, true, false));
        parentElement.appendChild(line);
    }

    public processComplexElement(parentElement, node) {
        const collapsible = this.createCollapsible();

        collapsible.expanded.start.appendChild(this.createTag(node, false, false));
        for (let child = node.firstChild; child; child = child.nextSibling) {
            this.nodeParentPairs.push({parentElement: collapsible.expanded.content, node: child});
        }
        collapsible.expanded.end.appendChild(this.createTag(node, true, false));

        collapsible.collapsed.content.appendChild(this.createTag(node, false, false));
        collapsible.collapsed.content.appendChild(this.createText('...'));
        collapsible.collapsed.content.appendChild(this.createTag(node, true, false));
        parentElement.appendChild(collapsible);
    }

    public processComment(parentElement, node) {
        if (this.isShort(node.nodeValue)) {
            const line = this.createLine();
            line.appendChild(this.createComment(`<!-- ${node.nodeValue} -->`));
            parentElement.appendChild(line);
        } else {
            const collapsible = this.createCollapsible();

            collapsible.expanded.start.appendChild(this.createComment('<!--'));
            collapsible.expanded.content.appendChild(this.createComment(node.nodeValue));
            collapsible.expanded.end.appendChild(this.createComment('-->'));

            collapsible.collapsed.content.appendChild(this.createComment('<!--'));
            collapsible.collapsed.content.appendChild(this.createComment('...'));
            collapsible.collapsed.content.appendChild(this.createComment('-->'));
            parentElement.appendChild(collapsible);
        }
    }

    public processCDATA(parentElement, node) {
        if (this.isShort(node.nodeValue)) {
            const line = this.createLine();
            line.appendChild(this.createText(`<![CDATA[${node.nodeValue}]]>`));
            parentElement.appendChild(line);
        } else {
            const collapsible = this.createCollapsible();

            collapsible.expanded.start.appendChild(this.createText('<![CDATA['));
            collapsible.expanded.content.appendChild(this.createText(node.nodeValue));
            collapsible.expanded.end.appendChild(this.createText(']]>'));

            collapsible.collapsed.content.appendChild(this.createText('<![CDATA['));
            collapsible.collapsed.content.appendChild(this.createText('...'));
            collapsible.collapsed.content.appendChild(this.createText(']]>'));
            parentElement.appendChild(collapsible);
        }
    }

    public processProcessingInstruction(parentElement, node) {
        if (this.isShort(node.nodeValue)) {
            const line = this.createLine();
            line.appendChild(this.createComment(`<?${node.nodeName} ${node.nodeValue}?>`));
            parentElement.appendChild(line);
        } else {
            const collapsible = this.createCollapsible();

            collapsible.expanded.start.appendChild(this.createComment(`<?${node.nodeName}`));
            collapsible.expanded.content.appendChild(this.createComment(node.nodeValue));
            collapsible.expanded.end.appendChild(this.createComment('?>'));

            collapsible.collapsed.content.appendChild(this.createComment(`<?${node.nodeName}`));
            collapsible.collapsed.content.appendChild(this.createComment('...'));
            collapsible.collapsed.content.appendChild(this.createComment('?>'));
            parentElement.appendChild(collapsible);
        }
    }

    public processText(parentElement, node) {
        parentElement.appendChild(this.createText(node.nodeValue));
    }

    public trim(value) {
        return value.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    }

    public isShort(value) {
        return this.trim(value).length <= 50;
    }

    public createHTMLElement(elementName) {
        return document.createElement(elementName);
    }

    public createCollapsible() {
        const collapsible = this.createHTMLElement('div');
        collapsible.classList.add(_.get(this.styles, 'collapsible'));
        collapsible.expanded = this.createHTMLElement('div');
        collapsible.expanded.classList.add('expanded');
        collapsible.appendChild(collapsible.expanded);

        collapsible.expanded.start = this.createLine();
        collapsible.expanded.start.appendChild(this.createCollapseButton());
        collapsible.expanded.appendChild(collapsible.expanded.start);

        collapsible.expanded.content = this.createHTMLElement('div');
        collapsible.expanded.content.classList.add(_.get(this.styles, 'collapsibleContent'));
        collapsible.expanded.appendChild(collapsible.expanded.content);

        collapsible.expanded.end = this.createLine();
        collapsible.expanded.appendChild(collapsible.expanded.end);

        collapsible.collapsed = this.createHTMLElement('div');
        collapsible.collapsed.classList.add('collapsed');
        collapsible.collapsed.classList.add(_.get(this.styles, 'hidden'));
        collapsible.appendChild(collapsible.collapsed);
        collapsible.collapsed.content = this.createLine();
        collapsible.collapsed.content.appendChild(this.createExpandButton());
        collapsible.collapsed.appendChild(collapsible.collapsed.content);

        return collapsible;
    }

    public createButton() {
        const button = this.createHTMLElement('span');
        button.classList.add(_.get(this.styles, 'button'));
        return button;
    }

    public createCollapseButton() {
        const button = this.createButton();
        button.classList.add(_.get(this.styles, 'collapseButton'));
        return button;
    }

    public createExpandButton() {
        const button = this.createButton();
        button.classList.add(_.get(this.styles, 'expandButton'));
        return button;
    }

    public createComment(commentString) {
        const comment = this.createHTMLElement('span');
        comment.classList.add(_.get(this.styles, 'comment'));
        comment.classList.add(_.get(this.styles, 'htmlComment'));
        comment.textContent = commentString;
        return comment;
    }

    public createText(value) {
        const text = this.createHTMLElement('span');
        text.textContent = this.trim(value);
        // text.classList.add(htmlText_.get(this.styles, ''));
        return text;
    }

    public createLine() {
        const line = this.createHTMLElement('div');
        // line.classList.add(line_.get(this.styles, ''));
        return line;
    }

    public createTag(node, isClosing, isEmpty) {
        const tag = this.createHTMLElement('span');
        tag.classList.add(_.get(this.styles, 'htmlTag'));

        let stringBeforeAttrs = '<';
        if (isClosing) {
            stringBeforeAttrs += '/';
        }
        // stringBeforeAttrs += node.nodeName;
        const textBeforeAttrs = document.createTextNode(stringBeforeAttrs);
        tag.appendChild(textBeforeAttrs);
        const tagNode = this.createHTMLElement('span');
        // tagNode.classList.add(htmlTagName_.get(this.styles, ''));
        tagNode.textContent = node.nodeName;
        tag.appendChild(tagNode);

        if (!isClosing) {
            for (const attr of node.attributes) {
                tag.appendChild(this.createAttribute(attr));
            }
        }

        let stringAfterAttrs = '';
        if (isEmpty) {
            stringAfterAttrs += '/';
        }
        stringAfterAttrs += '>';
        const textAfterAttrs = document.createTextNode(stringAfterAttrs);
        tag.appendChild(textAfterAttrs);

        return tag;
    }

    public createAttribute(attributeNode) {
        const attribute = this.createHTMLElement('span');
        // attribute.classList.add(_.get(this.styles, 'htmlAttribute'));

        const attributeName = this.createHTMLElement('span');
        attributeName.classList.add(_.get(this.styles, 'htmlAttributeName'));
        attributeName.textContent = attributeNode.name;

        const textBefore = document.createTextNode(' ');
        const textBetween = document.createTextNode('="');

        const attributeValue = this.createHTMLElement('span');
        attributeValue.classList.add(_.get(this.styles, 'htmlAttributeValue'));
        attributeValue.textContent = attributeNode.value;

        const textAfter = document.createTextNode('"');

        attribute.appendChild(textBefore);
        attribute.appendChild(attributeName);
        attribute.appendChild(textBetween);
        attribute.appendChild(attributeValue);
        attribute.appendChild(textAfter);
        return attribute;
    }

    public expandFunction(sectionId) {
        return () => {
            this.htmlNode.querySelector(`#${sectionId} > .expanded`).className = 'expanded';
            this.htmlNode.querySelector(`#${sectionId} > .collapsed`).className = `collapsed ${_.get(
                this.styles,
                'hidden',
            )}`;
        };
    }

    public collapseFunction(sectionId) {
        return () => {
            this.htmlNode.querySelector(`#${sectionId} > .collapsed`).className = 'collapsed';
            this.htmlNode.querySelector(`#${sectionId} > .expanded`).className = `expanded ${_.get(
                this.styles,
                'hidden',
            )}`;
        };
    }

    public initButtons() {
        const sections = this.htmlNode.querySelectorAll(`.${_.get(this.styles, 'collapsible')}`);
        for (let i = 0; i < sections.length; i += 1) {
            const sectionId = `collapsible${i}`;
            sections[i].id = sectionId;

            const expandedPart = sections[i].querySelector(`#${sectionId} > .expanded`);
            const collapseButton = expandedPart.querySelector(`.${_.get(this.styles, 'collapseButton')}`);
            collapseButton.onclick = this.collapseFunction(sectionId);
            collapseButton.onmousedown = this.handleButtonMouseDown;

            const collapsedPart = sections[i].querySelector(`#${sectionId} > .collapsed`);
            const expandButton = collapsedPart.querySelector(`.${_.get(this.styles, 'expandButton')}`);

            expandButton.onclick = this.expandFunction(sectionId);
            expandButton.onmousedown = this.handleButtonMouseDown;
        }
    }

    public handleButtonMouseDown(e) {
        // To prevent selection on double click
        e.preventDefault();
    }
}
