import {ConfigState as ConfigStateBase} from 'resources/config/import/models/config-state';

export class ConfigState extends ConfigStateBase {
  // this is used for a single item and the entire response
  public error: string;
  public isCreating: boolean;
  public isDeleting: boolean;
  public isLoading: boolean;
  public isLoadingParams: boolean;
  public isUpdating: boolean;
  public hasChanges: boolean;
  public isValid: boolean;
}
