import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {TypeConfigResponseCollection} from '../models/typeconfig-response-collection';

@autoinject
export class TypeConfigService {
  private url: string = '/api/v4/typeconfig';

  constructor(public acceo: Acceo) {}

  public get() {
    return this.acceo.get(TypeConfigResponseCollection)(this.url);
  }
}
