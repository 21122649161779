import {autoinject} from 'aurelia-framework';
import {ConfigEditor} from 'resources/config/import/editor';
import {LynkDialog, LynkInput, LynkPopover, LynkRadioGroup} from '@uplynk/lynk-design';
import {IConfigLineTableRow} from 'resources/config/models/config-line-table';
import {ConfigLineType} from 'resources/config/models/config-line';
import {ConfigSettingInputType, IConfigSetting} from 'resources/config/models/config-setting';
import {ConfigImportMode} from 'resources/config/import/models/config-import';
import {Toast} from 'resources/toast/toast';
import {dirtyCheckPrompt} from '@bindable-ui/bindable';
import {Config} from '../../models/config';
import {MacroInput} from '../../models/config-input';

@dirtyCheckPrompt
@autoinject
export class AdConfigEditor extends ConfigEditor {
  public config: Config;
  public dialogConfigSetting: LynkDialog;
  public dialogMacro: LynkDialog;
  public formConfigSetting: HTMLFormElement;
  public formMacro: HTMLFormElement;
  public popoverImportExport: LynkPopover;
  public hasChanges: boolean;
  public selectedMacro: IConfigSetting;

  constructor() {
    super();
  }

  public isDirty(): boolean {
    return JSON.stringify(this.config.input.table.rows) !== JSON.stringify(this.config.dirty);
  }

  public actionEditConfigSetting(row: IConfigLineTableRow) {
    this.config.input.configSetting.key = row.key;
    this.config.input.configSetting.id = row.id;
    this.config.input.configSetting.value = row.value;
    Object.assign(this.config.pristine.configSetting, this.config.input.configSetting);
    this.dialogConfigSetting.show();
  }

  public actionEditMacro(row: IConfigLineTableRow) {
    const macro = this.config.options.configSettingMacro.find(setting => row.value.indexOf(setting.value) !== -1);
    this.config.input.macro.behavior = row.key.substring(0, row.key.indexOf('.'));
    this.config.input.macro.key = row.key.substring(row.key.indexOf('.') + 1);
    this.config.input.macro.type = macro.value;
    this.config.input.macro.id = row.id;
    this.config.input.provider = row.provider;
    const value = row.value.replace(`${macro.value},`, '').replace(macro.value, '');
    this.config.input.macro.value = value;
    this.selectedMacro = this.findSelectedMacro();

    Object.assign(this.config.pristine.macro, this.config.input.macro);
    this.dialogMacro.show();
  }

  public async actionExport() {
    await super.actionExport(this.config.pristine.name);
    Toast.success('Export completed');
    this.popoverImportExport.hide();
  }

  public async actionImport() {
    await super.actionImport(ConfigImportMode.MERGE);
    Toast.success('Import completed');
    this.popoverImportExport.hide();
  }

  public actionSaveConfigSetting() {
    this.hasChanges = this.isDirty();

    if (this.config.input.configSetting.id) {
      this.config.input.table.editLine(
        this.config.input.configSetting.id,
        this.config.input.configSetting.key,
        `${this.config.input.configSetting.value}`,
      );
    } else {
      this.config.input.table.importLine(
        `${this.config.input.keyPrefix}${this.config.input.configSetting.key}`,
        `${this.config.input.configSetting.value}`,
      );
    }
    this.dialogConfigSetting.hide();
  }

  public actionSaveMacro() {
    this.hasChanges = this.isDirty();

    const prefix = this.config.input.macro.type ? `${this.config.input.macro.behavior}.` : '';
    const key = `${prefix}${this.config.input.macro.key}`;
    let value = `${this.config.input.macro.type}`;
    if (this.config.input.macro.value) {
      if (this.selectedMacro.input === ConfigSettingInputType.datetime) {
        const timestamp = new Date(`${this.config.input.macro.value}`).getTime();
        this.config.input.macro.value = `${timestamp}`;
      }
      const separator = this.config.input.macro.behavior === 'pfv' ? ',' : '';
      value = `${value}${separator}${this.config.input.macro.value}`;
    }
    if (this.config.input.macro.id) {
      this.config.input.table.editLine(this.config.input.macro.id, key, value);
    } else {
      this.config.input.table.importLine(key, value);
    }
    this.dialogMacro.hide();
  }

  public afterHideDialog(event: CustomEvent) {
    if (event.target === this.dialogConfigSetting) {
      this.resetInputConfigSetting();
    }
    if (event.target === this.dialogMacro) {
      this.resetInputMacro();
    }
  }

  public onChangeConfigSettingType(value: string) {
    this.config.input.configSetting.key = value;
  }

  public onChangeMacroType(value: string) {
    this.config.errors.macro.value = '';
    this.config.input.macro.value = '';
    this.config.input.macro.type = value;
    this.selectedMacro = this.findSelectedMacro();
  }

  public onclickParamMenu(menuOption: string) {
    if (menuOption === 'macroParam') {
      this.onMenuItemClickMacro('');
    } else if (menuOption === 'customParam') {
      this.config.input.keyPrefix = 'pt.';
      this.onMenuItemClickConfigSetting('');
    } else {
      this.onMenuItemClickConfigSetting('');
    }
  }

  public onMenuItemClickConfigSetting(paramType: string) {
    this.config.input.paramType = paramType;
    this.dialogConfigSetting.show();
  }

  public onMenuItemClickMacro(macro: string) {
    this.config.input.macro.type = macro;
    this.dialogMacro.show();
  }

  public onRequestCloseDialog(event: CustomEvent) {
    this.resetInputConfigSetting();
    if (event.detail.source === 'overlay') {
      event.preventDefault();
      return false;
    }
    return true;
  }

  public onRowClick(row: IConfigLineTableRow) {
    if (row.type === ConfigLineType.MANAGED) {
      return;
    }
    if (row.type === ConfigLineType.MACRO) {
      this.actionEditMacro(row);
    } else {
      this.actionEditConfigSetting(row);
    }
  }

  public resetInputConfigSetting() {
    this.config.input.keyPrefix = '';
    this.config.input.paramType = '';
    this.config.input.configSetting.id = '';
    this.config.input.configSetting.key = '';
    this.config.input.configSetting.value = '';
    this.config.errors.configSetting.key = undefined;
    this.config.errors.configSetting.value = undefined;
    this.formConfigSetting.reset();
  }

  public resetInputMacro() {
    this.config.input.macro = new MacroInput();
    this.config.errors.macro.key = undefined;
    this.config.errors.macro.value = undefined;
    this.formMacro.reset();
  }

  public findSelectedMacro() {
    return this.config.options.configSetting.find(setting => setting.macro === this.config.input.macro.type);
  }

  public validateConfigSettingKey(target: LynkInput) {
    let customError: string;

    this.setKeyPrefix(); // set the prefix based on how the param will be used
    // ensure unknown type's key isn't any of the 'managed' keys
    if (this.config.input.table.configTableData.keysManaged.indexOf(target.value) !== -1) {
      customError = 'Conflicts with a managed parameter';
    }

    // ensure unknown type's key isn't a duplicate of an existing key
    else if (this.config.input.table.rows.find(row => row.key === target.value)) {
      customError = 'Conflicts with an existing parameter';
    }
    // TODO - enhance above logic to mirror macro dup checks to evaluate full key including prefix
    //        i.e. 'pt.somekey' !== 'somekey' (which currently is evaluating as a duplicate)

    this.config.errors.macro.key = customError;
    target.setCustomValidity(customError || '');
    target.reportValidity();
  }

  public setKeyPrefix() {
    if (this.config.input.paramType === 'uplynk' && !this.isMacro()) {
      this.config.input.keyPrefix = '';
    } else if (this.config.input.paramType === 'adserver') {
      this.config.input.keyPrefix = 'pt.';
    }
  }

  public isMacro() {
    return this.config.input.paramType === 'macro';
  }

  public validateMacroBehavior(target: LynkRadioGroup, inputKey: LynkInput) {
    this.config.input.macro.behavior = target.value;
    if (inputKey) {
      this.validateMacroKey(inputKey);
    }
  }

  public validateMacroKey(target: LynkInput) {
    let customError: string;

    const key = `${this.config.input.macro.behavior}.${target.value}`;
    const pristinePrefix = this.config.pristine.macro.type ? `${this.config.pristine.macro.behavior}.` : '';
    const pristineKey = `${pristinePrefix}${this.config.pristine.macro.key}`;
    if (key !== pristineKey && this.config.input.table.rows.find(row => row.key === key)) {
      customError = 'Conflicts with an existing parameter';
    } // TODO this dup check is working properly; mirror this in validateConfigSettingKey

    this.config.errors.macro.key = customError;
    target.setCustomValidity(customError || '');
    target.reportValidity();
  }
}
