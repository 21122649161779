import {autoinject} from 'aurelia-framework';

import {Acceo} from 'services/acceo';
import {AdServerDebugItem} from '../models/ad-server-debug-response';

@autoinject
export class JobTransactionCallbackService {
  // to be deprecated:
  private url: string = '/api/v4/adproxy/ad-debug/adjob/s3';

  constructor(public acceo: Acceo) {}

  public async getById(zone: string, id: string, baseFolder: string = '', filePath: string = '') {
    const url: string = `${this.url}?zone=${encodeURIComponent(zone)}&job_id=${encodeURIComponent(
      id,
    )}&base_folder=${encodeURIComponent(baseFolder)}&file_path=${encodeURIComponent(filePath)}`;
    return this.acceo.get(AdServerDebugItem)(url);
  }
}
