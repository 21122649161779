import {bindable, inject, computedFrom} from 'aurelia-framework';
import {Notification} from 'resources/notification/service';

import {deepCopy} from 'resources/deep-copy';
import {LiveEventsService} from '../services/live-events';

const DEFAULT_PER_PAGE_LIMIT = 30;
const DEFAULT_SKIP = 0;

/**
 * @param params {Object} - Object that has different filter params
 * @param updateFilter {Function} - Function in parent VM that will update list from params selected
 * @param savedFilterRef {Au Component Reference} - Reference to <events-filter-saved> component
 * */

@bindable('operatorOptions')
@bindable('slicerOptions')
@bindable('stateOptions')
@bindable('filterGrid')
@inject(Notification, LiveEventsService)
export class EventsFilter {
    @bindable
    params;

    @bindable
    updateFilter;

    @bindable
    savedFilterRef;

    constructor(notification, liveEventsService) {
        this.notification = notification;
        this.liveEvents = liveEventsService;

        this.filterGrid = null;

        this.operatorOptions = [];
        this.slicerOptions = [];
        this.stateOptions = [];
    }

    attached() {
        Object.keys(this.liveEvents.states).forEach(key => {
            this.stateOptions.push({
                id: key,
                name: this.liveEvents.states[key],
            });
        });

        this.liveEvents.getSlicers().then(slicers => {
            this.slicerOptions = slicers;
        });

        this.liveEvents.getOperators().then(operators => {
            this.operatorOptions = operators;
        });
    }

    /**
     * Callbacks for dateFrom instance of timepicker component.
     *
     * @method onChange
     */
    dateFromCallbacks = {
        /**
         * Sets the `r` to new `timestamp` value. Then, if stop
         * isn't set, it defaults to `start + DEFAULT_DURATION_MS`.
         */
        onChange: timestamp => {
            this.filterGrid.params.r_start = Number(timestamp);
        },
    };

    /**
     * Callbacks for dateTo instance of timepicker component.
     *
     * @method onChange
     */
    dateToCallbacks = {
        /**
         * Sets the `r_stop` to new `timestamp` value.
         */
        onChange: timestamp => {
            this.filterGrid.params.r_stop = Number(timestamp);
        },
    };

    /**
     * Callbacks for endDateFrom instance of timepicker component.
     *
     * @method onChange
     */
    endDateFromCallbacks = {
        /**
         * Sets the `r` to new `timestamp` value. Then, if stop
         * isn't set, it defaults to `start + DEFAULT_DURATION_MS`.
         */
        onChange: timestamp => {
            this.filterGrid.params.end_r_start = Number(timestamp);
        },
    };

    /**
     * Callbacks for endDateTo instance of timepicker component.
     *
     * @method onChange
     */
    endDateToCallbacks = {
        /**
         * Sets the `r_stop` to new `timestamp` value.
         */
        onChange: timestamp => {
            this.filterGrid.params.end_r_stop = Number(timestamp);
        },
    };

    /**
     * Callbacks for createdDateFrom instance of timepicker component.
     *
     * @method onChange
     */
    createdDateFromCallbacks = {
        /**
         * Sets the `r` to new `timestamp` value. Then, if stop
         * isn't set, it defaults to `start + DEFAULT_DURATION_MS`.
         */
        onChange: timestamp => {
            this.filterGrid.params.created_r_start = Number(timestamp);
        },
    };

    /**
     * Callbacks for createdDateTo instance of timepicker component.
     *
     * @method onChange
     */
    createdDateToCallbacks = {
        /**
         * Sets the `r_stop` to new `timestamp` value.
         */
        onChange: timestamp => {
            this.filterGrid.params.created_r_stop = Number(timestamp);
        },
    };

    validateFilters(checkSaved) {
        let isValid = true;
        this.errorCreate = false;

        if (!this.filterGrid.calendar) {
            if (
                this.filterGrid.params.r_start &&
                this.filterGrid.params.r_stop &&
                this.filterGrid.params.r_stop < this.filterGrid.params.r_start
            ) {
                isValid = false;
                this.notification.error(
                    'Date To: Invalid selection',
                    'Please select a date/ time that is later than "Date From".',
                );
            }

            if (
                this.filterGrid.params.end_r_start &&
                this.filterGrid.params.end_r_stop &&
                this.filterGrid.params.end_r_stop < this.filterGrid.params.end_r_start
            ) {
                isValid = false;
                this.notification.error(
                    'Date To: Invalid selection',
                    'Please select a date/ time that is later than "Date From".',
                );
            }

            if (
                this.filterGrid.params.created_r_start &&
                this.filterGrid.params.created_r_stop &&
                this.filterGrid.params.created_r_stop < this.filterGrid.params.created_r_start
            ) {
                isValid = false;
                this.notification.error(
                    'Date To: Invalid selection',
                    'Please select a date/ time that is later than "Date From".',
                );
            }
        }

        if (checkSaved) {
            if (!this.filterName || !this.filterName.length) {
                this.errorCreate = "Can't be blank";
                isValid = false;
                return isValid;
            }

            if (!this.savedFilterRef.canUseName(this.filterName)) {
                this.errorCreate = 'Filter name already in use.';
                isValid = false;
            }
        }

        return isValid;
    }

    resetRangeValues(field) {
        if (field === 'start' && this.filterGrid.params.startRange !== 'Custom Date Range') {
            this.filterGrid.params.r_start = null;
            this.filterGrid.params.r_stop = null;
        } else if (field === 'end' && this.filterGrid.params.endRange !== 'Custom Date Range') {
            this.filterGrid.params.end_r_start = null;
            this.filterGrid.params.end_r_stop = null;
        } else if (field === 'created' && this.filterGrid.params.createdRange !== 'Custom Date Range') {
            this.filterGrid.params.created_r_start = null;
            this.filterGrid.params.created_r_stop = null;
        }
    }

    /**
     * Computed Values
     */
    @computedFrom('filterGrid.params.startRange')
    get showStartRange() {
        if (this.filterGrid.params && this.filterGrid.params.startRange === 'Custom Date Range') {
            return true;
        }

        return false;
    }

    @computedFrom('filterGrid.params.endRange')
    get showEndRange() {
        if (this.filterGrid.params && this.filterGrid.params.endRange === 'Custom Date Range') {
            return true;
        }

        return false;
    }

    @computedFrom('filterGrid.params.createdRange')
    get showCreatedRange() {
        if (this.filterGrid.params && this.filterGrid.params.createdRange === 'Custom Date Range') {
            return true;
        }

        return false;
    }

    openModal() {
        this.filterGrid.params = deepCopy(this.params);
        this.filterName = '';

        // Workaround to support searching of empty Operators and Slicers
        if (this.filterGrid.params.slicer === null) {
            this.filterGrid.params.slicer = '--- None ---';
        }
        if (this.filterGrid.params.operator === null) {
            this.filterGrid.params.operator = '--- None ---';
        }

        // Help with value on select2 changing in the background
        this.isChanged = Date.now();
        setTimeout(() => {
            $('#operators').select2();
        });

        this.filterGrid.open();
    }

    closeModal() {
        this.filterGrid.params = {};
        this.filterGrid.close();
    }

    getFilterParams(checkSaved) {
        if (!this.validateFilters(checkSaved)) {
            return false;
        }

        const currentParams = this.liveEvents.sanitizeParams(this.params);
        const params = this.liveEvents.getSanitizedBlankFilterParams();

        // Default these two guys because we need to reset on a new query with the new filter
        this.filterGrid.params.skip = DEFAULT_SKIP;
        this.filterGrid.params.limit = DEFAULT_PER_PAGE_LIMIT;

        this.filterGrid.params.since = null;
        this.filterGrid.params.deleted = null;

        if (currentParams.calendar) {
            this.filterGrid.params.r_start = currentParams.r_start;
            this.filterGrid.params.r_stop = currentParams.r_stop;
            this.filterGrid.params.calendar = currentParams.calendar;
            this.filterGrid.params.limit = currentParams.limit;
            this.filterGrid.params.include = currentParams.include;
        }

        return Object.assign(params, this.filterGrid.params);
    }

    applyFilter() {
        const params = this.getFilterParams();

        if (params) {
            this.updateFilter({params});
            this.closeModal();
        }
    }

    saveFilter() {
        this.filterGrid.params.name = this.filterName;
        const params = this.getFilterParams(true);

        if (params) {
            this.savedFilterRef.saveFilter(params);
            this.tc.hide();
            this.filterName = '';
            return true;
        }

        return false;
    }

    saveAndApplyFilter() {
        const didSave = this.saveFilter();

        if (didSave) {
            this.applyFilter();
        }
    }

    clearFilter() {
        const currentParams = deepCopy(this.params);

        this.filterGrid.params = this.liveEvents.getSanitizedBlankFilterParams();

        if (currentParams.calendar) {
            this.filterGrid.params.r_start = currentParams.r_start;
            this.filterGrid.params.r_stop = currentParams.r_stop;
            this.filterGrid.params.calendar = currentParams.calendar;
            this.filterGrid.params.limit = currentParams.limit;
            this.filterGrid.params.include = currentParams.include;
        }

        const params = {...this.filterGrid.params};
        this.updateFilter({params});
        this.closeModal();
    }
}
