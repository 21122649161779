import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {ClippingConfig, Overlay, UploadOverlay} from '../models/clipping-config';
import {ClippingSettingService} from '../services/clipping-service';

@autoinject()
export class ClippingOverlays {
    @bindable public config: ClippingConfig;
    @bindable public validationErrors: ValidationError[];
    @bindable public callbacks;
    @bindable() public upload: (uploadOverlay) => Promise<any>;

    public hasError = false;
    public hasUploadNameError = false;
    public hasUploadFileTypeError = false;
    public hasUploadFileSizeError = false;
    public viewOverlay: Overlay;
    public overlayModal;
    public uploadTip;
    public uploadOverlay: UploadOverlay;
    public configChanged = new Date();
    public disableUploadAdd = false;
    public isUploading = false;
    public descInput;

    constructor(public clippingSettingService: ClippingSettingService) {}

    public viewOverlayIamge(item) {
        this.viewOverlay = item;
        this.overlayModal.open();
    }

    public clearUploadList(fileToUpload) {
        this.uploadTip.hide();
        this.disableUploadAdd = false;
        this.isUploading = false;
        // Clear the filelist
        (document.getElementById('myfilelist') as HTMLInputElement).value = '';
        fileToUpload.overlayName = '';
    }

    public async uploadOverlayFile(fileToUpload: UploadOverlay) {
        this.hasUploadNameError = false;
        this.hasUploadFileTypeError = false;
        this.hasUploadFileSizeError = false;
        if (!fileToUpload || !fileToUpload.overlayName || fileToUpload.overlayName.length === 0) {
            this.hasUploadNameError = true;
        }

        if (!fileToUpload || !fileToUpload.fileList || fileToUpload.fileList.length === 0) {
            this.hasUploadFileTypeError = true;
        }

        if (fileToUpload.fileList[0].size > 8000000) {
            this.hasUploadFileSizeError = true;
        }

        if (this.hasUploadNameError || this.hasUploadFileTypeError || this.hasUploadFileSizeError) {
            return;
        }
        this.disableUploadAdd = true;
        this.isUploading = true;
        fileToUpload.clippingProfileId = this.config.id;

        await this.upload(fileToUpload)
            .then(() => {
                this.clearUploadList(fileToUpload);
            })
            .catch(() => {
                this.disableUploadAdd = false;
                this.isUploading = false;
            });
    }

    public remove(index) {
        this.config.overlays.splice(index, 1);
        this.callbacks.onChange();
    }

    public descFocus() {
        this.descInput.focus();
    }
}
