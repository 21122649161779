import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, LogManager} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {Router} from 'aurelia-router';
import {AnalyticsSystemGetResponse, AnalyticsSystemPostResponse, Dashboard} from '../models/model';

const log = LogManager.getLogger('roles-service');
const URI = '/ops/cms-analytics';
const SAVE_URI = `${URI}/save`;
const DASHBOARD_URI = `${URI}/dashboard`;
const DASHBOARD_EDIT_URI = `${URI}/dashboard/edit`;

@autoinject()
export class AnalyticsService {
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public newDashboardTitle: string = '';
  public newDashboardId: string = '';
  public newDashboardTag: string = 'tag_owner_name';
  public newDashboardView: string = '';
  public newDashboardRoute: string = '';
  public currentModel: AnalyticsSystemGetResponse = null;
  public savedDashboardTitle: string = '';
  public currentDashboard: Dashboard = null;
  public dashboards: Dashboard[] = [];
  public currentUserDashboards: Dashboard[] = [];
  public currentAdminDashboards: Dashboard[] = [];
  public userDashboards: Dashboard[] = [];
  public adminDashboards: Dashboard[] = [];
  public routeCallback: () => any;
  public selectAllDashboards: boolean = false;
  // private isSaving: boolean = false;
  public selectedDashboards: string[] = [];
  public addDashboardDialog: any;
  public newDashboardError: string = '';
  public disableSaveOrder: boolean = true;
  public selectedStyle: string = '0';
  public dashboardLimit = 25;
  public alertStylesLabels = [
    'Info (purple)',
    'Warning (yellow)',
    'Danger (red)',
  ];

  // Analytics dashboards are queried with one of these tags. By owner name is the most common
  // and will be the default.
  public dashboardSessionTags = [
    'tag_owner_name',
    'billing_owner_name',
  ];

  // Currently dashboards are either for users (e.g. customers) or admin meant for internal
  // users that give access to proprietary information.
  public dashboardViews = [
    'user',
    'admin',
  ];

  constructor(private acceo: Acceo, private notification: CToastsService, private router: Router) {}

  public async add() {
    this.isSaving = true;
    this.newDashboardError = '';
    const newDashboard = {
      route: this.newDashboardRoute,
      id: this.newDashboardId,
      title: this.newDashboardTitle,
      view: this.newDashboardView,
      tag: this.newDashboardTag,
    };
    try {
      await this.acceo.post(AnalyticsSystemPostResponse)(DASHBOARD_URI, newDashboard);
      this.notification.success('Dashboard added');
      this.addDashboardDialog.hide();
      this.getSystemAnalytics();
    } catch (err) {
      this.newDashboardError = err.message;
    } finally {
      this.isSaving = false;
    }
  }

  public showAddDashboardDialog() {
    this.newDashboardError = '';
    this.addDashboardDialog.show();
  }

  public async deleteDashboard() {
    try {
      await this.acceo.post(AnalyticsSystemPostResponse)(`${DASHBOARD_URI}/delete`, {guid: this.currentDashboard.guid});
      this.notification.success('Dashboard removed');
      this.router.navigate(`/cms-analytics/dashboards`);
    } catch (err) {
      this.notification.error(err.message);
    } finally {
      this.isSaving = false;
    }
  }

  public async save() {
    const data = {
      custom_msg_enabled: this.currentModel.custom_msg_enabled,
      custom_msg_text: this.currentModel.custom_msg_text,
      custom_msg_style: this.currentModel.custom_msg_style,
      dashboards: [
        ...this.userDashboards,
        ...this.adminDashboards,
      ],
    };
    this.isSaving = true;
    try {
      await this.acceo.post(AnalyticsSystemPostResponse)(SAVE_URI, data);
      this.notification.success('Settings saved');
    } catch (err) {
      this.notification.error(err.message);
    } finally {
      this.isSaving = false;
    }
  }

  public async saveSingle() {
    this.isSaving = true;
    try {
      await this.acceo.post(AnalyticsSystemPostResponse)(DASHBOARD_EDIT_URI, this.currentDashboard);
      this.notification.success('Settings saved');
    } catch (err) {
      this.notification.error(err.message);
    } finally {
      this.isSaving = false;
    }
  }

  public async getSystemAnalytics() {
    try {
      const res = await this.acceo.get(AnalyticsSystemGetResponse)(`${URI}`);
      this.currentModel = res;
      this.currentUserDashboards = res.dashboards.filter(d => d.view === 'user');
      this.currentAdminDashboards = res.dashboards.filter(d => d.view === 'admin');
      this.dashboards = _.cloneDeep(res.dashboards);
      this.userDashboards = _.uniqBy(this.currentUserDashboards.concat(this.currentUserDashboards), 'guid');
      this.adminDashboards = _.uniqBy(this.currentAdminDashboards.concat(this.currentAdminDashboards), 'guid');
    } catch (err) {
      log.error(err);
      this.notification.error(err);
    }
  }

  public didReorderChangeList() {
    const userIsSameOrder = this.userDashboards.every((obj1, index) => {
      const obj2 = this.currentUserDashboards[index];
      return obj1.guid === obj2.guid;
    });
    const adminIsSameOrder = this.adminDashboards.every((obj1, index) => {
      const obj2 = this.currentAdminDashboards[index];
      return obj1.guid === obj2.guid;
    });
    this.disableSaveOrder = userIsSameOrder && adminIsSameOrder;
  }

  public reorder() {
    this.userDashboards = _.uniqBy(this.currentUserDashboards.concat(this.currentUserDashboards), 'guid');
    this.adminDashboards = _.uniqBy(this.currentAdminDashboards.concat(this.currentAdminDashboards), 'guid');
    this.didReorderChangeList();
  }
}
