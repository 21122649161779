import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';

import {IsNumber, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class GoogleAdManager {
    /*
     * Computed Properties
     */

    @computedFrom('creatingProfiles')
    get createProfilesBtnState() {
        return this.creatingProfiles ? 'thinking' : '';
    }

    @computedFrom('creatingLiveStreamEvent')
    get createLiveStreamEventBtnState() {
        return this.creatingLiveStreamEvent ? 'thinking' : '';
    }

    /*
     * Public Properties
     */

    public createLiveStreamNetworkCode: string = '';
    public createLiveStreamChannelID: string = '';
    public liveStreamEventName: string = '';
    public adTag: string = '';
    public createProfilesChannelID: string = '';
    public createProfilesNetworkCode: string = '';
    public profileID: string = '';
    private creatingProfiles: boolean = false;
    private creatingLiveStreamEvent: boolean = false;

    constructor(private acceo: Acceo, private notification: CToastsService) {}

    /*
     * Public Methods
     */

    public async createLiveStreamEvent() {
        this.creatingLiveStreamEvent = true;

        const url: string = '/ops/create-gam-live-stream-event';
        try {
            const resp = await this.acceo.post(Response)(url, {
                adTag: this.adTag,
                channelID: this.createLiveStreamChannelID,
                name: this.liveStreamEventName,
                networkCode: this.createLiveStreamNetworkCode,
            });
            if (resp.success) {
                this.notification.success('Live Stream Event successfully created');
            } else {
                this.notification.error(`API returned an error: ${resp.error}`);
            }
        } catch (err) {
            this.notification.error(err);
        }

        this.creatingLiveStreamEvent = false;
    }

    public async createEncodingProfiles() {
        this.creatingProfiles = true;

        const url: string = '/ops/create-gam-encoding-profiles';
        try {
            const resp = await this.acceo.post(Response)(url, {
                channelID: this.createProfilesChannelID,
                networkCode: this.createProfilesNetworkCode,
                profileID: this.profileID,
            });
            if (resp.success) {
                this.notification.success('Encoding Profiels successfully creatd');
            } else {
                this.notification.error(`API returned an error: ${resp.error}`);
            }
        } catch (err) {
            this.notification.error(err);
        }

        this.creatingProfiles = false;
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsNumber()
    public success: number;

    @IsNumber()
    public error: any;
}
