import {AdServerDebugItem, Status, Transaction} from '../models/ad-server-debug-response';

export class JobStatusTipContentValueConverter {
  public toView(job: AdServerDebugItem) {
    let content: string;
    let count = 0;
    if (job.status === Status.Complete && job.hasWarnings) {
      const transaction: Transaction = job.transactions.find(t => !!t.warnings);
      if (transaction) {
        content = transaction.warnings;
      } else {
        content = 'Warning data not available.';
      }
      count = job.warningsCount - 1;
    } else if (job.status === Status.Fail) {
      const transaction: Transaction = job.transactions.find(t => !!t.failure_reason);
      // below only applies after Transactions are loaded... consider removing it altogether and just using not available in the future
      if (transaction) {
        content = `${transaction.failure_type}_${transaction.failure_reason}`;
      } else {
        content = 'Failure reason not available.';
      }
      count = job.failed_transaction_count - 1;
    }
    if (content && count > 0) {
      content += ` \nand ${count} more...`;
    }

    return content;
  }
}
