import {autoinject} from 'aurelia-framework';

import styles from './index.css';

@autoinject
export class LoadingSpinner {
  public styles;
  constructor() {
    this.styles = styles;
  }
}
