import {bindable, computedFrom, autoinject, BindingEngine, LogManager} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {Router} from 'aurelia-router';

import {BetaMode} from 'services/beta-mode';
import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';

import {AD_BREAK_WARNING_LIST_SIZE} from 'apps/cms/routes/constants';

import {LiveEventsService as LiveEventsSettings} from 'apps/cms/routes/settings/live-events/services/live-events-service';
import {LiveEventsService} from '../services/live-events';

import {BaseEventSingle} from './base';
import {LiveEventsListIndex} from '../list';

interface IOptions {
  text: string;
  value: string | number;
}

const logger = LogManager.getLogger('Live Events | Single | Event Config');

@autoinject
export class EventConfig extends BaseEventSingle {
  @bindable displayWarning: any;

  public hasLowLatency: boolean = false;
  public hasSandbox: boolean = false;
  public adBreakOptions: IOptions[];
  public lowLatencyProfiles: IOptions[];
  public publishingAutoStarts: IOptions[] = [
    {
      text: 'Off',
      value: 'Off',
    },
    {
      text: 'Test',
      value: 'Test',
    },
    {
      text: 'Live',
      value: 'Live',
    },
  ];

  public isChanged: number;

  public operators: any = [];
  public libraries: any = [];

  maxMinuets;
  constructor(
    public betaMode: BetaMode,
    router: Router,
    notification: Notification,
    liveEventsService: LiveEventsService,
    bindingEngine: BindingEngine,
    dialogService: DialogService,
    list: LiveEventsListIndex,
    sessionService: SessionService,
    private settingsService: LiveEventsSettings,
  ) {
    super(router, notification, liveEventsService, bindingEngine, dialogService, list, sessionService);
    this.hasLowLatency = betaMode.hasScope('low-latency-profiles');
    this.hasSandbox = betaMode.hasScope('live-event-sandbox');
  }

  async activate(params) {
    await super.activate(params);
    try {
      const settings = (await this.settingsService.getLiveEventsSettings()) || {
        live_event_syndication_auto_start: 'Off',
      };
      const defaultAutoStart = settings.live_event_syndication_auto_start || 'Off';
      this.publishingAutoStarts = [
        {
          text: `Off${defaultAutoStart === 'Off' ? ' (Account Default)' : ''}`,
          value: 'Off',
        },
        {
          text: `Test${defaultAutoStart === 'Test' ? ' (Account Default)' : ''}`,
          value: 'Test',
        },
        {
          text: `Live${defaultAutoStart === 'Live' ? ' (Account Default)' : ''}`,
          value: 'Live',
        },
      ];
    } catch (err) {
      this.publishingAutoStarts = [
        {
          text: 'Off',
          value: 'Off',
        },
        {
          text: 'Test',
          value: 'Test',
        },
        {
          text: 'Live',
          value: 'Live',
        },
      ];
    }

    try {
      const profiles = await this.liveEvents.getLowLatencyProfiles();
      this.lowLatencyProfiles = profiles.map(profile => ({
        text: profile.name,
        value: profile.id,
      }));
      this.lowLatencyProfiles.unshift({
        text: 'Default',
        value: this.hasLowLatency ? '' : 'Default',
      });
    } catch (err) {
      logger.error(err);
    }
  }

  async attached() {
    this.adBreakOptions = [];

    for (let i = 0; i < AD_BREAK_WARNING_LIST_SIZE; i++) {
      this.adBreakOptions.push({
        text: i === 0 ? 'None' : `${i}`,
        value: i,
      });
    }

    try {
      const operators = await this.liveEvents.getOperators();
      this.operators = operators;

      // Help with value on select2 changing in the background
      this.isChanged = Date.now();
      setTimeout(() => {
        ($('#id_event_operator') as any).select2();
      }, 0);
    } catch (err) {
      logger.error(err);
    }

    try {
      const libraries = await this.liveEvents.getLibraries();

      this.libraries = libraries;
    } catch (err) {
      logger.error(err);
    }

    try {
      const profiles = await this.liveEvents.getLowLatencyProfiles();
      this.lowLatencyProfiles = profiles.map(profile => ({
        text: profile.name,
        value: profile.id,
      }));
      this.lowLatencyProfiles.unshift({
        text: 'Default',
        value: this.hasLowLatency ? '' : 'Default',
      });
    } catch (err) {
      logger.error(err);
    }
  }

  deactivate() {
    super.deactivate();

    if (this.liveEvents.isLeavingNewRoute) {
      return;
    }

    const current = this.router.currentInstruction;
    const tabModel = this.liveEvents.cleanEvent(this.model);

    this.liveEvents.validateEvent(tabModel, current.config.name);
  }

  @computedFrom('model.playback_profile_id', 'hasLowLatency')
  get playbackProfileId() {
    let id = this.model.playback_profile_id || '';
    if (id === '' && !this.hasLowLatency) {
      id = 'Default';
    }
    return id;
  }

  set playbackProfileId(newValue) {
    if (!this.hasLowLatency) {
      return;
    }
    this.model.playback_profile_id = newValue;
  }

  @computedFrom('model.syndication_auto_start')
  get publishingAutoStart() {
    return this.model.syndication_auto_start || 'Off';
  }

  set publishingAutoStart(newValue) {
    this.model.syndication_auto_start = newValue;
  }

  @computedFrom('model.slicers')
  get slicers() {
    if (this.model.slicers == null) {
      return '';
    }
    const slicers = this.model.slicers.map(s => s.id);

    return slicers.join(', ');
  }

  set slicers(newValue) {
    if (!newValue || newValue === '') {
      this.liveEvents.model.slicers = [];
    } else {
      this.liveEvents.model.slicers = newValue
        .replace(/[\s+]/g, '')
        .split(',')
        .map(s => ({id: s}));
    }
  }

  @computedFrom('liveEvents.origEvent.slicers', 'slicers')
  get displaySlicers() {
    if (!this.liveEvents.origEvent || !this.liveEvents.origEvent.slicers.length) {
      return 'None';
    }

    return this.liveEvents.origEvent.slicers.map(s => s.id).join(', ');
  }

  @computedFrom('liveEvents.origEvent.operator', 'operators')
  get displayOperator() {
    const operatorID = this.liveEvents.origEvent.operator;
    const op =
      operatorID && operatorID !== '' && Array.isArray(this.operators) && this.operators.length
        ? this.operators.find(o => o.id === operatorID).username
        : 'None';

    return op;
  }

  @computedFrom('liveEvents.origEvent.autoexpire_hours')
  get displayAutoexpireHours() {
    return this.liveEvents.origEvent.autoexpire_hours || 'None';
  }

  @computedFrom('liveEvents.origEvent.vod_autoexpire_hours')
  get displayVodAutoexpireHours() {
    return this.liveEvents.origEvent.vod_autoexpire_hours || 'None';
  }

  @computedFrom('liveEvents.origEvent.sandbox_expire')
  get displaySandboxExpire() {
    return this.liveEvents.origEvent.sandbox_expire || 'None';
  }

  @computedFrom('model.state')
  get displaySandboxControls() {
    return this.model.state !== 'live' ? '' : 'disabled';
  }
}
