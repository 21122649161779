import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {IsArray, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class ProfileConfigure {
    /*
     * Computed Properties
     */

    @computedFrom('state')
    get searchState() {
        return this.state === 'searching' ? 'disabled' : '';
    }

    /*
     * Private Properties
     */

    public selectedOwner;
    private username: string = '';
    private owners: any[] = [];
    private selectedValue: string = '';
    public origOwners: any[] = [];
    private ownerStates: string[] = [];
    private state: string = 'idle';
    public rateCardsList: any[] = [];
    public defaultProfile;
    public defaultLiveProfile;
    public enabledCols = [
        {
            colHeadName: 'rate_card',
            colHeadValue: 'Rate Card ID',
        },
        {
            colHeadName: 'num_profiles',
            colHeadValue: 'Number of enabled Profiles',
        },
        {
            colClass: 't30',
            colHeadName: 'delete',
            colHeadValue: '',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
        },
    ];

    private enabledRows = [];
    private availableCols = [
        {
            colHeadName: 'rate_card',
            colHeadValue: 'Rate Card ID',
        },
        {
            colClass: 't350',
            colHeadName: 'num_profiles',
            colHeadValue: 'Number of available Profiles',
        },
        {
            colClass: 't75',
            colHeadName: 'select',
            colHeadValue: 'Select',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
        },
    ];

    private availableRows = [];
    private newRateCards: any[] = [];
    private disabledRows = [];

    constructor(private acceo: Acceo, private notification: CToastsService, public dialogService: DialogService) {}

    /*
     * Public Methods
     */
    public async searchUsername() {
        this.state = 'searchingOwners';
        this.selectedValue = '';
        if (!this.username) {
            this.username = '';
            this.state = 'idle';
            this.notification.error('No email/username entered.');
        } else {
            const url: string = '/ops/owners';
            const params = $.param({username: this.username, account_state: 'active'});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.owners = resp.owners;
                if (this.owners.length !== 0) {
                    this.origOwners = _.cloneDeep(resp.owners);
                    for (let i = 0; i < this.owners.length; i += 1) {
                        this.ownerStates[i] = 'clean';
                    }
                    this.state = this.owners.length ? 'hasData' : 'not-found';
                    this.selectedAccount(0);
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching owner for ${this.username}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedAccount(ownerIndex) {
        this.selectedValue = this.owners[ownerIndex].username;
        this.selectedOwner = this.owners[ownerIndex];
        this.defaultProfile = this.selectedOwner.default_profile.id;
        this.defaultLiveProfile = this.selectedOwner.default_live_profile.id;
        this.state = 'accountDataSelected';
        this.getAllRateCardsList();
        this.getOwnerRateCardsList();
    }

    public async getOwnerRateCardsList() {
        const url: string = '/ops/get-owner-rate-cards';
        const params = $.param({username: this.selectedValue});
        try {
            const resp = await this.acceo.get(Response)([url, params].join('?'));
            this.enabledRows = resp.rate_cards;
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }

    public async getAllRateCardsList() {
        const url: string = '/ops/get-all-rate-cards';
        try {
            const resp = await this.acceo.get(Response)(url);
            this.availableRows = resp.all_rate_cards;
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }

    public async specialRateCardModal(output, modalValue, activeOwner) {
        if (output) {
            this.newRateCards = modalValue.availableRows;
            this.saveChanges(activeOwner);
        }
    }

    public async openRateCardModal() {
        const modalValue = {
            availableCols: this.availableCols,
            availableRows: this.availableRows,
        };
        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        inputValues: modalValue,
                    },
                    bodyViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/encoding-profiles/rate-card',
                    ),
                    footerEnable: true,
                    footerViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/encoding-profiles/rate-card-footer',
                    ),
                    size: 'large',
                    title: 'Create new slicer/profile mapping',
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed()
            .then(response => {
                this.specialRateCardModal(response.output, modalValue, this.selectedValue);
            });
    }

    public async deleteRateCard(activeOwner, rows) {
        this.notification.info(`Attempting to delete mappings for "${this.selectedOwner.username}".`);

        for (let i = 0; i < rows.length; i += 1) {
            if (rows[i].delete === true) {
                this.disabledRows.push(this.enabledRows[i]);
            }
        }

        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }

        const url = '/ops/delete-owner-profile';
        try {
            const resp = await this.acceo.post(Response)(url, {
                disabled_rate_cards: this.disabledRows,
                username: activeOwner,
            });
            if (resp.save_response) {
                this.notification.success(`Rate Cards deleted for "${this.selectedValue}"`);
            } else {
                this.notification.error(`API returned an error: ${resp.save_response}`);
            }
        } catch (err) {
            this.notification.error(`Error updating owner record: ${err}`);
        }
        await this.updateSearchUsername(index);
    }

    public async updateSearchUsername(ownerIndex) {
        const url: string = '/ops/owners';
        const params = $.param({username: this.username, account_state: 'active'});
        try {
            const resp = await this.acceo.get(Response)([url, params].join('?'));
            this.owners = resp.owners;
            if (this.owners) {
                await this.getOwnerRateCardsList();
                await this.selectedAccount(ownerIndex);
                this.state = this.owners.length ? 'accountDataSelected' : 'not-found';
            }
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }

    public async saveChanges(activeOwner) {
        this.notification.info(`Attempting to save "${this.selectedOwner.username}".`);

        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }

        if (this.newRateCards) {
            const url = '/ops/save-owner-profile';
            try {
                const resp = await this.acceo.post(Response)(url, {
                    new_rate_cards: this.newRateCards,
                    username: activeOwner,
                });
                if (resp.save_response) {
                    this.notification.success(`New Rate Cards enabled for "${this.selectedValue}"`);
                } else {
                    this.notification.error(`API returned an error: ${resp.save_response}`);
                }
            } catch (err) {
                this.notification.error(`Error updating owner record: ${err}`);
            }
            await this.updateSearchUsername(index);
        } else {
            this.notification.info('No modified data for owner record. Not updating.');
        }
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsArray()
    public owners: any[] = [];

    @IsArray()
    public rate_cards: any[] = [];

    @IsArray()
    public all_rate_cards: any[] = [];

    @IsArray()
    public save_response: any[] = [];
}
