import {SharedNav} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterConfiguration} from 'aurelia-router';

export enum RouteName {
  SlicerList = 'slicerOwnedList',
  SlicerSingle = 'slicerOwnedSingle',
}

@autoinject()
export class SlicersIndex {
  public router: Router;
  public routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: RouteName.SlicerList,
      nav: false,
      route: '',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: RouteName.SlicerSingle,
      nav: false,
      route: ':id',
    },
  ];

  constructor(public sharedNav: SharedNav) {}

  public attached() {
    this.sharedNav.setNextText('On-Prem Slicers');
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }
}
