import {CTableCol, CTipActions, CToastsService} from '@bindable-ui/bindable';
import {IngestPoint} from 'apps/cms/routes/slicers/models/slicer';
import {SlicerService} from 'apps/cms/routes/slicers/services/slicer-service';
import {autoinject, computedFrom} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {AcceoErrorToast} from 'resources/acceo-error-toast';
import {HyperionPolling} from 'services/hyperion-polling';
import {LynkDialogServiceModel} from 'resources/services/dialog';
import {MSOHostingLocationService} from '../../../services/mso-hostingloc-service';
import {MSOStreamProtocolService} from '../../../services/mso-streamproto-service';
import {ConfigMessages} from '../models';
import {Config, ConfigErrors} from './models';
import {IngestPointSelectionTable} from './table';
import {IngestPointTableRow} from './table-models';

const STATUS_REFRESH_INTERVAL = 8000;

@autoinject
export class IngestPointSelection {
  public config = new Config();
  public model: LynkDialogServiceModel;
  public table = new IngestPointSelectionTable();
  public tipCreate: any;
  public tipCreateActions: CTipActions = {
    // launches Create Ingest Point dialog
    onShow: () => {
      this.config.input.description = undefined;
      this.config.input.name = undefined;
      this.config.errors = new ConfigErrors();
    },
  };

  protected acceoErrorToast: AcceoErrorToast;
  protected searchKeys = [
    'created',
    'description',
    'domain_name',
    'endpoint_id',
    'name',
    'status',
  ];

  protected searchQuery: string;
  protected hostingLocationService: MSOHostingLocationService;
  protected slicerService: SlicerService;
  protected streamProtocolService: MSOStreamProtocolService;
  private locations: any;
  public pollTracker: HyperionPolling;

  constructor(protected acceo: Acceo, protected notification: CToastsService) {
    this.config.state.isLoading = true;
    this.acceoErrorToast = new AcceoErrorToast(notification);
    this.hostingLocationService = new MSOHostingLocationService(acceo);
    this.slicerService = new SlicerService(acceo);
    this.streamProtocolService = new MSOStreamProtocolService(acceo);
    this.table.actions.sortColumn = (_col: CTableCol, _reverse: boolean) => {
      const sortOrder = _reverse ? 'desc' : 'asc';
      this.table.rows = _.orderBy(this.table.rows, _col.colHeadName, sortOrder);
    };
    this.pollTracker = new HyperionPolling({
      ms: STATUS_REFRESH_INTERVAL,
      promiseFn: async () => {
        this.table.rows = await this.requestRead();
        this.table.rows = _.orderBy(this.table.rows, 'created', 'desc');
      },
    });
  }

  public activate(model: LynkDialogServiceModel) {
    this.model = model;
    this.config = model.settings.sharedModel.input.ingestPoint;
    this.config.state.isLoading = true; // silence no ingest points message on load
    this.table.selected = model.settings.sharedModel.input.ingestPoint.input.selection;
    model.settings.sharedModel.input.ingestPoint.input.selectionReset = this.table.removeSelectedRadio.bind(this.table);

    this.config.input.search = '';
  }

  @computedFrom('table.rows', 'config.input.search')
  get filteredRows(): IngestPointTableRow[] {
    return this.table.rows.filter(row => {
      let foundText = true;
      if (this.config.input.search && this.config.input.search.length) {
        this.searchQuery = this.config.input.search;
        foundText = this.searchText(row, this.searchKeys);
      }
      return foundText;
    });
  }

  public async actionCreate() {
    const valid = this.validate();
    if (!valid) return;
    this.config.state.isProcessing = true;
    try {
      await this.slicerService.createIngestPoints(this.transformRequestInput());
      this.config.state.isProcessing = false;
      this.notification.success(ConfigMessages.INGEST_POINT_CREATE_SUCCESS);
      this.tipCreate.hide();
      this.load();
    } catch (error) {
      this.config.state.isProcessing = false;
      this.acceoErrorToast.show(error);
    }
  }

  protected async load() {
    this.config.state.error = '';
    this.config.state.isLoading = true;
    try {
      this.table.rows = await this.requestRead();
      this.table.rows = _.orderBy(this.table.rows, 'created', 'desc');
    } catch (error) {
      if (error.status_code === 404) {
        this.config.state.error = `${error.details} (${error.status_code})`;
      }
      this.config.state.error = error.details.length ? error.details[0] : error.message || error;
    }
    this.config.state.isLoading = false;
  }

  public async attached() {
    const locations = await this.hostingLocationService.get();
    const protocols = await this.streamProtocolService.get();
    this.config.options.hostingLocation = locations.items.map(item => ({
      text: item.name,
      value: item.id,
    }));
    this.locations = locations.items.map(item => ({
      name: item.name,
      value: item.id,
    }));
    this.config.options.streamProtocol = protocols.items.map(item => ({
      text: item.name,
      value: item.id,
    }));
    this.load();
    this.pollTracker.start();
  }

  protected async requestRead(): Promise<IngestPointTableRow[]> {
    return Promise.all([this.slicerService.getIngestPoints()]).then(items =>
      items[0].map((item, index) => {
        const result = item as IngestPointTableRow;
        result.order = index;
        result.selectedState = result.status.toUpperCase() === 'IN_USE' ? 'disabled' : '';
        result.connectionSourceUrl = item.connection.sourceUrl;
        result.connectionSecureSourceUrl = item.connection.secureSourceUrl;
        result.connectionStreamKey = item.connection.streamKey;
        result.portsPort = item.ports.port;
        result.portsPortFec2 = item.ports.port_fec2;
        result.portsPortFec4 = item.ports.port_fec4;
        result.streamTypeName = item.stream_type.name;
        const matchingHostingLocation = this.locations.find(l => item.zone.id === l.value);
        if (matchingHostingLocation) result.zoneRegion = matchingHostingLocation.name;
        return result;
      }),
    );
  }

  protected searchText(row, queryKeys) {
    let foundMatch = false;
    if (!this.searchQuery) {
      return true;
    }
    const query = this.searchQuery.replace(/\+/g, '\\+');
    const regex = new RegExp(query, 'i');
    queryKeys.forEach(key => {
      if (typeof row[key] === 'string' && !foundMatch) {
        foundMatch = !!row[key].toLowerCase().match(regex);
      }
    });
    return foundMatch;
  }

  protected transformRequestInput() {
    const request = new IngestPoint();
    request.description = this.config.input.description;
    request.name = this.config.input.name;
    request.stream_type = {
      id: this.config.input.streamProtocol,
      name: undefined,
    };
    request.zone = {
      environment: undefined,
      id: this.config.input.hostingLocation,
      location: undefined,
      name: undefined,
      region: undefined,
      type: undefined,
    };
    return request;
  }

  protected validate() {
    this.config.errors = new ConfigErrors();
    let valid = true;
    // manually validate request because IngestPoint isn't using the class validator decorators
    if (!this.config.input.hostingLocation) {
      this.config.errors.hostingLocation = ConfigMessages.INGEST_POINT_INVALID_REGION;
      valid = false;
    }
    if (!this.config.input.name) {
      this.config.errors.name = ConfigMessages.INGEST_POINT_INVALID_NAME;
      valid = false;
    }
    if (!this.config.input.streamProtocol) {
      this.config.errors.streamProtocol = ConfigMessages.INGEST_POINT_INVALID_PROTOCOL;
      valid = false;
    }
    return valid;
  }

  public detached() {
    this.pollTracker.stop();
  }

  protected showHideColumns(col) {
    col.selected = !col.selected;
    this.table.trackSelectedCol(col);
  }
}
