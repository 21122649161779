import {CToastsService, LocalStorageHelper} from '@bindable-ui/bindable';
import {Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable, BindingEngine, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import {HyperionPolling} from 'services/hyperion-polling';
import {SessionService} from 'services/session';

import {ContentType, IContentFilterParams, ILibrarySelect, LibraryType} from '../models/models';
import {ContentService} from '../services/content';
import {LibraryService} from '../services/library';

const LOCAL_STORAGE_KEY = 'content-default-view';
const DEFAULT_LIBRARY_NAME = 'My Content';

interface TableColumn {
  label: string;
  isSortable: boolean;
  key: string;
  sortDirection: number;
  width?: number;
}

@autoinject()
export class ContentList {
  public addLibraryTip: any;
  public assetTypes = [
    {label: 'All', id: `assetType_${ContentType.All}`},
    {label: 'Clip', id: `assetType_${ContentType.Clip}`},
    {label: 'Event', id: `assetType_${ContentType.Event}`},
    {label: 'File', id: `assetType_${ContentType.File}`},
    {label: 'Live', id: `assetType_${ContentType.Live}`},
  ];

  public contentType: string = `assetType_${ContentType.All}`;
  public currentAssetSort: string = '-created';
  public deleteTip: any;
  public filterTip: any;
  public searchInput: any;
  public isProcessing: boolean = false;
  public libraryId: string;
  public addToLibraryDialog: any;
  public librarySelect: ILibrarySelect = {
    actions: {
      onScrollBottom: () => {
        const {meta} = this.librarySelect;
        const {hasMore = false} = meta;

        if (hasMore) {
          this.loadLibraryOptions(true);
        }
      },
      onSearch: searchQuery => {
        this.librarySelect.searchQuery = searchQuery;
        this.librarySelect.params.page = 1;
        this.loadLibraryOptions();
      },
    },
    meta: {},
    options: [],
    params: {
      order: 'desc',
      page: 1,
    },
    searchQuery: '',
  };

  public tableColumns: TableColumn[] = [
    {
      label: 'Asset Name',
      isSortable: true,
      key: 'title',
      sortDirection: 0,
    },
    {
      label: 'Status',
      isSortable: true,
      key: 'status_desc',
      sortDirection: 0,
      width: 150,
    },
    {
      label: 'Source',
      isSortable: true,
      key: 'source',
      sortDirection: 0,
      width: 150,
    },
    {
      label: 'Duration',
      isSortable: true,
      key: 'duration',
      sortDirection: 0,
      width: 120,
    },
    {
      label: 'Added',
      isSortable: true,
      key: 'created',
      sortDirection: 1,
      width: 200,
    },
  ];

  public selectedLibrary: string;
  public subscription: Subscription = null;
  public viewStyle: string = LocalStorageHelper.loadOrDefault(LOCAL_STORAGE_KEY, 'list');

  public pollTracker: HyperionPolling;
  public pollInterval: number = 8 * 1000;
  @bindable public searchText: string = '';
  public searchPopoverDisabled: boolean = true;
  public searchPopoverOpen: boolean = false;
  public searchTips: object[] = [];

  /* Computed Properties */
  @computedFrom('content.selectedContent.length')
  get assetPluralized() {
    const selectedContent = this.content.selectedContent || [];
    return `Asset${selectedContent.length === 1 ? '' : 's'}`;
  }

  @computedFrom('content.searchQueryApplied')
  get searchTags(): string[] | null {
    const {searchQueryApplied} = this.content;

    if (!searchQueryApplied) {
      return null;
    }
    return searchQueryApplied.split(',').map(tag => tag.trim());
  }

  @computedFrom('content.contentType')
  get filteringBy(): string {
    if (this.content.contentType && this.content.contentType !== 'all') {
      const filterText = this.content.contentType;
      return filterText;
    }

    return '';
  }

  @computedFrom('libraryService.currentLibraries')
  get library() {
    const currentLibraries = this.libraryService.currentLibraries || [];

    if (currentLibraries.length > 0) {
      return currentLibraries[0];
    }
    return null;
  }

  @computedFrom('libraryId', 'library.desc')
  get libraryName(): string {
    if (this.libraryId && this.library) {
      return this.library.desc;
    }

    return DEFAULT_LIBRARY_NAME;
  }

  @computedFrom('librarySelect.options.length', 'libraries.owner')
  get librarySelectMap() {
    const {options = []} = this.librarySelect;
    return options.map(library => ({
      text: library.desc,
      value: library.id,
    }));
  }

  @computedFrom('libraryId', 'library', 'library.library_type')
  get showTileDrag() {
    if (this.libraryId && this.library && this.library.library_type === LibraryType.SHARED) {
      return false;
    }

    return true;
  }

  @computedFrom('content.meta.total', 'content.meta.maxResults')
  get totalResultsText() {
    const {maxResults, total} = this.content.meta;
    if (total > maxResults) {
      return `${maxResults}+`;
    }

    return `${total}`;
  }

  constructor(
    private content: ContentService,
    private libraryService: LibraryService,
    private router: Router,
    private notification: CToastsService,
    private sessionService: SessionService,
    private bindingEngine: BindingEngine,
  ) {
    this.pollTracker = new HyperionPolling({
      callbackFn: res => this.content.processPollUpdate(res),
      getParams: () => this.content.params,
      ms: this.pollInterval,
      // eslint-disable-next-line consistent-return
      promiseFn: paramsArg => {
        if (!document.hidden) {
          return this.content.pollAssets(paramsArg);
        }
      },
      useAfter: true,
    });
  }

  /*
   * Aurelia Hooks
   */

  public async activate(params) {
    const {id, link} = params;

    if (this.content.contentType) {
      this.contentType = `assetType_${this.content.contentType}`;
    }

    if (this.content.params && this.content.params.order) {
      this.currentAssetSort = this.content.params.order;
    }

    this.libraryService.activeId = id;
    this.content.libraryId = id;
    this.libraryId = id;

    if (link !== undefined) {
      this.content.searchQuery = link.substr('encoding_beam_'.length);
    }

    await this.init();
  }

  public attached() {
    this.subscription = this.bindingEngine
      .propertyObserver(this, 'contentType')
      .subscribe(() => this.contentTypeChanged());

    this.libraryService.setNavActive();
  }

  private resetSort(col = null) {
    if (col) {
      col.sortDirection = 0;
    } else {
      this.tableColumns.forEach(column => {
        column.sortDirection = 0;
      });
    }
    this.tableColumns.find(c => c.key === 'created').sortDirection = 1;
    this.currentAssetSort = '-created';
  }

  public async onSortEvent(event: any, col: TableColumn) {
    const {key, sortDirection} = event.target;
    this.currentAssetSort = `${sortDirection < 0 ? '' : '-'}${key}`;
    const res = await this.content.getContent({
      library: this.libraryId,
      order: this.currentAssetSort,
    });

    this.tableColumns.forEach(column => {
      column.sortDirection = 0;
    });

    col.sortDirection = sortDirection;
    if (res === null) {
      this.resetSort(col);
      event.target.sortDirection = 0;
    }
  }

  public detached() {
    this.pollTracker.stop();
    this.pollTracker = null;

    if (this.subscription) {
      this.subscription.dispose();
    }

    this.content.savedSearchQuery = this.content.searchQuery;
    this.content.resetSearch();
  }

  /*
   * Public Methods
   */

  public async addToLibrary() {
    this.isProcessing = true;

    const promises = [];

    const {selectedContent = []} = this.content;

    if (selectedContent.length === 0) {
      return;
    }

    this.content.selectedContent.forEach(id => {
      const sAsset = this.content.assets.find(aa => aa.id === id);
      promises.push(this.libraryService.addAssetToLibrary(this.selectedLibrary, sAsset));
    });

    await Promise.all(promises);

    this.isProcessing = false;

    this.hideAddToLibraryDialog();
  }

  public contentTypeChanged() {
    if (this.filterTip) {
      this.filterTip.hide();
    }
    this.content.contentType = this.contentType.replace('assetType_', '') as ContentType;

    this.loadAssets();
  }

  public updateFilter(item) {
    this.contentType = item.value;
    this.content.contentType = item.value.replace('assetType_', '') as ContentType;

    this.loadAssets();
  }

  public clearFilter() {
    this.contentType = 'assetType_all';
    this.content.contentType = 'all' as ContentType;

    this.loadAssets();
  }

  public async showAddToLibraryDialog() {
    await this.loadLibraryOptions();

    if (this.addToLibraryDialog) {
      this.addToLibraryDialog.show();
    }
  }

  public hideAddToLibraryDialog() {
    this.selectedLibrary = null;

    if (this.addToLibraryDialog) {
      this.addToLibraryDialog.hide();
    }
  }

  public goToSingle(assetId) {
    this.router.navigateToRoute('single', {id: assetId, libraryId: this.libraryId});
  }

  /*
  // eslint-disable-next-line class-methods-use-this
  public getDragOptions(asset: SimpleAsset): IDragOptions {
    return {
      dragData: () => ({id: asset.id, type: 'asset'}),
      thumbnailSrc: () => asset.poster_url || asset.default_poster_url,
    };
  }
  */

  public async removeAssets() {
    const {selectedContent} = this.content;

    if (selectedContent.length > 0) {
      if (await this.libraryService.removeAssetsFromLibrary(this.libraryId, selectedContent)) {
        this.content.removeSelectedContent();

        if (this.deleteTip) {
          this.deleteTip.hide();
        }
      }
    }
  }

  public toggleView() {
    if (this.viewStyle === 'grid') {
      this.viewStyle = 'list';
    } else {
      this.viewStyle = 'grid';
    }

    LocalStorageHelper.save(LOCAL_STORAGE_KEY, this.viewStyle);
  }

  public async unsubscribe() {
    if (!this.libraryId) {
      return;
    }

    const {sessionInfo} = this.sessionService;

    try {
      await this.libraryService.removeUserFromLibrary(this.libraryId, [sessionInfo.ownerID]);

      this.libraryService.deleteLibraryFromNav(this.libraryId);

      this.router.navigate('/content');
      this.notification.success('Unsubscribed');
    } catch (err) {
      this.notification.error(`Unsubscribe failed: ${err.message}`);
    }
  }

  public searchTextChanged(newVal) {
    if (this.content.searchVersion < 3) {
      return;
    }
    let query = newVal;

    if (query.includes(',')) {
      const parts = query.split(',');
      query = parts[parts.length - 1].trim();
      this.clearSearchPopover();
    }
    if (!query || query.includes(':')) {
      this.clearSearchPopover();
    } else {
      const operators = [
        {
          field: '',
          postText: 'in any search field',
          operator: '@',
        },
        {
          field: 'Name: ',
          postText: 'in the Asset Name',
          operator: 'Name:@',
        },
        {
          field: 'ID: ',
          postText: 'in the GUID',
          operator: 'ID:@',
        },
        {
          field: 'External_ID: ',
          postText: 'in the External ID',
          operator: 'External_ID:@',
        },
        {
          field: 'Meta: ',
          postText: 'in any Metadata Values',
          operator: 'Meta:@',
        },
        {
          field: 'Meta: ',
          preText: 'Find Metadata Values with',
          postText: 'as the Metadata Key',
          operator: 'Meta:@=',
        },
      ];
      this.searchTips = [];
      operators.forEach(o => {
        this.searchTips.push({
          field: o.field,
          preText: o.preText || 'Find',
          postText: o.postText,
          searchText: query,
          operator: o.operator.replace('@', query),
        });
      });
      this.searchPopoverOpen = true;
      this.searchPopoverDisabled = false;
    }
  }

  public searchReplaceTip(operator) {
    const replaceQueryText: string = operator;
    const current: string = this.searchText;
    if (current.includes(',')) {
      const parts: string[] = current.split(',');
      let newValue: string = '';
      for (let i = 0; i < parts.length - 1; i++) {
        newValue += `${parts[i].trim()}, `;
      }
      this.searchText = newValue + replaceQueryText;
    } else {
      this.searchText = replaceQueryText;
    }
    /* istanbul ignore if */
    if (this.searchInput) {
      this.searchInput.focus();
    }
    this.clearSearchPopover();
  }

  public clearSearchPopover() {
    this.searchTips = [];
    this.searchPopoverDisabled = true;
    this.searchPopoverOpen = false;
  }

  public resetSearch() {
    if (this.content.searchQueryApplied) {
      this.searchText = '';
      this.content.resetSearch();
      this.content.search('');
      this.resetSort();
    }
  }

  // This method is used to remove a search tag from the search text and update the search results.
  public removeSearchTag(event: MouseEvent): void {
    // Get the tag from the clicked element's text content and remove any whitespace
    const tag: string = (event.target as HTMLElement).textContent.trim();
    // Create a new array of search tags excluding the one that was clicked
    const newSearchTags: string[] = this.searchTags.filter((t: string) => t !== tag);
    // Set the new search text by joining the remaining tags with commas
    this.searchText = newSearchTags.join(', ');
    // Call the search method with the new search text to update the search results
    this.content.search(this.searchText);

    if (!this.searchText.length) {
      this.resetSort();
    }
  }

  public search(query) {
    this.searchPopoverOpen = false;
    this.currentAssetSort = '-created';
    this.content.search(query);
  }

  public static getSearchHighlightPhrases(query) {
    if (!query) return [];
    const queries: string[] = query.split(',');
    let highlightPhrases: string[] = [];
    queries.forEach(q => {
      const queryParts: string[] = q.split(':');
      let operand: string = queryParts.pop().trim();
      let operator: string = queryParts.pop();
      if (operator) {
        operator = operator.trim().toLowerCase();
        if (
          ![
            'name',
            'desc',
            'title',
          ].includes(operator)
        ) {
          return;
        }
      }

      // # Check for modifiers
      // find phrases
      const phrases: string[] = operand.match(/[-+]?"[^"]+"/g);
      if (phrases) {
        phrases.forEach(p => {
          operand = operand.replace(p, '');
          if (p.startsWith('-')) {
            return;
          }
          const phrase = p.replace(/^\+?"/, '').replace(/"$/, '');
          // escape special characters so it will match bindable-ui highlight
          highlightPhrases.push(_.escape(phrase));
        });
      }
      // split words
      const words: string[] = operand.split(' ');
      words.forEach(w => {
        if (w) {
          // remove negated
          if (w.startsWith('-')) {
            return;
          }
          // remove modifiers
          const word: string = w.replace(/^\+/, '').replace(/^\*/, '').replace(/\*$/, '');
          // remove any non alphabetic or number characters and split by letters and numbers
          const regex = /([a-zA-Z]+|[0-9]+)/g;
          const matches = word.match(regex);
          if (matches) {
            highlightPhrases = highlightPhrases.concat(matches);
          }
        }
      });
    });
    // remove duplicates
    highlightPhrases = highlightPhrases.filter((hp, idx) => highlightPhrases.indexOf(hp) === idx);

    // sort by length descending - this is important so that longer phrases are highlighted first
    highlightPhrases.sort((a, b) => b.length - a.length);

    return highlightPhrases;
  }

  /*
   * Private Methods
   */
  private async init() {
    try {
      if (this.libraryId) {
        await this.libraryService.getLibrary([this.libraryId]);
      }

      this.loadAssets();
    } catch (err) {
      this.notification.error('An error occurred while attempting to load the library.');
    }
  }

  private async loadAssets() {
    const params: IContentFilterParams = {};

    if (this.currentAssetSort) {
      params.order = this.currentAssetSort;
    }

    this.content.selectedContent = [];

    if (this.content.searchQuery) {
      await this.content.search(this.content.searchQuery);
    } else {
      if (this.libraryId) {
        params.library = this.libraryId;
      }
      await this.content.getContent(params);
    }

    if (this.pollTracker) {
      this.pollTracker.start();
    }
  }

  private async loadLibraryOptions(loadMore: boolean = false) {
    const {options, searchQuery, params: queryParams} = this.librarySelect;
    const {libraries, meta, params} = await this.libraryService.getLibraries(
      options,
      queryParams,
      searchQuery,
      loadMore,
    );

    this.librarySelect.options = libraries;
    this.librarySelect.params = params;
    this.librarySelect.meta = meta;
  }

  public onSelectAsset(selected, asset) {
    asset.selected = selected;
    this.content.trackSelectedAsset(asset);
  }
}
