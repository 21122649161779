import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {IsArray, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class ProfileAssign {
    /*
     * Computed Properties
     */

    @computedFrom('state')
    get searchState() {
        return this.state === 'searching' ? 'disabled' : '';
    }

    /*
     * Private Properties
     */

    public selectedOwner;
    private username: string = '';
    private owners: any[] = [];
    private selectedValue: string = '';
    private origOwners: any[] = [];
    private ownerStates: string[] = [];
    private state: string = 'idle';
    private profilesList: any[] = [];
    private defaultProfile;
    private defaultLiveProfile;
    public mappingCols = [
        {
            colHeadName: 'slicer',
            colHeadValue: 'Slicer',
        },
        {
            colHeadName: 'profile',
            colHeadValue: 'Profile',
        },
        {
            colClass: 't30',
            colHeadName: 'delete',
            colHeadValue: '',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
        },
    ];

    private mappingRows = [];
    private mappingProfile = '';
    private mappingSlicerIdList = '';
    private saveState = '';
    public demo_expires = false;
    private removeDemo = false;
    private demo_numdays = '';

    constructor(private acceo: Acceo, private notification: CToastsService, public dialogService: DialogService) {}

    /*
     * Public Methods
     */
    public async searchUsername() {
        this.state = 'searchingOwners';
        this.selectedValue = '';
        if (!this.username) {
            this.username = '';
            this.state = 'idle';
            this.notification.error('No email/username entered.');
        } else {
            const url: string = '/ops/owners';
            const params = $.param({username: this.username, account_state: 'active'});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.owners = resp.owners;
                if (this.owners.length !== 0) {
                    this.origOwners = _.cloneDeep(resp.owners);
                    for (let i = 0; i < this.owners.length; i += 1) {
                        this.ownerStates[i] = 'clean';
                    }
                    this.state = this.owners.length ? 'hasData' : 'not-found';
                    this.selectedAccount(0);
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching owner for ${this.username}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedAccount(ownerIndex) {
        this.selectedValue = this.owners[ownerIndex].username;
        this.selectedOwner = this.owners[ownerIndex];
        this.defaultProfile = this.selectedOwner.default_profile.id;
        this.defaultLiveProfile = this.selectedOwner.default_live_profile.id;
        this.state = 'accountDataSelected';
        this.mappingRows = this.selectedOwner.live_profiles;
        this.demo_expires = this.selectedOwner.demo_expires;
        this.removeDemo = false;
        this.getProfilesList();
    }

    public async getProfilesList() {
        const url: string = '/ops/profiles-list';
        const params = $.param({username: this.selectedValue});
        try {
            const resp = await this.acceo.get(Response)([url, params].join('?'));
            this.profilesList = resp.profiles;
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }

    public async specialMappingModal(output, modalValue, activeOwner) {
        if (output) {
            this.mappingProfile = modalValue.mappingProfile;
            this.mappingSlicerIdList = modalValue.slicerIdList.trim().split(/\s*,\s*/);
            for (const value of this.mappingSlicerIdList) {
                this.mappingRows.push({slicer: value, profile: this.mappingProfile});
            }
            this.saveChanges(activeOwner);
        }
    }

    public async openMappingModal() {
        this.getProfilesList();
        const modalValue = {
            mappingProfile: '',
            profilesList: this.profilesList,
            slicerIdList: '',
        };
        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        inputValues: modalValue,
                    },
                    bodyViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/encoding-profiles/mapping',
                    ),
                    footerEnable: true,
                    footerViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/encoding-profiles/footer',
                    ),
                    size: 'auto',
                    title: 'Create new slicer/profile mapping',
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed()
            .then(response => {
                this.specialMappingModal(response.output, modalValue, this.selectedValue);
            });
    }

    public async deleteMapping(activeOwner, rows) {
        for (let i = 0; i < rows.length; i += 1) {
            if (rows[i].delete === true) {
                this.mappingRows.splice(i, 1);
            }
        }

        this.saveState = 'delete';
        await this.saveChanges(activeOwner, this.saveState);
    }

    public async updateSearchUsername(ownerIndex) {
        const url: string = '/ops/owners';
        const params = $.param({username: this.username, account_state: 'active'});
        try {
            const resp = await this.acceo.get(Response)([url, params].join('?'));
            this.owners = resp.owners;
            if (this.owners) {
                await this.selectedAccount(ownerIndex);
                this.state = this.owners.length ? 'accountDataSelected' : 'not-found';
            }
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }

    public async saveChanges(activeOwner, saveState = 'save') {
        if (saveState === 'delete') {
            this.notification.info(`Attempting to delete mappings for "${this.selectedOwner.username}".`);
        } else {
            this.notification.info(`Attempting to save "${this.selectedOwner.username}".`);
        }
        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }

        this.owners[index].default_profile = this.defaultProfile;
        this.owners[index].default_live_profile = this.defaultLiveProfile;
        this.owners[index].live_profiles = this.mappingRows;
        this.owners[index].demo_numdays = this.demo_numdays;
        if (this.removeDemo) {
            this.owners[index].demo_numdays = 'remove me';
        }

        const owner: any = this.owners[index];
        const originalOwner: any = this.origOwners[index];
        if (_.isEqual(owner, originalOwner)) {
            this.ownerStates[index] = 'clean';
        } else {
            this.ownerStates[index] = 'dirty';
        }

        if (this.ownerStates[index] === 'dirty') {
            this.origOwners[index] = this.owners[index];
            const url = `/ops/owners/${this.origOwners[index].id}`;
            try {
                await this.acceo.post()(url, this.origOwners[index]);
                this.notification.success('Successfully updated owner record.');
            } catch (err) {
                this.notification.error(`Error updating owner record. ${err}`);
            }

            await this.updateSearchUsername(index);
        } else {
            this.notification.info('No modified data for owner record. Not updating.');
        }
    }

    public async cancelChanges(activeOwner) {
        this.notification.info(`Cancelling changes to "${this.selectedOwner.username}".`);
        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }

        await this.updateSearchUsername(index);
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsArray()
    public owners: any[] = [];

    @IsArray()
    public profiles: any[] = [];
}
