import {autoinject, BindingEngine, containerless} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {Router} from 'aurelia-router';

import {authorizationConstants} from 'services/authorization';
import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';

import {SyndicationPlatformType, SyndicationScheduleContentType} from 'services/models/syndication';
import {PublishController} from 'shared/syndication/publish-controller';

import {LiveEventsService} from '../services/live-events';

import {BaseEventSingle} from './base';
import {LiveEventsListIndex} from '../list';

@containerless
@authorizationConstants
@autoinject
export class Publish extends BaseEventSingle {
  public targetSearchText: string = '';
  public removeConfModal;

  constructor(
    public publishController: PublishController,
    router: Router,
    notification: Notification,
    liveEventsService: LiveEventsService,
    bindingEngine: BindingEngine,
    dialogService: DialogService,
    list: LiveEventsListIndex,
    sessionService: SessionService,
  ) {
    super(router, notification, liveEventsService, bindingEngine, dialogService, list, sessionService);
  }

  public async activate(params) {
    await super.activate(params);

    this.publishController.init(
      SyndicationScheduleContentType.LIVE_EVENT,
      this.eventId,
      this.model && this.model.require_studio_drm ? this.model.require_studio_drm : false,
      this.model && this.model.desc ? this.model.desc : '',
    );
  }

  public deactivate() {
    this.publishController.deactivate();
    super.deactivate();
  }

  public async delete() {
    await this.publishController.deleteSelectedTargets();
    this.removeConfModal.hide();
  }

  public targetSearch() {
    this.publishController.searchText = this.targetSearchText;
  }

  get filteredTargets() {
    // Exclude Wurl targets
    return this.publishController.remainingTargets.filter(t => t.platform !== SyndicationPlatformType.WURL);
  }
}
