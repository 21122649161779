// tslint:disable max-line-length
import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {
  MSOSlicerLogResponseGet,
  MSOSlicerRequestPatch,
  MSOSlicerRequestPost,
  MSOSlicerResponse,
  MSOSlicerResponseGet,
  MSOSlicerResponseGetByOwner,
  MSOSlicerResponsePost,
} from '../models/msoslicer';

@autoinject
export class MSOSlicerService {
  private url: string = '/api/v4/mso/slicer';

  constructor(private acceo: Acceo) {}

  public delete(id: string) {
    return this.acceo.delete(MSOSlicerResponse)(`${this.url}/${id}?force_delete=false`);
  }

  public get() {
    return this.acceo.get(MSOSlicerResponseGetByOwner)(`${this.url}`);
  }

  public getById(id: string) {
    /* FOR LOCALHOST TESTING ONLY! DO NOT COMMIT THE FOLLOWING LINE UNCOMMENTED */
    // return this.getByIdMock('rist');
    return this.acceo.get(MSOSlicerResponseGet)(`${this.url}/${id}`);
  }

  public patch(id: string, body: MSOSlicerRequestPatch) {
    return this.acceo.patch(MSOSlicerResponse)(`${this.url}/${id}`, body);
  }

  public post(body: MSOSlicerRequestPost) {
    return this.acceo.post(MSOSlicerResponsePost)(this.url, body);
  }

  public getSlicerLog(id: string): Promise<any> {
    return this.acceo.get(MSOSlicerLogResponseGet)(`/api/v4/managed-slicer/slicers/${id}/log`);
  }
}
