import {EventAggregator} from 'aurelia-event-aggregator';
import {autoinject} from 'aurelia-framework';
import {MetadataSchemaEvent} from '.';

@autoinject()
export class KeyDeleteConfirm {
    public tcDeleteKey;

    constructor(public eventAggregator: EventAggregator) {}

    public deleteKey(itemType: string) {
        this.tcDeleteKey.hide();
        this.eventAggregator.publish(MetadataSchemaEvent.KEY_DELETED, parseInt(itemType, 10));
    }
}
