import {computedFrom} from 'aurelia-framework';

import {copyToClipboard} from '@bindable-ui/bindable';

import {BaseEventSingle} from './base';

export class Playback extends BaseEventSingle {
  width = 640;

  height = 480;

  isAdding = false;

  isExpiring = false;

  generatingEmbed = false;

  testPlayer = this.resetTestPlayer();

  editTestPlayer = this.resetTestPlayer();

  editPlayerIndex = -1;

  testPlayersCopy = [];

  @computedFrom('width', 'height', 'model.embed_player_url')
  get embedCode() {
    if (!this.model.embed_player_url) {
      return false;
    }
    // eslint-disable-next-line max-len
    return `<iframe style="border:none" allow="encrypted-media" width="${this.width}" height="${this.height}" src="${this.model.embed_player_url}"></iframe>`;
  }

  @computedFrom('width', 'height', 'model.embed_player_url')
  get embedHtml5Code() {
    if (!this.model.embed_player_url) {
      return false;
    }
    return `<iframe style="border:none" allow="encrypted-media" width="${this.width}" height="${
      this.height
    }" src="${this.model.embed_player_url.replace('player', 'player5')}"></iframe>`;
  }

  @computedFrom('liveEvents.origEvent.embed_domains')
  get displayEmbedDomains() {
    return this.liveEvents.origEvent.embedDomains || 'None';
  }

  @computedFrom('model.test_player_url')
  get hlsUrl() {
    if (this.model != null && this.model.test_player_url) {
      const parts = this.model.test_player_url.split('/');
      parts.splice(3, 2);
      if (this.session.isStudioDRMEnabled && this.model.require_studio_drm) {
        parts.push('event', `${this.model.id}.m3u8?rmt=fps`);
      } else {
        parts.push('event', `${this.model.id}.m3u8`);
      }
      return parts.join('/');
    }
    return null;
  }

  @computedFrom('model.test_player_url')
  get dashUrl() {
    if (this.model != null && this.model.test_player_url) {
      const parts = this.model.test_player_url.split('/');
      parts.splice(3, 2);
      parts.push('event', `${this.model.id}.mpd`);
      return parts.join('/');
    }
    return null;
  }

  @computedFrom('model.test_player_url')
  get llUrl() {
    if (this.model != null && this.model.test_player_url) {
      const parts = this.model.test_player_url.split('/');
      parts.splice(3, 2);
      if (this.session.isStudioDRMEnabled && this.model.require_studio_drm) {
        return null;
      }
      parts.push('llevent', `${this.model.id}.m3u8`);

      return parts.join('/');
    }
    return null;
  }

  editExpireCallbacks = {
    /**
     * Updates model with newValue.
     */
    onChange: timestamp => {
      if (timestamp === '') {
        this.editTestPlayer.expire = null;
      } else {
        this.editTestPlayer.expire = Number(timestamp);
      }
    },
  };

  newExpireCallbacks = {
    /**
     * Updates model with newValue.
     */
    onChange: timestamp => {
      if (timestamp === '') {
        this.testPlayer.expire = null;
      } else {
        this.testPlayer.expire = Number(timestamp);
      }
    },
  };

  openEditModal(index) {
    // Stop event polling so we don't get conflicts
    this.liveEvents.stopSinglePoll();

    this.editPlayerIndex = index;
    this.editTestPlayer = _.cloneDeep(this.model.test_players[index]);

    this.editTestPlayerDialog.show();
    // this.smallModal.open();
  }

  saveTestPlayer() {
    if (!this.editTestPlayer.desc) {
      this.notification.error('Test Player must have a name');
      return;
    }
    if (this.editTestPlayer.desc !== this.model.test_players[this.editPlayerIndex].desc) {
      if (_.find(this.model.test_players, {desc: this.editTestPlayer.desc})) {
        this.notification.error('Test Player must have a unique name');
        return;
      }
    }

    const modelClone = _.cloneDeep(this.model);

    this.liveEvents.fields.test_players.isDirty = true;

    modelClone.test_players[this.editPlayerIndex] = _.cloneDeep(this.editTestPlayer);

    super.saveEvent(modelClone).then(() => {
      this.closeModal();
    });
  }

  closeModal() {
    this.editTestPlayerDialog.hide();
    // this.smallModal.close();
    this.liveEvents.startSinglePoll();
    this.editTestPlayer = this.resetTestPlayer();
    this.liveEvents.fields.test_players.isDirty = false;
  }

  resetTestPlayer() {
    return {
      desc: '',
      expire: null,
      whitelist: false,
      params: '',
    };
  }

  addTestPlayer() {
    if (this.isAdding) {
      return;
    }

    if (_.find(this.model.test_players, {desc: this.testPlayer.desc})) {
      this.notification.error('Test Player must have a unique name');
      return;
    }

    this.isAdding = true;
    this.liveEvents
      .addTestPlayer(this.testPlayer)
      .then(() => {
        this.testPlayer = this.resetTestPlayer();
        this.notification.success('Test Player added successfully.');
      })
      .finally(() => {
        this.isAdding = false;
      });
  }

  deleteTestPlayer(tp) {
    tp.deleted = !tp.deleted;
    this.liveEvents.fields.delete_test_players.isDirty = this.model.test_players.some(t => t.deleted);
  }

  expireEmbedCode() {
    if (!this.isExpiring) {
      this.isExpiring = true;
      this.liveEvents
        .embedCode('expire')
        .catch(() => {})
        .finally(() => {
          this.isExpiring = false;
          // this.tcExpire.hide();
        });
    }
  }

  generateEmbedCode() {
    if (!this.generatingEmbed) {
      this.generatingEmbed = true;
      this.liveEvents
        .embedCode('generate')
        .catch(() => {})
        .finally(() => {
          this.generatingEmbed = false;
        });
    }
  }

  copy(text) {
    copyToClipboard(text, this.notification);
  }

  copyEmbed(html) {
    copyToClipboard(html)
      .then(() => {
        this.notification.success('Embed code copied to clipboard.');
      })
      .catch(() => {
        this.notification.error('Failed to copy embed code to clipboard.');
      });
  }

  saveEvent() {
    if (this.isSaving) {
      return;
    }

    const evtConfig = this.liveEvents.cleanEvent(this.model);
    const tabName = this.liveEvents.isNew ? 'liveEventEmbed' : false;

    if (!this.liveEvents.validateEvent(evtConfig, tabName)) {
      this.isSaving = false;
      delete this.model.delete_test_players;
      return;
    }

    // get ids of test players to delete
    const deleteTestPlayers = [];
    this.model.test_players.forEach(tp => {
      if (tp.deleted) {
        deleteTestPlayers.push(tp.id);
      }
    });
    evtConfig.delete_test_players = deleteTestPlayers;
    // hack isDirty
    this.liveEvents.fields.delete_test_players.isDirty = true;

    super.saveEvent(evtConfig).then(() => {
      this.liveEvents.fields.delete_test_players.isDirty = false;
    });
  }
}
