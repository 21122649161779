import {bindable, computedFrom} from 'aurelia-framework';

import {sortBy} from 'utils/sort-tools';

import {IdsUser} from '../../models/ids-user';

export class IdsUserInfo {
  @bindable()
  public model: IdsUser;

  @bindable()
  public target: 'Source' | 'Target';

  @bindable()
  public restricted: boolean = false;

  @computedFrom('model.metadata')
  public get metadata() {
    if (!this.model) {
      return [];
    }

    const {metadata = {}} = this.model;

    const keys: string[] = Object.keys(metadata);

    return keys.map(key => ({key, value: metadata[key]}));
  }

  @computedFrom('model.identities')
  public get identities() {
    if (!this.model) {
      return [];
    }

    const {identities = []} = this.model;

    return sortBy(identities, 'source');
  }

  @computedFrom('model.tenants')
  public get tenants() {
    if (!this.model) {
      return [];
    }

    const {tenants = []} = this.model;

    return sortBy(tenants, 'name');
  }
}
