import {bindable, containerless} from 'aurelia-framework';

function getState(group) {
  const label = group.active?.status?.stateLabel;

  switch (label) {
    case 'Slicing':
      return 'slicing';
    case 'Ads':
      return 'ad';
    case 'Replacing content':
      return 'override';
    case 'Blackout':
      return 'blackout';
    case 'Shutting Down':
      return 'warning';
    case 'Shutdown':
      return 'neutral';
    default:
      return null;
  }
}

@containerless()
export class FailoverGroupRow {
  @bindable()
  public group;

  @bindable()
  public selected: boolean;

  public groupFailoverTime = null;
  public groupHostname = null;
  public groupState = null;
  public groupLabel = null;

  protected groupChanged(group) {
    this.groupState = getState(group);
    this.groupLabel = group.active?.status?.stateLabel || null;
    this.groupHostname = group.active?.hostname || null;
    this.groupFailoverTime = this.group.failover_time || null;
  }
}
