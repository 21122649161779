import {bindable, computedFrom} from 'aurelia-framework';
import {SyndicationPlatformType} from '../../../services/models/syndication';
import {SessionService} from '../../../services/session';

export class StatusColumn {
  @bindable public platform: SyndicationPlatformType;
  @bindable public status: string;
  @bindable public session: SessionService;
  @bindable public resetScheduleAction: () => void;
  public style: any;

  public clear() {
    this.resetScheduleAction();
  }

  @computedFrom('status')
  get showClear(): boolean {
    return this.status === 'error';
  }

  @computedFrom('platform', 'session')
  get disableClear(): boolean {
    return this.platform === SyndicationPlatformType.WURL && !this.session.hasFastAccess;
  }

  @computedFrom('status')
  get showPulse(): boolean {
    return this.status === 'active' || this.status === 'scheduled';
  }

  @computedFrom('status')
  get getStatus(): string {
    return this.status ? this.status.charAt(0).toUpperCase() + this.status.slice(1) : '';
  }
}
