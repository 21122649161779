import {DialogController} from 'aurelia-dialog';
import {Config as ConfigBase, ConfigInput as ConfigInputBase} from '../models';
import {ITemplateListTableRow} from '../table-models';

export class Model {
    public controller: DialogController;
    public shared: Config;
}

export class Config extends ConfigBase {
    public input = new ConfigInput();
}

class ConfigInput extends ConfigInputBase {
    public selection = {} as ITemplateListTableRow;
}
