import * as styles from './b-time-entry.css';

export class BTimeEntryPopover {
  public styles = styles;
  public item = null;

  public activate(model) {
    this.item = model.data;
  }
}
