import {autoinject} from 'aurelia-framework';
import {HttpClient} from 'aurelia-fetch-client';
import {CmsHttpClient} from 'services/cms-http-client';
import {Acceo} from 'services/acceo';
import {Overlay, UploadOverlay} from 'apps/cms/routes/settings/clipping/models/clipping-config';
import {IsArray} from 'class-validator';
import {CToastsService} from '@bindable-ui/bindable';
import {GraphicsUploaderFile} from '../models/models';

const GRAPHICS_URL = '/api/v4/graphics';

export class Response {
  @IsArray()
  public items: Overlay[];
}

@autoinject()
export class GraphicsService {
  public hasUploadNameError = false;
  public hasUploadFileTypeError = false;
  public hasUploadFileSizeError = false;
  public viewOverlay: Overlay;
  public overlayModal;
  public uploadTip;
  public uploadOverlay: UploadOverlay = new UploadOverlay();
  public configChanged = new Date();
  public disableUploadAdd = false;
  public isUploading = false;
  public descInput;
  public isLoading = false;
  public httpClient: HttpClient;
  public queuedFiles: GraphicsUploaderFile[] = [];

  public html5OverlayUrl: string = null;
  public html5OverlayName: string = null;

  constructor(httpClient: CmsHttpClient, private acceo: Acceo, private notification: CToastsService) {
    this.httpClient = httpClient.httpClient;
  }

  public async uploadFiles(files: GraphicsUploaderFile[] = this.queuedFiles) {
    try {
      this.isUploading = true;
      const uploadPromises = files.map(async file => {
        const overlayName = file.name || file.file.name;
        const overlayFile = file.file;

        if (overlayFile && overlayFile.size <= 4000000 && overlayName && overlayName.length > 0) {
          let progress = 0;
          const interval = setInterval(() => {
            progress += 1;
            file.progress = progress;
            if (progress >= 99) {
              clearInterval(interval);
            }
          }, 1);
          await this.upload(overlayName, overlayFile, null);
          file.progress = 100;
          file.isUploaded = true;
        } else if (!overlayFile) {
          file.error = 'File is empty or not supported.';
        } else if (overlayFile.size > 4000000) {
          file.error = 'File is greater than 4 MB.';
        } else if (!overlayName || overlayName.length === 0) {
          file.error = 'File name is required.';
        }
      });

      await Promise.all(uploadPromises);
    } catch (err) {
      this.notification.error(err.message, 'Upload files Failed');
      throw new Error(err);
    } finally {
      this.isUploading = false;
    }
  }

  public async uploadHtml() {
    try {
      this.isUploading = true;
      await this.upload(this.html5OverlayName || this.html5OverlayUrl, null, this.html5OverlayUrl);
      this.notification.success('Upload successful');
    } catch (err) {
      this.notification.error(err.message, 'Upload Html5 graphic failed');
      throw new Error(err);
    } finally {
      this.html5OverlayUrl = null;
      this.html5OverlayName = null;
      this.isUploading = false;
    }
  }

  public async getOverlayList(query: string = ''): Promise<Overlay[]> {
    try {
      this.isLoading = true;
      const response = await this.acceo.get(Response)(GRAPHICS_URL);
      this.isLoading = false;
      return query
        ? response.items.filter(value => value.name.toUpperCase().includes(query.toUpperCase()))
        : response.items;
    } catch {
      this.isLoading = false;
      return [];
    }
  }

  public async deleteOverlay(item: Overlay): Promise<boolean> {
    try {
      await this.acceo.delete(Response)(`${GRAPHICS_URL}/${item.id}`);
      return true;
    } catch {
      return false;
    }
  }

  public async updateOverlay(item: Overlay): Promise<boolean> {
    try {
      await this.acceo.patch(Response)(`${GRAPHICS_URL}/${item.id}`, {
        name: item.name,
      });
      this.notification.success('Updated successful');
      return true;
    } catch (err) {
      this.notification.error(err.message, 'Update overlay failed');
      return false;
    }
  }

  private async upload(overlayName: string, overlayFile: any, overlayUrl: string): Promise<any> {
    const API_URL = '/settings/upload-overlay/';
    const API_ERROR = 'Error uploading overlay file';
    const formData = new FormData();

    if (overlayFile) {
      formData.append('overlayType', 'png');
      formData.append('overlayFile', overlayFile);
    } else if (overlayUrl) {
      formData.append('overlayType', 'html5');
      formData.append('overlayUrl', overlayUrl);
    } else {
      throw new Error('No PNG file or Html5 url provided');
    }

    formData.append('overlayName', overlayName);

    const response = await this.httpClient.fetch(API_URL, {
      body: formData,
      headers: {
        Accept: 'application/json',
      },
      method: 'post',
    });

    if (!response.ok) {
      throw new Error(API_ERROR);
    }

    const result = await response.json();

    if ('error' in result && result.error) {
      throw new Error(result.msg);
    }
  }
}
