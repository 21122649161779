import {KJUR} from 'jsrsasign';

import {WorkspaceKey} from '../models/workspace-key';
import {WorkspaceKeyRevision} from '../models/workspace-key-revision';

export interface IXAuthHeaders {
  alg: string;
  kid: string;
  typ: string;
}

export interface IXAuthPayload {
  exp?: number;
  iat?: number;
  iss?: string;
  scp: string[];
  sub: string;
  net?: boolean;
  nbf?: number;
}

export interface IXAuthDetails {
  headers: IXAuthHeaders;
  payload: IXAuthPayload;
}

export interface IXAuthDetailsString {
  headers: string;
  payload: string;
}

export const PEM_REGEX = /^(?:-+BEGIN PRIVATE KEY-+)(.*)(?:-+END PRIVATE KEY-+$)/s;

export function asJsonString(value) {
  if (!value) {
    return '';
  }

  const str = JSON.stringify(value, undefined, 4);

  return str;
}

export class XAuthUtils {
  /**
   * Attempts to use atob to decode b64 string. If that fails, falls back to Buffer
   * @param b64String
   */
  static atob(b64String: string): string {
    try {
      return atob(b64String);
    } catch (err) {
      return Buffer.from(b64String, 'base64').toString('binary');
    }
  }

  static generateToken(b64Key: string, headers: IXAuthHeaders, payload: IXAuthPayload): string {
    const secret = XAuthUtils.atob(b64Key);

    if (!secret) {
      throw new Error('Failed to decode secret');
    }

    if (!PEM_REGEX.test(secret)) {
      throw new Error('Secret must be base 64 encoded PEM');
    }

    const {net = false} = payload;
    const iat = new Date().valueOf() / 1000;

    payload.iat = iat;

    if (!net) {
      payload.exp = iat + 300;
    } else {
      delete payload.exp;
    }

    const _headers = JSON.stringify(headers);
    const _payload = JSON.stringify(payload);

    return KJUR.jws.JWS.sign(headers.alg, _headers, _payload, secret);
  }

  /**
   * Decode b64 encoded headers or payload from JWT
   * @param base64Url
   */
  static decodePart(base64Url: string) {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  /**
   * Decodes JWT into headers and payload JSON objects
   * @param token
   */
  static parseToken(token: string): IXAuthDetails {
    const [
      _headers,
      _payload,
    ] = token.split('.');

    return {
      headers: XAuthUtils.decodePart(_headers),
      payload: XAuthUtils.decodePart(_payload),
    };
  }

  /**
   * Returns JWT headers and payload from key and revision data
   * @param key
   * @param revision
   */
  static revisionDetails(key: WorkspaceKey, revision: WorkspaceKeyRevision): IXAuthDetails {
    const {pk} = revision;

    const headers: IXAuthHeaders = {
      alg: pk.algorithm,
      kid: key.id,
      typ: 'JWT',
    };

    const payload: IXAuthPayload = {
      sub: key.workspace_id,
      scp: revision.scope,
    };

    if (revision.domain) {
      payload.iss = revision.domain;
    }

    return {
      headers,
      payload,
    };
  }

  static revisionDetailsString(key: WorkspaceKey, revision: WorkspaceKeyRevision): IXAuthDetailsString {
    const {headers, payload} = XAuthUtils.revisionDetails(key, revision);

    return {
      headers: asJsonString(headers),
      payload: asJsonString(payload),
    };
  }
}
