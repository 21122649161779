import {LocalStorageHelper} from '@bindable-ui/bindable';
import {deepCopy} from 'resources/deep-copy';

const localStorageKey = 'last-calendar-view';

export class CalendarService {
    constructor() {
        this.sharedParams = null;
    }

    getLastViewed() {
        return LocalStorageHelper.loadOrDefault(localStorageKey, 'calendarDay');
    }

    setLastViewed(calType) {
        LocalStorageHelper.save(localStorageKey, calType);
    }

    saveFilter(params) {
        if (!params) {
            this.sharedParams = null;
            return;
        }

        const paramsCopy = deepCopy(params);

        // Save everything but the start and stop timestamps
        delete paramsCopy.r_start;
        delete paramsCopy.r_stop;
        delete paramsCopy.end_r_start;
        delete paramsCopy.end_r_stop;
        delete paramsCopy.end_r_start;
        delete paramsCopy.end_r_stop;

        this.sharedParams = paramsCopy;
    }
}
