import {DialogService} from 'aurelia-dialog';
import {autoinject, BindingEngine, computedFrom, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';

import {CToastsService, dirtyCheckPrompt, LocalStorageHelper} from '@bindable-ui/bindable';
import {Field, FieldMap} from 'resources/field/index';
import {authorizationConstants} from 'services/authorization';
import {LibraryService} from 'services/library-service';
import {SessionService} from 'services/session';

import metaUpdate from 'resources/meta-update';
import testPlayerUpdate from 'resources/test-player-update';
import {BetaMode} from 'services/beta-mode';
import {RuleService} from '../../rules/services/rules';

import {BlackoutService} from '../services/blackouts';
import {LiveChannelsService} from '../services/live-channels';

import {ScheduleEntryService} from '../services/scheduled-entry';
import {ChannelModel} from './models/channel-model';

const log = LogManager.getLogger('base-channel-service');
const ADD_BODY = 'add-body';

@authorizationConstants
@dirtyCheckPrompt
@autoinject()
export class BaseChannelSingle {
  // Each tab must override this to enable dirty check.
  get tabDirty() {
    return false;
  }

  @computedFrom('tabDirty', 'isSaving')
  get saveButtonState() {
    if (this.isSaving) {
      return 'thinking';
    }

    if (!this.tabDirty) {
      return 'disabled';
    }

    return '';
  }

  public channelId: string = null;
  public isSaving: boolean = false;
  /** Cloned and editable version of the current channel */
  public model: ChannelModel = null;
  /** Fields for validation/dirty checking */
  public fields: FieldMap = {
    ad_slate: new Field('ad_slate'),
    blackout_slate: new Field('blackout_slate'),
    desc: new Field('desc', {
      rules: [
        {
          message: 'You must enter a Channel title.',
          rule: model => {
            const value = model.desc;
            return !!value;
          },
        },
      ],
    }),
    embed_domains: new Field('embed_domains'),
    embed_html5_player_url: new Field('embed_html5_player_url'),
    external_id: new Field('external_id'),
    failover_monitoring: new Field('failover_monitoring'),
    meta: new Field('meta'),
    missing_content_slate: new Field('missing_content_slate'),
    require_drm: new Field('require_drm'),
    require_studio_drm: new Field('require_studio_drm'),
    slicer_id: new Field('slicer_id'),
    test_players: new Field('test_players', {
      rules: [
        {
          message: 'Test player must have a description.',
          rule: model => {
            let isValid = true;
            _.forEach(model.test_players, tp => {
              if (!tp.desc) {
                isValid = false;
              }
            });

            return isValid;
          },
        },
      ],
    }),
    playback_profile_id: new Field('playback_profile_id'),
  };

  /** Assigned on extended class */
  public tabFields: string[] = [];

  public channelPollInterval: any = null;
  public hasLowLatency: boolean = false;
  public lowLatencyProfiles: any[] = [];

  constructor(
    public bindingEngine: BindingEngine,
    public liveChannels: LiveChannelsService,
    public blackoutService: BlackoutService,
    public ruleService: RuleService,
    public notification: CToastsService,
    public dialogService: DialogService,
    public session: SessionService,
    public libraryService: LibraryService,
    public router: Router,
    public scheduleEntryService: ScheduleEntryService,
    public betaMode: BetaMode,
  ) {}

  public async activate(params) {
    LocalStorageHelper.save(ADD_BODY, null);
    this.channelId = params.id;

    this.blackoutService.setChannelId(this.channelId);
    await this.getChannel();
    await this.getChannels();
    this.liveChannels.setNavActive(this.channelId);
    this.activateView();
  }

  public attached() {
    this.liveChannels.updateSubNavItems();
  }

  public deactivate() {
    if (this.channelPollInterval) {
      clearInterval(this.channelPollInterval);
    }
  }

  public isDirty() {
    return this.tabDirty;
  }

  /**
   * Get the channel data
   */
  public async getChannel() {
    if (!this.liveChannels.currentModel || this.liveChannels.currentModel.id !== this.channelId) {
      this.liveChannels.isChannelLoading = true;

      try {
        await this.liveChannels.getSingleChannel(this.channelId);
        if (this.liveChannels.channelNotFound) {
          this.model = null;
        } else {
          this.model = _.cloneDeep(this.liveChannels.currentModel);
          this.pollChannel();
        }
      } catch (e) {
        log.error(e);
      } finally {
        this.liveChannels.isChannelLoading = false;
      }
    } else if (this.liveChannels.currentModel.id === this.channelId) {
      this.model = _.cloneDeep(this.liveChannels.currentModel);
      this.pollChannel();
      if (!this.model.playback_profile_id) {
        this.model.playback_profile_id = '';
      }
    }
  }

  public async getPlayURL() {
    try {
      await this.liveChannels.getPlayURL(this.channelId);
    } catch (e) {
      log.error(e);
    }
  }

  /**
   * Lazy-load channels in the background if it's a fresh page load
   */
  public async getChannels() {
    if (_.get(this.liveChannels, 'channels.length')) {
      return;
    }

    try {
      await this.liveChannels.getChannels({});
    } catch (e) {
      log.error(e);
    }
  }

  /**
   * Save the channel
   */
  public async save() {
    if (!this.tabDirty) {
      return;
    }

    const dirtyFields = this.getDirtyFields();

    if (!this.validateTab(dirtyFields)) {
      log.error('Failed validation.');
      this.notification.error('Error updating channel. Please make sure all fields are valid.');
      throw new Error('Validation Error'); // Bubble up to dirty checking modal
    }

    const updateModel = {
      id: this.model.id,
    };

    _.forEach(dirtyFields, field => {
      updateModel[field] = this.model[field];
    });

    this.isSaving = true;

    try {
      const res = await this.liveChannels.saveChannel(updateModel);
      this.liveChannels.currentModel = res;
      this.model = _.cloneDeep(res);
      this.liveChannels.updateNavTitle();
    } catch (e) {
      if (e.message) {
        this.notification.error(e.message);
        if (e.message.toLowerCase().includes('slicer id')) {
          this.fields.slicer_id.errors = ['Must be alphanumeric value.'];
        }
      } else {
        this.notification.error('Error updating channel.');
      }
      throw e; // Bubble up to dirty checking modal
    } finally {
      this.isSaving = false;
    }
  }

  protected activateView() {
    // Override in the view
  }

  /**
   * Override the channel model except dirty fields
   */
  private assignDirtyFields() {
    const updatedChannel = _.cloneDeep(this.liveChannels.currentModel);
    // Do it this way so the table inputs won't lose focus
    _.forOwn(this.model, (_value, key) => {
      if (this.fields[key] && !this.fields[key].isDirty) {
        if (key === 'meta') {
          this.model.meta = metaUpdate(this.model.meta, updatedChannel.meta);
        } else if (key === 'test_players') {
          this.model.test_players = testPlayerUpdate(this.model.test_players, updatedChannel.test_players);
        } else {
          this.model[key] = updatedChannel[key];
        }
      }
    });
  }

  /**
   * Get a list of dirty fields
   */
  private getDirtyFields(): string[] {
    const arr: string[] = [];

    _.forEach(this.tabFields, field => {
      if (this.fields[field].isDirty) {
        arr.push(field);
      }
    });

    return arr;
  }

  /**
   * Handle the channel data update without overriding a dirty field
   */
  private async gracefulUpdate() {
    try {
      await this.liveChannels.getSingleChannel(this.channelId, true);
      this.assignDirtyFields();
      this.liveChannels.channelSinglePoll = Date.now();
    } catch (e) {
      log.error(e);
    }
  }

  /**
   * Setup the polling to keep the channel fresh
   */
  private pollChannel() {
    if (this.channelPollInterval) {
      clearInterval(this.channelPollInterval);
    }
    this.channelPollInterval = setInterval(() => {
      if (document.hidden) {
        return;
      }

      this.gracefulUpdate();
    }, 5 * 1000);
  }

  /** Validate the data on the tab doesn't have any errors
   *
   * @param dirtyFields list of dirty fields
   */
  private validateTab(dirtyFields): boolean {
    let isValid = true;

    _.forEach(dirtyFields, dirtyField => {
      const field = this.fields[dirtyField];

      // Reset field errors.
      field.errors = [];

      // Validate against field rules.
      field.rules.forEach(r => {
        let didPass = true;

        // Handle RegExp rules.
        if (_.isRegExp(r.rule)) {
          didPass = r.rule.test(this.model);
        }

        // Handle Function rules.
        if (_.isFunction(r.rule)) {
          didPass = r.rule(this.model);
        }

        if (!didPass) {
          field.errors.push(r.message);
          isValid = false;
        }
      });
    });
    return isValid;
  }
}
