import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {ValidationError} from 'class-validator';

import {dirtyCheckPrompt} from 'decorators';

import {CToastsService} from '@bindable-ui/bindable';
import {PrebidConfig} from './models/prebid-config';
import {PrebidService} from './services/prebid-service';
import {PrebidUtil} from './services/prebid-util';

const log = LogManager.getLogger('prebid-configuration');

export const PREBID_CONFIG_EVENT = {
    ConfigCreated: 'PrebidConfigEvent:ConfigCreated',
    EnableNewConfigCreate: 'PrebidConfigEvent:EnableNewConfigCreate',
};

@dirtyCheckPrompt
@autoinject()
export class PrebidServer {
    public routeName;
    public tabs = {
        details: {hasError: false, ref: {}},
        optionalParameters: {hasError: false, ref: {}},
    };

    public isLoading: boolean = false;
    public isSaving: boolean = false;
    public loadingError: boolean = false;
    public selectedTargets: any[] = [];

    public canDeactivate;
    public config: PrebidConfig;
    public configs: PrebidConfig[] = [];
    public currentConfigPristine: PrebidConfig;
    public currentConfig: PrebidConfig;
    public deleteTip;
    public isDeleting = false;
    public isFormDirty = false;
    public isNew = false;
    public pendingConfigSwitch = false;
    public validationErrors: ValidationError[];
    public callbacks;
    public subscriptions: Subscription[] = [];

    private debouncedDirtyCheck = _.debounce(this.doDirtyCheck, 100);

    constructor(
        public router: Router,
        public prebidService: PrebidService,
        public notification: CToastsService,
        public eventAggregator: EventAggregator,
    ) {
        this.callbacks = {
            change: () => {
                this.debouncedDirtyCheck();
            },
            keyup: () => {
                this.debouncedDirtyCheck();
            },
        };
    }

    public activate({}, routeConfig) {
        this.routeName = routeConfig.name || '';
    }

    public attached(): Promise<any> {
        this.loadingError = false;
        this.subscriptions.push(
            this.eventAggregator.subscribe(PREBID_CONFIG_EVENT.ConfigCreated, opt => {
                const newConfig = new PrebidConfig();
                newConfig.name = opt.name;
                this.configs = this.configs.concat([newConfig]);
                // set pristine for duplicate not for new (future enhancement)
                this.canSetCurrentConfig(newConfig, !opt.isNew).then(() => {
                    this.isNew = opt.isNew;
                });
            }),
        );

        return new Promise((resolve, reject) => {
            this.isLoading = true;
            this.prebidService
                .getPrebidConfigs()
                .then(() => {
                    this.configs = _.cloneDeep(this.prebidService.prebidConfigs);
                    if (this.configs && this.configs.length) {
                        this.setCurrentConfig(this.configs[0]);
                    }
                    resolve();
                })
                .catch(e => {
                    this.loadingError = true;
                    reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    public async setCurrentConfig(config, doSetPristine = true) {
        if (!config) {
            this.currentConfigPristine = null;
            this.currentConfig = null;
            return;
        }
        if (doSetPristine) {
            this.currentConfigPristine = _.cloneDeep(config);
        } else {
            this.currentConfigPristine = null;
        }
        this.currentConfig = _.cloneDeep(config);
        this.validationErrors = null;

        // should never need to validate in here since you can't save an invalid config
        await PrebidUtil.validate(this.currentConfig).then(info => {
            if (info.length) {
                this.validationErrors = info;
            }
        });

        this.debouncedDirtyCheck();
    }

    public isDirty(): boolean {
        if (
            !this.pendingConfigSwitch &&
            window.location.hash.match(/^#\/settings\/prebid-server(\/[a-zA-Z0-9-_]*\/?)?$/)
        ) {
            return false;
        }
        if (!this.currentConfig) return false;

        return this.doDirtyCheck();
    }

    public doDirtyCheck(): boolean {
        const currentConfigCleaned = _.cloneDeep(this.currentConfig);
        this.isFormDirty = !_.isEqual(currentConfigCleaned, this.currentConfigPristine);
        return this.isFormDirty;
    }

    public canSetCurrentConfig(cfg, doSetPristine) {
        this.pendingConfigSwitch = true;
        return new Promise((resolve, reject) => {
            this.canDeactivate()
                .then(resp => {
                    if (resp) {
                        if (this.isNew) {
                            const lastIndex = this.configs.length - 1;
                            this.configs.splice(lastIndex, 1);
                            this.eventAggregator.publish(PREBID_CONFIG_EVENT.EnableNewConfigCreate);
                            this.isNew = false;
                        }
                        this.setCurrentConfig(cfg, doSetPristine);
                    }
                    this.pendingConfigSwitch = false;
                    resolve();
                })
                .catch(e => {
                    log.error(e);
                    reject(e);
                });
        });
    }

    public save() {
        return new Promise((resolve, reject) => {
            PrebidUtil.validate(this.currentConfig)
                .then(errs => {
                    if (errs.length) {
                        this.validationErrors = errs;
                        this.notification.error('Please fix form error(s) and try again.');
                        return resolve();
                    }
                    this.isSaving = true;
                    this.prebidService
                        .savePrebidConfig(this.currentConfig)
                        .then(configs => {
                            this.eventAggregator.publish(PREBID_CONFIG_EVENT.EnableNewConfigCreate);
                            this.isNew = false;
                            this.configs = _.cloneDeep(configs);
                            const currentIndex = _.findIndex(this.configs, {id: this.currentConfig.name});
                            this.setCurrentConfig(configs[currentIndex]);
                            this.notification.success('Changes saved successfully.');
                            resolve();
                        })
                        .catch(e => {
                            this.notification.error(e.message || 'Error saving changes.');
                            log.error(e);
                            reject(e);
                        })
                        .finally(() => {
                            this.isSaving = false;
                        });
                })
                .catch(err => {
                    reject(err);
                    log.error(`Validation Error: ${err}`);
                });
        });
    }

    public delete(): Promise<any> {
        this.deleteTip.hide();
        this.isDeleting = true;
        return new Promise((resolve, reject) => {
            const deletedConfigName = this.currentConfig.name;
            this.prebidService
                .deletePrebidConfig(this.currentConfig.id)
                .then(() => {
                    let deletedIndex = -1;
                    let nextIndex = -1;
                    this.configs = this.configs.filter((c, index) => {
                        if (c.id === this.currentConfig.id) {
                            deletedIndex = index;
                            return false;
                        }
                        return true;
                    });
                    if (this.configs.length) {
                        if (deletedIndex === this.configs.length) {
                            nextIndex = deletedIndex - 1;
                        } else {
                            nextIndex = deletedIndex;
                        }
                    }
                    this.setCurrentConfig(this.configs[nextIndex]);
                    this.notification.success(`${deletedConfigName} prebid config deleted successfully.`);
                    resolve();
                })
                .catch(e => {
                    this.notification.error('Error deleting prebid config.');
                    reject(e);
                })
                .finally(() => {
                    this.isDeleting = false;
                });
        });
    }
}
