import {autoinject, LogManager, computedFrom} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';

import {IPaginationMeta} from 'interfaces/hyperion';
import {Acceo} from 'services/acceo';
import {IdsUsersResponse, IdsUser} from '../models/ids-user';

export interface IUserParams {
  email?: string;
  order?: string;
  tenant_id?: string;
  username?: string;
  page?: number;
  page_size?: number;
}

export const BASE_URL = '/api/v4/ops/identity/users';
export const DEFAULT_PER_PAGE_LIMIT = 20;
export const DEFAULT_ORDER = '-created';

@autoinject()
export class IdsUserService {
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public isProcessing: boolean = false;
  public params: IUserParams = {};
  public users: IdsUser[] = [];
  public meta: IPaginationMeta = {};

  protected logger: Logger;

  @computedFrom('params.order')
  public get order() {
    return this.params?.order || DEFAULT_ORDER;
  }

  constructor(protected acceo: Acceo) {
    this.logger = LogManager.getLogger('IDS User Service');
  }

  /**
   * Get IDS User record
   * @param id
   */
  public async getUser(id: string): Promise<IdsUser> {
    let record: IdsUser;

    this.isLoading = true;

    try {
      record = await this.acceo.get(IdsUser)(`${BASE_URL}/${id}`);
    } catch (err) {
      this.logger.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }

    return record;
  }

  /**
   * Get IDS Users
   * @param params
   * @param isLoadingMore
   */
  public async getUsers(params: IUserParams, isLoadingMore: boolean = false): Promise<void> {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }

    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (!params.order) {
      params.order = DEFAULT_ORDER;
    }

    if (!isLoadingMore) {
      this.users = [];
      this.isLoading = true;
      params.page = 1;
    } else {
      if (!this.meta.hasMore) {
        return;
      }

      this.isLoadingMore = true;
      params.page = (params.page || 1) + 1;
    }

    this.params = _.cloneDeep(params);

    try {
      const urlParams = $.param(this.params);

      const url = `${BASE_URL}?${urlParams}`;

      const resp = await this.acceo.get(IdsUsersResponse)(url);

      this.users = _.uniqBy(this.users.concat(resp.items), 'id');

      // Safety check to prevent query spamming
      const totalPages = Math.ceil(resp.total_items / params.page_size);

      this.meta.total = resp.total_items;
      this.meta.showing = this.users.length;
      this.meta.limit = params.page_size || null;
      this.meta.hasMore = params.page < totalPages;
    } catch (err) {
      this.logger.error(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  /**
   * Loads more records
   */
  public async getMore() {
    if (this.isLoading || this.isLoadingMore || !this.meta.hasMore) {
      return;
    }

    const params = _.cloneDeep(this.params);

    await this.getUsers(params, true);
  }

  public async findUser(txt: string): Promise<IdsUser> {
    let user: IdsUser = null;

    user = await this.findRecord({email: txt});

    if (!user) {
      user = await this.findRecord({username: txt});
    }

    return user;
  }

  /**
   * Merge two IDS user accounts
   * @param source Source account that is linked to an uplynk account
   * @param target Target account that is currently not linked to an uplynk account
   */
  public async mergeUsers(source: IdsUser, target: IdsUser): Promise<IdsUser> {
    try {
      this.isProcessing = true;
      const identity = source.identities.find(i => i.source === 'uplynk');

      if (!identity) {
        throw new Error('Invalid source user');
      }

      const url = `/api/v4/ops/owners/${identity.external_id}/merge`;
      const data = {
        target_id: target.id,
      };

      const user = this.acceo.post(IdsUser)(url, data);

      return user;
    } finally {
      this.isProcessing = false;
    }
  }

  private async findRecord(params: IUserParams): Promise<IdsUser> {
    const urlParams = $.param(params);
    const url = `${BASE_URL}?${urlParams}`;

    const resp = await this.acceo.get(IdsUsersResponse)(url);

    if (resp.items.length > 0) {
      return resp.items[0];
    }

    return null;
  }
}
