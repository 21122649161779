import {autoinject, inlineView} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@autoinject()
@inlineView('<template></template>')
export class AdServerDebug2 {
    constructor(public router: Router) {}

    public activate(params) {
        const paramStr = Object.keys(params).length
            ? `?${Object.keys(params)
                  .map(key => `${key}=${params[key]}`)
                  .join('&')}`
            : '';
        this.router.navigate(`/ad-server-debug${paramStr}`);
    }
}
