import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

@autoinject()
export class Playlists {
    public isLoading: boolean = false;

    public router: Router;
    public routes = [
        {
            moduleId: PLATFORM.moduleName('./list/index'),
            name: 'playlistsList',
            nav: false,
            route: '',
            title: 'Playlists List',
        },
        {
            moduleId: PLATFORM.moduleName('./single/index'),
            name: 'playlistSingle',
            nav: false,
            route: ':id',
            title: 'Playlist',
        },
    ];

    public configureRouter(config, router) {
        this.router = router;
        config.map(this.routes);
    }
}
