import {Type} from 'class-transformer';
import {IsNumber, IsOptional, IsString, IsNotEmpty} from 'class-validator';
import {PublicKey} from './public-key';

export interface IWorkspaceRevisionParams {
  domain?: string;
  invalid_after?: string;
  scope: string[];
}

export class WorkspaceKeyRevision {
  @IsString()
  @IsOptional()
  public created_by?: string;

  @IsString()
  @IsOptional()
  public deleted?: string;

  @IsString()
  @IsOptional()
  public domain?: string;

  @IsString()
  public id: string;

  @IsString()
  @IsOptional()
  public invalid_after?: string;

  @IsNumber()
  public revision: number;

  @IsString({each: true})
  public scope: string[];

  @IsNotEmpty()
  @Type(() => PublicKey)
  public pk: PublicKey;
}
