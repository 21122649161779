export class TestPlayerFooter {
  public controller: any;
  public model: any;
  public shared: any;

  public activate(model) {
    this.controller = model.controller;
    this.model = model.footer;
    this.shared = model.shared;
  }

  public saveTP() {
    let errMsg = null;

    if (this.model.updateTestPlayer) {
      errMsg = this.model.updateTestPlayer();
    }

    if (!errMsg) {
      this.controller.close();
    } else {
      this.shared.errMsg = errMsg;
    }
  }
}
