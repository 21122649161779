import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom, LogManager, observable} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

// tslint:disable-next-line: max-line-length
import {CFormAddRemoveActions, CToastsService, dirtyCheckPrompt} from '@bindable-ui/bindable';
import {Util} from 'services/util';
import {AUDIENCE_TYPE, AudienceFilterParams, DELETED_AUDIENCE, SimpleAudience} from '../models/models';
import {AudiencesService} from '../services/audiences';

const log = LogManager.getLogger('live-channels-audience-single');

@dirtyCheckPrompt
@autoinject()
export class AudienceSingle {
    @observable
    public selectedAudienceList;

    @observable
    public availableAudienceList;

    public leftActions: CFormAddRemoveActions = {
        onScrollBottom: async () => {
            await this.loadMoreAudiences();
        },
        onSearch: async searchText => {
            await this.getAvailableAudiences(searchText);
        },
    };

    public AUDIENCE_TYPE = AUDIENCE_TYPE;

    public matchTypeOptions: any[] = [
        {
            text: 'Any',
            value: 'ANY',
        },
        {
            text: 'All',
            value: 'ALL',
        },
        {
            text: 'None',
            value: 'NONE',
        },
    ];

    public audienceType: string = 'audience';
    public audienceId: string = null;
    public model: SimpleAudience = null;
    public modelPristine: SimpleAudience;
    public isSaving: boolean = false;
    public isLoading: boolean = false;
    public error: any;
    public isAudienceLoading: boolean = false;

    public searchText?: string;

    constructor(
        private liveAudiences: AudiencesService,
        private notification: CToastsService,
        public dialogService: DialogService,
        private router: Router,
    ) {}

    public async activate(params) {
        this.audienceId = params.id;
        await this.init();
        _.defer(() => this.liveAudiences.setNavActive(this.audienceId));
    }

    public backToAudiences() {
        this.router.navigate('/live-channels/audiences');
    }

    public async init() {
        await Promise.all([this.getAudience(), this.getAudiences()]);

        if (this.model && this.model.type === AUDIENCE_TYPE.MULTIPLE) {
            await this.getAvailableAudiences();
            this.initializeSelectedAudiences();
        }
        this.liveAudiences.setNavActive(this.audienceId);
    }

    public addZipCodesModal() {
        const modalValue = {
            inputText: '',
        };
        this.dialogService.open({
            model: {
                bodyModel: {
                    inputValues: modalValue,
                },
                bodyViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/audiences/single/modals/add-zip-codes',
                ),
                footerEnable: true,
                footerModel: {
                    updateCallBack: async () => await this.addZipCodes(modalValue),
                },
                footerViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/audiences/single/modals/add-footer',
                ),
                sharedModel: {
                    errMsg: '',
                },
                size: 'medium',
                title: 'Add Zip Codes',
            },
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        });
    }

    public addIpNetworksModal() {
        const modalValue = {
            inputText: '',
        };
        this.dialogService.open({
            model: {
                bodyModel: {
                    inputValues: modalValue,
                },
                bodyViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/audiences/single/modals/add-ip-networks',
                ),
                footerEnable: true,
                footerModel: {
                    updateCallBack: async () => await this.addIpNetworks(modalValue),
                },
                footerViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/audiences/single/modals/add-footer',
                ),
                sharedModel: {
                    errMsg: '',
                },
                size: 'medium',
                title: 'Add IP Networks',
            },
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        });
    }

    public addDmasModal() {
        const modalValue = {
            inputText: '',
        };
        this.dialogService.open({
            model: {
                bodyModel: {
                    inputValues: modalValue,
                },
                bodyViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/audiences/single/modals/add-dmas'),
                footerEnable: true,
                footerModel: {
                    updateCallBack: async () => await this.addDMACodes(modalValue),
                },
                footerViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/audiences/single/modals/add-footer',
                ),
                sharedModel: {
                    errMsg: '',
                },
                size: 'medium',
                title: 'Add DMAs',
            },
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        });
    }

    public addDevicesModal() {
        const modalValue = {
            inputText: '',
        };
        this.dialogService.open({
            model: {
                bodyModel: {
                    inputValues: modalValue,
                },
                bodyViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/audiences/single/modals/add-devices'),
                footerEnable: true,
                footerModel: {
                    updateCallBack: async () => await this.addDevices(modalValue),
                },
                footerViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/audiences/single/modals/add-footer',
                ),
                sharedModel: {
                    errMsg: '',
                },
                size: 'medium',
                title: 'Add Devices',
            },
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        });
    }

    public addCountryCodesModal() {
        const modalValue = {
            inputText: '',
        };
        this.dialogService.open({
            model: {
                bodyModel: {
                    inputValues: modalValue,
                },
                bodyText: 'Modal Body',
                bodyViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/audiences/single/modals/add-country-codes',
                ),
                footerEnable: true,
                footerModel: {
                    updateCallBack: async () => await this.addCountryCodes(modalValue),
                },
                footerText: 'footer',
                footerViewModel: PLATFORM.moduleName(
                    'apps/cms/routes/live-channels/audiences/single/modals/add-footer',
                ),
                sharedModel: {
                    errMsg: '',
                },
                size: 'medium',
                title: 'Add Country Codes',
            },
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
        });
    }

    public addValidatedValue(newModel: SimpleAudience, propertyName: string) {
        const newItems = newModel[propertyName];
        newItems.forEach(i => {
            const iu = typeof i === 'string' ? i.toUpperCase() : i;
            const exists = !!this.model[propertyName].find(p => {
                const pu = typeof p === 'string' ? p.toUpperCase() : p;
                return pu === iu;
            });
            if (!exists) {
                // this.model[propertyName].unshift(iu);
                // this.modelChanges[propertyName] = new Date();
                this.model[propertyName] = [...this.model[propertyName], iu];
            }
        });
    }

    public async addCountryCodes(returnValue: any) {
        const propertyName = 'country_codes';
        const inputRequired = 'At least one country code is required';
        const newModel = this.newTestOject();

        if (returnValue.inputText === '') {
            return Promise.resolve(inputRequired);
        }

        newModel.country_codes = returnValue.inputText
            .split(',')
            .map(d => d.trim())
            .filter(i => !!i);

        const hasErrors = await Util.validate(newModel);

        if (!hasErrors) {
            this.addValidatedValue(newModel, propertyName);
            return false;
        }

        return hasErrors[propertyName];
    }

    public async addDevices(returnValue: any) {
        const propertyName = 'devices';
        const inputRequired = 'At least one device is required';
        const newModel = this.newTestOject();

        if (returnValue.inputText === '') {
            return Promise.resolve(inputRequired);
        }

        newModel.devices = returnValue.inputText
            .split(',')
            .map(d => d.trim())
            .filter(i => !!i);

        const hasErrors = await Util.validate(newModel);

        if (!hasErrors) {
            this.addValidatedValue(newModel, propertyName);
            return false;
        }

        return hasErrors[propertyName];
    }

    public async addDMACodes(returnValue: any) {
        const propertyName = 'dma_codes';
        const inputRequired = 'At least one DMA code is required';
        const newModel = this.newTestOject();

        if (returnValue.inputText === '') {
            return Promise.resolve(inputRequired);
        }

        newModel.dma_codes = returnValue.inputText
            .split(',')
            .map(d => d.trim())
            .filter(i => !!i);

        const hasErrors = await Util.validate(newModel);

        if (!hasErrors) {
            this.addValidatedValue(newModel, propertyName);
            return false;
        }

        return hasErrors[propertyName];
    }

    public async addIpNetworks(returnValue: any) {
        const propertyName = 'ip_networks';
        const inputRequired = 'At least one ip address or netmask is required';
        const newModel = this.newTestOject();

        if (returnValue.inputText === '') {
            return Promise.resolve(inputRequired);
        }

        newModel.ip_networks = returnValue.inputText
            .split(',')
            .map(d => d.trim())
            .filter(i => !!i);

        const hasErrors = await Util.validate(newModel);

        if (!hasErrors) {
            this.addValidatedValue(newModel, propertyName);
            return false;
        }

        return hasErrors[propertyName];
    }

    public async addZipCodes(returnValue: any) {
        const propertyName = 'zip_codes';
        const inputRequired = 'At least one zip code is required';
        const newModel = this.newTestOject();

        if (returnValue.inputText === '') {
            return Promise.resolve(inputRequired);
        }

        newModel.zip_codes = returnValue.inputText
            .split(',')
            .map(d => d.trim())
            .filter(i => !!i);

        const hasErrors = await Util.validate(newModel);

        if (!hasErrors) {
            this.addValidatedValue(newModel, propertyName);
            return false;
        }

        return hasErrors[propertyName];
    }

    /**
     * Lazy-load audiences in the background if it's a fresh page load
     */
    public async getAudiences() {
        if (this.liveAudiences.audiences && this.liveAudiences.audiences.length) {
            return;
        }

        try {
            this.isLoading = true;
            await this.liveAudiences.getAudiences();
        } catch (e) {
            log.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    /**
     * Get the audience data
     */
    public async getAudience() {
        this.isAudienceLoading = true;

        try {
            await this.liveAudiences.getAudience(this.audienceId);
            this.model = _.cloneDeep(this.liveAudiences.currentModel);
            this.modelPristine = _.cloneDeep(this.liveAudiences.currentModel);
            // this.pollChannel();
        } catch (e) {
            log.error(e);
        } finally {
            this.isAudienceLoading = false;
        }
    }

    public async save() {
        if (!this.formDirty) {
            return;
        }

        this.error = {};
        const err = await Util.validate(this.model);
        if (err) {
            this.error = err;
            log.error('Validation Error', err);
            this.notification.error('Error updating audience. Please make sure all fields are valid.');
            throw new Error('Validation Error'); // Bubble up to dirty checking modal
        }

        this.isSaving = true;
        try {
            const res = await this.liveAudiences.saveAudience(this.model);
            const descDirty = this.model.desc !== this.modelPristine.desc;
            this.model = _.cloneDeep(res);
            this.modelPristine = _.cloneDeep(res);
            if (descDirty) {
                this.liveAudiences.setNavText(this.modelPristine.id, this.modelPristine.desc);
            }
        } catch (e) {
            if (e.message.toLowerCase().includes('external id')) {
                this.error = {
                    external_id: e.message,
                };
            }
            throw e; // Bubble up to dirty checking modal
        } finally {
            this.isSaving = false;
        }
    }

    public remove(property, items = []) {
        if (!property || !items.length) {
            return;
        }
        if (items.length === 1 && items[0] === '__all__') {
            this.model[property] = [];
        } else {
            this.model[property] = this.model[property].filter(i => !items.includes(i));
        }
    }

    @computedFrom('formDirty', 'isSaving')
    get saveButtonState() {
        if (this.isSaving) {
            return 'thinking';
        }

        if (!this.formDirty) {
            return 'disabled';
        }

        return '';
    }

    public isDirty() {
        return this.formDirty;
    }

    get formDirty() {
        return !this.isLoadingData && this.model && this.modelPristine && !_.isEqual(this.model, this.modelPristine);
    }

    public async getAvailableAudiences(searchText?) {
        const params: AudienceFilterParams = {type: AUDIENCE_TYPE.SINGLE};

        if (searchText) {
            this.searchText = searchText;
            params.search = searchText;
        } else {
            this.searchText = null;
        }

        await this.liveAudiences.getAllSingleAudiences(params);

        this.filterAudiences();
    }

    public async loadMoreAudiences() {
        const {singleMeta = {}} = this.liveAudiences;
        const {page = 1, hasMore = true} = singleMeta;

        if (!hasMore) {
            return;
        }

        const params: AudienceFilterParams = {
            page: page + 1,
            search: this.searchText,
            type: AUDIENCE_TYPE.SINGLE,
        };

        await this.liveAudiences.getAllSingleAudiences(params, true);

        this.filterAudiences();
    }

    public filterAudiences() {
        let availableAudiences = this.liveAudiences.singleAudiences;
        availableAudiences = availableAudiences.filter(r => !this.model.sub_audiences.includes(r.id));
        this.availableAudienceList = availableAudiences.map(r => ({text: r.desc, value: r.id}));
    }

    public initializeSelectedAudiences() {
        this.selectedAudienceList = _.map(this.model.sub_audiences, audienceId => {
            const retVal = _.find(this.model['@included'], {id: audienceId});
            if (retVal) {
                return {text: retVal.desc, value: retVal.id};
            }
            return {text: DELETED_AUDIENCE, value: audienceId};
        });

        this.selectedAudienceList = _.orderBy(this.selectedAudienceList, [audience => audience.text.toLowerCase()]);

        // Save the new order so the save button isn't enabled
        this.modelPristine.sub_audiences = this.selectedAudienceList.map(r => r.value);
    }

    public selectedAudienceListChanged() {
        this.model.sub_audiences = this.selectedAudienceList.map(r => r.value);
    }

    public availableAudienceListChanged() {
        _.remove(this.availableAudienceList, (audience: any) => audience.text === DELETED_AUDIENCE);
    }

    @computedFrom('isLoading', 'isAudienceLoading')
    get isLoadingData() {
        return this.isLoading || this.isAudienceLoading;
    }

    private newTestOject(): SimpleAudience {
        const newModel = new SimpleAudience();
        // we have to set a value, otherwise the validator will throw an error for testing our modals
        newModel.desc = 'test';
        return newModel;
    }
}
