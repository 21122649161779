/* eslint-disable no-shadow */

import {ISelectOption} from 'interfaces/select';
import {IKeyValue} from 'interfaces/key-value';
import {ProtocolBindingType, EncryptionType, SsoAuthType, IIntegrationNew} from './models/ids-integration';

export const BASE_DOMAIN_URL = '/api/v4/ops/sso/domains';
export const BASE_INTEGRATION_URL = '/api/v4/ops/identity/integrations';

export const HOST_NAME = window?.location?.host.indexOf('downlynk') > -1 ? 'downlynk' : 'uplynk';

export enum TargetType {
  SOURCE = 'source',
  TARGET = 'target',
}

export enum PROVIDERS {
  OKTA_IDP = 'okta_idp',
  MS_ENTRA_IDP = 'ms_entra_idp',
}

export const SSO_PROVIDERS: ISelectOption[] = [
  {text: 'Okta IdP', value: PROVIDERS.OKTA_IDP},
  {text: 'MS Entra', value: PROVIDERS.MS_ENTRA_IDP},
];

export const PROTOCOL_BINDINGS: ISelectOption[] = [
  {text: 'HTTP POST', value: ProtocolBindingType.POST},
  {text: 'HTTP Redirect', value: ProtocolBindingType.REDIRECT},
];

export const PROVIDER_DEFAULTS: IKeyValue<IIntegrationNew> = {
  [`${PROVIDERS.OKTA_IDP}`]: {
    attribute_mappings: {
      // email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email',
      first_name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
      last_name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/familyName',
    },
    certificate_name: 'okta_saml_cert',
    certificate: '',
    description: '',
    digest: EncryptionType.SHA256,
    encrypt_assertion: false,
    entity: '',
    protocol_binding: ProtocolBindingType.POST,
    redirect_uris: [
      `https://cms.${HOST_NAME}.com`,
    ],
    relay_state_uri: `https://cms.${HOST_NAME}.com`,
    sign_assertion: true,
    sign_authentication_request: false,
    sign_in_uri: '',
    sign_out_uri: '',
    sign_request: false,
    sign_response: true,
    signature: EncryptionType.SHA256,
    type: SsoAuthType.IDP,
    user_email_attribute: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email',
    user_id_attribute: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email',
  },
  [`${PROVIDERS.MS_ENTRA_IDP}`]: {
    attribute_mappings: {
      // email: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email',
      first_name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
      last_name: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/familyName',
    },
    certificate_name: 'entra_saml_cert',
    certificate: '',
    description: '',
    digest: EncryptionType.SHA256,
    encrypt_assertion: false,
    entity: '',
    protocol_binding: ProtocolBindingType.POST,
    redirect_uris: [
      `https://cms.${HOST_NAME}.com`,
    ],
    relay_state_uri: `https://cms.${HOST_NAME}.com`,
    sign_assertion: true,
    sign_authentication_request: false,
    sign_in_uri: '',
    sign_out_uri: '',
    sign_request: false,
    sign_response: true,
    signature: EncryptionType.SHA256,
    type: SsoAuthType.IDP,
    user_email_attribute: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email',
    user_id_attribute: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/email',
  },
};
