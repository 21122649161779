import {ConfigErrors as ConfigErrorsBase} from '../../models/config-errors';

class ConfigErrorsImport {
  public config?: string;
  public fileValue?: string;
  public template?: string;
}

export class ConfigErrors extends ConfigErrorsBase {
  public import = new ConfigErrorsImport();
}
