/* eslint-disable max-classes-per-file */

import {Type, Exclude} from 'class-transformer';
import {IsString, IsNumber, IsOptional, ValidateNested} from 'class-validator';

import {IdsIdentity} from './ids-identity';
import {IdsPolicy} from './ids-policy';

export class IdsTenantBase {
  @IsString()
  public id: string;

  @IsString()
  public name: string;
}

export class IdsTenant extends IdsTenantBase {
  @IsOptional()
  @IsString()
  public desc: string;

  @IsOptional()
  @IsString()
  public display_name: string;

  @IsOptional()
  @IsString()
  public external_id: string;

  @IsOptional()
  @IsString()
  public parent_tenant_id: string;

  @IsOptional()
  @IsString()
  public source: string;

  @ValidateNested()
  @Type(() => IdsIdentity)
  @Exclude({toPlainOnly: true})
  public identities: IdsIdentity[];

  @ValidateNested()
  @Type(() => IdsPolicy)
  @Exclude({toPlainOnly: true})
  public policies: IdsPolicy[];
}

export class IdsTenantsResponse {
  @ValidateNested()
  @Type(() => IdsTenant)
  public items: IdsTenant[];

  @IsNumber()
  public total_items: number;
}
