import {Config as ConfigBase} from '../../models/config';
import {ConfigErrors} from './config-errors';
import {ConfigInfo} from './config-info';
import {ConfigInput} from './config-input';
import {ConfigOptions} from './config-options';
import {ConfigState} from './config-state';

export class Config extends ConfigBase {
  public errors = new ConfigErrors();
  public info = new ConfigInfo();
  public input = new ConfigInput();
  public options = new ConfigOptions();
  public state = new ConfigState();
}
