import {CToastsService, CTableCol} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {IdsSettingsService} from '../service';

@autoinject
export class PostLogoutRedirectUri {
    public postRedirectUrl: any[] = [];
    constructor(private notification: CToastsService, public IdsSetting: IdsSettingsService) {}

    public async activate() {
        await this.getPostLogoutRedirectUri();
    }

    public urlCols: CTableCol[] = [
        {
            colHeadName: 'id',
            colHeadValue: 'Post Logout Redirect Url',
        },
    ];

    public urlRows = [];

    public async getPostLogoutRedirectUri() {
        try {
            await this.IdsSetting.getIdsSettings();
            this.postRedirectUrl = this.IdsSetting.clientSettings.post_logout_redirect_uris;
            this.urlRows = [];
            for (let i = 0; i < this.postRedirectUrl.length; i++) {
                this.urlRows.push({
                    id: this.postRedirectUrl[i],
                });
            }
        } catch (error) {
            this.notification.error(error);
        }
    }
}
