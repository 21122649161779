exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1cCj4{align-items:center;display:flex;flex-direction:column;font-size:var(--lynk-font-size-small);gap:8px;height:100%;justify-content:center;overflow:hidden;text-align:center}._3DgyR{background-color:var(--lynk-color-neutral-50);border-radius:4px;height:48px;padding:12px;width:48px}", ""]);

// exports
exports.locals = {
	"spinner": "_1cCj4",
	"spinner__scrim": "_3DgyR"
};