import {SharedNav} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

@autoinject()
export class LiveChannelsIndex {
    public router: Router;
    public routes = [
        {
            moduleId: PLATFORM.moduleName('./channels/index'),
            name: 'liveChannelsIndex',
            nav: true,
            route: ['', 'channels'],
            title: 'Live Channels',
        },
        {
            moduleId: PLATFORM.moduleName('./rules/index'),
            name: 'rulesIndex',
            nav: true,
            route: 'rules',
            title: 'Rules',
        },
        {
            moduleId: PLATFORM.moduleName('./audiences/index'),
            name: 'audiencesIndex',
            nav: true,
            route: 'audiences',
            title: 'Audiences',
        },
    ];

    constructor(public sharedNav: SharedNav) {}

    public attached() {
        this.sharedNav.initMainNav(this.router);
    }

    public configureRouter(config, router) {
        this.router = router;
        config.map(this.routes);
    }
}
