import {Type} from 'class-transformer';
import {IsOptional, IsString, ValidateNested, IsDefined, IsNotEmpty, IsNumber} from 'class-validator';

export class ParamConfigResponseItem {
  /** Indicates this config setting should be removed any time the config model is exported */
  @IsNumber()
  @IsOptional()
  public disallowed?: number; // 0 or 1

  /** The type of UI form input this config setting should map to  */
  @IsOptional()
  @IsString()
  public formElement?: string;

  /** Additional information to help describe what this config setting does  */
  @IsOptional()
  @IsString()
  public info?: string;

  /** The data type the server will process this field with  */
  @IsOptional()
  @IsString()
  public input?: string;

  /** (REQUIRED) The 'key' of the key value pair this config setting represents */
  @IsDefined()
  @IsNotEmpty()
  @IsString()
  public key: string;

  /** Indicates this config setting is allowed to exist in the config model but will not be included during export */
  @IsNumber()
  @IsOptional()
  public managed?: number; // 0 or 1

  /** An array of options to use when the 'input' is set to 'radio' or 'select' */
  @IsOptional()
  public options?: {
    /** An option which should not be selectable, however may already be defined  */
    disabled?: number; // 0 or 1

    /** A human-friendly label to display for this UI option */
    text: string;

    /** The 'value' which this key value pair will be set to when the user selects this config setting option */
    value: number | string;
  }[];

  /** Indicates this config setting is expected to exist within every config model */
  @IsNumber()
  @IsOptional()
  public required?: number; // 0 or 1

  /** A short, human-friendly label to describe this config setting  */
  @IsOptional()
  @IsString()
  public text?: string;
}

export class ParamConfigResponseCollection {
  @ValidateNested()
  @Type(() => ParamConfigResponseItem)
  public items: ParamConfigResponseItem[];
}
