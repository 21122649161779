import {autoinject, BindingEngine, LogManager} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {Router} from 'aurelia-router';

import * as moment from 'moment';

import {Column} from 'resources/data-table';

import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';
// eslint-disable-next-line max-len
import {LiveEventsService as LiveEventsSettingsService} from '../../../settings/live-events/services/live-events-service';
import {LiveEventsService} from '../services/live-events';

import {BaseEventSingle} from './base';
import {LiveEventsListIndex} from '../list';

const DATE_FORMAT = 'MMM D, YYYY [at] hh:mm:ss A';

const logger = LogManager.getLogger('Live Events Logs');

@autoinject
export class Logs extends BaseEventSingle {
  public columns: Column[];
  public hasCallback: boolean = false;
  public retriggering: boolean = false;

  constructor(
    public liveEventsSettingsService: LiveEventsSettingsService,
    router: Router,
    notification: Notification,
    liveEventsService: LiveEventsService,
    bindingEngine: BindingEngine,
    dialogService: DialogService,
    list: LiveEventsListIndex,
    sessionService: SessionService,
  ) {
    super(router, notification, liveEventsService, bindingEngine, dialogService, list, sessionService);
    this.columns = [
      new Column('Action', 'event'),
      new Column('Date/Time', 'timestamp', {
        getValue(item) {
          if (item[this.key] !== undefined) {
            return moment(item[this.key], 'x').format(DATE_FORMAT);
          }
          return this.defaultValue;
        },
      }),
      new Column('Who', 'username'),
    ];
    this.hasCallback = false;
  }

  async activate(params) {
    await super.activate(params);
  }

  async attached() {
    try {
      const settings = await this.liveEventsSettingsService.getLiveEventsSettings();

      this.hasCallback = !!settings.live_event_webhook;
    } catch (err) {
      logger.error(err);
    }
  }

  public async retriggerCallback(): Promise<void> {
    this.retriggering = true;

    try {
      await this.liveEvents.retriggerEventCallback(this.model.id);
    } catch (err) {
      this.notification.error(err.message);
    } finally {
      this.retriggering = false;
    }
  }
}
