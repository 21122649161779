export class TextReader {
    public load(file: File) {
        return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onabort = event => reject(event);
            fileReader.onerror = event => reject(event);
            fileReader.onloadend = (event: ProgressEvent<FileReader>) => {
                const {result} = event.target;
                if (!result) {
                    reject(new ErrorEvent('empty'));
                } else {
                    resolve(result.toString());
                }
            };
            fileReader.readAsText(file);
        }).bind(this);
    }
}
