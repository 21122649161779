import {Type} from 'class-transformer';
import {IsArray, IsBoolean, IsNumber, IsOptional, IsString, ValidateNested} from 'class-validator';

export class WebhookProfile {
    @IsString()
    public '@id': string;

    @IsString()
    public name: string;

    @IsString()
    public description: string = '';

    @IsString()
    public target_url: string;

    @IsArray()
    public event_type: string[] = [];

    @IsString()
    public owner_id: string;

    @IsBoolean()
    public status: boolean = true;

    @IsString()
    public authentication_token: string;

    @IsNumber()
    public created_at: string;

    @IsBoolean()
    public checkbox: boolean = false;

    /**
     * getId returns the ID from the `@id` hyperion uri.
     */
    public getId() {
        return this['@id'].split('/').pop();
    }
}

export class WebhookProfilesListResponse {
    @ValidateNested()
    @Type(() => WebhookProfile)
    public items?: WebhookProfile[];

    @IsNumber()
    @IsOptional()
    public total_items?: number;
}

export interface ProfilesMeta {
    total?: number;
    showing?: number;
    limit?: number;
    nextToken?: number;
    hasMore?: boolean;
}

export interface ProfileFilterParams {
    page_size?: number;
    page?: number;
    archived?: boolean;
}
