import {bindable} from 'aurelia-framework';

export interface ISearchActions {
  onSearch: (txt: string) => void;
  onClear: () => void;
}

export class UserSearch {
  @bindable()
  public actions: ISearchActions;

  @bindable()
  public isThinking: boolean = false;

  public clearSearch(): void {
    if (this.isThinking) {
      return;
    }

    if (!this.actions.onClear) {
      throw Error('onClear not set');
    }

    this.actions.onClear();
  }

  public updateSearch(txt: string): Promise<void> {
    if (this.isThinking) {
      return;
    }

    if (!this.actions.onSearch) {
      throw Error('onSearch not set');
    }

    this.actions.onSearch(txt);
  }
}
