import {autoinject} from 'aurelia-framework';
import {CToastsService, LocalStorageHelper} from '@bindable-ui/bindable';

import * as moment from 'moment';
import * as papa from 'papaparse';

import {ScheduleEntryService} from '../../../services';
import {IScheduledEntryListParams} from '../../../services/scheduled-entry';

export const LOCAL_STORAGE_KEY = 'schedule-bulk-export';

export interface IExportActions {
  onClose: () => void;
  onSave: () => void;
}

export interface IExportModel {
  actions: IExportActions;
  channelId: string;
}

@autoinject()
export class BulkExport {
  public startDate: any;
  public endDate: any;

  public fields = [
    // {key: 'id', value: true, title: 'Id', type: 'string', help: 'Entry Id'},
    // {key: 'channel', value: true, title: 'Channel Id', type: 'string', help: 'Channel Id for entry'},
    {
      key: 'content_id',
      value: true,
      title: 'Asset Id',
      type: 'string',
      help: 'Asset Id for content to be played',
      required: true,
    },
    // {key: 'type', value: false, title: 'Entry Type', type: 'string', help: null},
    {
      key: 'content_type',
      value: false,
      title: 'Entry Type',
      type: 'string',
      help: 'The type of the entry (Asset, Ad, or Slicer)',
      required: true,
    },
    {
      key: 'start_time',
      value: true,
      title: 'Start Time',
      type: 'string',
      help: 'Start time of entry (ISO)',
      required: true,
    },
    {key: 'duration', value: true, title: 'Duration', type: 'number', help: 'Entry duration (seconds)', required: true},
    {key: 'external_id', value: true, title: 'External Id', type: 'string', help: 'External Id assigned to the asset'},
    // {key: 'blackout_id', value: false, title: 'Blackout Id', type: '', help: ''},
    {key: 'ad_breaks', value: false, title: 'Ad Breaks', type: 'array', help: 'Ad Break durations'},
    {key: 'created', value: false, title: 'Created At', type: 'string', help: 'Created at time (ISO)'},
    {key: 'end', value: true, title: 'End Time', type: 'string', help: 'End time of entry (ISO)'},
    {key: 'desc', value: false, title: 'Description', type: 'string', help: 'Entry description'},
    // {key: 'rules', value: false, title: 'Rules', type: '', help: ''},
  ];

  public isProcessing: boolean = false;

  private channelId: string;
  private actions: IExportActions;

  private defaultSelectedFields: string[] = [
    'content_id',
    'content_type',
    'start_time',
    'duration',
  ];

  private selectedFields: string;

  constructor(private scheduleEntryService: ScheduleEntryService, public notificationService: CToastsService) {}

  public activate(model: IExportModel) {
    this.channelId = model.channelId;
    this.actions = model.actions;

    this.startDate = moment().startOf('day').unix() * 1000;
    this.endDate = moment().endOf('day').unix() * 1000;

    this.loadSelectedFields();
  }

  public fieldChanged(key) {
    const field = this.fields.find(f => f.key === key);
    field.value = !field.value;

    this.updateSelectedFields();
  }

  public closeDialog(e?) {
    if (this.isProcessing) {
      if (e) {
        e.preventDefault();
      }

      this.notificationService.warning('Processing, please wait.');
      return;
    }

    this.actions.onClose();
  }

  public async exportData() {
    this.isProcessing = true;
    try {
      const endDate = moment(Number(this.endDate)).endOf('day');
      const startDate = moment(Number(this.startDate)).startOf('day');

      const params: IScheduledEntryListParams = {
        end: endDate.toISOString(),
        start: startDate.toISOString(),
      };

      const {items} = await this.scheduleEntryService.getScheduledEntries(params, this.channelId);

      const columns = this.fields.filter(f => f.value === true).map(f => f.key);

      const config = {
        columns,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: true,
        newline: '\r\n',
      };

      const data = {
        fields: columns,
        data: items,
      };
      let csv = papa.unparse(data, config);

      // Converting the titles to csv names
      csv = csv.replace('content_type', 'type');
      csv = csv.replace('content_id', 'id');

      const file = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
      const format = 'MM_DD_YYYY';
      const fileName = `channel_${this.channelId}_events_${startDate.format(format)}_to_${endDate.format(format)}.csv`;

      this.downloadFile(file, fileName);
    } catch (err) {
      this.notificationService.error('Export failed');
    } finally {
      this.isProcessing = false;
      this.closeDialog();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private downloadFile(file: Blob, fileName: string) {
    const windowURL = window.URL || window.webkitURL;
    const link = document.createElement('a');
    link.href = windowURL.createObjectURL(file);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private loadSelectedFields() {
    this.selectedFields = LocalStorageHelper.loadOrDefault(LOCAL_STORAGE_KEY, this.defaultSelectedFields.join(','));

    const values = this.selectedFields.split(',');

    this.fields.forEach(f => {
      f.value = values.includes(f.key);
    });
  }

  private updateSelectedFields() {
    this.selectedFields = this.fields
      .filter(f => f.value === true)
      .map(f => f.key)
      .join(',');

    LocalStorageHelper.save(LOCAL_STORAGE_KEY, this.selectedFields);
  }
}
