import {CTableSelectable} from 'resources/components/c-table-selectable/c-table-selectable';
import {
    CTableColSelectable,
    CTableRowBehavior,
} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {ISlicerVersionTableRow} from './table-models';

export class SlicerVersionSelectionTable extends CTableSelectable {
    public rows: ISlicerVersionTableRow[];
    public selected: ISlicerVersionTableRow;
    protected colsSelectableDefault: CTableColSelectable[] = [
        {
            colHeadName: 'version_id',
            colHeadValue: 'Version ID',
            selected: true,
            sort: true,
            valueConverter: 'notApplicable',
        },
        // todo: re-enable this once MSO adds support for it
        // {
        //     colHeadName: 'dockerTag',
        //     colHeadValue: 'Docker Tag',
        //     selected: true,
        //     sort: true,
        //     valueConverter: 'notApplicable',
        // },
        {
            colHeadName: 'slicer_version',
            colHeadValue: 'Slicer Version',
            selected: true,
            sort: true,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'description',
            colHeadValue: 'Description',
            selected: true,
            sort: true,
            valueConverter: 'notApplicable',
        },
        {
            colHeadName: 'creation_time',
            colHeadValue: 'Creation Time',
            selected: true,
            sort: true,
            valueConverter: "timezoneTimeToStandardDateTimeMS:'MMM D, YYYY @ h:mm A' | notApplicable",
        },
    ];

    constructor() {
        super(CTableRowBehavior.RADIO);
        this.colsSelectable = this.colsSelectableDefault;
        this.actions.getBarColor = this.getBarColor;
        this.actions.getColClass = this.getColClass;
        this.actions.rowClick = this.rowClickRadio.bind(this);
    }

    protected getBarColor(row, col) {
        if (col.colHeadName === 'version_id') {
            if (row.version_id.toLowerCase().indexOf('latest') !== -1) {
                return 'var(--c_subOneMain)';
            }
            if (row.version_id.toLowerCase().indexOf('stable') !== -1) {
                return 'var(--c_secondaryMain)';
            }
        }
        return '';
    }

    protected getColClass(row, col) {
        if (col.colHeadName === 'version_id') {
            if (
                row.version_id.toLowerCase().indexOf('latest') !== -1 ||
                row.version_id.toLowerCase().indexOf('stable') !== -1
            ) {
                return 'bar';
            }
        }
    }

    protected trackSelectedRadio() {
        super.trackSelectedRadio();
        const item = this.rows.filter(row => row.id === this.rowsSelected[0])[0];
        Object.assign(this.selected, item);
    }
}
