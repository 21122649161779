exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3RDe_{border-bottom:var(--time-block-border-bottom);height:100%;padding-left:4px;position:relative}._3e8K4{color:var(--time-color);font-size:var(--time-font-size);z-index:var(--z_base)}", ""]);

// exports
exports.locals = {
	"timeBlock": "_3RDe_",
	"time": "_3e8K4"
};