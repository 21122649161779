exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1AIoh{left:590px;max-width:170px;position:absolute;top:23px}", ""]);

// exports
exports.locals = {
	"statusWrap": "_1AIoh"
};