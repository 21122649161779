import {DialogService} from 'aurelia-dialog';
import {autoinject, bindable, containerless} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {CToastsService} from '@bindable-ui/bindable';

import {Slot, Ad, AdServerDebugItem} from '../models/ad-server-debug-response';
import {AD_DEBUG_EVENT} from '../index';
import {PlayerUrlService} from '../services/player-url-service';

export interface AdRow {
  breakIndex: number;
  adIndex: number;
  adId: string;
  creativeId: string;
  duration: number;
  uplynkId: string;
  playerUrl: string;
  uplynkStatus: string;
  isSelected: string;
  initialAdId: string;
  isFallback: string;
}

const NUMERIC_FIELDS = [
  'adIndex',
  'breakIndex',
  'duration',
];

@autoinject()
@containerless()
export class AdDetail {
  @bindable
  public ads: Ad[] = [];

  public slots: Slot[] = [];

  public adRows: AdRow[] = [];

  public columns: string[];

  public isAvailable: boolean = false;

  public order: string = 'breakIndex';
  public displayColumns: any[];

  constructor(
    public eventAggregator: EventAggregator,
    public dialogService?: DialogService,
    public playerUrlService?: PlayerUrlService,
    public notification?: CToastsService,
  ) {
    // this.notification = new CToastsService();
    this.displayColumns = [
      {
        colHeadName: 'isSelected',
        colHeadValue: 'Selected?',
        display: true,
      },
      {
        colHeadName: 'breakIndex',
        colHeadValue: 'Break #',
        display: true,
        class: 'col-min-width-9',
      },
      {
        colHeadName: 'adIndex',
        colHeadValue: 'Ad #',
        display: true,
        class: 'col-min-width-8',
      },
      {
        colHeadName: 'initialAdId',
        colHeadValue: 'Initial Ad Id',
        display: true,
        class: 'col-min-width-11',
      },
      {
        colHeadName: 'adId',
        colHeadValue: 'Ad Id',
        display: true,
        class: 'col-min-width-9',
      },
      {
        colHeadName: 'creativeId',
        colHeadValue: 'Creative Id',
        display: true,
        class: 'col-min-width-13',
      },
      {
        colHeadName: 'duration',
        colHeadValue: 'Duration',
        display: true,
        class: 'col-min-width-11',
      },
      {
        colHeadName: 'isFallback',
        colHeadValue: 'Fallback?',
        display: true,
      },
      {
        colHeadName: 'uplynkStatus',
        colHeadValue: 'Asset Status',
        display: true,
      },
    ];
  }

  public adsChanged() {
    if (this.ads) {
      this.adRows = this.ads.map(ad => ({
        breakIndex: ad.breakIndex,
        adIndex: ad.adIndex,
        adId: ad.adId,
        creativeId: ad.creativeId,
        duration: ad.duration,
        uplynkId: ad.uplynkId,
        isSelected: ad.selected,
        isFallback: ad.isFallback,
        initialAdId: ad.initialAdId,
        uplynkStatus: ad.uplynkStatus,
        playerUrl: null,
      }));
      const reverse = this.order.indexOf('-') > -1;
      const key = this.order.replace('-', '');
      this.sortItems(key, reverse);
    }
  }

  public sortColumn($event) {
    // get the id of the item we are sorting and manage sort order
    const sortById = $event.target.id;

    if (!sortById) {
      return;
    }

    const key = this.order.replace('-', '');
    if (sortById === key) {
      //  we have the same key - just flip the order
      const reverse = this.order.indexOf('-') > -1;
      this.order = reverse ? key : `-${this.order}`;
      this.sortItems(key, reverse);
    } else {
      // reset the current sort order key
      let colHeadElement: HTMLElement;
      this.order = `-${sortById}`;

      this.sortItems(sortById, false);
      this.displayColumns.forEach(col => {
        if (col.display && col.colHeadName !== sortById) {
          colHeadElement = document.getElementById(col.colHeadName);
          colHeadElement.removeAttribute('sort-direction');
        }
      });
    }
  }

  public sortItems(key: string, reverse: boolean) {
    const numberSorting = NUMERIC_FIELDS.includes(key);

    this.adRows.sort((a, b) => {
      let aVal = a[key];
      let bVal = b[key];

      if (numberSorting) {
        // coerce negative value when undefined
        if (Number.isNaN(parseInt(aVal, 10))) aVal = -1;
        if (Number.isNaN(parseInt(aVal, 10))) bVal = -1;
      } else {
        // coerce all other values as string values handling undefined
        // and also convert to lowercase to ensure proper sorting
        aVal = `${aVal}`.toLowerCase();
        bVal = `${bVal}`.toLocaleLowerCase();
      }

      if (!reverse) {
        return bVal > aVal ? -1 : 1;
      }
      return bVal > aVal ? 1 : -1;
    });
  }

  public async runTestPlayer(row) {
    const {uplynkId} = row;

    // If the uplynkId has been seen before, the playerUrl is set to a URL or an error message.
    if (row.playerUrl !== null) {
      if (this.isValidUrl(row.playerUrl)) {
        window.open(row.playerUrl);
      } else {
        this.notification.error('Viewing this selected Ad Asset is not available at this time.', 'Player unavailable');
      }

      // Otherwise, we'll need to call the endpoint and update all the ads with the same uplynkId with the response.
    } else {
      let response = await this.playerUrlService.getPlayerUrl(uplynkId);
      if (this.isValidUrl(response)) {
        const request = new XMLHttpRequest();
        request.open('GET', response, false);
        request.send();
        if (request.status !== 200) {
          this.notification.error('The page you are trying to reach is not available.', 'Player unavailable');
          response = 'error2_Html_player_not_working';
        } else {
          window.open(response);
        }
      } else {
        this.notification.error('Viewing this selected Ad Asset is not available at this time.', 'Player unavailable');
      }
      this.updateRows(uplynkId, response);
    }
  }

  public updateRows(uplynkId: string, response: string) {
    const updatedAdRows = this.adRows.map(adRow => {
      if (adRow.uplynkId === uplynkId) {
        return {...adRow, playerUrl: response};
      }
      return adRow;
    });
    this.adRows = updatedAdRows;
  }

  public exportAsJson(job: AdServerDebugItem) {
    const data = {
      jobId: job.id,
      slots: job.slots,
    };
    this.eventAggregator.publish(AD_DEBUG_EVENT.ExportAds, data);
  }

  public isValidUrl(url: string): boolean {
    const urlPattern: RegExp = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    return urlPattern.test(url);
  }
}
