import {inject, Factory} from 'aurelia-framework';

import {CalendarFactory} from './services/calendar-factory';
import {CalendarService} from './services/calendar-service';

const viewType = 'calendarWeek';

@inject(Factory.of(CalendarFactory), CalendarService)
export class CalendarMonth {
    constructor(calendarFactory, calendarService) {
        this.calendar = calendarFactory(viewType);
        this.calendarService = calendarService;
    }

    activate() {
        this.calendar.getEvents();
        this.calendarService.setLastViewed(viewType);
    }

    deactivate() {
        this.calendar.cleanUp();
    }

    openFilterGrid() {
        this.eventsFilter.openModal();
    }
}
