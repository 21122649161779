import {DialogController} from 'aurelia-dialog';
import {Config as ConfigBase} from 'resources/config/import/models/config';
import {ConfigErrors as ConfigErrorsBase} from 'resources/config/import/models/config-errors';
import {ConfigInfo as ConfigInfoBase} from 'resources/config/import/models/config-info';
import {ConfigInput as ConfigInputBase} from 'resources/config/import/models/config-input';
import {ConfigOptions as ConfigOptionsBase} from 'resources/config/import/models/config-options';
import {ConfigState as ConfigStateBase} from 'resources/config/import/models/config-state';

export class Config extends ConfigBase {
    public errors = new ConfigErrors();
    public info = new ConfigInfo();
    public input = new ConfigInput();
    public options = new ConfigOptions();
    public state = new ConfigState();
}

class ConfigErrors extends ConfigErrorsBase {
    public description: string;
    public name: string;
}

export class ConfigInfo extends ConfigInfoBase {
    public description = 'Information on how this template will be used';
    public name = 'The name of the slicer configuration template';
    public parent =
        'Another template to inherit configuration settings from where any duplicate settings will override the values defined in this template';
}

class ConfigInput extends ConfigInputBase {
    public accessPublic: boolean;
    public default: boolean;
    public description: string;
    public favorite: boolean;
    public id: string;
    public name: string;
    public parent: string;
}

class ConfigOptions extends ConfigOptionsBase {
    public parent: object[];
}

class ConfigState extends ConfigStateBase {
    public error: string;
    public canSetParent: boolean;
    public canSetPublic: boolean;
    public isCreating: boolean;
    public isDeleting: boolean;
    public isLoading: boolean;
    public isUpdating: boolean;
}

export class ModalModel {
    public controller: DialogController;
    public shared: Config;
}
