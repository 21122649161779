export const slateConfigs = [
    {
        header: 'Account Default Slate',
        routeName: 'acountDefaultSlate',
        types: [
            {
                helpText: "This asset will be used to fill in any time during an ad break that isn't filled by an ad.",
                libSelectLabel: 'Default Slate Library',
                name: 'Default Slate',
                slateKey: 'default_slate',
                slateSelectLabel: 'Default Slate',
            },
        ],
    },
    {
        header: 'Slate Types',
        helpText: `The following values will be used as the slate types for live channels that are
            set to use the Account Value. Each video should be loopable and 3-4 seconds in duration.`,
        routeName: 'slateTypes',
        types: [
            {
                helpText: "This asset will be used to fill in any time during an ad break that isn't filled by an ad.",
                libSelectLabel: 'Ad Slate Library',
                name: 'Ad Slate',
                slateKey: 'ad_slate',
                slateSelectLabel: 'Ad Slate',
            },
            {
                helpText: "This asset will be used when the slicer isn't producing content.",
                libSelectLabel: 'Missing Content Slate Library',
                name: 'Missing Content Slate',
                slateKey: 'missing_content_slate',
                slateSelectLabel: 'Missing Content Slate',
            },
            {
                helpText: "This asset will be used when the slicer isn't producing content.",
                libSelectLabel: 'Conditional Blackout Slate Library',
                name: 'Blackout Slate',
                slateKey: 'blackout_slate',
                slateSelectLabel: 'Conditional Blackout Slate',
            },
        ],
    },
    {
        header: 'Live Event Slates',
        helpText: `Set which library to use for each slate type. If you don't have your slates separated
            into libraries you will need to do that before setting these options.`,
        routeName: 'liveEventSlates',
        types: [
            {
                helpText: 'This asset will be used during a pre event if no other pre event slate is set.',
                libSelectLabel: 'Pre Event Slate Library',
                name: 'Pre-Slate',
                slateKey: 'pre_slate',
                slateSelectLabel: 'Pre Event Slate',
            },
            {
                helpText: `A default mid slate is not used for Live Events.
                    The assets in the mid slate library will be presented as possible slate options to switch
                    to during a Live Event.`,
                libSelectLabel: 'Mid Event Slate Library',
                name: 'Mid-Slate',
                slateKey: 'mid_slate',
                slateSelectDisabled: true,
                slateSelectLabel: 'Default Mid Event Slate',
            },
            {
                helpText: 'This asset will be used during a post event if no other post event slate is set.',
                libSelectLabel: 'Post Event Slate Library',
                name: 'Post-Slate',
                slateKey: 'post_slate',
                slateSelectLabel: 'Post Event Slate',
            },
        ],
    },
];
