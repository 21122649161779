import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';

import {IsArray, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class OwnerPermissions {
  @computedFrom('state')
  get searchState() {
    return this.state === 'searching' ? 'disabled' : '';
  }

  @computedFrom('clipboard')
  get pasteState() {
    return this.clipboard ? '' : 'disabled';
  }

  public newFeature: string[] = [];
  public perm_def = {
    admin: 'Gives access to everything. Used by dev.',
    api: 'Can use integration APIs. Default for new accounts',
    api_su: '(New for 2017 - Not yet enabled in Acuity)',
    cms: 'Can login to CMS and read/write. Default for new accounts',
    cms_su: 'Replacement for cmsadminro, is more granular, there is an CMS SU Entitlements panel.',
    identity: 'Internal-only IDS admin control (Never give this permission to a customer)',
    ops: 'Permissions to use tools in https://cms.uplynk.com/static/cms/ops.html',
    opsro: 'Can view ops charts but not change anything. Also need to this to get access to the ops.html page.',
    service: 'Internal-only use for encoders. (App Support will likely never need to give this to anyone.)',
    slice: 'Can run a slicer. Default for new accounts.',
  };

  public cms_su_def = {
    ad_server_debug_ro: 'Enables read-only access to Ad Server Debug',
    analytics: 'Can only see CMS Analytics tab and underlying information for any CMS account.',
    billing:
      'Access to billing for all CMS accounts (with this right you can update the billing notification email via CMS)',
    billingro: 'Allows billing read only for all accounts.',
    chanedit: 'Not in use. No One is to be granted this access. The Channel Edit tool is not supported.',
    clipping: 'Clipping tool access across all accounts.',
    copying: 'Not in use (was used for libraries in the past) as of 2017/12/31',
    led: 'Live Events Dashboard read-write across all CMS accounts',
    ledop: 'Live Events Dashboard Operator level read-write across all CMS accounts',
    ledro: 'Live Events Dashboard read only rights across all CMS accounts',
    monitoring: 'Not in use. Access to monitoring with edit rights to all CMS accounts.',
    monitoringadmin:
      'Not in use. No One is to be granted this access. The monitoringadmin controls permissions for some admin views the in the legacy monitoring system.',
    monitoringro: 'Read Only access to monitoring tab in all CMS accounts.',
    noc: "Do not grant. This gives access to the Master Dashboard for monitoring (managed slicers CMS account, monitoring tab), gives users a 'noc' view (view slicers in a table or tiles view), but users have to have specific permissions to other accounts to see those slicers as well. (Just because it has noc in the name doesn't mean it is for everyone in the noc)",
    nocro:
      "Do not grant. There is only one account that has this and it's our managed_slicers CMS account. Do not NOT to give anyone else, including NOC personnel. (Just because it has noc in the name doesn't mean it is for everyone in the noc)",
    postcdnmon: 'Deprecated Entitlement',
    settings: 'Permission to see and read-write any setting under the Settings tab in any CMS account.',
    settingsro: 'Permission to see but not edit any setting under the Settings tab in any CMS account.',
    slicemon2: 'Not in use. Was used to give permission to slicer monitoring 2 when it was still in beta.',
    'slicers:latency': 'Make low latency slicer setting visible for a slicer',
    slicermanagement: "Permission to manage slicers on the customer's behalf",
    slicers_cloud: 'Permission to create, edit and restart cloud slicers',
    slicers_cloudro: 'Permission to see but not edit or restart cloud slicers',
    slicers_live: 'Permission to edit and restart live slicers',
    slicers_livero: 'Permission to see but not edit or restart live slicers',
    slicethrough: 'Allows you to slice through one account to another shared account.',
    stream: 'Permission to edit metadata (desc, external_id, etc.) or DELETE any asset or channel in any CMS account.',
    streamro: 'Permission to see but not edit any asset or channel in any CMS account.',
    syndication:
      'Enables write access to Syndication settings. Make sure that the customer has Syndication enabled for their account in "Object Explorer". Also add these three beta flags: hls_push, hls_pull, zixi.',
    syndicationro:
      'Enables read-only access to Syndication settings. Make sure that the customer has Syndication enabled for their account in "Object Explorer".',
    testplayers: 'Enables write access to Test Player Urls',
    testplayersro: 'Enables read-only access to Test Player Urls',
    webhook: 'Enables read/write access to Webhook',
    universal_ad_config: 'Enables access to create Universal Ad Config server settings',
    fast_management: 'Permission to manage Wurl based syndication targets in FAST channels',
  };

  public acuity_def = {
    adminActions: 'Retrieves admin action entries - aka logging of Acuity updates.',
    balanceAdjustment: 'Allows credits/debits for accounts.',
    boxManage: 'Allows bounce/kill/remove from ALB in regards to content/service boxes.',
    channelFailover: "Not used. Updates a channel's override URLs/values.",
    createAccount: 'Allows user to create a new Uplynk account',
    customPricing: 'Can create/modify pricing on a CMS user account.  Used to update CMS accounts.',
    deadbeats:
      'Enables the deadbeats panel in Acuity.  Setting acct as "deadbeat" does not allow the content in this account to playback. Greta Capeluto and others in billing use this.',
    dnsTooling: 'Allows chaning dns weighted values',
    idsSettings: 'Allow user to have access to ids settings.',
    encodingProfiles: 'Can view encoding profiles. Encoding profile information is not as simple as it might appear.',
    externalAds: 'allows user to search for and delete external ads that were ingested from an AD provider.',
    failoverSettings: 'Allow user to have access to failover settings.',
    googleAdManager: 'Panel for managing Google Ad Manager (GAM) settings',
    // mongoPasswordMgr: 'Allows user to update their mongo password',
    moochify: "Can 'moochify' owners (they don't get billed - no longer used, please use Free-rate pricing instead).",
    multiCdnSettings:
      'Allows user to move Uplynk/CDN traffic, change global account config, mid-session cdn selection settings, etc. ',
    newsItems:
      'Added on 8/31/2015 - provides the Permission to add news items to CMS.  This is only for use by the dev team.',
    objectExplorer:
      'Update billing, IDS and reset password emails.  Accounts can be deleted. Assets/Channel/Event detail search.',
    ownerPerms: 'Can update permissions & entitlements. ',
    payments:
      'New panel for entering manual payments. Panel is complete but not merged with master.  Billing team ONLY.',
    rtsPlayer:
      'Shows a list of all channels sorted by viewership.  You can click on each channel to view the content in that channel. Support has a similar page in ops section.',
    scalingCalc: 'Pre-Scale Calculator',
    searchService: 'Allow users to enable Atlas search by zone or all',
    uccSearch:
      "Allows user to disable search queries going to Elasticsearch for them to go directly to MongoDB. It's also possible to set it to Dark Mode which will send requests to both Elasticsearch and Mongo, but return results from Mongo.",
    vpaServicesSettings: 'Allows configuration of VPA settings (CDN config stuff).',
    vpaStreamFilteringSettings: 'Stream Filtering Config.',
    vpaStreamFilteringSimulation: 'Not Used Currently.',
  };

  private username: string = '';
  private owners: any[] = [];
  private origOwners: any[] = [];
  private state: string = 'idle';
  private ownerStates: string[] = [];
  private clipboard: any = false;

  constructor(private acceo: Acceo, private notification: CToastsService, private signaler: BindingSignaler) {}

  public objectWithKeySorted(object) {
    const result = {};
    _.forEach(Object.keys(object).sort(), key => {
      result[key] = object[key];
    });
    return result;
  }

  public async searchOwners() {
    this.state = 'searching';

    const url: string = '/ops/owners';
    const params = $.param({username: this.username});
    try {
      const resp = await this.acceo.get(Response)(
        [
          url,
          params,
        ].join('?'),
      );
      this.owners = resp.owners;
      if (this.owners.length !== 0) {
        this.origOwners = _.cloneDeep(resp.owners);
        for (let i = 0; i < this.owners.length; i += 1) {
          this.ownerStates[i] = 'clean';
        }
        // Sort the entitlements to make it easier to look at
        _.forEach(this.owners, owner => {
          owner.cms_entitlements = this.objectWithKeySorted(owner.cms_entitlements);
          owner.cms_su_entitlements = this.objectWithKeySorted(owner.cms_su_entitlements);
          owner.ops_entitlements = this.objectWithKeySorted(owner.ops_entitlements);
          owner.permissions = this.objectWithKeySorted(owner.permissions);
        });
      } else {
        this.state = 'idle';
        this.notification.warning(`Couldn't find a matching owner for ${this.username}`);
      }
      this.signaler.signal('save-button');
      this.state = this.owners.length ? 'idle' : 'not-found';
    } catch (err) {
      this.notification.error(err);
      this.state = 'idle';
    }
  }

  public removeBetaFeature(owner: any, featureIndex: number) {
    const features = owner.cms_flags.beta.split(',');
    features.splice(featureIndex, 1);
    if (features.length > 0) {
      owner.cms_flags.beta = features.join(',');
    } else {
      owner.cms_flags.beta = null;
    }
    this.owners.forEach((o, i) => {
      if (o.id === owner.id) {
        this.updateOwnerState(i);
      }
    });
  }

  public addBetaFeature(ownerIndex: number) {
    const feature: string = this.newFeature[ownerIndex].trim();
    if (!feature) return;
    const owner: any = this.owners[ownerIndex];
    let features: string[] = [];
    if (owner.cms_flags.beta != null) {
      features = owner.cms_flags.beta.split(',');
    }
    features.push(feature);

    // de-dupe and remove blanks
    features = _.union(features);
    features = features.filter(e => e !== '');

    owner.cms_flags.beta = features.join(',');
    this.newFeature = [];
    this.updateOwnerState(ownerIndex);
  }

  public async saveOwner(ownerIndex: number) {
    this.ownerStates[ownerIndex] = 'saving';
    this.signaler.signal('save-button');
    const owner: any = this.owners[ownerIndex];
    let features: string[] = [];
    if (owner.cms_flags.beta != null) {
      features = owner.cms_flags.beta.split(',');
    }
    try {
      let url = '';
      // Save permissions
      url = `/ops/owners/${owner.id}`;
      await this.acceo.post()(url, owner);
      // Save beta features
      url = `/ops/owners/beta-features/${owner.id}`;
      await this.acceo.post()(url, {beta: features});

      this.origOwners[ownerIndex] = _.cloneDeep(owner);
      this.notification.success(`Succesfully saved permissions for "${owner.username}"`);
      this.updateOwnerState(ownerIndex);
    } catch (err) {
      this.notification.error(`Error saving owner record. ${err}`);
      this.ownerStates[ownerIndex] = 'error';
      this.signaler.signal('save-button');
    }
  }

  public undoOwner(index: number) {
    const owner = this.owners[index];
    const origOwner = _.cloneDeep(this.origOwners[index]);
    owner.cms_flags = origOwner.cms_flags;
    owner.cms_su_entitlements = this.objectWithKeySorted(origOwner.cms_su_entitlements);
    owner.ops_entitlements = this.objectWithKeySorted(origOwner.ops_entitlements);
    owner.permissions = this.objectWithKeySorted(origOwner.permissions);
    owner.cms_entitlements = this.objectWithKeySorted(origOwner.cms_entitlements);
    this.updateOwnerState(index);
  }

  public setSaveButtonState(index: number) {
    switch (this.ownerStates[index]) {
      case 'clean':
        return 'disabled';
      case 'saving':
        return 'thinking';
      default:
        return '';
    }
  }

  public copyPerms(index: number) {
    const owner = this.owners[index];
    if (owner.permissions.admin) {
      this.notification.error('Cannot copy admin permissiosn');
    } else {
      this.clipboard = {
        cms_entitlements: _.cloneDeep(owner.cms_entitlements),
        cms_flags: _.cloneDeep(owner.cms_flags),
        cms_su_entitlements: _.cloneDeep(owner.cms_su_entitlements),
        ops_entitlements: _.cloneDeep(owner.ops_entitlements),
        permissions: _.cloneDeep(owner.permissions),
        username: owner.username,
      };
      this.notification.info(`${owner.username} settings copied to clipboard.`);
    }
  }

  public pastePerms(index: number) {
    const owner = this.owners[index];
    if (owner.permissions.admin) {
      this.notification.error('Cannot paste permissions to admins');
      return;
    }
    if (this.clipboard) {
      owner.cms_flags = _.cloneDeep(this.clipboard.cms_flags);
      owner.cms_su_entitlements = _.cloneDeep(this.clipboard.cms_su_entitlements);
      owner.cms_entitlements = _.cloneDeep(this.clipboard.cms_entitlements);
      owner.ops_entitlements = _.cloneDeep(this.clipboard.ops_entitlements);
      owner.permissions = _.cloneDeep(this.clipboard.permissions);
      this.updateOwnerState(index);
      this.notification.success(
        `Pasted settings from ${this.clipboard.username}. Be sure to save in order for this to take effect.`,
      );
    } else {
      this.notification.warning('There are no settings copied to the clipboard.');
    }
  }

  public cmsSuPerms(index: number) {
    const owner = this.owners[index];
    if (owner.permissions.admin) {
      this.notification.error('Cannot change admin permissions');
      return;
    }
    const active_cms_su_entitlements: string[] = [
      'analytics',
      'billingro',
      'clipping',
      'ledro',
      'monitoringro',
      'settingsro',
      'testplayersro',
    ];
    const active_permissions: string[] = [
      'api',
      'cms',
      'cms_su',
      'slice',
    ];

    Object.keys(owner.cms_su_entitlements).forEach(key => {
      /* istanbul ignore else */
      if (key in owner.cms_su_entitlements) {
        if (active_cms_su_entitlements.includes(key)) {
          owner.cms_su_entitlements[key] = true;
        }
        // owner.cms_su_entitlements[key] = active_cms_su_entitlements.includes(key) ? true : false;
      }
    });

    Object.keys(owner.permissions).forEach(key => {
      /* istanbul ignore else */
      if (key in owner.permissions) {
        if (active_permissions.includes(key)) {
          owner.permissions[key] = true;
        }
        // owner.permissions[key] = active_permissions.includes(key) ? true : false;
      }
    });

    this.updateOwnerState(index);
  }

  public defaultPerms(ownerIndex: number) {
    const owner = this.owners[ownerIndex];
    if (owner.permissions.admin) {
      this.notification.error('Cannot change admin permissions');
      return;
    }
    const active_permissions: string[] = [
      'api',
      'cms',
      'slice',
    ];

    Object.keys(owner.cms_su_entitlements).forEach(key => {
      /* istanbul ignore else */
      if (key in owner.cms_su_entitlements) {
        owner.cms_su_entitlements[key] = false;
      }
    });

    Object.keys(owner.ops_entitlements).forEach(key => {
      /* istanbul ignore else */
      if (key in owner.ops_entitlements) {
        owner.ops_entitlements[key] = false;
      }
    });

    Object.keys(owner.permissions).forEach(key => {
      /* istanbul ignore else */
      if (key in owner.permissions) {
        owner.permissions[key] = !!active_permissions.includes(key);
      }
    });

    const active_entitlements: string[] = [
      'analytics',
      'billing',
      'billingro',
      'clipping',
      'led',
      'ledop',
      'monitoring',
      'monitoringro',
      'settings',
      'settingsro',
      'slicers_live',
      'slicers_livero',
      'stream',
      'streamro',
      'testplayers',
      'testplayersro',
      'webhook',
      'universal_ad_config',
    ];

    Object.keys(owner.cms_entitlements).forEach(key => {
      /* istanbul ignore else */
      if (key in owner.cms_entitlements) {
        owner.cms_entitlements[key] = !!active_entitlements.includes(key);
      }
    });

    owner.cms_flags = {};
    this.updateOwnerState(ownerIndex);
  }

  private updateOwnerState(ownerIndex) {
    const owner: any = this.owners[ownerIndex];
    const originalOwner: any = this.origOwners[ownerIndex];
    if (_.isEqual(owner, originalOwner)) {
      this.ownerStates[ownerIndex] = 'clean';
    } else {
      this.ownerStates[ownerIndex] = 'dirty';
    }
    this.signaler.signal('save-button');
  }
}

class Response {
  @IsString()
  public cms_session_id_fingerprint: string;

  @IsString()
  public cms_session_fingerprint: string;

  @IsArray()
  public owners: any[];
}
