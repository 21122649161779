import {autoinject, computedFrom} from 'aurelia-framework';
import {TextFile} from 'resources/file/text-file';
import {SlicerViewModel} from './index';

export interface Config {
  key: string;
  value: string;
  error?: string;
}

@autoinject()
export class OwnedSlicersSingleAdvanced {
  @computedFrom('parentView.slicer.configuration')
  get configs() {
    if (this.parentView.slicer && this.parentView.slicer.configuration) {
      return this.parentView.slicer.configuration;
    }
    return [];
  }

  constructor(public parentView: SlicerViewModel) {}

  public async actionExport() {
    const fileData = encodeURIComponent(
      this.parentView.slicer.configuration.map(c => `${c.key}:${c.value}`).join('\n'),
    );
    const file = new File([fileData], 'configuration.txt', {type: 'text/plain'});
    await TextFile.save(file);
  }
}
