import {LogManager} from 'aurelia-framework';
import {validate, ValidationError} from 'class-validator';
import {DRMConfig} from '../models/drm-config';

const log = LogManager.getLogger('drm-config-util');

export class DRMConfigUtil {
    public static VALUE_MAX_LENGTH = 128;
    public static URL_MAX_LENGTH = 10000;
    public static NUM_MIN = 0;

    public static validate(config: DRMConfig): Promise<any> {
        return new Promise((resolve, reject) => {
            validate(config, {skipMissingProperties: true})
                .then((errs: ValidationError[]) => {
                    if (errs.length) {
                        errs.forEach(err => log.error(`${err}`));
                    }
                    resolve(errs);
                })
                .catch(er => {
                    const msg = 'Validation Error';
                    log.error(`${msg}:`, er);
                    reject(new Error(msg));
                });
        });
    }
}
