import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {MultiCdnSettingsConfig, QosPreset} from '../models/multi-cdn-settings-config';
import {MultiCdnUtil} from '../services/cdn-util';

@autoinject()
export class QosPresetContent {
    @bindable
    public multiCdnSettings: MultiCdnSettingsConfig;

    @bindable
    public currentPreset: QosPreset;

    @bindable
    public callbacks;

    @bindable
    public validationErrors: ValidationError[];

    public rttErrorTooltipText: string = 'Minimum RTT value that is considered an error';

    public availabilityErrorTooltipText: string = 'Minimum Availability percent that is considered an error';

    get isDefaultPreset() {
        if (this.currentPreset) {
            if (this.currentPreset.name === MultiCdnUtil.defaultPresetName) {
                return true;
            }
        }
        return false;
    }
}
