import {CTipActions} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';
import {ValidateHelper} from 'resources/validate-helper';
import {Config} from './models/config';

@autoinject
export class ConfigEditor {
  public config: Config;
  public configSettingKeyActions = {
    onChange: () => {
      this.config.errors.configSetting.value = undefined;
      this.config.input.configSetting.value = undefined;
    },
  };

  @computedFrom('config.input.configSetting.key')
  public get selectedConfigSetting() {
    const result = this.config.options.configSetting.filter(
      setting => setting.key === this.config.input.configSetting.key,
    );
    return result.length ? result[0] : undefined;
  }

  public tipAddConfigSettingCustom: any;
  public tipAddConfigSettingStandard: any;
  public tipAddConfigSettingCustomActions: CTipActions = {
    onShow: () => {
      this.config.errors.configSetting.key = undefined;
      this.config.errors.configSetting.value = undefined;
      this.config.input.configSetting.key = undefined;
      this.config.input.configSetting.value = undefined;
    },
  };

  public tipAddConfigSettingStandardActions: CTipActions = {
    onShow: () => {
      if (this.config.options.configSettingAllowed.length) {
        this.config.input.configSetting.key = this.config.options.configSettingAllowed[0].key;
      }
      this.config.input.configSetting.value = undefined;
      this.config.errors.configSetting.key = undefined;
      this.config.errors.configSetting.value = undefined;
    },
  };

  public async actionAddConfigSetting() {
    const valid = await ValidateHelper.validate(this.config.input.configSetting, this.config.errors.configSetting);
    if (!valid) return;
    this.config.input.table.importLine(this.config.input.configSetting.key, `${this.config.input.configSetting.value}`);
    this.tipAddConfigSettingCustom.hide();
    this.tipAddConfigSettingStandard.hide();
  }

  public actionDocumentation() {
    window.open(this.config.info.documentationUrl, '_blank');
  }

  public actionRemove() {
    this.config.input.table.removeLines(this.config.input.table.rowsSelected);
    // TODO set hasChanged here?
    this.config.input.table.selectAllRows(false);
  }
}
