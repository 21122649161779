import {EncodingProfileList} from 'apps/cms/routes/slicers/encoding-profiles/list';
import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {LynkDialogServiceModel} from 'resources/services/dialog';
import {Config} from './models';
import {EncodingProfileSelectionTable} from './table';

@autoinject
export class EncodingProfileSelection extends EncodingProfileList {
  public config: Config;
  public model: LynkDialogServiceModel;
  public table = new EncodingProfileSelectionTable();

  constructor(protected acceo: Acceo, protected router: Router, protected sessionService: SessionService) {
    super(acceo, router, sessionService);
  }

  public activate(model?: LynkDialogServiceModel) {
    this.model = model;
    this.config = model.settings.sharedModel.input.encodingProfile;
    this.table.selected = this.config.input.selection;
    this.config.input.selectionReset = this.table.removeSelectedRadio.bind(this.table);
    super.activate();
    if (this.config.input.selection) {
      this.table.cols[0].radioSelected = this.config.input.selection.id;
      this.table.rowsSelected = [this.table.cols[0].radioSelected];
    }
  }
}
