import {autoinject, LogManager} from 'aurelia-framework';
import {plainToClass} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {SessionService} from 'services/session';
import {AccountSettings} from '../models/account-settings';

const logger = LogManager.getLogger('Account Settings Service');

export const BASE_URL = '/api/v4/me';

export enum IdsFieldType {
  EMAIL = 'IdsEmail',
  USERNAME = 'IdsUsername',
}

@autoinject
export class AccountSettingsService {
  public logger = logger;
  constructor(public acceo: Acceo, public session: SessionService) {}

  public getAccountInfo(): Promise<AccountSettings> {
    const {ids_email, ids_id, ids_domain_id, ids_username, ownerID, username} = this.session.sessionInfo;

    return Promise.resolve(
      plainToClass(AccountSettings, {
        ids_email,
        ids_domain_id,
        ids_id,
        ids_username,
        ownerID,
        username,
      }),
    );
  }

  public async updateAccount(updateType: string, pw: string, value: string, confirmationValue: string) {
    switch (updateType) {
      case IdsFieldType.EMAIL:
        return this.updateAccountIdsEmail(pw, value, confirmationValue);
      case IdsFieldType.USERNAME:
        return this.updateAccountIdsUsername(pw, value, confirmationValue);
      default:
        throw new Error('invalid property name');
    }
  }

  public async updateAccountIdsEmail(pw, newEmail, newEmailConfirm): Promise<void> {
    const data = {
      ids_e1: newEmail,
      ids_e2: newEmailConfirm,
      ids_password: pw,
    };

    try {
      await this.acceo.post()(BASE_URL, data);
    } catch (ex) {
      this.logger.error(ex);
      throw new Error('Error updating IDS email');
    }
  }

  public async updateAccountIdsUsername(pw, newUsername, newUsernameConfirm): Promise<void> {
    const data = {
      ids_password: pw,
      ids_u1: newUsername,
      ids_u2: newUsernameConfirm,
    };

    try {
      await this.acceo.post()(BASE_URL, data);
    } catch (ex) {
      this.logger.error(ex);
      throw new Error('Error updating IDS username');
    }
  }
}
