/* eslint-disable camelcase */
import {DialogService} from 'aurelia-dialog';
import {autoinject, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {BetaMode} from 'services/beta-mode';
import {CPopoverService, CToastsService} from '@bindable-ui/bindable';

import * as moment from 'moment';

import {LiveChannelsService, ScheduleEntryService, SchedulePlaylistService} from '../../services';

import {
  ITrackedScheduledEntry,
  MATCH_TYPES,
  ScheduledEntryContentType,
  ScheduleEntry,
  trackedScheduleEntry,
} from '../models/event-model';

const log = LogManager.getLogger('schedule-popover-base');

@autoinject()
export class BaseTimeEntry {
  public model;
  public modeText: string = 'Add';
  public removeEntry: () => boolean;

  constructor(
    public betaMode: BetaMode,
    protected dialogService: DialogService,
    protected liveChannels: LiveChannelsService,
    protected popoverService: CPopoverService,
    protected scheduleEntryService: ScheduleEntryService,
    protected playlistService: SchedulePlaylistService,
    private notificationService: CToastsService,
  ) {}

  public isGraphicsAllowed(): boolean {
    return this.betaMode.hasScope('graphic_overlay');
  }

  public activate(model) {
    if (model.data.actions) {
      this.removeEntry = model.data.actions.removeEntry;
    }
    this.model = this.mapToScheduledEntry(model.data);
  }

  public async showAdBreak() {
    this.model.type = MATCH_TYPES.TIME;
    this.model.content_type = ScheduledEntryContentType.AD;

    const entry = await this.getTrackedScheduledEntry();

    this.assetDialog(entry, 'Ad Break', 'large');
  }

  public async showSlicer() {
    this.model.type = MATCH_TYPES.TIME;
    this.model.content_type = ScheduledEntryContentType.SLICER;

    const entry = await this.getTrackedScheduledEntry();

    if (entry.content_owner && entry.content_id) {
      entry.content_id = `${entry.content_owner}:${entry.content_id}`;
    }

    this.assetDialog(entry, 'Slicer', 'large');
  }

  public async showRepeat(useEntryInfo: boolean = false) {
    this.model.type = MATCH_TYPES.TIME;
    this.model.content_type = ScheduledEntryContentType.REPLAY;

    const entry = await this.getTrackedScheduledEntry();

    if (entry.content_owner && entry.content_id) {
      entry.content_id = `${entry.content_owner}:${entry.content_id}`;
    }

    if (useEntryInfo) {
      // Add duration to startTime
      if (entry.desc.length) {
        entry.desc = `Replay: ${entry.desc}`;
      } else {
        entry.desc = `Replay: ${entry.content_type}`;
      }
      entry.replay_source_start = entry.start;
      entry.replay_source_end = entry.end;
      entry.id = undefined;
      entry.content_type = ScheduledEntryContentType.REPLAY;
      entry.start = entry.end;
    }

    this.assetDialog(entry, 'Replay', 'large');
  }

  public async showMatchSignal() {
    const {type} = this.model;

    if (!type) {
      this.model.type = MATCH_TYPES.SIGNAL;
    }

    const entry = await this.getTrackedScheduledEntry();

    this.assetDialog(entry, `${this.modeText} Signal Blackout`, 'large');
  }

  public async showMatchTime() {
    const {type} = this.model;

    if (!type) {
      this.model.type = MATCH_TYPES.TIME;
    }

    const entry = await this.getTrackedScheduledEntry();

    this.assetDialog(entry, `${this.modeText} Timed Blackout`, 'large');
  }

  public async showVOD() {
    this.model.content_type = ScheduledEntryContentType.ASSET;
    const entry = await this.getTrackedScheduledEntry();

    let extraTitle = '';
    if (this.model.desc) {
      extraTitle = ` - ${this.model.desc}`;
    }
    this.assetDialog(entry, `${this.modeText} Asset${extraTitle}`, 'full');
  }

  public async showPlaylist() {
    this.model.content_type = ScheduledEntryContentType.PLAYLIST;

    const entry = await this.getTrackedScheduledEntry();

    const modules = {
      body: PLATFORM.moduleName('apps/cms/routes/live-channels/channels/single/modals/playlist-add/body'),
      footer: PLATFORM.moduleName('apps/cms/routes/live-channels/channels/single/modals/playlist-add/footer'),
    };

    this.showDialog(entry, 'Add Playlist to Schedule', 'full', modules);
  }

  public async showOverlay() {
    this.model.content_type = ScheduledEntryContentType.OVERLAY;
    const entry = await this.getTrackedScheduledEntry();
    let extraTitle = '';
    if (this.model.desc) {
      extraTitle = ` - ${this.model.desc}`;
    }

    this.showOverlayDialog(entry, `${this.modeText} Graphic Overlay${extraTitle}`, 'full');
  }

  public async getPlaylistName() {
    const playlist = await this.playlistService.getPlaylist(this.model.source.id);
    return playlist.desc;
  }

  public async deleteScheduleEntry(includeLinked = false) {
    let query = {};
    if (includeLinked) {
      query = {include_linked: includeLinked};
    }
    try {
      await this.scheduleEntryService.delete(this.model, this.model.channel, query);
      this.notificationService.success('Deleted');
    } catch (ex) {
      log.error(ex);
      this.notificationService.error(`Failed: ${ex.message}`);
    } finally {
      this.popoverService.close();
    }
  }

  private async getTrackedScheduledEntry(): Promise<ITrackedScheduledEntry> {
    let {model} = this;

    if (model.id) {
      // Reload the model for minty freshness
      this.modeText = 'Update';

      const {id, channel} = this.model;

      model = await this.scheduleEntryService.getScheduledEntry(id, {}, channel);

      if (!model) {
        return null;
      }
    }

    // eslint-disable-next-line consistent-return
    return trackedScheduleEntry(model);
  }

  /**
   * Normalizes Timeline data as a ScheduleEntry
   * @param data
   */
  private mapToScheduledEntry(data) {
    let model: ScheduleEntry;

    if (data.model) {
      model = data.model;

      if (model.id) {
        // LiveSlice

        if (data.model.start) {
          model.start = moment(data.model.start).toISOString();
        }

        if (data.model.stop) {
          model.end = moment(data.model.stop).toISOString();
        }

        if (data.model.replay_source_start) {
          model.replay_source_start = moment(data.model.replay_source_start).toISOString();
        }

        if (data.model.replay_source_end) {
          model.replay_source_end = moment(data.model.replay_source_end).toISOString();
        }
      }
    } else {
      // New Entry
      model = new ScheduleEntry();
      model.dur = 0;
      model.start = data.isoTime;
      model.rules = [];
    }

    model.channel = this.liveChannels.currentModel.id;

    return model;
  }

  private assetDialog(entry, title, size) {
    const modules = {
      body: PLATFORM.moduleName('apps/cms/routes/live-channels/channels/single/modals/schedule-add/add-body'),
      footer: PLATFORM.moduleName('apps/cms/routes/live-channels/channels/single/modals/schedule-add/add-footer'),
    };

    this.showDialog(entry, title, size, modules);
  }

  private showOverlayDialog(entry, title, size) {
    if (!entry) {
      return;
    }

    this.popoverService.close();

    this.dialogService
      .open({
        model: {
          size,
          title,
          bodyViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/channels/single/modals/overlay-add/body'),
          footerViewModel: PLATFORM.moduleName(
            'apps/cms/routes/live-channels/channels/single/modals/overlay-add/footer',
          ),
          footerEnable: true,
          sharedModel: {
            entry,
            channelId: this.model.channel,
            removeEntry: this.removeEntry,
          },
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed(() => {
        this.scheduleEntryService.forceTimelinePoll();
      });
  }

  private showDialog(entry, title, size, modalModules) {
    if (!entry) {
      return;
    }

    this.popoverService.close();

    this.dialogService
      .open({
        model: {
          size,
          title,
          bodyViewModel: modalModules.body,
          footerEnable: true,
          footerViewModel: modalModules.footer,
          sharedModel: {
            entry,
            channelId: this.model.channel,
            removeEntry: this.removeEntry,
          },
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed(() => {
        this.scheduleEntryService.forceTimelinePoll();
      });
  }
}
