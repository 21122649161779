import {Type} from 'class-transformer';

export class AccountAccess {
  @Type(() => Access)
  public access: Access;
}

export class Access {
  public accounts: Account[];
}

export class Account {
  public username: string;
  public id: string;
  public cms_entitlements: string[];
  public billing_with_me: boolean;
  public billing_allow: boolean;
  public allAccess: boolean;
}

export const ACCESS_ONLY_ENTITLEMENTS = [
  'clipping',
  'analytics',
];

export const ADMIN_ENTITLEMENTS = [
  'noc',
  'nocro',
];

export const GENERAL_ENTITLEMENTS = [
  'billing',
  'billingro',
  'monitoring',
  'monitoringro',
  'settings',
  'settingsro',
  'slicers_live',
  'slicers_livero',
  'slicers_cloud',
  'slicers_cloudro',
  'stream',
  'streamro',
  'testplayers',
  'testplayersro',
];

export const OPS_ENTITLEMENTS = [
  'led',
  'ledro',
  'ledop',
];

export const SYNDICATION_ENTITLEMENTS = [
  'syndication',
  'syndicationro',
];

export const ALL_ENTITLEMENTS = [
  ...ACCESS_ONLY_ENTITLEMENTS,
  ...ADMIN_ENTITLEMENTS,
  ...GENERAL_ENTITLEMENTS,
  ...OPS_ENTITLEMENTS,
];

export const KEYSMAP = {
  adServerDebugRO: 'ad_server_debug_ro',
  allTabs: 'allTabs',
  analyticsTab: 'analytics',
  billingTabRO: 'billingro',
  billingTabRW: 'billing',
  clippingTool: 'clipping',
  liveEventDashboardOP: 'ledop',
  liveEventDashboardRO: 'ledro',
  liveEventDashboardRW: 'led',
  monitoringTabRO: 'monitoringro',
  monitoringTabRW: 'monitoring',
  nocTabRO: 'nocro',
  nocTabRW: 'noc',
  settingsTabRO: 'settingsro',
  settingsTabRW: 'settings',
  slicersCloudRO: 'slicers_cloud',
  slicersCloudRW: 'slicers_cloudro',
  slicerCloudMe: 'slicermanagement',
  slicersLiveRO: 'slicers_livero',
  slicersLiveRW: 'slicers_live',
  streamingTabsRO: 'streamro',
  streamingTabsRW: 'stream',
  syndicationRO: 'syndicationro',
  syndicationRW: 'syndication',
  testPlayersRO: 'testplayersro',
  testPlayersRW: 'testplayers',
  universalAdConfig: 'universal_ad_config',
};

export const KEYSMAP2 = {
  billing_allow: 'billing_allow',
  billing_with_me: 'billing_with_me',
  cms_entitlements: 'cms_entitlements',
  id: 'id',
  randomID: 'randomID',
  username: 'username',
};
