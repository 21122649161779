import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable, containerless, LogManager} from 'aurelia-framework';
import {validate, ValidationError} from 'class-validator';
import {MetadataSchemaEvent} from '.';
import {MetadataSchema} from '../models/metadata';

const log = LogManager.getLogger('live-event-settings-metadata-schema-create');

@containerless
@autoinject()
export class MetadataSchemaCreate {
  @bindable
  public schemas: MetadataSchema[];

  @bindable
  public isCreating: boolean = false;

  public schema: MetadataSchema;
  public schemaErrors: ValidationError[];
  public duplicateFrom: string;
  public schemaNameInput: HTMLInputElement;
  public createSchemaPopover: any;
  public createMode: string = 'new';

  private subscriptions: Subscription[] = [];

  constructor(public eventAggregator: EventAggregator) {}

  public init() {
    this.schema = new MetadataSchema();
    this.schema.existingSchemaNames = this.schemas.map(_ => _.name);
    this.schemaErrors = null;
  }

  public async create() {
    return validate(this.schema, {skipMissingProperties: true}).then((errs: ValidationError[]) => {
      if (errs.length) {
        errs.forEach(err => log.error(String(err)));
        this.schemaErrors = errs;
      } else {
        this.createSchemaPopover.hide();
        if (this.createMode === 'duplicate') {
          this.schema = {
            ...this.schemas.find(schema => schema.id === this.duplicateFrom),
            id: undefined,
            name: this.schema.name,
          };
        }
        this.eventAggregator.publish(MetadataSchemaEvent.CREATE, this.schema);
      }
    });
  }

  public onShow() {
    this.schemaNameInput.focus();
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }
}
