import * as moment from 'moment';

import {inject} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';

import {Column} from 'resources/data-table';
import {MsToHmsValueConverter} from '@bindable-ui/bindable';

import {BaseEventSingle} from './base';

const POLL_EVERY_MS = 5000;
const DATE_FORMAT = 'MMM D, YYYY [at] h:mm A';

@inject(BindingSignaler)
export class EventAssets extends BaseEventSingle {
  constructor(bindingSignaler, ...rest) {
    super(...rest);
    this.bindingSignaler = bindingSignaler;

    this.columns = [
      new Column('Description', 'title', {
        width: 300,
        view: PLATFORM.moduleName('apps/cms/routes/live-events/live-events/single/event-assets-description.html'),
        viewModel: PLATFORM.moduleName('apps/cms/routes/live-events/live-events/single/event-assets-description'),
      }),
      new Column('Creation Date', 'created', {
        colClass: 't-215',
        width: 215,
        getValue(item) {
          if (item[this.key] !== undefined) {
            return moment(item[this.key], 'x').format(DATE_FORMAT);
          }
          return this.defaultValue;
        },
      }),
      new Column('Duration', 'dur', {
        colClass: 't-105',
        width: 105,
        getValue(item) {
          if (item[this.key] !== undefined) {
            return MsToHmsValueConverter.transform(item[this.key] * 1000);
          }
          return this.defaultValue;
        },
      }),
      new Column('Slicer', 'slicer_id', {
        colClass: 't-120',
        width: 120,
      }),
      new Column('Status', 'status_desc', {
        colClass: 't-85',
        width: 85,
        getTdClass(item) {
          if (item[this.key] === 'Slicing') {
            return 'bg-pulse-secondary-with-text';
          }
          return '';
        },
      }),
      new Column('Actions', null, {
        colClass: 't-90',
        width: 90,
        isSortable: false,
        view: PLATFORM.moduleName('apps/cms/routes/live-events/live-events/single/event-assets-actions.html'),
        viewModel: PLATFORM.moduleName('apps/cms/routes/live-events/live-events/single/event-assets-actions'),
      }),
      new Column('&nbsp;', null, {
        colClass: 't-30',
        width: 30,
        isSortable: false,
        view: PLATFORM.moduleName('apps/cms/routes/live-events/live-events/single/event-assets-delete.html'),
        viewModel: PLATFORM.moduleName('apps/cms/routes/live-events/live-events/single/event-assets-delete'),
        getTdClass() {
          return 'table__mark-delete';
        },
      }),
    ];
    this.assets = [];
    this.event = null;
    this.pollId = null;
    this.autoexpire = 0;
    this.changeAutoExpire = 0;
    this.canAutoExpire = false;
    this.loading = true;
    this.selectedRecords = [];
    this.tdSignal = 'data-updated';

    this.dataTableActions = {
      onSelectionChange: selection => {
        this.selectedRecords = selection;
      },
    };

    this.eventIdSubscription = this.bindingEngine.propertyObserver(this.router, 'eventId').subscribe(newVal => {
      this.eventChanged(newVal);
    });
  }

  attached() {
    this.liveEvents.fetchLiveEventPromise.then(() => {
      this.eventChanged(this.router.eventId);
    });
  }

  detached() {
    clearInterval(this.pollId);
    this.eventIdSubscription.dispose();
  }

  pollAncillaryAssets() {
    clearInterval(this.pollId);
    this.pollId = setInterval(() => {
      this.liveEvents
        .getAncillaryAssets(this.eventId, true)
        .then(assets => {
          this.updateAssets(assets);
          this.bindingSignaler.signal(this.tdSignal);
        })
        .catch(() => {})
        .finally(() => {
          this.findEarliestExpiration();
        });
    }, POLL_EVERY_MS);
  }

  eventChanged(eventId) {
    if (!eventId) {
      return;
    }

    this.loading = true;
    this.canAutoExpire = false;
    this.eventId = eventId;

    this.columns[this.columns.length - 1].isVisible =
      this.liveEvents.origEvent.state === 'complete' || this.liveEvents.origEvent.sandbox === true;

    this.liveEvents
      .getAncillaryAssets(this.eventId)
      .then(assets => {
        this.assets = assets;
        this.findEarliestExpiration();
        this.changeAutoExpire = this.autoexpire;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });

    this.liveEvents
      .getLiveEvent(this.eventId)
      .then(event => {
        this.event = event;
        // Since we can expire after the event ends - need to always poll
        this.pollAncillaryAssets();
      })
      .catch(() => {});
  }

  updateAssets(assets) {
    assets.forEach(asset => {
      const existing = this.findAssetById(asset.id);
      if (existing) {
        if (asset.deleted) {
          // Remove from our list
          this.assets.splice(existing.index, 1);
        } else {
          // Update the item
          Object.assign(this.assets[existing.index], asset);
        }
      } else if (!asset.deleted) {
        // Add the item - only if it isn't deleted though
        this.assets.unshift(asset);
      }
    });
  }

  findAssetById(assetId) {
    // returns asset and index in
    let rval = false;
    this.assets.forEach((asset, index) => {
      if (assetId === asset.id) {
        rval = {asset, index};
        return rval;
      }
      return false;
    });
    return rval;
  }

  findEarliestExpiration() {
    // We don't allow auto expire change on saved assets or final VOD
    let _canAutoExpire = false;
    let _autoexpire = 0;

    this.assets.forEach(asset => {
      if (!asset.event_vod && !asset.event_save) {
        if (_autoexpire === 0 || asset.autoexpire < _autoexpire) {
          _autoexpire = asset.autoexpire;
        }
        _canAutoExpire = true;
      }
    });

    this.canAutoExpire = _canAutoExpire;
    this.autoexpire = _autoexpire;
  }

  deleteSelectedAssets() {
    if (this.isAssetDeleting) {
      return;
    }

    const selectedRecords = [];
    this.assets.forEach(asset => {
      if (asset.deleted) {
        selectedRecords.push(asset.id);
      }
    });
    const {dialogService} = this;
    let deleteWarning = false;

    const postModel = {
      header: 'Warning',
      question: `One or more selected assets have either been saved to the All Content tab,
                or is the Event's Final VOD.`,
      yes: "I don't care, delete them.",
      no: 'I changed my mind.',
      hideCancel: true,
      footerClass: 'btn-split',
    };

    this.assets.forEach(asset => {
      if (selectedRecords.includes(asset.id)) {
        if (asset.event_vod || asset.event_save) {
          deleteWarning = true;
        }
      }
    });

    if (deleteWarning) {
      dialogService
        .open({viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'), model: postModel})
        .whenClosed(data => {
          if (data.output === true) {
            this.deleteEventAssets(selectedRecords);
          }
        });
    } else {
      this.deleteEventAssets(selectedRecords);
    }
  }

  deleteEventAssets(ids) {
    this.isAssetDeleting = true;
    return new Promise((resolve, reject) => {
      this.liveEvents.httpClient
        .fetch('/assets/delete/', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({asset_ids: ids.join()}),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                // handle API error.
                reject(new Error(res.msg));
              } else {
                this.notification.success('The selected assets were deleted.');

                // remove from deleted assets from assets list
                // remove from data and sessionStorage
                for (let i = this.assets.length - 1; i >= 0; i -= 1) {
                  const asset = this.assets[i];
                  if (ids.includes(asset.id)) {
                    this.assets.splice(i, 1);
                  }
                }

                // Removed selectedRecords
                this.selectedRecords.splice(0, this.selectedRecords.length);
                resolve(res);
              }
              this.findEarliestExpiration();
            });
          }
        })
        .catch(err => {
          this.notification.error(err);
        })
        .finally(() => {
          this.findEarliestExpiration();
          this.isAssetDeleting = false;
        });
    });
  }

  saveAutoExpire() {
    if (this.isAutoExpireUpdating) {
      return;
    }
    const assetIds = [];

    this.assets.forEach(asset => {
      if (!asset.event_vod && !asset.event_save) {
        assetIds.push(asset.id);
      }
    });

    if (assetIds.length) {
      this.updateAutoExpires(assetIds);
    }

    this.datePickerModal.close();
  }

  updateAutoExpires(ids) {
    const _now = Math.floor(new Date().getTime());
    // If the never expire checkbox is selected - use 0
    if (this.neverExpire.checked) {
      this.changeAutoExpire = 0;
    } else if (this.changeAutoExpire <= _now) {
      // Use now if the date-picker is set to be in the past
      this.changeAutoExpire = _now;
    }

    this.isAutoExpireUpdating = true;
    return new Promise((resolve, reject) => {
      this.liveEvents.httpClient
        .fetch('/live-event/asset-auto-expire/update', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: this.model.id,
            asset_ids: ids.join(),
            auto_expire: this.changeAutoExpire,
          }),
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              if (res.error) {
                // handle API error.
                this.notification.error(res.msg);
                reject(res.msg);
              } else {
                this.notification.success('Auto Expire Updated.');
                this.autoexpire = this.changeAutoExpire;
                resolve(res);
              }
            });
          }
        })
        .catch(err => {
          this.notification.error(err);
          reject(err);
        })
        .finally(() => {
          this.isAutoExpireUpdating = false;
        });
    });
  }

  setRowClass(record) {
    return record.deleted ? 'table__primary-cells' : '';
  }

  autoExpireChangeCallback = {
    /**
     * Updates model with newValue.
     */
    onChange: timestamp => {
      if (timestamp === '') {
        this.changeAutoExpire = 0;
      } else {
        this.changeAutoExpire = Number(timestamp);
      }
    },
  };
}
