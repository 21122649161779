import {autoinject, bindable, computedFrom} from 'aurelia-framework';

import {authorizationConstants} from 'services/authorization';
import {BetaMode} from 'services/beta-mode';
import {
  SYNDICATION_TARGET_PLATFORM_BETA,
  SYNDICATION_TARGET_PLATFORM_PROTOCOLS,
  SYNDICATION_TARGET_PROTOCOL_BETA,
  SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS,
  SyndicationPlatformType,
  SyndicationTargetProtocolType
} from "services/models/syndication";
import {SyndicationClippingProfile} from 'services/models/syndication-facebook-page';
import {SyndicationTargetService} from 'services/syndication-target-service';

@authorizationConstants
@autoinject()
export class PublishingDetails {
  public faceBookPagesFetched: boolean = false;
  public faceBookPagesList: object[] = [];
  public clippingProfilesList: object = {};

  @bindable public callbacks;
  @bindable public protocolOption;
  @bindable public platformOption;
  public target: any;

  public eventListeners = {
    focus: () => {
      if (this.target.target_stream === '******') {
        this.target.target_stream = '';
      }
    },
  };

  private options = [
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.RTMP],
      value: SyndicationTargetProtocolType.RTMP,
      db_value: SyndicationTargetProtocolType.RTMP,
    },
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.RTMPS],
      value: SyndicationTargetProtocolType.RTMPS,
      db_value: SyndicationTargetProtocolType.RTMPS,
    },
    {
      db_value: SyndicationTargetProtocolType.RTMP_PULL,
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.RTMP_PULL],
      value: SyndicationTargetProtocolType.RTMP_PULL,
    },
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.ZIXI],
      value: SyndicationTargetProtocolType.ZIXI,
      db_value: SyndicationTargetProtocolType.ZIXI,
    },
    {
      db_value: SyndicationTargetProtocolType.HLS_PUSH,
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.HLS_PUSH],
      value: SyndicationTargetProtocolType.HLS_PUSH,
    },
    {
      db_value: SyndicationTargetProtocolType.HLS_PULL,
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.HLS_PULL],
      value: SyndicationTargetProtocolType.HLS_PULL,
    },
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.SRT],
      value: SyndicationTargetProtocolType.SRT,
      db_value: SyndicationTargetProtocolType.SRT,
    },
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.RIST],
      value: SyndicationTargetProtocolType.RIST,
      db_value: SyndicationTargetProtocolType.RIST,
    },
  ];

  constructor(public betaMode: BetaMode, public syndicationTargetService: SyndicationTargetService) {
    this.callbacks = {
      onChangeFbIntegrated: async (integrated: boolean) => {
        if (integrated) {
          if (!this.faceBookPagesFetched) {
            this.fetchFaceBookPages(this.target.platform_config.clipping_profile_id);
          }
          return this.faceBookPagesList;
        }
      },
    };
  }

  @computedFrom('SyndicationTargetProtocolType', 'target.platform')
  get protocolOptionsList() {
    return _.map(
      _.filter(this.options, info => {
        if (
          SYNDICATION_TARGET_PLATFORM_PROTOCOLS.hasOwnProperty(this.target.platform) &&
          !SYNDICATION_TARGET_PLATFORM_PROTOCOLS[this.target.platform].includes(info.db_value)
        ) {
          return false;
        }

        if (SYNDICATION_TARGET_PROTOCOL_BETA.includes(info.db_value) && !this.betaMode.hasScope(info.db_value)) {
          return false;
        }

        return true;
      }),
      info => {
        if (
          this.betaMode.hasScope(info.db_value) &&
          SYNDICATION_TARGET_PROTOCOL_BETA.includes(info.db_value) &&
          !info.text.includes('(beta)')
        ) {
          info.text += ' (beta)';
        }
        return {text: info.text, value: info.value};
      },
    );
  }

  @computedFrom('SyndicationPlatformType')
  get platformOptions() {
    return _.map(
      _.filter(
        SyndicationPlatformType,
        platformType => {
          if(!SYNDICATION_TARGET_PLATFORM_BETA.includes(platformType)) {
            return true;
          }
          return this.betaMode.hasScope(platformType);
        }
      ),
      info => ({text: info, value: info}),
    );
  }

  get showStreamUri() {
    if (
      [
        SyndicationPlatformType.FACEBOOK,
        SyndicationPlatformType.YOUTUBE,
      ].includes(this.target.platform) &&
      this.target.platform_config.integrated
    ) {
      return false;
    }
    return [
      SyndicationTargetProtocolType.RTMP,
      SyndicationTargetProtocolType.RTMPS,
      SyndicationTargetProtocolType.HLS_PUSH,
      SyndicationTargetProtocolType.SRT,
      SyndicationTargetProtocolType.RIST,
    ].includes(this.target.target_protocol);
  }

  get onlyStreamKeyPlatform() {
    return [
      SyndicationPlatformType.FACEBOOK,
      SyndicationPlatformType.YOUTUBE,
      SyndicationPlatformType.TWITCH,
      SyndicationPlatformType.TIKTOK,
    ].includes(this.target.platform);
  }

  get faceBookPages() {
    if (!this.faceBookPagesFetched) {
      this.fetchFaceBookPages(this.target.platform_config.clipping_profile_id);
    }
    return this.faceBookPagesList;
  }

  get clippingProfiles() {
    const {platform} = this.target;
    if (!this.clippingProfilesList.hasOwnProperty(platform)) {
      // fake temp empty array until fetchClippingProfiles will get response from server
      this.clippingProfilesList[platform] = [];
      this.fetchClippingProfiles(platform).then(data => {
        this.clippingProfilesList[platform] = data;
      });
    }
    return this.clippingProfilesList[platform];
  }

  public activate(model) {
    const result = _.find(this.options, {db_value: model.shared.target_protocol});
    this.target = model.shared;
    this.protocolOption = result.value;
    this.platformOption = this.target.platform;
  }

  public platformOptionChanged(newValue) {
    this.target.platform = newValue;
    // set the first protocol option temporary to force trigger the protocol change
    this.target.target_protocol = this.options[0].db_value;
    if (SYNDICATION_TARGET_PLATFORM_PROTOCOLS.hasOwnProperty(newValue)) {
      this.target.target_protocol = SYNDICATION_TARGET_PLATFORM_PROTOCOLS[newValue][0];
      this.resetFields();
    }

    // only for new FB/YT targets , set as integrated by default
    if (
      (!this.target.hasOwnProperty('id') || !this.target.id) &&
      [
        SyndicationPlatformType.FACEBOOK,
        SyndicationPlatformType.YOUTUBE,
      ].includes(newValue)
    ) {
      this.target.platform_config.integrated = true;
    }
  }

  public protocolOptionChanged(newValue) {
    const result = _.find(this.options, {value: newValue});
    this.target.target_protocol = result.db_value;
  }

  public clippingProfileChanged(clipping_profile_id: string = '') {
    const cp_id = clipping_profile_id || this.target.platform_config.clipping_profile_id;
    if (this.target.platform === SyndicationPlatformType.FACEBOOK && cp_id) {
      this.fetchFaceBookPages(cp_id);
    }
  }

  public async fetchFaceBookPages(profile_id: string) {
    this.faceBookPagesFetched = true;
    return await this.syndicationTargetService
      .getSyndicationTargetFaceBookPages(profile_id)
      .then(() => {
        const faceBookPagesList = _.cloneDeep(this.syndicationTargetService.syndicationFaceBookPages);
        this.faceBookPagesList = _.map(faceBookPagesList, fbPage => ({text: fbPage.name, value: fbPage.id}));
        this.faceBookPagesList.unshift({text: 'Please Choose', value: ''});
        return this.faceBookPagesList;
      })
      .catch(() => []);
  }

  public async fetchClippingProfiles(platform: string = '') {
    return await this.syndicationTargetService
      .getSyndicationTargetClippingProfiles(platform)
      .then((data: SyndicationClippingProfile[]) => {
        const items = _.map(data, item => ({text: item.name, value: item.id}));
        items.unshift({text: 'Please Choose', value: ''});
        return items;
      })
      .catch(() => []);
  }

  public resetFields() {
    this.protocolOption = this.target.target_protocol;

    this.target.platform_config = {
      port: 2088,
      integrated: !(this.target && this.target.id),
      re_schedule: false,
    };
  }

  public getTargetProtocolText(targetProtocol) {
    return SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[targetProtocol];
  }
}
