import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {OwnerDefaultProfiles} from '../models/default-profiles';

@autoinject
export class OwnerDefaultProfilesService {
    private url: string = '/api/v4/owner/default-profiles';

    constructor(public acceo: Acceo) {}

    public postOwnerDefaultProfiles(body: OwnerDefaultProfiles) {
        return this.acceo.post(OwnerDefaultProfiles)(this.url, body);
    }
}
