import {bindable} from 'aurelia-framework';
import * as styles from './b-timeline-month-item.css';

export class BTimelineMonthItem {
  @bindable
  public dayDate;

  @bindable
  public activeMonth = true;

  @bindable
  public today = false;

  public styles = styles;

  public attached() {
    if (typeof this.activeMonth !== 'boolean') {
      this.activeMonth = true;
    }

    if (typeof this.today !== 'boolean') {
      this.today = false;
    }
  }
}
