import {TextReader} from './text-reader';

export class TextFile {
    public static save(file: File) {
        return new Promise<string>(async (resolve, reject) => {
            await new TextReader().load(file).then(
                value => {
                    const element = document.createElement('a');
                    element.setAttribute('href', `data:${file.type};charset=utf-8,${value}`);
                    element.setAttribute('download', file.name);
                    element.style.display = 'none';
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    resolve(value);
                },
                error => {
                    reject(error);
                },
            );
        });
    }

    public static open(accept?: string[]) {
        return new Promise<string>(async (resolve, reject) => {
            const element = document.createElement('input');
            element.setAttribute('type', 'file');
            element.setAttribute('accept', (accept || ['.txt', '.text']).join(','));
            element.addEventListener('change', () => {
                new TextReader().load(element.files[0]).then(
                    value => {
                        resolve(value);
                    },
                    error => {
                        reject(error);
                    },
                );
            });
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        });
    }
}
