import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {HyperionPolling} from 'services/hyperion-polling';
import {DialogService} from 'aurelia-dialog';
import {SessionService} from 'services/session';
import {Toast} from 'resources/toast/toast';
import {SlicerService} from '../../services/slicer-service';
import {Slicer} from '../../models/slicer';
import {RouteName} from '../index';

const logger = LogManager.getLogger('On-prem Slicers');

@autoinject()
export class SlicersList {
  public isLoading: boolean = true;
  public isRestarting: boolean = false;
  public searchText: string = '';
  public slicers: Slicer[] = [];
  public selectedCount: number = 0;

  public stateColor = {
    Inactive: 'neutral',
    Slicing: 'success', // green
    Ads: 'ad', // blue
    // 'Replacing content': '',
    Blackout: '', // gray
  };

  protected hyperionPollingInterval = 5000;

  constructor(
    public slicerService: SlicerService,
    public router: Router,
    public hyperionPolling: HyperionPolling,
    public dialogService: DialogService,
    public session: SessionService,
  ) {
    this.hyperionPolling = new HyperionPolling({
      callbackFn: updatedSlicers => {
        this.slicers = _.orderBy(updatedSlicers, 'id', 'asc');
      },
      ms: this.hyperionPollingInterval,
      promiseFn: this.requestReadInterval.bind(this),
      useAfter: true,
    });
  }

  public requestReadInterval() {
    return Promise.resolve(this.slicerService.getSlicers());
  }

  public async attached() {
    this.isLoading = true;
    try {
      this.slicers = _.orderBy(await this.slicerService.getSlicers(), 'id', 'asc');
    } catch (err) {
      Toast.danger('Error loading On-Prem Slicers');
      logger.error(err);
    }
    this.hyperionPolling.start();
    this.isLoading = false;
  }

  @computedFrom('searchText', 'slicers')
  public get slicersFiltered() {
    return (
      this.slicers && this.slicers.filter(slicer => slicer.id.toLowerCase().includes(this.searchText.toLowerCase()))
    );
  }

  public rowClick(_event, slicer: Slicer) {
    const routeObj = this.router.routes.find(x => x.name === RouteName.SlicerSingle);
    routeObj.settings.data = {
      slicer,
      index: _.findIndex(this.slicers, {id: slicer.id}),
      slicers: this.slicers,
    };

    this.router.navigate(`/slicers/on-prem/${slicer.id}`);
  }

  public async onClickAction(_event, slicer: Slicer, action: string) {
    if (slicer.isRestarting) {
      return;
    }

    try {
      slicer.isRestarting = true;
      await this.slicerService.restartSlicer(slicer.id);
      Toast.success(`Restart of On-Prem Slicer (${slicer.id}) initiated`);
    } catch (error) {
      Toast.danger(`Failed to ${action} slicer: ${error}`);
    } finally {
      slicer.isRestarting = false;
    }
  }

  public detached() {
    this.hyperionPolling.stop();
  }
}
