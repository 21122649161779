import {ITimeEntryBasic} from '@bindable-ui/bindable';
import {ScheduleEntry} from 'apps/cms/routes/live-channels/channels/single/models/event-model';

interface IScheduledEntryTimeEntry extends ITimeEntryBasic {
  model: ScheduleEntry;
}

export class TimeEntryValueConverter {
  public toView(items = []) {
    return items.map((item: ScheduleEntry | ITimeEntryBasic) => {
      if (item instanceof ScheduleEntry) {
        return mapScheduleEntry(item);
      }

      return item;
    });
  }
}

function mapScheduleEntry(item) {
  const icons = [];
  const now = new Date().getTime();
  const itemStart = new Date(item.start).getTime();
  const itemEnd = new Date(item.end).getTime();

  let accentColor = null;
  let active = false;
  let background = null;
  const color = null;
  let sizeDay = 'small';
  let sizeWeek = 'expandable';
  let title = item.desc;

  if (now > itemStart && now < itemEnd) {
    active = true;
  }

  if (item.isAdBreak) {
    accentColor = '#359AC5';
    icons.push('ad-break');
    sizeDay = null;
    sizeWeek = null;
  }

  if (item.isSlicer) {
    icons.push('slicer');
    title = item.content_id;
    sizeDay = null;
    sizeWeek = null;
  }

  if (item.isAsset) {
    accentColor = '#8E5DB2';
    icons.push('play');
    sizeDay = null;
    sizeWeek = null;
  }

  if (item.isRepeat) {
    accentColor = '#8E5DB2';
    icons.push('repeat');
    sizeDay = null;
    sizeWeek = null;
  }

  if (item.isReplaced) {
    background =
      "#252525 url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23161616' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E\")";

    title = `${title} (REPLACED)`;
  }

  if (item.isMatchSignal) {
    background = '#111';
    icons.push('signal');
  }

  if (item.isMatchTime) {
    background = '#111';
    icons.push('time');
  }

  if (item.rules.length > 0) {
    icons.push('rules');
  }

  const entry: IScheduledEntryTimeEntry = {
    accentColor,
    active,
    background,
    color,
    icons,
    sizeDay,
    sizeWeek,
    title,
    duration: (item.dur || 0) / 1000,
    end: item.end,
    model: item,
    start: item.start,
  };

  return entry;
}
