import {inject} from 'aurelia-framework';
import {SessionService} from './session';

@inject(SessionService)
export class AuthorizeStep {
    constructor(sessionService) {
        this.sessionService = sessionService;
    }

    run(navigationInstruction, next) {
        // From Keith - I'm removing this (with this return) - not checking the session for each
        // Route Switch - I really don't think we need to do this since all of our calls are session
        // checking anyway through the cms-http-router stuff - the only differnce I can see by removing
        // this call - is a performance gain when switching routes

        // Check the session on route change
        // return this.sessionService.check().then((r) => {
        //     // if (r.haveSession === false || r.error && r.msg === "Invalid session") {
        //     //     var cur = window.location.href;
        //     //     window.location.href = r.login + '?login_redirect=' + encodeURIComponent(cur);
        //     // }
        //     return next();
        // });
        return next();
    }
}
