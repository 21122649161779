import {bindable} from 'aurelia-framework';
// tslint:disable-next-line:import-name
import * as moment from 'moment-timezone';

import * as styles from './v-inline-clock.css';

export class VInlineClock {
    @bindable
    public serverOffset = 0;

    @bindable
    public timezone: string = null;

    @bindable
    public textSize: string = 'var(--s3)';

    @bindable
    public textColor: string = 'var(--c_marshmellow)';

    @bindable
    public height: string = '40px';

    public styles = styles;

    public currentTime: string = null;

    private timeTracker;

    public attached() {
        this.setTime();
        this.timeTracker = setInterval(() => this.setTime(), 1000);
    }

    public detached() {
        if (this.timeTracker) {
            clearInterval(this.timeTracker);
        }
    }

    private setTime() {
        let time = moment().add(this.serverOffset, 'seconds');

        if (this.timezone) {
            time = time.tz(this.timezone);
        }

        this.currentTime = time.format('HH:mm:ss');
    }
}
