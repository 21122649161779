import {MSOSlicerClassification} from '../../models/msoslicer';

export class Config {
  public input = new ConfigInput();
  public options = new ConfigOptions();
  public state = new ConfigState();
}

export class ConfigInput {
  public classification: string;
  public search: string;
  public searchKeys: string[] = [];
  public searchQuery: string;
  public showDeleted: boolean = false;
}

class ConfigOptions {
  public classification = [
    {
      text: 'All Classifications',
      value: null,
    },
    {
      text: 'Demo',
      value: MSOSlicerClassification.DEMO,
    },
    {
      text: 'Development',
      value: MSOSlicerClassification.DEVELOPMENT,
    },
    {
      text: 'Production',
      value: MSOSlicerClassification.PRODUCTION,
    },
    {
      text: 'Proof of Concept',
      value: MSOSlicerClassification.PROOF_OF_CONCEPT,
    },
  ];

  public serverLocation: ISelectableOption[];
  public serverRegion: ISelectableOption[];
  public streamProtocol: ISelectableOption[];
}

class ConfigState {
  public canExportCSV: boolean;
  public canViewClassification: boolean;
  public canViewDeleted: boolean;
  public isDeleting: boolean;
  public isLoading: boolean;
  public isRestarting: boolean;
}

interface ISelectableOption {
  text: string;
  value: string;
}
