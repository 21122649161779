declare const window: any;

/**
 * Downloads a file with the given name and blob content.
 */

export function downloadFile(filename: string, blob: any) {
    const windowURL = window.URL || window.webkitURL;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = windowURL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    windowURL.revokeObjectURL(blob);
    document.body.removeChild(a);
}
