import {inject} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';

@inject(BindingSignaler)
export class EventAssetsActions {
    constructor(bindingSignaler) {
        this.bindingSignaler = bindingSignaler;
    }

    activate(model) {
        this.asset = model.record;
    }
}
