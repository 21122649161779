import {BaseTimeEntry} from './base';

export class ViewTimeEntry extends BaseTimeEntry {
  public type: string = 'Live Asset';
  public icon: string = 'live-event';
  public isHistoryView: boolean = true;

  public activate(model) {
    super.activate(model);

    //  console.log(this.model);
  }
}
