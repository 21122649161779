import {CToastsService} from '@bindable-ui/bindable';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable, BindingEngine, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {FailoverGroup, FailoverGroupSlicer} from '../../models/failover-group';
import {FailoverGroupService} from '../../services/failover-group-service';
import {FailoverGroupEvent} from './index';

const logger = LogManager.getLogger('Failover Group');

@autoinject()
export class BaseGroup {
  @bindable
  public group: FailoverGroup;

  @bindable
  public groups: FailoverGroup[];

  @bindable
  public originalGroup: FailoverGroup;

  @bindable
  public isDirty: boolean;

  public isLoading: boolean = true;
  public hasNameChanged: boolean = false;
  public haveSlicersChanged: boolean = false;
  public haveChannelsChanged: boolean = false;
  public haveThresholdsChanged: boolean = false;
  public hasModeChanged: boolean = false;
  public hasHotWarmChanged: boolean = false;
  public hasAutoFailoverChanged: boolean = false;
  public subscriptions: Subscription[] = [];
  public logger = logger;

  public failoverGroupService: FailoverGroupService;
  public eventAggregator: EventAggregator;
  public bindingEngine: BindingEngine;
  public router: Router;
  public toastsService: CToastsService;

  public attached() {
    try {
      this.isLoading = false;
      this.subscriptions.push(this.eventAggregator.subscribe(FailoverGroupEvent.SAVE, this.save.bind(this)));
    } catch (e) {
      logger.error(e);
    }
  }

  public async save() {
    try {
      if (!this.group.name) {
        this.toastsService.error('Failover Group Name is required.');
      } else if (
        _.findIndex(
          _.filter(this.groups, g => g.id !== this.group.id),
          [
            'name',
            this.group.name,
          ],
        ) !== -1
      ) {
        this.toastsService.error('Failover Group Name already in use.');
      } else {
        this.isLoading = true;

        const updateParams: {[key: string]: any} = {
          auto_failback: this.group.auto_failback,
          hot_warm: parseInt(String(this.group.hot_warm), 10),
          mode: this.group.mode,
          name: this.group.name,
        };

        if (this.haveSlicersChanged) {
          const slicers = {};
          _.each(this.group.slicers, fs => {
            slicers[fs.id] = {
              force_blacklist: !fs.enabled,
              priority: this.group.mode !== 'flat' ? fs.order : 1,
            };
          });
          updateParams.slicers = slicers;
        }

        if (this.haveChannelsChanged) {
          updateParams.channels = _.map(this.group.channels, 'id');
        }

        if (this.group.thresholds && this.haveThresholdsChanged) {
          const thresholds = {};
          _.each(this.group.thresholds, t => {
            const keys = Object.keys(t.data);
            _.each(keys, k => {
              thresholds[t.status ? k : `_${k}`] = t.data[k].value;
            });
          });
          updateParams.thresholds = thresholds;
        }

        const group = (await this.failoverGroupService.updateFailoverGroup(
          this.group.id,
          updateParams,
        )) as FailoverGroup;

        this.isDirty = false;
        this.haveSlicersChanged = false;
        this.hasNameChanged = false;
        this.haveChannelsChanged = false;
        this.haveThresholdsChanged = false;

        this.group.slicers = _.map(this.group.slicers, s =>
          Object.assign(new FailoverGroupSlicer(), JSON.parse(JSON.stringify(s))),
        );

        this.group.channels = group.channels;

        this.originalGroup = _.cloneDeep(this.group);
        this.isLoading = false;
      }
    } catch (e) {
      this.logger.error(e);
      this.displayErrorInformation(e);
    } finally {
      this.isLoading = false;
    }
    this.eventAggregator.publish(FailoverGroupEvent.SAVE_COMPLETE);
  }

  public detached() {
    while (this.subscriptions.length) {
      this.subscriptions.pop().dispose();
    }
  }

  public isEqual(a, b) {
    if (a && b && a.length !== b.length) {
      return false;
    }
    let equal = true;
    _.each(a, (s, k) => {
      if (!_.isEqual(_.pick(a[k], Object.keys(s)), _.pick(b[k], Object.keys(s)))) {
        equal = false;
      }
    });
    return equal;
  }

  public dirtyCheck(_newGroup, _oldGroup) {
    this.isLoading = true;

    if (!this.originalGroup || !_.isEqual(_newGroup.id, _oldGroup.id)) {
      this.originalGroup = _.cloneDeep(this.group);
    }

    this.hasNameChanged = !_.isEqual(this.originalGroup.name, this.group.name);

    this.originalGroup.slicers = _.map(this.originalGroup.slicers, s =>
      Object.assign(new FailoverGroupSlicer(), JSON.parse(JSON.stringify(s))),
    );

    this.group.slicers = _.map(this.group.slicers, s =>
      Object.assign(new FailoverGroupSlicer(), JSON.parse(JSON.stringify(s))),
    );

    this.haveSlicersChanged = !_.isEqualWith(
      _.map(_.cloneDeep(this.originalGroup.slicers), s => {
        delete s.status;
        return s;
      }),
      this.group.slicers,
      this.isEqual,
    );

    this.haveChannelsChanged = !_.isEqualWith(this.originalGroup.channels, this.group.channels, this.isEqual);
    this.haveThresholdsChanged = !_.isEqual(this.originalGroup.thresholds, this.group.thresholds);
    this.hasHotWarmChanged = !_.isEqual(this.originalGroup.hot_warm, this.group.hot_warm);
    this.hasModeChanged = !_.isEqual(this.originalGroup.mode, this.group.mode);
    this.hasAutoFailoverChanged = !_.isEqual(this.originalGroup.auto_failback, this.group.auto_failback);

    this.isLoading = false;
    this.isDirty =
      this.hasNameChanged ||
      this.haveSlicersChanged ||
      this.haveChannelsChanged ||
      this.hasHotWarmChanged ||
      this.hasAutoFailoverChanged ||
      this.haveThresholdsChanged ||
      this.hasModeChanged;
  }

  public displayErrorInformation(errorInfo) {
    if (errorInfo.details !== undefined && errorInfo.details.length) {
      _.forEach(errorInfo.details, detail => {
        this.toastsService.error(detail);
      });
    } else if (errorInfo.description !== undefined) {
      this.toastsService.error(errorInfo.description);
    } else if (errorInfo.message !== undefined) {
      this.toastsService.error(errorInfo.message);
    }
  }
}
