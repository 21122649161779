export class AddFooter {
    public controller: any;
    public model: any;
    public shared: any;

    public activate(model) {
        this.controller = model.controller;
        this.model = model.footer;
        this.shared = model.shared;
    }

    public async addValue() {
        let errMsg = null;

        if (this.model.updateCallBack) {
            errMsg = await this.model.updateCallBack();
        }

        if (!errMsg) {
            this.controller.close();
        } else {
            this.shared.errMsg = errMsg;
        }
    }
}
