import * as CTableCssJson from '@bindable-ui/bindable/dist/native-modules/components/tables/c-table/c-table.css.json';
import {TemplatesAccess} from 'apps/cms/routes/slicers/models/template';
import {PLATFORM} from 'aurelia-framework';
import {CTableSelectable} from 'resources/components/c-table-selectable/c-table-selectable';
import {
    CTableColSelectable,
    CTableRowBehavior,
} from 'resources/components/c-table-selectable/c-table-selectable-models';
import {ITemplateListTableRow} from './table-models';

export class TemplateListTable extends CTableSelectable {
    public rows: ITemplateListTableRow[];

    protected colsSelectableDefault: CTableColSelectable[] = [
        {
            colHeadName: 'id',
            colHeadValue: 'ID',
            selected: false,
            sort: true,
        },
        {
            colClass: this.getClass(CTableCssJson.t350),
            colHeadName: 'name',
            colHeadValue: 'Name',
            selected: true,
            sort: true,
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
            ),
        },
        {
            colHeadName: 'desc',
            colHeadValue: 'Description',
            selected: true,
            sort: true,
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
            ),
        },
        {
            colClass: this.getClass(CTableCssJson.t120),
            colHeadName: 'access',
            colHeadValue: 'Access',
            selected: true,
            sort: true,
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-truncate/c-td-truncate',
            ),
        },
        {
            colClass: this.getClass(CTableCssJson.t240),
            colHeadName: 'created',
            colHeadValue: 'Date Created',
            selected: false,
            sort: true,
            valueConverter: 'timezoneTimeToStandardDateTimeMS',
        },
        {
            colClass: this.getClass(CTableCssJson.t240),
            colHeadName: 'lastmod',
            colHeadValue: 'Date Modified',
            selected: true,
            sort: true,
            valueConverter: 'timezoneTimeToStandardDateTimeMS',
        },
    ];

    constructor(
        rowClick: (row: ITemplateListTableRow) => void,
        rowBehavior: CTableRowBehavior = CTableRowBehavior.CHECK,
    ) {
        super(rowBehavior);
        this.colsSelectable = this.colsSelectableDefault;
        this.actions.getColClass = this.getColClass;
        this.actions.rowClick = rowClick;
    }

    public addRowProperties(row: ITemplateListTableRow) {
        row.accessIcon = row.access === TemplatesAccess.PUBLIC ? (row.isReadOnly ? undefined : 'warning') : 'lock';
        row.accessIconSize = '1em';
        // valueConverter isn't supported with c-td-truncate & this also makes exported csv prettier
        row.access = row.isReadOnly ? 'Read Only' : _.capitalize(row.access);
        // only show 'default' and 'favorite' indicators for templates the user owns
        if (!row.isReadOnly) {
            if (row.default) {
                row.namePill = 'Default';
                row.namePillColor = 'var(--c_secondaryMain)';
            }
            if (row.favorite) {
                row.nameIcon = 'star';
                row.nameIconSize = '1em';
            }
        }
        if (row.isReadOnly) {
            row.selectedState = 'disabled';
        }
        return row;
    }

    private getColClass(row: ITemplateListTableRow, col: CTableColSelectable) {
        if (col.colHeadName === 'access') {
            if (!row.isReadOnly) {
                if (row.access.toLowerCase() === TemplatesAccess.PUBLIC) {
                    return 'bgWarning';
                }
            }
        }
        return '';
    }
}
