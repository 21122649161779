import {bindable, computedFrom} from 'aurelia-framework';

export class DataCell {
    @bindable
    col;

    @bindable
    record;

    @bindable
    prop;

    @computedFrom('record', 'prop')
    get cls() {
        const {record} = this;
        if (!this.col.getClass) {
            return '';
        }

        return this.col.getClass(record);
    }
}
