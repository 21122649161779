import {Subscription} from 'aurelia-event-aggregator';
import {BindingEngine, inject} from 'aurelia-framework';
import {dirtyCheckPrompt} from 'decorators';
import * as DOMPurify from 'dompurify';
import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';
import {BaseSettingsUtils} from '../base-settings';
import {ExpandedParameters, ParamExpansionSettingService} from './services/parameter-expansion-service';

const ERROR = {
    exceedMaxLength: false,
    isDup: false,
    isMissing: false,
    notUrlSafe: false,
};

export const ERROR_OBJ = {
    expandedParam: {...ERROR},
    hasErr: false,
    param: {...ERROR},
};

const MAX_LENGTH = {
    expandedParam: 512,
    param: 32,
};

interface ParameterPair {
    expandedParam: string;
    param: string;
}

interface ErrorObject {
    exceedMaxLength: boolean;
    isDup?: boolean;
    isMissing?: boolean;
    notUrlSafe?: boolean;
    hasErr?: boolean;
}

interface ErrorObjects {
    param: ErrorObject;
    expandedParam: ErrorObject;
    hasErr: boolean;
}

interface ErrorMessages {
    [index: string]: ErrorObjects;
}

@dirtyCheckPrompt
@inject(Notification, ParamExpansionSettingService, SessionService, BindingEngine)
export class ParameterExpansion extends BaseSettingsUtils {
    public parameterItems: ExpandedParameters;
    public paramsArray: ParameterPair[];
    public paramsArrayPristine: ParameterPair[];
    public canAddParams: boolean;
    public settingsTabRW: boolean = false;
    public settingsTabRO: boolean = false;
    public canSave: boolean;
    public errorMessages: ErrorMessages = {};
    public newPairError: ErrorObjects;
    public hasErr: boolean;
    public paramNew: string;
    public expandedParamNew: string;
    public isFormDirty: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(
        public notification: Notification,
        public paramExpansionSettingService: ParamExpansionSettingService,
        public sessionService: SessionService,
        public bindingEngine: BindingEngine,
    ) {
        super(notification, paramExpansionSettingService, sessionService);
    }

    public initializeProperties(): void {
        const parameterItems = _.get(this.model, 'params', {});
        this.paramsArray = this.sortAndTransform(parameterItems);
        this.paramsArrayPristine = _.cloneDeep(this.paramsArray);
        this.subscriptions = [
            this.bindingEngine.collectionObserver(this.paramsArray).subscribe(() => {
                this.isFormDirty = !_.isEqual(this.paramsArray, this.paramsArrayPristine);
            }),
        ];

        if (this.entitlements.indexOf('settings') > -1 || this.isAdmin) {
            this.settingsTabRW = true;
        } else if (this.entitlements.indexOf('settingsro') > -1) {
            this.settingsTabRO = true;
        }

        this.canAddParams = this.getCanAddParams();
    }

    public sortAndTransform(parameterItems): ParameterPair[] {
        const sortedParams = Object.keys(parameterItems).sort();
        const sortedPairs = sortedParams.map(param => ({
            param,
            expandedParam: _.get(parameterItems, param),
        }));
        return sortedPairs;
    }

    // validate if not disable save, show error
    public checkIsValid(target: string, index: number): void {
        const value = _.get(this.paramsArray[index], target);
        const itemError = {..._.cloneDeep(ERROR_OBJ)};
        this.errorMessages[index] = this.errorMessages[index] || {..._.cloneDeep(ERROR_OBJ)};
        const copy = _.cloneDeep(this.errorMessages[index]);
        this.errorMessages[index] = {
            ...copy,
            [target]: this.validate(target, value, itemError, index)[target],
            hasErr: this.validate(target, value, itemError, index).hasErr,
        };
        this.hasErr = this.checkHasErr(this.errorMessages);
    }

    // TODO: how to make it more functional?
    public validate(target: string, value: string, itemError: ErrorObjects, index?: number): ErrorObjects {
        if (target === 'param' && !value) {
            itemError[target].isMissing = true;
            itemError.hasErr = true;
            return itemError;
        }

        if (value.length > MAX_LENGTH[target]) {
            itemError[target].exceedMaxLength = true;
            itemError.hasErr = true;
            return itemError;
        }
        // this is to prevent the notorious infinite espcaping of &
        const eValue = value.replace('&', '');
        const cleanVal = DOMPurify.sanitize(eValue);
        const isSafeUrlParam = !/^([A-Za-z0-9\+\-\.\&\;\*\s]*?)(?:\:|&*0*58|&*x0*3a)/gi.test(value);
        // do not consider the & here
        if (!isSafeUrlParam || eValue !== cleanVal) {
            itemError[target].notUrlSafe = true;
            itemError.hasErr = true;
            return itemError;
        }
        // only consider dup check for param not for expandedParam
        if (target === 'param') {
            const dupIndex = [];
            // when index is undefined
            this.paramsArray.forEach((pair, idx) => {
                if (pair.param === value && idx !== index) {
                    dupIndex.push(idx);
                }
            });
            if (dupIndex.length) {
                // for editing exisiting one this works, but for new parameter
                dupIndex.forEach(i => {
                    // this if statement didnt initialize the errorObject
                    let ithErrorObj = this.errorMessages[i];
                    if (_.isEmpty(ithErrorObj)) {
                        ithErrorObj = {..._.cloneDeep(ERROR_OBJ)};
                    }
                    ithErrorObj.param.isDup = true;
                    ithErrorObj.hasErr = true;
                    this.errorMessages[i] = ithErrorObj;
                });
                // this line will take care of new param
                itemError.param.isDup = true;
                itemError.hasErr = true;
            } else {
                // this will also take care of the new param
                itemError.param.isDup = false;
                itemError.hasErr = false;
                const indexes = Object.keys(this.errorMessages);
                indexes.forEach(idx => {
                    const ithErrorObj = this.errorMessages[idx];
                    if (_.get(ithErrorObj, 'param.isDup', '')) {
                        ithErrorObj.param.isDup = false;
                        ithErrorObj.hasErr = false;
                    }
                    this.errorMessages[idx] = ithErrorObj;
                });
            }
        }
        return itemError;
    }

    public checkHasErr(errorMessages: ErrorMessages): boolean {
        if (errorMessages) {
            const test = !_.every(Object.keys(errorMessages) || [], index => !_.get(errorMessages[index], 'hasErr'));
            return !!test;
        }
        return false;
    }

    public isDirty(): boolean {
        return !_.isEqual(this.paramsArray, this.paramsArrayPristine);
    }

    public sanitizeUrl(target: string, index?: number, value?: string): void {
        // let value;
        const valueToClean = value || this[`${target}New`];
        const cVal = DOMPurify.sanitize(valueToClean);
        if (!isNaN(index)) {
            this.paramsArray[index][target] = cVal;
            this.checkIsValid(target, index);
        } else {
            this[`${target}New`] = cVal;
            this.newPairError[target].notUrlSafe = false;
            this.newPairError.hasErr = false;
        }
    }

    public addNewParams(): void {
        if (this.settingsTabRW) {
            const newItem = {
                expandedParam: this.expandedParamNew || '',
                param: this.paramNew || '',
            };

            const itemError1 = {..._.cloneDeep(ERROR_OBJ)};
            const itemError2 = {..._.cloneDeep(ERROR_OBJ)};
            // check add new entry
            const result1 = this.validate('param', this.paramNew || '', itemError1);
            const result2 = this.validate('expandedParam', this.expandedParamNew || '', itemError2);
            this.newPairError = {
                ...{param: _.cloneDeep(result1).param},
                ...{expandedParam: _.cloneDeep(result2).expandedParam},
                hasErr: _.get(result1, 'hasErr') || _.get(result2, 'hasErr'),
            };

            if (
                _.every(Object.values(this.newPairError.param), e => !e) &&
                _.every(Object.values(this.newPairError.expandedParam), e => !e)
            ) {
                this.paramsArray.push(newItem);
                this.hasErr = this.checkHasErr(this.errorMessages) || this.newPairError.hasErr;
                this.canAddParams = this.getCanAddParams();
                this.expandedParamNew = '';
                this.paramNew = '';
            }
        } else {
        }
    }

    public deleteParams(index: number): void {
        this.paramsArray.splice(index, 1);
        this.canAddParams = this.getCanAddParams();
        // checkIsValid
        this.paramsArray.forEach(pair => {
            const id = this.paramsArray.indexOf(pair);
            this.checkIsValid('param', id);
        });
    }

    public getCanAddParams(): boolean {
        return !!((this.paramsArray || []).length <= 100);
    }

    public get disableSaveBtn() {
        // assigning a parameter here is necessary!
        const isDirty = this.isDirty();
        return !this.settingsTabRW || this.hasErr || !isDirty;
    }

    // TODO: add a promise return type, but need to fix the promise typescript typing issue first
    public saveParameters(): Promise<any> {
        const paramItems = {};
        // auto removing pairs that has empty param
        this.paramsArray.forEach(p => {
            if (p.param) {
                // key is not empty string
                paramItems[p.param] = p.expandedParam;
            }
        });
        this.isSaving = true;
        return this.paramExpansionSettingService
            .saveParameters(paramItems)
            .then(res => {
                this.paramsArray = this.sortAndTransform(res);
                this.paramsArrayPristine = _.cloneDeep(this.paramsArray);
                this.isFormDirty = false;
                this.notification.success('Success Saving Expanded Parameters');
            })
            .catch(() => {
                this.notification.error('Error Saving Expanded Parameters');
            })
            .finally(() => {
                this.isSaving = false;
            });
    }

    public detached(): void {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }
}
