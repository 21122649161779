import {bindable, inject} from 'aurelia-framework';

import {Accordion} from 'resources/accordion/accordion';

// time it takes for animation to run - so we can get the right height of the content
// const ANIMATION_DELAY = 100;

@inject(Accordion)
export class AccordionItem {
    // the field below used to be 'title', but it interfered with the browser's default tooltip
    // behavior, since "title" is a valid attribute for HTML elements, displaying "${title}"
    // for the tooltip when setting the title dynamically
    @bindable
    itemTitle;

    @bindable
    defaultOpen;

    @bindable
    deleteConfirmViewModel;

    @bindable
    isVisible = true;

    @bindable
    isReadOnly;

    @bindable
    itemDisabled;

    @bindable
    itemType;

    @bindable
    itemAction;

    constructor(accordion) {
        this.accordion = accordion;
        this.isOpen = false;
        this.uid = `ab-${Math.random().toString(36).substring(7)}`;
        this.height = 'auto';
        this.loadClass = 'accordion__item--load';
        this.isLoaded = false;
        this.animate = false;
    }

    attached() {
        this.accordion.items.push(this);
        this.isOpen = this.defaultOpen === 'true';
        this.setMaxHeight();

        // Wait for 1 second and then remove the loading class
        setTimeout(() => {
            this.loadClass = '';
        }, 1000);
    }

    bind(context) {
        this.context = context;

        if (this.animate === null) {
            this.animate = true;
        }
    }

    setMaxHeight() {
        // set the max height of the accordion item because we are animating the opening of the
        // item using by animating a change in the 'max-height' property (this is because it is
        // difficult to find the actual height of the accordion item.)
        const el = document.getElementById(this.uid);
        const h = this.measureHeightDiv.offsetTop;

        // measure the height with a 1px tall <div> at the bottom of the accordion item, and add an
        // (arbitrary) 200 to that height (to overshoot, intentionally). If any measurements fail,
        // fall back to 2000 as max height.
        el.style.maxHeight = `${h ? h + 700 : 2000}px`;
        this.isLoaded = true;
    }

    showContent(initialLoad = false) {
        // this.loadClass = '';
        if (this.accordion.closeOthers || initialLoad) {
            this.accordion.items.forEach(item => {
                item.isOpen = false;
            });
            this.isOpen = true;
        } else {
            this.animate = true;
            this.isOpen = !this.isOpen;
        }
    }
}
