import {validate} from 'class-validator';

export class ValidateHelper {
    /**
     * Executes the class-validator.validate method upon the specified config, and
     * copies first error message to specified object for any invalid config properties
     *
     * @param config A data model class with class-validator decorators
     * @param errors An object which UI controls can use for displaying error messages
     * @returns Truthy only if the entire config is valid
     */
    public static async validate(config: object, errors: object) {
        const errs = await validate(config, {skipMissingProperties: true});

        // empty object but preserve bindings to it
        Object.keys(errors).forEach(key => (errors[key] = undefined));

        // copy first constraint's message to errors object for invalids
        if (errs.length) {
            errs.forEach(err => {
                errors[err.property] = Object.values(err.constraints)[0];
            });
            return false;
        }

        // only return true if entire config is valid
        return true;
    }
}
