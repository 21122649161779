import {bindable} from 'aurelia-framework';

import {IValidationError} from '../../interfaces';
import {IdsUser} from '../../models/ids-user';

export class IdsUserSearch {
  @bindable()
  public model: IdsUser;

  @bindable()
  public target: 'Source' | 'Target';

  @bindable()
  public validationErrors: IValidationError[] = [];

  @bindable()
  public onSearch: (target: string, txt: string) => Promise<void>;

  @bindable()
  public onClear: (target: string) => void;

  public isThinking: boolean = false;

  public clearSearch(): void {
    if (!this.onClear) {
      throw Error('onClear not set');
    }

    this.onClear(this.target.toLowerCase());
  }

  public userSearchActions = {
    onSearch: async (txt: string) => this.updateSearch(txt),
    onClear: () => this.clearSearch(),
  };

  public async updateSearch(txt: string): Promise<void> {
    if (!this.onSearch) {
      throw Error('onSearch not set');
    }

    this.isThinking = true;

    try {
      await this.onSearch(this.target.toLowerCase(), txt);
    } catch (err) {
      // do nothing
    } finally {
      this.isThinking = false;
    }
  }
}
