import {autoinject, bindable, computedFrom} from 'aurelia-framework';
import {authorizationConstants} from 'services/authorization';

@autoinject()
@authorizationConstants
export class PublishingSavebar {
    @bindable public callbacks;
    public model: any;
    public controller: any;
    public hasError = false;
    public saveEnabled = true;

    @computedFrom('saveEnabled')
    get saveBtnState() {
        if (!this.saveEnabled) {
            return 'disabled';
        }

        return '';
    }

    public activate(model) {
        this.model = model.shared;
        this.controller = model.controller;
    }

    public async done() {
        if (!this.saveEnabled) {
            return;
        }

        this.saveEnabled = false;

        this.model.onDone(this.model).then(isValid => {
            setTimeout(() => {
                this.saveEnabled = true;
            }, 1500);

            if (isValid) {
                this.controller.cancel();
            }
        });
    }
}
