import {SharedNav} from '@bindable-ui/bindable';
import {inject} from 'aurelia-framework';

import {CalendarService} from './services/calendar-service';

@inject(CalendarService, SharedNav)
export class LiveEventsCalendar {
    constructor(calendarService, sharedNav) {
        this.calendarService = calendarService;
        this.sharedNav = sharedNav;
        this.routes = [
            {
                route: ['', 'day'],
                name: 'calendarDay',
                moduleId: PLATFORM.moduleName('./day'),
                nav: true,
                title: 'Day',
            },
            {
                route: 'week',
                name: 'calendarWeek',
                moduleId: PLATFORM.moduleName('./week'),
                nav: true,
                title: 'Week',
            },
            {
                route: 'month',
                name: 'calendarMonth',
                moduleId: PLATFORM.moduleName('./month'),
                nav: true,
                title: 'Month',
            },
        ];
    }

    configureRouter(config, router) {
        this.router = router;
        config.map(this.routes);
    }

    attached() {
        const lastView = this.calendarService.getLastViewed();

        if (lastView !== this.router.currentInstruction.config.name) {
            this.router.navigateToRoute(lastView);
        }

        this.sharedNav.setActive(0, 2);
    }

    detached() {
        // Reset the shared filter
        this.calendarService.saveFilter();
    }
}
