export class TableSortingTools {
  static sort(key: string, order: string, defaultSortDirection: string | null = null): string {
    const normalizedOrder = order.replace(/^-/, '');

    let newOrder = '';

    if (normalizedOrder === key) {
      newOrder = /^-/.test(order) ? key : `-${key}`;
    } else {
      newOrder = defaultSortDirection || key;
    }

    return newOrder;
  }

  static sorted(key: string, value: string): number {
    const isDescending = value === `-${key}`;
    const isAscending = value === key;

    if (isDescending) {
      return 1;
    }
    if (isAscending) {
      return -1;
    }
    return 0;
  }
}

export class TableColumn {
  constructor(
    public label: string,
    public key: string,
    {
      isHideable = true,
      isVisible = true,
      isSortable = false,
    }: {
      isHideable?: boolean;
      isVisible?: boolean;
      isSortable?: boolean;
    } = {},
  ) {
    this.isHideable = isHideable;
    this.isVisible = isVisible;
    this.isSortable = isSortable;
  }

  isHideable: boolean;
  isVisible: boolean;
  isSortable: boolean;

  public getSortedValue(currentSort: string): number {
    return TableSortingTools.sorted(this.key, currentSort);
  }
}
