/* eslint-disable max-classes-per-file */

import {computedFrom, inject, LogManager, observable} from 'aurelia-framework';
import * as papa from 'papaparse';

import {deepCopy} from 'resources/deep-copy';
import {MetadataSchemaService} from 'apps/cms/routes/settings/live-events/services/metadata-schema-service';
import {SchemaPropertyType, SchemaPropertyRestriction} from 'apps/cms/routes/settings/live-events/models/metadata';
import {BaseEventSingle} from './base';

export const IMPORT_ERROR = {
  EMPTY_FILE: 'There was an error importing the CSV. Nothing in the file.',
  DUPLICATE_KEY: 'There was an error importing the CSV. There are duplicate keys in the file.',
  EMPTY_KEY: 'There was an error importing the CSV. Empty keys are not allowed.',
  SERVER_ERROR: 'There was a server error importing the CSV. Please try again.',
  PARSING_ERROR: 'There was an error importing the CSV. Please check the file validity.',
  NO_FILE: 'Please select a file to import.',
};

const log = LogManager.getLogger('live-events-metadata');

@inject(MetadataSchemaService)
export class Metadata extends BaseEventSingle {
  @observable
  schema = null;

  constructor(metadataSchemaService, router, notification, liveEventsService, bindingEngine, dialogService) {
    super(router, notification, liveEventsService, bindingEngine, dialogService);
    this.bindingEngine = bindingEngine;
    this.metadataSchemaService = metadataSchemaService;
    this._metas = {};
    this.isAdding = false;
    this.showKeyRequired = false;
    this.csvFiles = [];
    this.SchemaPropertyType = SchemaPropertyType;
    this.SchemaPropertyRestriction = SchemaPropertyRestriction;
    this.schemaValueMap = {};
    this.importMethod = 'auto';
    this.key = null;
    this.value = null;
  }

  activate(params) {
    super.activate(params);
    // initialize schema if model already loaded
    const metaSchema = this.liveEvents.tabs.liveEventMetadata.schema || _.get(this.liveEvents, 'model.meta_schema');
    if (metaSchema) {
      this.schema = metaSchema;
    } else {
      this.schema = null;
    }
    // detect when model has loaded since it's done outside of this class
    // to check if there is a schema id assigned
    this.schemaChangeSubscription = this.bindingEngine.propertyObserver(this.liveEvents, 'model').subscribe(model => {
      if (model && model.meta_schema) {
        this.schema = this.liveEvents.tabs.liveEventMetadata.schema || model.meta_schema;
      }
    });
    this.schemasLoadError = null;
    return this.metadataSchemaService
      .list({cache: true, excludeEmpty: true})
      .then(schemas => {
        this.schemas = schemas;
        this.schemaChanged();
      })
      .catch(err => {
        // don't prevent metadata from displaying if there is an error loading schemas
        this.schemasLoadError = err;
        log.error(err);
      });
  }

  deactivate() {
    super.deactivate();

    if (!this.model || this.liveEvents.isLeavingNewRoute) {
      return;
    }

    const current = this.router.currentInstruction;
    const tabModel = this.liveEvents.cleanEvent(this.model);

    tabModel.metas = this.reduceMetas();

    this.liveEvents.validateEvent(tabModel, current.config.name);

    this.schemaChangeSubscription.dispose();
  }

  @computedFrom('_metas', 'model.meta', 'liveEvents.tabs.liveEventMetadata.keysToDelete.length', 'schemaValueMap')
  get metas() {
    const metas = [];
    const {edits} = this.liveEvents.tabs.liveEventMetadata;

    if (!this.model || this.model.meta == null) {
      return metas;
    }

    Object.entries(this.model.meta).forEach(arr => {
      const m = {key: arr[0], val: arr[1]};
      if (this.liveEvents.tabs.liveEventMetadata.keysToDelete.indexOf(arr[0]) !== -1) {
        m.deleted = true;
      }
      if (m.key in edits && edits[m.key] !== m.val) {
        m.val = edits[m.key];
        m.isDirty = true;
      }
      metas.push(m);
    });
    return metas;
  }

  set metas(newValue) {
    this._metas = deepCopy(newValue); // clone object
  }

  @computedFrom('_metas', 'model.meta', 'schemaValueMap')
  get schemaMetas() {
    if (!this.currentSchema) return [];
    // blend schema keys with meta keys to retain meta key values and dirty status
    const {edits} = this.liveEvents.tabs.liveEventMetadata;
    return this.currentSchema.keys.map(k => {
      const metaObj = this.metas.find(m => m.key === k.name) || {};
      const editVal = edits[k.name];
      let val = editVal;
      if (editVal === undefined && k.default_value) {
        val = k.default_value;
      }
      return {
        key: k.name,
        val,
        isDirty: editVal && editVal !== metaObj.val,
        ...k,
        ...metaObj,
      };
    });
  }

  schemaChanged() {
    this.schemaNotFoundError = false;
    if (!this.schemas) return;
    this.currentSchema = this.schemas.find(s => s.id === this.schema);
    if (this.schema !== 'unselected') {
      this.liveEvents.tabs.liveEventMetadata.schema = this.schema;
    }
    if (
      this.model &&
      _.isString(this.model.meta_schema) &&
      !this.currentSchema &&
      this.useSchema &&
      !this.schemaSelectDirty
    ) {
      // shown once when schema does not exist in list of schemas but user hasn't changed the select value
      this.schemaNotFoundError = true;
    }
    // clear errors on existing values if they exist, otherwise object references
    // can remain that will cause an error to show if you change back to the schema
    // or import metadata through csv
    Object.values(this.schemaValueMap).forEach(val => {
      delete val.error;
    });
    this.schemaValueMap = {};
    if (this.currentSchema) {
      // reset schema that was assigned on load once a change occurs
      // so that we only display the error on first load
      this.currentSchema.keys.forEach(key => {
        this.schemaValueMap[key.name] = key;
        // need to fake having edited keys that don't currently exist in model.meta
        // otherwise they will get filtered out by the get metas function
        if (!this.model.meta[key.name]) {
          let value = key.default_value || '';
          if (key.type === SchemaPropertyType.ENUM && !key.include_none_option) {
            const [optionValue] = key.option_values;
            value = optionValue;
          }
          this.editMeta({key: key.name, val: value});
        }
      });
      // restart polling as it may have been stopped by a call to editMeta for
      // keys that don't currently exit
      this.liveEvents.startSinglePoll();
    }
  }

  @computedFrom('schema')
  get useSchema() {
    return !!this.schema;
  }

  set useSchema(val) {
    this.schemaSelectDirty = true;
    if (val && !this.schema) {
      this.schema = 'unselected';
    } else if (!val) {
      this.schema = null;
      this.currentSchema = null;
      this.schemaValueMap = {};
    }
  }

  keyChanged() {
    this.showKeyRequired = false;
  }

  addMetadata() {
    if (this.isAdding) {
      return;
    }

    if (!this.key) {
      this.showKeyRequired = true;
      return;
    }

    if (this.key in this.schemaValueMap) {
      this.notification.error(`Key (${this.key}) is already part of Schema.`);
      return;
    }

    this.isAdding = true;
    if (this.model.meta[this.key] == null) {
      this.liveEvents
        .addMeta(this.key, this.value)
        .then(() => {
          this.key = '';
          this.value = '';
        })
        .finally(() => {
          this.isAdding = false;
        });
    } else {
      this.notification.error(`This key (${this.key}) already exists.`);
      this.isAdding = false;
    }
  }

  deleteMeta(meta) {
    meta.deleted = !meta.deleted;
    if (meta.deleted) {
      if (this.liveEvents.tabs.liveEventMetadata.keysToDelete.indexOf(meta.key) === -1) {
        this.liveEvents.tabs.liveEventMetadata.keysToDelete.push(meta.key);
      }
    } else {
      this.liveEvents.tabs.liveEventMetadata.keysToDelete.splice(
        this.liveEvents.tabs.liveEventMetadata.keysToDelete.indexOf(meta.key),
        1,
      );
    }
    this.isDirty();
  }

  editMeta(newVal) {
    if (this.schemaValueMap[newVal.key]) {
      delete this.schemaValueMap[newVal.key].error;
    }
    // stop polling while editing a field, otherwise input gets janky. Input's change event
    // (on blur) will start the polling back up.
    this.liveEvents.stopSinglePoll();
    const {edits} = this.liveEvents.tabs.liveEventMetadata;
    if (newVal.key in edits && newVal.val === this.model.meta[newVal.key]) {
      delete edits[newVal.key];
    } else {
      edits[newVal.key] = newVal.val;
    }
    this.metas = {};
  }

  reduceMetas() {
    const meta = this.metas.reduce((newObj, obj) => {
      const res = newObj;
      res[obj.key] = obj.val;
      return res;
    }, {});
    this.isDirty();

    return meta;
  }

  importCSVError(msg) {
    this.notification.error(msg, 'CSV Error', 15000);
  }

  importCSV() {
    if (!this.csvFiles.length) {
      this.importCSVError(IMPORT_ERROR.NO_FILE);
      return;
    }

    papa.parse(this.csvFiles[0], {
      header: false,
      skipEmptyLines: true,
      complete: results => {
        let {data} = results;

        if (!data.length) {
          this.importCSVError(IMPORT_ERROR.EMPTY_FILE);
          return;
        }

        // convert array to match that of key/value pairs across rows
        // when key/value pairs are across columns
        if ((this.importMethod === 'auto' && data[0].length > 2) || this.importMethod === 'rows') {
          data = _.zip(data[0], data[1]);
        }

        // Check to make sure there aren't any duplicate keys
        const keys = data.map(_ => _[0]);
        const uniqKeys = _.uniq(keys);
        if (uniqKeys.length !== data.length) {
          this.importCSVError(IMPORT_ERROR.DUPLICATE_KEY);
          return;
        }

        // Check to make sure key name isn't empty
        if (!_.every(keys)) {
          this.importCSVError(IMPORT_ERROR.EMPTY_KEY);
          return;
        }

        const metadataList = data.map(
          ([
            key,
            value,
          ]) => ({key, value}),
        );

        this.isAdding = true;
        this.liveEvents
          .addMetaList(metadataList)
          .then(() => {
            this.notification.success('Metadata CSV imported successfully.');
            this.schemaChanged();
          })
          .catch(() => {
            this.importCSVError(IMPORT_ERROR.SERVER_ERROR);
          })
          .finally(() => {
            this.isAdding = false;
          });
      },
      error: () => {
        this.importCSVError(IMPORT_ERROR.PARSING_ERROR);
      },
    });
  }

  isDirty() {
    if (!this.liveEvents.origEvent) {
      this.liveEvents.fields.meta.isDirty = false;
    } else {
      const isDirty =
        JSON.stringify(this.liveEvents.origEvent.meta) !== JSON.stringify(this.model.meta) ||
        this.liveEvents.tabs.liveEventMetadata.keysToDelete.length > 0;
      this.liveEvents.fields.meta.isDirty = isDirty;
    }
  }

  @computedFrom('metas', 'schemaMetas', 'liveEvents.fields.meta.isDirty')
  get metadataIsDirty() {
    return (
      this.liveEvents.fields.meta.isDirty ||
      (this.metas.length && this.metas.some(e => e.isDirty)) ||
      (this.schemaMetas.length && this.schemaMetas.some(e => e.isDirty))
    );
  }

  get hasValidMetadata() {
    let valid = true;
    const metaMap = _.keyBy(this.metas, m => m.key);
    const {edits} = this.liveEvents.tabs.liveEventMetadata;
    if (this.currentSchema) {
      this.currentSchema.keys.forEach(key => {
        const value = (metaMap[key.name] || {}).val || edits[key.name];
        if (key.restriction === SchemaPropertyRestriction.REQUIRED && !value) {
          key.error = `You must ${key.type === SchemaPropertyType.ENUM ? 'choose' : 'enter'} a value.`;
          valid = false;
        }
      });
    }
    return valid;
  }

  saveEvent(event) {
    if (!this.hasValidMetadata) {
      this.metas = {};
      this.notification.error(
        'Metadata Schema Validation Error. Please ensure all the metadata fields are filled out.',
      );
      return;
    }

    if (this.schema && this.model.meta_schema !== this.schema && this.schema !== 'unselected') {
      this.model.meta_schema = this.schema;
      this.liveEvents.fields.meta_schema.isDirty = true;
    } else if ((!this.schema || this.schema === 'unselected') && this.model.meta_schema) {
      this.model.meta_schema = null;
      this.liveEvents.fields.meta_schema.isDirty = true;
    }

    const {edits} = this.liveEvents.tabs.liveEventMetadata;

    // clear edits for keys that are not part of selected schema or existing keys since
    // unused edits can be introduced when the schemas are changed since we "fake" the
    // edit for new keys introduced by schema for it not to be filtered by metas
    Object.keys(edits).forEach(key => {
      if (!(key in this.model.meta) && !(key in this.schemaValueMap)) {
        delete edits[key];
      }
    });

    this.model.meta = {...this.model.meta, ...edits};
    this.isDirty();
    super.saveEvent(event, {validateMetadata: !!this.model.meta_schema}).finally(() => {
      this.metas = this.model.meta;
      this.isDirty();
      this.liveEvents.fields.meta_schema.isDirty = false;
    });
  }
}

export class NonSchemaMetaValueConverter {
  toView(metas, schemaKeyMap) {
    return metas.filter(m => !schemaKeyMap[m.key]);
  }
}

export class SelectMetaValueConverter {
  toView(selectValue, meta) {
    return meta.val || selectValue;
  }

  fromView(selectValue, meta) {
    if (selectValue === 'null' || selectValue === 'undefined' || !selectValue) {
      meta.val = undefined;
    } else {
      meta.val = selectValue;
    }
    return meta.val;
  }
}
