import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

import {SharedNav} from '@bindable-ui/bindable';

@autoinject()
export class AudiencesIndex {
    public router: Router;
    public routes = [
        {
            moduleId: PLATFORM.moduleName('./list/index'),
            name: 'audiencesList',
            nav: false,
            route: '',
            title: 'Audiences',
        },
        {
            moduleId: PLATFORM.moduleName('./single/index'),
            name: 'audienceSingle',
            nav: false,
            route: ':id',
            title: 'Audience',
        },
    ];

    constructor(public sharedNav: SharedNav) {}

    public attached() {
        this.sharedNav.setNextText('Audience List');
    }

    public configureRouter(config, router) {
        this.router = router;
        config.map(this.routes);
    }
}
