import {autoinject, PLATFORM} from 'aurelia-framework';
import {activationStrategy, RouteConfig, Router, RouterConfiguration} from 'aurelia-router';
import {BetaMode} from 'services/beta-mode/index';

@autoinject()
export class FailoverGroupRoute {
    public router: Router;
    public routes: RouteConfig[];
    public isLoading: boolean;
    public error: string;

    constructor(public betaMode: BetaMode) {
        this.routes = [
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'failoverGroupConfiguration',
                nav: true,
                route: ['', 'configuration'],
                title: 'Configuration',
            },
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'failoverGroupThresholdsConfiguration',
                nav: true,
                route: 'thresholds',
                title: 'Thresholds',
            },
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'failoverChangeLog',
                nav: true,
                route: 'change-log',
                title: 'Change Log',
            },
            {
                activationStrategy: activationStrategy.invokeLifecycle,
                moduleId: PLATFORM.moduleName('./index'),
                name: 'failoverEventLog',
                nav: true,
                route: 'event-log',
                title: 'Event Log',
            },
        ];
    }

    public configureRouter(config: RouterConfiguration) {
        config.map(this.routes);
    }
}
