import {autoinject, bindable, computedFrom} from 'aurelia-framework';
import {
  SYNDICATION_TARGET_PLATFORM_BETA,
  SYNDICATION_TARGET_PLATFORM_PROTOCOLS,
  SYNDICATION_TARGET_PROTOCOL_BETA,
  SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS,
  SyndicationHlsUriType,
  SyndicationPlatformType,
  SyndicationTarget,
  SyndicationTargetProtocolType,
} from 'services/models/syndication';
import {SyndicationTargetService} from 'services/syndication-target-service';
import {CToastsService} from '@bindable-ui/bindable';
import {BetaMode} from 'services/beta-mode';
import {Router} from 'aurelia-router';
import {DialogService} from 'aurelia-dialog';
import {TableColumn} from 'utils/table-sorting-tools';
import {resolve} from 'bluebird';
import {SyndicationClippingProfile} from 'services/models/syndication-facebook-page';

@autoinject()
export class SyndicationTargetsList {
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public loadingError: boolean = false;
  public selectedTargets: any[] = [];
  public targets: SyndicationTarget[] = [];
  public searchText: string = '';
  public searchInput: any;
  public isSearchFiltered: boolean = false;

  // dialog fields
  public target: any;
  public faceBookPagesList: object[] = [];
  public clippingProfilesList: object = {};
  @bindable public protocolOption;
  @bindable public platformOption;
  @bindable public profileIdOption;
  @bindable public facebookPageOption;
  public targetDialog: any;

  public tableColumns = [
    new TableColumn('Name', 'description'),
    new TableColumn('Platform', 'platform'),
    new TableColumn('Protocol', 'target_protocol'),
  ];

  private options = [
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.RTMP],
      value: SyndicationTargetProtocolType.RTMP,
      db_value: SyndicationTargetProtocolType.RTMP,
    },
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.RTMPS],
      value: SyndicationTargetProtocolType.RTMPS,
      db_value: SyndicationTargetProtocolType.RTMPS,
    },
    {
      db_value: SyndicationTargetProtocolType.RTMP_PULL,
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.RTMP_PULL],
      value: SyndicationTargetProtocolType.RTMP_PULL,
    },
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.ZIXI],
      value: SyndicationTargetProtocolType.ZIXI,
      db_value: SyndicationTargetProtocolType.ZIXI,
    },
    {
      db_value: SyndicationTargetProtocolType.HLS_PUSH,
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.HLS_PUSH],
      value: SyndicationTargetProtocolType.HLS_PUSH,
    },
    {
      db_value: SyndicationTargetProtocolType.HLS_PULL,
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.HLS_PULL],
      value: SyndicationTargetProtocolType.HLS_PULL,
    },
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.SRT],
      value: SyndicationTargetProtocolType.SRT,
      db_value: SyndicationTargetProtocolType.SRT,
    },
    {
      text: SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[SyndicationTargetProtocolType.RIST],
      value: SyndicationTargetProtocolType.RIST,
      db_value: SyndicationTargetProtocolType.RIST,
    },
  ];

  constructor(
    public betaMode: BetaMode,
    public router: Router,
    public dialogService: DialogService,
    public syndicationTargetService: SyndicationTargetService,
    public notification: CToastsService,
  ) {
    this.router = router;
  }

  public async activate() {
    this.syndicationTargetService.updateParams({page: 1});
    this.isLoading = true;
    this.loadTargets(true);
    this.target = {};
    this.platformOptionChanged(SyndicationPlatformType.TWITCH);
  }

  public detached() {
    this.target = {};
    this.faceBookPagesList = [];
    this.clippingProfilesList = {};
    this.protocolOption = '';
    this.platformOption = '';
    this.profileIdOption = '';
    this.facebookPageOption = '';
    this.syndicationTargetService.params = {};
    this.syndicationTargetService.resetSearch();
  }

  @computedFrom('platformOption')
  get protocolOptionsList() {
    return _.map(
      _.filter(this.options, info => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          SYNDICATION_TARGET_PLATFORM_PROTOCOLS.hasOwnProperty(this.target.platform) &&
          !SYNDICATION_TARGET_PLATFORM_PROTOCOLS[this.target.platform].includes(info.db_value)
        ) {
          return false;
        }

        if (SYNDICATION_TARGET_PROTOCOL_BETA.includes(info.db_value) && !this.betaMode.hasScope(info.db_value)) {
          return false;
        }

        return true;
      }),
      info => {
        if (
          this.betaMode.hasScope(info.db_value) &&
          SYNDICATION_TARGET_PROTOCOL_BETA.includes(info.db_value) &&
          !info.text.includes('(beta)')
        ) {
          info.text += ' (beta)';
        }
        return {text: info.text, value: info.value};
      },
    );
  }

  @computedFrom('SyndicationPlatformType')
  get platformOptions() {
    return _.map(
      _.filter(SyndicationPlatformType, platformType => {
        if (!SYNDICATION_TARGET_PLATFORM_BETA.includes(platformType)) {
          return true;
        }
        return this.betaMode.hasScope(platformType);
      }),
      info => ({value: info}),
    );
  }

  @computedFrom('target.platform_config.integrated', 'target.target_protocol')
  get showStreamUri() {
    if (
      [
        SyndicationPlatformType.FACEBOOK,
        SyndicationPlatformType.YOUTUBE,
      ].includes(this.target.platform) &&
      this.target.platform_config.integrated
    ) {
      return false;
    }
    return [
      SyndicationTargetProtocolType.RTMP,
      SyndicationTargetProtocolType.RTMPS,
      SyndicationTargetProtocolType.HLS_PUSH,
      SyndicationTargetProtocolType.SRT,
      SyndicationTargetProtocolType.RIST,
    ].includes(this.target.target_protocol);
  }

  @computedFrom('target.platform')
  get onlyStreamKeyPlatform() {
    return [
      SyndicationPlatformType.FACEBOOK,
      SyndicationPlatformType.YOUTUBE,
      SyndicationPlatformType.TWITCH,
      SyndicationPlatformType.TIKTOK,
    ].includes(this.target.platform);
  }

  @computedFrom('target.target_protocol', 'target.platform')
  get streamTip() {
    if (
      !this.onlyStreamKeyPlatform &&
      (this.target.target_protocol === 'rtmp' || this.target.target_protocol === 'rtmps')
    ) {
      return `e.g. ${this.target.target_protocol}://live.platform.com/{stream_key}`;
    }
    if (this.target.target_protocol === 'rist') {
      return 'e.g. rist://host:port (port must be an even number)';
    }
    if (this.target.target_protocol === 'srt') {
      return 'e.g. srt://host:port';
    }
    return '';
  }

  @computedFrom('target.target_protocol')
  get getTargetProtocolText() {
    return SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS[this.target.target_protocol];
  }

  public openTargetDialog() {
    this.createDialogInit();
    this.targetDialog.show();
  }

  public protocolOptionChanged(newValue) {
    const result = _.find(this.options, {value: newValue});
    this.target.target_protocol = result.db_value;
  }

  public async platformOptionChanged(newValue) {
    this.target.platform = newValue;
    // eslint-disable-next-line no-prototype-builtins
    if (SYNDICATION_TARGET_PLATFORM_PROTOCOLS.hasOwnProperty(newValue)) {
      this.target.target_protocol = SYNDICATION_TARGET_PLATFORM_PROTOCOLS[newValue][0];
      this.protocolOption = this.target.target_protocol;
    } else {
      // set the first protocol option temporary to force trigger the protocol change
      this.target.target_protocol = this.options[0].db_value;
    }

    // only for new FB/YT targets , set as integrated by default
    if (
      // eslint-disable-next-line no-prototype-builtins
      (!this.target.hasOwnProperty('id') || !this.target.id) &&
      [
        SyndicationPlatformType.FACEBOOK,
        SyndicationPlatformType.YOUTUBE,
      ].includes(newValue)
    ) {
      this.target.platform_config.integrated = true;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (newValue.length && !this.clippingProfilesList.hasOwnProperty(newValue)) {
      this.clippingProfilesList[newValue] = await this.fetchClippingProfiles(newValue);
    }
  }

  public async facebookPageOptionChanged(newValue) {
    this.target.platform_config.page_id = newValue;
  }

  public async profileIdOptionChanged(newValue) {
    this.target.platform_config.clipping_profile_id = newValue;
    if (this.target.platform === SyndicationPlatformType.FACEBOOK && newValue) {
      await this.fetchFaceBookPages(newValue);
    }
  }

  public createDialogInit() {
    this.target = {
      audio_track: '',
      description: '',
      platform: SyndicationPlatformType.TWITCH,
      platform_config: {port: 2088, host: '', stream_id: '', password: ''},
      ray: 'a',
      status: '',
      syndication_target: '',
      target_protocol: 'rtmp',
      target_stream: '',
    };
    this.protocolOption = this.target.target_protocol;
    this.platformOption = this.target.platform;
    this.profileIdOption = '';
    this.facebookPageOption = '';
  }

  public async saveTarget() {
    await this.validateTarget();
    if (
      this.target.error.description ||
      this.target.error.stream ||
      this.target.error.platform_config.host ||
      this.target.error.platform_config.port ||
      this.target.error.platform_config.stream_id
    ) {
      return false;
    }

    if (
      this.target.platform === SyndicationPlatformType.FACEBOOK &&
      this.target.platform_config.integrated &&
      (this.target.error.platform_config.clipping_profile_id || this.target.error.platform_config.page_id)
    ) {
      return false;
    }

    if (
      this.target.platform === SyndicationPlatformType.YOUTUBE &&
      this.target.platform_config.integrated &&
      this.target.error.platform_config.clipping_profile_id
    ) {
      return false;
    }

    const target = new SyndicationTarget();
    if (this.target.id) {
      target.id = this.target.id;
      if (this.target.target_stream === '******') {
        delete this.target.target_stream;
      }
    }

    target.platform = this.target.platform;
    target.target_protocol = this.target.target_protocol;
    target.target_stream = this.target.target_stream;
    target.description = this.target.description;

    if (
      target.target_protocol === SyndicationTargetProtocolType.ZIXI ||
      target.platform === SyndicationPlatformType.HULU ||
      target.platform === SyndicationPlatformType.FACEBOOK ||
      target.platform === SyndicationPlatformType.YOUTUBE ||
      target.target_protocol === SyndicationTargetProtocolType.HLS_PULL
    ) {
      target.platform_config = this.target.platform_config;
    }
    try {
      await this.syndicationTargetService.saveSyndicationTarget(target).then(() => {
        this.targets = _.cloneDeep(this.syndicationTargetService.syndicationTargets);
        resolve(true);
      });
    } catch (e) {
      return false;
    } finally {
      this.targetDialog.hide();
    }
    return true;
  }

  public validateURI(model) {
    return (
      !model.id || (model.id && model.target_stream !== '******')
      // || model.target_protocol !== model.target_protocol_pristine
    );
  }

  public async validateTarget() {
    this.target.error = {
      platform_config: {},
    };
    let streamRequired = 'Stream URI is required.';
    if (this.onlyStreamKeyPlatform) {
      streamRequired = 'Stream Key is required.';
    }
    // eslint-disable-next-line prefer-regex-literals
    const rtmpRegex = new RegExp(/^(rtmp?|rtmps):\/\/[^\s$.?#].[^\s]*$/);

    if (!this.target.description) {
      this.target.error.description = 'Target Name is required.';
    }

    if (this.target.platform === SyndicationPlatformType.FACEBOOK && this.target.platform_config.integrated) {
      if (!this.target.platform_config.clipping_profile_id) {
        this.target.error.platform_config.clipping_profile_id = 'Clipping profile is required.';
      } else if (!this.target.platform_config.page_id) {
        this.target.error.platform_config.page_id = 'Facebook Page is required.';
      }
      return;
    }

    if (this.target.platform === SyndicationPlatformType.YOUTUBE && this.target.platform_config.integrated) {
      if (!this.target.platform_config.clipping_profile_id) {
        this.target.error.platform_config.clipping_profile_id = 'Clipping profile is required.';
      }
      return;
    }

    if (this.target.target_protocol === SyndicationTargetProtocolType.ZIXI) {
      if (!this.target.platform_config.host) {
        this.target.error.platform_config.host = 'Host is required.';
      }
      if (!this.target.platform_config.port) {
        this.target.error.platform_config.port = 'Port is required and must be greater than 0.';
      }
      if (!this.target.platform_config.stream_id) {
        this.target.error.platform_config.stream_id = 'Stream ID is required.';
      }
      return;
    }

    if (
      this.target.target_protocol === SyndicationTargetProtocolType.HLS_PUSH ||
      this.target.platform === SyndicationPlatformType.HULU
    ) {
      if (!this.target.target_stream) {
        this.target.error.stream = streamRequired;
      } else if (
        this.target.target_stream &&
        this.validateURI(this.target) &&
        this.target.target_stream.toLowerCase().substring(0, 8) !== 'https://' &&
        this.target.target_stream.toLowerCase().substring(0, 7) !== 'http://'
      ) {
        this.target.error.stream = 'Stream URI must start with http:// or https://';
      } else if (
        this.target.target_stream &&
        this.validateURI(this.target) &&
        !this.target.target_stream.endsWith('/')
      ) {
        this.target.error.stream = 'Stream URI must end with a trailing /';
      }

      if (
        this.target.platform === SyndicationPlatformType.HULU &&
        (!this.target.id || (this.target.id && this.target.target_stream !== '******'))
      ) {
        // check and see if <hulu_channel_id> is in the URI
        this.target.platform_config.hls_uri_type =
          this.target.target_stream.indexOf('<hulu_channel_id>') === -1
            ? SyndicationHlsUriType.NONE
            : SyndicationHlsUriType.CHANNEL_ID;
      }
      return;
    }

    if (
      this.target.target_protocol === SyndicationTargetProtocolType.HLS_PULL ||
      this.target.target_protocol === SyndicationTargetProtocolType.RTMP_PULL
    ) {
      // nothing to verify at the moment, just an empty if to avoid validating the stream_uri
      return;
    }

    if (this.target.target_protocol === SyndicationTargetProtocolType.SRT) {
      if (!this.target.target_stream) {
        this.target.error.stream = streamRequired;
      } else if (
        this.target.target_stream &&
        this.validateURI(this.target) &&
        this.target.target_stream.toLowerCase().substring(0, 6) !== 'srt://'
      ) {
        this.target.error.stream = 'Stream URI must start with srt://';
      }

      return;
    }

    if (this.target.target_protocol === SyndicationTargetProtocolType.RIST) {
      if (!this.target.target_stream) {
        this.target.error.stream = streamRequired;
      } else if (
        this.validateURI(this.target) &&
        this.target.target_stream.toLowerCase().substring(0, 7) !== 'rist://'
      ) {
        this.target.error.stream = 'Stream URI must start with rist://';
      } else if (
        this.validateURI(this.target) &&
        Number(this.target.target_stream.slice(this.target.target_stream.lastIndexOf(':') + 1)) % 2 !== 0
      ) {
        this.target.error.stream = 'RIST port must be an even number.';
      }

      return;
    }

    if (!this.target.target_stream) {
      this.target.error.stream = streamRequired;
      return;
    }

    if (!this.onlyStreamKeyPlatform) {
      if (this.validateURI(this.target) && !rtmpRegex.test(this.target.target_stream)) {
        this.target.error.stream = 'Stream URI is not valid.';
      } else if (
        this.validateURI(this.target) &&
        this.target.target_protocol === SyndicationTargetProtocolType.RTMP &&
        this.target.target_stream.toLowerCase().substring(0, 5) !== `${SyndicationTargetProtocolType.RTMP}:`
      ) {
        this.target.error.stream = 'Stream URI must start with rtmp://';
      } else if (
        this.validateURI(this.target) &&
        this.target.target_protocol === SyndicationTargetProtocolType.RTMPS &&
        this.target.target_stream.toLowerCase().substring(0, 6) !== `${SyndicationTargetProtocolType.RTMPS}:`
      ) {
        this.target.error.stream = 'Stream URI must start with rtmps://';
      }
    }
  }

  public async fetchFaceBookPages(profile_id: string) {
    try {
      await this.syndicationTargetService.getSyndicationTargetFaceBookPages(profile_id);
      const faceBookPagesList = _.cloneDeep(this.syndicationTargetService.syndicationFaceBookPages);
      this.faceBookPagesList = _.map(faceBookPagesList, fbPage => ({text: fbPage.name, value: fbPage.id}));
      return this.faceBookPagesList;
    } catch (e) {
      return [];
    }
  }

  public async fetchClippingProfiles(platform: string = '') {
    try {
      const data: SyndicationClippingProfile[] =
        await this.syndicationTargetService.getSyndicationTargetClippingProfiles(platform);
      return _.map(data, item => ({text: item.name, value: item.id}));
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
    return [];
  }

  public async loadTargets(overrideTargets) {
    try {
      const ans = await this.syndicationTargetService.getSyndicationTargets(overrideTargets);
      if (ans) {
        this.targets = _.cloneDeep(this.syndicationTargetService.syndicationTargets);
      }
    } catch (error) {
      this.loadingError = true;
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  public async resetSearch() {
    if (this.syndicationTargetService.searchQueryApplied) {
      this.searchText = '';
      this.searchInput = '';
      this.syndicationTargetService.resetSearch();
      this.isSearchFiltered = false;
      this.syndicationTargetService.updateParams({page: 1});
      await this.loadTargets(true);
    }
  }

  public async search() {
    this.isLoading = true;
    this.searchInput = this.searchText;
    this.isSearchFiltered = !!this.searchText;
    this.syndicationTargetService.search(this.searchText);
    this.syndicationTargetService.updateParams({page: 1});
    await this.loadTargets(true);
    this.syndicationTargetService.searchQueryApplied = this.searchText;
  }

  public async deleteTargets() {
    this.selectedTargets = await Promise.filter(this.selectedTargets, async targetId => {
      const target = _.find(this.targets, {id: targetId});
      if (target.status === 'active') {
        this.notification.error(`Unable to delete active target ${target.description}`);
      } else {
        try {
          await this.syndicationTargetService.deleteTarget(targetId);
          return false;
        } catch (e) {
          return true;
        }
      }
      return false;
    });
    this.targets = _.cloneDeep(this.syndicationTargetService.syndicationTargets);
  }

  public async rowClickHandler(target) {
    this.target = {};
    this.target = _.cloneDeep(target);
    this.platformOption = target.platform;
    this.protocolOption = target.target_protocol;
    // eslint-disable-next-line no-prototype-builtins
    if (!this.clippingProfilesList.hasOwnProperty(target.platform)) {
      this.clippingProfilesList[target.platform] = await this.fetchClippingProfiles(target.platform);
    }
    if (target.platform_config && target.platform_config.clipping_profile_id) {
      this.profileIdOption = target.platform_config.clipping_profile_id;
      if (this.target.platform === SyndicationPlatformType.FACEBOOK && this.profileIdOption) {
        await this.fetchFaceBookPages(this.profileIdOption);
        if (target.platform_config.page_id) {
          this.facebookPageOption = target.platform_config.page_id;
        }
      }
    }
    this.targetDialog.show();
  }

  public toggleTargetSelected(target) {
    target.checkbox = !target.checkbox;
    this.trackSelectedEvent(target);
  }

  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.targets, target => {
      target.checkbox = isSelected;
    });

    this.selectedTargets = _.map(
      _.filter(this.targets, target => target.checkbox),
      target => target.id,
    );
  }

  public trackSelectedEvent(target) {
    if (target.checkbox) {
      this.selectedTargets.push(target.id);
    } else {
      _.remove(this.selectedTargets, targetId => targetId === target.id);
    }

    this.selectedTargets = _.uniq(this.selectedTargets);
  }

  public async loadMoreTargets() {
    if (this.isLoading || this.isLoadingMore || !this.syndicationTargetService.meta.hasMore) {
      return;
    }

    this.isLoadingMore = true;
    this.syndicationTargetService.getMoreTargets();
    await this.loadTargets(false);
  }
}
