import {IVNavSliderNavList, IVNavSliderPageList, SharedNav} from '@bindable-ui/bindable';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, singleton} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterConfiguration, RouterEvent} from 'aurelia-router';
import {Acceo} from 'services/acceo';
import {TemplatesService} from '../services/template-service';

export enum RouteName {
    TemplateList = 'templateList',
    TemplateSingle = 'templateSingle',
}

@singleton()
@autoinject()
export class TemplateMenu {
    protected navPage: IVNavSliderPageList = {
        navs: [],
        prevText: 'Slicers Menu',
        searchFn: this.search.bind(this),
        searchPlaceholder: 'Search Templates',
        searchQuery: '',
        title: 'Templates',
    };

    protected navs: IVNavSliderNavList[] = [];
    protected routerEventComplete: Subscription;
    protected routes = [
        {
            moduleId: PLATFORM.moduleName('./list/index'),
            name: RouteName.TemplateList,
            nav: false,
            route: '',
        },
        {
            moduleId: PLATFORM.moduleName('./single/index'),
            name: RouteName.TemplateSingle,
            nav: false,
            route: ':id',
        },
    ];

    protected templatesService: TemplatesService;

    constructor(
        protected acceo: Acceo,
        protected eventAggregator: EventAggregator,
        protected router: Router,
        protected sharedNav: SharedNav,
    ) {
        this.templatesService = new TemplatesService(acceo);
    }

    public attached() {
        this.routerEventComplete = this.eventAggregator.subscribe(
            RouterEvent.Complete,
            this.onRouterEventComplete.bind(this),
        );
        this.load();
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        this.router = router;
        config.map(this.routes);
    }

    public detached() {
        this.routerEventComplete.dispose();
        if (this.sharedNav.nav.pages.indexOf(this.navPage) !== -1) {
            this.sharedNav.nav.pages.splice(this.sharedNav.nav.pages.indexOf(this.navPage), 1);
        }
    }

    public async load() {
        if (this.router.currentInstruction.fragment) {
            this.sharedNav.replacePage(this.navPage, 1);
            this.sharedNav.setNextText('Template List');
        }
        this.navPage.isLoading = true;
        this.navs = await this.requestRead();
        this.updateNav();
        this.search();
        this.navPage.isLoading = false;
    }

    protected onRouterEventComplete() {
        this.updateNav();
    }

    protected async requestRead(): Promise<IVNavSliderNavList[]> {
        return this.templatesService.get().then(value =>
            value.items.map(item => ({
                active: false,
                href: `#/slicers/templates/${item.id}`,
                subText: item.desc,
                title: item.name,
            })),
        );
    }

    protected search(query: string = '') {
        return (this.navPage.navs = this.navs.filter(
            nav =>
                !query.length ||
                nav.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                nav.subText.toLowerCase().indexOf(query.toLowerCase()) !== -1,
        ));
    }

    protected updateNav() {
        let templateIndex = -1;
        this.navs.forEach((nav, index) => {
            nav.active =
                this.router.currentInstruction.fragment &&
                nav.href.indexOf(this.router.currentInstruction.fragment) !== -1;
            if (nav.active) templateIndex = index;
            return nav;
        }, this);
        if (templateIndex === -1) return;
        if (this.sharedNav.nav.pages.indexOf(this.navPage) !== -1) {
            this.sharedNav.setActive(1, templateIndex);
        } else {
            this.sharedNav.replacePage(this.navPage, 1);
            this.sharedNav.setNextText('Template List');
        }
    }
}
