import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {TableSortingTools} from 'utils/table-sorting-tools';
import {PlaylistService} from '../service';

@autoinject()
export class PlaylistList {
  public colModified;
  public currentSort: string = '';
  public currentPlaylistSort: string = 'desc';
  public hiddenColumns = [];
  public isLoading: boolean;
  public isSorting: boolean = false;
  public scrollToLoad: boolean = true;

  public hideableCols: any[] = [
    {
      colHeadName: 'id',
      colHeadValue: 'Playlist ID',
      selected: true,
    },
    {
      colHeadName: 'entries',
      colHeadValue: 'Entries',
      selected: true,
    },
    {
      colHeadName: 'total_duration',
      colHeadValue: 'Duration',
      selected: true,
    },
    {
      colHeadName: 'created',
      colHeadValue: 'Created',
      selected: true,
    },
  ];

  public createPlaylistActions = {
    onHide: () => {
      if (this.playlistService.createPlaylistForm) {
        this.playlistService.createPlaylistForm.reset();
      }
      this.playlistService.createErrMsg = '';
      this.playlistService.newPlaylistName = '';
    },
  };

  constructor(
    protected notification: CToastsService,
    public playlistService: PlaylistService,
    protected router: Router,
  ) {}

  public activate() {
    if (this.playlistService.params && this.playlistService.params.order) {
      this.currentSort = this.playlistService.params.order;
    }

    this.init();
  }

  public async init() {
    try {
      this.isLoading = true;
      await this.playlistService.getPlaylists({order: this.currentSort});
    } catch (err) {
      this.notification.error(`Error loading playlists: ${err}`);
    } finally {
      this.isLoading = false;
    }
  }

  public async deletePlaylists() {
    try {
      await this.playlistService.archivePlaylist(this.playlistService.selectedPlaylists);
      this.notification.success(
        `Deleted ${this.playlistService.selectedPlaylists.length} playlist${
          this.playlistService.selectedPlaylists.length === 1 ? '' : 's'
        }`,
      );
    } catch (reason) {
      this.notification.error(reason);
    }
  }

  public async search(textValue: string) {
    this.scrollToLoad = false;
    this.isLoading = true;
    await this.playlistService.search(textValue);
    this.isLoading = false;
    this.scrollToLoad = true;
  }

  public async sortRules(key: string, defaultSortDirection: string = null) {
    let order = defaultSortDirection;

    if (this.playlistService.params && this.playlistService.params.order) {
      order = this.playlistService.params.order;
    }

    const newOrder = TableSortingTools.sort(key, order, defaultSortDirection);

    this.currentSort = newOrder;
    this.isSorting = true;
    await this.playlistService.getPlaylists({order: newOrder});
    this.isSorting = false;
  }

  public goToSingle(playlistId) {
    this.playlistService.assetTableData = [];
    this.isLoading = true;
    this.router.navigateToRoute('playlistSingle', {id: playlistId});
  }

  public togglePlaylistSelected(playlist) {
    playlist.checkbox = !playlist.checkbox;
    this.playlistService.trackSelectedPlaylist(playlist);
  }

  public showHideColumn(col) {
    col.selected = !col.selected;
    if (col.selected) {
      const idx = this.hiddenColumns.indexOf(col.colHeadName);
      if (idx !== -1) {
        this.hiddenColumns.splice(idx, 1);
      }
    } else {
      this.hiddenColumns.push(col.colHeadName);
    }
    this.colModified = new Date();
  }

  @computedFrom('currentSort')
  public get sortedName(): number {
    return TableSortingTools.sorted('desc', this.currentSort);
  }

  @computedFrom('currentSort')
  public get sortedId(): number {
    return TableSortingTools.sorted('id', this.currentSort);
  }

  @computedFrom('currentSort')
  public get sortedCreated(): number {
    return TableSortingTools.sorted('created', this.currentSort);
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showIdCol(): boolean {
    return !this.hiddenColumns.includes('id');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showEntriesCol(): boolean {
    return !this.hiddenColumns.includes('entries');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showDurationCol(): boolean {
    return !this.hiddenColumns.includes('total_duration');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showCreatedCol(): boolean {
    return !this.hiddenColumns.includes('created');
  }
}
