import {autoinject, computedFrom} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {WebhookProfile} from '../../models/models';
import {WebhookService} from '../../services/profiles';

@autoinject()
export class WebhookSingleConfig {
    public isLoading: boolean = false;
    public profile: WebhookProfile;
    public isRevealed: boolean = false;
    public origToken: string;

    constructor(private profileService: WebhookService, private router: Router) {}

    /**
     * Determine the current model on activate.
     * @param params {Object} - route params.
     */
    public async activate(params) {
        const {id = null} = params;

        await this.profileService.getSingleProfile(id);

        this.profile = _.cloneDeep(this.profileService.currentModel);
        this.origToken = _.cloneDeep(this.profile.authentication_token);
    }

    /**
     * Adds/removes key from event_type toggled by checkbox.
     * @param key (String) event_type string.
     * @param checked (Boolean) if the key is being added/removed from the array.
     */
    public toggleEventType(key: string, checked: boolean) {
        if (checked) {
            this.profile.event_type.push(key);
        } else {
            const i = this.profile.event_type.indexOf(key, 0);
            if (i > -1) {
                this.profile.event_type.splice(i, 1);
            }
        }
    }

    /**
     * isAsset used for checkbox toggle `event_type` "asset".
     */
    @computedFrom('profile.event_type')
    get isAsset() {
        return this.profile.event_type.includes('asset');
    }

    /**
     * Set method for isAsset. Any time you toggle asset checkbox, we add/remove 'asset' from `event_type`.
     */
    set isAsset(value) {
        this.toggleEventType('asset', value);
    }

    /**
     * isClip used for checkbox toggle `event_type` "clip".
     */
    @computedFrom('profile.event_type')
    get isClip() {
        return this.profile.event_type.includes('clip');
    }

    /**
     * Set method for isClip. Any time you toggle clip checkbox, we add/remove 'clip' from `event_type`.
     */
    set isClip(value) {
        this.toggleEventType('clip', value);
    }

    @computedFrom('isRevealed')
    get tokenInputType() {
        const t: string = this.isRevealed ? 'text' : 'password';

        return t;
    }

    /**
     * Toggle isRevealed on and off.
     */
    public toggleTokenReveal() {
        this.isRevealed = !this.isRevealed;
    }

    /**
     * Resets token to original.
     */
    public resetToken() {
        this.profile.authentication_token = this.origToken;
    }

    /**
     * Save updates the profile.
     */
    public async save() {
        await this.profileService.saveProfile(this.profile);
        this.router.navigateToRoute('profilesList');
    }
}
