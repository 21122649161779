import * as moment from 'moment';

import {computedFrom, LogManager} from 'aurelia-framework';

import {BaseEventSingle} from './base';

// const DEFAULT_DURATION_MS = 3600 * 24 * 1000;
const DATETIME_FORMAT = 'MM/DD/YYYY - hh:mm A';

const logger = LogManager.getLogger('Live Events | Single | Details');

export class Details extends BaseEventSingle {
  public markerTemplates;

  async attached() {
    try {
      const markerTemplates = await this.liveEvents.getMarkerTemplates();
      this.markerTemplates = markerTemplates;
    } catch (err) {
      logger.error(err);
    }
  }

  deactivate() {
    super.deactivate();

    if (this.liveEvents.isLeavingNewRoute) {
      return;
    }

    const current = this.router.currentInstruction;
    const tabModel = this.liveEvents.cleanEvent(this.model);

    this.liveEvents.validateEvent(tabModel, current.config.name);
  }

  detached() {
    this.liveEvents.stopSinglePoll();
  }

  /* ---------------------------------------------------------------------- *\
     * Computed Properties
    \* ---------------------------------------------------------------------- */

  @computedFrom('isSaving')
  get saveBtnText() {
    return this.isSaving ? 'Saving...' : 'Save';
  }

  @computedFrom('isDeleting')
  get deleteBtnText() {
    return this.isSaving ? 'Deleting Event...' : 'Delete Event';
  }

  @computedFrom('model.state')
  get stateLabel() {
    if (this.model) {
      return this.liveEvents.states[this.model.state];
    }
    return '';
  }

  @computedFrom('model.testing_start', 'model.testing_complete')
  get testingLabel() {
    if (!this.model) {
      return '';
    }

    let label = 'Not Started';

    if (this.model.testing_start && this.model.testing_complete) {
      label = 'Complete';
    }

    if (this.model.testing_start && !this.model.testing_complete) {
      label = 'Testing';
    }

    return label;
  }

  @computedFrom('model.actual_start')
  get actualStartLabel() {
    if (!this.model) {
      return '';
    }

    const start = this.model.actual_start;
    let label = "Hasn't Started";

    if (start) {
      label = moment(start).format(DATETIME_FORMAT);
    }

    return label;
  }

  @computedFrom('model.actual_stop')
  get actualStopLabel() {
    if (!this.model) {
      return '';
    }

    const stop = this.model.actual_stop;
    let label = "Hasn't Ended";

    if (stop) {
      label = moment(stop).format(DATETIME_FORMAT);
    }

    return label;
  }

  @computedFrom('liveEvents.origEvent.actual_start', 'liveEvents.origEvent.expected_start')
  get displayExpectedStart() {
    if (!this.liveEvents.origEvent) {
      return 'None';
    }

    return this.liveEvents.origEvent.expected_start
      ? moment(this.liveEvents.origEvent.expected_start, 'x').format(DATETIME_FORMAT)
      : 'None';
  }

  @computedFrom('liveEvents.origEvent.actual_stop', 'liveEvents.origEvent.expected_stop')
  get displayExpectedStop() {
    if (!this.liveEvents.origEvent) {
      return 'None';
    }

    return this.liveEvents.origEvent.expected_stop
      ? moment(this.liveEvents.origEvent.expected_stop, 'x').format(DATETIME_FORMAT)
      : 'None';
  }

  @computedFrom('model.test_player_url')
  get hlsUrl() {
    if (this.model != null && this.model.test_player_url) {
      const parts = this.model.test_player_url.split('/');
      parts.splice(3, 2);
      parts.push('event', `${this.model.id}.m3u8`);
      return parts.join('/');
    }
    return null;
  }

  @computedFrom('model.test_player_url')
  get dashUrl() {
    if (this.model != null && this.model.test_player_url) {
      const parts = this.model.test_player_url.split('/');
      parts.splice(3, 2);
      parts.push('event', `${this.model.id}.mpd`);
      return parts.join('/');
    }
    return null;
  }

  @computedFrom('model.test_player_url')
  get llUrl() {
    if (this.model != null && this.model.test_player_url) {
      const parts = this.model.test_player_url.split('/');
      parts.splice(3, 2);
      parts.push('llevent', `${this.model.id}.m3u8`);
      return parts.join('/');
    }
    return null;
  }

  @computedFrom('model.test_player_url')
  get html5TestPlayerUrl() {
    if (this.model != null && this.model.test_player_url) {
      return this.model.test_player_url.replace('player', 'player5');
    }
    return null;
  }

  /* ---------------------------------------------------------------------- *\
     * Child Component Action Closures
    \* ---------------------------------------------------------------------- */

  /**
   * Action closure for startTime datetimepicker.
   *
   * Included Actions:
   * - onChange
   */
  startTimeCallbacks = {
    /**
     * Updates model with newValue.
     */
    onChange: timestamp => {
      if (timestamp === '') {
        this.model.expected_start = null;
      } else {
        this.model.expected_start = Number(timestamp);
      }
    },
  };

  /**
   * Action closure for stopTime datetimepicker.
   *
   * Included Actions:
   * - onChange
   */
  stopTimeCallbacks = {
    /**
     * Updates model with newValue.
     */
    onChange: timestamp => {
      if (timestamp === '') {
        this.model.expected_stop = null;
      } else {
        this.model.expected_stop = Number(timestamp);
      }
    },
  };
}
