export class DeleteWarningFooter {
  public controller: any;
  public model: any;
  public shared: any;

  public activate(model) {
    this.controller = model.controller;
    this.model = model.footer;
    this.shared = model.shared;
  }

  public save() {
    this.controller.ok({});
  }
}
