import {AureliaConfiguration} from 'aurelia-configuration';
import {PLATFORM} from 'aurelia-pal';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';

@inject(Router, AureliaConfiguration)
export class App {
    activate() {
        $('#screencss').remove();
    }

    constructor(router, config) {
        this.router = router;
        this.config = config;

        this.routes = [
            {
                route: '',
                redirect: 'introduction',
            },
            {
                route: 'introduction',
                name: 'introduction',
                moduleId: PLATFORM.moduleName('./routes/introduction/index', 'dev-docs'),
                nav: true,
                title: 'Introduction',
            },
            {
                route: 'components',
                name: 'components',
                moduleId: PLATFORM.moduleName('./routes/components/index', 'dev-docs'),
                nav: true,
                title: 'Components',
            },
            {
                route: 'authorization-examples',
                name: 'authorization-examples',
                moduleId: PLATFORM.moduleName('./routes/authorization-examples/index', 'dev-docs'),
                nav: true,
                title: 'Authorization Examples',
            },
        ];
    }

    configureRouter(config, router) {
        this.router = router;
        config.map(this.routes);
    }
}
