import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, singleton} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router, RouterConfiguration, RouterEvent} from 'aurelia-router';
import {Acceo} from 'services/acceo';
import {SubnavItem, SubnavList} from '../components/subnav-menu';
import {ProfilesService} from '../services/profiles-service';

// eslint-disable-next-line no-shadow
export enum RouteName {
  SlicerEncodingProfileList = 'slicerEncodingProfileList',
  SlicerEncodingProfileSingle = 'slicerEncodingProfileSingle',
}

@singleton()
@autoinject()
export class ProfilesMenu {
  protected navPage: SubnavList = {
    navs: [],
    title: 'Encoding Profiles List',
  };

  protected routerEventComplete: Subscription;
  protected routes = [
    {
      moduleId: PLATFORM.moduleName('./list/index'),
      name: RouteName.SlicerEncodingProfileList,
      nav: false,
      route: '',
      title: 'Encoding Profiles',
    },
    {
      moduleId: PLATFORM.moduleName('./single/index'),
      name: RouteName.SlicerEncodingProfileSingle,
      nav: false,
      route: ':id',
      title: 'Encoding Profile',
    },
  ];

  protected profilesService: ProfilesService;

  constructor(protected acceo: Acceo, protected eventAggregator: EventAggregator, protected router: Router) {
    this.profilesService = new ProfilesService(acceo);
  }

  public attached() {
    this.routerEventComplete = this.eventAggregator.subscribe(
      RouterEvent.Complete,
      this.onRouterEventComplete.bind(this),
    );
    this.load();
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }

  public detached() {
    this.routerEventComplete.dispose();
  }

  public async load() {
    this.navPage.isLoading = true;
    this.navPage.navs = await this.requestRead();
    this.updateNav();
    this.search();
    this.navPage.isLoading = false;
  }

  protected onRouterEventComplete() {
    this.updateNav();
  }

  protected async requestRead(): Promise<SubnavItem[]> {
    return this.profilesService.get().then(value =>
      value.items.map(item => ({
        active: false,
        href: `#/slicers/encoding-profiles/${item.id}`,
        // hopefully these desc values get cleaned up,
        // or we get a new customer-friendly property
        subText: item.desc,
        title: item.rate_card_id ? item.rate_card_id : 'Default',
      })),
    );
  }

  protected search(query: string = '') {
    // eslint-disable-next-line no-return-assign
    this.navPage.navs = this.navPage.navs.filter(
      nav =>
        !query.length ||
        nav.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        nav.subText.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    );
  }

  protected updateNav() {
    // let templateIndex = -1;

    if (this.navPage.navs) {
      this.navPage.navs.forEach(nav => {
        nav.active =
          this.router.currentInstruction.fragment && nav.href.indexOf(this.router.currentInstruction.fragment) !== -1;
        // if (nav.active) templateIndex = index;
        return nav;
      }, this);
    }
    // if (templateIndex === -1) return;
  }
}
