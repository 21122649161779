import {IContentFilterParams, ILibraryFilterParams, Library} from 'apps/cms/routes/content/models/models';
import {ContentService} from 'apps/cms/routes/content/services/content';
import {LibraryService} from 'apps/cms/routes/content/services/library';
import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, bindable} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {ClippingConfig} from '../models/clipping-config';

export const CLIPPING_BUMPERS_EVENT = {
    ConfigurationChanged: 'ClippingBumpersEvent:ConfigChanged',
};

@autoinject()
export class ClippingBumpers {
    @bindable public config: ClippingConfig;
    @bindable public validationErrors: ValidationError[];
    @bindable public callbacks;

    public hasError = false;
    public libraryParams: ILibraryFilterParams = {
        order: 'desc',
        page: 1,
        page_size: 1000,
    };

    public assetParams: IContentFilterParams = {
        order: 'title',
        page: 1,
        page_size: 1000,
    };

    public libraries: Library[] = [];
    public subscriptions: Subscription[] = [];

    constructor(
        public contentService: ContentService,
        public libraryService: LibraryService,
        public eventAggregator: EventAggregator,
    ) {}

    public async attached() {
        this.subscriptions.push(
            this.eventAggregator.subscribe(
                CLIPPING_BUMPERS_EVENT.ConfigurationChanged,
                opt =>
                    new Promise(resolve => {
                        this.init(opt.default_library, opt.default_intro, opt.default_outro);
                        resolve();
                    }),
            ),
        );

        const introId = this.config.default_intro;
        const outroId = this.config.default_outro;
        const library = await this.libraryService.getLibraries([], this.libraryParams);
        this.libraries = library.libraries;

        await this.loadLibraryAssets(this.config.default_library, introId, outroId);
    }

    public detached() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }

    public async loadLibraryAssets(libraryId, introId, outroId) {
        if (libraryId) {
            await this.selectedLibraryChanged(libraryId);
            this.config.default_intro = introId;
            this.config.default_outro = outroId;
        }
    }

    public async selectedLibraryChanged(value) {
        if (value) {
            this.contentService.libraryId = value;
            await this.contentService.getContent(this.assetParams);
        } else {
            this.config.default_library = '';
        }
    }

    public async init(libraryId, introId, outroId): Promise<any> {
        await this.loadLibraryAssets(libraryId, introId, outroId);
        return Promise.resolve();
    }
}
