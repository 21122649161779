// import {computedFrom} from 'aurelia-framework';

export interface IValidationLength {
  min?: number;
  max?: number;
}

export interface IValidationRange {
  gt?: number;
  gte?: number;
  lt?: number;
  lte?: number;
}

export interface IValidationOptions {
  /**
   * Custom validation function
   *
   * Requires "message" param be provided
   */
  customValidator?: (value: any, model?: any, scope?: any) => boolean;

  /**
   * Function for detecting if a validation should be active
   */
  enabled?: (model: any, scope?: any) => boolean;

  /**
   * Regex used to validate string format
   */
  format?: RegExp;

  /**
   * Validate the min or max length of a string or array
   */
  length?: IValidationLength;

  /**
   * Custom error message
   *
   * A Function can be provided for added control
   */
  message?: string | ((model: any, scope?: any) => string);

  /**
   * Used to indicate that the FormValidator should skip validation
   */
  passive?: boolean;

  /**
   * Indicates if a value is required for validation to succeed
   */
  presence?: boolean;

  /**
   * Validates integer ranges
   */
  range?: IValidationRange;
}

export class FieldValidation {
  public errorMsg: string;
  public passive: boolean = false;

  private model: any;
  private scope: any;

  constructor(private options: IValidationOptions) {}

  public validate(model: any, key: string, scope?: any) {
    this.model = model;
    this.scope = scope;

    let value: any;

    try {
      value = model[key];
    } catch {
      value = null;
    }

    const {enabled = () => true} = this.options;
    const types = Object.keys(this.options).filter(
      k =>
        ![
          'enabled',
          'message',
        ].includes(k),
    );

    this.errorMsg = null;

    if (!enabled(model, scope)) {
      return;
    }

    try {
      this.validateTypes(key, types, value);
    } catch (err) {
      this.errorMsg = err.message;
    }
  }

  private validateTypes(key: string, types: string[], value: any) {
    const {message} = this.options;

    const finalMsg = _.isFunction(message) ? message(this.model, this.scope) : message;

    types.forEach(type => {
      if (type === 'presence' && this.options[type] === true) {
        if (!value || value.length === 0) {
          throw new Error(finalMsg || `${key} is required`);
        }
      }

      if (type === 'customValidator') {
        try {
          if (!this.options[type](value, this.model, this.scope)) {
            throw new Error(finalMsg || 'Error message not provided');
          }
        } catch (ex) {
          throw Error(ex.message);
        }
      }

      if (type === 'format') {
        const regEx = this.options[type];

        if (!(regEx instanceof RegExp)) {
          throw Error('format must be a RegExp');
        }

        if (!regEx.test(value)) {
          throw new Error(finalMsg || 'Incorrect format');
        }
      }

      if (type === 'range') {
        const {gt = null, gte = null, lt = null, lte = null} = this.options[type];

        if (typeof value !== 'number') {
          throw new Error('value is not a number');
        }

        if (gt !== null && value <= gt) {
          throw new Error(finalMsg || `Must be greater than ${gt}`);
        }

        if (gte !== null && value < gte) {
          throw new Error(finalMsg || `Must be greater than or equal to ${gte}`);
        }

        if (lt !== null && value >= lt) {
          throw new Error(finalMsg || `Must be less than ${lt}`);
        }

        if (lte !== null && value > lte) {
          throw new Error(finalMsg || `Must be less than or equal to ${lte}`);
        }
      }

      if (type === 'length') {
        const {min, max} = this.options[type];
        const _value = value || '';

        if (min && _value.length < min) {
          throw new Error(finalMsg || `Min length is ${min}`);
        }

        if (max && _value.length > max) {
          throw new Error(finalMsg || `Max length is ${max}`);
        }
      }
    });
  }
}
