import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, BindingEngine, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {PLAYLIST_ASSETS_LIMIT} from 'apps/cms/routes/constants';
import {SessionService} from 'services/session';
import {ValidateHelper} from 'resources/validate-helper';
import dragula, {DragulaOptions, Drake} from 'dragula';
import {BasePlaylistSingle} from '../base';
import {PlaylistService} from '../../service';
import {AdBreak} from './models';
import {IPlaylistAssetsTableRow} from './table-models';

@autoinject()
export class PlaylistAssets extends BasePlaylistSingle {
  public tabFields: string[] = ['playlist'];
  public adBreak: AdBreak;
  public limit = PLAYLIST_ASSETS_LIMIT;
  public newAdBreakPopover: any;
  private drake: Drake | null = null;
  public orderModified;
  public selectedModified;

  @computedFrom('playlistService.assetTableData.length')
  get tabDirty(): boolean {
    const dirty = !_.isEqual(this.playlistService.cleanAssetTableData, this.playlistService.assetTableData);
    this.fields.playlist.isDirty = dirty;
    return dirty;
  }

  @computedFrom('playlistService.assetTableData.length')
  get addEntriesBtnState(): boolean {
    if (this.playlistService.assetTableData.length >= this.limit) {
      return true;
    }

    return false;
  }

  @computedFrom('playlistService.assetTableData', 'selectedModified')
  get selectedRows(): any[] {
    return this.playlistService.assetTableData.filter(row => row.selected);
  }

  constructor(
    public playlistService: PlaylistService,
    public session: SessionService,
    public dialogService: DialogService,
    public notification: CToastsService,
    public bindingEngine: BindingEngine,
  ) {
    super(bindingEngine, notification, playlistService, session, dialogService) /* istanbul ignore next */;
  }

  public async activate(params) {
    await super.activate(params);

    this.playlistService.cleanAssetTableData = _.clone(this.playlistService.assetTableData);
  }

  public attached() {
    this.setupDragDrop();
  }

  public onDirtyPromptLeave() {
    this.playlistService.model = _.cloneDeep(this.playlistService.playlist);
    this.playlistService.assetTableData = _.cloneDeep(this.playlistService.cleanAssetTableData);
  }

  /**
   * Open the Add Assets Dialog Form
   */
  public openAddAssetsModal() {
    const modalValue = {selectedAssets: []};
    this.dialogService
      .open({
        model: {
          bodyViewModel: PLATFORM.moduleName('resources/components/playlists/modals/add-assets/body'),
          footerEnable: true,
          footerViewModel: PLATFORM.moduleName('resources/components/playlists/modals/add-assets/footer'),
          // Adding 'Enter' in the list below should close the dialog with confirmation
          // but it does not work. A bug in the lib or in the implementation of c-text-input.
          keyboard: ['Escape'],
          sharedModel: modalValue,
          size: 'large',
          title: 'Add Asset(s)',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed()
      .then(response => {
        if (response.output) {
          // Add selectedAssets
          modalValue.selectedAssets.forEach(asset => {
            this.addAsset(asset, this.playlistService.assetTableData.length + 1);
            this.playlistService.model['@included'].push(asset);
          });
        }
      });
  }

  public removeSelectedPlaylistItems() {
    this.playlistService.assetTableData = this.playlistService.assetTableData.filter(row => row.selected !== true);
    this.updateEntriesOrder();
  }

  public async submitAddAdBreakPopover() {
    if (await ValidateHelper.validate(this.adBreak.input, this.adBreak.errors)) {
      if (this.playlistService.assetTableData.length + 1 <= PLAYLIST_ASSETS_LIMIT) {
        this.addAdBreak(this.adBreak.input.durationAsNumber, this.playlistService.assetTableData.length + 1);
        if (this.newAdBreakPopover) {
          this.newAdBreakPopover.hide();
        }
      } else {
        this.notification.error(`Can't add more than ${PLAYLIST_ASSETS_LIMIT} assets`);
      }
    }
  }

  public initNewAdBreak() {
    this.adBreak = new AdBreak();
  }

  private setupDragDrop(): void {
    if (this.drake) {
      this.drake.destroy();
      this.drake = null;
    }
    const entryList = document.getElementById('entry-list');

    if (!entryList) return;
    const options: DragulaOptions = {
      moves: (_, __, handle) => handle.classList.contains('js--drag'),
    };

    this.drake = dragula([entryList], options);

    this.drake.on('drop', (_, target) => {
      const newOrder = Array.from(target.children)
        .map((li: HTMLElement) => li.getAttribute('data-uid'))
        .filter(Boolean);

      const sortedEntries = newOrder.map((uid, index) => {
        const entryToUpdate = this.playlistService.assetTableData.find(row => row.uid === uid);
        if (entryToUpdate) {
          entryToUpdate.order = index + 1;
        }
        return entryToUpdate;
      });

      // Update assetTableData with sorted entries
      this.playlistService.assetTableData = sortedEntries.filter(Boolean);

      this.sortEntriesByOrder();
    });
  }

  private sortEntriesByOrder(): void {
    this.playlistService.assetTableData.sort((a, b) => a.order - b.order);
  }

  private updateEntriesOrder(): void {
    this.playlistService.assetTableData.forEach((row, index) => {
      row.order = index + 1;
    });
  }

  public toggleRowSelected(row) {
    row.selected = !row.selected;
    this.selectedModified = new Date();
  }

  public toggleSelectAll(isSelected: boolean) {
    this.playlistService.assetTableData.forEach(row => {
      row.selected = isSelected;
    });
    this.selectedModified = new Date();
  }

  public onDurationChange(value: number, row: IPlaylistAssetsTableRow): void {
    if (value !== row.duration) {
      const clonedRow = _.cloneDeep(row);
      clonedRow.duration = value;
      this.playlistService.assetTableData = this.playlistService.assetTableData.filter(e => e.uid !== clonedRow.uid);
      this.playlistService.assetTableData.push(clonedRow);
      this.sortEntriesByOrder();
    }
  }
}
