import {IsArray, IsBoolean, IsNumber, IsOptional, IsString, IsJSON} from 'class-validator';

export interface IIdsClient {
    id?: string;
    name?: string;
    post_logout_redirect_uris?: any[];
    redirect_uris?: any[];
}

export class IdsClient implements IIdsClient {
    // Added only minimal fields I think their are more fields to display on this client Data page
    @IsString()
    @IsOptional()
    public id?: string = '';

    @IsString()
    @IsOptional()
    public name?: string = '';

    @IsString()
    @IsOptional()
    public description?: string = '';

    @IsJSON()
    @IsOptional()
    public tenant?: any;

    @IsBoolean()
    @IsOptional()
    public allow_offline_access?: boolean = false;

    @IsArray()
    @IsOptional()
    public scopes?: any[] = [];

    @IsNumber()
    @IsOptional()
    public identity_token_lifetime?: number = 0;

    @IsNumber()
    @IsOptional()
    public access_token_lifetime?: number = 0;

    @IsNumber()
    @IsOptional()
    public authorization_code_lifetime?: number = 0;

    @IsNumber()
    @IsOptional()
    public absolute_refresh_token_lifetime?: number = 0;

    @IsNumber()
    @IsOptional()
    public sliding_refresh_token_lifetime?: number = 0;

    @IsArray()
    @IsOptional()
    public post_logout_redirect_uris?: any[] = [];

    @IsArray()
    @IsOptional()
    public redirect_uris?: any[] = [];

    @IsBoolean()
    @IsOptional()
    public sliding_refresh_token_expiration?: boolean = false;

    @IsBoolean()
    @IsOptional()
    public require_consent?: boolean = false;

    @IsBoolean()
    @IsOptional()
    public require_pkce?: boolean = false;

    @IsBoolean()
    @IsOptional()
    public is_captcha_enabled?: boolean = false;

    @IsArray()
    @IsOptional()
    public allowed_grant_types?: any[] = [];
}
export class Rsponse {
    @IsNumber()
    public success: Number;

    @IsBoolean()
    public msg: Boolean;
}
