import {Expose, Transform, Type} from 'class-transformer';
import {
    IsBoolean,
    IsDefined,
    IsIn,
    IsInt,
    IsNotEmpty,
    IsOptional,
    IsString,
    Max,
    MaxLength,
    Min,
    ValidateIf,
    ValidateNested,
} from 'class-validator';
import {DRMConfigUtil} from '../services/drm-config-util';

const stringToBoolean = value => {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string') return value.toLowerCase() === 'true';
    return value;
};

export class DRMConfig {
    @IsString()
    @Expose({name: '_id'})
    public id: string;

    // if {skipMissingProperties: true} in validate function, @isDefined() fields are still checked.
    @IsDefined({message: 'You must enter a value.'})
    @IsNotEmpty({message: 'You must enter a value.'})
    @IsString()
    @Expose({name: 'policy_name'})
    @MaxLength(DRMConfigUtil.VALUE_MAX_LENGTH, {message: 'Name must be $constraint1 characters or less.'})
    @Transform(value => value.trim(), {toPlainOnly: true})
    public name: string;

    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    public created: number;

    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    public lastmod: number;

    // _orig_name is used as a primary key in server side code.
    @Transform(value => value, {toClassOnly: true})
    @IsString()
    public origName: string;

    // runtime field, need not be serialized
    public skipValidate: boolean;

    @IsOptional() // ignores null or undefined
    @ValidateNested()
    @Type(() => Fairplay)
    public fairplay: Fairplay;

    @IsOptional()
    @ValidateNested()
    @Type(() => PlayReady)
    public playready: PlayReady;

    @IsOptional()
    @ValidateNested()
    @Type(() => Widevine)
    public widevine: Widevine;
}

export class Fairplay {
    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.lease_duration_seconds}`.length > 0) // ignores '' values.
    public lease_duration_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.rental_duration_seconds}`.length > 0)
    public rental_duration_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.playback_duration_seconds}`.length > 0)
    public playback_duration_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.persistence_duration_seconds}`.length > 0)
    public persistence_duration_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.key_duration_seconds}`.length > 0)
    public key_duration_seconds: number;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public rental: boolean;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public persistence: boolean;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public lease: boolean;

    @ValidateNested()
    @Type(() => FairplayTrackTypeRestriction)
    public sd: FairplayTrackTypeRestriction;

    @ValidateNested()
    @Type(() => FairplayTrackTypeRestriction)
    public hd: FairplayTrackTypeRestriction;

    @ValidateNested()
    @Type(() => FairplayTrackTypeRestriction)
    public uhd1: FairplayTrackTypeRestriction;

    @ValidateNested()
    @Type(() => FairplayTrackTypeRestriction)
    public uhd2: FairplayTrackTypeRestriction;
}

export class FairplayTrackTypeRestriction {
    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public allow_airplay: boolean;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public allow_av_adapter: boolean;

    @IsOptional()
    @IsIn([-1, 0, 1])
    @ValidateIf(o => `${o.hdcp_enforcement}`.length > 0)
    public hdcp_enforcement: number;
}

export class PlayReady {
    @ValidateNested()
    @Type(() => PlayReadyTrackTypeRestriction)
    public sd: PlayReadyTrackTypeRestriction;

    @ValidateNested()
    @Type(() => PlayReadyTrackTypeRestriction)
    public hd: PlayReadyTrackTypeRestriction;

    @ValidateNested()
    @Type(() => PlayReadyTrackTypeRestriction)
    public uhd1: PlayReadyTrackTypeRestriction;

    @ValidateNested()
    @Type(() => PlayReadyTrackTypeRestriction)
    public uhd2: PlayReadyTrackTypeRestriction;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.license_duration_seconds}`.length > 0)
    public license_duration_seconds: number;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public can_persist: boolean;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public can_play: boolean;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.playback_duration_seconds}`.length > 0)
    public playback_duration_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.license_begin_seconds}`.length > 0)
    public license_begin_seconds: number;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public realtime_expiration: boolean;
}

export class PlayReadyTrackTypeRestriction {
    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public play_enabler: boolean;

    @IsOptional()
    @IsIn([150, 2000, 3000])
    @ValidateIf(o => `${o.security_level}`.length > 0)
    public security_level: number;

    @IsOptional()
    @IsIn([100, 250, 270, 300, 301])
    @ValidateIf(o => `${o.digital_video_protection_level}`.length > 0)
    public digital_video_protection_level: number;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public require_hdcp_type_1: boolean;

    @IsOptional()
    @IsIn([100, 150, 200, 201])
    @ValidateIf(o => `${o.analog_video_protection_level}`.length > 0)
    public analog_video_protection_level: number;

    @IsOptional()
    @IsIn([100, 250, 300, 301])
    @ValidateIf(o => `${o.uncompressed_digital_audio_protection_level}`.length > 0)
    public uncompressed_digital_audio_protection_level: number;

    @IsOptional()
    @IsIn([100, 301])
    @ValidateIf(o => `${o.compressed_digital_audio_protection_level}`.length > 0)
    public compressed_digital_audio_protection_level: number;
}

export class Widevine {
    @IsOptional()
    @ValidateNested({each: true})
    @Type(() => ContentKeySpec)
    public content_key_specs: ContentKeySpec[];

    @IsOptional()
    @ValidateNested()
    @Type(() => PolicyOverrides)
    public policy_overrides: PolicyOverrides;
}

const CONTENT_KEY_SPEC_ERR = 'Either security level or hdcp is required.';

export class ContentKeySpec {
    @IsIn(['ALL', 'ALL_VIDEO', 'AUDIO', 'HD', 'SD', 'UHD1', 'UHD2'])
    public track_type: string;

    @IsDefined({message: CONTENT_KEY_SPEC_ERR})
    @IsIn([1, 2, 3, 4, 5], {message: CONTENT_KEY_SPEC_ERR})
    @ValidateIf(o => !_.get(o, 'required_output_protection.hdcp'))
    public security_level: number;

    @IsDefined({message: CONTENT_KEY_SPEC_ERR})
    @ValidateIf(o => !_.get(o, 'security_level'))
    @ValidateNested()
    @Type(() => RequiredOutputProtection)
    public required_output_protection: RequiredOutputProtection;
}

export class RequiredOutputProtection {
    @IsOptional()
    @IsIn(['', 'HDCP_NONE', 'HDCP_V1', 'HDCP_V2', 'HDCP_V2_1', 'HDCP_V2_2', 'HDCP_NO_DIGITAL_OUTPUT'])
    public hdcp?: string;

    @IsOptional()
    @IsIn(['', 'HDCP_SRM_RULE_NONE', 'CURRENT_SRM'])
    public hdcp_srm_rule?: string;

    @IsOptional()
    @IsIn(['', 'CGMS_NONE', 'COPY_FREE', 'COPY_ONCE', 'COPY_NEVER'])
    public cgms_flags?: string;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public disable_analog_output?: boolean;
}

export class PolicyOverrides {
    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public always_include_client_id: boolean;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public can_play: boolean;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public can_persist: boolean;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public can_renew: boolean;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.rental_duration_seconds}`.length > 0)
    public rental_duration_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.playback_duration_seconds}`.length > 0)
    public playback_duration_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.license_duration_seconds}`.length > 0)
    public license_duration_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.renewal_recovery_duration_seconds}`.length > 0)
    public renewal_recovery_duration_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.renewal_delay_seconds}`.length > 0)
    public renewal_delay_seconds: number;

    @IsOptional()
    @IsInt()
    @Min(DRMConfigUtil.NUM_MIN)
    @Max(Number.MAX_SAFE_INTEGER)
    @ValidateIf(o => `${o.renewal_retry_interval_seconds}`.length > 0)
    public renewal_retry_interval_seconds: number;

    @IsOptional()
    @IsBoolean()
    @Transform(stringToBoolean, {toClassOnly: true})
    public renew_with_usage: boolean;
}
