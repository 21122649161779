import {ProfilesResponseItem} from 'apps/cms/routes/slicers/models/profiles';
import {autoinject, singleton} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {ChangeLog, IngestPoint, Slicer, SlicerRestartResult, StreamType, Zone} from '../models/slicer';

@autoinject()
@singleton()
export class SlicerService {
  constructor(public acceo: Acceo) {}

  public getSlicers(): Promise<Slicer[]> {
    return this.acceo.get([Slicer])(`/api/v4/ingest/live/slicers/`, {prefix: 'items'});
  }

  public getSlicer(slicerId: string = ''): Promise<Slicer> {
    return this.acceo.get(Slicer)(`/api/v4/ingest/live/slicers/${slicerId}`);
  }

  public updateSlicer(slicer: Slicer): Promise<any> {
    return this.acceo.patch()(`/api/v4/ingest/live/slicers/${slicer.id}`, {encoding_profile_id: slicer.profile.id});
  }

  public restartSlicer(slicerId: string = ''): Promise<SlicerRestartResult[]> {
    return this.acceo.post([SlicerRestartResult])(
      '/api/v4/slicers/restart',
      {slicer_ids: [slicerId]},
      {prefix: 'items'},
    );
  }

  public getProfiles(): Promise<ProfilesResponseItem[]> {
    return this.acceo.get([ProfilesResponseItem])('/api/v4/profiles', {prefix: 'items'});
  }

  public getSlicerChangeLog(type: string, id: string): Promise<ChangeLog[]> {
    return this.acceo.get([ChangeLog])(`/api/v4/slicer/changelog/${type}/${id}`, {prefix: 'items'});
  }

  public getZones(): Promise<any> {
    return this.acceo.get([Zone])('/api/v4/mso/hostingloc', {prefix: 'items'});
  }

  public getSlicerTypes(): Promise<any> {
    return this.acceo.get([StreamType])('/api/v4/mso/streamproto', {prefix: 'items'});
  }

  public getIngestPoints(id: string = ''): Promise<IngestPoint[]> {
    return this.acceo.get([IngestPoint])(`/api/v4/mso/slicer/reservations/${id}`, {
      responseTransform: data => {
        const itemsArray = id ? [data] : data.items;
        let port = '';
        let slicers = [];
        let activeSlicer = '';
        _.each(itemsArray, i => {
          _.set(i, 'name', i.endpoint_name);
          _.set(i, 'description', i.desc);
          _.set(i, 'status', i.ingest_point.status);

          _.set(i, 'stream_type.id', i.stream_type_id);

          _.set(i, 'stream_type.name', i.stream_type_name);
          _.set(i, 'stream_type_name', i.stream_type_name);
          _.set(i, 'zone.id', i.server_region_id);
          _.set(i, 'zone.region', i.ingest_point.server_region);
          _.set(i, 'region', i.ingest_point.server_region);

          _.set(i, 'endpoint_id', i.ingest_point.ingest_point_id);
          _.set(i, 'domain_name', i.ingest_point.reserved_domain_name);

          port = _.get(i.ingest_point.reserved_ports, 'port', '');
          _.set(i, 'port', port);

          _.set(i, 'ports.port', port);
          _.set(i, 'ports.port_fec2', _.get(i.ingest_point.reserved_ports, 'port_fec2', ''));
          _.set(i, 'ports.port_fec4', _.get(i.ingest_point.reserved_ports, 'port_fec4', ''));

          _.set(i, 'connection.sourceUrl', i.ingest_point.stream_rtmp_url);
          _.set(i, 'connection.secureSourceUrl', i.ingest_point.secure_rtmp_url);
          _.set(i, 'connection.streamKey', i.ingest_point.slicer_stream_key);
          _.set(i, 'slicerAccountName', i.ingest_point.slicer_account_name);
          activeSlicer = '';
          slicers = _.get(i, 'slicers', []);
          if (slicers.length) {
            activeSlicer = slicers[0];
          }
          _.set(i, 'active_slicer', activeSlicer);
        });
        return itemsArray;
      },
    });
  }

  public createIngestPoints(ingestPoint: IngestPoint): Promise<IngestPoint> {
    return this.acceo.post(IngestPoint)(
      '/api/v4/mso/slicer/reservations',
      {
        desc: ingestPoint.description,
        endpoint_name: ingestPoint.name,
        server_region_id: ingestPoint.zone.id,
        stream_type_id: ingestPoint.stream_type.id,
      },
      {
        responseTransform: i => {
          _.set(i, 'name', i.endpoint_name);
          _.set(i, 'description', i.desc);
          _.set(i, 'status', i.ingest_point.status);

          _.set(i, 'stream_type.id', i.stream_type_id);
          _.set(i, 'stream_type.name', i.stream_type_name);
          _.set(i, 'zone.region', i.ingest_point.server_region);

          _.set(i, 'endpoint_id', i.ingest_point.ingest_point_id);
          _.set(i, 'domain_name', i.ingest_point.reserved_domain_name);

          _.set(i, 'ports.port', _.get(i.ingest_point.reserved_ports, 'port', ''));
          _.set(i, 'ports.port_fec2', _.get(i.ingest_point.reserved_ports, 'port_fec2', ''));
          _.set(i, 'ports.port_fec4', _.get(i.ingest_point.reserved_ports, 'port_fec4', ''));

          _.set(i, 'connection.sourceUrl', i.ingest_point.stream_rtmp_url);
          _.set(i, 'connection.secureSourceUrl', i.ingest_point.secure_rtmp_url);
          _.set(i, 'connection.streamKey', i.ingest_point.slicer_stream_key);
          _.set(i, 'slicerAccountName', i.ingest_point.slicer_account_name);
          return i;
        },
      },
    );
  }

  public deleteIngestPoint(id: string): Promise<any> {
    return this.acceo.delete()(`/api/v4/mso/slicer/reservations/${id}`);
  }

  public updateIngestPoint(id: string, params: object): Promise<any> {
    return this.acceo.patch(IngestPoint)(`/api/v4/mso/slicer/reservations/${id}`, params, {
      responseTransform: i => {
        _.set(i, 'name', i.endpoint_name);
        _.set(i, 'description', i.desc);
        _.set(i, 'status', i.ingest_point.status);

        _.set(i, 'stream_type.id', i.stream_type_id);
        _.set(i, 'stream_type.name', i.stream_type_name);
        _.set(i, 'zone.region', i.ingest_point.server_region);

        _.set(i, 'endpoint_id', i.ingest_point.ingest_point_id);
        _.set(i, 'domain_name', i.ingest_point.reserved_domain_name);

        _.set(i, 'ports.port', _.get(i.ingest_point.reserved_ports, 'port', ''));
        _.set(i, 'ports.port_fec2', _.get(i.ingest_point.reserved_ports, 'port_fec2', ''));
        _.set(i, 'ports.port_fec4', _.get(i.ingest_point.reserved_ports, 'port_fec4', ''));

        _.set(i, 'connection.sourceUrl', i.ingest_point.stream_rtmp_url);
        _.set(i, 'connection.secureSourceUrl', i.ingest_point.secure_rtmp_url);
        _.set(i, 'connection.streamKey', i.ingest_point.slicer_stream_key);
        _.set(i, 'slicerAccountName', i.ingest_point.slicer_account_name);
        return i;
      },
    });
  }
}
