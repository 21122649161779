export class IpNetworks {
    public controller: any;
    public model: any;
    public shared: any;

    public activate(model) {
        this.controller = model.controller;
        this.model = model.body;
        this.shared = model.shared;
    }
}
