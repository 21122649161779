import {autoinject, computedFrom, observable} from 'aurelia-framework';
import {authorizationConstants} from 'services/authorization';
import {
  SyndicationHlsUriType,
  SyndicationPlatformType,
  SyndicationScheduleVideoQualityType,
  SyndicationScte35Types,
  SyndicationTargetProtocolType,
  SyndicationYouTubeIntegratedPrivacy,
} from 'services/models/syndication';

@autoinject()
@authorizationConstants
export class PublishTargetBody {
  public job: any;
  public controller: any;
  @observable public ytMadeForKidsRadio: string;
  public showTargetPrepareError: boolean = false;

  public srtEventListeners = {
    blur: () => {
      this.onSrtLatencyBlur();
    },
  };

  public activate(model) {
    this.job = model.shared;
    this.controller = model.controller;

    let ytMadeForKids = false;
    if ('target_platform_config' in this.job && 'integrated_yt_made_for_kids' in this.job.target_platform_config) {
      ytMadeForKids = this.job.target_platform_config.integrated_yt_made_for_kids;
    }
    this.ytMadeForKidsRadio = `yt_made_for_kids_${ytMadeForKids}`;

    this.showTargetPrepareError =
      this.job.target_info &&
      this.job.status === 'error' &&
      (this.job.target_info.platform === SyndicationPlatformType.YOUTUBE ||
        this.job.target_info.platform === SyndicationPlatformType.FACEBOOK ||
        this.job.target_protocol === SyndicationTargetProtocolType.ZIXI ||
        this.job.target_protocol === SyndicationTargetProtocolType.RTMP_PULL);
  }

  @computedFrom('SyndicationScheduleVideoQualityType')
  get qualityOptions() {
    return _.map(SyndicationScheduleVideoQualityType, info => ({
      text: info.charAt(0).toUpperCase() + info.slice(1),
      value: info,
    }));
  }

  @computedFrom('job.rayDetail')
  get rayDetailAvailable() {
    return this.job.rayDetail !== undefined;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayDescription() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = `<span>${this.job.rayDetail.high.max_width}x${this.job.rayDetail.high.max_height}</span> • <span>${this.job.rayDetail.high.fps}fps</span> • <span>${this.rayVideo}</span> • <span>${this.job.rayDetail.high.audio_codec} Audio @ ${this.job.rayDetail.high.audio_kbps}Kbps</span>`;
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = `<span>${this.job.rayDetail.medium.max_width}x${this.job.rayDetail.medium.max_height}</span> • <span>${this.job.rayDetail.medium.fps}fps</span> • <span>${this.rayVideo}</span> • <span>${this.job.rayDetail.medium.audio_codec} Audio @ ${this.job.rayDetail.medium.audio_kbps}Kbps</span>`;
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = `<span>${this.job.rayDetail.low.max_width}x${this.job.rayDetail.low.max_height}</span> • <span>${this.job.rayDetail.low.fps}fps</span> • <span>${this.rayVideo}</span> • <span>${this.job.rayDetail.low.audio_codec} Audio @ ${this.job.rayDetail.low.audio_kbps}Kbps</span>`;
      }
    }
    return value;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayResolution() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayResolutionDescription(this.job.rayDetail.high);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayResolutionDescription(this.job.rayDetail.medium);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayResolutionDescription(this.job.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayFPS() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayFPSDescription(this.job.rayDetail.high);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayFPSDescription(this.job.rayDetail.medium);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayFPSDescription(this.job.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayVideo() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayVideoDescription(this.job.rayDetail.high);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayVideoDescription(this.job.rayDetail.medium);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayVideoDescription(this.job.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('job.rayDetail', 'job.video_quality')
  get rayAudio() {
    let value = '';
    if (this.job.rayDetail !== undefined) {
      if (this.job.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayAudioDescription(this.job.rayDetail.high);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayAudioDescription(this.job.rayDetail.medium);
      } else if (this.job.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayAudioDescription(this.job.rayDetail.low);
      }
    }
    return value;
  }

  get showAllVideos() {
    return (
      this.job.target_protocol === SyndicationTargetProtocolType.HLS_PUSH ||
      this.job.target_protocol === SyndicationTargetProtocolType.HLS_PULL
    );
  }

  @computedFrom('job.rayDetail')
  get getAllRays() {
    if (this.job.rayDetail === undefined) {
      return [];
    }
    return _.map(this.job.rayDetail.all, ray => ({
      id: ray.ray_name,
      label: `${this.rayResolutionDescription(ray)} • ${this.rayFPSDescription(ray)}fps •
                ${this.rayVideoDescription(ray)} • ${this.rayAudioDescription(ray)}`,
      value:
        this.job.target_platform_config.rays.length === 0 ||
        this.job.target_platform_config.rays.includes(ray.ray_name),
    }));
  }

  public onRayCheckedChange(ray, isChecked) {
    ray.value = isChecked;

    const rays = _.cloneDeep(this.job.target_platform_config.rays);
    if (rays.includes(ray.id)) {
      const idx = rays.indexOf(ray.id);
      if (idx !== -1) {
        rays.splice(idx, 1);
      }
    } else {
      rays.push(ray.id);
    }
    rays.sort();
    this.job.target_platform_config.rays = _.cloneDeep(rays);
  }

  get showChannelIdInput() {
    return this.job.hls_uri_type === SyndicationHlsUriType.CHANNEL_ID;
  }

  public rayVideoDescription(ray) {
    let value = '';
    if (ray !== undefined) {
      if (ray.video_kbps >= 1000) {
        const speed = (ray.video_kbps / 1000).toFixed(2);
        value = `${ray.video_codec} Video @ ${speed}Mbps`;
      } else {
        value = `${ray.video_codec} Video @ ${ray.video_kbps}Kbps`;
      }
    }
    return value;
  }

  public rayAudioDescription(ray) {
    return `${ray.audio_codec} Audio @ ${ray.audio_kbps}Kbps`;
  }

  public rayResolutionDescription(ray) {
    return `${ray.max_width}x${ray.max_height}`;
  }

  public rayFPSDescription(ray) {
    return `${ray.fps}`;
  }

  @computedFrom('SyndicationYouTubeIntegratedPrivacy')
  get youTubeIntegratedPrivacyOptions() {
    return _.map(SyndicationYouTubeIntegratedPrivacy, info => ({
      text: info.charAt(0).toUpperCase() + info.slice(1),
      value: info,
    }));
  }

  public async resetStatusError() {
    const success = await this.job.resetSchedule(this.job.id);
    if (success) {
      this.controller.cancel();
    }
  }

  @computedFrom('SyndicationScte35Types')
  get scte35Types() {
    const s35options = [
      {
        text: 'DATERANGE',
        value: SyndicationScte35Types.DATERANGE,
      },
      {
        text: 'CUE-OUT / IN',
        value: SyndicationScte35Types.CUE,
      },
    ];
    return s35options.filter(
      value =>
        !(this.job.target_info.platform === SyndicationPlatformType.WURL && value.value !== SyndicationScte35Types.CUE),
    );
  }

  public ytMadeForKidsRadioChanged(newVal: string) {
    if ('target_platform_config' in this.job && 'integrated_yt_made_for_kids' in this.job.target_platform_config) {
      this.job.target_platform_config.integrated_yt_made_for_kids = newVal.split('_').pop() === 'true';
    }
  }

  public onSrtLatencyBlur() {
    let srtLatency: any = `${this.job.target_platform_config.srt_latency}`;
    srtLatency = srtLatency.replace(/\D/g, '');
    srtLatency = parseInt(srtLatency, 10);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(srtLatency) || srtLatency < 120 || srtLatency > 15000) {
      srtLatency = 500;
    }
    this.job.target_platform_config.srt_latency = `${srtLatency}`;
  }
}
