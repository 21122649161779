import VanillaModal from 'vanilla-modal';

import {bindable, containerless} from 'aurelia-framework';

@containerless
export class Modal {
    @bindable
    id;

    @bindable
    type;

    attached() {
        this.modal = new VanillaModal();
    }

    open() {
        // Set color class (based on `type`) before opening modal.
        if (this.type) {
            const $modal = $('.modal');
            $modal.removeClass('modal--neutral modal--healthy modal--warning modal--critical modal--attention');
            $modal.addClass(`modal--${this.type}`);
        }

        this.modal.open(`#${this.id}`);
    }

    close() {
        this.modal.close();
        // if (this.type) {
        //     setTimeout(() => {
        //         $('.modal').removeClass(`modal--${this.type}`);
        //     }, 500);
        // }
    }
}
