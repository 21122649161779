import {autoinject} from 'aurelia-framework';
import {HostedSlicersSingle} from '.';
import {ProfilesResponseItem, ProfilesResponseItems} from '../../models/profiles';

@autoinject()
export class HostedSlicersSingleGeneral {
  public encodingProfiles: ProfilesResponseItems = null;
  public encodingProfileDialog: any = null;
  public selectedProfile: ProfilesResponseItem = null;

  public allowProtocolChange: boolean = false;
  public allowRegionChange: boolean = false;

  constructor(public parentView: HostedSlicersSingle) {}

  public async onShowChangeProfileDialog() {
    this.encodingProfileDialog.show();
    this.selectedProfile = _.cloneDeep(this.parentView.profile);

    if (!this.encodingProfiles) {
      this.parentView.loadEncodingProfiles();
    }
  }

  public setProfileHandler = profile => {
    this.selectedProfile = profile;
  };

  public onChangeProfile() {
    this.parentView.slicer.encoding_profile_id = this.selectedProfile.id;
    this.parentView.profile = this.selectedProfile;
    this.encodingProfileDialog.hide();
  }
}
