export class LayoutCoverTableHorizontal {
    public testCols = [
        {
            colClass: 't270',
            colHeadName: 'name',
            colHeadValue: 'Name',
            colWidth: 270,
            sort: true,
        },
        {
            colHeadName: 'ship',
            colHeadValue: 'Ship',
            colWidth: 1700,
            sort: true,
        },
        {
            colClass: 't85',
            colHeadName: 'gender',
            colHeadValue: 'Gender',
            colWidth: 50,
        },
    ];

    public testData = [
        {
            _barColor: 'var(--c_secondaryMain)',
            _status: 'bar',
            gender: 'Male',
            name: 'Luke Skywalker',
            ship: 'X-Wing',
        },
        {
            _barColor: 'var(--c_subTwoMain)',
            _class: 'active',
            _status: 'bar',
            gender: 'Male',
            name: 'Han Solo',
            ship: 'M. Falcon',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
        {
            _barColor: 'var(--c_primaryLight)',
            _status: 'bar',
            gender: 'Male',
            name: 'Fin',
            ship: 'Tie Fighter',
        },
    ];

    public tableActionsSample = {
        getColClass: (row, col) => {
            let cls = col._class || '';
            /* istanbul ignore else */
            if (col.colHeadName === 'name') {
                cls += ` ${row._status}`;
            }
            return cls;
        },

        getBarColor: row => {
            const color = row._barColor || '';
            return color;
        },
    };
}
