/* eslint-disable max-classes-per-file */
import {AureliaCookie} from 'aurelia-cookie';
import {Router} from 'aurelia-router';
import {bindable, BindingEngine, inject, containerless} from 'aurelia-framework';

import sortArrayOfObjectsByKey from 'resources/sorting';

@inject(Element, BindingEngine, Router)
@containerless
/**
 * Component for wide tables with a lot of columns. Also supports toggling columns
 * on/off. Table recalculates width on resize and when column selection is adjusted.
 *
 * @param columns {Array} - List of column objects that contain properties for
 *                          configuring each column.
 * Example Column Object:
 *      {
 *          name: 'Title',
 *          key: 'desc',
 *          width: 150,
 *          colClass: 't-215',
 *          default: '-',
 *          isSelected: true,
 *          getValue: function(item) {
 *              return item[this.key] || this.default;
 *          }
 *      }
 * @param data {Array} - List of table record data objects.
 */
export class DataTable {
  @bindable
  columns;

  @bindable
  data;

  @bindable
  selectedRecords = [];

  @bindable
  actions;

  @bindable
  sortKey;

  @bindable
  sortCookie;

  @bindable
  selectable = false;

  // Adds checkbox to each row.
  @bindable
  draggable = false;

  // Adds Drag icon & checkbox to each row.
  @bindable
  hoverable = true;

  // Make rows change bg color on hover.
  @bindable
  scrollToLoad = true;

  // Enables scroll observer to fire `actions.onScrollBottom`.
  @bindable
  rowClass;

  @bindable
  tdClassSignal = 'data-table--td-class';

  // used to trigger getTdClass()
  @bindable
  trClassSignal = 'data-table--tr-class'; // used to trigger rowClass()

  constructor(element, bindingEngine, router) {
    this.element = element;
    this.bindingEngine = bindingEngine;
    this.router = router;

    this.tableWidth = 'auto';
    this.tableHasColumns = true;
    this.allAreSelected = false;
    // this.MOBILE_MEDIA = 450;
  }

  bind() {
    this.setColumnObservables();
    this.setSelectionObservable();
  }

  attached() {
    this.observeColumns();
    this.resizeTable();
    $(window).resize($.proxy(this.resizeTable, this));
  }

  unbind() {
    this.observers.forEach(obs => {
      obs.dispose();
    });
  }

  detached() {
    this.columnsSubscription.dispose();
    $(window).off('resize', this.resizeTable);
  }

  observeColumns() {
    if (this.columnsSubscription) {
      this.columnsSubscription.dispose();
    }
    this.columnsSubscription = this.bindingEngine.collectionObserver(this.columns).subscribe(() => {
      this.columnsModified();
    });
  }

  columnsModified() {
    this.setColumnObservables();
  }

  checkLoadMore() {
    const $container = $(this.element.ownerDocument).find('.fixed-table-header__inner');
    const $el = $container.find('table tr:last');

    if (!$el) {
      return false;
    }

    const $win = $(window);
    const viewport = {
      top: $win.scrollTop(),
      left: $win.scrollLeft(),
    };
    viewport.right = viewport.left + $win.width();
    viewport.bottom = viewport.top + $win.height();

    const bounds = $el.offset();

    if (!bounds) {
      return false;
    }

    bounds.right = bounds.left + $el.outerWidth();
    bounds.bottom = bounds.top + $el.outerHeight();

    return !(
      viewport.right < bounds.left ||
      viewport.left > bounds.right ||
      viewport.bottom < bounds.top ||
      viewport.top > bounds.bottom
    );
  }

  onScroll() {
    if (!this.scrollToLoad) {
      return;
    }

    if (this.checkLoadMore()) {
      if (this.actions && this.actions.onScrollBottom) {
        this.actions.onScrollBottom();
      }
    }
  }

  /**
   * Callback function that fires anytime `col.isVisible` is changes.
   *
   * @param n {Boolean} - New value.
   * @param o {Boolean} - Old value.
   */
  columnChanged() {
    this.resizeTable();
  }

  dataChanged() {
    this.allAreSelected = this.selectedRecords.length > 0 && this.selectedRecords.length === this.data.length;

    if (this.sortCookie != null) {
      const sortKey = AureliaCookie.get(this.sortCookie);
      if (sortKey) {
        this.sortKey = sortKey;
      }
    }
    this.frontEndSort();
  }

  selectedRecordsChanged() {
    // Update `allAreSelected`.
    this.allAreSelected = this.selectedRecords.length === this.data.length;

    // Call `onSelectionChange` action.
    if (this.actions && this.actions.onSelectionChange) {
      this.actions.onSelectionChange(this.selectedRecords);
    }
  }

  /**
   * Iterates `columns` and sets propertyObserver for each
   */
  setColumnObservables() {
    // Observe selection.
    const observers = [];

    this.columns.forEach(col => {
      observers.push(
        this.bindingEngine.propertyObserver(col, 'isVisible').subscribe((newValue, oldValue) => {
          this.columnChanged(newValue, oldValue);
        }),
      );
    });

    this.observers = observers;
  }

  setSelectionObservable() {
    const subscription = this.bindingEngine.collectionObserver(this.selectedRecords).subscribe(splices => {
      this.selectedRecordsChanged(splices);
    });

    this.observers.push(subscription);
  }

  /**
   * Resizes the table if the sum of col.width is greater than the container width.
   * Also resizes back to auto if the sum of col.width is less than container width.
   *
   * @return {String} the appropriate css value for the width of the table (px | auto).
   */
  resizeTable() {
    const width = this.getWidth();
    const containerW = $('#data-table-width-calc').width();

    if (width > containerW) {
      this.tableWidth = `${width}px`;
    } else {
      this.tableWidth = 'auto';
    }

    if (this.scrollToLoad) {
      setTimeout(() => {
        if (this.checkLoadMore()) {
          if (this.actions && this.actions.onScrollBottom) {
            this.actions.onScrollBottom();
          }
        }
      }, 300);
    }
  }

  /**
   * Calculates the sum of the widths of selected columns (provided on the column object).
   *
   * @return {Int} sum of selected column widths.
   */
  getWidth() {
    let width = 0;

    this.columns.forEach(col => {
      if (col.width && col.isVisible) {
        width += col.width;
      }
    });

    // Determine if there are any columns turned on.
    if (width > 0) {
      this.tableHasColumns = true;
    } else {
      this.tableHasColumns = false;
    }

    return width;
  }

  frontEndSort() {
    if ((!this.actions || !this.actions.onSortTable) && this.data != null) {
      let key = this.sortKey;
      let reverse = false;
      if (!key) {
        return;
      }
      if (key[0] === '-') {
        key = key.substr(1);
        reverse = true;
      }
      this.data.sort(sortArrayOfObjectsByKey(key, reverse));
      this.columns.forEach((col, i) => {
        if (this.sortKey === col.key) {
          this.columns[i].sort = 'table--sort__asc';
        } else if (this.sortKey === `-${col.key}`) {
          this.columns[i].sort = 'table--sort__desc';
        } else {
          this.columns[i].sort = '';
        }
      });
    }
  }

  /**
   * Trigger `onSortTable` action.
   *
   * @param col {Object} - The column object whose sort was clicked
   * @param index {Number} - The column index for the column header that was clicked.
   */
  sortTable(col, index) {
    if (this.actions && this.actions.onSortTable) {
      this.actions.onSortTable(col, index);
    } else {
      // default to using a front-end sort
      if (col.key === this.sortKey) {
        this.sortKey = `-${col.key}`;
      } else {
        this.sortKey = col.key;
      }

      if (this.sortCookie != null) {
        const tenYears = 10 * 365 * 24;
        AureliaCookie.set(this.sortCookie, this.sortKey, {
          path: '/',
          expiry: tenYears,
        });
      }

      this.frontEndSort();
    }
  }

  /**
   * Trigger `onRowClick` action.
   *
   * @param record {Object} - Record of the row that was clicked.
   */
  recordClick(record) {
    if (this.actions && this.actions.onRecordClick) {
      this.actions.onRecordClick(record);
    }
  }

  toggleCheckbox(record) {
    if (!record) {
      return false;
    }

    const idx = this.data.findIndex(r => r.id === record.id);

    if (idx !== -1) {
      this.data[idx].isSelected = !record.isSelected;
    }

    return true;
  }

  toggleSelectAll() {
    const allAreSelected = this.selectedRecords.length === this.data.length;
    this.selectedRecords.splice(0, this.selectedRecords.length);

    if (!allAreSelected) {
      // Select all.
      this.data.forEach(r => {
        if (r.isSelectable) {
          this.selectedRecords.push(r.id);
        }
      });
    }
  }
}

/**
 * Constructor for columns with some basic defaults
 *
 * @param label {String} - Column Header label
 * @param key {String} - data key, if not defined it will derive from the label
 * @param width {Number} - default is 150
 * @param colClass {String} - Talk to Luke about this
 * @param dafaultValue {String} - Default value to put in cell if no value defined, defualt is '-'
 * @param isVisivle {Boolean} - Is column visible
 * @param isSortable {Boolean} - Is column sortable
 * @param getValue {Function} - Function used to get the value to put in the table cell
 *                              default is to use the data[key] or defaultValue
 * @param getClass {Function} - Function to set class on column record value
 * @param getTdClass {Function} - Function to set class on column record's <td> element
 * @param valueDict {Object} - A dictionary used to convert item key values to user friendly values
 *                           - will override getValue
 */
export class Column {
  constructor(
    label,
    key,
    {
      width = 150,
      colClass = '',
      defaultValue = '-',
      isVisible = true,
      isSortable = true,
      getValue = undefined,
      getClass = undefined,
      getTdClass = undefined,
      view = undefined,
      valueDict = undefined,
      viewModel = undefined,
      truncate = false,
      noWrap = false,
    } = {},
  ) {
    this.label = label;
    if (key !== undefined) {
      this.key = key;
    } else {
      this.key = this.titleToKey();
    }
    this.width = width;
    this.colClass = colClass;
    this.defaultValue = defaultValue;
    this.isVisible = isVisible;
    this.isSortable = isSortable;
    if (getValue !== undefined) {
      this.getValue = getValue;
    } else {
      this.getValue = item => item[this.key] || this.defaultValue;
    }
    if (valueDict !== undefined) {
      this.getValue = item => valueDict[item[this.key]];
    }
    if (getClass !== undefined) {
      this.getClass = getClass;
    } else {
      this.getClass = () => '';
    }
    if (getTdClass !== undefined) {
      this.getTdClass = getTdClass;
    } else {
      this.getTdClass = () => '';
    }
    this.view = view;
    this.viewModel = viewModel;
    this.truncate = truncate;
    this.noWrap = noWrap;
    this.sort = '';
  }

  titleToKey() {
    return this.label
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '_') // Replace spaces with _
      .replace(/[^\w_]+/g, '') // Remove all non-word chars
      .replace(/__+/g, '_') // Replace multiple _ with single _
      .replace(/^_+/, '') // Trim _ from start of text
      .replace(/_+$/, ''); // Trim _ from end of text
  }
}
