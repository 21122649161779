import {ConfigTable} from 'resources/config/table';
import {ConfigInput as ConfigInputBase} from 'resources/config/import/models/config-input';
import {ConfigFormat} from 'resources/config/models/config-format';
import {AdServerType} from '../../ad-server/models/ad-config';

export class MacroInput {
  // is a single Macro param - key/value
  public behavior: string = 'pt';
  // above is like keyPrefix - and later - think about using 'pt.' to avoid having to append the '.' after it
  // also evalute refactor of this attribute 'behavior' vs. 'keyPrefix'
  public key: string = '';
  public id?: string;
  public type: string = '';
  public value: boolean | number | string = '';
}

export class ConfigInput extends ConfigInputBase {
  // is a single Ad Server or Uplynk param - key/value
  public warnings = [];
  public errors = [];
  public keyPrefix = '';
  public paramType: string = '';
  // above is set based on type of param - operational (uplynk) or ad server (pass through)
  public configSetting = {id: '', key: '', value: ''};
  public exportFormat: ConfigFormat = ConfigFormat.JSON;
  public macro = new MacroInput();
  public name: string = '';
  public provider: string = '';
  public fields = {};
  public table: ConfigTable;
  public type: AdServerType;
  public typeLabel: string = '';
}
