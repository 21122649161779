// eslint-disable-next-line max-classes-per-file
import {Type, Transform, Exclude} from 'class-transformer';
import {IsBoolean, IsNumber, IsString, ValidateNested, IsOptional, IsArray} from 'class-validator';

export const SIZE_OPTIONS = [
  {val: '320x240', title: 'Half SD (320x240)'},
  {val: '640x480', title: 'SD (640x480)'},
  {val: '640x360', title: 'Half HD (640x360)'},
  {val: '1280x720', title: 'HD (1280x720)'},
  {val: '1920x1080', title: 'Full HD (1920x1080)'},
];

export class TemplateObject {
  @IsOptional()
  @IsNumber()
  @Transform((value, _obj, type) => (type === 1 && Number(value) === 0 ? undefined : Number(value)))
  public autoexpire?: number = 0;

  @IsOptional()
  @IsString()
  @Transform((value, _obj, type) => (type === 1 && value.trim().length <= 0 ? undefined : value))
  public force_aspect_ratio?: string = '';

  @IsOptional()
  @IsArray()
  @Transform((value, _obj, type) => (type === 1 && value.length <= 0 ? undefined : value))
  public libraries?: string[] = [];

  @IsOptional()
  @IsString()
  @Transform((value, _obj, type) => (type === 1 && value.trim().length <= 0 ? undefined : value))
  public mix_atracks?: string = '';

  @IsOptional()
  @IsNumber()
  @Transform((value, obj, type) => {
    if (type === 1) {
      if (obj.poster_size.trim().length > 0) {
        return Number(value);
      }
      return undefined;
    }

    return Number(value);
  })
  public poster?: number = 0;

  @IsOptional()
  @IsString()
  @Transform((value, _obj, type) => (type === 1 && value.trim().length <= 0 ? undefined : value))
  public poster_size?: string = '';

  @IsOptional()
  @IsNumber()
  @Transform((value, _obj, type) => (type === 1 && Number(value) === 0 ? undefined : Number(value)))
  public auto_replace?: number = 0;

  @IsOptional()
  @IsNumber()
  @Transform((value, _obj, type) => (type === 1 && Number(value) === 0 ? undefined : Number(value)))
  public skip_drm?: number = 0;
}

export class Template {
  @IsString()
  public id?: string;

  @IsString()
  public name: string = '';

  @IsString()
  public desc?: string = '';

  @IsBoolean()
  public is_default: boolean = false;

  @ValidateNested()
  @Type(() => TemplateObject)
  public template: TemplateObject = new TemplateObject();

  @Exclude()
  @IsBoolean()
  public selected?: boolean = false;
}

export class TemplatesListResponse {
  @ValidateNested()
  @Type(() => Template)
  public items?: Template[];

  @IsNumber()
  public total_items: number;
}

export class KeyValue {
  @IsString()
  key: string = '';

  @IsString()
  value: string = '';
}

export class OffsetKeyValue extends KeyValue {
  @IsString()
  offset: string = '';
}

export class TemplateOverride {
  @IsOptional()
  @IsString()
  @Transform((value, _obj, type) => (type === 1 && value.length === 0 ? undefined : value))
  public external_id?: string = '';

  @IsOptional()
  @IsArray()
  @Transform((value, _obj, type) => {
    if (type === 1) {
      const val = value.filter(x => x.trim().length > 0);
      return val.length ? val : undefined;
    }

    return value;
  })
  public breaks?: string[] = [''];

  @IsOptional()
  @ValidateNested()
  @Type(() => KeyValue)
  @Transform((value, _obj, type) => {
    if (type === 1) {
      let val = value.filter(x => x.key.trim().length > 0 && x.value.trim().length > 0);
      if (val.length) {
        val = val.map(x => `${x.key.trim()}=${x.value.trim()}`);
        return val.length ? val.join(',,,') : undefined;
      }

      return undefined;
    }

    return value;
  })
  public meta?: KeyValue[] = [new KeyValue()];

  @IsOptional()
  @ValidateNested()
  @Type(() => OffsetKeyValue)
  @Transform((value, _obj, type) => {
    if (type === 1) {
      let val = value.filter(x => x.key.trim().length > 0 && x.value.trim().length > 0 && x.offset.trim().length > 0);
      if (val.length) {
        val = val.map(x => `${x.offset.trim()}:${x.key.trim()}=${x.value.trim()}`);
        return val.length ? val.join(',') : undefined;
      }

      return undefined;
    }

    return value;
  })
  public timedmeta?: OffsetKeyValue[] = [new OffsetKeyValue()];

  public get isEmpty() {
    return JSON.stringify(this) === JSON.stringify(new TemplateOverride());
  }
}
