import {Type} from 'class-transformer';
import {IsString, ValidateNested} from 'class-validator';

export class PluginResponseItem {
  @IsString()
  public creation_time: string;

  @IsString()
  public slicer_plugin: string;

  @IsString()
  public slicer_plugin_version: string;

  @IsString()
  public status: string;
}

export class PluginsResponseItems {
  @ValidateNested()
  @Type(() => PluginResponseItem)
  public items: PluginResponseItem[];
}
