import {IAdServerDebugTableRow} from './table-models';
import {objectToStringTree, Status, Transaction, TransactionsTreeItem} from '../models/ad-server-debug-response';

export class AdServerDebugTableRowFormatter {
  static transactionMeta: {[id: string]: {failed: number; spawned: number; ends: number}} = {};

  public static format(row: IAdServerDebugTableRow) {
    this.transactionMeta = {};
    // format the row using the tree object - if there are transactions
    // they will be further enhanced in setTransactions

    if (!_.isEmpty(row.transactionsTreeItems)) {
      this.setTransactionsTreeItems(row);
    }

    if (!_.isEmpty(row.transactions)) {
      this.setWarningsFlag(row);
      this.setTransactions(row);
    }
    this.setCallbacks(row);

    this.setExportability(row);

    return row;
  }

  private static flattenTransactionsTree(
    tree: TransactionsTreeItem[],
    parentLevel: number = 0,
    parentId: string = null,
  ): [Transaction[], number, number, number] {
    // loop over the hierarchy of transactions returning a list of transactions
    //   each child will be listed after its parent to the end of its wrapper chain

    const flattenedList: Transaction[] = [];
    let errorCounts: number = 0;
    let wrapperEndCounts = 0;
    let spawnedCounts = 0;

    if (tree.length > 0) {
      tree.forEach(treeBranch => {
        const {transaction} = treeBranch;
        const row: Transaction = transaction;
        row.level = parentLevel;
        row.parentId = parentId;
        row.numChildren = 0;
        row.numChildrenFailed = 0;
        if (transaction.status === Status.Fail && transaction.parentId) {
          errorCounts += 1;
        }
        spawnedCounts += 1;

        if (treeBranch.children) {
          row.numChildren = treeBranch.children.length;
        }

        flattenedList.push(row);

        if (row.numChildren > 0) {
          const [
            childrenList,
            childrenErrors,
            spawned,
            wrapperEnds,
          ] = this.flattenTransactionsTree(treeBranch.children, parentLevel + 1, transaction.id);
          flattenedList.push(...childrenList);

          if (!this.transactionMeta[row.id]) {
            this.transactionMeta[row.id] = {failed: 0, spawned: 0, ends: 0};
          }
          this.transactionMeta[row.id].spawned += spawned;
          this.transactionMeta[row.id].failed = childrenErrors;
          this.transactionMeta[row.id].ends += wrapperEnds;
          errorCounts += childrenErrors;
          wrapperEndCounts += wrapperEnds;
          spawnedCounts += spawned;
        } else {
          wrapperEndCounts += 1;
          // spawnedCounts += 1; // this may double up on ends...
        }
      });
    }

    return [
      flattenedList,
      errorCounts,
      spawnedCounts,
      wrapperEndCounts,
    ];
  }

  private static setTransactions(row: IAdServerDebugTableRow) {
    row.transactions.forEach(transaction => {
      if (transaction.request_headers && transaction.request_headers !== '') {
        transaction.request_headers = JSON.stringify(transaction.request_headers);
      }

      transaction.warnings = objectToStringTree(transaction.warnings);

      if (transaction.level === 1 && transaction.typeField === 'Wrapper' && transaction.is_wrapper) {
        transaction.typeField = 'Primary Wrapper';
      }
    });
  }

  private static setTransactionsTreeItems(row: IAdServerDebugTableRow) {
    const [
      flattenedList,
      errorCounts,
      spawnedCounts,
      wrapperEnds,
    ] = this.flattenTransactionsTree(row.transactionsTreeItems);

    row.transactions = flattenedList.map(transaction => ({
      ...transaction,
      numChildrenFailed:
        this.transactionMeta[transaction.id] && errorCounts ? this.transactionMeta[transaction.id].failed || 0 : 0,
      numTransactionsSpawned:
        this.transactionMeta[transaction.id] && spawnedCounts ? this.transactionMeta[transaction.id].spawned || 0 : 0,
      numWrapperEnds:
        this.transactionMeta[transaction.id] && wrapperEnds ? this.transactionMeta[transaction.id].ends || 0 : 0,
    }));
  }

  private static setCallbacks(row: IAdServerDebugTableRow) {
    // Parse the callback id to obtain the break and ad indices
    // TODO - move the logic here to services and deprecate

    let callbackIdList: string[] = [];
    let hasAdIndex: boolean = false;
    let hasBreakIndex: boolean = false;

    row.callbacks.forEach(callback => {
      callbackIdList = callback.id.split('-');
      hasBreakIndex = callbackIdList.length >= 6;
      hasAdIndex = callbackIdList.length >= 7;

      if (hasBreakIndex && callbackIdList[5] !== '' && !Number.isNaN(+callbackIdList[5])) {
        callback.breakIndex = +callbackIdList[5];
      }
      if (hasAdIndex && callbackIdList[6] !== '' && !Number.isNaN(+callbackIdList[6])) {
        callback.adIndex = +callbackIdList[6];
      }
    });
  }

  private static setExportability(row: IAdServerDebugTableRow) {
    // no need to check ads since you can only have a selected ad if you have a transaction
    row.canExport = row.transactions.length > 0 || row.callbacks.length > 0;
  }

  private static setWarningsFlag(row: IAdServerDebugTableRow) {
    row.warningsCount = 0;
    row.hasWarnings = false;

    row.transactions.forEach(t => {
      if (t.warnings && Object.keys(t.warnings).length > 0) {
        row.warningsCount += 1;
        row.hasWarnings = true;
      }
    });
  }
}
