import {ConfigInfo as ConfigInfoBase} from '../../models/config-info';

export class ConfigInfoImport {
  public fileValue = 'Select a file from your computer to import configuration settings from';

  public mode =
    // eslint-disable-next-line max-len
    'Select where the imported configuration settings will be placed, relative to the configuration settings you already defined';

  public source = 'Select where the configuration settings will be imported from';

  public template = 'Select an existing Template to import configuration settings from';
}

export class ConfigInfo extends ConfigInfoBase {
  public import = new ConfigInfoImport();
}
