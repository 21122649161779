import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

import {SharedNav} from '@bindable-ui/bindable';
import {TableSortingTools} from 'utils/table-sorting-tools';

import {ALT_CONTENT_TYPE, ALT_CONTENT_TYPE_VALUES} from '../models/models';
import {RuleService} from '../services/rules';

@autoinject()
export class RulesList {
  public hiddenColumns = [];
  public colModified;
  public currentSort: string = '';

  public readonly createRuleOptions = ALT_CONTENT_TYPE_VALUES.map(opt => ({text: _.capitalize(opt), value: opt}));

  public availableCols: any[] = [
    {
      colHeadName: 'alternate_content_type',
      colHeadValue: 'Alt. Content Type',
      selected: true,
    },
    {
      colHeadName: 'alternate_content_id',
      colHeadValue: 'Alt. Content ID',
      selected: true,
    },
    {
      colHeadName: 'alternate_content_loop',
      colHeadValue: 'Loop',
      selected: true,
    },
    {
      colHeadName: 'external_id',
      colHeadValue: 'External ID',
      selected: true,
    },
    {
      colHeadName: 'audience',
      colHeadValue: 'Audience',
      selected: true,
    },
    {
      colHeadName: 'id',
      colHeadValue: 'Rule GUID',
      selected: true,
    },
    {
      colHeadName: 'created',
      colHeadValue: 'Created On',
      selected: true,
    },
  ];

  public createRuleActions = {
    onHide: () => {
      if (this.ruleService.createRuleForm) {
        this.ruleService.createRuleForm.reset();
      }
      this.ruleService.createErrMsg = '';
      this.ruleService.newRuleName = '';
      this.ruleService.newRuleType = ALT_CONTENT_TYPE.SLATE;
      this.ruleService.createErrMsgContent = '';
      this.ruleService.newAltContentId = '';
    },
  };

  constructor(
    private ruleService: RuleService,
    private sharedNav: SharedNav,
    private dialogService: DialogService,
    private router: Router,
  ) {}

  public async activate() {
    if (this.ruleService.params && this.ruleService.params.order) {
      this.currentSort = this.ruleService.params.order;
    }

    this.init();
    this.ruleService.clearSelected();
  }

  public async init() {
    await Promise.all([
      this.ruleService.getRules({order: this.currentSort}),
      this.getMetadata(),
    ]);
  }

  public attached() {
    this.sharedNav.setActive(0, 1);

    if (this.ruleService.ruleTypeInput) {
      this.ruleService.ruleTypeInput.defaultValue = ALT_CONTENT_TYPE.SLATE;
    }

    if (this.ruleService.createRuleDialog) {
      this.ruleService.createRuleDialog.addEventListener('on:hide', event => {
        if (event.target === event.currentTarget) {
          this.createRuleActions.onHide();
        }
      });
    }
  }

  public detached() {
    this.ruleService.clearSelected();
  }

  public importRulesModal() {
    this.dialogService.open({
      model: {
        bodyModel: {
          stuff: 'here',
        },
        bodyViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/modals/import'),
        footerEnable: true,
        footerModel: {
          stuff: 'here',
        },
        footerText: 'footer',
        footerViewModel: PLATFORM.moduleName('apps/cms/routes/live-channels/modals/import-footer'),
        size: 'medium',
        title: 'Import Rules',
      },
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
    });
  }

  public async getMetadata() {
    await this.ruleService.getMetadata();
    if (this.ruleService.currentMetadata) {
      this.hiddenColumns = this.ruleService.currentMetadata.hiddenColumns;
      this.hiddenColumns.forEach(key => {
        this.availableCols.forEach((column, index) => {
          if (column.colHeadName === key) {
            this.availableCols[index].selected = false;
          }
        });
      });
    }
  }

  public showHideColumn(col) {
    col.selected = !col.selected;

    if (col.selected) {
      const idx = this.hiddenColumns.indexOf(col.colHeadName);
      if (idx !== -1) {
        this.hiddenColumns.splice(idx, 1);
      }
    } else {
      this.hiddenColumns.push(col.colHeadName);
    }

    this.ruleService.currentMetadata.hiddenColumns = this.hiddenColumns;
    this.ruleService.saveMetadata(this.ruleService.currentMetadata);
    this.colModified = new Date();
  }

  public async sortRules(key: string, defaultSortDirection: string = null) {
    const {order} = this.ruleService.params;

    const newOrder = TableSortingTools.sort(key, order, defaultSortDirection);

    this.currentSort = newOrder;
    this.ruleService.getRules({order: newOrder});
  }

  public goToSingle(id) {
    this.router.navigate(`/live-channels/rules/${id}`);
  }

  public toggleRuleSelected(rule) {
    rule.selected = !rule.selected;
    this.ruleService.trackSelectedRule(rule);
  }

  @computedFrom('ruleService.selectedRules.length', 'ruleService.isDeleting')
  get deleteBtnState() {
    if (this.ruleService && this.ruleService.isDeleting) {
      return 'thinking';
    }
    if (this.ruleService && this.ruleService.selectedRules.length) {
      return '';
    }

    return 'disabled';
  }

  @computedFrom('currentSort')
  public get sortedName(): number {
    return TableSortingTools.sorted('desc', this.currentSort);
  }

  @computedFrom('currentSort')
  public get sortedExid(): number {
    return TableSortingTools.sorted('external_id', this.currentSort);
  }

  @computedFrom('currentSort')
  public get sortedCreated(): number {
    return TableSortingTools.sorted('created', this.currentSort);
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showAltContentTypeCol(): boolean {
    return !this.hiddenColumns.includes('alternate_content_type');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showAltContentIdCol(): boolean {
    return !this.hiddenColumns.includes('alternate_content_id');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showLoopCol(): boolean {
    return !this.hiddenColumns.includes('alternate_content_loop');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showExidCol(): boolean {
    return !this.hiddenColumns.includes('external_id');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showAudienceCol(): boolean {
    return !this.hiddenColumns.includes('audience');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showIdCol(): boolean {
    return !this.hiddenColumns.includes('id');
  }

  @computedFrom('hiddenColumns', 'colModified')
  public get showCreatedCol(): boolean {
    return !this.hiddenColumns.includes('created');
  }
}
