import {autoinject, LogManager, singleton} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {Library, LibraryAsset, LibraryCollection} from './models/library';

const log = LogManager.getLogger('library-service');

@autoinject()
@singleton()
export class LibraryService {
    constructor(public acceo: Acceo) {}

    public async getLibraries(includeShared: boolean = true): Promise<Library[]> {
        try {
            const resp: LibraryCollection = await this.acceo.post(LibraryCollection)('/libraries/list/', undefined);

            const outgoing = _.get(resp, 'outgoing', []);
            const incoming = _.get(resp, 'incoming', []);

            let libraries: Library[];
            if (includeShared) {
                libraries = _.union(incoming, outgoing);
            } else {
                libraries = outgoing;
            }

            // Remove the "All Content" Library
            libraries = libraries.filter(lib => lib.id !== '');

            return libraries;
        } catch (err) {
            log.error(`Error in getLibraries: ${err}`);
            return [];
        }
    }

    public getLibraryOptions(includeAllContent = true): Promise<object[]> {
        return new Promise((resolve, reject) => {
            this.acceo
                .post(LibraryCollection)('/libraries/list/', undefined)
                .then((resp: LibraryCollection) => {
                    const outgoing: any = _.get(resp, 'outgoing', []);
                    const incoming: any = _.get(resp, 'incoming', []);
                    const libraries: object[] = [];
                    outgoing.forEach(lib => {
                        libraries.push({value: lib.id, text: lib.desc});
                    });
                    incoming.forEach(lib => {
                        if (lib.id !== '') {
                            libraries.push({value: lib.id, text: `${lib.desc} (${lib.owner_name})`});
                        }
                    });
                    if (includeAllContent) {
                        libraries.unshift({value: '', text: 'All Content'});
                    }
                    resolve(libraries);
                })
                .catch(reject);
        });
    }

    public async getLibraryAssets(libraryId, maxDuration = 10): Promise<LibraryAsset[] | undefined> {
        try {
            let queryString = `sort_col=created&sort_dir=desc&type=asset&library=${libraryId}`;
            if (maxDuration != null) {
                queryString += `&maxDur=${maxDuration}`;
            }
            const assets: LibraryAsset[] = await this.acceo.post([LibraryAsset])('/assets/find/', queryString, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
                },
                prefix: 'assets',
            });
            return assets;
        } catch (err) {
            log.error(`getLibraryAssets error: ${err}`);
            return [];
        }
    }
}
