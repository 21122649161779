import {autoinject, LogManager} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import {Acceo} from 'services/acceo';
import {CToastsService} from '@bindable-ui/bindable';
import {
  JobVOD,
  JobsVODListResponse,
  IHyperionFilterParams,
  IHyperionMeta,
  VodUploaderFile,
  VODUploaderPreURL,
  VODUploaderPreURLList,
} from '../models/models';
import {Template, TemplatesListResponse} from '../../settings/vod-uploader-templates/models/templates-model';
import {DEFAULT_PER_PAGE_LIMIT, VOD_UPLOADER_FILE_PART_SIZE} from '../models/defaults';
import {VOD_TEMPLEATES_URL} from '../../settings/vod-uploader-templates/services/templates-service';

export const VOD_UPLOADER_URL: string = '/api/v4/vod_uploader/jobs';

const log = LogManager.getLogger('content-service');

@autoinject()
export class VodJobService {
  public jobs: JobVOD[] = [];
  public selectedFiles: VodUploaderFile[] = [];
  public isClearingFinishedJobs: boolean = false;
  public isLoading: boolean = false;
  public isDeleting: boolean = false;
  public isUploading: boolean = false;
  public meta: IHyperionMeta = {page: 1, limit: DEFAULT_PER_PAGE_LIMIT};

  constructor(private acceo: Acceo, private notification: CToastsService) {}

  public async getJobs(params: IHyperionFilterParams, firstload: boolean = false) {
    if (!params.page_size) {
      params.page_size = this.meta.limit;
    }

    params.page = this.meta.page;

    const queryString = $.param(params);

    try {
      if (firstload) {
        this.isLoading = true;
      }
      const url = `${VOD_UPLOADER_URL}`; // ?top=10&skip=1`;
      const res = await this.acceo.get(JobsVODListResponse)(
        [
          url,
          queryString,
        ].join('?'),
      );

      if (firstload) {
        this.isLoading = false;
      }

      return res;
    } catch (err) {
      this.notification.error('Failed to get Jobs');
      throw new Error(err);
    }
  }

  private updateJobData(job: JobVOD, isPolling: Boolean = false) {
    const {vod_checksum} = job;
    const {jobs} = this;

    const index = jobs.findIndex(_job => _job.vod_checksum === vod_checksum);

    if (index > -1) {
      job.selected = jobs[index].selected;
      jobs[index] = job;
    } else if (!isPolling) {
      jobs.unshift(job);
    }
  }

  public processJobs(changes, isPolling: Boolean = false) {
    let hasChanges = false;

    if (!isPolling) {
      this.meta.total = changes.total_items;
    }

    if (changes && changes.items && changes.items.length > 0) {
      hasChanges = true;

      changes.items.forEach(item => this.updateJobData(item, isPolling));
      this.jobs = _.uniqBy(this.jobs.concat(), 'vod_checksum');
    }

    this.jobs = _.sortBy(this.jobs, [
      'created',
      'title',
    ]).reverse();

    return hasChanges;
  }

  public async uploadVODUrl(data: any) {
    try {
      const url = `${VOD_UPLOADER_URL}`;
      const res = await this.acceo.post(JobsVODListResponse)(url, data);
      const itemsCount = res && res.items ? res.items.length : 0;
      const duplicateCount = res && res.duplicates ? res.duplicates.length : 0;

      if (duplicateCount) {
        this.notification.warning(
          `${duplicateCount} Duplicate Files Skipped, ${itemsCount} files uploaded`,
          'Duplicate Files',
        );
      }

      this.reloadJobs();

      return res;
    } catch (err) {
      this.notification.error(err.message, 'Upload failed');
      log.error(err);
      throw new Error(err);
    }
  }

  public async deleteJob(job: JobVOD) {
    try {
      job.isDeleting = true;
      const url = `${VOD_UPLOADER_URL}/${job.id}`;
      const res = await this.acceo.delete()(url);
      await this.reloadJobs();
      job.isDeleting = false;
      return res;
    } catch (err) {
      job.isDeleting = false;
      this.notification.error(err.message, 'Delete Job failed');
      log.error(err);
      throw new Error(err);
    }
  }

  public async deleteClearableJobs() {
    this.isClearingFinishedJobs = true;

    try {
      const url = `${VOD_UPLOADER_URL}`;
      const res = await this.acceo.delete()(url);

      await this.reloadJobs();
      this.isClearingFinishedJobs = false;
      return res;
    } catch (err) {
      this.isClearingFinishedJobs = false;

      this.notification.error(err.message, 'Clear all Jobs failed');
      log.error(err);
      throw new Error(err);
    }
  }

  public async cancelJob(job: JobVOD) {
    try {
      job.isCanceling = true;
      const url = `${VOD_UPLOADER_URL}/${job.id}`;
      const res = await this.acceo.patch(JobVOD)(url, {action: 'cancel'});
      this.processJobs({items: [res]}, true);
      job.isCanceling = false;
      return res;
    } catch (err) {
      job.isCanceling = false;
      this.notification.error(err.message, 'Cancel failed');
      throw new Error(err);
    }
  }

  public async abortMultiPartFile(file: VodUploaderFile) {
    try {
      const url = `${VOD_UPLOADER_URL}/${file.upload_id}`;
      const res = await this.acceo.patch()(url, {action: 'abort_upload', file_name: file.name});
      return res;
    } catch (err) {
      this.notification.error(err.message, 'Abort job failed');
      throw new Error(err);
    }
  }

  public async reloadJobs() {
    const jobs = await this.getJobs({});
    this.jobs = [];
    this.processJobs(jobs);
  }

  public static splitFile(file: VodUploaderFile) {
    // Calculate the number of parts
    const partSize = VOD_UPLOADER_FILE_PART_SIZE;
    const numParts = Math.ceil(file.file.size / partSize);

    // Split the file into parts
    const parts: VodUploaderFile[] = [];
    let startPosition = 0;
    for (let partNumber = 1; partNumber <= numParts; partNumber++) {
      const endPosition = Math.min(startPosition + partSize, file.file.size);
      const partBlob = file.file.slice(startPosition, endPosition, file.file.type);
      const partFile = new File([partBlob], `${file.name}.part${partNumber}`);
      parts.push(new VodUploaderFile(partFile));
      startPosition += partSize;
    }

    return parts;
  }

  public async getPresignedPost(files: VodUploaderFile[]) {
    try {
      const url = `${VOD_UPLOADER_URL}`;
      const fileNames = files.map(x => x.name);
      const uploadFiles = files.map(file => {
        file.fileParts = VodJobService.splitFile(file);
        return {name: file.name, num_parts: file.fileParts.length, type: file.file.type};
      });
      const filesData = {
        call_params: {
          file_names: fileNames,
          upload_files: uploadFiles,
        },
      };
      return await this.acceo.put(VODUploaderPreURLList)(url, filesData);
    } catch (err) {
      log.error(err);
      throw new Error(`Failed to get Presigned Urls: ${err}`);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public parseXmlError(xml: string) {
    if (xml === undefined) {
      return '';
    }
    const parser = new window.DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/xml');
    const msgElm = xmlDoc.getElementsByTagName('Message')[0];

    return (msgElm && msgElm.childNodes[0].nodeValue) || '';
  }

  public async uploadS3file(presignedPost: VODUploaderPreURL, file: VodUploaderFile, doneCallBack: Function) {
    if (!presignedPost) {
      this.notification.error(`Faild to get Presigned URL for file '${file.name}'`, 'Upload Error');
      return;
    }

    try {
      file.http = new HttpClient();
      file.error = undefined;
      file.upload_id = presignedPost.upload_id;

      const promises = presignedPost.urls.map(({url, part_number}) => {
        const correntPart = part_number - 1;
        const fileData = file.fileParts.length > 1 ? file.fileParts[correntPart] : file;

        return file.http
          .createRequest(url)
          .asPut()
          .withContent(fileData.file)
          .withHeader('Content-Type', fileData.file.type)
          .withProgressCallback((e: ProgressEvent) => {
            fileData.loaded = e.loaded;
            const sum = file.fileParts.reduce((accumulator, current) => accumulator + current.loaded, 0);
            file.loaded = file.fileParts.length > 1 ? sum : fileData.loaded;
            const percent = Math.round((file.loaded / file.file.size) * 100);
            file.percentage = percent;
          })
          .send();
      });

      const res = await Promise.all(promises);
      const parts = res.map(x => {
        const etag = x.headers.get('etag');
        let partNumber;
        new URL(x.requestMessage.url).searchParams.forEach((value, key) => {
          if (key === 'partNumber') {
            partNumber = value;
          }
        });

        return {PartNumber: Number(partNumber), ETag: etag.replace(/"/g, '')};
      });

      doneCallBack(parts);
    } catch (err) {
      const {response} = err;
      if (response.type === 'abort') {
        return;
      }
      const error = `Failed to upload file "${file.name}". ${this.parseXmlError(err.response)}`;
      file.error = error;
      log.error(err);
      this.notification.error(error, 'Upload Error');
      throw new Error('Failed to upload file');
    }
  }

  public removeSelectedFile(file: VodUploaderFile) {
    this.selectedFiles.splice(
      this.selectedFiles.findIndex(v => v === file),
      1,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  public uploadAbort(file: VodUploaderFile) {
    const requests = file.http && file.http.pendingRequests && file.http.pendingRequests;

    if (requests && requests.length) {
      requests.forEach((request: XMLHttpRequest) => {
        request.abort();
      });
    }

    // abort multipart upload on aws side
    if (file.upload_id) {
      this.abortMultiPartFile(file);
    }
  }

  public async uploadFiles(files: VodUploaderFile[]) {
    try {
      this.isUploading = true;
      const sortedFiles = _.sortBy(files, ['isVideo']);
      const res = await this.getPresignedPost(sortedFiles);
      const {items} = res;

      sortedFiles.forEach(file => {
        const presigned = items.find(item => item.file_name === file.name);

        this.uploadS3file(presigned, file, async (parts: []) => {
          file.http = undefined;

          if (file.isVideo) {
            const data = {
              call_params: {
                media_url: 'local',
                files: [
                  {
                    name: file.name,
                    upload_id: presigned.upload_id,
                    parts: presigned.upload_id ? parts : [],
                  },
                ],
              },
            };
            await this.uploadVODUrl(data);
          }

          this.removeSelectedFile(file);
        });
      });

      this.isUploading = false;
    } catch (err) {
      this.isUploading = false;
      this.notification.error(err.message, 'Upload files Failed');
      log.error(err);
      throw new Error(err);
    }
  }

  public async getTemplates() {
    try {
      const res = await this.acceo.get(TemplatesListResponse)(VOD_TEMPLEATES_URL);
      return res.items;
    } catch (err) {
      this.notification.error('Failed To Get Templates');
      log.error(err);
      throw new Error(err);
    }
  }

  public async updateTemplate(model: Template) {
    const data = _.cloneDeep(model);
    delete data.id;

    try {
      const res = await this.acceo.patch(Template)(`${VOD_TEMPLEATES_URL}${model.id}`, data);
      return res;
    } catch (err) {
      this.notification.error('Failed To Update Template');
      log.error(err);
      throw new Error(err);
    }
  }
}
