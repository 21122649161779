import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';

import {FailoverGroup, Meta} from '../models/failover-group';

@autoinject()
export class FailoverGroupSNSService {
  public meta: Meta = {};
  public params: any = {};

  public order: string = 'desc';

  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;

  public groups: FailoverGroup[] = [];
  public searchResultGroups: FailoverGroup[] = [];

  constructor(public acceo: Acceo) {}

  public deleteSNSTopic(snsId: string = ''): Promise<any> {
    return this.acceo.delete()(`/api/v4/groups/sns/topic/${snsId}`);
  }

  public getSNSTopic(): Promise<any> {
    return this.acceo.get()('/api/v4/groups/sns/topic');
  }

  public updateSNSTopic(snsTopic: string = ''): Promise<any> {
    return this.acceo.post()('/api/v4/groups/sns/topic', {topic: snsTopic});
  }
}
