import {CTableCol} from '@bindable-ui/bindable';
import {autoinject, bindable, LogManager} from 'aurelia-framework';
import {FailoverGroup} from '../../models/failover-group';
import {FailoverGroupService} from '../../services/failover-group-service';

const logger = LogManager.getLogger('SlicerChangeLog');

@autoinject()
export class EventLog {
    @bindable
    public group: FailoverGroup;

    public columns: CTableCol[];
    public isLoading: boolean = true;
    public logger = logger;

    public constructor(private failoverGroupService: FailoverGroupService) {
        this.columns = [
            {
                colHeadName: 'timestamp',
                colHeadValue: 'Time',
                sort: true,
                valueConverter: 'timezoneTimeToStandardDateTimeMS',
            },
            {
                colHeadName: 'message',
                colHeadValue: 'Message',
                sort: true,
            },
            {
                colHeadName: 'module',
                colHeadValue: 'Module',
                sort: true,
            },
        ];
    }

    public async attached() {
        try {
            this.isLoading = true;
            this.group.event_log = await this.failoverGroupService.getFailoverGroupEvents(this.group.id);
            this.isLoading = false;
        } catch (e) {
            logger.error(e);
        }
    }
}
