/* eslint-disable no-shadow */
/* eslint-disable max-classes-per-file */

import {Type} from 'class-transformer';
import {
  IsString,
  IsOptional,
  IsBoolean,
  IsArray,
  ValidateNested,
  ArrayMinSize,
  ArrayNotEmpty,
  IsNumber,
} from 'class-validator';
import {IKeyValue} from 'interfaces/key-value';
import {IHyperionResponse} from 'interfaces/hyperion';

export enum EncryptionType {
  SHA256 = 'sha256',
  SHA512 = 'sha512',
}

export enum SsoAuthType {
  IDP = 'saml_idp',
  SP = 'saml_sp',
}

export enum ProtocolBindingType {
  POST = 'http_post',
  REDIRECT = 'http_redirect',
}

export interface ITenantMin {
  id: string;
}

export interface ICertificate {
  name: string;
  value: string;
}

export interface IIntegrationBase {
  assertion_uri?: string;
  attribute_mappings?: IKeyValue<string>;
  description?: string;
  digest: EncryptionType;
  encrypt_assertion: boolean;
  entity?: string; // Should be required but some of the IDS records use name instead
  protocol_binding: ProtocolBindingType;
  redirect_uris: string[];
  relay_state_uri: string;
  sign_assertion: boolean;
  sign_authentication_request: boolean;
  sign_in_uri?: string;
  sign_out_uri?: string;
  sign_request: boolean;
  sign_response: boolean;
  signature: EncryptionType;
  type: SsoAuthType;
  user_email_attribute?: string;
  user_id_attribute?: string;
}

export interface IIntegrationNew extends IIntegrationBase {
  certificate_name: string;
  certificate: string;
}

export interface IIntegration extends IIntegrationBase {
  created: string;
  certificates: ICertificate[];
  id: string;
  updated: string;
  tenant: ITenantMin;
}

export class TenantMin implements ITenantMin {
  @IsString()
  public id: string;
}

export class Certificate implements ICertificate {
  @IsString()
  @IsOptional()
  public name: string;

  @IsString()
  public value: string;
}

export class IdsIntegrationBase implements IIntegrationBase {
  @IsString()
  @IsOptional()
  public assertion_uri?: string;

  @IsOptional()
  @ValidateNested()
  public attribute_mappings?: IKeyValue<string>;

  @ArrayNotEmpty()
  @ArrayMinSize(1)
  @ValidateNested()
  @IsArray()
  @Type(() => Certificate)
  public certificates: Certificate[];

  @IsString()
  @IsOptional()
  public description?: string;

  @IsString()
  public digest: EncryptionType;

  @IsBoolean()
  public encrypt_assertion: boolean;

  @IsString()
  public entity: string;

  @IsString()
  public protocol_binding: ProtocolBindingType;

  @ArrayNotEmpty()
  @ArrayMinSize(1)
  @IsArray()
  public redirect_uris: string[];

  @IsString()
  public relay_state_uri: string;

  @IsBoolean()
  public sign_assertion: boolean;

  @IsBoolean()
  public sign_authentication_request: boolean;

  @IsString()
  @IsOptional()
  public sign_in_uri?: string;

  @IsString()
  @IsOptional()
  public sign_out_uri?: string;

  @IsBoolean()
  public sign_request: boolean;

  @IsBoolean()
  public sign_response: boolean;

  @IsString()
  public signature: EncryptionType;

  @IsString()
  public type: SsoAuthType;

  @IsString()
  @IsOptional()
  public user_email_attribute?: string;

  @IsString()
  @IsOptional()
  public user_id_attribute?: string;
}

export class IdsIntegrationNew extends IdsIntegrationBase implements IIntegrationNew {
  @IsString()
  public certificate: string;

  @IsString()
  public certificate_name: string;
}

export class IdsIntegration extends IdsIntegrationBase implements IIntegration {
  @IsOptional()
  @IsString()
  public created: string;

  @IsString()
  public id: string;

  @ValidateNested()
  @Type(() => TenantMin)
  public tenant: TenantMin;

  @IsOptional()
  @IsString()
  public updated: string;
}

export class IdsIntegrationResponse implements IHyperionResponse<IdsIntegration> {
  @ValidateNested()
  public items: IdsIntegration[];

  @IsNumber()
  public total_items: number;
}
