import {CToastsService, LocalStorageHelper, ZOOM_LEVELS, ITimeEntryBasic} from '@bindable-ui/bindable';
import {BindingEngine, autoinject, computedFrom, observable} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {Router} from 'aurelia-router';
import {PLATFORM} from 'aurelia-pal';

import * as moment from 'moment';

import {HyperionPolling} from 'services/hyperion-polling';
import {BetaMode} from 'services/beta-mode';
import {SessionService} from 'services/session';
import {LibraryService} from 'services/library-service';

import {DraggabillyService, IDraggabillyActions} from 'resources/timeline/services/draggabilly-service';

import {RuleService} from '../../rules/services/rules';

import {BlackoutService} from '../services/blackouts';
import {LiveChannelsService} from '../services/live-channels';
import {IScheduledEntryListParams, IScheduledEntryParams, ScheduleEntryService} from '../services/scheduled-entry';
import {cleanupWorkers} from '../services/schedule-workers';

import {BaseChannelSingle} from './base';
import {trackedScheduleEntry} from './models/event-model';
import {NAV_TABS} from './utils/constants';
import {EntryDragHandler} from './utils/entry-drag-handler';

export enum DATE_TYPES {
  DAY = 'day',
  THREE_DAY = 'three-day',
  WEEK = 'week',
}

export const SCHEDULER_VIEW = 'scheduler-view';
export const SCHEDULER_ZOOM = 'scheduler-zoom';

const editEntryViewModel = PLATFORM.moduleName(
  'apps/cms/routes/live-channels/channels/single/popovers/edit-time-entry',
);
const viewEntryViewModel = PLATFORM.moduleName(
  'apps/cms/routes/live-channels/channels/single/popovers/view-time-entry',
);

interface ITimelineActions {
  getEntries: (start, stop) => Promise<ITimeEntryBasic[]>;
  isReadOnly: () => boolean;
  pollEntries: (start, stop, existingEntries) => Promise<ITimeEntryBasic[]>;
  dragOptions: IDraggabillyActions;
}

// const log = LogManager.getLogger('live-channels-channels-single-scheduler-view');

@autoinject()
export class Scheduler extends BaseChannelSingle {
  constructor(
    public draggabillyService: DraggabillyService,
    public bindingEngine: BindingEngine,
    public liveChannels: LiveChannelsService,
    public blackoutService: BlackoutService,
    public ruleService: RuleService,
    public notification: CToastsService,
    public dialogService: DialogService,
    public session: SessionService,
    public libraryService: LibraryService,
    public router: Router,
    public scheduleEntryService: ScheduleEntryService,
    public betaMode: BetaMode,
  ) {
    super(
      bindingEngine,
      liveChannels,
      blackoutService,
      ruleService,
      notification,
      dialogService,
      session,
      libraryService,
      router,
      scheduleEntryService,
      betaMode,
    );

    this.importEnabled = this.betaMode.hasScope('schedule-import');
  }

  /*
   * Computed Properties
   */

  @computedFrom('liveChannels.currentModel.use_chsched')
  get readOnly() {
    if (this.liveChannels.currentModel && this.liveChannels.currentModel.use_chsched !== 2) {
      return true;
    }
    return false;
  }

  @computedFrom('activeDate', 'calView')
  get startDate() {
    if (this.calView === 'week' || this.calView === 'day') {
      return moment(this.activeDate)
        .startOf(this.calView as moment.unitOfTime.StartOf)
        .toISOString();
    }

    return moment(this.activeDate).subtract(1, 'day').startOf('day').toISOString();
  }

  @computedFrom('activeDate', 'calView')
  get stopDate() {
    if (this.calView === 'week' || this.calView === 'day') {
      return moment(this.activeDate)
        .endOf(this.calView as moment.unitOfTime.StartOf)
        .toISOString();
    }

    return moment(this.activeDate).add(1, 'day').endOf('day').toISOString();
  }

  @computedFrom('readOnly')
  get title() {
    return this.readOnly ? 'History' : 'Schedule';
  }

  @computedFrom('activeDeleteEndDate', 'activeDeleteStartDate')
  get endDateError() {
    this.deleteBackendError = null;
    if (moment(this.activeDeleteEndDate).isBefore(moment(this.activeDeleteStartDate))) {
      return 'End time cannot be before start time';
    }

    if (moment(this.activeDeleteEndDate).diff(moment(this.activeDeleteStartDate), 'days') >= 5) {
      return 'Cannot delete more than 5 days';
    }

    return '';
  }

  /*
   * Public Properties
   */

  public activeDeleteStartDate: string;
  public activeDeleteEndDate: string;
  public deleteConfirm: string = '';
  public deleteBackendError: string = null;
  public deleteTip: any;
  public isDeleting: boolean = false;
  public importEnabled: boolean = false;

  @observable
  public zoomLevel: number = parseInt(LocalStorageHelper.loadOrDefault(SCHEDULER_ZOOM, 2), 10);

  public activeDate: string;
  public calView: string = LocalStorageHelper.loadOrDefault(SCHEDULER_VIEW, DATE_TYPES.DAY);
  public dateTypes = DATE_TYPES;
  public scrollTime: string = null;

  public editEntryViewModel;
  public addEntryViewModel = PLATFORM.moduleName(
    'apps/cms/routes/live-channels/channels/single/popovers/add-time-entry',
  );

  public params: IScheduledEntryListParams;
  public scheduleEntryPollTracker: HyperionPolling;

  public dateCallbacks = {
    /**
     * This is to trigger getting a new date in the timeline
     * _.throttle is used to prevent issue when picker is empty
     */
    onChange: _.throttle(
      timestamp => {
        if (timestamp) {
          // Compare to current date so it doesn't trigger a reload
          const parsedDate = moment(Number(timestamp)).startOf('day').format('MM/DD/YYYY');
          const parsedCurrent = moment(Number(this.currentTracker)).format('MM/DD/YYYY');
          if (parsedDate !== parsedCurrent) {
            this.currentTracker = timestamp;
          }

          this.setPersistentDate();
          this.setDeleteDates(this.calView);
        }
      },
      100,
      {leading: false, trailing: true},
    ).bind(this),
  };

  public deleteTipActions = {
    onHide: () => {
      this.setDeleteDates(this.calView);
      this.deleteBackendError = null;
      this.deleteConfirm = null;
    },
  };

  public modalViewModel = null;
  public modalModel = null;
  public showModal = false;
  public attachedFinished: boolean = false;

  public actions: ITimelineActions = {
    getEntries: async (start, stop) => {
      let query;

      if (this.readOnly) {
        query = {
          id: this.channelId,
          start: Number(moment(start).format('x')),
          stop: Number(moment(stop).format('x')),
        };
      } else {
        query = {
          end: moment(stop).toISOString(),
          start: moment(start).toISOString(),
        };
      }

      return this.scheduleEntryService.getChannelSchedule(query, this.channelId, this.readOnly);
    },
    pollEntries: (start, stop, existingEntries) => {
      let query;

      if (this.draggabillyService.isDragging || this.showModal) {
        return null;
      }

      if (this.readOnly) {
        query = {
          id: this.channelId,
          start: Number(moment(start).format('x')),
          stop: Number(moment(stop).format('x')),
        };

        return this.scheduleEntryService.getChannelSchedule(query, this.channelId, this.readOnly);
      }

      query = {
        start: Number(moment(start).format('x')),
        stop: Number(moment(stop).format('x')),
      };

      return this.scheduleEntryService.getScheduleUpdates(this.channelId, existingEntries, query);
    },
    dragOptions: {
      onDragStart: (entry, draggie) => {
        this.dragHandler = new EntryDragHandler(entry, this.zoomLevel, draggie);
      },
      onDragMove: async event => this.dragHandler.onMove(event),
      onDragEnd: async () => {
        const updated = await this.dragHandler.onEnd(e => this.updateScheduleEntry(e));
        this.dragHandler = null;

        return updated;
      },
    },
    isReadOnly: () => this.readOnly,
  };

  /*
   * Private Properties
   */

  private dragHandler: EntryDragHandler;

  /**
   * iso formatted value for NOW
   */
  // eslint-disable-next-line class-methods-use-this
  private get now() {
    return moment().startOf('day').toISOString();
  }

  protected entriesLastPollTime: string;
  protected itemsLastPollTime: string;

  private currentTracker = moment(this.activeDate).format('x');

  /**
   * Prepare the URL query params with a start and end datetime properly ISO formatted
   * These date values should already have been formatted in setDeleteDates.
   * The idea is that start date will be the start of the day at the start of the range.
   * The end date will be the end of the day at the end of our range.
   *
   * Use scheduleEntryService class object to initiate a build delete API call
   */
  public async deleteEntries() {
    // Here is where we will use scheduleEntryService to delete scheduled entries by date range
    const query = {
      end: moment(this.activeDeleteEndDate).toISOString(),
      keep_live: 1,
      start: moment(this.activeDeleteStartDate).toISOString(),
    };
    try {
      this.isDeleting = true;
      await this.scheduleEntryService.deleteScheduledEntriesByDaterange(this.channelId, query);
      this.deleteTip.toggleVisible();
    } catch (ex) {
      this.deleteBackendError = ex.message;
    } finally {
      this.isDeleting = false;
    }
  }

  public attached() {
    // This is temporary
    if (this.calView === 'week') {
      const type = DATE_TYPES.THREE_DAY;
      LocalStorageHelper.save(SCHEDULER_VIEW, type);
      this.calView = type;
    }

    this.liveChannels.activeTab = 'schedule';
    super.attached();
    this.editEntryViewModel = this.readOnly ? viewEntryViewModel : editEntryViewModel;

    // For some reason in Firefox, the timeline constructor is initialized with missing values
    // only when navigating to the schedule page but not when refreshing the page.
    // By waiting until the page is fully attached before rendering the timeline, we
    // are preventing this bug in Firefox and ensuring all necessary values are passed in
    this.attachedFinished = true;
  }

  public deactivate() {
    super.deactivate();
    this.attachedFinished = false;

    this.liveChannels.routeCallback = null;
    cleanupWorkers();
  }

  public bulkExport() {
    this.modalViewModel = PLATFORM.moduleName('apps/cms/routes/live-channels/channels/single/modals/bulk-export/index');
    this.modalModel = {
      channelId: this.channelId,
      actions: {
        onClose: () => {
          this.showModal = false;
        },
      },
    };

    this.showModal = true;
  }

  public bulkImport() {
    this.modalViewModel = PLATFORM.moduleName('apps/cms/routes/live-channels/channels/single/modals/bulk-import/index');
    this.modalModel = {
      channelId: this.channelId,
      actions: {
        onClose: () => {
          this.showModal = false;
          this.scheduleEntryService.forceTimelinePoll();
          this.scheduleEntryService.forceUpdate = new Date().toISOString();
        },
      },
    };

    this.showModal = true;
  }

  public async changeView(type: string) {
    LocalStorageHelper.save(SCHEDULER_VIEW, type);
    this.setDeleteDates(type);
    this.calView = type;
  }

  /**
   * For now, the delete dates are set to the start of the day and end of the day,
   * since we are not currently allowing the user to fine tune the time of day.
   *
   * This code will have to change if we ever allow the user to select time of day.
   */
  public async setDeleteDates(type: string) {
    if (type === DATE_TYPES.THREE_DAY) {
      this.activeDeleteStartDate = moment(this.activeDate).startOf('day').add(-1, 'days').toISOString();
      this.activeDeleteEndDate = moment(this.activeDate).endOf('day').add(+1, 'days').toISOString();
    } else {
      this.activeDeleteStartDate = moment(this.activeDate).startOf('day').toISOString();
      this.activeDeleteEndDate = moment(this.activeDate).endOf('day').toISOString();
    }
    // if (moment(this.activeDeleteEndDate).isBefore(moment(this.now).endOf('day'))) {
    //     this.activeDeleteEndDate = moment(this.now).endOf('day').toISOString();
    // }
  }

  public async changeDate(dir: number) {
    switch (this.calView) {
      case 'day':
      case 'three-day':
        this.activeDate = moment(this.activeDate).add(dir, 'days').toISOString();
        break;
      case 'week':
        this.activeDate = moment(this.activeDate).add(dir, 'weeks').toISOString();
        break;
      default:
        break;
    }
    this.setDeleteDates(this.calView);
    this.setPersistentDate();
  }

  public zoomLevelChanged(_new, orig) {
    if (!orig) {
      return;
    }

    LocalStorageHelper.save(SCHEDULER_ZOOM, this.zoomLevel);
  }

  public preventCreateEntry(isoDate) {
    if (
      this.readOnly ||
      moment(isoDate).add(ZOOM_LEVELS[this.zoomLevel].minutes, 'minutes').isSameOrBefore(moment()) ||
      this.draggabillyService.preventCreateNewEntry
    ) {
      return true;
    }

    return false;
  }

  public async today() {
    const now = moment(this.now);
    const activeDate = moment(this.activeDate);

    if (now.isSame(activeDate, 'day')) {
      this.setScrollTime();
      return;
    }

    this.activeDate = now.toISOString();
    this.setPersistentDate();
  }

  public zoom(direction) {
    const zoomLevel = parseInt(this.zoomLevel.toString(), 10);

    if (direction === 1 && zoomLevel < 5) {
      this.zoomLevel = zoomLevel + 1;
    } else if (direction === -1 && zoomLevel > 0) {
      this.zoomLevel = zoomLevel - 1;
    }
  }

  protected async activateView() {
    this.activeDate = this.liveChannels.selectedDate || this.now;
    this.setDeleteDates(this.calView);
    if (this.readOnly && this.calView !== DATE_TYPES.DAY) {
      this.changeView(DATE_TYPES.DAY);
    }

    if (moment(this.activeDate).isSame(this.now, 'day')) {
      this.setScrollTime();
    }
  }

  private setScrollTime() {
    this.scrollTime = null;
    // eslint-disable-next-line no-return-assign
    _.delay(() => (this.scrollTime = moment().format('HH:mm')), 100);
  }

  private getModalSizeTitle(entry) {
    let title;
    let size = 'large';
    if (entry.isAdBreak) {
      title = 'Ad Break';
    } else if (entry.isSlicer) {
      if (entry.content_owner && entry.content_id) {
        entry.content_id = `${entry.content_owner}:${entry.content_id}`;
      }
      title = 'Slicer';
    } else if (entry.isAsset) {
      let extraTitle = '';
      if (entry.desc) {
        extraTitle = ` - ${entry.desc}`;
      }
      title = `Asset${extraTitle}`;
      size = 'full';
    } else if (entry.isMatchSignal) {
      title = 'Signal Blackout';
    } else if (entry.isMatchTime) {
      title = 'Timed Blackout';
    }
    return {title, size};
  }

  private async loadConflicts(id, dur, start) {
    const _params: IScheduledEntryListParams = {
      end: moment(start).add(dur, 'milliseconds').toISOString(),
      start: moment(start).toISOString(),
    };

    await this.scheduleEntryService.getConflicts(_params, this.channelId, id);
  }

  private async openModal(id, newStartTime) {
    const schedEntry = await this.scheduleEntryService.getScheduledEntry(id, {}, this.channelId);
    const model = await trackedScheduleEntry(schedEntry);
    model.start = newStartTime;
    const {title, size} = this.getModalSizeTitle(model);
    this.dialogService.open({
      model: {
        size,
        title,
        bodyViewModel: PLATFORM.moduleName(
          'apps/cms/routes/live-channels/channels/single/modals/schedule-add/add-body',
        ),
        footerEnable: true,
        footerViewModel: PLATFORM.moduleName(
          'apps/cms/routes/live-channels/channels/single/modals/schedule-add/add-footer',
        ),
        sharedModel: {
          entry: model,
          channelId: this.channelId,
          activeTab: NAV_TABS.CONFLICTS,
        },
      },
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
    });
  }

  private setPersistentDate() {
    this.liveChannels.selectedDate = this.activeDate;
  }

  private async updateScheduleEntry(item) {
    const newStartTime = item.start;
    let ad_breaks = [];
    const {
      blackout_id,
      content_id,
      content_type,
      desc,
      dur,
      id,
      isAsset,
      rules,
      replay_source_start,
      replay_source_end,
    } = item.model;

    if (isAsset) {
      ad_breaks = item.model.ad_breaks;
    }

    // Normalized for serializations
    const params: IScheduledEntryParams = {
      ad_breaks,
      blackout_id,
      content_id,
      content_type,
      desc,
      dur,
      rules,
      replay_source_start: moment(replay_source_start).toISOString(),
      replay_source_end: moment(replay_source_end).toISOString(),
      start: newStartTime,
    };

    if (item.isSlicer && content_id.indexOf(':') > -1) {
      const idSplit = content_id.split(':');
      params.content_owner = idSplit[0];
      params.content_id = idSplit[1];
    }

    try {
      await this.scheduleEntryService.updateScheduledEntry(id, params, this.channelId);
      this.notification.success('Updated');
      return true;
    } catch (ex) {
      this.notification.error(ex.message);
      if (ex.details && ex.details.length > 0) {
        await this.loadConflicts(id, params.dur, newStartTime);
        await this.openModal(id, newStartTime);
      }
      return false;
    }
  }
}
