import {DialogService} from 'aurelia-dialog';
import {autoinject, LogManager} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {classToPlain} from 'class-transformer';
// import {serialize} from 'class-transformer';
// import * as $ from 'jquery';
import * as _ from 'lodash';

import {CToastsService, IVNavSliderPageList, SharedNav} from '@bindable-ui/bindable';
// tslint:disable-next-line: max-line-length
// import {IVNavSliderPageList} from 'interfaces/c-nav-vertical-sliding-interfaces';
import {Acceo} from 'services/acceo';

import {DEFAULT_PAGE_NUM, DEFAULT_PER_PAGE_LIMIT, LIST_NAV_INDEX} from '../../models/defaults';
import {HyperionFilterParams, HyperionMeta} from '../../models/models';
import {
  AUDIENCE_TYPE,
  AudienceFilterParams,
  AudienceMetadata,
  AudiencesListResponse,
  SimpleAudience,
} from '../models/models';

const log = LogManager.getLogger('live-channels-audience-service');

@autoinject()
export class AudiencesService {
  public params: HyperionFilterParams = {};
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public searchLoading: boolean = false;
  public isCreating: boolean = false;
  public isDeleting: boolean = false;
  public isSaving: boolean = false;
  public audiences: SimpleAudience[] = [];
  public singleAudiences: SimpleAudience[] = [];
  public audienceNotFound: boolean = false;
  public audienceStatusCodeError: number = 0;
  public metadataNotFound: boolean = false;
  public searchQuery: string = '';
  public searchQueryApplied: string = '';
  public meta: HyperionMeta = {};
  public singleMeta: HyperionMeta = {};
  public selectedAudiences: string[] = [];
  public currentModel: SimpleAudience = null;
  public currentMetadata: AudienceMetadata = null;
  public newAudienceName: string = '';
  public createErrMsg: string = null;
  public createAudienceForm: any;
  public createAudienceDialog: any;

  private currentIndex: string = null;

  private navPage: IVNavSliderPageList = {
    loadMore: () => this.getMoreAudiences(),
    navs: [],
    prevText: 'Live Channels Menu',
    searchFn: query => this.search(query),
    searchPlaceholder: 'Search Audiences',
    searchQuery: '',
    title: 'Audiences',
  };

  private audiencesUrl: string = '/api/v4/audiences';
  private audienceMetaUrl: string = '/api/v4/audience-metas';
  private audiencesHref: string = '#/live-channels/audiences';

  constructor(
    private acceo: Acceo,
    private sharedNav: SharedNav,
    private notification: CToastsService,
    private dialogService: DialogService,
    public router: Router,
  ) {}

  /**
   * Sets an audience active in the side nav
   *
   * @param id Audience ID
   */
  public setNavActive(id: string): void {
    this.sharedNav.replacePage(this.navPage, LIST_NAV_INDEX);
    this.currentIndex = id;

    if (id) {
      for (let index = 0; index < this.audiences.length; index++) {
        const audience = this.audiences[index];
        if (audience.id === id) {
          this.sharedNav.setActive(LIST_NAV_INDEX, index);
          break;
        }
      }
    }
  }

  /**
   * Changes the navigation title in the side nav
   *
   * @param id Audience ID
   * @param title Title of the new navigation text
   */
  public setNavText(id: string, title: string): void {
    this.sharedNav.replacePage(this.navPage, LIST_NAV_INDEX);

    if (id) {
      for (let index = 0; index < this.audiences.length; index++) {
        const audience = this.audiences[index];
        if (audience.id === id) {
          this.sharedNav.nav.pages[LIST_NAV_INDEX].navs[index].title = title;
          this.sharedNav.setActive(LIST_NAV_INDEX, index);
          break;
        }
      }
    }
  }

  /**
   * Search the audiences
   *
   * @param searchQuery Optional search term that comes from the sidebar
   */
  public async search(searchQuery?: string) {
    this.navPage.searchQuery = searchQuery;
    this.searchQuery = searchQuery;
    this.searchQueryApplied = '';

    const params: HyperionFilterParams = {};
    if (searchQuery) {
      params.search = searchQuery;
    }

    this.searchLoading = true;

    try {
      await this.getAudiences(params);
      this.searchQueryApplied = searchQuery;
    } catch (e) {
      // Do something with the error
    } finally {
      this.searchLoading = false;
    }
  }

  /**
   * Get a list of audiences based on a set of parameters
   *
   * @param params HyperionFilterParams object
   * @param isLoadMore Optional boolean for if it's a fresh load or it's loading more in the existing list
   */
  public async getAudiences(params: HyperionFilterParams = {}, isLoadMore: boolean = false) {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }

    if (!params.order) {
      params.order = 'desc';
    }
    if (!params.page) {
      params.page = DEFAULT_PAGE_NUM;
    }
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }
    if (this.searchQuery) {
      params.search = this.searchQuery;
    }

    // keeping params in memory so that getMoreAudiences can reuse it.
    this.params = _.cloneDeep(params);
    const urlParams = $.param(params);
    if (!isLoadMore) {
      this.audiences = [];
      this.isLoading = true;
      this.navPage.isLoading = true;
    } else {
      this.isLoadingMore = true;
    }

    try {
      const res = await this.acceo.get(AudiencesListResponse)(`${this.audiencesUrl}?${urlParams}`, {
        responseTransform: (resp: AudiencesListResponse) => resp,
      });
      this.audiences = _.uniqBy(this.audiences.concat(res.items), 'id');
      this.navPage.navs = _.map(this.audiences, audience => ({
        active: audience.id === this.currentIndex,
        href: `${this.audiencesHref}/${audience.id}`,
        title: audience.desc,
      }));

      this.meta.total = res.total_items;
      this.meta.showing = this.audiences.length;
      this.meta.page = this.params.page;
      this.meta.hasMore = this.meta.total > this.meta.showing;
    } catch (err) {
      log.error(err);
      this.notification.error('Error loading audiences. Please try again.');
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.navPage.isLoading = false;
    }
  }

  /**
   * Get a single audience by ID
   * @param id Audience ID
   */
  public async getAudience(audienceId: string) {
    try {
      this.audienceNotFound = false;
      const res = await this.acceo.get(SimpleAudience)(`${this.audiencesUrl}/${audienceId}`);

      this.currentModel = res;
    } catch (err) {
      this.audienceNotFound = true;
      this.audienceStatusCodeError = err.status_code;
      log.error(err);
    }
  }

  /**
   * gets more audience list (if exists)
   */
  public async getMoreAudiences() {
    if (this.meta.hasMore) {
      const params = _.cloneDeep(this.params);
      // updating params page count before making the call.
      // meta page count shall be updated once the call completes.
      params.page = this.meta.page + 1;
      await this.getAudiences(params, true);
    }
  }

  /**
   * Create a new Audience
   *
   * @param isAudienceType What type is this going to be: Single or List
   * @param andEdit If you're going to navigate to it after creation
   */
  public async createAudience(audienceType: AUDIENCE_TYPE = AUDIENCE_TYPE.SINGLE, andEdit: boolean = false) {
    const createParams = {
      desc: this.newAudienceName,
      type:
        audienceType.toUpperCase() === AUDIENCE_TYPE.SINGLE.toUpperCase()
          ? AUDIENCE_TYPE.SINGLE
          : AUDIENCE_TYPE.MULTIPLE,
    };

    this.createErrMsg = null;
    if (!this.newAudienceName.length) {
      this.createErrMsg = 'Name is required.';
      return;
    }

    this.isCreating = true;

    try {
      const res = await this.acceo.post(SimpleAudience)(this.audiencesUrl, createParams, {
        requestTransform: params => JSON.stringify(params),
      });
      this.notification.success('Audience created successfully.');
      this.audiences.unshift(res);
      this.navPage.navs.unshift({
        active: false,
        href: `${this.audiencesHref}/${res.id}`,
        title: res.desc,
      });

      this.newAudienceName = '';
      this.meta.total += 1;
      this.createAudienceDialog.hide();

      if (andEdit) {
        document.location.href = `${this.audiencesHref}/${res.id}`;
      }
    } catch (err) {
      this.createErrMsg = 'An audience with that name already exists';
      log.error(err);
    } finally {
      this.isCreating = false;
    }
  }

  /**
   * Delete all the selected audiences
   */
  public async deleteAudiences(id?: string) {
    if (this.isDeleting) {
      return;
    }

    if (id) {
      this.selectedAudiences = [id];
    }

    this.isDeleting = true;
    const conflicts = [];
    const errors = [];

    this.selectedAudiences = await Promise.filter(this.selectedAudiences, async audienceId => {
      try {
        await this.acceo.delete()(`${this.audiencesUrl}/${audienceId}`);

        // Remove it from the audiences array
        _.remove(this.audiences, {id: audienceId});

        // Remove it from the side nav
        const navItemIndex = _.findIndex(this.navPage.navs, (navItem: any) =>
          _.startsWith(navItem.href, `${this.audiencesHref}/${audienceId}`),
        );
        if (navItemIndex > -1) {
          this.navPage.navs.splice(navItemIndex, 1);
        }

        this.meta.total -= 1;
        this.meta.showing = this.audiences.length;

        return false; // Only keep errors
      } catch (e) {
        if (e.status_code === 409) {
          conflicts.push({id: audienceId, message: e.message, details: e.details});
        } else {
          errors.push(audienceId);
        }
        return true;
      }
    });

    if (errors.length) {
      this.notification.error(`Error removing audience${errors.length > 1 ? 's' : ''}. Please try again.`);
    }

    if (conflicts.length) {
      const messages = _.map(conflicts, conflict => {
        const audience: any = _.find(this.audiences, {id: conflict.id});
        const message = {
          notes: [],
          subject: audience.desc,
        };

        message.notes.push(conflict.message);

        return message;
      });

      const audienceWord = `audience${conflicts.length > 1 ? 's' : ''}`;
      const correctWording = `${conflicts.length > 1 ? 'they are' : 'it is'}`;

      this.dialogService.open({
        model: {
          bodyModel: {
            messages,
            message: `Cannot delete ${audienceWord} below because ${correctWording} in use`,
          },
          bodyViewModel: PLATFORM.moduleName('resources/dialogs/notifications/notifications-modal-body'),
          footerEnable: true,
          footerViewModel: PLATFORM.moduleName('resources/dialogs/notifications/notifications-modal-footer'),
          size: 'medium',
          title: 'Errors occurred',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      });
    }

    this.isDeleting = false;

    if (!this.selectedAudiences.length) {
      this.selectedAudiences = [];

      if (id) {
        this.router.navigate('#/live-channels/audiences');
      }
    }
  }

  /**
   * Save audience to the database
   */
  public async saveAudience(audience: any): Promise<SimpleAudience> {
    try {
      this.isSaving = true;
      const data: any = classToPlain(audience);
      const res = await this.acceo.patch(SimpleAudience)(`${this.audiencesUrl}/${audience.id}`, data);
      this.notification.success('Changes saved successfully.');
      return res;
    } catch (e) {
      log.error(e);
      this.notification.error(e.message || 'Error updating audience.');
      throw new Error(e.message);
    }
  }

  /**
   * Toggle all visible audiences as deletable
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.audiences, audience => {
      audience.selected = isSelected;
      this.trackSelectedAudience(audience);
    });
  }

  /**
   * Track an audience as it's toggled for deletion
   *
   * @param audience SimpleAudience object
   */
  public trackSelectedAudience(audience: SimpleAudience) {
    if (audience.selected && !this.selectedAudiences.includes(audience.id)) {
      this.selectedAudiences.push(audience.id);
    } else if (!audience.selected) {
      _.remove(this.selectedAudiences, audienceId => audienceId === audience.id);
    }
  }

  /**
   * Get a list of audiences based on a set of parameters
   *
   * @param params HyperionFilterParams object
   * @param isLoadMore Optional boolean for if it's a fresh load or it's loading more in the existing list
   */
  public async getAllSingleAudiences(
    params: AudienceFilterParams = {type: AUDIENCE_TYPE.SINGLE},
    isLoadMore: boolean = false,
  ) {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }
    params.order = 'desc';
    if (!params.page) {
      params.page = DEFAULT_PAGE_NUM;
    }
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }
    if (this.searchQuery) {
      params.search = this.searchQuery;
    }

    // keeping params in memory so that getMoreAudiences can reuse it.
    this.params = _.cloneDeep(params);
    const urlParams = $.param(params);
    if (!isLoadMore) {
      this.singleAudiences = [];
      this.isLoading = true;
    } else {
      this.isLoadingMore = true;
    }

    try {
      const res = await this.acceo.get(AudiencesListResponse)(`${this.audiencesUrl}?${urlParams}`, {
        responseTransform: (resp: AudiencesListResponse) => resp,
      });
      this.singleAudiences = _.uniqBy(this.singleAudiences.concat(res.items), 'id');

      this.singleMeta.total = res.total_items;
      this.singleMeta.showing = this.singleAudiences.length;
      this.singleMeta.page = this.params.page;
      this.singleMeta.hasMore = this.singleMeta.total > this.singleMeta.showing;
    } catch (err) {
      log.error(err);
      this.notification.error('Error loading audiences. Please try again.');
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  /**
   * Get metadata for Audience.  This is used to hide/show columns in the Audience List view
   */
  public async getMetadata() {
    try {
      const res = await this.acceo.get(AudienceMetadata)(this.audienceMetaUrl);

      this.currentMetadata = res;
    } catch (err) {
      if (err && err.status_code === 404) {
        this.metadataNotFound = true;
      }
      log.error(err);
      throw new Error(err);
    }
  }

  /**
   * Save metadata for Audience.
   */
  public async saveMetadata(metadata: any): Promise<AudienceMetadata> {
    try {
      this.isSaving = true;
      const data: any = classToPlain(metadata);
      const res = await this.acceo.put(AudienceMetadata)(this.audienceMetaUrl, data);
      return res;
    } catch (err) {
      log.error(err);
      throw new Error(err.message);
    }
  }

  /**
   * Removes all selected Audiences
   */
  public clearSelected() {
    this.selectedAudiences = [];
  }
}
