// tslint:disable: max-classes-per-file
import {Type} from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsDefined,
  IsInt,
  IsIP,
  IsNotEmpty,
  IsOptional,
  IsString,
  MaxLength,
  ValidateNested,
} from 'class-validator';
import {IHyperion, IMSOId, IMSOOwner, IMSOSession, IMSOTimestamp, IMSOTotalItems} from './mso';
import {IMSOSlicerTaskSlicer, IMSOSlicerTaskTask, MSOSlicerTaskSlicer, MSOSlicerTaskTask} from './task';
import {SlicerStreamStats} from './csl-slicers';

// Slicer common properties

interface IMSOSlicerChannelId {
  /**
   * (optional) The MSO Channel ID associated with this slicer
   */
  channel_id?: string;
}

interface IMSOSlicerClassification {
  /**
   * (optional) The classification of this slicer’s usage
   * (dev/demo/prod/poc)
   */
  classification?: string;
}

export enum MSOSlicerClassification {
  PRODUCTION = 'prod',
  PROOF_OF_CONCEPT = 'poc',
  DEVELOPMENT = 'dev',
  DEMO = 'demo',
  UNKNOWN = 'unknown',
}

export class MSOSlicerChangeLog {
  public action: string;
  public by_whom: string;
  public created: number;
}

interface IMSOSlicerConfiguration {
  /**
   * (optional) A live slicer configuration as either a KVP
   * or JSON formatted string. Ignored when default_slicer_config
   * is enabled
   */
  configuration?: string;
}

export interface IMSOSlicerId {
  /**
   * The 'slicerID' value from the configuration applied to this slicer.
   * A case-sensitive alphanumeric value by which this slicer will be identified.
   */
  slicer_id: string;
}

interface IMSOSlicerIngestPointId {
  /**
   * (optional) The MSO ID corresponding to the
   * Ingest Point for creating this slicer with
   */
  ingest_point_id: string;
}

interface IMSOSlicerName {
  /**
   * (optional) A customizable, user-friendly name for this MSO slicer
   */
  slicer_name?: string;
}

interface IMSOSlicerNotes {
  /**
   * (optional) The description/notes for this MSO slicer
   */
  notes?: string;
}

interface IMSOSlicerProfile {
  /**
   * The encoding profile JSON string returned from MSS
   * {profileId, fps, maxHeight}
   */
  profile?: string;
}

interface IMSOSlicerProfileId {
  /**
   * (optional) Uplynk id of an Encoding Profile
   */
  profile_id?: string;
}

export interface IMSOSlicerServerInstanceId {
  /**
   * UUID generated by the MSS system for later operations
   */
  server_instance_id: string;
}

interface IMSOSlicerServerRegionId {
  /**
   * The ID of the MSO Hosting Location assigned to this slicer
   */
  server_region_id: string;
}

interface IMSOSlicerSoftwareVersion {
  /**
   * The Slicer software version (i.e 20010700) this MSS slicer was deployed with
   */
  slicer_software_version?: string;
}

export interface IMSOSlicerStatus {
  /**
   * Current slicer status, may (or may not) be one of the following statuses:
   * NEW, INITIALIZING, INITIALIZED, CREATED, RUNNING, CHECKIN, FAILED, OFFLINE, DARK, UNKNOWN
   */
  status: string;
}

interface IMSOSlicerStreamInformation {
  /**
   * The secure URL this RTMP/RTMPS MSS slicer is streaming to
   */
  slicer_secure_stream_url?: string;

  /**
   * The stream key for this RTMP/RTMPS slicer (will be empty for RTP/UDP slicers)
   */
  slicer_stream_key?: string;

  /**
   * The URL this MSS slicer is streaming to
   * for RTP/UDP - udp://{domain}:{port}
   * for RTMP - rtmp://{domain}/{appName}
   * for SRT - srt://{domain}:{port}
   */
  slicer_stream_url: string;
}

interface IMSOSlicerStreamTypeId {
  /**
   * The ID of the MSO Stream Protocol for this slicer
   */
  stream_type_id: string;
}

interface IMSOSlicerManaged {
  /**
   * (optional) The managed state of this slicer
   */
  managed?: boolean;
}

// Slicer request: patch

interface IMSORequestPatch
  extends IMSOSlicerChannelId,
    IMSOSlicerClassification,
    IMSOSlicerConfiguration,
    IMSOSlicerName,
    IMSOSlicerManaged,
    IMSOSlicerNotes {}

export class MSOSlicerRequestPatch implements IMSORequestPatch {
  @IsOptional()
  @IsString()
  public channel_id?: string;

  @IsNotEmpty({message: 'Slicer Classification is required'})
  @IsOptional()
  @IsString()
  public classification?: string;

  @IsString()
  public configuration?: string;

  @IsOptional()
  @IsString()
  @MaxLength(256, {message: 'Exceeds $constraint1 character limit'})
  public notes?: string;

  @IsNotEmpty({message: 'Slicer Name is required'})
  @IsOptional()
  @IsString()
  @MaxLength(50, {message: 'Exceeds $constraint1 character limit'})
  public slicer_name?: string;

  @IsOptional()
  @IsString()
  public slicer_version?: string;

  @IsOptional()
  @IsBoolean()
  public managed?: boolean;
}

export class MSOSlicerRequestPatchErrors {
  public channel_id?: string;
  public classification?: string;
  public configuration?: string;
  public managed?: string;
  public notes?: string;
  public slicer_name?: string;
  public slicer_version?: string;
}

// Slicer request: post

interface IMSOSlicerRequestPost
  extends IMSORequestPatch,
    IMSOSlicerConfiguration,
    IMSOSlicerIngestPointId,
    IMSOSlicerProfileId,
    IMSOSlicerServerRegionId,
    IMSOSlicerStreamTypeId {
  /**
   * (optional) RTP/UDP - An IP that the system will be ready to
   * accept the stream from. When the slicer is deleted, the
   * security rule for this slicer will be deleted as well.
   *
   * RTMP/RTMPS - Not relevant
   */
  source_ip?: string;

  /**
   * (optional) MSO versionId of a Slicer Version
   */
  version_id?: string;
}

export class MSOSlicerRequestPost extends MSOSlicerRequestPatch implements IMSOSlicerRequestPost {
  @IsString()
  public configuration?: string;

  @IsString()
  public profile_id?: string;

  @IsDefined({message: 'Ingest Point is required'})
  @IsNotEmpty({message: 'Ingest Point is required'})
  @IsString()
  public ingest_point_id: string;

  @IsDefined({message: 'Server Region is required'})
  @IsString()
  @IsNotEmpty({message: 'Server Region is required'})
  public server_region_id: string;

  @IsIP(4, {message: 'Must be a valid IPv4 address'})
  @IsNotEmpty({message: 'Source IP Address is required'})
  @IsString()
  public source_ip?: string;

  @IsString()
  public slicer_plugin?: string;

  @IsString()
  public slicer_plugin_version?: string;

  @IsDefined({message: 'Streaming Protocol is required'})
  @IsNotEmpty({message: 'Streaming Protocol is required'})
  @IsString()
  public stream_type_id: string;

  @IsString()
  public version_id?: string;

  @IsOptional()
  @IsBoolean()
  public managed?: boolean;
}

export class MSOSlicerRequestPostErrors extends MSOSlicerRequestPatchErrors {
  public configuration?: string;
  public default_slicer_config?: string;
  public isolation_required?: string;
  public ingest_point_id?: string;
  public managed?: string;
  public server_region_id?: string;
  public slicer_id?: string;
  public source_ip?: string;
  public stream_type_id?: string;
}

// Slicer response: delete/patch/restart

interface IMSOSlicerResponse {
  /**
   * MSO slicer task information
   */
  task: IMSOSlicerTaskTask;
}

export class MSOSlicerResponse implements IMSOSlicerResponse, IHyperion, IMSOSession {
  @IsString()
  public '@id': string;

  @IsString()
  public '@type': string;

  @IsString()
  public cms_session_fingerprint: string;

  @IsString()
  public cms_session_id_fingerprint: string;

  @ValidateNested()
  @Type(() => MSOSlicerTaskTask)
  public task: MSOSlicerTaskTask;
}

// Slicer response: getById

interface IMSOSlicerResponseGetMeta
  extends IMSOSlicerChannelId,
    IMSOSlicerClassification,
    IMSOSlicerName,
    IMSOSlicerNotes,
    IMSOSlicerProfileId {}

class MSOSlicerResponseGetMeta implements IMSOSlicerResponseGetMeta {
  @IsOptional()
  @IsString()
  public channel_id?: string;

  @IsOptional()
  @IsString()
  public classification?: string;

  @IsOptional()
  @IsString()
  public notes?: string;

  @IsOptional()
  @IsString()
  public profile_id?: string;

  @IsOptional()
  @IsString()
  public slicer_name?: string;

  @IsOptional()
  @IsBoolean()
  public managed?: boolean;
}

interface IMSOSlicerResponseGetSlicerPorts {
  /**
   * The port to send the stream to
   */
  port: number;

  /**
   * A local port, not accessible from the internet,
   * used for monitoring by internal MSS services
   */
  api_port: number;

  /**
   * The port to access the Slicer API
   */
  ssl_port: number;
}

class MSOSlicerResponseGetSlicerPorts implements IMSOSlicerResponseGetSlicerPorts {
  @IsInt()
  public port: number;

  @IsInt()
  public api_port: number;

  @IsInt()
  public ssl_port: number;
}

interface IMSOSlicerResponseGetSlicer
  extends IMSOOwner,
    IMSOSlicerId,
    IMSOSlicerProfile,
    IMSOSlicerServerInstanceId,
    IMSOSlicerServerRegionId,
    IMSOSlicerSoftwareVersion,
    IMSOSlicerStatus,
    IMSOSlicerStreamInformation,
    IMSOSlicerStreamTypeId {
  /**
   * UTC time at which the MSS Slicer was created
   */
  creation_time: string;

  /**
   * The tag of the MSS slicer docker image this slicer was created with
   */
  docker_tag?: string;

  /**
   * The AWS EC2 instance the MSS slicer is running on
   */
  ec2_machine: string;

  /**
   * Indicates this slicer is linked to a production environment ('UPLYNK')
   * or staging environment ('DOWNLYNK')
   */
  environment: string;

  /**
   * Undocumented property inherited from MSS
   */
  FromArchive: boolean;

  /**
   * The MSS Docker Image ID used to run this slicer instance
   */
  server_image_id: string;

  /**
   * The AWS region where MSS created this slicer (i.e. 'us-west-2')
   */
  server_region: string;

  /**
   * The MSO account owner IDs associated with this slicer, following a
   * naming convention of 'MSO_{owner_id}_{real_owner_id}'
   */
  slicer_account_name: string;

  /**
   * HTTPS origin for this MSS slicer's API (https://{domain}:{port})
   */
  slicer_api_url: string;

  /**
   * The contents of the configuration file which was applied to this slicer,
   * formatted as a text string of colon separated key/value pairs and
   * new line delimiters
   */
  slicer_config: string;

  /**
   * The domain for this MSS slicer's API
   */
  slicer_domain_name: string;

  /**
   * (deprecated) Duplicate of slicer_id, MSS is in the process of deprecating this
   */
  slicer_name: string;

  /**
   * The port configurations for this MSS slicer
   */
  slicer_ports: IMSOSlicerResponseGetSlicerPorts;

  /**
   * The MSS label for the version this slicer was deployed with
   */
  slicer_version: string;

  /**
   * RTP/UDP - An IP that the system will be ready to accept the stream from.
   * When the slicer is deleted, the security rule for this slicer will be deleted as well.
   *
   * RTMP/RTMPS - Not relevant
   */
  source_ip_address: string;
}

class MSOSlicerResponseGetSlicer implements IMSOSlicerResponseGetSlicer {
  @IsString()
  public creation_time: string;

  @IsOptional()
  @IsString()
  public docker_tag?: string;

  @IsString()
  public ec2_machine: string;

  @IsString()
  public environment: string;

  @IsBoolean()
  public 'FromArchive': boolean;

  @IsString()
  public owner: string;

  @IsString()
  public profile: string;

  @IsString()
  public server_image_id: string;

  @IsString()
  public server_instance_id: string;

  @IsString()
  public server_region: string;

  @IsString()
  public server_region_id: string;

  @IsString()
  public slicer_account_name: string;

  @IsString()
  public slicer_api_url: string;

  @IsString()
  public slicer_config: string;

  @IsString()
  public slicer_domain_name: string;

  @IsString()
  public slicer_id: string;

  @IsString()
  public slicer_plugin: string;

  @IsString()
  public slicer_plugin_version: string;

  @IsString()
  public slicer_name: string;

  @ValidateNested()
  @Type(() => MSOSlicerResponseGetSlicerPorts)
  public slicer_ports: MSOSlicerResponseGetSlicerPorts;

  @IsString()
  public slicer_version: string;

  @IsString()
  @IsOptional()
  public slicer_secure_stream_url?: string;

  @IsOptional()
  @IsString()
  public slicer_software_version?: string;

  @IsString()
  @IsOptional()
  public slicer_stream_key?: string;

  @IsString()
  public slicer_stream_url: string;

  @IsString()
  public source_ip_address: string;

  @IsString()
  public status: string;

  @IsString()
  public stream_type_id: string;
}

interface IMSOSlicerResponseGet {
  /**
   * Optional MSO slicer related data
   */
  meta?: MSOSlicerResponseGetMeta;

  /**
   * Standard MSO slicer related data
   */
  slicer: MSOSlicerResponseGetSlicer;
}

export class MSOSlicerResponseGet implements IMSOSlicerResponseGet, IHyperion, IMSOSession {
  @IsString()
  public '@id': string;

  @IsString()
  public '@type': string;

  @IsString()
  public cms_session_fingerprint: string;

  @IsString()
  public cms_session_id_fingerprint: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => MSOSlicerResponseGetMeta)
  public meta?: MSOSlicerResponseGetMeta;

  @ValidateNested()
  @Type(() => MSOSlicerResponseGetSlicer)
  public slicer: MSOSlicerResponseGetSlicer;
}

// Slicer response: getByOwner

export interface IMSOSlicerResponseGetByOwnerItem
  extends IHyperion,
    IMSOId,
    IMSOTimestamp,
    IMSOSlicerChannelId,
    IMSOSlicerClassification,
    IMSOSlicerId,
    IMSOSlicerName,
    IMSOSlicerNotes,
    IMSOSlicerProfile,
    IMSOSlicerServerInstanceId,
    IMSOSlicerServerRegionId,
    IMSOSlicerSoftwareVersion,
    IMSOSlicerStatus,
    IMSOSlicerStreamInformation,
    IMSOSlicerStreamTypeId {}

export class MSOSlicerResponseGetByOwnerItem implements IMSOSlicerResponseGetByOwnerItem {
  @IsString()
  public '@id': string;

  @IsString()
  public '@type': string;

  @IsOptional()
  @IsString()
  public channel_id?: string;

  @IsOptional()
  @IsArray()
  public change_log?: MSOSlicerChangeLog[];

  @IsOptional()
  @IsString()
  public classification?: string;

  @IsString()
  public created: string;

  @IsString()
  @IsOptional()
  public deleted?: string;

  @IsString()
  public id: string;

  @IsString()
  public lastmod: string;

  @IsOptional()
  @IsString()
  public notes?: string;

  @IsOptional()
  @IsString()
  public profile?: string;

  @IsString()
  public server_instance_id: string;

  @IsString()
  public server_region_id: string;

  @IsString()
  public slicer_id: string;

  @IsOptional()
  @IsString()
  public slicer_name?: string;

  @IsString()
  @IsOptional()
  public slicer_secure_stream_url?: string;

  @IsOptional()
  @IsString()
  public slicer_software_version?: string;

  @IsString()
  @IsOptional()
  public slicer_stream_key?: string;

  @IsString()
  public slicer_stream_url: string;

  @IsString()
  public slicer_version: string;

  @IsString()
  public status: string;

  @IsString()
  public stream_type_id: string;

  @IsOptional()
  @IsString()
  public ingest_point_id?: string;
}

interface IMSOSlicerResponseGetByOwner {
  /**
   * An array of MSO slicer items which use the "get by owner" schema
   */
  items: IMSOSlicerResponseGetByOwnerItem[];
}

export class MSOSlicerResponseGetByOwner
  implements IMSOSlicerResponseGetByOwner, IHyperion, IMSOTotalItems, IMSOSession
{
  @IsString()
  public '@id': string;

  @IsString()
  public '@type': string;

  @IsString()
  public cms_session_fingerprint: string;

  @IsString()
  public cms_session_id_fingerprint: string;

  @ValidateNested()
  @Type(() => MSOSlicerResponseGetByOwnerItem)
  public items: MSOSlicerResponseGetByOwnerItem[];

  @IsInt()
  public total_items: number;
}

// Slicer response: post

interface IMSOSlicerResponsePost {
  /**
   * The MSO ID for the slicer being created
   */
  server_id: string;

  /**
   * A small subset of MSO slicer data related to this creation task
   */
  slicer: IMSOSlicerTaskSlicer;
}

export class MSOSlicerResponsePost extends MSOSlicerResponse implements IMSOSlicerResponsePost {
  @IsString()
  public server_id: string;

  @ValidateNested()
  @Type(() => MSOSlicerTaskSlicer)
  public slicer: MSOSlicerTaskSlicer;
}

export class MSOSlicerHealth {
  @IsInt()
  public buffer: number;

  @IsInt()
  public jitter: number;

  @IsInt()
  public maxkeepaway: number;
}

export class MSOSlicerHealthMonitor extends SlicerStreamStats {}

export class MSOSlicerHealthMonitorResponseGet implements IHyperion, IMSOSession {
  @IsString()
  public '@id': string;

  @IsString()
  public '@type': string;

  @IsString()
  public cms_session_fingerprint: string;

  @IsString()
  public cms_session_id_fingerprint: string;

  @IsOptional()
  @Type(() => MSOSlicerHealthMonitor)
  public session: MSOSlicerHealthMonitor;

  @IsOptional()
  @Type(() => MSOSlicerHealthMonitor)
  public recent: MSOSlicerHealthMonitor;
}

export class MSOSlicerLogResponseGet implements IHyperion, IMSOSession {
  @IsString()
  public '@id': string;

  @IsString()
  public '@type': string;

  @IsString()
  public cms_session_fingerprint: string;

  @IsString()
  public cms_session_id_fingerprint: string;

  @IsString()
  public slicerlog: string;
}
