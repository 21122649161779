exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2JFkz{align-items:center;background-color:#161616;background-color:var(--c_soot);display:flex;justify-content:center;overflow:hidden;position:relative;text-align:center}._2JFkz ._9xD29,._2JFkz c-button,._2JFkz img{position:absolute}._2JFkz:hover c-button{opacity:1}._2JFkz c-button{align-self:flex-start;left:50%;margin-top:8px;opacity:0;transform:translateX(-50%);z-index:8}._2JFkz c-button,._2JFkz img{transition:opacity .3s ease-in-out;transition:opacity var(--trans_main)}._2JFkz img{width:100%;z-index:7}._9xD29{width:100%;z-index:6}._2JFkz uplynk-player>div{height:0;padding-top:56.25%;position:relative}._2JFkz uplynk-player>div iframe{height:100%;left:0;position:absolute;top:0;width:100%}._2vK8Z{height:0;padding-top:56.25%}.t9uAR{opacity:0}._2evIy{opacity:1}._1sYX8{height:5px;position:absolute;top:0;transition:opacity .3s ease-in-out;transition:opacity var(--trans_main);width:100%;z-index:9}", ""]);

// exports
exports.locals = {
	"container": "_2JFkz",
	"playerContainer": "_9xD29",
	"spacer": "_2vK8Z",
	"goodbye": "t9uAR",
	"hello": "_2evIy",
	"status": "_1sYX8"
};