import {Type} from 'class-transformer';
import {IsInt, IsString, ValidateNested} from 'class-validator';
import {IHyperion, IMSOSession, IMSOTotalItems} from './mso';
import {IMSOSlicerServerInstanceId, IMSOSlicerStatus} from './msoslicer';

// Slicer Task common properties

export interface IMSOSlicerTaskSlicer extends IMSOSlicerServerInstanceId, IMSOSlicerStatus {
    /**
     * The 'slicerID' value from the configuration applied to this slicer.
     * A case-sensitive alphanumeric value by which this slicer will be identified.
     */
    id: string;
}

export class MSOSlicerTaskSlicer implements IMSOSlicerTaskSlicer {
    @IsString()
    public id: string;

    @IsString()
    public server_instance_id: string;

    @IsString()
    public status: string;
}

export interface IMSOSlicerTaskTask {
    /**
     * Slicer Task ID from MSS
     */
    id: string;

    /**
     * Slicer Task Type
     */
    operation: string;

    /**
     * Current Slicer Task state (IN_PROGRESS typically)
     */
    state: string;
}

export class MSOSlicerTaskTask implements IMSOSlicerTaskTask {
    @IsString()
    public id: string;

    @IsString()
    public operation: string;

    @IsString()
    public state: string;
}

interface IMSOSlicerTaskTimestamp {
    /**
     * MSO Slicer Task timestamp
     */
    timestamp: number;
}

// Slicer Task response: get/getById

class MSOSlicerTaskResponseItem implements IMSOSlicerTaskTask, IMSOSlicerTaskTimestamp {
    @IsString()
    public id: string;

    @IsString()
    public operation: string;

    @IsString()
    public state: string;

    @IsInt()
    public timestamp: number;
}

export class MSOSlicerTaskResponseItems implements IHyperion, IMSOTotalItems, IMSOSession {
    @IsString()
    public '@id': string;

    @IsString()
    public '@type': string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsString()
    public cms_session_id_fingerprint: string;

    @ValidateNested()
    @Type(() => MSOSlicerTaskResponseItem)
    public items: MSOSlicerTaskResponseItem[];

    @IsInt()
    public total_items: number;
}
