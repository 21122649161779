import {CToastsService} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';

import {DialogService} from 'aurelia-dialog';
import {PLATFORM} from 'aurelia-pal';
import {IsArray, IsNumber, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class NewsItems {
    /*
     * Public Properties
     */

    public currentNewsItems = [];

    public newsCols = [
        {
            colAction: row => this.openNewsItem(row),
            colClass: 't240',
            colHeadName: 'title',
            colHeadValue: 'Title',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-action/c-td-action',
            ),
        },

        {
            colClass: 't120',
            colHeadName: 'date',
            colHeadValue: 'Date',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-pill/c-td-pill.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-pill/c-td-pill'),
        },
        {
            colClass: 't150',
            colHeadName: 'tags',
            colHeadValue: 'Tags',
            view: PLATFORM.moduleName('apps/acuity/panels/news-items/multiple-td-pill/index.html'),
            viewModel: PLATFORM.moduleName('apps/acuity/panels/news-items/multiple-td-pill/index'),
        },
        {
            colHeadName: 'detailHTML',
            colHeadValue: 'Detail HTML',
        },
        {
            colAction: row => this.editNewsItemModal(row),
            colHeadName: 'action',
            colHeadValue: 'Action',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-button/c-td-button.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-button/c-td-button',
            ),
        },
    ];

    public newsRows = [];
    public tag_options = [];

    constructor(public acceo: Acceo, public notification: CToastsService, public dialogService: DialogService) {
        this.getNewsItems();
    }

    /*
     * Public Methods
     */

    public async getNewsItems() {
        this.newsRows = [];
        const url: string = '/ops/newsitems/get';
        const resp = await this.acceo.get(Response)(url);
        if (resp.error === 1) {
            this.notification.error('Failed to retrieved current news items.');
        } else {
            this.notification.success('Successfully retrieved current news items.');

            resp.items.forEach(item => {
                let publishedState = '';
                let publishedColor = '';
                if (!item.published) {
                    publishedState = 'UnPublished';
                    publishedColor = 'var(--c_subTwoMain)';
                }
                this.newsRows.push({
                    action: 'Modify',
                    date: item.date,
                    datePill: publishedState,
                    datePillColor: publishedColor,
                    datePillRight: true,
                    detailHTML: item.detail_html,
                    id: item.id,
                    published: item.published,
                    tags: item.tags,
                    tagsPill: item.tags,
                    tagsPillColor: 'var(--c_secondaryMain)',
                    title: item.title,
                    titleIcon: 'search',
                    titleIconSize: '10px',
                });
            });

            const optionsUrl: string = '/ops/newsitemtagoptions/get';
            const optionsResp = await this.acceo.get(Response)(optionsUrl);
            this.tag_options = optionsResp.options;
        }
    }

    public async editNewsItemModal(row) {
        const ind = this.currentNewsItems.findIndex(item => item.id === row.id);

        const filterTagOptions = [];

        this.tag_options.forEach(tag => {
            if (!row.tagsPill.includes(this.tag_options[tag])) {
                filterTagOptions.push(this.tag_options[tag]);
            }
        });

        const modalValue = {
            date: row.date,
            delete: false,
            detailHTML: row.detailHTML,
            id: row.id,
            index: ind,
            published: row.published,
            tag_options: filterTagOptions,
            tags: row.tagsPill,
            title: row.title,
        };

        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        inputValues: modalValue,
                    },
                    bodyViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/news-items/modify-body',
                    ),
                    footerEnable: true,
                    footerViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/news-items/modify-footer',
                    ),
                    size: 'large',
                    title: `Modifying "${row.title}"`,
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed()
            .then(response => {
                this.submitNewsItemModal(response.output, modalValue);
            });
    }

    public async submitNewsItemModal(output, modalValue) {
        if (output === true && modalValue.delete === false) {
            const url: string = '/ops/newsitem/edit';
            try {
                const resp = await this.acceo.post(Response)(url, {
                    detail_html: modalValue.detailHTML,
                    id: modalValue.id,
                    published: modalValue.published,
                    tags: modalValue.tags,
                    title: modalValue.title,
                });
                if (resp.error === 0) {
                    this.notification.success(`${modalValue.title} (${modalValue.id} has been updated.)`);
                } else {
                    this.notification.error('API returned an error.');
                }
            } catch (err) {
                this.notification.error(err);
            }
            await this.getNewsItems();
        } else if (output === true && modalValue.delete === true) {
            const url: string = '/ops/newsitem/delete';
            const params = $.param({
                detail_html: modalValue.detailHTML,
                id: modalValue.id,
                published: modalValue.published,
                tags: modalValue.tags,
                title: modalValue.title,
            });

            try {
                const resp = await this.acceo.post(Response)(url, params);
                if (resp.error === 0) {
                    this.notification.success(`${modalValue.title} (${modalValue.id} has been deleted.)`);
                } else {
                    this.notification.error('API returned an error.');
                }
            } catch (err) {
                this.notification.error(err);
            }
            await this.getNewsItems();
        }
    }

    public async initiateNewsItem() {
        const modalValue = {
            date: '',
            detailHTML: '',
            published: true,
            tag_options: this.tag_options,
            tags: [],
            title: '',
        };

        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        inputValues: modalValue,
                    },
                    bodyViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/news-items/create-body',
                    ),
                    footerEnable: true,
                    footerViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/news-items/create-footer',
                    ),
                    size: 'large',
                    title: 'Creating News Item',
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed()
            .then(response => {
                this.createNewsItemModal(response.output, modalValue);
            });
    }

    public async createNewsItemModal(output, modalValue) {
        if (output === true) {
            const url: string = '/ops/newsitem/create';
            const params = $.param({
                detail_html: modalValue.detailHTML,
                tags: modalValue.tags,
                title: modalValue.title,
            });
            try {
                const resp = await this.acceo.post(Response)(url, params);
                if (resp.error === 0) {
                    this.notification.success(`${modalValue.title} has been created.`);
                } else {
                    this.notification.error('API returned an error.');
                }
            } catch (err) {
                this.notification.error(err);
            }
            await this.getNewsItems();
        }
    }

    public openNewsItem(row) {
        window.open(`/static/cms/news.html?newsitemid=${row.id}`, '_blank').focus();
    }
}

export class Response {
    @IsArray()
    public items: any[] = [];

    @IsNumber()
    public error: any;

    @IsArray()
    public options: any[] = [];

    @IsString()
    public msg: string = '';
}
