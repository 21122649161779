/* eslint-disable max-classes-per-file */

import {Type} from 'class-transformer';
import {IsArray, IsNumber, IsOptional, IsString, ValidateNested, IsBoolean} from 'class-validator';
import {IKeyValue} from 'interfaces/key-value';

interface IRay {
  ray: string;
  max_w: number;
  max_h: number;
  v_kbps: number;
  a_kbps: number;
}

interface IProfile {
  bools: IKeyValue<boolean>;
  desc: string;
  id: string;
  rays: IRay[];
  transition_to: string;
}

interface ILiveProfile {
  profile: string;
  slicer: string;
}

interface IPricingBreakdown {
  id: number;
  hours: string;
  cost: string;
  label: string;
}

interface IBreakdown {
  breakdown: IPricingBreakdown[];
}

interface IPricing {
  created: number;
  default: boolean;
  desc: string;
  encoding: IBreakdown;
  id: string;
  lastmod: number;
  num_owners: string;
}

export interface IOwnerLimited {
  id: string;
  ids_email?: string;
  ids_username?: string;
  ids_domain_id?: string;
  username: string;
}

export interface IOwner extends IOwnerLimited {
  account_state: string;
  ad_timeouts: object;
  billing_balance: number;
  billing_contacts: string;
  billing_moocher: number;
  brokerGroup: string;
  brokerGroups: string[];
  cloudProvider: string;
  cloudProviders: string[];
  cms_entitlements: IKeyValue<boolean>;
  cms_flags: IKeyValue<string>;
  cms_su_entitlements: IKeyValue<boolean>;
  contact_emails: string;
  created: string;
  deadbeat: number;
  default_live_profile: IProfile;
  default_profile: IProfile;
  deleted: string;
  demo_expires?: boolean;
  lastmod?: string;
  live_profiles?: ILiveProfile;
  note?: string;
  ops_entitlements: IKeyValue<boolean>;
  permissions: IKeyValue<boolean>;
  pricing?: IPricing;
  secureStorageEnabled?: boolean;
  sharing_enabled?: number;
  slicer_profiles?: IKeyValue<IProfile>;
  studiodrm?: number;
  syndicationEnabled?: number;
}

export class Owner implements IOwner {
  @IsString()
  public account_state: string;

  @ValidateNested()
  public ad_timeouts: object;

  @IsNumber()
  public billing_balance: number;

  @IsString()
  public billing_contacts: string;

  @IsNumber()
  public billing_moocher: number;

  @IsString()
  public brokerGroup: string;

  @IsArray()
  public brokerGroups: string[];

  @IsString()
  public cloudProvider: string;

  @IsArray()
  public cloudProviders: string[];

  @ValidateNested()
  public cms_entitlements: IKeyValue<boolean>;

  @ValidateNested()
  public cms_flags: IKeyValue<string>;

  @ValidateNested()
  public cms_su_entitlements: IKeyValue<boolean>;

  @IsString()
  public contact_emails: string;

  @IsString()
  public created: string;

  @IsNumber()
  public deadbeat: number;

  @ValidateNested()
  // @Type(() => IProfile)
  // @Exclude({toPlainOnly: true})
  public default_live_profile: IProfile;

  @ValidateNested()
  // @Type(() => IProfile)
  // @Exclude({toPlainOnly: true})
  public default_profile: IProfile;

  @IsString()
  public deleted: string;

  @IsBoolean()
  public demo_expires?: boolean;

  @IsString()
  public id: string;

  @IsOptional()
  @IsString()
  public ids_email?: string;

  @IsOptional()
  @IsString()
  public ids_username?: string;

  @IsOptional()
  @IsString()
  public ids_domain_id?: string;

  @IsString()
  @IsOptional()
  public lastmod?: string;

  @ValidateNested()
  // @Type(() => IProfile)
  // @Exclude({toPlainOnly: true})
  public live_profiles: ILiveProfile;

  @IsString()
  public note: string;

  @ValidateNested()
  public ops_entitlements: IKeyValue<boolean>;

  @ValidateNested()
  public permissions: IKeyValue<boolean>;

  @ValidateNested()
  // @Type(() => IProfile)
  // @Exclude({toPlainOnly: true})
  public pricing: IPricing;

  @IsBoolean()
  public secureStorageEnabled?: boolean;

  @IsNumber()
  public sharing_enabled: number;

  @ValidateNested()
  public slicer_profiles: IKeyValue<IProfile>;

  @IsNumber()
  public studiodrm: number;

  @IsNumber()
  public syndicationEnabled: number;

  @IsString()
  public username: string;
}

export class OwnerLimited implements IOwnerLimited {
  @IsString()
  public id: string;

  @IsString()
  @IsOptional()
  public ids_email?: string;

  @IsString()
  @IsOptional()
  public ids_username?: string;

  @IsString()
  public username: string;
}

export class OwnerResponse {
  @ValidateNested()
  @Type(() => Owner)
  public owners: Owner[];
}
export class OwnerLimitedResponse {
  @ValidateNested()
  @Type(() => OwnerLimited)
  public items: OwnerLimited[];

  @IsNumber()
  public total_items: number;
}
