import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {IsArray, IsDefined, IsString} from 'class-validator';
import {Acceo} from 'services/acceo';

@autoinject
export class LowLatency {
    /*
     * Computed Properties
     */

    @computedFrom('state')
    get searchState() {
        return this.state === 'searching' ? 'disabled' : '';
    }

    /*
     * Private Properties
     */

    public selectedOwner;
    private username: string = '';
    private owners: any[] = [];
    private selectedValue: string = '';
    private origOwners: any[] = [];
    private ownerStates: string[] = [];
    private state: string = 'idle';
    public defaultProfile = {};
    public defaultLiveProfile;
    private fastpath;
    private use_brokers;
    private slicer_use_brokers;
    private use_brokers_override;

    public mappingCols = [
        {
            colHeadName: 'slicer',
            colHeadValue: 'Slicer ID',
            sort: true,
        },
        {
            colHeadName: 'broker_setting',
            colHeadValue: 'Broker Setting',
            sort: true,
        },
        {
            colClass: 't30',
            colHeadName: 'delete',
            colHeadValue: '',
            view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
            viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
        },
    ];

    private mappingRows = [];
    private mappingSlicerIdList = '';
    private saveState = 'save';

    private broker_setting_options = [
        {
            text: '1: HTTP connection per slice, no websocket (DEFAULT)',
            value: '1',
        },
        {
            text: '2: Websocket',
            value: '2',
        },
        {
            text: '3: Encrypted websocket',
            value: '3',
        },
        {
            text: '4: Frame-by-Frame slicer->broker',
            value: '4',
        },
        {
            text: '5: Frame-by-Frame broker->encoder',
            value: '5',
        },
    ];

    public details_url = '';
    public assign_url = '';

    // *** Channel data
    public channelCols = [
        {
            colHeadName: 'id',
            colHeadValue: 'Channel ID',
            sort: true,
        },
        {
            colHeadName: 'desc',
            colHeadValue: 'Channel Description',
            sort: true,
        },
        {
            colHeadName: 'delay',
            colHeadValue: 'Delay',
            sort: true,
        },
        {
            colHeadName: 'target_duration',
            colHeadValue: 'Target Duration',
            sort: true,
        },
        {
            colAction: row => this.openChannelModal(row),
            colClass: 't30',
            colHeadName: 'edit',
            colHeadValue: '',
            view: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-button/c-td-button.html',
            ),
            viewModel: PLATFORM.moduleName(
                '@bindable-ui/bindable/components/tables/td-contents/c-td-button/c-td-button',
            ),
        },
    ];

    public channelRows = [];
    private channelInfo: any[] = [];

    // *** Live Event data
    private live_event_guid: string = '';
    private event: any[] = [];
    private eventInfo: any[] = [];

    constructor(private acceo: Acceo, private notification: CToastsService, public dialogService: DialogService) {}

    /*
     * Public Methods
     */
    public async searchUsername() {
        this.state = 'searchingOwners';
        this.selectedValue = '';
        if (!this.username) {
            this.username = '';
            this.state = 'idle';
            this.notification.error('No email/username entered.');
        } else {
            const url: string = '/ops/llowners';
            const params = $.param({username: this.username, account_state: 'active'});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.owners = resp.owners;
                if (this.owners.length !== 0) {
                    this.origOwners = _.cloneDeep(resp.owners);
                    for (let i = 0; i < this.owners.length; i += 1) {
                        this.ownerStates[i] = 'clean';
                    }
                    this.state = this.owners.length ? 'hasData' : 'not-found';
                    this.selectedAccount(0);
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching owner for ${this.username}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'idle';
            }
        }
    }

    public async selectedAccount(ownerIndex) {
        this.selectedValue = this.owners[ownerIndex].username;
        this.selectedOwner = this.owners[ownerIndex];
        this.defaultProfile = this.selectedOwner.default_profile;
        this.defaultLiveProfile = this.selectedOwner.default_live_profile;
        this.fastpath = this.selectedOwner.fastpath;
        this.use_brokers = this.selectedOwner.use_brokers;
        this.state = 'accountDataSelected';
        this.details_url = this.selectedOwner.details_url;
        this.assign_url = this.selectedOwner.assign_url;
        this.mappingRows = this.selectedOwner.use_brokers_override;

        // Channel data
        this.channelRows = this.selectedOwner.channels;
    }

    // *** Owner functions
    public async submitMappingModal(output, modalValue, activeOwner) {
        if (output) {
            this.slicer_use_brokers = modalValue.slicer_use_brokers;
            this.mappingSlicerIdList = modalValue.slicerIdList.trim().split(/\s*,\s*/);
            for (const value of this.mappingSlicerIdList) {
                this.mappingRows.push({slicer: value, broker_setting: this.slicer_use_brokers});
                this.use_brokers_override = this.mappingRows;
            }
            this.saveOwnerChanges(activeOwner);
        }
    }

    public async openMappingModal() {
        const modalValue = {
            broker_setting_options: this.broker_setting_options,
            slicerIdList: '',
            slicer_use_brokers: '',
        };
        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        inputValues: modalValue,
                    },
                    bodyViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/low-latency/mapping',
                    ),
                    footerEnable: true,
                    footerViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/low-latency/mapping-footer',
                    ),
                    size: 'auto',
                    title: 'Create new slicer/broker mapping',
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed()
            .then(response => {
                this.submitMappingModal(response.output, modalValue, this.selectedValue);
            });
    }

    public async deleteMapping(activeOwner, rows) {
        for (let i = 0; i < rows.length; i += 1) {
            if (rows[i].delete === true) {
                this.mappingRows.splice(i, 1);
                this.use_brokers_override = this.mappingRows;
            }
        }

        this.saveState = 'delete';
        await this.saveOwnerChanges(activeOwner);
    }

    public async updateSearchUsername(ownerIndex) {
        const url: string = '/ops/llowners';
        const params = $.param({username: this.username, account_state: 'active'});
        try {
            const resp = await this.acceo.get(Response)([url, params].join('?'));
            this.owners = resp.owners;
            if (this.owners) {
                await this.selectedAccount(ownerIndex);
                this.state = this.owners.length ? 'accountDataSelected' : 'not-found';
            }
        } catch (err) {
            this.notification.error(err);
            this.state = 'idle';
        }
    }

    public async saveOwnerChanges(activeOwner) {
        if (this.saveState === 'delete') {
            this.notification.info(`Attempting to delete mappings for "${this.selectedOwner.username}".`);
        } else {
            this.notification.info(`Attempting to save "${this.selectedOwner.username}".`);
        }
        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }

        this.owners[index].fastpath = this.fastpath;
        this.owners[index].use_brokers = this.use_brokers;
        this.owners[index].use_brokers_override = this.use_brokers_override;

        const owner: any = this.owners[index];
        const originalOwner: any = this.origOwners[index];
        if (_.isEqual(owner, originalOwner)) {
            this.ownerStates[index] = 'clean';
        } else {
            this.ownerStates[index] = 'dirty';
        }

        if (this.ownerStates[index] === 'dirty') {
            this.origOwners[index] = this.owners[index];
            const url = `/ops/llowners/save/${this.origOwners[index].id}`;
            try {
                await this.acceo.post()(url, this.origOwners[index]);
                this.notification.success('Successfully updated owner record.');
            } catch (err) {
                this.notification.error(`Error updating owner record. ${err}`);
            }

            await this.updateSearchUsername(index);
        } else {
            this.notification.info('No modified data for owner record. Not updating.');
        }
    }

    public async cancelChanges(activeOwner) {
        this.notification.info(`Cancelling changes to "${this.selectedOwner.username}".`);
        let index;
        for (let i = 0; i < this.owners.length; i += 1) {
            if (this.owners[i].username === activeOwner) {
                index = i;
            }
        }

        await this.updateSearchUsername(index);
    }

    // *** Channel functions
    public async submitChannelModal(output, modalValue) {
        this.channelInfo = modalValue;

        if (output) {
            // Call save endpoint
            const url = `/ops/llchannels/save/${modalValue.channel_id}`;
            try {
                await this.acceo.post()(url, this.channelInfo);
                this.notification.success('Successfully updated owner record.');
            } catch (err) {
                this.notification.error(`Error updating owner record. ${err}`);
            }

            // Update front-end
            let index;
            for (let i = 0; i < this.owners.length; i += 1) {
                if (this.owners[i].username === modalValue.activeOwner) {
                    index = i;
                }
            }
            await this.updateSearchUsername(index);
        }
    }

    public async openChannelModal(row) {
        const modalValue = {
            activeOwner: this.selectedValue,
            channel_delay: row.delay,
            channel_desc: row.desc,
            channel_id: row.id,
            channel_target_duration: row.target_duration,
        };
        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        inputValues: modalValue,
                    },
                    bodyViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/low-latency/channel',
                    ),
                    footerEnable: true,
                    footerViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/low-latency/channel-footer',
                    ),
                    size: 'auto',
                    title: 'Edit Channel low latency settings',
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed()
            .then(response => {
                this.submitChannelModal(response.output, modalValue);
            });
    }

    // *** Live Event functions
    public async searchEvents() {
        if (!this.live_event_guid) {
            this.live_event_guid = '';
            this.state = 'idle';
            this.notification.error('No guid entered.');
        } else {
            const url: string = '/ops/llevents';
            const params = $.param({guid: this.live_event_guid, owner_id: this.selectedOwner.id});
            try {
                const resp = await this.acceo.get(Response)([url, params].join('?'));
                this.event = resp.event;
                if (this.event) {
                    this.openEventModal(this.event);
                    this.state = 'accountDataSelected';
                } else {
                    this.state = 'idle';
                    this.notification.warning(`Couldn't find a matching event for ${this.live_event_guid}`);
                }
            } catch (err) {
                this.notification.error(err);
                this.state = 'accountDataSelected';
            }
        }
    }

    public async submitEventModal(output, modalValue) {
        this.eventInfo = modalValue;

        if (output) {
            // Call save endpoint
            const url = `/ops/llevents/save/${modalValue.event_id}`;
            try {
                await this.acceo.post()(url, this.eventInfo);
                this.notification.success('Successfully updated owner record.');
            } catch (err) {
                this.notification.error(`Error updating owner record. ${err}`);
            }

            // Update front-end
            let index;
            for (let i = 0; i < this.owners.length; i += 1) {
                if (this.owners[i].username === modalValue.activeOwner) {
                    index = i;
                }
            }
            await this.updateSearchUsername(index);
        }
    }

    public async openEventModal(row) {
        const modalValue = {
            activeOwner: this.selectedValue,
            event_delay: row.delay,
            event_desc: row.desc,
            event_id: row.id,
            event_target_duration: row.target_duration,
        };
        this.dialogService
            .open({
                model: {
                    bodyModel: {
                        inputValues: modalValue,
                    },
                    bodyViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/low-latency/event',
                    ),
                    footerEnable: true,
                    footerViewModel: PLATFORM.moduleName(
                        'apps/acuity/templates/components/modals/modal/low-latency/event-footer',
                    ),
                    size: 'auto',
                    title: 'Edit Live Event low latency settings',
                },
                viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
            })
            .whenClosed()
            .then(response => {
                this.live_event_guid = '';
                this.submitEventModal(response.output, modalValue);
            });
    }
}

export class Response {
    @IsString()
    public cms_session_id_fingerprint: string;

    @IsString()
    public cms_session_fingerprint: string;

    @IsArray()
    public owners: any[] = [];

    @IsArray()
    public profiles: any[] = [];

    @IsDefined()
    public event: any[] = [];
}
