import {bindable, computedFrom} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {PrebidConfig} from '../models/prebid-config';

export class PrebidServerDetails {
    @bindable public callbacks;
    @bindable public config: PrebidConfig;
    @bindable public configPristine: PrebidConfig;
    @bindable public isNew: boolean;
    @bindable public validationErrors: ValidationError[];

    public getStateError(key) {
        let state = '';
        if (Array.isArray(this.validationErrors)) {
            _.forEach(this.validationErrors, error => {
                if (error.property === key) {
                    state = 'error';
                }
            });
        }
        return state;
    }

    @computedFrom('validationErrors')
    public get getNameState() {
        return this.getStateError('name');
    }

    @computedFrom('validationErrors')
    public get getPodConfigIdState() {
        return this.getStateError('podConfigConfigid');
    }

    @computedFrom('validationErrors')
    public get getUrlState() {
        return this.getStateError('url');
    }
}
