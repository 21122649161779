import {EventAggregator, Subscription} from 'aurelia-event-aggregator';
import {autoinject, BindingEngine, containerless, LogManager} from 'aurelia-framework';
import {ValidationError} from 'class-validator';
import {dirtyCheckPrompt} from 'decorators';
import {Notification} from 'resources/notification/service';
import {Util} from 'services/util';
import {
    ContentKeySpec,
    DRMConfig,
    Fairplay,
    PlayReady,
    PolicyOverrides,
    RequiredOutputProtection,
    Widevine,
} from './models/drm-config';
import {DRMConfigService} from './services/drm-config-service';
import {DRMConfigUtil} from './services/drm-config-util';

const log = LogManager.getLogger('drm-config-page');

const TRACK_TYPES = ['ALL', 'ALL_VIDEO', 'AUDIO', 'HD', 'SD', 'UHD1', 'UHD2'];

export const DRM_CONFIG_EVENT = {
    Reload: 'DRMConfigEvent:Reload',
};

@containerless
@dirtyCheckPrompt
@autoinject()
export class DRMConfigPage {
    public callbacks;
    public addTip;
    public deleteTip;
    public canDeactivate;
    public isLoading = false;
    public loadingError = false;
    public isAdding = false;
    public isDeleting = false;
    public isSaving = false;
    public isFormDirty = false;
    public configs: DRMConfig[];
    public config: DRMConfig;
    public configPristine: DRMConfig;
    public configIndex: number;
    public fairplay: Fairplay;
    public playready: PlayReady;
    public contentKeySpecs: ContentKeySpec[];
    public newContentKeySpec: ContentKeySpec;
    public policyOverrides: PolicyOverrides;
    public newConfig: DRMConfig;
    public newConfigErrors: ValidationError[];
    public configErrors: ValidationError[];
    public tabs;
    public subscriptions: Subscription[] = [];
    public disabledTrackTypes = {};

    private debouncedDirtyCheck = _.debounce(this.isDirty, 100);

    constructor(
        public bindingEngine: BindingEngine,
        public eventAggregator: EventAggregator,
        public notification: Notification,
        public drmConfigService: DRMConfigService,
    ) {
        this.tabs = [
            {id: 'fairplay', label: 'FairPlay', selected: true},
            {id: 'play-ready', label: 'PlayReady'},
            {id: 'widevine', label: 'Widevine'},
        ];
        this.callbacks = {
            onChange: () => {
                this.debouncedDirtyCheck();
            },
        };
        this.newConfigInit();
        this.isLoading = true;
    }

    public newConfigInit() {
        this.newConfig = new DRMConfig();
        this.newConfig.skipValidate = true;
        this.newConfigErrors = [];
    }

    public newContentKeySpecInit() {
        this.newContentKeySpec = new ContentKeySpec();
        this.newContentKeySpec.required_output_protection = new RequiredOutputProtection();
        this.newContentKeySpec.track_type = TRACK_TYPES.find(tt => !this.disabledTrackTypes[tt]);
    }

    public attached() {
        this.config = null; // having value = undefined causes if.binds to not work in HTML.
        this.configPristine = null;
        this.subscriptions.push(
            this.eventAggregator.subscribe(DRM_CONFIG_EVENT.Reload, data => {
                this.configs = data.configs;
                const index = typeof data.index === 'undefined' ? this.configs.length - 1 : data.index;
                if (this.configs && this.configs.length) {
                    this.addMissingConfigTypes();
                    this.setCurrentConfig(this.configs[index], index);
                }
            }),
        );
        this.loadingError = false;
        return new Promise((resolve, reject) => {
            this.drmConfigService
                .getDRMConfigs()
                .then(cfgs => {
                    this.configs = cfgs;
                    if (this.configs && this.configs.length) {
                        this.addMissingConfigTypes();
                        this.setCurrentConfig(this.configs[0], 0);
                    }
                    resolve();
                })
                .catch(e => {
                    this.notification.error(e.message || 'Error loading DRM configs.');
                    this.loadingError = true;
                    reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    public isDirty(): boolean {
        const configCleaned = Util.compact(_.cloneDeep(this.config));
        const configPristineCleaned = Util.compact(_.cloneDeep(this.configPristine));
        this.isFormDirty = !_.isEqual(configCleaned, configPristineCleaned);
        return this.isFormDirty;
    }

    public addMissingConfigTypes() {
        this.configs.forEach(cfg => {
            if (!cfg.fairplay) {
                cfg.fairplay = new Fairplay();
            }
            if (!cfg.playready) {
                cfg.playready = new PlayReady();
            }
            if (!cfg.widevine) {
                cfg.widevine = new Widevine();
            }
            if (!cfg.widevine.content_key_specs) {
                cfg.widevine.content_key_specs = [];
            }
            if (!cfg.widevine.policy_overrides) {
                cfg.widevine.policy_overrides = new PolicyOverrides();
            }
        });
    }

    public canSetCurrentConfig(cfg, index) {
        return new Promise((resolve, reject) => {
            this.canDeactivate()
                .then(resp => {
                    if (resp) {
                        this.setCurrentConfig(cfg, index);
                    }
                    resolve();
                })
                .catch(e => {
                    log.error(e);
                    reject(e);
                });
        });
    }

    public setCurrentConfig(config, index) {
        return new Promise((resolve, reject) => {
            if (!config) {
                this.configPristine = null;
                this.config = null;
                return resolve();
            }
            this.configPristine = config;
            this.config = _.cloneDeep(config);
            this.configIndex = index;
            this.fairplay = this.config.fairplay;
            this.playready = this.config.playready;
            this.contentKeySpecs = this.config.widevine.content_key_specs;
            this.onContentKeySpecsChange();
            this.newContentKeySpecInit();
            this.policyOverrides = this.config.widevine.policy_overrides;
            if (!this.config.skipValidate) {
                this.configErrors = null;
                DRMConfigUtil.validate(this.config)
                    .then(errs => {
                        if (errs.length) {
                            this.configErrors = errs;
                        }
                        resolve();
                    })
                    .catch(err => {
                        log.error(`Validation Error: ${err}`);
                        reject(err);
                    });
            } else {
                resolve();
            }
        });
    }

    public create() {
        this.newConfig.skipValidate = false;
        return new Promise((resolve, reject) => {
            DRMConfigUtil.validate(this.newConfig)
                .then(errs => {
                    if (errs.length) {
                        this.newConfigErrors = errs;
                        return resolve();
                    }
                    this.addTip.hide();
                    this.isAdding = true;
                    this.drmConfigService
                        .createOrUpdateDRMConfig(this.newConfig)
                        .then((configs: DRMConfig[]) => {
                            this.notification.success(`${this.newConfig.name} created successfully.`);
                            this.eventAggregator.publish(DRM_CONFIG_EVENT.Reload, {configs});
                            this.newConfigInit();
                            resolve();
                        })
                        .catch(e => {
                            this.notification.error(e.message || 'Error creating DRM config.');
                            log.error(e);
                            reject(e);
                        })
                        .finally(() => {
                            this.isAdding = false;
                        });
                })
                .catch(err => {
                    log.error(`Validation Error: ${err}`);
                    reject(err);
                });
        });
    }

    public addTrackType() {
        const {
            track_type,
            security_level,
            required_output_protection: {hdcp},
        } = this.newContentKeySpec;
        const existingSpec = this.contentKeySpecs.find(cks => cks.track_type === track_type);
        if (existingSpec) {
            this.notification.warning(`${track_type} track type has already been added.
             Please remove it to add again.`);
            return;
        }
        if (!security_level && !hdcp) {
            this.notification.warning('Either Security Level or HDCP must be selected.');
            return;
        }
        this.contentKeySpecs.push(this.newContentKeySpec);
        this.onContentKeySpecsChange();
        this.newContentKeySpecInit();
    }

    public removeTrackType(index) {
        this.contentKeySpecs.splice(index, 1);
        this.onContentKeySpecsChange();
    }

    public onContentKeySpecsChange() {
        TRACK_TYPES.forEach(tt => {
            this.disabledTrackTypes[tt] = false;
        });
        this.contentKeySpecs.forEach(cks => {
            this.disabledTrackTypes[cks.track_type] = true;
        });
        this.isDirty();
    }

    public save() {
        this.config.skipValidate = false;
        return new Promise((resolve, reject) => {
            DRMConfigUtil.validate(this.config)
                .then(errs => {
                    if (errs.length) {
                        this.configErrors = errs;
                        this.notification.error('Please fix form error(s) in each tab and try again.');
                        return resolve();
                    }
                    this.isSaving = true;
                    const configCleaned = Util.compact(_.cloneDeep(this.config));
                    this.drmConfigService
                        .createOrUpdateDRMConfig(configCleaned)
                        .then((configs: DRMConfig[]) => {
                            this.notification.success(`${this.config.name} saved successfully.`);
                            this.eventAggregator.publish(DRM_CONFIG_EVENT.Reload, {configs, index: this.configIndex});
                            this.isFormDirty = false;
                            resolve();
                        })
                        .catch(e => {
                            this.notification.error(e.message || `Error saving DRM config ${this.config.name}.`);
                            reject(e);
                        })
                        .finally(() => {
                            this.isSaving = false;
                        });
                })
                .catch(err => {
                    log.error(`Validation Error: ${err}`);
                    reject(err);
                });
        });
    }

    public delete() {
        this.deleteTip.hide();
        this.isDeleting = true;
        return new Promise((resolve, reject) => {
            const deletedConfigName = this.config.name;
            this.drmConfigService
                .deleteDRMConfig(this.config.id)
                .then(() => {
                    let deletedIndex = -1;
                    let nextIndex = -1;
                    this.configs = this.configs.filter((c, index) => {
                        if (c.id === this.config.id) {
                            deletedIndex = index;
                            return false;
                        }
                        return true;
                    });
                    if (this.configs.length) {
                        if (deletedIndex === this.configs.length) {
                            nextIndex = deletedIndex - 1;
                        } else {
                            nextIndex = deletedIndex;
                        }
                    }
                    this.setCurrentConfig(this.configs[nextIndex], nextIndex);
                    this.notification.success(`${deletedConfigName} DRM config deleted successfully.`);
                    resolve();
                })
                .catch(e => {
                    this.notification.error(e.message || `Error deleting DRM config ${deletedConfigName}.`);
                    reject(e);
                })
                .finally(() => {
                    this.isDeleting = false;
                });
        });
    }

    public detached() {
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }
}
