export class GraphicsUploaderFile {
  name: string;
  progress?: number;
  error?: string;
  isUploaded: boolean = false;

  constructor(public file: File) {
    this.name = file.name.replace(/\.[^/.]+$/, '');
  }
}
