import {autoinject} from 'aurelia-framework';
import {Acceo} from 'services/acceo';
import {MSOSlicerVersionResponseItems} from '../models/version';

@autoinject
export class MSOSlicerVersionService {
    private url: string = '/api/v4/mso/slicer/version';

    constructor(public acceo: Acceo) {}

    public get(regionId: string) {
        /* FOR LOCALHOST TESTING ONLY! DO NOT COMMIT THE FOLLOWING LINE UNCOMMENTED */
        // return this.getMock(regionId);
        return this.acceo.get(MSOSlicerVersionResponseItems)(`${this.url}/${regionId}`);
    }

    // this function is only for testing on localhost
    public getMock(regionId: string) {
        const result = new MSOSlicerVersionResponseItems();
        result['@id'] = `/api/v4/mso/slicer/version/${regionId}`;
        // tslint:disable: object-literal-sort-keys
        result.items = [
            {
                // "dockerTag": "0.8.21081100",
                version_id: '0.8.21081100',
                slicer_version: '21081100',
                description: 'Latest version generated from slicer version 21081100',
                creation_time: 'Oct 18, 2021 11:23:42 AM',
            },
            {
                // "dockerTag": "0.7.21072900",
                version_id: '21072900',
                slicer_version: '21072900',
                description: 'Updated custom tag',
                creation_time: 'Sep 21, 2021 8:04:09 PM',
            },
            {
                // "dockerTag": "0.8.21081100",
                version_id: 'LATEST',
                slicer_version: '21081100',
                description: 'Latest version generated from slicer version 21081100',
                creation_time: 'Oct 18, 2021 11:23:42 AM',
            },
            {
                // "dockerTag": "0.6.20010700",
                version_id: '1',
                slicer_version: '20010700',
            },
            {
                // "dockerTag": "21060800-pkoshevoy",
                version_id: '21060800-pkoshevoy',
                slicer_version: '21060800',
                description: 'Latest version generated from slicer version 21060800',
                creation_time: 'Jun 9, 2021 1:58:10 PM',
            },
            {
                // "dockerTag": "21060800-pkoshevoy",
                version_id: 'TestHWENC',
                slicer_version: '21060800',
                description: 'Custom tag for testing forcing of hwenc',
                creation_time: 'Jun 10, 2021 8:05:00 AM',
            },
            {
                // "dockerTag": "0.6.2001070",
                version_id: 'LL',
                slicer_version: '2001070',
            },
            {
                // "dockerTag": "0.6.20122400",
                version_id: '0.6.20122400',
                slicer_version: '20122400',
                description: 'Latest version generated from slicer version 20122400',
                creation_time: 'Jun 9, 2021 1:52:58 PM',
            },
            {
                // "dockerTag": "0.7.20122400",
                version_id: '0.7.20122400',
                slicer_version: '20122400',
                description: 'Latest version generated from slicer version 20122400',
                creation_time: 'Jun 10, 2021 9:28:33 AM',
            },
            {
                // "dockerTag": "0.6.20081700",
                version_id: 'BOAZ_TEST',
                slicer_version: '20081700',
                description: 'Testing custom tags',
                creation_time: 'Jan 26, 2021 3:59:32 PM',
            },
            {
                // "dockerTag": "0.6.20081700",
                version_id: '0.6.20081700',
                slicer_version: '20081700',
                description: 'Latest version generated from slicer version 20081700',
                creation_time: 'Jan 26, 2021 3:00:23 PM',
            },
            {
                // "dockerTag": "0.7.21061800",
                version_id: '0.7.21061800',
                slicer_version: '21072900',
                description: 'Latest version generated from slicer version 21072900',
                creation_time: 'Sep 21, 2021 7:47:50 PM',
            },
            {
                // "dockerTag": "21021100-upscale",
                version_id: 'C360',
                slicer_version: '21021100',
                description: 'Custom tag for testing C360 saturation issue',
                creation_time: 'Feb 18, 2021 8:28:00 PM',
            },
            {
                // "dockerTag": "0.8.21081100",
                version_id: 'STABLE',
                slicer_version: '21081100',
                description: 'Updated stable tag',
                creation_time: 'Dec 9, 2021 2:47:06 PM',
            },
        ];
        return Promise.resolve(result);
    }
}
