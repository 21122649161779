import {computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';

import {CTableCol} from '@bindable-ui/bindable';

import {BaseAssetSingle} from './base';

export class AssetMetadata extends BaseAssetSingle {
  public addMetaTip: any;
  public isDeletingMeta: boolean = false;
  public isUploading: boolean = false;
  public metaKey: string = '';
  public metaKeyHasFocus: boolean = false;
  public metaValue: string = '';
  public metaKeyError: string = '';
  public metaChangeTracker: number;
  public selectedMeta: any[] = [];

  public metadataCols: CTableCol[] = [
    {
      checkChanged: row => this.trackSelectedMeta(row),
      colClass: 't30',
      colHeadName: 'checkbox',
      colHeadSelectedChanged: isChecked => this.selectAllMeta(isChecked),
      colHeadSelectedVal: false,
      colHeadValue: 'Select',
      view: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check.html'),
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/tables/td-contents/c-td-check/c-td-check'),
    },
    {
      colHeadName: 'key',
      colHeadValue: 'Key',
      sort: true,
    },
    {
      _class: 'textInput',
      colClass: 't240',
      colHeadName: 'value',
      colHeadValue: 'Value',
      rowValChanged: () => {
        this.metaChangeTracker = Date.now();
      },
      view: PLATFORM.moduleName(
        '@bindable-ui/bindable/components/tables/td-contents/c-td-text-input/c-td-text-input.html',
      ),
      viewModel: PLATFORM.moduleName(
        '@bindable-ui/bindable/components/tables/td-contents/c-td-text-input/c-td-text-input',
      ),
    },
  ];

  public metadataColsRO: CTableCol[] = [
    {
      colHeadName: 'key',
      colHeadValue: 'Key',
      sort: true,
    },
  ];

  public tabFields: string[] = ['meta'];

  public metaTipActions = {
    onHide: () => {
      this.metaKeyHasFocus = false;
    },
    onShow: () => {
      this.metaKey = '';
      this.metaValue = null;
      this.metaKeyHasFocus = true;
    },
  };

  /* Aurelia Hooks */
  public attached() {
    this.content.activeTab = 'metadata';
    this.metaFormInit();
    super.attached();
  }

  /* Computed Properties */
  @computedFrom('selectedMeta.length', 'model.mine')
  get deleteButtonState() {
    if (!this.model || !this.model.mine || !this.selectedMeta.length) {
      return 'disabled';
    }

    return '';
  }

  // Aurelia won't track an array, so we have track when a value is changed
  @computedFrom('model.meta', 'metaChangeTracker')
  get metaDirty() {
    if (!this.content.currentModel || !this.model) {
      return false;
    }

    let isDirty = false;
    const {meta = []} = this.content.currentModel;
    const modelMeta = this.model.meta || [];

    if (meta.length !== modelMeta.length) {
      isDirty = true;
    }

    if (!isDirty) {
      meta.forEach(_meta => {
        const otherMeta = _.find(modelMeta, {key: _meta.key});

        if (!otherMeta || otherMeta.value !== _meta.value || (otherMeta as any)._hidden) {
          isDirty = true;
          return false; // Break out of loop
        }
        return true;
      });
    }

    // Update field `isDirty` state
    this.fields.meta.isDirty = isDirty;

    return isDirty;
  }

  @computedFrom('metaDirty')
  get tabDirty() {
    return this.metaDirty;
  }

  @computedFrom('model.meta', 'metaChangeTracker')
  get meta() {
    return this.model.meta.filter(m => !m.key.startsWith('_upl'));
  }

  /* Public Methods */
  public addMetadata() {
    this.metaKeyError = '';
    if (!this.metaKey) {
      this.metaKeyError = 'Key is required.';
    } else if (
      _.find(
        this.model.meta,
        m =>
          // allow duplicate key only if existing one is soft deleted.
          m.key === this.metaKey && !(m as any)._hidden,
      )
    ) {
      this.metaKeyError = 'Key already used.';
    }
    if (!this.metaKeyError) {
      this.model.meta.unshift({
        key: this.metaKey,
        value: this.metaValue,
      });
      this.addMetaTip.toggleVisible();
      this.metaFormInit();
    }
    this.metaChangeTracker = Date.now();
  }

  public async deleteMetadata() {
    _.remove(this.model.meta, m => m.checkbox);
    this.selectedMeta = [];
    this.metaChangeTracker = Date.now();
  }

  public metaFormInit() {
    this.metaKey = '';
    this.metaValue = '';
    this.metaKeyError = '';
  }

  /*
   * Select/Deselect all meta items
   *
   * @param isSelected
   */
  public selectAllMeta(isSelected: boolean) {
    const {meta = []} = this.model;

    meta.forEach(_meta => {
      _meta.checkbox = isSelected;
    });
  }

  public trackSelectedMeta(meta) {
    if (meta.checkbox) {
      this.selectedMeta.push(meta.key);
    } else {
      _.remove(this.selectedMeta, metaKey => metaKey === meta.key);
    }
  }
}
