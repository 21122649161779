import {PLATFORM} from 'aurelia-pal';
import {RouteConfig, Router, RouterConfiguration} from 'aurelia-router';

export class EncodingProfiles {
    public isLoading: boolean;
    public routes: RouteConfig[];
    constructor(public router: Router) {
        this.router = router;

        this.routes = [
            {
                moduleId: PLATFORM.moduleName('./ratecard'),
                name: 'ratecard',
                nav: true,
                route: '',
                title: 'Rate Cards',
            },
            {
                moduleId: PLATFORM.moduleName('./assign'),
                name: 'assign',
                nav: true,
                route: 'assign',
                title: 'Assign',
            },
            {
                moduleId: PLATFORM.moduleName('./details'),
                name: 'details',
                nav: true,
                route: 'details',
                title: 'Details',
            },
            // {
            //     moduleId: PLATFORM.moduleName('./manager'),
            //     name: 'manager',
            //     nav: true,
            //     route: 'manager',
            //     title: 'Manager',
            // },
        ];
    }

    public configureRouter(config: RouterConfiguration, router: Router) {
        this.router = router;
        config.map(this.routes);
    }

    public tabClick(row) {
        this.router.navigateToRoute(row.config.name);
    }
}
