import {DialogService} from 'aurelia-dialog';
import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {basicSetup, dirtyCheckPrompt} from 'decorators';
import {Notification} from 'resources/notification/service';
import {Authorization, authorizationConstants} from 'services/authorization';
import {SessionService} from 'services/session';

import {IPermission, PERMISSIONS} from './constants/permissions';

import {Account, KEYSMAP, KEYSMAP2} from './models/account-access';
import {TrackedAccount} from './models/tracked-account';

import {AccountAccessService} from './services/account-access-service';

export enum SaveAction {
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

@basicSetup
@dirtyCheckPrompt
@authorizationConstants
@autoinject()
export class AccountAccess {
  public accounts: TrackedAccount[];

  public isSaving: boolean;
  public isAdding: boolean;
  public isDeleting: boolean;
  public isCheckingUser: boolean;
  public isFormDirty: boolean;
  public grantUser: string;
  public password: string;
  public subscription;
  public inCorrectPw: boolean;
  public dupUser: boolean;
  public addUserTip;
  public pwRequired: boolean;
  public userRequired: boolean;
  public userNotExist: boolean;
  public deleteTip;
  public origUsername: string;
  public username: string;
  public enterPasswordModal;
  public continueAction: SaveAction;
  public passwordInput: HTMLInputElement;
  public invalidPassword: boolean = false;
  public save;

  public showModal: boolean = false;
  public modalModel;
  public modalView;

  public permissions: IPermission[] = [];
  public entitlementOptions: string[];
  // public accountOptions: string[];
  public headers = [];
  public selectedAccounts: Set<string>;

  constructor(
    public accountAccessService: AccountAccessService,
    public authService: Authorization,
    private dialogService: DialogService,
    private notification: Notification,
    private session: SessionService,
  ) {
    this.origUsername = this.session.sessionInfo.origUsername;
    this.username = this.session.sessionInfo.username;

    this.permissions = PERMISSIONS.filter(p => (p.enabled instanceof Function ? p.enabled(this.session) : true));
  }

  public attached() {
    this.generateTableHeaders();
    this.generateOptions();

    this.selectedAccounts = new Set();
  }

  /**
   * "Continues" to process update request after password success
   */
  public async continueUpdate(): Promise<boolean> {
    if (!this.password) {
      this.invalidPassword = true;
      return null;
    }
    this.enterPasswordModal.close();

    switch (this.continueAction) {
      case SaveAction.ADD:
        return this.addUser();
      case SaveAction.DELETE:
        return this.removeUser();
      case SaveAction.UPDATE:
        return this.updateAccounts();
      default:
        throw new Error('Unknown action');
    }
  }

  /**
   * Queries and loads data
   */
  public async getData(): Promise<any> {
    const resp = await this.accountAccessService.getAccountAccess();
    const accounts = _.get(resp, 'access.accounts', []);

    if (accounts.length === 0) {
      this.accounts = [];
      return;
    }

    this.accounts = accounts.map(a => new TrackedAccount(a, this.entitlementOptions));
  }

  public openAddUser() {
    this.inCorrectPw = false;
    this.userNotExist = false;
    this.pwRequired = false;
    this.userRequired = false;
    this.dupUser = false;
    this.grantUser = null;
    this.password = null;
    this.userRequired = false;
  }

  /**
   * Handle all the click events coming from the table
   * @param event HtmlEvent
   */
  public rowClicked(event: any): void {
    event.preventDefault();

    const tr = event.target.closest('tr');
    const accountId = tr.dataset.id;

    if (!accountId) {
      return;
    }

    const td = event.target.closest('td');
    const column = td?.dataset.column || null;

    switch (column) {
      case 'select':
        this.toggleAccount(accountId);
        return;
      case 'all':
        this.toggleAllEntitlements(accountId);
        return;
      case 'username':
        this.resetAccount(event, accountId);
        return;
      default:
        this.toggleEntitlement(event, accountId);
    }
  }

  public async saveData(accounts: Account[]) {
    await this.accountAccessService.updateAccountAccess(accounts, this.password);

    this.notification.success('Changes saved successfully');
  }

  public async update(action: SaveAction) {
    this.invalidPassword = false;
    this.password = undefined;
    this.continueAction = action;

    const status = await this.allowDelegation(action);

    if (status) {
      this.openPasswordVerificationModal();
      this.passwordInput.focus();
    }
  }

  public openPasswordVerificationModal() {
    this.modalModel = {
      origUsername: this.origUsername,
      username: this.username,
      actions: {
        onSave: async (password: string) => {
          this.password = password;
          return this.continueUpdate();
        },
        onClose: () => {
          this.showModal = false;
          this.modalModel = null;
          this.modalView = null;
        },
      },
    };

    this.modalView = PLATFORM.moduleName(
      'apps/cms/routes/settings/account-access/modals/authorization-verification/index',
    );
    this.showModal = true;
  }

  /**
   * Verifies that a user account exists and adds account
   */
  private async addUser(): Promise<boolean> {
    let success = true;
    this.userNotExist = false;
    this.userRequired = false;

    if (!this.grantUser) {
      this.userRequired = true;
    }

    if (this.userRequired || this.pwRequired) {
      return true;
    }

    const data = {
      addusername: this.grantUser,
      password: this.password,
    };

    const index = this.accounts.findIndex(a => a.username === this.grantUser);

    if (index > -1) {
      this.dupUser = true;
      return true;
    }

    this.isCheckingUser = true;
    let account: TrackedAccount = null;

    try {
      const resp: {error?: number; user?: any} = await this.accountAccessService.checkUser(data);
      const {user} = resp;

      this.addUserTip.hide();
      account = new TrackedAccount(user, this.entitlementOptions);
      this.accounts.push(account);
      this.isAdding = true;

      await this.saveData(this.accounts);
    } catch (ex) {
      const msg = ex.message;

      if (msg === 'Sorry, that user does not exist') {
        this.userNotExist = true;
      }

      if (account) {
        this.accounts = this.accounts.filter(a => a.id !== account.id);
      }

      this.notification.error(msg);
      success = false;
    } finally {
      this.isAdding = false;
      this.isCheckingUser = false;
    }

    return success;
  }

  /**
   * Verifies if the delegation modal needs to be show on save
   * @param action
   */
  private async allowDelegation(action: SaveAction) {
    let showDialog: boolean = false;
    let accepted = true;

    // only do this if updating permissions for existing users
    if (action !== SaveAction.UPDATE) {
      return accepted;
    }
    const accounts = this.accounts.filter(a => a.isDirty);

    if (accounts.find(a => a.newEntitlements().has(KEYSMAP.settingsTabRW))) {
      showDialog = true;
    }

    if (!showDialog) {
      return accepted;
    }

    await this.dialogService
      .open({
        model: {
          bodyViewModel: PLATFORM.moduleName('apps/cms/routes/settings/account-access/modals/delegation-body'),
          footerEnable: true,
          footerViewModel: PLATFORM.moduleName('apps/cms/routes/settings/account-access/modals/delegation-footer'),
          size: 'medium',
          title: '!!WARNING!!',
          titleHelp: 'Please carefully read over this before agreeing',
        },
        viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
      })
      .whenClosed()
      .then(response => {
        accepted = response.output;
      });

    return accepted;
  }

  /**
   * Generates the permissions options used by accounts
   */
  private generateOptions() {
    const keys = Object.keys(KEYSMAP).map(k => KEYSMAP[k]);
    const options = this.permissions.map(p => p.options.filter(o => o.includeInAll).map(o => o.value)).flat();

    this.entitlementOptions = options.filter(p => keys.includes(p));
  }

  /**
   * Creates the table headers used in the view
   */
  private generateTableHeaders() {
    this.headers = this.permissions.map(p => ({
      class: p.class,
      text: p.text,
      key: p.key,
      subText: p.options.map(o => o.text).join('/'),
    }));
  }

  /**
   * Resets an account back to it's original state
   * @param event
   * @param accountId
   */
  private resetAccount(event: any, accountId: string): void {
    const btn = event.target.closest('lynk-button');

    if (!btn) {
      return;
    }

    const {action = null} = btn.dataset;

    if (action !== 'reset') {
      return;
    }

    const account = this.accounts.find(a => a.id === accountId);

    if (!account) {
      return;
    }

    account.reset();

    this.setDirtyState();
  }

  /**
   * Removes user account
   */
  private async removeUser(): Promise<boolean> {
    let success = true;

    if (this.selectedAccounts.size === 0) {
      this.notification.error('Please select one or more account(s) to delete.');
      this.deleteTip.hide();
      return false;
    }

    this.isDeleting = true;

    try {
      this.deleteTip.hide();

      const accounts = this.accounts.filter(a => !this.selectedAccounts.has(a.id));

      await this.saveData(accounts);

      this.selectedAccounts.forEach(id => {
        const idx = this.accounts.findIndex(a => a.id === id);
        this.accounts.splice(idx, 1);
      });

      this.selectedAccounts.clear();
    } catch (ex) {
      this.notification.error(ex.message);
      success = false;
    } finally {
      this.isDeleting = false;
    }

    return success;
  }

  /**
   * Sets form dirty state
   */
  private setDirtyState(): void {
    this.isFormDirty = !!this.accounts.find(a => a.isDirty);
  }

  /**
   * Adds or removes an account from selectedAccounts
   * @param accountId
   */
  private toggleAccount(accountId: string) {
    if (this.selectedAccounts.has(accountId)) {
      this.selectedAccounts.delete(accountId);
    } else {
      this.selectedAccounts.add(accountId);
    }

    // Aurelia can't track a set, so replace it to force rendering updates
    this.selectedAccounts = new Set(this.selectedAccounts);
  }

  /**
   * Toggles all account options that are not set to manual select only
   * @param accountId
   */
  private toggleAllEntitlements(accountId: string) {
    const account = this.accounts.find(a => a.id === accountId);

    if (!account) {
      return;
    }

    account.allAccess = !account.allAccess;

    this.setDirtyState();
  }

  /**
   * Handles the options toggles
   * @param event
   * @param accountId
   */
  private toggleEntitlement(event: any, accountId: string): void {
    event.preventDefault();

    const li = event.target.closest('li');

    if (!li) {
      return;
    }

    const {value, linkedValue} = li.dataset;

    const account = this.accounts.find(a => a.id === accountId);
    const entitlements = new Set(account.cms_entitlements);

    if (value === KEYSMAP2.billing_with_me) {
      account.billing_with_me = !account.billing_with_me;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (entitlements.has(value)) {
        entitlements.delete(value);

        if (linkedValue) {
          entitlements.delete(linkedValue);
        }
      } else {
        entitlements.add(value);

        if (linkedValue) {
          entitlements.add(linkedValue);
        }
      }
    }

    account.cms_entitlements = Array.from(entitlements);

    this.setDirtyState();
  }

  /**
   * Updates canonical state of accounts
   * @param accounts
   */
  private async updateAccounts(): Promise<boolean> {
    try {
      await this.saveData(this.accounts);

      const accounts = this.accounts.filter(a => a.isDirty);
      accounts.forEach(a => a.save());

      this.setDirtyState();
    } catch (ex) {
      this.notification.error(ex.message);
      return false;
    }

    return true;
  }
}
